import React, { useState } from 'react';

import DropdownPopup from './components/DropdownPopup/DropdownPopup';
import blackArrowDown from '../../../../../../../../../../../assets/img/black-arrow-down.svg';
import styles from './Dropdown.module.css';

const dropdownPositionStyle = {
  bottomPosition: '40px'
};

const Dropdown = ({
  options,
  selectedOptions,
  onSelectCompleted,
  multipleChoice,
  inverse
}) => {
  const [showDropdownPopup, setShowDropdownPopup] = useState(false);

  const page = [];

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = [].concat(
        JSON.parse(question)
          .blocks.map(draftBlock => draftBlock.text)
          .join('\n')
      );
    } catch (error) {
      questionValue = question;
    }
    return questionValue;
  };

  const getSelectedOptionsLabel = () => {
    if (selectedOptions && selectedOptions.length) {
      return selectedOptions.map(labels => {
        if (labels.name) {
          if (labels.inverse) {
            return `NOT ${parseQuestion(labels.name)}`;
          }
          return parseQuestion(labels.name).toString();
        }
        if (labels.inverse) {
          return `NOT ${parseQuestion(labels.label)}`;
        }
        return parseQuestion(labels.label).toString();
      });
    }
    return '-';
  };

  page.push(
    <div
      key="population-label-dropdown-placeholder"
      className={`${
        styles.placeholderContainer
      } workspaces-dropdown-placeholder-label`}
      style={{ backgroundImage: `url(${blackArrowDown})` }}
      onClick={() => setShowDropdownPopup(true)}
      role="presentation"
    >
      {getSelectedOptionsLabel()}
    </div>
  );

  if (showDropdownPopup) {
    page.push(
      <DropdownPopup
        key="population-label-dropdown-placeholder-popup"
        onBlur={() => setShowDropdownPopup(false)}
        onActionDropdownBlur={() => setShowDropdownPopup(false)}
        positionStyle={dropdownPositionStyle}
        options={options}
        selectedOptions={selectedOptions}
        onSelectCompleted={onSelectCompleted}
        multipleChoice={multipleChoice}
        setShowDropdownPopup={setShowDropdownPopup}
        inverse={inverse}
      />
    );
  }

  return page;
};

export default Dropdown;
