import React, { useState } from 'react';
import qs from 'query-string';
import { useMutation } from '@apollo/react-hooks';

import './ResetPasswordPage.css';

import AuthWelcome from '../Welcome/Welcome';

import { RESET_PASSWORD } from '../../../graphql/Auth';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    minWidth: 300
  },
  avatar: {
    margin: '1em',
    textAlign: 'center '
  },
  form: {
    marginTop: 10,
    padding: '0 1em 1em 1em'
  },
  input: {
    display: 'flex'
  }
};

export default ({ location }) => {
  const query = qs.parse(location.search);
  const [email, setEmail] = useState(query.email || '');
  const [token, setToken] = useState(query.token || '');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState({});
  const [complete, setComplete] = useState(false);

  const [resetPassword, { loading: isLoading }] = useMutation(RESET_PASSWORD, {
    onCompleted: response => {
      if (response && response.resetPassword && response.resetPassword.done) {
        setEmail('');
        setToken('');
        setPassword('');
        setPassword2('');
        setComplete(true);
      } else {
        setErrors({ token: 'Token not valid!' });
      }
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setErrors({});
    setComplete(false);

    if (!email) return setErrors({ email: 'This field is required' });
    if (!password) return setErrors({ password: 'This field is required' });
    if (password !== password2) {
      setErrors({ password2: "Passwords don't match" });
      return false;
    }

    resetPassword({ variables: { password, token } });

    return true;
  };

  return (
    <div className="right-container">
      <AuthWelcome
        title="BUFFL Password Reset"
        body="Use the form to change your buffl password."
      />
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div style={styles.form}>
            {complete && (
              <div className="success-message">
                Your password has been successfully changed!
              </div>
            )}
            {errors.token && (
              <div className="error-message">{errors.token}</div>
            )}
            <div style={styles.input}>
              <input
                type="text"
                className="email-input"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            {errors.email && (
              <div className="error-message">{errors.email}</div>
            )}
            <div style={styles.input}>
              <input
                type="text"
                className="token-input"
                value={token}
                onChange={e => setToken(e.target.value)}
                placeholder="Password Reset Token"
                style={{ display: 'none' }}
              />
            </div>
            <div style={styles.input}>
              <input
                type="password"
                className="password-input"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="New Password"
              />
            </div>
            {errors.password && (
              <div className="error-message">{errors.password}</div>
            )}
            <div style={styles.input}>
              <input
                type="password"
                className="password-input"
                value={password2}
                onChange={e => setPassword2(e.target.value)}
                placeholder="Confirm New Password"
              />
            </div>
            {errors.password2 && (
              <div className="error-message">{errors.password2}</div>
            )}
          </div>
          {!isLoading ? (
            <button type="submit" className="button">
              Reset Password
            </button>
          ) : (
            <div className="button-loading">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
