import React from 'react';

import styles from './OverviewTabs.module.css';

import rightArrow from '../../../../../assets/img/right-arrow.png';

const OverviewTabs = ({ statsLoading, stats, refs, strings }) => {
  if (statsLoading) {
    return (
      <div className={`${styles.placeholderContainer} loader-container`} />
    );
  }

  const showLabel = (value, label) => {
    if (value && value !== 0) {
      return label;
    }
    return '-';
  };

  const goToLink = (label, ref) => (
    <div
      className={styles.link}
      onClick={() => {
        refs[ref].scrollIntoView();
      }}
      role="presentation"
    >
      {label}
      <img src={rightArrow} alt="Right arrow" className={styles.rightArrow} />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.tabItem}>
        <div className={`${styles.title} roboto-medium-font`}>
          {strings.LAST_MONTH_TOTAL_EARNED}
        </div>
        <div className={`${styles.big} roboto-medium-font`}>
          {showLabel(
            stats.lastMonthsTotalEarnedEuros,
            `€ ${stats.lastMonthsTotalEarnedEuros.toFixed(2)}`
          )}
        </div>
        {goToLink(strings.GO_TO_REVENUES, 'revenues')}
      </div>
      <div className={styles.tabItem}>
        <div className={`${styles.title} roboto-medium-font`}>
          {strings.USER_OF_THE_WEEK}
        </div>
        <div className={`${styles.big} roboto-medium-font`}>
          {showLabel(stats.userOfTheWeek, stats.userOfTheWeek)}
        </div>
        {goToLink(strings.GO_TO_RANKING, 'ranking')}
      </div>
      <div className={styles.tabItem}>
        <div className={`${styles.title} roboto-medium-font`}>
          {strings.NUMBER_OF_THE_USERS_ON_THE_APP}
        </div>
        <div className={`${styles.big} roboto-medium-font`}>
          {showLabel(stats.numberOfUserOnTheApp, stats.numberOfUserOnTheApp)}
        </div>
        {goToLink(strings.GO_TO_DATA, 'data')}
      </div>
    </div>
  );
};

export default OverviewTabs;
