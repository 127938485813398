import React from 'react';

import ToggleSelector from '../../../SidePropertiesContainer/components/ToggleSelector/ToggleSelector';
import Action from '../../../SidePropertiesContainer/components/Action/Action';
import Icon from '../../../../../Icon/Icon';
import ConstraintTooltip from '../../../ConstraintTooltip/ConstraintTooltip';
import isActionAllowed from '../../../../../../helpers/isActionAllowed/isActionAllowed';
import {
  QUESTION_DESIGNER_ACTION,
  SURVEY_BUILDER_POPUP_TYPES
} from '../../../../../../helpers/constants';

import styles from './SideProperties.module.css';

export default ({
  actions,
  group,
  surveyLists,
  surveyStructure,
  groupNameRef,
  surveyListGroup
}) => {
  const onDeleteClick = () => {
    const questionDesignerConstraintsPopup = localStorage.getItem(
      'qd_cpopup_delete_group_dontshow'
    );

    const confirmationPopup = {
      type: SURVEY_BUILDER_POPUP_TYPES.CONFIRMATION,
      onClick: () => {
        actions.deleteGroup(group.id);
      },
      onClose: () => actions.toggleSurveyBuilderPopup(),
      onDontShowAnymoreChecked: () => {
        if (!questionDesignerConstraintsPopup) {
          localStorage.setItem('qd_cpopup_delete_group_dontshow', true);
        }
      },
      title: 'Please confirm',
      description:
        'Are you sure you want to delete this group? \nAll questions within this group will be removed.'
    };

    if (!questionDesignerConstraintsPopup) {
      actions.toggleSurveyBuilderPopup(confirmationPopup);
    } else {
      actions.deleteGroup(group.id);
    }
  };

  const isAllowedToRandomize = isActionAllowed(
    QUESTION_DESIGNER_ACTION.GROUP_RANDOMIZE,
    surveyListGroup,
    null,
    surveyLists,
    surveyStructure
  );

  const duplicateAction =
    surveyListGroup && surveyListGroup.jumpFromDistributor ? (
      <Action
        isActionAllowed={{ allowed: true }}
        isActionAllowedChecker={destinationFlow =>
          isActionAllowed(
            QUESTION_DESIGNER_ACTION.GROUP_DUPLICATE,
            surveyListGroup,
            destinationFlow.id,
            surveyLists,
            surveyStructure
          )
        }
        onActionClick={() =>
          actions.duplicateGroup(
            group,
            'AFTER',
            surveyListGroup.jumpFromDistributor,
            surveyListGroup.jumpFromDistributor
          )
        }
        icon={<Icon type="duplicate" className={styles.icon} />}
        label="Duplicate"
      />
    ) : (
      <Action
        isActionAllowed={{ allowed: true }}
        isActionAllowedChecker={destinationFlow =>
          isActionAllowed(
            QUESTION_DESIGNER_ACTION.GROUP_DUPLICATE,
            surveyListGroup,
            destinationFlow.id,
            surveyLists,
            surveyStructure
          )
        }
        onActionClick={() => {}}
        icon={<Icon type="duplicate" className={styles.icon} />}
        propertiesComponent="BlockDestinationPosition"
        onDoneButtonPress={(location, locationQuestion) => {
          actions.duplicateGroup(group, location, locationQuestion);
        }}
        label="Duplicate"
      />
    );

  return (
    <>
      <div className={styles.blockTitle}>General</div>
      <div
        className={
          isAllowedToRandomize && isAllowedToRandomize.allowed
            ? styles.blockContent
            : styles.blockContentWithTooltip
        }
      >
        {isAllowedToRandomize && isAllowedToRandomize.allowed ? (
          <ToggleSelector
            label="Randomise all blocks in group"
            onChange={() =>
              actions.updateGroupProperty(
                group.id,
                'randomized',
                !group.randomized
              )
            }
            value={group.randomized}
          />
        ) : (
          [
            'Randomise all blocks in group',
            <ConstraintTooltip label={isAllowedToRandomize.message} />
          ]
        )}
      </div>
      <div className={styles.blockTitle}>Actions</div>
      <div className={styles.blockContent}>
        <Action
          isActionAllowed={isActionAllowed(
            QUESTION_DESIGNER_ACTION.GROUP_UNGROUP,
            surveyListGroup,
            null,
            surveyLists,
            surveyStructure
          )}
          confirmationRequired={false}
          onActionClick={() => actions.ungroup(group.id)}
          icon={<Icon type="layer" className={styles.icon} />}
          label="Ungroup"
        />
        <Action
          isActionAllowed={{ allowed: true }}
          confirmationRequired={false}
          onActionClick={() => groupNameRef.focus()}
          icon={<Icon type="layer" className={styles.icon} />}
          label="Rename group"
        />
        {duplicateAction}
        <Action
          isActionAllowed={isActionAllowed(
            QUESTION_DESIGNER_ACTION.GROUP_MOVE,
            surveyListGroup,
            null,
            surveyLists,
            surveyStructure
          )}
          isActionAllowedChecker={destinationFlow =>
            isActionAllowed(
              QUESTION_DESIGNER_ACTION.GROUP_MOVE,
              surveyListGroup,
              destinationFlow.id,
              surveyLists,
              surveyStructure
            )
          }
          confirmationRequired={false}
          onActionClick={() => {}}
          icon={<Icon type="move" className={styles.icon} />}
          propertiesComponent="BlockDestinationPosition"
          onDoneButtonPress={(
            location,
            locationQuestion,
            setBlockDestinationDropdown
          ) => {
            actions.moveGroup(group.id, location, locationQuestion);
            setBlockDestinationDropdown(null);
          }}
          label="Move To"
        />
        <Action
          isActionAllowed={isActionAllowed(
            QUESTION_DESIGNER_ACTION.GROUP_DELETE,
            surveyListGroup,
            null,
            surveyLists,
            surveyStructure
          )}
          confirmationRequired={false}
          onActionClick={() => onDeleteClick()}
          icon={<Icon type="trash" className={styles.icon} />}
          label="Delete"
        />
      </div>
    </>
  );
};
