import React from 'react';
import { withRouter } from 'react-router-dom';

import buffl from '../../../assets/img/buffl.svg';

import styles from './AppBar.module.css';
/* global localStorage */

const BufflBar = ({ builder = false, hideBufflLogo, customThemeImage }) => (
  <header
    className={`${styles.appBarContainer} ${
      builder ? styles.appBarBuilder : ''
    }`}
  >
    <div className={styles.webSurveyLogoContainer}>
      {(!customThemeImage ||
        (customThemeImage &&
          customThemeImage.NAME &&
          customThemeImage.IMAGE &&
          customThemeImage.IMAGE === 'default')) &&
      !hideBufflLogo ? (
        <img src={buffl} alt="BUFFL" className={styles.webSurveyLogo} />
      ) : null}

      {customThemeImage &&
      customThemeImage.IMAGE &&
      customThemeImage.IMAGE !== 'default' ? (
        <img
          src={customThemeImage.IMAGE}
          alt="BUFFL"
          className={styles.webSurveyCustomLogo}
        />
      ) : null}
    </div>
  </header>
);

export default withRouter(BufflBar);
