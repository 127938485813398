import React, { useState, memo, useEffect } from 'react';

import VideoPopup from './components/QuestionThumbnail/components/VideoPopup/VideoPopup';
import Icon from '../../../Icon/Icon';
import Draft from './components/Draft/Draft';
import QuestionThumbnail from './components/QuestionThumbnail/QuestionThumbnail';
import SidePropertiesContainer from '../SidePropertiesContainer/SidePropertiesContainer';
import SideProperties from './components/SideProperties/SideProperties';
import QuestionProperties from './components/QuestionProperties/QuestionProperties';
import Popup from '../../../../../Popup/Popup';

import { QUESTION_TYPES } from '../../../../helpers/constants';

import useOnScreen from '../../../../hooks/useOnScreen';
import getQuestionPlaceholder from '../../../../helpers/getQuestionPlaceholder';

import styles from './Question.module.css';

export default memo(
  ({
    question,
    isActiveQuestion,
    actions,
    divRef,
    setDivRef,
    surveyLists,
    indexInSet,
    setIndex,
    innerSurveyStructure,
    isAdmin,
    isProfilingTagsManager,
    maxSurveyResponses,
    surveyListQuestion,
    surveyLanguage,
    surveyPageRef
  }) => {
    const [activeDraftButtons, setActiveDraftButtons] = useState([]);
    const [toggleDraftButton, setToggleDraftButton] = useState(null);
    const [addDraftEmoji, setAddDraftEmoji] = useState(null);
    const [videoPopup, setVideoPopup] = useState(null);

    const onScreen = useOnScreen(divRef, '0px');

    useEffect(
      () => {
        setActiveDraftButtons([]);
        setToggleDraftButton(null);
        setAddDraftEmoji(null);
      },
      [isActiveQuestion]
    );

    // console.log(`Render Question ${question.id}`);

    const onChangeQuestionProperty = (property, value) => {
      actions.updateQuestionProperty(property, value);
    };

    let questionIcon = question.type;
    if (
      question.type === QUESTION_TYPES.MULTIPLE_CHOICE &&
      question.rankAnswers
    ) {
      questionIcon = QUESTION_TYPES.RANKING;
    } else if (
      question.type === QUESTION_TYPES.MULTIPLE_CHOICE &&
      question.isCommunityAssignQuestion
    ) {
      questionIcon = QUESTION_TYPES.ADD_TO_COMMUNITY;
    }

    let questionPlaceholderRankingQuestion = question.type;
    if (
      question.type === QUESTION_TYPES.MULTIPLE_CHOICE &&
      question.rankAnswers
    ) {
      questionPlaceholderRankingQuestion = QUESTION_TYPES.RANKING;
    } else if (
      question.type === QUESTION_TYPES.MULTIPLE_CHOICE &&
      question.isCommunityAssignQuestion
    ) {
      questionPlaceholderRankingQuestion = QUESTION_TYPES.ADD_TO_COMMUNITY;
    }
    const questionPlaceholder = getQuestionPlaceholder(
      questionPlaceholderRankingQuestion
    );

    // Not handling 2nd level nesting
    const sidePropetiesRightPosition =
      surveyListQuestion && surveyListQuestion.group ? '-350px' : '-315px';

    let questionContainerClassName = `${styles.questionContainer} question-${
      question.id
    }`;
    if (isActiveQuestion) {
      questionContainerClassName = `${questionContainerClassName} ${
        styles.active
      } survey-active-question-container`;
    }
    if (surveyListQuestion && surveyListQuestion.group) {
      questionContainerClassName = `${questionContainerClassName} ${
        styles.questionInGroup
      }`;
    }

    const notAllowedTextContentEdit =
      surveyListQuestion &&
      surveyListQuestion.profiling &&
      surveyListQuestion.profiling.isProfilingQuestion &&
      (surveyListQuestion.profiling.answerCollected ||
        surveyListQuestion.profiling.isReusedProfilingQuestion ||
        !isProfilingTagsManager);

    return (
      <div
        className={
          isActiveQuestion
            ? `${styles.container} active-qroot-container`
            : styles.container
        }
      >
        {videoPopup ? (
          <Popup
            component={
              <VideoPopup video={videoPopup} setVideoPopup={setVideoPopup} />
            }
            onClose={() => {
              setVideoPopup(null);
            }}
          />
        ) : null}
        <div className={questionContainerClassName} ref={r => setDivRef(r)}>
          <div className={styles.blockContainer}>
            <div className={styles.blockStructure}>
              <div className={styles.blockNumber} />
              <div className={styles.blockIcon}>
                <span className={styles.icon}>
                  <Icon type={questionIcon} />
                </span>
              </div>
              <div className={styles.blockContent}>
                <QuestionThumbnail
                  question={question}
                  path="mediaFilename"
                  label="Upload"
                  image="media"
                  videoPopup={videoPopup}
                  setVideoPopup={setVideoPopup}
                  type={question.type}
                />
                {question.type !== QUESTION_TYPES.MATRIX && (
                  <div
                    className={`${styles.blockRow} question-draft-${
                      question.id
                    } question-editor-container`}
                  >
                    {notAllowedTextContentEdit ? (
                      <div className={styles.draftOverlay} />
                    ) : null}
                    {questionPlaceholder ? (
                      <Draft
                        addDraftEmoji={addDraftEmoji}
                        setAddDraftEmoji={setAddDraftEmoji}
                        setActiveDraftButtons={setActiveDraftButtons}
                        toggleDraftButton={toggleDraftButton}
                        setToggleDraftButton={setToggleDraftButton}
                        isActiveQuestion={isActiveQuestion}
                        value={
                          question && question.question ? question.question : ''
                        }
                        placeholder={questionPlaceholder}
                        onChangeQuestionProperty={onChangeQuestionProperty}
                      />
                    ) : (
                      <span className={styles.noQuestionPlaceholder}>
                        Add a text block above to provide instructions
                      </span>
                    )}
                  </div>
                )}
                <QuestionProperties
                  question={question}
                  isActiveQuestion={isActiveQuestion}
                  onScreen={onScreen}
                  actions={actions}
                  surveyListQuestion={surveyListQuestion}
                  surveyLists={surveyLists}
                  surveyStructure={innerSurveyStructure}
                  isAdmin={isAdmin}
                  maxSurveyResponses={maxSurveyResponses}
                  isProfilingTagsManager={isProfilingTagsManager}
                  notAllowedTextContentEdit={notAllowedTextContentEdit}
                  surveyPageRef={surveyPageRef}
                  surveyLanguage={surveyLanguage}
                  addDraftEmoji={addDraftEmoji}
                  setAddDraftEmoji={setAddDraftEmoji}
                  setActiveDraftButtons={setActiveDraftButtons}
                  toggleDraftButton={toggleDraftButton}
                  setToggleDraftButton={setToggleDraftButton}
                />
              </div>
            </div>
            {question.rerenderControlValue && (
              <div className="rerender-control-field">
                {question.rerenderControlValue}
              </div>
            )}
          </div>
          {isActiveQuestion && (
            <SidePropertiesContainer
              key="side-properties"
              title={
                question.type === QUESTION_TYPES.DISTRIBUTOR
                  ? 'Edit distributor'
                  : 'Edit question'
              }
              icon
              right={sidePropetiesRightPosition}
            >
              <SideProperties
                isActiveQuestion={isActiveQuestion}
                setAddDraftEmoji={setAddDraftEmoji}
                activeDraftButtons={activeDraftButtons}
                setToggleDraftButton={setToggleDraftButton}
                setActiveDraftButtons={setActiveDraftButtons}
                questionType={question.type}
                actions={actions}
                indexInSet={indexInSet}
                setIndex={setIndex}
                questionId={question.id}
                question={question}
                surveyLists={surveyLists}
                surveyStructure={innerSurveyStructure}
                isAdmin={isAdmin}
                isProfilingTagsManager={isProfilingTagsManager}
                surveyLanguage={surveyLanguage}
                notAllowedTextContentEdit={notAllowedTextContentEdit}
              />
            </SidePropertiesContainer>
          )}
        </div>
      </div>
    );
  }
);
