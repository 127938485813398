export default (
  graph,
  features,
  xQuestionName,
  yQuestionName,
  xLabel,
  yLabel
) => {
  let featuresHaveChanged = true;
  if (
    ((!graph.features || (graph.features && graph.features.length === 0)) &&
      (!features || (features && features.length === 0))) ||
    (graph.features &&
      (graph.features.length === features.length &&
        features.every(inputF =>
          graph.features.some(
            f =>
              inputF[xQuestionName] === f[xQuestionName] &&
              inputF[yQuestionName] === f[yQuestionName] &&
              inputF.choiceId === f.choiceId &&
              inputF.description === f.description
          )
        )))
  ) {
    featuresHaveChanged = false;
  }
  // If features or xLabel or yLabel is different then graph has changed
  if (
    graph.xLabel !== xLabel ||
    graph.yLabel !== yLabel ||
    featuresHaveChanged
  ) {
    return true;
  }
  return false;
};
