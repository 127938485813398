import { select, put } from 'redux-saga/effects';

import * as actions from '../../actions';

import deleteGroup from './deleteGroup';

import executeRequestsInSequence from '../../helpers/executeRequestsInSequence';
import surveyPermissionsCharge from '../../../helpers/surveyPermissionsCharge';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);
  const authState = yield select(state => state.auth);

  const newState = deleteGroup(surveysState, action.groupId);

  // Account management warnings
  const newPermissionsStateData = surveyPermissionsCharge(authState, newState);
  if (newPermissionsStateData) {
    newState.newState = {
      ...newState.newState,
      ...newPermissionsStateData
    };
  }

  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  // Remove all graphs of decision framework questions
  if (newState.requests && newState.requests.length) {
    yield executeRequestsInSequence(action.api, newState.requests);
  }

  action.api('deleteQuestions', {
    questionIdsArray: newState.deletedQuestions,
    surveyContent: newState.newState.survey.content
  });

  // Remove selected question id from url
  if (action.history && newState.newState && newState.newState.survey) {
    action.history.push(`/survey/${newState.newState.survey.id}/edit/`);
  }

  // If flow jump from other question after this action is changed, update it
  if (
    newState.changedFlowsRemovedJumpsToUpdate &&
    newState.changedFlowsRemovedJumpsToUpdate.length
  ) {
    newState.changedFlowsRemovedJumpsToUpdate.forEach(q => {
      if (q && !q.flows) {
        action.api('updateQuestion', { question: { ...q } });
      }
    });
  }
}
