export const RECOMMENDED_DEFAULT_VALUE = {
  $and: [
    {
      $dynamic: {
        $max: 15,
        $type: '%'
      },
      age: {
        $gte: 18,
        $lte: 24
      }
    },
    {
      $dynamic: {
        $max: 25,
        $type: '%'
      },
      age: {
        $gte: 25,
        $lte: 34
      }
    },
    {
      $dynamic: {
        $max: 29,
        $type: '%'
      },
      age: {
        $gte: 35,
        $lte: 44
      }
    },
    {
      $dynamic: {
        $max: 30,
        $type: '%'
      },
      age: {
        $gte: 45,
        $lte: 54
      }
    },
    {
      $dynamic: {
        $max: 32,
        $type: '%'
      },
      age: {
        $gte: 55,
        $lte: 65
      }
    },
    {
      $dynamic: {
        $max: 55,
        $type: '%'
      },
      gender: {
        $in: ['Male']
      }
    },
    {
      $dynamic: {
        $max: 55,
        $type: '%'
      },
      gender: {
        $in: ['Female']
      }
    },
    {
      $dynamic: {
        $max: 5,
        $type: '%'
      },
      gender: {
        $in: ['Other']
      }
    }
  ]
};

export const RECOMMENDED_DEFAULT_VALUE_WITH_65 = {
  $and: [
    {
      $dynamic: {
        $max: 12,
        $type: '%'
      },
      age: {
        $gte: 18,
        $lte: 24
      }
    },
    {
      $dynamic: {
        $max: 20,
        $type: '%'
      },
      age: {
        $gte: 25,
        $lte: 34
      }
    },
    {
      $dynamic: {
        $max: 24,
        $type: '%'
      },
      age: {
        $gte: 35,
        $lte: 44
      }
    },
    {
      $dynamic: {
        $max: 25,
        $type: '%'
      },
      age: {
        $gte: 45,
        $lte: 54
      }
    },
    {
      $dynamic: {
        $max: 26,
        $type: '%'
      },
      age: {
        $gte: 55,
        $lte: 65
      }
    },
    {
      $dynamic: {
        $max: 31,
        $type: '%'
      },
      age: {
        $gte: 66,
        $lte: 100
      }
    },
    {
      $dynamic: {
        $max: 55,
        $type: '%'
      },
      gender: {
        $in: ['Male']
      }
    },
    {
      $dynamic: {
        $max: 55,
        $type: '%'
      },
      gender: {
        $in: ['Female']
      }
    },
    {
      $dynamic: {
        $max: 5,
        $type: '%'
      },
      gender: {
        $in: ['Other']
      }
    }
  ]
};

export const STRICT_DEFAULT_VALUE = {
  $and: [
    {
      $dynamic: {
        $max: 12,
        $type: '%'
      },
      age: {
        $gte: 18,
        $lte: 24
      }
    },
    {
      $dynamic: {
        $max: 21,
        $type: '%'
      },
      age: {
        $gte: 25,
        $lte: 34
      }
    },
    {
      $dynamic: {
        $max: 21,
        $type: '%'
      },
      age: {
        $gte: 35,
        $lte: 44
      }
    },
    {
      $dynamic: {
        $max: 22,
        $type: '%'
      },
      age: {
        $gte: 45,
        $lte: 54
      }
    },
    {
      $dynamic: {
        $max: 24,
        $type: '%'
      },
      age: {
        $gte: 55,
        $lte: 65
      }
    },
    {
      $dynamic: {
        $max: 50,
        $type: '%'
      },
      gender: {
        $in: ['Male']
      }
    },
    {
      $dynamic: {
        $max: 51,
        $type: '%'
      },
      gender: {
        $in: ['Female']
      }
    },
    {
      $dynamic: {
        $max: 5,
        $type: '%'
      },
      gender: {
        $in: ['Other']
      }
    }
  ]
};

export const STRICT_DEFAULT_VALUE_WITH_65 = {
  $and: [
    {
      $dynamic: {
        $max: 10,
        $type: '%'
      },
      age: {
        $gte: 18,
        $lte: 24
      }
    },
    {
      $dynamic: {
        $max: 16,
        $type: '%'
      },
      age: {
        $gte: 25,
        $lte: 34
      }
    },
    {
      $dynamic: {
        $max: 16,
        $type: '%'
      },
      age: {
        $gte: 35,
        $lte: 44
      }
    },
    {
      $dynamic: {
        $max: 17,
        $type: '%'
      },
      age: {
        $gte: 45,
        $lte: 54
      }
    },
    {
      $dynamic: {
        $max: 18,
        $type: '%'
      },
      age: {
        $gte: 55,
        $lte: 65
      }
    },
    {
      $dynamic: {
        $max: 23,
        $type: '%'
      },
      age: {
        $gte: 66,
        $lte: 100
      }
    },
    {
      $dynamic: {
        $max: 50,
        $type: '%'
      },
      gender: {
        $in: ['Male']
      }
    },
    {
      $dynamic: {
        $max: 51,
        $type: '%'
      },
      gender: {
        $in: ['Female']
      }
    },
    {
      $dynamic: {
        $max: 5,
        $type: '%'
      },
      gender: {
        $in: ['Other']
      }
    }
  ]
};
