import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import UserConsents from './components/UserConsents/UserConsents';
import Footer from '../Footer/Footer';
import Loader from '../../../../base/components/Loader/Loader';
import TranslationInputField from '../TranslationInputField/TranslationInputField';

import styles from './CampaignCompletedPage.module.css';

import {
  CUSTOM_THEME_COLOR_NAMES,
  CUSTOM_THEME_IMAGE_NAMES
} from '../../../../../clients/surveys/helpers/constants';

import { GET_RESULT } from '../../../../graphql/Campaigns';

export default props => {
  const {
    campaign,
    result,
    strings,
    match,
    setCampaignComponents,
    location: { search },
    history,
    setHideBufflLogo,
    customTranslationsLoaded,
    setCustomTranslations,
    translations,
    updateTranslationsLanguage,
    setCustomThemeBaseColor,
    setCustomThemeImage,
    savingTranslationError,
    setSavingTranslationError,
    clientId
  } = props;

  const [consentCompleted, setConsentCompleted] = useState(false);

  const customCopyFields = {
    completed: {
      name: 'completed',
      fields: {
        THANK_YOU: 'THANK_YOU',
        MORE_IMPACT: 'MORE_IMPACT',
        YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY:
          'YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY',
        COMPLETED: 'COMPLETED'
      }
    }
  };

  const languages = {
    ENGLISH: 'English',
    DUTCH: 'Dutch',
    FRENCH: 'French',
    GERMAN: 'German'
  };

  const userLanguages = {
    EN: 'English',
    NL: 'Dutch',
    FR: 'French',
    DE: 'German'
  };

  let campaignLanguage = languages.DUTCH;
  if (result && result.userData && result.userData.language) {
    campaignLanguage = userLanguages[result.userData.language];
  } else if (campaign && campaign.language) {
    campaignLanguage = campaign.language;
  }

  const [getResult] = useLazyQuery(GET_RESULT, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      setCampaignComponents(
        response.result.result.survey,
        response.result.nextBlock,
        response.result.result
      );
    }
  });

  useEffect(() => {
    if (!campaign) {
      getResult({ variables: { id: match.params.resultId } });
    }
  }, []);

  useEffect(
    () => {
      if (
        campaign &&
        ((campaign.hideLogoInWebSurvey && campaign.customBranding) ||
          campaign.adaptConsentForExternalInWebSurvey)
      )
        setHideBufflLogo(true);
      if (
        campaign &&
        !(campaign.customCopies && campaign.customCopies.length)
      ) {
        updateTranslationsLanguage(campaignLanguage);
      }
      if (
        !customTranslationsLoaded &&
        campaign &&
        campaign.customCopies &&
        campaign.customCopies.length
      ) {
        setCustomTranslations(campaignLanguage, campaign.customCopies);
      }
      if (customThemeBaseColor) setCustomThemeBaseColor(customThemeBaseColor);
      if (customThemeImage) {
        setCustomThemeImage({
          NAME: customThemeLogoName,
          IMAGE: customThemeLogoImage
        });
      }
    },
    [campaign]
  );

  const isTesting = () => search === '?test=true';

  const customThemeBaseColor =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeColors &&
    campaign.customThemeColors.length &&
    campaign.customThemeColors.find(
      cT => cT.name === CUSTOM_THEME_COLOR_NAMES.BASE
    ).colorCode;

  const customThemeImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length;

  const customThemeLogoName =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).name;

  const customThemeLogoImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).imageFilename;

  const allowedToEditTranslations =
    campaign &&
    isTesting() &&
    clientId &&
    ((campaign.client && campaign.client === clientId) ||
      (campaign.collaborators &&
        campaign.collaborators.length &&
        campaign.collaborators.find(c => c.id === clientId)));

  const translationInputFieldProps = campaign
    ? {
        surveyId: campaign.id,
        clientId,
        language: campaignLanguage,
        disabled: !allowedToEditTranslations || savingTranslationError,
        testing: isTesting(),
        setSavingTranslationError
      }
    : {};

  if (result && !result.nextBlock && !result.failure) {
    document.documentElement.scrollTop = 0;
    if (!result.webSurveyFinalized && !consentCompleted) {
      return (
        <UserConsents
          campaign={campaign}
          result={result}
          setConsentCompleted={setConsentCompleted}
          strings={strings}
          translations={translations}
          isTesting={isTesting}
          match={match}
          history={history}
        />
      );
    }

    if (campaign.webVersionEmailConfirmation) {
      document.documentElement.scrollTop = 0;

      return (
        <div className={styles.parentContainer}>
          <div
            className={`${styles.mainContainer} ${
              styles.confirmationMainContainer
            }`}
          >
            <div
              className={`${styles.contentContainer} ${
                styles.confirmationContentContainer
              }`}
            >
              <div
                className={`${
                  styles.title
                } campaign-user-details-waiting-to-confirm-title`}
              >
                {translations.OPEN_MAILBOX_CONFIRM_ANSWER}
              </div>
              <div className={styles.description}>
                {translations.CHECK_SPAM_FOLDER}
              </div>
            </div>
          </div>
          <Footer disabledNext fixedPosition />
        </div>
      );
    }

    // Custom redirection page
    if (
      campaign &&
      campaign.redirectionsWebSurvey &&
      campaign.redirectionsWebSurvey.campaignEndSuccess
    ) {
      let redirectUrl = campaign.redirectionsWebSurvey.campaignEndSuccess;

      if (match.params.customClientIdentifier) {
        const urlCustomIdentifierSign = redirectUrl.includes('?') ? '&' : '?';
        redirectUrl = `${redirectUrl}${urlCustomIdentifierSign}m=${
          match.params.customClientIdentifier
        }`;
      }

      window.location.href = encodeURI(redirectUrl);

      return (
        <div className={styles.loaderContainer}>
          <Loader />
          <div className={styles.loaderText}>
            Please wait while we redirect you.
          </div>
        </div>
      );
    }

    document.documentElement.scrollTop = 0;

    return (
      <div className={styles.parentContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.contentContainer}>
            <TranslationInputField
              name={customCopyFields.completed.name}
              translationKey={customCopyFields.completed.fields.THANK_YOU}
              value={translations[customCopyFields.completed.fields.THANK_YOU]}
              customClasses={`${
                styles.title
              } campaign-user-details-waiting-to-confirm-title`}
              {...translationInputFieldProps}
            />
            <TranslationInputField
              name={customCopyFields.completed.name}
              translationKey={
                customCopyFields.completed.fields
                  .YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY
              }
              value={
                translations[
                  customCopyFields.completed.fields
                    .YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY
                ]
              }
              customClasses={`${
                styles.description
              } campaign-user-details-waiting-to-confirm-title`}
              {...translationInputFieldProps}
            />
          </div>
        </div>
        <Footer
          hiddenNext
          hideIllustrationsInWebSurvey={
            campaign &&
            campaign.hideIllustrationsInWebSurvey &&
            campaign.customBranding
          }
          customThemeBaseColor={customThemeBaseColor}
          showBackgroundGrid={{
            showColor: !(campaign && campaign.customBranding)
          }}
        />
      </div>
    );
  }

  return null;
};
