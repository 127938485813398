import React from 'react';
import styles from './SurveyUpdatedPopup.module.css';

export default () => (
  <div className={styles.container}>
    <div className={styles.title}>Survey updated</div>
    <div className={styles.message}>
      Survey updated in another session
      <div
        className={styles.button}
        onClick={() => window.location.reload()}
        role="presentation"
      >
        Reload page
      </div>
    </div>
  </div>
);
