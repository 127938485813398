import { USER_PROPERTIES } from './constants';
import { COMMUNITY_DASHBOARD_FILTER_TYPE } from '../../CommunityDashboard/helpers/constants';

export default filters =>
  filters.reduce(
    (count, filter) =>
      (filter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG ||
        filter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY) &&
      !(
        filter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY &&
        filter.userProperties &&
        filter.userProperties.propertyName &&
        (filter.userProperties.propertyName === USER_PROPERTIES.AGE ||
          filter.userProperties.propertyName === USER_PROPERTIES.GENDER ||
          filter.userProperties.propertyName === USER_PROPERTIES.LANGUAGE)
      )
        ? count + 1
        : count,
    0
  );
