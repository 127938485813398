import React from 'react';

import useLazyQuery from '../../../../../../../../surveys/hooks/useLazyQuery';

import {
  COMMUNITY_DASHBOARD_FILTER_TYPE,
  COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES
} from '../../../../../../helpers/constants';

import SearchDropdown from '../SearchDropdown/SearchDropdown';

import deleteIcon from '../../../../../../../../assets/img/delete.svg';

import styles from './AcquisitionFilter.module.css';
import MultipleSearchDropdown from '../MultipleSearchDropdown/MultipleSearchDropdown';
import DebounceInput from '../../../../../../../../campaigns/components/DebounceInput/DebounceInput';
import {
  GET_ACQUISITION_METRIC_DETAILS_FROM_URL,
  SEARCH_TRACKING_PROPERTY
} from '../../../../../../../../graphql/AcquisitionMetrics';

export default ({
  singleFilterIndex,
  appliedFilters,
  setAppliedFilters,
  onFilterSelectCompleted,
  onTypeChange,
  getTypeOptions
}) => {
  const singleFilter = appliedFilters[singleFilterIndex];

  const searchTrackingProperty = useLazyQuery(SEARCH_TRACKING_PROPERTY, {
    fetchPolicy: 'no-cache'
  });

  const getAcquisitionMetricDetailsFromURL = useLazyQuery(
    GET_ACQUISITION_METRIC_DETAILS_FROM_URL,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const setAppliedFiltersFromURL = async url => {
    const aFilters = appliedFilters;

    // setUrlDetailsLoading(true);
    const acquisitionMetricDetails = await getAcquisitionMetricDetailsFromURL({
      variables: {
        url
      }
    });
    // setUrlDetailsLoading(false);

    if (
      acquisitionMetricDetails &&
      acquisitionMetricDetails.data &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl.params
    ) {
      delete acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl
        .params.__typename;

      const paramsToAssign = {
        ...acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl
          .params
      };

      aFilters[singleFilterIndex] = {
        ...aFilters[singleFilterIndex],
        type: COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION,
        acquisition: paramsToAssign
      };
      /*
      setIsDirty(true);
      */
    }

    if (
      acquisitionMetricDetails &&
      acquisitionMetricDetails.data &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl.errors &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl.errors
        .length
    ) {
      aFilters[singleFilterIndex] = {
        ...aFilters[singleFilterIndex],
        type: COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION,
        acquisition: {}
      };
      /*
      setIsDirty(true);
      */
    }

    setAppliedFilters([...aFilters]);
  };

  const getSearchTrackingProperty = async (page, search, param) => {
    const preparedAppliedFilters = { ...appliedFilters };
    preparedAppliedFilters[param] = [];

    const endTimeframe = new Date();

    const searchResults = await searchTrackingProperty({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        search,
        param,
        startTimeframe: new Date('2024-05-01T12:00:00Z'),
        endTimeframe
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchTrackingProperty &&
      searchResults.data.searchTrackingProperty.options
      ? searchResults.data.searchTrackingProperty.options
      : [];
  };

  return (
    <div className={styles.container}>
      <div className={styles.firstRowContainer}>
        <SearchDropdown
          fetchOptions={getTypeOptions}
          placeholder="Select a filter type"
          onSelectCompleted={value => onTypeChange(value)}
          value={{
            value: singleFilter.type,
            label: COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[singleFilter.type]
          }}
          search={false}
          hideNoValueOption
        />
        <div className={styles.campaignLinkContainer}>
          <DebounceInput
            className={styles.linkInput}
            initialValue={singleFilter.acquisitionLink || ''}
            onChangeValue={search => {
              const aFilters = appliedFilters;
              aFilters[singleFilterIndex] = {
                ...aFilters[singleFilterIndex],
                acquisitionLink: search
              };
              setAppliedFilters([...aFilters]);

              setAppliedFiltersFromURL(search);
            }}
            placeholder="Paste link here"
          />
        </div>
        <img
          src={deleteIcon}
          className={styles.deleteIcon}
          onClick={() => {
            const newAppliedFilters = appliedFilters.filter(
              (aF, i) => i !== singleFilterIndex
            );
            setAppliedFilters(newAppliedFilters);
          }}
          alt="Delete filter"
          role="presentation"
        />
      </div>
      <div className={styles.secondRowContainer}>
        <div className={styles.dropdownContainers}>
          <div className={styles.searchDropdownContainer}>
            <div className={styles.searchDropdownTitle}>Channel</div>
            <MultipleSearchDropdown
              fetchOptions={(targetPage, searchString) =>
                getSearchTrackingProperty(targetPage, searchString, 'channel')
              }
              placeholder="Select channel"
              onSelectCompleted={value =>
                onFilterSelectCompleted('channel', value, 'acquisition', true)
              }
              value={{
                value: singleFilter.acquisition.channel,
                label: singleFilter.acquisition.channel
              }}
              disabled={
                singleFilter &&
                singleFilter.acquisitionLink &&
                singleFilter.acquisitionLink !== ''
              }
              hideSelectAllCheckbox
              fixedWidth={219}
            />
          </div>
          <div className={styles.searchDropdownContainer}>
            <div className={styles.searchDropdownTitle}>Launch date</div>
            <MultipleSearchDropdown
              fetchOptions={(targetPage, searchString) =>
                getSearchTrackingProperty(
                  targetPage,
                  searchString,
                  'launchDate'
                )
              }
              placeholder="dd/mm/yyyy"
              onSelectCompleted={value =>
                onFilterSelectCompleted(
                  'launchDate',
                  value,
                  'acquisition',
                  true
                )
              }
              value={{
                value: singleFilter.acquisition.launchDate,
                label: singleFilter.acquisition.launchDate
              }}
              disabled={
                singleFilter &&
                singleFilter.acquisitionLink &&
                singleFilter.acquisitionLink !== ''
              }
              hideSelectAllCheckbox
              fixedWidth={219}
            />
          </div>
          <div className={styles.searchDropdownContainer}>
            <div className={styles.searchDropdownTitle}>Partner</div>
            <MultipleSearchDropdown
              fetchOptions={(targetPage, searchString) =>
                getSearchTrackingProperty(targetPage, searchString, 'partner')
              }
              placeholder="Select partner"
              onSelectCompleted={value =>
                onFilterSelectCompleted('partner', value, 'acquisition', true)
              }
              value={{
                value: singleFilter.acquisition.partner,
                label: singleFilter.acquisition.partner
              }}
              disabled={
                singleFilter &&
                singleFilter.acquisitionLink &&
                singleFilter.acquisitionLink !== ''
              }
              hideSelectAllCheckbox
              fixedWidth={219}
            />
          </div>
          <div className={styles.searchDropdownContainer}>
            <div className={styles.searchDropdownTitle}>Medium</div>
            <MultipleSearchDropdown
              fetchOptions={(targetPage, searchString) =>
                getSearchTrackingProperty(targetPage, searchString, 'medium')
              }
              placeholder="Select medium"
              onSelectCompleted={value =>
                onFilterSelectCompleted('medium', value, 'acquisition', true)
              }
              value={{
                value: singleFilter.acquisition.medium,
                label: singleFilter.acquisition.medium
              }}
              disabled={
                singleFilter &&
                singleFilter.acquisitionLink &&
                singleFilter.acquisitionLink !== ''
              }
              hideSelectAllCheckbox
              fixedWidth={219}
            />
          </div>
          <div className={styles.searchDropdownContainer}>
            <div className={styles.searchDropdownTitle}>Action</div>
            <MultipleSearchDropdown
              fetchOptions={(targetPage, searchString) =>
                getSearchTrackingProperty(targetPage, searchString, 'action')
              }
              placeholder="Select action"
              onSelectCompleted={value =>
                onFilterSelectCompleted('action', value, 'acquisition', true)
              }
              value={{
                value: singleFilter.acquisition.action,
                label: singleFilter.acquisition.action
              }}
              disabled={
                singleFilter &&
                singleFilter.acquisitionLink &&
                singleFilter.acquisitionLink !== ''
              }
              hideSelectAllCheckbox
              fixedWidth={219}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
