import React from 'react';

import styles from './TestingBanner.module.css';

const TestingBanner = ({ customThemeBaseColor }) => (
  <div
    className={styles.testingBanner}
    style={
      customThemeBaseColor
        ? { backgroundColor: customThemeBaseColor, color: '#FFFFFF' }
        : { backgroundColor: '#EC008B', color: '#FFFFFF' }
    }
  >
    <span>
      You are now in our test environment where you can test the behaviour of
      your survey and/or modify the copy.
    </span>
  </div>
);

export default TestingBanner;
