import React, { useState, useEffect } from 'react';
import { Player, LoadingSpinner, ControlBar } from 'video-react';

import styles from '../../CampaignQuestionsPage.module.css';
import 'video-react/dist/video-react.css';
import './VideoPlayer.css';

export default props => {
  const { setValid, block } = props;
  const [playerRef, setPlayerRef] = useState(null);

  const handlePlayerStateChange = state => {
    if (state && state.ended) {
      setValid(true);
    }
  };

  useEffect(
    () => {
      if (playerRef) {
        playerRef.subscribeToStateChange(handlePlayerStateChange.bind(this));
      }
    },
    [playerRef]
  );

  return (
    <div
      className={`${styles.questionContainer} ${styles.videoPlayerContainer}`}
    >
      <Player
        ref={player => {
          setPlayerRef(player);
        }}
        fluid
        src={block.mediaUrl}
        autoPlay
      >
        <LoadingSpinner />
        <ControlBar disableCompletely />
      </Player>
    </div>
  );
};
