import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import Router from './Router';

export default ({ apiUrl }) => {
  const client = new ApolloClient({
    uri: `${apiUrl}/graphql/organisations`,
    request: async operation => {
      const authToken = localStorage.getItem('@buffl:auth-token');

      operation.setContext({
        headers: {
          ...(authToken ? { authorization: `Bearer ${authToken}` } : {})
        }
      });
    }
  });

  return (
    <div>
      <ApolloProvider client={client}>
        <Router />
      </ApolloProvider>
    </div>
  );
};
