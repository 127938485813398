const mapQuota = quotas =>
  quotas.map(q => ({
    label: q.label,
    value: q.value
  }));

export default filters =>
  filters && filters.length
    ? filters.reduce((newFilters, filter) => {
        if (filter.tags) {
          return [
            ...newFilters,
            {
              type: filter.type,
              ...{
                tagCategory: filter.tags.category,
                tagName: filter.tags.tagName,
                tagValue: filter.tags.tagValue,
                ...(filter.tags.quota && filter.tags.quota.length
                  ? { quota: mapQuota(filter.tags.quota) }
                  : {})
              }
            }
          ];
        }
        if (filter.userProperties) {
          return [
            ...newFilters,
            {
              type: filter.type,
              ...{
                propertyName: filter.userProperties.propertyName,
                propertyValue: filter.userProperties.propertyValue,
                ...(filter.userProperties.quota &&
                filter.userProperties.quota.length
                  ? { quota: mapQuota(filter.userProperties.quota) }
                  : {})
              }
            }
          ];
        }
        if (filter.community) {
          return [
            ...newFilters,
            {
              type: filter.type,
              community: filter.community.name
            }
          ];
        }
        return newFilters;
      }, [])
    : [];
