import React, { useState, useEffect } from 'react';
import DebounceInput from '../../../../../../../../campaigns/components/DebounceInput/DebounceInput';
import Loader from '../../../../../../../../campaigns/components/Loader/Loader';
import blackArrowDown from './assets/black-arrow-down.svg';
import addIcon from '../../../../../../../../assets/img/add.svg';

import styles from './DropdownSelector.module.css';

const FilterDropdownSelector = props => {
  const {
    options,
    fetchOptions,
    value,
    onSelectCompleted,
    multiple,
    placeholder,
    search,
    filterDropdownStyle,
    addNew,
    addNewCallback,
    disableDropdownExpand,
    hideNoValueOption,
    disabled,
    returnAdditionalFieldOnSelect
  } = props;

  let wrapperRef = null;

  const [dropdownWrapperRef, setDropdownWrapperRef] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(0);
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const [leftPosition, setLeftPosition] = useState(-1000); // hacky but works
  const [isFetching, setIsFetching] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(options || []);
  const [isMaxReached, setIsMaxReached] = useState(false);

  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setShowPopup(false);
    }
  };

  const fetchOptionsExecute = (targetPage, inputFieldSearch, forceSearch) => {
    const runFetchOptions = async () => {
      const searchString =
        inputFieldSearch || inputFieldSearch === ''
          ? inputFieldSearch
          : inputSearch;
      setPage(targetPage);
      const fetchedOptions = await fetchOptions(targetPage, searchString);

      if (
        availableOptions &&
        fetchedOptions &&
        availableOptions.length === fetchedOptions.length
      ) {
        setIsMaxReached(true);
      }

      setAvailableOptions(fetchedOptions);
      setIsFetching(false);
    };

    if (
      fetchOptions &&
      !isFetching &&
      (!isMaxReached || forceSearch === true)
    ) {
      setIsFetching(true);
      runFetchOptions();
    }
  };

  const onScroll = e => {
    const isDropdownPopup =
      e &&
      e.target &&
      e.target.className &&
      // ((typeof str === 'string') || (e.target.className instanceof String)) &&
      e.target.className.toString().substring(0, 35) &&
      e.target.className.toString().substring(0, 35) ===
        'DropdownSelector_filterDropdownItem'; // hacky, but I do not see a better way to solve it now

    if (!isDropdownPopup && showPopup) {
      setShowPopup(false);
    }
  };

  const emptyState = () => {
    setPage(0);
    setInputSearch('');
    setAvailableOptions([]);
    setIsMaxReached(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('wheel', onScroll, {
        passive: false
      });
      setAvailableOptions([]);
    },
    []
  );

  useEffect(() => setAvailableOptions(options), [options]);

  useEffect(
    () => {
      if (showPopup) {
        document.addEventListener('wheel', onScroll, {
          passive: false
        });
        fetchOptionsExecute(0);
      } else {
        document.removeEventListener('wheel', onScroll, {
          passive: false
        });
        emptyState();
      }
    },
    [showPopup]
  );

  const selectValue = option => {
    if (
      returnAdditionalFieldOnSelect &&
      option[returnAdditionalFieldOnSelect]
    ) {
      onSelectCompleted(
        option.value,
        option.label,
        option[returnAdditionalFieldOnSelect]
      );
    } else {
      onSelectCompleted(option.value, option.label);
    }
    if (!multiple) {
      setShowPopup(false);
    }
  };

  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    if (elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight) {
      fetchOptionsExecute(page + 1, inputSearch);
    }
  };

  const onInputSearchChangeValue = searchValue => {
    setIsMaxReached(false);
    setInputSearch(searchValue);
    fetchOptionsExecute(0, searchValue, true);
  };

  const onAddItemClick = () => {
    if (addNew) {
      onSelectCompleted(inputSearch, inputSearch);

      if (addNewCallback) {
        addNewCallback(inputSearch);
      }

      setShowPopup(false);
      emptyState();
    }
  };

  useEffect(
    () => {
      if (dropdownWrapperRef) {
        const height = dropdownWrapperRef.offsetHeight;
        const rect = dropdownWrapperRef.getBoundingClientRect();
        const win = dropdownWrapperRef.ownerDocument.defaultView;

        setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
        setLeftPosition(rect.left + win.pageXOffset - window.scrollX);
      }
    },
    [showPopup]
  );

  return (
    <div
      className={filterDropdownStyle || styles.filterDropdownSelectorContainer}
      ref={elem => {
        if (elem) {
          wrapperRef = elem;
          setDropdownWrapperRef(elem);
        }
      }}
    >
      <div
        role="presentation"
        className={
          value &&
          (value.value === '' || !value.value || value.value.length === 0)
            ? styles.selectedOptionsLabel
            : `${styles.selectedOptionsLabel} ${styles.valueSelected} ${
                disabled ? styles.dropdownDisabled : null
              }`
        }
        style={{ backgroundImage: `url(${blackArrowDown})` }}
        onClick={() => {
          if (!disableDropdownExpand && !disabled) {
            setShowPopup(!showPopup);
          }
        }}
      >
        {value &&
        (value.value === '' || !value.value || value.value.length === 0)
          ? placeholder
          : value.label}
      </div>
      {showPopup ? (
        <div
          className={styles.filterDropdownPopup}
          style={{ left: `${leftPosition}`, top: `${topPosition}px` }}
        >
          {search ? (
            <div className={styles.filterDropdownPopupSearchInputContainer}>
              <DebounceInput
                initialValue={inputSearch}
                onChangeValue={searchValue =>
                  onInputSearchChangeValue(searchValue)
                }
              />
              <div className={styles.inputLoaderContainer}>
                {isFetching && availableOptions.length > 0 ? (
                  <Loader size="small" />
                ) : null}
                {addNew &&
                !isFetching &&
                inputSearch &&
                inputSearch.trim() &&
                inputSearch.trim() !== '' ? (
                  <img
                    src={addIcon}
                    alt="Add item"
                    className={styles.addItemIcon}
                    onClick={onAddItemClick}
                    role="presentation"
                  />
                ) : null}
              </div>
            </div>
          ) : null}
          <div
            className={styles.filterDropdownPopupItemsContainer}
            onScroll={listOptionsDivScroll}
          >
            {!hideNoValueOption ? (
              <div
                className={styles.filterDropdownItem}
                role="presentation"
                onClick={() => selectValue({ label: null, value: null })}
              >
                <div className={styles.filterDropdownItemLabel}>-</div>
              </div>
            ) : null}
            {availableOptions && availableOptions.length
              ? availableOptions.map((option, index) => (
                  <div
                    className={
                      value && value.value === option.value
                        ? `${styles.filterDropdownItem} ${styles.active}`
                        : styles.filterDropdownItem
                    }
                    key={`filter-dropdown-item-${index.toString()}`}
                    role="presentation"
                    onClick={() => selectValue(option)}
                  >
                    <div className={styles.filterDropdownItemLabel}>
                      {option.label}
                    </div>
                  </div>
                ))
              : null}
            {!isFetching &&
            (!availableOptions ||
              (availableOptions && availableOptions.length === 0)) ? (
              <div className={styles.noAvailableClientAccounts}>
                No available options
              </div>
            ) : null}
            {isFetching && availableOptions.length === 0 ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterDropdownSelector;
