import getFirstAvailableDistributorFlow from '../getFirstNonDistributorFlow/getFirstAvailableDistributorFlow';

import { QUESTION_TYPES, SURVEY_END_TYPES } from '../constants';

const processSingleDistributor = (surveyToModify, distributorQuestion) => {
  if (
    distributorQuestion &&
    distributorQuestion.branches &&
    distributorQuestion.branches.length
  ) {
    // To prevent issues with old type of surveys, script will run if all branch jumps are to groups
    const branchesWithJumps = distributorQuestion.branches.filter(
      b => b.nextFlow
    );
    const anyBranchWithJumpToQuestion = branchesWithJumps.some(
      b => surveyToModify.questions[b.nextFlow]
    );

    if (branchesWithJumps.length > 0 && !anyBranchWithJumpToQuestion) {
      const distributorFlowIndex = surveyToModify.content.flows.indexOf(
        distributorQuestion.id
      );

      for (let i = distributorQuestion.branches.length - 1; i >= 0; i -= 1) {
        if (distributorQuestion.branches[i].nextFlow) {
          // Move group right after the distributor
          const existingFlowId = surveyToModify.content.flows.indexOf(
            distributorQuestion.branches[i].nextFlow
          );
          if (existingFlowId > -1) {
            // eslint-disable-next-line
            surveyToModify.content.flows = surveyToModify.content.flows.filter(
              f => f !== distributorQuestion.branches[i].nextFlow
            );
          }
          surveyToModify.content.flows.splice(
            distributorFlowIndex + 1,
            0,
            distributorQuestion.branches[i].nextFlow
          );

          // Get first available flow after distributor
          const firstAvailableFlowAfterDistributor = getFirstAvailableDistributorFlow(
            distributorQuestion.id,
            surveyToModify
          );

          const distributorGroup = surveyToModify.content.groups.find(
            g => g.id === distributorQuestion.branches[i].nextFlow
          );
          if (firstAvailableFlowAfterDistributor) {
            if (distributorGroup.end) {
              delete distributorGroup.end;
            }

            if (
              distributorGroup &&
              distributorGroup.id &&
              distributorGroup.id !== firstAvailableFlowAfterDistributor
            ) {
              distributorGroup.nextFlow = firstAvailableFlowAfterDistributor;
            }
          } else {
            if (distributorGroup.nextFlow) {
              delete distributorGroup.nextFlow;
            }
            distributorGroup.end = SURVEY_END_TYPES.SUCCESSFUL;
          }
        }
      }
    }
  }
};

export default surveyToModify => {
  const distributorQuestions = Object.values(
    surveyToModify.survey.questions
  ).filter(q => q.type === QUESTION_TYPES.DISTRIBUTOR);

  distributorQuestions.map(q =>
    processSingleDistributor(surveyToModify.survey, q)
  );
};
