import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import api from '../../../api';
import styles from './CommunicationCenter.module.css';
import AppBar from '../../../base/components/AppBar/AppBar';

import useLazyQuery from '../../../surveys/hooks/useLazyQuery';

import NotificationsList from '../NotificationsList/NotificationsList';
import SendPushNotifications from '../SendPushNotifications/SendPushNotifications';
import SendEmailNotifications from '../SendEmailNotifications/SendEmailNotifications';
import NotificationDetails from '../NotificationDetails/NotificationDetails';
import useUserback from '../../../hooks/useUserback';

import { GET_SURVEY_COMMUNITIES } from '../../../graphql/Survey';
import { GET_APP_CONFIGURATION } from '../../../graphql/Dashboard';
import {
  CANCEL_PUSH_NOTIFICATION,
  GET_PUSH_NOTIFICATIONS
} from '../../../graphql/PushNotifications';

const SECTION_KEYS = {
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  EMAIL: 'EMAIL'
};

const SECTIONS = {
  PUSH_NOTIFICATION: 'Push notification',
  EMAIL: 'Email'
};

const SECTION_PAGES = {
  [SECTION_KEYS.PUSH_NOTIFICATION]: 'push',
  [SECTION_KEYS.EMAIL]: 'email'
};

const FILTER_CONSTANTS = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  RANGE: 'RANGE',
  SINGLE_OPTION: 'SINGLE_OPTION',
  DEFAULT_PLACEHOLDER: 'Please select value'
};

const LIST_TABS = {
  SCHEDULED: 'SCHEDULED',
  HISTORY: 'HISTORY'
};

const COMMUNITY_MANAGEMENT_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC'
};

const PushNotificationsCenter = props => {
  useUserback({});

  const { history } = props;

  const [isListFetching, setIsListFetching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [pushNotificationDetails, setPushNotificationDetails] = useState(false);
  const [filterConfiguration, setFilterConfigurationGenerator] = useState(null);
  const [pushNotifications, setPushNotifications] = useState([]);
  const [
    pushNotificationToSendAgain,
    setPushNotificationToSendAgain
  ] = useState({});
  const [isAppendFetching, setIsAppendFetching] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  /* eslint-disable */
  const section =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.page === SECTION_PAGES[SECTION_KEYS.PUSH_NOTIFICATION]
      ? SECTION_KEYS.PUSH_NOTIFICATION
      : SECTION_KEYS.EMAIL;

  const selectedAccountManagementRecord =
    props &&
    props.accountManagement &&
    props.accountManagement.selectedAccountManagementRecord &&
    props.accountManagement.selectedAccountManagementRecord.value;
  /* eslint-enable */

  const [activeListTab, setActiveListTab] = useState(
    section === SECTION_KEYS.PUSH_NOTIFICATION
      ? LIST_TABS.SCHEDULED
      : LIST_TABS.HISTORY
  );

  const searchCommunitiesApi = useLazyQuery(GET_SURVEY_COMMUNITIES, {
    fetchPolicy: 'no-cache'
  });

  const getAppConfiguration = useLazyQuery(GET_APP_CONFIGURATION, {
    fetchPolicy: 'no-cache'
  });

  const [cancelPushNotificationMutation] = useMutation(
    CANCEL_PUSH_NOTIFICATION
  );

  const getPushNotifications = useLazyQuery(GET_PUSH_NOTIFICATIONS, {
    fetchPolicy: 'no-cache'
  });

  const fetchSurveys = async (page, search) => {
    const filter = `&campaignFilter[campaignNameSearch]=${search}`;

    const campaigns = await api().getCampaigns(
      selectedAccountManagementRecord,
      0,
      (page + 1) * 10,
      filter,
      '-createdAt'
    );
    if (campaigns.campaigns) {
      return campaigns.campaigns.map(option => ({
        value: option.id,
        label: option.projectName
      }));
    }
    return null;
  };

  const filterConfigurationGenerator = geodata => ({
    users: {
      type: FILTER_CONSTANTS.MULTIPLE_CHOICE,
      label: 'Users',
      name: 'users',
      search: true,
      fetchOptions: async (page, search) => {
        const users = await api().getAllUsers(0, (page + 1) * 10, search);
        if (users.json.data) {
          return users.json.data.map(option => ({
            value: option.id,
            label: option.attributes.email
          }));
        }
        return null;
      }
    },
    communities: {
      type: null, // type is determined by whether static/dynamic is selected in subFilter
      label: 'Communities',
      name: 'communities',
      search: true,
      additionalFilter: {
        options: [
          {
            label: 'Static',
            value: COMMUNITY_MANAGEMENT_TYPES.STATIC
          },
          {
            label: 'Dynamic',
            value: COMMUNITY_MANAGEMENT_TYPES.DYNAMIC
          }
        ]
      },
      fetchOptions: async (page, search, additionalFiltersInFetch) => {
        const searchResults = await searchCommunitiesApi({
          variables: {
            start: 0,
            end: (page + 1) * 10,
            search,
            salt: Math.random().toString(),
            ...(additionalFiltersInFetch || {})
          }
        });
        return searchResults &&
          searchResults.data &&
          searchResults.data.getSurveyCommunities &&
          searchResults.data.getSurveyCommunities.records &&
          searchResults.data.getSurveyCommunities.records.length
          ? searchResults.data.getSurveyCommunities.records.map(r => ({
              label: r.name,
              value: r.profilingTag.tagValue
            }))
          : [];
      }
    },
    campaigns: {
      type: FILTER_CONSTANTS.MULTIPLE_CHOICE,
      label: 'Surveys',
      name: 'campaigns',
      search: true,
      fetchOptions: fetchSurveys,
      hideFromSections: [SECTION_KEYS.EMAIL]
    },
    age: {
      type: FILTER_CONSTANTS.RANGE,
      label: 'Age',
      name: 'age',
      gteLabel: 'Younger than',
      lteLabel: 'Older than'
    },
    gender: {
      type: FILTER_CONSTANTS.MULTIPLE_CHOICE,
      label: 'Gender',
      name: 'gender',
      options: [
        {
          value: 'Male',
          label: 'Male'
        },
        {
          value: 'Female',
          label: 'Female'
        },
        {
          value: 'Other',
          label: 'Other'
        }
      ]
    },
    language: {
      type: FILTER_CONSTANTS.MULTIPLE_CHOICE,
      label: 'Language',
      name: 'language',
      options: [
        {
          label: 'Dutch',
          value: 'Dutch'
        },
        {
          label: 'French',
          value: 'French'
        }
      ]
    },
    activity: {
      type: FILTER_CONSTANTS.SINGLE_OPTION,
      label: 'Activity',
      options: [
        {
          label: 'Active today',
          value: 'activeToday'
        },
        {
          label: 'Active in past 7 days',
          value: 'activePast7Days'
        },
        {
          label: 'Active in past 30 days',
          value: 'activePast30Days'
        },
        {
          label: 'Inactive today',
          value: 'inactiveToday'
        },
        {
          label: 'Inactive in past 7 days',
          value: 'inactivePast7Days'
        },
        {
          label: 'Inactive in past 30 days',
          value: 'inactivePast30Days'
        }
      ],
      name: 'activity'
    },
    regions: {
      type: FILTER_CONSTANTS.MULTIPLE_CHOICE,
      label: 'Regions',
      name: 'regions',
      search: true,
      options: geodata.regions
    },
    postCodes: {
      type: FILTER_CONSTANTS.MULTIPLE_CHOICE,
      label: 'Post Codes',
      name: 'postCodes',
      search: true,
      fetchOptions: async (page, search) => {
        const postCodes = geodata.postCodes.filter(postCode =>
          postCode.value.includes(search)
        );
        return postCodes.slice(0, (page + 1) * 10);
      }
    },
    places: {
      type: FILTER_CONSTANTS.MULTIPLE_CHOICE,
      label: 'Places',
      name: 'places',
      search: true,
      fetchOptions: async (page, search) => {
        const places = geodata.places.filter(place =>
          place.label.includes(search)
        );
        const placesSlice = places.slice(0, (page + 1) * 10);

        return placesSlice.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              t => t.value === thing.value && t.label === thing.label
            )
        );
      }
    }
  });

  const processGeodata = geodata => {
    const regions = [];
    const postCodes = [];
    const places = [];

    Object.keys(geodata).map(region => {
      regions.push({
        value: region,
        label: region
      });

      Object.keys(geodata[region].postcodes).map(postcode => {
        postCodes.push({
          value: postcode,
          label: postcode
        });

        geodata[region].postcodes[postcode].forEach(singlePlace => {
          const value = Object.keys(singlePlace)[0];
          const label = singlePlace[value];
          const existingPlace = places.filter(
            place => place.value === singlePlace.value
          );

          if (!existingPlace || existingPlace.length === 0) {
            places.push({
              value,
              label
            });
          }
        });

        return null;
      });
      return null;
    });

    return {
      regions,
      postCodes,
      places
    };
  };

  const cancelPushNotification = async id => {
    await cancelPushNotificationMutation({ variables: { id } });
    setActiveFilters([]);
    setPushNotificationDetails(false);
    fetchOptionsExecute(false, true);
  };

  const fetchOptionsExecute = (
    newPage,
    empty,
    prepend = [],
    forceActiveListTab
  ) => {
    const getAllPushNotifications = async () => {
      if (newPage) {
        setIsAppendFetching(true);
      } else {
        setIsListFetching(true);
      }

      if (!totalPages || newPage < totalPages) {
        const tab = forceActiveListTab || activeListTab;

        const pushNotificationsData = await getPushNotifications({
          variables: {
            page: newPage || 0,
            pageSize: 10,
            scheduled: tab === LIST_TABS.SCHEDULED,
            type: section
          }
        });

        // setTotalPushNotifications(pushNotificationsData.data.getPushNotifications.total);
        setTotalPages(
          Math.ceil(pushNotificationsData.data.getPushNotifications.total / 10)
        );

        const pnToPrepend =
          prepend &&
          prepend.length &&
          pushNotificationsData &&
          pushNotificationsData.data &&
          pushNotificationsData.data.getPushNotifications &&
          pushNotificationsData.data.getPushNotifications.data &&
          pushNotificationsData.data.getPushNotifications.data[0].id ===
            prepend[0].id
            ? []
            : prepend;

        if (empty) {
          setPushNotifications([
            ...pnToPrepend,
            ...(pushNotificationsData.data.getPushNotifications.data || [])
          ]);
        }
        if (!empty) {
          setPushNotifications([
            ...pnToPrepend,
            ...(pushNotifications || []),
            ...(pushNotificationsData.data.getPushNotifications.data || [])
          ]);
        }
      }

      if (newPage) {
        setIsAppendFetching(false);
      } else {
        setIsListFetching(false);
      }
    };

    getAllPushNotifications();
  };

  const getPushNotificationsConf = async () => {
    setIsFetching(true);

    const configuration = await getAppConfiguration({});
    const parsedGeodata = JSON.parse(
      configuration.data.getAppConfiguration.geodata
    );
    const geodata = processGeodata(parsedGeodata.NL);

    setFilterConfigurationGenerator(filterConfigurationGenerator(geodata));

    setIsFetching(false);
  };

  const updateActiveFilters = (name, values) => {
    let activeFiltersToUpdate;

    activeFilters.forEach(activeFilter => {
      if (activeFilter.name === name) {
        activeFiltersToUpdate = activeFilter;
      }
    });

    if (activeFiltersToUpdate) {
      activeFiltersToUpdate.values = values;
      setActiveFilters([...activeFilters]);
    }
  };

  useEffect(
    () => {
      const newActiveListTab =
        section === SECTION_KEYS.PUSH_NOTIFICATION
          ? LIST_TABS.SCHEDULED
          : LIST_TABS.HISTORY;

      setActiveListTab(newActiveListTab);
      fetchOptionsExecute(false, true, [], newActiveListTab);
      getPushNotificationsConf();
    },
    [section]
  );

  const header = () => (
    <div className={styles.sections}>
      {Object.keys(SECTIONS).map((s, index) => (
        <div
          className={
            s === section
              ? `${styles.sectionName} ${styles.active}`
              : styles.sectionName
          }
          onClick={() => history.push(`/notifications/${SECTION_PAGES[s]}`)}
          role="presentation"
          key={`test-survey-section-item-${index.toString()}`}
        >
          {SECTIONS[s]}
        </div>
      ))}
    </div>
  );

  if (isFetching) {
    return (
      <div>
        <AppBar
          {...props}
          appBarProperties={{}}
          isFetching={isFetching}
          key="app-bar"
        />
        <div className={styles.pageContainer}>
          {header()}
          <div className={styles.pageContent}>
            <div
              className={`${
                styles.pageContentListPlaceholder
              } loader-container`}
            />
            <div
              className={`${
                styles.pageContentMainPlaceholder
              } loader-container`}
            />
          </div>
        </div>
      </div>
    );
  }

  if (filterConfiguration && !isFetching) {
    return (
      <div>
        <AppBar
          {...props}
          appBarProperties={{}}
          isFetching={isFetching}
          isAuthenticated
          isAdmin
          onGoToPortalPage={() => props.history.push('/')}
          key="app-bar"
        />
        <div className={styles.pageContainer}>
          {header()}
          <div className={styles.pageContent}>
            <NotificationsList
              isAppendFetching={isAppendFetching}
              pushNotificationDetails={pushNotificationDetails}
              setPushNotificationDetails={setPushNotificationDetails}
              isListFetching={isListFetching}
              pushNotifications={pushNotifications}
              fetchOptionsExecute={fetchOptionsExecute}
              activeListTab={activeListTab}
              setActiveListTab={setActiveListTab}
              LIST_TABS={LIST_TABS}
              section={section}
              SECTION_KEYS={SECTION_KEYS}
            />
            <div
              className={`${styles.notificationDetailsContainer} ${
                styles.whiteContainer
              }`}
            >
              {pushNotificationDetails ? (
                <NotificationDetails
                  pushNotificationDetails={pushNotificationDetails}
                  setPushNotificationDetails={setPushNotificationDetails}
                  setPushNotificationToSendAgain={
                    setPushNotificationToSendAgain
                  }
                  cancelPushNotification={cancelPushNotification}
                  section={section}
                  SECTION_KEYS={SECTION_KEYS}
                />
              ) : null}
              {section === SECTION_KEYS.PUSH_NOTIFICATION &&
              !pushNotificationDetails ? (
                <SendPushNotifications
                  filterConfiguration={filterConfiguration}
                  FILTER_CONSTANTS={FILTER_CONSTANTS}
                  COMMUNITY_MANAGEMENT_TYPES={COMMUNITY_MANAGEMENT_TYPES}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  isListFetching={isListFetching}
                  pushNotificationToSendAgain={pushNotificationToSendAgain}
                  setPushNotificationToSendAgain={
                    setPushNotificationToSendAgain
                  }
                  setPushNotificationDetails={setPushNotificationDetails}
                  LIST_TABS={LIST_TABS}
                  fetchOptionsExecute={fetchOptionsExecute}
                  section={section}
                  SECTION_KEYS={SECTION_KEYS}
                  updateActiveFilters={updateActiveFilters}
                />
              ) : null}
              {section === SECTION_KEYS.EMAIL && !pushNotificationDetails ? (
                <SendEmailNotifications
                  filterConfiguration={filterConfiguration}
                  FILTER_CONSTANTS={FILTER_CONSTANTS}
                  COMMUNITY_MANAGEMENT_TYPES={COMMUNITY_MANAGEMENT_TYPES}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  isListFetching={isListFetching}
                  pushNotificationToSendAgain={pushNotificationToSendAgain}
                  setPushNotificationToSendAgain={
                    setPushNotificationToSendAgain
                  }
                  setPushNotificationDetails={setPushNotificationDetails}
                  LIST_TABS={LIST_TABS}
                  fetchOptionsExecute={fetchOptionsExecute}
                  section={section}
                  SECTION_KEYS={SECTION_KEYS}
                  fetchSurveys={fetchSurveys}
                  updateActiveFilters={updateActiveFilters}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default PushNotificationsCenter;
