import frameWorkValueNames from '../../helpers/frameWorkValueNames';

export default (survey, question, decisionFrameworkType) => {
  let surveyWithRemovedGraph = null;
  let removeRequest = null;

  if (survey && question && decisionFrameworkType) {
    const valueNames = decisionFrameworkType
      ? frameWorkValueNames.find(
          vNames => vNames.type === decisionFrameworkType
        )
      : null;

    if (valueNames && valueNames.valuePath && valueNames.removeApi) {
      const graphToRemove = survey[valueNames.valuePath].find(
        g => g.question === question.id
      );

      if (graphToRemove && graphToRemove.id) {
        const newGraphs = [...survey[valueNames.valuePath]].filter(
          q => q.id !== graphToRemove.id
        );
        surveyWithRemovedGraph = {
          ...survey,
          [valueNames.valuePath]: newGraphs
        };

        removeRequest = {
          api: valueNames.removeApi,
          variables: {
            id: graphToRemove.id,
            survey: survey.id
          }
        };
      }
    }
  }

  return { surveyWithRemovedGraph, removeRequest };
};
