import React, { useState, useEffect } from 'react';

import WordCloud from './WordCloud/WordCloud';

const WordCloudsContainer = props => {
  const {
    questionId,
    results,
    surveyId,
    setAnswerSearch,
    setView,
    views,
    initialWordClouds,
    hiddenWordCloudWords,
    isAdmin,
    isAllowedToExecuteRequests,
    activeFiltersLength,
    wordCloudTabActive,
    openQuestionCategories,
    assignCategoriesFromWords,
    openAnswerCategoryPairs,
    viewToken
  } = props;

  const [initialWords, setInitialWords] = useState([]);
  const [wordCloudsActiveWords, setWordCloudsActiveWords] = useState([]);
  const [wordCloudsMergedWords, setWordCloudsMergedWords] = useState([]);
  const [wordCloudsFilteredWords, setWordCloudsFilteredWords] = useState([]);
  const [versionNames, setVersionNames] = useState(['Cloud 1']);
  const [containerLoaded, setContainerLoaded] = useState(false);
  const [versionsLoaded, setWordCloudsLoaded] = useState([false]);
  const [selectedWordCloudIndex, setSelectedWordCloudIndex] = useState(0);
  const [prevousActiveFiltersLength, setPrevousActiveFiltersLength] = useState([
    0
  ]);
  const [selectAllWords, setSelectAllWords] = useState([false]);
  const [selectAllCategories, setSelectAllCategories] = useState([false]);
  const [wordCloudsActiveCategories, setWordCloudsActiveCategories] = useState(
    []
  );

  const filterOrders = {
    DESC: 'DESC',
    ASC: 'ASC'
  };

  const filterProperties = {
    TEXT: 'TEXT',
    VALUE: 'VALUE'
  };

  const dropDownFilterOptions = [
    {
      name: 'Value high - low',
      property: filterProperties.VALUE,
      order: filterOrders.DESC
    },
    {
      name: 'Value low - high',
      property: filterProperties.VALUE,
      order: filterOrders.ASC
    },
    {
      name: 'Alphabetical A - Z',
      property: filterProperties.TEXT,
      order: filterOrders.ASC
    },
    {
      name: 'Alphabetical Z - A',
      property: filterProperties.TEXT,
      order: filterOrders.DESC
    }
  ];

  useEffect(
    () => {
      if (!containerLoaded) {
        setInitialWords(
          wordCount(createWordsFromResults(hiddenWordCloudWords))
        );
        setContainerLoaded(true);
        if (initialWordClouds && initialWordClouds.length) {
          setSelectAllWords(initialWordClouds.map(() => false));
          setSelectAllCategories(initialWordClouds.map(() => false));
          setPrevousActiveFiltersLength(initialWordClouds.map(() => 0));
        }
      } else if (
        prevousActiveFiltersLength[selectedWordCloudIndex] !==
        activeFiltersLength
      ) {
        const newPrevousActiveFiltersLength = [...prevousActiveFiltersLength];
        newPrevousActiveFiltersLength.splice(
          selectedWordCloudIndex,
          1,
          activeFiltersLength
        );
        setPrevousActiveFiltersLength(newPrevousActiveFiltersLength);

        if (activeFiltersLength === 0) {
          updateWordCloudsFilteredWords([]);
        } else {
          const filteredWords = wordCount(
            createWordsFromResults(hiddenWordCloudWords)
          );
          updateWordCloudsFilteredWords(filteredWords);
          updateWordCloudsActiveWords(filteredWords);
        }
      }
    },
    [results]
  );

  const createWordsFromResults = wordsToIgnore => {
    let resultString = results.reduce(
      (result, currentValue) => `${result} ${currentValue.answer_string}`,
      ''
    );
    const ignoreString = new RegExp(
      wordsToIgnore.reduce((result, currentValue, currentIndex) => {
        if (currentIndex === 0) {
          return `${result}\\b${currentValue}\\b`;
        }
        return `${result}|\\b${currentValue}\\b`;
      }, ''),
      'gi'
    );
    resultString = resultString
      .replace(/[^A-Za-z0-9\-\s]/g, '')
      .replace(/\s{2,}/g, ' ')
      .replace(/[\r\n]+/gm, '')
      .toLowerCase()
      .replace(ignoreString, '');
    return resultString;
  };

  useEffect(
    () => {
      if (initialWordClouds && initialWordClouds.length) {
        const collectiveNames = initialWordClouds.map(
          wordCloud => wordCloud.name
        );
        setVersionNames(collectiveNames);

        if (!wordCloudsMergedWords.length) {
          const collectiveMergedWords = initialWordClouds.map(
            wordCloud => wordCloud.mergedWords
          );
          setWordCloudsMergedWords(collectiveMergedWords);
        }
        if (!wordCloudsActiveWords.length) {
          setWordCloudsActiveWords(initialWordClouds.map(() => []));
        }
        if (!wordCloudsFilteredWords.length) {
          setWordCloudsFilteredWords(initialWordClouds.map(() => []));
        }
        if (!wordCloudsActiveCategories.length) {
          setWordCloudsActiveCategories(initialWordClouds.map(() => []));
        }
      }
    },
    [initialWordClouds]
  );

  const wordCount = str => {
    const wordValueObject = {};
    str.split(' ').forEach(el => {
      if (el !== '') {
        wordValueObject[el] = wordValueObject[el]
          ? (wordValueObject[el] += 1)
          : 1;
      }
    });
    const mappedAnswers = [];
    Object.entries(wordValueObject).forEach(wordValuePair => {
      mappedAnswers.push({
        text: wordValuePair[0],
        value: wordValuePair[1],
        visible: false,
        parentWords: []
      });
    });
    return mappedAnswers;
  };

  const deepCopy = stateArrays => {
    if (stateArrays && stateArrays.length) {
      return stateArrays.map(stateArray => {
        if (stateArray && stateArray.length) {
          return stateArray.map(stateVal => stateVal);
        }
        return stateArray;
      });
    }
    return stateArrays;
  };

  const updateWordCloudState = (stateVar, newValue) => {
    const newStateVar = deepCopy(stateVar);
    if (newValue) {
      newStateVar.splice(selectedWordCloudIndex, 1, newValue);
    } else {
      newStateVar.splice(selectedWordCloudIndex, 1);
    }
    return newStateVar;
  };

  const updateWordCloudsMergedWords = updatedWords => {
    setWordCloudsMergedWords(
      updateWordCloudState(wordCloudsMergedWords, updatedWords)
    );
  };

  const updateWordCloudsActiveWords = updatedWords => {
    setWordCloudsActiveWords(
      updateWordCloudState(wordCloudsActiveWords, updatedWords)
    );
  };

  const updateWordCloudsFilteredWords = updatedWords => {
    setWordCloudsFilteredWords(
      updateWordCloudState(wordCloudsFilteredWords, updatedWords)
    );
  };

  const updateWordCloudsActiveCategories = updatedCategories => {
    setWordCloudsActiveCategories(
      updateWordCloudState(wordCloudsActiveCategories, updatedCategories)
    );
  };

  const updateVersionNames = updatedName => {
    const newVersionNames = [...versionNames];
    if (updatedName) {
      newVersionNames.splice(selectedWordCloudIndex, 1, updatedName);
    } else {
      newVersionNames.splice(selectedWordCloudIndex, 1);
    }
    setVersionNames(newVersionNames);
  };

  const removeWordCloudsLoaded = () => {
    const newWordCloudsLoaded = [...versionsLoaded];
    newWordCloudsLoaded.splice(selectedWordCloudIndex, 1);
    setWordCloudsLoaded(newWordCloudsLoaded);
    const newPrevousActiveFiltersLength = [...prevousActiveFiltersLength];
    newPrevousActiveFiltersLength.splice(selectedWordCloudIndex, 1);
    setPrevousActiveFiltersLength(newPrevousActiveFiltersLength);
  };

  const onNewWordCloudAdded = () => {
    setWordCloudsMergedWords([...wordCloudsMergedWords, []]);
    setWordCloudsActiveWords([...wordCloudsActiveWords, []]);
    setWordCloudsFilteredWords([...wordCloudsFilteredWords, []]);
    setWordCloudsLoaded([...versionsLoaded, false]);
    setSelectAllWords([...selectAllWords, false]);
    setSelectAllCategories([...selectAllCategories, false]);
    setWordCloudsActiveCategories([...wordCloudsActiveCategories, []]);
  };

  const onSingleWordCloudLoaded = () => {
    const newWordCloudsLoaded = [...versionsLoaded];
    newWordCloudsLoaded[selectedWordCloudIndex] = true;
    setWordCloudsLoaded(newWordCloudsLoaded);
  };

  return (
    <>
      {wordCloudTabActive ? (
        <WordCloud
          questionId={questionId}
          surveyId={surveyId}
          setAnswerSearch={setAnswerSearch}
          setView={setView}
          views={views}
          activeWords={wordCloudsActiveWords[selectedWordCloudIndex]}
          initialWordClouds={initialWordClouds}
          hiddenWordCloudWords={hiddenWordCloudWords}
          isAdmin={isAdmin}
          isAllowedToExecuteRequests={isAllowedToExecuteRequests}
          dropDownFilterOptions={dropDownFilterOptions}
          filterOrders={filterOrders}
          selectedWordCloudIndex={selectedWordCloudIndex}
          setSelectedWordCloudIndex={setSelectedWordCloudIndex}
          updateWordCloudsMergedWords={updatedWords =>
            updateWordCloudsMergedWords(updatedWords)
          }
          updateWordCloudsActiveWords={updatedWords =>
            updateWordCloudsActiveWords(updatedWords)
          }
          updateWordCloudsFilteredWords={updatedWords =>
            updateWordCloudsFilteredWords(updatedWords)
          }
          updateWordCloudsActiveCategories={updatedCategories =>
            updateWordCloudsActiveCategories(updatedCategories)
          }
          versionNames={versionNames}
          updateVersionNames={updatedName => updateVersionNames(updatedName)}
          onNewWordCloudAdded={() => onNewWordCloudAdded()}
          initialWords={
            wordCloudsFilteredWords &&
            wordCloudsFilteredWords[selectedWordCloudIndex] &&
            wordCloudsFilteredWords[selectedWordCloudIndex].length
              ? wordCloudsFilteredWords[selectedWordCloudIndex]
              : initialWords
          }
          setInitialWords={setInitialWords}
          mergedWords={wordCloudsMergedWords[selectedWordCloudIndex]}
          wordCloudLoaded={versionsLoaded[selectedWordCloudIndex]}
          onSingleWordCloudLoaded={() => onSingleWordCloudLoaded()}
          removeWordCloudsLoaded={() => removeWordCloudsLoaded()}
          selectAllWords={selectAllWords}
          setSelectAllWords={setSelectAllWords}
          selectAllCategories={selectAllCategories}
          setSelectAllCategories={setSelectAllCategories}
          openQuestionCategories={openQuestionCategories}
          assignCategoriesFromWords={assignCategoriesFromWords}
          openAnswerCategoryPairs={openAnswerCategoryPairs}
          activeCategories={wordCloudsActiveCategories[selectedWordCloudIndex]}
          viewToken={viewToken}
        />
      ) : null}
    </>
  );
};

export default WordCloudsContainer;
