import React from 'react';

import RangeSelector from '../ValueSelectors/RangeSelector';

import checkAgeGroupsSum from '../../helpers/checkAgeGroupsSum/checkAgeGroupsSum';
import resetCrossedSample from '../../helpers/resetCrossedSample/resetCrossedSample';

// import exclamation from '../../../../../../assets/img/exclamation.svg';
import deleteIcon from '../../../../../assets/x.svg';
import styles from '../../TargetAudience.module.css';

export default ({
  userFilter,
  onUserFilterChange,
  filter,
  filterIndex,
  filterSettings,
  maxResponses
}) => {
  if (!filter || !filter.age) return null;

  const setFilterProperties = newFilterProperties => {
    const newUserFilter = { ...userFilter };

    const newFilter = {
      ...filter,
      ...newFilterProperties
    };

    newUserFilter.$and[filterIndex] = newFilter;

    // Changing filters will also unset crossed property
    if (
      filterSettings &&
      filterSettings.crossedSample &&
      checkAgeGroupsSum(userFilter, maxResponses) !== 100
    ) {
      resetCrossedSample(newUserFilter, filterSettings, onUserFilterChange);
      return;
    }

    onUserFilterChange(newUserFilter);
  };

  const onDeleteFilter = () => {
    const newUserFilter = { ...userFilter };

    newUserFilter.$and.splice(filterIndex, 1);

    // Delete filters will also unset crossed property
    if (
      filterSettings &&
      filterSettings.crossedSample &&
      checkAgeGroupsSum(userFilter, maxResponses) !== 100
    ) {
      resetCrossedSample(newUserFilter, filterSettings, onUserFilterChange);
      return;
    }

    onUserFilterChange(newUserFilter);
  };

  return (
    <div className={styles.rowContainer}>
      {/*
        targetAgeBoundsError.error &&
      targetAgeBoundsError.filtersIndexes.includes(filterIndex) ? (
        <img className="exclamation-icon" src={exclamation} alt="warning" />
      ) : null
        */}
      <div className={styles.scalesContainer}>
        <RangeSelector
          minValue={16}
          maxValue={100}
          initialMinValue={
            filter.age ? filter.age.$gte || filter.age.$gt : undefined
          }
          initialMaxValue={
            filter.age ? filter.age.$lte || filter.age.$lt : undefined
          }
          setTargetAudiencesConfigure={newRange => {
            if (
              newRange &&
              newRange.age &&
              newRange.age.gte &&
              newRange.age.lte
            ) {
              setFilterProperties({
                age: {
                  $gte: newRange.age.gte,
                  $lte: newRange.age.lte
                }
              });
            }
          }}
          userFilter={userFilter}
          greaterThanOperator="gte"
          lessThanOperator="lte"
          audienceGroupName="age"
        />
        <div className={styles.targetValuesTrack}>
          <div className={styles.targetValues}>
            <div className={styles.targetLeftValue}>
              <span>16</span>
            </div>
            <div className={styles.targetRightValue}>
              <span>100</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.additionalConfigurationContainer}>
        <div>
          At most
          <input
            value={
              filter.$dynamic &&
              (filter.$dynamic.$max || filter.$dynamic.$max === 0)
                ? filter.$dynamic.$max
                : ''
            }
            onChange={e =>
              setFilterProperties({
                $dynamic: {
                  ...filter.$dynamic,
                  $max:
                    e.target.value && e.target.value !== ''
                      ? parseInt(e.target.value, 10)
                      : 0
                }
              })
            }
          />
        </div>
        <div>
          At least
          <input
            value={
              filter.$dynamic && filter.$dynamic.$min
                ? filter.$dynamic.$min
                : ''
            }
            onChange={e =>
              setFilterProperties({
                $dynamic: {
                  ...filter.$dynamic,
                  $min:
                    e.target.value && e.target.value !== ''
                      ? parseInt(e.target.value, 10)
                      : null
                }
              })
            }
          />
        </div>
        <div>
          <select
            value={filter.$dynamic ? filter.$dynamic.$type : undefined}
            onChange={e =>
              setFilterProperties({
                $dynamic: {
                  ...filter.$dynamic,
                  $type: e.target.value
                }
              })
            }
          >
            <option>#</option>
            <option>%</option>
          </select>
        </div>
        <img
          className={styles.deleteFilterRowIcon}
          alt="Delete filer"
          role="presentation"
          src={deleteIcon}
          onClick={onDeleteFilter}
        />
      </div>
    </div>
  );
};
