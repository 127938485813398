import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeQuestionFromSurvey from '../../../helpers/removeQuestionFromSurvey/removeQuestionFromSurvey';
import removeGroupFromSurvey from '../../../helpers/removeGroupFromSurvey/removeGroupFromSurvey';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';

import { QUESTION_TYPES } from '../../../helpers/constants';

const ObjectID = require('bson-objectid');

export default (
  state,
  flowId,
  branch,
  updateActiveQuestion,
  onlyLabelChange
) => {
  const surveyToModify = JSON.parse(JSON.stringify(state.survey));

  const branchIndex = surveyToModify.questions[flowId].branches.findIndex(
    c => c.id === branch.id
  );

  // To save performances this step is executed before all others
  if (onlyLabelChange) {
    const labelBranches = [...state.survey.questions[flowId].branches];
    labelBranches[branchIndex] = {
      ...state.survey.questions[flowId].branches[branchIndex],
      ...branch
    };

    return {
      newState: {
        ...state,
        survey: {
          ...state.survey,
          questions: {
            ...state.survey.questions,
            [flowId]: {
              ...state.survey.questions[flowId],
              branches: labelBranches
            }
          }
        },
        activeFlow: {
          ...state.activeFlow,
          branches: labelBranches
        }
      }
    };
  }

  const flowQuestion = surveyToModify.questions[flowId];

  // To prevent issues with old type of surveys, script will run if all branch jumps are to groups
  const branchesWithJumps = flowQuestion.branches.filter(b => b.nextFlow);
  const anyBranchWithJumpToQuestion = branchesWithJumps.some(
    b => surveyToModify.questions[b.nextFlow]
  );
  // anyBranchWithJumpToQuestion - If this is TRUE, then survey is created with version before this

  // If nextFlow is a group then remove it and all questions within it
  const currentBranchNextFlow =
    surveyToModify.questions[flowId].branches[branchIndex].nextFlow;
  if (
    currentBranchNextFlow !== branch.nextFlow &&
    !anyBranchWithJumpToQuestion
  ) {
    const isExistingJumpToGroup = surveyToModify.content.groups.some(
      g => g.id === currentBranchNextFlow
    );
    if (isExistingJumpToGroup) {
      const newSurvey = removeGroupFromSurvey(
        currentBranchNextFlow,
        surveyToModify.content,
        surveyToModify.questions,
        false
      );

      surveyToModify.content = newSurvey.content;
      surveyToModify.questions = newSurvey.questions;
    }
  }

  if (branch.nextFlow) {
    // eslint-disable-next-line
    delete surveyToModify.questions[flowId].branches[branchIndex].end;
  }

  if (branch.end) {
    // eslint-disable-next-line
    delete surveyToModify.questions[flowId].branches[branchIndex].nextFlow;
  }

  let newBranch = {
    ...surveyToModify.questions[flowId].branches[branchIndex],
    ...branch
  };

  // Distributor has a bit different branch handling than Rating scale question
  if (
    currentBranchNextFlow !== branch.nextFlow &&
    surveyToModify.questions[flowId].type === QUESTION_TYPES.DISTRIBUTOR &&
    !anyBranchWithJumpToQuestion
  ) {
    const isDestinationGroup = surveyToModify.content.groups.some(
      g => g.id === branch.nextFlow
    );

    let newGroupId;
    let newGroup;

    if (!isDestinationGroup) {
      newGroupId = ObjectID().toString();

      const questionIdToRemove = newBranch.nextFlow;

      // Delete existing question
      const newSurvey = removeQuestionFromSurvey(
        newBranch.nextFlow,
        surveyToModify.content,
        surveyToModify.questions,
        true,
        false
      );

      surveyToModify.content = newSurvey.content;
      surveyToModify.questions = newSurvey.questions;

      newGroup = {
        id: newGroupId,
        label: `Group ${surveyToModify.content.groups.length + 1}`,
        flows: [newBranch.nextFlow]
      };

      // Add new group
      surveyToModify.content.groups.push(newGroup);

      // Set branch jump to new group where question is added
      newBranch = {
        ...newBranch,
        nextFlow: newGroupId
      };

      if (surveyToModify.questions[questionIdToRemove].nextFlow) {
        surveyToModify.questions[questionIdToRemove].nextFlow = null;
      }
      if (surveyToModify.questions[questionIdToRemove].end) {
        surveyToModify.questions[questionIdToRemove].end = null;
      }
    } else {
      // Set branch jump to selected group
      newBranch = {
        ...newBranch,
        nextFlow: newBranch.nextFlow
      };
    }

    const branches = [...surveyToModify.questions[flowId].branches];
    branches[branchIndex] = newBranch;
  }

  const branches = [...surveyToModify.questions[flowId].branches];
  branches[branchIndex] = newBranch;

  const activeFlow = {
    ...state.activeFlow,
    ...(!state.activeFlow || state.activeFlow.id !== flowId ? {} : { branches })
  };

  const modifiedFlow = {
    ...surveyToModify.questions[flowId],
    branches
  };

  const newState = {
    ...state,
    survey: {
      ...surveyToModify,
      questions: {
        ...surveyToModify.questions,
        [flowId]: modifiedFlow
      }
    },
    activeFlow
  };

  // Sort distributor groups according to branch jumps
  if (updateActiveQuestion) {
    sortDistributorGroupsAndJumps(newState);
  }

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return { newState };
};
