import React, { useState } from 'react';
import BlockDestinationPosition from './components/BlockDestinationPosition/BlockDestinationPosition';
import ConstraintTooltip from '../../../ConstraintTooltip/ConstraintTooltip';

import styles from './Action.module.css';

export default ({
  isActionAllowed,
  isActionAllowedChecker,
  onActionClick,
  icon,
  propertiesComponent,
  label,
  onDoneButtonPress
}) => {
  const [showPropertiesComponent, setShowPropertiesComponent] = useState(false);

  const renderPropertiesComponent = () => {
    if (propertiesComponent === 'BlockDestinationPosition') {
      return (
        <BlockDestinationPosition
          setShowPropertiesComponent={setShowPropertiesComponent}
          onDoneButtonPress={onDoneButtonPress}
          isActionAllowedChecker={isActionAllowedChecker}
        />
      );
    }
    return null;
  };

  const onClick = () => {
    if (onActionClick) {
      onActionClick();
    }

    if (propertiesComponent) {
      if (!showPropertiesComponent) {
        setShowPropertiesComponent(true);
      } else {
        setShowPropertiesComponent(false);
      }
    }
  };

  return (
    <div>
      {!showPropertiesComponent && true ? (
        <div
          role="presentation"
          className={styles.container}
          onClick={() => {
            if (isActionAllowed && isActionAllowed.allowed === true) {
              onClick();
            }
          }}
        >
          <div
            className={
              isActionAllowed && isActionAllowed.allowed !== true
                ? styles.notAllowed
                : ''
            }
          >
            {icon}
            {label}
          </div>
          {isActionAllowed && isActionAllowed.allowed !== true ? (
            <ConstraintTooltip label={isActionAllowed.message} />
          ) : null}
        </div>
      ) : null}
      {showPropertiesComponent ? renderPropertiesComponent() : null}
    </div>
  );
};
