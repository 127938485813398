import React, { useState, useRef, useEffect } from 'react';

import edit from '../../../../../../../../../assets/img/edit.svg';
import trashIcon from '../../../../../../../../../assets/img/trash.svg';
import warningIcon from '../../../../../../../../../assets/img/exclamation.svg';
import groupIcon from '../../../../../../../../../assets/img/group.svg';
import infoIcon from '../../../../../../../../../assets/img/info.svg';
import questionIcon from '../../../../../../../../../assets/img/question.svg';

import styles from './Population.module.css';

import Toggle from '../../../Toggle/Toggle';

const Population = ({
  population,
  index,
  setSelectedPopulationIndex,
  filteredResultsCount,
  populationColor,
  removePopulationClick,
  duplicatePopulationClick,
  activePopulations,
  setActivePopulations,
  selectedWorkspacePopulationsLength
}) => {
  const [populationActions, setPopulationActions] = useState(false);
  const isInitialMount = useRef(null);
  const containerRef = useRef(null);

  useEffect(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [populationActions]
  );

  const getActiveLabels = () => {
    if (population && population.length) {
      const activeLabels = population.map(label => label.name);
      return activeLabels.toString();
    }
    return '-';
  };

  // FOR DISPLAYING GTE & LTE values if needed
  /*
  const getValuesLabel = label => {
    if (label.type === 'range') {
      const gte = label && label.gte ? label.gte : 'X';
      const lte = label && label.lte ? label.lte : 'X';
      return `${gte}-${lte}`;
    }
    if (label.type === 'dropdown') {
      const valueLabel =
        label && label.value ? label.value.map(v => v.label).toString() : '-';
      return valueLabel;
    }
    if (label.type === 'answer') {
      const blockLabel = label && label.block ? label.block.label : '-';
      const valueLabel =
        label && label.value ? label.value.map(v => v.label).toString() : '-';
      return `${blockLabel}: ${valueLabel}`;
    }
    return null;
  };

  const changeLabelValue = (property, value, labelIndex) => {
    const populationToEdit = population;
    populationToEdit[labelIndex][property] = value;

    const newLabels = [...populationToEdit];
    updatePopulationClick(newLabels, index);
  };
  */

  return (
    <div className="workspaces-population-container" ref={isInitialMount}>
      <div className={styles.container} ref={containerRef}>
        {population && population.length && (
          <div className={styles.titleContainersWrapper}>
            <div className={styles.titleContainer}>
              <div className={styles.titleTextContainer}>
                <div
                  className={styles.colorBullet}
                  style={{ backgroundColor: populationColor }}
                />
                <span className={styles.populationLabel}>
                  <span>
                    {population[0].inverse
                      ? `NOT "${population[0].label}"`
                      : population[0].label}{' '}
                  </span>
                  <span
                    className={styles.filteredResultsCount}
                    style={{ color: populationColor }}
                  >
                    n = {filteredResultsCount}
                  </span>
                </span>
              </div>
              <div className={styles.toggleContainer}>
                <Toggle
                  checked={
                    activePopulations[index] ? activePopulations[index] : false
                  }
                  onToggle={() => {
                    const newActivePopulations = [...activePopulations];
                    newActivePopulations[index] = !newActivePopulations[index];
                    setActivePopulations(newActivePopulations);
                  }}
                  backgroundColor={populationColor}
                />
              </div>
            </div>
          </div>
        )}
        {population[0].label !== 'Default Sample' && (
          <div className={styles.content}>
            <div
              className={`${styles.title} workspaces-population-expand-button`}
              role="presentation"
            >
              <div className={styles.details}>
                <span className={styles.name}>Active filters: </span>
                <span
                  className={`${
                    styles.filters
                  } workspaces-population-active-labels`}
                >
                  {getActiveLabels()}
                </span>
              </div>
              <div>
                <span
                  className={
                    populationActions ? styles.dotsActive : styles.dots
                  }
                  role="presentation"
                  onClick={() => setPopulationActions(!populationActions)}
                >
                  ...
                </span>
                {populationActions ? (
                  <div className={styles.populationActionsDropdown}>
                    <div
                      className={styles.dropdownAction}
                      role="presentation"
                      onClick={() => {
                        setPopulationActions(!populationActions);
                        setSelectedPopulationIndex(index);
                      }}
                    >
                      <div className={styles.dropdownActionIconWrapper}>
                        <img
                          src={edit}
                          className={`${
                            styles.dropdownActionIcon
                          } workspaces-edit-population-button`}
                          alt="Edit population"
                        />
                      </div>
                      <span className={styles.dropdownActionText}>
                        Edit population
                      </span>
                    </div>
                    {selectedWorkspacePopulationsLength < 5 && (
                      <>
                        <div
                          className={styles.dropdownAction}
                          role="presentation"
                          onClick={() => {
                            const populationToAdd = [];
                            population.forEach(label => {
                              const labelToAdd = {
                                name: label.name,
                                label: `${label.label} copy`,
                                type: label.type,
                                active: label.active,
                                value: label.value,
                                gte: label.gte,
                                lte: label.lte,
                                inverse: label.inverse
                              };
                              if (label.block) {
                                labelToAdd.block = label.block;
                              }
                              populationToAdd.push(labelToAdd);
                            });
                            setPopulationActions(!populationActions);
                            duplicatePopulationClick(populationToAdd, index);
                          }}
                        >
                          <div className={styles.dropdownActionIconWrapper}>
                            <span
                              className={styles.dropdownActionIcon}
                              style={{
                                fontFamily: 'BufflClientIcons',
                                textTransform: 'lowercase'
                              }}
                            >
                              l
                            </span>
                          </div>
                          <span className={styles.dropdownActionText}>
                            Duplicate population
                          </span>
                        </div>
                        <div
                          className={`${styles.dropdownAction} ${
                            styles.inverseAction
                          }`}
                          role="presentation"
                          onClick={() => {
                            const populationToAdd = [];
                            population.forEach(label => {
                              const labelToAdd = {
                                name: label.name,
                                label: label.label,
                                type: label.type,
                                active: label.active,
                                value: label.value,
                                gte: label.gte,
                                lte: label.lte,
                                inverse: !label.inverse
                              };
                              if (label.block) {
                                labelToAdd.block = label.block;
                              }
                              populationToAdd.push(labelToAdd);
                            });
                            setPopulationActions(!populationActions);
                            duplicatePopulationClick(populationToAdd, index);
                          }}
                        >
                          <div className={styles.dropdownActionIconWrapper}>
                            <img
                              src={groupIcon}
                              className={`${
                                styles.dropdownActionIcon
                              } workspaces-edit-population-button`}
                              alt="Edit population"
                            />
                          </div>
                          <span
                            className={`${styles.dropdownActionText} ${
                              styles.inverseActionText
                            }`}
                          >
                            <span className={styles.inverseActionTextHover}>
                              Create inverse population
                            </span>{' '}
                            <img
                              src={questionIcon}
                              className={styles.inverseExplanationIcon}
                              alt="info"
                            />
                            <div className={styles.inverseExplanation}>
                              <img
                                src={infoIcon}
                                className={styles.dropdownActionIcon}
                                alt="Inverse info"
                              />
                              <span className={styles.inverseExplanationText}>
                                This option will create a new population
                                containing all respondents not included in the
                                current population.
                              </span>
                            </div>
                          </span>
                        </div>
                      </>
                    )}
                    <div
                      className={styles.dropdownAction}
                      role="presentation"
                      onClick={() => {
                        removePopulationClick(index);
                        setSelectedPopulationIndex(null);
                        setPopulationActions(!populationActions);
                        const newActivePopulations = [...activePopulations];
                        newActivePopulations.splice(index, 1);
                        setActivePopulations(newActivePopulations);
                      }}
                    >
                      <div className={styles.dropdownActionIconWrapper}>
                        <img
                          src={trashIcon}
                          className={`${
                            styles.dropdownActionIcon
                          } workspaces-edit-population-button`}
                          alt="Edit population"
                        />
                      </div>
                      <span
                        className={`${styles.dropdownActionText} ${
                          styles.dropdownActionTextRed
                        }`}
                      >
                        Delete population
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      {parseInt(filteredResultsCount, 10) < 30 ? (
        <div
          className={`${
            styles.smallPopulation
          } workspaces-population-small-size`}
        >
          <img src={warningIcon} alt="Warning" />
          This population might be too small to provide accurate data.
        </div>
      ) : null}
    </div>
  );
};

export default Population;
