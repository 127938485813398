import React from 'react';

import styles from './SavingTranslationError.module.css';

const SavingTranslationError = () => (
  <div className={`${styles.container}`}>
    An error has occured when saving translation
  </div>
);

export default SavingTranslationError;
