import {
  AGE_IR_MAPPING,
  FILTER_RELATIONS,
  GENDER_IR_MAPPING
} from '../constants';
import {
  COMMUNITY_DASHBOARD_FILTER_TYPE,
  USER_PROPERTIES,
  USER_PROPERTY_NAMES
} from '../../../CommunityDashboard/helpers/constants';

export default (appliedFilters, statisticsData, api, filterRelation) => {
  const newStatisticsData = JSON.parse(JSON.stringify(statisticsData));
  let totalIR = filterRelation === FILTER_RELATIONS.AND ? 1 : 0;

  const handleIR = (total, single) => {
    if (filterRelation === FILTER_RELATIONS.AND) {
      if (total === 0) {
        return single;
      }
      return total * single;
    }
    if (filterRelation === FILTER_RELATIONS.OR) {
      return total + single;
    }
    return total;
  };

  const getTotalSectorIR = sector => {
    const totalSectorIR = sector.unfilteredValues.reduce((accS, uFV, uFVI) => {
      if (uFV.yValue) {
        return accS + uFV.yValue;
      }
      if (!uFV.yValue && sector.values[uFVI].yValue) {
        return accS + sector.values[uFVI].yValue;
      }

      return accS;
    }, 0);
    return totalSectorIR;
  };

  const calculateIRParams = (
    sector,
    dataType,
    name,
    value,
    totalSectorIR,
    filter,
    setTotalIR = true
  ) => {
    const processedValues = value.map(v => {
      if (name === 'age') {
        return v.toLowerCase();
      }
      return v.toLowerCase().replace(/_/g, ' ');
    });

    const sectorIncidenceRate = sector.values.reduce((accSIR, sV, sVI) => {
      if (
        processedValues.length &&
        processedValues.indexOf(sV.xValue.toLowerCase()) > -1
      ) {
        const newAcc = [...accSIR];

        if (name === USER_PROPERTY_NAMES.AGE.toLowerCase()) {
          newAcc[sVI] = {
            index: sVI,
            incidenceRate: AGE_IR_MAPPING[sector.values[sVI].xValue]
          };
        } else if (name === USER_PROPERTY_NAMES.GENDER.toLowerCase()) {
          newAcc[sVI] = {
            index: sVI,
            incidenceRate: GENDER_IR_MAPPING[sector.values[sVI].xValue]
          };
        } else {
          newAcc[sVI] = {
            index: sVI,
            incidenceRate: sector.values[sVI].yValue / totalSectorIR
          };
        }

        return newAcc;
      }
      return accSIR;
    }, []);

    if (
      sectorIncidenceRate &&
      sectorIncidenceRate &&
      sectorIncidenceRate.length
    ) {
      const newSector = {
        ...sector,
        sectorIncidenceRate
      };
      newStatisticsData[dataType][name] = newSector;

      if (
        filterRelation === FILTER_RELATIONS.OR &&
        filter &&
        ((filter.tags &&
          filter.tags.quota &&
          filter.tags.quota.some(f => f.value || f.value === 0)) ||
          (filter.userProperties &&
            filter.userProperties.quota &&
            filter.userProperties.quota.some(f => f.value || f.value === 0)))
      ) {
        const singleIRValues = sectorIncidenceRate.reduce((sumAcc, sIR) => {
          if (sIR) {
            return [...sumAcc, sIR.incidenceRate];
          }
          return sumAcc;
        }, []);

        const minIR = Math.min(...singleIRValues);
        if (setTotalIR) {
          totalIR = handleIR(totalIR, minIR);
        }
      } else {
        const sumSectorIncidenceRate = sectorIncidenceRate.reduce(
          (sumAcc, sIR) => {
            if (sIR) {
              return sumAcc + sIR.incidenceRate;
            }
            return sumAcc;
          },
          0
        );
        if (setTotalIR) {
          totalIR = handleIR(totalIR, sumSectorIncidenceRate);
        }
      }
    }
  };

  if (appliedFilters && appliedFilters.length) {
    appliedFilters.forEach(aF => {
      if (aF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY) {
        if (
          aF &&
          aF.userProperties &&
          [USER_PROPERTIES.LANGUAGE].indexOf(aF.userProperties.propertyName) >
            -1
        ) {
          return;
        }

        if (
          aF &&
          aF.userProperties &&
          [USER_PROPERTIES.GENDER, USER_PROPERTIES.AGE].indexOf(
            aF.userProperties.propertyName
          ) > -1
        ) {
          const sector =
            newStatisticsData.socioDemographics[
              aF.userProperties.propertyName.toLowerCase()
            ];

          calculateIRParams(
            sector,
            'socioDemographics',
            aF.userProperties.propertyName.toLowerCase(),
            aF.userProperties.propertyValue,
            null,
            aF
          );
        } else {
          const sector =
            newStatisticsData.profileAttributes[
              USER_PROPERTY_NAMES[aF.userProperties.propertyName]
            ];

          const totalSectorIR = getTotalSectorIR(sector);

          calculateIRParams(
            sector,
            'profileAttributes',
            USER_PROPERTY_NAMES[aF.userProperties.propertyName],
            aF.userProperties.propertyValue,
            totalSectorIR,
            aF
          );
        }
      }

      if (aF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG) {
        if (aF && aF.tags && aF.tags.tagName) {
          const sector = newStatisticsData.profileAttributes[aF.tags.tagName];

          let totalSectorIR = 1;
          if (sector && sector.totalUnfilteredValue) {
            totalSectorIR = sector.totalValue / sector.totalUnfilteredValue;
          }

          const setTotalIR = false;

          // Still done for IR of individual bars in profile tag chart
          calculateIRParams(
            sector,
            'profileAttributes',
            aF.tags.tagName,
            aF.tags.tagValue,
            sector.totalUnfilteredValue,
            aF,
            setTotalIR
          );

          totalIR = handleIR(totalIR, totalSectorIR);
        }
      }
    });
  }

  return {
    totalIR,
    newStatisticsData
  };
};
