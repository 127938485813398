import React, { useState, useEffect } from 'react';

import checkAgeGroupsSum from '../../../../TargetAudience/helpers/checkAgeGroupsSum/checkAgeGroupsSum';
import resetCrossedSample from '../../../../TargetAudience/helpers/resetCrossedSample/resetCrossedSample';
import getAccountManagementSampleSizeChargeWarning from '../../../../SurveyBuilder/helpers/getAccountManagementSampleSizeChargeWarning/getAccountManagementSampleSizeChargeWarning';

import useDebounce from '../../../../../../../campaigns/components/DebounceInput/debounceHook';

import NumberInput from './NumberInput';

export default ({ survey, setConfirmationPopup, surveyRefetch, api }) => {
  const [maxResponses, setMaxResponses] = useState(survey.maxResponses);
  const debouncedMaxResponsesValue = useDebounce(maxResponses, 300);
  const [
    debouncedMaxResponsesInputLoaded,
    setDebouncedMaxResponsesInputLoaded
  ] = useState(false);

  const userFilter = survey.userFilter ? JSON.parse(survey.userFilter) : {};

  const updateSurveyTargetAudience = async (attributes, waitingTime) => {
    await api(
      'updateTargetAudience',
      {
        variables: {
          survey: survey.id,
          ...attributes
        }
      },
      waitingTime
    );
    await surveyRefetch();
  };

  const onUserFilterChange = (parsedUserFilter, filterSettings) => {
    if (parsedUserFilter) {
      let updateProperties = {
        userFilter: JSON.stringify(parsedUserFilter)
      };

      if (filterSettings) {
        updateProperties = {
          ...updateProperties,
          filterSettings
        };
      }

      updateSurveyTargetAudience(updateProperties);
    }
  };

  useEffect(
    () => {
      updateSurveyTargetAudience({ maxResponses }, 0);

      // Calculate survey permissions charge after change
      const calculatedSurveyPermissionsCharge = getAccountManagementSampleSizeChargeWarning(
        {
          ...survey,
          maxResponses
        }
      );

      if (debouncedMaxResponsesInputLoaded) {
        if (
          calculatedSurveyPermissionsCharge &&
          calculatedSurveyPermissionsCharge.accountManagementSampleSizeWarning
        ) {
          const confirmationPopup = {
            onClick: () => {},
            onClose: () => setConfirmationPopup(null),
            title: 'Are you sure?',
            description: `Please note that the number of respondents exceeds your fair use policy, which may result in additional charges. For more information, please contact your BUFFL representative.`
          };

          setConfirmationPopup(confirmationPopup);
        }
      } else {
        setDebouncedMaxResponsesInputLoaded(true);
      }

      if (
        userFilter &&
        survey.filterSettings &&
        survey.filterSettings.crossedSample &&
        checkAgeGroupsSum(userFilter, maxResponses) !== 100
      ) {
        const newUserFilter = { ...userFilter };
        resetCrossedSample(
          newUserFilter,
          survey.filterSettings,
          onUserFilterChange
        );
      }
    },
    [debouncedMaxResponsesValue]
  );

  return (
    <NumberInput
      label="Total sample size"
      required
      value={maxResponses}
      setValue={setMaxResponses}
      placeholder="300"
    />
  );
};
