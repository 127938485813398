import gql from 'graphql-tag';

export const GET_RESULTS_WORKSPACE = gql`
  query GetResultsWorkspaces($survey: String!) {
    getResultsWorkspaces(survey: $survey) {
      id
      name
      questions {
        id
        question
      }
      survey {
        id
        name
      }
      shared
      client {
        id
        email
      }
      populations {
        name
        label
        type
        active
        block {
          value
          label
        }
        value {
          value
          label
        }
        gte
        lte
        inverse
      }
    }
  }
`;

export const CREATE_RESULTS_WORKSPACE = gql`
  mutation CreateResultsWorkspace(
    $name: String!
    $survey: String!
    $questions: [String]
    $populations: [[ResultsWorkspacePopulationInput]]
    $shared: Boolean
  ) {
    createResultsWorkspace(
      input: {
        name: $name
        survey: $survey
        questions: $questions
        shared: $shared
        populations: $populations
      }
    ) {
      resultsWorkspace {
        id
        name
        shared
        survey {
          id
          name
        }
        questions {
          id
        }
        populations {
          name
          label
          type
          active
          block {
            value
            label
          }
          value {
            value
            label
          }
          gte
          lte
          inverse
        }
      }
    }
  }
`;
export const UPDATE_RESULTS_WORKSPACE = gql`
  mutation UpdateResultsWorkspace(
    $id: String!
    $name: String
    $questions: [String]
    $populations: [[ResultsWorkspacePopulationInput]]
    $shared: Boolean
  ) {
    updateResultsWorkspace(
      input: {
        id: $id
        name: $name
        questions: $questions
        populations: $populations
        shared: $shared
      }
    ) {
      resultsWorkspace {
        id
        name
        shared
        questions {
          id
        }
        populations {
          name
          label
          type
          active
          block {
            value
            label
          }
          value {
            value
            label
          }
          gte
          lte
          inverse
        }
      }
    }
  }
`;

export const DELETE_RESULTS_WORKSPACE = gql`
  mutation DeleteResultsWorkspace($id: String!) {
    deleteResultsWorkspace(input: { id: $id }) {
      done
    }
  }
`;

export const GET_ANALYTICS_DATA = gql`
  mutation GetAnalyticsData($input: [SingleAnalyticsDataInput]) {
    getAnalyticsData(input: { input: $input }) {
      values {
        alternative
        fit
        lower
        upper
      }
    }
  }
`;
