import React from 'react';

import styles from './AccountManagementRecordSelect.module.css';
import DropdownSelector from './components/DropdownSelector/DropdownSelector';
import useLazyQuery from '../../../surveys/hooks/useLazyQuery';

import { GET_RECORDS_TO_SELECT } from '../../../graphql/AccountManagement';

export default ({
  isAdmin,
  accountManagement,
  hideInfoText,
  onSelect,
  fixedLeftPadding,
  surveyAssignDropdown,
  filterDropdownStyle,
  disabled
}) => {
  const searchAccountManagementRecordApi = useLazyQuery(GET_RECORDS_TO_SELECT, {
    fetchPolicy: 'no-cache'
  });
  const searchRecords = async (page, search) => {
    const searchResults = await searchAccountManagementRecordApi({
      variables: {
        start: 0,
        end: (page + 1) * 10,
        search
      }
    });

    if (
      searchResults &&
      searchResults.data &&
      searchResults.data.getRecordsToSelect &&
      searchResults.data.getRecordsToSelect.recordsToSelect &&
      searchResults.data.getRecordsToSelect.recordsToSelect.length
    ) {
      return searchResults.data.getRecordsToSelect.recordsToSelect;
    }

    return [];
  };

  const onSelectCompleted = record => {
    onSelect(record);

    if (!surveyAssignDropdown) {
      // Save selected value in local storage
      localStorage.setItem('qd_am_selected_record', record.value);
    }
  };
  const getValue = () => {
    if (
      accountManagement &&
      accountManagement.selectedAccountManagementRecord
    ) {
      return {
        value: accountManagement.selectedAccountManagementRecord.value,
        label: accountManagement.selectedAccountManagementRecord.label
      };
    }
    return {
      value: null,
      label: null
    };
  };

  return (
    <div
      className={
        disabled ? `${styles.disabled} ${styles.container}` : styles.container
      }
    >
      {!hideInfoText ? <div className={styles.label}>View as</div> : null}
      <DropdownSelector
        fixedLeftPadding={fixedLeftPadding}
        fetchOptions={searchRecords}
        onSelectCompleted={value => onSelectCompleted(value)}
        hideNoValueOption={accountManagement && surveyAssignDropdown}
        surveyAssignDropdown={surveyAssignDropdown}
        placeholder="Please select value"
        filterDropdownStyle={filterDropdownStyle}
        search={isAdmin}
        value={getValue()}
        disableDropdownExpand={disabled}
      />
    </div>
  );
};
