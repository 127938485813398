import React from 'react';

import LogicField from './components/LogicField/LogicField';
import Icon from '../../../../../../../../../../../Icon/Icon';

import ExclamationIcon from '../../../../../../../../../../../../../../../../assets/img/exclamation.svg';

import styles from './LogicFields.module.css';

export default ({ branch, questionId, actions, min, max, error }) => {
  const onChangeRangeField = (type, value) => {
    const newBranch = branch;

    if (!newBranch.range) {
      newBranch.range = {};
    }
    newBranch.range[type] = value;

    actions.updateQuestionBranch(questionId, newBranch);
  };

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.fieldValues}>
        <span className={styles.label}>If the answer is between</span>
        <LogicField
          value={min}
          fieldType="min"
          onChangeRangeField={(type, value) => onChangeRangeField(type, value)}
        />
        <span>and</span>
        <LogicField
          value={max}
          fieldType="max"
          onChangeRangeField={(type, value) => onChangeRangeField(type, value)}
        />
        {error && (error.overlap || error.invalid) ? (
          <div className={styles.logicJumpError}>
            <img
              className={styles.warningIcon}
              src={ExclamationIcon}
              alt="Warning"
            />
            <span className={styles.logicErrorMessage}>
              {error.invalid ? 'Invalid value' : 'Overlapping ranges'}
            </span>
          </div>
        ) : null}
      </div>
      <span
        role="presentation"
        className={styles.close}
        onClick={() => actions.deleteQuestionBranch(questionId, branch.id)}
      >
        <Icon type="x" />
      </span>
    </div>
  );
};
