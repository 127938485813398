import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

const RangeSelector = props => {
  const {
    minValue,
    maxValue,
    initialMinValue,
    initialMaxValue,
    disabled,
    audienceGroupName,
    targetAudiences,
    setTargetAudiencesConfigure,
    greaterThanOperator = '$gte',
    lessThanOperator = '$lte'
  } = props;

  const selectedMinValue =
    initialMinValue || initialMinValue === 0 ? initialMinValue : minValue;

  const [sliderValue, setSliderValue] = useState({
    min: selectedMinValue,
    max: initialMaxValue
  });

  useEffect(
    () => {
      setSliderValue({
        min: selectedMinValue,
        max: initialMaxValue
      });
    },
    [initialMinValue, initialMaxValue]
  );

  useEffect(() => {
    setTargetAudiencesConfigure({
      ...targetAudiences,
      ...{
        [audienceGroupName]: {
          [greaterThanOperator]: initialMinValue,
          [lessThanOperator]: initialMaxValue
        }
      }
    });
  }, []);

  return (
    <InputRange
      disabled={disabled}
      draggableTrack
      step={1}
      maxValue={maxValue}
      minValue={minValue}
      value={sliderValue}
      onChange={value => {
        const draggedValue = value;
        if (value.min < minValue) {
          draggedValue.min = minValue;
        }
        if (value.max > maxValue) {
          draggedValue.max = maxValue;
        }
        if (value.max === value.min && value.max === maxValue) {
          draggedValue.min -= 5;
        }
        if (value.max === value.min && value.min === minValue) {
          draggedValue.max += 5;
        }
        setSliderValue(draggedValue);
      }}
      onChangeComplete={value => {
        const finalValue = {
          [greaterThanOperator]: value.min,
          [lessThanOperator]: value.max
        };
        return setTargetAudiencesConfigure({
          ...targetAudiences,
          ...{ [audienceGroupName]: finalValue }
        });
      }}
    />
  );
};

export default RangeSelector;
