import { COMMUNITY_MANAGEMENT_TYPES } from '../../CommunityDashboard/helpers/constants';

export default activeFilters => {
  const transformName = (filterName, subFilterValue) => {
    switch (filterName) {
      case 'communities':
        return subFilterValue === COMMUNITY_MANAGEMENT_TYPES.STATIC
          ? 'staticCommunities'
          : 'dynamicCommunities';
      default:
        return filterName;
    }
  };

  return activeFilters.map(filter => ({
    ...(filter.name
      ? {
          name:
            filter.subFilter && filter.subFilter.value
              ? transformName(filter.name, filter.subFilter.value)
              : filter.name
        }
      : {}),
    ...(filter.label ? { label: filter.label } : {}),
    ...(filter.values && filter.values ? { values: filter.values } : {})
  }));
};
