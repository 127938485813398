import React, { useRef } from 'react';

import textareaAutoExpand from '../../../../../../../../../helpers/textareaAutoExpand';

import styles from './InputField.module.css';

export default ({
  label,
  property,
  value,
  type,
  placeholder,
  onChange,
  textEditor,
  negative,
  maxLength
}) => {
  if (textEditor) {
    const textEditorRef = useRef(null);

    return (
      <>
        <span className={styles.descriptionTitle}>{label}</span>
        <textarea
          className={styles.description}
          rows="1"
          placeholder={placeholder}
          ref={textEditorRef}
          onChange={e => {
            if (textEditorRef) {
              textareaAutoExpand(textEditorRef);
              onChange(property, e.target.value);
            }
          }}
          value={value || ''}
        />
      </>
    );
  }

  return (
    <>
      <span className={styles.descriptionTitle}>{label}</span>
      <input
        className={styles.description}
        placeholder={placeholder}
        type={type}
        onKeyDown={evt => {
          if (type === 'number') {
            if (negative && evt.key === '-') {
              return true;
            }

            return evt.key === 'ArrowDown' ||
              evt.key === 'ArrowUp' ||
              evt.key === 'e' ||
              evt.key === 'E' ||
              evt.key === '.' ||
              evt.key === '-'
              ? evt.preventDefault()
              : null;
          }
          return true;
        }}
        onChange={e => onChange(property, e.target.value)}
        onWheel={e => e.target.blur()}
        maxLength={maxLength}
        value={value || value === 0 ? value : ''}
        spellCheck
      />
    </>
  );
};
