import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import Loader from '../../../../campaigns/components/Loader/Loader';

import tickIcon from '../../../../assets/img/tick.svg';
import closeIcon from '../../../../assets/img/delete.svg';
import amWarning from '../../../../assets/img/accountmanagement/am-warning.svg';

import styles from './SelectTimeframePopup.module.css';
import formatDateForAcquisitionDate from '../../helpers/formatDateForAcquisitionDate';

export default ({ title, onClose, onSubmit, startTimeframe, endTimeframe }) => {
  const [internalStartTimeframe, setInternalStartTimeframe] = useState(
    startTimeframe
  );
  const [internalEndTimeframe, setInternalEndTimeframe] = useState(
    endTimeframe
  );
  const [loading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmitClick = async () => {
    onSubmit({
      startTimeframe: internalStartTimeframe,
      endTimeframe: internalEndTimeframe
    });
  };

  const isAllowedToSubmit = () => {
    if (internalStartTimeframe > internalEndTimeframe) {
      setError('Start date must be before end date');
      return false;
    }
    return true;
  };

  const parseDate = dateString => {
    if (typeof dateString === 'string' && dateString.includes('/')) {
      const splitted = dateString.split('/');
      const day = parseInt(splitted[0], 10);
      const month = parseInt(splitted[1], 10);
      const year = parseInt(splitted[2], 10);
      const parsedDate = new Date(year, month - 1, day);
      if (parsedDate instanceof Date) {
        return parsedDate;
      }
    }
    return new Date();
  };

  const format = 'dd/MM/yyyy';

  const isValid = internalStartTimeframe && internalEndTimeframe;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.dateInputsContainer}>
          <div className={styles.dateInputWrapper}>
            <div>Start date</div>
            <DayPickerInput
              onDayChange={d => {
                setInternalStartTimeframe(d);
              }}
              value={internalStartTimeframe}
              dayPickerProps={{
                canChangeMonth: true
              }}
              placeholder="dd/mm/yyyy"
              format={format}
              formatDate={formatDateForAcquisitionDate}
              parseDate={parseDate}
              inputProps={{ readOnly: true }}
            />
          </div>
          <div className={styles.dateInputWrapper}>
            <div>End date</div>
            <DayPickerInput
              onDayChange={d => {
                setInternalEndTimeframe(d);
              }}
              value={internalEndTimeframe}
              dayPickerProps={{
                canChangeMonth: true
              }}
              placeholder="dd/mm/yyyy"
              format={format}
              formatDate={formatDateForAcquisitionDate}
              parseDate={parseDate}
              inputProps={{ readOnly: true }}
            />
          </div>
        </div>
        <div className={styles.note}>Note: start and end date are included</div>
      </div>
      {error ? (
        <div className={styles.errorContainer}>
          <img
            className={styles.warningErrorIcon}
            alt="Touchpoints tooltip"
            src={amWarning}
          />
          {error}
        </div>
      ) : null}
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          {loading && (
            <div className={styles.loaderContainer}>
              <Loader size="small" />
            </div>
          )}
          {!loading && [
            isValid ? (
              <img
                key="submit-button"
                className={`${
                  styles.confirm
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
                onClick={async () => {
                  if (isValid) {
                    const allowedToSubmit = isAllowedToSubmit();
                    if (allowedToSubmit) {
                      setError(null);
                      await onSubmitClick();
                    }
                  }
                }}
                role="presentation"
              />
            ) : (
              <img
                key="submit-button"
                className={`${styles.confirm} ${
                  styles.invalid
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
              />
            ),
            <img
              key="close-button"
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => onClose()}
              role="presentation"
            />
          ]}
        </div>
      </div>
    </div>
  );
};
