import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Loader from '../../../base/components/Loader/Loader';

import styles from './Goal.module.css';

import { GET_BLOCK_UPLOAD_SAS } from '../../../graphql/Organisation';

const Goal = props => {
  const {
    fields,
    setFields,
    errors,
    allowCoverImageUpload,
    organisation,
    strings
  } = props;
  const [uploading, setUploading] = useState(false);

  let blockUploadSAS;
  const getBlockUploadSAS = () => {
    blockUploadSAS = useQuery(GET_BLOCK_UPLOAD_SAS, {
      variables: { organisationId: organisation.id }
    });
  };
  if (allowCoverImageUpload) {
    getBlockUploadSAS();
  }

  const onCoverImageUpload = e => {
    const sasData = blockUploadSAS.data.blockUploadSAS;

    if (blockUploadSAS && blockUploadSAS.data && sasData) {
      setUploading(true);

      const xhr = new XMLHttpRequest();

      xhr.onload = () => {
        if (xhr.status === 201) {
          const imageFilename =
            sasData && sasData.uri
              ? sasData.uri.replace(/(\?.*)|(#.*)/g, '')
              : null;

          setFields({
            ...fields,
            ...{ imageFilename }
          });
        }
        setUploading(false);
      };

      xhr.open('PUT', sasData.uri);
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

      xhr.send(e.target.files[0]);
    }
  };

  const inputs = [
    {
      name: 'name',
      label: strings.ORGANISATION_NAME,
      type: 'text'
    },
    {
      name: 'contactPersonFirstName',
      label: strings.CONTACT_PERSON_NAME,
      type: 'text'
    },
    {
      name: 'contactPersonLastName',
      label: strings.CONTACT_PERSON_LAST_NAME,
      type: 'text'
    },
    {
      name: 'contactPersonPhone',
      label: strings.CONTACT_PERSON_PHONE_NUMBER,
      type: 'text'
    },
    {
      name: 'savingsTarget',
      label: strings.SAVING_TARGET,
      type: 'number'
    }
  ];

  return (
    <div>
      <Loader isFetching={uploading} />
      {inputs.map((input, index) => (
        <div key={`goal-item-${index.toString()}`}>
          <div className={`roboto-light-font ${styles.inputLabel}`}>
            {input.label}
            <span
              className={`error-message-${input.name} roboto-bold-font ${
                styles.error
              }`}
            >
              {errors[input.name]}
            </span>
          </div>
          <input
            className={`${styles.input} goal-form-${input.name} `}
            type={input.type}
            value={fields[input.name]}
            onChange={e =>
              setFields({
                ...fields,
                ...{ [input.name]: e.currentTarget.value }
              })
            }
          />
        </div>
      ))}

      <div className={`roboto-light-font ${styles.inputLabel}`}>
        {strings.DESCRIPTION_OF_ORGANISATION}
        <span
          className={`error-message-description roboto-bold-font ${
            styles.error
          }`}
        >
          {errors.description}
        </span>
      </div>
      <textarea
        className={`${styles.textarea} goal-form-description`}
        value={fields.description}
        onChange={e =>
          setFields({ ...fields, ...{ description: e.currentTarget.value } })
        }
      />
      {allowCoverImageUpload ? (
        <div>
          <div className={`roboto-light-font ${styles.inputLabel}`}>
            Logo
            <span
              className={`error-message-imageFilename roboto-bold-font ${
                styles.error
              }`}
            >
              {errors.imageFilename}
            </span>
          </div>
          <div className={styles.imageContainer}>
            {fields.imageFilename ? (
              <div
                style={{ backgroundImage: `url(${fields.imageFilename})` }}
                className={styles.imageThumbnail}
              />
            ) : null}
            <div className={styles.logoName}>
              {fields.imageFilename || strings.UPLOAD_AN_IMAGE}
            </div>
            <input
              className={`${styles.file} goal-form-imageFilename`}
              type="file"
              onChange={onCoverImageUpload}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Goal;
