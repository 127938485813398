import React from 'react';

import styles from './PlaceholderChart.module.css';

export default ({ title, description, metricWidth = 100 }) => (
  <div className={styles.container} style={{ width: `${metricWidth}%` }}>
    <div className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
    <div className={styles.chartContainer}>{description}</div>
  </div>
);
