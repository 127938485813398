import { connect } from 'react-redux';
import {
  changeRegistrationField,
  register,
  showErrors,
  requestLogin
} from '../actions';

import RegistrationPage from '../components/RegistrationPage';

const mapStateToProps = state => ({
  fields: state.auth.register.fields,
  isFetching: state.auth.register.isFetching,
  isAuthenticated: state.auth.isAuthenticated,
  isProcessing: state.auth.isProcessing,
  isCompleted: state.auth.isCompleted,
  errors: state.auth.register.errors
});

const mapDispatchToProps = dispatch => ({
  onChange: field => event =>
    dispatch(changeRegistrationField(field, event.target.value)),
  onSubmit: (
    event,
    email,
    password,
    password2,
    betaKey,
    company,
    telephone,
    firstname,
    lastname,
    howDidYouLearnAboutBuffl
  ) => {
    event.preventDefault();
    if (password !== password2) {
      return dispatch(showErrors("Passwords don't match!"));
    }

    return dispatch(
      register(
        email,
        password,
        betaKey,
        company,
        telephone,
        firstname,
        lastname,
        howDidYouLearnAboutBuffl
      )
    );
  },
  onLoginAfterRegister: (email, password, invitationToken) => {
    dispatch(requestLogin(email, password, invitationToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationPage);
