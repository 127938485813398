export default {
  NOT_ALLOWED_WITH_JUMP:
    'This action could not be completed because there are logic jumps attached to this question. Please remove jumps first and then try again.',
  NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS:
    'This action could not be completed because there are logic jumps attached to one or more answers in this block. Please remove jumps first and then try again.',
  NOT_ALLOWED_WITH_JUMP_POINTING_ON_IT:
    'This action could not be completed because there are logic jumps leading to this block. Please remove jumps first and then try again.',
  NOT_ALLOWED_RANDOMIZER_BLOCK:
    'This action is not allowed for distributor blocks.',
  NOT_ALLOWED_QUESTION_BEFORE_RANDOMIZER_BLOCK:
    'This action is not allowed for questions in first position with distributor block',
  NOT_ALLOWED_GROUP_BEFORE_RANDOMIZER_BLOCK:
    'This action is not allowed for group in first position with distributor block',
  NOT_ALLOWED_WITH_JUMP_GROUP:
    'This action could not be completed because there are logic jumps attached to this group. Please remove jumps first and then try again.',
  NOT_ALLOWED_GROUP_WITH_JUMP_POINTING_ON_IT:
    'This action could not be completed because there are logic jumps leading to this group. Please remove jumps first and then try again.',
  NOT_ALLOWED_TO_RANDOMIZE: 'You are not allowed to randomize this group.',
  NOT_ALLOWED_TO_JUMP_NONGROUPED_TO_GROUPED:
    'You cannot jump to a block within a group. Either ungroup the question you would like to jump to, or jump to the group instead.',
  NOT_ALLOWED_TO_JUMP_GROUPED_QUESTIONS_IN_SAME_RANDOMIZED_GROUP:
    'You cannot jump to between questions in a randomized group.',
  NOT_ALLOWED_TO_JUMP_TO_PREVIOUS_QUESTIONS:
    'You cannot jump to previous questions.',
  NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP:
    'You cannot jump from a question within a group to a question outside of the group.',
  NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_NONGROUPED:
    'You cannot jump from a question within a group to a question outside of the group.',
  NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_OTHER_GROUPED:
    'You cannot jump from a question within a group to a question outside of the group.',
  NOT_ALLOWED_TO_JUMP_GROUP_TO_GROUPED_QUESTION:
    'You cannot jump to a block within a group. Either ungroup the question you would like to jump to, or jump to the group instead.',
  NOT_ALLOWED_DISTRIBUTOR_AFTER_RANDOMISED_GROUP:
    'You cannot place a distributor after a randomized group.',
  NOT_ALLOWED_DISTRIBUTOR_ON_THE_FIRST_POSITION_IN_SURVEY:
    'You cannot place a distributor at the start of a survey.',
  NOT_ALLOWED_TO_JUMP_ON_ITSELF: 'Not allowed to jump on itself',
  NOT_ALLOWED_TO_RANDOMIZE_ON_FIRST_POSITION:
    'Not allowed to randomize group on first position of the survey',
  NOT_ALLOWED_TO_RANDOMIZE_WITH_QUESTION_WITH_JUMPS:
    'Not allowed to randomize group with questions with jumps',
  NOT_ALLOWED_QUESTION_BEFORE_RANDOMIZED_GROUP:
    'This action is not allowed for questions in first position before randomized group',
  NOT_ALLOWED_TO_RANDOMIZE_GROUP_WITH_QUESTIONS_WITH_JUMPS_POINTING_ON_IT:
    'Not allowed to randomize group with logic jumps leading to this questions of this group',
  NOT_ALLOWED_WITH_GROUP_JUMP_POINTING_ON_IT:
    'This action could not be completed because there are logic jumps leading to group. Please remove jumps first and then try again.',
  NOT_ALLOWED_WITH_GROUP_WITH_QUESTIONS_JUMP_POINTING_ON_IT:
    'This action could not be completed because there are logic jumps leading to questions within this group. Please remove jumps first and then try again.',
  NOT_ALLOWED_LAST_QUESTION:
    'This action could not be completed for the last question of the survey.',
  NOT_ALLOWED_MOVE_INTO_SELF: 'Not allowed to move a group into itself',
  NOT_ALLOWED_DEEPER_NESTING: 'Not allowed to nest groups',
  NOT_ALLOWED_MERGE_TOP_LOGIC:
    'It is not possible to merge these groups because the top group has a logic jump',
  NOT_ALLOWED_MERGE_BOTTOM_LOGIC:
    'It is not possible to merge these groups because the bottom group has a logic jump',
  ALREADY_TARGETED_BY_DISTRIBUTOR:
    'It is not possible to distribute to the same element twice',
  NOT_ALLOWED_DISTRIBUTOR_WITH_BRANCHES:
    'It is not possible to move a distributor with active branches into a group, move it into the correct group before setting branches instead',
  NOT_ALLOWED_TO_JUMP_TO_PREVIOUS_GROUPS: 'You cannot jump to previous groups.',
  NOT_ALLOWED_MERGE_DIFF_RANDOM:
    'It is not allowed to merge a randomized group with a normal group',
  NOT_ALLOWED_TO_JUMP_GROUP_TO_OUTSIDE_PARENT:
    'It is not possible to jump from a group to an element outside its parent group',
  NOT_ALLOWED_TO_JUMP_TO_PARENT_GROUP:
    'It is not possible to jump from an element to its parent group',
  NOT_ALLOWED_DISTRIBUTOR_INTO_GROUP:
    'It is not allowed to place distributor into a group',
  NOT_ALLOWED_EDIT_PROFILING_QUESTION_AFTER_RESULTS:
    'Profiling questions cannot be edited after results have been collected',
  NOT_ALLOWED_WITH_PROFILING_QUESTIONS:
    'This action is not allowed for profiling questions',
  NOT_ALLOWED_FOR_REUSED_PROFILING_QUESTIONS:
    'This action is not allowed for reused profiling questions',
  NOT_ALLOWED_TO_SET_MIN_QUOTA_AFTER_LOGIC_JUMPS_OR_DISTRIBUTOR:
    'You cannot apply minimum quota after a distributor or logic jump.',
  NOT_ALLOWED_TO_MOVE_OR_DUPLICATE_MIN_QUOTA_AFTER_DISTRIBUTOR_OR_JUMPS:
    'It is not allowed to move/duplicate a question with minimum quota below a distributor or a question with logic jumps',
  NOT_ALLOWED_TO_ADD_LOGIC_JUMPS_BEFORE_MIN_QUOTA:
    'It is not allowed to add logic jumps if there is a question with minimum quota after it'
};
