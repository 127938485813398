import React from 'react';
import './FlowBar.css';

const FlowBar = ({ flowBarTabs, activeTab, setActiveTab }) => (
  <div className="flow-bar-root-container">
    <div className="flow-bar-container" role="presentation">
      {flowBarTabs.map((flowBarTab, index) => (
        <div className="flow-bar-tab-wrapper" key={`'tab-${flowBarTab.name}`}>
          <div
            role="presentation"
            onClick={() => {
              setActiveTab(flowBarTab);
            }}
            className={
              activeTab === flowBarTab
                ? `flow-bar-tab flow-bar-tab-active`
                : `flow-bar-tab`
            }
          >
            {index + 1}. {flowBarTab.label}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default FlowBar;
