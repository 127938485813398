import React, { useEffect } from 'react';
import Footer from '../../../Footer/Footer';
import styles from '../UserDetails/UserDetails.module.css';

export default props => {
  const {
    termsContent,
    strings,
    languages,
    selectedLanguage,
    setViewTerms,
    customThemeBaseColor
  } = props;

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const translations = strings[selectedLanguage];
  if (!translations) {
    return false;
  }

  const languageShortNames = {
    [languages.ENGLISH]: 'EN',
    [languages.FRENCH]: 'FR',
    [languages.DUTCH]: 'NL',
    [languages.GERMAN]: 'DE'
  };

  const showTermsAndConditions = () =>
    Object.values(
      termsContent[languageShortNames[selectedLanguage]].markdownContent.content
    ).map((value, index) => {
      if (value.type === 'text') {
        return (
          <p className={styles.text} key={`terms-item-${index.toString()}`}>
            {value.content}
          </p>
        );
      }
      /*
      if (value.type === 'image') {
        return (
          <img
            key={`terms-item-${index.toString()}`}
            alt="Terms and Conditions"
            src={image1}
            className={styles.termsContentImage}
          />
        );
      }
      */
      return null;
    });

  if (termsContent) {
    return (
      <div className={styles.parentContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.container}>
            <div className={styles.termsContentContainer}>
              <div>
                <div
                  className={`${styles.title} ${
                    styles.termsTitle
                  } campaign-user-details-page-title`}
                >
                  {strings[selectedLanguage].THE_TERMS_AND_CONDITIONS}
                </div>
                <div>{showTermsAndConditions()}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer
            backLabel={translations.BACK}
            hiddenNext
            onClickBack={() => setViewTerms(false)}
            customThemeBaseColor={customThemeBaseColor}
          />
        </div>
      </div>
    );
  }

  return null;
};
