import React, { memo } from 'react';

import InputField from '../../../InputField/InputField';

export default memo(({ actions, minCharacters, maxCharacters }) => (
  <>
    <InputField
      property="minCharacters"
      value={minCharacters}
      type="number"
      placeholder="Enter value here.."
      label="Minimum Answer Length"
      onChange={actions.updateQuestionProperty}
    />
    <InputField
      property="maxCharacters"
      value={maxCharacters}
      type="number"
      placeholder="Enter value here.."
      label="Maximum Answer Length"
      onChange={actions.updateQuestionProperty}
    />
  </>
));
