import React, { useState, useEffect } from 'react';

import ImagePopup from './components/ImagePopup/ImagePopup';

import zoomInIcon from '../../../../../../assets/img/zoom-in.svg';

import styles from '../../CampaignQuestionsPage.module.css';

export const isOtherFieldVisible = (block, values, allowOtherIndex) => {
  if (block.allowOther) {
    if (
      block.type === 'mc_question' &&
      block.selectAtMost === 1 &&
      values &&
      values[0] === allowOtherIndex
    ) {
      return true;
    }
    if (
      block.type === 'mc_question' &&
      block.selectAtMost > 1 &&
      values &&
      values[0] &&
      values[0].indexOf(allowOtherIndex) > -1
    ) {
      return true;
    }
  }
  return false;
};

export default props => {
  const {
    block,
    setValid,
    values,
    setValues,
    answers,
    translations,
    customThemeBaseColor
  } = props;

  const [displayImagePopup, setDisplayImagePopup] = useState({
    visible: false,
    src: '',
    alt: ''
  });

  const allowOtherIndex =
    block && block.allowOther && answers && answers.length
      ? answers.length - (block.allowNoneOfTheAbove ? 2 : 1)
      : null;

  const allowNoneOfTheAboveIndex =
    block && block.allowNoneOfTheAbove && answers && answers.length
      ? answers.length - 1
      : null;

  const checkIsValid = (selectedValues = null, otherAnswerString = null) => {
    if (
      selectedValues &&
      selectedValues.length >= 0 &&
      (selectedValues.length >= block.selectAtLeast ||
        selectedValues.some(v => v === allowNoneOfTheAboveIndex)) &&
      selectedValues.length <= block.selectAtMost
    ) {
      if (block.allowOther) {
        if (selectedValues && selectedValues.some(v => v === allowOtherIndex)) {
          setValid(!!(otherAnswerString && otherAnswerString.length > 0));
        } else {
          setValid(true);
        }
      } else {
        setValid(true);
      }
    } else if (!selectedValues && block.selectAtLeast === 0) {
      setValues([[], undefined]);
      setValid(true);
    } else {
      setValid(false);
    }
  };

  useEffect(
    () => {
      checkIsValid(
        // eslint-disable-next-line no-nested-ternary
        values && values.length
          ? block.selectAtMost > 1
            ? values[0]
            : [values[0]]
          : null,
        values && values.length && values[1] ? values[1] : null
      );
    },
    [block]
  );

  useEffect(() => {
    if (document && document.documentElement && customThemeBaseColor) {
      document.documentElement.style.setProperty(
        '--mc-question-answer-background-color',
        `${customThemeBaseColor}10`
      );
      document.documentElement.style.setProperty(
        '--mc-question-answer-background-color-active',
        `${customThemeBaseColor}60`
      );
      document.documentElement.style.setProperty(
        '--mc-question-image-answer-background-color-hover',
        `${customThemeBaseColor}60`
      );
      document.documentElement.style.setProperty(
        '--mc-question-image-answer-background-color-active',
        `${customThemeBaseColor}90`
      );
    }
  }, []);

  const onChangeValue = index => {
    if (block.type === 'mc_question' && block.selectAtMost === 1) {
      if (!block.random) {
        setValues([index, values ? values[1] : null]);
      }
      if (block.random) {
        const selectedValue = answers[index];

        const realValue = selectedValue.originalIndex;

        setValues([realValue, values ? values[1] : null]);
      }
      checkIsValid([index], values ? values[1] : null);
    }

    if (block.type === 'mc_question' && block.selectAtMost > 1) {
      let arr = null;
      if (!block.random) {
        arr = values && values.length > 0 && values[0] ? [...values[0]] : [];
        if (arr.includes(index)) {
          arr.splice(arr.indexOf(index), 1);
        } else if (
          values &&
          values.length > 0 &&
          values[0] &&
          (values[0].length === block.selectAtMost &&
            index !== allowNoneOfTheAboveIndex)
        ) {
          return;
        } else {
          arr.push(index);
        }

        // Unselect other / none of the above option - not allowed to choose both
        if (block.allowNoneOfTheAbove) {
          if (
            index !== allowNoneOfTheAboveIndex &&
            arr.some(i => i === allowNoneOfTheAboveIndex)
          ) {
            arr.splice(arr.findIndex(i => i === allowNoneOfTheAboveIndex), 1);
          } else if (index === allowNoneOfTheAboveIndex) {
            arr.splice(0, arr.length - 1);
          }
        }

        setValues([arr, values ? values[1] : null]);
      }
      if (block.random) {
        const selectedValue = answers[index];

        const realValue = selectedValue.originalIndex;

        arr = values && values.length > 0 && values[0] ? [...values[0]] : [];

        if (arr.includes(realValue)) {
          arr.splice(arr.indexOf(realValue), 1);
        } else if (
          values &&
          values.length > 0 &&
          values[0] &&
          (values[0].length === block.selectAtMost &&
            index !== allowNoneOfTheAboveIndex)
        ) {
          return;
        } else {
          arr.push(realValue);
        }

        // Unselect other / none of the above - not allowed to choose both
        if (block.allowNoneOfTheAbove) {
          if (
            index !== allowNoneOfTheAboveIndex &&
            arr.some(i => i === allowNoneOfTheAboveIndex)
          ) {
            arr.splice(arr.findIndex(i => i === allowNoneOfTheAboveIndex), 1);
          } else if (index === allowNoneOfTheAboveIndex) {
            arr.splice(0, arr.length - 1);
          }
        }

        setValues([arr, values ? values[1] : null]);
      }
      checkIsValid(arr, values ? values[1] : null);
    }
  };

  const onOtherFieldChangeValue = newOtherAnswer => {
    const newValue = [...values];
    newValue[1] = newOtherAnswer;
    setValues(newValue);
    checkIsValid(
      Number.isInteger(newValue[0]) ? [newValue[0]] : newValue[0],
      newOtherAnswer
    );
  };

  const getAnswer = (answer, index) => {
    let isActive = false;
    let selectedAnswerOrder = null;

    if (block.type === 'mc_question' && block.selectAtMost === 1) {
      if (!block.random) {
        if (values) {
          isActive = values[0] === index;
        }
      }
      if (block.random) {
        if (values) {
          isActive = values[0] === answer.originalIndex;
        }
      }
    }

    if (block.type === 'mc_question' && block.selectAtMost > 1) {
      if (!block.random) {
        if (values && values[0]) {
          isActive = values[0] && values[0].indexOf(index) > -1;
          if (
            values &&
            values.length &&
            block.ranked &&
            values[0].indexOf(index) >= 0
          ) {
            selectedAnswerOrder = values[0].indexOf(index) + 1;
          }
        }
      }
      if (block.random) {
        isActive =
          values &&
          values.length &&
          values[0] &&
          values[0].length > 0 &&
          values[0].indexOf(answer.originalIndex) >= 0;

        if (block.ranked && isActive) {
          selectedAnswerOrder = values[0].indexOf(answer.originalIndex) + 1;
        }
      }
    }

    if (answer.imageUrl) {
      return (
        <>
          <div
            key={`answer-option-${block.id}-${index.toString()}`}
            className={
              isActive
                ? `${styles.imageAnswer} ${styles.active} active-answer`
                : styles.imageAnswer
            }
            role="presentation"
            onClick={() => {
              onChangeValue(index);
            }}
          >
            {block.ranked &&
            selectedAnswerOrder &&
            index !== allowNoneOfTheAboveIndex ? (
              <span
                className={styles.imageQuestionCounter}
                style={{ backgroundColor: customThemeBaseColor || '#24B2E8' }}
              >
                {selectedAnswerOrder}
              </span>
            ) : null}
            <img src={answer.imageUrl} alt={answer.answer} />
            <span className={styles.imageAnswerText}>{answer.answer}</span>
            <div
              className={
                isActive
                  ? `${styles.zoomInIconContainer} ${styles.visible}`
                  : styles.zoomInIconContainer
              }
              role="presentation"
              onClick={() => {
                setDisplayImagePopup({
                  visible: true,
                  src: answer.imageUrl,
                  alt: answer.answer
                });
              }}
            >
              <img
                src={zoomInIcon}
                alt="Zoom in"
                className={styles.zoomInIcon}
              />
            </div>
          </div>
          {allowOtherIndex === index &&
          isOtherFieldVisible(block, values, allowOtherIndex) ? (
            <div className={styles.questionContainer}>
              <textarea
                className={`${styles.inputField} ${
                  styles.otherAnswerInputField
                } user-answers-textarea-input`}
                onChange={event => onOtherFieldChangeValue(event.target.value)}
                value={values[1]}
              />
            </div>
          ) : null}
        </>
      );
    }

    return (
      <div
        key={`answer-option-${block.id}-${index.toString()}`}
        className={styles.mcAnswerContainer}
      >
        <div
          className={
            isActive
              ? `${styles.answer} ${styles.active} active-answer`
              : styles.answer
          }
          role="presentation"
          onClick={() => {
            onChangeValue(index);
          }}
        >
          {block.ranked &&
          selectedAnswerOrder &&
          index !== allowNoneOfTheAboveIndex ? (
            <span
              className={styles.questionCounter}
              style={{ backgroundColor: customThemeBaseColor || '#24B2E8' }}
            >
              {selectedAnswerOrder}
            </span>
          ) : null}
          {answer.answer}
        </div>
        {allowOtherIndex === index &&
        isOtherFieldVisible(block, values, allowOtherIndex) ? (
          <div className={` ${styles.inputFieldContainer}`}>
            <textarea
              className={`${styles.inputField} ${
                styles.otherAnswerInputField
              } user-answers-textarea-input`}
              onChange={event => onOtherFieldChangeValue(event.target.value)}
              value={values[1]}
            />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      <div
        className={`${styles.questionContainer} ${
          styles.mcQuestionContainer
        } user-answers-question-container`}
      >
        {block && block.selectAtMost > 1 ? (
          <div className={styles.amountOfAnswerOptionsContainer}>
            {block.selectAtLeast === block.selectAtMost
              ? translations.SELECT_ANSWERS.replace(
                  '{selectAtLeast}',
                  block.selectAtLeast
                )
              : translations.SELECT_AT_LEAST_AND_AT_MOST_ANSWERS.replace(
                  '{selectAtLeast}',
                  block.selectAtLeast
                ).replace('{selectAtMost}', block.selectAtMost)}
          </div>
        ) : null}
        <div className={styles.mcQuestionAnswers}>
          {block &&
            answers &&
            answers.map((answer, index) => getAnswer(answer, index))}
        </div>
      </div>
      {displayImagePopup.visible ? (
        <ImagePopup
          src={displayImagePopup.src}
          alt={displayImagePopup.alt}
          onClose={() =>
            setDisplayImagePopup({
              visible: false,
              src: '',
              alt: ''
            })
          }
        />
      ) : null}
    </div>
  );
};
