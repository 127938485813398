export default (setFunction, d, i, opacity, colors, unfiltered = false) => {
  setFunction(tooltips => [
    ...tooltips,
    {
      index: unfiltered ? `${i}_unfiltered` : i,
      color: colors[i],
      x: d.x,
      y: d.y,
      opacity
    }
  ]);
};
