import React, { useState } from 'react';
import Line from '../../../Charts/Line';

const TimeTaken = props => {
  const { block, onTimePrettyPrint } = props;
  const [lineChartParent, setLineChartParent] = useState(null);

  const stats = block.resultStats.timeTaken.reduce(
    (acumulator, { x }) => [
      acumulator[0] + x,
      Math.min(x, acumulator[1]),
      Math.max(x, acumulator[2])
    ],
    [0, Number.MAX_VALUE, Number.MIN_VALUE]
  );

  stats[0] =
    block.resultStats.timeTaken.length && stats[0]
      ? onTimePrettyPrint(stats[0] / block.resultStats.timeTaken.length)
      : null;
  stats[1] =
    block.resultStats.timeTaken.length && stats[1]
      ? onTimePrettyPrint(stats[1])
      : null;
  stats[2] =
    block.resultStats.timeTaken.length && stats[2]
      ? onTimePrettyPrint(stats[2])
      : null;

  return (
    <div className="result-view-data area-chart-container">
      <div
        className="result-view-data area-chart"
        ref={elem => setLineChartParent(elem)}
      >
        <Line
          data={block.resultStats.timeTaken}
          parent={lineChartParent}
          height="400"
          yLabel="Answers"
          xLabel="Seconds"
          xType="number"
          order
        />
      </div>
      <div className="result-view-data stats-data">
        {stats[0] ? (
          <div className="results-view-stats-big-container">
            <div className="results-view-stats-big-number">{stats[0]}</div>
            <div className="results-view-stats-big-label">AVG</div>
          </div>
        ) : null}
        <div className="results-view-stats-small-container">
          {stats[1] ? (
            <div className="results-view-stats-small-subcontainer">
              <div className="results-view-stats-small-number">{stats[1]}</div>
              <div className="results-view-stats-small-label">MIN</div>
            </div>
          ) : null}
          {stats[2] ? (
            <div className="results-view-stats-small-subcontainer">
              <div className="results-view-stats-small-number">{stats[2]}</div>
              <div className="results-view-stats-small-label">MAX</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TimeTaken;
