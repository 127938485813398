import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import api from '../../../../api';
import ActionDropdown from '../ActionDropdown/ActionDropdown';
import TableColumnHeaderItem from './TableColumnHeaderItem/TableColumnHeaderItem';
import CampaignName from './CampaignName/CampaignName';
import Popup from '../../Popup';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import './CampaignListItem.css';
import starChecked from './assets/star_checked.svg';
import starUnchecked from './assets/star_unchecked.svg';
import onlyWebVersionIcon from './assets/only_web_version.png';

import { DUPLICATE_SURVEY } from '../../../../graphql/Survey';

import dutchFlag from './assets/dutch.png';
import frenchFlag from './assets/french.png';
import englishFlag from './assets/english.png';
import germanFlag from './assets/german.png';

const CampaignListItem = props => {
  const [duplicationError, setDuplicationError] = React.useState(false);

  const [duplicateSurvey] = useMutation(DUPLICATE_SURVEY, {
    onCompleted: data => onEditCampaign({ id: data.duplicateSurvey.survey.id }),
    onError: () => setDuplicationError(true)
  });

  const {
    onSelectCampaign,
    selectedCampaign,
    onBlurSelectedCampaign,
    isProcessing,
    isFetching,
    calculatePercentage,
    formatDate,
    statusDisplay,
    onPublishSurvey,
    onUnpublishCampaign,
    onPayCampaign,
    onEditCampaign,
    onShowResults,
    isAdmin,
    onLoad,
    setClientStarredSurvey,
    onChangeColumnSort,
    otherCampaigns,
    starredCampaigns,
    starredCampaignIds,
    setSurveysToCombine,
    setDisplayCombineSurveysTab,
    onRequestApproval
  } = props;

  const actionDropdownPositionStyle = {
    right: '10px',
    top: '50px',
    bottomPosition: '40px',
    width: '160px'
  };

  const campainResultsCount = campaign =>
    campaign.results_count ? campaign.results_count : 0;

  const getCampaignAvailableToLanguagesFlags = campaign => {
    const getLanguageFlagFile = language => {
      if (language === 'Dutch') {
        return dutchFlag;
      }
      if (language === 'French') {
        return frenchFlag;
      }
      if (language === 'English') {
        return englishFlag;
      }
      if (language === 'German') {
        return germanFlag;
      }
      return null;
    };

    if (campaign && campaign.language) {
      return (
        <div className="flags-container">
          {campaign && campaign.language && (
            <img
              src={getLanguageFlagFile(campaign.language)}
              alt={`${campaign.language} FLAG`}
            />
          )}
        </div>
      );
    }
    return null;
  };

  const changeColumnSort = (column, campaignsPath, campaignsConfiguration) => {
    let newSortType;
    let newSortColumn = campaignsConfiguration.sortColumn;
    if (campaignsConfiguration.sortColumn !== column) {
      newSortColumn = column;
      newSortType = 'desc';
    } else {
      newSortType = campaignsConfiguration.sortType === 'desc' ? 'asc' : 'desc';
    }

    onChangeColumnSort(newSortColumn, newSortType, campaignsPath);
    onLoad();
  };

  const starCampaign = async (campaign, star) => {
    await api().starCampaign(campaign, star);
    setClientStarredSurvey(campaign, star);
    onLoad();
  };

  const openCampaign = campaign => {
    if (!campaign.paid) {
      onEditCampaign(campaign);
    } else {
      onShowResults(campaign);
    }
  };

  const openCampaignInNewTab = (event, campaign) => {
    event.preventDefault();

    const url = !campaign.paid
      ? `${window.location.origin.toString()}/clients/survey/${
          campaign.id
        }/edit`
      : `${window.location.origin.toString()}/clients/campaign/${
          campaign.id
        }/results`;

    window.open(url, '_blank', 'noreferrer');
  };

  const getSingleCampaignRow = (campaign, index, star) => (
    <div
      className="list-item-container"
      key={`campaign-row-${campaign.id} - ${index.toString()}`}
      role="presentation"
      onClick={() => openCampaign(campaign)}
      onContextMenu={e => openCampaignInNewTab(e, campaign)}
    >
      <div className="column-05 list-item">
        <img
          src={
            starredCampaignIds && starredCampaignIds.indexOf(campaign.id) > -1
              ? starChecked
              : starUnchecked
          }
          alt="Unstared campaign"
          className="list-item-campaign-star"
          role="presentation"
          onClick={e => {
            starCampaign(campaign, star);
            e.stopPropagation();
          }}
        />
      </div>
      <div className="column-1 list-item campaign-imag-container">
        <div
          className="campaign-img"
          style={{
            backgroundImage: `url(/media/${campaign.image_filename})`
          }}
        />
        {getCampaignAvailableToLanguagesFlags(campaign)}
      </div>
      <div className="column-max-width list-item campaign-list-name">
        <CampaignName name={campaign.projectName || campaign.name || '/'} />
        <div className="list-item-client-email">
          {campaign && campaign.client && campaign.clientEmail
            ? campaign.clientEmail
            : ''}
          {campaign.only_web_version ? (
            <img
              src={onlyWebVersionIcon}
              alt="Only web version icon"
              className="only-web-version-icon"
            />
          ) : null}
        </div>
      </div>
      <div className="column-account list-item">
        {campaign && campaign.record && campaign.record.customer ? (
          <div className="list-item-client-email">
            {campaign.record.customer}
          </div>
        ) : null}
        {campaign && campaign.record && campaign.record.team ? (
          <div className="list-item-client-email">{campaign.record.team}</div>
        ) : null}
      </div>
      <div className="column-7 list-item campaign-list-created-at">
        {formatDate(campaign.publishedAt)}
      </div>
      <div className="column-7 list-item">
        {formatDate(campaign.latest_response)}
      </div>
      <div className="column-2 list-item align-right campaign-list-target">
        {campaign.max_responses}
      </div>
      <div className="column-2 list-item align-right list-item-campaign-results">
        {campaign.status !== 'incomplete' || campainResultsCount(campaign) > 0
          ? campainResultsCount(campaign)
          : null}
      </div>
      {campaign.status !== 'incomplete' ||
      (campainResultsCount(campaign) > 0 && campaign.max_responses > 0) ? (
        <div className="column-6 list-item">
          <div className="percentage">
            {calculatePercentage(
              campaign.results_count,
              campaign.max_responses
            )}
          </div>
          <div className="bar-container">
            <div
              className="bar"
              style={{
                width: calculatePercentage(
                  campaign.results_count,
                  campaign.max_responses,
                  true
                )
              }}
            />
          </div>
        </div>
      ) : (
        <div className="column-6 list-item" />
      )}
      <div className="column-5 list-item list-item-campaign-status">
        {statusDisplay(campaign)}
      </div>
      <div
        role="presentation"
        className="column-1 list-item align-right show-actions"
        onClick={e => {
          e.stopPropagation();
          onSelectCampaign(campaign);
        }}
      >
        ...
      </div>
      {selectedCampaign && selectedCampaign.id === campaign.id ? (
        <ActionDropdown
          {...props}
          onBlur={() => {
            onBlurSelectedCampaign();
          }}
          onDuplicateSurvey={id =>
            duplicateSurvey({
              variables: {
                id
              }
            })
          }
          onPublishSurvey={onPublishSurvey}
          onRequestApproval={onRequestApproval}
          onUnpublishCampaign={onUnpublishCampaign}
          onPayCampaign={onPayCampaign}
          positionStyle={actionDropdownPositionStyle}
          isAdmin={isAdmin}
          mergeSurvey={() =>
            setSurveysToCombine([
              {
                id: campaign.id,
                name: campaign.projectName || campaign.name
              }
            ])
          }
          setDisplayCombineSurveysTab={setDisplayCombineSurveysTab}
        />
      ) : null}
    </div>
  );

  const getCampaignsTable = (
    list,
    title,
    campaignsPath,
    campaignsConfiguration
  ) => (
    <div
      className="list-container"
      key={`campaigns-table-list-${campaignsPath}`}
    >
      {title ? (
        <div className="list-item-header-section-title">{title}</div>
      ) : null}
      <div className="list-item-header-container">
        <div className="column-05" />
        <div className="column-1" />
        <div className="column-max-width campaign-list-name">
          <TableColumnHeaderItem
            label="Survey name & creator"
            column="projectName"
            changeColumnSort={changeColumnSort}
            sortColumn={
              campaignsConfiguration && campaignsConfiguration.sortColumn
            }
            sortType={campaignsConfiguration && campaignsConfiguration.sortType}
            campaignsPath={campaignsPath}
            campaignsConfiguration={campaignsConfiguration}
          />
        </div>
        <div className="column-account">
          <TableColumnHeaderItem
            label="Customer & initiative"
            column="record"
            changeColumnSort={changeColumnSort}
            sortColumn={
              campaignsConfiguration && campaignsConfiguration.sortColumn
            }
            sortType={campaignsConfiguration && campaignsConfiguration.sortType}
            campaignsPath={campaignsPath}
            campaignsConfiguration={campaignsConfiguration}
          />
        </div>
        <div className="column-7 campaign-list-created-at">
          <TableColumnHeaderItem
            label="Published"
            column="publishedAt"
            changeColumnSort={changeColumnSort}
            sortColumn={
              campaignsConfiguration && campaignsConfiguration.sortColumn
            }
            sortType={campaignsConfiguration && campaignsConfiguration.sortType}
            campaignsPath={campaignsPath}
            campaignsConfiguration={campaignsConfiguration}
          />
        </div>
        <div className="column-7">
          <TableColumnHeaderItem
            label="Last response"
            column="latestResponse"
            changeColumnSort={changeColumnSort}
            sortColumn={
              campaignsConfiguration && campaignsConfiguration.sortColumn
            }
            sortType={campaignsConfiguration && campaignsConfiguration.sortType}
            campaignsPath={campaignsPath}
            campaignsConfiguration={campaignsConfiguration}
          />
        </div>
        <div className="column-2 align-right campaign-list-target">
          <TableColumnHeaderItem
            label="Sample size"
            column="maxResponses"
            changeColumnSort={changeColumnSort}
            sortColumn={
              campaignsConfiguration && campaignsConfiguration.sortColumn
            }
            sortType={campaignsConfiguration && campaignsConfiguration.sortType}
            campaignsPath={campaignsPath}
            campaignsConfiguration={campaignsConfiguration}
          />
        </div>
        <div className="column-2 align-right">
          <TableColumnHeaderItem
            label="Responses"
            column="resultsCount"
            changeColumnSort={changeColumnSort}
            sortColumn={
              campaignsConfiguration && campaignsConfiguration.sortColumn
            }
            sortType={campaignsConfiguration && campaignsConfiguration.sortType}
            campaignsPath={campaignsPath}
            campaignsConfiguration={campaignsConfiguration}
          />
        </div>
        <div className="column-6">Completion</div>
        <div className="column-5">Status</div>
        <div className="column-1" />
      </div>
      {list}
    </div>
  );

  if (isProcessing || isFetching) {
    const loadingCampaigns = (
      <div className="loader-container">
        <div className="item" />
        <div className="item" />
        <div className="item" />
        <div className="item" />
      </div>
    );
    return getCampaignsTable(loadingCampaigns);
  }

  if (!isProcessing && !isFetching) {
    const campaignsArray = [];

    const otherCampaignsList =
      otherCampaigns &&
      otherCampaigns.campaigns &&
      otherCampaigns.campaigns.length > 0
        ? otherCampaigns.campaigns.map((campaign, index) =>
            getSingleCampaignRow(campaign, index, true)
          )
        : null;

    const starredCampaignsList =
      starredCampaigns &&
      starredCampaigns.campaigns &&
      starredCampaigns.campaigns.length > 0
        ? starredCampaigns.campaigns.map((campaign, index) =>
            getSingleCampaignRow(campaign, index, false)
          )
        : null;

    if (
      starredCampaigns &&
      starredCampaigns.campaigns &&
      starredCampaigns.campaigns.length
    ) {
      campaignsArray.push(
        getCampaignsTable(
          starredCampaignsList,
          'Favorites',
          'starredCampaigns',
          starredCampaigns
        )
      );
    }

    campaignsArray.push(
      getCampaignsTable(
        otherCampaignsList,
        starredCampaigns &&
          starredCampaigns.campaigns &&
          starredCampaigns.campaigns.length
          ? 'Other surveys'
          : null,
        'otherCampaigns',
        otherCampaigns
      )
    );

    if (duplicationError) {
      campaignsArray.push(
        <Popup
          key="error"
          component={
            <ConfirmationDialog
              label="Something went wrong while duplicating survey"
              confirmLabel="Ok"
              cancelLabel="Cancel"
              onConfirm={() => {
                setDuplicationError(false);
              }}
              onCancel={() => {
                setDuplicationError(false);
              }}
            />
          }
          onClose={() => {
            setDuplicationError(false);
          }}
        />
      );
    }

    return campaignsArray;
  }

  return null;
};

export default CampaignListItem;
