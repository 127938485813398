import { COMMUNITY_DASHBOARD_FILTER_TYPE } from './constants';

export default (innerAppliedFilters, appliedFilters) => {
  let filterDifferences = false;

  if (appliedFilters.length === 0 && innerAppliedFilters.length === 0) {
    return false;
  }

  for (let i = 0; i < innerAppliedFilters.length; i += 1) {
    const iAF = innerAppliedFilters[i];

    if (iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG) {
      if (!iAF.tags.category || iAF.tags.category === '') {
        filterDifferences = true;
        break;
      }

      if (!iAF.tags.tagName || iAF.tags.tagName === '') {
        filterDifferences = true;
        break;
      }

      if (
        !iAF.tags.tagValue ||
        iAF.tags.tagValue === '' ||
        (Array.isArray(iAF.tags.tagValue) && !iAF.tags.tagValue.length)
      ) {
        filterDifferences = true;
        break;
      }
    }

    if (iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY) {
      if (
        !iAF.userProperties.propertyName ||
        iAF.userProperties.propertyName === ''
      ) {
        filterDifferences = true;
        break;
      }

      if (
        !iAF.userProperties.propertyValue ||
        iAF.userProperties.propertyValue === '' ||
        (Array.isArray(iAF.userProperties.propertyValue) &&
          !iAF.userProperties.propertyValue.length)
      ) {
        filterDifferences = true;
        break;
      }
    }

    if (iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY) {
      if (
        !iAF.community.name ||
        iAF.community.name === '' ||
        (Array.isArray(iAF.community.name) && !iAF.community.name.length)
      ) {
        filterDifferences = true;
        break;
      }
    }

    if (iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION) {
      const nonActiveAcquisionParams = [];

      if (
        !iAF.acquisition.channel ||
        iAF.acquisition.channel === '' ||
        (Array.isArray(iAF.acquisition.channel) &&
          !iAF.acquisition.channel.length)
      ) {
        nonActiveAcquisionParams.push('channel');
      }
      if (
        !iAF.acquisition.launchDate ||
        iAF.acquisition.launchDate === '' ||
        (Array.isArray(iAF.acquisition.launchDate) &&
          !iAF.acquisition.launchDate.length)
      ) {
        nonActiveAcquisionParams.push('launchDate');
      }
      if (
        !iAF.acquisition.partner ||
        iAF.acquisition.partner === '' ||
        (Array.isArray(iAF.acquisition.partner) &&
          !iAF.acquisition.partner.length)
      ) {
        nonActiveAcquisionParams.push('partner');
      }
      if (
        !iAF.acquisition.medium ||
        iAF.acquisition.medium === '' ||
        (Array.isArray(iAF.acquisition.medium) &&
          !iAF.acquisition.medium.length)
      ) {
        nonActiveAcquisionParams.push('medium');
      }
      if (
        !iAF.acquisition.action ||
        iAF.acquisition.action === '' ||
        (Array.isArray(iAF.acquisition.action) &&
          !iAF.acquisition.action.length)
      ) {
        nonActiveAcquisionParams.push('action');
      }

      if (nonActiveAcquisionParams && nonActiveAcquisionParams.length === 5) {
        filterDifferences = true;
        break;
      }
    }
  }

  return !filterDifferences;
};
