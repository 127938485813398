import React, { useState, useEffect, useRef } from 'react';
import Scheduler from '../SurveyScheduler/components/Scheduler';
import ResultPopup from '../ResultPopup/ResultPopup';
import {
  isDatePast,
  isTimePast,
  scheduleErrors
} from '../SurveyScheduler/SurveyScheduler';

import styles from './SchedulePopup.module.css';

export default ({
  initialDate,
  actionLabel,
  leftButtonLabel = 'Delete schedule',
  rightButtonLabel = 'Schedule',
  onLeftButtonClick,
  onRightButtonClick,
  onDirtyRightButtonClick,
  publishScheduled
}) => {
  const [dirty, setDirty] = useState(null);
  const [date, setDate] = useState(initialDate || null);
  const [dateError, setDateError] = useState(null);
  const [timeError, setTimeError] = useState(null);

  const [scheduled, setScheduled] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    height: 251,
    width: 446
  });

  const schedulerRef = useRef(null);

  useEffect(
    () => {
      if (schedulerRef.current) {
        setWindowDimensions({
          height: schedulerRef.current.offsetHeight,
          width: schedulerRef.current.offsetWidth
        });
      }
    },
    [schedulerRef]
  );

  const isScheduleButtonEnabled = () => !date || dateError || timeError;

  useEffect(
    () => {
      if (date) {
        setDateError(isDatePast(date) ? scheduleErrors.pastDate : null);
        setTimeError(isTimePast(date) ? scheduleErrors.pastTime : null);

        if (
          publishScheduled &&
          new Date(publishScheduled.time).getTime() >= new Date(date).getTime()
        ) {
          setDateError(scheduleErrors.unpublishMatch);
          setTimeError(null);
        }
      }
    },
    [date]
  );

  if (scheduled) {
    return (
      <ResultPopup
        title="Scheduling succesful"
        description="Your changes have been logged."
        width={windowDimensions.width}
        height={windowDimensions.height}
      />
    );
  }

  if (canceled) {
    return (
      <ResultPopup
        title="Scheduling canceled"
        description="Your changes have been logged."
        width={windowDimensions.width}
        height={windowDimensions.height}
      />
    );
  }

  return (
    <div className={styles.container} ref={schedulerRef}>
      <div className={styles.schedulerContainer}>
        <Scheduler
          actionLabel={actionLabel}
          toggle
          date={date}
          setDate={newDate => {
            if (initialDate && !dirty) {
              setDirty(true);
            }
            setDate(newDate);
          }}
          dateError={dateError}
          timeError={timeError}
        />
      </div>
      <div
        className={styles.buttonsContainer}
        style={{
          marginTop: dateError || timeError ? '25px' : '0px',
          justifyContent: onLeftButtonClick ? 'space-between' : 'flex-end'
        }}
      >
        {onLeftButtonClick && (
          <div
            role="presentation"
            className={`${styles.button} ${styles.leftButton}`}
            onClick={() => {
              if (!dirty && onLeftButtonClick) onLeftButtonClick();
              setCanceled(true);
            }}
          >
            {leftButtonLabel}
          </div>
        )}

        <div
          role="presentation"
          className={`${styles.button} ${styles.rightButton} ${
            isScheduleButtonEnabled() ? styles.buttonDisabled : null
          }`}
          onClick={() => {
            if (!dirty && onRightButtonClick) {
              onRightButtonClick(date);
            } else if (dirty && onDirtyRightButtonClick) {
              onDirtyRightButtonClick(date);
            }
            setScheduled(true);
          }}
        >
          {dirty ? 'Update' : rightButtonLabel}
        </div>
      </div>
    </div>
  );
};
