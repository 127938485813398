import addQuestionBranch from '../../addQuestionBranch/addQuestionBranch';
import updateQuestionBranch from '../../updateQuestionBranch/updateQuestionBranch';

import { QUOTA_UNITS } from '../../../../helpers/constants';

export default (newState, destinationQuestionId) => {
  const { newGroupId } = newState;

  // Add new paths to the distributor
  const newModifiedState = addQuestionBranch(
    newState.newState,
    destinationQuestionId
  );

  const distributorQuestion =
    newModifiedState &&
    newModifiedState.newState &&
    newModifiedState.newState.surveyLists.find(
      sL => sL.id === destinationQuestionId
    );

  if (
    distributorQuestion &&
    distributorQuestion.branches &&
    distributorQuestion.branches.length > 0
  ) {
    // Point new branch to the duplicated group
    const newBranch = {
      ...distributorQuestion.branches[distributorQuestion.branches.length - 1],
      quotaType: QUOTA_UNITS.ABSOLUTE,
      quotaAmount: newModifiedState.newState.survey.maxResponses || 0,
      nextFlow: newGroupId
    };

    return updateQuestionBranch(
      newModifiedState.newState,
      destinationQuestionId,
      newBranch,
      true
    );
  }

  return null;
};
