import React from 'react';

import { FILTER_RANGE_LABELS } from '../../../../../../helpers/constants';

import styles from './RangeSelector.module.css';

export default ({ name, availableRanges, value, onClick }) => (
  <div className={styles.container}>
    {availableRanges.map((r, i) => (
      <div
        className={
          r === value ? `${styles.range} ${styles.active}` : styles.range
        }
        key={`available-ranges-${name}-${i.toString()}`}
        role="presentation"
        onClick={() => onClick(r)}
      >
        {FILTER_RANGE_LABELS[r]}
      </div>
    ))}
  </div>
);
