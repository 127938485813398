import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import style from './BarChart.module.css';

export default props => {
  const { tooltipContainerRef, parent, data, isPercentage } = props;

  const [svg, setSvg] = useState(null);

  useEffect(
    () => {
      if (parent && parent.current) {
        drawChart(svg, props);
      }
    },
    [svg, data]
  );

  useEffect(
    () => {
      drawChart(svg, props);
    },
    [parent.current]
  );

  const drawChart = (svgRef, chartProps) => {
    const parentContainer = parent.current;
    const tooltipContainer = tooltipContainerRef.current;

    if (
      parentContainer &&
      tooltipContainer &&
      parentContainer.offsetWidth &&
      data &&
      data.length
    ) {
      const { height } = chartProps;

      const margins = {
        top: 20,
        right: 25,
        rightPadding: 5,
        bottom: 30,
        left: 50
      };

      const barWidth = 35;

      const width = parentContainer.offsetWidth;

      const maxYTicks = data.length > 5 ? 5 : data.length;

      const svgContainer = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      svgContainer.selectAll('*').remove();

      const chartSvg = svgContainer
        .append('g')
        .attr('width', width - margins.left - margins.right)
        .attr('height', height - margins.top - margins.bottom)
        .attr('transform', `translate(${margins.left}, ${margins.top})`);

      const maxValue = Math.max(...data.map(o => o.yValue), 0);
      const maxPreviousValue = Math.max(...data.map(o => o.yPreviousValue), 0);

      // Scales
      const yScale = d3
        .scaleLinear()
        .domain([0, maxValue + maxPreviousValue])
        .range([height - margins.top - margins.bottom, 0]);

      const xScale = d3
        .scaleLinear()
        .domain([0, data.length - 1])
        .range([
          0,
          width -
            margins.left -
            margins.right -
            margins.rightPadding -
            barWidth / 2
        ]);

      // Y axis
      // Gridlines
      const gridGroup = chartSvg
        .append('g')
        .attr('class', style.gridLines)
        .attr('transform', `translate(0, 0)`);

      // Y gridlines
      const yGridlines = d3
        .axisLeft()
        .tickFormat('')
        .tickSize(-width)
        .ticks(maxYTicks)
        .scale(yScale);
      gridGroup.append('g').call(yGridlines);

      // Y Axis
      chartSvg
        .append('g')
        .attr('class', style.yAxis)
        .call(
          d3
            .axisLeft(yScale)
            .ticks(maxYTicks)
            .tickSizeOuter(0)
            .tickFormat(d => {
              if (d === 0) return '';
              return d;
            })
        );

      // X Axis
      chartSvg
        .append('g')
        .call(
          d3
            .axisBottom(xScale)
            .tickSizeOuter(0)
            .tickFormat(d => {
              if (data && data[d]) {
                return data[d].xValue;
              }
              return '';
            })
        )
        .attr('class', style.xAxis)
        .attr(
          'transform',
          `translate(${barWidth / 2}, ${height - margins.bottom - margins.top})`
        );

      // Additional lines
      chartSvg
        .append('line')
        .style('stroke', 'black')
        .style('stroke-width', 1)
        .attr('x1', -20)
        .attr('y1', height - margins.bottom - margins.top + 0.35)
        .attr('x2', barWidth / 2 + 3)
        .attr('y2', height - margins.bottom - margins.top + 0.35);
      chartSvg
        .append('line')
        .style('stroke', 'black')
        .style('stroke-width', 1)
        .attr('x1', width - margins.right - barWidth - 50)
        .attr('y1', height - margins.bottom - margins.top + 0.35)
        .attr('x2', width)
        .attr('y2', height - margins.bottom - margins.top + 0.35);

      // Main line
      const chartValueSvg = chartSvg
        .append('g')
        .attr('class', style.valueCircle);

      const tooltipDiv = d3.select(tooltipContainer).style('display', 'none');

      // Previous value
      chartValueSvg
        .selectAll('.previousVValueBar')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', (d, i) => xScale(i))
        .attr('y', d => yScale(d.yPreviousValue))
        .attr(
          'height',
          d => height - yScale(d.yPreviousValue) - margins.bottom - margins.top
        )
        .attr('width', barWidth)
        .style('fill', '#5200f1')
        .on('mousemove', function(d) {
          d3.select(this).style('fill', '#7c3bff');
          tooltipDiv.style('display', 'block');

          tooltipDiv
            .html(
              `<strong>${d.yPreviousValue}${isPercentage ? '%' : ''}</strong>`
            )
            .style('left', `${d3.event.pageX + 5}px`)
            .style('top', `${d3.event.pageY - window.scrollY - 20}px`)
            .style('min-width', 'auto');
        })
        .on('mouseout', function() {
          d3.select(this).style('fill', '#5200f1');
          tooltipDiv.style('display', 'none');
        });

      // Value
      chartValueSvg
        .selectAll('.valueBar')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', (d, i) => xScale(i))
        .attr('y', d => {
          const rectHeight =
            height - yScale(d.yPreviousValue) - margins.bottom - margins.top;
          if (rectHeight < 0) return 0;
          return yScale(d.yValue) - rectHeight;
        })
        .attr('height', d => {
          const rectHeight =
            height - yScale(d.yValue) - margins.bottom - margins.top;
          if (rectHeight < 0) return 0;
          return rectHeight;
        })
        .attr('width', barWidth)
        .style('fill', '#9f94ed')
        .on('mousemove', function(d) {
          d3.select(this).style('fill', '#7c3bff');
          tooltipDiv.style('display', 'block');

          tooltipDiv
            .html(`<strong>${d.yValue}${isPercentage ? '%' : ''}</strong>`)
            .style('left', `${d3.event.pageX + 5}px`)
            .style('top', `${d3.event.pageY - window.scrollY - 20}px`)
            .style('min-width', 'auto');
        })
        .on('mouseout', function() {
          d3.select(this).style('fill', '#9f94ed');
          tooltipDiv.style('display', 'none');
        });

      // Text value
      chartValueSvg
        .selectAll('.valueText')
        .data(data)
        .enter()
        .append('text')
        .attr('x', (d, i) => xScale(i) + barWidth / 2)
        .attr(
          'y',
          d =>
            +yScale(d.yValue) -
            height +
            yScale(d.yPreviousValue) +
            margins.bottom +
            margins.top -
            5
        )
        .text(d => d.yPreviousValue + d.yValue)
        .style('font-size', '12px')
        .style('text-anchor', 'middle');
    }

    return null;
  };

  return (
    <div>
      <svg ref={elem => setSvg(elem)} />
    </div>
  );
};
