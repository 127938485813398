import {
  TEMPLATES_WIZARD_TEMPLATE_TYPES,
  SURVEY_LANGUAGES
} from '../../../../../helpers/constants';

import enStrings from '../../../../../components/TemplatesWizard/strings/en';
import nlStrings from '../../../../../components/TemplatesWizard/strings/nl';
import frStrings from '../../../../../components/TemplatesWizard/strings/fr';

export default (
  newQuestions,
  xQuestionLabel,
  yQuestionLabel,
  templateType,
  pointsStep,
  language
) => {
  let strings;
  switch (language) {
    case SURVEY_LANGUAGES.ENGLISH:
      strings = enStrings;
      break;
    case SURVEY_LANGUAGES.DUTCH:
      strings = nlStrings;
      break;
    case SURVEY_LANGUAGES.FRENCH:
      strings = frStrings;
      break;
    default:
      strings = enStrings;
  }

  const features = [];
  for (let i = 2; i < newQuestions.length; i += 2) {
    const pointsStepLabel = pointsStep[features.length];
    const description =
      templateType === TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
        ? `${strings[
            TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
          ].SITUATION.replace('...', '')} ${pointsStepLabel}`
        : pointsStepLabel;

    features.push({
      active: true,
      description,
      [xQuestionLabel]: newQuestions[i].id,
      [yQuestionLabel]: newQuestions[i + 1].id
    });
  }

  return features;
};
