import React from 'react';

import ColorPicker from '../../../../../../../surveys/components/SurveyBuilderPage/SurveyOverview/components/ColorPicker/ColorPicker';

import styles from './Branding.module.css';

export default ({
  updateGraphMutation,
  graphId,
  themeColors,
  setThemeColors,
  features,
  setFeatures,
  defaultDataPointColors,
  surveyId,
  dataPointColors,
  setDataPointColors,
  selectedExtremeUsersFeature,
  setSelectedExtremeUsersFeature,
  questionTypes,
  isAllowedToExecuteRequests,
  viewToken
}) => {
  const onUpdateDataPointColor = async (f, i, colorCode) => {
    if (colorCode.length === 7 && colorCode.charAt(0) === '#') {
      // Update selected Lovers Haters data point in case it's color changed
      if (
        selectedExtremeUsersFeature &&
        selectedExtremeUsersFeature[questionTypes.xQuestion] ===
          f[questionTypes.xQuestion] &&
        selectedExtremeUsersFeature[questionTypes.yQuestion] ===
          f[questionTypes.yQuestion] &&
        selectedExtremeUsersFeature.description === f.description
      ) {
        setSelectedExtremeUsersFeature({ ...f, color: colorCode, colorCode });
      }
      let updatedFeatures = [...features].map(feature => ({ ...feature }));

      updatedFeatures[i] = {
        ...f,
        colorCode
      };

      setFeatures(updatedFeatures);

      updatedFeatures = updatedFeatures.map(iF => {
        const innerFeature = iF;

        delete innerFeature.__typename;
        return innerFeature;
      });

      // eslint-disable-next-line
      dataPointColors[i] = colorCode;
      setDataPointColors([...dataPointColors]);

      if (isAllowedToExecuteRequests) {
        const result = await updateGraphMutation({
          variables: {
            id: graphId,
            survey: surveyId,
            features: updatedFeatures,
            ...(viewToken ? { viewToken } : {})
          }
        });

        if (
          result &&
          result.data &&
          result.data.updateIdeaSelectionGraph &&
          result.data.updateIdeaSelectionGraph.errors &&
          result.data.updateIdeaSelectionGraph.errors.length
        ) {
          // setError('Failed to update Idea selection graph');
        }
      }
    }
  };

  const onUpdateCustomThemeColor = async (cTT, i, colorCode) => {
    if (colorCode.length === 7 && colorCode.charAt(0) === '#') {
      const updatedThemeColors = themeColors.find(tC => tC.name === cTT.name);
      updatedThemeColors.colorCode = colorCode;

      const customThemeColorsRequest = themeColors.map(uC => {
        const iUC = uC;
        delete iUC.__typename;
        return iUC;
      });

      // Just to prevent need to refresh page
      setThemeColors([...themeColors]);

      if (isAllowedToExecuteRequests) {
        const result = await updateGraphMutation({
          variables: {
            id: graphId,
            survey: surveyId,
            customThemeColors: customThemeColorsRequest,
            ...(viewToken ? { viewToken } : {})
          }
        });

        if (
          result &&
          result.data &&
          result.data.updateIdeaSelectionGraph &&
          result.data.updateIdeaSelectionGraph.errors &&
          result.data.updateIdeaSelectionGraph.errors.length
        ) {
          // setError('Failed to update Idea selection graph');
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.explanation}>Graph</div>
        <div className={styles.colorPickersContainer}>
          {themeColors.map((cTT, i) => (
            <div
              className={styles.colorPicker}
              key={`color-picker-${i.toString()}`}
            >
              <ColorPicker
                colorCode={cTT.colorCode}
                onChange={colorCode =>
                  onUpdateCustomThemeColor(cTT, i, colorCode)
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.explanation}>Data points</div>
        <div className={styles.colorPickersContainer}>
          {features.map((f, i) => (
            <div
              className={styles.colorPicker}
              key={`color-picker-dp-${i.toString()}`}
            >
              <ColorPicker
                colorCode={f.colorCode || defaultDataPointColors[i]}
                onChange={colorCode => onUpdateDataPointColor(f, i, colorCode)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
