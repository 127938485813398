/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_COMMUNITIES = gql`
  query GetCommunities(
    $page: PositiveInt!
    $search: String
    $recordsPerPage: PositiveInt!
  ) {
    getCommunities(
      input: { page: $page, search: $search, recordsPerPage: $recordsPerPage }
    ) {
      totalCount
      records {
        id
        name
        type
        createdAt
        profilingTag {
          category
          tagName
          tagValue
        }
      }
    }
  }
`;

export const GET_COMMUNITY = gql`
  query GetCommunity($id: ID!, $page: PositiveInt) {
    getCommunity(input: { id: $id, page: $page }) {
      community {
        id
        name
        type
        createdAt
        filterRelation
        appliedFilters {
          type
          tags {
            category
            tagName
            tagValue
          }
          userProperties {
            propertyName
            propertyValue
          }
        }
      }
      totalCount
      users {
        id
        email
        createdAt
        convertedFromSurvey
        allSurveysVisible
      }
      onboardingSurveys {
        id
        projectName
      }
    }
  }
`;

export const CREATE_COMMUNITY = gql`
  mutation createCommunity(
    $name: String!
    $type: CommunityManagementType!
    $appliedFilters: [UserFilterInput]
    $filterRelation: String
  ) {
    createCommunity(
      input: {
        name: $name
        type: $type
        appliedFilters: $appliedFilters
        filterRelation: $filterRelation
      }
    ) {
      community {
        id
      }
      done
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_COMMUNITY = gql`
  mutation UpdateCommunity(
    $id: ID!
    $name: String!
    $appliedFilters: [UserFilterInput]
    $filterRelation: String
    $removedUsers: [ID]
  ) {
    updateCommunity(
      input: {
        id: $id
        name: $name
        appliedFilters: $appliedFilters
        filterRelation: $filterRelation
        removedUsers: $removedUsers
      }
    ) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const REMOVE_COMMUNITY = gql`
  mutation RemoveCommunity($id: ID!) {
    removeCommunity(input: { id: $id }) {
      done
      errors {
        field
        message
      }
    }
  }
`;
