const getFlows = (content, flows, group, nestingLevel = 0) => {
  const groupsList = content.groups;

  return flows.reduce((dict, el) => {
    if (groupsList.some(g => g.id === el)) {
      const g = groupsList.find(gL => gL.id === el); // Check if el is a group

      if (nestingLevel === 1) {
        const parentGroup = groupsList.find(pG => pG.flows.indexOf(el) > -1);
        if (parentGroup) {
          g.parentGroup = JSON.parse(
            JSON.stringify({ ...parentGroup, parent: true, nestingLevel: 1 })
          );
        }
      }

      return [...dict, ...getFlows(content, g.flows, g, nestingLevel + 1)];
    }

    return [...dict, el];
  }, []);
};

export default c => {
  if (!c || Object.values(c).length === 0) return [];

  const content = JSON.parse(JSON.stringify(c));

  return getFlows(content, content.flows);
};
