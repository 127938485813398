import React from 'react';

import Icon from '../../../../../../../../../Icon/Icon';

import { QUOTA_UNITS } from '../../../../../../../../../../helpers/constants';

import styles from './AddPath.module.css';

export default ({ question, actions, maxSurveyResponses }) => (
  <div className={styles.container}>
    Add a path
    <Icon
      type="add"
      onClick={e => {
        actions.addQuestionBranch(question.id, {
          quotaType: QUOTA_UNITS.ABSOLUTE,
          quotaAmount: maxSurveyResponses || 0
        });

        e.stopPropagation();
      }}
      className={styles.addPathIcon}
    />
  </div>
);
