import React, { useContext } from 'react';

import { Droppable } from 'react-beautiful-dnd';
import SurveyBuilderContext from '../../SurveyBuilderContext';

import styles from './QuestionColumn.module.css';

import { getQuestionsColumn } from '../../helpers/getQuestionsColumn/getQuestionsColumn';

export default () => {
  const { state } = useContext(SurveyBuilderContext);
  const { survey, surveyLists } = state;
  const { content, questions } = survey;

  if (!questions || Object.values(questions).length === 0) return null;

  return (
    <div className={styles.container}>
      {getQuestionsColumn(true, content, questions)}
      <Droppable droppableId="add-new-question">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div className={styles.dropdownPlaceholderContainer}>
              {surveyLists && surveyLists.length ? (
                <div className={styles.dropdownPlaceholder}>
                  Drag and drop a new question here
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};
