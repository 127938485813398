import React from 'react';

import styles from './AcquisitionMetricsList.module.css';

export default ({ loading = true, acqusitionStatistics }) => {
  const metricsList = [
    {
      name: '# campaign link clicks or scans',
      total:
        (acqusitionStatistics && acqusitionStatistics.campaignLinkClickScan) ||
        0,
      conversionRate:
        (acqusitionStatistics &&
          acqusitionStatistics.campaignLinkClickScanConversionRate) ||
        0
    },
    {
      name: '# users opening the app for the first time',
      total:
        (acqusitionStatistics && acqusitionStatistics.userOpenAppFirstTime) ||
        0,
      conversionRate:
        (acqusitionStatistics &&
          acqusitionStatistics.userOpenAppFirstTimeConversionRate) ||
        0
    },
    {
      name: '# users registering',
      total:
        (acqusitionStatistics && acqusitionStatistics.userRegistering) || 0,
      conversionRate:
        (acqusitionStatistics &&
          acqusitionStatistics.userRegisteringConversionRate) ||
        0
    },
    {
      name: '# users accepting push notifications',
      total: (acqusitionStatistics && acqusitionStatistics.userAcceptPN) || 0,
      conversionRate:
        (acqusitionStatistics &&
          acqusitionStatistics.userAcceptPNConversionRate) ||
        0
    },
    {
      name: '# users start first onboarding survey',
      total:
        (acqusitionStatistics &&
          acqusitionStatistics.userStartFirstOnboarding) ||
        0,
      conversionRate:
        (acqusitionStatistics &&
          acqusitionStatistics.userStartFirstOnboardingConversionRate) ||
        0
    },
    {
      name: '# users finish last onboarding survey',
      total:
        (acqusitionStatistics &&
          acqusitionStatistics.userFinishLastOnboarding) ||
        0,
      conversionRate:
        (acqusitionStatistics &&
          acqusitionStatistics.userFinishLastOnboardingConversionRate) ||
        0
    },
    {
      name: '# users finish first non-onboarding survey',
      total:
        (acqusitionStatistics &&
          acqusitionStatistics.userFinishFirstNonOnboarding) ||
        0,
      conversionRate:
        (acqusitionStatistics &&
          acqusitionStatistics.userFinishFirstNonOnboardingConversionRate) ||
        0
    }
  ];

  return (
    <>
      {loading ? (
        <div className={`${styles.listContainer} list-container`}>
          <div className="loader-container">
            <div className={`${styles.loadingItem} item`} />
            <div className={`${styles.loadingItem} item`} />
            <div className={`${styles.loadingItem} item`} />
            <div className={`${styles.loadingItem} item`} />
          </div>
        </div>
      ) : null}
      {!loading ? (
        <table border="0" className={styles.tableContainer}>
          <thead>
            <tr className={styles.headerRow}>
              <th>Metric</th>
              <th width="10%" className={styles.rightAlignValues}>
                Total
              </th>
              <th width="15%" className={styles.rightAlignValues}>
                Conversion Rate %
              </th>
            </tr>
          </thead>
          <tbody>
            {metricsList &&
              metricsList.map((mL, mLi) => (
                <tr
                  className={styles.tableRow}
                  key={`metrics-list-${mLi.toString()}`}
                >
                  <td>{mL.name}</td>
                  <td className={styles.rightAlignValues}>{mL.total}</td>
                  <td className={styles.rightAlignValues}>
                    {(mL.conversionRate * 100).toFixed(0)}%
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : null}
    </>
  );
};
