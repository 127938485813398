import React, { useState, useEffect } from 'react';

import useDebounce from '../../../../../../campaigns/components/DebounceInput/debounceHook';

import styles from './TotalSampleSize.module.css';

export default props => {
  const {
    totalSampleSize,
    setTotalSampleSize,
    setTotalSampleSizeLoading
  } = props;

  const [sampleSize, setSampleSize] = useState(totalSampleSize);
  const debouncedInputValue = useDebounce(sampleSize, 500);

  useEffect(
    () => {
      setTotalSampleSizeLoading(false);
      setTotalSampleSize(parseInt(sampleSize || 0, 10));
    },
    [debouncedInputValue]
  );

  return (
    <div className={styles.container}>
      Total sample size =
      <input
        className={styles.input}
        type="number"
        value={sampleSize}
        onKeyDown={evt =>
          evt.key === 'ArrowDown' ||
          evt.key === 'ArrowUp' ||
          evt.key === 'e' ||
          evt.key === 'E' ||
          evt.key === '.' ||
          evt.key === ',' ||
          evt.key === '-' ||
          evt.key === '+'
            ? evt.preventDefault()
            : null
        }
        onChange={e => {
          setTotalSampleSizeLoading(true);
          setSampleSize(e.target.value);
        }}
        onWheel={e => e.target.blur()}
      />
    </div>
  );
};
