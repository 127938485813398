import React from 'react';
import styles from './ConfirmInput.module.css';

import tick from '../../assets/img/white-tick.svg';

export default props => {
  const { name, label, onClick, active } = props;

  return (
    <div
      role="presentation"
      className={
        active
          ? `${styles.confirmInputContainer} ${
              styles.confirmInputContainerActive
            } accept-input-${name}`
          : `${styles.confirmInputContainer} accept-input-${name}`
      }
      onClick={() => onClick()}
    >
      <div
        className={styles.checkmark}
        style={active ? { backgroundImage: `url()` } : null}
      >
        {active ? <img src={tick} alt="Tick" className={styles.tick} /> : null}
      </div>
      <span>{label}</span>
    </div>
  );
};
