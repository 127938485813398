import React from 'react';

import styles from './Legend.module.css';

export default ({ themeColors }) => {
  const themeColor = themeColors.reduce(
    (acc, tC) => ({
      ...acc,
      [tC.name]: tC.colorCode
    }),
    {}
  );

  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_2
            }}
          />
          <div>
            Values in this zone create value &amp; are essential to your user.{' '}
            <span className={styles.bold}>
              These values should be incorporated in your (basic) solution
            </span>
            . Often they create a competitive edge &amp; justify premium
            pricing. In a market leadership / growth strategy you might want to
            look for winner values with value scores higher than 8.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_4
            }}
          />
          <div>
            Values in this zone are valuable but not essential.{' '}
            <span className={styles.bold}>
              These values should be included, but probably not as part of your
              minimum viable product (MVP) or basic package
            </span>
            . They are often suited to include in a top tier or to use in your
            marketing, especially if the value score is higher than 8.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_1
            }}
          />{' '}
          <div>
            Values in this zone are essential to include in your solution, but
            are not creating additional value. Often these are{' '}
            <span className={styles.bold}>
              market standard values you should include in your MVP or basic
              package for normal use
            </span>
            , especially if they get an essentialness score higher than 8.
            However you shouldn’t charge premium prices, neither focus your
            marketing efforts on these values.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_3
            }}
          />
          <div>
            Values in this zone are neither valuable, nor essential to include.
            <br />
            <span className={styles.bold}>
              Don’t waste your resources on these values.
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: '1px solid rgba(255,255,255,0)',
              backgroundColor: 'rgba(255,255,255,0)'
            }}
          />
          <div className={styles.dots}>...</div>
          <div>Lovers benchmark</div>
        </div>
      </div>
    </div>
  );
};
