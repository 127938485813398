import { Component } from 'react';
/* global document, window */

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    const nodeElement = node;
    const { positionStyle, handlePosition = true } = this.props;
    if (handlePosition && nodeElement) {
      const footerHeight = 90;
      const height = nodeElement.offsetHeight;
      const rect = nodeElement.getBoundingClientRect();
      const win = nodeElement.ownerDocument.defaultView;
      const viewpointHeight = window.innerHeight;

      const absolutePosition = {
        top: rect.top + win.pageYOffset - window.scrollY,
        left: rect.left + win.pageXOffset
      };

      if (
        absolutePosition &&
        absolutePosition.top &&
        absolutePosition.left &&
        height
      ) {
        if (absolutePosition.top + height + footerHeight > viewpointHeight) {
          nodeElement.style.top = 'auto';

          if (positionStyle && positionStyle.bottomPosition) {
            nodeElement.style.bottom = positionStyle.bottomPosition;
          } else {
            nodeElement.style.bottom = '10px';
          }

          if (height - absolutePosition.top > 0) {
            nodeElement.style.height = `${absolutePosition.top - 20}px`;
            nodeElement.style.overflowY = 'auto';
          }
        }
      }
    }
    this.wrapperRef = nodeElement;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const { onBlur } = this.props;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      onBlur();
    }
  }

  render() {
    return null;
  }
}

Dropdown.propTypes = {
  // children: PropTypes.element.isRequired,
};

export default Dropdown;
