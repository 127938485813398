import React from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

import './Draft.css';

const Draft = props => {
  const { blockQuestion, blockType } = props;

  const styleMap = {
    BOLD: {
      fontFamily: 'Roboto Bold'
    }
  };

  // Create state for editor and check if question already has a draft value
  const createEditorState = () => {
    if (
      blockQuestion &&
      (blockQuestion !== undefined || blockQuestion !== '')
    ) {
      const parsedQuestion = JSON.parse(blockQuestion);

      if (
        parsedQuestion &&
        (parsedQuestion.blocks > 1 || parsedQuestion.blocks[0].text !== '')
      ) {
        const contentState = convertFromRaw(parsedQuestion);
        return EditorState.createWithContent(contentState);
      }
    }

    return null;
  };

  const editorState = createEditorState();

  const RenderStyleControls = () => {
    if (editorState) {
      return (
        <Editor
          editorState={editorState}
          readOnly="true"
          customStyleMap={styleMap}
        />
      );
    }
    return blockType;
  };

  return <RenderStyleControls />;
};

export default Draft;
