import React, { useEffect, useState } from 'react';
import useLazyQuery from '../../../../../../../hooks/useLazyQuery';

import Icon from '../../../../../SurveyBuilder/components/Icon/Icon';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import ProfilingQuestionDetails from './components/ProfilingQuestionDetails/ProfilingQuestionDetails';

import isActionAllowed from '../../../../../SurveyBuilder/helpers/isActionAllowed/isActionAllowed';

import { GET_PROFILING_QUESTIONS } from '../../../../../../../../graphql/Question';

import {
  INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS,
  QUESTION_DESIGNER_ACTION,
  QUESTION_TYPES,
  TARGET_AUDIENCE_STEPS
} from '../../../../../SurveyBuilder/helpers/constants';

import styles from './ProfilingQuestionGroup.module.css';

const ObjectID = require('bson-objectid');

export default ({
  groupIndex,
  api,
  actions,
  profilingQuestion, // This is what represets profling question group
  profilingQuestions,
  setProfilingQuestions,
  surveyLanguage,
  setConfirmationPopup,
  targetAudienceStep,
  setShowGetInTouchPopup
}) => {
  const [appliedFilters, setAppliedFilters] = useState({
    language: surveyLanguage || 'Dutch'
  });
  const [
    availableProfilingQuestionsToChoose,
    setAvailableProfilingQuestionsToChoose
  ] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scrollContainerRef, setScrollContainerRef] = useState(null);

  const loadQuestionsQuery = useLazyQuery(GET_PROFILING_QUESTIONS, {
    fetchPolicy: 'no-cache'
  });

  const loadQuestions = async () => {
    setLoading(true);

    const variables = {
      start: 0,
      end: 30,
      showOnlyVisibleForSearch: true,
      salt: Math.random().toString(),
      ...appliedFilters
    };

    if (profilingQuestion && profilingQuestion.id) {
      variables.forceShowQuestionIds = [profilingQuestion.profiling.id];
    }

    const loadedAvailableProfilingQuestions = await loadQuestionsQuery({
      variables
    });

    if (
      loadedAvailableProfilingQuestions &&
      loadedAvailableProfilingQuestions.data &&
      loadedAvailableProfilingQuestions.data.getProfilingQuestions &&
      loadedAvailableProfilingQuestions.data.getProfilingQuestions.questions &&
      loadedAvailableProfilingQuestions.data.getProfilingQuestions.questions
        .length
    ) {
      setAvailableProfilingQuestionsToChoose(
        loadedAvailableProfilingQuestions.data.getProfilingQuestions.questions
      );
    }

    setLoading(false);
  };

  useEffect(
    () => {
      if (
        appliedFilters.category ||
        appliedFilters.tagName ||
        appliedFilters.tagValue
      ) {
        loadQuestions();
      } else {
        setAvailableProfilingQuestionsToChoose(null);
      }
    },
    [appliedFilters]
  );

  useEffect(
    () => {
      if (profilingQuestion) {
        if (
          profilingQuestion &&
          profilingQuestion.profiling &&
          profilingQuestion.profiling.reuseQuestionSearchSelection
        ) {
          const newAppliedFilters = { ...appliedFilters };
          const reuseFilterSelections =
            profilingQuestion.profiling.reuseQuestionSearchSelection;
          if (reuseFilterSelections && reuseFilterSelections.category) {
            newAppliedFilters.category = reuseFilterSelections.category;
          }
          if (reuseFilterSelections && reuseFilterSelections.tagName) {
            newAppliedFilters.tagName = reuseFilterSelections.tagName;
          }
          if (reuseFilterSelections && reuseFilterSelections.tagValue) {
            newAppliedFilters.tagValue = reuseFilterSelections.tagValue;
          }

          setAppliedFilters(newAppliedFilters);
        }
      }
    },
    [profilingQuestion.id]
  );

  const searchCategories = async (page, search) => {
    const preparedAppliedFilters = { ...appliedFilters };
    delete preparedAppliedFilters.category;

    const searchResults = await api('searchProfilingCategories', {
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        showOnlyVisibleForSearch: true,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingCategories &&
      searchResults.data.searchProfilingCategories.options
      ? searchResults.data.searchProfilingCategories.options
      : [];
  };

  const searchProfilingTagNames = async (page, search) => {
    const preparedAppliedFilters = { ...appliedFilters };
    delete preparedAppliedFilters.tagName;

    const searchResults = await api('searchProfilingTagNames', {
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        showOnlyVisibleForSearch: true,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagNames &&
      searchResults.data.searchProfilingTagNames.options
      ? searchResults.data.searchProfilingTagNames.options
      : [];
  };

  const searchProfilingTagValues = async (page, search) => {
    const preparedAppliedFilters = { ...appliedFilters };
    delete preparedAppliedFilters.tagValue;

    const searchResults = await api('searchProfilingTagValues', {
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        showOnlyVisibleForSearch: true,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagValues &&
      searchResults.data.searchProfilingTagValues.options
      ? searchResults.data.searchProfilingTagValues.options
      : [];
  };

  const isNotEligiblePage =
    targetAudienceStep === TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS;

  const onDeleteQuestionRequested = removeQuestionGroup => {
    const isAllowed = isActionAllowed(
      QUESTION_DESIGNER_ACTION.QUESTION_DELETE,
      profilingQuestion,
      null,
      profilingQuestions
    );

    if (isAllowed && isAllowed.allowed) {
      deleteQuestion(removeQuestionGroup);
    } else {
      const confirmationPopup = {
        onClick: () => setConfirmationPopup(false),
        onClose: () => setConfirmationPopup(false),
        description:
          'You cannot delete this profiling question because there are logic jumps attached to it. Please remove these jumps in the survey builder and then try again.'
      };
      setConfirmationPopup(confirmationPopup);
    }
  };

  const deleteQuestion = removeQuestionGroup => {
    let newProfilingQuestions = [...profilingQuestions];

    if (profilingQuestions && profilingQuestions.length === 1) {
      newProfilingQuestions = [{}];

      if (removeQuestionGroup) {
        setAppliedFilters({ language: surveyLanguage || 'Dutch' });
      }
    } else {
      if (removeQuestionGroup) {
        newProfilingQuestions.splice(groupIndex, 1);
      }
      if (!removeQuestionGroup) {
        newProfilingQuestions[groupIndex] = {};
      }
    }

    setProfilingQuestions(newProfilingQuestions);

    if (profilingQuestion && profilingQuestion.id) {
      actions.deleteQuestion(profilingQuestion.id);
    }
  };

  const onDropdownOptionSelectCompleted = (property, value) => {
    if (value) {
      setAppliedFilters({
        ...appliedFilters,
        [property]: value
      });
    } else {
      const filteredAppliedFilters = {
        ...appliedFilters,
        [property]: null
      };
      setAppliedFilters(filteredAppliedFilters);
    }
  };

  const onSelectAvailableProfilingQuestionClick = availableProfilingQuestionToChoose => {
    if (availableProfilingQuestionToChoose) {
      const reuseQuestionSearchSelection = {
        category: appliedFilters.category,
        tagName: appliedFilters.tagName,
        tagValue: appliedFilters.tagValue
      };

      const newQuestionId = ObjectID().toString(); // Question of a question in a survey

      const newQuestionFromReusedQuestion = {
        ...availableProfilingQuestionToChoose,
        id: newQuestionId, // Id of a question in a survey
        type: QUESTION_TYPES.MULTIPLE_CHOICE,
        profiling: {
          ...availableProfilingQuestionToChoose.profiling,
          id: availableProfilingQuestionToChoose.id,
          isReusedProfilingQuestion: true,
          visibleForSearch: false,
          answerCollected: false,
          reuseQuestionSearchSelection
        },
        incidenceRate: {
          profilingQuestionCorrectionApplied: false,
          dropoutValueEstimation: INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS.HIGH
        }
      };

      // Clean question from __typename
      delete newQuestionFromReusedQuestion.__typename;
      delete newQuestionFromReusedQuestion.createdAt;

      if (
        newQuestionFromReusedQuestion &&
        newQuestionFromReusedQuestion.nextFlow
      ) {
        delete newQuestionFromReusedQuestion.nextFlow;
      }

      newQuestionFromReusedQuestion.choices =
        newQuestionFromReusedQuestion.choices &&
        newQuestionFromReusedQuestion.choices.map(c => {
          const newC = c;
          if (c && c.__typename) delete newC.__typename;
          if (c && c.profilingTag && c.profilingTag.__typename)
            delete newC.profilingTag.__typename;
          if (c && c.nextFlow) delete newC.nextFlow;

          newC.id = ObjectID().toString();

          return newC;
        });

      if (newQuestionFromReusedQuestion.profiling) {
        delete newQuestionFromReusedQuestion.profiling.__typename;
      }

      if (!profilingQuestion.id) {
        let previousProfilingQuestionGroup = null;
        for (let i = groupIndex; i >= 0; i -= 1) {
          if (
            profilingQuestions[i] &&
            Object.keys(profilingQuestions[i]).length
          ) {
            previousProfilingQuestionGroup = profilingQuestions[i];
            break;
          }
        }

        if (!previousProfilingQuestionGroup) {
          actions.addProfilingQuestion(
            newQuestionFromReusedQuestion,
            null,
            null,
            newQuestionId
          );
        }
        if (
          previousProfilingQuestionGroup &&
          previousProfilingQuestionGroup.id
        ) {
          actions.addProfilingQuestion(
            newQuestionFromReusedQuestion,
            'AFTER',
            previousProfilingQuestionGroup.id,
            newQuestionId
          );
        }

        const newProfilingQuestions = [...profilingQuestions];
        newProfilingQuestions[groupIndex] = newQuestionFromReusedQuestion;
        setProfilingQuestions(newProfilingQuestions);
      } else if (
        profilingQuestion &&
        profilingQuestion.profiling &&
        availableProfilingQuestionToChoose.id === profilingQuestion.profiling.id
      ) {
        onDeleteQuestionRequested(false);
      }
    }
  };

  const availableProfilingQuestions =
    targetAudienceStep === TARGET_AUDIENCE_STEPS.PROFILING_QUESTIONS
      ? availableProfilingQuestionsToChoose
      : availableProfilingQuestionsToChoose &&
        availableProfilingQuestionsToChoose.filter(
          q =>
            profilingQuestion.profiling &&
            q.id === profilingQuestion.profiling.id
        );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <SearchDropdown
            fetchOptions={searchCategories}
            placeholder="Search for a category"
            onSelectCompleted={value =>
              onDropdownOptionSelectCompleted('category', value)
            }
            value={{
              value: appliedFilters.category,
              label: appliedFilters.category
            }}
            disableDropdownExpand={
              targetAudienceStep ===
              TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS
            }
          />
          <SearchDropdown
            fetchOptions={searchProfilingTagNames}
            placeholder="Search for tag name"
            onSelectCompleted={value =>
              onDropdownOptionSelectCompleted('tagName', value)
            }
            value={{
              value: appliedFilters.tagName,
              label: appliedFilters.tagName
            }}
            disableDropdownExpand={
              targetAudienceStep ===
              TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS
            }
          />
          <SearchDropdown
            fetchOptions={searchProfilingTagValues}
            placeholder="Search for tag value"
            onSelectCompleted={value =>
              onDropdownOptionSelectCompleted('tagValue', value)
            }
            value={{
              value: appliedFilters.tagValue,
              label: appliedFilters.tagValue
            }}
            disableDropdownExpand={
              targetAudienceStep ===
              TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS
            }
          />
          {!(
            Object.keys(profilingQuestions[0]).length === 0 && groupIndex === 0
          ) ? (
            <Icon
              type="x"
              className={styles.removeQuestion}
              onClick={() => onDeleteQuestionRequested(true)}
            />
          ) : null}
        </div>
        <div
          className={`${styles.mainContent} ${
            isNotEligiblePage ? styles.isNotEligiblePage : ''
          }`}
        >
          {!loading &&
          !isNotEligiblePage &&
          (availableProfilingQuestionsToChoose &&
            availableProfilingQuestionsToChoose.length) ? (
            <div className={styles.title}>Available questions</div>
          ) : null}
          {!loading &&
          isNotEligiblePage &&
          profilingQuestion &&
          profilingQuestion.id ? (
            <div className={styles.title}>
              Select the answer options that filter respondents out of the
              survey (not eligible)
            </div>
          ) : null}
          <div
            className={
              availableProfilingQuestionsToChoose &&
              availableProfilingQuestionsToChoose.length > 4
                ? `${styles.questionContainer} ${styles.limitHeight}`
                : styles.questionContainer
            }
            ref={r => setScrollContainerRef(r)}
          >
            {!loading &&
              availableProfilingQuestions &&
              availableProfilingQuestions.map(
                (availableProfilingQuestion, i) => (
                  <ProfilingQuestionDetails
                    key={`profiling-group-${groupIndex.toString()}-question-list-${i.toString()}`}
                    groupIndex={groupIndex}
                    actions={actions}
                    availableProfilingQuestion={availableProfilingQuestion}
                    profilingQuestion={profilingQuestion}
                    profilingQuestions={profilingQuestions}
                    setProfilingQuestions={setProfilingQuestions}
                    onSelectAvailableProfilingQuestionClick={
                      onSelectAvailableProfilingQuestionClick
                    }
                    isNotEligiblePage={isNotEligiblePage}
                    scrollContainerRef={scrollContainerRef}
                    targetAudienceStep={targetAudienceStep}
                  />
                )
              )}
            {loading ? (
              <div className={styles.loaderFillContainer}>
                <div className={styles.loadingAnimation}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {availableProfilingQuestionsToChoose &&
      Object.keys(availableProfilingQuestionsToChoose[0]).length &&
      profilingQuestions.length - 1 === groupIndex &&
      !isNotEligiblePage ? (
        <div className={styles.getInTouchContainer}>
          The answer options are fixed for benchmark reasons. Can{`'`}t find
          what you are looking for?{' '}
          <span
            onClick={() => setShowGetInTouchPopup(true)}
            role="presentation"
          >
            Get in touch
          </span>{' '}
          with one of our innovation experts.
        </div>
      ) : null}
    </>
  );
};
