import React, { useRef, useState, useEffect } from 'react';

import leftArrow from '../../../../../../assets/img/arrow-left-grey.svg';
import rightArrow from '../../../../../../assets/img/arrow-right-grey.svg';

import styles from './SurveyTimeline.module.css';

const scrollOffset = 150;

export default ({ steps }) => {
  const scheduleListContainerRef = useRef(null);
  const [stepsCount, setStepsCount] = useState(0);

  useEffect(
    () => {
      if (scheduleListContainerRef.current) {
        setStepsCount(scheduleListContainerRef.current.children.length);
        scheduleListContainerRef.current.scrollLeft =
          scheduleListContainerRef.current.scrollWidth;
      }
    },
    [steps, scheduleListContainerRef.current]
  );

  return (
    <>
      <div className={styles.titleContainer}>Timeline</div>
      <div className={styles.timelineContainer}>
        {stepsCount > 3 && (
          <div className={styles.arrowContainer}>
            <img
              role="presentation"
              src={leftArrow}
              alt="Left"
              className={`${styles.arrowController} ${styles.leftArrow}`}
              onClick={() => {
                scheduleListContainerRef.current.scrollLeft -= scrollOffset;
              }}
            />
          </div>
        )}

        <div className={`${styles.gradient} ${styles.leftGradient}`} />
        <div className={`${styles.gradient} ${styles.rightGradient}`} />

        <div
          className={`${styles.scheduleListContainer} ${stepsCount <= 3 &&
            styles.centeredScheduleListContainer}`}
          ref={scheduleListContainerRef}
          onWheel={e => {
            scheduleListContainerRef.current.scrollLeft += e.deltaX;
          }}
        >
          {stepsCount > 3 && <div style={{ minWidth: '55px' }} />}
          {steps}
          {stepsCount > 3 && <div style={{ minWidth: '55px' }} />}
        </div>

        {stepsCount > 3 && (
          <div className={styles.arrowContainer}>
            <img
              role="presentation"
              src={rightArrow}
              alt="Right"
              className={`${styles.arrowController} ${styles.rightArrow}`}
              onClick={() => {
                scheduleListContainerRef.current.scrollLeft += scrollOffset;
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
