import React from 'react';
import 'react-image-lightbox/style.css'; // This only needs to be imported

const Videos = props => {
  const { results } = props;

  if (results && results.length) {
    return [
      results.map(result => (
        <div
          className="camera-question-image-item"
          key={result._id}
          role="presentation"
        >
          <video
            width="230"
            height="110"
            controls="controls"
            preload="metadata"
          >
            <track kind="captions" {...props} />
            <source
              src={`/uploads/${result.media_filename}`}
              type="video/mp4"
            />
          </video>
        </div>
      ))
    ];
  }

  if (!results || !results.length) {
    return <div className="no-chart-data">No data</div>;
  }

  return null;
};

export default Videos;
