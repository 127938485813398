import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import styles from '../CampaignFilters.module.css';

const DateCampaignFilter = ({
  filter,
  filterOptions,
  date,
  index,
  setCampaignFilters
}) => {
  const dateChange = (field, value) => {
    const dateFilter = filter && filter.date;
    const specifiedDateFilter = filter && filter.date && filter.date[date];
    const newFilter = {
      ...filter,
      date: {
        ...dateFilter,
        [date]: { ...specifiedDateFilter, [field]: { value } }
      }
    };
    setCampaignFilters({ ...filter, ...newFilter });
  };

  const after =
    filter &&
    filter.date &&
    filter.date[date] &&
    filter.date[date].after &&
    filter.date[date].after.value &&
    new Date(filter.date[date].after.value);
  const before =
    filter &&
    filter.date &&
    filter.date[date] &&
    filter.date[date].before &&
    filter.date[date].before.value &&
    new Date(filter.date[date].before.value);

  return (
    <div
      className={styles.dateItemContainer}
      key={`date-item-container-${index.toString()}`}
    >
      <div className={styles.sectionTitle}>
        {filterOptions.date[date].label}
      </div>
      <div className={styles.subSectionContainer}>
        <div className={styles.subSectionItemContainer}>
          <span className={styles.subsectionItemLabel}>After</span>
          <span className={styles.subsectionItemValue}>
            <DayPickerInput
              onDayChange={d => {
                dateChange('after', d);
              }}
              value={after && after !== '' ? after : ' '}
              dayPickerProps={{
                canChangeMonth: true
              }}
            />
          </span>
        </div>
        <div className={styles.subSectionItemContainer}>
          <span className={styles.subsectionItemLabel}>Before</span>
          <span className={styles.subsectionItemValue}>
            <DayPickerInput
              onDayChange={d => {
                dateChange('before', d);
              }}
              value={before && before !== '' ? before : ' '}
              dayPickerProps={{
                canChangeMonth: true
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DateCampaignFilter;
