import React, { useEffect, useRef } from 'react';

import styles from './DropdownPopup.module.css';

const DropdownPopup = ({
  options,
  onSelectCompleted,
  hideDropdownPopup,
  emptyListPlaceholder
}) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target)
      )
    ) {
      hideDropdownPopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className={styles.container}>
      <div className={styles.dropdownPopupItemsContainer}>
        {options && options.length ? (
          options.map(option => (
            <div
              className={styles.dropdownItem}
              key={option.id}
              role="presentation"
              onClick={() => {
                onSelectCompleted(option);
                hideDropdownPopup();
              }}
            >
              {option.color && (
                <span
                  className={styles.circle}
                  style={{ backgroundColor: option.color }}
                />
              )}
              {option.description}
            </div>
          ))
        ) : (
          <div className={styles.noAvailableOptions}>
            {emptyListPlaceholder || 'No available options'}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownPopup;
