/* eslint-disable no-useless-escape */
import { DECISION_FRAMEWORK_TYPES } from '../../../helpers/constants';

export default {
  [DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH]: {
    X_MATRIX_LABEL: 'belangrijk',
    Y_MATRIX_LABEL: 'uitdagend',
    X_MATRIX_QUESTION:
      'Wat vind je van de stelling voor volgende problemen: <b>"Ik vind dit belangrijk"</b>',
    Y_MATRIX_QUESTION:
      'Wat vind je van deze stelling voor volgende problemen: <b>"Ik vind dit uitdagend"</b>'
  },
  [DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'waardevol',
    Y_MATRIX_LABEL: 'uniek',
    X_MATRIX_QUESTION:
      'Wat vind je van deze stelling voor volgende waarden: <b>"Ik vind dit waardevol"</b>',
    Y_MATRIX_QUESTION:
      'Wat vind je van deze stelling voor volgende waarden: <b>"Ik vind dit uniek"</b>'
  },
  [DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'waardevol',
    Y_MATRIX_LABEL: 'een basisvereiste',
    X_MATRIX_QUESTION:
      'Wat vind je van deze stelling voor volgende concepten: <b>"Ik vind dit waardevol"</b>',
    Y_MATRIX_QUESTION:
      'Wat vind je van deze stelling voor volgende concepten: <b>"Ik vind dit een basisvereiste"</b>'
  },
  [DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH]: {
    X_MATRIX_LABEL: 'waardevol',
    Y_MATRIX_LABEL: 'uniek',
    X_MATRIX_QUESTION:
      'Wat vind je van deze stelling voor volgende concepten: <b>"Ik vind dit waardevol"</b>',
    Y_MATRIX_QUESTION:
      'Wat vind je van deze stelling voor volgende concepten: <b>"Ik vind dit uniek"</b>'
  },
  [DECISION_FRAMEWORK_TYPES.CUSTOM_GRAPH]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field empty for this type
    X_MATRIX_QUESTION:
      'Wat vind je van volgende stelling: <b>"Ik vind dit ..."</b>',
    Y_MATRIX_QUESTION:
      'Wat vind je van volgende stelling: <b>"Ik vind dit ..."</b>'
  },
  [DECISION_FRAMEWORK_TYPES.SINGLE]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field not available for this type
    X_MATRIX_QUESTION:
      'Wat vind je van volgende stelling: <b>"Ik vind dit ..."</b>',
    Y_MATRIX_QUESTION: '' // field not available for this type
  }
};
