import React from 'react';

import styles from './SearchDropdown.module.css';
import DropdownSelector from '../DropdownSelector/DropdownSelector';

export default ({
  fetchOptions,
  placeholder,
  onSelectCompleted,
  value,
  search = true,
  hideNoValueOption,
  filterDropdownStyle
}) => (
  <div className={styles.filterItemContainer}>
    <div className={styles.filterType}>
      <DropdownSelector
        value={value}
        onSelectCompleted={onSelectCompleted}
        fetchOptions={fetchOptions}
        placeholder={placeholder}
        search={search}
        filterDropdownStyle={filterDropdownStyle}
        hideNoValueOption={hideNoValueOption}
      />
    </div>
  </div>
);
