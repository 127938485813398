import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';

import introspectionQueryResultData from './fragmentTypes.json';

const backend = localStorage.getItem('backend');

let appUrl;

if (backend === 'local') {
  appUrl = 'http://localhost:8080';
} else if (backend === 'test') {
  appUrl = 'https://test.buffl.be';
} else if (backend === 'prod') {
  appUrl = 'https://buffl-prod-backend.azurewebsites.net';
} else if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test'
) {
  appUrl = 'http://localhost:8080';
} else if (process.env.REACT_APP_ENV === 'qa') {
  appUrl = 'https://test.buffl.be';
} else {
  appUrl = 'https://buffl-prod-backend.azurewebsites.net';
}

const request = async operation => {
  let authToken = localStorage.getItem('@buffl:auth-token');
  // DEPRECATED
  authToken = localStorage.getItem('token');

  if (authToken)
    operation.setContext({
      headers: {
        authorization: `Bearer ${authToken}`
      }
    });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResultData.data
});

export default new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (
          graphQLErrors.some(e => e.extensions && e.extensions.status === 401)
        ) {
          window.location.href = '/clients/auth/login';
        }

        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    new HttpLink({
      uri: `${appUrl}/graphql/clients`,
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache({ fragmentMatcher })
});
