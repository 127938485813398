import React, { useState, useEffect } from 'react';

import FileSaver from 'file-saver';

import TrackingParamFilters from '../TrackingParamFilters/TrackingParamFilters';
import AcquisitionMetricsList from '../AcquisitionMetricsList/AcquisitionMetricsList';

import styles from './AcquisitionMetricsPage.module.css';

import Button from '../../../../campaigns/components/Button/Button';
import AppBar from '../../../../base/components/AppBar/AppBar';
import useLazyQuery from '../../../../surveys/hooks/useLazyQuery';
import { GET_ACQUISITION_METRIC_DETAILS } from '../../../../graphql/AcquisitionMetrics';
import restApi from '../../../../api';

const getTimeframeDates = dates => {
  let startTimeframe;
  let endTimeframe;

  if (dates) {
    startTimeframe = new Date(dates.startTimeframe);
    endTimeframe = new Date(dates.endTimeframe);
  } else {
    startTimeframe = new Date();
    startTimeframe = new Date(
      startTimeframe.setMonth(startTimeframe.getMonth() - 1)
    );

    endTimeframe = new Date();
  }

  return {
    startTimeframe,
    endTimeframe
  };
};

export default ({ setShowCreatePage, pageProps }) => {
  const [appliedFilters, setAppliedFilters] = useState({});
  const [timeframe, setTimeframe] = useState(getTimeframeDates());
  const [dataLoading, setDataLoading] = useState(false);
  const [acqusitionStatistics, setAcqusitionStatistics] = useState(null);

  const getAcquisitionMetricDetails = useLazyQuery(
    GET_ACQUISITION_METRIC_DETAILS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(
    () => {
      const getAcquisitionStatistics = async () => {
        setDataLoading(true);
        const acqusitionStatisticsResponse = await getAcquisitionMetricDetails({
          variables: {
            ...appliedFilters,
            ...timeframe
          }
        });
        setAcqusitionStatistics(
          acqusitionStatisticsResponse.data.getAcquisitionMetricDetails
        );
        setDataLoading(false);
      };

      getAcquisitionStatistics();
    },
    [appliedFilters, timeframe]
  );

  const onExportClick = async () => {
    const res = await restApi().exportAcquisitionMetricStats(
      appliedFilters,
      timeframe
    );

    if (res && res.blob) {
      FileSaver.saveAs(res.blob, `CommunityAcquisitionExport.xlsx`, {
        autoBom: true
      });
    } else {
      window.alert('Something went wrong');
    }
  };

  return (
    <>
      <AppBar {...pageProps} />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Acquisition metrics</div>
          <div className={styles.rightContainer}>
            <div className={styles.buttonContainer}>
              <Button
                role="button"
                label="Generate link"
                onClick={() => setShowCreatePage(true)}
                icon=""
                type="white"
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                role="button"
                label="Export"
                onClick={() => onExportClick()}
                icon=""
              />
            </div>
          </div>
        </div>
        <TrackingParamFilters
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          dataLoading={dataLoading}
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          getTimeframeDates={getTimeframeDates}
        />
        <div className={styles.resultsContainer}>
          <AcquisitionMetricsList
            loading={dataLoading}
            acqusitionStatistics={acqusitionStatistics}
          />
        </div>
      </div>
    </>
  );
};
