export default questions =>
  questions && questions.length
    ? questions.reduce((matrixQuestions, currentQuestion) => {
        let newMatrixQuestions = [...matrixQuestions];
        if (
          currentQuestion.type === 'Matrix' &&
          currentQuestion.matrix &&
          currentQuestion.matrix.xQuestionId &&
          currentQuestion.matrix.yQuestionId
        ) {
          newMatrixQuestions = [
            ...newMatrixQuestions,
            {
              ...currentQuestion,
              id: currentQuestion.matrix.xQuestionId
            },
            {
              ...currentQuestion,
              id: currentQuestion.matrix.yQuestionId
            }
          ];
        }
        return newMatrixQuestions;
      }, [])
    : [];
