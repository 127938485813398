import { connect } from 'react-redux';
import * as actions from '../actions';
import Campaigns from '../components/Campaigns/CampaignsPage/CampaignsPage';

const mapStateToProps = state => ({
  result: state.userCampaigns.result,
  block: state.userCampaigns.block
});

const mapDispatchToProps = dispatch => ({
  setCampaignComponents: (campaign, block, result) =>
    dispatch(actions.setCampaignComponents(campaign, block, result)),
  setUserEmailSuggestion: userEmailSuggestion =>
    dispatch(actions.setUserEmailSuggestion(userEmailSuggestion))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns);
