import React, { useState, useContext, useEffect } from 'react';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import Footer from '../../Footer/Footer';

import arrow from '../../../../../../../../../assets/img/templateswizard/arrow-2.svg';

import {
  TEMPLATES_WIZARD_STEP_KEYS,
  TEMPLATES_WIZARD_TEMPLATE_TYPES
} from '../../../../../helpers/constants';

import styles from './SelectTestsNumber.module.css';

export default () => {
  const {
    steps,
    currentStep,
    setStepValue,
    goToNextStep,
    goToPreviousStep,
    strings,
    resetWizartInputData
  } = useContext(TemplatesWizardContext);
  const [countsOffset, setCountsOffset] = useState(0);

  const templateTypeStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
  );

  let maxCounts = 0;
  switch (templateTypeStep.value) {
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION:
      maxCounts = 6;
      break;
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION:
      maxCounts = 12;
      break;
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION:
      maxCounts = 9;
      break;
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST:
      maxCounts = 4;
      break;
    default:
      maxCounts = 0;
  }

  useEffect(() => {
    if (
      currentStep &&
      currentStep.value > 4 &&
      templateTypeStep.value !== TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST
    ) {
      const newCurrentValue = currentStep.value - 2;
      if (newCurrentValue > maxCounts - 4) {
        setCountsOffset(maxCounts - 3);
      } else {
        setCountsOffset(newCurrentValue);
      }
    }
  }, []);

  const setTestsNumber = (key, value) => {
    setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER, value);

    const definePointsStep = steps.find(
      s => s.key === TEMPLATES_WIZARD_STEP_KEYS.DEFINE_POINTS
    );
    if (!definePointsStep.value || definePointsStep.value.length !== value) {
      resetWizartInputData();
    }
  };

  const countsToShow = maxCounts > 4 ? 4 : maxCounts;

  const templateStrings = strings[templateTypeStep.value];

  return (
    <div className={styles.container}>
      <div className={styles.title}>{templateStrings.HOW_MANY_TO_TEST}</div>
      <div className={styles.optionsWrapper}>
        <div className={styles.arrowLeftContainer}>
          {countsOffset > 0 && (
            <img
              src={arrow}
              alt="Arrow"
              role="presentation"
              onClick={() => {
                if (countsOffset > 0) {
                  setCountsOffset(countsOffset - 1);
                }
              }}
            />
          )}
        </div>
        <div className={styles.optionsContainer}>
          {[...Array(countsToShow).keys()].map(
            o =>
              o + 1 + countsOffset <= maxCounts && (
                <div
                  key={`select-how-many-problems-${o.toString()}`}
                  className={styles.option}
                  onClick={() =>
                    setTestsNumber(
                      TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER,
                      o + 1 + countsOffset
                    )
                  }
                  role="presentation"
                >
                  {o + 1 + countsOffset}
                  {currentStep &&
                    currentStep.value === o + 1 + countsOffset && (
                      <div className={styles.selectedBar} />
                    )}
                </div>
              )
          )}
          {(countsOffset >= maxCounts - 3 || maxCounts === 4) && (
            <div
              className={styles.option}
              onClick={() =>
                setTestsNumber(
                  TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER,
                  maxCounts + 1
                )
              }
              role="presentation"
            >
              {maxCounts}+
              {currentStep && currentStep.value === maxCounts + 1 && (
                <div className={styles.selectedBar} />
              )}
            </div>
          )}
        </div>
        <div className={styles.arrowRightContainer}>
          {1 + countsOffset < maxCounts - 2 && maxCounts > 4 && (
            <img
              src={arrow}
              alt="Arrow"
              role="presentation"
              onClick={() => {
                if (1 + countsOffset < maxCounts - 2) {
                  setCountsOffset(countsOffset + 1);
                }
              }}
            />
          )}
        </div>
      </div>
      {currentStep.value === maxCounts + 1 && (
        <div className={styles.info}>
          {templateStrings.WOULD_YOU_LIKE_MORE_TO_TEST.replace(
            '{MAX_COUNTS}',
            maxCounts
          )}{' '}
          <a href="mailto:operations@buffl.be">operations@buffl.be</a>
        </div>
      )}
      <div className={styles.footerContainer}>
        <Footer
          onNextClick={goToNextStep}
          onPreviousClick={goToPreviousStep}
          disabledNext={currentStep.value === maxCounts + 1}
          nextLabel={strings.NEXT}
          previousLabel={strings.PREVIOUS}
        />
      </div>
    </div>
  );
};
