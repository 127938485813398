import React, { useState, useMemo, useEffect } from 'react';

import Popup from '../../../../../../campaigns/components/Popup';
import ContactInnovationExpertPopup from '../../../../../../campaigns/components/CampaignResults/Blocks/Graphs/components/ContactInnovationExpertPopup/ContactInnovationExpertPopup';
import ProfilingQuestionGroup from './components/ProfilingQuestionGroup/ProfilingQuestionGroup';
import ConfirmationPopup from '../../../ConfirmationPopup/ConfirmationPopup';

import { TARGET_AUDIENCE_STEPS } from '../../../SurveyBuilder/helpers/constants';

import generateSurveyLists from '../../../SurveyBuilder/helpers/generateSurveyLists/generateSurveyLists';

import styles from './ProfilingQuestions.module.css';

export default ({ api, actions, survey, targetAudienceStep }) => {
  const [showGetInTouchPopup, setShowGetInTouchPopup] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [profilingQuestions, setProfilingQuestions] = useState([{}]);

  const memoizedSurveyQuestionsMapping = useMemo(
    () =>
      survey.questions &&
      survey.questions.reduce((acc, q) => ({ ...acc, [q.id]: q }), {}),
    [survey]
  );
  const memoizedSurveyQuestionsSurveyLists = useMemo(
    () => {
      const surveyLists = generateSurveyLists(
        survey.content,
        memoizedSurveyQuestionsMapping
      );
      return surveyLists.filter(
        sL =>
          sL.profiling &&
          sL.profiling.isProfilingQuestion &&
          sL.profiling.isReusedProfilingQuestion
      );
    },
    [survey]
  );

  useEffect(
    () => {
      if (
        memoizedSurveyQuestionsSurveyLists &&
        memoizedSurveyQuestionsSurveyLists.length
      ) {
        setProfilingQuestions(memoizedSurveyQuestionsSurveyLists);
      }
    },
    [memoizedSurveyQuestionsSurveyLists]
  );

  const addNewProfilingQuestion = () => {
    setProfilingQuestions([...profilingQuestions, {}]);
  };

  const isNotEligiblePage =
    targetAudienceStep === TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS;

  return (
    <div className={styles.container}>
      {profilingQuestions &&
        profilingQuestions.length > 0 &&
        profilingQuestions.map((profilingQuestion, i) => (
          <ProfilingQuestionGroup
            key={`profiling-question-group-${i.toString()}`}
            groupIndex={i}
            api={api}
            actions={actions}
            profilingQuestion={profilingQuestion}
            profilingQuestions={profilingQuestions}
            setProfilingQuestions={setProfilingQuestions}
            setConfirmationPopup={setConfirmationPopup}
            targetAudienceStep={targetAudienceStep}
            surveyLanguage={survey.language}
            setShowGetInTouchPopup={setShowGetInTouchPopup}
          />
        ))}
      {showGetInTouchPopup ? (
        <Popup
          component={
            <ContactInnovationExpertPopup
              onClose={() => setShowGetInTouchPopup(false)}
              surveyId={survey.id}
              title="Get in touch"
              messagePlaceholder="Message"
            />
          }
          customStyles={{ padding: 'none' }}
          onClose={() => setShowGetInTouchPopup(false)}
        />
      ) : null}
      {confirmationPopup ? (
        <Popup
          component={
            <ConfirmationPopup
              onClick={confirmationPopup.onClick}
              onClose={() => setConfirmationPopup(false)}
              title={confirmationPopup.title}
              description={confirmationPopup.description}
              warning={confirmationPopup.warning}
            />
          }
          customStyles={{ padding: 'none' }}
          onClose={() => setConfirmationPopup(false)}
        />
      ) : null}
      {!isNotEligiblePage ? (
        <div
          className={styles.addProfilingQuestion}
          onClick={() => {
            if (profilingQuestions.length === 2) {
              const popupContent = {
                onClick: () => addNewProfilingQuestion(),
                onClose: () => setConfirmationPopup(false),
                title: 'Are you sure?',
                description: `It's not recommended to add more than two profiling questions. In our experience it is more efficient to test one assumption at a time.`
              };
              setConfirmationPopup(popupContent);
            } else {
              addNewProfilingQuestion();
            }
          }}
          role="presentation"
        >
          Add profiling question
        </div>
      ) : null}
    </div>
  );
};
