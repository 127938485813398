import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import setGroupJump from './setGroupJump';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  const newState = setGroupJump(surveysState, action.groupId, action.jump);
  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  // Update survey
  const surveyUpdateContent = {
    survey: newState.newState.survey.id,
    content: newState.newState.survey.content
  };
  action.api('updateSurvey', { variables: surveyUpdateContent });
}
