import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loader from '../../base/components/Loader/Loader';

// Because of draging and other complexity, survey builder is required to has separate route
export default props => {
  const {
    component: Component,
    location,
    isAuthenticationProcessing,
    isAdmin,
    accountManagement,
    setSelectedAccountManagementRecord,
    onLogout,
    path,
    ...rest
  } = props;

  useEffect(
    () => {
      if (props && isAuthenticationProcessing === null) {
        if (location && location.pathname && location.pathname !== '/') {
          // Pass requested url to redirect to after authentication/login
          props.onLoad(location.pathname);
        } else {
          props.onLoad();
        }
      }
    },
    [isAuthenticationProcessing]
  );

  // Authenticating and fetch client data
  if (rest && isAuthenticationProcessing === true) {
    return (
      <div style={style.mainLoadingContainer}>
        <Loader isProcessing />
      </div>
    );
  }

  // Not authenticated and authentication not in process
  if (rest && !rest.isAuthenticated && isAuthenticationProcessing === false) {
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: location }
        }}
      />
    );
  }

  const pageContent = renderProps => (
    <div>
      <div
        className="tooltip-container"
        style={{ position: 'absolute', zIndex: 9999999999999999 }}
      />
      <Component {...renderProps} />
    </div>
  );

  return (
    <Route
      {...rest}
      render={renderProps =>
        rest.isAuthenticated !== null ? pageContent(renderProps) : []
      }
    />
  );
};

const style = {
  mainLoadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  }
};
