import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useLazyQuery from '../../../../../users/hooks/useLazyQuery';

import Filters from '../../../CommunityDashboard/components/CommunityDashboardPage/components/Filters/Filters';
import AppBar from '../../../../base/components/AppBar/AppBar';
import Loader from '../Loader/Loader';

import styles from './CommunityAutomationPage.module.css';
import {
  COMMUNITY_DASHBOARD_FILTER_TYPE,
  FILTER_RELATIONS
} from '../../../CommunityDashboard/helpers/constants';
import Button from '../../../../campaigns/components/Button/Button';
import CommunityAutomationMainContainer from './components/CommunityAutomationMainContainer/CommunityAutomationMainContainer';
import leftArrow from '../../../../assets/img/left-arrow.svg';
import ActionNameEditor from './components/ActionNameEditor/ActionNameEditor';
import isAllowedToSaveCommunityAutomation from '../../helpers/isAllowedToSaveCommunityAutomation';
import { COMMUNITY_AUTOMATION_TYPES } from '../../helpers/constants';
import {
  CREATE_COMMUNITY_AUTOMATION,
  GET_COMMUNITY_AUTOMATION,
  UPDATE_COMMUNITY_AUTOMATION
} from '../../../../graphql/CommunityAutomations';

export default props => {
  const { automationId, history } = props;

  const [communityAutomation, setCommunityAutomation] = useState({});
  const [filterRelation, setFilterRelation] = useState(FILTER_RELATIONS.AND);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [
    selectedCommunityAutomationStepIndex,
    setSelectedCommunityAutomationStepIndex
  ] = useState(0);

  const [
    createCommunityAutomation,
    { loading: createCommunityAutomationLoading }
  ] = useMutation(CREATE_COMMUNITY_AUTOMATION);

  const [
    updateCommunityAutomation,
    { loading: updateCommunityAutomationLoading }
  ] = useMutation(UPDATE_COMMUNITY_AUTOMATION);

  const getCommunityAutomation = useLazyQuery(GET_COMMUNITY_AUTOMATION, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    const getLoadData = async () => {
      setIsGlobalLoading(true);
      if (automationId === 'new') {
        setCommunityAutomation({
          name: '',
          steps: [
            {
              type: COMMUNITY_AUTOMATION_TYPES.EVENT
            },
            {
              type: COMMUNITY_AUTOMATION_TYPES.ACTION
            }
          ]
        });
      }
      if (automationId !== 'new') {
        const data = await getCommunityAutomation({
          variables: {
            id: automationId
          }
        });

        if (
          data &&
          data.data &&
          data.data.getCommunityAutomation &&
          data.data.getCommunityAutomation.communityAutomation &&
          data.data.getCommunityAutomation.communityAutomation.id
        ) {
          setCommunityAutomation(
            data.data.getCommunityAutomation.communityAutomation
          );
          setFilterRelation(
            data.data.getCommunityAutomation.communityAutomation.filterRelation
          );
        }
      }
      setIsGlobalLoading(false);
    };

    getLoadData();
  }, []);

  const isLoading =
    createCommunityAutomationLoading ||
    updateCommunityAutomationLoading ||
    isGlobalLoading;

  const onSaveClick = async () => {
    if (isAllowedToSaveCommunityAutomation(communityAutomation)) {
      const communityAutomationToSave = {
        ...communityAutomation,
        filterRelation
      };

      if (
        communityAutomationToSave &&
        communityAutomationToSave.appliedFilters &&
        communityAutomationToSave.appliedFilters.length
      ) {
        communityAutomationToSave.appliedFilters.forEach(filter => {
          const newFilter = filter;

          delete newFilter.__typename;
          if (newFilter.tags) {
            delete newFilter.tags.__typename;
          }
          if (newFilter.userProperties) {
            delete newFilter.userProperties.__typename;
          }
          if (newFilter.community) {
            delete newFilter.community.__typename;
            if (newFilter.community.label) {
              delete newFilter.community.label;
            }
          }
        });
      } else {
        communityAutomationToSave.appliedFilters = [];
      }

      if (
        communityAutomationToSave &&
        communityAutomationToSave.steps &&
        communityAutomationToSave.steps.length
      ) {
        communityAutomationToSave.steps.forEach(step => {
          const newStep = step;

          if (newStep.option1) {
            delete newStep.option1.__typename;
          }

          delete newStep.__typename;
        });
      }

      if (automationId === 'new') {
        const data = await createCommunityAutomation({
          variables: communityAutomationToSave
        });

        if (
          data &&
          data.data &&
          data.data.createCommunityAutomation &&
          data.data.createCommunityAutomation.communityAutomation &&
          data.data.createCommunityAutomation.communityAutomation.id
        ) {
          history.push(
            `/community-automations/${
              data.data.createCommunityAutomation.communityAutomation.id
            }`
          );

          setCommunityAutomation({
            ...communityAutomation,
            id: data.data.createCommunityAutomation.communityAutomation.id
          });
        }
      }
      if (automationId !== 'new') {
        await updateCommunityAutomation({
          variables: communityAutomationToSave
        });
        // Redirect to list page after update
        props.history.push('/community-automations');
      }
    }
  };

  return (
    <>
      <AppBar {...props} />
      {isLoading ? <Loader /> : null}
      {!isLoading ? (
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.leftHeader}>
              <img
                alt="Back icon"
                src={leftArrow}
                className={styles.leftHeaderArrow}
                role="presentation"
                onClick={() => props.history.push('/community-automations')}
              />
              <ActionNameEditor
                value={communityAutomation.name}
                onChange={value =>
                  setCommunityAutomation({
                    ...communityAutomation,
                    name: value
                  })
                }
              />
              <div />
            </div>
            <div className={styles.rightHeader}>
              <Button
                role="button"
                label={
                  automationId === 'new'
                    ? 'Activate automation'
                    : 'Update automation'
                }
                onClick={onSaveClick}
                icon={null}
                customStyle={
                  isAllowedToSaveCommunityAutomation(communityAutomation)
                    ? styles.rightHeaderButton
                    : `${styles.rightHeaderButton} ${styles.disabled}`
                }
              />
            </div>
          </div>
          <div className={styles.filtersContainer}>
            <Filters
              appliedFilters={communityAutomation.appliedFilters || []}
              setAppliedFilters={newAppliedFilters => {
                setCommunityAutomation({
                  ...communityAutomation,
                  appliedFilters: newAppliedFilters
                });
              }}
              filterRelation={filterRelation}
              setFilterRelation={setFilterRelation}
              isGlobalLoading={isGlobalLoading}
              customHideFilterTypes={[
                COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION
              ]}
            />
          </div>
          <CommunityAutomationMainContainer
            communityAutomation={communityAutomation}
            setCommunityAutomation={setCommunityAutomation}
            selectedCommunityAutomationStepIndex={
              selectedCommunityAutomationStepIndex
            }
            setSelectedCommunityAutomationStepIndex={
              setSelectedCommunityAutomationStepIndex
            }
          />
        </div>
      ) : null}
    </>
  );
};
