import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import ungroup from './ungroup';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  const newState = ungroup(
    surveysState,
    action.groupId,
    action.questionIndexInSet,
    action.history
  );
  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  // Update survey
  const surveyUpdateContent = {
    survey: newState.newState.survey.id,
    content: newState.newState.survey.content
  };
  action.api('updateSurvey', { variables: surveyUpdateContent });

  // Remove ungrouped id from url
  if (action.history && newState.newState && newState.newState.survey) {
    action.history.push(`/survey/${newState.newState.survey.id}/edit/`);
  }
}
