import React from 'react';

export default ({ styles, setCurrentTemplatesTab, templateTypes }) => (
  <div className={styles.templateTabWrapper}>
    <div className={`${styles.title}`}>Select a decision framework</div>
    <div
      className={styles.templateTab}
      role="presentation"
      onClick={() => setCurrentTemplatesTab(templateTypes.OPPORTUNITY_GRAPH)}
    >
      <span className={styles.bold}>Problem validation</span>
      <br />
      <span>
        {' '}
        This framework shows you how <strong>important</strong> it is for your
        end users to reach their goal or solve their problem and how{' '}
        <strong>challenging</strong> this is given the currently available
        alternatives. This way you can determine which problems are worth
        solving and find new opportunities to innovate.
      </span>
    </div>
    <div
      className={styles.templateTab}
      role="presentation"
      onClick={() => setCurrentTemplatesTab(templateTypes.IDEA_SELECTION_GRAPH)}
    >
      <span className={styles.bold}>Idea selection</span>
      <br />
      <span>
        {' '}
        This framework shows you how <strong>valuable</strong> and{' '}
        <strong>unique</strong> an idea is. Use this framework when you have a
        wide range of ideas on different topics. This way you can prioritise and
        select ideas worth elaborating on.
      </span>
    </div>
    <div
      className={styles.templateTab}
      role="presentation"
      onClick={() =>
        setCurrentTemplatesTab(templateTypes.VALUE_SELECTION_GRAPH)
      }
    >
      <span className={styles.bold}>Concept exploration</span>
      <br />
      <span>
        {' '}
        This framework shows you which values to include in your final concept,
        especially useful when creating new or more complex concepts. It shows
        you which values your end user considers <strong>
          valuable
        </strong> and <strong>essential</strong> to include in your MVP or basic
        package.
      </span>
    </div>
    <div
      className={styles.templateTab}
      role="presentation"
      onClick={() => setCurrentTemplatesTab(templateTypes.CONCEPT_TEST_GRAPH)}
    >
      <span className={styles.bold}>Concept validation</span>
      <br />
      <span>
        {' '}
        This framework shows you how <strong>valuable</strong> and{' '}
        <strong>unique</strong> a solution is. This gives you an idea of the
        competitive landscape and the market potential of your solution.
      </span>
    </div>
    <div className={styles.hrLine} />
    <div
      className={styles.templateTab}
      role="presentation"
      onClick={() => setCurrentTemplatesTab(templateTypes.CUSTOM_GRAPH)}
    >
      <span className={styles.bold}>Create custom graph</span>
    </div>
  </div>
);
