import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { UPDATE_ORGANISATION } from '../../../graphql/Organisation';
import styles from './EditGoalPage.module.css';

import Goal from '../../../auth/components/Goal/Goal';
import LoaderAnimation from '../../../base/components/LoaderAnimation/LoaderAnimation';
import Bubbles1 from '../../../base/components/Bubbles1/Bubbles1';

const EditGoalPage = ({ organisation, strings }) => {
  const initialFields = {
    name: organisation.name,
    description: organisation.description,
    savingsTarget: organisation.savings_target,
    contactPersonPhone: organisation.contact_person.phone,
    contactPersonFirstName: organisation.contact_person.first_name,
    contactPersonLastName: organisation.contact_person.last_name,
    imageFilename: organisation.image_filename
  };

  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState(initialFields);
  const [editGoal, { loading }] = useMutation(UPDATE_ORGANISATION);

  const onUpdate = () => {
    let registerErrors = {};
    setErrors({});

    Object.keys(fields).forEach(field => {
      if (
        (!fields[field] || fields[field] === '') &&
        field !== 'imageFilename'
      ) {
        registerErrors = {
          ...registerErrors,
          ...{ [field]: strings.FIELD_IS_REQUIRED }
        };
      }
    });

    if (Object.keys(registerErrors).length === 0) {
      fields.savingsTarget = parseFloat(fields.savingsTarget, 10);
      editGoal({ variables: fields }).catch(() => {
        setErrors(registerErrors);
      });
    } else {
      setErrors(registerErrors);
    }
  };

  return (
    <div>
      <Bubbles1 />
      <form className={styles.container}>
        <div
          className={`registration-page-title roboto-bold-font ${styles.title}`}
        >
          {strings.ADJUST_SAVING_GOAL_HERE}
        </div>
        <div className={styles.panel}>
          <Goal
            fields={fields}
            setFields={setFields}
            errors={errors}
            organisation={organisation}
            strings={strings}
            allowCoverImageUpload
          />
        </div>
        <div className={styles.buttonContainer}>
          {loading ? (
            <LoaderAnimation />
          ) : (
            <div>
              {Object.keys(errors).length > 0 ? (
                <div className={`roboto-bold-font ${styles.fixErrors}`}>
                  {strings.PLEASE_FIX_ERRORS_IN_FORM}
                </div>
              ) : null}
              <div
                className={`${styles.button} registration-page-submit-button`}
                onClick={() => onUpdate()}
                role="presentation"
              >
                {strings.SAVE_ADJUSTMENTS}
              </div>
              <div
                className={styles.login}
                onClick={() => {
                  window.location.href = 'https://landing.buffl.be/contact/';
                }}
                role="presentation"
              >
                {strings.DO_YOU_STILL_HAVE_QUESTION}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default EditGoalPage;
