import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import styles from './Bars.module.css';

const Bar = props => {
  const {
    data,
    colorsPallete,
    isPercentage,
    isFiltered,
    hiddenAnswers,
    onBarClick,
    activeSorting,
    question,
    analyticAnswers,
    probabilityAnswerSelected
  } = props;

  const [svg, setSvg] = useState(null);

  const answerSorting = activeSorting.find(
    s => s.sortingName === question.id && s.sortingOrder === 'ANSWER'
  );

  // Did mount
  useEffect(() => {
    drawChart(svg, props);
  }, []);

  // Did update
  useEffect(
    () => {
      drawChart(svg, props);
    },
    [
      svg,
      data,
      colorsPallete,
      isPercentage,
      isFiltered,
      hiddenAnswers,
      onBarClick
    ]
  );

  const calculateDataIndex = i => {
    if (isFiltered) {
      if (i % 2) {
        return Math.floor(i / 2);
      }
      return i / 2;
    }
    return i;
  };

  const drawChart = (svgRef, chartProps) => {
    const margin = { top: 10, right: 30, bottom: 20 };
    margin.left = 35;
    const width =
      chartProps.parent.current.offsetWidth -
      (margin.left ? parseInt(margin.left, 10) : 0) -
      (margin.right ? parseInt(margin.right, 10) : 0);
    const height = chartProps.height - margin.top - margin.bottom;

    // Get max number
    const maxNumber = chartProps.data.reduce((max, num) => {
      const numbers = [...num.values()].filter(
        // eslint-disable-next-line no-restricted-globals
        chartPropNum => !isNaN(chartPropNum) && typeof chartPropNum === 'number'
      );
      let maxNumberInGroup = Math.max(...numbers);
      if (probabilityAnswerSelected && analyticAnswers.length) {
        maxNumberInGroup = numbers.reduce(
          (a, b, i) =>
            Math.max(
              a,
              (b / (analyticAnswers[calculateDataIndex(i)].fit / 100)) *
                (analyticAnswers[calculateDataIndex(i)].upper / 100)
            ),
          0
        );
      }
      return max > maxNumberInGroup ? max : maxNumberInGroup;
    }, 0);

    let barColorsPallete = colorsPallete;

    if (isFiltered) {
      barColorsPallete = barColorsPallete.reduce(
        (pallete, color) => [...pallete, ...[color, `${color}33`]],
        []
      );
    }

    const transparentPallete = barColorsPallete.filter(c => c.endsWith('33'));

    const chartSvg = d3
      .select(svgRef)
      .attr('width', chartProps.parent.current.offsetWidth)
      .attr('height', chartProps.height);

    chartSvg.selectAll('*').remove();

    const chart = chartSvg
      .append('g')
      .attr('width', width - margin.left - margin.right)
      .attr('height', height)
      .attr('class', 'results-bar-container')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const filterNumbers = arr =>
      arr.filter(singleColumn =>
        typeof singleColumn === 'string'
          ? singleColumn !== 'group' && !singleColumn.includes('nofilter')
          : typeof singleColumn === 'number'
      );

    let subgroups = data && data.length && filterNumbers([...data[0].keys()]);

    if (isFiltered && subgroups) {
      subgroups = subgroups.reduce(
        (all, subgroup) => [...all, ...[subgroup, `${subgroup}_nofilter`]],
        []
      );
    }

    subgroups = Array.from({ length: subgroups.length }, (_, i) => i);

    // List of groups = species here = value of the first column called group -> I show them on the X axis

    const groups = data.map(d => d.get('group'));

    const tooltipDiv = d3.select('.tooltip-container').style('display', 'none');

    const onBarClickEvent = (d, i, subgroup) => {
      if (subgroup && subgroup.length) {
        const firstSubgroup = d3.select(subgroup[0]);

        if (firstSubgroup && firstSubgroup.node()) {
          // eslint-disable-next-line prefer-destructuring
          const parentNode = firstSubgroup.node().parentNode;

          if (d3.select(parentNode)) {
            const subgroupIndex = d3.select(parentNode).attr('subgroupIndex');

            onBarClick(d.key, subgroupIndex);
          }
        }
      }
    };

    const calculateLowerAnalyticValue = (value, lower, fit) => {
      const lowerY = ((height - value) / (fit / 100)) * (lower / 100);
      if (lowerY < height) {
        return height - lowerY;
      }
      return lowerY - height;
    };

    const calculateUpperAnalyticValue = (value, upper, fit) => {
      const upperY = ((height - value) / (fit / 100)) * (upper / 100);
      if (upperY < height) {
        return height - upperY;
      }
      return -Math.abs(upperY - height);
    };

    const mapChartData = (d, i) =>
      [...d.entries()]
        // eslint-disable-next-line no-unused-vars
        .filter(([_, val]) => typeof val === 'number')
        .map(([key, value], idx) => {
          let color = colorsPallete[key];
          if (answerSorting) {
            color =
              typeof key === 'string'
                ? transparentPallete[i]
                : colorsPallete[i];
          } else if (isFiltered) {
            let unfilteredKey;
            if (typeof key === 'string') [unfilteredKey] = key.split('_');

            color =
              typeof key === 'string'
                ? transparentPallete[unfilteredKey]
                : colorsPallete[key];
          }

          return {
            key,
            value,
            subgroupIdx: i,
            subgroupOrder: idx,
            color
          };
        });

    const getAnalyticStroke = d => {
      let nofilterIdx;
      if (typeof d.key === 'string') {
        nofilterIdx = Number(d.key.split('_').shift());
      }

      if (answerSorting && hiddenAnswers.indexOf(d.subgroupIdx) > -1) {
        return 0;
      }
      if (
        !answerSorting &&
        (hiddenAnswers.indexOf(d.key) > -1 ||
          hiddenAnswers.indexOf(nofilterIdx) > -1)
      ) {
        return 0;
      }
      return 2;
    };

    // Add X axis
    const x = d3
      .scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2]);
    chart
      .append('g')
      .attr('class', 'bottom-axis')
      .attr('transform', `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(x)
          .tickSize(0)
          .tickFormat(t => {
            const maxStringLength = 20;

            return t.length > maxStringLength
              ? `${t.substring(0, maxStringLength)}...`
              : t;
          })
      );

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([0, maxNumber])
      .range([height, 0]);
    chart.append('g').call(
      d3.axisLeft(y).tickFormat(e => {
        if (Math.floor(e) !== e) {
          return null;
        }
        return isPercentage ? `${e}%` : e;
      })
    );

    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .paddingInner([0])
      .paddingOuter([2]);

    chart
      .select('.bottom-axis')
      .selectAll('.tick')
      .on('mouseover', t => {
        tooltipDiv
          .style('color', '#000000')
          .attr('width', 300)
          .transition()
          .duration(200)
          .style('display', 'block');
        tooltipDiv
          .html(() => `<span style="font-size: 12px">${t}</span>`)
          .style('left', () => `${d3.event.pageX + 10}px`)
          .style('top', () => `${d3.event.pageY}px`);
      })
      .on('mouseout', () => {
        tooltipDiv
          .transition()
          .duration(500)
          .style('display', 'none');
      });

    chart
      .append('g')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${x(d.get('group'))}, 0)`)
      .attr('subgroupIndex', (d, i) => i)
      .selectAll('rect')
      .data((d, i) => mapChartData(d, i))
      .enter()
      .append('rect')
      .attr('x', (_, i) => xSubgroup(i))
      .attr('y', d => y(d.value))
      .attr('width', xSubgroup.bandwidth() - 1)
      .attr('height', d => {
        let nofilterIdx;
        if (typeof d.key === 'string') {
          nofilterIdx = Number(d.key.split('_').shift());
        }

        if (answerSorting && hiddenAnswers.indexOf(d.subgroupIdx) > -1) {
          return null;
        }
        if (
          !answerSorting &&
          (hiddenAnswers.indexOf(d.key) > -1 ||
            hiddenAnswers.indexOf(nofilterIdx) > -1)
        ) {
          return null;
        }

        return height - y(d.value);
      })
      .attr('fill', d => d.color)
      .attr(
        'class',
        () => `${probabilityAnswerSelected ? 'result-chart-half-opacity' : ''}`
      )
      .on('click', onBarClickEvent)
      .on('mousemove', (d, i) => {
        let { key } = d;
        if (Number.isNaN(+key)) {
          [key] = d.key.split('_');
        }
        tooltipDiv
          .style('color', colorsPallete[answerSorting ? d.subgroupIdx : key])
          .style('display', 'block');

        tooltipDiv
          .html(() => {
            if (isPercentage) {
              if (probabilityAnswerSelected && analyticAnswers.length) {
                return `<strong>${d.value}%</strong>
                <br><span style="font-size: 12px">${(
                  (d.value /
                    (analyticAnswers[calculateDataIndex(i)].fit / 100)) *
                  (analyticAnswers[calculateDataIndex(i)].lower / 100)
                ).toFixed(2)}% - ${(
                  (d.value /
                    (analyticAnswers[calculateDataIndex(i)].fit / 100)) *
                  (analyticAnswers[calculateDataIndex(i)].upper / 100)
                ).toFixed(2)}%</span>
                `;
              }
              return `<strong>${d.value}%</strong>`;
            }
            return `<strong>${d.value}</strong>`;
          })
          .style('left', `${d3.event.pageX + 5}px`)
          .style('top', `${d3.event.pageY - 28}px`)
          .style('min-width', 'auto')
          .style('font-size', '12px');
      })
      .on('mouseout', () => {
        tooltipDiv.style('display', 'none');
      });

    if (probabilityAnswerSelected && analyticAnswers.length) {
      chart
        .append('g')
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${x(d.get('group'))}, 0)`)
        .attr('subgroupIndex', (d, i) => i)
        .selectAll('line')
        .data((d, i) => mapChartData(d, i))
        .enter()
        .insert('line')
        .attr('x1', (_, i) => xSubgroup(i) + xSubgroup.bandwidth() * 0.2)
        .attr('y1', (d, i) =>
          calculateLowerAnalyticValue(
            y(d.value),
            analyticAnswers[calculateDataIndex(i)].lower,
            analyticAnswers[calculateDataIndex(i)].fit
          )
        )
        .attr('x2', (_, i) => xSubgroup(i) + xSubgroup.bandwidth() * 0.8)
        .attr('y2', (d, i) =>
          calculateLowerAnalyticValue(
            y(d.value),
            analyticAnswers[calculateDataIndex(i)].lower,
            analyticAnswers[calculateDataIndex(i)].fit
          )
        )
        .style('stroke', d => d.color)
        .style('stroke-width', d => getAnalyticStroke(d));

      chart
        .append('g')
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${x(d.get('group'))}, 0)`)
        .attr('subgroupIndex', (d, i) => i)
        .selectAll('line')
        .data((d, i) => mapChartData(d, i))
        .enter()
        .insert('line')
        .attr('x1', (_, i) => xSubgroup(i) + xSubgroup.bandwidth() * 0.2)
        .attr('y1', (d, i) =>
          calculateUpperAnalyticValue(
            y(d.value),
            analyticAnswers[calculateDataIndex(i)].upper,
            analyticAnswers[calculateDataIndex(i)].fit
          )
        )
        .attr('x2', (_, i) => xSubgroup(i) + xSubgroup.bandwidth() * 0.8)
        .attr('y2', (d, i) =>
          calculateUpperAnalyticValue(
            y(d.value),
            analyticAnswers[calculateDataIndex(i)].upper,
            analyticAnswers[calculateDataIndex(i)].fit
          )
        )
        .style('stroke', d => d.color)
        .style('stroke-width', d => getAnalyticStroke(d));

      chart
        .append('g')
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${x(d.get('group'))}, 0)`)
        .attr('subgroupIndex', (d, i) => i)
        .selectAll('line')
        .data((d, i) => mapChartData(d, i))
        .enter()
        .insert('line')
        .attr('x1', (_, i) => xSubgroup(i) + xSubgroup.bandwidth() * 0.5)
        .attr('y1', (d, i) =>
          calculateLowerAnalyticValue(
            y(d.value),
            analyticAnswers[calculateDataIndex(i)].lower,
            analyticAnswers[calculateDataIndex(i)].fit
          )
        )
        .attr('x2', (_, i) => xSubgroup(i) + xSubgroup.bandwidth() * 0.5)
        .attr('y2', (d, i) =>
          calculateUpperAnalyticValue(
            y(d.value),
            analyticAnswers[calculateDataIndex(i)].upper,
            analyticAnswers[calculateDataIndex(i)].fit
          )
        )
        .style('stroke', d => d.color)
        .style('stroke-width', d => getAnalyticStroke(d));
    }
  };

  return (
    <div>
      {data && data.length ? (
        <>
          {hiddenAnswers &&
          hiddenAnswers.length &&
          hiddenAnswers.length === question.answers.length ? (
            <span className={styles.allOptionsHidden}>
              All answers options are hidden
            </span>
          ) : null}
          <svg
            className={styles.container}
            ref={elem => {
              if (elem) {
                setSvg(elem);
              }
            }}
          />
        </>
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default Bar;
