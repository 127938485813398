import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';

export default (state, isTop, firstFlow, secondFlow) => {
  const newSurvey = JSON.parse(JSON.stringify(state.survey));

  // Adding question to the group
  // Main flow
  const firstFlowIndex = newSurvey.content.groups.findIndex(
    g => g.id === firstFlow.id
  );

  if (newSurvey.content.flows.some(f => f === secondFlow.id)) {
    newSurvey.content.flows = newSurvey.content.flows.filter(
      f => f !== secondFlow.id
    );
  } else {
    const groupsFlowIndex = newSurvey.content.groups.findIndex(
      g => g.flows.indexOf(secondFlow.id) > -1
    );

    if (
      groupsFlowIndex !== undefined &&
      newSurvey.content.groups[groupsFlowIndex]
    ) {
      newSurvey.content.groups[
        groupsFlowIndex
      ].flows = newSurvey.content.groups[groupsFlowIndex].flows.filter(
        f => f !== secondFlow.id
      );
    }
  }

  // If second flow is a question
  if (!secondFlow.flows) {
    if (isTop) {
      newSurvey.content.groups[firstFlowIndex].flows = [
        secondFlow.id,
        ...newSurvey.content.groups[firstFlowIndex].flows
      ];
    }

    if (!isTop) {
      newSurvey.content.groups[firstFlowIndex].flows = [
        ...newSurvey.content.groups[firstFlowIndex].flows,
        secondFlow.id
      ];
    }
  }

  // If second flow is a group
  if (secondFlow.flows) {
    if (isTop) {
      newSurvey.content.groups[firstFlowIndex].flows = [
        ...secondFlow.flows,
        ...newSurvey.content.groups[firstFlowIndex].flows
      ];
    }

    if (!isTop) {
      newSurvey.content.groups[firstFlowIndex].flows = [
        ...newSurvey.content.groups[firstFlowIndex].flows,
        ...secondFlow.flows
      ];
    }

    // Remove second group
    newSurvey.content.groups = newSurvey.content.groups.filter(
      g => g.id !== secondFlow.id
    );
  }

  // Adding group to the group

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      content: newSurvey.content,
      questions: newSurvey.questions
    }
  };

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return { newState };
};
