import api from '../../../../../../../../api';

export const getStatisticalRelevanceForQuestions = async (
  questionResults,
  activeFilters,
  surveyId,
  viewToken
) => {
  const getQuestionStatisticalRelevance = async (
    question,
    answers,
    results
  ) => {
    const values = results.map(result => [result.answers.join()]);

    const levels = results.reduce((resultsMap, result) => {
      if (result && result.answers) {
        result.answers.map(answer => {
          if (resultsMap.indexOf(answer) === -1) {
            resultsMap.push(answer);
          }
          return null;
        });
      }
      return resultsMap;
    }, []);

    levels.sort((a, b) => a - b);

    const input = {
      Q004: {
        class: ['checkbox'],
        label: [question],
        label0: {},
        levels: levels.map(level => `${level}`),
        values
      }
    };

    return api().getAnalyticsData({ id: surveyId }, input, viewToken || null);
  };

  const statisticalRelevanceUnfilteredRequests = questionResults.map(question =>
    getQuestionStatisticalRelevance(
      question.question,
      question.answers,
      question.unfilteredResults
    )
  );
  const unfilteredResponses = await Promise.all(
    statisticalRelevanceUnfilteredRequests
  );

  let filteredResponses = null;
  if (activeFilters && activeFilters.length) {
    const statisticalRelevanceFilteredRequests = questionResults.map(question =>
      getQuestionStatisticalRelevance(
        question.question,
        question.answers,
        question.results
      )
    );
    filteredResponses = await Promise.all(statisticalRelevanceFilteredRequests);
  }

  return {
    unfilteredResponses,
    filteredResponses
  };
};

export const parseStatisticalAnalysisData = (
  unfilteredResponses,
  filteredResponses,
  mergedGraphs,
  hiddenQuestions
) => {
  if (
    !mergedGraphs ||
    mergedGraphs.length === 0 ||
    !mergedGraphs[0].answers ||
    mergedGraphs[0].answers.length === 0
  )
    return {};

  const parsedStatisticsArray = [];

  if (unfilteredResponses) {
    const getResponses = responses => {
      const groupArray = [];

      for (let j = 0; j < mergedGraphs[0].answers.length; j += 1) {
        if (!responses.json || !responses.json.data) {
          groupArray.push({
            min: null,
            max: null
          });
        }

        const answerValue =
          responses &&
          responses.json &&
          responses.json.data &&
          responses.json.data.find(value => value.alternative === `${j}`);

        if (answerValue) {
          groupArray.push({
            min: answerValue.lower,
            max: answerValue.upper
          });
        }

        if (!answerValue) {
          groupArray.push({
            min: null,
            max: null
          });
        }
      }

      return groupArray;
    };

    for (let i = 0; i < unfilteredResponses.length; i += 1) {
      if (filteredResponses && filteredResponses.length) {
        const filteredGroupArray = getResponses(filteredResponses[i]);

        if (filteredGroupArray && filteredGroupArray.length) {
          parsedStatisticsArray.push(filteredGroupArray);
        }
      }

      const groupArray = getResponses(unfilteredResponses[i]);

      if (groupArray && groupArray.length) {
        parsedStatisticsArray.push(groupArray);
      }
    }
  }

  const parsedStatistics = parsedStatisticsArray.reduce(
    (questionsMap, questionsArray, questionIndex) => {
      if (
        !(
          hiddenQuestions &&
          hiddenQuestions.length &&
          hiddenQuestions.indexOf(questionIndex) > -1
        )
      ) {
        const questionsMapStatistics = questionsMap;
        questionsMapStatistics[questionIndex] = questionsArray.reduce(
          (questionMap, question, questionAnswerIndex) => {
            const questionMapStatistics = questionMap;
            questionMapStatistics[questionAnswerIndex] = question;
            return questionMap;
          },
          {}
        );
      }

      return questionsMap;
    },
    {}
  );

  return parsedStatistics;
};
