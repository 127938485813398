import {
  RECOMMENDED_DEFAULT_VALUE,
  RECOMMENDED_DEFAULT_VALUE_WITH_65,
  STRICT_DEFAULT_VALUE,
  STRICT_DEFAULT_VALUE_WITH_65
} from '../../defaultSurveyFilterValues';

import { DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS } from '../../../SurveyBuilder/helpers/constants';

const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export default (
  filterGroup,
  representativity,
  userFilter,
  if65AgeIncluded,
  applyOnAllSet
) => {
  if (representativity) {
    let representativitySets = {};

    if (if65AgeIncluded) {
      representativitySets = {
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.RECOMMENDED]: RECOMMENDED_DEFAULT_VALUE_WITH_65,
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.STRICT]: STRICT_DEFAULT_VALUE_WITH_65
      };
    } else {
      representativitySets = {
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.RECOMMENDED]: RECOMMENDED_DEFAULT_VALUE,
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.STRICT]: STRICT_DEFAULT_VALUE
      };
    }
    const representativitySet = representativitySets[representativity];

    if (applyOnAllSet) {
      const defaultAge =
        representativitySet && representativitySet.$and
          ? representativitySet.$and.filter(v => v.age)
          : [];
      const userFilterAge =
        userFilter && userFilter.$and ? userFilter.$and.filter(v => v.age) : [];

      const defaultGender =
        representativitySet && representativitySet.$and
          ? representativitySet.$and.filter(v => v.gender)
          : [];
      const userFilterGender =
        userFilter && userFilter.$and
          ? userFilter.$and.filter(v => v.gender)
          : [];

      const isAgeSame = arraysEqual(defaultAge, userFilterAge);
      const isGenderSame = arraysEqual(defaultGender, userFilterGender);

      return isAgeSame && isGenderSame;
    }

    const defaultFilterGroupValuesFromDefaultSet =
      representativitySet && representativitySet.$and
        ? representativitySet.$and.filter(v => v[filterGroup.toLowerCase()])
        : [];

    const filterGroupValuesFromUserFilter =
      userFilter && userFilter.$and
        ? userFilter.$and.filter(v => v[filterGroup.toLowerCase()])
        : [];

    return arraysEqual(
      defaultFilterGroupValuesFromDefaultSet,
      filterGroupValuesFromUserFilter
    );
  }
  return false;
};
