import React from 'react';

import styles from './Revenues.module.css';

import coverImage from '../../../../../assets/img/revenues.svg';

const Revenues = ({ data, strings }) => (
  <div className={styles.container}>
    <div className={`${styles.title} roboto-bold-font`}>{strings.REVENUES}</div>
    <div className={styles.contentContainer}>
      <div className={styles.table}>
        {data.map((revenue, index) => (
          <div
            key={`revenues-list-item-${index.toString()}`}
            className={index === 0 ? styles.tableRowFocused : styles.tableRow}
          >
            <div className={styles.month}>{revenue.label}</div>
            <div className={styles.value}>€{revenue.value.toFixed(2)}</div>
          </div>
        ))}
        {!data || !data.length ? strings.NO_DATA_AVAILABLE : null}
      </div>
      <img className={styles.cover} src={coverImage} alt="Revenues cover" />
    </div>
  </div>
);

export default Revenues;
