import React, { useState, useEffect } from 'react';
import DebounceInput from '../../../../../../../../campaigns/components/DebounceInput/DebounceInput';
// import Loader from '../../../../../../../../campaigns/components/Loader/Loader';
import blackArrowDown from '../../../../../../../../assets/img/black-arrow-down.svg';

import styles from './MultipleSearchDropdown.module.css';
import Icon from '../../../../../../../../campaigns/components/Icon';

const FilterDropdownSelector = props => {
  const {
    options,
    fetchOptions,
    value,
    onSelectCompleted,
    placeholder,
    // search,
    filterDropdownStyle,
    fixedWidth,
    onSelectAllClick,
    hideSelectAllCheckbox,
    showQuotaFields,
    quotaValues,
    onQuotaChange,
    disabled
  } = props;

  let wrapperRef = null;

  const [dropdownWrapperRef, setDropdownWrapperRef] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(0);
  /* eslint-disable */
  const [isFetching, setIsFetching] = useState(false);
  /* eslint-enable */
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const [leftPosition, setLeftPosition] = useState(-1000); // hacky but works
  const [availableOptions, setAvailableOptions] = useState(options || []);

  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setShowPopup(false);
    }
  };

  const fetchOptionsExecute = (targetPage, inputFieldSearch) => {
    const runFetchOptions = async () => {
      const searchString =
        inputFieldSearch || inputFieldSearch === ''
          ? inputFieldSearch
          : inputSearch;
      setPage(targetPage);
      const fetchedOptions = await fetchOptions(targetPage, searchString);
      setAvailableOptions(fetchedOptions);
      setIsFetching(false);
    };

    if (fetchOptions) {
      setIsFetching(true);
      runFetchOptions();
    }
  };

  const onScroll = e => {
    const isDropdownPopup =
      e &&
      e.target &&
      e.target.className &&
      // ((typeof str === 'string') || (e.target.className instanceof String)) &&
      e.target.className.toString().substring(0, 35) &&
      (e.target.className.toString().substring(0, 35) ===
        'MultipleSearchDropdown_filterDropdo' ||
        e.target.className.toString().substring(0, 35) === '' ||
        e.target.className.toString().substring(0, 35) ===
          '[object SVGAnimatedString]'); // hacky, but I do not see a better way to solve it now

    if (!isDropdownPopup && showPopup) {
      setShowPopup(false);
    }
  };

  const emptyState = () => {
    setPage(0);
    setInputSearch('');
    setAvailableOptions([]);
    // setIsMaxReached(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    fetchOptionsExecute(0);
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener('mousedown', handleClickOutside);
    },
    []
  );

  useEffect(() => setAvailableOptions(options), [options]);

  useEffect(
    () => {
      if (dropdownWrapperRef) {
        const height = dropdownWrapperRef.offsetHeight;
        const rect = dropdownWrapperRef.getBoundingClientRect();
        const win = dropdownWrapperRef.ownerDocument.defaultView;

        setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
        setLeftPosition(rect.left + win.pageXOffset - window.scrollX);
      }

      if (showPopup) {
        document.addEventListener('wheel', onScroll, {
          passive: false
        });
        fetchOptionsExecute(0);
      } else {
        document.removeEventListener('wheel', onScroll, {
          passive: false
        });
        emptyState();
      }
    },
    [showPopup]
  );

  const allValuesSelected =
    value &&
    value.value &&
    availableOptions &&
    value.value.length === availableOptions.length;

  const selectValue = option => {
    onSelectCompleted(option.value, option.label);
  };

  const checkIsMultipleSelected = singleValue => {
    if (value && value.value && value.value.length) {
      const checkedFilter = value.value.filter(
        checkedValue => checkedValue === singleValue.value
      );
      if (checkedFilter && checkedFilter.length) {
        return true;
      }
      return false;
    }
    return false;
  };

  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    if (elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight) {
      fetchOptionsExecute(page + 1, inputSearch);
    }
  };

  const onInputSearchChangeValue = searchValue => {
    setInputSearch(searchValue);
    fetchOptionsExecute(0, searchValue);
  };

  const selectedValueLabel =
    value && value.label && Array.isArray(value.label) && value.label.length
      ? value.label.reduce((acc, v) => {
          if (acc === '') {
            return v;
          }
          return `${acc}, ${v}`;
        }, '')
      : value.label;

  const getQuotaValue = fieldValue => {
    if (quotaValues && quotaValues.length) {
      const fieldQuota = quotaValues.find(
        quota => quota.key === fieldValue && quota.value
      );
      if (fieldQuota) {
        return fieldQuota.value;
      }
    }
    return null;
  };

  const updateQuota = (quotaKey, quotaOption, newQuotaValue) => {
    let newQuotaValues =
      quotaValues && quotaValues.length ? [...quotaValues] : [];
    const newQuota = {
      key: quotaKey,
      label: quotaOption,
      value: newQuotaValue
    };
    const existingIndex = newQuotaValues.findIndex(q => q.key === quotaKey);

    if (existingIndex >= 0) {
      newQuotaValues[existingIndex] = newQuota;
    } else {
      newQuotaValues = [...newQuotaValues, newQuota];
    }

    onQuotaChange(newQuotaValues);
  };

  return (
    <div
      className={filterDropdownStyle || styles.filterDropdownSelectorContainer}
      ref={elem => {
        if (elem) {
          wrapperRef = elem;
          setDropdownWrapperRef(elem);
        }
      }}
      style={fixedWidth ? { width: fixedWidth } : {}}
    >
      <div
        role="presentation"
        className={
          value && value.value && value.value.length
            ? `${styles.selectedOptionsLabel} ${styles.valueSelected}  ${
                disabled ? styles.dropdownDisabled : null
              }`
            : styles.selectedOptionsLabel
        }
        style={{ backgroundImage: `url(${blackArrowDown})` }}
        onClick={() => {
          if (!disabled) {
            setShowPopup(!showPopup);
          }
        }}
      >
        {value &&
        (value.value === '' || !value.value || value.value.length === 0)
          ? placeholder
          : selectedValueLabel}
      </div>
      {showPopup ? (
        <div
          className={styles.filterDropdownPopup}
          style={{ left: `${leftPosition}`, top: `${topPosition}px` }}
        >
          <div className={styles.filterDropdownPopupSearchInputContainer}>
            <DebounceInput
              initialValue={inputSearch}
              onChangeValue={searchValue =>
                onInputSearchChangeValue(searchValue)
              }
            />
          </div>
          {availableOptions && availableOptions.length ? (
            <div className={styles.additionalInfoContainer}>
              <div className={styles.title}>
                Filter relationship between values is OR
              </div>
              {!hideSelectAllCheckbox ? (
                <div className={styles.checkboxContainer}>
                  <span
                    className={styles.checkboxSelectAllContainer}
                    role="presentation"
                    onClick={async () => {
                      await onSelectAllClick(
                        availableOptions,
                        allValuesSelected
                      );
                    }}
                  >
                    <span
                      className={`${
                        allValuesSelected
                          ? styles.filterDropdownItemCheckboxSelected
                          : styles.filterDropdownItemCheckbox
                      } check-icon`}
                    >
                      <Icon
                        type={allValuesSelected ? 'checked' : 'unchecked'}
                      />
                    </span>
                  </span>
                  Select all values
                </div>
              ) : null}
            </div>
          ) : null}
          <div
            className={styles.filterDropdownPopupItemsContainer}
            onScroll={listOptionsDivScroll}
          >
            {availableOptions && availableOptions.length ? (
              availableOptions.map((option, index) => (
                <div
                  className={styles.filterDropdownItem}
                  key={`filter-dropdown-item-${index.toString()}`}
                >
                  <div
                    style={{ flexGrow: 1 }}
                    role="presentation"
                    onClick={() => selectValue(option)}
                  >
                    <span
                      className={`${
                        checkIsMultipleSelected(option)
                          ? styles.filterDropdownItemCheckboxSelected
                          : styles.filterDropdownItemCheckbox
                      } check-icon`}
                    >
                      <Icon
                        type={
                          checkIsMultipleSelected(option)
                            ? 'checked'
                            : 'unchecked'
                        }
                      />
                    </span>
                    <div className={styles.filterDropdownItemLabel}>
                      {option.label}
                    </div>
                  </div>
                  {showQuotaFields && checkIsMultipleSelected(option) && (
                    <div className={styles.quotaInputContainer}>
                      <input
                        value={getQuotaValue(option.value)}
                        type="number"
                        onKeyDown={evt =>
                          evt.key === 'ArrowDown' ||
                          evt.key === 'ArrowUp' ||
                          evt.key === 'e' ||
                          evt.key === 'E' ||
                          evt.key === '.' ||
                          evt.key === ',' ||
                          evt.key === '-' ||
                          evt.key === '+'
                            ? evt.preventDefault()
                            : null
                        }
                        onChange={e => {
                          updateQuota(
                            option.value,
                            option.label,
                            parseInt(e.target.value, 10)
                          );
                        }}
                        onWheel={e => e.target.blur()}
                        className={styles.quotaInput}
                      />
                      <span className={styles.quotaIcon}>#</span>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className={styles.noAvailableClientAccounts}>
                No available options
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterDropdownSelector;
