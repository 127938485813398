import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import { COLORS } from '../../../../../../helpers/constants';

import style from './Chart.module.css';

export default props => {
  const {
    title,
    tooltipContainerRef,
    parent,
    data,
    unfilteredData,
    deltaData,
    isSocioDemoChart,
    sectorIncidenceRate,
    customLabels
  } = props;

  const [svg, setSvg] = useState(null);

  useEffect(
    () => {
      if (parent && parent.current) {
        drawChart(svg, props);
      }
    },
    [svg, data, deltaData, sectorIncidenceRate]
  );

  useEffect(
    () => {
      drawChart(svg, props);
    },
    [parent.current]
  );

  const wrapText = (barLabels, bandWidth) => {
    barLabels.each((_d, i, n) => {
      const text = d3.select(n[i]);
      const originalText = text.text();
      let words = text
        .text()
        .split(/\s+/)
        .reverse(); // Split text in different words

      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.3;
      const y = text.attr('y');
      const dy = parseFloat(text.attr('dy'));

      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', `${dy}em`);

      word = words[words.length - 1];

      // Loop array where we add words as tspan elements and into new lines if necessary
      while (words.length && word === words[words.length - 1]) {
        const wordToAdd = words.pop();
        word = words[words.length - 1];

        line.push(wordToAdd);
        tspan.text(line.join(' '));

        // Based on width of bar and whitespace besides it we prevent label text from overlapping with other bars' labels by adding new lines
        if (tspan.node().getComputedTextLength() > bandWidth) {
          line.pop();
          tspan.text(line.join(' '));
          line = [wordToAdd];
          lineNumber += 1;
          const newLineHeight = lineNumber * lineHeight + dy;

          if (lineNumber === 3) {
            // Length would exceed three lines so add three dots to last line
            words = [];
            tspan.text(`${tspan.text()}...`);
          } else {
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', `${newLineHeight}em`)
              .text(wordToAdd);
          }
        }
      }

      // Incidence rate
      if (sectorIncidenceRate && sectorIncidenceRate.length) {
        const incidenceRateValue = sectorIncidenceRate.find(
          sIR => sIR && sIR.index === i
        );

        if (incidenceRateValue) {
          lineNumber += 1;
          const newLineHeight = lineNumber * lineHeight + dy;

          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${newLineHeight + 0.2}em`)
            .attr('class', style.xAxisIRTick)
            .text(
              `${parseFloat(incidenceRateValue.incidenceRate * 100).toFixed(
                2
              )}%`
            );
        }
      }

      text.append('title').text(originalText);
    });
  };

  const getColorByConstant = (colorC, opacity = 1) => {
    if (colorC === COLORS.RED) {
      return `rgba(255, 0, 95, ${opacity}`;
    }
    if (colorC === COLORS.ORANGE) {
      return `rgba(232, 189, 43, ${opacity}`;
    }
    if (colorC === COLORS.GREEN) {
      return `rgba(160, 208, 53, ${opacity}`;
    }
    return `rgba(82, 0, 241, ${opacity}`;
  };

  const getBarColor = d => {
    if (
      deltaData &&
      deltaData.length &&
      deltaData.some(dD => dD.xValue === d.xValue && dD.color)
    ) {
      const delta = deltaData.find(dD => dD.xValue === d.xValue && dD.color);
      return getColorByConstant(delta.color);
    }
    return getColorByConstant(isSocioDemoChart ? COLORS.GREEN : COLORS.BLUE);
  };

  const returnValidNumber = value =>
    value && typeof value === 'number' ? value : 0;

  const drawChart = (svgRef, chartProps) => {
    const parentContainer = parent.current;
    const tooltipContainer = tooltipContainerRef.current;

    if (
      parentContainer &&
      tooltipContainer &&
      parentContainer.offsetWidth &&
      data &&
      data.length
    ) {
      const { height } = chartProps;

      const margins = {
        top: 10,
        right: 15,
        rightPadding: 5,
        bottom: 60,
        left: 50
      };

      const width = parentContainer.offsetWidth;

      const svgContainer = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      svgContainer.selectAll('*').remove();

      const innerHeight = height - margins.top - margins.bottom;
      const innerWidth = width - margins.left - margins.right;

      const chartSvg = svgContainer
        .append('g')
        .attr('width', innerWidth)
        .attr('height', innerHeight)
        .attr('transform', `translate(${margins.left}, ${margins.top})`)
        .attr('class', title);

      const yScaleMin = 0;
      const deltaDataMax = deltaData.map(delta => {
        const yValue = returnValidNumber(delta.yValue);
        const repSampleValue = returnValidNumber(delta.repSampleValue);
        const missingValue = returnValidNumber(delta.missingValue);
        const quotaValue = returnValidNumber(delta.quotaValue);

        const max = Math.max(yValue, repSampleValue, missingValue, quotaValue);

        return {
          ...delta,
          yValue: max
        };
      });
      const yScaleMax =
        d3.max([...data, ...unfilteredData, ...deltaDataMax], d =>
          Math.max(d.yValue)
        ) || 99;
      const yRange = yScaleMax - yScaleMin;
      const extensionRange = 5;
      const yScaleMaxExtended = yScaleMax + yRange / extensionRange;

      // Scales
      const xScale = d3
        .scaleBand()
        .domain(data.map((_d, i) => i))
        .range([0, innerWidth - margins.rightPadding]);

      const yScale = d3
        .scaleLinear()
        .domain([0, yScaleMaxExtended])
        .range([innerHeight, 0]);

      const yValues = data.map(d => d.yValue);

      // Gridlines
      chartSvg
        .append('g')
        .attr('class', style.gridLines)
        .attr('transform', `translate(0, 0)`)
        .call(
          d3
            .axisLeft(yScale)
            .tickSize(-innerWidth + margins.rightPadding)
            .tickFormat('')
            .ticks(yValues.length < 8 ? yValues.length : 8)
        );
      // Give className to gridlines
      const gridLineTicks = svgContainer.selectAll(`.${style.gridLines}>.tick`);
      gridLineTicks.attr('class', style.gridLineTick);

      // X axis
      const xValues = data.map(d => d.xValue);
      const xBandWidth = xScale.bandwidth(); // Full width of section of a bar, if you were to set this as barwidth then there would be no spacing between bars
      const barWidth = 30;
      const xTextWidth = xBandWidth - 5; // give at least 5px spacing between labels on x-axis
      const xBarCenteredWidth = (xBandWidth - barWidth) / 2; // Align bars at center of their section

      chartSvg
        .append('g')
        .attr('class', style.xAxis)
        .attr(
          'transform',
          `translate(0, ${height - margins.bottom - margins.top})`
        )
        .call(
          d3
            .axisBottom(xScale)
            .ticks(xValues.length)
            .tickFormat(index =>
              customLabels && customLabels[xValues[index]]
                ? customLabels[xValues[index]]
                : xValues[index]
            )
            .tickSizeOuter(0)
        );

      // Give className to x axis ticks
      const xAxisTicks = svgContainer.selectAll(`.${style.xAxis}>.tick`);
      xAxisTicks.attr('class', style.xAxisTick);
      // Wrap text of x axis ticks
      svgContainer
        .selectAll(`.${style.xAxis}>.${style.xAxisTick}>text`)
        .call(wrapText, xTextWidth);

      // Y axis
      chartSvg
        .append('g')
        .attr('class', style.yAxis)
        .call(
          d3
            .axisLeft(yScale)
            .ticks(yValues.length < 8 ? yValues.length : 8)
            .tickSizeOuter(0)
        );

      // Give className to y axis ticks
      const yAxisTicks = svgContainer.selectAll(`.${style.yAxis}>.tick`);
      yAxisTicks.attr('class', style.yAxisTick);

      // Legend text on Y axis
      chartSvg
        .append('text')
        .attr('class', style.yAxisLegend)
        .text(() => 'Delivery potential in 48h')
        .attr('transform', `translate(-40, ${innerHeight - 30}) rotate(-90)`);

      // Display bars of data
      const tooltipDiv = d3.select(tooltipContainer).style('display', 'none');

      if (deltaData && deltaData.length) {
        // Delta bars
        chartSvg
          .selectAll('.bar')
          .data(deltaData)
          .enter()
          .append('rect')
          .style('fill', d => getColorByConstant(d.color, 0.2))
          .attr('x', (_d, i) => xBarCenteredWidth + xScale(i))
          .attr('width', barWidth)
          .attr('y', d => (d.yValue !== null ? yScale(d.yValue) : 0))
          .attr('height', d =>
            d.yValue !== null ? innerHeight - yScale(d.yValue) : 0
          )
          .on('mouseover', d => {
            tooltipDiv
              .style('color', 'rgba(')
              .style('min-width', 'auto')
              .style('display', 'flex');
            tooltipDiv
              .html(`<span style="font-size: 12px">${d.yValue}</span>`)
              .style('left', `${d3.event.pageX + 10}px`)
              .style('top', `${d3.event.pageY - window.scrollY - 20}px`);
          })
          .on('mouseout', () => {
            tooltipDiv.style('display', 'none');
          });
      }

      // Unfiltered (grey) data bars
      chartSvg
        .selectAll('.bar')
        .data(unfilteredData)
        .enter()
        .append('rect')
        .style('fill', 'rgba(0,0,0,0.06)')
        .attr('x', (_d, i) => xBarCenteredWidth + xScale(i))
        .attr('width', barWidth)
        .attr('y', d => yScale(d.yValue))
        .attr('height', d => innerHeight - yScale(d.yValue))
        .attr('class', style.unfilteredBar)
        .on('mouseover', d => {
          tooltipDiv
            .style('color', 'rgba(')
            .style('min-width', 'auto')
            .style('display', 'flex');
          tooltipDiv
            .html(`<span style="font-size: 12px">${d.yValue}</span>`)
            .style('left', `${d3.event.pageX + 10}px`)
            .style('top', `${d3.event.pageY - window.scrollY - 20}px`);
        })
        .on('mouseout', () => {
          tooltipDiv.style('display', 'none');
        });

      // Filtered (green/orange/red) data bars
      chartSvg
        .selectAll(style.bar)
        .data(data)
        .enter()
        .append('rect')
        .style('fill', d => getBarColor(d))
        .attr('x', (_d, i) => xBarCenteredWidth + xScale(i))
        .attr('width', barWidth)
        .attr('y', d => yScale(d.yValue))
        .attr('height', d => innerHeight - yScale(d.yValue))
        .attr('class', style.bar)
        .on('mouseover', d => {
          tooltipDiv
            .style('color', '#000000')
            .style('min-width', 'auto')
            .style('display', 'flex');
          tooltipDiv
            .html(() => `<span style="font-size: 12px">${d.yValue}</span>`)
            .style('left', `${d3.event.pageX + 10}px`)
            .style('top', `${d3.event.pageY - window.scrollY - 20}px`);
        })
        .on('mouseout', () => {
          tooltipDiv.style('display', 'none');
        });

      if (deltaData && deltaData.length) {
        // Black representative sample lines (recommened/strict sample)
        chartSvg
          .selectAll('.delta-line')
          .data(deltaData)
          .enter()
          .append('line')
          .style('stroke', '#000000')
          .style('stroke-width', d =>
            d.repSampleValue || d.quotaValue ? 1 : 0
          )
          .style('stroke-opacity', 0.9)
          .attr('x1', (_d, i) => xScale(i) + xBarCenteredWidth - 4) //  4px wider than left side of corresponding bar
          .attr('y1', d => yScale(d.repSampleValue || d.quotaValue))
          .attr('x2', (_d, i) => xBandWidth + xScale(i) - xBarCenteredWidth + 4) //  4px wider than right side of corresponding bar
          .attr('y2', d => yScale(d.repSampleValue || d.quotaValue));

        if (isSocioDemoChart) {
          // Delta text
          chartSvg
            .selectAll('.delta-values')
            .data(deltaData)
            .enter()
            .append('text')
            .attr('x', (_d, i) => xBarCenteredWidth + xScale(i) + barWidth / 2)
            .attr('y', d =>
              d.yValue >= 0 &&
              d.yValue / d.repSampleValue > 0.85 &&
              d.yValue / d.repSampleValue < 1
                ? yScale(d.repSampleValue) - 5
                : yScale(d.yValue) - 5
            )
            .style('text-anchor', 'middle')
            .text(d => (d.missingValue ? `Δ ${d.missingValue}` : ''))
            .attr('font-family', 'Open Sans SemiBold')
            .attr('font-size', '13px')
            .attr('fill', d => getColorByConstant(d.color));
        }
      }
    }

    return null;
  };

  return (
    <div>
      <svg ref={elem => setSvg(elem)} />
    </div>
  );
};
