import { QUESTION_TYPES } from '../constants';

let flowJumpsMapping = {};

const getFlows = (content, questions, flows, group, nestingLevel = 0) => {
  const groupsList = content.groups;

  return flows.reduce((dict, el) => {
    if (groupsList.some(g => g.id === el)) {
      const g = groupsList.find(gL => gL.id === el); // Check if el is a group

      if (nestingLevel === 1) {
        const parentGroup = groupsList.find(pG => pG.flows.indexOf(el) > -1);
        if (parentGroup) {
          g.parentGroup = JSON.parse(
            JSON.stringify({ ...parentGroup, parent: true, nestingLevel: 1 })
          );
        }
      }

      return [
        ...dict,
        ...getFlows(content, questions, g.flows, g, nestingLevel + 1)
      ];
    }

    const question = {
      id: questions && questions[el] && questions[el].id,
      question: questions && questions[el] && questions[el].question,
      type: questions && questions[el] && questions[el].type
    };

    if (questions[el] && questions[el].profiling) {
      question.profiling = questions[el].profiling;
    }

    if (questions[el] && questions[el].matrix) {
      question.matrix = questions[el].matrix;
    }

    // Question Jump
    if (questions && questions[el] && questions[el].nextFlow) {
      question.nextFlow = questions[el].nextFlow;

      const existingFlowJumpsMapping =
        flowJumpsMapping[questions[el].nextFlow] || [];

      if (existingFlowJumpsMapping.indexOf(questions[el].id) === -1) {
        flowJumpsMapping[questions[el].nextFlow] = [
          ...existingFlowJumpsMapping,
          questions[el].id
        ];
      }
    }
    if (questions && questions[el] && questions[el].end) {
      question.end = questions[el].end;
    }

    // Choices jump
    if (
      questions &&
      questions[el] &&
      questions[el].choices &&
      questions[el].choices.length
    ) {
      question.choices = questions[el].choices;

      questions[el].choices.forEach(choice => {
        if (choice.nextFlow) {
          const existingFlowJumpsMapping =
            flowJumpsMapping[choice.nextFlow] || [];

          if (existingFlowJumpsMapping.indexOf(questions[el].id) === -1) {
            flowJumpsMapping[choice.nextFlow] = [
              ...existingFlowJumpsMapping,
              questions[el].id
            ];
          }
        }
      });
    }

    // Branches jump
    if (
      questions &&
      questions[el] &&
      questions[el].branches &&
      questions[el].branches.length
    ) {
      question.branches = questions[el].branches;

      questions[el].branches.forEach(choice => {
        if (choice.nextFlow) {
          const existingFlowJumpsMapping =
            flowJumpsMapping[choice.nextFlow] || [];

          if (existingFlowJumpsMapping.indexOf(questions[el].id) === -1) {
            flowJumpsMapping[choice.nextFlow] = [
              ...existingFlowJumpsMapping,
              questions[el].id
            ];
          }
        }
      });
    }

    // Part of a group
    if (group) {
      if (group.nextFlow) {
        const existingFlowJumpsMapping = flowJumpsMapping[group.nextFlow] || [];

        if (existingFlowJumpsMapping.indexOf(group.id) === -1) {
          flowJumpsMapping[group.nextFlow] = [
            ...existingFlowJumpsMapping,
            group.id
          ];
        }
      }

      question.group = {
        ...group,
        nestingLevel
      };
    }

    return [...dict, question];
  }, []);
};

const setJumpsFrom = (flows, questions) => {
  Object.keys(flowJumpsMapping).forEach(flowJump => {
    const flow = flows.find(f => f.id === flowJump);

    if (flow) {
      flow.jumpsFrom = flowJumpsMapping[flowJump];
    }

    if (!flow) {
      const flowsInGroup = flows.filter(
        f => f.group && f.group.id === flowJump
      );

      if (flowsInGroup && flowsInGroup.length) {
        flowsInGroup.forEach(flowInGroup => {
          const fG = flowInGroup;
          fG.group.jumpsFrom = flowJumpsMapping[flowJump];

          // Check if jump from Distributor
          const jumpFromQuestions = flowJumpsMapping[flowJump]
            .map(f => questions[f])
            .filter(f => f);
          const isJumpFromMappingIsDistributor = jumpFromQuestions.some(
            f => f.type === QUESTION_TYPES.DISTRIBUTOR
          );

          if (isJumpFromMappingIsDistributor) {
            const distributorsWithJumpHere = jumpFromQuestions.find(
              f => f.type === QUESTION_TYPES.DISTRIBUTOR
            );

            if (distributorsWithJumpHere && distributorsWithJumpHere.id) {
              fG.group.jumpFromDistributor = distributorsWithJumpHere.id;
            }
          }
        });
      }

      const flowsInParentGroup = flows.filter(
        f =>
          f.group && f.group.parentGroup && f.group.parentGroup.id === flowJump
      );

      if (flowsInParentGroup && flowsInParentGroup.length) {
        flowsInParentGroup.forEach(flowInGroup => {
          const fG = flowInGroup;
          fG.group.parentGroup.jumpsFrom = flowJumpsMapping[flowJump];
        });
      }
    }
  });

  return flows;
};

const flowAdditionalInformation = (flowWithJumps, groupIds) => {
  // This method gives additional information about flow (question) neighbour situation
  let globalIndex = 0;
  let formattedGlobalIndex = 0;
  let groupOrderIndexesMapping = {};
  let groupEntryIndex = 0;
  let groupInSequenceIndex = -1;
  flowWithJumps.forEach((flow, flowIndex) => {
    const f = flow;

    // Formatted Global Index
    if (f.group && f.group.id) {
      let groupOrderIndex;

      if (!groupEntryIndex) {
        groupEntryIndex = formattedGlobalIndex + 1;
      }

      if (groupOrderIndexesMapping[f.group.id] === undefined) {
        groupOrderIndex = 0;
        groupOrderIndexesMapping[f.group.id] = 1;
        groupInSequenceIndex += 1;
      } else {
        groupOrderIndex = groupOrderIndexesMapping[f.group.id];
        groupOrderIndexesMapping[f.group.id] += 1;
      }

      // Global index
      globalIndex += 1;
      f.globalIndex = globalIndex;

      f.formattedGlobalIndex = `${groupEntryIndex +
        groupOrderIndex}${String.fromCharCode(97 + groupInSequenceIndex)}`;
    } else {
      let numberOfSequenceGroupQuestions = Math.max(
        ...Object.values(groupOrderIndexesMapping)
      );
      numberOfSequenceGroupQuestions =
        numberOfSequenceGroupQuestions > 0 ? numberOfSequenceGroupQuestions : 0;

      // Global index
      if (numberOfSequenceGroupQuestions) {
        formattedGlobalIndex = groupEntryIndex + numberOfSequenceGroupQuestions;
      } else if (f.type !== QUESTION_TYPES.DISTRIBUTOR) {
        formattedGlobalIndex += 1;
      }
      globalIndex += 1;

      // eslint-disable-next-line
      f.globalIndex = globalIndex;

      groupEntryIndex = 0;
      groupInSequenceIndex = -1;

      if (f.type !== QUESTION_TYPES.DISTRIBUTOR) {
        f.formattedGlobalIndex = formattedGlobalIndex;
      }
      groupOrderIndexesMapping = {};
    }

    // First survey question (first set question)
    if (flowIndex === 0) {
      f.additionalInformation = { firstSetQuestion: true };
    }

    // First group question
    if (flow.group && flow.group.flows.indexOf(flow.id) === 0) {
      f.additionalInformation = {
        ...f.additionalInformation,
        firstSetQuestion: true,
        firstGroupQuestion: true
      };
    }

    // Last group question
    if (
      flow.group &&
      flow.group.flows.indexOf(flow.id) === flow.group.flows.length - 1
    ) {
      f.additionalInformation = {
        ...f.additionalInformation,
        lastSetQuestion: true,
        lastGroupQuestion: true
      };
    }

    // First set question
    if (
      flowWithJumps[flowIndex - 1] &&
      ((!flow.group && flowWithJumps[flowIndex - 1].group) ||
        (flow.group &&
          flowWithJumps[flowIndex - 1].group &&
          flowWithJumps[flowIndex - 1].group.id !== flow.group.id))
    ) {
      f.additionalInformation = {
        ...f.additionalInformation,
        firstSetQuestion: true
      };
    }

    if (flowWithJumps[flowIndex + 1]) {
      // Last set question
      if (!flow.group && flowWithJumps[flowIndex + 1].group) {
        f.additionalInformation = {
          ...f.additionalInformation,
          lastSetQuestion: true,
          isNextGroup: true
        };
      }

      if (
        flow.group &&
        flowWithJumps[flowIndex + 1].group &&
        flowWithJumps[flowIndex + 1].group.id !== flow.group.id
      ) {
        f.additionalInformation = {
          ...f.additionalInformation,
          lastSetQuestion: true
        };

        if (
          flowWithJumps[flowIndex + 1].group.nestingLevel >
            flow.group.nestingLevel ||
          (flowWithJumps[flowIndex + 1].group.nestingLevel === 1 &&
            flow.group.nestingLevel === 1)
        ) {
          f.additionalInformation = {
            ...f.additionalInformation,
            isNextGroup: true
          };
        }
      }

      if (flowWithJumps[flowIndex + 1].type === QUESTION_TYPES.DISTRIBUTOR) {
        f.additionalInformation = {
          ...f.additionalInformation,
          isNextRandomizer: true
        };
      }
    }

    // Last survey question (last set question)
    if (flowIndex === flowWithJumps.length - 1) {
      f.additionalInformation = {
        ...f.additionalInformation,
        lastSetQuestion: true
      };
    }

    // Check if group is parent group
    if (f.group && f.group.flows && f.group.flows.length) {
      const childGroups = f.group.flows.some(gF => groupIds.indexOf(gF) > -1);

      if (childGroups) {
        f.group.parent = true;
      }
    }

    if (f.group && f.group.nestingLevel === 2 && f.group.parentGroup) {
      // Check if nested group is nestedTopEdgeGroup
      if (f.group.parentGroup.flows.indexOf(f.group.id) === 0) {
        f.group.additionalInformation = {
          ...f.group.additionalInformation,
          nestedTopEdgeGroup: true
        };
      }

      // Check if nested group is nestedBottomEdgeGroup
      if (
        f.group.parentGroup.flows.indexOf(f.group.id) ===
        f.group.parentGroup.flows.length - 1
      ) {
        f.group.additionalInformation = {
          ...f.group.additionalInformation,
          nestedBottomEdgeGroup: true
        };
      }
    }
  });

  return flowWithJumps;
};

export default (c, q) => {
  if (!c || Object.values(c).length === 0) return [];

  const content = JSON.parse(JSON.stringify(c));
  const questions = JSON.parse(JSON.stringify(q));

  const groupIds = content.groups.map(cG => cG.id);

  flowJumpsMapping = {};
  const flows = getFlows(content, questions, content.flows);

  const flowWithJumps = setJumpsFrom(flows, questions);

  const setFlowAdditionalInformation = flowAdditionalInformation(
    flowWithJumps,
    groupIds
  );

  return setFlowAdditionalInformation;
};
