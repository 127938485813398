import React from 'react';

import blackArrowDownIcon from '../../assets/img/black-arrow-down.svg';

export default props => {
  const { onChange, value, name, options, className, placeholder } = props;

  return (
    <div>
      <select
        type="backend"
        onChange={event => onChange(event.target.value)}
        value={value}
        options={options}
        className={className}
        style={{
          backgroundImage: `url(${blackArrowDownIcon})`
        }}
      >
        <option value="">{placeholder || 'Please select value'}</option>
        {options.map((option, index) =>
          option && option instanceof Object ? (
            <option
              key={`dropdown-${name}-${index.toString()}`}
              value={option.value}
            >
              {option.label}
            </option>
          ) : (
            <option key={`dropdown-${name}-${index.toString()}`} value={option}>
              {option}
            </option>
          )
        )}
      </select>
    </div>
  );
};
