import React, { useRef } from 'react';

import {
  addSpaces,
  removeNonNumeric
} from '../../../../helpers/creditsConversion/creditsConversion';

import NumberInputTooltip from './components/NumberInputTooltip/NumberInputTooltip';

import styles from './NumberInputRow.module.css';

export default ({
  label,
  description,
  value,
  onChange,
  disabled = false,
  tooltipText
}) => {
  const textContainerRef = useRef(null);

  return (
    <div className={styles.row}>
      <div className={styles.textContainer} ref={textContainerRef}>
        <div className={styles.label}>
          {label}{' '}
          {tooltipText && tooltipText.length ? (
            <NumberInputTooltip
              textContainerRef={textContainerRef}
              tooltipText={tooltipText}
            />
          ) : null}
        </div>
        {description && description.length ? (
          <div className={styles.description}>{description}</div>
        ) : null}
      </div>
      <div className={styles.inputContainer}>
        <input
          className={styles.valueInput}
          placeholder="0"
          value={value ? addSpaces(value) : ''}
          onChange={e => {
            let newAllocatedValue = 0;
            if (e && e.target && e.target.value && e.target.value !== '') {
              const valueWithoutSpaces = removeNonNumeric(e.target.value);
              newAllocatedValue = parseInt(valueWithoutSpaces, 10);
            }
            onChange(newAllocatedValue);
          }}
          onKeyDown={evt =>
            evt.key === 'ArrowDown' ||
            evt.key === 'ArrowUp' ||
            evt.key === 'e' ||
            evt.key === 'E' ||
            evt.key === '-'
              ? evt.preventDefault()
              : null
          }
          onWheel={e => e.target.blur()}
          maxLength="7"
          type="text"
          spellCheck
          disabled={disabled}
        />
      </div>
    </div>
  );
};
