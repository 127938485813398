export default (distributorId, surveyStructure) => {
  const structure = JSON.parse(JSON.stringify(surveyStructure));
  const contentFlows = structure.content.flows;
  const distributorIndex = contentFlows.indexOf(distributorId);

  if (distributorIndex === -1) {
    return null;
  }

  const distributorQuestion = structure.questions[distributorId];
  const distributorJumps = distributorQuestion.branches.reduce((acc, b) => {
    if (b.nextFlow) {
      return [...acc, b.nextFlow];
    }
    return acc;
  }, []);

  contentFlows.splice(0, distributorIndex + 1);

  const remainingFlowsAfterDistributor = contentFlows.reduce((acc, f) => {
    if (distributorJumps.indexOf(f) === -1) {
      return [...acc, f];
    }
    return acc;
  }, []);

  if (remainingFlowsAfterDistributor.length === 0) {
    return null;
  }

  return remainingFlowsAfterDistributor[0];
};
