import React, { useState } from 'react';

import styles from './AnswersList.module.css';

export default ({
  answers,
  colorsPallete,
  hiddenAnswers,
  setHiddenAnswers,
  parseRichText,
  allowOtherIndex,
  allowNoneOfTheAboveIndex,
  showOtherAnswers
}) => {
  const [displayOtherAnswers, setDisplayOtherAnswers] = useState(false);
  const [textOverflow, setTextOverflow] = useState({
    overflow: false,
    text: '',
    x: 0,
    y: 0
  });

  const isAnswerHidden = index => hiddenAnswers.indexOf(index) === -1;

  return (
    <div className={styles.container}>
      <div className={styles.answersList}>
        {answers.map((answer, index) => (
          <div>
            <div className={styles.answer} key={answer.id}>
              <div
                style={{
                  backgroundColor: colorsPallete[index]
                }}
                className={styles.answerColor}
              />
              <div className={styles.answerString}>
                <span
                  className={styles.answerStringText}
                  onMouseEnter={e => {
                    if (
                      e.currentTarget.clientWidth -
                        e.currentTarget.scrollWidth <
                      0
                    ) {
                      const bounds = e.target.getBoundingClientRect();
                      setTextOverflow({
                        overflow: true,
                        text:
                          answer && parseRichText(answer.answer)
                            ? parseRichText(answer.answer)
                            : `Answer ${index + 1}`,
                        x: bounds.x + 10,
                        y: bounds.y + 5 + bounds.height
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (textOverflow.overflow) {
                      setTextOverflow({
                        ...textOverflow,
                        overflow: false
                      });
                    }
                  }}
                >
                  {answer && parseRichText(answer.answer)
                    ? parseRichText(answer.answer)
                    : `Answer ${index + 1}`}
                </span>
                {index === allowOtherIndex && (
                  <span
                    className={styles.answerOtherLabel}
                    style={{
                      color: colorsPallete[index],
                      borderColor: colorsPallete[index]
                    }}
                  >
                    Other answer
                  </span>
                )}
                {index === allowNoneOfTheAboveIndex && (
                  <span
                    className={styles.answerOtherLabel}
                    style={{
                      color: colorsPallete[index],
                      borderColor: colorsPallete[index]
                    }}
                  >
                    None of the above
                  </span>
                )}
              </div>
              <div className={styles.answerToggle}>
                <span role="presentation">
                  <label className={styles.answerSwitch} htmlFor="checkbox">
                    <input
                      type="checkbox"
                      checked={!isAnswerHidden(index)}
                      className={styles.input}
                    />
                    <span
                      className={styles.answerSlider}
                      style={{
                        backgroundColor: !isAnswerHidden(index)
                          ? '#d4d4d4'
                          : colorsPallete[index]
                      }}
                      role="presentation"
                      onClick={e => {
                        e.stopPropagation();

                        if (isAnswerHidden(index)) {
                          const newHiddenQuestion = [...hiddenAnswers, index];
                          setHiddenAnswers(newHiddenQuestion);
                        } else {
                          const indexOfHiddenQuestion = hiddenAnswers.indexOf(
                            index
                          );
                          if (indexOfHiddenQuestion > -1) {
                            hiddenAnswers.splice(indexOfHiddenQuestion, 1);
                            setHiddenAnswers(
                              JSON.parse(JSON.stringify(hiddenAnswers))
                            );
                          }
                        }
                      }}
                    />
                  </label>
                </span>
              </div>
            </div>
            {index === allowOtherIndex && (
              <div
                className={`${styles.otherAnswerResults} ${
                  displayOtherAnswers ? styles.opened : ''
                }`}
              >
                <div
                  className={styles.otherAnswerButton}
                  role="presentation"
                  onClick={() => setDisplayOtherAnswers(!displayOtherAnswers)}
                  style={{ color: colorsPallete[allowOtherIndex] }}
                >
                  {displayOtherAnswers === true ? 'Hide' : 'Show'} answers
                </div>
                {displayOtherAnswers &&
                  showOtherAnswers(colorsPallete[allowOtherIndex])}
              </div>
            )}
          </div>
        ))}
        {textOverflow && textOverflow.overflow ? (
          <span
            className={styles.textOverflowInfo}
            style={{
              top: `${textOverflow.y}px`,
              left: `${textOverflow.x}px`
            }}
          >
            {textOverflow.text}
          </span>
        ) : null}
      </div>
    </div>
  );
};
