import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import qs from 'query-string';

import AppBar from '../../base/components/AppBar/AppBar';

import styles from './AuthRoute.module.css';

import DutchStrings from '../../strings/nl';
import FrenchStrings from '../../strings/fr';

const AuthRoute = props => {
  const {
    component: Component,
    location,
    userTokens,
    backgroundColor,
    ...rest
  } = props;

  const [language, setLanguage] = useState('NL');

  useEffect(() => {
    document.documentElement.style.backgroundColor = '#FFFFFF';
    document.getElementsByTagName('body')[0].style.backgroundColor = '#FFFFFF';
    const query = qs.parse(location.search);

    if (query && query.lang) {
      if (query.lang === 'FR') {
        setLanguage('FR');
      }
    }
  }, []);

  const pageContent = (renderProps, strings) => (
    <Component {...renderProps} strings={strings} language={language} />
  );

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div
          className={`${styles.backgroundContainer} ${
            backgroundColor === 'green'
              ? styles.backgroundContainerGreen
              : styles.backgroundContainerBlue
          }`}
        />
        <AppBar
          strings={language === 'NL' ? DutchStrings : FrenchStrings}
          auth
          language={language}
          setLanguage={setLanguage}
        />
        <Route
          {...rest}
          render={renderProps =>
            pageContent(
              renderProps,
              language === 'NL' ? DutchStrings : FrenchStrings
            )
          }
        />
      </div>
    </div>
  );
};

export default AuthRoute;
