import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';

export default (state, flowId, choiceId) => {
  const choiceIndex = state.survey.questions[flowId].choices.findIndex(
    c => c.id === choiceId
  );

  const choices = [...state.survey.questions[flowId].choices];

  // If last option is other value, and last option is removed allow other should be false;
  const newActiveFlowProperties = {};

  const question = state.survey.questions[flowId];

  if (choiceIndex > -1 && choiceIndex === choices.length - 1) {
    if (question.allowNoneOfTheAbove) {
      newActiveFlowProperties.allowNoneOfTheAbove = false;
    } else {
      newActiveFlowProperties.allowOther = false;
    }
  }

  // If second last option removed and allowOther + allowNoneOfTheAbove are active, allow other should be false
  if (
    question.allowOther &&
    question.allowNoneOfTheAbove &&
    choiceIndex > -1 &&
    choiceIndex === choices.length - 2
  ) {
    newActiveFlowProperties.allowOther = false;
  }

  choices.splice(choiceIndex, 1);

  let activeFlow = {
    ...state.survey.questions[flowId],
    ...newActiveFlowProperties
  };

  if (!activeFlow.id) {
    activeFlow = {
      ...activeFlow,
      ...state.survey.questions[flowId]
    };
  }

  activeFlow = {
    ...activeFlow,
    choices
  };

  const newState = {
    newState: {
      ...state,
      survey: {
        ...state.survey,
        questions: {
          ...state.survey.questions,
          [flowId]: activeFlow
        }
      },
      activeFlow
    }
  };

  newState.newState.surveyLists = generateSurveyLists(
    newState.newState.survey.content,
    newState.newState.survey.questions
  );

  return newState;
};
