import React from 'react';
import Icon from '../../Icon';
import DebounceInput from '../../DebounceInput/DebounceInput';
import SearchInputDropdown from './SearchInputDropdown/SearchInputDropdown';
import styles from './CampaignNameInput.module.css';

const CampaignNameInput = props => {
  const {
    filter,
    setCampaignFilters,
    setSearchVisible,
    onlySearchProjectName,
    fullWidth
  } = props;

  return (
    <div
      className={styles.headerInputContainer}
      style={fullWidth ? { width: 'auto' } : {}}
    >
      <SearchInputDropdown onlySearchProjectName={onlySearchProjectName} />
      <DebounceInput
        initialValue={
          filter && filter.campaignNameSearch && filter.campaignNameSearch.value
        }
        onChangeValue={value =>
          setCampaignFilters({
            ...filter,
            campaignNameSearch: { value, label: value }
          })
        }
      />
      <span
        role="presentation"
        className={styles.close}
        onClick={() => {
          setSearchVisible(false);
        }}
      >
        <Icon type="close" />
      </span>
    </div>
  );
};

export default CampaignNameInput;
