import {
  TEMPLATES_WIZARD_TEMPLATE_TYPES,
  TEMPLATES_SCALES
} from '../../../../helpers/constants';
import { removeStylingsFromString } from '../../../../components/TemplatesWizard/helpers/styleText';

const AorBEnum = {
  A: 'A',
  B: 'B'
};

export default (text, strings, scaleType, templateType, AorB) => {
  let stringsToTranslate = [];
  if (scaleType === TEMPLATES_SCALES.RATING_SCALE) {
    if (AorB === AorBEnum.A) {
      const mainQuestionTextArray = `${removeStylingsFromString(
        strings.IT_IS_1
      )} ${text.text}`
        .split('\n')
        .map(s => ({ text: s.trim() }));

      stringsToTranslate = [
        {
          text: removeStylingsFromString(strings.WHAT_DO_YOU_THINK_OF_FOLLOWING)
        },
        { text: '' },
        ...mainQuestionTextArray,
        { text: '' },
        {
          text: removeStylingsFromString(
            strings.GIVE_A_SCORE_1.replace('{MIN}', text.min).replace(
              '{MAX}',
              text.max
            )
          )
        }
      ];
    }
    if (AorB === AorBEnum.B) {
      const mainQuestionTextArray = `${removeStylingsFromString(
        strings.IT_IS_2
      )} ${text.text}`
        .split('\n')
        .map(s => ({ text: s.trim() }));

      stringsToTranslate = [
        {
          text: removeStylingsFromString(strings.WHAT_DO_YOU_THINK_OF_FOLLOWING)
        },
        { text: '' },
        ...mainQuestionTextArray,
        { text: '' },
        {
          text: removeStylingsFromString(
            strings.GIVE_A_SCORE_2.replace('{MIN}', text.min).replace(
              '{MAX}',
              text.max
            )
          )
        }
      ];
    }
  }

  if (scaleType === TEMPLATES_SCALES.MULTIPLE_CHOICE) {
    if (templateType === TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION) {
      if (AorB === AorBEnum.A) {
        const mainQuestionTextArray = `${removeStylingsFromString(
          strings.IT_IS_1
        )} ${text.text}`
          .split('\n')
          .map(s => ({ text: s.trim() }));

        stringsToTranslate = [
          {
            text: removeStylingsFromString(
              strings.WHAT_DO_YOU_THINK_OF_FOLLOWING
            )
          },
          { text: '' },
          ...mainQuestionTextArray
        ];
      }
      if (AorB === AorBEnum.B) {
        const mainQuestionTextArray = `${removeStylingsFromString(
          strings.IT_IS_2
        )} ${text.text}`
          .split('\n')
          .map(s => ({ text: s.trim() }));

        stringsToTranslate = [
          {
            text: removeStylingsFromString(
              strings.WHAT_DO_YOU_THINK_OF_FOLLOWING
            )
          },
          { text: '' },
          ...mainQuestionTextArray
        ];
      }
    }

    if (templateType !== TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION) {
      const mainQuestionTextArray = text.text
        .split('\n')
        .map(s => ({ text: s.trim() }));

      if (AorB === AorBEnum.A) {
        stringsToTranslate = [
          ...mainQuestionTextArray,
          { text: '' },
          { text: removeStylingsFromString(strings.TO_WHAT_EXTEND_YOU_AGREE_1) }
        ];
      }
      if (AorB === AorBEnum.B) {
        stringsToTranslate = [
          ...mainQuestionTextArray,
          { text: '' },
          { text: removeStylingsFromString(strings.TO_WHAT_EXTEND_YOU_AGREE_2) }
        ];
      }
    }
  }

  return stringsToTranslate;
};
