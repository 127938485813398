import React, { useState, useEffect } from 'react';

import styles from './ConstraintTooltipContainer.module.css';

export default ({ label, scrolledFromTop = 0, addedIndex = 0 }) => {
  const [ref, setRef] = useState(null);

  useEffect(
    () => {
      if (ref) {
        const { innerWidth: screenWidth } = window;
        const rect = ref.getBoundingClientRect();
        const maxX = rect.x + rect.width;

        // Right orientation
        if (maxX - 15 > screenWidth) {
          ref.style.right = '30px';
        }
      }
    },
    [ref]
  );

  return (
    <div
      ref={r => setRef(r)}
      className={styles.tooltipContainer}
      style={{ top: `${addedIndex * 25 - scrolledFromTop}px` }}
    >
      <div className={styles.tooltip}>{label}</div>
    </div>
  );
};
