import gql from 'graphql-tag';

export const GET_COMMUNITY_AUTOMATIONS = gql`
  query GetCommunityAutomations($page: PositiveInt!, $search: String) {
    getCommunityAutomations(input: { page: $page, search: $search }) {
      totalCount
      records {
        id
        name
        client
        status
        createdAt
        updatedAt
        languageFilterApplied
      }
    }
  }
`;

export const GET_COMMUNITY_AUTOMATION = gql`
  query GetCommunityAutomation($id: ID!) {
    getCommunityAutomation(input: { id: $id }) {
      communityAutomation {
        id
        name
        client
        status
        createdAt
        updatedAt
        filterRelation
        appliedFilters {
          type
          tags {
            category
            tagName
            tagValue
          }
          userProperties {
            propertyName
            propertyValue
          }
          community {
            communityType
            name
          }
        }
        steps {
          type
          stepName
          title
          description
          amount1
          amount2
          amount3
          option1 {
            value
            label
          }
          submitText
        }
      }
    }
  }
`;

export const CREATE_COMMUNITY_AUTOMATION = gql`
  mutation CreateCommunityAutomation(
    $name: String!
    $appliedFilters: [UserFilterInput]!
    $steps: [CommunityAutomationStepInput]!
    $filterRelation: String!
  ) {
    createCommunityAutomation(
      input: {
        name: $name
        appliedFilters: $appliedFilters
        steps: $steps
        filterRelation: $filterRelation
      }
    ) {
      communityAutomation {
        id
      }
      done
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_COMMUNITY_AUTOMATION = gql`
  mutation UpdateCommunityAutomation(
    $id: ID!
    $name: String!
    $appliedFilters: [UserFilterInput]!
    $steps: [CommunityAutomationStepInput]!
    $filterRelation: String!
  ) {
    updateCommunityAutomation(
      input: {
        id: $id
        name: $name
        appliedFilters: $appliedFilters
        steps: $steps
        filterRelation: $filterRelation
      }
    ) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const REMOVE_COMMUNITY_AUTOMATION = gql`
  mutation RemoveCommunityAutomation($id: ID!) {
    removeCommunityAutomation(input: { id: $id }) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const CHANGE_COMMUNITY_AUTOMATION_STATUS = gql`
  mutation ChangeCommunityAutomationStatus($id: ID!) {
    changeCommunityAutomationStatus(input: { id: $id }) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const DUPLICATE_COMMUNITY_AUTOMATION = gql`
  mutation DuplicateCommunityAutomation($id: ID!) {
    duplicateCommunityAutomation(input: { id: $id }) {
      done
      errors {
        field
        message
      }
    }
  }
`;
