import gql from 'graphql-tag';

export const GET_ACQUISITION_METRIC_DETAILS = gql`
  query GetAcquisitionMetricDetails(
    $channel: [String]
    $launchDate: [String]
    $partner: [String]
    $medium: [String]
    $action: [String]
    $startTimeframe: DateTime!
    $endTimeframe: DateTime!
  ) {
    getAcquisitionMetricDetails(
      input: {
        channel: $channel
        launchDate: $launchDate
        partner: $partner
        medium: $medium
        action: $action
        startTimeframe: $startTimeframe
        endTimeframe: $endTimeframe
      }
    ) {
      campaignLinkClickScan
      campaignLinkClickScanConversionRate
      userOpenAppFirstTime
      userOpenAppFirstTimeConversionRate
      userRegistering
      userRegisteringConversionRate
      userAcceptPN
      userAcceptPNConversionRate
      userStartFirstOnboarding
      userStartFirstOnboardingConversionRate
      userFinishLastOnboarding
      userFinishLastOnboardingConversionRate
      userFinishFirstNonOnboarding
      userFinishFirstNonOnboardingConversionRate
    }
  }
`;

export const GENERATE_ACQUISITION_METRIC_LINK = gql`
  mutation GenerateAcquisitionMetricLink(
    $channel: String!
    $launchDate: String!
    $partner: String!
    $medium: String!
    $action: String!
    $community: ID
  ) {
    generateAcquisitionMetricLink(
      input: {
        channel: $channel
        launchDate: $launchDate
        partner: $partner
        medium: $medium
        action: $action
        community: $community
      }
    ) {
      generatedNewLink
    }
  }
`;

export const SEARCH_TRACKING_PROPERTY = gql`
  query SearchTrackingProperty(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $param: String!
    $startTimeframe: DateTime!
    $endTimeframe: DateTime!
    $channel: [String]
    $launchDate: [String]
    $partner: [String]
    $medium: [String]
    $action: [String]
  ) {
    searchTrackingProperty(
      input: {
        start: $start
        end: $end
        search: $search
        param: $param
        startTimeframe: $startTimeframe
        endTimeframe: $endTimeframe
        channel: $channel
        launchDate: $launchDate
        partner: $partner
        medium: $medium
        action: $action
      }
    ) {
      options {
        label
        value
      }
    }
  }
`;

export const GET_ACQUISITION_METRIC_DETAILS_FROM_URL = gql`
  query GetAcquisitionMetricDetailsFromUrl($url: String!) {
    getAcquisitionMetricDetailsFromUrl(input: { url: $url }) {
      errors {
        message
      }
      params {
        channel
        launchDate
        partner
        medium
        action
      }
    }
  }
`;
