export default (question, results, resultStats) => {
  let totalAnswers = 0;

  const detailedAnswers = question.answers.map((answer, index, arr) => {
    const isOther = question.allowOther && index === arr.length - 1;
    const answerResponse = resultStats.values.filter(
      result => result.x === index
    );
    if (answerResponse.length) {
      totalAnswers += answerResponse[0].y;
      return {
        x: answerResponse[0].x,
        label: question.answers[answerResponse[0].x].answer,
        y: answerResponse[0].y,
        image_filename: question.answers[answerResponse[0].x].image_filename,
        isOther
      };
    }
    return {
      x: index,
      label: answer.answer,
      y: 0,
      image_filename: answer.image_filename,
      isOther
    };
  });

  return detailedAnswers.map(answer => {
    const answerWithPercentage = answer;
    if (answerWithPercentage.dropOut === true) {
      answerWithPercentage.percentage = (
        (answer.y / (results.length + question.dropOutResults.length)) *
        100
      ).toFixed(2);
      return answerWithPercentage;
    }
    if (
      question.type === 'Checkboxes' ||
      (question.type === 'Multiple Choice Question' &&
        question.selectAtMost &&
        question.selectAtMost > 1)
    ) {
      answerWithPercentage.percentage =
        question && results && results.length
          ? ((answer.y / results.length) * 100).toFixed(2)
          : null;
    }
    if (
      (question.type === 'Multiple Choice Question' &&
        (!question.selectAtMost || question.selectAtMost === 1)) ||
      question.type === 'Matrix'
    ) {
      answerWithPercentage.percentage = (
        (answer.y / totalAnswers) *
        100
      ).toFixed(2);
    }
    return answerWithPercentage;
  });
};
