import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Stage, Layer, Text } from 'react-konva';

import useDebounce from '../../../../../DebounceInput/debounceHook';

import styles from './WordCloud.module.css';
import tickIcon from '../../../../../../../assets/img/tick_white.svg';
import blackArrowDown from '../../../../../../../assets/img/black-arrow-down.svg';
import mergeIcon from '../../../../../../../assets/img/merge.svg';
import hideIcon from '../../../../../../../assets/img/hide.svg';
import addIcon from '../../../../../../../assets/img/add.svg';
import trashIcon from '../../../../../../../assets/img/trash.svg';

import MergePopup from './MergePopup/MergePopup';
import HidePopup from './HidePopup/HidePopup';
import CategoryPopup from './CategoryPopup/CategoryPopup';

import {
  CREATE_WORD_CLOUD,
  UPDATE_WORD_CLOUD,
  REMOVE_WORD_CLOUD,
  ADD_HIDDEN_WORD_CLOUD_WORD
} from '../../../../../../../graphql/Survey';

const WordCloud = props => {
  const {
    questionId,
    surveyId,
    setAnswerSearch,
    setView,
    views,
    activeWords,
    wordCloudLoaded,
    initialWordClouds,
    hiddenWordCloudWords,
    isAdmin,
    isAllowedToExecuteRequests,
    dropDownFilterOptions,
    filterOrders,
    selectedWordCloudIndex,
    setSelectedWordCloudIndex,
    updateWordCloudsMergedWords,
    updateWordCloudsActiveWords,
    updateWordCloudsFilteredWords,
    updateWordCloudsActiveCategories,
    versionNames,
    updateVersionNames,
    onNewWordCloudAdded,
    initialWords,
    setInitialWords,
    mergedWords,
    onSingleWordCloudLoaded,
    removeWordCloudsLoaded,
    selectAllWords,
    setSelectAllWords,
    selectAllCategories,
    setSelectAllCategories,
    openQuestionCategories,
    assignCategoriesFromWords,
    openAnswerCategoryPairs,
    activeCategories,
    viewToken
  } = props;

  const [createWordCloud] = useMutation(CREATE_WORD_CLOUD);
  const [updateWordCloud] = useMutation(UPDATE_WORD_CLOUD);
  const [removeWordCloud] = useMutation(REMOVE_WORD_CLOUD);
  const [addHiddenWordCloudWord] = useMutation(ADD_HIDDEN_WORD_CLOUD_WORD);

  const mergePopupActions = {
    ADD: 'ADD',
    EDIT: 'EDIT'
  };

  const cloudProperties = {
    WORDS: 'Words',
    CATEGORIES: 'Categories'
  };

  const [words, setWords] = useState([]);
  const [bubbles, setBubbles] = useState([]);
  const [dropDownFilter, setDropDownFilter] = useState(
    dropDownFilterOptions[0]
  );
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [displayDropDownFilter, setDisplayDropDownFilter] = useState(false);
  const [showMergePopup, setShowMergePopup] = useState({
    visible: false,
    action: mergePopupActions.ADD
  });
  const [selectedMergedWord, setSelectedMergedWord] = useState();
  const [showHidePopup, setShowHidePopup] = useState(false);
  const [showCategoryPopup, setShowCategoryPopup] = useState(false);
  const [ignoreWords, setIgnoreWords] = useState(hiddenWordCloudWords);
  const debouncedInputValue = useDebounce(searchFilterValue, 300);
  const [cloudTabNameValue, setCloudTabNameValue] = useState(
    versionNames && versionNames.length
      ? versionNames[selectedWordCloudIndex]
      : ''
  );
  const [cloudTabNameActive, setCloudTabNameActive] = useState({
    active: false,
    width: 70
  });
  const [cloudTabNameOverflow, setCloudTabNameOverflow] = useState({
    overflow: false,
    index: 0
  });
  const [wordListOverflow, setWordListOverflow] = useState({
    overflow: false,
    text: '',
    x: 0,
    y: 0
  });
  const cloudContainerRef = useRef(null);
  const cloudVersionsRef = useRef(null);
  const inputRef = useRef(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [stageDimensions, setStageDimensions] = useState(null);
  const [cloudProperty, setCloudProperty] = useState(cloudProperties.WORDS);
  const [
    displayCloudPropertyDropDown,
    setDisplayCloudPropertyDropDown
  ] = useState(false);
  const [wordCloudCategories, setWordCloudCategories] = useState([]);

  useEffect(
    () => {
      if (initialWords && initialWords.length) {
        setWords(mapWords());
        onSingleWordCloudLoaded();
        if (cloudProperty === cloudProperties.CATEGORIES) {
          setWordCloudCategories(
            wordCloudCategories.map(wordCloudCategory => {
              if (
                activeCategories.some(
                  activeCategory =>
                    activeCategory.text === wordCloudCategory.text
                )
              ) {
                return {
                  ...wordCloudCategory,
                  visible: true
                };
              }
              return {
                ...wordCloudCategory,
                visible: false
              };
            })
          );
        }
      }
    },
    [initialWords, selectedWordCloudIndex]
  );

  useEffect(
    () => {
      let activeValues = activeWords;
      let activeValuesToPass = activeWords;
      if (cloudProperty === cloudProperties.CATEGORIES) {
        activeValues = activeCategories;
        activeValuesToPass =
          activeCategories && activeCategories.length
            ? activeCategories
                .reduce((acc, curr) => {
                  acc.push(curr.words);
                  return acc;
                }, [])
                .flat()
                .filter(
                  (activeCategory, index, self) =>
                    index ===
                    self.findIndex(
                      otherCategory =>
                        otherCategory.text === activeCategory.text
                    )
                )
            : [];
      }
      if (
        activeValues &&
        (activeValues.length || (!activeValues.length && bubbles.length)) &&
        stageDimensions
      ) {
        calculateBubbles(activeValuesToPass);
      }
    },
    [activeWords, stageDimensions, activeCategories, cloudProperty]
  );

  useEffect(
    () => {
      setSearchFilter(searchFilterValue);
    },
    [debouncedInputValue]
  );

  useEffect(
    () => {
      if (cloudVersionsRef && cloudVersionsRef.current && !stageDimensions) {
        setStageDimensions({
          width: cloudVersionsRef.current.clientWidth,
          height: 500
        });
      }
    },
    [cloudVersionsRef]
  );

  useEffect(
    () => {
      if (
        cloudProperty === cloudProperties.CATEGORIES &&
        !wordCloudCategories.length
      ) {
        const mappedCategories = openAnswerCategoryPairs
          ? openAnswerCategoryPairs.reduce((acc, curr) => {
              acc.push({
                text: curr.category,
                words: curr.string
                  .replace(/[^A-Za-z0-9\-\s]/g, '')
                  .replace(/\s{2,}/g, ' ')
                  .replace(/[\r\n]+/gm, '')
                  .toLowerCase()
                  .split(' '),
                visible: false
              });
              return acc;
            }, [])
          : [];
        const categoryWithWords = mappedCategories.map(mappedCategory => ({
          ...mappedCategory,
          words: mappedCategory.words.reduce((acc, curr) => {
            const matchingWord = words.find(word => word.text === curr);
            if (matchingWord) {
              acc.push({
                text: matchingWord.text,
                value: matchingWord.value,
                parentWords: []
              });
            }
            return acc;
          }, [])
        }));
        setWordCloudCategories(categoryWithWords);
      }
    },
    [cloudProperty]
  );

  const dynamicsort = (property, order) => {
    let sortOrder;
    if (order === filterOrders.DESC) {
      sortOrder = -1;
    } else {
      sortOrder = 1;
    }
    return (a, b) => {
      if (a[property] < b[property]) {
        // a should come before b in the sorted order
        return -1 * sortOrder;
      }
      if (a[property] > b[property]) {
        // a should come after b in the sorted order
        return 1 * sortOrder;
      }
      return 0 * sortOrder; // a and b are the same
    };
  };

  const mapWords = () => {
    let mappedAnswers = [...initialWords];
    if (initialWordClouds && mergedWords && mergedWords.length) {
      if (wordCloudLoaded) {
        mergedWords.forEach(word => {
          let mergedWordFiltered = true;
          mappedAnswers = mappedAnswers.filter(mappedAnswer => {
            const matchingAnswer = word.parentWords.some(
              parentWord => parentWord.text === mappedAnswer.text
            );
            if (matchingAnswer) {
              mergedWordFiltered = false;
            }
            return !matchingAnswer;
          });
          if (!mergedWordFiltered) {
            // only include merged word in list if parent words were not filtered out
            mappedAnswers.push(word);
          }
        });
      } else {
        // setting merged words on first load
        const initialMergedWords = [];
        mergedWords.forEach(word => {
          let value = 0;
          const parentWords = [];
          let mergedWordFiltered = true;
          mappedAnswers = mappedAnswers.filter(mappedAnswer => {
            const matchingAnswer = word.parentWords.some(
              parentWord => parentWord === mappedAnswer.text
            );
            if (matchingAnswer) {
              value += mappedAnswer.value;
              const saveAnswerAsParentWord = {
                ...mappedAnswer,
                visible: true
              };
              parentWords.push(saveAnswerAsParentWord);
              mergedWordFiltered = false;
            }
            return !matchingAnswer;
          });
          const savedWordToAdd = {
            ...word,
            value,
            visible: true,
            parentWords
          };
          if (!mergedWordFiltered) {
            // only include merged word in list if parent words were not filtered out
            mappedAnswers.push(savedWordToAdd);
          }
          initialMergedWords.push(savedWordToAdd);
        });
        updateWordCloudsMergedWords(initialMergedWords);
      }
    }
    mappedAnswers.sort(
      dynamicsort(dropDownFilter.property.toLowerCase(), dropDownFilter.order)
    );
    if (!wordCloudLoaded) {
      const mappedAnswersCount =
        mappedAnswers.length > 10 ? 10 : mappedAnswers.length;
      const intitialActiveAnswers = [];
      for (let i = 0; i < mappedAnswersCount; i += 1) {
        mappedAnswers[i].visible = true;
        intitialActiveAnswers.push(mappedAnswers[i]);
      }
      updateWordCloudsActiveWords(intitialActiveAnswers);
    } else {
      mappedAnswers = mappedAnswers.map((mappedAnswer, i) => {
        const find = activeWords.findIndex(
          activeWord => mappedAnswer.text === activeWord.text
        );
        const updatedWord = {
          ...mappedAnswers[i],
          visible: find > -1
        };
        return updatedWord;
      });
    }
    return mappedAnswers;
  };

  const findClosestActiveIndex = (i, originalValues) =>
    [...originalValues]
      .reduce((acc, curr, index) => {
        if (curr.visible) {
          acc.push(index);
        }
        return acc;
      }, [])
      .reduce((acc, curr) =>
        Math.abs(curr - i) < Math.abs(acc - i) ? curr : acc
      );

  const handleVisibiltyChange = (e, i) => {
    let originalValues = words;
    let updateFunction = updateWordCloudsActiveWords;
    let activeValues = activeWords;
    let setFunction = setWords;
    if (cloudProperty === cloudProperties.CATEGORIES) {
      originalValues = wordCloudCategories;
      updateFunction = updateWordCloudsActiveCategories;
      activeValues = activeCategories;
      setFunction = setWordCloudCategories;
    }

    const wordsArray = [...originalValues];
    if (e.shiftKey) {
      const closestActiveIndex = findClosestActiveIndex(i, originalValues);
      let min = i;
      let max = closestActiveIndex - 1;
      if (i > closestActiveIndex) {
        min = closestActiveIndex + 1;
        max = i;
      }
      const wordsInRange = wordsArray.reduce((acc, curr, index) => {
        if (index >= min && index <= max) {
          acc.push({
            ...curr,
            visible: true
          });
        }
        return acc;
      }, []);
      wordsArray.splice(min, wordsInRange.length, ...wordsInRange);
      const newActiveWords = [...activeValues, ...wordsInRange];
      updateFunction(newActiveWords);
    } else {
      const updatedWord = {
        ...wordsArray[i],
        visible: !originalValues[i].visible
      };
      wordsArray[i] = updatedWord;
      if (updatedWord.visible) {
        const newActiveWords = [...activeValues, updatedWord];
        updateFunction(newActiveWords);
      } else if (
        activeValues.some(activeWord => activeWord.text === updatedWord.text)
      ) {
        let newActiveWords = [...activeValues];
        newActiveWords = newActiveWords.filter(
          word => word.text !== updatedWord.text
        );
        updateFunction(newActiveWords);
      }
    }
    setFunction(wordsArray);
  };

  const randomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const convertToRangeValue = (oldValue, oldMin, oldMax, newMin, newMax) =>
    ((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;

  const overlapping = (circles, x, y, r, fontSize) => {
    const circlesLength = circles.length;
    if (circlesLength) {
      for (let i = 0; i < circlesLength; i += 1) {
        const rOverlap = !(
          circles[i].x - circles[i].r + 5 > x + r - 5 ||
          circles[i].x + circles[i].r - 5 < x - r + 5 ||
          circles[i].y - circles[i].fontSize + 12 > y + fontSize - 12 ||
          circles[i].y + circles[i].fontSize - 12 < y - fontSize + 12
        );
        if (rOverlap) {
          return rOverlap;
        }
      }
    }
    return false;
  };

  const getFontBySize = fontSize => {
    if (fontSize < 28) {
      return 'Open Sans Light, Arial';
    }
    if (fontSize >= 28 && fontSize < 32) {
      return 'Open Sans Regular, Arial';
    }
    if (fontSize >= 32) {
      return 'Open Sans SemiBold, Arial';
    }
    return 'Open Sans Regular, Arial';
  };

  const calculateBubbles = activeValues => {
    const circles = [];
    if (!activeValues.length) {
      setBubbles(circles);
      return;
    }
    const limitedActiveWords = [...activeValues].slice(0, 41);
    let lowestValue = limitedActiveWords[0].value;
    let highestValue = limitedActiveWords[0].value;

    limitedActiveWords.reduce((acc, curr) => {
      if (curr.value > highestValue) {
        highestValue = curr.value;
      }
      if (curr.value < lowestValue) {
        lowestValue = curr.value;
      }
      return acc;
    });

    limitedActiveWords.forEach(word => {
      let count = 0;
      let circle = {};
      let fontSize;
      if (lowestValue === highestValue) {
        fontSize = 35;
      } else {
        fontSize = convertToRangeValue(
          word.value,
          lowestValue,
          highestValue,
          20,
          35
        );
        if (word.text.length > 10) {
          fontSize *= 0.8; // downsize very long words
        }
      }
      const r =
        (convertToRangeValue(fontSize, 20, 35, 24, 35) * word.text.length) / 2;
      const halfWordLength = r / 2;
      for (let i = 0; i < 1000; i += 1) {
        let x;
        let y;
        if (count < 20) {
          x = randomNumber(
            stageDimensions.width / 2 -
              stageDimensions.width * 0.05 -
              halfWordLength,
            stageDimensions.width / 2 +
              stageDimensions.width * 0.05 -
              halfWordLength
          );
          y = randomNumber(200, 300);
        } else if (count < 40) {
          x = randomNumber(200 - halfWordLength, 500 - halfWordLength);
          y = randomNumber(150, 350);
        } else if (count < 60) {
          x = randomNumber(120 - halfWordLength, 483 - halfWordLength);
          y = randomNumber(100, 400);
        } else {
          x = randomNumber(1, stageDimensions.width - halfWordLength);
          y = randomNumber(1, stageDimensions.height);
        }

        if (
          x - r > 0 &&
          x + r < stageDimensions.width &&
          y - r > 0 &&
          y + r < stageDimensions.height &&
          !overlapping(circles, x, y, r, fontSize)
        ) {
          circle = {
            x,
            y,
            r,
            t: word.text,
            fontSize,
            opacity:
              lowestValue === highestValue
                ? 1
                : convertToRangeValue(
                    word.value,
                    lowestValue,
                    highestValue,
                    40,
                    100
                  ) / 100,
            fontFamily: getFontBySize(fontSize),
            parentWords: word.parentWords
          };
          circles.push(circle);
          return;
        }
        count += 1;
      }
    });
    setBubbles(circles);
  };

  const bubbleSelected = (t, parentWords) => {
    document.body.style.cursor = 'default';
    if (parentWords.length) {
      let selectedWordsToSearch = '';
      parentWords.forEach(word => {
        if (selectedWordsToSearch.length) {
          selectedWordsToSearch += `; ${word.text}`;
        } else {
          selectedWordsToSearch += word.text;
        }
      });
      setAnswerSearch(selectedWordsToSearch);
    } else {
      setAnswerSearch(t);
    }
    setView(views.ANSWERS);
  };

  const bubbleFocus = () => {
    document.body.style.cursor = 'pointer';
  };

  const bubbleUnFocus = () => {
    document.body.style.cursor = 'default';
  };

  const displayMergePopup = (action, mergedWord) => {
    if (mergedWord) {
      setSelectedMergedWord(mergedWord);
    } else if (activeWords.some(activeWord => activeWord.parentWords.length)) {
      const activeMergedWord = activeWords.find(
        activeWord => activeWord.parentWords.length
      );
      setSelectedMergedWord(activeMergedWord);
    } else {
      setSelectedMergedWord(null);
    }
    if (action === mergePopupActions.ADD) {
      setShowMergePopup({
        visible: true,
        action: mergePopupActions.ADD
      });
    } else {
      setShowMergePopup({
        visible: true,
        action: mergePopupActions.EDIT
      });
    }
  };

  const prepareWordsToSave = wordsToSave =>
    wordsToSave.map(wordToSave => ({
      text: wordToSave.text,
      visible: wordToSave.visible,
      parentWords: wordToSave.parentWords.map(parentWord => parentWord.text)
    }));

  const onCreateWordCloud = async (initialMergedWords, onAdd) => {
    let wordClouds;
    if (!initialWordClouds && !initialMergedWords.length && onAdd) {
      // add 2 wordclouds on add button click if no wordclouds exist in backend yet
      wordClouds = [
        {
          name: `Cloud 1`,
          question: questionId,
          mergedWords: []
        },
        {
          name: `Cloud 2`,
          question: questionId,
          mergedWords: []
        }
      ];
    } else {
      wordClouds = [
        {
          name: `${
            initialWordClouds && initialWordClouds.length
              ? `Cloud ${initialWordClouds.length + 1}`
              : 'Cloud 1'
          }`,
          question: questionId,
          mergedWords: initialMergedWords
        }
      ];
    }
    setCloudTabNameValue(wordClouds[wordClouds.length - 1].name);
    if (isAllowedToExecuteRequests) {
      await createWordCloud({
        variables: {
          wordClouds,
          survey: surveyId,
          ...(viewToken ? { viewToken } : {})
        }
      });
    }
    onNewWordCloudAdded();
    if (initialWordClouds && initialWordClouds.length) {
      setSelectedWordCloudIndex(initialWordClouds.length);
    } else if (wordClouds.length > 1) {
      setSelectedWordCloudIndex(1);
    } else {
      setSelectedWordCloudIndex(0);
    }
    setButtonDisabled(false);
  };

  const onUpdateWordCloud = async updatedWords => {
    if (
      isAllowedToExecuteRequests &&
      initialWordClouds &&
      initialWordClouds.length
    ) {
      await updateWordCloud({
        variables: {
          id: initialWordClouds[selectedWordCloudIndex].id,
          name: cloudTabNameValue,
          survey: surveyId,
          question: questionId,
          mergedWords: prepareWordsToSave(updatedWords),
          ...(viewToken ? { viewToken } : {})
        }
      });
    }
  };

  const onRemoveWordCloud = async () => {
    if (
      initialWordClouds &&
      initialWordClouds.length &&
      initialWordClouds[selectedWordCloudIndex]
    ) {
      updateWordCloudsMergedWords();
      updateWordCloudsActiveWords();
      updateWordCloudsFilteredWords();
      updateVersionNames();
      removeWordCloudsLoaded();
      const newHideAllWords = [...selectAllWords];
      newHideAllWords.splice(selectedWordCloudIndex, 1);
      setSelectAllWords(newHideAllWords);
      const newHideAllCategories = [...selectAllCategories];
      newHideAllCategories.splice(selectedWordCloudIndex, 1);
      setSelectAllCategories(newHideAllCategories);
      setCloudTabNameValue(versionNames[selectedWordCloudIndex - 1]);
      if (isAllowedToExecuteRequests) {
        await removeWordCloud({
          variables: {
            wordCloud: initialWordClouds[selectedWordCloudIndex].id,
            survey: surveyId,
            ...(viewToken ? { viewToken } : {})
          }
        });
      }
      if (selectedWordCloudIndex !== 0) {
        setSelectedWordCloudIndex(selectedWordCloudIndex - 1);
      }
    }
    setButtonDisabled(false);
  };

  const onCreateHiddenWordCloudWord = async wordsToHide => {
    const actualWordsToHideText = wordsToHide.reduce((acc, curr) => {
      if (curr.parentWords.length) {
        curr.parentWords.forEach(parentWord => acc.push(parentWord.text));
      } else {
        acc.push(curr.text);
      }
      return acc;
    }, []);
    setIgnoreWords([...ignoreWords, ...actualWordsToHideText]);
    let initialWordsWithoutWordToHide = [...initialWords];
    initialWordsWithoutWordToHide = initialWordsWithoutWordToHide.filter(
      word => !actualWordsToHideText.includes(word.text)
    );
    setInitialWords(initialWordsWithoutWordToHide);
    let newActiveWords = [...activeWords];
    newActiveWords = newActiveWords.filter(
      word => !wordsToHide.find(wordToHide => wordToHide.text === word.text)
    );
    updateWordCloudsActiveWords(newActiveWords);
    if (isAllowedToExecuteRequests) {
      await addHiddenWordCloudWord({
        variables: {
          words: actualWordsToHideText.map(word => ({ word }))
        }
      });
    }
  };

  const checkSelectAllValue = () =>
    (cloudProperty === cloudProperties.WORDS &&
      selectAllWords[selectedWordCloudIndex]) ||
    (cloudProperty === cloudProperties.CATEGORIES &&
      selectAllCategories[selectedWordCloudIndex]);

  const checkSearchMatchesCategory = category =>
    openAnswerCategoryPairs.some(
      pair =>
        pair.category === category.text &&
        (pair.category.includes(searchFilter) ||
          pair.string.toLowerCase().includes(searchFilter.toLowerCase()))
    );

  return (
    <div className={styles.wordCloudContainer}>
      {showMergePopup.visible ? (
        <MergePopup
          words={words}
          setWords={setWords}
          activeWords={activeWords}
          updateWordCloudsActiveWords={newActiveWords =>
            updateWordCloudsActiveWords(newActiveWords)
          }
          mergedWords={mergedWords && mergedWords.length ? mergedWords : []}
          updateWordCloudsMergedWords={updateWordCloudsMergedWords}
          selectedMergedWord={selectedMergedWord}
          onClose={() =>
            setShowMergePopup({
              ...showMergePopup,
              visible: false
            })
          }
          action={showMergePopup.action}
          mergePopupActions={mergePopupActions}
          newWordCloud={
            !(
              initialWordClouds &&
              initialWordClouds.length &&
              initialWordClouds[selectedWordCloudIndex]
            )
          }
          onCreateWordCloud={onCreateWordCloud}
          onUpdateWordCloud={onUpdateWordCloud}
        />
      ) : null}
      {isAdmin && showHidePopup ? (
        <HidePopup
          onClose={() => setShowHidePopup(false)}
          onCreateHiddenWordCloudWord={wordsToHide =>
            onCreateHiddenWordCloudWord(wordsToHide)
          }
          activeWords={activeWords}
        />
      ) : null}
      {showCategoryPopup ? (
        <CategoryPopup
          onClose={() => setShowCategoryPopup(false)}
          openQuestionCategories={openQuestionCategories}
          onComplete={categories => {
            const selectedWords = activeWords.reduce((acc, curr) => {
              if (curr.parentWords.length) {
                curr.parentWords.forEach(parentWord =>
                  acc.push(parentWord.text)
                );
              } else {
                acc.push(curr.text);
              }
              return acc;
            }, []);
            assignCategoriesFromWords(selectedWords, categories);
          }}
        />
      ) : null}
      <div className={styles.actionsBar}>
        <div className={styles.actionsBarLeft}>
          <div className={styles.searchAnswerInputContainer}>
            <input
              className={styles.searchAnswerInput}
              type="text"
              placeholder="Search"
              value={searchFilterValue}
              onChange={e => {
                setSearchFilterValue(e.target.value);
              }}
              ref={inputRef}
            />
            {searchFilterValue && searchFilterValue.length && (
              <span
                className={styles.closeIcon}
                role="presentation"
                onClick={() => {
                  setSearchFilterValue('');
                  if (inputRef && inputRef.current) {
                    inputRef.current.focus();
                  }
                }}
              >
                t
              </span>
            )}
          </div>
        </div>
        <div className={styles.actionsBarRight}>
          <div
            className={`${styles.dropDownContainer} ${styles.propertyDropDown}`}
            onClick={() =>
              setDisplayCloudPropertyDropDown(!displayCloudPropertyDropDown)
            }
            role="presentation"
          >
            <img
              className={`${styles.dropDownArrow} ${
                displayCloudPropertyDropDown ? styles.dropDownArrowActive : ''
              }`}
              src={blackArrowDown}
              alt="Drop down arrow"
            />
            <span>{cloudProperty}</span>
            {displayCloudPropertyDropDown ? (
              <div className={styles.dropDown}>
                <div
                  className={styles.dropDownOption}
                  onClick={() => setCloudProperty(cloudProperties.WORDS)}
                  role="presentation"
                >
                  {cloudProperties.WORDS}
                </div>
                <div
                  className={styles.dropDownOption}
                  onClick={() => setCloudProperty(cloudProperties.CATEGORIES)}
                  role="presentation"
                >
                  {cloudProperties.CATEGORIES}
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={`${styles.dropDownContainer} ${styles.filterDropDown}`}
            onClick={() => setDisplayDropDownFilter(!displayDropDownFilter)}
            role="presentation"
          >
            <img
              className={`${styles.dropDownArrow} ${
                displayDropDownFilter ? styles.dropDownArrowActive : ''
              }`}
              src={blackArrowDown}
              alt="Drop down arrow"
            />
            <span>
              {
                dropDownFilterOptions.find(
                  option => dropDownFilter.name === option.name
                ).name
              }
            </span>
            {displayDropDownFilter ? (
              <div className={styles.dropDown}>
                {dropDownFilterOptions.map(option => (
                  <div
                    key={option.name}
                    className={styles.dropDownOption}
                    onClick={() => {
                      if (dropDownFilter.name !== option.name)
                        setDropDownFilter(option);
                    }}
                    role="presentation"
                  >
                    {option.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div
            className={`${styles.actionButton} ${
              !activeWords ||
              activeWords.length < 2 ||
              cloudProperty === cloudProperties.CATEGORIES
                ? styles.disabledButton
                : ''
            }`}
            role="presentation"
            onClick={() =>
              activeWords &&
              activeWords.length > 1 &&
              cloudProperty === cloudProperties.WORDS
                ? displayMergePopup(mergePopupActions.ADD)
                : null
            }
          >
            <img src={mergeIcon} className={styles.mergeIcon} alt="Merge" />
            <span className={styles.text}>Merge</span>
          </div>
          {isAdmin ? (
            <div
              className={`${styles.actionButton} ${
                activeWords &&
                activeWords.length &&
                cloudProperty === cloudProperties.WORDS
                  ? ''
                  : styles.disabledButton
              }`}
              role="presentation"
              onClick={() => {
                if (
                  activeWords &&
                  activeWords.length &&
                  cloudProperty === cloudProperties.WORDS
                ) {
                  setShowHidePopup(true);
                }
              }}
            >
              <img src={hideIcon} className={styles.hideIcon} alt="Block" />
              <span className={styles.text}>Block</span>
            </div>
          ) : null}
          <div
            className={`${styles.actionButton} ${
              !activeWords ||
              activeWords.length < 1 ||
              cloudProperty === cloudProperties.CATEGORIES
                ? styles.disabledButton
                : ''
            }`}
            role="presentation"
            onClick={() => {
              if (
                activeWords &&
                activeWords.length &&
                cloudProperty === cloudProperties.WORDS
              ) {
                setShowCategoryPopup(true);
              }
            }}
          >
            <img src={addIcon} className={styles.addIcon} alt="Category" />
            <span className={styles.text}>Add to category</span>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className={styles.sidebar}>
          <div className={styles.hideAll}>
            <div
              className={`${styles.visibleBox} ${
                checkSelectAllValue() ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => {
                let originalValues = words;
                let updateFunction = updateWordCloudsActiveWords;
                let setFunction = setWords;
                let selectValue = selectAllWords;
                let setSelectFunction = setSelectAllWords;
                if (cloudProperty === cloudProperties.CATEGORIES) {
                  originalValues = wordCloudCategories;
                  updateFunction = updateWordCloudsActiveCategories;
                  setFunction = setWordCloudCategories;
                  selectValue = selectAllCategories;
                  setSelectFunction = setSelectAllCategories;
                }

                let newWords = [];
                const activeWordsDependingOnSearch = [];
                let searchMatch = false;
                if (searchFilter && searchFilter.length) {
                  newWords = originalValues.map(word => {
                    if (
                      (cloudProperty === cloudProperties.CATEGORIES &&
                        (word.visible && !checkSearchMatchesCategory(word))) ||
                      (checkSearchMatchesCategory(word) &&
                        !selectValue[selectedWordCloudIndex]) ||
                      ((cloudProperty === cloudProperties.WORDS &&
                        (word.visible && !word.text.includes(searchFilter))) ||
                        (word.text.includes(searchFilter) &&
                          !selectValue[selectedWordCloudIndex]))
                    ) {
                      if (!searchMatch) {
                        searchMatch = true;
                      }
                      activeWordsDependingOnSearch.push({
                        ...word,
                        visible: true
                      });
                      return {
                        ...word,
                        visible: true
                      };
                    }
                    return {
                      ...word,
                      visible: false
                    };
                  });
                } else {
                  newWords = originalValues.map(word => ({
                    ...word,
                    visible: !selectValue[selectedWordCloudIndex]
                  }));
                }

                setFunction(newWords);
                let invertedActiveWords = [];
                if (
                  (!selectValue[selectedWordCloudIndex] &&
                    !searchFilter.length) ||
                  (searchFilter.length && searchMatch)
                ) {
                  invertedActiveWords = newWords;
                }
                updateFunction(
                  activeWordsDependingOnSearch.length
                    ? activeWordsDependingOnSearch
                    : invertedActiveWords
                );

                const newHideAllWords = [...selectValue];
                newHideAllWords[selectedWordCloudIndex] = !selectValue[
                  selectedWordCloudIndex
                ];
                setSelectFunction(newHideAllWords);
              }}
            >
              {checkSelectAllValue() &&
              ((cloudProperty === cloudProperties.CATEGORIES &&
                activeCategories.length < wordCloudCategories.length) ||
                (cloudProperty === cloudProperties.WORDS &&
                  activeWords.length < words.length)) ? (
                <div className={styles.hideAllLine} />
              ) : null}
              {checkSelectAllValue() &&
              ((cloudProperty === cloudProperties.CATEGORIES &&
                activeCategories.length === wordCloudCategories.length) ||
                (cloudProperty === cloudProperties.WORDS &&
                  activeWords.length === words.length)) ? (
                <img src={tickIcon} className={styles.visibleTick} alt="Tick" />
              ) : null}
            </div>
            <span className={styles.text}>Select all</span>
          </div>
          <div className={styles.sideBarList}>
            {cloudProperty === cloudProperties.CATEGORIES &&
            wordCloudCategories &&
            wordCloudCategories.length &&
            openAnswerCategoryPairs.find(
              pair =>
                pair.category.includes(searchFilter) ||
                pair.string.toLowerCase().includes(searchFilter.toLowerCase())
            ) ? (
              wordCloudCategories.map((category, index) =>
                checkSearchMatchesCategory(category) ? (
                  <div key={category.text} className={styles.listItem}>
                    <div
                      className={`${styles.visibleBox} ${
                        category.visible ? styles.active : ''
                      }`}
                      role="presentation"
                      onClick={e => handleVisibiltyChange(e, index)}
                    >
                      <img
                        src={tickIcon}
                        className={styles.visibleTick}
                        alt="Tick"
                      />
                    </div>
                    <span
                      className={`${styles.text} ${styles.wordListText}`}
                      onMouseEnter={e => {
                        if (e.currentTarget.offsetWidth >= 180) {
                          setWordListOverflow({
                            overflow: true,
                            text: category.text,
                            x: e.clientX + 10,
                            y: e.clientY
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        if (wordListOverflow.overflow) {
                          setWordListOverflow({
                            ...wordListOverflow,
                            overflow: false
                          });
                        }
                      }}
                    >
                      {category.text}
                    </span>
                    <span>
                      (
                      {category.words.reduce(
                        (acc, curr) => acc + curr.value,
                        0
                      )}
                      )
                    </span>
                  </div>
                ) : null
              )
            ) : (
              <div className={styles.noWords}>
                {cloudProperty === cloudProperties.CATEGORIES &&
                  'No categories found.'}
              </div>
            )}
            {cloudProperty === cloudProperties.WORDS &&
            words &&
            words.length &&
            words.find(word => word.text.includes(searchFilter)) ? (
              words
                .sort(
                  dynamicsort(
                    dropDownFilter.property.toLowerCase(),
                    dropDownFilter.order
                  )
                )
                .map((word, index) =>
                  word.text.includes(searchFilter) ? (
                    <div
                      key={`${word.text}-${word.value}`}
                      className={`${styles.listItem} ${
                        index % 2 !== 0 ? styles.oddWord : ''
                      }`}
                    >
                      <div
                        className={`${styles.visibleBox} ${
                          word.visible ? styles.active : ''
                        }`}
                        role="presentation"
                        onClick={e => handleVisibiltyChange(e, index)}
                      >
                        <img
                          src={tickIcon}
                          className={styles.visibleTick}
                          alt="Tick"
                        />
                      </div>
                      {word.parentWords.length ? (
                        <img
                          role="presentation"
                          onClick={() =>
                            displayMergePopup(mergePopupActions.EDIT, word)
                          }
                          className={styles.listMergeIcon}
                          src={mergeIcon}
                          alt="(Merged)"
                        />
                      ) : null}
                      <span
                        className={`${styles.text} ${styles.wordListText}`}
                        onMouseEnter={e => {
                          if (e.currentTarget.offsetWidth >= 180) {
                            setWordListOverflow({
                              overflow: true,
                              text: word.text,
                              x: e.clientX + 10,
                              y: e.clientY
                            });
                          }
                        }}
                        onMouseLeave={() => {
                          if (wordListOverflow.overflow) {
                            setWordListOverflow({
                              ...wordListOverflow,
                              overflow: false
                            });
                          }
                        }}
                      >
                        {word.text}
                      </span>
                      <span>({word.value})</span>
                    </div>
                  ) : null
                )
            ) : (
              <div className={styles.noWords}>
                {cloudProperty === cloudProperties.WORDS && 'No words found.'}
              </div>
            )}
          </div>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.cloudVersionsContainer} ref={cloudVersionsRef}>
            <div className={styles.cloudVersionsTabs}>
              {versionNames.map((name, index) => (
                <div
                  className={styles.cloudTabContainer}
                  key={`${name}-${index.toString()}`}
                >
                  {cloudTabNameActive.active &&
                  index === selectedWordCloudIndex ? (
                    <input
                      style={{ width: cloudTabNameActive.width }}
                      value={cloudTabNameValue}
                      onChange={e => setCloudTabNameValue(e.target.value)}
                      className={styles.cloudTabNameInput}
                      onBlur={() => {
                        if (
                          cloudTabNameValue &&
                          cloudTabNameValue.length &&
                          !versionNames.includes(cloudTabNameValue)
                        ) {
                          updateVersionNames(cloudTabNameValue);
                        }
                        if (initialWordClouds) {
                          onUpdateWordCloud(mergedWords);
                        } else {
                          onCreateWordCloud([], false);
                        }
                        setCloudTabNameActive({ active: false, width: 70 });
                      }}
                      // eslint-disable-next-line
                      autoFocus
                    />
                  ) : (
                    <span
                      className={`${styles.cloudTab} ${
                        selectedWordCloudIndex === index
                          ? styles.cloudTabActive
                          : ''
                      }`}
                      key={name}
                      role="presentation"
                      onClick={() => {
                        if (selectedWordCloudIndex !== index) {
                          setCloudTabNameValue(name);
                          setSelectedWordCloudIndex(index);
                        }
                      }}
                      onDoubleClick={e => {
                        setCloudTabNameValue(name);
                        setCloudTabNameActive({
                          active: true,
                          width: e.currentTarget.offsetWidth
                        });
                      }}
                      onMouseEnter={e => {
                        if (e.currentTarget.offsetWidth >= 130) {
                          setCloudTabNameOverflow({
                            overflow: true,
                            index
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        if (cloudTabNameOverflow.overflow) {
                          setCloudTabNameOverflow({
                            ...cloudTabNameOverflow,
                            overflow: false
                          });
                        }
                      }}
                    >
                      {name}
                    </span>
                  )}
                  {cloudTabNameOverflow.overflow &&
                  cloudTabNameOverflow.index === index ? (
                    <span className={styles.nameOverflowInfo}>
                      This is a very long name for a version.
                    </span>
                  ) : null}
                </div>
              ))}
            </div>
            <div className={styles.cloudVersionsActions}>
              <div className={styles.actionIconContainer}>
                <img
                  src={addIcon}
                  className={`${styles.addIcon} ${
                    !versionNames ||
                    (versionNames &&
                      versionNames.length &&
                      versionNames.length < 3)
                      ? styles.versionActionActive
                      : styles.versionActionDisabled
                  }`}
                  alt="Add wordcloud"
                  role="presentation"
                  onClick={() => {
                    if (
                      !versionNames ||
                      (versionNames &&
                        versionNames.length &&
                        versionNames.length < 3 &&
                        !buttonDisabled)
                    ) {
                      setButtonDisabled(true);
                      onCreateWordCloud([], true);
                    }
                  }}
                />
                {versionNames &&
                versionNames.length &&
                versionNames.length > 2 ? (
                  <span className={styles.addDisabledInfo}>
                    You have reached the maximum amount.
                  </span>
                ) : null}
              </div>
              <div className={styles.actionIconContainer}>
                <img
                  src={trashIcon}
                  className={`${styles.trashIcon} ${
                    versionNames &&
                    versionNames.length &&
                    versionNames.length > 1
                      ? styles.versionActionActive
                      : styles.versionActionDisabled
                  }`}
                  alt="Delete wordcloud"
                  role="presentation"
                  onClick={() => {
                    if (
                      versionNames &&
                      versionNames.length &&
                      versionNames.length > 1 &&
                      !buttonDisabled
                    ) {
                      setButtonDisabled(true);
                      onRemoveWordCloud();
                    }
                  }}
                />
                {!initialWordClouds ||
                (initialWordClouds &&
                  initialWordClouds.length &&
                  initialWordClouds.length === 1) ? (
                  <span className={styles.trashDisabledInfo}>
                    You have reached the minimum amount.
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.cloudContainer} ref={cloudContainerRef}>
            {stageDimensions && bubbles && bubbles.length ? (
              <Stage
                width={stageDimensions.width}
                height={stageDimensions.height}
              >
                <Layer>
                  {bubbles.map(bubble => (
                    <Text
                      position={{
                        x: bubble.x,
                        y: bubble.y - bubble.fontSize / 2
                      }}
                      align="center"
                      fontFamily={bubble.fontFamily}
                      fontSize={bubble.fontSize}
                      text={bubble.t}
                      opacity={bubble.opacity}
                      onMouseDown={() =>
                        bubbleSelected(bubble.t, bubble.parentWords)
                      }
                      onMouseEnter={() => bubbleFocus()}
                      onMouseLeave={() => bubbleUnFocus()}
                      fill="#5300f2"
                      key={`${bubble.t}${bubble.r}`}
                    />
                  ))}
                </Layer>
              </Stage>
            ) : (
              <div className={styles.noWords}>
                All{' '}
                {cloudProperty === cloudProperties.WORDS
                  ? 'words'
                  : 'categories'}{' '}
                are hidden.
              </div>
            )}
          </div>
        </div>
        {wordListOverflow && wordListOverflow.overflow ? (
          <span
            className={styles.wordOverflowInfo}
            style={{
              top: `${wordListOverflow.y}px`,
              left: `${wordListOverflow.x}px`
            }}
          >
            {wordListOverflow.text}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default WordCloud;
