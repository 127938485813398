import React from 'react';

import SelectTemplate from './components/SelectTemplate';
import DefaultGraphTemplate from './components/DefaultGraphTemplate';
import CustomGraphTemplate from './components/CustomGraphTemplate';

import styles from '../EditGraphsPopup.module.css';

export default ({
  tabProperties,
  mergeQuestionName,
  setMergeQuestionName,
  questions,
  questionBlocks,
  features,
  setFeatures,
  currentTemplatesTab,
  setCurrentTemplatesTab,
  templateTypes,
  templateConfigurations,
  axisLabels,
  setAxisLabels,
  isAdmin
}) => (
  <div className={styles.templateContainer}>
    {!currentTemplatesTab ? (
      <SelectTemplate
        styles={styles}
        setCurrentTemplatesTab={setCurrentTemplatesTab}
        templateTypes={templateTypes}
      />
    ) : null}
    {[
      templateTypes.OPPORTUNITY_GRAPH,
      templateTypes.VALUE_SELECTION_GRAPH,
      templateTypes.IDEA_SELECTION_GRAPH,
      templateTypes.CONCEPT_TEST_GRAPH
    ].includes(currentTemplatesTab) ? (
      <DefaultGraphTemplate
        styles={styles}
        tabProperties={tabProperties}
        mergeQuestionName={mergeQuestionName}
        setMergeQuestionName={setMergeQuestionName}
        questions={questions}
        questionBlocks={questionBlocks}
        features={features}
        setFeatures={setFeatures}
        templateConfiguration={templateConfigurations[currentTemplatesTab]}
        isAdmin={isAdmin}
      />
    ) : null}
    {currentTemplatesTab === templateTypes.CUSTOM_GRAPH ? (
      <CustomGraphTemplate
        styles={styles}
        tabProperties={tabProperties}
        mergeQuestionName={mergeQuestionName}
        setMergeQuestionName={setMergeQuestionName}
        questions={questions}
        questionBlocks={questionBlocks}
        features={features}
        setFeatures={setFeatures}
        templateConfiguration={templateConfigurations[currentTemplatesTab]}
        axisLabels={axisLabels}
        setAxisLabels={setAxisLabels}
        isAdmin={isAdmin}
      />
    ) : null}
    <div className={styles.bottomLine} />
  </div>
);
