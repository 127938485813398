import {
  TEMPLATES_WIZARD_STEP_KEYS,
  TEMPLATES_WIZARD_TEMPLATE_TYPES,
  TEMPLATES_SCALES,
  QUESTION_TYPES,
  SURVEY_END_TYPES,
  SURVEY_LANGUAGES,
  QUOTA_UNITS
} from '../../../helpers/constants';

import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import generateQuestions from './helpers/generateQuestions';
import getFeaturesForTemplate from './helpers/getFeaturesForTemplate/getFeaturesForTemplate';
import { removeStylingsFromString } from '../../../components/TemplatesWizard/helpers/styleText';

import enStrings from '../../../components/TemplatesWizard/strings/en';
import nlStrings from '../../../components/TemplatesWizard/strings/nl';
import frStrings from '../../../components/TemplatesWizard/strings/fr';

const ObjectID = require('bson-objectid');

const generateRichQuestion = strings => {
  if (!strings) {
    return '';
  }

  const question = {
    blocks: strings.map(s => ({
      key: Math.random()
        .toString(36)
        .substr(2, 5),
      text: s.text,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: s.inlineStyleRanges || [],
      entityRanges: [],
      data: {}
    })),
    entityMap: {}
  };

  return JSON.stringify(question);
};

export default wizardSteps => {
  const languageStep = wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_LANGUAGE
  );
  let strings;
  switch (languageStep.value) {
    case SURVEY_LANGUAGES.ENGLISH:
      strings = enStrings;
      break;
    case SURVEY_LANGUAGES.DUTCH:
      strings = nlStrings;
      break;
    case SURVEY_LANGUAGES.FRENCH:
      strings = frStrings;
      break;
    default:
      strings = enStrings;
  }

  const templateTypeStep = wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
  );

  const maxGroups =
    templateTypeStep.value === TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST
      ? 2
      : 3;

  let newQuestions = [];

  // PV doesn't have problem context
  let introQuestionString = null;
  if (
    templateTypeStep.value ===
    TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
  ) {
    introQuestionString = generateRichQuestion([
      {
        text: removeStylingsFromString(
          strings[templateTypeStep.value].INTRO_QUESTION_1
        )
      },
      {
        text: removeStylingsFromString(
          strings[templateTypeStep.value].INTRO_QUESTION_2
        ),
        inlineStyleRanges: []
      }
    ]);
  } else {
    // Problem context
    const contextStep = wizardSteps.find(
      s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SET_CONTEXT
    );

    const introQuestionTextArray =
      contextStep.value &&
      contextStep.value.split('\n').map(s => ({ text: s }));
    introQuestionString = generateRichQuestion(introQuestionTextArray);
  }
  const introQuestion = {
    id: ObjectID().toString(),
    type: QUESTION_TYPES.MESSAGE,
    question: introQuestionString
  };
  newQuestions.push(introQuestion);

  // Distributor
  const distributorQuestion = {
    id: ObjectID().toString(),
    type: QUESTION_TYPES.DISTRIBUTOR,
    question: generateRichQuestion([
      {
        text: 'Distributor'
      }
    ]),
    distributeEvenly: true
  };
  newQuestions.push(distributorQuestion);

  // Questions
  const scaleTypeStep = wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SET_SCALE
  );

  const selectTestsNumberStep = wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER
  );
  const groupsToCreate =
    selectTestsNumberStep.value > maxGroups
      ? maxGroups
      : selectTestsNumberStep.value;

  let newQuestionProperties = {};
  if (scaleTypeStep.value.type === TEMPLATES_SCALES.RATING_SCALE) {
    newQuestionProperties = {
      minValue: scaleTypeStep.value.values.min,
      maxValue: scaleTypeStep.value.values.max
    };
  } else if (scaleTypeStep.value.type === TEMPLATES_SCALES.MULTIPLE_CHOICE) {
    newQuestionProperties = {
      selectAtLeast: 1,
      selectAtMost: 1,
      choices: scaleTypeStep.value.values.values.map(c => ({
        id: ObjectID().toString(),
        answer: strings[c]
      }))
    };
  }

  const pointsStep = wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.DEFINE_POINTS
  );

  const newQuestionGroups = [...Array(selectTestsNumberStep.value).keys()].map(
    i => [
      {
        id: ObjectID().toString(),
        type:
          scaleTypeStep.value.type === TEMPLATES_SCALES.RATING_SCALE
            ? QUESTION_TYPES.RATING_SCALE
            : QUESTION_TYPES.MULTIPLE_CHOICE,
        question: generateRichQuestion(
          generateQuestions(
            {
              text: pointsStep.value[i],
              min:
                scaleTypeStep.value.type === TEMPLATES_SCALES.RATING_SCALE
                  ? scaleTypeStep.value.values.min
                  : null,
              max:
                scaleTypeStep.value.type === TEMPLATES_SCALES.RATING_SCALE
                  ? scaleTypeStep.value.values.max
                  : null
            },
            strings[templateTypeStep.value],
            scaleTypeStep.value.type,
            templateTypeStep.value,
            'A'
          )
        ),
        ...newQuestionProperties
      },
      {
        id: ObjectID().toString(),
        type:
          scaleTypeStep.value.type === TEMPLATES_SCALES.RATING_SCALE
            ? QUESTION_TYPES.RATING_SCALE
            : QUESTION_TYPES.MULTIPLE_CHOICE,
        question: generateRichQuestion(
          generateQuestions(
            {
              text: pointsStep.value[i],
              min:
                scaleTypeStep.value.type === TEMPLATES_SCALES.RATING_SCALE
                  ? scaleTypeStep.value.values.min
                  : null,
              max:
                scaleTypeStep.value.type === TEMPLATES_SCALES.RATING_SCALE
                  ? scaleTypeStep.value.values.max
                  : null
            },
            strings[templateTypeStep.value],
            scaleTypeStep.value.type,
            templateTypeStep.value,
            'B'
          )
        ),
        ...newQuestionProperties
      }
    ]
  );

  // Question IDs distributed per group
  const groupFlows = [];
  newQuestionGroups.forEach((qG, qGIndex) => {
    newQuestions = [...newQuestions, ...qG];

    const groupIndexToAddQuestions = qGIndex % groupsToCreate;
    groupFlows[groupIndexToAddQuestions] = [
      ...(groupFlows[groupIndexToAddQuestions] || []),
      qG[0].id.toString(),
      qG[1].id.toString()
    ];
  });

  // Survey Content
  const groups = [...Array(groupsToCreate).keys()].map((p, groupIndex) => ({
    id: ObjectID().toString(),
    flows: groupFlows[groupIndex],
    end: SURVEY_END_TYPES.SUCCESSFUL,
    label: `Group ${groupIndex + 1}`
  }));

  // Distributor branches
  distributorQuestion.branches = groups.map((g, i) => ({
    id: ObjectID().toString(),
    nextFlow: g.id.toString(),
    label: `Path ${i + 1}`,
    quotaType: QUOTA_UNITS.PERCENTAGE,
    quotaAmount:
      i < groups.length - 1
        ? Math.floor(100 / groups.length)
        : Math.ceil(100 / groups.length)
  }));

  const contentGroupIds = groups.map(g => g.id.toString());
  const surveyContent = {
    flows: [
      introQuestion.id.toString(),
      distributorQuestion.id.toString(),
      ...contentGroupIds
    ],
    groups
  };

  const questionsMapping = newQuestions.reduce(
    (acc, q) => ({
      ...acc,
      [q.id]: q
    }),
    {}
  );

  const features = getFeaturesForTemplate(
    newQuestions,
    templateTypeStep.value ===
      TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
      ? 'importanceQuestion'
      : 'xQuestion',
    templateTypeStep.value ===
      TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
      ? 'satisfactionQuestion'
      : 'yQuestion',
    templateTypeStep.value,
    pointsStep.value,
    languageStep.value
  );

  return {
    newQuestions,
    questionsMapping,
    surveyContent,
    surveyLists: generateSurveyLists(surveyContent, questionsMapping),
    features
  };
};
