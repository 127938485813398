import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

const RangeSelector = props => {
  const {
    minValue,
    maxValue,
    initialMinValue,
    initialMaxValue,
    disabled,
    audienceGroupName,
    setTargetAudiencesConfigure,
    greaterThanOperator = '$gte',
    lessThanOperator = '$lte',
    userFilter
  } = props;
  const [sliderValue, setSliderValue] = useState({
    min: initialMinValue,
    max: initialMaxValue
  });
  const [showSlider, setShowSlider] = useState(false);

  useEffect(
    () =>
      setSliderValue({
        min: initialMinValue,
        max: initialMaxValue
      }),
    [initialMinValue, initialMaxValue]
  );

  useEffect(
    () => {
      setShowSlider(false);
      setTimeout(() => setShowSlider(true), 1); // hacky solution to prevent range transition on userFilter change
    },
    [userFilter]
  );

  if (!showSlider) return null;

  return (
    <InputRange
      disabled={disabled}
      draggableTrack
      step={1}
      maxValue={maxValue}
      minValue={minValue}
      value={sliderValue}
      onChange={value => {
        const draggedValue = value;
        if (value.min < minValue) {
          draggedValue.min = minValue;
        }
        if (value.max > maxValue) {
          draggedValue.max = maxValue;
        }
        if (value.max === value.min && value.max === maxValue) {
          draggedValue.min -= 5;
        }
        if (value.max === value.min && value.min === minValue) {
          draggedValue.max += 5;
        }
        setSliderValue(draggedValue);
      }}
      onChangeComplete={value => {
        const finalValue = {
          [greaterThanOperator]: value.min,
          [lessThanOperator]: value.max
        };
        return setTargetAudiencesConfigure({
          ...{ [audienceGroupName]: finalValue }
        });
      }}
    />
  );
};

export default RangeSelector;
