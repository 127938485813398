import * as mixpanel from '../../../../../../../mixpanel';

export default wizardSteps => {
  const templateAttributes = {};
  const returnRightProp = step => {
    switch (step.key) {
      case 'SELECT_LANGUAGE':
        templateAttributes[mixpanel.eventProperties.SURVEY_TEMPLATE_LANGUAGE] =
          step.value;
        break;
      case 'SELECT_TEMPLATE_TYPE':
        templateAttributes[mixpanel.eventProperties.SURVEY_TEMPLATE_TYPE] =
          step.value;
        break;
      case 'SET_CONTEXT':
        templateAttributes[
          mixpanel.eventProperties.SURVEY_TEMPLATE_PROBLEM_CONTEXT
        ] = step.value;
        break;
      case 'SELECT_TESTS_NUMBER':
        templateAttributes[
          mixpanel.eventProperties.SURVEY_TEMPLATE_AMOUNT_OF_TESTS
        ] = step.value;
        break;
      case 'DEFINE_POINTS':
        templateAttributes[
          mixpanel.eventProperties.SURVEY_TEMPLATE_DEFINED_POINTS
        ] = step.value;
        break;
      case 'SET_SCALE':
        templateAttributes[
          mixpanel.eventProperties.SURVEY_TEMPLATE_QUESTIONS_TYPE
        ] = step.value.type;
        templateAttributes[
          mixpanel.eventProperties.SURVEY_TEMPLATE_SCALE
        ] = step.value.values.max
          ? `${step.value.values.min}-${step.value.values.max}`
          : `0-${step.value.values.values.length}`;
        break;
      case 'SET_SUCCESS_CRITERIA':
        templateAttributes[
          mixpanel.eventProperties.SURVEY_TEMPLATE_DEFINED_POINTS
        ] = JSON.stringify(step.value);
        break;
      default:
        break;
    }
  };
  wizardSteps.forEach(step => {
    returnRightProp(step);
  });
  mixpanel.actions.trackEvent(
    mixpanel.events.CLIENT_CREATE_SURVEY_TEMPLATE,
    templateAttributes
  );
};
