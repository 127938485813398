import React from 'react';

import { FILTER_RELATIONS } from '../../../../../../helpers/constants';

import styles from './FiltersRelation.module.css';

export default ({ filterRelation, setFilterRelation, disabledRelation }) => {
  const onChangeRelationClick = () => {
    if (filterRelation === FILTER_RELATIONS.AND) {
      setFilterRelation(FILTER_RELATIONS.OR);
    }

    if (filterRelation === FILTER_RELATIONS.OR) {
      setFilterRelation(FILTER_RELATIONS.AND);
    }
  };

  const disabled = disabledRelation && disabledRelation.disabled;

  return (
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ''}`}
      role="presentation"
      onClick={() => {
        if (!disabled) {
          onChangeRelationClick();
        }
      }}
    >
      {filterRelation}
      {disabled && disabledRelation.message ? (
        <div className={styles.tooltip}>{disabledRelation.message}</div>
      ) : null}
    </div>
  );
};
