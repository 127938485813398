import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import moveQuestion from '../moveQuestion/moveQuestion';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  const newState = moveQuestion(
    surveysState,
    false,
    undefined,
    undefined,
    undefined,
    undefined,
    action.destinationPosition,
    action.destinationQuestionId,
    action.groupId
  );
  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  // Update survey
  const surveyUpdateContent = {
    survey: newState.newState.survey.id,
    content: newState.newState.survey.content
  };
  action.api('updateSurvey', { variables: surveyUpdateContent });

  // If flow jump from other question after this action is changed, update it
  if (
    newState.changedFlowsRemovedJumpsToUpdate &&
    newState.changedFlowsRemovedJumpsToUpdate.length
  ) {
    newState.changedFlowsRemovedJumpsToUpdate.forEach(q => {
      if (q && !q.flows) {
        action.api('updateQuestion', { question: { ...q } });
      }
    });
  }
}
