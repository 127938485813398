import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported
import Icon from '../../../../../../../../../../../Icon/Icon';

import styles from '../../../../MCAnswers.module.css';

const AnswerThumbnail = props => {
  const { answer, onRemoveCoverPhoto, onMediaUpload, allowEdit } = props;

  const [isOpen, setIsOpen] = useState(false);

  if (!allowEdit && answer && !answer.imageFilename) {
    return null;
  }

  return (
    <div className={styles.answerCoverImageContainer}>
      <div className={styles.leftContainer}>
        {answer && answer.image ? (
          [
            <div
              role="presentation"
              className={styles.coverImagePreview}
              style={{
                backgroundImage: `url(${answer.image})`
              }}
              onClick={() => {
                setIsOpen(true);
              }}
              key={`${answer.id}-thumbnail`}
            />,
            isOpen && (
              <Lightbox
                mainSrc={`${answer.image}`}
                onCloseRequest={() => setIsOpen(false)}
                key={`${answer.id}-lightbox`}
              />
            )
          ]
        ) : (
          <span className={styles.imagePlaceholder}>
            <Icon type="image" />
          </span>
        )}
      </div>
      {allowEdit ? (
        <div className={styles.rightContainer}>
          {answer && answer.image ? (
            <span
              role="presentation"
              className={styles.action}
              onClick={onRemoveCoverPhoto}
            >
              <Icon type="x" className={styles.actionButton} />
            </span>
          ) : (
            <span className={styles.action}>
              <input
                type="file"
                onChange={onMediaUpload}
                accept="'.gif,.jpg,.jpeg,.png,.bmp'"
                className={styles.fileUploadInput}
              />
              <Icon type="add" className={styles.actionButton} />
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AnswerThumbnail;
