export default (graph, features) =>
  [...features].map((f, i) => {
    const unfilteredId =
      graph &&
      graph.unFilteredFeatures &&
      graph.unFilteredFeatures[i] &&
      graph.unFilteredFeatures[i].id
        ? graph.unFilteredFeatures[i].id
        : null;
    const unfilteredF = {
      ...f,
      ...(unfilteredId ? { id: unfilteredId } : {})
    };
    if (!unfilteredId) {
      delete unfilteredF.id;
    }
    return unfilteredF;
  });
