import React, { useState, useEffect } from 'react';

import InfoIcon from '../../../../../../../../../../../../../../assets/img/info.svg';
import TickIcon from '../../../../../../../../../../../../../../assets/img/tick_white.svg';

import styles from './MultipleAnswersProperties.module.css';

export default ({
  choices,
  ifQuotaIsActive,
  selectAtLeast,
  selectAtMost,
  isRanking,
  actions,
  isActiveQuestion,
  setActiveQuestion
}) => {
  const [multipleAnswersMaxClicked, setMultipleAnswersMaxClicked] = useState(
    false
  );
  const [choicesLength, setChoicesLength] = useState(
    choices && choices.length ? choices.length : 0
  );
  const [multipleAnswersPossible, setMultipleAnswersPossible] = useState(
    selectAtLeast !== 1 || selectAtMost !== 1 || isRanking
  );

  useEffect(
    () => {
      if (
        choices &&
        choicesLength !== choices.length &&
        multipleAnswersPossible &&
        multipleAnswersMaxClicked
      ) {
        actions.updateQuestionProperty(
          'selectAtMost',
          parseInt(choices.length, 10)
        );
        setChoicesLength(choices.length);
      }
    },
    [choices]
  );

  const choiceToNode =
    choices && choices.nextFlow ? choices.filter(c => c.nextFlow) : null;

  const logicJumpOnMultipleAnswers =
    choiceToNode &&
    choiceToNode.length > 0 &&
    (selectAtLeast !== 1 || selectAtMost !== 1);

  return (
    <div
      className={`${styles.multipleAnswersPossibleContainer} ${
        logicJumpOnMultipleAnswers || ifQuotaIsActive
          ? styles.multipleAnswersNotPossible
          : ''
      }`}
    >
      {logicJumpOnMultipleAnswers || ifQuotaIsActive ? (
        <>
          {ifQuotaIsActive ? (
            <div className={styles.logicJumpError}>
              <img className={styles.infoIcon} src={InfoIcon} alt="Warning" />
              <span className={styles.logicErrorMessage}>
                Warning: Quota on single answers can only be applied if multiple
                answers possible is toggled off.
              </span>
            </div>
          ) : null}
          {logicJumpOnMultipleAnswers ? (
            <div className={styles.logicJumpError}>
              <img className={styles.infoIcon} src={InfoIcon} alt="Warning" />
              <span className={styles.logicErrorMessage}>
                Multiple answers and logic jumps cannot coexist.
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <div className={styles.multipleAnswersPossibleControls}>
          {!isRanking && (
            <span role="presentation">
              <label className="switch" htmlFor="checkbox">
                <input
                  type="checkbox"
                  checked={multipleAnswersPossible}
                  disabled={ifQuotaIsActive}
                  readOnly
                />
                <span
                  className="slider round"
                  role="presentation"
                  onClick={async () => {
                    const newMultipleAnswersPossible = !multipleAnswersPossible;
                    if (!isActiveQuestion) {
                      await setActiveQuestion();
                    }
                    if (newMultipleAnswersPossible) {
                      const totalChoices =
                        choices && choices.length ? choices.length : 1;
                      actions.updateQuestionProperty(
                        'selectAtMost',
                        parseInt(totalChoices, 10)
                      );
                    } else {
                      if (selectAtLeast !== 1)
                        actions.updateQuestionProperty('selectAtLeast', 1);
                      actions.updateQuestionProperty('selectAtMost', 1);
                    }
                    setMultipleAnswersPossible(newMultipleAnswersPossible);
                  }}
                />
              </label>
            </span>
          )}
          <span className={styles.multipleAnswersPossibleText}>
            Multiple answers possible
          </span>
          {multipleAnswersPossible ? (
            <>
              <label className={styles.inputContainer} htmlFor="min">
                Min
                <input
                  name="min"
                  type="number"
                  onKeyDown={evt =>
                    evt.key === 'ArrowDown' ||
                    evt.key === 'ArrowUp' ||
                    evt.key === 'e' ||
                    evt.key === 'E' ||
                    evt.key === '.' ||
                    evt.key === '-'
                      ? evt.preventDefault()
                      : true
                  }
                  onChange={e =>
                    actions.updateQuestionProperty(
                      'selectAtLeast',
                      parseInt(e.target.value, 10)
                    )
                  }
                  onWheel={e => e.target.blur()}
                  value={selectAtLeast}
                />
              </label>
              <label className={styles.inputContainer} htmlFor="max">
                Max
                <input
                  type="number"
                  name="max"
                  onKeyDown={evt =>
                    evt.key === 'ArrowDown' ||
                    evt.key === 'ArrowUp' ||
                    evt.key === 'e' ||
                    evt.key === 'E' ||
                    evt.key === '.' ||
                    evt.key === '-'
                      ? evt.preventDefault()
                      : true
                  }
                  onChange={e =>
                    actions.updateQuestionProperty(
                      'selectAtMost',
                      parseInt(e.target.value, 10)
                    )
                  }
                  onWheel={e => e.target.blur()}
                  value={selectAtMost}
                  disabled={multipleAnswersMaxClicked}
                />
              </label>
              <div className={styles.setToMax}>
                <div
                  className={`${styles.visibleBox} ${
                    multipleAnswersMaxClicked ? styles.visibleBoxActive : ''
                  }`}
                  role="presentation"
                  onClick={async () => {
                    if (!isActiveQuestion) {
                      await setActiveQuestion();
                    }
                    setMultipleAnswersMaxClicked(!multipleAnswersMaxClicked);
                    actions.updateQuestionProperty(
                      'selectAtMost',
                      parseInt(choices.length, 10)
                    );
                    setChoicesLength(choices.length);
                  }}
                >
                  <img
                    src={TickIcon}
                    alt="tick icon"
                    className={styles.tickIcon}
                  />
                </div>
                Always set to max
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};
