import React from 'react';

import styles from './UploadMedia.module.css';
import useLazyQuery from '../../../../../../../../../hooks/useLazyQuery';

import { GET_MEDIA_UPLOAD_SAS } from '../../../../../../../../../../graphql/Survey';

export default ({ label, value, allowedFileTypes, actions }) => {
  const getMediaUploadSAS = useLazyQuery(GET_MEDIA_UPLOAD_SAS, {
    fetchPolicy: 'no-cache'
  });

  const onMediaUpload = async e => {
    e.persist();
    const origFilename = e.target.files[0].name;
    const isVideo =
      origFilename.substring(origFilename.length - 3, origFilename.length) ===
      'mp4';

    actions.setCustomLoading(true);

    const {
      data: { mediaUploadSAS: { uri: sasURI, filename, uri } = {} } = {}
    } = await getMediaUploadSAS({
      variables: {
        fileName: origFilename
      }
    });

    if (!sasURI) {
      // FIXME: error
      return;
    }

    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      actions.setCustomLoading(false);
      if (xhr.status === 201) {
        const fileUri = uri.split('?');
        actions.updateQuestionProperty('media', filename);
        actions.updateQuestionProperty('mediaFilename', fileUri[0]);
      }
    };

    xhr.open('PUT', sasURI, true);

    if (isVideo) {
      xhr.setRequestHeader('Content-Type', 'video/mp4');
    } else {
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
    }

    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

    xhr.send(e.target.files[0]);
  };

  return (
    <div className={styles.container}>
      <span>
        {label.split('\n').map((l, i, arr) => (
          <React.Fragment key={l}>
            {l}
            {i !== arr.length - 1 && <br />}
          </React.Fragment>
        ))}
      </span>
      {value ? (
        <span
          role="presentation"
          className="action remove"
          onClick={() => {
            actions.updateQuestionProperty('media', null);
            actions.updateQuestionProperty('mediaFilename', null);
          }}
        >
          Remove
        </span>
      ) : (
        <span className="action">
          <input
            type="file"
            onChange={onMediaUpload}
            accept={allowedFileTypes}
            className="file-upload-input"
          />
          <span>Upload</span>
        </span>
      )}
    </div>
  );
};
