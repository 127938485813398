export default (
  questions,
  questionBlocks,
  features,
  xQuestionName,
  yQuestionName
) => {
  let filteredQuestions = questionBlocks.filter(block => {
    const questionBlock = questions.find(q => q.id === block.id);

    if (questionBlock.ranked) {
      return false;
    }

    return (
      (block.type === 'Multiple Choice Question' || block.type === 'Slider') &&
      !block.ranked
    );
  });

  if (features && features.length && features[0]) {
    const xQuestion =
      features[0][xQuestionName] && features[0][xQuestionName].id;
    const yQuestion =
      features[0][yQuestionName] && features[0][yQuestionName].id;

    const firstQuestionId = xQuestion || yQuestion;
    const firstQuestion = questions.find(q => q.id === firstQuestionId);

    if (firstQuestion) {
      filteredQuestions = filteredQuestions.filter(b => {
        const bQuestion = questions.find(q => q.id === b.id);
        if (
          (firstQuestion.ranked && !bQuestion.ranked) ||
          (!firstQuestion.ranked && bQuestion.ranked)
        ) {
          return false;
        }
        return b.type === firstQuestion.type;
      });
    }
  }

  // Add icon property
  if (filteredQuestions && filteredQuestions.length) {
    filteredQuestions = filteredQuestions.map(q => {
      const fullQuestionDetails = questions.find(
        question => question.id === q.id
      );

      if (fullQuestionDetails && fullQuestionDetails.type === 'Slider') {
        return { ...q, icon: 'rating' };
      }

      if (fullQuestionDetails && fullQuestionDetails.ranked) {
        return { ...q, icon: 'ranking' };
      }

      return { ...q, icon: 'multiple-choice' };
    });
  }

  return filteredQuestions;
};
