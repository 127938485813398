import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';

const ObjectID = require('bson-objectid');

export default (state, groupId, questionIndexInSet) => {
  const newSurvey = JSON.parse(JSON.stringify(state.survey));

  const groupToUngroup = newSurvey.content.groups.find(g => g.id === groupId);

  if (groupToUngroup !== undefined) {
    // Main flow
    const groupIndexInRootArray = newSurvey.content.flows.indexOf(groupId);

    if (groupIndexInRootArray > -1) {
      if (
        (groupToUngroup.flows && groupToUngroup.flows.length <= 2) ||
        questionIndexInSet === undefined
      ) {
        newSurvey.content.flows.splice(
          groupIndexInRootArray,
          0,
          ...groupToUngroup.flows
        );

        newSurvey.content.flows = newSurvey.content.flows.filter(
          f => f !== groupId
        );

        // Remove group
        newSurvey.content.groups = newSurvey.content.groups.filter(
          g => g.id !== groupId
        );
      } else {
        const groupFlows = [...groupToUngroup.flows]; // After splicing groupFlows becomes firstGroupPart
        const secondGroupPart = groupFlows.splice(questionIndexInSet + 1);

        if (groupFlows.length === 1) {
          newSurvey.content.flows.splice(
            groupIndexInRootArray,
            0,
            groupFlows[0]
          );

          // Remove ungrouped question
          groupToUngroup.flows = groupToUngroup.flows.filter(
            f => f !== groupFlows[0]
          );
        } else if (secondGroupPart.length === 1) {
          newSurvey.content.flows.splice(
            groupIndexInRootArray + 1,
            0,
            secondGroupPart[0]
          );

          // Remove ungrouped question
          groupToUngroup.flows = groupToUngroup.flows.filter(
            f => f !== secondGroupPart[0]
          );
        } else {
          const newGroupId = ObjectID().toString();

          const newGroup = {
            id: newGroupId,
            label: `Group ${newSurvey.content.groups.length + 1}`,
            flows: secondGroupPart
          };

          // Add new group Id in the content
          newSurvey.content.flows.splice(
            groupIndexInRootArray + 1,
            0,
            newGroupId
          );

          // Remove ungrouped question
          groupToUngroup.flows = groupFlows;
          newSurvey.content.groups.push(newGroup);
        }
      }
    }
  }

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      content: newSurvey.content,
      questions: newSurvey.questions
    }
  };

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  // Sort distributor groups according to branch jumps
  sortDistributorGroupsAndJumps(newState);

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return { newState };
};
