import React from 'react';

import Icon from '../Icon';
import './Button.css';

const Button = props => {
  const { type, disabled, icon, label, onClick, customStyle } = props;

  let buttonClass = customStyle
    ? `app-bar-button ${customStyle}`
    : 'app-bar-button button';
  if (type === 'primary') {
    if (disabled) {
      buttonClass = `${buttonClass} primary-disabled`;
    } else {
      buttonClass = `${buttonClass} primary-active`;
    }
  } else if (type === 'secondary') {
    if (disabled) {
      buttonClass = `${buttonClass} secondary-disabled`;
    } else {
      buttonClass = `${buttonClass} secondary-active`;
    }
  } else if (type === 'black') {
    buttonClass = `${buttonClass} black-active`;
  } else if (type === 'white') {
    if (disabled) {
      buttonClass = `${buttonClass} white-disabled`;
    } else {
      buttonClass = `${buttonClass} white-active`;
    }
  }

  return (
    <div role="presentation" className={buttonClass} onClick={onClick}>
      {icon ? (
        <span className="button-icon">
          <Icon type={icon} />
        </span>
      ) : null}
      <span>{label}</span>
    </div>
  );
};

export default Button;

Button.defaultProps = {
  type: 'primary',
  disabled: false,
  icon: null
};
