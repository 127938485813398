import React from 'react';

import DropdownSelector from '../DropdownSelector/DropdownSelector';

import styles from './SearchDropdown.module.css';

export default ({
  fetchOptions,
  placeholder,
  onSelectCompleted,
  value,
  disableDropdownExpand
}) => (
  <div>
    <div className={styles.filterItemContainer}>
      <div className={styles.filterType}>
        <DropdownSelector
          value={value}
          onSelectCompleted={onSelectCompleted}
          fetchOptions={fetchOptions}
          placeholder={placeholder}
          disableDropdownExpand={disableDropdownExpand}
          search
        />
      </div>
    </div>
  </div>
);
