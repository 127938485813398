import React from 'react';

import closeIcon from '../../../../../../assets/img/delete.svg';

import styles from './WarningPopup.module.css';

export default ({ title, description, onClose }) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
    <div className={styles.footer}>
      <div />
      <div className={styles.actions}>
        <img
          className={styles.close}
          src={closeIcon}
          alt="Close icon"
          onClick={onClose}
          role="presentation"
        />
      </div>
    </div>
  </div>
);
