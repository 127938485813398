export default (value, name, linkCopied, setLinkCopied) => {
  setLinkCopied({ ...linkCopied, ...{ [name]: true } });

  const dummy = document.createElement('input');
  // dummy.style.display = 'none';
  document.body.appendChild(dummy);

  dummy.setAttribute('id', 'goalurl');
  document.getElementById('goalurl').value = value;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};
