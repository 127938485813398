import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './store';
import './index.css';

// import 'typeface-roboto';

import App from './App';

let apiUrl;
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  apiUrl = 'http://localhost:8080';
} else if (process.env.REACT_APP_ENV === 'qa') {
  apiUrl = 'https://test.buffl.be';
} else {
  apiUrl = 'https://buffl-prod-backend.azurewebsites.net';
  // apiUrl = 'https://buffl.be';
}
const webSocketUrl = apiUrl;

const store = createStore(apiUrl);

render(
  <Provider store={store}>
    <App apiUrl={apiUrl} webSocketUrl={webSocketUrl} />
  </Provider>,
  document.getElementById('root')
);
