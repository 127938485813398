import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import styles from './Bar.module.css';

const Bar = props => {
  const {
    data,
    width,
    height,
    unFilteredData,
    isFiltered,
    xLabel,
    yLabel,
    axisEdgeLabels,
    questions,
    themeColors,
    dataPointColors,
    dataTooltips,
    setDataTooltips,
    onTooltipAdd,
    onTooltipRemove,
    onDataPointClick
  } = props;
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    drawChart(svg, props, dataTooltips);
  }, []);

  useEffect(
    () => {
      drawChart(svg, props, dataTooltips);
    },
    [
      svg,
      data,
      isFiltered,
      axisEdgeLabels,
      questions,
      themeColors,
      unFilteredData,
      dataPointColors
    ]
  );

  const drawChart = (svgRef, chartProps, tooltips) => {
    if (chartProps.parent && chartProps.parent.current.offsetWidth) {
      const { scaleQuestionMinMax, unFilteredDataPointColors } = chartProps;

      const themeColor = themeColors.reduce(
        (acc, tC) => ({
          ...acc,
          [tC.name]: tC.colorCode
        }),
        {}
      );

      const margin = {
        top: 20,
        right: 20,
        bottom: 30,
        left: 40
      };

      const chartSvg = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      chartSvg.selectAll('*').remove();

      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      const chart = chartSvg
        .append('g')
        .attr('width', chartWidth)
        .attr('height', chartHeight)
        .attr('class', 'results-bar-container')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      const x = d3.scaleLinear().range([0, chartWidth]);
      x.domain([
        scaleQuestionMinMax.xQuestionMin,
        scaleQuestionMinMax.xQuestionMax
      ]);

      const y = d3.scaleLinear().range([chartHeight, 0]);
      y.domain([
        scaleQuestionMinMax.yQuestionMin,
        scaleQuestionMinMax.yQuestionMax
      ]);

      const x6position =
        (scaleQuestionMinMax.xQuestionMax - scaleQuestionMinMax.xQuestionMin) *
          0.6 +
        scaleQuestionMinMax.xQuestionMin;
      const y6position =
        (scaleQuestionMinMax.yQuestionMax - scaleQuestionMinMax.yQuestionMin) *
          0.6 +
        scaleQuestionMinMax.yQuestionMin;

      const renderTopLeftPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(scaleQuestionMinMax.xQuestionMin), y(y6position));
        greenPath.lineTo(x(x6position), y(y6position));
        greenPath.lineTo(x(x6position), y(scaleQuestionMinMax.yQuestionMax));
        greenPath.lineTo(
          x(scaleQuestionMinMax.xQuestionMin),
          y(scaleQuestionMinMax.yQuestionMax)
        );
        greenPath.closePath();
        return greenPath;
      };

      const renderBottomLeftPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(
          x(scaleQuestionMinMax.xQuestionMin),
          y(scaleQuestionMinMax.yQuestionMin)
        );
        greenPath.lineTo(x(x6position), y(scaleQuestionMinMax.yQuestionMin));
        greenPath.lineTo(x(x6position), y(y6position));
        greenPath.lineTo(x(scaleQuestionMinMax.xQuestionMin), y(y6position));
        greenPath.closePath();
        return greenPath;
      };

      const renderTopRightPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(x6position), y(y6position));
        greenPath.lineTo(x(scaleQuestionMinMax.xQuestionMax), y(y6position));
        greenPath.lineTo(
          x(scaleQuestionMinMax.xQuestionMax),
          y(scaleQuestionMinMax.yQuestionMax)
        );
        greenPath.lineTo(x(x6position), y(scaleQuestionMinMax.yQuestionMax));
        greenPath.closePath();
        return greenPath;
      };

      const renderBottomRightPath = () => {
        const path = d3.path();
        path.moveTo(x(x6position), y(y6position));
        path.lineTo(x(scaleQuestionMinMax.xQuestionMax), y(y6position));
        path.lineTo(
          x(scaleQuestionMinMax.xQuestionMax),
          y(scaleQuestionMinMax.yQuestionMin)
        );
        path.lineTo(x(x6position), y(scaleQuestionMinMax.yQuestionMin));
        path.closePath();
        return path;
      };

      // Top left path shape
      chart
        .append('path')
        .attr('d', renderTopLeftPath())
        .attr('fill', themeColor.COLOR_1)
        .attr('opacity', 1);

      // Bottom left path shape
      chart
        .append('path')
        .attr('d', renderBottomLeftPath())
        .attr('fill', themeColor.COLOR_3)
        .attr('opacity', 1);

      // Top right path shape
      chart
        .append('path')
        .attr('d', renderTopRightPath())
        .attr('fill', themeColor.COLOR_2)
        .attr('opacity', 1);

      // Bottom right path shape
      chart
        .append('path')
        .attr('d', renderBottomRightPath())
        .attr('fill', themeColor.COLOR_4)
        .attr('opacity', 1);

      // X Axis
      chart
        .append('g')
        .attr('class', styles.customGraphAxis)
        .attr('transform', `translate(0,${chartHeight})`)
        .call(
          d3
            .axisBottom(x)
            .scale(x)
            .ticks(
              scaleQuestionMinMax.xQuestionMax -
                scaleQuestionMinMax.xQuestionMin
            )
            .tickValues([
              scaleQuestionMinMax.xQuestionMin,
              scaleQuestionMinMax.xQuestionMax
            ])
        )
        .style('color', '#c7c7c7')
        .style('stroke-width', 1);

      // Y Axis
      chart
        .append('g')
        .attr('class', styles.customGraphAxis)
        .call(
          d3
            .axisLeft(y)
            .scale(y)
            .ticks(
              scaleQuestionMinMax.yQuestionMax -
                scaleQuestionMinMax.yQuestionMin
            )
            .tickValues([
              scaleQuestionMinMax.yQuestionMin,
              scaleQuestionMinMax.yQuestionMax
            ])
        )
        .style('color', '#c7c7c7')
        .style('stroke-width', 1);

      // Background grid horizontal
      chart
        .append('g')
        .attr('class', 'grid')
        .call(
          d3
            .axisLeft(y)
            .ticks(
              scaleQuestionMinMax.yQuestionMax -
                scaleQuestionMinMax.yQuestionMin
            )
            .tickSize(-width + margin.left + margin.right)
            .tickFormat('')
        )
        .style('color', '#e3e3e3');

      // Background grid vertical
      chart
        .append('g')
        .attr('class', 'grid')
        .call(
          d3
            .axisBottom(x)
            .ticks(
              scaleQuestionMinMax.xQuestionMax -
                scaleQuestionMinMax.xQuestionMin
            )
            .tickSize(height - margin.bottom - margin.top)
            .tickFormat('')
        )
        .style('color', '#e3e3e3');

      // Features calculated weight (x: importance, y: satisfaction) represented by a letter (A, B, C, ...)
      chart
        .selectAll('.feature')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', d => (d.x || d.x === 0 ? x(d.x) : -150)) // hacky solution to hide data point
        .attr('cy', d => (d.y || d.y === 0 ? y(d.y) : -150)) // hacky solution to hide data point
        .attr('r', 5)
        .attr('fill', (_d, i) => dataPointColors[i])
        .style('opacity', '1')
        .on('mouseover', (d, i) => {
          if (!tooltips.some(t => t.index === i)) {
            onTooltipAdd(setDataTooltips, d, i, 1, dataPointColors);
          }
        })
        .on('mouseout', (_d, i) => {
          if (!tooltips.some(t => t.index === i && t.click)) {
            onTooltipRemove(setDataTooltips, i);
          }
        })
        .on('click', (_d, i) => onDataPointClick(setDataTooltips, i));

      if (isFiltered) {
        // Filtered features
        chart
          .selectAll('.feature')
          .data(unFilteredData)
          .enter()
          .append('circle')
          .attr('cx', d => (d.x || d.x === 0 ? x(d.x) : -150)) // hacky solution to hide data point
          .attr('cy', d => (d.y || d.y === 0 ? y(d.y) : -150)) // hacky solution to hide data point
          .attr('r', 5)
          .attr('fill', (_d, i) => unFilteredDataPointColors[i])
          .style('opacity', '0.4')
          .on('mouseover', (d, i) => {
            if (!tooltips.some(t => t.index === `${i}_unfiltered`)) {
              onTooltipAdd(
                setDataTooltips,
                d,
                i,
                0.4,
                unFilteredDataPointColors,
                true
              );
            }
          })
          .on('mouseout', (_d, i) => {
            if (!tooltips.some(t => t.index === `${i}_unfiltered` && t.click)) {
              onTooltipRemove(setDataTooltips, `${i}_unfiltered`);
            }
          })
          .on('click', (_d, i) =>
            onDataPointClick(setDataTooltips, `${i}_unfiltered`)
          );
      }

      chart
        .selectAll('.feature')
        .data(dataTooltips)
        .enter()
        .append('rect')
        .attr('width', '75px')
        .attr('height', '21px')
        .attr('x', d => x(d.x) - 37.5)
        .attr('y', d => y(d.y) - 30)
        .attr('rx', '2')
        .attr('font-family', 'Open Sans Regular')
        .attr('font-size', '11px')
        .attr('fill', '#FFFFFF')
        .style('background-color', '#ffffff')
        .style('stroke', '#efefef')
        .style('padding', '10px')
        .style('border-radius', '5px')
        .on('mouseout', d => {
          // Remove to prevent hacky behaviour of tooltip persisting when not clicked
          if (!d.click) {
            onTooltipRemove(setDataTooltips, d.index);
          }
        });

      chart
        .selectAll('.feature')
        .data(dataTooltips)
        .enter()
        .append('text')
        .attr('font-family', 'Open Sans Regular')
        .attr('x', d => x(d.x))
        .attr('y', d => y(d.y) - 15)
        .style('text-anchor', 'middle')
        .text(
          d =>
            `(${d.x ? Math.round(d.x * 100) / 100 : d.x}; ${
              d.y ? Math.round(d.y * 100) / 100 : d.y
            })`
        )
        .attr('font-size', '12px')
        .attr('fill', d => d.color)
        .attr('opacity', d => d.opacity)
        .on('mouseout', d => {
          // Remove to prevent hacky behaviour of tooltip persisting when not clicked
          if (!d.click) {
            onTooltipRemove(setDataTooltips, d.index);
          }
        });
    }
  };

  return (
    <div>
      <svg
        className="filter-results-charts-bar"
        ref={elem => {
          if (elem) {
            setSvg(elem);
          }
        }}
      />
      <span
        className={`${styles.xAxisLabel} ${styles.xAxisLabelRating}`}
        title={xLabel}
      >
        {xLabel}
      </span>
      <span
        className={`${styles.yAxisLabel} ${styles.yAxisLabelRating}`}
        title={yLabel}
      >
        {yLabel}
      </span>
    </div>
  );
};

export default Bar;
