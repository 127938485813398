import calculateWeight from './calculateWeight';
import calculateRange from './calculateRange';

export default (
  features,
  unFilteredFeatures,
  useUnfilteredResultStats,
  questionTypes,
  reducedQuestions,
  filteringOnSelection,
  { graphRange = { min: 0, max: 10 } } = {}
) => {
  const chartData = [];
  let featuresToUse = [];

  if (
    useUnfilteredResultStats &&
    unFilteredFeatures &&
    unFilteredFeatures.length
  ) {
    featuresToUse = [...unFilteredFeatures.map(f => ({ ...f }))];
  } else {
    featuresToUse = [...features.map(f => ({ ...f }))];
  }

  const returnMatrixValues = (stats, question, feature) => {
    const questionValues =
      stats.values && stats.values.length
        ? stats.values.find(
            matrixOption =>
              matrixOption.questionId === question.id &&
              feature.choiceId &&
              feature.choiceId === matrixOption.choiceId
          )
        : null;
    let values = [];
    if (questionValues && questionValues.values) {
      values = [...questionValues.values];
    }
    return values;
  };

  featuresToUse.forEach(feature => {
    if (feature.active) {
      const graphData = {
        x: null,
        y: null
      };

      const featureQuestions = reducedQuestions.reduce(
        (acc, currentQuestion) => {
          const curr = { ...currentQuestion };
          if (curr.type === 'Matrix') {
            // Get stats for a matrix question + choice
            curr.unfilteredResultStats = {
              ...curr.unfilteredResultStats,
              values: returnMatrixValues(
                curr.unfilteredResultStats,
                currentQuestion,
                feature
              )
            };

            curr.resultStats = {
              ...curr.resultStats,
              values: returnMatrixValues(
                curr.resultStats,
                currentQuestion,
                feature
              )
            };
          }

          let currValues = [
            ...curr[
              useUnfilteredResultStats ? 'unfilteredResultStats' : 'resultStats'
            ].values
          ]
            .filter(r => (r.x || r.x === 0) && (r.y || r.y === 0))
            .map(r => ({ ...r }));

          if (useUnfilteredResultStats && filteringOnSelection) {
            const filteredData = curr.resultStats.values.filter(
              r => (r.x || r.x === 0) && (r.y || r.y === 0)
            );
            /* eslint-disable no-param-reassign */
            currValues = currValues.reduce(
              (unfilteredValuesWithoutSelection, currentDataPoint) => {
                if (filteredData && filteredData.length) {
                  const matchingValue = filteredData.find(
                    datapoint => datapoint.x === currentDataPoint.x
                  );
                  if (matchingValue) {
                    currentDataPoint.y -= matchingValue.y;
                  }
                }
                if (currentDataPoint.y > 0) {
                  unfilteredValuesWithoutSelection.push(currentDataPoint);
                }
                return unfilteredValuesWithoutSelection;
              },
              []
            );
            /* eslint-enable no-param-reassign */
          }

          // eslint-disable-next-line prefer-destructuring
          let maxValue = curr.maxValue;
          if (
            curr.type === 'Multiple Choice Question' &&
            curr.answers &&
            curr.answers.length
          ) {
            maxValue = curr.answers.length - 1;
          } else if (curr.type === 'Matrix' && curr.selectAtMost) {
            maxValue = curr.selectAtMost - 1; // Matrix default: 4 (selectAtMost) answers options
          }

          if (
            curr.id === feature[questionTypes.xQuestion] ||
            curr.id === feature[questionTypes.yQuestion]
          ) {
            acc.push({
              values: currValues,
              type:
                curr.id === feature[questionTypes.xQuestion]
                  ? questionTypes.xQuestion
                  : questionTypes.yQuestion,
              minValue:
                curr.type === 'Multiple Choice Question' ||
                curr.type === 'Matrix'
                  ? 0
                  : curr.minValue,
              maxValue
            });
          }
          return acc;
        },
        []
      );

      const xQuestion = featureQuestions.find(
        featureQuestion => featureQuestion.type === questionTypes.xQuestion
      );
      const yQuestion = featureQuestions.find(
        featureQuestion => featureQuestion.type === questionTypes.yQuestion
      );

      if (!xQuestion || !yQuestion) {
        return null;
      }

      graphData.x =
        xQuestion.values && xQuestion.values.length
          ? calculateRange(
              xQuestion,
              calculateWeight(xQuestion.values),
              graphRange || { min: xQuestion.minValue, max: xQuestion.maxValue }
            )
          : null;

      graphData.y =
        yQuestion.values && yQuestion.values.length
          ? calculateRange(
              yQuestion,
              calculateWeight(yQuestion.values),
              graphRange || { min: yQuestion.minValue, max: yQuestion.maxValue }
            )
          : null;
      chartData.push(graphData);
    }
    return null;
  });
  return chartData;
};
