import { SURVEY_BUILDER_POPUP_TYPES } from '../constants';
import getPermissionValue from '../getPermissionValue';

export default survey => {
  const sampleSizeInFairPolicy = getPermissionValue(
    survey,
    'sampleSizeIncludedInLicence'
  );

  if (
    survey.maxResponses !== undefined &&
    survey.maxResponses !== null &&
    sampleSizeInFairPolicy &&
    survey.maxResponses > sampleSizeInFairPolicy
  ) {
    return {
      accountManagementSampleSizeWarning:
        SURVEY_BUILDER_POPUP_TYPES.SAMPLE_SIZE_CHARGE_INCREASED
    };
  }

  return {
    accountManagementSampleSizeWarning: null
  };
};
