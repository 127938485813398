import React from 'react';
import check from '../../../../../../assets/img/check.svg';

import styles from './ResultPopup.module.css';

export default ({ title, description, height = 251, width = 446 }) => (
  <div
    className={styles.container}
    style={{ height, width }}
    role="presentation"
  >
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
    <img alt="Checkmark" src={check} className={styles.checkmark} />
  </div>
);
