import React, { useState, useEffect, useContext } from 'react';

import Step from './components/Step/Step';

import SurveyTimeline from './components/SurveyTimeline/SurveyTimeline';
import PublishSurveyJumbotron from './components/PublishSurveyJumbotron/PublishSurveyJumbotron';
import SurveyScheduler from './components/SurveyScheduler/SurveyScheduler';

import SurveyBuilderPageContext from '../SurveyBuilderPageContext';

import {
  getLastEvent,
  jumbotronLabels,
  stepTypes,
  moments
} from './PublishSurvey';

import * as mixpanel from '../../../../../mixpanel';

import styles from './PublishSurvey.module.css';

const getAllIndices = (arr, typename) =>
  arr.reduce(
    (acc, t, i) => (t.__typename === typename ? [...acc, i] : acc),
    []
  );

export default ({ survey, getSurvey, setShowResultPopup }) => {
  const [timeline, setTimeline] = useState([]);
  const [jumbotronLabel, setJumbotronLabel] = useState(null);
  const [lastEvent, setLastEvent] = useState(null);
  const [showScheduler, setShowScheduler] = useState(false);

  const { api } = useContext(SurveyBuilderPageContext);

  useEffect(
    () => {
      setTimeline(
        [...survey.history, ...survey.schedule].sort((a, b) => {
          if (
            a.__typename === 'ScheduledTask' &&
            b.__typename === 'ScheduledTask'
          ) {
            return new Date(a.time) - new Date(b.time);
          }

          if (a.__typename === 'History' && b.__typename === 'History') {
            return new Date(a.createdAt) + new Date(b.createdAt);
          }

          if (a.__typename === 'ScheduledTask' && b.__typename === 'History') {
            return new Date(a.time) - new Date(b.createdAt);
          }

          if (a.__typename === 'History' && b.__typename === 'ScheduledTask') {
            return new Date(a.createdAt) + new Date(b.time);
          }

          return a.createdAt + b.createdAt;
        })
      );
      setLastEvent(getLastEvent([...survey.history, ...survey.schedule]));
    },
    [survey]
  );

  useEffect(
    () => {
      if (lastEvent) {
        if (lastEvent.__typename === 'ScheduledTask') {
          setJumbotronLabel(jumbotronLabels.SCHEDULE);
        } else if (lastEvent.__typename === 'History') {
          setJumbotronLabel(jumbotronLabels[lastEvent.type]);
        }
      }
    },
    [lastEvent]
  );

  if (showScheduler) {
    return (
      <SurveyScheduler
        setShowScheduler={setShowScheduler}
        setShowResultPopup={setShowResultPopup}
        survey={survey}
      />
    );
  }

  if (timeline.length > 0) {
    const historyIndices = getAllIndices(timeline, 'History');

    const { publishScheduled, unpublishScheduled } = timeline.reduce(
      (acc, t) => {
        if (t.__typename === 'ScheduledTask' && t.status === 'SCHEDULED') {
          if (t.action === stepTypes.PUBLISH_SURVEY) {
            return { ...acc, publishScheduled: t };
          }
          if (t.action === stepTypes.UNPUBLISH_SURVEY) {
            return { ...acc, unpublishScheduled: t };
          }
        }
        return acc;
      },
      {}
    );

    const steps = timeline.map((t, i, arr) => {
      const isStart = i === 0 || arr.length === 1;
      if (t.__typename === 'History') {
        return (
          <Step
            key={t.id}
            author={t.client.email}
            date={new Date(t.createdAt)}
            isStart={isStart}
            moment={
              i === historyIndices[historyIndices.length - 1]
                ? moments.NOW
                : moments.PAST
            }
            type={stepTypes[t.type]}
            getSurvey={getSurvey}
            survey={survey}
          />
        );
      }

      return (
        <Step
          key={t.id}
          survey={survey}
          author={t.creator.email}
          date={new Date(t.time)}
          isStart={isStart}
          moment={t.status === 'SCHEDULED' ? moments.FUTURE : moments.PAST}
          additionalButton={
            historyIndices.length === 0 && t.action === stepTypes.PUBLISH_SURVEY
          }
          type={stepTypes[t.action]}
          getSurvey={getSurvey}
          stepId={t.id}
          publishScheduled={
            t.action === stepTypes.UNPUBLISH_SURVEY ? publishScheduled : null
          }
        />
      );
    });

    const potentialPublishStep = (
      <Step
        key="POTENTIAL.PUBLISH_SURVEY"
        moment={moments.POTENTIAL}
        type={stepTypes.PUBLISH_SURVEY}
        getSurvey={getSurvey}
        survey={survey}
        additionalButton={historyIndices.length === 0}
      />
    );
    const potentialUnpublishStep = (
      <Step
        key="POTENTIAL.UNPUBLISH_SURVEY"
        moment={moments.POTENTIAL}
        type={stepTypes.UNPUBLISH_SURVEY}
        getSurvey={getSurvey}
        survey={survey}
        publishScheduled={publishScheduled}
      />
    );

    if (historyIndices.length > 0) {
      const lastHistoryStep =
        timeline[historyIndices[historyIndices.length - 1]];
      if (lastHistoryStep.type === stepTypes.UNPUBLISH_SURVEY) {
        if (!publishScheduled) {
          steps.push(potentialPublishStep);
        }
        if (!unpublishScheduled) {
          steps.push(potentialUnpublishStep);
        }
      } else if (lastHistoryStep.type === stepTypes.PUBLISH_SURVEY) {
        if (!unpublishScheduled) {
          steps.push(potentialUnpublishStep);
        }
        if (!publishScheduled) {
          steps.push(potentialPublishStep);
        }
      }
    } else {
      if (!publishScheduled) {
        steps.push(potentialPublishStep);
      }

      if (!unpublishScheduled) {
        steps.push(potentialUnpublishStep);
      }
    }

    return (
      <>
        {jumbotronLabel && (
          <PublishSurveyJumbotron description={jumbotronLabel} />
        )}
        <SurveyTimeline steps={steps} />
      </>
    );
  }

  return (
    <>
      <div
        className={`${
          survey && survey.surveyDetailsConfirmedByAdmin
            ? styles.testingContainer
            : styles.disabledPublishContainer
        }`}
      >
        <div className={styles.description}>I would like to</div>
        <div className={styles.buttonContainer}>
          <div
            className={`${styles.button} ${styles.publishButton}`}
            role="presentation"
            onClick={async () => {
              if (survey && survey.surveyDetailsConfirmedByAdmin) {
                await api('publishSurvey', {
                  variables: {
                    survey: survey.id,
                    publish: true
                  }
                });
                getSurvey();
                mixpanel.actions.trackEvent(
                  mixpanel.events.CLIENT_PUBLISH_SURVEY,
                  {
                    [mixpanel.eventProperties.SURVEY_ID]: survey.id
                  }
                );
              }
            }}
          >
            Publish this survey now
          </div>
          {survey.schedule.length === 0 && (
            <div
              className={`${styles.button} ${styles.scheduleButton}`}
              role="presentation"
              onClick={() => {
                if (survey && survey.surveyDetailsConfirmedByAdmin) {
                  setShowScheduler(true);
                }
              }}
            >
              <span>Schedule this survey</span>
              <span className={styles.scheduleButtonAdditionalText}>ADMIN</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
