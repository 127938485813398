import React from 'react';

import AgeDynamicFiltersRow from './AgeDynamicFiltersRow';
import AdvancedFilterSettings from '../AdvancedFilterSettings/AdvancedFilterSettings';
import Popup from '../../../Popup/Popup';
import DynamicFiltersPropertiesDropdown from '../DynamicFiltersPropertiesDropdown/DynamicFiltersPropertiesDropdown';

import { DYNAMIC_FILTER_GROUPS } from '../../../SurveyBuilder/helpers/constants';

import ifDynamicFiltersNotUseFullRange from '../../helpers/ifDynamicFiltersNotUseFullRange/ifDynamicFiltersNotUseFullRange';

import exclamation from '../../../../../../assets/img/exclamation.svg';
import styles from '../../TargetAudience.module.css';

export default ({
  userFilter,
  onUserFilterChange,
  updateSurveySettings,
  filterSettings,
  maxResponses
}) => {
  const [
    showAdvancedSettingsDropdown,
    setShowAdvancedSettingsDropdown
  ] = React.useState(false);
  const [
    advancedSettingsDropdownRef,
    setAdvancedSettingsDropdownRef
  ] = React.useState(null);
  const [
    showAdvancedSettingsPopup,
    setShowAdvancedSettingsPopup
  ] = React.useState(false);

  const ageFilters =
    userFilter && userFilter.$and ? userFilter.$and.filter(v => v.age) : [];

  const error = (() => {
    const overlapping = ageFilters.reduce((overlap, currentFilter, i) => {
      if (overlap) return overlap;

      return ageFilters.some((f, j) => {
        if (i === j) return false;

        if (!f.age || !currentFilter.age) return false;

        const x1 = f.age.$gte !== undefined ? f.age.$gte : f.age.$gt;
        const x2 = f.age.$lte !== undefined ? f.age.$lte : f.age.$lt;
        const y1 =
          currentFilter.age.$gte !== undefined
            ? currentFilter.age.$gte
            : currentFilter.age.$gt;
        const y2 =
          currentFilter.age.$lte !== undefined
            ? currentFilter.age.$lte
            : currentFilter.age.$lt;

        if (
          x1 < y1 &&
          x2 === y1 &&
          (f.age.$lt !== undefined || currentFilter.age.$gt !== undefined)
        )
          return false;
        if (
          y1 < x1 &&
          y2 === x1 &&
          (currentFilter.age.$lt !== undefined || f.age.$gt !== undefined)
        )
          return false;

        return x1 <= y2 && y1 <= x2;
      });
    }, false);

    if (overlapping) {
      return 'You have overlapping age ranges';
    }

    const atLeastHigherThanAtMost = ageFilters.some(f => {
      if (
        f &&
        f.$dynamic &&
        f.$dynamic.$max !== undefined &&
        f.$dynamic.$min !== undefined &&
        f.$dynamic.$min > f.$dynamic.$max
      ) {
        return true;
      }
      return false;
    });

    if (atLeastHigherThanAtMost) {
      return 'At least cannot exceed at most';
    }

    const atLeastHigherThanMaxResponses = ageFilters.some(f => {
      if (
        f &&
        f.$dynamic &&
        f.$dynamic.$min !== undefined &&
        ((f.$dynamic.$type === '%' && f.$dynamic.$min > 100) ||
          (maxResponses &&
            f.$dynamic.$type === '#' &&
            f.$dynamic.$min > maxResponses))
      ) {
        return true;
      }
      return false;
    });

    const isAtLeastHigherThanFullRange = ifDynamicFiltersNotUseFullRange(
      ageFilters,
      maxResponses,
      '$min'
    );

    if (
      ageFilters.length &&
      (atLeastHigherThanMaxResponses || isAtLeastHigherThanFullRange)
    ) {
      return 'At least cannot exceed sample size';
    }

    const isFullRange = ifDynamicFiltersNotUseFullRange(
      ageFilters,
      maxResponses
    );

    if (!isFullRange) {
      return 'Age filters aren‘t covering your full target audience.';
    }

    return null;
  })();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerTitle}>
          <span>AGE</span>
          <div className={styles.advancedSettingsContainer}>
            <div
              onClick={() =>
                setShowAdvancedSettingsDropdown(!showAdvancedSettingsDropdown)
              }
              role="presentation"
              className={`${
                styles.advancedSettingsButton
              } age-dynamics-filter-toggle-advanced-settings-dropdown`}
            >
              ...
            </div>
            {showAdvancedSettingsDropdown && (
              <DynamicFiltersPropertiesDropdown
                userFilter={userFilter}
                filterGroup={DYNAMIC_FILTER_GROUPS.AGE}
                advancedSettingsDropdownRef={advancedSettingsDropdownRef}
                setShowAdvancedSettingsDropdown={
                  setShowAdvancedSettingsDropdown
                }
                setAdvancedSettingsDropdownRef={setAdvancedSettingsDropdownRef}
                setShowAdvancedSettingsPopup={setShowAdvancedSettingsPopup}
                onUserFilterChange={onUserFilterChange}
                filterSettings={filterSettings}
              />
            )}
          </div>
        </div>
        {error && (
          <div className={styles.dynamicFilterWarnings}>
            <div className={styles.dynamicFilterWarningMessageBlock}>
              <span className={styles.warningMessage}>
                <img
                  className={styles.warningIcon}
                  src={exclamation}
                  alt="Warning"
                />
                <span>{error}</span>
              </span>
            </div>
          </div>
        )}
        <div className={styles.blockContainer}>
          {userFilter &&
            userFilter.$and &&
            userFilter.$and.map((f, i) => (
              <AgeDynamicFiltersRow
                key={`age-dynamic-filter-row-${i.toString()}`}
                filter={f}
                filterIndex={i}
                userFilter={userFilter}
                onUserFilterChange={onUserFilterChange}
                filterSettings={filterSettings}
                maxResponses={maxResponses}
              />
            ))}
          {!ageFilters ||
            (ageFilters && !ageFilters.length && (
              <div className={styles.noFiltersAvailable}>No active filters</div>
            ))}
        </div>
      </div>
      {showAdvancedSettingsPopup && (
        <Popup
          component={
            <AdvancedFilterSettings
              title="Advanced age settings"
              type={DYNAMIC_FILTER_GROUPS.AGE}
              updateSurveySettings={updateSurveySettings}
              filterSettings={filterSettings}
              setShowAdvancedSettingsPopup={setShowAdvancedSettingsPopup}
              userFilter={userFilter}
              onUserFilterChange={onUserFilterChange}
              maxResponses={maxResponses}
            />
          }
          onClose={() => {
            setShowAdvancedSettingsPopup(false);
          }}
        />
      )}
    </>
  );
};
