export default populations => {
  populations.forEach(population => {
    population.forEach(p => {
      const singlePopulation = p;

      if (singlePopulation.__typename) {
        delete singlePopulation.__typename;
      }

      if (singlePopulation.block && singlePopulation.block.__typename) {
        delete singlePopulation.block.__typename;
      }

      if (singlePopulation.value) {
        singlePopulation.value.forEach(value => {
          const singleValue = value;

          if (singleValue.__typename) {
            delete singleValue.__typename;
          }
        });
      }
    });
  });
  return populations;
};
