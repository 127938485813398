import React from 'react';

import SearchDropdown from '../SearchDropdown/SearchDropdown';

import styles from './QuestionChoiceTagging.module.css';

const intToChar = int => {
  const code = 'a'.charCodeAt(0);
  return String.fromCharCode(code + int);
};

export default ({
  api,
  choiceIndex,
  choices,
  setChoices,
  formattedGlobalIndex,
  newSessionValues,
  setNewSessionValues,
  isAllowedToEditTagsForThisQuestion
}) => {
  const choice = choices[choiceIndex];
  const choiceProfilingTag = choice.profilingTag || {};

  const searchCategories = async (page, search) => {
    const searchResults = await api('searchProfilingCategories', {
      variables: {
        start: 0,
        end: (page + 1) * 10,
        showOnlyVisibleForSearch: true,
        search,
        salt: Math.random().toString()
      }
    });

    const newSessionOptions =
      newSessionValues &&
      newSessionValues.category &&
      newSessionValues.category.length
        ? newSessionValues.category.map(o => ({
            label: o,
            value: o
          }))
        : [];

    const stringFilteredSessionOptions =
      search && search.trim() !== ''
        ? newSessionOptions.filter(v => v && v.value.includes(search))
        : newSessionOptions;

    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingCategories &&
      searchResults.data.searchProfilingCategories.options
      ? [
          ...(stringFilteredSessionOptions || []),
          ...(searchResults.data.searchProfilingCategories.options || [])
        ]
      : [];
  };

  const searchProfilingTagNames = async (page, search) => {
    const preparedChoiceProfilingTag = { ...choiceProfilingTag };
    delete preparedChoiceProfilingTag.tagName;

    const searchResults = await api('searchProfilingTagNames', {
      variables: {
        ...preparedChoiceProfilingTag,
        start: 0,
        end: (page + 1) * 10,
        showOnlyVisibleForSearch: true,
        search,
        salt: Math.random().toString()
      }
    });

    const newSessionOptions =
      newSessionValues &&
      newSessionValues.tagName &&
      newSessionValues.tagName.length
        ? newSessionValues.tagName.map(o => ({
            label: o,
            value: o
          }))
        : [];

    const stringFilteredSessionOptions =
      search && search.trim() !== ''
        ? newSessionOptions.filter(v => v && v.value.includes(search))
        : newSessionOptions;

    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagNames &&
      searchResults.data.searchProfilingTagNames.options
      ? [
          ...(stringFilteredSessionOptions || []),
          ...(searchResults.data.searchProfilingTagNames.options || [])
        ]
      : [];
  };

  const searchProfilingTagValues = async (page, search) => {
    const preparedChoiceProfilingTag = { ...choiceProfilingTag };
    delete preparedChoiceProfilingTag.tagValue;

    const searchResults = await api('searchProfilingTagValues', {
      variables: {
        ...preparedChoiceProfilingTag,
        start: 0,
        end: (page + 1) * 10,
        showOnlyVisibleForSearch: true,
        search,
        salt: Math.random().toString()
      }
    });

    const newSessionOptions =
      newSessionValues &&
      newSessionValues.tagValue &&
      newSessionValues.tagValue.length
        ? newSessionValues.tagValue.map(o => ({
            label: o,
            value: o
          }))
        : [];

    const stringFilteredSessionOptions =
      search && search.trim() !== ''
        ? newSessionOptions.filter(v => v && v.value.includes(search))
        : newSessionOptions;

    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagValues &&
      searchResults.data.searchProfilingTagValues.options
      ? [
          ...(stringFilteredSessionOptions || []),
          ...(searchResults.data.searchProfilingTagValues.options || [])
        ]
      : [];
  };

  const onSelectCompleted = (property, value) => {
    const newChoices = choices;

    if (newChoices[choiceIndex] && !newChoices[choiceIndex].profilingTag) {
      newChoices[choiceIndex].profilingTag = {};
    }

    const { profilingTag } = newChoices[choiceIndex];

    if (value) {
      profilingTag[property] = value;
    } else {
      profilingTag[property] = null;
    }
    setChoices([...newChoices]);
  };

  const addNewCallback = (property, value) => {
    setNewSessionValues({
      ...newSessionValues,
      [property]: [...(newSessionValues[property] || []), value]
    });
  };

  const isAllowedToExpandSearchDropdowns =
    isAllowedToEditTagsForThisQuestion &&
    isAllowedToEditTagsForThisQuestion.allowed;

  return (
    <div className={styles.container}>
      <div className={styles.answer}>
        {formattedGlobalIndex}.{intToChar(choiceIndex)} {choice.answer}
      </div>
      <div className={styles.selectorsContainer}>
        <SearchDropdown
          fetchOptions={searchCategories}
          placeholder="Search for a category"
          onSelectCompleted={value => onSelectCompleted('category', value)}
          addNewCallback={value => addNewCallback('category', value)}
          value={{
            value: choiceProfilingTag.category,
            label: choiceProfilingTag.category
          }}
          disableDropdownExpand={!isAllowedToExpandSearchDropdowns}
        />
        <SearchDropdown
          fetchOptions={searchProfilingTagNames}
          placeholder="Search for tag name"
          onSelectCompleted={value => onSelectCompleted('tagName', value)}
          addNewCallback={value => addNewCallback('tagName', value)}
          value={{
            value: choiceProfilingTag.tagName,
            label: choiceProfilingTag.tagName
          }}
          disableDropdownExpand={!isAllowedToExpandSearchDropdowns}
        />
        <SearchDropdown
          fetchOptions={searchProfilingTagValues}
          placeholder="Search for tag value"
          onSelectCompleted={value => onSelectCompleted('tagValue', value)}
          addNewCallback={value => addNewCallback('tagValue', value)}
          value={{
            value: choiceProfilingTag.tagValue,
            label: choiceProfilingTag.tagValue
          }}
          disableDropdownExpand={!isAllowedToExpandSearchDropdowns}
        />
      </div>
    </div>
  );
};
