import React, { useState } from 'react';

import Draft from '../../../../../../../Draft/Draft';

import getBlockQuestion from '../../../../../../../../../../../../helpers/getBlockQuestion';

import styles from './AnswerText.module.css';

export default ({
  onScreen,
  answer,
  isOtherAnswer,
  isNoneOfTheAboveAnswer,
  isMatrixQuestion,
  notAllowedTextContentEdit,
  setInternalRef,
  handleExistingAnswerKeyPress,
  returnPlaceholder,
  onChange,
  onFocus,

  addDraftEmoji,
  setAddDraftEmoji,
  setActiveDraftButtons,
  toggleDraftButton,
  setToggleDraftButton,
  isFocused,
  onFocusDraft,
  onBlurDraft
}) => {
  const [isRichTextOption, setIsRichTextOption] = useState(null);

  if (!onScreen) {
    return (
      <span className={`${styles.answerTextarea} ${styles.answerTextareaSpan}`}>
        {!answer || answer === '' ? '-' : getBlockQuestion(answer)}
      </span>
    );
  }

  const defaultField = (
    <textarea
      className={`${
        styles.answerTextarea
      } answer-container-input ${(isOtherAnswer || isNoneOfTheAboveAnswer) &&
        styles.isOtherDebounce} ${notAllowedTextContentEdit &&
        styles.disabled}`}
      ref={element => setInternalRef(element)}
      onKeyDown={e => handleExistingAnswerKeyPress(e)}
      onChange={e => onChange(e.target.value)}
      onFocus={e => onFocus(e)}
      maxLength="1000"
      value={answer || ''}
      placeholder={returnPlaceholder()}
    />
  );

  const tryParseRichText = a => {
    try {
      JSON.parse(a)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
      return true;
    } catch (error) {
      return false;
    }
  };

  if (isMatrixQuestion) {
    let showDraft = isRichTextOption;

    if (isRichTextOption === null) {
      showDraft =
        answer === '' || answer.trim() === '' || tryParseRichText(answer);
      setIsRichTextOption(showDraft);
    }

    if (showDraft) {
      return (
        <div
          className={`${styles.answerTextarea} ${styles.draftField}`}
          onFocus={e => onFocusDraft(e)}
          onBlur={() => onBlurDraft()}
        >
          {notAllowedTextContentEdit ? (
            <div className={styles.draftOverlay} />
          ) : null}
          <Draft
            addDraftEmoji={addDraftEmoji}
            setAddDraftEmoji={setAddDraftEmoji}
            setActiveDraftButtons={setActiveDraftButtons}
            toggleDraftButton={toggleDraftButton}
            setToggleDraftButton={setToggleDraftButton}
            isActiveQuestion={isFocused}
            value={answer || ''}
            placeholder={returnPlaceholder()}
            onChangeQuestionProperty={(_property, value) => onChange(value)}
            setInternalRef={setInternalRef}
          />
        </div>
      );
    }
  }

  return defaultField;
};
