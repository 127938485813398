import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';

const ObjectID = require('bson-objectid');

export default (state, flowId, customAttributes) => {
  let newBranches = [];

  let newBranch = {
    id: ObjectID().toString()
  };

  if (customAttributes) {
    newBranch = {
      ...newBranch,
      ...customAttributes
    };
  }

  if (state.survey.questions[flowId].branches) {
    newBranches = [...state.survey.questions[flowId].branches, newBranch];
  }
  if (!state.survey.questions[flowId].branches) {
    newBranches = [newBranch];
  }

  const newFlow = {
    ...state.survey.questions[flowId],
    branches: newBranches
  };

  const newState = {
    newState: {
      ...state,
      survey: {
        ...state.survey,
        questions: {
          ...state.survey.questions,
          [flowId]: newFlow
        }
      },
      activeFlow: newFlow
    }
  };

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState.newState);
  newState.newState = removedNextFlowJumps.state;

  newState.newState.surveyLists = generateSurveyLists(
    newState.newState.survey.content,
    newState.newState.survey.questions
  );

  return newState;
};
