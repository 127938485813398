export default (campaignFilter, otherFilter) => {
  let filter = null;

  const isValue = value => (value || value === 0) && value !== '';

  if (campaignFilter) {
    filter = `&campaignFilter[${campaignFilter.name}]=${campaignFilter.value}`;
  }

  if (otherFilter) {
    let formattedFiltesList = [];
    Object.keys(otherFilter).map(topFilter => {
      if (otherFilter[topFilter].value && otherFilter[topFilter].label) {
        if (isValue(otherFilter[topFilter].value)) {
          filter = `${filter}&campaignFilter[${topFilter.replace('.', '][')}]=${
            otherFilter[topFilter].value
          }`;
        }
      } else if (Array.isArray(otherFilter[topFilter])) {
        const arrayFilter = otherFilter[topFilter].map(
          arrayFilterItem => arrayFilterItem.value
        );
        if (arrayFilter && arrayFilter.length) {
          filter = `${filter}&campaignFilter[${topFilter.replace(
            '.',
            ']['
          )}]=${arrayFilter.toString()}`;
        }
      } else {
        const arrayFilter = [];
        Object.keys(otherFilter[topFilter]).forEach(subFilter => {
          if (isValue(otherFilter[topFilter][subFilter].value)) {
            arrayFilter.push({
              path: `${topFilter}.${subFilter}`,
              value: otherFilter[topFilter][subFilter].value
            });
          } else {
            Object.keys(otherFilter[topFilter][subFilter]).forEach(
              thirdFilter => {
                if (
                  isValue(otherFilter[topFilter][subFilter][thirdFilter].value)
                ) {
                  arrayFilter.push({
                    path: `${topFilter}.${subFilter}.${thirdFilter}`,
                    value: otherFilter[topFilter][subFilter][thirdFilter].value
                  });
                }
              }
            );
          }
        });

        if (arrayFilter) {
          arrayFilter.forEach(filterToAdd => {
            filter = `${filter}&campaignFilter[${filterToAdd.path.replace(
              /\./g,
              ']['
            )}]=${filterToAdd.value}`;
          });
        }
        formattedFiltesList = [...formattedFiltesList, ...arrayFilter];
      }
      return null;
    });
  }
  return filter;
};
