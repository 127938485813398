import React from 'react';
import { withRouter } from 'react-router-dom';

import './Loader.css';

const Loader = (props) => {
  const {
    isProcessing,
  } = props;

  return isProcessing ? <div className="loader-fixed-container">
    <div className="loading-animation"><div></div><div></div><div></div><div></div></div>
    </div> : null;
}

export default withRouter(Loader);
