import React from 'react';

import Loader from '../../../../../../../CommunityDashboard/components/CommunityDashboardPage/components/Loader/Loader';

import { COLORS, FILTER_RELATIONS } from '../../../../../../helpers/constants';
import getColorCode from '../../../../../../helpers/getColorCode';

import styles from './DeliveryPotential.module.css';
import Popup from '../../../../../../../../campaigns/components/Popup';
import CalculatingIncidenceRate from '../CalculatingIncidenceRate/CalculatingIncidenceRate';
import { COMMUNITY_DASHBOARD_FILTER_TYPE } from '../../../../../../../CommunityDashboard/helpers/constants';

export default props => {
  const {
    appliedFilters,
    filterRelation,
    totalSampleSize,
    maxDeliveryPotentialCount,
    totalSampleSizeLoading,
    onCalculateIncidenceRateClick,
    totalIncidenceRate,
    calculateIRLoading,
    isGlobalLoading
  } = props;

  let color = null;
  if (
    (totalSampleSize || totalSampleSize === 0) &&
    (maxDeliveryPotentialCount || maxDeliveryPotentialCount === 0)
  ) {
    const deliveryFactor = maxDeliveryPotentialCount / totalSampleSize;
    color = getColorCode(deliveryFactor);
  }

  const isAllowedToShowCalculateIR = () => {
    if (filterRelation === FILTER_RELATIONS.OR && appliedFilters.length > 1) {
      let doubleExist = false;
      appliedFilters.forEach(aF => {
        if (aF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY) {
          const otherSurveys = appliedFilters.filter(
            iAF =>
              iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY &&
              iAF.userProperties &&
              iAF.userProperties.propertyName === aF.userProperties.propertyName
          );
          if (otherSurveys && otherSurveys.length) doubleExist = true;
        }
        if (aF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG) {
          const otherSurveys = appliedFilters.filter(
            iAF =>
              iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG &&
              iAF.tags &&
              iAF.tags.category === aF.tags.category &&
              iAF.tags.tagName === aF.tags.tagName
          );
          if (otherSurveys && otherSurveys.length) doubleExist = true;
        }
        if (aF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY) {
          const otherSurveys = appliedFilters.filter(
            iAF =>
              iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY &&
              iAF.community &&
              iAF.community.name === aF.community.name
          );
          if (otherSurveys && otherSurveys.length) doubleExist = true;
        }
      });

      return !doubleExist;
    }
    return true;
  };

  const getCalculateIRButtonElement = () => {
    if (isAllowedToShowCalculateIR()) {
      return (
        <div
          className={
            !isGlobalLoading
              ? styles.calculateButton
              : styles.calculateButtonDisabled
          }
          role="presentation"
          onClick={() => {
            if (!isGlobalLoading) {
              onCalculateIncidenceRateClick();
            }
          }}
        >
          Calculate
        </div>
      );
    }

    return <div className={styles.maxDeliveryPotentialCount}>-%</div>;
  };

  const getIncidenceRateValue = () => {
    const totalIncidenceRateValue = totalIncidenceRate * 100;

    if (totalIncidenceRateValue > 100) {
      return `100%`;
    }

    return `${parseFloat(totalIncidenceRate * 100).toFixed(0)}%`;
  };

  return (
    <div>
      {calculateIRLoading ? (
        <Popup component={<CalculatingIncidenceRate />} onClose={() => {}} />
      ) : null}
      <div className={styles.title}>Can we deliver?</div>
      <div className={styles.container}>
        <div
          className={`${styles.trafficLightContainer} ${
            totalSampleSizeLoading ||
            !(maxDeliveryPotentialCount || maxDeliveryPotentialCount === 0)
              ? styles.loadingTrafficLight
              : ''
          }`}
        >
          <div
            className={`${styles.light} ${styles.redLight} ${
              color === COLORS.RED && !totalSampleSizeLoading
                ? styles.active
                : ''
            }`}
          />
          <div
            className={`${styles.light} ${styles.orangeLight} ${
              color === COLORS.ORANGE && !totalSampleSizeLoading
                ? styles.active
                : ''
            }`}
          />
          <div
            className={`${styles.light} ${styles.greenLight} ${
              color === COLORS.GREEN && !totalSampleSizeLoading
                ? styles.active
                : ''
            }`}
          />
        </div>
        {maxDeliveryPotentialCount || maxDeliveryPotentialCount === 0 ? (
          <div className={styles.deliveryItemsContainer}>
            <div className={styles.deliveryContainer}>
              <div className={styles.deliveryTitleContainer}>
                <span className={styles.deliveryTitle}>Delivery potential</span>
                <div className={styles.moreInfoContainer}>
                  <div className={styles.questionMark}>?</div>
                  <div className={styles.tooltip}>
                    <div>
                      Delivery potential is measured by taking the active users
                      of the selected filters and multiplying that number with
                      the response rate of the selected filters.
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      Active users: users who have completed one or more surveys
                      in the past 14 days.
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      Response rate: average of respondents that fill a survey
                      within 48 hours, taken from surveys with 10000 respondents
                      in the last month.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mainContainer}>
                <div className={styles.maxDeliveryPotentialCount}>
                  {maxDeliveryPotentialCount}
                </div>
                <div>
                  <div>max amount of</div>
                  <div>completes in 48h</div>
                </div>
              </div>
            </div>
            <div className={styles.deliveryContainer}>
              <div className={styles.deliveryTitleContainer}>
                <span className={styles.deliveryTitle}>Incidence rate</span>
                <div className={styles.moreInfoContainer}>
                  <div className={styles.questionMark}>?</div>
                  <div className={styles.tooltip}>
                    <div>
                      Total incidence rate is calculated by taking the sum (for
                      OR relationship) or multiplying (for AND relationship) the
                      incidence rate of each individual filter
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mainContainer}>
                {totalIncidenceRate || totalIncidenceRate === 0 ? (
                  <>
                    <div className={styles.maxDeliveryPotentialCount}>
                      {getIncidenceRateValue()}
                    </div>
                    <div>
                      <div>total incidence</div>
                      <div>rate for this project</div>
                    </div>
                  </>
                ) : (
                  getCalculateIRButtonElement()
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.deliveryContainer}>
            <div className={styles.loadingContainer}>
              <Loader fixed={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
