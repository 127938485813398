import { connect } from 'react-redux';
import * as actions from '../actions';
import CampaignFailedPage from '../components/Campaigns/CampaignFailed/CampaignFailedPage';

const mapStateToProps = state => ({
  campaign: state.userCampaigns.campaign
});

const mapDispatchToProps = dispatch => ({
  setCampaign: campaign => dispatch(actions.setCampaignComponents(campaign)),
  resetToInitState: () => dispatch(actions.resetToInitState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignFailedPage);
