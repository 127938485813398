import React from 'react';

import arrowPurple from '../../../../../assets/img/arrow-down-purple.svg';
import arrowGrey from '../../../../../assets/img/arrow-down.svg';

import styles from './SortingArrow.module.css';

export default props => {
  const { sorting, sortingOrder, sortingOption } = props;

  return (
    <img
      alt={`${sortingOption} ${(sorting &&
        sorting[sortingOption] &&
        sorting[sortingOption] === sortingOrder.ASC) ||
        sortingOrder.DESC}`}
      className={`${
        sorting &&
        sorting[sortingOption] &&
        sorting[sortingOption] === sortingOrder.ASC
          ? `${styles.arrow} ${styles.arrowPointingUp}`
          : styles.arrow
      }`}
      src={sorting && sorting[sortingOption] ? arrowPurple : arrowGrey}
    />
  );
};
