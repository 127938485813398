import React, { memo } from 'react';

import InputField from '../../../InputField/InputField';

export default memo(({ value, actions }) => (
  <>
    <InputField
      property="source"
      value={value}
      type="text"
      placeholder="Enter a website URL here.."
      onChange={actions.updateQuestionProperty}
    />
  </>
));
