import React, { useEffect } from 'react';
import Loader from './Loader/Loader';

const LinkShortener = props => {
  let baseUrl;
  let targetUrl;
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    baseUrl = 'http://localhost:8080';
    targetUrl = 'http://localhost:3000';
  } else if (process.env.REACT_APP_ENV === 'qa') {
    baseUrl = 'https://test.buffl.be';
    targetUrl = baseUrl;
  } else {
    baseUrl = 'https://buffl-prod-backend.azurewebsites.net';
    targetUrl = 'https://app.buffl.be';
  }

  const getCampaignId = async url =>
    fetch(url, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-app-version': 1000
      },
      method: 'GET'
    });

  const redirectToCampaign = async () => {
    if (props && props.match && props.match.params) {
      const campaign = await getCampaignId(
        `${baseUrl}/api/users/v2/url-string/${props.match.params.id}`
      );
      const campaignData = await campaign.json();

      if (
        campaignData.campaign &&
        campaignData.campaign.length &&
        campaignData.campaign[0].id &&
        campaignData.campaign[0].client
      ) {
        window.location.replace(
          `${targetUrl}/users/campaign/${campaignData.campaign[0].id}/user/${
            campaignData.campaign[0].client
          }`
        );
      } else {
        window.location.replace(
          `${targetUrl}/users/campaign/not-valid/user/not-valid`
        );
      }
    }
  };

  useEffect(() => {
    redirectToCampaign();
  }, []);

  return <Loader />;
};

export default LinkShortener;
