import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';

export default (state, flowId, choice, answerUpdate) => {
  const choiceIndex = state.survey.questions[flowId].choices.findIndex(
    c => c.id === choice.id
  );

  if (choice.nextFlow) {
    // eslint-disable-next-line
    delete state.survey.questions[flowId].choices[choiceIndex].end;
  }

  if (choice.end) {
    // eslint-disable-next-line
    delete state.survey.questions[flowId].choices[choiceIndex].nextFlow;
  }

  const newChoice = {
    ...state.survey.questions[flowId].choices[choiceIndex],
    ...choice
  };

  const { choices } = state.survey.questions[flowId];
  choices[choiceIndex] = newChoice;

  let activeFlow = {
    ...state.activeFlow
  };

  if (!activeFlow.id) {
    activeFlow = {
      ...activeFlow,
      ...state.survey.questions[flowId]
    };
  }

  activeFlow = {
    ...activeFlow,
    choices
  };

  const modifiedFlow = {
    ...state.survey.questions[flowId],
    choices
  };

  let newActiveFlow = { ...activeFlow };
  if (newActiveFlow && flowId && newActiveFlow.id !== flowId) {
    newActiveFlow = {
      ...newActiveFlow,
      ...state.survey.questions[flowId]
    };
  }

  const newState = {
    newState: {
      ...state,
      survey: {
        ...state.survey,
        questions: {
          ...state.survey.questions,
          [flowId]: modifiedFlow
        }
      },
      activeFlow: newActiveFlow
    }
  };

  if (!answerUpdate) {
    newState.newState.surveyLists = generateSurveyLists(
      newState.newState.survey.content,
      newState.newState.survey.questions
    );
  }

  return newState;
};
