const ObjectID = require('bson-objectid');

export default (state, flowId, customAttributes, isOther, isNoneOfTheAbove) => {
  let newChoices = [];
  if (state.survey.questions[flowId].choices) {
    const qAllowOther = state.survey.questions[flowId].allowOther;
    const qAllowNoneOfTheAbove =
      state.survey.questions[flowId].allowNoneOfTheAbove;

    if ((qAllowOther && !isNoneOfTheAbove) || qAllowNoneOfTheAbove) {
      const choicesWithoutTheLast = [...state.survey.questions[flowId].choices];
      const qAllowCount = qAllowOther && qAllowNoneOfTheAbove ? 2 : 1;
      const lastChoices = choicesWithoutTheLast.slice(
        choicesWithoutTheLast.length - qAllowCount
      );
      choicesWithoutTheLast.splice(
        choicesWithoutTheLast.length - qAllowCount,
        qAllowCount
      );

      newChoices = [
        ...choicesWithoutTheLast,
        {
          id: ObjectID().toString(),
          answer: '',
          ...customAttributes
        },
        ...lastChoices
      ];
    } else {
      newChoices = [
        ...state.survey.questions[flowId].choices,
        {
          id: ObjectID().toString(),
          answer: '',
          ...customAttributes
        }
      ];
    }
  }
  if (!state.survey.questions[flowId].choices) {
    newChoices = [
      {
        id: ObjectID().toString(),
        answer: '',
        ...customAttributes
      }
    ];
  }

  if (isOther) {
    // eslint-disable-next-line
    state.survey.questions[flowId].allowOther = true;
  }

  if (isNoneOfTheAbove) {
    // eslint-disable-next-line
    state.survey.questions[flowId].allowNoneOfTheAbove = true;
  }

  const newFlow = {
    ...state.survey.questions[flowId],
    choices: newChoices
  };

  return {
    newState: {
      ...state,
      survey: {
        ...state.survey,
        questions: {
          ...state.survey.questions,
          [flowId]: newFlow
        }
      },
      activeFlow: newFlow
    }
  };
};
