import React, { useState } from 'react';

import LineChart from './components/LineChart/LineChart';

import styles from './MoneyGraph.module.css';

const MoneyGraph = ({ data, strings }) => {
  const [moneyGraphContainer, setMoneyGraphContainer] = useState(null);

  const reversedData = JSON.parse(JSON.stringify(data)).reverse();

  return (
    <div
      className={styles.container}
      ref={elem => setMoneyGraphContainer(elem)}
    >
      <div className={`${styles.title} roboto-bold-font`}>
        {strings.MONEY_GRAPH}
      </div>
      {moneyGraphContainer && reversedData && reversedData.length ? (
        <LineChart
          data={reversedData}
          height="400"
          parent={moneyGraphContainer}
        />
      ) : null}
      <div className={styles.noDataAvailable}>
        {!reversedData || reversedData.length === 0
          ? strings.NO_DATA_AVAILABLE
          : null}
      </div>
    </div>
  );
};

export default MoneyGraph;
