import {
  SOCIO_DEMOGRAPHICS_CHARTS,
  REPRESENTATIVITY_OPTIONS,
  AGE_REPRESENTATIVITY,
  GENDER_REPRESENTATIVITY,
  LANGUAGE_REPRESENTATIVITY,
  WARNINGS,
  FILTER_RELATIONS,
  COLORS
} from '../constants';

import returnDeltaValue from '../returnDeltaValue';

export const calculateRepresentativitity = (
  values,
  totalSampleSize,
  repSample
) => {
  const representationPercentage = values.reduce(
    (percentage, socioDemoGroup) => {
      let representativityPercentage = 0;

      const repSamplePercentage =
        repSample && repSample[socioDemoGroup.xValue]
          ? repSample[socioDemoGroup.xValue]
          : null;
      const socioDemoGroupPercentage =
        (socioDemoGroup.yValue / totalSampleSize) * 100;

      if (
        repSamplePercentage &&
        socioDemoGroupPercentage > repSamplePercentage
      ) {
        representativityPercentage = repSamplePercentage; // Max representativity (eg age 18-24 recommended sample value is 15%)
      } else {
        representativityPercentage = socioDemoGroupPercentage;
      }

      return percentage + representativityPercentage;
    },
    0
  );

  const maxMissingDelta = Math.round(
    ((100 - representationPercentage) / 100) * totalSampleSize
  ); // Value needed for sum to be 100%

  // Sum of all the socio demo categories should be at least 100% without going over their recommended/strict sample percentages
  const representationValues = values.reduce((deltaValues, socioDemoGroup) => {
    const repSamplePercentage =
      (repSample && repSample[socioDemoGroup.xValue]) || null;

    const repSampleValue = repSamplePercentage
      ? Math.round((repSamplePercentage * totalSampleSize) / 100)
      : null;

    let deltaValue = {
      xValue: socioDemoGroup.xValue,
      yValue: null,
      repSampleValue
    };

    if (repSamplePercentage) {
      const socioDemoGroupPercentage =
        (socioDemoGroup.yValue / totalSampleSize) * 100;

      if (socioDemoGroupPercentage < repSamplePercentage) {
        // Not reaching representativity -> set delta data
        const actualValue = socioDemoGroup.yValue;

        let missingValue = repSampleValue - actualValue;

        if (representationPercentage < 100) {
          if (missingValue > maxMissingDelta) {
            missingValue = maxMissingDelta;
          }

          if (missingValue > 0) {
            deltaValue = returnDeltaValue(
              deltaValue,
              missingValue,
              actualValue
            );
          }
        }
      }
    }

    return [...deltaValues, deltaValue];
  }, []);

  return {
    representationPercentage,
    representationValues
  };
};

const WARNING_MESSAGES = {
  [SOCIO_DEMOGRAPHICS_CHARTS.AGE]: WARNINGS.AGE_NOT_REPRESENTATIVE,
  [SOCIO_DEMOGRAPHICS_CHARTS.GENDER]: WARNINGS.GENDER_NOT_REPRESENTATIVE,
  [SOCIO_DEMOGRAPHICS_CHARTS.LANGUAGE]: WARNINGS.LANGUAGE_NOT_REPRESENTATIVE
};

const REPRESENTATIVITY_TYPES = {
  [SOCIO_DEMOGRAPHICS_CHARTS.AGE]: AGE_REPRESENTATIVITY,
  [SOCIO_DEMOGRAPHICS_CHARTS.GENDER]: GENDER_REPRESENTATIVITY,
  [SOCIO_DEMOGRAPHICS_CHARTS.LANGUAGE]: LANGUAGE_REPRESENTATIVITY
};

const getWarningMessage = title => WARNING_MESSAGES[title] || null;

const getRepSample = (title, representativeSampleValue) =>
  (REPRESENTATIVITY_TYPES[title] &&
    REPRESENTATIVITY_TYPES[title][representativeSampleValue]) ||
  null;

const socioDemoGroupHasFilters = (title, appliedFilters) =>
  appliedFilters &&
  appliedFilters.length &&
  appliedFilters.some(
    f =>
      f.userProperties &&
      f.userProperties.propertyName &&
      f.userProperties.propertyName === title.toUpperCase()
  );

export const findSocioDemoDeltaOnUserPropertyFiltering = (
  appliedFilters,
  totalSampleSize,
  values,
  title
) => {
  let deltaValues = [];
  // Specific filtering on age/gender/language -> show delta's in relation to how much people are needed for delivery
  const activeSocioDemoFilters = appliedFilters.filter(
    filter =>
      filter.userProperties &&
      filter.userProperties.propertyName &&
      filter.userProperties.propertyName.toLowerCase() === title
  );

  if (activeSocioDemoFilters && activeSocioDemoFilters.length) {
    const totalCount = values.reduce((acc, curr) => acc + curr.yValue, 0);
    if (totalCount < totalSampleSize) {
      deltaValues = values.reduce((dValues, socioDemoGroup) => {
        const activeMatchingFilter = activeSocioDemoFilters.find(
          activeF =>
            activeF.userProperties &&
            activeF.userProperties.propertyValue &&
            activeF.userProperties.propertyValue.length &&
            activeF.userProperties.propertyValue.includes(socioDemoGroup.xValue)
        );

        let deltaValue = {
          xValue: socioDemoGroup.xValue,
          yValue: null
        };

        if (activeMatchingFilter) {
          const missingValue = totalSampleSize - totalCount;
          const actualValue = socioDemoGroup.yValue;

          deltaValue = returnDeltaValue(deltaValue, missingValue, actualValue);

          return [...dValues, deltaValue];
        }
        return [...dValues, deltaValue];
      }, []);
    }
  }

  return deltaValues;
};

export const calculateSocioDemoDeltas = (
  appliedFilters,
  filterRelation,
  totalSampleSize,
  values,
  representativeSampleValue,
  title
) => {
  const totalSampleSizeCount = totalSampleSize
    ? parseInt(totalSampleSize, 10)
    : 0;

  let deltaWarning = null;
  let deltaValues = [];

  // Is there an active age and/or gender and/or language filter -> show delta's in relation to delivery potential
  if (socioDemoGroupHasFilters(title, appliedFilters)) {
    deltaValues = findSocioDemoDeltaOnUserPropertyFiltering(
      appliedFilters,
      totalSampleSize,
      values,
      title
    );

    const appliedFiltersLength = (appliedFilters && appliedFilters.length) || 0;

    if (
      deltaValues &&
      deltaValues.length &&
      deltaValues.some(delta => delta.yValue) &&
      filterRelation === FILTER_RELATIONS.OR &&
      appliedFiltersLength > 1
    ) {
      deltaWarning = WARNINGS.DELTAS_WITH_OR_FILTERING;
    }

    return { title, deltaWarning, deltaValues };
  }

  // Show delta's in relation to representivity
  let repSample;
  if (representativeSampleValue === REPRESENTATIVITY_OPTIONS.RECOMMENDED) {
    repSample = getRepSample(title, REPRESENTATIVITY_OPTIONS.RECOMMENDED);
  } else {
    repSample = getRepSample(title, REPRESENTATIVITY_OPTIONS.STRICT);
  }

  const { representationValues } = calculateRepresentativitity(
    values,
    totalSampleSizeCount,
    repSample,
    representativeSampleValue
  );

  if (
    representationValues.some(
      delta =>
        delta.yValue &&
        delta.color &&
        (delta.color === COLORS.RED || delta.color === COLORS.ORANGE)
    )
  ) {
    const appliedFiltersLength = (appliedFilters && appliedFilters.length) || 0;

    if (filterRelation === FILTER_RELATIONS.OR && appliedFiltersLength > 1) {
      deltaWarning = WARNINGS.DELTAS_WITH_OR_FILTERING;
    } else {
      deltaWarning = getWarningMessage(title);
    }
  }

  return { title, deltaWarning, deltaValues: representationValues };
};

export default calculateSocioDemoDeltas;
