import React, { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { DragDropContext } from 'react-beautiful-dnd';

import QuestionsColumn from './components/QuestionsColumn/QuestionsColumn';
import SurveyBuilderPopup from './components/SurveyBuilderPopup/SurveyBuilderPopup';
import AddQuestionList from './components/AddQuestionList/AddQuestionList';
import FlowNavigation from './components/FlowNavigation/FlowNavigation';
import SurveysPlaceholder from './components/SurveysPlaceholder/SurveysPlaceholder';
import Popup from '../Popup/Popup';
import SurveyBuilderContext from './SurveyBuilderContext';
import ErrorOccuredPopup from './components/ErrorOccuredPopup/ErrorOccuredPopup';
import TemplatesWizardWrapper from './components/TemplatesWizard/TemplatesWizardWrapper';

import prepareSurveyToLoadInState from './helpers/prepareSurveyToLoadInState';

import onDragEnd from './helpers/onDragEnd';

import styles from './SurveyBuilder.module.css';

export default ({
  match,
  survey,
  isAdmin,
  isProfilingTagsManager,
  surveyPageRef,
  isJestTest,
  state,
  actions,
  api
}) => {
  if (!state || !actions) return null;

  const [contentStructureCorrupted, setContentStructureCorrupted] = useState(
    false
  );

  useEffect(() => {
    const setSurvey = () => {
      const surveyToSet = prepareSurveyToLoadInState(survey);

      if (surveyToSet === false) {
        setContentStructureCorrupted(true);
      }

      actions.setSurvey({
        ...surveyToSet.surveyTemp,
        questions: surveyToSet.questions
      });

      if (match && match.params && match.params.nodeId) {
        actions.setActiveFlowFromId(match.params.nodeId);
      }
    };

    setTimeout(() => {
      setSurvey();
    }, 1);

    if (isJestTest) {
      setSurvey();
    }
  }, []);

  const doSomethingBeforeUnload = async () => {
    // On refresh
    if (state && state.activeFlow && !state.activeFlow.flows) {
      const activeQuestion = state.activeFlow;

      /*
      if (
        activeQuestion &&
        activeQuestion.id &&
        activeQuestion.nextFlow &&
        activeQuestion.id === activeQuestion.nextFlow
      ) {
        activeQuestion.nextFlow = null;
      }
      */

      if (
        activeQuestion.matrix &&
        activeQuestion.matrix.decisionFrameworkType
      ) {
        await actions.updateDecisionFramework(activeQuestion);
      }

      await api('updateQuestion', {
        question: activeQuestion
      });
    }
  };
  useBeforeunload(doSomethingBeforeUnload);

  useEffect(
    () => {
      if (state && state.activeFlow && state.activeFlow.id) {
        window.history.replaceState(
          null,
          null,
          `/clients/survey/${survey.id}/edit/question/${state.activeFlow.id}`
        );
      }
    },
    [state.activeFlow]
  );

  // console.clear();

  return (
    <SurveyBuilderContext.Provider
      value={{ state, actions, isAdmin, surveyPageRef, isProfilingTagsManager }}
    >
      <div className={styles.container}>
        <TemplatesWizardWrapper actions={actions} />
        {contentStructureCorrupted ? (
          <Popup
            component={<ErrorOccuredPopup onClick={() => {}} />}
            onClose={() => {}}
          />
        ) : null}
        {state && state.surveyBuilderPopup ? (
          <SurveyBuilderPopup
            api={api}
            actions={actions}
            surveyBuilderPopup={state.surveyBuilderPopup}
          />
        ) : null}
        {state.survey && <FlowNavigation />}
        <DragDropContext onDragEnd={event => onDragEnd(event, actions)}>
          {state.survey && state.surveyLists && state.surveyLists.length ? (
            <QuestionsColumn />
          ) : null}
          <SurveysPlaceholder
            survey={state.survey}
            questions={state.surveyLists}
          />
          <AddQuestionList />
        </DragDropContext>
      </div>
    </SurveyBuilderContext.Provider>
  );
};
