import React, { useEffect } from 'react';

import Dropdown from './components/Dropdown/Dropdown';

import styles from './LoversHaters.module.css';

const LoversHaters = props => {
  const {
    activeLoversHaters,
    setActiveLoversHaters,
    features,
    dataPointColors,
    selectedFeature,
    setSelectedFeature,
    selectedFilter,
    setSelectedFilter,
    explanation
  } = props;

  useEffect(
    () => {
      if (!activeLoversHaters && selectedFeature && selectedFilter) {
        setSelectedFeature();
        setSelectedFilter();
      }
    },
    [activeLoversHaters]
  );

  const filterLabels = {
    xLovers: 'Lovers x-axis (highest 25% scores)',
    yLovers: 'Lovers y-axis (highest 25% scores)',
    xLoversyLovers: 'Lovers x-axis and y-axis (highest 25% scores)',
    xHaters: 'Haters x-axis (lowest 25% scores)',
    yHaters: 'Haters y-axis (lowest 25% scores)',
    xHatersyHaters: 'Haters x-axis and y-axis (lowest 25% scores)'
  };

  return (
    <div className={styles.container}>
      <div className={styles.explanation}>{explanation}</div>
      <Dropdown
        options={features.map((feature, index) => ({
          ...feature,
          color: dataPointColors[index],
          featureIndex: index
        }))}
        selectedOption={selectedFeature}
        onSelectCompleted={value => {
          setSelectedFeature(value);
          setActiveLoversHaters({
            ...activeLoversHaters,
            ...value
          });
        }}
        placeholder="Select the value you would like to filter on"
      />
      <Dropdown
        options={Object.entries(filterLabels).map(([key, value]) => ({
          id: key,
          description: value
        }))}
        selectedOption={selectedFilter}
        onSelectCompleted={value => {
          setSelectedFilter(value);
          setActiveLoversHaters({
            ...activeLoversHaters,
            filter: value.id
          });
        }}
        placeholder="Select the filter you would like to use"
      />
    </div>
  );
};

export default LoversHaters;
