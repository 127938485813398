import { connect } from 'react-redux';
import * as actions from '../../actions';
import OrganisationsRoute from './OrganisationsRoute';

const mapStateToProps = state => ({
  organisation: state.organisations.organisation
});

const mapDispatchToProps = dispatch => ({
  onSetOrganisation: organisation =>
    dispatch(actions.setOrganisation(organisation))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationsRoute);
