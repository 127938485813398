import React, { useState } from 'react';
import Bar from '../Bar/Bar';
import blackArrowDown from '../../../../../../../../../../../assets/img/black-arrow-down.svg';

import styles from './Slider.module.css';

export const getAverageRating = inputData => {
  const valueArray = inputData.map(d => d.y * d.x);
  const numberOfResults = inputData.reduce((total, v) => total + v.y, 0);
  return valueArray.reduce((p, c) => p + c, 0) / numberOfResults;
};

const Slider = props => {
  const {
    parent,
    data,
    start,
    end,
    stats,
    populationsLength,
    populationColors,
    statisticalRelevanceStats,
    answerStrings,
    displayStatisticalRelevance,
    displayRatingPercentage,
    setDisplayRatingPercentage
  } = props;

  const [vertical, setVertical] = useState(true);
  const [displayDropDown, setDisplayDropDown] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.sliderSettingsContainer}>
        <div
          className={`${styles.dropDownContainer} ${
            vertical ? styles.vertical : styles.horizontal
          } ${displayDropDown ? styles.dropDownActive : ''}`}
          onClick={() => setDisplayDropDown(!displayDropDown)}
          role="presentation"
        >
          <img
            className={`${styles.dropDownArrow} ${
              displayDropDown ? styles.dropDownArrowActive : ''
            }`}
            src={blackArrowDown}
            alt="Drop down arrow"
          />
          <span>Bar graph ({vertical ? 'vertical' : 'horizontal'})</span>
          {displayDropDown && (
            <div className={styles.dropDown}>
              <div
                className={styles.dropDownOption}
                onClick={() => setVertical(!vertical)}
                role="presentation"
              >
                Bar graph ({vertical ? 'horizontal' : 'vertical'})
              </div>
            </div>
          )}
        </div>

        <div className={styles.switchContainer}>
          <span role="presentation">
            <label className={styles.ratingSwitch} htmlFor="checkbox">
              <input
                type="checkbox"
                checked={displayRatingPercentage}
                className={styles.input}
              />
              <span
                className={styles.ratingSlider}
                role="presentation"
                onClick={e => {
                  setDisplayRatingPercentage(!displayRatingPercentage);
                  e.stopPropagation();
                }}
              >
                <span
                  className={
                    displayRatingPercentage
                      ? styles.ratingPercentageOn
                      : styles.ratingPercentageOff
                  }
                >
                  %
                </span>
                <span
                  className={
                    !displayRatingPercentage
                      ? styles.ratingNumberOn
                      : styles.ratingNumberOff
                  }
                >
                  #
                </span>
              </span>
            </label>
          </span>
        </div>
      </div>
      <Bar
        parent={parent}
        data={data}
        color="#5200F1"
        marginLeft="110"
        height={vertical ? '250' : '350'}
        tickFrequency="1"
        start={start}
        end={end}
        showCounts
        stats={stats}
        populationsLength={populationsLength}
        populationColors={populationColors}
        displayRatingPercentage={displayRatingPercentage}
        vertical={vertical}
        statisticalRelevanceStats={statisticalRelevanceStats}
        displayStatisticalRelevance={displayStatisticalRelevance}
        answerStrings={answerStrings}
      />
    </div>
  );
};

export default Slider;
