import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import styles from './Bar.module.css';

const Bar = props => {
  const {
    data,
    width,
    height,
    unFilteredData,
    isFiltered,
    questions,
    activeLoversHaters,
    themeColors,
    dataPointColors,
    fontSize,
    xLabel,
    yLabel,
    dataTooltips,
    setDataTooltips,
    onTooltipAdd,
    onTooltipRemove,
    onDataPointClick
  } = props;
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    drawChart(svg, props, dataTooltips);
  }, []);

  useEffect(
    () => {
      drawChart(svg, props, dataTooltips);
    },
    [
      svg,
      data,
      isFiltered,
      questions,
      activeLoversHaters,
      themeColors,
      unFilteredData,
      dataPointColors
    ]
  );

  const drawChart = (svgRef, chartProps, tooltips) => {
    if (chartProps.parent && chartProps.parent.current.offsetWidth) {
      const {
        graphRange = { min: 0, max: 10 },
        unFilteredDataPointColors
      } = chartProps;

      const start = graphRange.min;
      const end = graphRange.max;

      const themeColor = themeColors.reduce(
        (acc, tC) => ({
          ...acc,
          [tC.name]: tC.colorCode
        }),
        {}
      );

      const margin = {
        top: 20,
        right: 20,
        bottom: 60,
        left: 100
      };

      const chartSvg = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      chartSvg.selectAll('*').remove();

      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      const chart = chartSvg
        .append('g')
        .attr('width', chartWidth)
        .attr('height', chartHeight)
        .attr('class', 'results-bar-container')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      const x = d3.scaleLinear().range([0, chartWidth]);
      x.domain([start, end]);

      const y = d3.scaleLinear().range([0, chartHeight]);
      y.domain([end, start]);

      const renderTopLeftPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(start), y(6));
        greenPath.lineTo(x(6), y(6));
        greenPath.lineTo(x(6), y(end));
        greenPath.lineTo(x(start), y(end));
        greenPath.closePath();
        return greenPath;
      };

      const renderBottomLeftPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(start), y(start));
        greenPath.lineTo(x(6), y(start));
        greenPath.lineTo(x(6), y(6));
        greenPath.lineTo(x(start), y(6));
        greenPath.closePath();
        return greenPath;
      };

      const renderTopRightPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(6), y(6));
        greenPath.lineTo(x(end), y(6));
        greenPath.lineTo(x(end), y(end));
        greenPath.lineTo(x(6), y(end));
        greenPath.closePath();
        return greenPath;
      };

      const renderBottomRightPath = () => {
        const path = d3.path();
        path.moveTo(x(6), y(6));
        path.lineTo(x(end), y(6));
        path.lineTo(x(end), y(start));
        path.lineTo(x(6), y(start));
        path.closePath();
        return path;
      };

      // X Axis
      chart
        .append('g')
        .attr('class', styles.opportunityGraphAxis)
        .attr('transform', `translate(0,${chartHeight})`)
        .call(
          d3
            .axisBottom(x)
            .scale(x)
            .ticks(end)
            .tickValues([start, 8, end])
        )
        .style('color', '#c7c7c7')
        .style('stroke-width', 1)
        .style('font-size', fontSize || '10px');

      // Y Axis
      chart
        .append('g')
        .attr('class', styles.opportunityGraphAxis)
        .call(
          d3
            .axisLeft(y)
            .scale(y)
            .ticks(end)
            .tickValues([start, 8, end])
        )
        .style('color', '#c7c7c7')
        .style('stroke-width', 1)
        .style('font-size', fontSize || '10px');

      // Top left path shape
      chart
        .append('path')
        .attr('d', renderTopLeftPath())
        .attr('fill', themeColor.COLOR_1)
        .attr('opacity', 1);

      // Bottom left path shape
      chart
        .append('path')
        .attr('d', renderBottomLeftPath())
        .attr('fill', themeColor.COLOR_3)
        .attr('opacity', 1);

      // Top right path shape
      chart
        .append('path')
        .attr('d', renderTopRightPath())
        .attr('fill', themeColor.COLOR_2)
        .attr('opacity', 1);

      // Bottom right path shape
      chart
        .append('path')
        .attr('d', renderBottomRightPath())
        .attr('fill', themeColor.COLOR_4)
        .attr('opacity', 1);

      // Background grid horizontal
      chart
        .append('g')
        .attr('class', 'grid')
        .call(
          d3
            .axisLeft(y)
            .ticks(14)
            .tickSize(-width + margin.left + margin.right)
            .tickFormat('')
        )
        .style('color', '#cccccc');

      // Background grid vertical
      chart
        .append('g')
        .attr('class', 'grid')
        .call(
          d3
            .axisBottom(x)
            .ticks(14)
            .tickSize(height - margin.bottom - margin.top)
            .tickFormat('')
        )
        .style('color', '#cccccc');

      // Features calculated weight (x: importance, y: satisfaction) represented by a letter (A, B, C, ...)
      chart
        .selectAll('.feature')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', d => (d.x || d.x === 0 ? x(d.x) : -150)) // hacky solution to hide data point
        .attr('cy', d => (d.y || d.y === 0 ? y(d.y) : -150)) // hacky solution to hide data point
        .attr('r', 5)
        .attr('fill', (_d, i) => dataPointColors[i])
        .style('opacity', '1')
        .on('mouseover', (d, i) => {
          if (!tooltips.some(t => t.index === i)) {
            onTooltipAdd(setDataTooltips, d, i, 1, dataPointColors);
          }
        })
        .on('mouseout', (_d, i) => {
          if (!tooltips.some(t => t.index === i && t.click)) {
            onTooltipRemove(setDataTooltips, i);
          }
        })
        .on('click', (_d, i) => onDataPointClick(setDataTooltips, i));

      if (isFiltered) {
        // Filtered features
        chart
          .selectAll('.feature')
          .data(unFilteredData)
          .enter()
          .append('circle')
          .attr('cx', d => (d.x || d.x === 0 ? x(d.x) : -150)) // hacky solution to hide data point
          .attr('cy', d => (d.y || d.y === 0 ? y(d.y) : -150)) // hacky solution to hide data point
          .attr('r', 5)
          .attr('fill', (_d, i) => unFilteredDataPointColors[i])
          .style('opacity', '0.4')
          .on('mouseover', (d, i) => {
            if (!tooltips.some(t => t.index === `${i}_unfiltered`)) {
              onTooltipAdd(
                setDataTooltips,
                d,
                i,
                0.4,
                unFilteredDataPointColors,
                true
              );
            }
          })
          .on('mouseout', (_d, i) => {
            if (!tooltips.some(t => t.index === `${i}_unfiltered` && t.click)) {
              onTooltipRemove(setDataTooltips, `${i}_unfiltered`);
            }
          })
          .on('click', (_d, i) =>
            onDataPointClick(setDataTooltips, `${i}_unfiltered`)
          );
      }

      // Lovers benchmark - horizontal
      chart
        .append('line')
        .style('stroke', '#919191')
        .style('stroke-dasharray', '3, 3')
        .style('stroke-width', 2)
        .attr('class', 'gridline')
        .attr('x1', 0)
        .attr('y1', y(8))
        .attr('x2', chartWidth)
        .attr('y2', y(8));

      // Lovers benchmark - vertical
      chart
        .append('line')
        .style('stroke', '#919191')
        .style('stroke-dasharray', '3, 3')
        .style('stroke-width', 2)
        .attr('class', 'gridline')
        .attr('x1', x(8))
        .attr('y1', 0)
        .attr('x2', x(8))
        .attr('y2', chartHeight);

      chart
        .selectAll('.feature')
        .data(dataTooltips)
        .enter()
        .append('rect')
        .attr('width', '75px')
        .attr('height', '21px')
        .attr('x', d => x(d.x) - 37.5)
        .attr('y', d => y(d.y) - 30)
        .attr('rx', '2')
        .attr('font-family', 'Open Sans Regular')
        .attr('font-size', '11px')
        .attr('fill', '#FFFFFF')
        .style('background-color', '#ffffff')
        .style('stroke', '#efefef')
        .style('padding', '10px')
        .style('border-radius', '5px')
        .on('mouseout', d => {
          // Remove to prevent hacky behaviour of tooltip persisting when not clicked
          if (!d.click) {
            onTooltipRemove(setDataTooltips, d.index);
          }
        });

      chart
        .selectAll('.feature')
        .data(dataTooltips)
        .enter()
        .append('text')
        .attr('font-family', 'Open Sans Regular')
        .attr('x', d => x(d.x))
        .attr('y', d => y(d.y) - 15)
        .style('text-anchor', 'middle')
        .text(
          d =>
            `(${d.x ? Math.round(d.x * 100) / 100 : d.x}; ${
              d.y ? Math.round(d.y * 100) / 100 : d.y
            })`
        )
        .attr('font-size', '12px')
        .attr('fill', d => d.color)
        .attr('opacity', d => d.opacity)
        .on('mouseout', d => {
          // Remove to prevent hacky behaviour of tooltip persisting when not clicked
          if (!d.click) {
            onTooltipRemove(setDataTooltips, d.index);
          }
        });

      if (!(xLabel && yLabel)) {
        // Y Axis edge top label
        chart
          .append('text')
          .append('tspan')
          .text(() => 'very unique')
          .attr('text-anchor', 'end')
          .attr('x', -10)
          .attr('y', fontSize ? 12 : 17)
          .style('font-size', fontSize || '10px')
          .style('fill', '#000000')
          .style('transform', 'translate(-5px, 6px)');

        // Y Axis edge bottom label
        chart
          .append('text')
          .append('tspan')
          .text(() => 'not unique')
          .attr('text-anchor', 'end')
          .attr('x', -10)
          .attr('y', height - 90)
          .style('font-size', fontSize || '10px')
          .style('fill', '#000000')
          .style('transform', 'translate(-5px, 6px)');

        // X Axis edge left label
        chart
          .append('text')
          .append('tspan')
          .text(() => 'not valuable')
          .attr('text-anchor', 'start')
          .attr('x', 0)
          .attr('y', height - margin.bottom + (fontSize ? 5 : 10))
          .style('font-size', fontSize || '10px')
          .style('fill', '#000000')
          .style('transform', 'translate(-5px, 6px)');

        // X Axis edge right label
        chart
          .append('text')
          .append('tspan')
          .text(() => 'very valuable')
          .attr('text-anchor', 'end')
          .attr('x', width - margin.left - margin.right + 5)
          .attr('y', height - margin.bottom + (fontSize ? 5 : 10))
          .style('font-size', fontSize || '10px')
          .style('fill', '#000000')
          .style('transform', 'translate(-5px, 6px)');
      }
    }
  };

  return (
    <div>
      <svg
        className="filter-results-charts-bar"
        ref={elem => {
          if (elem) {
            setSvg(elem);
          }
        }}
      />
      {xLabel && <span className={styles.xAxisLabel}>{xLabel}</span>}
      {yLabel && <span className={styles.yAxisLabel}>{yLabel}</span>}
    </div>
  );
};

export default Bar;
