import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import useLazyQuery from '../../../../../hooks/useLazyQuery';

import { GET_MEDIA_UPLOAD_SAS } from '../../../../../../graphql/Survey';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';
import close from '../../../../../../assets/img/close-white.svg';
import webSurveyLogo from '../../../../../../../users/assets/img/buffl.svg';

import styles from '../Properties/Properties.module.css';
import {
  CUSTOM_THEME_COLOR_NAMES,
  CUSTOM_THEME_IMAGE_NAMES
} from '../../../../../helpers/constants';
import ColorPicker from '../ColorPicker/ColorPicker';

const DropZoneText = ({ isDragActive, uploading }) => {
  if (uploading) {
    return (
      <div className={styles.dropContainer}>
        Uploading image, please wait...
      </div>
    );
  }

  if (isDragActive) {
    return (
      <div className={styles.dropContainer}>Drop the image file here...</div>
    );
  }

  return (
    <div className={styles.dropContainer}>
      <div className={styles.dropButton}>Select a file</div>
      <div className={styles.dropDescription}>or drag and drop file here</div>
    </div>
  );
};

const CustomBranding = props => {
  const { survey, isAdmin, onUpdate } = props;
  const [customThemeColorsInner, setCustomThemeColorsInner] = useState(null);
  const [customThemeImagesInner, setCustomThemeImagesInner] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [defaultImage, setDefaultImage] = useState(true);

  const getMediaUploadSAS = useLazyQuery(GET_MEDIA_UPLOAD_SAS);

  const onImageSelect = useCallback(async acceptedFiles => {
    setUploading(true);
    const origFilename = acceptedFiles[0].name;

    const {
      data: { mediaUploadSAS: { uri: sasURI, filename } = {} } = {}
    } = await getMediaUploadSAS({
      variables: {
        fileName: origFilename
      }
    });

    if (!sasURI) {
      // FIXME: error
      return;
    }

    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status === 201 && filename && filename.length) {
        const customThemeImages = [
          {
            name: CUSTOM_THEME_IMAGE_NAMES.LOGO,
            imageFilename: filename
          }
        ];
        onUpdate('customThemeImages', customThemeImages);
      }
    };

    xhr.open('PUT', sasURI, true);
    xhr.setRequestHeader('Content-Type', 'image/jpeg');
    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

    xhr.send(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    multiple: false,
    onDrop: onImageSelect
  });

  useEffect(
    () => {
      if (
        survey &&
        survey.customThemeColors &&
        survey.customThemeColors.length
      ) {
        setCustomThemeColorsInner(survey.customThemeColors);
      } else {
        setCustomThemeColorsInner(null);
      }

      if (
        survey &&
        survey.customThemeImages &&
        survey.customThemeImages.length
      ) {
        setCustomThemeImagesInner(survey.customThemeImages);
      } else {
        setCustomThemeImagesInner(null);
      }
    },
    [survey]
  );

  useEffect(
    () => {
      if (
        customThemeImagesInner &&
        ((customThemeImagesInner[0].imageFilename &&
          customThemeImagesInner[0].imageFilename !== 'default') ||
          !customThemeImagesInner[0].imageFilename) &&
        defaultImage
      ) {
        setDefaultImage(false);
      }
    },
    [customThemeImagesInner]
  );

  const properties = [
    {
      label: 'Hide BUFFL illustrations',
      type: 'survey-property-change',
      name: 'hideIllustrationsInWebSurvey',
      graphName: 'hideIllustrationsInWebSurvey',
      value: false,
      adminOnly: false,
      visible: true
    }
  ];

  const getActiveBlockProperty = property => {
    if (
      (!property.adminOnly || (property.adminOnly && isAdmin)) &&
      property.visible
    ) {
      return (
        <div key={property.name} className={styles.singleProperty}>
          <span>{property.label}</span>

          {property.type === 'survey-property-change' ? (
            /* eslint-disable */
            <span role="presentation" className={styles.switchContainer}>
              <label className={styles.switch} htmlFor="checkbox">
                <input
                  type="checkbox"
                  checked={survey[property.graphName]}
                  readOnly
                />
                <span
                  className={`slider round survey-property-${property.name}`}
                  role="presentation"
                  onClick={e => {
                    property.value = !survey[property.graphName];
                    survey[property.name] = property.value;
                    onUpdate(property.graphName, property.value);
                    e.stopPropagation();
                  }}
                />
              </label>
            </span>
          ) : /* eslint-enable */
          null}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container} role="presentation">
      <div
        className={styles.titleContainer}
        role="presentation"
        onClick={() => setExpanded(() => setExpanded(!expanded))}
      >
        <div className={styles.title}>Web Survey Custom Branding</div>
        <img
          src={arrowDownPurple}
          alt="ExpandColapse"
          className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
        />
      </div>
      {expanded ? (
        <div className={styles.contentContainer}>
          <div>
            <div className={styles.propertiesContentContainer}>
              <div
                className={styles.singleProperty}
                style={customThemeColorsInner ? { borderBottom: 0 } : null}
              >
                <span>
                  Change color of web survey according to your branding
                </span>
                <span role="presentation" className={styles.switchContainer}>
                  <label className={styles.switch} htmlFor="checkbox">
                    <input
                      type="checkbox"
                      checked={!!customThemeColorsInner}
                      readOnly
                    />
                    <span
                      className="slider round"
                      role="presentation"
                      onClick={e => {
                        let customThemeColors;
                        if (
                          customThemeColorsInner &&
                          customThemeColorsInner.length
                        ) {
                          customThemeColors = null;
                        } else {
                          customThemeColors = [
                            {
                              name: CUSTOM_THEME_COLOR_NAMES.BASE,
                              colorCode: '#24B2E8'
                            }
                          ];
                        }

                        onUpdate('customThemeColors', customThemeColors);
                        setCustomThemeColorsInner(customThemeColors);
                        e.stopPropagation();
                      }}
                    />
                  </label>
                </span>
              </div>
              {!!customThemeColorsInner && (
                <div className={styles.colorCodesContainer}>
                  <ColorPicker
                    colorCode={customThemeColorsInner[0].colorCode}
                    onChange={colorCode => {
                      const customThemeColors = [
                        {
                          name: CUSTOM_THEME_COLOR_NAMES.BASE,
                          colorCode
                        }
                      ];

                      if (
                        colorCode.length === 7 &&
                        colorCode.charAt(0) === '#'
                      ) {
                        onUpdate('customThemeColors', customThemeColors);
                        setCustomThemeColorsInner(customThemeColors);
                      }
                    }}
                  />
                </div>
              )}
              {properties.map((property, index) =>
                getActiveBlockProperty(property, index)
              )}
              <div
                className={styles.singleProperty}
                style={customThemeImagesInner ? { borderBottom: 0 } : null}
              >
                <span>Upload your own logo</span>
                <span role="presentation" className={styles.switchContainer}>
                  <label className={styles.switch} htmlFor="checkbox">
                    <input
                      type="checkbox"
                      checked={!!customThemeImagesInner}
                      readOnly
                    />
                    <span
                      className="slider round"
                      role="presentation"
                      onClick={e => {
                        let customThemeImages;
                        if (
                          customThemeImagesInner &&
                          customThemeImagesInner.length
                        ) {
                          customThemeImages = null;
                        } else {
                          customThemeImages = [
                            {
                              name: CUSTOM_THEME_IMAGE_NAMES.LOGO,
                              imageFilename: 'default'
                            }
                          ];
                          setDefaultImage(true);
                        }

                        onUpdate('customThemeImages', customThemeImages);
                        setCustomThemeImagesInner(customThemeImages);
                        e.stopPropagation();
                      }}
                    />
                  </label>
                </span>
              </div>
              {!!customThemeImagesInner && (
                <div className={styles.customImageUploadContainer}>
                  {(customThemeImagesInner[0].imageFilename &&
                    customThemeImagesInner[0].imageFilename !== 'default') ||
                  defaultImage ? (
                    <div className={styles.imageContainer}>
                      <img
                        className={styles.selectedImage}
                        alt="Cover"
                        src={
                          defaultImage
                            ? webSurveyLogo
                            : customThemeImagesInner[0].imageFilename
                        }
                      />
                      <div
                        className={styles.removeImage}
                        role="presentation"
                        onClick={() => {
                          const customThemeImages = [
                            {
                              name: CUSTOM_THEME_IMAGE_NAMES.LOGO,
                              imageFilename: null
                            }
                          ];
                          setUploading(false);
                          if (defaultImage) {
                            setDefaultImage(false);
                          }
                          onUpdate('customThemeImages', customThemeImages);
                        }}
                      >
                        <img alt="Close" src={close} />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.dropZone}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <DropZoneText
                          isDragActive={isDragActive}
                          pdfCoupons={[]}
                          uploading={uploading}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CustomBranding;
