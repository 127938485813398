import React from 'react';

import styles from './DropdownOptionsPopup.module.css';

export default ({ options, setDropdownOptionsPopupRef, onOptionClick }) => {
  const getOption = (option, index) => {
    if (option && option.type === 'horizontalLine') {
      return (
        <div
          key={`options-list-popup-item-${index.toString()}`}
          className={styles.horizontalLine}
        />
      );
    }

    if (option) {
      return (
        <div
          key={`options-list-popup-item-${index.toString()}`}
          className={`${styles.question} dropdown-question`}
          role="presentation"
          onClick={() => onOptionClick(option)}
        >
          <div
            className={
              option.constraints
                ? `${styles.questionContainerWithConstraints} ${
                    styles.questionContainer
                  }`
                : styles.questionContainer
            }
          >
            {option.icon ? option.icon : null}
            <span>{option.label || '-'}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`${
        styles.absoluteContainer
      } dropdown-questions-absolute-container`}
      ref={r => setDropdownOptionsPopupRef(r)}
    >
      <div className={`${styles.container} dropdown-questions-container`}>
        {options && options.map((o, index) => getOption(o, index))}
      </div>
    </div>
  );
};
