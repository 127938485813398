import React from 'react';
import styles from './CommunityAutomationMainDiagram.module.css';
import CommunityAutomationStep from './components/CommunityAutomationStep/CommunityAutomationStep';

import connectingArrowDown from '../../../../../../../../assets/img/connectingArrowDown.svg';

export default ({
  communityAutomation,
  setCommunityAutomation,
  selectedCommunityAutomationStepIndex,
  setSelectedCommunityAutomationStepIndex
}) => {
  const getAutomationStep = (step, stepIndex) => {
    const steps = [];

    const nextStep =
      communityAutomation &&
      communityAutomation.steps &&
      communityAutomation.steps.length &&
      communityAutomation.steps[stepIndex + 1];
    const isNextStepFirstAction =
      communityAutomation.steps && nextStep && step.type !== nextStep.type;

    steps.push(
      <CommunityAutomationStep
        key={`community-automation-step-${stepIndex.toString()}`}
        step={step}
        stepIndex={stepIndex}
        communityAutomation={communityAutomation}
        setCommunityAutomation={setCommunityAutomation}
        selectedCommunityAutomationStepIndex={
          selectedCommunityAutomationStepIndex
        }
        setSelectedCommunityAutomationStepIndex={
          setSelectedCommunityAutomationStepIndex
        }
        isNextStepFirstAction={isNextStepFirstAction}
      />
    );

    if (isNextStepFirstAction) {
      steps.push(
        <img
          src={connectingArrowDown}
          className={styles.arrowDown}
          key={`community-automation-arrow-${stepIndex.toString()}`}
          alt="Connecting arrow"
        />
      );
    }

    return steps;
  };

  return (
    <div className={styles.container}>
      {communityAutomation.steps &&
        communityAutomation.steps.map((aS, aSI) => getAutomationStep(aS, aSI))}
    </div>
  );
};
