import React, { useState, useContext } from 'react';

import SurveyBuilderContext from '../../SurveyBuilderContext';

import Popup from '../Popup/Popup';
import TemplatesWizard from './TemplatesWizard';

import styles from './TemplatesWizard.module.css';

export default ({ actions }) => {
  const [wizardHidden, setWizardHidden] = useState(false);

  const { state } = useContext(SurveyBuilderContext);
  const { survey } = state;

  if (!survey) return null;

  const { questions } = survey;

  if ((questions && Object.keys(questions).length) || wizardHidden) {
    return null;
  }

  return (
    <div className={styles.popupWrapper}>
      <Popup
        disableBackgroundClick
        component={
          <TemplatesWizard
            setWizardHidden={setWizardHidden}
            actions={actions}
            surveyId={survey.id}
            surveyLanguage={survey.language}
          />
        }
        onClose={() => setWizardHidden(true)}
      />
    </div>
  );
};
