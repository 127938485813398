import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import AuthWelcome from './AuthWelcome';

import bufflLogo from '../../assets/img/buffl.svg';

import { RESET_PASSWORD } from '../../graphql/Auth';

export default props => {
  const { match, history } = props;

  const emptyFields = { password: '', repeatPassword: '' };
  const ERROR_MESSAGES = {
    INVALID_TOKEN: 'Link to reset password has expired',
    PASSWORD_NOT_MATCH: 'Passwords do not match',
    RESET_FAILED: 'Password reset request failed',
    FILL_ALL_FIELDS: 'Please fill in all fields'
  };

  const [error, setError] = useState(null);
  const [fields, setFields] = useState(emptyFields);

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: response => {
      if (
        response &&
        response.resetPassword &&
        response.resetPassword.done &&
        !(response.resetPassword.errors && response.resetPassword.errors.length)
      ) {
        history.push({
          pathname: '/auth/login',
          search: 'passwordReset=success'
        });
      } else {
        setError(ERROR_MESSAGES.INVALID_TOKEN);
      }
    },
    onError: () => setError(ERROR_MESSAGES.RESET_FAILED)
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);
    if (Object.values(fields).some(field => !field || field === ''))
      return setError(ERROR_MESSAGES.FILL_ALL_FIELDS);
    if (fields.password !== fields.repeatPassword) {
      return setError(ERROR_MESSAGES.PASSWORD_NOT_MATCH);
    }
    if (Object.values(fields).some(field => field.length < 8)) {
      return setError('Password must be at least 8 characters long');
    }

    if (match && match.params && match.params.token) {
      resetPassword({
        variables: { password: fields.password, token: match.params.token }
      });
    } else {
      setError(ERROR_MESSAGES.INVALID_TOKEN);
    }

    return true;
  };

  return (
    <div className="right-container">
      <AuthWelcome />
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <img className="buffl-logo" src={bufflLogo} alt="Buffl Logo" />
          <div className="form-container-title">Reset password</div>
          <input
            type="password"
            className="password-input"
            value={fields.password}
            onChange={e =>
              setFields({ ...fields, ...{ password: e.currentTarget.value } })
            }
            placeholder="New password"
          />
          <input
            type="password"
            className="password-input"
            value={fields.repeatPassword}
            onChange={e =>
              setFields({
                ...fields,
                ...{ repeatPassword: e.currentTarget.value }
              })
            }
            placeholder="Repeat new password"
          />
          {error && !loading && <div className="error-message">{error}</div>}
          {!loading ? (
            <button type="submit" className="button">
              Reset password
            </button>
          ) : (
            <div className="button-loading">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
          <span className="link">
            <Link to="/auth/register">Don&#39;t have an account? Sign up</Link>
          </span>
        </form>
      </div>
    </div>
  );
};
