import React from 'react';
import { withRouter } from 'react-router-dom';

import LoaderAnimation from '../LoaderAnimation/LoaderAnimation';
import './Loader.css';

const Loader = props => {
  const { isProcessing, isFetching } = props;

  return isProcessing || isFetching ? (
    <div className="loader-fixed-container">
      <LoaderAnimation />
    </div>
  ) : null;
};

export default withRouter(Loader);
