export const GET_CAMPAIGNS_REQUESTED = 'GET_CAMPAIGNS_REQUESTED';
export const GET_CAMPAIGNS_SUCCEEDED = 'GET_CAMPAIGNS_SUCCEEDED';
export const GET_CAMPAIGNS_FAILED = 'GET_CAMPAIGNS_FAILED';

export const SEARCH_CAMPAIGNS = 'SEARCH_CAMPAIGNS';

export const CREATE_CAMPAIGN_REQUESTED = 'CREATE_CAMPAIGN_REQUESTED';
export const CREATE_CAMPAIGN_SUCCEEDED = 'CREATE_CAMPAIGN_SUCCEEDED';
export const CREATE_CAMPAIGN_FAILED = 'CREATE_CAMPAIGN_FAILED';

export const REQUEST_APPROVAL_REQUESTED = 'REQUEST_APPROVAL_REQUESTED';
export const REQUEST_APPROVAL_FAILED = 'REQUEST_APPROVAL_FAILED';

export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';
export const SHOW_CAMPAIGN_RESULTS = 'SHOW_CAMPAIGN_RESULTS';

export const DELETE_CAMPAIGN_REQUESTED = 'DELETE_CAMPAIGN_REQUESTED';
export const DELETE_CAMPAIGN_SUCCEEDED = 'DELETE_CAMPAIGN_SUCCEEDED';
export const DELETE_CAMPAIGN_FAILED = 'DELETE_CAMPAIGN_FAILED';

export const PUBLISH_CAMPAIGN_REQUESTED = 'PUBLISH_CAMPAIGN_REQUESTED';
export const PUBLISH_CAMPAIGN_FAILED = 'PUBLISH_CAMPAIGN_FAILED';

export const UNPUBLISH_CAMPAIGN_REQUESTED = 'UNPUBLISH_CAMPAIGN_REQUESTED';
export const UNPUBLISH_CAMPAIGN_FAILED = 'UNPUBLISH_CAMPAIGN_FAILED';

export const PAY_CAMPAIGN_REQUESTED = 'PAY_CAMPAIGN_REQUESTED';
export const PAY_CAMPAIGN_FAILED = 'PAY_CAMPAIGN_FAILED';

export const WITHDRAW_APPROVAL_REQUEST_REQUESTED =
  'WITHDRAW_APPROVAL_REQUEST_REQUESTED';
export const WITHDRAW_APPROVAL_REQUEST_FAILED =
  'WITHDRAW_APPROVAL_REQUEST_FAILED';

export const DUPLICATE_CAMPAIGN_REQUESTED = 'DUPLICATE_CAMPAIGN_REQUESTED';
export const DUPLICATE_CAMPAIGN_SUCCEEDED = 'DUPLICATE_CAMPAIGN_SUCCEEDED';
export const DUPLICATE_CAMPAIGN_FAILED = 'DUPLICATE_CAMPAIGN_FAILED';

export const CHANGE_CAMPAIGNS_PAGE_REQUESTED =
  'CHANGE_CAMPAIGNS_PAGE_REQUESTED';
export const CHANGE_CAMPAIGNS_PAGE_SUCCEEDED =
  'CHANGE_CAMPAIGNS_PAGE_SUCCEEDED';
export const CHANGE_CAMPAIGNS_PAGE_FAILED = 'CHANGE_CAMPAIGNS_PAGE_FAILED';

export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN';
export const CHANGE_CAMPAIGNS_VIEW = 'CHANGE_CAMPAIGNS_VIEW';
export const BLUR_SELECTED_CAMPAIGN = 'BLUR_SELECTED_CAMPAIGN';
export const SET_CAMPAIGN_ERRORS = 'SET_CAMPAIGN_ERRORS';

export const LOAD_RESULTS_PAGE_REQUESTED = 'LOAD_RESULTS_PAGE_REQUESTED';
export const LOAD_RESULTS_PAGE_SUCCEEDED = 'LOAD_RESULTS_PAGE_SUCCEEDED';
export const LOAD_RESULTS_PAGE_FAILED = 'LOAD_RESULTS_PAGE_FAILED';

export const SET_RESULTS_ONLY = 'SET_RESULTS_ONLY';

export const TOGGLE_SORTING = 'TOGGLE_SORTING';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_FILTERS_RELATION = 'SET_FILTERS_RELATION';

export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';

export const GENERATE_FILE_REQUESTED = 'GENERATE_FILE_REQUESTED';
export const GENERATE_FILE_SUCCEEDED = 'GENERATE_FILE_SUCCEEDED';
export const GENERATE_FILE_FAILED = 'GENERATE_FILE_FAILED';

export const UPLOAD_MEDIA_REQUESTED = 'UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_MEDIA_SUCCEEDED = 'UPLOAD_MEDIA_SUCCEEDED';
export const UPLOAD_MEDIA_FAILED = 'UPLOAD_MEDIA_FAILED';

export const GO_TO_PORTAL_PAGE_REQUESTED = 'GO_TO_PORTAL_PAGE_REQUESTED';
export const GO_TO_PORTAL_PAGE_SUCCEEDED = 'GO_TO_PORTAL_PAGE_SUCCEEDED';
export const GO_TO_PORTAL_PAGE_FAILED = 'GO_TO_PORTAL_PAGE_FAILED';

export const TOGGLE_ADD_NEW_LOGIC_JUMP = 'TOGGLE_ADD_NEW_LOGIC_JUMP';
export const ADD_NEW_LOGIC_JUMP = 'ADD_NEW_LOGIC_JUMP';

export const GET_SELECTOR_CAMPAIGNS_REQUESTED =
  'GET_SELECTOR_CAMPAIGNS_REQUESTED';
export const GET_SELECTOR_CAMPAIGNS_SUCCEEDED =
  'GET_SELECTOR_CAMPAIGNS_SUCCEEDED';
export const GET_SELECTOR_CAMPAIGNS_FAILED = 'GET_SELECTOR_CAMPAIGNS_FAILED';

export const MERGE_DATASETS_REQUESTED = 'MERGE_DATASETS_REQUESTED';
export const MERGE_DATASETS_SUCCEEDED = 'MERGE_DATASETS_SUCCEEDED';
export const MERGE_DATASETS_FAILED = 'MERGE_DATASETS_FAILED';

export const GET_CAMPAIGN_OVERALL_STATS_REQUESTED =
  'GET_CAMPAIGN_OVERALL_STATS_REQUESTED';
export const GET_CAMPAIGN_OVERALL_STATS_SUCCEEDED =
  'GET_CAMPAIGN_OVERALL_STATS_SUCCEEDED';
export const GET_CAMPAIGN_OVERALL_STATS_FAILED =
  'GET_CAMPAIGN_OVERALL_STATS_FAILED';

export const ASSIGN_CATEGORIES_TO_OPEN_ANSWER_REQUESTED =
  'ASSIGN_CATEGORIES_TO_OPEN_ANSWER_REQUESTED';
export const ASSIGN_CATEGORIES_TO_OPEN_ANSWER_SUCCEEDED =
  'ASSIGN_CATEGORIES_TO_OPEN_ANSWER_SUCCEEDED';
export const ASSIGN_CATEGORIES_TO_OPEN_ANSWER_FAILED =
  'ASSIGN_CATEGORIES_TO_OPEN_ANSWER_FAILED';

export const SET_CAMPAIGN_FILTERS = 'SET_CAMPAIGN_FILTERS';
export const CHANGE_COLUMN_SORT = 'CHANGE_COLUMN_SORT';
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE';

export const RESET_CAMPAIGNS_STATE = 'RESET_CAMPAIGNS_STATE';

export const REMOVE_SETTINGS_ERRORS = 'REMOVE_SETTINGS_ERRORS';

export const SET_ACTIVE_SORTING = 'SET_ACTIVE_SORTING';

export function getCampaigns(page) {
  return { type: GET_CAMPAIGNS_REQUESTED, page };
}

export function createCampaign(history) {
  return { type: CREATE_CAMPAIGN_REQUESTED, history };
}

export function editCampaign(campaign) {
  return { type: EDIT_CAMPAIGN, campaign };
}

export function showCampaignResults(campaign) {
  return { type: SHOW_CAMPAIGN_RESULTS, campaign };
}

export function deleteCampaign(id) {
  return { type: DELETE_CAMPAIGN_REQUESTED, id };
}

export function duplicateCampaign(id) {
  return { type: DUPLICATE_CAMPAIGN_REQUESTED, id };
}

export function publishCampaign(id) {
  return { type: PUBLISH_CAMPAIGN_REQUESTED, id };
}

export function unpublishCampaign(id) {
  return { type: UNPUBLISH_CAMPAIGN_REQUESTED, id };
}

export function payCampaign(id) {
  return { type: PAY_CAMPAIGN_REQUESTED, id };
}

export function requestApprovalCampaign(id, onSuccess, onFailure, history) {
  return {
    type: REQUEST_APPROVAL_REQUESTED,
    id,
    onSuccess,
    onFailure,
    history
  };
}

export function withdrawApprovalRequest(id) {
  return { type: WITHDRAW_APPROVAL_REQUEST_REQUESTED, id };
}

export function changeCampaignsPage(page) {
  return { type: CHANGE_CAMPAIGNS_PAGE_REQUESTED, page };
}

export function changeCampaignsView(view) {
  return { type: CHANGE_CAMPAIGNS_VIEW, view };
}

export function selectCampaign(campaign) {
  return { type: SELECT_CAMPAIGN, campaign };
}

export function onBlurSelectedCampaign() {
  return { type: BLUR_SELECTED_CAMPAIGN };
}

export function loadResultsPage(id, viewToken, survey) {
  return { type: LOAD_RESULTS_PAGE_REQUESTED, id, viewToken, survey };
}

export function setResultsOnly(results) {
  return { type: SET_RESULTS_ONLY, results };
}

export function getCampaignOverallStats(campaignId) {
  return { type: GET_CAMPAIGN_OVERALL_STATS_REQUESTED, campaignId };
}

export function toggleSorting(sortingName, sortingOrder) {
  return {
    type: TOGGLE_SORTING,
    sortingName,
    sortingOrder
  };
}

export function toggleFilter(
  filterName,
  valuePath,
  value,
  resultsPath = null,
  transformator = null,
  additionalAnswerLabel = ''
) {
  return {
    type: TOGGLE_FILTER,
    filterName,
    valuePath,
    value,
    resultsPath,
    transformator,
    additionalAnswerLabel
  };
}

export function resetFilters() {
  return { type: RESET_FILTERS };
}

export function setFiltersRelation(relation) {
  return { type: SET_FILTERS_RELATION, relation };
}

export function onSetActiveFilters(activeFilters) {
  return { type: SET_ACTIVE_FILTERS, activeFilters };
}

export function generateCSV(
  id,
  questionIndices,
  viewToken,
  exportRemovedResponses
) {
  return {
    type: GENERATE_FILE_REQUESTED,
    id,
    filetype: 'csv',
    questionIndices,
    viewToken,
    exportRemovedResponses
  };
}

export function mergeDatasets(ids) {
  return { type: MERGE_DATASETS_REQUESTED, ids, filetype: 'csv' };
}

export function onMediaUpload(file, blockType, answerIndex) {
  return {
    type: UPLOAD_MEDIA_REQUESTED,
    file,
    blockType,
    answerIndex
  };
}

export function goToPortalPage(onSuccess, history) {
  return {
    type: GO_TO_PORTAL_PAGE_REQUESTED,
    onSuccess,
    history
  };
}

export function setCampaignErrors(errors) {
  return {
    type: SET_CAMPAIGN_ERRORS,
    errors
  };
}

export function searchCampaigns(search) {
  return { type: SEARCH_CAMPAIGNS, search };
}

export function toggleAddNewLogicJump() {
  return { type: TOGGLE_ADD_NEW_LOGIC_JUMP };
}

export function addNewLogicJump() {
  return { type: ADD_NEW_LOGIC_JUMP };
}

export function getSelectorCampaigns(page) {
  return { type: GET_SELECTOR_CAMPAIGNS_REQUESTED, page };
}

export function onAssignCategoresToOpenAnswer(
  campaignId,
  categorization,
  viewToken
) {
  return {
    type: ASSIGN_CATEGORIES_TO_OPEN_ANSWER_REQUESTED,
    campaignId,
    categorization,
    viewToken
  };
}

export function onChangeColumnSort(sortColumn, sortType, campaignsPath) {
  return { type: CHANGE_COLUMN_SORT, sortColumn, sortType, campaignsPath };
}

export function onSetCampaignFilters(filter) {
  return { type: SET_CAMPAIGN_FILTERS, filter };
}

export function onChangeRowsPerPage(rowsPerPage) {
  return { type: SET_ROWS_PER_PAGE, rowsPerPage };
}

export function removeSettingsErrors() {
  return { type: REMOVE_SETTINGS_ERRORS };
}

export function setActiveSorting(activeSorting) {
  return { type: SET_ACTIVE_SORTING, activeSorting };
}
