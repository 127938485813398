import React, { useEffect } from 'react';
import Branch from './components/Branch/Branch';
import AddPath from './components/AddPath/AddPath';

import { QUOTA_UNITS } from '../../../../../../../../helpers/constants';

import styles from './Distributor.module.css';

export default props => {
  const {
    branches = [],
    isActiveQuestion,
    isAdmin,
    actions,
    question,
    surveyListQuestion,
    surveyLists,
    surveyStructure,
    maxSurveyResponses
  } = props;

  useEffect(
    () => {
      if (question.distributeEvenly) {
        const newBranches = [...branches];
        newBranches.forEach((b, branchIndex) => {
          const newB = { ...b };
          newB.quotaType = QUOTA_UNITS.PERCENTAGE;

          const integerPartOfDistribution = Math.floor(
            100 / newBranches.length
          );
          const totalRest =
            100 - integerPartOfDistribution * newBranches.length;

          if (branchIndex < totalRest) {
            newB.quotaAmount = integerPartOfDistribution + 1;
          } else {
            newB.quotaAmount = integerPartOfDistribution;
          }

          actions.updateQuestionBranch(question.id, newB, true);
        });
      }
    },
    [question.distributeEvenly, branches.length]
  );
  return (
    <div className={styles.blockAdditionalOptions} key="multiple-answers">
      <span className={styles.descriptionTitle}>Paths</span>
      <div className="mc-question-answers-container">
        <div>
          {branches &&
            branches.map((branch, i) => (
              <Branch
                key={branch.id}
                branchIndex={i}
                branches={branches}
                isActiveQuestion={isActiveQuestion}
                isAdmin={isAdmin}
                actions={actions}
                question={question}
                surveyListQuestion={surveyListQuestion}
                surveyLists={surveyLists}
                surveyStructure={surveyStructure}
                maxSurveyResponses={maxSurveyResponses}
              />
            ))}
        </div>
      </div>
      <AddPath
        question={question}
        actions={actions}
        maxSurveyResponses={maxSurveyResponses}
      />
    </div>
  );
};
