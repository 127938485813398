import React from 'react';
import ReactPaginate from 'react-paginate';

import CommunityUserRecord from './CommunityUserRecord/CommunityUserRecord';

import styles from './CommunityUsersList.module.css';

export default props => {
  const {
    totalCount,
    users,
    recordsPerPage,
    filtering,
    setFiltering,
    getLoadData,
    setRemovedUsers
  } = props;

  return (
    <>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        className={styles.mainTable}
      >
        <thead>
          <tr className={styles.headerRow}>
            <th className={styles.firstColumn}>Email</th>
            <th>Assigned at</th>
            <th>Converted from survey</th>
            <th>All surveys visible</th>
            <th />
          </tr>
        </thead>
        {users && users.length ? (
          <tbody>
            {users.map((u, i) => (
              <CommunityUserRecord
                user={u}
                key={`community-automations-record-${i.toString()}`}
                removeUser={
                  setRemovedUsers
                    ? userId =>
                        setRemovedUsers(removedUsers => [
                          ...removedUsers,
                          userId
                        ])
                    : null
                }
              />
            ))}
          </tbody>
        ) : null}
      </table>
      {!users || !users.length ? (
        <div className={styles.noResultsPlaceholder}>
          No users in this community
        </div>
      ) : null}
      {recordsPerPage && users && users.length ? (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalCount ? Math.ceil(totalCount / recordsPerPage) : 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          initialPage={filtering.page}
          forcePage={filtering.page}
          disableInitialCallback
          onPageChange={pageNumber => {
            const newFilter = { ...filtering, page: pageNumber.selected };
            setFiltering(newFilter);
            getLoadData(newFilter);
          }}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      ) : null}
    </>
  );
};
