import React from 'react';
import DropdownSelector from '../DropdownSelector/DropdownSelector';

export default ({
  filter,
  activeFilters,
  FILTER_CONSTANTS,
  onSelectCompleted,
  emptyPlaceholder,
  filterDropdownStyle,
  subFilter
}) => {
  const placeholder = emptyPlaceholder || FILTER_CONSTANTS.DEFAULT_PLACEHOLDER;
  const singleActiveFilterListValue = activeFilters.filter(
    activeFilter => activeFilter.name === filter.name
  );
  const singleActiveFilterValue =
    singleActiveFilterListValue && singleActiveFilterListValue.length
      ? singleActiveFilterListValue[0].values
      : null;

  return (
    <DropdownSelector
      filterDropdownStyle={filterDropdownStyle}
      options={filter.options}
      fetchOptions={filter.fetchOptions}
      value={singleActiveFilterValue || { value: null, label: '' }}
      onSelectCompleted={(value, label) =>
        onSelectCompleted(value, label, singleActiveFilterValue)
      }
      placeholder={placeholder}
      search={filter.search}
      filterName={filter.name}
      subFilter={subFilter}
      activeFiltersLength={
        activeFilters && activeFilters.length ? activeFilters.length : 0
      }
    />
  );
};
