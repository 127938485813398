import React, { memo } from 'react';

import InputField from '../../../InputField/InputField';
import SliderLogicJumps from './components/SliderLogicJumps/SliderLogicJumps';

import BranchIcon from '../../../../../../../Icon/custom/BranchIcon';

import styles from './Slider.module.css';
import IncidenceRateSelectors from '../MCAnswers/components/IncidenceRateSelectors/IncidenceRateSelectors';

export default memo(props => {
  const {
    isActiveQuestion,
    minValue,
    maxValue,
    branches,
    actions,
    question,
    surveyListQuestion,
    surveyLists,
    surveyStructure
  } = props;

  return (
    <div>
      <div className={styles.sliderValuesContainer}>
        <div className={styles.fieldContainer}>
          <InputField
            property="minValue"
            value={minValue}
            type="number"
            placeholder=""
            label="Minimum value"
            onChange={actions.updateQuestionProperty}
          />
        </div>
        <div className={styles.fieldContainer}>
          <InputField
            property="maxValue"
            value={maxValue}
            type="number"
            placeholder=""
            label="Maximum value"
            onChange={actions.updateQuestionProperty}
          />
        </div>
        <div
          role="presentation"
          className={styles.branchIcon}
          onClick={() => {
            if (!branches || (branches && branches.length === 0)) {
              actions.addQuestionBranch(question.id, {
                range: { min: 1, max: 1 }
              });
            }
          }}
        >
          <BranchIcon
            onClick
            fill={branches && branches.length ? '#8500f0' : null}
          />
        </div>
      </div>
      {isActiveQuestion && branches && branches.length ? (
        <div className={`${styles.blockRow} ${styles.sliderLogicJumpsRow}`}>
          <SliderLogicJumps
            actions={actions}
            branches={branches}
            minValue={minValue}
            maxValue={maxValue}
            question={question}
            surveyListQuestion={surveyListQuestion}
            surveyLists={surveyLists}
            surveyStructure={surveyStructure}
          />
        </div>
      ) : null}
      {isActiveQuestion ? (
        <IncidenceRateSelectors actions={actions} question={question} />
      ) : null}
    </div>
  );
});
