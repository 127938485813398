import getUserPropertiesLabels from './getUserPropertiesLabels';

import { USER_PROPERTIES, AGE_RANGES } from './constants';

const ageRanges = [
  {
    value: AGE_RANGES.BELLOW_18,
    label: getUserPropertiesLabels(AGE_RANGES.BELLOW_18)
  },
  {
    value: AGE_RANGES['18_24'],
    label: getUserPropertiesLabels(AGE_RANGES['18_24'])
  },
  {
    value: AGE_RANGES['25_34'],
    label: getUserPropertiesLabels(AGE_RANGES['25_34'])
  },
  {
    value: AGE_RANGES['35_44'],
    label: getUserPropertiesLabels(AGE_RANGES['35_44'])
  },
  {
    value: AGE_RANGES['45_54'],
    label: getUserPropertiesLabels(AGE_RANGES['45_54'])
  },
  {
    value: AGE_RANGES['55_65'],
    label: getUserPropertiesLabels(AGE_RANGES['55_65'])
  },
  {
    value: AGE_RANGES.ABOVE_65,
    label: getUserPropertiesLabels(AGE_RANGES.ABOVE_65)
  }
];

// Feasibility Checker does not include first and last age ranges 'BELLOW_18' & 'ABOVE_65'
const feasibilityCheckerAgeRanges = [...ageRanges].slice(1, -1);

export default (appConfiguration, isFeasibilityChecker) => {
  const dataPath = appConfiguration.data.getAppConfiguration.profileOptions;

  const geodata = JSON.parse(appConfiguration.data.getAppConfiguration.geodata);

  const provinceKeys = Object.keys(geodata.NL).map(provinceKey => ({
    value: provinceKey,
    label: getUserPropertiesLabels(provinceKey) // geodata.NL[provinceKey].label,
  }));

  let postcodes = [];
  let places = [];
  Object.keys(geodata.NL).map(provinceKey => {
    const postcodesToAdd = Object.keys(geodata.NL[provinceKey].postcodes).map(
      postCode => {
        geodata.NL[provinceKey].postcodes[postCode].map(pC => {
          const mappedPlaces = Object.keys(pC).map(mP => ({
            value: mP,
            label: pC[mP]
          }));

          places = [...places, ...mappedPlaces];

          return null;
        });

        return {
          value: postCode,
          label: postCode
        };
      }
    );

    postcodes = [...postcodes, ...postcodesToAdd];

    return null;
  });

  return {
    [USER_PROPERTIES.LANGUAGE]: dataPath.languages.map(o => ({
      value: o.label,
      label: getUserPropertiesLabels(o.label)
    })),
    [USER_PROPERTIES.GENDER]: dataPath.genders.map(o => ({
      value: o.label,
      label: getUserPropertiesLabels(o.label)
    })),
    [USER_PROPERTIES.AGE]: isFeasibilityChecker
      ? feasibilityCheckerAgeRanges
      : ageRanges,
    [USER_PROPERTIES.COUNTRY]: dataPath.countries.map(o => ({
      value: o.label,
      label: getUserPropertiesLabels(o.label)
    })),
    [USER_PROPERTIES.PROVINCE]: provinceKeys,
    [USER_PROPERTIES.POSTAL_CODE]: postcodes,
    [USER_PROPERTIES.PLACE]: places,
    [USER_PROPERTIES.BETA_TESTER]: [
      { value: 'true', label: getUserPropertiesLabels(true) },
      { value: 'false', label: getUserPropertiesLabels(false) }
    ],
    [USER_PROPERTIES.SIGNED_UP_THROUGH]: dataPath.signedUpThrough.map(o => ({
      value: o.value,
      label: getUserPropertiesLabels(o.value)
    })),
    [USER_PROPERTIES.PENDING_ACTIVATION]: [
      { value: 'true', label: getUserPropertiesLabels(true) },
      { value: 'false', label: getUserPropertiesLabels(false) }
    ]
  };
};
