import React from 'react';

import CommunityManagementListPage from './components/CommunityManagementListPage/CommunityManagementListPage';
import CommunityManagementPage from './components/CommunityManagementPage/CommunityManagementPage';

export default props => {
  const {
    match: {
      params: { communityId }
    }
  } = props;

  return (
    <>
      {!communityId && (
        <CommunityManagementListPage recordsPerPage={20} {...props} />
      )}
      {communityId && (
        <CommunityManagementPage communityId={communityId} {...props} />
      )}
    </>
  );
};
