import React from 'react';

import styles from './TableColumnHeaderItem.module.css';
import arrow from './assets/purple-arrow-down.svg';

const TableColumnHeaderItem = props => {
  const {
    changeColumnSort,
    sortColumn,
    sortType,
    label,
    column,
    campaignsPath,
    campaignsConfiguration
  } = props;

  return (
    <span
      role="presentation"
      className={
        sortColumn === column
          ? `${styles.column} ${styles.activeColumn}`
          : styles.column
      }
      onClick={() =>
        changeColumnSort(column, campaignsPath, campaignsConfiguration)
      }
    >
      {sortColumn === column ? (
        <img
          alt="Column sort"
          className={
            sortType === 'desc'
              ? styles.arrow
              : `${styles.arrow} ${styles.arrowTop}`
          }
          src={arrow}
        />
      ) : null}
      {label}
    </span>
  );
};

export default TableColumnHeaderItem;
