export default (selectedBlocks, blocks, block) => {
  if (selectedBlocks.length === 0) return true;

  const selectedQuestion = blocks.find(
    selectedBlock => selectedBlock.id === selectedBlocks[0]
  );

  if (
    selectedQuestion.answers &&
    block.answers &&
    selectedQuestion.answers.length !== block.answers.length
  )
    return false;

  const sameAnswers = selectedQuestion.answers.filter(
    (selectedQuestionAnswer, index) => {
      if (
        block &&
        block.answers &&
        block.answers[0] &&
        selectedQuestionAnswer.answer.trim() ===
          block.answers[index].answer.trim()
      ) {
        return selectedQuestionAnswer;
      }
      return null;
    }
  );

  if (selectedQuestion.answers.length !== sameAnswers.length) return false;

  return true;
};
