import React, { useState } from 'react';
import qs from 'query-string';
import { useMutation } from '@apollo/react-hooks';

import { REQUEST_PASSWORD_RESET } from '../../graphql/Auth';

import AuthWelcome from './AuthWelcome';

import bufflLogo from '../../assets/img/buffl.svg';

const RequestPasswordResetPage = props => {
  const { location, history } = props;

  const query = qs.parse(location.search);

  const emptyFields = { email: (query && query.email) || '' };
  const ERROR_MESSAGES = {
    EMAIL_REQUIRED: 'Please provide an email',
    INVALID_EMAIL: 'Please enter a valid email',
    EMAIL_NOT_FOUND: 'This email address is not linked to an account',
    RESET_FAILED: 'Password reset failed'
  };
  const SUCCESS_MESSAGES = {
    EMAIL_SENT: 'A password recovery email has been sent'
  };

  const [error, setError] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [fields, setFields] = useState(emptyFields);

  const [requestResetPassword, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onCompleted: response => {
        if (
          response &&
          response.requestPasswordReset &&
          response.requestPasswordReset.done &&
          !(
            response.requestPasswordReset.errors &&
            response.requestPasswordReset.errors.length
          )
        ) {
          setIsCompleted(true);
          history.push({
            pathname: location.pathname,
            search: 'completed=true'
          });
        } else {
          setError(ERROR_MESSAGES.EMAIL_NOT_FOUND);
        }
      },
      onError: () => setError(ERROR_MESSAGES.RESET_FAILED)
    }
  );

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);

    if (!fields.email || fields.email === '') {
      return setError(ERROR_MESSAGES.EMAIL_REQUIRED);
    }
    if (fields.email) {
      const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (!emailRegex.test(fields.email)) {
        return setError(ERROR_MESSAGES.INVALID_EMAIL);
      }
    }

    requestResetPassword({ variables: fields });

    return true;
  };

  return (
    <div className="right-container">
      <AuthWelcome />
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <img className="buffl-logo" src={bufflLogo} alt="Buffl Logo" />
          <div className="form-container-title">Forgot password</div>
          <div className="form-text">
            <div>Please enter the email address linked to your account,</div>
            <div>in order to receive a password recovery email.</div>
          </div>
          {isCompleted ||
          (query && query.completed && query.completed === 'true') ? (
            <div className="success-message">{SUCCESS_MESSAGES.EMAIL_SENT}</div>
          ) : (
            <>
              <input
                type="text"
                className="email-input"
                value={fields.email}
                onChange={e =>
                  setFields({ ...fields, ...{ email: e.currentTarget.value } })
                }
                placeholder="Email"
              />
              {error && !loading && (
                <div className="error-message">{error}</div>
              )}
              {!loading ? (
                <button type="submit" className="button">
                  Send
                </button>
              ) : (
                <div className="button-loading">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default RequestPasswordResetPage;
