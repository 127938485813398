import React, { useState, useEffect, useRef } from 'react';

import blackArrowDown from '../../../../../../assets/img/black-arrow-down.svg';
import tickIconWhite from '../../../../../../assets/img/tick_white.svg';
import tickIcon from '../../../../../../assets/img/tick.svg';
import crossIcon from '../../../../../../assets/img/delete.svg';
import dropDownBoldGreyIcon from '../../../../../../assets/img/dropdown-arrow-bold-grey.svg';

import styles from './Toolbar.module.css';
import AddInsight from '../AddInsight/AddInsight';

const MultipleChoiceToolbar = props => {
  const {
    block,
    mcAnswerSortingOrders,
    sortingOrder,
    setSortingOrder,
    onToggleSorting,
    views,
    view,
    setView,
    initialView,
    probabilityAnswerSelected,
    setProbabilityAnswerSelected,
    filteringOnSelection,
    setFilteringOnSelection,
    showCompareToTotalSample,
    setShowAddInsightsPopup,
    viewToken
  } = props;

  const [toolbarActive, setToolbarActive] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const dropdownRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        dropdownRef &&
        dropdownRef.current &&
        dropdownRef.current.children &&
        dropdownRef.current.children.length &&
        Array.from(dropdownRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setDisplayDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.container} ${
          toolbarActive ? styles.containerActive : ''
        }`}
      >
        <div
          className={`${styles.toolbarItem} ${
            !block.random ? styles.randomDisabled : ''
          } ${styles.verticalAlign}`}
        >
          <img
            src={block.random ? tickIcon : crossIcon}
            alt={block.random ? 'Active' : 'Disabled'}
            className={`${block.random ? styles.tickIcon : styles.crossIcon}`}
          />
          Randomiser
        </div>
        <div className={styles.toolbarItem}>
          {block.selectAtLeast > 1 || block.selectAtMost > 1
            ? `${block.selectAtLeast} - ${block.selectAtMost}`
            : block.selectAtLeast}{' '}
          answer {block.selectAtMost > 1 ? 'options' : 'option'}
        </div>
        <div
          className={`${styles.toolbarItem} ${styles.clickable} ${
            styles.verticalAlign
          }`}
          role="presentation"
          onClick={() => {
            setProbabilityAnswerSelected(!probabilityAnswerSelected);
            if (!block.show_selected_answers_order) {
              if (view === views.PROBABILITY_ANSWER_SELECTED) {
                setView(initialView);
              } else {
                setView(views.PROBABILITY_ANSWER_SELECTED);
              }
            }
          }}
        >
          <div
            className={`${styles.visibleBox} ${
              probabilityAnswerSelected ? styles.visibleBoxActive : null
            }`}
          >
            <img
              src={tickIconWhite}
              className={styles.visibleTick}
              alt="Tick"
            />
          </div>
          Show statistical relevance
        </div>
        {showCompareToTotalSample ? (
          <div
            className={`${styles.toolbarItem} ${styles.verticalAlign} ${
              styles.clickable
            }`}
            role="presentation"
            onClick={() => {
              setFilteringOnSelection(!filteringOnSelection);
            }}
          >
            <div
              className={`${styles.visibleBox} ${
                !filteringOnSelection ? styles.visibleBoxActive : null
              }`}
            >
              <img
                src={tickIconWhite}
                className={styles.visibleTick}
                alt="Tick"
              />
            </div>
            Compare to total sample
          </div>
        ) : null}
        <div className={styles.toolbarItem}>
          <div
            ref={dropdownRef}
            role="presentation"
            className={styles.dropdownContainer}
            onClick={() => setDisplayDropdown(prev => !prev)}
            style={{ minWidth: '165px' }}
          >
            <img
              className={`${styles.dropdownArrow} ${
                displayDropdown ? styles.dropdownArrowActive : ''
              }`}
              src={blackArrowDown}
              alt="Drop down arrow"
            />
            {sortingOrder}
            {displayDropdown && (
              <div className={styles.dropdownList}>
                {Object.entries(mcAnswerSortingOrders)
                  .filter(([key]) =>
                    !block.show_selected_answers_order ? key !== 'ANSWER' : true
                  )
                  .map(([key, value]) => (
                    <span
                      role="presentation"
                      className={styles.dropdownOption}
                      key={key}
                      onClick={() => {
                        if (sortingOrder !== value) {
                          setSortingOrder(value);
                          onToggleSorting(block.id, key);
                        }
                      }}
                    >
                      {value}
                    </span>
                  ))}
              </div>
            )}
          </div>
        </div>
        {!viewToken ? (
          <AddInsight
            setShowAddInsightsPopup={setShowAddInsightsPopup}
            questionId={block.id}
          />
        ) : null}
      </div>
      <img
        src={dropDownBoldGreyIcon}
        alt="Display toolbar"
        className={`${styles.dropDownToolbarIcon} ${
          !toolbarActive ? styles.dropDownToolbarIconInactive : ''
        }`}
        role="presentation"
        onClick={() => {
          setToolbarActive(!toolbarActive);
        }}
      />
    </>
  );
};

export default MultipleChoiceToolbar;
