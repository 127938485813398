import React from 'react';
import Icon from '../../../../campaigns/components/Icon';

import styles from './ActiveFilters.module.css';
import { ACQUISITION_METRICS_PROPERTIES } from '../../helpers/constants';

export default ({ appliedFilters, onClearFiltersClick }) => {
  const someParamActive = Object.values(ACQUISITION_METRICS_PROPERTIES).reduce(
    (acc, property) => {
      if (appliedFilters[property] && appliedFilters[property].length) {
        return true;
      }
      return acc;
    },
    false
  );

  const getFilterLabel = () =>
    Object.values(ACQUISITION_METRICS_PROPERTIES).reduce((acc, property) => {
      if (
        appliedFilters &&
        appliedFilters[property] &&
        appliedFilters[property].length
      ) {
        if (acc === '') {
          return appliedFilters[property].join();
        }

        return `${acc} > ${appliedFilters[property].join()}`;
      }
      return acc;
    }, '');

  return (
    <div className={styles.activeFiltersContainer}>
      {someParamActive ? (
        <div
          className={`${
            styles.activeFilterItem
          } campaign-filters-active-filter-item`}
        >
          <span
            className={styles.activeFilterItemLabel}
            title={getFilterLabel()}
          >
            {getFilterLabel()}
          </span>
          <span
            className={styles.removeIcon}
            role="presentation"
            onClick={() => onClearFiltersClick()}
          >
            <Icon type="close" />
          </span>
        </div>
      ) : null}
      {someParamActive ? (
        <span
          className={`${
            styles.clearAllFilters
          } campaign-filters-clear-all-filters-button`}
          role="presentation"
          onClick={() => onClearFiltersClick()}
        >
          Clear all filters
        </span>
      ) : null}
    </div>
  );
};
