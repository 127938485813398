import {
  RECOMMENDED_DEFAULT_VALUE,
  RECOMMENDED_DEFAULT_VALUE_WITH_65,
  STRICT_DEFAULT_VALUE,
  STRICT_DEFAULT_VALUE_WITH_65
} from '../../defaultSurveyFilterValues';

import { DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS } from '../../../SurveyBuilder/helpers/constants';

import removeFilterGroup from '../removeFilterGroup/removeFilterGroup';

export default (filterGroup, representativity, userFilter, if65AgeIncluded) => {
  if (representativity) {
    let representativitySets = {};

    if (if65AgeIncluded) {
      representativitySets = {
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.RECOMMENDED]: RECOMMENDED_DEFAULT_VALUE_WITH_65,
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.STRICT]: STRICT_DEFAULT_VALUE_WITH_65
      };
    } else {
      representativitySets = {
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.RECOMMENDED]: RECOMMENDED_DEFAULT_VALUE,
        [DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.STRICT]: STRICT_DEFAULT_VALUE
      };
    }
    const representativitySet = representativitySets[representativity];

    if (representativitySet) {
      const removedCurrentValues = removeFilterGroup(filterGroup, userFilter);

      const defaultFilterGroupValuesFromDefaultSet =
        representativitySet && representativitySet.$and
          ? representativitySet.$and.filter(v => v[filterGroup.toLowerCase()])
          : [];

      return JSON.parse(
        JSON.stringify({
          ...removedCurrentValues,
          $and: [
            ...removedCurrentValues.$and,
            ...defaultFilterGroupValuesFromDefaultSet
          ]
        })
      );
    }
  }
  return { $and: {} };
};
