import React from 'react';

import styles from './AnswerQuotaInput.module.css';

const AnswerQuotaInput = props => {
  const { value, onChange, disabled } = props;

  return (
    <input
      className={`${styles.quotaInput} ${disabled ? styles.disabled : ''}`}
      value={value}
      onChange={e => onChange(e)}
      type="number"
      onKeyDown={evt => {
        if (
          evt.keyCode &&
          ((evt.keyCode >= 48 && evt.keyCode <= 57) ||
            evt.keyCode === 8 ||
            (evt.keyCode >= 96 && evt.keyCode <= 105))
        ) {
          return null;
        }
        return evt.preventDefault();
      }}
      disabled={disabled}
    />
  );
};

export default AnswerQuotaInput;
