import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import getChartData from './helpers/getChartData';

import { REMOVE_MERGE_GRAPH } from '../../../../../../../graphql/Survey';

import Icon from '../../../../../Icon';
import Bars from './components/Bars/Bars';
import QuestionsList from './components/QuestionsList/QuestionsList';
import Loader from '../Loader/Loader';
import MergedGraphToolbar from '../Toolbars/MergedGraphToolbar';

import {
  getStatisticalRelevanceForQuestions,
  parseStatisticalAnalysisData
} from './helpers/statisticalRelevance';

import styles from './MergedGraph.module.css';
import colorsPallete from '../../../../colors';

export default ({
  graph,
  questions,
  surveyId,
  mergedGraphs,
  activeFilters,
  isFiltered,
  parseQuestion,
  onToggleFilter,
  isAllowedToExecuteRequests,
  viewToken,
  setShowAddInsightsPopup
}) => {
  const [removeMergeGraph, { loading: removeMergeGraphLoading }] = useMutation(
    REMOVE_MERGE_GRAPH
  );
  const barsParent = useRef();
  const [barsVertical, setBarsVertical] = useState(true);
  const [isPercentage, setIsPercentage] = useState(true);
  const [hiddenQuestions, setHiddenQuestions] = useState([]);
  const [
    fetchedStatisticalRelevance,
    setFetchedStatisticalRelevance
  ] = useState(false);
  const [loading, setLoading] = useState(false);

  const mergedGraphQuestions = graph.questions.map(q =>
    questions.find(question => question.id === q.id)
  );

  const data = useMemo(
    () =>
      getChartData(
        mergedGraphQuestions,
        isPercentage,
        isFiltered,
        hiddenQuestions
      ),
    [activeFilters, isPercentage, mergedGraphs, graph, hiddenQuestions]
  );

  useEffect(
    () => {
      if (fetchedStatisticalRelevance) {
        if (activeFilters && activeFilters.length) {
          getStatisticalRelevance();
        }
        if (!activeFilters) {
          setFetchedStatisticalRelevance({
            unfilteredResponses:
              fetchedStatisticalRelevance.unfilteredResponses,
            filteredResponses: null
          });
        }
      }
    },
    [activeFilters]
  );

  const statisticalRelevance = useMemo(
    () => {
      if (!fetchedStatisticalRelevance) return null;
      return parseStatisticalAnalysisData(
        fetchedStatisticalRelevance.unfilteredResponses,
        fetchedStatisticalRelevance.filteredResponses,
        mergedGraphQuestions,
        hiddenQuestions
      );
    },
    [fetchedStatisticalRelevance, hiddenQuestions]
  );

  const getStatisticalRelevance = async () => {
    if (isAllowedToExecuteRequests) {
      setLoading(true);

      const unparsedStatisticalRelevance = await getStatisticalRelevanceForQuestions(
        mergedGraphQuestions,
        activeFilters,
        surveyId,
        viewToken || null
      );

      setFetchedStatisticalRelevance(unparsedStatisticalRelevance);

      setLoading(false);
    }
    return null;
  };

  const onRemoveMergedChartClick = async () => {
    if (isAllowedToExecuteRequests && !removeMergeGraphLoading) {
      await removeMergeGraph({
        variables: {
          survey: surveyId,
          id: graph.id,
          ...(viewToken ? { viewToken } : {})
        }
      });

      /*
      if (
        result.data &&
        result.data.removeMergedGraph &&
        result.data.removeMergedGraph.survey
      ) {
        const newMergedGraphs = mergedGraphs.filter(
          mergedGraph => mergedGraph.id !== graph.id
        );
        setMergedGraphs(newMergedGraphs);
      }
      */
    }
  };

  const onBarClick = (questionIndex, answerStringIndex) => {
    const clickedBarQuestion = mergedGraphQuestions[questionIndex];
    const answerIndex = parseInt(answerStringIndex, 10);

    if (clickedBarQuestion) {
      if (
        clickedBarQuestion.type === 'Checkboxes' ||
        (clickedBarQuestion.type === 'Multiple Choice Question' &&
          clickedBarQuestion.selectAtMost &&
          clickedBarQuestion.selectAtMost > 1)
      ) {
        onToggleFilter(
          clickedBarQuestion.id,
          null,
          answerIndex,
          null,
          result => {
            if (result && result.attributes.block_results) {
              const questionBlock = result.attributes.block_results.filter(
                question => question.block === clickedBarQuestion.id
              );
              if (questionBlock.length) {
                if (
                  questionBlock[0].answers &&
                  questionBlock[0].answers.indexOf(answerIndex) > -1
                ) {
                  return true;
                }
              }
            }
            return false;
          }
        );
      }
      if (
        clickedBarQuestion.type === 'Multiple Choice Question' &&
        (!clickedBarQuestion.selectAtMost ||
          clickedBarQuestion.selectAtMost === 1)
      ) {
        onToggleFilter(
          clickedBarQuestion.id,
          'answer',
          answerIndex,
          'attributes.block_results'
        );
      }
    }
  };

  const onStatisticalRelevanceClick = async () => {
    if (fetchedStatisticalRelevance) {
      setFetchedStatisticalRelevance(null);
      return null;
    }

    setIsPercentage(true);
    getStatisticalRelevance();
    return null;
  };

  return (
    <div className={styles.container}>
      <MergedGraphToolbar
        onStatisticalRelevanceClick={onStatisticalRelevanceClick}
        fetchedStatisticalRelevance={fetchedStatisticalRelevance}
        barsVertical={barsVertical}
        setBarsVertical={setBarsVertical}
        isPercentage={isPercentage}
        setIsPercentage={setIsPercentage}
        onRemoveMergedChartClick={onRemoveMergedChartClick}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        questionId={graph ? graph.id : null}
        viewToken={viewToken}
      />
      <div className={styles.blockTitle}>
        <div className={styles.blockIcon}>
          <Icon type="merged-graph" />
        </div>
        <div className={styles.blockContent}>
          <div className={styles.blockText}>{graph.name}</div>
        </div>
        <div className={styles.removeMergedGraph}>
          {removeMergeGraphLoading && <Loader size="small" />}
        </div>
      </div>
      <div className={styles.blockContent}>
        <div className={styles.chartContainer} ref={barsParent}>
          {loading ? (
            <div className={styles.loadingAnimation}>
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            <Bars
              parent={barsParent}
              height="400"
              data={data}
              barsVertical={barsVertical}
              colorsPallete={colorsPallete.filter(
                (_c, index) => hiddenQuestions.indexOf(index) === -1
              )}
              isPercentage={isPercentage}
              isFiltered={isFiltered}
              onBarClick={onBarClick}
              statisticalRelevance={statisticalRelevance}
            />
          )}
        </div>
        <QuestionsList
          mergedGraphQuestions={mergedGraphQuestions}
          colorsPallete={colorsPallete}
          hiddenQuestions={hiddenQuestions}
          setHiddenQuestions={setHiddenQuestions}
          parseQuestion={parseQuestion}
        />
      </div>
    </div>
  );
};
