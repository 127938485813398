import React from 'react';

import styles from '../Welcome.module.css';

export default ({ label, required, value, setValue, placeholder }) => (
  <div className={styles.detailContainer}>
    <div className={styles.rowText}>
      {label}
      {required ? <span className={styles.requiredAsterisk}>*</span> : null}
    </div>
    <input
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
      className={styles.numberInput}
      placeholder={placeholder || '0'}
      type="number"
      onKeyDown={evt =>
        evt.key === 'ArrowDown' ||
        evt.key === 'ArrowUp' ||
        evt.key === 'e' ||
        evt.key === 'E' ||
        evt.key === '.' ||
        evt.key === '-'
          ? evt.preventDefault()
          : null
      }
      onWheel={e => e.target.blur()}
    />
  </div>
);
