import React from 'react';

import closeIcon from '../../../../../../../../assets/img/delete.svg';

import styles from '../ResearchCostIncreased/ResearchCostIncreased.module.css';

export default ({ onClose }) => (
  <div className={styles.container}>
    <div className={styles.title}>Are you sure?</div>
    <div className={styles.description}>
      <div>With BUFFL, we want to encourage short surveys.</div>
      <div>
        Adding too many statements/concepts makes the survey long and can
        negatively impact the respondent’s user experience and the quality of
        the data. If more statements are added to this matrix, we recommend
        limiting the number of additional questions in the rest of the survey
      </div>
    </div>
    <div className={styles.footer}>
      <div />
      <div className={styles.actions}>
        <img
          className={styles.close}
          src={closeIcon}
          alt="Close icon"
          onClick={onClose}
          role="presentation"
        />
      </div>
    </div>
  </div>
);
