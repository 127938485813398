export default (filters, unfilteredResults) => {
  const filterMapping = {
    age: {
      type: 'range',
      valuePath: 'attributes.userData.age'
    },
    gender: {
      type: 'userData',
      valuePath: 'attributes.userData.gender'
    },
    occupation: {
      type: 'userData',
      valuePath: 'attributes.userData.newOccupation.mainActivity'
    },
    answer: {
      type: 'answer',
      resultsPath: 'attributes.block_results'
    }
  };

  const deepFind = (obj, path) => {
    const paths = path.split('.');
    let current = obj;
    let i = null;

    for (i = 0; i < paths.length; i += 1) {
      if (current[paths[i]] === undefined) {
        return undefined;
      }
      if (current[paths[i]] !== undefined) {
        current = current[paths[i]];
      }
    }
    return current;
  };

  const deepFindMultipleAnswers = (singleDeepFindAnswer, filterValues) => {
    const contains = element => singleDeepFindAnswer.indexOf(element) > -1;
    if (filterValues.some(contains)) {
      return true;
    }
    return false;
  };

  const filterResult = (activeFilters, result) => {
    let matched = 0;

    activeFilters.forEach(filter => {
      const filterDetails = filterMapping[filter.name];

      if (filterDetails && !filter.active) {
        matched += 1;
      }
      if (filterDetails && filter.active) {
        if (filterDetails.type === 'answer') {
          const results = deepFind(result, filterDetails.resultsPath);
          const singleAnswer = results.filter(
            r => r.block === filter.block.value
          );

          if (filter && filter.value) {
            const filterValues = filter.value.map(f => parseInt(f.value, 10));
            if (singleAnswer && singleAnswer.length) {
              if (singleAnswer[0].answer || singleAnswer[0].answer === 0) {
                if (filter.inverse) {
                  matched =
                    singleAnswer &&
                    singleAnswer[0] &&
                    !filterValues.indexOf(deepFind(singleAnswer[0], 'answer')) >
                      -1 // inverse is not
                      ? (matched += 1)
                      : matched;
                } else {
                  matched =
                    singleAnswer &&
                    singleAnswer[0] &&
                    filterValues.indexOf(deepFind(singleAnswer[0], 'answer')) >
                      -1
                      ? (matched += 1)
                      : matched;
                }
              } else if (filter.inverse) {
                matched =
                  singleAnswer &&
                  singleAnswer[0] &&
                  !deepFindMultipleAnswers(
                    singleAnswer[0].answers,
                    filterValues
                  ) // inverse is not
                    ? (matched += 1)
                    : matched;
              } else {
                matched =
                  singleAnswer &&
                  singleAnswer[0] &&
                  deepFindMultipleAnswers(singleAnswer[0].answers, filterValues)
                    ? (matched += 1)
                    : matched;
              }
            }
          }
        }
        if (filterDetails.type === 'userData') {
          const filterValues = filter.value.map(f => f.value);
          let valueCheck;
          if (filter.inverse) {
            valueCheck = !(
              filterValues.indexOf(deepFind(result, filterDetails.valuePath)) >
              -1
            );
          } else {
            valueCheck =
              filterValues.indexOf(deepFind(result, filterDetails.valuePath)) >
              -1;
          }
          matched = valueCheck ? (matched += 1) : matched;
        }
        if (filterDetails.type === 'range') {
          const value = deepFind(result, filterDetails.valuePath);
          let rangeMatched = null;
          if (filter.gte) {
            if (filter.inverse) {
              rangeMatched = parseInt(value, 10) < filter.gte;
            } else {
              rangeMatched = parseInt(value, 10) >= filter.gte;
            }
          }
          if (
            (rangeMatched !== false && filter.lte && !filter.inverse) ||
            (filter.inverse && filter.lte && rangeMatched !== true)
          ) {
            if (filter.inverse) {
              rangeMatched = parseInt(value, 10) > filter.lte;
            } else {
              rangeMatched = parseInt(value, 10) <= filter.lte;
            }
          }

          matched = rangeMatched ? (matched += 1) : matched;
        }
      }
    });

    // Filters don't match
    if (activeFilters.length && matched !== activeFilters.length) {
      return null;
    }

    return result;
  };

  const filteredResults = [];
  if (unfilteredResults && unfilteredResults.length) {
    unfilteredResults.forEach(result => {
      const filteredResult = filterResult(filters, result);
      if (filteredResult) {
        filteredResults.push(filteredResult);
      }
    });
  }

  return filteredResults;
};
