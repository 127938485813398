import gql from 'graphql-tag';

export const GET_DELIVERY_POTENTIAL = gql`
  query deliveryPotential(
    $appliedFilters: [SurveyFeasibilityCheckerAppliedFiltersInput]
    $filterRelation: String
  ) {
    deliveryPotential(
      input: {
        appliedFilters: $appliedFilters
        filterRelation: $filterRelation
      }
    ) {
      value
      responseRate
      timestamp
    }
  }
`;

export const GET_SOCIO_DEMOGRAPHICS = gql`
  query socioDemographicChecker(
    $appliedFilters: [SurveyFeasibilityCheckerAppliedFiltersInput]
    $filterRelation: String
  ) {
    socioDemographicChecker(
      input: {
        appliedFilters: $appliedFilters
        filterRelation: $filterRelation
      }
    ) {
      age {
        name
        value
        unfilteredValue
      }
      gender {
        name
        value
        unfilteredValue
      }
      language {
        name
        value
        unfilteredValue
      }
    }
  }
`;

export const GET_PROFILE_ATTRIBUTES = gql`
  query profileAttributesChecker(
    $appliedFilters: [SurveyFeasibilityCheckerAppliedFiltersInput]
    $filterRelation: String
  ) {
    profileAttributesChecker(
      input: {
        appliedFilters: $appliedFilters
        filterRelation: $filterRelation
      }
    ) {
      sectors {
        name
        questionId
        data {
          name
          value
          unfilteredValue
        }
        totalValue
        totalUnfilteredValue
      }
    }
  }
`;
