export default blockQuestion => {
  let question;
  try {
    question = [].concat(
      JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ')
    );
  } catch (error) {
    question = blockQuestion;
  }
  return question;
};
