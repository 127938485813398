import React, { useState } from 'react';
import Bar from '../../../../Charts/Bar';

import styles from './Slider.module.css';

export const getAverageRating = inputData => {
  const valueArray = inputData.map(d => d.y * d.x);
  const numberOfResults = inputData.reduce((total, v) => total + v.y, 0);
  return valueArray.reduce((p, c) => p + c, 0) / numberOfResults;
};

const Slider = props => {
  const {
    parent,
    data,
    start,
    end,
    onChartClick,
    filterCollectDataGroup,
    unfilteredData,
    onSetFiltersRelation,
    block,
    sorting,
    setSorting,
    sortingOptions,
    activeFilters,
    responses,
    extremeUsersFilterLabels
  } = props;

  const [displayRatingPercentage, setDisplayRatingPercentage] = useState(true);
  const [displayDropdown, setDisplayDropdown] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.switchContainer}>
        <div className={styles.sortContainer}>
          <div
            role="presentation"
            className={styles.dropdownContainer}
            onClick={() => setDisplayDropdown(prev => !prev)}
          >
            {sorting}
            {displayDropdown && (
              <div className={styles.dropdownList}>
                {Object.values(sortingOptions).map(o => (
                  <span
                    role="presentation"
                    className={styles.dropdownOption}
                    key={o}
                    onClick={() => setSorting(o)}
                  >
                    {o}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <span role="presentation">
          <label className={styles.ratingSwitch} htmlFor="checkbox">
            <input
              type="checkbox"
              checked={displayRatingPercentage}
              className={styles.input}
            />

            <span
              className={styles.ratingSlider}
              role="presentation"
              onClick={e => {
                setDisplayRatingPercentage(!displayRatingPercentage);
                e.stopPropagation();
              }}
            >
              <span
                className={
                  displayRatingPercentage
                    ? styles.ratingPercentageOn
                    : styles.ratingPercentageOff
                }
              >
                %
              </span>
              <span
                className={
                  !displayRatingPercentage
                    ? styles.ratingNumberOn
                    : styles.ratingNumberOff
                }
              >
                #
              </span>
            </span>
          </label>
        </span>
      </div>
      <Bar
        parent={parent}
        data={data}
        unfilteredData={unfilteredData}
        color="#5200F1"
        marginLeft="110"
        height="200"
        tickFrequency="1"
        start={start}
        end={end}
        onChartClick={onChartClick}
        filterCollectDataGroup={filterCollectDataGroup}
        showCounts
        onSetFiltersRelation={onSetFiltersRelation}
        displayRatingPercentage={displayRatingPercentage}
        numberOfResults={data.reduce((total, v) => total + v.y, 0)}
        unfilteredNumberOfResults={
          unfilteredData &&
          unfilteredData.reduce((total, v) => total + v.y, 0) > 0
            ? unfilteredData.reduce((total, v) => total + v.y, 0)
            : 1
        }
        block={block}
        activeFilteringOnThisChart={
          !!(
            activeFilters &&
            activeFilters.length &&
            activeFilters.some(
              activeFilter => activeFilter.filterName === block.id
            )
          )
        }
      />
      <div className={styles.additionalDetailsContainer}>
        Average rating:{' '}
        <span className={styles.averageRatingCount}>
          {getAverageRating(
            data.filter(d => !(block && block.allowSkip && d.x < 0))
          ).toFixed(2)}
        </span>
      </div>
      {responses < 75 &&
      !(
        activeFilters &&
        activeFilters.length &&
        activeFilters.some(
          activeFilter =>
            activeFilter.value &&
            extremeUsersFilterLabels.includes(activeFilter.value)
        )
      ) ? (
        <div className={styles.disclaimer}>
          <div>
            Disclaimer: The{' '}
            {activeFilters && activeFilters.length ? 'selected' : 'total'}{' '}
            sample is rather small
            {` (< 75 respondents) `} which means that results are indicative but
            might not be statistically relevant.
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Slider;
