import removeGroupFromSurvey from '../removeGroupFromSurvey/removeGroupFromSurvey';

export default (questionId, c, q, removeOnlyFromContent, preserveParent) => {
  const survey = JSON.parse(JSON.stringify({ content: c, questions: q }));

  let { content } = survey;
  const { questions } = survey;

  if (content.flows.indexOf(questionId) > -1) {
    content.flows.splice(content.flows.indexOf(questionId), 1);
  }

  const additionalGroupsRemoved = [];
  content.groups.forEach(group => {
    if (group.flows.indexOf(questionId) > -1) {
      group.flows.splice(group.flows.indexOf(questionId), 1);

      if (group.flows && group.flows.length === 0) {
        const innerContent = removeGroupFromSurvey(
          group.id,
          content,
          questions,
          preserveParent
        );
        content = innerContent.content; // eslint-disable-line
        additionalGroupsRemoved.push(group.id);
      }
    }
  });

  if (!removeOnlyFromContent) {
    delete questions[questionId];
  }

  return { content, questions, additionalGroupsRemoved };
};
