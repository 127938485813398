import React, { useState, useEffect } from 'react';

import {
  SURVEY_END_TYPES,
  QUESTION_DESIGNER_ACTION,
  QUESTION_TYPES
} from '../../../../helpers/constants';
import Icon from '../../../Icon/Icon';
import QuestionsListDropdown from '../QuestionsListDropdown/QuestionsListDropdown';
import { getNextNeigboingFlow } from '../../../../helpers/getNeigboringFlows/getNeigboringFlows';
import isActionAllowed from '../../../../helpers/isActionAllowed/isActionAllowed';

import styles from './FlowLogicJump.module.css';

export default ({
  flow,
  surveyLists,
  isGroup,
  actions,
  surveyStructure,
  isActive
}) => {
  const [nextNativeFlowId, setNextNativeFlowId] = useState(null);
  const [surveyListFlow, setSurveyListFlow] = useState(null);

  useEffect(
    () => {
      let sourceFlow;
      if (isGroup) {
        let groupFlows = surveyLists.filter(
          f => f.group && f.group.id === flow.id
        );
        if (groupFlows && groupFlows.length) {
          sourceFlow = groupFlows[0].group;
        }

        if (!sourceFlow) {
          groupFlows = surveyLists.filter(
            f =>
              f.group &&
              f.group.parentGroup &&
              f.group.parentGroup.id === flow.id
          );
          if (groupFlows && groupFlows.length) {
            sourceFlow = groupFlows[0].group.parentGroup;
          }
        }
      }
      if (!isGroup) {
        sourceFlow = surveyLists.find(f => f.id === flow.id);
      }

      const nextNativeFlow = getNextNeigboingFlow(sourceFlow, surveyLists);
      setSurveyListFlow(sourceFlow);
      setNextNativeFlowId(nextNativeFlow ? nextNativeFlow.id : null);
    },
    [surveyLists]
  );

  const getNextFlowValue = () => {
    if (surveyListFlow && surveyListFlow.nextFlow) {
      return surveyListFlow.nextFlow;
    }
    if (surveyListFlow && surveyListFlow.end) {
      return surveyListFlow.end;
    }
    if (nextNativeFlowId) {
      return nextNativeFlowId;
    }
    return SURVEY_END_TYPES.SUCCESSFUL;
  };

  const onOptionClick = selectedJump => {
    if (!isGroup) {
      actions.setQuestionJump(selectedJump);
    }

    if (isGroup) {
      actions.setGroupJump(flow.id, selectedJump);
    }
  };

  const getAdditionalRow = () => {
    if (flow && flow.type === QUESTION_TYPES.IFRAME) {
      return (
        <div className={`${styles.dropdownRow} ${styles.additionalRow}`}>
          Respondents can proceed after at least
          <input
            className={styles.secondsInput}
            value={flow.enableNextAfterSeconds}
            onChange={e =>
              actions.updateQuestionProperty(
                'enableNextAfterSeconds',
                e.target.value
              )
            }
            type="number"
            onKeyDown={evt =>
              evt.key === 'ArrowDown' ||
              evt.key === 'ArrowUp' ||
              evt.key === 'e' ||
              evt.key === 'E' ||
              evt.key === '.' ||
              evt.key === '-'
                ? evt.preventDefault()
                : null
            }
          />
          sec
        </div>
      );
    }

    return null;
  };

  if (
    surveyListFlow &&
    surveyListFlow.group &&
    surveyListFlow.group.randomized
  ) {
    return (
      <div
        className={`${styles.container} question-logic-jump-popup ${
          isActive ? styles.containerActiveFlow : ''
        } ${isGroup ? styles.group : ''}`}
      >
        {isGroup && <div className={styles.dummyLeftVerticalLine} />}
        <div className={styles.containerRow}>
          <div className={styles.dropdownRow}>
            Logic jumps on block level are not allowed because the blocks within
            this group are randomized.
          </div>
          {getAdditionalRow()}
        </div>
      </div>
    );
  }

  if (surveyListFlow && surveyListFlow.jumpFromDistributor) {
    return (
      <div
        className={`${styles.container} question-logic-jump-popup ${
          isActive ? styles.containerActiveFlow : ''
        } ${isGroup ? styles.group : ''}`}
      >
        {isGroup && <div className={styles.dummyLeftVerticalLine} />}
        <div className={styles.containerRow}>
          <div className={styles.dropdownRow}>
            This group jumps to the first available question after the
            distributor so all paths come back together.
          </div>
          {getAdditionalRow()}
        </div>
      </div>
    );
  }

  if (
    surveyListFlow &&
    surveyListFlow.additionalInformation &&
    surveyListFlow.additionalInformation.lastGroupQuestion
  ) {
    return (
      <div
        className={`${styles.container} question-logic-jump-popup ${
          styles.lastGroupQuestion
        }`}
      >
        <div className={styles.containerRow}>
          <div className={styles.dropdownRow}>
            {`This jump is equal to the group's jump.`}
          </div>
          {getAdditionalRow()}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.container} question-logic-jump-popup ${
        isActive ? styles.containerActiveFlow : ''
      } ${isGroup ? styles.group : ''}`}
    >
      {isGroup && <div className={styles.dummyLeftVerticalLine} />}
      <div className={styles.containerRow}>
        <div className={styles.dropdownRow}>
          <Icon type="ljArrow" className={styles.ljArrow} />
          This {isGroup ? 'group' : 'block'} jumps to
          <QuestionsListDropdown
            value={getNextFlowValue()}
            onOptionClick={selectedJump => onOptionClick(selectedJump)}
            isActionAllowedChecker={destinationFlow =>
              isActionAllowed(
                isGroup
                  ? QUESTION_DESIGNER_ACTION.SET_GROUP_JUMP
                  : QUESTION_DESIGNER_ACTION.SET_QUESTION_JUMP,
                surveyListFlow,
                destinationFlow.id,
                surveyLists,
                surveyStructure
              )
            }
          />
          after it finishes
        </div>
        {getAdditionalRow()}
      </div>
    </div>
  );
};
