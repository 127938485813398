import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';

const ObjectID = require('bson-objectid');

export default (state, isTop, firstFlow, secondFlow) => {
  const newSurvey = JSON.parse(JSON.stringify(state.survey));

  const shouldSkip =
    firstFlow.nestingLevel === 2 ||
    secondFlow.nestingLevel === 2 ||
    firstFlow.parent ||
    secondFlow.parent;

  let newGroup;
  if (!shouldSkip) {
    newGroup = {
      id: ObjectID().toString(),
      label: `Group ${newSurvey.content.groups.length + 1}`,
      flows: isTop
        ? [secondFlow.id, firstFlow.id]
        : [firstFlow.id, secondFlow.id]
    };

    newSurvey.content.groups.push(newGroup);

    // Main flow
    const firstFlowIndex = newSurvey.content.flows.indexOf(firstFlow.id);
    if (firstFlowIndex > -1) {
      newSurvey.content.flows = newSurvey.content.flows.filter(
        f => f !== firstFlow.id
      );
      newSurvey.content.flows = newSurvey.content.flows.filter(
        f => f !== secondFlow.id
      );

      newSurvey.content.flows.splice(
        isTop ? firstFlowIndex - 1 : firstFlowIndex,
        0,
        newGroup.id
      );
    }

    // Inside of group flow
    if (firstFlowIndex === -1) {
      const groupsFlowIndex = newSurvey.content.groups.findIndex(
        g => g.flows.indexOf(firstFlow.id) > -1
      );

      if (
        groupsFlowIndex !== undefined &&
        newSurvey.content.groups[groupsFlowIndex]
      ) {
        newSurvey.content.groups[
          groupsFlowIndex
        ].flows = newSurvey.content.groups[groupsFlowIndex].flows.filter(
          f => f !== firstFlow.id
        );
        newSurvey.content.groups[
          groupsFlowIndex
        ].flows = newSurvey.content.groups[groupsFlowIndex].flows.filter(
          f => f !== secondFlow.id
        );

        const firstFlowIndexInGroup = state.survey.content.groups[
          groupsFlowIndex
        ].flows.indexOf(firstFlow.id);

        newSurvey.content.groups[groupsFlowIndex].flows.splice(
          isTop ? firstFlowIndexInGroup - 1 : firstFlowIndexInGroup,
          0,
          newGroup.id
        );
      }
    }
  }

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      content: newSurvey.content,
      questions: newSurvey.questions
    }
  };

  if (newGroup) {
    // Set new question as active question
    newState.activeFlow = newGroup;
  }

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return { newState };
};
