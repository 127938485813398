import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import updateQuestionBranch from './updateQuestionBranch';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  const newState = updateQuestionBranch(
    surveysState,
    action.flowId,
    action.branch,
    action.updateActiveQuestion,
    action.onlyLabelChange
  );

  if (
    action &&
    action.updateActiveQuestion &&
    action.api &&
    surveysState.activeFlow &&
    !surveysState.activeFlow.flows &&
    surveysState.activeFlow.id
  ) {
    if (surveysState.activeFlow && !surveysState.activeFlow.flows) {
      const newSurveyStateActiveFlow = {
        question: { ...surveysState.activeFlow }
      };
      action.api('updateQuestion', newSurveyStateActiveFlow);
    }

    // Update survey
    const surveyUpdateContent = {
      survey: newState.newState.survey.id,
      content: newState.newState.survey.content
    };
    action.api('updateSurvey', { variables: surveyUpdateContent });
  }

  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });
}
