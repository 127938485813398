import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback
} from 'react';

import Icon from '../Icon/Icon';
import FlowChart from './FlowChart';
import SurveyBuilderContext from '../../SurveyBuilderContext';

import styles from './FlowNavigation.module.css';

const FlowNavigation = () => {
  const canvasWrapperRef = useRef(null);
  const [expanded, setExpanded] = useState(null);
  const [canvasWrapperWidth, setCanvasWrapperWidth] = useState(null);
  const [canvasWrapperHeight, setCanvasWrapperHeight] = useState(null);

  const { state, actions } = useContext(SurveyBuilderContext);
  const { content, questions } = state.survey;
  const { surveyLists } = state;

  useEffect(
    () => {
      setCanwasDimensions();
    },
    [canvasWrapperRef, expanded]
  );

  const setCanwasDimensions = () => {
    const wrapperWidth =
      canvasWrapperRef &&
      canvasWrapperRef.current &&
      canvasWrapperRef.current.offsetWidth;
    const wrapperHeight =
      canvasWrapperRef &&
      canvasWrapperRef.current &&
      canvasWrapperRef.current.offsetHeight;
    setCanvasWrapperWidth(wrapperWidth);
    setCanvasWrapperHeight(wrapperHeight);
  };

  const onQuestionClick = useCallback(flow => actions.setActiveFlow(flow), []);

  return (
    <div
      className={
        expanded ? `${styles.container} ${styles.expanded}` : styles.container
      }
      ref={canvasWrapperRef}
    >
      <div
        className={`${styles.expandButton} flow-navigation-expand-button`}
        role="presentation"
        onClick={() => setExpanded(!expanded)}
      >
        <div className={styles.label}>Logic Map</div>
        <Icon className={styles.icon} type="collapse_arrow" />
      </div>
      <div className={`${styles.dagContainer} flow-navigation-dag-container`}>
        <FlowChart
          width={canvasWrapperWidth}
          height={canvasWrapperHeight}
          onQuestionClick={onQuestionClick}
          surveyLists={surveyLists}
          content={content}
          questions={questions}
          activeFlowId={state.activeFlow && state.activeFlow.id}
        />
      </div>
    </div>
  );
};

export default FlowNavigation;
