import { connect } from 'react-redux';
import * as actions from '../actions';

import CampaignCompletedPage from '../components/Campaigns/CampaignCompleted/CampaignCompletedPage';

const mapStateToProps = state => ({
  campaign: state.userCampaigns.campaign,
  result: state.userCampaigns.result
});

const mapDispatchToProps = dispatch => ({
  setCampaignComponents: (campaign, block, result) =>
    dispatch(actions.setCampaignComponents(campaign, block, result)),
  resetToInitState: () => dispatch(actions.resetToInitState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignCompletedPage);
