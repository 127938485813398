import React from 'react';

import QuestionContainer from '../../components/QuestionsColumn/components/QuestionContainer/QuestionContainer';
import Group from '../../components/QuestionsColumn/components/Group/Group';
import QuestionsSet from '../../components/QuestionsColumn/QuestionsSet';

const getSet = (returnComponents, elements, setIndex, isInGroup) => {
  const setElements = returnComponents
    ? elements.map((element, index) => {
        const copiedElement = {
          ...element,
          props: { ...element.props, indexInSet: index, setIndex }
        };
        return copiedElement;
      })
    : elements;

  if (!returnComponents) {
    return { [setIndex]: setElements };
  }

  return (
    <QuestionsSet
      elements={setElements}
      key={setIndex}
      setIndex={setIndex}
      isInGroup={isInGroup}
    />
  );
};

export const getQuestionsColumn = (returnComponents, content, questions) => {
  let globalIndex = 0;
  const groupsList = content.groups;
  let setIndex = 0;

  const getFlows = (flows, isInGroup) => {
    let flowsSet = [];

    const addSeparateQuestionsToSet = () => {
      if (flowsSet.length) {
        let lastSet = [];

        for (let i = flowsSet.length - 1; i >= 0; i -= 1) {
          // We need to check if this is a group (same as next if)
          if (flowsSet[i] && flowsSet[i].props && flowsSet[i].props.elements) {
            break;
          }
          // Same as previous if
          if (!returnComponents && flowsSet[i] && Array.isArray(flowsSet[i])) {
            break;
          }

          if (flowsSet[i]) {
            lastSet = [flowsSet[i], ...lastSet];
            flowsSet.pop();
          }
        }
        if (lastSet.length) {
          flowsSet = [
            ...flowsSet,
            getSet(returnComponents, lastSet, setIndex, isInGroup)
          ];
          setIndex += 1;
        }
      }
    };

    flows.forEach((el, index) => {
      if (groupsList.some(group => group.id === el)) {
        // Check if el is a group
        const group = groupsList.find(g => g.id === el);
        const elements = getFlows(group.flows, true);

        if (returnComponents) {
          addSeparateQuestionsToSet(index);
          if (flowsSet.length) {
            flowsSet = [
              ...flowsSet,
              <Group
                key={group.id.toString()}
                group={group}
                elements={elements}
              />
            ];
          }
          if (flowsSet.length === 0) {
            flowsSet = [
              <Group
                key={group.id.toString()}
                group={group}
                elements={elements}
              />
            ];
          }
        }
        if (!returnComponents) {
          addSeparateQuestionsToSet();
          if (flowsSet.length) {
            flowsSet = [...flowsSet, elements];
          }
          if (flowsSet.length === 0) {
            flowsSet = [elements];
          }
        }

        setIndex += 1;
        return;
      }

      globalIndex += 1;

      if (questions[el]) {
        if (returnComponents) {
          flowsSet = [
            ...flowsSet,
            <QuestionContainer
              key={el.toString()}
              question={questions[el]}
              globalIndex={globalIndex}
              setIndex={setIndex}
            />
          ];
        }
        if (!returnComponents) {
          flowsSet = [...flowsSet, questions[el].id];
        }
      }

      if (flows.length - 1 === index && flowsSet.length) {
        addSeparateQuestionsToSet();
      }
    });
    return flowsSet;
  };

  return getFlows(content.flows);
};

export const getFlattenedQuestionsColumn = questionsColumn => {
  if (questionsColumn) {
    const flattenedQuestionsColumn = [].concat(
      ...[].concat(...questionsColumn)
    );
    const flatenedQuestionsArray = flattenedQuestionsColumn.reduce(
      (acc, el) => ({
        ...acc,
        ...el
      }),
      {}
    );
    if (flatenedQuestionsArray) {
      return flatenedQuestionsArray;
    }
  }
  return null;
};

export const getQuestionPosition = (
  sourceSet,
  sourceQuestionIndex,
  destinationSet,
  destinationQuestionIndex
) => {
  if (destinationQuestionIndex === 0) {
    return {
      position: 'BEFORE',
      questionId: destinationSet[0]
    };
  }
  if (sourceSet === destinationSet) {
    if (destinationQuestionIndex > sourceQuestionIndex) {
      return {
        position: 'AFTER',
        questionId: destinationSet[destinationQuestionIndex]
      };
    }
    return {
      position: 'AFTER',
      questionId: destinationSet[destinationQuestionIndex - 1]
    };
  }
  return {
    position: 'AFTER',
    questionId: destinationSet[destinationQuestionIndex - 1]
  };
};

export const getGroupParent = (groupId, groups) => {
  const parentGroup = groups.find(group => group.flows.indexOf(groupId) > -1);
  return parentGroup || null;
};
