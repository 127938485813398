import { QUESTION_TYPES, SURVEY_BUILDER_POPUP_TYPES } from './constants';

export default (event, actions) => {
  if (!event.destination) return;

  const getSetIndex = set => {
    const setElements = set.split('-');
    if (setElements && setElements[2]) {
      return parseInt(setElements[2], 10);
    }
    return null;
  };

  const sourceSetIndex = getSetIndex(event.source.droppableId);
  const sourceQuestionindex = event.source.index;
  const destinationDropElements = event.destination.droppableId.split('-');
  const destinationType = destinationDropElements[0];
  const isDropZone = destinationType === 'dropzone';
  const newQuestionType = event.draggableId;

  // Drop to add new question block placeholder
  if (event.destination.droppableId === 'add-new-question') {
    // Drag from new question blocks
    if (event.source.droppableId === 'new-questions-list') {
      // Drop new question
      if (newQuestionType === QUESTION_TYPES.ADD_TO_COMMUNITY) {
        actions.toggleSurveyBuilderPopup({
          type: SURVEY_BUILDER_POPUP_TYPES.RESEARCH_COST_INCREASED,
          onClose: () => actions.toggleSurveyBuilderPopup(),
          onClick: () => actions.addQuestion(newQuestionType)
        });
      } else {
        actions.addQuestion(newQuestionType);
      }
      return;
    }

    if (event.source.droppableId !== 'new-questions-list') {
      // Existing question drop (no dropdown locations)
      actions.moveQuestion(isDropZone, sourceSetIndex, sourceQuestionindex);
      return;
    }
  }

  const destinationSetIndex = isDropZone
    ? destinationDropElements[1]
    : getSetIndex(event.destination.droppableId);
  const destionationQuestionindex = isDropZone
    ? parseInt(destinationDropElements[2], 10)
    : event.destination.index;

  // Drag from new question blocks to existing set
  if (event.source.droppableId === 'new-questions-list') {
    // Drop add new question to existing sets
    if (newQuestionType === QUESTION_TYPES.ADD_TO_COMMUNITY) {
      actions.toggleSurveyBuilderPopup({
        type: SURVEY_BUILDER_POPUP_TYPES.RESEARCH_COST_INCREASED,
        onClose: () => actions.toggleSurveyBuilderPopup(),
        onClick: () =>
          actions.addQuestion(
            newQuestionType,
            isDropZone,
            destinationSetIndex,
            destionationQuestionindex
          )
      });
    } else {
      actions.addQuestion(
        newQuestionType,
        isDropZone,
        destinationSetIndex,
        destionationQuestionindex
      );
    }
    return;
  }

  // Move question blocks to other existing set position
  if (event.source.droppableId !== 'new-questions-list') {
    actions.moveQuestion(
      isDropZone,
      sourceSetIndex,
      sourceQuestionindex,
      destinationSetIndex,
      destionationQuestionindex
    );
  }
};
