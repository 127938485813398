import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styles from './UserConsents.module.css';
import AccountCreate from './components/AccountCreate/AccountCreate';
import Footer from '../../../Footer/Footer';
import Loader from '../../../../../../base/components/Loader/Loader';

import errorIcon from '../../../../../../assets/img/error.svg';

import { FINALISE_WEB_SURVEY } from '../../../../../../graphql/Campaigns';

export default props => {
  const {
    translations,
    result,
    campaign,
    strings,
    setConsentCompleted,
    isTesting,
    match,
    history
  } = props;

  const [userDetails, setUserDetails] = useState({
    createProfile: false,
    result: result.id
  });
  const [componentErrors, setComponentErrors] = useState(null);

  const [finaliseWebSurvey, { finaliseWebSurveyLoading }] = useMutation(
    FINALISE_WEB_SURVEY,
    {
      onCompleted: response => {
        if (response && response.finalizeWebSurvey) {
          setConsentCompleted(true);
        }
      },
      onError: () => {
        setComponentErrors(translations.YOU_HAVE_ALREADY_PARTICIPATED);
      }
    }
  );

  if (
    !(
      campaign &&
      (campaign.webVersionEmailConfirmation ||
        campaign.contactForPrizes ||
        campaign.contactForNextSurvey ||
        campaign.addToMailingListOfCustomer ||
        campaign.askToRegisterOnBufflWebSurvey)
    )
  ) {
    setConsentCompleted(true);
  }

  const submitUserDetails = () => {
    setComponentErrors(null);

    finaliseWebSurvey({
      variables: {
        ...userDetails,
        testing: isTesting(),
        language: localStorage.getItem('campaignLanguage')
      }
    });
  };

  /* eslint-disable */
  const onClickNext = () => {
    setComponentErrors(null);

    if (
      (campaign.contactForPrizes && userDetails.contactWin) ||
      (campaign.contactForNextSurvey && userDetails.contactNextSurveys) ||
      (campaign.addToMailingListOfCustomer &&
        userDetails.addToMailingListOfCustomer) ||
      userDetails.createProfile ||
      campaign.webVersionEmailConfirmation
    ) {
      const regexTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (!userDetails.email) {
        setComponentErrors(translations.EMAIL_IS_REQUIRED);
        return null;
      } else if (!regexTest.test(userDetails.email)) {
        setComponentErrors(translations.PLEASE_ENTER_VALID_EMAIL);
        return null;
      }
    }

    if (
      userDetails.createProfile &&
      (!userDetails.password ||
        !userDetails.repeatPassword ||
        userDetails.password !== userDetails.repeatPassword)
    ) {
      setComponentErrors(translations.PASSWORDS_ARE_NOT_SAME);
      return null;
    }

    submitUserDetails();
  };
  /* eslint-enable */

  const { customClientName, customClientIdentifier, userId } = match.params;

  const goToUrl = url => {
    let finalUrl = url;
    if (customClientIdentifier) {
      finalUrl = `${url}/${customClientName}/${customClientIdentifier}`;
    }
    if (isTesting()) {
      finalUrl = `${url}?test=true`;
    }

    history.push(finalUrl);
  };

  const onClickBack = () => {
    if (
      result &&
      result.id &&
      result.blockResults &&
      result.blockResults.length &&
      userId
    ) {
      const lastBlockId =
        result.blockResults[result.blockResults.length - 1].block.id;
      goToUrl(`/result/${result.id}/block/${lastBlockId}/user/${userId}`);
    }
  };

  const page = [];

  if (finaliseWebSurveyLoading) page.push(<Loader key="loading-popup" />);

  page.push(
    <div className={styles.parentContainer} key="user-consents=page">
      <div className={styles.mainContainer}>
        <AccountCreate
          campaign={campaign}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          componentErrors={componentErrors}
          setComponentErrors={setComponentErrors}
          onClickNext={onClickNext}
          strings={strings}
        />
        {componentErrors && componentErrors.length ? (
          <div className={styles.errorContainer}>
            <img src={errorIcon} alt="Error" className={styles.errorIcon} />
            <span>{componentErrors}</span>
          </div>
        ) : null}
      </div>
      <div>
        <Footer
          onClickNext={() => onClickNext()}
          onClickBack={() => onClickBack()}
          backLabel={translations.BACK}
          nextLabel={translations.NEXT}
        />
      </div>
    </div>
  );

  return page;
};
