import { ApiError, BadFormDataError } from './errors';
/* global localStorage, window */

export const fetchBlob = async (
  url,
  authToken,
  method = 'GET',
  customHeaders = {},
  customBody
) => {
  const res = await fetch(url, {
    headers: {
      'X-Auth-Token': authToken,
      ...customHeaders
    },
    method,
    ...(customBody && method !== 'GET' ? { body: customBody } : {})
  });

  let result;
  try {
    if (res.status === 204) {
      result = {};
    } else if (res.status === 400) {
      result = await res.json();
    } else {
      result = await res.blob();
    }
  } catch (err) {
    throw new Error(`${res.status}: ${res.statusText}`);
  }

  if (res.status === 401) {
    localStorage.removeItem('token');
    window.location.href = '/clients/auth/login';

    throw new ApiError(result.message);
  }

  if (res.status < 200 || res.status >= 300) {
    if (
      result &&
      result.errors &&
      result.errors[0] &&
      result.errors[0].source
    ) {
      throw new BadFormDataError(result.errors);
    }
    if (
      result &&
      result.errors &&
      result.errors[0] &&
      result.errors[0].detail
    ) {
      return {
        status: res.status,
        error: result.errors[0].detail
      };
    }
  }

  return {
    status: res.status,
    blob: result
  };
};

export const fetchJSON = async (
  url,
  authToken,
  method = 'GET',
  data,
  beforeLogin
) => {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'X-Auth-Token': authToken
    },
    method,
    body: JSON.stringify(data)
  });

  let result;
  try {
    if (res.status === 204) {
      result = {};
    } else {
      result = await res.json();
    }
  } catch (err) {
    throw new Error(`${res.status}: ${res.statusText}`);
  }

  if (res.status === 401) {
    localStorage.removeItem('token');
    window.location.href = `/clients/auth/login${
      beforeLogin ? `?beforeLogin=${beforeLogin}` : ''
    }`;

    throw new ApiError(result.message);
  }

  if (res.status < 200 || res.status >= 300) {
    if (result && result.errors[0] && result.errors[0].source) {
      throw new BadFormDataError(result.errors);
    }
  }

  return {
    status: res.status,
    json: result
  };
};

export const fetchRaw = async (url, authToken, method = 'GET', data) => {
  const res = await fetch(url, {
    headers: {
      'X-Auth-Token': authToken
    },
    method,
    body: data
  });

  if (res.status === 401) {
    throw new ApiError();
  }

  return res;
};
