import { QUESTION_TYPES } from '../../../helpers/constants';
import frameWorkValueNames from '../../helpers/frameWorkValueNames';
import getFeaturesFromQuestion from './helpers/getFeaturesFromQuestion';
import graphHasChanged from './helpers/graphHasChanged';

export default (survey, question) => {
  let newSurveyState = null;
  let updateRequest = null;

  const valueNames =
    question &&
    question.matrix &&
    question.matrix.decisionFrameworkType &&
    question.type === QUESTION_TYPES.MATRIX
      ? frameWorkValueNames.find(
          vNames => vNames.type === question.matrix.decisionFrameworkType
        )
      : null;

  if (
    valueNames &&
    valueNames.valuePath &&
    valueNames.xQuestionName &&
    valueNames.yQuestionName &&
    valueNames.updateApi
  ) {
    const surveyGraphs =
      survey &&
      survey[valueNames.valuePath] &&
      survey[valueNames.valuePath].length
        ? survey[valueNames.valuePath]
        : [];

    let graphIndex;
    const graph = surveyGraphs.find((g, index) => {
      graphIndex = index;
      return g.question === question.id;
    });

    if (graph) {
      const features = getFeaturesFromQuestion(
        question,
        valueNames.xQuestionName,
        valueNames.yQuestionName
      );

      const shouldUpdateGraph = graphHasChanged(
        graph,
        features,
        valueNames.xQuestionName,
        valueNames.yQuestionName,
        question.matrix.xLabel,
        question.matrix.yLabel
      );

      if (shouldUpdateGraph) {
        const { updateApi } = valueNames;

        const updatedGraph = {
          ...graph,
          features,
          xLabel: question.matrix.xLabel,
          yLabel: question.matrix.yLabel
        };

        surveyGraphs.splice(graphIndex, 1, updatedGraph);

        newSurveyState = {
          ...survey,
          [valueNames.valuePath]: surveyGraphs
        };

        updateRequest = {
          api: updateApi,
          variables: {
            id: graph.id,
            survey: survey.id,
            features,
            xLabel: question.matrix.xLabel,
            yLabel: question.matrix.yLabel
          }
        };
      }
    }
  }

  return {
    surveyWithUpdatedFramework: newSurveyState,
    updateRequest
  };
};
