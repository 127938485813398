import React, { useState, useEffect } from 'react';
import useLazyQuery from '../../../../surveys/hooks/useLazyQuery';

import styles from './TrackingParamFilters.module.css';
import DebounceInput from '../../../../campaigns/components/DebounceInput/DebounceInput';
import filterResultsButton from '../../../../campaigns/components/CampaignTable/CampaignFilters/assets/filter-results-button.svg';
import Loader from '../../../CommunityDashboard/components/CommunityDashboardPage/components/Loader/Loader';
import Icon from '../../../../campaigns/components/Icon';
import Popup from '../../../../campaigns/components/Popup';
import SelectTimeframePopup from '../SelectTimeframePopup/SelectTimeframePopup';
import {
  GET_ACQUISITION_METRIC_DETAILS_FROM_URL,
  SEARCH_TRACKING_PROPERTY
} from '../../../../graphql/AcquisitionMetrics';
import ActiveFilters from '../ActiveFilters/ActiveFilters';
import formatDateForAcquisitionDate from '../../helpers/formatDateForAcquisitionDate';
import MultipleSearchDropdown from '../../../CommunityDashboard/components/CommunityDashboardPage/components/Filters/components/MultipleSearchDropdown/MultipleSearchDropdown';

export default ({
  appliedFilters,
  setAppliedFilters,
  dataLoading,
  timeframe,
  setTimeframe,
  getTimeframeDates
}) => {
  const [expandFiltersContainer, setExpandFiltersContainer] = useState(false);
  const [innerAppliedFilters, setInnerAppliedFilters] = useState(
    appliedFilters
  );
  const [showTimeframePopup, setShowTimeframePopup] = useState(false);
  const [acquisitionLink, setAcquisitionLink] = useState(null);
  const [urlDetailsLoading, setUrlDetailsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const searchTrackingProperty = useLazyQuery(SEARCH_TRACKING_PROPERTY, {
    fetchPolicy: 'no-cache'
  });

  const getAcquisitionMetricDetailsFromURL = useLazyQuery(
    GET_ACQUISITION_METRIC_DETAILS_FROM_URL,
    {
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(
    () => {
      setInnerAppliedFilters(appliedFilters);
      setIsDirty(false);
    },
    [appliedFilters, expandFiltersContainer]
  );

  const getSearchTrackingProperty = async (page, search, param) => {
    const preparedAppliedFilters = { ...innerAppliedFilters };
    preparedAppliedFilters[param] = [];

    const searchResults = await searchTrackingProperty({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        search,
        param,
        startTimeframe: timeframe.startTimeframe,
        endTimeframe: timeframe.endTimeframe
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchTrackingProperty &&
      searchResults.data.searchTrackingProperty.options
      ? searchResults.data.searchTrackingProperty.options
      : [];
  };

  const onParamSelectCompleted = (property, value) => {
    const innerFiltersToApply = { ...innerAppliedFilters };

    const isValueSelected =
      innerAppliedFilters &&
      innerAppliedFilters[property] &&
      innerAppliedFilters[property].length &&
      innerAppliedFilters[property].indexOf(value) > -1;

    if (isValueSelected) {
      const innerFiltersWithoutRemoved = innerAppliedFilters[property].filter(
        iF => iF !== value
      );
      innerFiltersToApply[property] = innerFiltersWithoutRemoved;
    }

    if (!isValueSelected) {
      if (
        innerAppliedFilters &&
        innerAppliedFilters[property] &&
        innerAppliedFilters[property].length
      ) {
        innerFiltersToApply[property].push(value);
      } else {
        innerFiltersToApply[property] = [value];
      }
    }

    setInnerAppliedFilters(innerFiltersToApply);
    setIsDirty(true);
  };

  const setAppliedFiltersFromURL = async url => {
    setUrlDetailsLoading(true);
    const acquisitionMetricDetails = await getAcquisitionMetricDetailsFromURL({
      variables: {
        url
      }
    });
    setUrlDetailsLoading(false);

    if (
      acquisitionMetricDetails &&
      acquisitionMetricDetails.data &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl.params
    ) {
      delete acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl
        .params.__typename;

      const paramsToAssign = {
        ...acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl
          .params
      };
      setInnerAppliedFilters(paramsToAssign);
      setIsDirty(true);
    }

    if (
      acquisitionMetricDetails &&
      acquisitionMetricDetails.data &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl.errors &&
      acquisitionMetricDetails.data.getAcquisitionMetricDetailsFromUrl.errors
        .length
    ) {
      setInnerAppliedFilters({});
      setIsDirty(true);
    }
  };

  const isGlobalLoading = dataLoading || urlDetailsLoading;

  return (
    <div
      className={
        isGlobalLoading
          ? `${styles.container} ${
              styles.transparent
            } community-dashboard-filters-container`
          : `${styles.container} community-dashboard-filters-container`
      }
    >
      <div className={styles.selectTimeframeContainer}>
        <div className={styles.dateRange}>
          {formatDateForAcquisitionDate(timeframe.startTimeframe)} -{' '}
          {formatDateForAcquisitionDate(timeframe.endTimeframe)}{' '}
          <span
            className={
              expandFiltersContainer
                ? `${styles.editIcon} ${styles.editIconHidden}`
                : styles.editIcon
            }
            onClick={() => {
              if (!expandFiltersContainer) {
                setShowTimeframePopup(true);
              }
            }}
            role="presentation"
          >
            <Icon type="edit" />
          </span>
        </div>
        {showTimeframePopup ? (
          <Popup
            component={
              <SelectTimeframePopup
                title="Select timeframe"
                onClose={() => setShowTimeframePopup(false)}
                onSubmit={dates => {
                  const newTimeframe = getTimeframeDates(dates);
                  setTimeframe(newTimeframe);
                  setShowTimeframePopup(false);
                }}
                startTimeframe={timeframe.startTimeframe}
                endTimeframe={timeframe.endTimeframe}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
            showOverflow
          />
        ) : null}
      </div>
      <div className={styles.filtersButtonContainer}>
        <div
          role="presentation"
          className={
            expandFiltersContainer
              ? `${styles.filtersButton} ${styles.filtersButtonActive}`
              : styles.filtersButton
          }
          onClick={() => {
            if (!isGlobalLoading) {
              setExpandFiltersContainer(!expandFiltersContainer);
            }
          }}
        >
          <img src={filterResultsButton} alt="Filter result icon" />
          Filters
        </div>
      </div>
      {expandFiltersContainer ? (
        <div className={styles.filtersContainer}>
          {!isGlobalLoading ? (
            <>
              <div className={styles.campaignLinkContainer}>
                <div className={styles.campaignLinkTitle}>
                  Acquisition campaign link
                </div>
                <div>
                  <DebounceInput
                    className={styles.linkInput}
                    initialValue={acquisitionLink || ''}
                    onChangeValue={search => {
                      setAcquisitionLink(search);
                      setAppliedFiltersFromURL(search);
                    }}
                    placeholder="Paste link here"
                  />
                </div>
              </div>
              <div className={styles.dropdownContainers}>
                <div className={styles.searchDropdownContainer}>
                  <div className={styles.searchDropdownTitle}>Channel</div>
                  <MultipleSearchDropdown
                    fetchOptions={(targetPage, searchString) =>
                      getSearchTrackingProperty(
                        targetPage,
                        searchString,
                        'channel'
                      )
                    }
                    placeholder="Select channel"
                    onSelectCompleted={value =>
                      onParamSelectCompleted('channel', value)
                    }
                    value={{
                      value: innerAppliedFilters.channel,
                      label: innerAppliedFilters.channel
                    }}
                    disabled={acquisitionLink && acquisitionLink !== ''}
                    hideSelectAllCheckbox
                    fixedWidth={219}
                  />
                </div>
                <div className={styles.searchDropdownContainer}>
                  <div className={styles.searchDropdownTitle}>Launch date</div>
                  <MultipleSearchDropdown
                    fetchOptions={(targetPage, searchString) =>
                      getSearchTrackingProperty(
                        targetPage,
                        searchString,
                        'launchDate'
                      )
                    }
                    placeholder="dd/mm/yyyy"
                    onSelectCompleted={value =>
                      onParamSelectCompleted('launchDate', value)
                    }
                    value={{
                      value: innerAppliedFilters.launchDate,
                      label: innerAppliedFilters.launchDate
                    }}
                    disabled={acquisitionLink && acquisitionLink !== ''}
                    hideSelectAllCheckbox
                    fixedWidth={219}
                  />
                </div>
                <div className={styles.searchDropdownContainer}>
                  <div className={styles.searchDropdownTitle}>Partner</div>
                  <MultipleSearchDropdown
                    fetchOptions={(targetPage, searchString) =>
                      getSearchTrackingProperty(
                        targetPage,
                        searchString,
                        'partner'
                      )
                    }
                    placeholder="Select partner"
                    onSelectCompleted={value =>
                      onParamSelectCompleted('partner', value)
                    }
                    value={{
                      value: innerAppliedFilters.partner,
                      label: innerAppliedFilters.partner
                    }}
                    disabled={acquisitionLink && acquisitionLink !== ''}
                    hideSelectAllCheckbox
                    fixedWidth={219}
                  />
                </div>
                <div className={styles.searchDropdownContainer}>
                  <div className={styles.searchDropdownTitle}>Medium</div>
                  <MultipleSearchDropdown
                    fetchOptions={(targetPage, searchString) =>
                      getSearchTrackingProperty(
                        targetPage,
                        searchString,
                        'medium'
                      )
                    }
                    placeholder="Select medium"
                    onSelectCompleted={value =>
                      onParamSelectCompleted('medium', value)
                    }
                    value={{
                      value: innerAppliedFilters.medium,
                      label: innerAppliedFilters.medium
                    }}
                    disabled={acquisitionLink && acquisitionLink !== ''}
                    hideSelectAllCheckbox
                    fixedWidth={219}
                  />
                </div>
                <div className={styles.searchDropdownContainer}>
                  <div className={styles.searchDropdownTitle}>Action</div>
                  <MultipleSearchDropdown
                    fetchOptions={(targetPage, searchString) =>
                      getSearchTrackingProperty(
                        targetPage,
                        searchString,
                        'action'
                      )
                    }
                    placeholder="Select action"
                    onSelectCompleted={value =>
                      onParamSelectCompleted('action', value)
                    }
                    value={{
                      value: innerAppliedFilters.action,
                      label: innerAppliedFilters.action
                    }}
                    disabled={acquisitionLink && acquisitionLink !== ''}
                    hideSelectAllCheckbox
                    fixedWidth={219}
                  />
                </div>
              </div>
              <div
                className={`${styles.footer} ${
                  innerAppliedFilters && innerAppliedFilters.length
                    ? styles.innerFilters
                    : ''
                }`}
              >
                <div className={styles.filterControlsContainer}>
                  <div
                    role="presentation"
                    className={styles.whiteButton}
                    onClick={() => {
                      setInnerAppliedFilters(appliedFilters);
                      setExpandFiltersContainer(false);
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    role="presentation"
                    className={
                      isDirty ? styles.purpleButton : styles.buttonDisabled
                    }
                    onClick={() => {
                      if (isDirty) {
                        setAppliedFilters({ ...innerAppliedFilters });
                        setExpandFiltersContainer(false);
                      }
                    }}
                  >
                    Apply filters
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={{ top: '25px' }}>
              <Loader fixed={false} />
            </div>
          )}
        </div>
      ) : (
        <ActiveFilters
          appliedFilters={appliedFilters}
          isGlobalLoading={isGlobalLoading}
          onClearFiltersClick={() => {
            if (!isGlobalLoading) {
              setAppliedFilters({});
              setAcquisitionLink(null);
            }
          }}
        />
      )}
    </div>
  );
};
