import React from 'react';

import closeIcon from '../../../../../../../../assets/img/delete.svg';

import styles from './ExtraQuestions.module.css';

export default ({ message, onClose }) => (
  <div className={styles.container}>
    <div className={styles.description}>{message}</div>
    <div className={styles.footer}>
      <div />
      <div className={styles.actions}>
        <img
          className={styles.close}
          src={closeIcon}
          alt="Close icon"
          onClick={onClose}
          role="presentation"
        />
      </div>
    </div>
  </div>
);
