import React, { useState, useEffect, useRef } from 'react';

import Icon from '../../../../../../../../Icon';
import styles from './QuestionsSelectorDropdownPopup.module.css';

export default ({
  options,
  hideDropdownPopup,
  emptyListPlaceholder,
  dropdownParentRef,
  marginLeft,
  internalData,
  setInternalData
}) => {
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target)
      )
    ) {
      hideDropdownPopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(
    () => {
      const height = dropdownParentRef.current.offsetHeight;
      const rect = dropdownParentRef.current.getBoundingClientRect();
      const win = dropdownParentRef.current.ownerDocument.defaultView;

      setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
    },
    [dropdownParentRef]
  );

  const onQuestionOptionCheckboxClick = option => {
    const newInternalData = { ...internalData };
    const newSelectedQuestion = {
      questionId: option.id,
      type: option.optionType
    };

    const isQuestionSelected =
      internalData &&
      internalData.questions &&
      internalData.questions.length &&
      internalData.questions.some(q => q.questionId === option.id);

    if (isQuestionSelected) {
      const newQuestons = internalData.questions.filter(
        q => q.questionId !== option.id
      );
      newInternalData.questions = newQuestons;
    } else {
      newInternalData.questions = [
        ...((internalData && internalData.questions) || []),
        newSelectedQuestion
      ];
    }

    setInternalData(newInternalData);
  };

  const isOptionSelected = option => {
    if (
      internalData &&
      internalData.questions &&
      internalData.questions.length
    ) {
      const isQuestionSelected = internalData.questions.some(
        q => q.questionId === option.id
      );
      return isQuestionSelected;
    }
    return false;
  };

  return (
    <div
      ref={wrapperRef}
      className={styles.container}
      style={{ top: `${topPosition}px`, marginLeft }}
    >
      <div className={styles.dropdownPopupItemsContainer}>
        {options && options.length ? (
          options.map(option => (
            <div className={styles.dropdownItem} key={option.id}>
              <input
                className={styles.visibleForSearchCheckbox}
                type="checkbox"
                checked={isOptionSelected(option)}
                onClick={() => onQuestionOptionCheckboxClick(option)}
                onChange={() => {}}
              />
              <span className={styles.dropdownItemNumber}>
                {option.formattedGlobalIndex
                  ? `${option.formattedGlobalIndex}.`
                  : `\u00a0\u00a0\u00a0`}
              </span>
              <Icon
                type={option.icon}
                style={{
                  color: '#5200f1',
                  marginLeft: '5px',
                  marginRight: '5px',
                  fontSize: '10px'
                }}
              />
              {option.question}
            </div>
          ))
        ) : (
          <div className={styles.noAvailableOptions}>
            {emptyListPlaceholder || 'No available options'}
          </div>
        )}
      </div>
    </div>
  );
};
