import { put, select } from 'redux-saga/effects';

import { SET_NEW_STATE } from '../../actions';

import changeMatrixFrameworkType from './changeMatrixFrameworkType';
import executeRequestsInSequence from '../../helpers/executeRequestsInSequence';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  if (
    action.question &&
    action.question.matrix &&
    action.question.matrix.decisionFrameworkType &&
    action.previousDecisionFrameworkType
  ) {
    const { newSurveyFrameworksState, requests } = changeMatrixFrameworkType(
      surveysState,
      action.question,
      action.previousDecisionFrameworkType
    );
    if (newSurveyFrameworksState) {
      const newState = {
        ...newSurveyFrameworksState
      };

      yield put({ type: SET_NEW_STATE, newState });

      if (requests && requests.length) {
        yield executeRequestsInSequence(action.api, requests);
      }
    }
  }
}
