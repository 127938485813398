import React from 'react';

import styles from './AddInsight.module.css';
import { insightQuestionTypes } from '../../../Insights/helpers/constants';

export default ({
  questionId,
  setShowAddInsightsPopup,
  type = insightQuestionTypes.QUESTION
}) => (
  <div
    onClick={() =>
      setShowAddInsightsPopup({
        baseQuestionId: questionId,
        baseQuestionType: type
      })
    }
    className={styles.addInsightButton}
    role="presentation"
  >
    <span className={styles.plusIcon}>+</span> Add insight
  </div>
);
