import React, { useState } from 'react';
import Icon from '../../../../../../../Icon/Icon';
import blackArrowDown from '../../../../../../../../../../../assets/black-arrow-down.svg';
import styles from './BlockDestinationPosition.module.css';
import ShowInsideScreen from '../../../../../../../../../ShowInsideScreen/ShowInsideScreen';
import DropdownOptionsPopup from '../../../../../DropdownOptionsPopup/DropdownOptionsPopup';
import QuestionsListDropdown from '../../../../../QuestionsListDropdown/QuestionsListDropdown';

const DROPDOWN_TYPE = {
  LOCATION: 'location',
  QUESTION: 'question'
};

const LOCATION_TYPES = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER'
};

export default ({
  setShowPropertiesComponent,
  onDoneButtonPress,
  isActionAllowedChecker
}) => {
  const [blockDestinationDropdown, setBlockDestinationDropdown] = useState(
    false
  );
  const [blockDestinationLocation, setBlockDestinationLocation] = useState(
    null
  );
  const [blockDestinationQuestion, setBlockDestinationQuestion] = useState(
    null
  );
  const [dropdownOptionsPopupRef, setDropdownOptionsPopupRef] = useState(null); // setDropdownOptionsPopupRef

  const locationOptions = [
    {
      name: LOCATION_TYPES.BEFORE,
      label: 'Before'
    },
    {
      name: LOCATION_TYPES.AFTER,
      label: 'After'
    }
  ];

  const onActionDropdownBlur = () => {
    setBlockDestinationDropdown(false);
  };

  const isActionAllowedCheckerWithLocation = destinationFlow =>
    isActionAllowedChecker(
      destinationFlow,
      (blockDestinationLocation && blockDestinationLocation.name) || null
    );

  const showBlockDestinationDropdown = () => (
    <ShowInsideScreen
      positionStyle={{
        top: '33px',
        left: '10px',
        bottomPosition: '33px'
      }}
      childReference={dropdownOptionsPopupRef}
      onBlur={() => onActionDropdownBlur()}
    >
      <DropdownOptionsPopup
        options={locationOptions}
        setDropdownOptionsPopupRef={setDropdownOptionsPopupRef}
        onOptionClick={option => {
          setBlockDestinationLocation(option);
          setBlockDestinationDropdown(null);
        }}
      />
    </ShowInsideScreen>
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>Select</div>
      <div>
        <div
          role="presentation"
          className={`${
            styles.dropdownPlaceholder
          } block-destination-position-source`}
          style={{ backgroundImage: `url(${blackArrowDown})` }}
          onClick={() => {
            setBlockDestinationDropdown(DROPDOWN_TYPE.LOCATION);
          }}
        >
          {blockDestinationLocation ? blockDestinationLocation.label : '-'}
        </div>
        {blockDestinationDropdown === DROPDOWN_TYPE.LOCATION &&
          showBlockDestinationDropdown()}
      </div>

      <div>
        <QuestionsListDropdown
          containerStyle={styles.containerStyle}
          value={blockDestinationQuestion}
          onOptionClick={option => setBlockDestinationQuestion(option)}
          hideSurveyEndJumps
          isActionAllowedChecker={isActionAllowedCheckerWithLocation}
        />
      </div>
      <div className={styles.additionalPropertiesActions}>
        <span
          className={
            blockDestinationLocation && blockDestinationQuestion
              ? `${
                  styles.additionalPropertiesEditButton
                } block-destination-position-done`
              : `${styles.additionalPropertiesEditButton} ${styles.disabled}`
          }
          role="presentation"
          onClick={() => {
            if (blockDestinationLocation && blockDestinationQuestion) {
              onDoneButtonPress(
                blockDestinationLocation.name,
                blockDestinationQuestion,
                setShowPropertiesComponent
              );
            }
          }}
        >
          Done
        </span>
        <span
          role="presentation"
          className={styles.additionalPropertiesCloseButton}
          onClick={() => setShowPropertiesComponent(false)}
        >
          <Icon type="x" />
        </span>
      </div>
    </div>
  );
};
