import React, { useState, useEffect, useRef } from 'react';
import useLazyQuery from '../../../../hooks/useLazyQuery';
import styles from './Footer.module.css';
import { POLL_RESULT_IS_CONFIRMED } from '../../../../graphql/Campaigns';

import backgroundGridColor from '../../../../assets/img/background-grid-color.svg';
import backgroundGridWhite from '../../../../assets/img/background-grid-white.svg';

export default props => {
  const {
    blockId,
    resultId,
    blockType,
    onClickNext,
    onClickBack,
    disabledNext,
    hiddenNext,
    backLabel,
    nextLabel,
    allowMarginTop,
    fixedPosition,
    hideIllustrationsInWebSurvey,
    customThemeBaseColor,
    enableNextAfterSeconds,
    secLabel,
    confirmResultWithApi,
    showBackgroundGrid = false
  } = props;

  const pollResult = useLazyQuery(POLL_RESULT_IS_CONFIRMED, {
    fetchPolicy: 'no-cache'
  });

  const [remainingSecondsToEnable, setRemainingSecondsToEnable] = useState(
    null
  );
  const timer = useRef();
  const confirmTimer = useRef();

  useEffect(
    () => {
      if (remainingSecondsToEnable) {
        timer.current = setTimeout(() => {
          const remaining =
            remainingSecondsToEnable === null
              ? enableNextAfterSeconds
              : remainingSecondsToEnable - 1;
          if (remaining > 0) {
            setRemainingSecondsToEnable(remaining);
          } else {
            setRemainingSecondsToEnable(null);
          }
        }, 1000);
      } else {
        setRemainingSecondsToEnable(null);
        if (timer && timer.current) {
          clearTimeout(timer.current);
        }
      }
    },
    [remainingSecondsToEnable]
  );

  useEffect(
    () => {
      if (enableNextAfterSeconds) {
        setRemainingSecondsToEnable(enableNextAfterSeconds);
      }
      if (timer && timer.current) {
        clearTimeout(timer.current);
      }
      if (confirmTimer && confirmTimer.current) {
        clearTimeout(confirmTimer.current);
      }

      if (blockType === 'iframe' && confirmResultWithApi) {
        const pollResultIsConfirmed = async () => {
          const res = await pollResult({
            variables: {
              result: resultId,
              question: blockId
            }
          });

          if (
            res &&
            res.data &&
            res.data.pollResultIsConfirmed &&
            !res.data.pollResultIsConfirmed.done
          ) {
            confirmTimer.current = setTimeout(() => {
              pollResultIsConfirmed();
            }, 2000);
          }

          if (
            res &&
            res.data &&
            res.data.pollResultIsConfirmed &&
            res.data.pollResultIsConfirmed.done
          ) {
            setRemainingSecondsToEnable(null);
            if (timer && timer.current) {
              clearTimeout(timer.current);
            }
            if (confirmTimer && confirmTimer.current) {
              clearTimeout(confirmTimer.current);
            }

            // Proceed with next question
            onClickNext(true);
          }
        };

        pollResultIsConfirmed();
      }
    },
    [blockId]
  );

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        if (event && event.data && event.data === 'BUFFL') {
          setRemainingSecondsToEnable(null);
          if (timer && timer.current) {
            clearTimeout(timer.current);
          }
        }
      },
      false
    );
  }, []);

  const isNextDisabled = () => {
    if (remainingSecondsToEnable) {
      return true;
    }

    return disabledNext;
  };

  const nextButtonLabel = () => {
    if (remainingSecondsToEnable) {
      return `${remainingSecondsToEnable} ${secLabel}`;
    }

    return nextLabel;
  };

  const anyButtonAvailable = (onClickNext && !hiddenNext) || onClickBack;

  return (
    <div
      className={`${styles.container} ${
        fixedPosition ? styles.fixedContainer : ''
      }`}
    >
      {anyButtonAvailable ? (
        <div
          className={`${styles.bottomButtonWrapper} ${
            !allowMarginTop ? styles.noMobileTopMargin : ''
          }`}
        >
          <div className={styles.bottomButtonContainer}>
            <div className={styles.leftButtonContainer}>
              {onClickBack && !confirmResultWithApi ? (
                <div
                  className={`${styles.button} user-campaigns-back-button`}
                  style={{
                    backgroundColor: customThemeBaseColor || '#24B2E8',
                    color: '#FFFFFF'
                  }}
                  onClick={() => {
                    if (enableNextAfterSeconds) {
                      setRemainingSecondsToEnable(null);
                    }
                    if (timer && timer.current) {
                      clearTimeout(timer.current);
                    }
                    onClickBack();
                  }}
                  role="presentation"
                >
                  {backLabel || 'BACK'}
                </div>
              ) : null}
            </div>
            <div className={styles.rightButtonContainer}>
              {onClickNext && !hiddenNext && !confirmResultWithApi ? (
                <div
                  style={{
                    backgroundColor: customThemeBaseColor || '#24B2E8',
                    color: '#FFFFFF'
                  }}
                  className={`${styles.button} ${
                    isNextDisabled() ? styles.buttonDisabled : ''
                  } user-campaigns-next-button`}
                  onClick={() => {
                    if (!isNextDisabled()) {
                      onClickNext();
                    }
                  }}
                  role="presentation"
                >
                  {nextButtonLabel() || 'NEXT'}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={styles.bottomBarContainer}
        style={{
          backgroundColor: customThemeBaseColor || '#792483',
          ...(!showBackgroundGrid.showColor ? { zIndex: 3 } : {})
        }}
      />
      {showBackgroundGrid && !hideIllustrationsInWebSurvey ? (
        <img
          alt="Background grid"
          src={
            showBackgroundGrid.showColor
              ? backgroundGridColor
              : backgroundGridWhite
          }
          className={styles.backgroundGrid}
        />
      ) : null}
    </div>
  );
};
