import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import duplicateGroup from './duplicateGroup';
import duplicateDistributorGroup from './duplicateDistributorGroup/duplicateDistributorGroup';
import updateDecisionFramework from '../updateDecisionFramework/updateDecisionFramework';
import executeRequestsInSequence from '../../helpers/executeRequestsInSequence';
import surveyPermissionsCharge from '../../../helpers/surveyPermissionsCharge';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);
  const authState = yield select(state => state.auth);

  let newState = { ...surveysState };
  let frameworkRequests = [];

  // Update active flow
  if (surveysState.activeFlow && !surveysState.activeFlow.flows) {
    const newSurveyStateActiveFlow = {
      question: { ...surveysState.activeFlow }
    };
    action.api('updateQuestion', newSurveyStateActiveFlow);

    if (
      surveysState.activeFlow.matrix &&
      surveysState.activeFlow.matrix.decisionFrameworkType
    ) {
      const {
        surveyWithUpdatedFramework,
        updateRequest
      } = updateDecisionFramework(surveysState.survey, surveysState.activeFlow);
      if (surveyWithUpdatedFramework) {
        newState = {
          ...newState,
          survey: surveyWithUpdatedFramework
        };
        if (updateRequest) {
          frameworkRequests = [updateRequest];
        }
      }
    }
  }

  newState = duplicateGroup(
    newState,
    action.group,
    action.destinationPosition,
    action.destinationQuestionId
  );

  const { duplicatedQuestions } = newState;

  if (
    action.jumpFromDistributorGroup &&
    action.destinationPosition &&
    action.destinationQuestionId &&
    newState.newGroupId
  ) {
    newState = duplicateDistributorGroup(
      newState,
      action.destinationQuestionId
    );
  }

  // Account management warnings
  const newPermissionsStateData = surveyPermissionsCharge(authState, newState);
  if (newPermissionsStateData) {
    newState.newState = {
      ...newState.newState,
      ...newPermissionsStateData
    };
  }

  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  if (
    (newState.requests && newState.requests.length) ||
    frameworkRequests.length
  ) {
    frameworkRequests = [
      ...frameworkRequests,
      ...(newState.requests && newState.requests.length
        ? newState.requests
        : [])
    ];
    yield executeRequestsInSequence(action.api, frameworkRequests);
  }

  // Add new question
  action.api('addQuestions', {
    questionsArray: duplicatedQuestions,
    surveyContent: newState.newState.survey.content
  });
}
