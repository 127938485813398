import React from 'react';

import goalOverviewBackground from '../../../../../assets/img/goal-overview.svg';
import edit from '../../../../../assets/img/edit.png';
import copy from '../../../../../assets/img/copy.svg';

import styles from './GoalOverview.module.css';

const GoalOverview = ({
  history,
  statsLoading,
  organisation,
  stats,
  isLoggedIn,
  strings
}) => {
  const copyLinkToClipboard = value => {
    const dummy = document.createElement('input');
    // dummy.style.display = 'none';
    document.body.appendChild(dummy);

    dummy.setAttribute('id', 'goalurl');
    document.getElementById('goalurl').value = value;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };

  const getPercentage = () => {
    const totalPercentage = (
      (stats.totalEurosCollectedViaBuffl * 100) /
      organisation.savings_target
    ).toFixed(2);
    return totalPercentage;
  };

  return (
    <div className={styles.container}>
      {isLoggedIn ? (
        <img
          src={edit}
          className={styles.editGoalButton}
          onClick={() => history.push('/goal/edit')}
          role="presentation"
          alt="Edit goal"
        />
      ) : null}
      <div className={styles.goalOverviewContainer}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            alt="Company Logo"
            src={organisation.image_filename}
          />
        </div>
        <div className={styles.detailsContainer}>
          <div className={`${styles.title} roboto-medium-font`}>
            {strings.GOAL}
          </div>
          <div className={styles.description}>{organisation.description}</div>
          {statsLoading ? (
            <div
              className={`${styles.placeholderContainer} loader-container`}
            />
          ) : (
            <div>
              <div className={styles.barContainer}>
                <div
                  className={styles.bar}
                  style={{
                    width: `${getPercentage() > 100 ? 100 : getPercentage()}%`
                  }}
                />
              </div>
              <div className={`${styles.valueContainer} roboto-bold-font`}>
                <div className={styles.currentValue}>
                  € {stats.totalEurosCollectedViaBuffl.toFixed(2)}
                  <div className={styles.remainingPercentage}>
                    {getPercentage()}% {strings.FROM_THE_TOTAL}
                  </div>
                </div>
                <div className={styles.maximumValue}>
                  € {organisation.savings_target}
                </div>
              </div>
            </div>
          )}
          <div className={styles.linkContainer}>
            <div className={`${styles.linkLabel} roboto-bold-font`}>
              {strings.PERSONAL_LINK}:
            </div>
            <div className={styles.linkInputContainer}>
              <div className={styles.linkInput}>{organisation.link}</div>
              <div
                className={styles.copyIconContainer}
                style={{ backgroundImage: `url(${copy})` }}
                onClick={() => copyLinkToClipboard(organisation.link)}
                role="presentation"
              />
            </div>
          </div>
        </div>
      </div>
      <img
        src={goalOverviewBackground}
        className={styles.goalOverviewBackground}
        alt="Goal Overview"
      />
    </div>
  );
};

export default GoalOverview;
