import React, { useEffect, useState, useRef, useCallback } from 'react';

import Icon from '../../../../../../Icon';
import styles from '../CategoryList.module.css';

export default ({
  block,
  category,
  index,
  removeCategoryFromAnswers,
  renameCategoryFromAnswers,
  categoryResultsCount,
  onToggleFilter
}) => {
  const [editMode, setEditMode] = useState(false);
  const [newCategory, setNewCategory] = useState(null);
  const [spanWidth, setSpanWidth] = useState(null);
  const [categoryOverflow, setCategoryOverflow] = useState({
    overflow: false,
    text: '',
    x: 0,
    y: 0
  });

  const containerRef = useRef(null);
  const labelRef = useRef(null);
  const inputRef = useRef(null);

  const handleInput = () => {
    setEditMode(false);

    if (newCategory && category !== newCategory) {
      renameCategoryFromAnswers(category, newCategory);
      setNewCategory(null);
    } else if (newCategory === '') {
      setNewCategory(category);
    }
  };

  const useSimpleAndDoubleClick = (oneClick, doubleClick) => {
    const [click, setClick] = useState(0);

    useEffect(
      () => {
        const timer = setTimeout(() => {
          if (click === 1) oneClick();
          setClick(0);
        }, 300);

        if (click === 2) doubleClick();

        return () => {
          clearTimeout(timer);
        };
      },
      [click]
    );

    return () => {
      setClick(prev => prev + 1);
    };
  };

  const handleDoubleClick = useSimpleAndDoubleClick(
    () => {
      if (!editMode) {
        onToggleFilter(`CAT-${block.id}`, null, category, null, result => {
          if (result && result.attributes.block_results) {
            const questionBlock = result.attributes.block_results.filter(
              question => question.block === block.id
            );
            if (questionBlock.length) {
              if (
                questionBlock[0].categories &&
                questionBlock[0].categories.length
              ) {
                if (
                  questionBlock[0].categories &&
                  questionBlock[0].categories.indexOf(category) > -1
                ) {
                  return true;
                }
              }
            }
          }
          return false;
        });
      }
    },
    () => {
      if (!editMode) {
        setSpanWidth(labelRef.current.offsetWidth - 21);
        setTimeout(() => {
          inputRef.current.focus();
        }, 100);
        setEditMode(true);
      }
    }
  );

  const handleBlur = useCallback(
    e => {
      if (!(containerRef.current && containerRef.current.contains(e.target))) {
        handleInput();
      }
    },
    [newCategory]
  );

  useEffect(
    () => {
      document.addEventListener('mousedown', handleBlur);
      return () => {
        document.removeEventListener('mousedown', handleBlur);
      };
    },
    [newCategory]
  );

  return (
    <div
      className={`${
        styles.singleActiveFilter
      } category-list-single-active-filter`}
      key={`single-active-filter-${index.toString()}`}
      ref={containerRef}
      onMouseEnter={e => {
        const bounds = e.target.getBoundingClientRect();
        if (e.currentTarget.offsetWidth > 135) {
          setCategoryOverflow({
            overflow: true,
            text: category,
            x: bounds.x + bounds.width - 35,
            y: bounds.y - bounds.height
          });
        }
      }}
      onMouseLeave={() => {
        if (categoryOverflow.overflow) {
          setCategoryOverflow({
            ...categoryOverflow,
            overflow: false
          });
        }
      }}
    >
      <span
        role="presentation"
        className={`${
          styles.singleActiveFilterLabel
        } single-active-filter-label`}
        onClick={handleDoubleClick}
        ref={labelRef}
      >
        {editMode ? (
          <input
            value={newCategory == null ? category : newCategory}
            disabled={!editMode}
            onChange={e => setNewCategory(e.target.value)}
            className={styles.categoryInput}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleInput();
              }
            }}
            style={{ width: spanWidth }}
            ref={inputRef}
          />
        ) : (
          <>
            <span className={styles.singleActiveFilterLabelText}>
              {category}
            </span>
            <span className={styles.singleActiveFilterLabelCount}>
              ({categoryResultsCount[category] || 0})
            </span>
          </>
        )}
      </span>

      <div className={styles.doubleClickHint}>
        Double tap to rename category.
      </div>

      <span
        className={`${
          styles.removeSingleActiveFilter
        } category-list-remove-single-active-filter`}
        role="presentation"
        onClick={() => removeCategoryFromAnswers(category)}
      >
        <Icon type="close" style={{ color: '#5200f1' }} />
      </span>

      {categoryOverflow && categoryOverflow.overflow ? (
        <span
          className={styles.categoryOverflowInfo}
          style={{
            top: `${categoryOverflow.y}px`,
            left: `${categoryOverflow.x}px`
          }}
        >
          {categoryOverflow.text}
        </span>
      ) : null}
    </div>
  );
};
