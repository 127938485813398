import React, { useState, useEffect, useRef } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  Modifier
} from 'draft-js';

import './Draft.css';

const createEditorState = value => {
  try {
    if (value && (value !== undefined || value !== '')) {
      const parsedQuestion = JSON.parse(value);

      if (
        parsedQuestion.blocks &&
        (parsedQuestion.blocks > 1 || parsedQuestion.blocks[0].text !== '')
      ) {
        const contentState = convertFromRaw(parsedQuestion);
        return EditorState.createWithContent(contentState);
      }
    }
  } catch (error) {
    return EditorState.createEmpty();
  }
  return EditorState.createEmpty();
};

const Draft = props => {
  const {
    addDraftEmoji: emojiToAdd,
    setAddDraftEmoji,
    toggleDraftButton,
    setToggleDraftButton,
    setActiveDraftButtons,
    onChangeQuestionProperty,
    placeholder,
    value,
    isActiveQuestion,
    matrixQuestionKey,
    triggerValueReset,
    setTriggerValueReset,
    setInternalRef
  } = props;
  const inputRef = useRef(null);

  const [activeButtonState, setActiveButtonState] = useState({
    bold: false,
    italic: false,
    underline: false
  });

  const [editorState, setEditorState] = useState(createEditorState(value));

  const SelectionState = editorState.getSelection();
  const inlineStyle = editorState.getCurrentInlineStyle();
  const isBold = inlineStyle.has('BOLD');
  const isItalic = inlineStyle.has('ITALIC');
  const isUnderline = inlineStyle.has('UNDERLINE');

  useEffect(
    () => {
      changeButtonState();
    },
    [editorState]
  );

  useEffect(
    () => {
      if (emojiToAdd && isActiveQuestion) {
        addEmoji(emojiToAdd);
        setAddDraftEmoji(null);
      }
    },
    [emojiToAdd]
  );

  useEffect(
    () => {
      if (toggleDraftButton) {
        const newEditorState = RichUtils.toggleInlineStyle(
          editorState,
          toggleDraftButton
        );
        setEditorState(newEditorState);
        setToggleDraftButton(null);
      }
    },
    [toggleDraftButton]
  );

  useEffect(
    () => {
      setActiveDraftButtons(
        Object.keys(activeButtonState)
          .filter(k => activeButtonState[k])
          .map(k => k.toUpperCase())
      );
    },
    [activeButtonState]
  );

  useEffect(
    () => {
      if (
        isActiveQuestion &&
        inputRef.current &&
        document &&
        document.getSelection &&
        inputRef.current.editor.contains(document.getSelection().anchorNode)
      )
        setEditorState(
          EditorState.forceSelection(
            editorState,
            SelectionState.merge({
              anchorKey: SelectionState.getFocusKey(),
              anchorOffset: document.getSelection().focusOffset,
              focusKey: SelectionState.getFocusKey(),
              focusOffset: document.getSelection().focusOffset
            })
          )
        );

      if (isActiveQuestion === false && inputRef && inputRef.current) {
        inputRef.current.blur();
      }
    },
    [isActiveQuestion]
  );

  useEffect(
    () => {
      if (
        matrixQuestionKey &&
        triggerValueReset &&
        Array.isArray(triggerValueReset) &&
        triggerValueReset.length &&
        triggerValueReset.includes(matrixQuestionKey)
      ) {
        setEditorState(createEditorState(value));

        const removeTriggerValue = async () => {
          const newTriggerValueReset = [...triggerValueReset];
          newTriggerValueReset.splice(
            newTriggerValueReset.indexOf(matrixQuestionKey),
            1
          );
          await setTriggerValueReset(newTriggerValueReset);
        };

        removeTriggerValue();
      }
    },
    [triggerValueReset]
  );

  useEffect(
    () => {
      if (setInternalRef && inputRef && inputRef.current) {
        setInternalRef(inputRef.current);
      }
    },
    [inputRef]
  );

  const checkCurrentInlineStyles = () => {
    const currentActiveButtonState = {
      bold: isBold,
      italic: isItalic,
      underline: isUnderline
    };
    return currentActiveButtonState;
  };

  const changeButtonState = () => {
    const newActiveButtonState = checkCurrentInlineStyles();
    if (
      newActiveButtonState.bold !== activeButtonState.bold ||
      newActiveButtonState.italic !== activeButtonState.italic ||
      newActiveButtonState.underline !== activeButtonState.underline
    ) {
      setActiveButtonState(newActiveButtonState);
    }
  };

  const changeQuestionProperty = state => {
    const contentState = state.getCurrentContent();
    onChangeQuestionProperty(
      'question',
      JSON.stringify(convertToRaw(contentState))
    );
  };

  const addEmoji = emoji => {
    let nextEditorState = EditorState.createEmpty();
    let nextContentState;
    if (SelectionState.isCollapsed()) {
      nextContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        SelectionState,
        emoji
      );
      nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        'insert-characters'
      );
    } else {
      nextContentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        SelectionState,
        emoji
      );
      nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        'insert-characters'
      );
    }
    onChangeQuestionProperty(
      'question',
      JSON.stringify(convertToRaw(nextContentState))
    );
    setEditorState(nextEditorState);
  };

  const customStyle = () => {
    if (matrixQuestionKey) {
      return 'matrixQuestionText';
    }
    return '';
  };

  return (
    <Editor
      ref={inputRef}
      editorState={editorState}
      onChange={state => {
        if (isActiveQuestion) {
          changeQuestionProperty(state);
          setEditorState(state);
        }
      }}
      placeholder={placeholder}
      spellCheck
      blockStyleFn={customStyle}
    />
  );
};

export default Draft;
