export const PROFILING_QUESTION_CONSENTS = {
  PROFILING_QUESTION_YES_CONSENT: 'PROFILING_QUESTION_YES_CONSENT',
  PROFILING_QUESTION_NO_CONSENT: 'PROFILING_QUESTION_NO_CONSENT',
  PROFILING_QUESTION_NO_DO_NOT_ASK: 'PROFILING_QUESTION_NO_DO_NOT_ASK',
  PROFILING_QUESTION_NO_DO_NOT_ASK_EVER: 'PROFILING_QUESTION_NO_DO_NOT_ASK_EVER'
};

export const DECISION_FRAMEWORK_TYPES = {
  VALUE_SELECTION_GRAPH: 'VALUE_SELECTION_GRAPH',
  OPPORTUNITY_GRAPH: 'OPPORTUNITY_GRAPH',
  IDEA_SELECTION_GRAPH: 'IDEA_SELECTION_GRAPH',
  CONCEPT_TEST_GRAPH: 'CONCEPT_TEST_GRAPH',
  SINGLE: 'SINGLE',
  CUSTOM_GRAPH: 'CUSTOM_GRAPH'
};

export default {};
