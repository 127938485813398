import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import TimePicker from '../../TimePicker/TimePicker';

import styles from '../SurveyScheduler.module.css';
import { scheduleErrors } from '../SurveyScheduler';

export default ({
  actionLabel,
  toggle,
  setToggle,
  date,
  setDate,
  dateError,
  timeError
}) => (
  <div className={styles.actionOption}>
    <div className={styles.switcherContainer}>
      {setToggle && (
        <span role="presentation">
          <label className="switch" htmlFor="checkbox">
            <input type="checkbox" checked={toggle} />
            <span
              className="slider round"
              role="presentation"
              onClick={e => {
                setToggle(v => !v);
                e.stopPropagation();
              }}
            />
          </label>
        </span>
      )}

      <span className={styles.checkboxLabel}>
        I want to schedule the time of{' '}
        <span className={styles.actionLabel}>{actionLabel}</span>
      </span>
    </div>
    <div
      className={`${styles.dateTimeContainer} ${
        toggle ? null : styles.disabled
      }`}
    >
      <div className={styles.dateTimeInputContainer}>
        Date
        <span
          className={`${styles.dateInput} ${
            dateError ? styles.errorMessageBorder : null
          }`}
        >
          <DayPickerInput
            placeholder="##/##/##"
            format="MM/dd/yyyy"
            onDayChange={d => {
              const oldTime = new Date(date);
              setDate(
                new Date(d).setHours(oldTime.getHours(), oldTime.getMinutes())
              );
            }}
            value={date && date !== '' ? new Date(date) : null}
            dayPickerProps={{
              canChangeMonth: true
            }}
          />
        </span>
        {dateError && dateError !== '' && (
          <span
            className={`${styles.errorMessage} ${dateError ===
              scheduleErrors.unpublishMatch && styles.extendedErrorMessage}`}
          >
            {dateError}
          </span>
        )}
      </div>
      <div
        className={`${styles.dateTimeInputContainer} ${
          timeError ? styles.errorMessageBorder : null
        }`}
      >
        Time
        <span>
          <TimePicker
            placeholder="##:##"
            className={styles.timeInput}
            onTimeChange={t => setDate(t)}
            time={date && date !== '' ? date : null}
          />
          {timeError && (
            <span className={styles.errorMessage}>{timeError}</span>
          )}
        </span>
      </div>
    </div>
  </div>
);
