import React from 'react';

import styles from '../../../Matrix.module.css';

const InputField = props => {
  const { value, customPlaceholder, onChange } = props;

  const placeholder = customPlaceholder || 'Add label';

  return (
    <div className={styles.fieldContainer}>
      <input
        className={`${styles.field}`}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
