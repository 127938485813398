import React, { useState, useEffect } from 'react';

import tickIcon from '../../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../../assets/img/delete.svg';
import trashIcon from '../../../../../../../../../assets/img/trash.svg';
import addIcon from '../../../../../../../../../assets/img/add.svg';
import warningIcon from '../../../../../../../../../assets/img/exclamation.svg';

import PopulationRow from './components/PopulationRow/PopulationRow';

import styles from './ManagePopulation.module.css';

const ManagePopulation = ({
  selectedPopulation,
  selectedPopulationIndex,
  setSelectedPopulationIndex,
  addPopulationClick,
  removePopulationClick,
  updatePopulationClick,
  isPopulationEdit,
  blocks,
  calculatePopulationResultsCount,
  activePopulations,
  setActivePopulations
}) => {
  const [labels, setLabels] = useState(selectedPopulation || []);
  const [labelName, setLabelName] = useState();
  const [populationSize, setPopulationSize] = useState();
  const [inverse, setInverse] = useState(false);
  const availableLabels = [
    {
      name: 'age',
      label: 'Age',
      type: 'range'
    },
    {
      name: 'gender',
      label: 'Gender',
      type: 'dropdown',
      options: [
        {
          value: 'Male',
          label: 'Male'
        },
        {
          value: 'Female',
          label: 'Female'
        }
      ]
    },
    {
      name: 'occupation',
      label: 'Occupation',
      type: 'dropdown',
      options: [
        {
          value: 'WORKER',
          label: 'Worker'
        },
        {
          value: 'CLERK',
          label: 'Clerk'
        },
        {
          value: 'CIVIL_SERVANT',
          label: 'Civil servant'
        },
        {
          value: 'SELF_EMPLOYED_PERSON',
          label: 'Self employed person'
        },
        {
          value: 'HOUSEWIFE',
          label: 'Housewife'
        },
        {
          value: 'WITHOUT_OCCUPATION',
          label: 'Without occupation'
        },
        {
          value: 'RETIRED',
          label: 'Retired'
        },
        {
          value: 'LOOKING_FOR_JOB',
          label: 'Looking for a job'
        },
        {
          value: 'STUDENT',
          label: 'Student'
        },
        {
          value: 'OTHER',
          label: 'Other'
        },
        {
          value: 'EMPLOYEE',
          label: 'Employee'
        }
      ]
    },
    {
      name: 'answer',
      label: 'Answer',
      type: 'answer'
    }
  ];

  useEffect(
    () => {
      setPopulationSize(getPopulationSize());
      if (selectedPopulation && selectedPopulation.length) {
        if (!labelName) {
          setLabelName(selectedPopulation[0].label);
        }
        if (selectedPopulation[0].inverse) {
          setInverse(true);
        }
      }
    },
    [labels, selectedPopulation]
  );

  const onAddLabelClick = () => {
    const activeLabels = labels.map(label => label.name);
    const availableLabelsToTake = availableLabels.filter(
      availableLabel => activeLabels.indexOf(availableLabel.name) === -1
    );
    if (availableLabelsToTake && availableLabelsToTake.length) {
      const availableToTakeObject = {
        name: availableLabelsToTake[0].name,
        label: availableLabelsToTake[0].label,
        type: availableLabelsToTake[0].type,
        inverse: availableLabelsToTake[0].inverse
      };
      availableToTakeObject.active = true;
      if (inverse) {
        availableToTakeObject.inverse = true;
      }
      setLabels([...labels, ...[availableToTakeObject]]);
    }
  };

  const changeLabelValue = (property, value, labelIndex) => {
    labels[labelIndex][property] = value;
    setLabels([...labels]);
  };

  const getPopulationSize = () => {
    // calculatePopulationResultsCount
    if (labels) {
      const labelsToCalculate = [];
      labels.forEach(label => {
        switch (label.type) {
          case 'range':
            if (label.gte && label.lte) {
              labelsToCalculate.push(label);
            }
            break;
          case 'dropdown':
            if (label.value && label.value.length) {
              labelsToCalculate.push(label);
            }
            break;
          case 'answer':
            if (label.block) {
              labelsToCalculate.push(label);
            }
            break;
          default:
            break;
        }
      });
      return calculatePopulationResultsCount(labelsToCalculate);
    }
    return '/';
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.title} workspaces-manage-population-title`}>
        {isPopulationEdit ? 'Edit' : 'Create a'} population
      </div>
      <div>
        <input
          className={`${styles.textInput}`}
          onChange={e => setLabelName(e.currentTarget.value)}
          value={labelName}
          placeholder="Population name"
        />
      </div>
      <div className={styles.description}>
        Choose which properties you would like to define your population. These
        can still be edited later on. Keep an eye on your population size!
      </div>
      <div
        className={`${
          styles.labelsContainer
        } workspaces-manage-population-labels-container`}
      >
        {labels &&
          labels.map((label, labelIndex) => (
            <PopulationRow
              key={`population-row-${labelIndex.toString()}`}
              label={label}
              labelIndex={labelIndex}
              setLabels={setLabels}
              changeLabelValue={changeLabelValue}
              availableLabels={availableLabels}
              labels={labels}
              blocks={blocks}
              inverse={inverse}
            />
          ))}
        {labels && labels.length === 0 ? (
          <>
            <div
              className={`${
                styles.noLabelsAdded
              } workspaces-manage-population-no-labels`}
            >
              No properties added
            </div>
            <div className={styles.line} />
          </>
        ) : null}
      </div>
      {labels.length < availableLabels.length ? (
        <div className={styles.addLabelContainer}>
          <span
            role="presentation"
            onClick={() => onAddLabelClick()}
            className="workspaces-manage-population-add-label"
          >
            <img
              src={addIcon}
              className={styles.addIcon}
              alt="Add label container"
            />
            Add property
          </span>
        </div>
      ) : null}
      <div className={styles.line} />
      <div
        className={`${styles.populationSizeInformation} ${
          populationSize < 75 ? styles.populationSizeInformationRed : ''
        }`}
      >
        <span className={styles.populationSize}>
          Population size: {populationSize}
        </span>
        {populationSize < 75 ? (
          <div className={styles.populationSmallAlert}>
            <img src={warningIcon} alt="Warning" />
            <span>
              This population might be too small to provide accurate data. You
              can fix this by removing certain properties, or by increasing your
              sample size.
            </span>
          </div>
        ) : null}
      </div>
      <div className={styles.line} />
      <div className={styles.footer}>
        {isPopulationEdit ? (
          <div
            className={`${
              styles.deletePopulation
            } workspaces-manage-population-delete`}
            role="presentation"
            onClick={() => {
              removePopulationClick(selectedPopulationIndex);
              setSelectedPopulationIndex(null);
              const newActivePopulations = [...activePopulations];
              newActivePopulations.splice(selectedPopulationIndex);
              setActivePopulations(newActivePopulations);
            }}
          >
            <img
              src={trashIcon}
              className={styles.deletePopulationIcon}
              alt="Delete population"
            />
            Delete population
          </div>
        ) : (
          <div />
        )}
        <div className={styles.actions}>
          <img
            role="presentation"
            onClick={() => {
              if (labels && labels.length && labelName) {
                const newLabelsWithNames = [...labels];
                newLabelsWithNames.forEach(label => {
                  // eslint-disable-next-line no-param-reassign
                  label.label = labelName;
                });
                if (isPopulationEdit) {
                  updatePopulationClick(
                    newLabelsWithNames,
                    selectedPopulationIndex
                  );
                } else {
                  addPopulationClick(newLabelsWithNames);
                }
                setSelectedPopulationIndex(null);
              }
            }}
            className={`${
              styles.confirm
            } workspaces-manage-population-confirm ${
              !labels || !labels.length || !labelName
                ? styles.confirmDisabled
                : ''
            }`}
            src={tickIcon}
            alt="Confirm icon"
          />
          <img
            role="presentation"
            onClick={() => setSelectedPopulationIndex(null)}
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
          />
        </div>
      </div>
    </div>
  );
};

export default ManagePopulation;
