import mixpanel from 'mixpanel-browser';

mixpanel.init('5b47ecf562d5e8b74404f212cc11857a');

const backend = localStorage.getItem('backend');
const envCheck = process.env.NODE_ENV === 'production' || backend === 'prod';

export const events = {
  CLIENT_LOGIN: 'client:login',
  CLIENT_SIGNUP: 'client:signup',
  CLIENT_NEW_SURVEY: 'client:new_survey',
  CLIENT_OPEN_SURVEY: 'client:open_survey',
  CLIENT_OPEN_WORKSPACE: 'client:open_workspace',
  CLIENT_RESULTS_PAGE_CLICK_LOGIC_MAP:
    'client:results_page_click_open_logic_map',
  CLIENT_CLICK_DECISION_TOOLS: 'client:click_decision_tools',
  CLIENT_RESULTS_PAGE_CLICK_EDIT_SURVEY:
    'client:results_page_click_edit_survey',
  CLIENT_RESULTS_PAGE_CLICK_SHARE_RESULTS:
    'client:results_page_click_share_results',
  CLIENT_RESULTS_PAGE_CLICK_EXPORT_RESULTS:
    'client:results_page_click_export_results',
  CLIENT_RESULTS_PAGE_DEMOGRAPHICS_FILTER_USAGE:
    'client:results_page_demographics_filter_usage',
  CLIENT_RESULTS_PAGE_QUESTIONS_FILTER_USAGE:
    'client:results_page_questions_filter_usage',
  CLIENT_EXPORT_PDF: 'client:export_pdf',
  CLIENT_EXPORT_CSV: 'client:export_csv',
  CLIENT_EXPORT_PPT: 'client:export_ppt',
  CLIENT_MULTIPLE_ACTIVE_FILTERS: 'client:multiple_active_filters',
  CLIENT_CREATE_NEW_OPEN_ANSWER_CATEGORY:
    'client:create_new_open_answer_category',
  CLIENT_SESSION_DURATION: 'client:session_duration',
  CLIENT_REQUEST_APPROVAL: 'client:request_approval',
  CLIENT_PUBLISH_SURVEY: 'client:publish_survey',
  CLIENT_CREATE_DECISION_FRAMEWORK: 'client:create_decision_framework',
  CLIENT_CONTACT_FOR_HELP_IN_DECISION_FRAMEWORK:
    'client:contact_for_help_in_decision_framework',
  CLIENT_SET_LOVERS_HATERS_FILTER: 'client:set_lovers_haters_filter',
  CLIENT_CLICK_START_FROM_SCRATCH: 'client:click_start_from_scratch',
  CLIENT_CLICK_SELECT_A_TEMPLATE: 'client:click_select_a_template',
  CLIENT_CLICK_WHEN_OR_HOW_TO_USE_IN_SURVEY_TEMPLATES:
    'client:click_when_or_how_to_use_in_survey_templates',
  CLIENT_CREATE_SURVEY_TEMPLATE: 'client:create_survey_template',
  CLIENT_APPLY_FILTERS_ON_FEASIBILITY_CHECKER:
    'client:apply_filters_on_feasibility_checker',
  CLIENT_ENABLE_MIN_QUOTA_ON_FEASIBILITY_CHECKER:
    'client:enable_min_quota_on_feasibility_checker'
};

export const eventProperties = {
  SURVEY_ID: 'Survey ID',
  QUESTION_ID: 'Question ID',
  FILTER_TYPE: 'Filter type',
  EMAIL: 'Email',
  COMPANY: 'Company',
  TELEPHONE: 'Telephone',
  FIRSTNAME: 'Firstname',
  LASTNAME: 'Lastname',
  MESSAGE: 'Message',
  HOW_DID_YOU_LEARN_ABOUT_BUFFL: 'How did you learn about BUFFL',
  OPEN_ANSWER_CATEGORY: 'Open answer category',
  SURVEY_TEMPLATE_LANGUAGE: 'Survey template language',
  SURVEY_TEMPLATE_TYPE: 'Survey template type',
  SURVEY_TEMPLATE_PROBLEM_CONTEXT: 'Survey template problem context',
  SURVEY_TEMPLATE_AMOUNT_OF_TESTS: 'Survey template amount of tests',
  SURVEY_TEMPLATE_DEFINED_POINTS: 'Survey template defined points',
  SURVEY_TEMPLATE_QUESTIONS_TYPE: 'Survey template questions type',
  SURVEY_TEMPLATE_SCALE: 'Survey template scale',
  SURVEY_TEMPLATE_SUCCESS_CRITERIA: 'Survey template success criteria',
  DECISION_FRAMEWORK_TYPE: 'Decision framework type',
  DECISION_FRAMEWORK_NAME: 'Decision framework name',
  MULTIPLE_ACTIVE_FILTERS_COUNT: 'Multiple active filters count',
  FEASIBLITY_CHECKER: {
    TOTAL_SAMPLE_SIZE: 'Total sample size',
    APPLIED_FILTERS: 'Applied filters',
    FILTER_RELATION: 'Filter relation'
  }
};

export const actions = {
  trackEvent: (event, eventAttributes, preventIdentify) => {
    if (envCheck) {
      const clientId = localStorage.getItem('clientId');
      if (clientId && !preventIdentify) {
        mixpanel.identify(clientId);
      }

      let env = 'production';
      if (process.env.REACT_APP_ENV === 'qa') {
        env = 'test';
      }
      const attributes = {
        ...eventAttributes,
        environment: env
      };
      mixpanel.track(event, attributes);
    }
  },
  people: {
    set: props => {
      if (envCheck) mixpanel.people.set(props);
    }
  },
  logout: () => {
    if (envCheck) mixpanel.reset();
  }
};
