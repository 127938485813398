import { TEMPLATES_WIZARD_TEMPLATE_TYPES } from '../../../helpers/constants';

export default {
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  WOULD_YOU_LIKE_TO_USE_TEMPLATE: 'Would you like to use a template?',
  SELECT_TEMPLATE: 'Select a template',
  START_FROM_SCRATCH: 'Start from scratch',
  IN_WHAT_LANGUAGE_WOULD_YOU_LIKE_TO:
    'What language would you like to {PURPLE}prepare your survey{/PURPLE} in?',
  PREPARE_IN_MOTHER_LANGUAGE:
    'Prepare your survey in the mother tongue of (at least a part of) your respondents to avoid translation costs.',
  WHEN_OR_HOW_TO_USE: 'When or how to use?',
  WHAT_TEMPLATE_WOULD_YOU_USE: 'What template would you like to use?',
  PROBLEM_VALIDATION_LABEL: 'Problem validation',
  IDEA_SELECTION_LABEL: 'Idea selection',
  VALUE_SELECTION_LABEL: 'Concept exploration',
  CONCEPT_TEST_LABEL: 'Concept validation',
  WOULD_YOU_LIKE_CUSTOM_TEMPLATE:
    'Would you like to use a custom template? Send your request to',
  WHEN_TO_USE: 'When to use?',
  BENEFITS: 'Benefits:',
  HOW_TO_USE: 'How to use?',
  FOR_ALL_QUESTIONS_CONTACT: 'For any and all questions, please contact',
  RATING_SCALE: 'Rating scale',
  MULTIPLE_CHOICE: 'Multiple choice',
  WHAT_SCALE_WOULD_YOU_LIKE_TO_WORK_WITH:
    'What scale would you like to work with?',
  DECIDE_WHAT_SCALE_YOU_WANT: 'Decide what scale you want to work with',
  WE_RECOMMEND_SCALE: 'We recommend a scale from 1 to 7',
  WE_RECOMMEND_THE_FIRST_OPTION: 'We recommend the first option',
  EXAMPLE_OF_SCALE_WILL_BE_USED: 'Example of how the scale will be used',
  DISCLAIMER:
    'Disclaimer: The respondent completes the questions with the scale you have selected, our system will automatically recalculate the data to a scale from 0 to 10 for benchmark reasons.',
  I_STRONGLY_DISAGREE: 'I strongly disagree',
  I_DISAGREE: 'I disagree',
  I_AGREE: 'I agree',
  I_STRONGLY_AGREE: 'I strongly agree',
  I_NEITHER_AGREE_NOR_DISAGREE: 'I neither agree nor disagree',
  I_SOMEWHAT_DISAGREE: 'I somewhat disagree',
  I_SOMEWHAT_AGREE: 'I somewhat agree',
  LOGIC_MAP_FOR_SURVEY: 'Logic map',
  PROBLEM_CONTEXT: 'Problem context',
  IT_IS_STRONGLY_RECOMMENDED:
    '*It is strongly recommended to validate the (unmet) needs of your target audience in the context you created a solution for upfront. Use our problem validation template or contact',
  FOR_HELP: 'for help.',
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION]: {
    TO_PRIORITISE_PAINS:
      'To prioritise the problems you discovered in explorative research (e.g. interviews) and make an estimate of the unmet user needs and thus untapped market potential.',
    YOU_WONT_WASTE_RESOURCES:
      '{BOLD}You won’t waste your resources.{/BOLD} You will only spend your resources on validated problems that represent market potential.',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'You will know whether it makes sense to {BOLD}segment your market{/BOLD} and build solutions tailored to the needs of a particular target audience.',
    WELL_DEFINED_VALIDATED:
      'A well defined & validated problem statement (when-where-how does a problem occur?) is a perfect {BOLD}scope for an ideation session.{/BOLD} It will make your ideation less fuzzy. Furthermore it is scientifically proven that setting boundaries on the ideation context boosts your creativity.',
    PROBLEMS_ARE_PRIORITISED: 'Problems are prioritised on the following axes:',
    X_AXIS_INFO:
      'X-axis: How {BOLD}important{/BOLD} is it to solve a problem for a particular target audience? The more important, the more potential to create value.',
    Y_AXIS_INFO:
      'Y-axis: How {BOLD}challenging{/BOLD} is it for your target audience to solve a particular problem? The more challenging, the less alternatives are currently available. Less adequate alternatives means less competition, so more possibilities to attain market leadership.',
    HOW_MANY_TO_TEST: 'How many problems would you like to test?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Would you like to test more than {MAX_COUNTS} problems? Send your request to',
    DEFINE_YOUR: 'Define your problems',
    YOUR_INPUT_WILL_BE_USED:
      'Write concise assumptions about your problem including the context elements that matter. Your input will be used to automatically create your survey structure and questions.',
    SITUATION: 'It is important yet challenging to...',
    POINT_INPUT_LABEL: 'Problem',
    WE_INSTALL_CORNER_EXAMPLE:
      'continuously (when) reduce domestic (where) energy waste (what) without making an effort (how).',
    NEED_HELP_CONTACT:
      'Trouble in defining your assumptions or problem statements? Contact',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'What do you think of following statement?',
    IT_IS_1: 'It is {PURPLE}important{/PURPLE} to',
    GIVE_A_SCORE_1:
      'Give a score from {MIN} (= {PURPLE}not important at all{/PURPLE}) to {MAX} (= {PURPLE}very important{/PURPLE})',
    IT_IS_2: 'It is {PURPLE}challenging{/PURPLE} to',
    GIVE_A_SCORE_2:
      'Give a score from {MIN} (= {PURPLE}not challenging at all{/PURPLE}) to {MAX} (= {PURPLE}very challenging{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA:
      'Fill in the success criteria for your assumptions',
    POINT_ASSUMPTION_HEADER: 'It is important yet challenging to',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'We expect respondents to give this problem an average score of ...',
    OUT_OF_TO_1:
      'out of {MAX}, which means it is {BOLD}important{/BOLD} to solve this problem',
    OUT_OF_TO_2:
      'out of {MAX}, which means it is {BOLD}challenging{/BOLD} to solve this problem',
    WE_THINK_1:
      'We expect respondents to say {SPLIT} that this is {BOLD}important{/BOLD}.',
    WE_THINK_2:
      'We expect respondents to say {SPLIT} that this is {BOLD}challenging{/BOLD}.',
    INTRO_QUESTION_1:
      'In the next few questions, we will present some problems.',
    INTRO_QUESTION_2:
      'It is up to you to indicate how {BOLD}important{/BOLD} or {BOLD}challenging{/BOLD} they are.'
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST]: {
    TO_PRIORITISE_PAINS:
      'To give you an idea of how valuable your concept is to your target audience and of its ability to differentiate from competition. ',
    YOU_WONT_WASTE_RESOURCES:
      'The concept validation template will allow you to estimate whether your concept is attractive enough to generate interest among your target audience. Is it possible to attain the market share you had in mind?',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Evaluate whether your concept has the ability to move your target audience.',
    WELL_DEFINED_VALIDATED:
      'Identify people who love your brand or early adopters if any.',
    PROBLEMS_ARE_PRIORITISED: 'Concepts are prioritised on the following axes:',
    X_AXIS_INFO:
      'X-axis: How {BOLD}valuable{/BOLD} is your concept to your target audience? The more valuable your concept, the higher its attraction power.',
    Y_AXIS_INFO:
      'Y-axis: How {BOLD}unique{/BOLD} is your concept to your target audience? The more unique, the more market share you will be able to pursue.',
    DESCRIBE_THE_SITUATION:
      'Describe the situation that respondents need to keep in mind when rating your concept. The text block below will be shown before your concepts are presented.',
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Where{/BOLD} and {BOLD}when{/BOLD} will your concept be used? {BOLD}How{/BOLD} is your target audience feeling? {BOLD}What{/BOLD} are their problems / unmet needs in their situation?*',
    CONTEXT_EXAMPLE:
      'You’re in the supermarket (where) after a busy day of work (when) and you have to quickly (how) decide what ingredients to buy (what).',
    HOW_MANY_TO_TEST: 'How many concepts would you like to test?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Would you like to test more than {MAX_COUNTS} concepts? Send your request to',
    DEFINE_YOUR: 'Define your concepts',
    YOUR_INPUT_WILL_BE_USED:
      'Your input will be used to automatically create your survey structure and questions.',
    POINT_INPUT_LABEL: 'Concept',
    WE_INSTALL_CORNER_EXAMPLE:
      'A mobile app (product/service category) that suggests healthy new recipes when you enter the supermarket (functional description) based on the preferences indicated by your family in the app that day and on the discounts applicable in the supermarket (USPs)*',
    NEED_HELP_CONTACT:
      '*If you want to mention more than 2 USPs we advise you to use our concept exploration template first. Contact',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'What do you think of following concept?',
    GIVE_A_SCORE_1:
      'Give a score from {MIN} (= {PURPLE}not valuable at all{/PURPLE}) to {MAX} (= {PURPLE}very valuable{/PURPLE})',
    GIVE_A_SCORE_2:
      'Give a score from {MIN} (= {PURPLE}not unique at all{/PURPLE}) to {MAX} (= {PURPLE}very unique{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA:
      'Fill in the success criteria for your concepts',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'We expect respondents to give this concept an average score of ...',
    OUT_OF_TO_1: 'out of {MAX} in terms of {BOLD}value{/BOLD}',
    OUT_OF_TO_2: 'out of {MAX} in terms of {BOLD}uniqueness{/BOLD}',
    WE_THINK_1:
      'We expect respondents to say {SPLIT} that this is {BOLD}valuable{/BOLD}.',
    WE_THINK_2:
      'We expect respondents to say {SPLIT} that this is {BOLD}unique{/BOLD}.',
    TO_WHAT_EXTEND_YOU_AGREE_1:
      'To what extend do you agree with following statement: "I think this is a {PURPLE}valuable{/PURPLE} concept"',
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'To what extend do you agree with following statement: "I think this is a {PURPLE}unique{/PURPLE} concept"'
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION]: {
    TO_PRIORITISE_PAINS:
      'To prioritise and select the ideas worth elaborating upon after a first ideation session.',
    YOU_WONT_WASTE_RESOURCES:
      'Dot voting? If you want to create value for your end user, it is the end user who should decide which ideas are worth elaborating upon. You only want to spend your resources on ideas that create value & thus have the potential to be successful. ',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Immediately get rid of unpromising ideas to align the stakeholders & avoid exhausting decisions further on in the process. The more time people spend on an idea, the more they will love it.',
    WELL_DEFINED_VALIDATED:
      'Validated ideas can boost the interest of various stakeholders to commit to next steps.',
    PROBLEMS_ARE_PRIORITISED: 'Ideas are prioritised on the following axes:',
    X_AXIS_INFO:
      'X-axis: How {BOLD}valuable{/BOLD} is a certain idea to your target audience? The more valuable your idea, the more people will be interested and the higher the prize you will be able to charge.',
    Y_AXIS_INFO:
      'Y-axis: How {BOLD}unique{/BOLD} is a certain idea? The more unique an idea is, the easier it will be to get your end users’ attention and to stand out from competition.',
    DESCRIBE_THE_SITUATION:
      'Describe the situation that respondents should keep in mind when rating your idea. The text block below will be shown before your ideas are presented.',
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Where{/BOLD} and {BOLD}when{/BOLD} will your idea be used? {BOLD}How{/BOLD} is your target audience feeling? {BOLD}What{/BOLD} are their problems / unmet needs in their situation?*',
    CONTEXT_EXAMPLE:
      'You’re in a telco shop (where) and you want to have your smartphone fixed (what) as quickly as possible (how).',
    HOW_MANY_TO_TEST: 'How many ideas would you like to test?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Would you like to test more than {MAX_COUNTS} ideas? Send your request to',
    DEFINE_YOUR: 'Define your ideas',
    YOUR_INPUT_WILL_BE_USED:
      'Your input will be used to automatically create your survey structure and questions.',
    POINT_INPUT_LABEL: 'Idea',
    WE_INSTALL_CORNER_EXAMPLE:
      'A corner in the telco shop where you can hand in your old smartphone for reparation.',
    NEED_HELP_CONTACT: 'Contact',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'What do you think of following idea?',
    GIVE_A_SCORE_1:
      'Give a score from {MIN} (= {PURPLE}not valuable at all{/PURPLE}) to {MAX} (= {PURPLE}very valuable{/PURPLE})',
    GIVE_A_SCORE_2:
      'Give a score from {MIN} (= {PURPLE}not unique at all{/PURPLE}) to {MAX} (= {PURPLE}very unique{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA: 'Fill in the success criteria for your ideas',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'We expect respondents to give this idea an average score of ...',
    OUT_OF_TO_1: 'out of {MAX} in terms of {BOLD}value{/BOLD}',
    OUT_OF_TO_2: 'out of {MAX} in terms of {BOLD}uniqueness{/BOLD}',
    WE_THINK_1:
      'We expect respondents to say {SPLIT} that this is {BOLD}valuable{/BOLD}.',
    WE_THINK_2:
      'We expect respondents to say {SPLIT} that this is {BOLD}unique{/BOLD}.',
    TO_WHAT_EXTEND_YOU_AGREE_1:
      'To what extend do you agree with following statement: "I think this is a {PURPLE}valuable{/PURPLE} idea"',
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'To what extend do you agree with following statement: "I think this is a {PURPLE}unique{/PURPLE} idea"'
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION]: {
    TO_PRIORITISE_PAINS:
      'To only include the values in your solution that matter for your target audience. This template is recommended when you have trouble writing out your concept because you want to stress a lot of values at the same time.',
    YOU_WONT_WASTE_RESOURCES:
      'Your target audience is only going to use or pay for things that matter to them. Everything else is a loss of resources and focus. ',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Define what should be part of your MVP, initial product or basic package. ',
    WELL_DEFINED_VALIDATED:
      'Find out whether it makes sense to create multiple solutions or packages applying a needs-based segmentation (different target audiences prefer different values).',
    PROBLEMS_ARE_PRIORITISED: 'Values are prioritised on the following axes:',
    X_AXIS_INFO:
      'X-axis: How much {BOLD}value{/BOLD} is created for your target audience? ',
    Y_AXIS_INFO:
      'Y-axis: {BOLD}“Basic Requirement”{/BOLD}; what is indispensible & thus needs to be included in your MVP, initial product or basic package.',
    DESCRIBE_THE_SITUATION:
      'Describe the situation that respondents should keep in mind when rating your value. The text block below will be shown before your values are presented.',
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Where{/BOLD} and {BOLD}when{/BOLD} will your value be used? {BOLD}How{/BOLD} is your target audience feeling? {BOLD}What{/BOLD} are their problems / unmet needs in their situation?*',
    CONTEXT_EXAMPLE:
      'You’re in the supermarket (where) after a busy day of work (when) and have to quickly (how) decide what ingredients to buy (what).',
    HOW_MANY_TO_TEST: 'How many values would you like to test?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Would you like to test more than {MAX_COUNTS} values? Send your request to',
    DEFINE_YOUR: 'Define your values',
    YOUR_INPUT_WILL_BE_USED:
      'Your input will be used to automatically create your survey structure and questions.',
    POINT_INPUT_LABEL: 'Value',
    WE_INSTALL_CORNER_EXAMPLE:
      'Family members / roommates can notify you using a mobile app to tell you what dish they are in the mood for that day.',
    NEED_HELP_CONTACT: 'Contact',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'What do you think of following value?',
    GIVE_A_SCORE_1:
      'Give a score from {MIN} (= {PURPLE}not valuable at all{/PURPLE}) to {MAX} (= {PURPLE}very valuable{/PURPLE})',
    GIVE_A_SCORE_2:
      'Give a score from {MIN} (= {PURPLE}no basic requirement at all{/PURPLE}) to {MAX} (= {PURPLE}basic requirement{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA:
      'Fill in the success criteria for your values',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'We expect respondents to give this value an average score of ...',
    OUT_OF_TO_1: 'out of {MAX} in terms of {BOLD}value{/BOLD}',
    OUT_OF_TO_2: 'out of {MAX} in terms of {BOLD}a basic requirement{/BOLD}',
    WE_THINK_1:
      'We expect respondents to say {SPLIT} that this is {BOLD}valuable{/BOLD}.',
    WE_THINK_2:
      'We expect respondents to say {SPLIT} that this is {BOLD}a basic requirement{/BOLD}.',
    TO_WHAT_EXTEND_YOU_AGREE_1:
      'To what extend do you agree with following statement: "I think this is {PURPLE}valuable{/PURPLE}"',
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'To what extend do you agree with following statement: "I think this is {PURPLE}a basic requirement{/PURPLE}"'
  },
  COMMUNITY: {
    QUESTION_TEXT:
      'Would you like to be closely involved in the further development of this innovation and receive specific content about it?',
    ANSWER_YES: 'Yes please!',
    ANSWER_NO: 'No rather not.'
  }
};
