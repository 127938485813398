import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import {
  CREATE_RESULTS_WORKSPACE,
  UPDATE_RESULTS_WORKSPACE,
  DELETE_RESULTS_WORKSPACE
} from '../../graphql/Workspaces';

import Icon from '../../../../../Icon';
import Loader from '../Loader/Loader';

import multipleChoiceIcon from '../../../../../../../assets/img/multiple-choice-icon.svg';
import tickIcon from '../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../assets/img/delete.svg';
import trashIcon from '../../../../../../../assets/img/trash.svg';

import removePopulationsTypename from '../../helpers/removePopulationsTypename';

import styles from './ManageWorkspace.module.css';

const ManageWorkspace = ({
  surveyId,
  blocks,
  onClose,
  selectedWorkspace,
  setSelectedWorkspace,
  setShowEditWorkspacePopup,
  makeResultsFiltering
}) => {
  const questions =
    selectedWorkspace && selectedWorkspace.questions
      ? selectedWorkspace.questions.map(q => q.id)
      : [];
  const [selectedBlocks, setSelectedBlocks] = useState(questions || []);

  const [createResultsWorkspace, { loading: loadingCreate }] = useMutation(
    CREATE_RESULTS_WORKSPACE,
    {
      onCompleted: response => {
        const workspace = response.createResultsWorkspace.resultsWorkspace;
        workspace.populations = removePopulationsTypename(
          workspace.populations
        );
        setSelectedWorkspace(workspace);
      }
    }
  );
  const [updateResultsWorkspace, { loading: loadingUpdate }] = useMutation(
    UPDATE_RESULTS_WORKSPACE,
    {
      onCompleted: response => {
        const workspace = response.updateResultsWorkspace.resultsWorkspace;
        workspace.populations = removePopulationsTypename(
          workspace.populations
        );
        setSelectedWorkspace(workspace);
        makeResultsFiltering(workspace.populations);
        setShowEditWorkspacePopup(false);
      }
    }
  );
  const [deleteResultsWorkspace, { loading: loadingDelete }] = useMutation(
    DELETE_RESULTS_WORKSPACE,
    {
      onCompleted: () => {
        setSelectedWorkspace(false);
        setShowEditWorkspacePopup(false);
        window.location.reload();
      }
    }
  );

  const isLoading = loadingCreate || loadingUpdate || loadingDelete;

  const onSelectAllClick = () => {
    if (blocks.length === selectedBlocks.length) {
      setSelectedBlocks([]);
    } else {
      const allBlockIds = blocks.map(block => block.id);
      setSelectedBlocks(allBlockIds);
    }
  };

  const onBlockClick = id => {
    if (selectedBlocks.indexOf(id) > -1) {
      const newSelectedBlocks = selectedBlocks.filter(block => block !== id);
      setSelectedBlocks(newSelectedBlocks);
    } else {
      setSelectedBlocks([...selectedBlocks, id]);
    }
  };

  const onConfirmClick = () => {
    if (selectedBlocks && selectedBlocks.length) {
      const workspace = {
        name: 'Workspace 1',
        survey: surveyId,
        questions: selectedBlocks,
        populations: []
      };

      if (selectedWorkspace) {
        delete workspace.survey;
        delete workspace.populations;
        workspace.id = selectedWorkspace.id;
        updateResultsWorkspace({ variables: workspace });
      } else {
        workspace.populations.push([
          {
            name: 'age',
            label: 'Default Sample',
            type: 'range',
            active: true,
            gte: 16,
            lte: 100,
            inverse: null
          }
        ]);
        createResultsWorkspace({ variables: workspace });
      }
    }
  };

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = [].concat(
        JSON.parse(question)
          .blocks.map(draftBlock => draftBlock.text)
          .join('\n')
      );
    } catch (error) {
      questionValue = question;
    }
    return questionValue;
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.title} workspaces-manage-workspace-title`}>
        {selectedWorkspace ? 'Edit' : 'New'} workspace
      </div>
      <div className={styles.description}>
        Select the questions from you survey that you would like to further
        analyse in this workspace. That can still be changed afterwards if
        necessary.
      </div>
      <div className={styles.selectAllContainer}>
        <div className={styles.selectorContainer}>
          <span
            onClick={() => onSelectAllClick()}
            role="presentation"
            className="workspaces-manage-workspace-select-all"
          >
            <Icon
              type={
                blocks.length === selectedBlocks.length
                  ? 'checked'
                  : 'unchecked'
              }
            />
          </span>
        </div>
      </div>
      <div
        className={`${
          styles.questionsContainer
        } workspaces-manage-workspace-questions-container`}
      >
        {blocks.map((block, index) => (
          <div
            className={`${
              styles.question
            } workspaces-manage-workspace-question`}
            key={`create-workspace-${index.toString()}`}
          >
            <div className={styles.selectorContainer}>
              <span
                className={`${
                  styles.selectorIcon
                } workspaces-manage-workspace-question-icon
                ${
                  selectedBlocks.indexOf(block.id) > -1
                    ? 'workspaces-checked-icon'
                    : 'workspaces-unchecked-icon'
                }`}
                onClick={() => onBlockClick(block.id)}
                role="presentation"
              >
                <Icon
                  type={
                    selectedBlocks.indexOf(block.id) > -1
                      ? 'checked'
                      : 'unchecked'
                  }
                />
              </span>
            </div>
            <div className={styles.number}>{index + 1}</div>
            <div className={styles.iconContainer}>
              <img src={multipleChoiceIcon} alt="Question type icon" />
            </div>
            <div
              className={`${
                styles.questionTextContainer
              } workspaces-manage-workspace-question-question`}
            >
              {parseQuestion(block.question)}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.details}>
          {selectedWorkspace ? (
            <div
              className={`${
                styles.deletePopulation
              } workspaces-manage-workspace-delete-button`}
              role="presentation"
              onClick={() =>
                deleteResultsWorkspace({
                  variables: { id: selectedWorkspace.id }
                })
              }
            >
              <img
                src={trashIcon}
                className={styles.deletePopulationIcon}
                alt="Delete population"
              />
              Delete Workspace
            </div>
          ) : (
            <span className="workspaces-manage-workspace-selected-blocks">
              {selectedBlocks.length} blocks selected
            </span>
          )}
        </div>
        {isLoading ? (
          <div className={styles.actions}>
            <Loader size="small" />
          </div>
        ) : (
          <div className={styles.actions}>
            {selectedBlocks && selectedBlocks.length ? (
              <img
                className={`${
                  styles.confirm
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => onConfirmClick()}
                role="presentation"
              />
            ) : null}
            <img
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => onClose()}
              role="presentation"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageWorkspace;
