import { TEMPLATES_WIZARD_TEMPLATE_TYPES } from '../../../helpers/constants';

export default {
  NEXT: 'Suivant',
  PREVIOUS: 'Précédent',
  WOULD_YOU_LIKE_TO_USE_TEMPLATE: 'Voulez-vous utiliser un modèle?',
  SELECT_TEMPLATE: 'Sélectionnez un modèle',
  START_FROM_SCRATCH: 'Commencer à partir de zéro',
  IN_WHAT_LANGUAGE_WOULD_YOU_LIKE_TO:
    'Dans quelle langue souhaitez-vous {PURPLE}préparer votre enquête{/PURPLE}?',
  PREPARE_IN_MOTHER_LANGUAGE:
    'Préparez votre enquête dans la langue maternelle de (au moins une partie de) vos répondants pour éviter les frais de traduction.',
  WHEN_OR_HOW_TO_USE: 'Quand ou comment utiliser?',
  WHAT_TEMPLATE_WOULD_YOU_USE: 'Quel modèle souhaitez-vous utiliser?',
  PROBLEM_VALIDATION_LABEL: 'Problem validation',
  IDEA_SELECTION_LABEL: 'Idea selection',
  VALUE_SELECTION_LABEL: 'Concept exploration',
  CONCEPT_TEST_LABEL: 'Concept validation',
  WOULD_YOU_LIKE_CUSTOM_TEMPLATE:
    'Souhaitez-vous utiliser un modèle personnalisé? Envoyez votre demande à',
  WHEN_TO_USE: 'Quand utiliser?',
  BENEFITS: 'Avantages:',
  HOW_TO_USE: 'Comment utiliser?',
  FOR_ALL_QUESTIONS_CONTACT: 'Pour toutes questions, contactez',
  RATING_SCALE: 'Échelle de notation',
  MULTIPLE_CHOICE: 'Choix multiple',
  WHAT_SCALE_WOULD_YOU_LIKE_TO_WORK_WITH:
    'A quelle échelle aimeriez-vous travailler?',
  DECIDE_WHAT_SCALE_YOU_WANT:
    'Décidez avec quelle échelle vous voulez travailler',
  WE_RECOMMEND_SCALE: 'Nous recommandons une échelle de 1 à 7',
  WE_RECOMMEND_THE_FIRST_OPTION: 'Nous recommandons la première option',
  EXAMPLE_OF_SCALE_WILL_BE_USED: "Exemple d'utilisation de l'échelle",
  DISCLAIMER:
    "Avertissement: Le répondant complète les questions avec l'échelle que vous avez sélectionnée, notre système recalculera automatiquement les données sur une échelle de 0 à 10 pour des raisons de référence.",
  I_STRONGLY_DISAGREE: 'Je ne suis pas du tout d’accord',
  I_DISAGREE: "Je ne suis pas d'accord",
  I_AGREE: "Je suis d'accord",
  I_STRONGLY_AGREE: "Je suis entièrement d'accord",
  I_NEITHER_AGREE_NOR_DISAGREE: "Je ne suis ni d'accord ni en désaccord",
  I_SOMEWHAT_DISAGREE: "Je ne suis pas vraiment d'accord",
  I_SOMEWHAT_AGREE: "Je suis un peu d'accord",
  LOGIC_MAP_FOR_SURVEY: 'Logic map',
  PROBLEM_CONTEXT: 'Contexte du problème',
  IT_IS_STRONGLY_RECOMMENDED:
    '* Il est fortement recommandé de valider les besoins (non satisfaits) de votre public cible dans le contexte pour lequel vous avez créé une solution en amont. Utilisez notre modèle de problem validation ou contactez',
  FOR_HELP: 'pour aide.',
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION]: {
    TO_PRIORITISE_PAINS:
      'Prioriser les difficultés que vous avez découvertes dans la recherche exploratoire (par exemple, les entretiens) et faire une estimation des besoins des utilisateurs non satisfaits et donc du potentiel de marché inexploité.',
    YOU_WONT_WASTE_RESOURCES:
      '{BOLD}Ne gaspillerez pas vos ressources.{/BOLD} Vous ne dépenserez vos ressources que sur des problèmes validés qui représentent un potentiel de marché.',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      "Vous saurez s'il est judicieux de {BOLD}segmenter votre marché{/BOLD} et de créer des solutions adaptées aux besoins d'un public cible particulier.",
    WELL_DEFINED_VALIDATED:
      "Un énoncé de problème bien défini et validé (quand-où-comment un problème survient-il?) est une {BOLD}champ d'application parfait pour une session d'idéation.{/BOLD} Cela rendra votre idéation moins floue. De plus, il est scientifiquement prouvé que fixer des limites au contexte d'idéation stimule votre créativité.",
    PROBLEMS_ARE_PRIORITISED:
      'Les problèmes sont priorisés sur les axes suivants:',
    X_AXIS_INFO:
      'Axe X: dans quelle mesure est-il {BOLD}important{/BOLD} de résoudre un problème pour un public cible particulier. Plus il est important, plus il y a de potentiel de création de valeur.',
    Y_AXIS_INFO:
      "Axe Y: Dans quelle mesure est-il {BOLD}difficile{/BOLD} pour votre public cible de résoudre un problème particulier? Plus les alternatives sont difficiles et moins adéquates sont actuellement disponibles. Des alternatives moins adéquates signifient moins de concurrence, donc plus de possibilités d'atteindre le leadership du marché.",
    HOW_MANY_TO_TEST: 'Combien de problèmes voulez-vous tester?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Souhaitez-vous tester plus de {MAX_COUNTS} problèmes? Envoyez votre demande à',
    DEFINE_YOUR: 'Définissez vos problèmes',
    YOUR_INPUT_WILL_BE_USED:
      'Rédigez une hypothèse concise sur votre problème, y compris les éléments contextuels qui comptent. Votre entrée sera utilisée pour créer automatiquement la structure et les questions de votre sondage.',
    SITUATION: 'Il est important mais difficile de...',
    POINT_INPUT_LABEL: 'Problème',
    WE_INSTALL_CORNER_EXAMPLE:
      "continuer (quand) en réduire les déchets énergétiques domestiques (où) (quoi) sans faire d'effort (comment).",
    NEED_HELP_CONTACT:
      'Difficulté à définir votre hypothèse ou votre énoncé de problème? Contactez',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'Que penses-tu de la déclaration suivante?',
    IT_IS_1: 'Il est {PURPLE}important{/PURPLE} de',
    GIVE_A_SCORE_1:
      'Donne un score de {MIN} (= {PURPLE}pas du tout important{/PURPLE}) à {MAX} (= {PURPLE}très important{/PURPLE})',
    IT_IS_2: 'Il est {PURPLE}difficile{/PURPLE} de',
    GIVE_A_SCORE_2:
      'Donne un score de {MIN} (= {PURPLE}pas du tout difficile{/PURPLE}) à {MAX} (= {PURPLE}très difficile{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA:
      'Remplissez les critères de réussite de vos hypothèses',
    POINT_ASSUMPTION_HEADER: 'Il est important mais difficile de',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'Nous pensons que les répondants attribueraient à ce problème une note moyenne de ...',
    OUT_OF_TO_1:
      "sur {MAX}, ce qui signifie qu'il est {BOLD}important{/BOLD} pour résoudre ce problème",
    OUT_OF_TO_2:
      "sur {MAX}, ce qui signifie qu'il est {BOLD}difficile{/BOLD} de résoudre ce problème",
    WE_THINK_1:
      'Nous nous attendons à ce que les répondants disent {SPLIT} que cela est {BOLD}important{/BOLD}.',
    WE_THINK_2:
      'Nous nous attendons à ce que les répondants disent {SPLIT} que cela est {BOLD}difficile{/BOLD}.',
    INTRO_QUESTION_1:
      'Dans les prochaines questions, nous allons présenter quelques problèmes.',
    INTRO_QUESTION_2:
      "C'est à vous d'indiquer à quel point ils sont {BOLD}importants{/BOLD} ou difficiles."
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST]: {
    TO_PRIORITISE_PAINS:
      'Pour vous donner une idée de la valeur de votre concept pour votre public cible et de sa capacité à se différencier de la concurrence. ',
    YOU_WONT_WASTE_RESOURCES:
      "Concept validation vous permettra d'estimer si votre concept est suffisamment attractif pour susciter l'intérêt de votre public cible. Est-il possible d'atteindre la part de marché que vous aviez en tête?",
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Évaluez si votre concept a la capacité de mettre votre public cible en mouvement.',
    WELL_DEFINED_VALIDATED:
      'Identifiez vos amoureux de la marque ou vos premiers utilisateurs, le cas échéant.',
    PROBLEMS_ARE_PRIORITISED:
      'Les concepts sont priorisés sur les axes suivants:',
    X_AXIS_INFO:
      "Axe X: Dans quelle mesure votre concept {BOLD}valable{/BOLD} est-il pour votre public cible? Plus votre concept est précieux, plus son pouvoir d'attraction est élevé.",
    Y_AXIS_INFO:
      'Axe Y: Dans quelle mesure votre concept est-il {BOLD}unique{/BOLD} pour votre public cible? Plus vous serez unique, plus vous pourrez conquérir de parts de marché.',
    DESCRIBE_THE_SITUATION:
      "Décrivez la situation que les répondants doivent garder à l'esprit lorsqu'ils évaluent votre concept. Le bloc de texte ci-dessous sera affiché avant que vos concepts ne soient présentés.",
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Où{/BOLD} et {BOLD}quand{/BOLD} votre concept sera-t-il utilisé? {BOLD}Comment{/BOLD} votre public cible se sent-il? {BOLD}Quels{/BOLD} sont leurs problèmes/besoins non satisfaits dans leur situation?*',
    CONTEXT_EXAMPLE:
      'Tu es au supermarché (où) après une journée de travail chargée (quand) et tu dois prendre une décision rapide (comment) sur les ingrédients à acheter (quoi).',
    HOW_MANY_TO_TEST: 'Combien de concepts voudriez-vous tester?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Souhaitez-vous tester plus de {MAX_COUNTS} concepts? Envoyez votre demande à',
    DEFINE_YOUR: 'Définissez vos concepts',
    YOUR_INPUT_WILL_BE_USED:
      'Votre contribution sera utilisée pour créer automatiquement la structure et les questions de votre sondage.',
    POINT_INPUT_LABEL: 'Concept',
    WE_INSTALL_CORNER_EXAMPLE:
      "Une application mobile (catégorie de produit / service) qui te propose une nouvelle recette saine lorsque tu entres dans le supermarché (description fonctionnelle) en fonction des préférences du jour indiquées par ta famille dans l'application & des remises applicables dans le supermarché (USPs)*",
    NEED_HELP_CONTACT:
      "* Si vous souhaitez mentionner plus de 2 USP, nous vous conseillons d'effectuer d'abord notre modèle de concept exploration. Contactez",
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'Que penses-tu du concept suivant?',
    GIVE_A_SCORE_1:
      'Donne un score de {MIN} (= {PURPLE}pas valable du tout{/PURPLE}) à {MAX} (= {PURPLE}très valable {/PURPLE})',
    GIVE_A_SCORE_2:
      'Donne un score de {MIN} (= {PURPLE}pas unique du tout{/PURPLE}) à {MAX} (= {PURPLE}très unique{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA:
      'Remplissez les critères de réussite pour vos concepts',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'Nous pensons que les répondants attribueraient à ce concept une note moyenne de ...',
    OUT_OF_TO_1: 'sur {MAX} en termes de {BOLD}valeur{/BOLD}',
    OUT_OF_TO_2: "sur {MAX} en termes d'{BOLD}unicité{/BOLD}",
    WE_THINK_1:
      'Nous nous attendons à ce que les répondants disent {SPLIT} que cela est {BOLD}valable{/BOLD}.',
    WE_THINK_2:
      'Nous nous attendons à ce que les répondants disent {SPLIT} que cela est {BOLD}unique{/BOLD}.',
    TO_WHAT_EXTEND_YOU_AGREE_1:
      "Dans quelle mesure es-tu d'accord avec la déclaration suivante: \"Je pense qu'il s'agit d'un concept {PURPLE}précieux{/PURPLE}\"",
    TO_WHAT_EXTEND_YOU_AGREE_2:
      "Dans quelle mesure es-tu d'accord avec la déclaration suivante: \"Je pense qu'il s'agit d'un concept {PURPLE}unique{/PURPLE}\""
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION]: {
    TO_PRIORITISE_PAINS:
      "Prioriser et sélectionner les idées qui méritent d'être développées après une première session d'idéation.",
    YOU_WONT_WASTE_RESOURCES:
      "Le vote par points? Si vous souhaitez créer de la valeur pour votre utilisateur final, c'est l'utilisateur final qui doit décider quelles idées méritent d'être développées. Vous souhaitez uniquement consacrer vos ressources à des idées qui créent de la valeur et ont ainsi le potentiel de réussir. ",
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      "Tuez immédiatement les idées peu prometteuses pour aligner les parties prenantes et éviter d'épuiser les décisions plus loin dans le processus. Plus les gens passent de temps sur une idée, plus ils en tombent amoureux.",
    WELL_DEFINED_VALIDATED:
      "Les idées validées peuvent stimuler l'intérêt de diverses parties prenantes à s'engager dans les prochaines étapes.",
    PROBLEMS_ARE_PRIORITISED:
      'Les idées sont priorisées sur les axes suivants:',
    X_AXIS_INFO:
      'Axe X: Dans quelle mesure {BOLD}valable{/BOLD} est une certaine idée pour votre public cible? Plus votre idée est précieuse, plus les gens seront intéressés et plus le prix que vous pourrez facturer sera élevé.',
    Y_AXIS_INFO:
      "Axe Y: En quoi {BOLD}unique{/BOLD} est-elle une certaine idée? Plus une idée est unique, plus il est facile d'attirer l'attention de vos utilisateurs finaux et de vous démarquer de la concurrence.",
    DESCRIBE_THE_SITUATION:
      "Décrivez la situation que les répondants doivent garder à l'esprit lorsqu'ils évaluent votre idée. Le bloc de texte ci-dessous sera affiché avant que vos idées ne soient présentées.",
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Où{/BOLD} et {BOLD}quand{/BOLD} votre idée sera-t-elle utilisée? {BOLD}Comment{/BOLD} votre public cible se sent-il? {BOLD}Quels{/BOLD} sont leurs problèmes/besoins non satisfaits dans leur situation?*',
    CONTEXT_EXAMPLE:
      'Tu es dans un magasin telco (où) et tu souhaites faire réparer ton smartphone (quoi) le plus rapidement possible (comment).',
    HOW_MANY_TO_TEST: "Combien d'idées voudriez-vous tester?",
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Souhaitez-vous tester plus de {MAX_COUNTS} idées? Envoyez votre demande à',
    DEFINE_YOUR: 'Définissez vos idées',
    YOUR_INPUT_WILL_BE_USED:
      'Votre contribution sera utilisée pour créer automatiquement la structure et les questions de votre enquête.',
    POINT_INPUT_LABEL: 'Idée',
    WE_INSTALL_CORNER_EXAMPLE: `Un corner dans la boutique télécom où tu pourras remettre ton vieux smartphone pour qu'il soit réparé.`,
    NEED_HELP_CONTACT: 'Contact',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: "Que penses-tu de l'idée suivante?",
    GIVE_A_SCORE_1:
      'Donne un score de {MIN} (= {PURPLE}pas du tout valable{/PURPLE}) à {MAX} (= {PURPLE}très valable{/PURPLE})',
    GIVE_A_SCORE_2:
      'Donne un score de {MIN} (= {PURPLE}pas unique du tout{/PURPLE}) à {MAX} (= {PURPLE}très unique{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA:
      'Remplissez les critères de réussite pour vos idées',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'Nous pensons que les répondants donneraient à cette idée un score moyen de ...',
    OUT_OF_TO_1: 'sur {MAX} en termes de {BOLD}valeur{/BOLD}',
    OUT_OF_TO_2: 'sur {MAX} en termes d’{BOLD}unicité{/BOLD}',
    WE_THINK_1:
      'Nous nous attendons à ce que les répondants disent {SPLIT} que cela est {BOLD}valable{/BOLD}.',
    WE_THINK_2:
      'Nous nous attendons à ce que les répondants disent {SPLIT} que cela est {BOLD}unique{/BOLD}. ',
    TO_WHAT_EXTEND_YOU_AGREE_1: `Dans quelle mesure es-tu d'accord avec la déclaration suivante: "Je pense que c'est une idée {PURPLE}valable{/PURPLE}"`,
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'Dans quelle mesure es-tu d\'accord avec la déclaration suivante: "Je pense que c\'est une idée {PURPLE}unique{/PURPLE}"'
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION]: {
    TO_PRIORITISE_PAINS:
      "Pour inclure uniquement les valeurs dans votre solution qui comptent pour votre public cible. Ce modèle est recommandé lorsque vous avez du mal à rédiger votre concept car vous souhaitez mettre l'accent sur un grand nombre de valeurs en même temps.",
    YOU_WONT_WASTE_RESOURCES:
      "Votre public cible n'utilisera ou ne paiera que pour les choses qui comptent pour lui. Tout le reste est une perte de ressources et de concentration. ",
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Définissez ce qui devrait faire partie de votre MVP, produit initial ou package de base.',
    WELL_DEFINED_VALIDATED:
      "Découvrez s'il est judicieux de créer plusieurs solutions ou packages en appliquant une segmentation basée sur les besoins (différents publics cibles préfèrent des valeurs différentes).",
    PROBLEMS_ARE_PRIORITISED:
      'Les valeurs sont priorisées sur les axes suivants:',
    X_AXIS_INFO:
      'Axe X: Quelle quantité de {BOLD}valeur{/BOLD} est créée pour votre public cible? ',
    Y_AXIS_INFO:
      'Axe Y: {BOLD}“Exigence de base”{/BOLD}; ce qui est indispensable et qui doit être présent dans votre MVP, produit initial ou package de base.',
    DESCRIBE_THE_SITUATION:
      "Décrivez la situation que les répondants doivent garder à l'esprit lorsqu'ils évaluent votre valeur. Le bloc de texte ci-dessous sera affiché avant que vos valeurs ne soient présentées.",
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Où{/BOLD} et {BOLD}quand{/BOLD} votre valeur sera-t-elle utilisée? {BOLD}Comment{/BOLD} votre public cible se sent-il? {BOLD}Quels{/BOLD} sont leurs problèmes/besoins non satisfaits dans leur situation?*',
    CONTEXT_EXAMPLE:
      'Tu es au supermarché (où) après une journée de travail bien remplie (quand) et tu dois rapidement (comment) décider quels ingrédients acheter (quoi).',
    HOW_MANY_TO_TEST: 'Combien de valeurs souhaitez-vous tester?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Souhaitez-vous tester plus de {MAX_COUNTS} valeurs? Envoyez votre demande à',
    DEFINE_YOUR: 'Définissez votre validation de valeur',
    YOUR_INPUT_WILL_BE_USED:
      'Votre contribution sera utilisée pour créer automatiquement la structure et les questions de votre sondage.',
    POINT_INPUT_LABEL: 'Valeur',
    WE_INSTALL_CORNER_EXAMPLE:
      "Les membres de la famille / les colocataires peuvent t'informer à l'aide d'une application mobile pour te dire quel plat ils sont d'humeur pour ce jour-là.",
    NEED_HELP_CONTACT: 'Contact',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'Que penses-tu de la valeur suivante?',
    GIVE_A_SCORE_1:
      'Donne un score de {MIN} (= {PURPLE}pas valable du tout{/PURPLE}) à {MAX} (= {PURPLE}très précieux {/PURPLE})',
    GIVE_A_SCORE_2:
      'Donne un score de {MIN} (= {PURPLE}aucune exigence de base du tout{/PURPLE}) à {MAX} (= {PURPLE}exigence de base{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA:
      'Remplissez les critères de réussite de vos valeurs',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'Nous pensons que les répondants donneraient à cette valeur un score moyen de...',
    OUT_OF_TO_1: 'sur {MAX} en termes de {BOLD}valeur{/BOLD}',
    OUT_OF_TO_2: 'sur {MAX} en termes de {BOLD}une exigence de base{/BOLD}',
    WE_THINK_1:
      'Nous nous attendons à ce que les répondants disent {SPLIT} que cela est {BOLD}valable{/BOLD}.',
    WE_THINK_2:
      "Nous nous attendons à ce que les répondants disent {SPLIT} qu'il s'agit d'{BOLD}une exigence de base{/BOLD}.",
    TO_WHAT_EXTEND_YOU_AGREE_1:
      'Dans quelle mesure es-tu d\'accord avec la déclaration suivante: "Je pense que c\'est {PURPLE}valable{/PURPLE}"',
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'Dans quelle mesure es-tu d\'accord avec la déclaration suivante: "Je pense que c\'est {PURPLE}une exigence de base{/PURPLE}"'
  },
  COMMUNITY: {
    QUESTION_TEXT:
      'Voudrais-tu être étroitement associé à la poursuite du développement de cette innovation et recevoir un contenu spécifique à ce sujet ?',
    ANSWER_YES: "Oui s'il te plaît!",
    ANSWER_NO: 'Non plutôt pas.'
  }
};
