import { connect } from 'react-redux';
import * as actions from '../SurveyBuilder/state/actions';
import TargetAudience from './TargetAudience';

const mapStateToProps = state => ({
  state: state.surveys
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    setSurvey: survey => dispatch(actions.setSurvey(ownProps.api, survey)),
    setActiveFlowFromId: flowId =>
      dispatch(actions.setActiveFlowFromId(ownProps.api, flowId)),
    addProfilingQuestion: (
      flow,
      destinationPosition,
      destinationQuestionId,
      newQuestionId
    ) =>
      dispatch(
        actions.duplicateQuestion(
          ownProps.api,
          flow,
          destinationPosition,
          destinationQuestionId,
          newQuestionId
        )
      ),
    updateQuestionChoice: (
      flowId,
      choice,
      answerUpdate,
      updateActiveQuestion,
      saveUpdatedQuestion
    ) =>
      dispatch(
        actions.updateQuestionChoice(
          ownProps.api,
          flowId,
          choice,
          answerUpdate,
          updateActiveQuestion,
          saveUpdatedQuestion
        )
      ),
    deleteQuestion: questionId =>
      dispatch(actions.deleteQuestion(ownProps.api, questionId))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetAudience);
