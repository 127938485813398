import { AGE_RANGES, AGE_RANGE_NAMES } from './constants';

const ageRangesMapping = {
  [AGE_RANGES.BELLOW_18]: AGE_RANGE_NAMES[AGE_RANGES.BELLOW_18],
  [AGE_RANGES['18_24']]: AGE_RANGE_NAMES[AGE_RANGES['18_24']],
  [AGE_RANGES['25_34']]: AGE_RANGE_NAMES[AGE_RANGES['25_34']],
  [AGE_RANGES['35_44']]: AGE_RANGE_NAMES[AGE_RANGES['35_44']],
  [AGE_RANGES['45_54']]: AGE_RANGE_NAMES[AGE_RANGES['45_54']],
  [AGE_RANGES['55_65']]: AGE_RANGE_NAMES[AGE_RANGES['55_65']],
  [AGE_RANGES.ABOVE_65]: AGE_RANGE_NAMES[AGE_RANGES.ABOVE_65]
};

export default key => {
  if (key === true) {
    return 'Yes';
  }
  if (key === false) {
    return 'No';
  }

  if (ageRangesMapping[key]) {
    return ageRangesMapping[key];
  }

  let stringKey = key;
  if (Array.isArray(stringKey)) {
    stringKey =
      key && key.length
        ? key.reduce((acc, v) => {
            if (acc === '') {
              return v;
            }
            return `${acc}, ${v}`;
          }, '')
        : '';
  }

  const clearedString = stringKey.replaceAll('_', ' ');
  if (!clearedString.length) {
    return '';
  }
  return clearedString[0].toUpperCase() + clearedString.slice(1).toLowerCase();
};
