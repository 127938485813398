import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeGroupFromSurvey from '../../../helpers/removeGroupFromSurvey/removeGroupFromSurvey';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import { QUESTION_TYPES } from '../../../helpers/constants';
import removeDecisionFramework from '../removeDecisionFramework/removeDecisionFramework';

export default (state, flowId, branchId) => {
  const surveyToModify = JSON.parse(JSON.stringify(state.survey));

  const branchIndex = surveyToModify.questions[flowId].branches.findIndex(
    c => c.id === branchId
  );

  const branches = [...surveyToModify.questions[flowId].branches];
  const removedBranch = branches.splice(branchIndex, 1);

  const modifiedFlow = {
    ...surveyToModify.questions[flowId],
    branches
  };

  const newState = {
    newState: {
      ...state,
      survey: {
        ...surveyToModify,
        questions: {
          ...state.survey.questions,
          [flowId]: modifiedFlow
        }
      },
      activeFlow: modifiedFlow
    }
  };

  // If is distributor remove distributor group if exists
  if (modifiedFlow.type === QUESTION_TYPES.DISTRIBUTOR) {
    if (removedBranch && removedBranch.length && removedBranch[0].nextFlow) {
      const isGroup = surveyToModify.content.groups.some(
        g => g.id === removedBranch[0].nextFlow
      );

      if (isGroup) {
        const newSurvey = removeGroupFromSurvey(
          removedBranch[0].nextFlow,
          newState.newState.survey.content,
          newState.newState.survey.questions,
          false
        );

        newState.newState.survey.content = newSurvey.content;
        newState.newState.survey.questions = newSurvey.questions;

        newState.newState.deletedQuestions = newSurvey.removedQuestions;

        if (
          newSurvey.removedMatrixQuestions &&
          newSurvey.removedMatrixQuestions.length
        ) {
          newSurvey.removedMatrixQuestions.forEach(question => {
            const {
              surveyWithRemovedGraph,
              removeRequest
            } = removeDecisionFramework(
              newState.newState.survey,
              question,
              question.matrix.decisionFrameworkType
            );
            if (surveyWithRemovedGraph) {
              newState.newState.survey = surveyWithRemovedGraph;
              if (removeRequest) {
                newState.requests =
                  newState.requests && newState.requests.length
                    ? [...newState.requests, removeRequest]
                    : [removeRequest];
              }
            }
          });
        }
      }
    }
  }

  newState.newState.surveyLists = generateSurveyLists(
    newState.newState.survey.content,
    newState.newState.survey.questions
  );

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState.newState);
  newState.newState = removedNextFlowJumps.state;

  newState.newState.surveyLists = generateSurveyLists(
    newState.newState.survey.content,
    newState.newState.survey.questions
  );

  return newState;
};
