import React from 'react';

import herdyNotiification from '../../../../../../assets/img/herdy-notification.svg';

import styles from './PublishSurveyJumbotron.module.css';

export default ({ description, buttonLabel, onButtonClick }) => (
  <div className={styles.container}>
    <div className={styles.iconContainer}>
      <img src={herdyNotiification} alt="Herdy notification" />
    </div>
    <div className={styles.description}>{description}</div>
    {buttonLabel && (
      <div className={styles.buttonContainer}>
        <div
          className={styles.button}
          role="presentation"
          onClick={() => onButtonClick()}
        >
          {buttonLabel}
        </div>
      </div>
    )}
  </div>
);
