import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { GET_ANALYTICS_DATA } from '../../../../graphql/Workspaces';

import Icon from '../../../../../../../Icon';
import Loader from '../../../Loader/Loader';
import Slider from './components/Slider/Slider';

import warningIcon from '../../../../../../../../../assets/img/exclamation.svg';

import styles from './Question.module.css';

const Question = ({ block, index, populationColors, activePopulations }) => {
  const [analyticAnswers, setAnalyticAnswers] = useState([]);
  const [questionChartParent, setQuestionChartParent] = useState(null);
  const [stats, setStats] = useState();
  const [statisticalRelevanceStats, setStatisticalRelevanceStats] = useState();
  const [expandOtherAnswers, setExpandOtherAnswers] = useState(false);
  const [
    displayStatisticalRelevance,
    setDisplayStatisticalRelevance
  ] = useState(false);
  const [activePopulationColors, setActivePopulationColors] = useState([]);
  const [activePopulationsLength, setActivePopulationsLength] = useState(0);
  const [analyticDataFetched, setAnalyticDataFetched] = useState(0);
  const [displayRatingPercentage, setDisplayRatingPercentage] = useState(true);

  const [getAnalyticsDataMutation, { loading: isLoading }] = useMutation(
    GET_ANALYTICS_DATA,
    {
      onCompleted: response => {
        setAnalyticAnswers(response.getAnalyticsData.values);
        setAnalyticDataFetched(analyticDataFetched + 1);
      }
    }
  );

  if (!block || !block.blockPopulation) {
    return null;
  }

  const getAnalyticsData = populationResults => {
    if (!populationResults || !populationResults.length) {
      return false;
    }

    const input = createAnalyticsServiceInputFromBlockResults(
      populationResults
    );

    if (
      input &&
      input.Q004 &&
      input.Q004.values &&
      input.Q004.values.length < 2
    ) {
      return null;
    }

    return {
      class: input.Q004.class,
      label: input.Q004.label,
      levels: input.Q004.levels,
      values: input.Q004.values
    };
  };

  useEffect(
    () => {
      const requests = [];
      block.blockPopulation.forEach(population => {
        if (population && population.length) {
          requests.push(getAnalyticsData(population));
        } else {
          requests.push(null);
        }
      });
      getAnalyticsDataMutation({ variables: { input: requests } });
    },
    [block]
  );

  useEffect(
    () => {
      calculateBarStatsAnswers();
    },
    [block, analyticDataFetched, activePopulations, displayRatingPercentage]
  );

  let totalAnswers = 0;
  const detailedAnswers = block.answers.map((answer, answerIndex) => {
    const answerResponse = block.resultStats.values.filter(
      result => result.x === answerIndex
    );
    if (answerResponse.length) {
      totalAnswers += answerResponse[0].y;
      return {
        x: answerResponse[0].x,
        question: block.answers[answerResponse[0].x].answer,
        y: answerResponse[0].y,
        image_filename: block.answers[answerResponse[0].x].image_filename
      };
    }
    return {
      x: answerIndex,
      question: answer.answer,
      y: 0,
      image_filename: answer.image_filename
    };
  });

  const rawAnswers = detailedAnswers.map(answer => {
    const answerWithPercentage = answer;
    if (block.type === 'Checkboxes') {
      answerWithPercentage.percentage =
        block && block.results && block.results.length
          ? ((answer.y / block.results.length) * 100).toFixed(2)
          : null;
    }
    if (block.type === 'Multiple Choice Question') {
      answerWithPercentage.percentage = (
        (answer.y / totalAnswers) *
        100
      ).toFixed(2);
    }
    return answerWithPercentage;
  });

  const parseAnalyticsToAnswers = analyticsOutput => {
    if (block && block.answers && block.answers.length) {
      const fit = singleAnalytic =>
        parseFloat((singleAnalytic.fit * 100).toFixed(2));
      const lower = singleAnalytic =>
        parseFloat((singleAnalytic.lower * 100).toFixed(2));
      const upper = singleAnalytic =>
        parseFloat((singleAnalytic.upper * 100).toFixed(2));

      return block.answers.map((singleAnswer, answerIndex) => {
        const singleAnswerAnalytic = {
          question: singleAnswer.answer,
          image_filename: singleAnswer.image_filename,
          count:
            rawAnswers[answerIndex] && rawAnswers[answerIndex].y
              ? rawAnswers[answerIndex].y
              : 0
        };

        if (analyticsOutput) {
          const singleAnswerAnalytics = analyticsOutput.filter(
            analyticOutput =>
              parseInt(analyticOutput.alternative, 10) === answerIndex
          );

          if (singleAnswerAnalytics && singleAnswerAnalytics.length) {
            singleAnswerAnalytic.fit = fit(singleAnswerAnalytics[0]);
            singleAnswerAnalytic.lower = lower(singleAnswerAnalytics[0]);
            singleAnswerAnalytic.upper = upper(singleAnswerAnalytics[0]);
          }
        }
        return singleAnswerAnalytic;
      });
    }
    return null;
  };

  const createAnalyticsServiceInputFromBlockResults = inputBlockResults => {
    const answersToAnalyse = [];
    const values = inputBlockResults.map(result => {
      if (result.answer || result.answer === 0) {
        if (answersToAnalyse.indexOf(result.answer) === -1) {
          answersToAnalyse.push(result.answer);
        }

        return [`${result.answer}`];
      }
      if (result.answers || result.answers === 0) {
        result.answers.forEach(answer => {
          if (answersToAnalyse.indexOf(answer) === -1) {
            answersToAnalyse.push(parseInt(answer, 10));
          }
        });

        return [`${result.answers}`];
      }
      return null;
    });

    const levels = answersToAnalyse
      .sort((a, b) => a - b)
      .map(answerIndex => `${answerIndex}`);
    return {
      Q004: {
        class: ['checkbox'],
        values,
        levels,
        label: [block.question],
        label0: {}
      }
    };
  };

  // CODE FOR ANSWER BARS
  /*
  const formatAnswers = analyticAnswersGrouped => {
    if (analyticAnswersGrouped && analyticAnswersGrouped.length) {
      const answers = analyticAnswersGrouped[0].map(answerGroup => ({
        question: answerGroup && answerGroup.question,
        image_filename: answerGroup && answerGroup.image_filename,
        population: []
      }));

      answers.forEach((answer, answerIndex) => {
        analyticAnswersGrouped.forEach(answerGroup => {
          answer.population.push(answerGroup[answerIndex]);
        });
      });

      return answers;
    }

    return null;
  };
  */

  const analyticAnswersGrouped = analyticAnswers
    ? analyticAnswers.map(analyticAnswer =>
        parseAnalyticsToAnswers(analyticAnswer)
      )
    : [];

  // CODE FOR ANSWER BARS
  // const answers = formatAnswers(analyticAnswersGrouped);

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = [].concat(
        JSON.parse(question)
          .blocks.map(draftBlock => draftBlock.text)
          .join('\n')
      );
    } catch (error) {
      questionValue = question;
    }
    return questionValue;
  };

  const perepareValuesForSlider = values => {
    if (values && values.length) {
      return values.sort((a, b) => a.x - b.x);
    }
    return [];
  };

  const calculateBarStatsAnswers = () => {
    if (block && block.blockPopulation && block.blockPopulation.length) {
      const blockPopulationAnswers = [];
      const statsToAdd = [];
      const statisticalRelevanceStatsToAdd = [];
      const populationsLength = block.blockPopulation.length;

      for (let i = 0; i < populationsLength; i += 1) {
        blockPopulationAnswers.push([]);
        statsToAdd.push([]);
        statisticalRelevanceStatsToAdd.push([]);

        block.blockPopulation[i].forEach(result => {
          if (result.answer) {
            blockPopulationAnswers[i].push(result.answer);
          } else {
            result.answers.forEach(answer => {
              blockPopulationAnswers[i].push(answer);
            });
          }
        });
        for (let z = 1; z < block.answers.length + 1; z += 1) {
          statsToAdd[i].push({ x: z, y: 0 });
        }
        blockPopulationAnswers[i].forEach(answer => {
          statsToAdd[i].forEach(xyPairObject => {
            if (xyPairObject.x - 1 === answer) {
              // eslint-disable-next-line no-param-reassign
              xyPairObject.y += 1;
            }
          });
        });
      }

      if (displayRatingPercentage) {
        statsToAdd.forEach((stat, statIndex) => {
          stat.forEach(xyPairObject => {
            // eslint-disable-next-line no-param-reassign
            xyPairObject.y = xyPairObject.y
              ? (xyPairObject.y / blockPopulationAnswers[statIndex].length) *
                100
              : 0;
          });
        });
      }

      analyticAnswersGrouped.forEach((analyticAnswer, answerIndex) => {
        analyticAnswer.forEach(answer => {
          const statisticalObject = {
            lower: 0,
            fit: 0,
            upper: 0
          };
          if (answer.lower) {
            statisticalObject.lower = answer.lower;
          }
          if (answer.fit) {
            statisticalObject.fit = answer.fit;
          }
          if (answer.upper) {
            statisticalObject.upper = answer.upper;
          }
          if (statisticalRelevanceStatsToAdd[answerIndex]) {
            statisticalRelevanceStatsToAdd[answerIndex].push(statisticalObject);
          }
        });
      });

      // remove statToAdd by true false activePopulations, pass populationcolors property to slider & bar
      const activePopulationColorsArray = [];
      const activeStats = [];
      const activestatisticalRelevanceStats = [];
      let amountOfActivePopulations = 0;

      for (let i = 0; i < populationsLength; i += 1) {
        if (activePopulations[i]) {
          activeStats.push(statsToAdd[i]);
          activestatisticalRelevanceStats.push(
            statisticalRelevanceStatsToAdd[i]
          );
          activePopulationColorsArray.push(populationColors[i]);
          amountOfActivePopulations += 1;
        }
      }

      setActivePopulationsLength(amountOfActivePopulations);
      setActivePopulationColors(activePopulationColorsArray);
      setStats(activeStats);
      setStatisticalRelevanceStats(activestatisticalRelevanceStats);
    }
  };

  const getAnswerStrings = () => {
    const answerStrings = block.answers.map(answer => answer.answer);
    return answerStrings;
  };

  const detectOverlappingPopulations = () => {
    let overlap = false;
    block.blockPopulation.forEach((firstPopulation, firstPopulationIndex) => {
      if (
        firstPopulationIndex !== 0 &&
        activePopulations[firstPopulationIndex]
      ) {
        firstPopulation.forEach(answer => {
          block.blockPopulation.forEach(
            (otherPopulation, otherPopulationIndex) => {
              if (
                firstPopulationIndex !== otherPopulationIndex &&
                otherPopulationIndex !== 0 &&
                activePopulations[otherPopulationIndex]
              ) {
                otherPopulation.forEach(otherAnswer => {
                  if (answer.resultId === otherAnswer.resultId) {
                    overlap = true;
                  }
                });
              }
            }
          );
        });
      }
    });
    return overlap;
  };

  const answerOrderTable = () => {
    if (block && block.show_selected_answers_order) {
      if (
        block.resultStats.values &&
        block.resultStats.values[0] &&
        block.resultStats.values[0].answersCounter
      ) {
        const answersDistribution = block.resultStats.values[0].answersCounter;
        const orderTable = [];

        const columnsTotal = {};
        Object.keys(answersDistribution).map(column => {
          Object.keys(answersDistribution[column]).map(columnItem => {
            if (columnsTotal[columnItem]) {
              columnsTotal[columnItem] +=
                answersDistribution[column][columnItem];
            } else {
              columnsTotal[columnItem] =
                answersDistribution[column][columnItem];
            }
            return columnsTotal[columnItem];
          });

          return column;
        });

        const calulatePercentage = (value, total) => {
          if (!value || !total || value === 0 || total === 0) {
            return 0;
          }
          return ((value / total) * 100).toFixed();
        };

        // Table header
        const tableHeader = [
          <div className={`${styles.leftTitle} ${styles.topTitle}`} />
        ];
        for (let i = 0; i < block.answers.length; i += 1) {
          tableHeader.push(
            <div className={styles.topTitle}>{i + 1} choice</div>
          );
        }
        orderTable.push(<div className={styles.tableRow}>{tableHeader}</div>);

        block.answers.forEach((answer, blockIndex) => {
          const sortedAnswers = [
            <div className={styles.leftTitle}>{answer.answer}</div>
          ];

          block.answers.forEach((answerSelection, indexSelection) => {
            if (
              answersDistribution[blockIndex] &&
              answersDistribution[blockIndex][indexSelection]
            ) {
              sortedAnswers.push(
                <div className={styles.tableValue}>
                  {answersDistribution[blockIndex][indexSelection]}
                  <br />
                  <span className={styles.percentage}>
                    {calulatePercentage(
                      answersDistribution[blockIndex][indexSelection],
                      columnsTotal[indexSelection]
                    )}
                    %
                  </span>
                </div>
              );
            } else {
              sortedAnswers.push(
                <div className={styles.tableValue}>
                  0<br />
                  <span className={styles.percentage}>
                    {calulatePercentage(
                      answersDistribution &&
                        answersDistribution[blockIndex] &&
                        answersDistribution[blockIndex][indexSelection]
                        ? answersDistribution[blockIndex][indexSelection]
                        : null,
                      columnsTotal[indexSelection]
                    )}
                    %
                  </span>
                </div>
              );
            }
          });

          orderTable.push(
            <div className={styles.tableRow}>{sortedAnswers}</div>
          );
        });
        return (
          <div className={styles.sortAnswersTableContainer}>{orderTable}</div>
        );
      }
    }
    return null;
  };

  const isAnalyticsDataFetched = () => {
    if (analyticAnswers && analyticAnswers.length) {
      for (let i = 0; i < analyticAnswers.length; i += 1) {
        if (!analyticAnswers[i] || !analyticAnswers[i].length) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  };

  return (
    <div
      className={`${
        styles.container
      } workspaces-main-content-questions-question`}
      ref={elem => setQuestionChartParent(elem)}
    >
      <div className={styles.blockStructure}>
        <div className={styles.number}>{index + 1}</div>
        <div className={styles.iconContainer}>
          <span className={styles.icon}>
            <Icon type="multiple-choice" />
          </span>
        </div>
        <div className={styles.blockDetails}>
          <div className={`${styles.blockText} workspaces-question-question`}>
            {parseQuestion(block.question)}
          </div>
        </div>
      </div>
      {
        // CODE FOR ANSWER BARS
        /* isLoading ? (
        <Loader />
      ) : (
        <div
          className={`${
            styles.content
          } workspaces-question-answer-bar-container`}
        >
          {answers && answers.length ? (
            [
              answers.map((answer, answerIndex) => (
                <AnswerBar
                  key={`main-content-answer-bar-${
                    block.id
                  }-${answerIndex.toString()}`}
                  answer={answer}
                  answerIndex={answerIndex}
                  populationColors={populationColors}
                />
              ))
            ]
          ) : (
            <div className="no-chart-data">No data</div>
          )}
        </div>
          ) */
      }
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.resultsContainer}>
            <div className={styles.resultsContainerLeft}>
              <span role="presentation" className={styles.resultViewTypeItem}>
                Answers
              </span>

              {activePopulations &&
                activePopulations.length &&
                detectOverlappingPopulations() && (
                  <div className={styles.warning}>
                    <img src={warningIcon} alt="Warning" /> Some of your
                    populations overlap. This will cause innacurate statistics.
                  </div>
                )}
            </div>
            <div className={styles.resultsContainerRight}>
              <span
                role="presentation"
                className={`${styles.toggleButton}
                ${
                  displayStatisticalRelevance && isAnalyticsDataFetched()
                    ? styles.toggleButtonActive
                    : ''
                } ${styles.statisticalRelevance} ${
                  isAnalyticsDataFetched() ? '' : styles.toggleButtonDisabled
                }`}
                onClick={() => {
                  setDisplayStatisticalRelevance(!displayStatisticalRelevance);
                }}
              >
                Show statistical relevance
              </span>
            </div>
          </div>

          {activePopulations &&
          activePopulations.length &&
          activePopulations.some(Boolean) ? (
            <>
              <Slider
                parent={questionChartParent}
                data={perepareValuesForSlider(block.resultStats.values)}
                start={1}
                end={block.answers.length}
                stats={stats}
                populationsLength={activePopulationsLength}
                populationColors={activePopulationColors}
                statisticalRelevanceStats={statisticalRelevanceStats}
                answerStrings={getAnswerStrings()}
                displayStatisticalRelevance={
                  displayStatisticalRelevance && isAnalyticsDataFetched()
                    ? displayStatisticalRelevance
                    : false
                }
                activePopulationColors={activePopulationColors}
                displayRatingPercentage={displayRatingPercentage}
                setDisplayRatingPercentage={setDisplayRatingPercentage}
              />
              {answerOrderTable()}
            </>
          ) : (
            <div className="no-chart-data">No data</div>
          )}
        </>
      )}
      {block.allowOther &&
      activePopulations &&
      activePopulations.length &&
      activePopulations.some(Boolean) ? (
        <div className={styles.otherResultsContainer}>
          <div className={styles.otherResultsHeader}>
            <span className={styles.otherResultsTitle}>Other answers</span>
            <div className={styles.toggleButtonWrapper}>
              <span
                role="presentation"
                className={`${styles.toggleButton}
                ${expandOtherAnswers ? styles.toggleButtonActive : ''}`}
                onClick={() => {
                  setExpandOtherAnswers(!expandOtherAnswers);
                }}
              >
                Expand answers
              </span>
            </div>
          </div>
          <div
            className={`${styles.otherResultsList} ${
              expandOtherAnswers ? styles.otherResultsListExpanded : null
            }`}
          >
            {block.blockPopulation.map((population, populationIndex) =>
              activePopulations[populationIndex]
                ? [
                    population.map(
                      answer =>
                        answer.answer_string && (
                          <div className={styles.otherResult}>
                            <span
                              style={{
                                backgroundColor:
                                  populationColors[populationIndex]
                              }}
                              className={styles.otherResultColor}
                            />
                            <span className={styles.otherResultString}>
                              {answer.answer_string}
                            </span>
                          </div>
                        )
                    )
                  ]
                : null
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Question;
