import React, { useState } from 'react';

import useLazyQuery from '../../../../../../../../surveys/hooks/useLazyQuery';

import { GET_SURVEY_COMMUNITIES } from '../../../../../../../../graphql/Survey';

import {
  COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES,
  COMMUNITY_MANAGEMENT_TYPES,
  COMMUNITY_MANAGEMENT_NAMES
} from '../../../../../../helpers/constants';

import SearchDropdown from '../SearchDropdown/SearchDropdown';

import deleteIcon from '../../../../../../../../assets/img/delete.svg';

import styles from './CommunityTagsFilter.module.css';
import MultipleSearchDropdown from '../MultipleSearchDropdown/MultipleSearchDropdown';

export default ({
  singleFilterIndex,
  appliedFilters,
  setAppliedFilters,
  onFilterSelectCompleted,
  onTypeChange,
  getTypeOptions,
  onSelectAllClick,
  filterRelation,
  setFilterRelation
}) => {
  const singleFilter = appliedFilters[singleFilterIndex];
  const [hideSelectAllCheckbox, setHideSelectAllCheckbox] = useState(true);

  const searchCommunityTypes = async () =>
    Object.values(COMMUNITY_MANAGEMENT_TYPES).map(cType => ({
      value: cType,
      label: COMMUNITY_MANAGEMENT_NAMES[cType]
    }));

  const searchCommunityTagValuesApi = useLazyQuery(GET_SURVEY_COMMUNITIES, {
    fetchPolicy: 'no-cache'
  });

  const searchCommunityTagValues = async (page, search) => {
    if (
      singleFilter &&
      singleFilter.community &&
      singleFilter.community.communityType
    ) {
      const preparedAppliedFilters = { ...singleFilter.tags };
      delete preparedAppliedFilters.tagValue;

      // For dynamic communities we pass filterRelation to only show communities with the same relation as the main filter if there are multiple filters
      const searchResults = await searchCommunityTagValuesApi({
        variables: {
          ...preparedAppliedFilters,
          start: 0,
          end: (page + 1) * 10,
          search,
          salt: Math.random().toString(),
          type: singleFilter.community.communityType,
          ...(singleFilter.community.communityType ===
            COMMUNITY_MANAGEMENT_TYPES.DYNAMIC && appliedFilters.length > 1
            ? { filterRelation }
            : {})
        }
      });

      if (search && search !== '') {
        setHideSelectAllCheckbox(false);
      } else {
        setHideSelectAllCheckbox(true);
      }

      return searchResults &&
        searchResults.data &&
        searchResults.data.getSurveyCommunities &&
        searchResults.data.getSurveyCommunities.records &&
        searchResults.data.getSurveyCommunities.records.length
        ? searchResults.data.getSurveyCommunities.records.map(r => ({
            label: r.name,
            value: r.profilingTag.tagValue,
            filterRelation: r.filterRelation
          }))
        : [];
    }
    return [];
  };

  return (
    <div className={styles.container}>
      <SearchDropdown
        fetchOptions={getTypeOptions}
        placeholder="Select a filter type"
        onSelectCompleted={value => onTypeChange(value)}
        value={{
          value: singleFilter.type,
          label: COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[singleFilter.type]
        }}
        search={false}
        hideNoValueOption
      />
      <SearchDropdown
        fetchOptions={searchCommunityTypes}
        placeholder="Select a community type"
        onSelectCompleted={value =>
          onFilterSelectCompleted('communityType', value, 'community')
        }
        value={{
          value:
            singleFilter &&
            singleFilter.community &&
            singleFilter.community.communityType,
          label:
            singleFilter &&
            singleFilter.community &&
            COMMUNITY_MANAGEMENT_NAMES[singleFilter.community.communityType]
        }}
        search={false}
        hideNoValueOption
      />
      {singleFilter &&
      singleFilter.community &&
      singleFilter.community.communityType &&
      singleFilter.community.communityType ===
        COMMUNITY_MANAGEMENT_TYPES.STATIC ? (
        <MultipleSearchDropdown
          fetchOptions={searchCommunityTagValues}
          placeholder="Community name"
          onSelectCompleted={(value, label) => {
            onFilterSelectCompleted('name', value, 'community', true);
            onFilterSelectCompleted('label', label, 'community', true);
          }}
          value={{
            value: singleFilter.community.name,
            label:
              singleFilter.community.label &&
              singleFilter.community.label.length
                ? singleFilter.community.label
                : singleFilter.community.name
          }}
          fixedWidth={200}
          onSelectAllClick={(values, allValuesSelected) => {
            onSelectAllClick('name', values, 'community', allValuesSelected);
            const labels = values.map(v => ({
              ...v,
              value: v.label
            }));
            onSelectAllClick('label', labels, 'community', allValuesSelected);
          }}
          hideSelectAllCheckbox={hideSelectAllCheckbox}
        />
      ) : (
        <SearchDropdown
          fetchOptions={searchCommunityTagValues}
          placeholder="Community name"
          onSelectCompleted={(value, label, communityFilterRelation) => {
            if (
              communityFilterRelation &&
              communityFilterRelation !== filterRelation
            ) {
              setFilterRelation(communityFilterRelation);
            }
            onFilterSelectCompleted('name', value, 'community');
            onFilterSelectCompleted('label', label, 'community');
          }}
          value={{
            value: singleFilter.community.name,
            label: singleFilter.community.label || singleFilter.community.name
          }}
          search
          hideNoValueOption={false}
          returnAdditionalFieldOnSelect="filterRelation"
        />
      )}

      <img
        src={deleteIcon}
        className={styles.deleteIcon}
        onClick={() => {
          const newAppliedFilters = appliedFilters.filter(
            (aF, i) => i !== singleFilterIndex
          );
          setAppliedFilters(newAppliedFilters);
        }}
        alt="Delete filter"
        role="presentation"
      />
    </div>
  );
};
