export default [
  '#6096BB',
  '#3A86FF',
  '#274C77',
  '#B65916',
  '#505E75',
  '#5C4743',
  '#E0AAFD',
  '#FFC800',
  '#7B2CBF',
  '#1BA8E9',
  '#3D096C',
  '#7679ED',
  '#FFEE35',
  '#D5BA94',
  '#82C0CC',
  '#C3A900',
  '#A5978B',
  '#F3D5B5',
  '#EF8355',
  '#A3CFF2'
];
