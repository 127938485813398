const calculateValueScores = (values, lovers) => {
  let extremeUsersValues;
  if (lovers) {
    extremeUsersValues = values.sort((a, b) => a.x - b.x).reverse();
  } else {
    extremeUsersValues = values.sort((a, b) => a.x - b.x);
  }

  const questionQuota =
    extremeUsersValues.reduce((totalQuestionAnswersCount, answerOption) => {
      const count = totalQuestionAnswersCount + (answerOption.y || 0);
      return count;
    }, 0) / 4;

  let scoreCounter = 0;
  // Calculate values until score quota is reached (25%)
  extremeUsersValues = extremeUsersValues.reduce((newValues, currentValue) => {
    if (scoreCounter < questionQuota) {
      if (questionQuota - scoreCounter > currentValue.y) {
        scoreCounter += currentValue.y;
        newValues.push(currentValue);
      } else if (questionQuota - scoreCounter === currentValue.y) {
        scoreCounter += currentValue.y;
        newValues.push(currentValue);
      } else {
        newValues.push({
          x: currentValue.x,
          y: questionQuota - scoreCounter
        });
        scoreCounter = questionQuota;
      }
    }
    return newValues;
  }, []);
  return extremeUsersValues;
};

const filterExtremeUsersResults = (
  filter,
  extremeUsersValues,
  question,
  choiceId
) => {
  const extremeValuesCounters = extremeUsersValues.reduce(
    (extremeValues, currentExtremeValue) => {
      // eslint-disable-next-line no-param-reassign
      extremeValues[currentExtremeValue.x] = {
        count: 0,
        max: currentExtremeValue.y
      };
      return extremeValues;
    },
    {}
  );

  const extremeUsersResultIds =
    question.unfilteredResults && question.unfilteredResults.length
      ? question.unfilteredResults.reduce((resultIds, currentResult) => {
          let selectedAnswer = currentResult.answer;
          if (
            question.type === 'Matrix' &&
            currentResult.matrixAnswers &&
            currentResult.matrixAnswers.length &&
            choiceId
          ) {
            if (
              currentResult.matrixAnswers.some(
                answer =>
                  answer.questionId === question.id &&
                  answer.choices &&
                  answer.choices.length &&
                  answer.choices.some(
                    choice =>
                      choice.choiceId === choiceId &&
                      choice.answers &&
                      choice.answers.length
                  )
              )
            ) {
              const matrixAnswer = currentResult.matrixAnswers
                .find(a => a.questionId === question.id)
                .choices.find(choice => choice.choiceId === choiceId)
                .answers[0];
              if (matrixAnswer || matrixAnswer === 0) {
                selectedAnswer = matrixAnswer;
              }
            }
          }

          if (currentResult.answers && currentResult.answers.length) {
            if (
              filter === 'xLovers' ||
              filter === 'yLovers' ||
              filter === 'xLoversyLovers'
            ) {
              selectedAnswer = Math.max(...currentResult.answers);
            } else {
              selectedAnswer = Math.min(...currentResult.answers);
            }
          }
          if (
            extremeValuesCounters[selectedAnswer] &&
            extremeValuesCounters[selectedAnswer].count <
              extremeValuesCounters[selectedAnswer].max
          ) {
            extremeValuesCounters[selectedAnswer].count += 1;
            resultIds.push(currentResult.resultId);
          }
          return resultIds;
        }, [])
      : [];
  return extremeUsersResultIds;
};

const returnValues = (stats, question, feature) => {
  let values = [];
  if (stats.values && stats.values.length) {
    if (question.type === 'Matrix') {
      const questionValues = stats.values.find(
        matrixOption =>
          matrixOption.questionId === question.id &&
          feature.choiceId &&
          feature.choiceId === matrixOption.choiceId
      );
      if (questionValues && questionValues.values) {
        values = [...questionValues.values];
      }
    } else {
      values = [...stats.values];
    }
  }
  return values;
};

export default (activeLoversHaters, reducedQuestions, questionTypes) => {
  let extremeUsersResultIds = [];

  const xFilter = [
    'xLovers',
    'xHaters',
    'xLoversyLovers',
    'xHatersyHaters'
  ].includes(activeLoversHaters.filter);
  const yFilter = [
    'yLovers',
    'yHaters',
    'xLoversyLovers',
    'xHatersyHaters'
  ].includes(activeLoversHaters.filter);
  const lovers = ['xLovers', 'yLovers', 'xLoversyLovers'].includes(
    activeLoversHaters.filter
  );

  const xQuestion = reducedQuestions.find(
    q => q.id === activeLoversHaters[questionTypes.xQuestion]
  );
  const yQuestion = reducedQuestions.find(
    q => q.id === activeLoversHaters[questionTypes.yQuestion]
  );

  if (xQuestion && yQuestion) {
    const xQuestionValues = returnValues(
      xQuestion.unfilteredResultStats,
      xQuestion,
      activeLoversHaters
    );
    const yQuestionValues = returnValues(
      yQuestion.unfilteredResultStats,
      yQuestion,
      activeLoversHaters
    );
    let xValues = xFilter
      ? xQuestionValues.filter(r => (r.x || r.x === 0) && (r.y || r.y === 0))
      : [];
    let yValues = yFilter
      ? yQuestionValues.filter(r => (r.x || r.x === 0) && (r.y || r.y === 0))
      : [];
    xValues = calculateValueScores(xValues, lovers);
    yValues = calculateValueScores(yValues, lovers);
    let xResultIds = [];
    let yResultIds = [];

    if (xFilter) {
      xResultIds = filterExtremeUsersResults(
        activeLoversHaters.filter,
        xValues,
        xQuestion,
        activeLoversHaters.choiceId || null
      );
    }
    if (yFilter) {
      yResultIds = filterExtremeUsersResults(
        activeLoversHaters.filter,
        yValues,
        yQuestion,
        activeLoversHaters.choiceId || null
      );
    }
    if (xFilter && yFilter) {
      extremeUsersResultIds = xResultIds.filter(
        xR => yResultIds.findIndex(yR => yR === xR) > -1
      );
    } else {
      extremeUsersResultIds = [...xResultIds, ...yResultIds];
    }
  }
  return extremeUsersResultIds;
};
