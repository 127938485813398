import { applyMiddleware, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import authReducer from './clients/auth/reducers';
import authSaga from './clients/auth/sagas';

import campaignsReducer from './clients/campaigns/reducers';
import campaignsSaga from './clients/campaigns/sagas';

import surveysReducer from './clients/surveys/components/SurveyBuilderPage/SurveyBuilder/state/reducers';
import surveysSaga from './clients/surveys/components/SurveyBuilderPage/SurveyBuilder/state/sagas';

import ClientApi from './clients/api';

// import userAuthReducer from './users/auth/reducers';
// import userAuthSaga from './users/auth/sagas';

import userCampaignsReducer from './users/campaigns/reducers';
// import userCampaignsSaga from './users/campaigns/sagas';

// import userMarketReducer from './users/market/reducers';
// import userMarketSaga from './users/market/sagas';

// import UserCampaignsApi from './users/campaigns/api';
// import UserMarketApi from './users/market/api';

import organisationsReducer from './organisations/reducers';

/* import { reducer as formReducer } from 'redux-form';

import AdminApi from './admin/api';

import baseReducer from './admin/base/reducers';
import BaseSaga from './admin/base/sagas';

import dbReducer from './admin/db/reducers';
import DbSaga from './admin/db/sagas';

import kpiReducer from './admin/kpi/reducers';
import KPISaga from './admin/kpi/sagas';

import campaignReducer from './clients/campaigns/reducers';
import campaignSaga from './clients/campaigns/sagas';
 */

export const history = createBrowserHistory({
  basename: '/'
});

/* const logReducer = (state = {}, action) => {
  console.log(action); // eslint-disable-line no-console
  return state;
}; */

export default baseUrl => {
  const sagaMiddleware = createSagaMiddleware();
  const clientApi = ClientApi(`${baseUrl}`, history);
  // const userCampaignsApi = UserCampaignsApi(`${baseUrl}`, history);
  // const userMarketApi = UserMarketApi(`${baseUrl}`, history);
  // const adminApi = AdminApi(`${baseUrl}/api/admin/v2`, history);

  const store = createStore(
    combineReducers({
      auth: authReducer,
      campaigns: campaignsReducer,
      // userAuth: userAuthReducer,
      userCampaigns: userCampaignsReducer,
      // userMarket: userMarketReducer,
      organisations: organisationsReducer,
      surveys: surveysReducer
    }),
    {},
    applyMiddleware(sagaMiddleware)
  );

  /* sagaMiddleware.run(BaseSaga(adminApi));
  sagaMiddleware.run(DbSaga(adminApi));
  sagaMiddleware.run(KPISaga(adminApi));
  sagaMiddleware.run(campaignSaga(clientApi)); */

  sagaMiddleware.run(authSaga(clientApi));
  sagaMiddleware.run(campaignsSaga(clientApi));
  sagaMiddleware.run(surveysSaga());
  // sagaMiddleware.run(userAuthSaga(userCampaignsApi));
  // sagaMiddleware.run(userCampaignsSaga(userCampaignsApi));
  // sagaMiddleware.run(userMarketSaga(userMarketApi));

  return store;
};
