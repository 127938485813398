import React from 'react';

import styles from './LogicField.module.css';

export default ({ fieldType, value, onChangeRangeField }) => (
  <input
    className={styles.input}
    type="number"
    onKeyDown={evt => {
      if (evt.key === '-') {
        return true;
      }

      return evt.key === 'ArrowDown' ||
        evt.key === 'ArrowUp' ||
        evt.key === 'e' ||
        evt.key === 'E' ||
        evt.key === '.' ||
        evt.key === '-'
        ? evt.preventDefault()
        : null;
    }}
    onChange={e => {
      onChangeRangeField(fieldType, e.target.value);
    }}
    onWheel={e => e.target.blur()}
    maxLength="400"
    value={value || value === 0 ? value : ''}
  />
);
