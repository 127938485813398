import React from 'react';

import styles from './AccountManagementRecordLoader.module.css';

export default () => (
  <div className="loader-container">
    <div className={`item ${styles.placeholder}`} />
    <div className={`item ${styles.placeholder}`} />
    <div className={`item ${styles.placeholder}`} />
    <div className={`item ${styles.placeholder}`} />
    <div className={`item ${styles.placeholder}`} />
    <div className={`item ${styles.placeholder}`} />
  </div>
);
