import React, { useState, useEffect } from 'react';

import RecordSelection from './components/RecordSelection';
import textareaAutoExpand from '../../../../../helpers/textareaAutoExpand';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';

import styles from '../Welcome/Welcome.module.css';

export default ({ survey, isAdmin, onUpdate }) => {
  const [expanded, setExpanded] = useState(true);
  const [assumptionToValidateValue, setAssumptionToValidateValue] = useState(
    (survey && survey.scoping && survey.scoping.assumptionToValidate) || ''
  );
  const [
    targetAudienceDescriptionValue,
    setTargetAudienceDescriptionValue
  ] = useState(
    (survey && survey.scoping && survey.scoping.targetAudienceDescription) || ''
  );
  const [assumptionTextarea, setAssumptionTextarea] = useState(null);
  const [targetAudienceTextarea, setTargetAudienceTextarea] = useState(null);

  const onWindowResize = ev => {
    ev.preventDefault();
    if (assumptionTextarea) {
      textareaAutoExpand(assumptionTextarea);
    }

    if (targetAudienceTextarea) {
      textareaAutoExpand(targetAudienceTextarea);
    }
  };

  useEffect(
    () => {
      if (assumptionTextarea) {
        textareaAutoExpand(assumptionTextarea);
      }

      if (targetAudienceTextarea) {
        textareaAutoExpand(targetAudienceTextarea);
      }

      window.addEventListener('resize', onWindowResize);

      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    },
    [assumptionTextarea]
  );

  const removeTypenameFromPayload = payload => {
    const newPayload = { ...payload };
    delete newPayload.__typename;
    return newPayload;
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.titleContainer}
        role="presentation"
        onClick={() => setExpanded(() => setExpanded(!expanded))}
      >
        <div className={styles.title}>Scoping</div>
        <img
          src={arrowDownPurple}
          alt="ExpandColapse"
          className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
        />
      </div>
      {expanded ? (
        <div
          className={styles.detailsContainer}
          style={{ paddingBottom: '20px' }}
        >
          <RecordSelection
            survey={survey}
            isAdmin={isAdmin}
            onUpdate={onUpdate}
          />
          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              What do you want to learn or decide based on this survey?
              <span className={styles.requiredAsterisk}>*</span>
              <div className={styles.moreInfoContainer}>
                <div className={styles.questionMark}>?</div>
                <div className={styles.tooltip}>
                  <div>
                    We aim to provide actionable decisions. However, we can only
                    offer targeted recommendations when we have a clear
                    understanding of the specific assumption(s) you intend to
                    validate.
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    Good example: We worked out a concept for a new kind of
                    vegetarian meal and I want to check potential interest among
                    both vegetarians and non-vegetarians. Furthermore, I want to
                    learn how to optimise the concept.
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    Poor example: When it comes to nutrition, you are more of a…
                    <ul>
                      <li>- Pescatarian (only fish)</li>
                      <li>
                        - Flexitarian (consciously alternating between meat,
                        fish, and vegetarian options)
                      </li>
                      <li>- Vegetarian (no meat and no fish)</li>
                      <li>- Vegan (no animal products)</li>
                      <li>- Pollotarian (only poultry)</li>
                      <li>
                        - Omnivore (I eat everything, no specific preferences)
                      </li>
                      <li>- Other</li>
                      <li>- None of the above</li>
                    </ul>
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    It is not intended to note research questions. This is the
                    responsibility of the research designer once the scope is
                    clear.
                  </div>
                </div>
              </div>
            </div>
            <textarea
              className={styles.description}
              rows="1"
              ref={element => {
                if (element) {
                  setAssumptionTextarea(element);
                }
              }}
              placeholder="Type here"
              onBlur={() => {
                const newScoping = removeTypenameFromPayload(survey.scoping);
                newScoping.assumptionToValidate = assumptionToValidateValue;
                onUpdate('scoping', newScoping);
              }}
              onChange={e => {
                textareaAutoExpand(assumptionTextarea);
                setAssumptionToValidateValue(e.target.value);
              }}
              maxLength="2000"
              value={assumptionToValidateValue}
            />
            <div />
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              Is there a focus on a specific target audience? Why?
              <span className={styles.requiredAsterisk}>*</span>
              <div className={styles.moreInfoContainer}>
                <div className={styles.questionMark}>?</div>
                <div className={styles.tooltip}>
                  We are looking for a general description of the target
                  audience and why that audience is important to make an
                  actionable business decision. Focusing on a specific target
                  audience is only valuable when there is an assumption for a
                  different behavior that might result in a different business
                  decision. In other words, targeting a niche audience without
                  validating your assumptions, might reduce market potential.
                </div>
              </div>
            </div>
            <textarea
              className={
                !(survey && survey.scoping)
                  ? styles.description
                  : `${styles.description} ${styles.notesField}`
              }
              rows="1"
              ref={element => {
                if (element) {
                  setTargetAudienceTextarea(element);
                }
              }}
              placeholder="Type here"
              onBlur={() => {
                const newScoping = removeTypenameFromPayload(survey.scoping);
                newScoping.targetAudienceDescription = targetAudienceDescriptionValue;
                onUpdate('scoping', newScoping);
              }}
              onChange={e => {
                textareaAutoExpand(targetAudienceTextarea);
                setTargetAudienceDescriptionValue(e.target.value);
              }}
              maxLength="2000"
              value={targetAudienceDescriptionValue}
            />
            <div />
          </div>
        </div>
      ) : null}
    </div>
  );
};
