import React from 'react';

import ConstraintTooltip from '../../../ConstraintTooltip/ConstraintTooltip';
import LayersIcon from '../../../../../Icon/custom/LayersIcon';

import {
  getNextQuestion,
  getNextNeigboingFlow
} from '../../../../../../helpers/getNeigboringFlows/getNeigboringFlows';
import isActionAllowed from '../../../../../../helpers/isActionAllowed/isActionAllowed';
import {
  QUESTION_DESIGNER_ACTION,
  QUESTION_TYPES
} from '../../../../../../helpers/constants';

import styles from './QuestionContainerActions.module.css';

export default ({ question, surveyLists, actions, surveyStructure }) => {
  const surveyListQuestion = surveyLists.find(sLQ => sLQ.id === question.id);

  if (
    surveyListQuestion &&
    ((surveyListQuestion.additionalInformation &&
      (surveyListQuestion.additionalInformation.lastGroupQuestion ||
        surveyListQuestion.additionalInformation.lastSetQuestion ||
        surveyListQuestion.additionalInformation.isNextRandomizer)) ||
      surveyListQuestion.type === QUESTION_TYPES.DISTRIBUTOR)
  ) {
    return null;
  }

  // Question inside distributor group should not show icons
  if (
    surveyListQuestion &&
    surveyListQuestion.group &&
    surveyListQuestion.group.jumpFromDistributor
  ) {
    return null;
  }

  const allowedActionButtonBetweenTwoQuestion = surveyListQuestion.group
    ? isActionAllowed(
        QUESTION_DESIGNER_ACTION.GROUP_UNGROUP,
        surveyListQuestion.group,
        null,
        surveyLists,
        surveyStructure
      )
    : isActionAllowed(
        QUESTION_DESIGNER_ACTION.NEST_INTO_PARENT_GROUP,
        surveyListQuestion,
        getNextQuestion(surveyListQuestion, surveyLists).id,
        surveyLists,
        surveyStructure
      );

  const onGroupWithNextClick = e => {
    e.stopPropagation();

    if (allowedActionButtonBetweenTwoQuestion.allowed) {
      if (surveyListQuestion.group) {
        if (surveyListQuestion.group && surveyListQuestion.group.flows) {
          const indexInGroup = surveyListQuestion.group.flows.findIndex(
            f => f === surveyListQuestion.id
          );
          actions.ungroup(surveyListQuestion.group.id, indexInGroup);
        }
      }
      if (!surveyListQuestion.group) {
        const destinationFlow = getNextNeigboingFlow(question, surveyLists);
        actions.nestFlows(null, question, destinationFlow);
      }
    }
  };

  const getLayerIconFill = () => {
    if (surveyListQuestion.group) {
      return allowedActionButtonBetweenTwoQuestion.allowed
        ? 'rgba(255, 255, 255, 1)'
        : 'rgba(0, 0, 0, 0.2)';
    }
    if (!surveyListQuestion.group) {
      return allowedActionButtonBetweenTwoQuestion.allowed
        ? undefined
        : 'rgba(0, 0, 0, 0.2)';
    }
    return undefined;
  };

  let containerStyle = allowedActionButtonBetweenTwoQuestion.allowed
    ? styles.addToParentIconGroupedContainer
    : `${styles.addToParentIconGroupedContainer} ${styles.disabled}`;
  containerStyle = surveyListQuestion.group
    ? containerStyle
    : styles.addToParentIconContainer;

  return (
    <>
      <div
        role="presentation"
        className={containerStyle}
        onClick={onGroupWithNextClick}
      >
        <LayersIcon
          fill={getLayerIconFill()}
          className={
            allowedActionButtonBetweenTwoQuestion.allowed
              ? styles.groupIcon
              : `${styles.groupIcon} ${styles.disabled}`
          }
        />
        <div className={styles.groupIconTooltipContainer}>
          {!allowedActionButtonBetweenTwoQuestion.allowed && (
            <ConstraintTooltip
              label={allowedActionButtonBetweenTwoQuestion.message}
            />
          )}
        </div>
      </div>
    </>
  );
};
