import React from 'react';

import GenderDynamicFiltersRow from './GenderDynamicFiltersRow';
import AdvancedFilterSettings from '../AdvancedFilterSettings/AdvancedFilterSettings';
import Popup from '../../../Popup/Popup';
import DynamicFiltersPropertiesDropdown from '../DynamicFiltersPropertiesDropdown/DynamicFiltersPropertiesDropdown';

import { DYNAMIC_FILTER_GROUPS } from '../../../SurveyBuilder/helpers/constants';

import ifDynamicFiltersNotUseFullRange from '../../helpers/ifDynamicFiltersNotUseFullRange/ifDynamicFiltersNotUseFullRange';

import exclamation from '../../../../../../assets/img/exclamation.svg';
import styles from '../../TargetAudience.module.css';

const getArraysIntersection = (a1, a2) => a1.filter(n => a2.indexOf(n) !== -1);

export default ({
  userFilter,
  onUserFilterChange,
  updateSurveySettings,
  filterSettings,
  maxResponses
}) => {
  const [
    showAdvancedSettingsDropdown,
    setShowAdvancedSettingsDropdown
  ] = React.useState(false);
  const [
    advancedSettingsDropdownRef,
    setAdvancedSettingsDropdownRef
  ] = React.useState(null);
  const [
    showAdvancedSettingsPopup,
    setShowAdvancedSettingsPopup
  ] = React.useState(false);

  const genderFilters =
    userFilter && userFilter.$and ? userFilter.$and.filter(v => v.gender) : [];

  const error = (() => {
    const overlapping = genderFilters.reduce((overlap, currentFilter, i) => {
      if (overlap) return overlap;

      return genderFilters.some((f, j) => {
        if (i === j) return false;

        if (!f.gender || !currentFilter.gender) return false;

        const ifHaveSomeSameElements = getArraysIntersection(
          currentFilter.gender.$in,
          f.gender.$in
        );

        if (ifHaveSomeSameElements && ifHaveSomeSameElements.length)
          return true;
        return false;
      });
    }, false);

    if (overlapping) {
      return 'You have overlapping gender ranges';
    }

    const atLeastHigherThanAtMost = genderFilters.some(f => {
      if (
        f &&
        f.$dynamic &&
        f.$dynamic.$max !== undefined &&
        f.$dynamic.$min !== undefined &&
        f.$dynamic.$min > f.$dynamic.$max
      ) {
        return true;
      }
      return false;
    });

    if (atLeastHigherThanAtMost) {
      return 'At least cannot exceed at most';
    }

    const atLeastHigherThanMaxResponses = genderFilters.some(f => {
      if (
        f &&
        f.$dynamic &&
        f.$dynamic.$min !== undefined &&
        ((f.$dynamic.$type === '%' && f.$dynamic.$min > 100) ||
          (maxResponses &&
            f.$dynamic.$type === '#' &&
            f.$dynamic.$min > maxResponses))
      ) {
        return true;
      }
      return false;
    });

    const appliedGenderFilters = genderFilters.filter(
      gF => gF.gender && gF.gender.$in && gF.gender.$in.length
    );

    const isAtLeastHigherThanFullRange = ifDynamicFiltersNotUseFullRange(
      appliedGenderFilters,
      maxResponses,
      '$min'
    );

    if (
      appliedGenderFilters.length &&
      (atLeastHigherThanMaxResponses || isAtLeastHigherThanFullRange)
    ) {
      return 'At least cannot exceed sample size';
    }

    const isFullRange = ifDynamicFiltersNotUseFullRange(
      appliedGenderFilters,
      maxResponses
    );

    if (!isFullRange) {
      return 'Gender filters aren‘t covering your full target audience.';
    }

    return null;
  })();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerTitle}>
          <span>Gender</span>
          <div className={styles.advancedSettingsContainer}>
            <div
              onClick={() =>
                setShowAdvancedSettingsDropdown(!showAdvancedSettingsDropdown)
              }
              role="presentation"
              className={`${
                styles.advancedSettingsButton
              } gender-dynamics-filter-toggle-advanced-settings-dropdown`}
            >
              ...
            </div>
            {showAdvancedSettingsDropdown && (
              <DynamicFiltersPropertiesDropdown
                userFilter={userFilter}
                filterGroup={DYNAMIC_FILTER_GROUPS.GENDER}
                advancedSettingsDropdownRef={advancedSettingsDropdownRef}
                setShowAdvancedSettingsDropdown={
                  setShowAdvancedSettingsDropdown
                }
                setAdvancedSettingsDropdownRef={setAdvancedSettingsDropdownRef}
                setShowAdvancedSettingsPopup={setShowAdvancedSettingsPopup}
                onUserFilterChange={onUserFilterChange}
                filterSettings={filterSettings}
              />
            )}
          </div>
        </div>
        {error && (
          <div className={styles.dynamicFilterWarnings}>
            <div className={styles.dynamicFilterWarningMessageBlock}>
              <span className={styles.warningMessage}>
                <img
                  className={styles.warningIcon}
                  src={exclamation}
                  alt="Warning"
                />
                <span>{error}</span>
              </span>
            </div>
          </div>
        )}
        <div className={styles.blockContainer}>
          {userFilter &&
            userFilter.$and &&
            userFilter.$and.map((f, i) => (
              <GenderDynamicFiltersRow
                key={`age-dynamic-filter-row-${i.toString()}`}
                filter={f}
                filterIndex={i}
                userFilter={userFilter}
                onUserFilterChange={onUserFilterChange}
                filterSettings={filterSettings}
                maxResponses={maxResponses}
              />
            ))}
          {!genderFilters ||
            (genderFilters && !genderFilters.length && (
              <div className={styles.noFiltersAvailable}>No active filters</div>
            ))}
        </div>
      </div>
      {showAdvancedSettingsPopup && (
        <Popup
          component={
            <AdvancedFilterSettings
              title="Advanced age settings"
              type={DYNAMIC_FILTER_GROUPS.GENDER}
              updateSurveySettings={updateSurveySettings}
              filterSettings={filterSettings}
              setShowAdvancedSettingsPopup={setShowAdvancedSettingsPopup}
              userFilter={userFilter}
              onUserFilterChange={onUserFilterChange}
              maxResponses={maxResponses}
            />
          }
          onClose={() => {
            setShowAdvancedSettingsPopup(false);
          }}
        />
      )}
    </>
  );
};
