import parse from 'html-react-parser';

export default (string, styles) => {
  if (!string) return '';

  let newString = string;

  newString = newString.replace(
    /\{PURPLE\}/g,
    `<span className=${styles.purple}>`
  );
  newString = newString.replace(/\{\/PURPLE\}/g, '</span>');
  newString = newString.replace(/\{BOLD\}/g, `<span className=${styles.bold}>`);
  newString = newString.replace(/\{\/BOLD\}/g, '</span>');
  return parse(newString);
};

export const removeStylingsFromString = string => {
  if (!string) return '';

  let newString = string;
  newString = newString.replace(/\{PURPLE\}/g, '');
  newString = newString.replace(/\{\/PURPLE\}/g, '');
  newString = newString.replace(/\{BOLD\}/g, '');
  newString = newString.replace(/\{\/BOLD\}/g, '');
  return parse(newString);
};
