import React, { useContext } from 'react';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import Footer from '../../Footer/Footer';

import {
  TEMPLATES_WIZARD_STEP_KEYS,
  TEMPLATES_WIZARD_TEMPLATE_TYPES
} from '../../../../../helpers/constants';

import styles from './DefinePoints.module.css';

export default () => {
  const {
    currentStep,
    goToNextStep,
    goToPreviousStep,
    steps,
    strings,
    setStepValue
  } = useContext(TemplatesWizardContext);

  const templateTypeStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
  );

  const templateStrings = strings[templateTypeStep.value];

  const testsNumber = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER
  );

  const problemContextStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SET_CONTEXT
  );

  const isDisabledNext = () => {
    if (!currentStep.value || !currentStep.value.length) {
      return true;
    }

    const emptyPoints = currentStep.value.filter(
      v => !v || v === '' || v.length === 0
    );
    if (emptyPoints && emptyPoints.length) {
      return true;
    }

    if (Object.keys(currentStep.value).length !== testsNumber.value) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>{templateStrings.DEFINE_YOUR}</div>
        <div className={styles.text}>
          {templateStrings.YOUR_INPUT_WILL_BE_USED}
        </div>
        {templateTypeStep.value ===
          TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION && (
          <div className={`${styles.text} ${styles.purple}`}>
            {templateStrings.SITUATION}
          </div>
        )}
        {templateTypeStep.value !==
          TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION && (
          <div className={styles.text}>
            {strings.PROBLEM_CONTEXT}: {problemContextStep.value}
          </div>
        )}
        <div className="inputWrapper">
          {[...Array(testsNumber.value).keys()].map((i, index) => (
            <div
              className={styles.inputContainer}
              key={`point-${index.toString()}`}
            >
              <div className={styles.label}>
                {templateStrings.POINT_INPUT_LABEL} {i + 1}
              </div>
              <textarea
                placeholder={templateStrings.WE_INSTALL_CORNER_EXAMPLE}
                onChange={e => {
                  const values = currentStep.value;
                  values[index] = e.target.value;

                  setStepValue(
                    TEMPLATES_WIZARD_STEP_KEYS.DEFINE_POINTS,
                    values
                  );
                }}
                value={currentStep.value[index] || ''}
              />
            </div>
          ))}
        </div>
        <div className={styles.text}>
          {templateStrings.NEED_HELP_CONTACT}{' '}
          <a href="mailto:operations@buffl.be">operations@buffl.be</a>{' '}
          {strings.FOR_HELP}
        </div>
        <Footer
          onNextClick={goToNextStep}
          onPreviousClick={goToPreviousStep}
          nextLabel={strings.NEXT}
          previousLabel={strings.PREVIOUS}
          disabledNext={isDisabledNext()}
        />
      </div>
    </div>
  );
};
