import React, { useEffect, useState } from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

import styles from './Message.module.css';

const Message = props => {
  const { block, setValid } = props;
  const [editorState, setEditorState] = useState(null);

  const styleMap = {
    BOLD: {
      fontFamily: 'Roboto Bold'
    }
  };

  const createEditorState = () => {
    if (
      block.question &&
      (block.question !== undefined || block.question !== '')
    ) {
      setValid(true);
      const parsedQuestion = JSON.parse(block.question);

      if (parsedQuestion.blocks > 1 || parsedQuestion.blocks[0].text !== '') {
        const contentState = convertFromRaw(parsedQuestion);
        return EditorState.createWithContent(contentState);
      }
    }

    return null;
  };

  useEffect(
    () => {
      setEditorState(createEditorState());
    },
    [block]
  );

  const RenderStyleControls = () => {
    if (editorState) {
      return (
        <div className={styles.DraftEditor}>
          <Editor
            editorState={editorState}
            readOnly="true"
            customStyleMap={styleMap}
          />
        </div>
      );
    }
    return block.type;
  };

  return <RenderStyleControls />;
};

export default Message;
