import React, { useState, useEffect, useContext } from 'react';

import SurveyBuilderPageContext from '../../../SurveyBuilderPageContext';

import Scheduler from './components/Scheduler';

import styles from './SurveyScheduler.module.css';

export const scheduleErrors = {
  pastDate: "You can't pick a date in the past.",
  pastTime: "You can't pick a time in the past.",
  unpublishMatch:
    'Your UNPUBLISHING date/time need to be after your PUBLISHING date.'
};

export const isDatePast = date =>
  new Date(date).getFullYear() <= new Date().getFullYear() &&
  new Date(date).getMonth() <= new Date().getMonth() &&
  new Date(date).getDate() < new Date().getDate();

export const isTimePast = date =>
  new Date(date).getFullYear() <= new Date().getFullYear() &&
  new Date(date).getMonth() <= new Date().getMonth() &&
  new Date(date).getDate() <= new Date().getDate() &&
  new Date(date).getHours() <= new Date().getHours() &&
  new Date(date).getMinutes() <= new Date().getMinutes();

export default ({ survey, setShowScheduler, setShowResultPopup }) => {
  const [publish, setPublish] = useState(false);
  const [publishDate, setPublishDate] = useState(null);
  const [unpublish, setUnpublish] = useState(false);
  const [unpublishDate, setUnpublishDate] = useState(null);

  const [publishDateError, setPublishDateError] = useState(null);
  const [publishTimeError, setPublishTimeError] = useState(null);
  const [unpublishDateError, setUnpublishDateError] = useState(null);
  const [unpublishTimeError, setUnpublishTimeError] = useState(null);

  const { api } = useContext(SurveyBuilderPageContext);

  const isScheduleButtonEnabled = () => {
    if (publish || unpublish) {
      if (publish && unpublish) {
        return (
          !publishDateError &&
          !publishTimeError &&
          !unpublishDateError &&
          !unpublishTimeError &&
          publishDate &&
          unpublishDate
        );
      }

      if (publish && !publishDateError && !publishTimeError && publishDate) {
        return true;
      }

      if (
        unpublish &&
        !unpublishDateError &&
        !unpublishTimeError &&
        unpublishDate
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(
    () => {
      if (publish && publishDate) {
        const isPublishDatePast = isDatePast(publishDate);
        setPublishDateError(isPublishDatePast ? scheduleErrors.pastDate : null);

        if (!isPublishDatePast) {
          const isPublishTimePast = isTimePast(publishDate);
          setPublishTimeError(
            isPublishTimePast ? scheduleErrors.pastTime : null
          );
        }
      }

      if (unpublish && unpublishDate) {
        const isUnpublishDatePast = isDatePast(unpublishDate);
        setUnpublishDateError(
          isUnpublishDatePast ? scheduleErrors.pastDate : null
        );

        if (!isUnpublishDatePast) {
          const isUnpublishTimePast = isTimePast(unpublishDate);
          setUnpublishTimeError(
            isUnpublishTimePast ? scheduleErrors.pastTime : null
          );
        }
      }

      if (publish && publishDate && unpublish && unpublishDate) {
        if (
          new Date(publishDate).getTime() >= new Date(unpublishDate).getTime()
        ) {
          setUnpublishDateError(scheduleErrors.unpublishMatch);
          setUnpublishTimeError(true);
        }
      }
    },
    [publish, publishDate, unpublish, unpublishDate]
  );

  return (
    <div className={styles.container}>
      <div className={styles.schedulerHeader}>
        <span className={styles.schedulerHeaderTitle}>Schedule survey</span>
        <span className={styles.schedulerHeaderExtraTitle}>ADMIN</span>
      </div>
      <div className={styles.schedulerActionsContainer}>
        <Scheduler
          actionLabel="PUBLISHING"
          toggle={publish}
          setToggle={setPublish}
          date={publishDate}
          setDate={setPublishDate}
          dateError={publishDateError}
          timeError={publishTimeError}
        />
        <div className={styles.middle} />
        <Scheduler
          actionLabel="UNPUBLISHING"
          toggle={unpublish}
          setToggle={setUnpublish}
          date={unpublishDate}
          setDate={setUnpublishDate}
          dateError={unpublishDateError}
          timeError={unpublishTimeError}
        />
      </div>
      <div className={styles.schedulerFooter}>
        <div
          className={`${styles.scheduleButton} ${
            isScheduleButtonEnabled() ? null : styles.scheduleButtonDisabled
          }`}
          role="presentation"
          onClick={() => {
            setShowResultPopup(true);
            setShowScheduler(false);

            if (publish) {
              api('scheduleSurvey', {
                variables: {
                  survey: survey.id,
                  action: 'PUBLISH_SURVEY',
                  time: new Date(publishDate)
                }
              });
            }

            if (unpublish) {
              api('scheduleSurvey', {
                variables: {
                  survey: survey.id,
                  action: 'UNPUBLISH_SURVEY',
                  time: new Date(unpublishDate)
                }
              });
            }
          }}
        >
          Schedule
        </div>
        <span
          className={styles.orPublishNowButton}
          onClick={() => {
            setShowScheduler(false);
            api('publishSurvey', {
              variables: {
                survey: survey.id,
                publish: true
              }
            });
          }}
          role="presentation"
        >
          Or publish your survey now
        </span>
      </div>
    </div>
  );
};
