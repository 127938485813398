export default filters =>
  filters && filters.length
    ? filters.map(filter => {
        if (filter.tags && filter.tags.quota) {
          const filterTags = { ...filter.tags };
          delete filterTags.quota;
          return {
            ...filter,
            tags: {
              ...filterTags
            }
          };
        }
        if (filter.userProperties && filter.userProperties.quota) {
          const filterUserProperties = { ...filter.userProperties };
          delete filterUserProperties.quota;
          return {
            ...filter,
            userProperties: {
              ...filterUserProperties
            }
          };
        }
        return filter;
      })
    : [];
