import React, { useState, useEffect } from 'react';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';

import DutchStrings from '../../../../../../../users/strings/nl';
import FrenchStrings from '../../../../../../../users/strings/fr';
import EnglishStrings from '../../../../../../../users/strings/en';
import GermanStrings from '../../../../../../../users/strings/de';

import CustomCopyField from './components/CustomCopyField';

import styles from './WebSurveyCustomCopy.module.css';

const surveyLanguageProperties = {
  English: 'EN',
  Dutch: 'NL',
  French: 'FR',
  German: 'DE'
};

export const getCustomCopyFieldValue = (
  key,
  surveyLanguage,
  customCopies,
  translations
) => {
  const value = customCopies.reduce((acc, curr) => {
    if (curr.translations && curr.translations.length) {
      curr.translations.forEach(t => {
        if (t.KEY === key && t[surveyLanguageProperties[surveyLanguage]]) {
          // eslint-disable-next-line no-param-reassign
          acc = t[surveyLanguageProperties[surveyLanguage]];
        }
      });
    }
    return acc;
  }, '');
  if (value) return value;
  return translations[key];
};

const WebSurveyCustomCopy = props => {
  const { survey, updateCustomCopies } = props;

  const customCopies =
    survey &&
    survey.customCopies &&
    survey.customCopies.length &&
    survey.language
      ? survey.customCopies
      : [];

  const getCustomCopyActiveProperty = blockName => {
    const property = customCopies.find(block => block.name === blockName);
    if (property) {
      return property.active;
    }
    return false;
  };

  const strings = {
    Dutch: DutchStrings,
    French: FrenchStrings,
    English: EnglishStrings,
    German: GermanStrings
  };

  const translations =
    survey && survey.language && strings[survey.language]
      ? strings[survey.language]
      : strings.Dutch;

  const [expanded, setExpanded] = useState(true);
  const [customCopyToggles, setCustomCopyToggles] = useState({
    welcome: (customCopies && getCustomCopyActiveProperty('welcome')) || false,
    socioDemo:
      (customCopies && getCustomCopyActiveProperty('socioDemo')) || false,
    intro: (customCopies && getCustomCopyActiveProperty('intro')) || false,
    completed:
      (customCopies && getCustomCopyActiveProperty('completed')) || false,
    notEligible:
      (customCopies && getCustomCopyActiveProperty('notEligible')) || false,
    notAvailable:
      (customCopies && getCustomCopyActiveProperty('notAvailable')) || false,
    askForEmailAddressInWebSurvey:
      (customCopies &&
        getCustomCopyActiveProperty('askForEmailAddressInWebSurvey')) ||
      false
  });

  const getFieldValue = key =>
    getCustomCopyFieldValue(key, survey.language, customCopies, translations);

  const translationFields = {
    WELCOME: getFieldValue('WELCOME'),
    GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS: getFieldValue(
      'GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS'
    ),
    YOU_ARE_NOT_JANE: getFieldValue('YOU_ARE_NOT_JANE'),
    SOCIO_DEMO: getFieldValue('SOCIO_DEMO'),
    INTRODUCTION: getFieldValue('INTRODUCTION'),
    THANK_YOU: getFieldValue('THANK_YOU'),
    COMPLETED: getFieldValue('COMPLETED'),
    UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE: getFieldValue(
      'UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE'
    ),
    DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED: getFieldValue(
      'DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED'
    ),
    THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE: getFieldValue(
      'THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE'
    ),
    DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE: getFieldValue(
      'DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE'
    ),
    YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY: getFieldValue(
      'YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY'
    ),
    UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE: getFieldValue(
      'UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE'
    ),
    UNFORTUNATELY_ON_FAILED_PAGE: getFieldValue('UNFORTUNATELY_ON_FAILED_PAGE'),
    YOU_ARE_NOT_ELIGIBLE: getFieldValue('YOU_ARE_NOT_ELIGIBLE'),
    THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE: getFieldValue(
      'THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE'
    ),
    WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS: getFieldValue(
      'WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS'
    )
  };

  const [customCopyFields, setCustomCopyFields] = useState({});

  useEffect(
    () => {
      if (survey && survey.language) {
        setCustomCopyFields(translationFields);
      }
    },
    [survey]
  );

  const properties = [
    {
      label: 'Welcome',
      name: 'welcome',
      active: customCopyToggles.welcome,
      visible: true,
      fields: [
        {
          name: 'WELCOME',
          value: customCopyFields.WELCOME
        },
        {
          name: 'GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS',
          value: customCopyFields.GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS
        }
      ]
    },
    {
      label: 'Socio demographics',
      name: 'socioDemo',
      active: customCopyToggles.socioDemo,
      visible: survey.askForSocioDemoWebSurvey,
      fields: [
        {
          name: 'YOU_ARE_NOT_JANE',
          value: customCopyFields.YOU_ARE_NOT_JANE
        },
        {
          name: 'SOCIO_DEMO',
          value: customCopyFields.SOCIO_DEMO
        }
      ]
    },
    {
      label: 'Introduction',
      name: 'intro',
      active: customCopyToggles.intro,
      visible: true,
      fields: [
        {
          name: 'INTRODUCTION',
          value: customCopyFields.INTRODUCTION
        }
      ]
    },
    {
      label: 'Describe why respondents should leave their email address',
      name: 'askForEmailAddressInWebSurvey',
      active: customCopyToggles.askForEmailAddressInWebSurvey,
      visible: true,
      fields: [
        {
          name:
            'WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS',
          value:
            customCopyFields.WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS
        }
      ]
    },
    {
      label: 'Thank you',
      name: 'completed',
      active: customCopyToggles.completed,
      visible: !(survey && survey.askForEmailAddressInWebSurvey),
      fields: [
        {
          name: 'THANK_YOU',
          value: customCopyFields.THANK_YOU
        },
        survey.hideDownloadBufflInWebSurvey
          ? {
              name: 'YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY',
              value: customCopyFields.YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY
            }
          : {
              name: 'COMPLETED',
              value: customCopyFields.COMPLETED
            }
      ]
    },
    {
      label: 'Not eligible',
      name: 'notEligible',
      active: customCopyToggles.notEligible,
      visible: true,
      fields: survey.hideDownloadBufflInWebSurvey
        ? [
            {
              name: 'UNFORTUNATELY_ON_FAILED_PAGE',
              value: customCopyFields.UNFORTUNATELY_ON_FAILED_PAGE
            },
            {
              name: 'YOU_ARE_NOT_ELIGIBLE',
              value: customCopyFields.YOU_ARE_NOT_ELIGIBLE
            }
          ]
        : [
            {
              name: 'UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE',
              value: customCopyFields.UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE
            },
            {
              name: 'DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED',
              value: customCopyFields.DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED
            }
          ]
    },
    {
      label: 'Survey no longer available',
      name: 'notAvailable',
      active: customCopyToggles.notAvailable,
      visible: true,
      fields: survey.hideDownloadBufflInWebSurvey
        ? [
            {
              name: 'UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE',
              value: customCopyFields.UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE
            },
            {
              name: 'THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE',
              value: customCopyFields.THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE
            }
          ]
        : [
            {
              name: 'THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE',
              value:
                customCopyFields.THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE
            },
            {
              name: 'DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE',
              value:
                customCopyFields.DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE
            }
          ]
    }
  ];

  const getActiveBlockProperty = (property, index) => {
    if (property.visible) {
      return (
        <div
          key={`properties-item-${index.toString()}`}
          className={styles.blockPropertyContainer}
        >
          <div className={styles.singleProperty}>
            <span>{property.label}</span>
            <span role="presentation" className={styles.switchContainer}>
              <label className={styles.switch} htmlFor="checkbox">
                <input type="checkbox" checked={property.active} readOnly />
                <span
                  className={`slider round survey-property-${property.name}`}
                  role="presentation"
                  onClick={() => {
                    const updatedToggleValue = !customCopyToggles[
                      property.name
                    ];
                    setCustomCopyToggles({
                      ...customCopyToggles,
                      [property.name]: updatedToggleValue
                    });
                    updateCustomCopies({
                      name: property.name,
                      active: updatedToggleValue
                    });
                  }}
                />
              </label>
            </span>
          </div>
          {property.active && property.fields && property.fields.length ? (
            <div className={styles.propertyFields}>
              {property.fields.map(field => (
                <CustomCopyField
                  propertyName={property.name}
                  field={field}
                  updateCustomCopies={updateCustomCopies}
                  customCopyFields={customCopyFields}
                  setCustomCopyFields={setCustomCopyFields}
                  language={survey.language}
                />
              ))}
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  if (survey)
    return (
      <div className={styles.container} role="presentation">
        <div
          className={styles.titleContainer}
          role="presentation"
          onClick={() => setExpanded(() => setExpanded(!expanded))}
        >
          <div className={styles.title}>Web Survey Custom Copy</div>
          <img
            src={arrowDownPurple}
            alt="ExpandColapse"
            className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
          />
        </div>
        {expanded ? (
          <div className={styles.contentContainer}>
            <div>
              <div className={styles.propertiesContentContainer}>
                {properties.map((property, index) =>
                  getActiveBlockProperty(property, index)
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  return null;
};

export default WebSurveyCustomCopy;
