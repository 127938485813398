import React from 'react';

import absoluteActive from '../../../../../../../../../../../../../../../assets/quota-toggle/absolute-active.svg';
import absoluteError from '../../../../../../../../../../../../../../../assets/quota-toggle/absolute-error.svg';
import absoluteInactive from '../../../../../../../../../../../../../../../assets/quota-toggle/absolute-inactive.svg';
import percentageActive from '../../../../../../../../../../../../../../../assets/quota-toggle/percentage-active.svg';
import percentageError from '../../../../../../../../../../../../../../../assets/quota-toggle/percentage-error.svg';
import percentageInactive from '../../../../../../../../../../../../../../../assets/quota-toggle/percentage-inactive.svg';

import styles from './ToggleSwitch.module.css';

export default ({ values, value, onClick, error, disabled }) => {
  const valueIndex = values.map(v => v.value).indexOf(value);

  /* eslint-disable */
  let toggleImage;
  if (valueIndex === 0) {
    // Absolute
    if (error) {
      toggleImage = absoluteError;
    } else if (disabled) {
      toggleImage = absoluteInactive;
    } else {
      toggleImage = absoluteActive;
    }
  } else {
    if (error) {
      toggleImage = percentageError;
    } else if (disabled) {
      toggleImage = percentageInactive;
    } else {
      toggleImage = percentageActive;
    }
  }
  /* eslint-enable */

  return (
    <div className={styles.container} onClick={onClick} role="presentation">
      <img src={toggleImage} alt="Quota toggle" />
    </div>
  );
};
