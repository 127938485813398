import React from 'react';

import ProfilingTagsFilter from '../ProfilingTagsFilter/ProfilingTagsFilter';
import UserPropertiesFilter from '../UserPropertiesFilter/UserPropertiesFilter';
import CommunityTagsFilter from '../CommunityTagsFilter/CommunityTagsFilter';
import FiltersRelation from '../FiltersRelation/FiltersRelation';

import {
  COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES,
  COMMUNITY_DASHBOARD_FILTER_TYPE,
  COMMUNITY_MANAGEMENT_TYPES
} from '../../../../../../helpers/constants';

import styles from './SingleFilter.module.css';
import AcquisitionFilter from '../AcquisitionFilter/AcquisitionFilter';

export default ({
  singleFilterIndex,
  appliedFilters,
  setAppliedFilters,
  filterRelation,
  setFilterRelation,
  userPropertiesMapping,
  showQuota,
  isFeasibilityChecker,
  customHideFilterTypes = []
}) => {
  const singleFilter = appliedFilters[singleFilterIndex];

  const onTypeChange = value => {
    const aFilters = appliedFilters;
    const preparedAppliedFilter = {};

    preparedAppliedFilter.type = value;

    if (value === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG) {
      preparedAppliedFilter.tags = {};
    }

    if (value === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY) {
      preparedAppliedFilter.userProperties = {};
    }

    if (value === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY) {
      preparedAppliedFilter.community = {};
    }

    if (value === COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION) {
      preparedAppliedFilter.acquisition = {};
    }

    aFilters[singleFilterIndex] = preparedAppliedFilter;
    setAppliedFilters([...aFilters]);
  };

  const onFilterSelectCompleted = (
    property,
    value,
    subPath,
    isMultipleSelect
  ) => {
    const aFilters = appliedFilters;
    const preparedAppliedFilter = { ...singleFilter };

    if (
      preparedAppliedFilter &&
      preparedAppliedFilter.type ===
        COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY &&
      property === 'propertyName'
    ) {
      preparedAppliedFilter[subPath].propertyValue = null;
    }

    if (
      preparedAppliedFilter &&
      preparedAppliedFilter.type ===
        COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY &&
      property === 'communityType'
    ) {
      preparedAppliedFilter[subPath].name = null;
      preparedAppliedFilter[subPath].label = null;
    }

    if (!isMultipleSelect) {
      if (value) {
        preparedAppliedFilter[subPath][property] = value;
      } else {
        preparedAppliedFilter[subPath][property] = null;
      }
    }
    if (isMultipleSelect) {
      const isExistingValue =
        preparedAppliedFilter &&
        preparedAppliedFilter[subPath] &&
        preparedAppliedFilter[subPath][property] &&
        Array.isArray(preparedAppliedFilter[subPath][property]) &&
        preparedAppliedFilter[subPath][property].indexOf(value) > -1;

      if (!isExistingValue) {
        if (
          !preparedAppliedFilter[subPath][property] ||
          !Array.isArray(preparedAppliedFilter[subPath][property])
        ) {
          preparedAppliedFilter[subPath][property] = [value];
        } else {
          preparedAppliedFilter[subPath][property] = [
            ...preparedAppliedFilter[subPath][property],
            value
          ];
        }
      } else {
        const removedArrayValue = preparedAppliedFilter[subPath][
          property
        ].filter(v => v !== value);
        preparedAppliedFilter[subPath][property] = removedArrayValue;
      }
    }

    aFilters[singleFilterIndex] = preparedAppliedFilter;
    setAppliedFilters([...aFilters]);
  };

  const onSelectAllClick = async (
    property,
    values,
    subPath,
    allValuesSelected,
    searchProfilingTagValues
  ) => {
    const aFilters = appliedFilters;
    const preparedAppliedFilter = { ...singleFilter };

    if (allValuesSelected) {
      preparedAppliedFilter[subPath][property] = [];
    } else if ((values && values.length < 10) || property === 'propertyValue') {
      const selectedValues = values.map(v => v.value);
      preparedAppliedFilter[subPath][property] = selectedValues;
    } else {
      const fetchedOptions = await searchProfilingTagValues(3, '');
      const newFetchedOptions =
        fetchedOptions && fetchedOptions.length
          ? fetchedOptions.map(v => v.value)
          : [];
      preparedAppliedFilter[subPath][property] = newFetchedOptions;
    }

    aFilters[singleFilterIndex] = preparedAppliedFilter;
    setAppliedFilters([...aFilters]);
  };

  const getTypeOptions = () =>
    [
      {
        label:
          COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[
            COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG
          ],
        value: COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG
      },
      {
        label:
          COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[
            COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY
          ],
        value: COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY
      },
      {
        label:
          COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[
            COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY
          ],
        value: COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY
      },
      {
        label:
          COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[
            COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION
          ],
        value: COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION
      }
    ].filter(type => !customHideFilterTypes.includes(type.value));

  const disabledFilterRelation = () => {
    if (
      appliedFilters &&
      appliedFilters.length &&
      appliedFilters.some(
        f =>
          f.type &&
          f.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY &&
          f.community &&
          f.community.communityType &&
          f.community.communityType === COMMUNITY_MANAGEMENT_TYPES.DYNAMIC &&
          f.community.name &&
          f.community.name.length
      )
    ) {
      return {
        disabled: true,
        message:
          'It’s not possible to change the filter relationship when a dynamic community is selected. The filter relationship must match the one used within the dynamic commmunity.'
      };
    }
    return {
      disabled: false
    };
  };

  let singleFilterComponents = null;

  if (singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG) {
    singleFilterComponents = (
      <ProfilingTagsFilter
        singleFilterIndex={singleFilterIndex}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        onTypeChange={onTypeChange}
        onFilterSelectCompleted={onFilterSelectCompleted}
        getTypeOptions={getTypeOptions}
        showQuota={showQuota}
        onSelectAllClick={onSelectAllClick}
      />
    );
  }

  if (singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY) {
    singleFilterComponents = (
      <UserPropertiesFilter
        singleFilterIndex={singleFilterIndex}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        onTypeChange={onTypeChange}
        onFilterSelectCompleted={onFilterSelectCompleted}
        getTypeOptions={getTypeOptions}
        userPropertiesMapping={userPropertiesMapping}
        showQuota={showQuota}
        isFeasibilityChecker={isFeasibilityChecker}
        onSelectAllClick={onSelectAllClick}
      />
    );
  }

  if (singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY) {
    singleFilterComponents = (
      <CommunityTagsFilter
        singleFilterIndex={singleFilterIndex}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        onTypeChange={onTypeChange}
        onFilterSelectCompleted={onFilterSelectCompleted}
        getTypeOptions={getTypeOptions}
        onSelectAllClick={onSelectAllClick}
        filterRelation={filterRelation}
        setFilterRelation={setFilterRelation}
      />
    );
  }

  if (singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION) {
    singleFilterComponents = (
      <AcquisitionFilter
        singleFilterIndex={singleFilterIndex}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        onTypeChange={onTypeChange}
        onFilterSelectCompleted={onFilterSelectCompleted}
        getTypeOptions={getTypeOptions}
        onSelectAllClick={onSelectAllClick}
      />
    );
  }

  return (
    <div className={styles.singleFilter}>
      {singleFilterComponents}
      {singleFilterIndex < appliedFilters.length - 1 ? (
        <FiltersRelation
          filterRelation={filterRelation}
          setFilterRelation={newRelation => {
            // Remove selected dynamic community if filterRelation changed
            if (
              singleFilter &&
              singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY &&
              singleFilter.community &&
              singleFilter.community.communityType ===
                COMMUNITY_MANAGEMENT_TYPES.DYNAMIC
            ) {
              const aFilters = appliedFilters;
              const preparedAppliedFilter = { ...singleFilter };

              preparedAppliedFilter.community.name = null;
              aFilters[singleFilterIndex] = preparedAppliedFilter;
              setAppliedFilters([...aFilters]);
            }
            setFilterRelation(newRelation);
          }}
          disabledRelation={disabledFilterRelation()}
        />
      ) : null}
    </div>
  );
};
