import { connect } from 'react-redux';
import * as actions from '../actions';
// import { logout } from "../../auth/actions";
import CampaignQuestionsPage from '../components/Campaigns/CampaignQuestions/CampaignQuestionsPage';

const mapStateToProps = state => ({
  campaign: state.userCampaigns.campaign,
  block: state.userCampaigns.block,
  result: state.userCampaigns.result,
  userEmailSuggestion: state.userCampaigns.userEmailSuggestion
});

const mapDispatchToProps = dispatch => ({
  setCampaignComponents: (campaign, block, result, userEmailSuggestion) =>
    dispatch(
      actions.setCampaignComponents(
        campaign,
        block,
        result,
        userEmailSuggestion
      )
    ),
  setUserEmailSuggestion: userEmailSuggestion =>
    dispatch(actions.setUserEmailSuggestion(userEmailSuggestion))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignQuestionsPage);
