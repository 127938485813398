import React, { useState, useEffect, Fragment } from 'react';
// import BlockDestinationPosition from './BlockDestinationPosition/BlockDestinationPosition';
import boldIcon from '../../../../../../../../../../assets/img/bold.svg';
import itcalicIcon from '../../../../../../../../../../assets/img/italic.svg';
import underlineIcon from '../../../../../../../../../../assets/img/underline.svg';
import smileIcon from '../../../../../../../../../../assets/img/smile.svg';
import EmojiCategories from './components/EmojiCategories/EmojiCategories';

import './RichTextAction.css';
import ShowInsideScreen from '../../../../../../../ShowInsideScreen/ShowInsideScreen';

export default ({ action, isActiveQuestion }) => {
  const [showPropertiesComponent, setShowPropertiesComponent] = useState(false);
  const [actionCounter, setActionCounter] = useState(0);
  const [triggerDraftChange, setTriggerDraftChange] = useState(
    `${action.style}.${actionCounter}`
  );
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiPickerRef, setEmojiPickerRef] = useState(null);

  useEffect(
    () => {
      setShowEmojiPicker(false);
    },
    [isActiveQuestion]
  );

  const renderIcon = () => {
    let icon;
    if (action.label === 'B') {
      icon = <img src={boldIcon} alt="B" className="svg-text-icon" />;
    } else if (action.label === 'I') {
      icon = <img src={itcalicIcon} alt="I" className="svg-text-icon" />;
    } else if (action.label === 'U') {
      icon = <img src={underlineIcon} alt="U" className="svg-text-icon" />;
    } else if (action.label === 'E') {
      icon = <img src={smileIcon} alt="😀" className="svg-text-icon-bigger" />;
    }
    return icon;
  };

  const renderEmojis = () => (
    <ShowInsideScreen
      positionStyle={{
        bottomPosition: '35px',
        top: 40
      }}
      footerHeight={0}
      childReference={emojiPickerRef}
      onBlur={() => setShowEmojiPicker(false)}
    >
      <div ref={r => setEmojiPickerRef(r)} className="emoji-picker">
        {EmojiCategories.map(category => (
          <Fragment key={category.title}>
            <span className="emoji-category">{category.title}</span>
            {category.emojis.map(emoji => {
              if (emoji !== '️') {
                return (
                  <span
                    key={emoji}
                    role="presentation"
                    className="emoji"
                    onMouseDown={e => {
                      e.preventDefault();
                      action.action(emoji);
                    }}
                  >
                    {emoji}
                  </span>
                );
              }
              return null;
            })}
          </Fragment>
        ))}
      </div>
    </ShowInsideScreen>
  );

  const renderButtons = () => {
    if (action.action) {
      if (action.type === 'rich-text-emoji') {
        return (
          <div
            role="presentation"
            className={`text-editor-action-button ${
              showEmojiPicker ? 'picker-active' : ''
            }`}
            onMouseDown={e => {
              e.preventDefault();
              if (action.action) {
                setShowEmojiPicker(!showEmojiPicker);
              }
              if (action.propertiesComponent) {
                if (!showPropertiesComponent) {
                  setShowPropertiesComponent(true);
                } else {
                  setShowPropertiesComponent(false);
                }
              }
            }}
          >
            {renderIcon()}
          </div>
        );
      }
    }
    return (
      <div
        role="presentation"
        className="text-editor-action-button"
        onMouseDown={e => {
          e.preventDefault();

          if (action.action) {
            setActionCounter(actionCounter + 1);
            action.action(action.style, triggerDraftChange); // toggleDraftButton
            setTriggerDraftChange(`${action.style}.${actionCounter}`);
          }

          if (action.propertiesComponent) {
            if (!showPropertiesComponent) {
              setShowPropertiesComponent(true);
            } else {
              setShowPropertiesComponent(false);
            }
          }
        }}
      >
        {renderIcon()}
      </div>
    );
  };

  return (
    <div
      className={`text-editor-action ${
        action.type !== 'rich-text-emoji' ? false : '' // action.active : ''
      }`}
    >
      {!showPropertiesComponent ? renderButtons() : null}
      {showEmojiPicker ? renderEmojis() : null}
    </div>
  );
};
