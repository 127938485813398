import { select } from 'redux-saga/effects';

export default function*(action) {
  const surveyState = yield select(state => state.surveys);

  const toUpdate = {
    [action.property]: surveyState.survey[action.property]
  };

  action.api('updateSurvey', {
    variables: { survey: surveyState.survey.id, ...toUpdate }
  });
}
