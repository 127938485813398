import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported
import Icon from '../../Icon';

const QuestionThumbnail = props => {
  const { block, path, setVideoPopup } = props;

  const [isOpen, setIsOpen] = useState(false);

  const thumbnailPreview = type => {
    if (type === 'show-video') {
      return [
        <div
          className="video-file-preview"
          role="presentation"
          onClick={() => {
            setVideoPopup(block[path]);
          }}
          target="new"
          key={`${block.id}-videppreview`}
        >
          <Icon type="show-video" />
        </div>
      ];
    }

    if (type !== 'show-video') {
      return (
        <div
          role="presentation"
          className="cover-image-preview"
          style={{
            backgroundImage: `url(/media/${block[path]})`
          }}
          onClick={() => {
            setIsOpen(true);
          }}
          key={`${block.id}-thumbnail`}
        />
      );
    }

    return null;
  };

  return (
    <div className="media-upload">
      {block && block[path]
        ? [
            thumbnailPreview(block.name),
            isOpen && (
              <Lightbox
                mainSrc={`/media/${block[path]}`}
                onCloseRequest={() => setIsOpen(false)}
                key={`${block.id}-lightbox`}
              />
            )
          ]
        : null}
    </div>
  );
};

export default QuestionThumbnail;
