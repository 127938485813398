import React, { useState } from 'react';

import { FILTER_RELATIONS } from '../../helpers/constants';

import AppBar from '../../../../base/components/AppBar/AppBar';
import Button from '../../../../campaigns/components/Button/Button';
import Filters from './components/Filters/Filters';
import MainPageCharts from './components/MainPageCharts/MainPageCharts';

import styles from './CommunityDashboardPage.module.css';

export default props => {
  const { isAdmin, history } = props;
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filterRelation, setFilterRelation] = useState(FILTER_RELATIONS.AND);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  if (!isAdmin) return null;

  return (
    <div>
      <AppBar {...props} />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Community dashboard</div>
          <div className={styles.rightContainer}>
            <Button
              role="button"
              label="Manage communities"
              onClick={() => history.push('/community-management')}
              icon=""
            />
          </div>
        </div>
        <Filters
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          filterRelation={filterRelation}
          setFilterRelation={setFilterRelation}
          isGlobalLoading={isGlobalLoading}
        />
        <MainPageCharts
          appliedFilters={appliedFilters}
          filterRelation={filterRelation}
          isGlobalLoading={isGlobalLoading}
          setIsGlobalLoading={setIsGlobalLoading}
        />
      </div>
    </div>
  );
};
