import React, { useState } from 'react';

import LayersIcon from '../../../../../Icon/custom/LayersIcon';
import Icon from '../../../../../Icon/Icon';

import styles from './GroupHeader.module.css';

const GroupName = ({ setGroupNameRef, group, actions }) => {
  const [changeName, setChangeName] = useState(false);

  return (
    <input
      ref={r => setGroupNameRef(r)}
      className={`${styles.groupName} ${
        changeName ? styles.changeGroupName : styles.blurGroupName
      }`}
      value={group.label}
      size={group.label ? group.label.length : 0}
      onChange={e =>
        actions.updateGroupProperty(group.id, 'label', e.target.value)
      }
      onFocus={e => {
        setChangeName(true);
        e.target.select();
      }}
      onBlur={e => {
        e.target.blur();
        e.target.selectionStart = 0;
        e.target.selectionEnd = 0;
        setChangeName(false);

        //
      }}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === 'Tab') {
          e.preventDefault();
          e.target.blur();
        }
      }}
    />
  );
};

export default ({
  setGroupNameRef,
  group,
  isActiveQuestion,
  actions,
  hideGroupHeaderActions
}) => (
  <div
    className={
      hideGroupHeaderActions ? styles.groupHeaderNoActions : styles.groupHeader
    }
  >
    <div className={styles.groupNameContainer}>
      <div className={styles.groupIcon}>
        <LayersIcon fill="#FFFFFF" width={12} height={12} paddingTop={2} />
      </div>
      <GroupName
        setGroupNameRef={setGroupNameRef}
        group={group}
        actions={actions}
      />
    </div>
    <div
      className={styles.groupSettingsButton}
      role="presentation"
      onClick={() => !isActiveQuestion && actions.setActiveFlow(group)}
    >
      <Icon type="settings-icon-clear" className={styles.settingsIcon} />
    </div>
  </div>
);
