import { COLORS } from '../constants';

export default (appliedFilters, values, title, minimumQuotaEnabled) => {
  let deltaValues = [];

  if (minimumQuotaEnabled) {
    let matchingFilter = null;

    const matchingFilters =
      appliedFilters && appliedFilters.length
        ? appliedFilters.filter(filter => {
            const isTagFilter =
              filter.tags &&
              filter.tags.tagName &&
              filter.tags.tagName.includes(title) &&
              filter.tags.quota;

            const isUserPropertyFilter =
              filter.userProperties &&
              filter.userProperties.propertyName &&
              filter.userProperties.propertyName
                .replace(/_/g, ' ')
                .includes(title.toUpperCase()) &&
              filter.userProperties.quota &&
              filter.userProperties.quota.length;

            return isTagFilter || isUserPropertyFilter;
          })
        : null;

    if (matchingFilters && matchingFilters.length) {
      if (matchingFilters.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        matchingFilter = matchingFilters[0];
      } else {
        matchingFilter = matchingFilters.reduce((acc, curr) => {
          let path = 'tags';
          let valuePath = 'tagValue';
          if (curr.userProperties) {
            path = 'userProperties';
            valuePath = 'propertyValue';
          }

          return {
            ...curr,
            [path]: {
              ...curr[path],
              [valuePath]:
                acc[path] && acc[path][valuePath]
                  ? [...acc[path][valuePath], ...curr[path][valuePath]]
                  : curr[path][valuePath],
              quota:
                acc[path] && acc[path].quota
                  ? [...acc[path].quota, ...curr[path].quota]
                  : curr[path].quota
            }
          };
        }, {});
      }
    }

    if (matchingFilter) {
      const activeQuotas = matchingFilter.tags
        ? matchingFilter.tags.quota.filter(quota =>
            matchingFilter.tags.tagValue.includes(quota.key)
          )
        : matchingFilter.userProperties.quota.filter(quota =>
            matchingFilter.userProperties.propertyValue.includes(quota.key)
          );

      if (activeQuotas && activeQuotas.length) {
        deltaValues = values.map(v => {
          const quotaValue = activeQuotas.find(q => q.label === v.xValue);

          return {
            xValue: v.xValue,
            yValue: (quotaValue && quotaValue.value) || null,
            ...(quotaValue && quotaValue.value
              ? { quotaValue: quotaValue.value }
              : {}),
            ...(quotaValue && quotaValue.value ? { color: COLORS.BLUE } : {})
          };
        });
      }
    }
  }

  return { title, deltaValues };
};
