import { put, select } from 'redux-saga/effects'; // call,

import * as actions from '../../actions';
import addQuestion from './addQuestion';
import getSurveyQuestionIdsList from '../../../helpers/getSurveyQuestionIdsList';

import updateDecisionFramework from '../updateDecisionFramework/updateDecisionFramework';
import executeRequestsInSequence from '../../helpers/executeRequestsInSequence';
import surveyPermissionsCharge from '../../../helpers/surveyPermissionsCharge';

const ObjectID = require('bson-objectid');

export default function*(action) {
  const surveysState = yield select(state => state.surveys);
  const authState = yield select(state => state.auth);

  let newState = { ...surveysState };
  let frameworkRequests = [];

  if (surveysState.activeFlow && !surveysState.activeFlow.flows) {
    const newSurveyStateActiveFlow = {
      question: { ...surveysState.activeFlow }
    };
    action.api('updateQuestion', newSurveyStateActiveFlow);

    if (
      surveysState.activeFlow.matrix &&
      surveysState.activeFlow.matrix.decisionFrameworkType
    ) {
      const {
        surveyWithUpdatedFramework,
        updateRequest
      } = updateDecisionFramework(surveysState.survey, surveysState.activeFlow);
      if (surveyWithUpdatedFramework) {
        newState = {
          ...newState,
          survey: surveyWithUpdatedFramework
        };
        if (updateRequest) {
          frameworkRequests = [updateRequest];
        }
      }
    }
  }

  const objectId = ObjectID().toString();

  const questionId = objectId;
  newState = addQuestion(
    newState,
    action.newQuestionType,
    action.dropzone,
    action.destinationSetIndex,
    action.destionationQuestionindex,
    questionId,
    action.addQuestionsListClick
  );

  if (!newState) {
    return;
  }

  // Prevent difference between content and questions list
  const contentQuestions = getSurveyQuestionIdsList(
    newState.newState.survey.content
  );
  if (contentQuestions.indexOf(newState.newQuestion.id) === -1) {
    return;
  }

  if (
    (newState.requests && newState.requests.length) ||
    frameworkRequests.length
  ) {
    frameworkRequests = [
      ...frameworkRequests,
      ...(newState.requests && newState.requests.length
        ? newState.requests
        : [])
    ];
    yield executeRequestsInSequence(action.api, frameworkRequests);
  }

  // Account management warnings
  const newPermissionsStateData = surveyPermissionsCharge(authState, newState);
  if (newPermissionsStateData) {
    newState.newState = {
      ...newState.newState,
      ...newPermissionsStateData
    };
  }

  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  // Add new question
  action.api('addQuestions', {
    questionsArray: [newState.newQuestion],
    surveyContent: newState.newState.survey.content
  });
}
