import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from '@apollo/react-hooks';
import FileSaver from 'file-saver';

import { GET_COMMUNITIES } from '../../../../graphql/CommunityManagement';

import AppBar from '../../../../base/components/AppBar/AppBar';
import CommunityManagementHeader from './components/CommunityManagementHeader/CommunityManagementHeader';
import CommunityAutomationsRecordLoader from '../../../CommunityAutomations/components/CommunityAutomationsListPage/components/CommunityAutomationsRecordLoader/CommunityAutomationsRecordLoader';
import CommunityManagementRecord from './components/CommunityManagementRecord/CommunityManagementRecord';
import RouteTitle from '../RouteTitle/RouteTitle';
import restApi from '../../../../api';

import styles from './CommunityManagementListPage.module.css';

export default props => {
  const { history, recordsPerPage } = props;

  const [filtering, setFiltering] = useState({
    page: 0,
    search: ''
  });
  const [error, setError] = useState(null);

  const recordVariables = {
    page: filtering.page,
    search: filtering.search,
    recordsPerPage
  };

  const {
    data: { getCommunities: { records = [], totalCount } = {} } = {},
    refetch,
    loading: getCommunitiesLoading
  } = useQuery(GET_COMMUNITIES, {
    variables: recordVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const routeTitle = <RouteTitle history={history} />;

  const exportUsers = async communities => {
    if (communities) {
      const res = await restApi().exportCommunityUsers(communities);
      if (res && res.blob && !res.blob.error) {
        FileSaver.saveAs(
          res.blob,
          `community-export-${communities.toLowerCase()}.csv`,
          {
            autoBom: true
          }
        );
        setError(null);
      } else if (res && (res.error || (res.blob && res.blob.error))) {
        setError(
          res.error ||
            (res.blob && res.blob.error) ||
            'Something went wrong when exporting community'
        );
      }
    } else {
      setError('No community to export selected');
    }
  };

  return (
    <div>
      <AppBar
        {...props}
        appBarProperties={{
          routeTitle
        }}
      />
      <div className={styles.container}>
        <CommunityManagementHeader
          title="Communities"
          onAddButtonLabel="New community"
          onAddClick={() => history.push(`/community-management/new`)}
        />
        {error && error.length ? (
          <div className={styles.errorsContainer}>
            <div className={styles.error}>{error}</div>
          </div>
        ) : null}
        {getCommunitiesLoading ? <CommunityAutomationsRecordLoader /> : null}
        {!getCommunitiesLoading ? (
          <>
            <table
              border="0"
              cellSpacing="0"
              cellPadding="0"
              className={styles.mainTable}
            >
              <thead>
                <tr className={styles.headerRow}>
                  <th className={`${styles.typeColumn} ${styles.firstColumn}`}>
                    Community type
                  </th>
                  <th className={styles.nameColumn}>Community name</th>
                  <th className={styles.createdAtColumn}>Created</th>
                  <th />
                </tr>
              </thead>
              {records && records.length ? (
                <tbody>
                  {records.map((r, i) => (
                    <CommunityManagementRecord
                      record={r}
                      key={`community-record-${i.toString()}`}
                      history={history}
                      refetch={refetch}
                      exportUsers={exportUsers}
                    />
                  ))}
                </tbody>
              ) : null}
            </table>
            {!records || !records.length ? (
              <div className={styles.noResultsPlaceholder}>No communities</div>
            ) : null}
          </>
        ) : null}
        {recordsPerPage && records && records.length ? (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={totalCount ? Math.ceil(totalCount / recordsPerPage) : 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            initialPage={filtering.page}
            forcePage={filtering.page}
            disableInitialCallback
            onPageChange={pageNumber =>
              setFiltering({ ...filtering, page: pageNumber.selected })
            }
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};
