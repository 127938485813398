import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported
import Icon from '../../../../../Icon/Icon';
import styles from './QuestionThumbnail.module.css';

const QuestionThumbnail = props => {
  const { question, path, setVideoPopup } = props;

  const [isOpen, setIsOpen] = useState(false);

  const fullPath = question[path];

  const thumbnailPreview = type => {
    if (type === 'video') {
      return [
        <div
          className="video-file-preview"
          role="presentation"
          onClick={() => {
            setVideoPopup(fullPath);
          }}
          target="new"
          key={`${question.id}-videopreview`}
        >
          <Icon type="play_video_white" />
        </div>
      ];
    }

    if (type !== 'video') {
      return (
        <div
          role="presentation"
          className="cover-image-preview"
          style={{
            backgroundImage: `url(${fullPath})`,
            left: -20
          }}
          onClick={() => {
            setIsOpen(true);
          }}
          key={`${question.id}-thumbnail`}
        />
      );
    }

    return null;
  };

  return (
    <div className={styles.meduaUpload}>
      {question && question[path]
        ? [
            thumbnailPreview(question.type),
            isOpen && (
              <Lightbox
                mainSrc={`${fullPath}`}
                onCloseRequest={() => setIsOpen(false)}
                key={`${question.id}-lightbox`}
              />
            )
          ]
        : null}
    </div>
  );
};

export default QuestionThumbnail;
