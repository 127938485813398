import React from 'react';

import Dropdown from '../Dropdown/Dropdown';
import Toggle from '../../../../../Toggle/Toggle';

import closeIcon from '../../../../../../../../../../../assets/img/delete.svg';

import styles from './PopulationRow.module.css';

const PopulationRow = ({
  labels,
  setLabels,
  label,
  labelIndex,
  changeLabelValue,
  availableLabels,
  blocks,
  inverse
}) => {
  const onRemoveLabelClick = () => {
    let newLabels;
    if (label.name === 'answer' && (labelIndex || labelIndex === 0)) {
      newLabels = labels.filter((l, i) => i !== labelIndex);
    } else {
      newLabels = labels.filter(l => l.name !== label.name);
    }
    setLabels(newLabels);
  };

  const getNotUsedLabels = () => {
    const userLabels = labels.map(l => l.name);
    const notUsedLabels = availableLabels.filter(
      availableLabel =>
        userLabels.indexOf(availableLabel.name) === -1 ||
        availableLabel.name === 'answer'
    );
    return notUsedLabels.map(notUsedLabel => ({
      value: notUsedLabel.name,
      label: notUsedLabel.label
    }));
  };

  const populationNameChange = name => {
    if (name && name.length) {
      const labelsList = labels;
      const index = labels.findIndex(x => x.name === label.name);
      const labelToAdd = availableLabels.filter(
        al => al.name === name[0].value
      );
      if (labelToAdd && labelToAdd.length) {
        labelsList[index] = {
          name: labelToAdd[0].name,
          label: labelToAdd[0].label,
          type: labelToAdd[0].type,
          active: true,
          inverse
        };
        setLabels(JSON.parse(JSON.stringify(labels)));
      }
    }
  };

  const searchBlock = blockId => {
    let found = false;
    labels.forEach(findLabel => {
      if (
        findLabel &&
        findLabel.block &&
        findLabel.block.value &&
        findLabel.block.value === blockId
      ) {
        found = true;
      }
    });
    return found;
  };

  const getOptions = (singleLabel, parentField) => {
    if (singleLabel.name === 'gender' || singleLabel.name === 'occupation') {
      const availableSingleLabel = availableLabels.filter(
        al => al.name === singleLabel.name
      );
      if (
        availableSingleLabel &&
        availableSingleLabel.length &&
        availableSingleLabel[0].options
      ) {
        return availableSingleLabel[0].options;
      }
    }
    if (singleLabel.name === 'answer') {
      if (parentField) {
        const blockId =
          singleLabel && singleLabel[parentField]
            ? singleLabel[parentField].value
            : null;
        const block = blocks.filter(b => b.id === blockId);
        if (block && block[0]) {
          return block[0].answers.map((answer, answerIndex) => ({
            label: answer && answer.answer,
            value: answerIndex.toString()
          }));
        }
      }
      if (!parentField) {
        const availableBlocks = [];
        blocks.forEach(block => {
          if (!searchBlock(block.id)) {
            availableBlocks.push({
              label: block && block.question,
              value: block && block.id
            });
          }
        });
        return availableBlocks;
      }
    }
    return [];
  };

  if (label.type === 'range') {
    return (
      <div className={styles.populationRow}>
        <div className={styles.toggleContainer}>
          <Toggle
            checked={label.active}
            onToggle={() =>
              changeLabelValue('active', !label.active, labelIndex)
            }
          />
        </div>
        <div
          className={`${
            styles.typeContainer
          } workspaces-population-row-type-dropdown-container`}
        >
          <Dropdown
            options={getNotUsedLabels()}
            selectedOptions={[label]}
            onSelectCompleted={populationNameChange}
            inverse={inverse}
          />
        </div>
        <div
          className={`${
            styles.valueContainer
          } workspaces-population-row-range-value-container`}
        >
          Min
          <input
            className={`${
              styles.textInput
            } workspaces-population-row-range-min-value`}
            onChange={e =>
              changeLabelValue(
                'gte',
                parseInt(e.currentTarget.value, 10),
                labelIndex
              )
            }
            value={label.gte || ''}
            type="number"
            onKeyDown={evt =>
              evt.key === 'ArrowDown' ||
              evt.key === 'ArrowUp' ||
              evt.key === 'e' ||
              evt.key === 'E' ||
              evt.key === '.' ||
              evt.key === '-'
                ? evt.preventDefault()
                : null
            }
          />
          Max
          <input
            className={`${
              styles.textInput
            } workspaces-population-row-range-max-value`}
            onChange={e =>
              changeLabelValue(
                'lte',
                parseInt(e.currentTarget.value, 10),
                labelIndex
              )
            }
            value={label.lte || ''}
            type="number"
            onKeyDown={evt =>
              evt.key === 'ArrowDown' ||
              evt.key === 'ArrowUp' ||
              evt.key === 'e' ||
              evt.key === 'E' ||
              evt.key === '.' ||
              evt.key === '-'
                ? evt.preventDefault()
                : null
            }
          />
        </div>
        <div className={styles.deleteContainer}>
          <img
            src={closeIcon}
            alt="Remove"
            role="presentation"
            className="workspaces-population-delete-label"
            onClick={() => onRemoveLabelClick()}
          />
        </div>
      </div>
    );
  }

  if (label.type === 'dropdown') {
    return (
      <div className={styles.populationRow}>
        <div className={styles.toggleContainer}>
          <Toggle
            checked={label.active}
            onToggle={() =>
              changeLabelValue('active', !label.active, labelIndex)
            }
          />
        </div>
        <div
          className={`${
            styles.typeContainer
          } workspaces-population-row-type-dropdown-container`}
        >
          <Dropdown
            options={getNotUsedLabels()}
            selectedOptions={[label]}
            onSelectCompleted={populationNameChange}
            inverse={inverse}
          />
        </div>
        <div
          className={`${
            styles.valueContainer
          } workspaces-population-row-dropdown-type-value-container`}
        >
          <Dropdown
            options={getOptions(label)}
            selectedOptions={label.value ? label.value : []}
            onSelectCompleted={option => {
              if (option) {
                changeLabelValue('value', option, labelIndex);
              }
            }}
            multipleChoice
          />
        </div>
        <div className={styles.deleteContainer}>
          <img
            src={closeIcon}
            className="workspaces-population-delete-label"
            alt="Remove"
            role="presentation"
            onClick={() => onRemoveLabelClick()}
          />
        </div>
      </div>
    );
  }

  if (label.type === 'answer') {
    return (
      <div>
        <div className={styles.populationRow}>
          <div className={styles.toggleContainer}>
            <Toggle
              checked={label.active}
              onToggle={() =>
                changeLabelValue('active', !label.active, labelIndex)
              }
            />
          </div>
          <div
            className={`${
              styles.typeContainer
            } workspaces-population-row-type-dropdown-container`}
          >
            <Dropdown
              options={getNotUsedLabels()}
              selectedOptions={[label]}
              onSelectCompleted={populationNameChange}
              inverse={inverse}
            />
          </div>
          <div className={styles.valueContainer}>
            Only multiple choice question blocks can be selected
          </div>
          <div className={styles.deleteContainer}>
            <img
              src={closeIcon}
              className="workspaces-population-delete-label"
              alt="Remove"
              role="presentation"
              onClick={() => onRemoveLabelClick()}
            />
          </div>
        </div>
        <div className={styles.populationRowExtended}>
          <div
            className={`${
              styles.extendedRow
            } workspaces-population-row-question-dropdown-container`}
          >
            <Dropdown
              options={getOptions(label)}
              selectedOptions={label.block ? [label.block] : []}
              onSelectCompleted={option => {
                if (option) {
                  changeLabelValue('block', option[0], labelIndex);
                  changeLabelValue('value', null, labelIndex);
                }
              }}
            />
          </div>
          <div
            className={`${
              styles.extendedRow
            } workspaces-population-row-answer-dropdown-container`}
          >
            <Dropdown
              options={getOptions(label, 'block')}
              selectedOptions={label.value ? label.value : []}
              onSelectCompleted={option => {
                if (option) {
                  changeLabelValue('value', option, labelIndex);
                }
              }}
              multipleChoice
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default PopulationRow;
