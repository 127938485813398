import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import GoalOverview from './components/GoalOverview/GoalOverview';
import OverviewTabs from './components/OverviewTabs/OverviewTabs';
import TabContent from './components/TabContent/TabContent';
import UserAccountFunds from './components/UserAccountFunds/UserAccountFunds';

import Ranking from './components/Ranking/Ranking';
import Data from './components/Data/Data';
import Revenues from './components/Revenues/Revenues';
import MoneyGraph from './components/MoneyGraph/MoneyGraph';

import styles from './DashboardPage.module.css';

import { GET_STATISTICS } from '../../../graphql/Organisation';

const DashboardPage = ({
  history,
  organisation,
  match,
  isLoggedIn,
  strings
}) => {
  let statistics;
  const getStatistics = token => {
    statistics = useQuery(GET_STATISTICS, {
      variables: { token },
      fetchPolicy: 'network-only'
    });
  };
  getStatistics();

  if (match && match.params && match.params.token) {
    getStatistics(match.params.token);
  } else {
    getStatistics();
  }

  const refs = {};

  if (!statistics.data || !Object.keys(statistics.data).length) {
    return null;
  }

  const tabs = [
    strings.RANKING,
    strings.DATA,
    strings.REVENUES,
    strings.MONEY_GRAPH
  ];

  const collectedEurosInLastMonths = statistics.data.statistics.collectedEurosInLastMonths.map(
    monthItem => {
      const months = [
        strings.JANUARY,
        strings.FEBRUARY,
        strings.MARCH,
        strings.APRIL,
        strings.MAY,
        strings.JUNE,
        strings.JULY,
        strings.AUGUST,
        strings.SEPTEMBER,
        strings.OCTOBER,
        strings.NOVEMBER,
        strings.DECEMBER
      ];

      const item = monthItem;

      const label = monthItem.label.split('-');
      item.label = `${months[label[0] - 1]} ${label[1]}`;
      return item;
    }
  );

  return (
    <div className={styles.container}>
      <div className={`${styles.title} roboto-bold-font`}>
        {organisation.name}
      </div>
      <GoalOverview
        organisation={organisation}
        history={history}
        statsLoading={statistics.loading}
        stats={statistics.data.statistics}
        isLoggedIn={isLoggedIn}
        strings={strings}
      />
      {statistics.data.statistics.collectedTokensOnUserProfile && isLoggedIn ? (
        <div>
          <div className={styles.userFundsTopTabsSeparator} />
          <UserAccountFunds
            stats={statistics.data.statistics}
            statsLoading={statistics.loading}
            refs={refs}
            strings={strings}
            organisation={organisation}
          />
          <div className={styles.userFundsTabsSeparator} />
        </div>
      ) : (
        <div className={styles.overviewGoalTabsSeparator} />
      )}
      <OverviewTabs
        statsLoading={statistics.loading}
        stats={statistics.data.statistics}
        refs={refs}
        strings={strings}
      />
      <div className={styles.overviewTabsSeparator} />
      {statistics.loading ? (
        <div className={styles.tabContentsContainer}>
          <div className={styles.tabContentContainerPlaceholder}>
            <div
              className={`${
                styles.tabContentContainerLeftPlaceholder
              } loader-container`}
            />
            <div
              className={`${
                styles.tabContentContainerCenterPlaceholder
              } loader-container`}
            />
          </div>
        </div>
      ) : (
        <div className={styles.tabContentsContainer}>
          <div
            ref={elem => {
              refs.ranking = elem;
            }}
          >
            <TabContent
              tabs={tabs}
              active={0}
              content={
                <Ranking
                  data={statistics.data.statistics.ranking}
                  strings={strings}
                />
              }
              strings={strings}
            />
          </div>
          <div
            ref={elem => {
              refs.data = elem;
            }}
          >
            <TabContent
              tabs={tabs}
              active={1}
              content={
                <Data
                  data={statistics.data.statistics}
                  organisation={organisation}
                  strings={strings}
                />
              }
              strings={strings}
            />
          </div>
          <div
            ref={elem => {
              refs.revenues = elem;
            }}
          >
            <TabContent
              tabs={tabs}
              active={2}
              content={
                <Revenues data={collectedEurosInLastMonths} strings={strings} />
              }
              strings={strings}
            />
          </div>
          <TabContent
            tabs={tabs}
            active={3}
            content={
              <MoneyGraph data={collectedEurosInLastMonths} strings={strings} />
            }
            strings={strings}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
