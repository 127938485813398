import React from 'react';

import styles from './Ranking.module.css';

import coverImage from '../../../../../assets/img/ranking.svg';

const Ranking = ({ data, strings }) => (
  <div className={styles.container}>
    <div className={`${styles.title} roboto-bold-font`}>{strings.RANKING}</div>
    <div className={styles.contentContainer}>
      <div className={styles.table}>
        {data.map((ranking, index) => (
          <div
            className={styles.tableRow}
            key={`ranking-item-${index.toString()}`}
          >
            <div className={`${styles.index} roboto-bold-font`}>
              {index + 1}
            </div>
            <div className={styles.name}>{ranking.label}</div>
            <div className={styles.tokens}>
              {ranking.value} {strings.tokens} tokens
            </div>
          </div>
        ))}
        {!data || !data.length ? strings.NO_DATA_AVAILABLE : null}
      </div>
      <img className={styles.cover} src={coverImage} alt="Ranking cover" />
    </div>
  </div>
);

export default Ranking;
