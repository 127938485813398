import { QUESTION_DESIGNER_ACTION, QUESTION_TYPES } from '../constants';
import CLIENT_APPLICATION_STRINGS from '../../../../../strings/en';

export const getIsAllowedResponse = (allowed, message) => ({
  allowed,
  message
});

export const getGroupFirstQuestionFlow = (
  groupFlows,
  surveyLists,
  groups,
  questions
) => {
  const firstFlow = groupFlows[0];

  if (questions[firstFlow]) {
    return surveyLists.find(sLF => sLF.id === firstFlow);
  }

  const group = groups.find(g => g.id === firstFlow);

  if (!group) {
    return null;
  }

  return getGroupFirstQuestionFlow(group.flows, surveyLists, groups, questions);
};

export const getDestinationFlow = (
  destinationFlowId,
  surveyLists,
  structure
) => {
  const flow = surveyLists.find(f => f.id === destinationFlowId);

  if (flow) {
    return { isGroup: false, flow };
  }

  if (!flow) {
    const flowsInGroup = surveyLists.filter(
      f => f.group && f.group.id === destinationFlowId
    );

    if (flowsInGroup && flowsInGroup.length) {
      const firstQuestionFlow = getGroupFirstQuestionFlow(
        flowsInGroup[0].group.flows,
        surveyLists,
        structure.content.groups,
        structure.questions
      );

      return { isGroup: true, flow: flowsInGroup[0].group, firstQuestionFlow };
    }

    // Parent group
    if (flowsInGroup.length === 0) {
      const flowsInParentGroup = surveyLists.filter(
        f =>
          f.group &&
          f.group.parentGroup &&
          f.group.parentGroup.id === destinationFlowId
      );

      if (flowsInParentGroup && flowsInParentGroup.length) {
        const firstQuestionFlow = getGroupFirstQuestionFlow(
          flowsInParentGroup[0].group.parentGroup.flows,
          surveyLists,
          structure.content.groups,
          structure.questions
        );

        return {
          isGroup: true,
          flow: flowsInParentGroup[0].group.parentGroup,
          firstQuestionFlow
        };
      }
    }
  }

  return null;
};

const isMinQuotaQuestion = q =>
  q.type === QUESTION_TYPES.MULTIPLE_CHOICE &&
  q.choices &&
  q.choices.length &&
  q.choices.some(c => c.quotaType && c.quotaMinAmount);

export const minQuotaQuestionExists = surveyLists =>
  surveyLists &&
  surveyLists.length &&
  surveyLists.some(q => isMinQuotaQuestion(q));

export const minQuotaAfterThisFlow = (sourceFlow, surveyLists, groups) => {
  if (sourceFlow && surveyLists && surveyLists.length) {
    const firstMinQuotaQuestionIndex = surveyLists.findIndex(q =>
      isMinQuotaQuestion(q)
    );
    if (firstMinQuotaQuestionIndex > -1) {
      let activeFlowId = sourceFlow.id;
      if (sourceFlow.flows && sourceFlow.flows.length) {
        const currentGroup =
          groups &&
          groups.length &&
          groups.find(group => group.id && group.id === sourceFlow.id);

        if (
          currentGroup &&
          !(
            currentGroup.flows &&
            currentGroup.flows.length &&
            currentGroup.flows.some(
              groupFlow =>
                firstMinQuotaQuestionIndex ===
                surveyLists.findIndex(q => q.id === groupFlow)
            )
          )
        ) {
          const lastFlow = currentGroup.flows[currentGroup.flows.length - 1];
          if (lastFlow) {
            activeFlowId = lastFlow;
          }
        }
      }
      if (
        activeFlowId &&
        firstMinQuotaQuestionIndex >
          surveyLists.findIndex(q => q.id === activeFlowId)
      ) {
        return true;
      }
    }
  }
  return false;
};

const preventMinQuotaPositioning = (
  sourceFlow,
  destinationFlow,
  destinationLocation,
  surveyLists
) => {
  if (sourceFlow && destinationFlow) {
    const minQuotaId = sourceFlow.id;
    const minQuotaIndex = minQuotaId
      ? surveyLists &&
        surveyLists.length &&
        surveyLists.findIndex(q => q.id === minQuotaId)
      : -1;

    const firstJumpAfterMinQuota =
      minQuotaIndex > -1
        ? surveyLists.find(
            (q, questionIndex) =>
              questionIndex > minQuotaIndex &&
              (q.type === QUESTION_TYPES.DISTRIBUTOR ||
                (q.nextFlow || q.end) ||
                (q.choices &&
                  q.choices.length &&
                  q.choices.some(c => c.nextFlow || c.end)) ||
                (q.group && (q.group.nextFlow || q.group.end)))
          )
        : null;

    if (firstJumpAfterMinQuota && firstJumpAfterMinQuota.id) {
      let destinationFlowId = null;
      if (
        destinationFlow.isGroup &&
        destinationFlow.firstQuestionFlow &&
        destinationFlow.firstQuestionFlow.id
      ) {
        destinationFlowId = destinationFlow.firstQuestionFlow.id;
      } else if (destinationFlow.flow && destinationFlow.flow.id) {
        destinationFlowId = destinationFlow.flow.id;
      }
      if (destinationFlowId) {
        let destinationFlowIndex = surveyLists.findIndex(
          q => q.id === destinationFlowId
        );
        if (destinationLocation && destinationLocation === 'BEFORE') {
          destinationFlowIndex -= 1;
        }
        const firstJumpAfterMinQuotaIndex = surveyLists.findIndex(
          q => q.id === firstJumpAfterMinQuota.id
        );
        if (destinationFlowIndex > -1 && firstJumpAfterMinQuotaIndex > -1) {
          return destinationFlowIndex >= firstJumpAfterMinQuotaIndex;
        }
      }
    }
  }
  return false;
};

export default (
  action,
  sourceFlow,
  destinationFlowId,
  surveyLists,
  structure,
  destinationLocation
) => {
  switch (action) {
    case QUESTION_DESIGNER_ACTION.QUESTION_MOVE:
    case QUESTION_DESIGNER_ACTION.QUESTION_DELETE:
    case QUESTION_DESIGNER_ACTION.QUESTION_DUPLICATE: {
      if (sourceFlow) {
        const destinationFlow = getDestinationFlow(
          destinationFlowId,
          surveyLists,
          structure
        );

        // There is a jump (multiple jumps) going from this question
        if (sourceFlow.nextFlow || sourceFlow.end) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
          );
        }

        // There is a jump (multiple jumps) going from an answer within this question
        if (sourceFlow.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
          const createdBranches =
            sourceFlow.choices &&
            sourceFlow.choices.some(c => c.nextFlow || c.end);

          if (createdBranches) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
            );
          }
        }

        // There is a jump (multiple jumps) going to this question
        // If question has ONLY jumps from distributor groups it is allowed to move it
        // (additional script will modify distributor groups) to adapt to this change
        const anyJumpNotFromDistributorsGroup =
          sourceFlow.jumpsFrom &&
          sourceFlow.jumpsFrom.some(j => {
            const questionsWithGroup = surveyLists.find(
              f => f.group && f.group.id === j
            );
            if (
              !questionsWithGroup ||
              !questionsWithGroup.group ||
              !questionsWithGroup.group.jumpFromDistributor
            ) {
              return false;
            }
            return true;
          });
        if (
          sourceFlow.jumpsFrom &&
          sourceFlow.jumpsFrom.length &&
          !anyJumpNotFromDistributorsGroup
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP_POINTING_ON_IT
          );
        }

        // Source flow is a distributor block
        if (sourceFlow.type === QUESTION_TYPES.DISTRIBUTOR) {
          const createdBranches =
            sourceFlow.branches &&
            sourceFlow.branches.some(c => c.nextFlow || c.end);

          if (createdBranches) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_RANDOMIZER_BLOCK
            );
          }

          // Should not be allowed to move distributor block on a first position of a survey
          if (
            surveyLists &&
            destinationFlow &&
            destinationFlow.flow &&
            surveyLists[0] &&
            (surveyLists[0].id === destinationFlow.flow.id ||
              (surveyLists[0].group &&
                surveyLists[0].group.id === destinationFlow.flow.id))
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DISTRIBUTOR_ON_THE_FIRST_POSITION_IN_SURVEY
            );
          }

          // Should not be allowed to nest or move distributor into a group
          // debugger;
          if (
            destinationFlow &&
            !destinationFlow.isGroup &&
            destinationFlow.flow &&
            destinationFlow.flow.group &&
            destinationFlow.flow.group.id
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DISTRIBUTOR_INTO_GROUP
            );
          }
        }

        // There is a jump (multiple jumps) going from a range within this question
        if (sourceFlow.type === QUESTION_TYPES.RATING_SCALE) {
          const createdBranches =
            sourceFlow.branches &&
            sourceFlow.branches.filter(b => b.nextFlow || b.end);

          if (createdBranches && createdBranches.length) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
            );
          }
        }

        // Should not be allowed to move last group question if group has questions pointing on it
        if (
          sourceFlow.group &&
          sourceFlow.group.flows &&
          sourceFlow.group.flows.length === 1 &&
          sourceFlow.group.jumpsFrom &&
          sourceFlow.group.jumpsFrom.length
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_GROUP_WITH_JUMP_POINTING_ON_IT
          );
        }

        // Should not be allowed to move/delete question if it is a question on first position in survey and next is
        // randomized group
        if (sourceFlow.globalIndex === 1) {
          if (
            sourceFlow.additionalInformation &&
            sourceFlow.additionalInformation.isNextRandomizer
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_QUESTION_BEFORE_RANDOMIZER_BLOCK
            );
          }

          const nextFlowId = structure && structure.content.flows[1];
          if (nextFlowId) {
            const nextFlowGroup = structure.content.groups.find(
              g => g.id === nextFlowId
            );
            if (nextFlowGroup && nextFlowGroup.randomized) {
              return getIsAllowedResponse(
                false,
                CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_QUESTION_BEFORE_RANDOMIZED_GROUP
              );
            }
          }
        }

        // It is not allowed to duplicate profiling questions
        if (
          sourceFlow &&
          sourceFlow.profiling &&
          sourceFlow.profiling.isProfilingQuestion &&
          action === QUESTION_DESIGNER_ACTION.QUESTION_DUPLICATE
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_PROFILING_QUESTIONS
          );
        }

        // It is not allowed to position a min quota question after blocks that could lead to answer jump
        if (
          (action === QUESTION_DESIGNER_ACTION.QUESTION_MOVE ||
            action === QUESTION_DESIGNER_ACTION.QUESTION_DUPLICATE) &&
          sourceFlow &&
          destinationFlow &&
          destinationFlow.flow &&
          sourceFlow.id !== destinationFlowId &&
          isMinQuotaQuestion(sourceFlow) &&
          preventMinQuotaPositioning(
            sourceFlow,
            destinationFlow,
            destinationLocation,
            surveyLists
          )
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_MOVE_OR_DUPLICATE_MIN_QUOTA_AFTER_DISTRIBUTOR_OR_JUMPS
          );
        }
      }

      break;
    }
    case QUESTION_DESIGNER_ACTION.SET_QUESTION_JUMP:
    case QUESTION_DESIGNER_ACTION.SET_DISTRIBUTOR_JUMP: {
      const destinationFlow = getDestinationFlow(
        destinationFlowId,
        surveyLists,
        structure
      );

      // Should not be allowed to jump on itself
      if (sourceFlow.id === destinationFlowId) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_ON_ITSELF
        );
      }

      // Should not be allowed to jump to previous questions
      if (
        sourceFlow.globalIndex &&
        !destinationFlow.isGroup &&
        destinationFlow.flow.globalIndex &&
        sourceFlow.globalIndex > destinationFlow.flow.globalIndex
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_TO_PREVIOUS_QUESTIONS
        );
      }

      // Should not be allowed to jump to previous groups
      if (
        destinationFlow.isGroup &&
        destinationFlow.firstQuestionFlow &&
        (sourceFlow.globalIndex >
          destinationFlow.firstQuestionFlow.globalIndex ||
          (sourceFlow.globalIndex === 1 &&
            destinationFlow.firstQuestionFlow.globalIndex === 1))
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_TO_PREVIOUS_GROUPS
        );
      }

      // Should not be allowed to jump from not grouped question to grouped question
      if (
        !sourceFlow.group &&
        !destinationFlow.isGroup &&
        destinationFlow.flow.group
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_NONGROUPED_TO_GROUPED
        );
      }

      // Should not be allowed to jump from grouped question to non grouped question
      if (
        sourceFlow.group &&
        !destinationFlow.isGroup &&
        !destinationFlow.flow.group
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_NONGROUPED
        );
      }

      // Should not be allowed to jump from grouped question in the randomised group
      if (sourceFlow.group && sourceFlow.group.randomized) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_GROUPED_QUESTIONS_IN_SAME_RANDOMIZED_GROUP
        );
      }

      // Should not be allowed to jump from child group to not grouped question
      if (
        sourceFlow.flows &&
        sourceFlow.flows.length &&
        sourceFlow.nestingLevel === 2 &&
        !destinationFlow.isGroup &&
        destinationFlow.flow.group
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_NONGROUPED
        );
      }

      // Should not be allowed to jump from not grouped question to group with nesting level 2
      if (
        !sourceFlow.group &&
        destinationFlow.isGroup &&
        destinationFlow.flow.nestingLevel === 2
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // Should not be allowed to jump from grouped question to group out of group where is the question
      if (
        sourceFlow.group &&
        destinationFlow.isGroup &&
        sourceFlow.group.nestingLevel === 1 &&
        destinationFlow.flow.nestingLevel === 1
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // Should not be allowed to jump from not grouped question to group with nesting level 2
      if (
        sourceFlow.group &&
        sourceFlow.group.nestingLevel === 2 &&
        destinationFlow.flow &&
        destinationFlow.flow.group &&
        destinationFlow.flow.group.nestingLevel &&
        destinationFlow.flow.group.nestingLevel === 1
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // Should not be allowed to jump from grouped question to another grouped question in different groups
      if (
        sourceFlow &&
        sourceFlow.group &&
        destinationFlow &&
        !destinationFlow.isGroup &&
        destinationFlow.flow &&
        destinationFlow.flow.group &&
        destinationFlow.flow.group.id &&
        sourceFlow.group.id !== destinationFlow.flow.group.id
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // Should not be allowed to jump to "distributor group"
      if (
        destinationFlow &&
        destinationFlow.isGroup &&
        destinationFlow.flow &&
        destinationFlow.flow.jumpFromDistributor
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.ALREADY_TARGETED_BY_DISTRIBUTOR
        );
      }

      if (
        sourceFlow.group &&
        sourceFlow.group.nestingLevel === 2 &&
        sourceFlow.group.parentGroup &&
        destinationFlow.flow &&
        ((destinationFlow.flow.group &&
          destinationFlow.flow.group.nestingLevel &&
          destinationFlow.flow.group.nestingLevel === 2 &&
          destinationFlow.flow.group.parentGroup.id !==
            sourceFlow.group.parentGroup.id) ||
          (destinationFlow.flow.parentGroup &&
            destinationFlow.flow.parentGroup.id !==
              sourceFlow.group.parentGroup.id))
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // It is not allowed to add logic jumps if there is a min quota question after this block
      if (
        sourceFlow &&
        minQuotaQuestionExists(surveyLists) &&
        minQuotaAfterThisFlow(sourceFlow, surveyLists, structure.content.groups)
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_ADD_LOGIC_JUMPS_BEFORE_MIN_QUOTA
        );
      }

      break;
    }
    case QUESTION_DESIGNER_ACTION.PROFILE_QUESTION_TAGGING: {
      if (
        sourceFlow &&
        sourceFlow.profiling &&
        sourceFlow.profiling.answerCollected
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_EDIT_PROFILING_QUESTION_AFTER_RESULTS
        );
      }
      if (
        sourceFlow &&
        sourceFlow.profiling &&
        sourceFlow.profiling.isReusedProfilingQuestion
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_FOR_REUSED_PROFILING_QUESTIONS
        );
      }
      break;
    }
    case QUESTION_DESIGNER_ACTION.GROUP_MOVE:
    case QUESTION_DESIGNER_ACTION.GROUP_DELETE:
    case QUESTION_DESIGNER_ACTION.GROUP_UNGROUP: {
      const destinationFlow = getDestinationFlow(
        destinationFlowId,
        surveyLists,
        structure
      );

      // Should not be allowed select same group as the one already selected
      if (
        action === QUESTION_DESIGNER_ACTION.GROUP_MOVE &&
        sourceFlow &&
        sourceFlow.id === destinationFlowId
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_MOVE_INTO_SELF
        );
      }

      if (sourceFlow && (sourceFlow.nextFlow || sourceFlow.end)) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP_GROUP
        );
      }

      // Action not allowed if group has logic jumps pointing on it
      const anyJumpNotFromDistributorsGroup =
        sourceFlow.jumpsFrom &&
        sourceFlow.jumpsFrom.some(j => {
          const questionsWithGroup = surveyLists.find(
            f => f.group && f.group.id === j
          );
          if (
            !questionsWithGroup ||
            !questionsWithGroup.group ||
            !questionsWithGroup.group.jumpFromDistributor
          ) {
            return false;
          }
          return true;
        });
      if (
        sourceFlow.jumpsFrom &&
        sourceFlow.jumpsFrom.length &&
        !anyJumpNotFromDistributorsGroup
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_GROUP_WITH_JUMP_POINTING_ON_IT
        );
      }

      // Should not be allowed to move last group if group has questions pointing on it
      if (
        action === QUESTION_DESIGNER_ACTION.GROUP_MOVE ||
        action === QUESTION_DESIGNER_ACTION.GROUP_DELETE
      ) {
        if (
          sourceFlow &&
          sourceFlow.parentGroup &&
          sourceFlow.parentGroup.flows &&
          sourceFlow.parentGroup.flows.length === 1 &&
          sourceFlow.parentGroup.jumpsFrom
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_GROUP_WITH_JUMP_POINTING_ON_IT
          );
        }

        // Should not be allowed to put inside child group
        if (
          destinationFlow &&
          !destinationFlow.isGroup &&
          destinationFlow.flow &&
          destinationFlow.flow.group
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
          );
        }
      }

      // Should not be allowed to move/delete group on first place if next group is randomized or distributor
      if (
        (action === QUESTION_DESIGNER_ACTION.GROUP_MOVE ||
          action === QUESTION_DESIGNER_ACTION.GROUP_DELETE) &&
        structure &&
        structure.content.flows[0] === sourceFlow.id
      ) {
        const nextFlowId = structure.content.flows[1];
        const nextFlowGroup = structure.content.groups.find(
          g => g.id === nextFlowId
        );
        if (nextFlowGroup && nextFlowGroup.randomized) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_QUESTION_BEFORE_RANDOMIZED_GROUP
          );
        }

        const nextFlowQuestion = structure && structure.questions[nextFlowId];
        if (
          nextFlowQuestion &&
          nextFlowQuestion.type === QUESTION_TYPES.DISTRIBUTOR
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_GROUP_BEFORE_RANDOMIZER_BLOCK
          );
        }
      }

      if (sourceFlow && sourceFlow.flows && sourceFlow.flows.length) {
        if (sourceFlow.parent) {
          // Should not be allowed to move parent group before/after questions in group
          if (
            destinationFlow &&
            destinationFlow.flow &&
            destinationFlow.flow.group
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
            );
          }

          // Should not be allowed to move parent group before/after child group in other parent group
          if (
            destinationFlow &&
            destinationFlow.flow &&
            destinationFlow.flow.parentGroup
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
            );
          }
        } else {
          // Should not be allowed to move parent group into itself
          // eslint-disable-next-line
          if (
            destinationFlow &&
            destinationFlow.flow &&
            destinationFlow.flow.group &&
            destinationFlow.flow.group.id === sourceFlow.id
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
            );
          }
        }
      }

      // It is not allowed to position a group containing min quota question after blocks that could lead to answer jump
      const minQuotaInGroup =
        sourceFlow &&
        sourceFlow.flows &&
        sourceFlow.flows.length &&
        surveyLists &&
        surveyLists.length
          ? surveyLists.find(
              q => sourceFlow.flows.includes(q.id) && isMinQuotaQuestion(q)
            )
          : null;
      if (
        action === QUESTION_DESIGNER_ACTION.GROUP_MOVE &&
        destinationFlow &&
        destinationFlow.flow &&
        minQuotaInGroup &&
        minQuotaInGroup.id !== destinationFlowId &&
        preventMinQuotaPositioning(
          minQuotaInGroup,
          destinationFlow,
          destinationLocation,
          surveyLists
        )
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_MOVE_OR_DUPLICATE_MIN_QUOTA_AFTER_DISTRIBUTOR_OR_JUMPS
        );
      }

      break;
    }
    case QUESTION_DESIGNER_ACTION.GROUP_DUPLICATE: {
      const destinationFlow = getDestinationFlow(
        destinationFlowId,
        surveyLists,
        structure
      );

      // Should not be allowed to put group inside other group
      if (
        destinationFlow &&
        !destinationFlow.isGroup &&
        destinationFlow.flow &&
        destinationFlow.flow.group
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
        );
      }

      if (sourceFlow && sourceFlow.flows && sourceFlow.flows.length) {
        if (sourceFlow.parent) {
          // Should not be allowed to move parent group before/after questions in group
          if (
            destinationFlow &&
            destinationFlow.flow &&
            destinationFlow.flow.group
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
            );
          }

          // Should not be allowed to move parent group before/after child group in other parent group
          if (
            destinationFlow &&
            destinationFlow.flow &&
            destinationFlow.flow.parentGroup
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
            );
          }
        } else {
          // Should not be allowed to move parent group into itself
          // eslint-disable-next-line
          if (
            destinationFlow &&
            destinationFlow.flow &&
            destinationFlow.flow.group &&
            destinationFlow.flow.group.id === sourceFlow.id
          ) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
            );
          }
        }
      }

      // It is not allowed to position a group containing min quota question after blocks that could lead to answer jump
      const minQuotaInGroup =
        sourceFlow &&
        sourceFlow.flows &&
        sourceFlow.flows.length &&
        surveyLists &&
        surveyLists.length
          ? surveyLists.find(
              q => sourceFlow.flows.includes(q.id) && isMinQuotaQuestion(q)
            )
          : null;
      if (
        action === QUESTION_DESIGNER_ACTION.GROUP_DUPLICATE &&
        destinationFlow &&
        destinationFlow.flow &&
        minQuotaInGroup &&
        minQuotaInGroup.id !== destinationFlowId &&
        preventMinQuotaPositioning(
          minQuotaInGroup,
          destinationFlow,
          destinationLocation,
          surveyLists
        )
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_MOVE_OR_DUPLICATE_MIN_QUOTA_AFTER_DISTRIBUTOR_OR_JUMPS
        );
      }

      break;
    }
    case QUESTION_DESIGNER_ACTION.GROUP_RANDOMIZE: {
      // Should not be allowed to randomize group that has at least one child question or group with question jump
      let hasJumps = false;
      sourceFlow.flows.forEach(f => {
        const flow = surveyLists.find(sL => sL.id === f);

        if (flow) {
          if (flow.nextFlow || flow.end) {
            hasJumps = true;
          }
          if (flow.choices && flow.choices.some(fC => fC.nextFlow || fC.end)) {
            hasJumps = true;
          }
        }

        if (!flow) {
          const flowsInGroup = surveyLists.filter(
            sLF => sLF.group && sLF.group.id === f
          );

          if (flowsInGroup && flowsInGroup.length) {
            if (
              flowsInGroup[0].group &&
              (flowsInGroup[0].group.nextFlow || flowsInGroup[0].group.end)
            ) {
              hasJumps = true;
            }
          }
        }
      });
      if (hasJumps) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_RANDOMIZE_WITH_QUESTION_WITH_JUMPS
        );
      }

      // Should not be allowed to randomize group on the first position in survey
      if (structure.content.flows.indexOf(sourceFlow.id) === 0) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_RANDOMIZE_ON_FIRST_POSITION
        );
      }

      // Should not be allowed to randomize group on the first position in survey
      if (
        sourceFlow &&
        sourceFlow.parentGroup &&
        structure.content.flows.indexOf(sourceFlow.parentGroup.id) === 0 &&
        sourceFlow.parentGroup.flows[0] === sourceFlow.id
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_RANDOMIZE_ON_FIRST_POSITION
        );
      }

      break;
    }
    case QUESTION_DESIGNER_ACTION.SET_GROUP_JUMP: {
      const destinationFlow = getDestinationFlow(
        destinationFlowId,
        surveyLists,
        structure
      );
      const sourceGroupFirstQuestionFlow = getGroupFirstQuestionFlow(
        sourceFlow.flows,
        surveyLists,
        structure.content.groups,
        structure.questions
      );
      const parentContentGroup = structure.content.groups.find(
        g => g.flows.indexOf(sourceFlow.id) > -1
      );
      const parentGroupQuestion = surveyLists.find(
        sL =>
          sL.group &&
          parentContentGroup &&
          sL.group.id === parentContentGroup.id
      );
      const parentGroup = parentGroupQuestion && parentGroupQuestion.group;

      if (
        sourceFlow.flows &&
        sourceFlow.flows.length &&
        sourceFlow.nestingLevel === 1 &&
        destinationFlow &&
        !destinationFlow.isGroup &&
        destinationFlow.flow &&
        destinationFlow.flow.group &&
        destinationFlow.flow.group.nestingLevel === 1
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_GROUP_TO_GROUPED_QUESTION
        );
      }

      // Should not be allowed to jump on itself
      if (sourceFlow.id === destinationFlowId) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_ON_ITSELF
        );
      }

      // Should not be allowed to jump to previous questions
      if (
        sourceGroupFirstQuestionFlow &&
        sourceGroupFirstQuestionFlow.globalIndex >
          destinationFlow.flow.globalIndex
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_TO_PREVIOUS_QUESTIONS
        );
      }

      // Should not be allowed to jump to previous groups
      if (
        sourceGroupFirstQuestionFlow &&
        sourceGroupFirstQuestionFlow.globalIndex &&
        destinationFlow.firstQuestionFlow &&
        (sourceGroupFirstQuestionFlow.globalIndex >
          destinationFlow.firstQuestionFlow.globalIndex ||
          (sourceGroupFirstQuestionFlow.globalIndex === 1 &&
            destinationFlow.firstQuestionFlow.globalIndex === 1))
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_TO_PREVIOUS_GROUPS
        );
      }

      // Should not be allowed to jump from child group in the randomised group
      if (parentGroup && parentGroup.randomized) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_GROUPED_QUESTIONS_IN_SAME_RANDOMIZED_GROUP
        );
      }

      // Should not be allowed to jump from child group to not grouped question
      if (
        sourceFlow.nestingLevel === 2 &&
        !destinationFlow.isGroup &&
        !destinationFlow.flow.group
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_NONGROUPED
        );
      }

      // Should not be allowed to jump from group with nesting level 1 to group with nesting level 2
      if (
        sourceFlow.nestingLevel === 1 &&
        destinationFlow.isGroup &&
        destinationFlow.flow.nestingLevel === 2
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // Should not be allowed to jump from not grouped question to group with nesting level 2
      if (
        !sourceFlow.group &&
        destinationFlow.isGroup &&
        destinationFlow.flow.nestingLevel === 2
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // Should not be allowed to jump to inner questions
      if (
        destinationFlow.flow &&
        destinationFlow.flow.group &&
        sourceFlow.id === destinationFlow.flow.group.id
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_TO_PREVIOUS_QUESTIONS
        );
      }

      // Should not be allowed to jump from group child group to parent group of that child group
      if (
        sourceFlow.nestingLevel === 2 &&
        destinationFlow.isGroup &&
        destinationFlow.flow.nestingLevel === 1 &&
        sourceFlow.group &&
        sourceFlow.group.id === destinationFlow.flow.id
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      if (
        sourceFlow &&
        sourceFlow.nestingLevel === 2 &&
        sourceFlow.parentGroup &&
        !destinationFlow.isGroup &&
        destinationFlow.flow.group &&
        destinationFlow.flow.group.parentGroup &&
        sourceFlow.parentGroup.id !== destinationFlow.flow.group.parentGroup.id
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_FROM_GROUPED_QUESTION_TO_GROUP
        );
      }

      // Should not be allowed to jump from parent group to questions in child group of that parent
      if (
        sourceFlow &&
        destinationFlow &&
        destinationFlow.flow.group &&
        destinationFlow.flow.group &&
        destinationFlow.flow.group.parentGroup &&
        sourceFlow.id === destinationFlow.flow.group.parentGroup.id
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_JUMP_GROUP_TO_GROUPED_QUESTION
        );
      }

      // It is not allowed to add logic jumps to groups if there is a min quota question after this one
      if (
        sourceFlow &&
        minQuotaAfterThisFlow(sourceFlow, surveyLists, structure.content.groups)
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_ADD_LOGIC_JUMPS_BEFORE_MIN_QUOTA
        );
      }

      break;
    }
    case QUESTION_DESIGNER_ACTION.ADD_TO_GROUP: {
      const destinationFlow = getDestinationFlow(
        destinationFlowId,
        surveyLists,
        structure
      );

      if (!sourceFlow.flows) {
        if (sourceFlow.nextFlow || sourceFlow.end) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
          );
        }

        // There is a jump (multiple jumps) going from an answer within this question
        if (sourceFlow.choices) {
          const createdBranches = sourceFlow.choices.some(
            c => c.nextFlow || c.end
          );

          if (createdBranches) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
            );
          }
        }

        if (sourceFlow.branches) {
          const createdBranches = sourceFlow.branches.filter(
            b => b.nextFlow || b.end
          );

          if (createdBranches && createdBranches.length) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
            );
          }
        }
      }

      if (destinationFlow && !destinationFlow.isGroup) {
        if (destinationFlow.flow.nextFlow || destinationFlow.flow.end) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
          );
        }

        // There is a jump (multiple jumps) going from an answer within this question
        if (destinationFlow.flow.choices) {
          const createdBranches = destinationFlow.flow.choices.some(
            c => c.nextFlow || c.end
          );

          if (createdBranches) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
            );
          }
        }

        if (destinationFlow.flow.branches) {
          const createdBranches = destinationFlow.flow.branches.filter(
            b => b.nextFlow || b.end
          );

          if (createdBranches && createdBranches.length) {
            return getIsAllowedResponse(
              false,
              CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
            );
          }
        }
      }

      if (
        sourceFlow &&
        sourceFlow.flows &&
        destinationFlow &&
        destinationFlow.isGroup
      ) {
        if (sourceFlow.randomized || destinationFlow.flow.randomized) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_MERGE_DIFF_RANDOM
          );
        }

        if (
          sourceFlow.nextFlow ||
          sourceFlow.end ||
          destinationFlow.flow.nextFlow ||
          destinationFlow.flow.end
        ) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
          );
        }
      }

      // SHould not be possible to nest question with jumps on it
      if (
        destinationFlow &&
        destinationFlow.flow &&
        destinationFlow.flow.jumpsFrom &&
        destinationFlow.flow.jumpsFrom.length
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
        );
      }

      // SHould not be possible to nest group with jumps on it
      if (sourceFlow && sourceFlow.jumpsFrom && sourceFlow.jumpsFrom.length) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
        );
      }

      if (
        sourceFlow &&
        sourceFlow.randomized &&
        destinationFlow &&
        destinationFlow.flow &&
        destinationFlow.flow.globalIndex === 1
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_TO_RANDOMIZE_ON_FIRST_POSITION
        );
      }

      break;
    }
    case QUESTION_DESIGNER_ACTION.NEST_INTO_PARENT_GROUP: {
      const destinationFlow = getDestinationFlow(
        destinationFlowId,
        surveyLists,
        structure
      );

      if (sourceFlow.nextFlow || sourceFlow.end) {
        if (!sourceFlow.flows) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
          );
        }

        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP_GROUP
        );
      }

      if (sourceFlow.choices) {
        const createdBranches = sourceFlow.choices.some(
          c => c.nextFlow || c.end
        );

        if (createdBranches) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
          );
        }
      }

      if (sourceFlow.branches) {
        const createdBranches = sourceFlow.branches.filter(
          b => b.nextFlow || b.end
        );

        if (createdBranches && createdBranches.length) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
          );
        }
      }

      if (
        destinationFlow &&
        (destinationFlow.flow.nextFlow || destinationFlow.flow.end)
      ) {
        if (!destinationFlow.isGroup) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
          );
        }

        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP_GROUP
        );
      }

      // There is a jump (multiple jumps) going from an answer within this question
      if (destinationFlow && destinationFlow.flow.choices) {
        const createdBranches = destinationFlow.flow.choices.some(
          c => c.nextFlow || c.end
        );

        if (createdBranches) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
          );
        }
      }

      if (destinationFlow && destinationFlow.flow.branches) {
        const createdBranches = destinationFlow.flow.branches.filter(
          b => b.nextFlow || b.end
        );

        if (createdBranches && createdBranches.length) {
          return getIsAllowedResponse(
            false,
            CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMPS_IN_ANSWERS
          );
        }
      }

      // Should not be allowed to nest if already nested question in child group
      if (
        (sourceFlow &&
          ((sourceFlow.group && sourceFlow.group.nestingLevel === 2) ||
            sourceFlow.nestingLevel === 2)) ||
        ((destinationFlow &&
          destinationFlow.flow &&
          destinationFlow.flow.group &&
          destinationFlow.flow.group.nestingLevel === 2) ||
          (destinationFlow &&
            destinationFlow.flow &&
            destinationFlow.flow.nestingLevel === 2))
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
        );
      }

      // Should not be possible to nest question with jumps on it
      if (
        destinationFlow &&
        destinationFlow.flow &&
        destinationFlow.flow.jumpsFrom &&
        destinationFlow.flow.jumpsFrom.length
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
        );
      }

      // There is a jump (multiple jumps) going to this question
      // If source question has ONLY jumps from distributor groups it is allowed to nest it
      // (additional script will modify distributor groups) to adapt to this change
      const anyJumpNotFromDistributorsGroup =
        sourceFlow.jumpsFrom &&
        sourceFlow.jumpsFrom.some(j => {
          const questionsWithGroup = surveyLists.find(
            f => f.group && f.group.id === j
          );
          if (
            !questionsWithGroup ||
            !questionsWithGroup.group ||
            !questionsWithGroup.group.jumpFromDistributor
          ) {
            return false;
          }
          return true;
        });
      if (
        sourceFlow.jumpsFrom &&
        sourceFlow.jumpsFrom.length &&
        !anyJumpNotFromDistributorsGroup
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_WITH_JUMP
        );
      }

      if (
        (sourceFlow && sourceFlow.parent) ||
        (destinationFlow &&
          destinationFlow.isGroup &&
          destinationFlow.flow &&
          destinationFlow.flow.parent)
      ) {
        return getIsAllowedResponse(
          false,
          CLIENT_APPLICATION_STRINGS.NOT_ALLOWED_DEEPER_NESTING
        );
      }

      break;
    }
    default:
      return getIsAllowedResponse(true);
  }
  return getIsAllowedResponse(true);
};
