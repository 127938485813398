const parseRichText = answer => {
  let value = answer;
  try {
    value = JSON.parse(answer)
      .blocks.map(draftBlock => draftBlock.text)
      .join('\n');
  } catch (e) {
    return value;
  }
  return value;
};

export default (question, xQuestionName, yQuestionName) =>
  question.choices && question.choices.length
    ? question.choices
        .filter(
          c => c.decisionFrameworkType === question.matrix.decisionFrameworkType
        )
        .map(c => ({
          [xQuestionName]: question.matrix.xQuestionId,
          [yQuestionName]: question.matrix.yQuestionId,
          choiceId: c.id,
          description: parseRichText(c.answer),
          active: true
        }))
    : [];
