import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';
import createDecisionFramework from '../createDecisionFramework/createDecisionFramework';

const ObjectID = require('bson-objectid');

export default (state, group, destinationPosition, destinationQuestionId) => {
  const surveyToModify = JSON.parse(JSON.stringify(state.survey));

  const newGroupId = ObjectID().toString();

  const newGroup = JSON.parse(
    JSON.stringify({
      ...group,
      label: `[DUPLICATED] ${group.label}`,
      id: newGroupId
    })
  );

  // Remove group jumps
  if (newGroup) {
    if (newGroup.nextFlow) {
      delete newGroup.nextFlow;
    }

    if (newGroup.end) {
      delete newGroup.end;
    }
  }

  const newQuestionToAdd = {};
  const newGroupsToAdd = [];
  const oldQuestionIdToNewQuestionIdMapping = {};

  const resetGroupFlowIds = flows => {
    flows.forEach((g1F, g1FIndex) => {
      if (surveyToModify.content.groups.some(g => g.id === g1F)) {
        const innerGroupIndex = surveyToModify.content.groups.findIndex(
          g => g.id === g1F
        );
        const innerGroup = JSON.parse(
          JSON.stringify(surveyToModify.content.groups[innerGroupIndex])
        );

        const newInnerGroupId = ObjectID().toString();

        newGroupsToAdd.push({
          ...innerGroup,
          id: newInnerGroupId
        });

        // eslint-disable-next-line
        flows[g1FIndex] = newInnerGroupId;

        resetGroupFlowIds(innerGroup.flows);
        return;
      }

      const newQuestionId = ObjectID().toString();
      newQuestionToAdd[newQuestionId] = {
        ...surveyToModify.questions[g1F],
        id: newQuestionId
      };

      oldQuestionIdToNewQuestionIdMapping[g1F] = newQuestionId;

      if (
        newQuestionToAdd[newQuestionId].choices &&
        newQuestionToAdd[newQuestionId].choices.length
      ) {
        newQuestionToAdd[newQuestionId].choices = newQuestionToAdd[
          newQuestionId
        ].choices.map(c => {
          const choice = { ...c };

          choice.id = ObjectID().toString();
          return choice;
        });
      }

      if (
        newQuestionToAdd[newQuestionId].branches &&
        newQuestionToAdd[newQuestionId].branches.length
      ) {
        newQuestionToAdd[newQuestionId].branches = newQuestionToAdd[
          newQuestionId
        ].branches.map(b => {
          const branch = { ...b };

          branch.id = ObjectID().toString();
          return branch;
        });
      }

      if (
        newQuestionToAdd[newQuestionId].matrix &&
        newQuestionToAdd[newQuestionId].matrix.decisionFrameworkType
      ) {
        newQuestionToAdd[newQuestionId].matrix = {
          ...newQuestionToAdd[newQuestionId].matrix,
          xQuestionId: ObjectID().toString(),
          yQuestionId: ObjectID().toString()
        };
      }

      // eslint-disable-next-line
      flows[g1FIndex] = newQuestionId;
    });
  };
  resetGroupFlowIds(newGroup.flows);

  let newState = {
    ...state
  };
  const requests = [];

  // Fix nextFlow jumps from old values to new based on mapping and create frameworks for matrix questions
  if (newQuestionToAdd) {
    Object.keys(newQuestionToAdd).forEach(qId => {
      if (newQuestionToAdd[qId]) {
        if (newQuestionToAdd[qId].nextFlow) {
          newQuestionToAdd[qId].nextFlow =
            oldQuestionIdToNewQuestionIdMapping[newQuestionToAdd[qId].nextFlow];
        }

        if (
          newQuestionToAdd[qId].choices &&
          newQuestionToAdd[qId].choices.length
        ) {
          newQuestionToAdd[qId].choices = newQuestionToAdd[qId].choices.map(
            c => {
              const newChoice = c;

              if (newChoice.nextFlow) {
                newChoice.nextFlow =
                  oldQuestionIdToNewQuestionIdMapping[newChoice.nextFlow];
              }
              return c;
            }
          );
        }

        if (
          newQuestionToAdd[qId].branches &&
          newQuestionToAdd[qId].branches.length
        ) {
          newQuestionToAdd[qId].branches = newQuestionToAdd[qId].branches.map(
            b => {
              const newBranch = b;

              if (newBranch.nextFlow) {
                newBranch.nextFlow =
                  oldQuestionIdToNewQuestionIdMapping[newBranch.nextFlow];
              }
              return b;
            }
          );
        }

        if (
          newQuestionToAdd[qId].matrix &&
          newQuestionToAdd[qId].matrix.decisionFrameworkType
        ) {
          const {
            surveyWithCreatedFramework,
            createRequest
          } = createDecisionFramework(newState.survey, newQuestionToAdd[qId]);
          if (surveyWithCreatedFramework) {
            newState = {
              ...newState,
              survey: surveyWithCreatedFramework
            };
            if (createRequest) {
              requests.push(createRequest);
            }
          }
        }
      }
    });
  }

  const questionDestinationPosition = {
    position: destinationPosition,
    questionId: destinationQuestionId
  };

  if (
    surveyToModify.content.flows.indexOf(
      questionDestinationPosition.questionId
    ) > -1
  ) {
    const destinationQuestionIndex = surveyToModify.content.flows.indexOf(
      questionDestinationPosition.questionId
    );

    const destinationIndex =
      questionDestinationPosition.position === 'BEFORE'
        ? destinationQuestionIndex
        : destinationQuestionIndex + 1;

    surveyToModify.content.flows.splice(destinationIndex, 0, newGroup.id);
  } else {
    const contentGroup = surveyToModify.content.groups.find(
      g => g.flows.indexOf(questionDestinationPosition.questionId) > -1
    );

    if (contentGroup) {
      const destinationQuestionIndex = contentGroup.flows.indexOf(
        questionDestinationPosition.questionId
      );

      const destinationIndex =
        questionDestinationPosition.position === 'BEFORE'
          ? destinationQuestionIndex
          : destinationQuestionIndex + 1;

      contentGroup.flows.splice(destinationIndex, 0, newGroup.id);
    }
  }

  surveyToModify.content.groups = [
    ...surveyToModify.content.groups,
    newGroup,
    ...newGroupsToAdd
  ];

  newState = {
    ...newState,
    survey: {
      ...newState.survey,
      content: surveyToModify.content,
      questions: {
        ...surveyToModify.questions,
        ...newQuestionToAdd
      }
    }
  };

  // Set new question as active question
  newState.activeFlow = newGroup;

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  // Sort distributor groups according to branch jumps
  sortDistributorGroupsAndJumps(newState);

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  const duplicatedQuestions = Object.values(newQuestionToAdd).map(q => q);

  return { newState, duplicatedQuestions, newGroupId, requests };
};
