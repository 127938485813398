import React from 'react';

import styles from './Legend.module.css';

export default ({ themeColors }) => {
  const themeColor = themeColors.reduce(
    (acc, tC) => ({
      ...acc,
      [tC.name]: tC.colorCode
    }),
    {}
  );

  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_2
            }}
          />
          <div>
            Problems in this zone are important to solve for your target
            audience and moreover your target audience finds the current
            solutions moderate to very challenging.{' '}
            <span className={styles.bold}>
              These problems are in fact opportunities to innovate (new markets
              without competition) so you should focus on them
            </span>
            , especially if you can identify lovers above the dotted line,
            because this indicates that there are people for whom the problem is
            challenging enough to start looking for new solutions. As a next
            step you can come up with new ideas to solve these problems by how
            might we statements.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_3
            }}
          />
          <div>
            Problems in this zone are important to solve for your target
            audience, however your target audience doesn’t find the current
            solutions very challenging.{' '}
            <span className={styles.bold}>
              In fact these problems represent a relevant but competitive market
            </span>
            . These problems can be worth focusing on depending on your strategy
            &amp; possible competitive assets in solution phase.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: '1px solid #e9e7e7',
              backgroundColor: themeColor.COLOR_1
            }}
          />
          <div>
            Problems in this zone are not important to solve. In general,{' '}
            <span className={styles.bold}>
              you should not spend resources on solving these problems
            </span>
            , but there may be opportunities for luxury products.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: '1px solid rgba(255,255,255,0)',
              backgroundColor: 'rgba(255,255,255,0)'
            }}
          />
          <div className={styles.dots}>...</div>
          <div>Early adopter benchmark</div>
        </div>
      </div>
    </div>
  );
};
