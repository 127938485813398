import { QUESTION_TYPES } from '../constants';

export const getNumberOfQuestionsToCharge = surveyLists => {
  const processedSurveyLists =
    surveyLists &&
    surveyLists.filter(
      q =>
        q.type !== QUESTION_TYPES.DISTRIBUTOR &&
        (!q.profiling || !q.profiling.isProfilingQuestion) &&
        q.type !== QUESTION_TYPES.MATRIX
    );
  const lengthOfInitialProcessedSurveyLists = processedSurveyLists.length;

  const matrixQuestions =
    surveyLists && surveyLists.filter(q => q.type === QUESTION_TYPES.MATRIX);
  const matchingMatrixRows = matrixQuestions.reduce((acc, q) => {
    const matrixRows =
      q.choices &&
      q.choices.filter(
        c =>
          q.matrix && q.matrix.decisionFrameworkType === c.decisionFrameworkType
      );
    return acc + (matrixRows ? matrixRows.length : 0);
  }, 0);

  return lengthOfInitialProcessedSurveyLists + 2 * (matchingMatrixRows || 0);
};

export default getNumberOfQuestionsToCharge;
