import React from 'react';

import Loader from '../../../Loader/Loader';

import getFormattedNumber from '../../../../../../helpers/getFormattedNumber';

import styles from './ValueMetric.module.css';

export default ({ title, value, description, metricWidth = 100 }) => {
  if (!value) {
    return (
      <div className={styles.container} style={{ width: `${metricWidth}%` }}>
        <div className={styles.loadingContainer}>
          <Loader fixed={false} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} style={{ width: `${metricWidth}%` }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.valueContainer}>
        <div className={styles.value}>{getFormattedNumber(value.value)}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};
