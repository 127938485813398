import React, { useState, useEffect } from 'react';

import SurveyTimeline from './components/SurveyTimeline/SurveyTimeline';
import PublishSurveyJumbotron from './components/PublishSurveyJumbotron/PublishSurveyJumbotron';
import Step from './components/Step/Step';

import {
  getLastEvent,
  jumbotronLabels,
  stepTypes,
  moments
} from './PublishSurvey';

export default ({ survey, getSurvey }) => {
  const [timeline, setTimeline] = useState([]);
  const [jumbotronLabel, setJumbotronLabel] = useState(null);
  const [showTimeline, setShowTimeline] = useState(false);
  const [lastEvent, setLastEvent] = useState(null);

  useEffect(
    () => {
      setTimeline(
        survey.history.sort(
          (a, b) => new Date(a.createdAt) + new Date(b.createdAt)
        )
      );
      setLastEvent(getLastEvent(survey.history));
    },
    [survey]
  );

  useEffect(
    () => {
      if (lastEvent) {
        setJumbotronLabel(
          lastEvent.type === stepTypes.REQUEST_APPROVAL_CANCEL
            ? null
            : jumbotronLabels[lastEvent.type]
        );
        setShowTimeline(lastEvent.type !== stepTypes.REQUEST_APPROVAL_CANCEL);
      }
    },
    [lastEvent]
  );

  if (showTimeline) {
    let steps = [];

    if (lastEvent.type === stepTypes.REQUEST_APPROVAL) {
      steps = [
        <>
          <Step
            ket={lastEvent.id}
            author={lastEvent.client.email}
            date={new Date(lastEvent.createdAt)}
            isStart
            moment={moments.NOW}
            type={stepTypes.REQUEST_APPROVAL}
            getSurvey={getSurvey}
            survey={survey}
          />
          <Step moment={moments.POTENTIAL} type={stepTypes.INFO} />
        </>
      ];
    } else {
      steps = timeline
        .filter(t => t.type !== stepTypes.REQUEST_APPROVAL_CANCEL)
        .map((t, i, arr) => (
          <Step
            key={t.id}
            author={t.client.email}
            date={new Date(t.createdAt)}
            isStart={i === 0 || arr.length === 1}
            moment={i === arr.length - 1 ? moments.NOW : moments.PAST}
            type={stepTypes[t.type]}
            getSurvey={getSurvey}
            survey={survey}
          />
        ));
    }

    return (
      <>
        {jumbotronLabel && (
          <PublishSurveyJumbotron description={jumbotronLabel} />
        )}
        <SurveyTimeline steps={steps} />
      </>
    );
  }

  return null;
};
