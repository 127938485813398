import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported
import Icon from '../../Icon';

const AnswerThumbnail = props => {
  const {
    answer,
    answerIndex,
    onRemoveCoverPhoto,
    onMediaUpload,
    block,
    allowEdit
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  if (!allowEdit && answer && !answer.image_filename) {
    return null;
  }

  return (
    <div className="answer-cover-image-container">
      <div className="left-container">
        {answer && answer.image_filename ? (
          [
            <div
              role="presentation"
              className="cover-image-preview"
              style={{
                backgroundImage: `url(${answer.image_filename})`
              }}
              onClick={() => {
                setIsOpen(true);
              }}
              key={`${block.id}-thumbnail`}
            />,
            isOpen && (
              <Lightbox
                mainSrc={answer.image_filename}
                onCloseRequest={() => setIsOpen(false)}
                key={`${block.id}-lightbox`}
              />
            )
          ]
        ) : (
          <span className="image-placeholder">
            <Icon type="image" />
          </span>
        )}
      </div>
      {allowEdit ? (
        <div className="right-container">
          {answer && answer.image_filename ? (
            <span
              role="presentation"
              className="action"
              onClick={() => {
                onRemoveCoverPhoto('answers', answerIndex);
              }}
            >
              <span>
                <Icon type="close" />
              </span>
            </span>
          ) : (
            <span className="action">
              <input
                type="file"
                onChange={event => {
                  onMediaUpload(event.target.files[0], 'answers', answerIndex);
                }}
                accept="'.gif,.jpg,.jpeg,.png,.bmp'"
                className="file-upload-input"
              />
              <span>
                <Icon type="add" />
              </span>
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AnswerThumbnail;
