import React from 'react';

import styles from '../../TargetAudience.module.css';

const OptionsSelector = props => {
  const { selectedOptions, options, onOptionClick } = props;

  const optionsList = [];

  if (options) {
    options.forEach(option => {
      optionsList.push(
        <div
          key={option}
          role="presentation"
          className={
            selectedOptions && selectedOptions.indexOf(option) > -1
              ? `${styles.valueOption} ${styles.active}`
              : styles.valueOption
          }
          onClick={() => {
            onOptionClick(option);
          }}
        >
          {option}
        </div>
      );
    });
  }

  return <div className={styles.pickers}>{optionsList}</div>;
};

export default OptionsSelector;
