import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { UPDATE_RESULTS_WORKSPACE } from '../../graphql/Workspaces';

import Popup from '../../../../../Popup';
import Population from './components/Population/Population';
import ManagePopulation from './components/ManagePopulation/ManagePopulation';

import removePopulationsTypename from '../../helpers/removePopulationsTypename';

import addIcon from '../../../../../../../assets/img/add.svg';
import infoIcon from '../../../../../../../assets/img/info.svg';
import createpopulationheardy from '../../../../../../../assets/img/createpopulationheardy.png';

import styles from './Sidebar.module.css';

const Sidebar = ({
  selectedWorkspace,
  setSelectedWorkspace,
  filteredResultsCount,
  blocks,
  populationColors,
  makeResultsFiltering,
  calculatePopulationResultsCount,
  activePopulations,
  setActivePopulations
}) => {
  const [selectedPopulationIndex, setSelectedPopulationIndex] = useState(null);

  useEffect(() => {
    if (selectedWorkspace && selectedWorkspace.populations) {
      makeResultsFiltering(selectedWorkspace.populations);
    }
  }, []);

  useEffect(
    () => {
      if (
        activePopulations.length &&
        selectedWorkspace.populations.length > activePopulations.length
      ) {
        setActivePopulations([...activePopulations, true]);
      } else {
        const populations = [];
        for (let i = 0; i < selectedWorkspace.populations.length; i += 1) {
          populations.push(true);
        }
        setActivePopulations(populations);
      }
    },
    [selectedWorkspace.populations]
  );

  const [updateResultsWorkspace] = useMutation(UPDATE_RESULTS_WORKSPACE, {
    onCompleted: response => {
      const selectedWorkspaceResult =
        response.updateResultsWorkspace.resultsWorkspace;
      selectedWorkspaceResult.populations = removePopulationsTypename(
        selectedWorkspaceResult.populations
      );
      setSelectedWorkspace({
        ...selectedWorkspace,
        ...selectedWorkspaceResult.populations
      });
      makeResultsFiltering(selectedWorkspaceResult.populations);
    }
  });

  const stringifyWorkspacePopulation = selectedWorkspaceUpdate => {
    const selectedWorkspaceUpdateReference = JSON.parse(
      JSON.stringify(selectedWorkspaceUpdate)
    );

    selectedWorkspaceUpdateReference.questions = selectedWorkspaceUpdateReference.questions.map(
      question => question.id
    );

    updateResultsWorkspace({ variables: selectedWorkspaceUpdateReference });
  };

  const addPopulationClick = labels => {
    const selectedWorkspaceUpdate = selectedWorkspace;

    selectedWorkspaceUpdate.populations = [
      ...selectedWorkspaceUpdate.populations,
      ...[labels]
    ];

    stringifyWorkspacePopulation(selectedWorkspaceUpdate);
  };

  const duplicatePopulationClick = (labels, index) => {
    const selectedWorkspaceUpdate = selectedWorkspace;
    if (index === selectedWorkspaceUpdate.populations.length - 1) {
      selectedWorkspaceUpdate.populations = [
        ...selectedWorkspaceUpdate.populations,
        ...[labels]
      ];
    } else {
      const p1 = selectedWorkspaceUpdate.populations.slice(0, index + 1);
      const p2 = selectedWorkspaceUpdate.populations.slice(index + 1);

      selectedWorkspaceUpdate.populations = [...p1, ...[labels], ...p2];
    }

    stringifyWorkspacePopulation(selectedWorkspaceUpdate);
  };

  const updatePopulationClick = (labels, populationIndex) => {
    const selectedWorkspaceUpdate = selectedWorkspace;

    selectedWorkspaceUpdate.populations[populationIndex] = labels;

    stringifyWorkspacePopulation(selectedWorkspaceUpdate);
  };

  const removePopulationClick = index => {
    const selectedWorkspaceUpdate = selectedWorkspace;

    selectedWorkspaceUpdate.populations.splice(index, 1);

    stringifyWorkspacePopulation(selectedWorkspaceUpdate);
  };

  const isPopulationEdit =
    Number.isInteger(selectedPopulationIndex) && selectedPopulationIndex > -1;

  return (
    <div
      className={
        selectedPopulationIndex || selectedPopulationIndex === 0
          ? `${styles.container} ${styles.popupVisible}`
          : styles.container
      }
    >
      {selectedWorkspace &&
      (!selectedWorkspace.populations ||
        (selectedWorkspace.populations &&
          selectedWorkspace.populations.length < 5)) ? (
        <div className={styles.createAPopulationButtonContainer}>
          <div
            className={`${
              styles.createAPopulationButton
            } workspaces-sidebar-create-population-button`}
            role="presentation"
            onClick={() => setSelectedPopulationIndex(true)}
          >
            <img src={addIcon} alt="Add population" /> Create a population
          </div>
        </div>
      ) : null}
      {selectedWorkspace &&
      selectedWorkspace.populations &&
      selectedWorkspace.populations.length ? (
        <div>
          <div
            className={`${
              styles.populationContainer
            } workspaces-sidebar-populations-container`}
          >
            {selectedWorkspace.populations.map((population, index) => (
              <Population
                key={`sidebar-population-${index.toString()}`}
                population={population}
                index={index}
                populationColor={populationColors[index]}
                selectedPopulationIndex={selectedPopulationIndex}
                setSelectedPopulationIndex={setSelectedPopulationIndex}
                filteredResultsCount={filteredResultsCount[index]}
                removePopulationClick={removePopulationClick}
                duplicatePopulationClick={duplicatePopulationClick}
                activePopulations={activePopulations}
                setActivePopulations={setActivePopulations}
                selectedWorkspacePopulationsLength={
                  selectedWorkspace.populations.length
                }
              />
            ))}
            {selectedWorkspace.populations &&
            selectedWorkspace.populations.length === 0 ? (
              <img
                src={createpopulationheardy}
                alt="Create population"
                className={styles.createPopulationHeadry}
              />
            ) : null}
            {selectedWorkspace &&
              selectedWorkspace.populations &&
              selectedWorkspace.populations.length < 2 && (
                <div className={styles.populationsInfoContainer}>
                  <div className={styles.infoTitleWrapper}>
                    <img
                      src={infoIcon}
                      className={styles.infoIcon}
                      alt="Populations info"
                    />
                    <span className={styles.infoTitle}>Populations</span>
                  </div>
                  <div className={styles.infoText}>
                    You can create different populations to compare the
                    behaviour of different market segments and analyze the
                    differences in a statistically sound way.
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : null}
      {selectedPopulationIndex || selectedPopulationIndex === 0 ? (
        <Popup
          component={
            <ManagePopulation
              selectedPopulation={
                selectedWorkspace.populations[selectedPopulationIndex]
              }
              selectedPopulationIndex={selectedPopulationIndex}
              setSelectedPopulationIndex={setSelectedPopulationIndex}
              addPopulationClick={addPopulationClick}
              removePopulationClick={removePopulationClick}
              updatePopulationClick={updatePopulationClick}
              isPopulationEdit={isPopulationEdit}
              blocks={blocks}
              calculatePopulationResultsCount={calculatePopulationResultsCount}
              activePopulations={activePopulations}
              setActivePopulations={setActivePopulations}
            />
          }
          onClose={() => setSelectedPopulationIndex(null)}
        />
      ) : null}
    </div>
  );
};

export default Sidebar;
