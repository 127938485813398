import React from 'react';
import './LoginRegistration.css';
import loginImage from '../../assets/img/login-cover.jpg';
import availableAppleStore from '../../assets/img/available-apple-store.png';
import availablePlayStore from '../../assets/img/available-play-store.png';

const AuthWelcome = ({ customClass }) => (
  <div
    className={
      customClass ? `welcome-container ${customClass}` : 'welcome-container'
    }
    style={{ backgroundImage: `url(${loginImage})` }}
  >
    <div className="welcome-container-content">
      <div>
        <div className="welcome-message">Clients Dashboard</div>
        <div className="welcome-message-description">
          The BUFFL client platform offers a clear and structured overview of
          your active and finished surveys. Results will be updated continuously
          and you can track the course of your survey.
        </div>
      </div>

      <div className="mobile-apps-container">
        <a
          href="https://itunes.apple.com/be/app/buffl/id1356715033?l=nl&mt=8"
          target="new"
        >
          <img src={availableAppleStore} alt="BUFFL on Apple Store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=org.nativescript.buffl"
          target="new"
        >
          <img src={availablePlayStore} alt="BUFFL on Play Store" />
        </a>
      </div>
    </div>
  </div>
);

export default AuthWelcome;
