import React, { useContext, useState, useRef, useEffect } from 'react';

import {
  SURVEY_END_TYPES,
  QUESTION_TYPES
} from '../../../../helpers/constants';
import SurveyBuilderContext from '../../../../SurveyBuilderContext';
import Icon from '../../../Icon/Icon';
import ConstraintTooltip from '../ConstraintTooltip/ConstraintTooltip';
import ShowInsideScreen from '../../../../../ShowInsideScreen/ShowInsideScreen';
import LayersIcon from '../../../Icon/custom/LayersIcon';

import getBlockQuestion from '../../../../helpers/getBlockQuestion';

import styles from './QuestionsListDropdown.module.css';

import blackArrrowDownImage from '../../../../../../../assets/black-arrow-down.svg';

const END_NODES = {
  SUCCESSFULL: {
    icon: 'finish',
    type: SURVEY_END_TYPES.SUCCESSFULL,
    label: 'Finish survey'
  },
  NOT_ELIGIBLE: {
    icon: 'finish',
    type: SURVEY_END_TYPES.NOT_ELIGIBLE,
    label: 'Users not eligible'
  }
};

export default ({
  containerStyle,
  onOptionClick,
  value,
  hideSurveyEndJumps,
  isActionAllowedChecker,
  allowedValueOnly,
  firstOfSingleValuesSelected
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [dropdownOptionsPopupRef, setDropdownOptionsPopupRef] = useState(null);
  const [scrolledFromTop, setScrolledFromTop] = useState(0);

  const listInnerRef = useRef();

  const { state } = useContext(SurveyBuilderContext);

  useEffect(() => setScrolledFromTop(0), [showPopup]);

  const getFlows = () => {
    const flows = [];
    const addedGroupIds = [];
    let addedIndex = 0;

    state.surveyLists.forEach((flow, index) => {
      if (
        allowedValueOnly &&
        flow.id !== allowedValueOnly &&
        !firstOfSingleValuesSelected
      ) {
        return;
      }

      const addGroupElement = (nextElement, first) => {
        if (
          !flow.group &&
          nextElement.group &&
          nextElement.group.parentGroup &&
          addedGroupIds.indexOf(nextElement.group.parentGroup.id) === -1
        ) {
          flows.push(
            getOption(nextElement.group.parentGroup, index, addedIndex)
          );
          addedIndex += 1;
          addedGroupIds.push(nextElement.group.parentGroup.id);
        }
        if (
          flow.group &&
          nextElement.group &&
          nextElement.group.parentGroup &&
          flow.group.id !== nextElement.group.parentGroup.id &&
          addedGroupIds.indexOf(nextElement.group.parentGroup.id) === -1
        ) {
          flows.push(
            getOption(nextElement.group.parentGroup, index, addedIndex)
          );
          addedIndex += 1;
          addedGroupIds.push(nextElement.group.parentGroup.id);
        }

        if (
          !flow.group &&
          nextElement.group &&
          addedGroupIds.indexOf(nextElement.group.id) === -1
        ) {
          flows.push(getOption(nextElement.group, index, addedIndex));
          addedIndex += 1;
          addedGroupIds.push(nextElement.group.id);
          return;
        }
        if (
          flow.group &&
          nextElement.group &&
          (flow.group.id !== nextElement.group.id || first) &&
          addedGroupIds.indexOf(nextElement.group.id) === -1
        ) {
          flows.push(getOption(nextElement.group, index, addedIndex));
          addedIndex += 1;
          addedGroupIds.push(nextElement.group.id);
        }
      };

      // For first element only
      if (index === 0) {
        addGroupElement(state.surveyLists[0], true);
        addedIndex += 1;
      }

      flows.push(getOption(flow, index, addedIndex));
      addedIndex += 1;

      // If next question is group element
      if (state.surveyLists[index + 1]) {
        addGroupElement(state.surveyLists[index + 1]);
      }
    });

    if (!hideSurveyEndJumps && flows.length > 1) {
      flows.push(
        <div
          key="options-list-popup-item-horizondal-line"
          className={styles.horizontalLine}
        />
      );
    }

    if (
      !hideSurveyEndJumps &&
      (!allowedValueOnly ||
        firstOfSingleValuesSelected ||
        (allowedValueOnly && allowedValueOnly === SURVEY_END_TYPES.SUCCESSFUL))
    ) {
      flows.push(
        <div
          key={`options-list-popup-item-successfull-end}`}
          className={`${styles.question} dropdown-question`}
          role="presentation"
          onClick={() => onOptionClick(SURVEY_END_TYPES.SUCCESSFUL)}
        >
          <div className={styles.questionContainer}>
            <Icon type="finish" />
            <span>{END_NODES.SUCCESSFULL.label}</span>
          </div>
        </div>
      );
    }

    if (
      !hideSurveyEndJumps &&
      (!allowedValueOnly ||
        firstOfSingleValuesSelected ||
        (allowedValueOnly &&
          allowedValueOnly === SURVEY_END_TYPES.NOT_ELIGIBLE))
    ) {
      flows.push(
        <div
          key={`options-list-popup-item-failed-end}`}
          className={`${styles.question} dropdown-question`}
          role="presentation"
          onClick={() => onOptionClick(SURVEY_END_TYPES.NOT_ELIGIBLE)}
        >
          <div className={styles.questionContainer}>
            <Icon type="finish" />
            <span>{END_NODES.NOT_ELIGIBLE.label}</span>
          </div>
        </div>
      );
    }

    if (allowedValueOnly && !firstOfSingleValuesSelected) {
      flows.push([
        <div
          key="options-list-popup-item-horizondal-line"
          className={styles.horizontalLine}
        />,
        <div
          key={`options-list-popup-item-successfull-end}`}
          className={`${styles.question} dropdown-question`}
        >
          <div className={styles.footer}>You can only define one path</div>
        </div>
      ]);
    }

    return flows;
  };

  const getNestingLevelPadding = option => {
    if (
      (option.group && option.group.nestingLevel === 1) ||
      (option.nestingLevel === 2 && option.flows && option.flows.length)
    ) {
      return <div className={styles.nestingLevel1Padding} />;
    }

    if (option.group && option.group.nestingLevel === 2) {
      return <div className={styles.nestingLevel2Padding} />;
    }

    return null;
  };

  const getQuestionText = question => {
    let questionText = '';
    if (
      question.type === QUESTION_TYPES.MATRIX &&
      question.matrix &&
      question.matrix.xQuestion &&
      question.matrix.yQuestion
    ) {
      questionText = `${getBlockQuestion(
        question.matrix.xQuestion
      )}\n${getBlockQuestion(question.matrix.yQuestion)}`;
    } else if (question.question) {
      questionText = getBlockQuestion(question.question);
    } else if (question.label) {
      questionText = question.label;
    }
    return questionText;
  };

  const getOption = (option, index, addedIndex) => {
    if (option) {
      const isActionAllowed =
        isActionAllowedChecker && isActionAllowedChecker(option);

      const isDistributor = option.type === QUESTION_TYPES.DISTRIBUTOR;

      const icon = isDistributor ? (
        <span className={styles.distributorIconContainer}>
          <Icon type={option.type} />
        </span>
      ) : (
        <Icon type={option.type} />
      );

      const questionText = getQuestionText(option);

      return (
        <div
          key={`options-list-popup-item-${addedIndex.toString()}`}
          className={`${styles.question} dropdown-question`}
          role="presentation"
          onClick={() => {
            if (isActionAllowed && isActionAllowed.allowed) {
              onOptionClick(option.id);
            }
          }}
        >
          <div
            className={
              option.constraints
                ? `${styles.questionContainerWithConstraints} ${
                    styles.questionContainer
                  }`
                : styles.questionContainer
            }
          >
            {getNestingLevelPadding(option)}
            {option.formattedGlobalIndex && (
              <span className={styles.globalIndex}>
                {option.formattedGlobalIndex}.{' '}
              </span>
            )}
            {option.type ? (
              icon
            ) : (
              <span className={styles.groupIcon}>
                <LayersIcon width={12} height={12} />
              </span>
            )}
            {isDistributor && <span>Distributor</span>}
            {!isDistributor && <span>{questionText}</span>}
          </div>
          {isActionAllowed && isActionAllowed.allowed === false && (
            <ConstraintTooltip
              label={isActionAllowed.message}
              scrolledFromTop={scrolledFromTop}
              addedIndex={addedIndex}
            />
          )}
        </div>
      );
    }

    return null;
  };

  const getSelectedQuestion = () => {
    if (!value) return '-';
    let selectedFlowLabel = null;
    const rand = Math.floor(Math.random() * 1000000000) + 1000000;

    const { content } = state.survey;

    if (
      value === SURVEY_END_TYPES.SUCCESSFUL ||
      value === SURVEY_END_TYPES.NOT_ELIGIBLE
    ) {
      selectedFlowLabel = [
        <span key={`${rand}-index`} />,
        <Icon type="finish" key={`${rand}-icon`} />,
        <span key={`${rand}-label`}>
          {value === SURVEY_END_TYPES.SUCCESSFUL
            ? 'Finish survey'
            : 'Users not eligible'}
        </span>
      ];
    }

    if (!selectedFlowLabel) {
      const question = state.surveyLists.find(sL => sL.id === value);
      if (question) {
        const isDistributor = question.type === QUESTION_TYPES.DISTRIBUTOR;
        const icon = isDistributor ? (
          <span className={styles.distributorIconPContainer}>
            <Icon type={question.type} key={`${rand}-icon`} />
          </span>
        ) : (
          <Icon type={question.type} key={`${rand}-icon`} />
        );

        selectedFlowLabel = [
          question.formattedGlobalIndex ? (
            <span key={`${rand}-index`}>{question.formattedGlobalIndex}. </span>
          ) : null,
          icon,
          <span key={`${rand}-label`}>
            {isDistributor ? 'Distributor' : getQuestionText(question)}
          </span>
        ];
      }
    }

    if (!selectedFlowLabel) {
      const { groups } = content;
      const selectedGroup = groups.find(g => g.id === value);

      selectedFlowLabel = [
        <span className={styles.groupIcon} key={`${rand}-icon`}>
          <LayersIcon width={12} height={12} />
        </span>,
        <span key={`${rand}-label`}>
          {selectedGroup && selectedGroup.label}
        </span>
      ];
    }

    return selectedFlowLabel;
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop } = listInnerRef.current;
      setScrolledFromTop(scrollTop);
    }
  };

  return (
    <div
      className={
        showPopup
          ? `${styles.dropdownContainer} ${
              styles.dropdownVisisble
            } ${containerStyle}`
          : `${
              styles.dropdownContainer
            } ${containerStyle} question-logic-popup-destination`
      }
      style={{ backgroundImage: `url(${blackArrrowDownImage})` }}
      role="presentation"
      onClick={event => {
        event.stopPropagation();
        setShowPopup(!showPopup);
      }}
    >
      <span className={styles.dropdownContainerLabel}>
        {getSelectedQuestion()}
      </span>
      {showPopup && (
        <ShowInsideScreen
          positionStyle={{
            top: '33px',
            left: '10px',
            bottomPosition: '33px'
          }}
          childReference={dropdownOptionsPopupRef}
          onBlur={() => setShowPopup(!showPopup)}
        >
          <div
            className={`${
              styles.absoluteContainer
            } dropdown-questions-absolute-container`}
            ref={r => setDropdownOptionsPopupRef(r)}
          >
            <div
              className={`${styles.container} dropdown-questions-container`}
              ref={listInnerRef}
              onScroll={() => onScroll()}
            >
              {getFlows()}
            </div>
          </div>
        </ShowInsideScreen>
      )}
    </div>
  );
};
