import React, { useState, useEffect } from 'react';
import styles from './SendPushNotifications.module.css';
import SendNotificationFilter from '../SendNotificationFilter/SendNotificationFilter';
import DropdownSelector from '../DropdownSelector/DropdownSelector';
import Loader from '../../../campaigns/components/Loader/Loader';
import {
  SEND_PUSH_NOTIFICATIONS,
  SCHEDULE_PUSH_NOTIFICATIONS,
  GET_PUSH_NOTIFICATIONS_TARGET_COUNT
} from '../../../graphql/PushNotifications';
import useLazyQuery from '../../../surveys/hooks/useLazyQuery';
import Popup from '../../../campaigns/components/Popup';
import SchedulePopup from '../SchedulePopup/SchedulePopup';

import prepareActiveFiltersForRequest from '../helpers/prepareActiveFiltersForRequest';

const pushNotificationTemplates = [
  {
    label: 'Nieuwe BUFFL survey beschikbaar! 🐃',
    value: {
      title: 'Nieuwe BUFFL survey beschikbaar! 🐃',
      description: 'Laat jouw mening weten en verdien tokens! 💌'
    }
  },
  {
    label: 'Give us your opinion...',
    value: {
      title: 'Give us your opinion...',
      description: 'About BUFFL! 😅'
    }
  },
  {
    label: 'We want to hear what you think! 👂',
    value: {
      title: 'We want to hear what you think! 👂',
      description: 'Go to the BUFFL app 😉!'
    }
  },
  {
    label: 'Got a moment? ⌛',
    value: {
      title: 'Got a moment? ⌛',
      description: 'There is a new survey ready for you! 🌅'
    }
  }
];

const PushNotificationsCenter = props => {
  const {
    filterConfiguration,
    FILTER_CONSTANTS,
    COMMUNITY_MANAGEMENT_TYPES,
    activeFilters,
    setActiveFilters,
    isListFetching,
    pushNotificationToSendAgain,
    setPushNotificationToSendAgain,
    setPushNotificationDetails,
    LIST_TABS,
    fetchOptionsExecute,
    section,
    updateActiveFilters,
    SECTION_KEYS
  } = props;

  const [pushNotificationProps, setPushNotificationProps] = useState({
    title: '',
    description: ''
  });
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [requestCompletedStatus, setRequestCompletedStatus] = useState(null);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [targetUsers, setTargetUsers] = useState(false);

  const maxPushTitleLength = 39;

  useEffect(
    () => {
      if (pushNotificationToSendAgain) {
        setPushNotificationProps({
          title: pushNotificationToSendAgain.title,
          description: pushNotificationToSendAgain.description
        });

        if (pushNotificationToSendAgain.filter_string) {
          setActiveFilters(
            JSON.parse(pushNotificationToSendAgain.filter_string)
          );
        }
      }
    },
    [pushNotificationToSendAgain]
  );

  useEffect(
    () => () => {
      setPushNotificationProps({
        title: '',
        description: ''
      });

      setActiveFilters([]);
      setPushNotificationToSendAgain({});
    },
    []
  );

  const allowedToSendPush = () => {
    if (
      pushNotificationProps &&
      pushNotificationProps.title &&
      pushNotificationProps.title !== '' &&
      pushNotificationProps.description &&
      pushNotificationProps.description !== '' &&
      pushNotificationProps.title.length < 40
    ) {
      return true;
    }
    return false;
  };

  const sendPushNotifications = useLazyQuery(SEND_PUSH_NOTIFICATIONS, {
    fetchPolicy: 'no-cache'
  });

  const schedulePushNotifications = useLazyQuery(SCHEDULE_PUSH_NOTIFICATIONS, {
    fetchPolicy: 'no-cache'
  });

  const getPushNotificationTargetCountQuery = useLazyQuery(
    GET_PUSH_NOTIFICATIONS_TARGET_COUNT,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const sendPushNotification = () => {
    const sendPushNotificationApi = async () => {
      const activeFiltersWithValue = activeFilters.filter(filter => {
        if (filter.name === '') return false;
        if (Array.isArray(filter.values)) {
          return !!filter.values.length;
        }
        if (Object.keys(filter.values).length) return true;
        return null;
      });
      setRequestProcessing(true);

      const newPushNotification = await sendPushNotifications({
        variables: {
          ...pushNotificationProps,
          type: section,
          activeFilters: prepareActiveFiltersForRequest(activeFiltersWithValue)
        }
      });

      if (
        newPushNotification &&
        newPushNotification.data &&
        newPushNotification.data.sendPushNotifications &&
        newPushNotification.data.sendPushNotifications.newPushNotification
      ) {
        const newPushNotificationDetails =
          newPushNotification.data.sendPushNotifications.newPushNotification;

        const newPushNotificationFormatted = {
          id: newPushNotificationDetails.id,
          count: newPushNotificationDetails.count,
          createdAt: newPushNotificationDetails.createdAt,
          description: newPushNotificationDetails.description,
          filter_string: newPushNotificationDetails.filter_string,
          title: newPushNotificationDetails.title,
          servicePushIds: newPushNotificationDetails.servicePushIds,
          scheduled: newPushNotificationDetails.scheduled,
          scheduledDate: newPushNotificationDetails.scheduledDate
        };
        setPushNotificationDetails(newPushNotificationFormatted);
        fetchOptionsExecute(
          false,
          true,
          [newPushNotificationFormatted],
          LIST_TABS.HISTORY
        );
        setRequestCompletedStatus(true);
      } else {
        setRequestCompletedStatus(false);
      }
      setRequestProcessing(false);
    };

    sendPushNotificationApi();
  };

  const schedulePushNotification = async date => {
    if (date && allowedToSendPush()) {
      const formattedDate = new Date(date);

      const activeFiltersWithValue = activeFilters.filter(filter => {
        if (filter.name === '') return false;
        if (Array.isArray(filter.values)) {
          return !!filter.values.length;
        }
        if (Object.keys(filter.values).length) return true;
        return null;
      });
      setRequestProcessing(true);

      const newPushNotification = await schedulePushNotifications({
        variables: {
          ...pushNotificationProps,
          type: section,
          activeFilters: prepareActiveFiltersForRequest(activeFiltersWithValue),
          date: formattedDate
        }
      });

      if (
        newPushNotification &&
        newPushNotification.data &&
        newPushNotification.data.schedulePushNotifications &&
        newPushNotification.data.schedulePushNotifications.newPushNotification
      ) {
        const newPushNotificationDetails =
          newPushNotification.data.schedulePushNotifications
            .newPushNotification;

        const newPushNotificationFormatted = {
          id: newPushNotificationDetails.id,
          count: newPushNotificationDetails.count,
          createdAt: newPushNotificationDetails.createdAt,
          description: newPushNotificationDetails.description,
          filter_string: newPushNotificationDetails.filter_string,
          title: newPushNotificationDetails.title,
          servicePushIds: newPushNotificationDetails.servicePushIds,
          scheduled: newPushNotificationDetails.scheduled,
          scheduledDate: newPushNotificationDetails.scheduledDate
        };
        setPushNotificationDetails(newPushNotificationFormatted);
        fetchOptionsExecute(
          false,
          true,
          [newPushNotificationFormatted],
          LIST_TABS.SCHEDULED
        );
        setRequestCompletedStatus(true);
      } else {
        setRequestCompletedStatus(false);
      }
      setRequestProcessing(false);
      setRequestCompletedStatus(false);
    }
  };

  if (requestCompletedStatus === true || requestCompletedStatus === false) {
    return (
      <div className={styles.sendPushContainer}>
        <div className={styles.title}>Send Push Notification</div>
        <div className={styles.inputContainer}>
          {requestCompletedStatus === true
            ? 'Push Scheduled Sucessfully'
            : 'Error occured. Please contact administrator'}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.sendPushContainer}>
      <div className={styles.title}>
        Send Push Notification
        <DropdownSelector
          options={pushNotificationTemplates}
          value={{}}
          filterDropdownStyle={styles.filterDropdownStyle}
          placeholder="Select from template"
          popupAlign="right"
          onSelectCompleted={value =>
            setPushNotificationProps({
              title: value.title,
              description: value.description
            })
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <div>
          <input
            className={styles.input}
            placeholder="Title"
            value={pushNotificationProps.title || ''}
            onChange={e => {
              if (e.target.value.length <= maxPushTitleLength)
                setPushNotificationProps({
                  ...pushNotificationProps,
                  ...{ title: e.target.value }
                });
            }}
          />
          <span className={styles.inputCharacterCount}>
            {maxPushTitleLength -
              (pushNotificationProps &&
              pushNotificationProps.title &&
              pushNotificationProps.title.length
                ? pushNotificationProps.title.length
                : 0)}
          </span>
        </div>
        <input
          className={styles.input}
          placeholder="Description"
          value={pushNotificationProps.description || ''}
          onChange={e =>
            setPushNotificationProps({
              ...pushNotificationProps,
              ...{ description: e.target.value }
            })
          }
        />
      </div>
      <SendNotificationFilter
        notificationProps={pushNotificationProps}
        filterConfiguration={filterConfiguration}
        FILTER_CONSTANTS={FILTER_CONSTANTS}
        COMMUNITY_MANAGEMENT_TYPES={COMMUNITY_MANAGEMENT_TYPES}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        updateActiveFilters={updateActiveFilters}
        getPushNotificationTargetCountQuery={
          getPushNotificationTargetCountQuery
        }
        countField="getPushNotificationTargetCount"
        section={section}
        SECTION_KEYS={SECTION_KEYS}
        targetUsers={targetUsers}
        setTargetUsers={setTargetUsers}
        showTargetUsersCount
      />
      {requestProcessing ? <Loader /> : null}
      {showSchedulePopup ? (
        <Popup
          component={
            <SchedulePopup
              schedulePushNotification={schedulePushNotification}
            />
          }
          onClose={() => setShowSchedulePopup(false)}
          showOverflow
        />
      ) : null}
      {!isListFetching && !requestProcessing ? (
        <div className={styles.footerContainer}>
          <div
            className={
              allowedToSendPush()
                ? `${styles.sendPushButton}`
                : `${styles.sendPushButton} ${styles.sendPushButtonDisabled}`
            }
            role="presentation"
            onClick={() => {
              if (
                pushNotificationProps.title &&
                pushNotificationProps.description &&
                allowedToSendPush()
              ) {
                sendPushNotification();
              }
            }}
          >
            Send now
          </div>
          <div
            className={
              allowedToSendPush()
                ? `${styles.schedulePushButton}`
                : `${styles.sendPushButton} ${styles.sendPushButtonDisabled}`
            }
            role="presentation"
            onClick={() => {
              if (
                pushNotificationProps.title &&
                pushNotificationProps.description &&
                allowedToSendPush()
              ) {
                setShowSchedulePopup(true);
              }
            }}
          >
            Schedule
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PushNotificationsCenter;
