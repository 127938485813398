const ObjectID = require('bson-objectid');

export default (successCriteriaValues, newQuestions) => {
  // We skip first two questions, Intro and Distributor
  const successCriteria = [];
  for (let i = 2; i < newQuestions.length; i += 2) {
    const newIndex = successCriteria.length;

    successCriteria.push({
      id: ObjectID().toString(),
      xValue: parseInt(successCriteriaValues[newIndex].x, 10),
      yValue: parseInt(successCriteriaValues[newIndex].y, 10),
      xQuestion: newQuestions[i].id,
      yQuestion: newQuestions[i + 1].id
    });
  }

  return successCriteria;
};
