import React, { useState, memo, useEffect } from 'react';

import AnswerThumbnail from './components/AnswerThumbnail/AnswerThumbnail';
import Icon from '../../../../../../../../../Icon/Icon';
import BranchIcon from '../../../../../../../../../Icon/custom/BranchIcon';
import AnswerLogicJump from './components/AnswerLogicJump/AnswerLogicJump';
import AnswerQuota from './components/AnswerQuota/AnswerQuota';
import AnswerConnectionLine from './components/AnswerConnectionLine/AnswerConnectionLine';
import AnswerJumpMessage from './components/AnswerJumpMessage/AnswerJumpMessage';
import AnswerText from './components/AnswerText/AnswerText';
import useOnScreen from '../../../../../../../../../../hooks/useOnScreen';

import textareaAutoExpand from '../../../../../../../../../../../../../helpers/textareaAutoExpand';

import useLazyQuery from '../../../../../../../../../../../../../hooks/useLazyQuery';

import { GET_MEDIA_UPLOAD_SAS } from '../../../../../../../../../../../../../../graphql/Survey';
import {
  QUESTION_TYPES,
  QUOTA_UNITS
} from '../../../../../../../../../../helpers/constants';

import styles from './Answer.module.css';

export default memo(
  ({
    choiceIndex,
    choices,
    choice,
    isOtherAnswer,
    isNoneOfTheAboveAnswer,
    randomOrder,
    isActiveQuestion,
    actions,

    questionId,
    rankAnswers,
    selectAtMost,
    allowOther,
    allowNoneOfTheAbove,
    isCommunityAssignQuestion,
    isMatrixQuestion,
    decisionFrameworkType,

    surveyListQuestion,
    surveyLists,
    surveyStructure,
    choiceIndexToSelect,
    setChoiceIndexToSelect,
    isFocused,
    isLastAnswer,

    otherAnswerIndex,
    noneOfTheAboveAnswerIndex,
    notAllowedTextContentEdit,
    surveyPageRef,
    minQuotaExceedsTotalSample,

    addDraftEmoji,
    setAddDraftEmoji,
    setActiveDraftButtons,
    toggleDraftButton,
    setToggleDraftButton
  }) => {
    const [showLogicJumpControls, setShowLogicJumpControls] = useState(false);
    const [internalRef, setInternalRef] = useState(null);
    const [minQuotaDisabled, setMinQuotaDisabled] = useState(false);

    const getMediaUploadSAS = useLazyQuery(GET_MEDIA_UPLOAD_SAS, {
      fetchPolicy: 'no-cache'
    });

    useEffect(
      () => {
        if (isFocused && internalRef) {
          internalRef.focus();
        }
      },
      [isFocused, internalRef]
    );

    // If active question is changed, and this MC question is not active, blur all answer text containers
    useEffect(
      () => {
        if (internalRef && !isActiveQuestion) {
          internalRef.blur();
        }
      },
      [isActiveQuestion]
    );

    useEffect(
      () => {
        if (internalRef && !isMatrixQuestion) {
          textareaAutoExpand(internalRef);
        }

        window.addEventListener('resize', onWindowResize);

        return () => {
          window.removeEventListener('resize', onWindowResize);
        };
      },
      [internalRef]
    );

    useEffect(
      () => {
        if (choice.quotaType && surveyLists && surveyLists.length) {
          const currentQuestionIndex = surveyLists.findIndex(
            q => q.id === questionId
          );
          if (currentQuestionIndex > -1) {
            const jumpOrDistributorExistBeforeQuestion = surveyLists.some(
              (q, qIndex) =>
                qIndex < currentQuestionIndex &&
                (q.type === QUESTION_TYPES.DISTRIBUTOR ||
                  (q.nextFlow || q.end) ||
                  (q.choices &&
                    q.choices.length &&
                    q.choices.some(c => c.nextFlow || c.end)) ||
                  (q.group && (q.group.nextFlow || q.group.end)))
            );
            if (jumpOrDistributorExistBeforeQuestion) {
              setMinQuotaDisabled(true);
              if (choice.quotaMinAmount) {
                const newChoice = choice;
                newChoice.quotaMinAmount = 0;
                actions.updateQuestionChoice(questionId, newChoice);
              }
            } else {
              setMinQuotaDisabled(false);
            }
          }
        }
      },
      [isActiveQuestion, choice]
    );

    const logicJumpsVisible =
      choice && (choice.nextFlow || choice.end || choice.quotaType);

    const shouldActiveIconLogicJumpControls =
      showLogicJumpControls || (choice.nextFlow || choice.end);
    const shouldShowLogicJumpControls =
      isActiveQuestion && shouldActiveIconLogicJumpControls;

    const shouldShowQuotaControls = isActiveQuestion && choice.quotaType;

    const onImageUpload = async e => {
      e.persist();
      const origFilename = e.target.files[0].name;
      actions.setCustomLoading(true);

      const {
        data: { mediaUploadSAS: { uri: sasURI, filename, uri } = {} } = {}
      } = await getMediaUploadSAS({
        variables: {
          fileName: origFilename
        }
      });

      if (!sasURI) {
        // FIXME: error
        return;
      }

      const xhr = new XMLHttpRequest();

      xhr.onload = () => {
        actions.setCustomLoading(false);
        if (xhr.status === 201) {
          const fileUri = uri.split('?');
          const newChoice = { ...choice };

          [newChoice.image] = fileUri;
          newChoice.imageFilename = filename;

          actions.updateQuestionChoice(questionId, newChoice, false);
        }
      };

      xhr.open('PUT', sasURI, true);
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

      xhr.send(e.target.files[0]);
    };

    const scrollOnEnter = () => {
      // Scroll a little bit
      const answerDiv = answerDivRef.getBoundingClientRect();
      const divBottomPosition =
        answerDiv.top + answerDivRef.offsetHeight + surveyPageRef.scrollTop;
      const viewpointBottom = surveyPageRef.scrollTop + window.innerHeight;

      if (divBottomPosition + 100 > viewpointBottom) {
        surveyPageRef.scrollTo(0, surveyPageRef.scrollTop + 100);
      }
    };

    const handleExistingAnswerKeyPress = event => {
      if (
        !notAllowedTextContentEdit &&
        event.key === 'Enter' &&
        !event.shiftKey
      ) {
        event.preventDefault();
        let customAttributes = {};
        if (isMatrixQuestion && decisionFrameworkType) {
          customAttributes = {
            ...customAttributes,
            decisionFrameworkType
          };
        }

        if (
          (choiceIndex === choices.length - 2 &&
            ((allowOther || allowNoneOfTheAbove) &&
              !(allowOther && allowNoneOfTheAbove))) ||
          (choiceIndex === choices.length - 3 &&
            allowOther &&
            allowNoneOfTheAbove)
        ) {
          // Check if is option above other answer
          addQuestionChoice(
            customAttributes,
            false,
            choices.length - (allowOther && allowNoneOfTheAbove ? 2 : 1)
          );
        } else if (!isLastAnswer) {
          scrollOnEnter();

          setChoiceIndexToSelect(choiceIndex + 1);
        } else {
          scrollOnEnter();

          addQuestionChoice(customAttributes, false);
        }
      }
    };

    const addQuestionChoice = (customAttributes, other, selectChoiceIndex) => {
      if (!isCommunityAssignQuestion) {
        actions.addQuestionChoice(questionId, customAttributes, other);

        if (selectChoiceIndex || selectChoiceIndex === 0) {
          setChoiceIndexToSelect(selectChoiceIndex);
        } else {
          setChoiceIndexToSelect(choices ? choices.length : 0);
        }
      }
    };

    // If window is resized, go through all answers and auto expand
    const onWindowResize = ev => {
      ev.preventDefault();
      if (internalRef && !isMatrixQuestion) {
        textareaAutoExpand(internalRef);
      }
    };

    // textareaAutoExpand(internalRef);

    const returnPlaceholder = () => {
      if (isOtherAnswer) {
        return 'Open answer';
      }
      if (isNoneOfTheAboveAnswer) {
        return 'None of the above';
      }
      return null;
    };

    const [answerDivRef, setAnswerDivRef] = useState(null);
    const onScreen = useOnScreen(answerDivRef, '300px');

    useEffect(
      () => {
        if (internalRef && !isActiveQuestion) {
          internalRef.blur();
          setChoiceIndexToSelect(null);
        }
      },
      [internalRef, onScreen]
    );

    return (
      <div
        className={`${styles.blockAdditionalOption} ${
          isMatrixQuestion ? styles.matrixBlockAdditionalOption : ''
        } mc-question-answer`}
        style={{
          borderBottom: isActiveQuestion && logicJumpsVisible && 'none'
        }}
      >
        {onScreen ? (
          <AnswerConnectionLine
            choiceIndex={choiceIndex}
            choices={choices}
            lockedPosition={
              choice.lockedPosition ||
              isOtherAnswer ||
              isNoneOfTheAboveAnswer ||
              false
            }
            randomOrder={randomOrder}
            otherAnswerIndex={otherAnswerIndex}
            noneOfTheAboveAnswerIndex={noneOfTheAboveAnswerIndex}
          />
        ) : null}
        <div className={styles.container} ref={r => setAnswerDivRef(r)}>
          {onScreen && (
            <AnswerThumbnail
              answer={choice}
              onMediaUpload={onImageUpload}
              onRemoveCoverPhoto={() => {
                const newChoice = { ...choice };

                newChoice.image = null;
                newChoice.imageFilename = null;

                actions.updateQuestionChoice(questionId, newChoice, false);
              }}
              disabled={isOtherAnswer}
              allowEdit
            />
          )}
          {isOtherAnswer || isNoneOfTheAboveAnswer ? (
            <div className={styles.answerOtherNoneLabel}>
              {isOtherAnswer ? 'Other' : 'None'}
            </div>
          ) : null}
          <AnswerText
            onScreen={onScreen}
            answer={choice.answer}
            isOtherAnswer={isOtherAnswer}
            isNoneOfTheAboveAnswer={isNoneOfTheAboveAnswer}
            isMatrixQuestion={isMatrixQuestion}
            notAllowedTextContentEdit={notAllowedTextContentEdit}
            setInternalRef={setInternalRef}
            handleExistingAnswerKeyPress={handleExistingAnswerKeyPress}
            returnPlaceholder={returnPlaceholder}
            onChange={value => {
              const newChoice = { ...choice };

              newChoice.answer = value;
              if (!isMatrixQuestion) {
                textareaAutoExpand(internalRef);
              }

              actions.updateQuestionChoice(questionId, newChoice, true);
            }}
            onFocus={e => {
              if (notAllowedTextContentEdit && e && e.currentTarget) {
                e.currentTarget.blur();
              }

              setChoiceIndexToSelect(choiceIndex);
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              );
            }}
            addDraftEmoji={isFocused ? addDraftEmoji : null}
            setAddDraftEmoji={setAddDraftEmoji}
            setActiveDraftButtons={setActiveDraftButtons}
            toggleDraftButton={isFocused ? toggleDraftButton : null}
            setToggleDraftButton={setToggleDraftButton}
            isFocused={isFocused}
            onFocusDraft={e => {
              if (internalRef) {
                if (notAllowedTextContentEdit && e && e.currentTarget) {
                  internalRef.blur();
                } else {
                  internalRef.focus();
                }
              }

              setChoiceIndexToSelect(choiceIndex);
            }}
            onBlurDraft={() => {
              if (choiceIndex === choiceIndexToSelect) {
                setChoiceIndexToSelect(null); // unselect choice in state if no other choice was selected
              }
            }}
          />
          {randomOrder && onScreen && (
            <div className={styles.lock}>
              <Icon
                type={
                  choice.lockedPosition ||
                  isOtherAnswer ||
                  isNoneOfTheAboveAnswer
                    ? 'lock_closed'
                    : 'lock_opened'
                }
                style={{
                  cursor:
                    isOtherAnswer || isNoneOfTheAboveAnswer
                      ? 'not-allowed'
                      : 'pointer'
                }}
                className={`${styles.lockIcon} fade-in`}
                onClick={e => {
                  e.stopPropagation();

                  const newChoice = { ...choice };

                  if (!isOtherAnswer && !isNoneOfTheAboveAnswer) {
                    newChoice.lockedPosition = !newChoice.lockedPosition;
                    actions.updateQuestionChoice(
                      questionId,
                      newChoice,
                      false,
                      true
                    );
                  }
                }}
              />
              <div className={styles.lockTooltip}>
                <div className={styles.lockTooltipArrow} />
                {choice.lockedPosition ||
                isOtherAnswer ||
                isNoneOfTheAboveAnswer
                  ? 'Answer is locked in this position.'
                  : 'Answer order is randomized.'}
              </div>
            </div>
          )}
          {!isCommunityAssignQuestion && onScreen && (
            <span
              role="presentation"
              className={
                notAllowedTextContentEdit
                  ? `${styles.close} ${styles.disabled}`
                  : styles.close
              }
              onClick={e => {
                if (!notAllowedTextContentEdit) {
                  e.stopPropagation();

                  actions.deleteQuestionChoice(questionId, choice.id);
                  setChoiceIndexToSelect(null);
                }
              }}
            >
              <Icon type="x" />
            </span>
          )}
          {!rankAnswers && !isMatrixQuestion && onScreen ? (
            <span
              role="presentation"
              className={`${styles.quota} mc-question-quota`}
              onClick={e => {
                if (!choice.quotaType) {
                  e.stopPropagation();

                  const newChoice = choice;
                  newChoice.quotaAmount = 100;
                  newChoice.quotaMinAmount = 0;
                  newChoice.quotaType = QUOTA_UNITS.ABSOLUTE;
                  actions.updateQuestionChoice(
                    questionId,
                    newChoice,
                    false,
                    true
                  );
                }
              }}
              style={{
                color: choice.quotaType ? '#8500f0' : '#8b8b8d'
              }}
            >
              #
            </span>
          ) : null}
          {!rankAnswers && !isMatrixQuestion && onScreen && (
            <span
              role="presentation"
              className={`${styles.logicJumpAdmin} mc-question-answer-jump`}
              onClick={() => setShowLogicJumpControls(true)}
            >
              <BranchIcon
                fill={!shouldActiveIconLogicJumpControls ? null : '#8500f0'}
              />
            </span>
          )}
        </div>
        {shouldShowLogicJumpControls ? (
          <AnswerLogicJump
            questionId={questionId}
            choices={choices}
            choice={choice}
            actions={actions}
            surveyListQuestion={surveyListQuestion}
            surveyLists={surveyLists}
            surveyStructure={surveyStructure}
            setShowLogicJumpControls={setShowLogicJumpControls}
            selectAtMost={selectAtMost}
            singleJumpAllowed={selectAtMost > 1}
          />
        ) : null}
        {isCommunityAssignQuestion && choice.assignUserToCommunity ? (
          <AnswerJumpMessage message="Respondents selecting this answer option will be converted to your community" />
        ) : null}
        {shouldShowQuotaControls ? (
          <AnswerQuota
            questionId={questionId}
            choice={choice}
            actions={actions}
            minQuotaHigherThanMax={
              choice.quotaMinAmount &&
              (choice.quotaAmount || choice.quotaAmount === 0) &&
              choice.quotaMinAmount > choice.quotaAmount
            }
            minQuotaExceedsTotalSample={minQuotaExceedsTotalSample}
            minQuotaDisabled={minQuotaDisabled}
          />
        ) : null}
      </div>
    );
  }
);
