import {
  COMMUNITY_DASHBOARD_FILTER_TYPE,
  MAIN_DATA_METRICS
} from './constants';

export const getStatisticsData = (
  currentStatisticsData,
  d,
  chartData,
  otherData
) => {
  if (currentStatisticsData === null) {
    return {
      [MAIN_DATA_METRICS.ACTIVE_USERS]: null,
      [MAIN_DATA_METRICS.RESPONSE_RATE]: null,
      [MAIN_DATA_METRICS.COMMUNITY_SIZE]: null,
      [MAIN_DATA_METRICS.MAXIMUM_DELIVERY_POTENTIAL]: null
    };
  }

  const newStatisticsData = {
    ...currentStatisticsData
  };

  const calculateMaxDeliveryPotential = () => {
    if (
      newStatisticsData[MAIN_DATA_METRICS.ACTIVE_USERS] &&
      newStatisticsData[MAIN_DATA_METRICS.RESPONSE_RATE]
    ) {
      const activeUserValue =
        otherData && otherData.activeUserTodayCount
          ? otherData.activeUserTodayCount
          : 0;

      const value =
        activeUserValue &&
        newStatisticsData[MAIN_DATA_METRICS.RESPONSE_RATE].value
          ? parseInt(
              activeUserValue *
                (newStatisticsData[MAIN_DATA_METRICS.RESPONSE_RATE].value /
                  100),
              10
            )
          : null;

      newStatisticsData[MAIN_DATA_METRICS.MAXIMUM_DELIVERY_POTENTIAL] = {
        value
      };
    }
  };

  if (d === MAIN_DATA_METRICS.ACTIVE_USERS) {
    newStatisticsData[MAIN_DATA_METRICS.ACTIVE_USERS] = {
      value:
        chartData && chartData[chartData.length - 1]
          ? chartData[chartData.length - 1].yValue
          : 0,
      chartData
    };
    calculateMaxDeliveryPotential();
  }

  if (d === MAIN_DATA_METRICS.RESPONSE_RATE) {
    const responseRate =
      chartData && chartData.length
        ? chartData.reduce((a, b) => a + parseFloat(b.yValue, 10), 0) /
          chartData.length
        : null;

    newStatisticsData[MAIN_DATA_METRICS.RESPONSE_RATE] = {
      value:
        responseRate || responseRate === 0 ? responseRate.toFixed(2) : null,
      chartData
    };
    calculateMaxDeliveryPotential();
  }

  if (d === MAIN_DATA_METRICS.COMMUNITY_SIZE) {
    newStatisticsData[MAIN_DATA_METRICS.COMMUNITY_SIZE] = {
      value:
        chartData && chartData[chartData.length - 1]
          ? chartData[chartData.length - 1].yValue
          : 0,
      chartData
    };
  }

  return newStatisticsData;
};

export const mainChartDataFetch = {
  getActiveUsers: async (api, setStatisticsData, variables = {}) => {
    const res = await api.getActiveUsersApi(variables);

    const dataArray =
      res &&
      res.data &&
      res.data.activeUsersStatistics &&
      res.data.activeUsersStatistics.buckets &&
      res.data.activeUsersStatistics.buckets.length
        ? res.data.activeUsersStatistics.buckets.map(b => ({
            xValue: b.name,
            yValue: b.value,
            yPreviousValue: b.previousValue
          }))
        : [];

    const otherData = {
      activeUserTodayCount:
        res &&
        res.data &&
        res.data.activeUsersStatistics &&
        res.data.activeUsersStatistics.activeUserTodayCount
    };

    setStatisticsData(currentStatisticsData =>
      getStatisticsData(
        currentStatisticsData,
        MAIN_DATA_METRICS.ACTIVE_USERS,
        dataArray,
        otherData
      )
    );
  },
  getResponseRate: async (api, setStatisticsData, variables = {}) => {
    const res = await api.getResponseRateApi(variables);

    const dataArray = res.data.responseRateStatistics.buckets.map(b => ({
      xValue: b.name,
      yValue:
        b.value || b.value === 0 ? parseFloat((b.value * 100).toFixed(2)) : null
    }));
    setStatisticsData(currentStatisticsData =>
      getStatisticsData(
        currentStatisticsData,
        MAIN_DATA_METRICS.RESPONSE_RATE,
        dataArray
      )
    );
  },
  getCommunitySize: async (api, setStatisticsData, variables = {}) => {
    const res = await api.getCommunitySizeApi(variables);

    const dataArray = res.data.communitySizeStatistics.buckets.map(b => ({
      xValue: b.name,
      yValue: b.value
    }));
    setStatisticsData(currentStatisticsData =>
      getStatisticsData(
        currentStatisticsData,
        MAIN_DATA_METRICS.COMMUNITY_SIZE,
        dataArray
      )
    );
  }
};

export const transformAppliedFiltersBeforeDataFetch = appliedFilters => {
  if (appliedFilters) {
    return appliedFilters.map(f => {
      if (f.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY) {
        return {
          ...f,
          community: {
            name: f.community.name,
            communityType: f.community.communityType
          }
        };
      }
      return f;
    });
  }
  return null;
};

export const loadAllMetrics = async (
  api,
  setStatisticsData,
  appliedFilters,
  metricInnerSettings,
  filterRelation
) => {
  const transformedFilters = transformAppliedFiltersBeforeDataFetch(
    appliedFilters
  );

  mainChartDataFetch.getCommunitySize(api, setStatisticsData, {
    variables: {
      appliedFilters: transformedFilters,
      range: metricInnerSettings[MAIN_DATA_METRICS.COMMUNITY_SIZE].range,
      filterRelation
    }
  });

  // Run in paralalel
  mainChartDataFetch.getActiveUsers(api, setStatisticsData, {
    variables: {
      appliedFilters: transformedFilters,
      range: metricInnerSettings[MAIN_DATA_METRICS.ACTIVE_USERS].range,
      filterRelation
    }
  });
  mainChartDataFetch.getResponseRate(api, setStatisticsData, {
    variables: { appliedFilters: transformedFilters, filterRelation }
  });
};

export const reloadMetricData = async (
  api,
  statisticsData,
  setStatisticsData,
  appliedFilters,
  name,
  newMetricSetting,
  filterRelation
) => {
  setStatisticsData({
    ...statisticsData,
    [name]: null
  });

  const transformedFilters = transformAppliedFiltersBeforeDataFetch(
    appliedFilters
  );

  if (name === MAIN_DATA_METRICS.COMMUNITY_SIZE) {
    await mainChartDataFetch.getCommunitySize(api, setStatisticsData, {
      variables: {
        appliedFilters: transformedFilters,
        range: newMetricSetting.range,
        filterRelation
      }
    });
  }

  if (name === MAIN_DATA_METRICS.ACTIVE_USERS) {
    await mainChartDataFetch.getActiveUsers(api, setStatisticsData, {
      variables: {
        appliedFilters: transformedFilters,
        range: newMetricSetting.range,
        filterRelation
      }
    });
  }
};
