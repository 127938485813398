import React from 'react';

import Icon from '../../../../../../../../../../../Icon/Icon';
import QuestionsListDropdown from '../../../../../../../../../QuestionsListDropdown/QuestionsListDropdown';

import isActionAllowed from '../../../../../../../../../../../../helpers/isActionAllowed/isActionAllowed';
import {
  QUESTION_DESIGNER_ACTION,
  SURVEY_END_TYPES,
  SURVEY_BUILDER_POPUP_TYPES
} from '../../../../../../../../../../../../helpers/constants';

import styles from './BranchLogicJump.module.css';

export default ({
  questionId,
  branch,
  actions,
  surveyListQuestion,
  surveyLists,
  surveyStructure,
  branchDeleteOnClick
}) => (
  <div className={styles.container}>
    <div className={styles.dropdownRow}>
      <div className={styles.dropdownRowParent}>
        <Icon type="ljArrow" className={styles.ljArrow} />
        Go to{' '}
        <QuestionsListDropdown
          value={branch.nextFlow || branch.end}
          onOptionClick={selectedJump => {
            const newBranch = { ...branch };

            const setNewBranch = () => {
              if (
                SURVEY_END_TYPES.SUCCESSFUL === selectedJump ||
                SURVEY_END_TYPES.NOT_ELIGIBLE === selectedJump
              ) {
                newBranch.end = selectedJump;
                delete newBranch.nextFlow;
              } else {
                newBranch.nextFlow = selectedJump;
                delete newBranch.end;
              }

              actions.updateQuestionBranch(questionId, newBranch, true);
            };

            const questionDesignerConstraintsPopup = localStorage.getItem(
              'qd_cpopup_replace_distbranch_dontshow'
            );

            const confirmationPopup = {
              type: SURVEY_BUILDER_POPUP_TYPES.CONFIRMATION,
              onClick: () => setNewBranch(),
              onClose: () => actions.toggleSurveyBuilderPopup(),
              onDontShowAnymoreChecked: () => {
                if (!questionDesignerConstraintsPopup) {
                  localStorage.setItem(
                    'qd_cpopup_replace_distbranch_dontshow',
                    true
                  );
                }
              },
              title: 'Are you sure?',
              description:
                'If you add a new group to a path, the previous group linked to that path and the questions within that group will be removed.',
              warning: null
            };

            if (!questionDesignerConstraintsPopup && newBranch.nextFlow) {
              actions.toggleSurveyBuilderPopup(confirmationPopup);
            } else {
              setNewBranch();
            }
          }}
          isActionAllowedChecker={destinationFlow =>
            isActionAllowed(
              QUESTION_DESIGNER_ACTION.SET_DISTRIBUTOR_JUMP,
              surveyListQuestion,
              destinationFlow.id,
              surveyLists,
              surveyStructure
            )
          }
        />
      </div>
      <span
        role="presentation"
        className={styles.close}
        onClick={e => {
          branchDeleteOnClick();
          e.stopPropagation();
        }}
      >
        <Icon type="x" />
      </span>
    </div>
  </div>
);
