export default survey => {
  const surveyTemp = survey;

  /* eslint-disable no-param-reassign */
  // Remove GraphQL __typename keyword
  if (survey && survey.content) {
    let totalQuestionFlows = 0;
    totalQuestionFlows -= survey.content.groups
      ? survey.content.groups.length
      : 0;
    totalQuestionFlows += survey.content.flows
      ? survey.content.flows.length
      : 0;

    delete survey.content.__typename;
    survey.content.groups = survey.content.groups.map(g => {
      totalQuestionFlows += g.flows ? g.flows.length : 0;

      delete g.__typename;
      return g;
    });

    // Check if total number of question flows is different than number of questions to show error message
    if (totalQuestionFlows !== surveyTemp.questions.length) {
      return false;
    }
  }

  const questions =
    surveyTemp.questions &&
    Array.isArray(surveyTemp.questions) &&
    surveyTemp.questions.reduce((acc, q) => {
      if (q && q.choices && q.choices.length) {
        q.choices = q.choices.map(c => {
          delete c.__typename;
          return c;
        });
      }

      if (q && q.branches && q.branches.length) {
        q.branches = q.branches.map(b => {
          delete b.__typename;

          if (b.range && b.range.__typename) {
            delete b.range.__typename;
          }

          return b;
        });
      }

      delete q.__typename;

      return {
        ...acc,
        [q.id]: q
      };
    }, {});
  /* eslint-enable no-param-reassign */

  return { surveyTemp, questions };
};
