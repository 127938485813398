import React from 'react';

import OptionsSelector from '../ValueSelectors/OptionsSelector';

import checkAgeGroupsSum from '../../helpers/checkAgeGroupsSum/checkAgeGroupsSum';
import resetCrossedSample from '../../helpers/resetCrossedSample/resetCrossedSample';

import deleteIcon from '../../../../../assets/x.svg';
import styles from '../../TargetAudience.module.css';

export default ({
  userFilter,
  onUserFilterChange,
  filter,
  filterIndex,
  filterSettings,
  maxResponses
}) => {
  if (!filter || !filter.gender) return null;

  const setFilterProperties = newFilterProperties => {
    const newUserFilter = { ...userFilter };

    const newFilter = {
      ...filter,
      ...newFilterProperties
    };

    newUserFilter.$and[filterIndex] = newFilter;

    const genderFiltersLength =
      (newUserFilter &&
        newUserFilter.$and &&
        newUserFilter.$and.length &&
        newUserFilter.$and.filter(v => v.gender).length) ||
      0;

    // Changing filters will also unset crossed property
    if (
      filterSettings &&
      filterSettings.crossedSample &&
      (checkAgeGroupsSum(userFilter, maxResponses) !== 100 ||
        genderFiltersLength === 0)
    ) {
      resetCrossedSample(newUserFilter, filterSettings, onUserFilterChange);
      return;
    }

    onUserFilterChange(newUserFilter);
  };

  const onDeleteFilter = () => {
    const newUserFilter = { ...userFilter };

    newUserFilter.$and.splice(filterIndex, 1);

    const genderFiltersLength =
      (newUserFilter &&
        newUserFilter.$and &&
        newUserFilter.$and.length &&
        newUserFilter.$and.filter(v => v.gender).length) ||
      0;

    // Delete filters will also unset crossed property
    if (
      filterSettings &&
      filterSettings.crossedSample &&
      (checkAgeGroupsSum(userFilter, maxResponses) !== 100 ||
        genderFiltersLength === 0)
    ) {
      resetCrossedSample(newUserFilter, filterSettings, onUserFilterChange);
      return;
    }

    onUserFilterChange(newUserFilter);
  };

  return (
    <div className={styles.rowContainer}>
      <div className={styles.scalesContainer}>
        <OptionsSelector
          onOptionClick={f => {
            if (filter && filter.gender && filter.gender.$in) {
              let newLanguages = [...filter.gender.$in];
              if (filter.gender.$in.indexOf(f) > -1) {
                newLanguages = newLanguages.filter(l => l !== f);
              } else {
                newLanguages.push(f);
              }

              setFilterProperties({
                gender: { $in: newLanguages }
              });
            }
          }}
          selectedOptions={filter && filter.gender && filter.gender.$in}
          options={['Male', 'Female', 'Other']}
        />
      </div>
      <div className={styles.additionalConfigurationContainer}>
        <div>
          At most
          <input
            value={
              filter.$dynamic &&
              (filter.$dynamic.$max || filter.$dynamic.$max === 0)
                ? filter.$dynamic.$max
                : ''
            }
            onChange={e =>
              setFilterProperties({
                $dynamic: {
                  ...filter.$dynamic,
                  $max:
                    e.target.value && e.target.value !== ''
                      ? parseInt(e.target.value, 10)
                      : 0
                }
              })
            }
          />
        </div>
        <div>
          At least
          <input
            value={
              filter.$dynamic && filter.$dynamic.$min
                ? filter.$dynamic.$min
                : ''
            }
            onChange={e =>
              setFilterProperties({
                $dynamic: {
                  ...filter.$dynamic,
                  $min:
                    e.target.value && e.target.value !== ''
                      ? parseInt(e.target.value, 10)
                      : null
                }
              })
            }
          />
        </div>
        <div>
          <select
            value={filter.$dynamic ? filter.$dynamic.$type : undefined}
            onChange={e =>
              setFilterProperties({
                $dynamic: {
                  ...filter.$dynamic,
                  $type: e.target.value
                }
              })
            }
          >
            <option>#</option>
            <option>%</option>
          </select>
        </div>
        <img
          className={styles.deleteFilterRowIcon}
          alt="Delete filer"
          role="presentation"
          src={deleteIcon}
          onClick={onDeleteFilter}
        />
      </div>
    </div>
  );
};
