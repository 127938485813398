import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import textareaAutoExpand from '../../../../helpers/textareaAutoExpand';
import useDebounce from '../../../../helpers/debounceHook';

import { UPDATE_CUSTOM_COPIES } from '../../../../graphql/Campaigns';

import styles from './TranslationInputField.module.css';

const TranslationInputField = props => {
  const {
    surveyId,
    clientId,
    language,
    name,
    translationKey,
    value,
    customClasses,
    disabled,
    debounceTime = 600,
    testing,
    onUpdateLocalCustomCopy,
    setSavingTranslationError,
    resizeTranslationField,
    setResizeTranslationField
  } = props;

  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const [dirty, setDirty] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, debounceTime);
  const [valuePropChanged, setValuePropChanged] = useState(false);

  const [updateCustomCopies, { error }] = useMutation(UPDATE_CUSTOM_COPIES, {
    onCompleted: response => {
      if (
        response &&
        response.updateCustomCopies &&
        response.updateCustomCopies.errors &&
        response.updateCustomCopies.errors.length
      ) {
        setSavingTranslationError(true);
      }
    }
  });

  const onWindowResize = ev => {
    ev.preventDefault();
    if (inputRef && inputRef.current) {
      textareaAutoExpand(inputRef.current);
    }
  };

  useEffect(
    () => {
      if (inputRef && inputRef.current) {
        textareaAutoExpand(inputRef.current);
      }
      window.addEventListener('resize', onWindowResize);

      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    },
    [inputRef.current, value]
  );

  useEffect(
    () => {
      if (
        dirty &&
        (debouncedInputValue || debouncedInputValue === '') &&
        !disabled
      ) {
        if (onUpdateLocalCustomCopy) {
          onUpdateLocalCustomCopy(translationKey, inputValue, name);
        }
        updateCustomCopies({
          variables: {
            survey: surveyId,
            client: clientId,
            name,
            translation: {
              key: translationKey,
              value: inputValue
            },
            language,
            testing
          }
        });
        setDirty(false);
      }
    },
    [debouncedInputValue]
  );

  useEffect(
    () => {
      setInputValue(value);
      setValuePropChanged(true);
    },
    [value]
  );

  useEffect(
    () => {
      if (error) {
        setSavingTranslationError(true);
      }
    },
    [error]
  );

  useEffect(
    () => {
      // In case selected language changed fields need to be resized
      if (
        valuePropChanged &&
        resizeTranslationField &&
        resizeTranslationField === name
      ) {
        textareaAutoExpand(inputRef.current);
        setResizeTranslationField(false);
      }
      if (valuePropChanged) {
        setValuePropChanged(false);
      }
    },
    [valuePropChanged]
  );

  if (disabled) {
    return (
      <span className={`${styles.textAreaTranslation} ${customClasses || ''}`}>
        {inputValue}
      </span>
    );
  }

  return (
    <textarea
      value={inputValue}
      className={`${styles.translationInput} ${
        !disabled ? styles.enabled : ''
      } ${customClasses || ''}`}
      ref={inputRef}
      rows="1"
      onChange={e => {
        if (!disabled) {
          textareaAutoExpand(inputRef.current);
          setInputValue(e.target.value);
          setDirty(true);
        }
      }}
      maxLength="2000"
      disabled={disabled}
      spellCheck={false}
    />
  );
};

export default TranslationInputField;
