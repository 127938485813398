import removeGroupFromSurvey from '../../../helpers/removeGroupFromSurvey/removeGroupFromSurvey';
import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';
import removeDecisionFramework from '../removeDecisionFramework/removeDecisionFramework';

export default (state, groupId) => {
  const surveyToModify = JSON.parse(JSON.stringify(state.survey));

  let newState = { ...state };
  const requests = [];

  const newSurvey = removeGroupFromSurvey(
    groupId,
    surveyToModify.content,
    surveyToModify.questions,
    false
  );

  newState = {
    ...newState,
    survey: {
      ...newState.survey,
      content: newSurvey.content,
      questions: newSurvey.questions
    }
  };

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  // Sort distributor groups according to branch jumps
  sortDistributorGroupsAndJumps(newState);

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  if (
    newSurvey.removedMatrixQuestions &&
    newSurvey.removedMatrixQuestions.length
  ) {
    newSurvey.removedMatrixQuestions.forEach(question => {
      const { surveyWithRemovedGraph, removeRequest } = removeDecisionFramework(
        newState.survey,
        question,
        question.matrix.decisionFrameworkType
      );
      if (surveyWithRemovedGraph && removeRequest) {
        newState = {
          ...newState,
          survey: surveyWithRemovedGraph
        };
        if (removeRequest) {
          requests.push(removeRequest);
        }
      }
    });
  }

  return {
    newState,
    deletedQuestions: newSurvey.removedQuestions,
    changedFlowsRemovedJumpsToUpdate:
      removedNextFlowJumps.changedFlowsRemovedJumpsToUpdate,
    requests
  };
};
