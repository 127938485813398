import React from 'react';

import styles from './MCSelector.module.css';

export default ({ mcOptions, value, templateStrings, setValue }) => (
  <div className={styles.container}>
    {mcOptions.map(o => (
      <div
        className={
          o.id === value ? `${styles.option} ${styles.selected}` : styles.option
        }
        key={`mcselector-option-${o.id.toString()}`}
        role="presentation"
        onClick={() => setValue(o)}
      >
        {o.values.map((v, i) => (
          <div className={styles.answer} key={`mcselector-a-${i.toString()}`}>
            {templateStrings[v]}
          </div>
        ))}
      </div>
    ))}
  </div>
);
