import React, { useState, useEffect } from 'react';

import BranchIcon from '../../../../../../../../../Icon/custom/BranchIcon';
import LogicFields from './components/LogicFields/LogicFields';
import RangeLogicJump from './components/RangeLogicJump/RangeLogicJump';

import styles from './SliderLogicJumps.module.css';

export default ({
  actions,
  branches,
  question,
  minValue,
  maxValue,
  surveyListQuestion,
  surveyLists,
  surveyStructure
}) => {
  const [errors, setErrors] = useState({});

  useEffect(
    () => {
      const overlapErrors = {};

      for (let i = 0; i < branches.length; i += 1) {
        if (
          branches[i].range &&
          (parseInt(branches[i].range.min, 10) < parseInt(minValue, 10) ||
            parseInt(branches[i].range.min, 10) > parseInt(maxValue, 10) ||
            parseInt(branches[i].range.max, 10) > parseInt(maxValue, 10) ||
            parseInt(branches[i].range.max, 10) < parseInt(minValue, 10) ||
            parseInt(branches[i].range.min, 10) >
              parseInt(branches[i].range.max, 10))
        ) {
          if (!overlapErrors[i]) overlapErrors[i] = {};
          overlapErrors[i].invalid = true;
        }

        for (let y = 0; y < branches.length; y += 1) {
          if (
            branches[i].range &&
            branches[y].range &&
            i !== y &&
            (parseInt(branches[i].range.min, 10) <=
              parseInt(branches[y].range.max, 10) &&
              parseInt(branches[y].range.min, 10) <=
                parseInt(branches[i].range.max, 10))
          ) {
            if (!overlapErrors[i]) overlapErrors[i] = {};
            overlapErrors[i].overlap = true;

            if (!overlapErrors[y]) overlapErrors[y] = {};
            overlapErrors[y].overlap = true;
          }
        }
      }

      setErrors(overlapErrors);
    },
    [branches, minValue, maxValue]
  );

  return (
    <div className={styles.logicContainer}>
      {branches.map((branch, branchIndex) => (
        <div className={styles.container}>
          <LogicFields
            key={`logic-field-${branchIndex.toString()}`}
            branch={branch}
            min={branch.range && branch.range.min}
            max={branch.range && branch.range.max}
            error={errors[branchIndex]}
            actions={actions}
            questionId={question.id}
          />
          <RangeLogicJump
            key={`range-logic-jump-${branchIndex.toString()}`}
            error={errors[branchIndex]}
            questionId={question.id}
            branch={branch}
            actions={actions}
            surveyListQuestion={surveyListQuestion}
            surveyLists={surveyLists}
            surveyStructure={surveyStructure}
          />
        </div>
      ))}
      <div
        className={styles.addJumpButton}
        role="presentation"
        onClick={() =>
          actions.addQuestionBranch(question.id, { range: { min: 1, max: 1 } })
        }
      >
        <BranchIcon fill="#8500f0" />
        <span>Add a logic jump</span>
      </div>
    </div>
  );
};
