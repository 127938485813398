export default (level, title, higherRecords) => {
  if (title) {
    return title;
  }

  // Records
  if (higherRecords && higherRecords.length && level > 1) {
    const levelAbove = higherRecords.find(r => r.level === level - 1);

    if (levelAbove && levelAbove.name) {
      return levelAbove.name;
    }
  }

  // Members
  if (higherRecords && higherRecords.length && !level) {
    const sortedHigherRecords = [...higherRecords];
    sortedHigherRecords.sort((a, b) => a.level - b.level);

    const levelAbove = sortedHigherRecords[sortedHigherRecords.length - 1];

    if (levelAbove && levelAbove.name) {
      return levelAbove.name;
    }
  }

  return '';
};
