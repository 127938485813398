import React from 'react';

import messageQuestionIcon from '../../../../../assets/Text.svg';
import openQuestionIcon from '../../../../../assets/OpenQuestion.svg';
import mcQuestionIcon from '../../../../../assets/MultipleChoice.svg';
import rankingQuestionIcon from '../../../../../assets/AnswerRanking.svg';
import ratingQuestionIcon from '../../../../../assets/Rating.svg';
import cameraQuestionIcon from '../../../../../assets/TakePicture.svg';
import videoQuestionIcon from '../../../../../assets/PlayVideo.svg';
import videoCameraQuestionIcon from '../../../../../assets/RecordVideo.svg';
import iFrameQuestion from '../../../../../assets/Website.svg';
import addToCommunityQuestionIcon from '../../../../../assets/AddToCommunity.svg';
import clearSettingsIcon from '../../../../../assets/settings-nobackground.svg';
import settingsIcon from '../../../../../assets/settings-withbackground.svg';
import trashIcon from '../../../../../assets/trash.svg';
import xIcon from '../../../../../assets/x.svg';
import xWhiteIcon from '../../../../../assets/x-white.svg';
import branchIcon from '../../../../../assets/branch.png';
import checkmarkIcon from '../../../../../assets/checkmark.svg';
import randomizeIcon from '../../../../../../assets/img/randomize-active.svg';
import filterIcon from '../../../../../../assets/img/filter.svg';
import flagIcon from '../../../../../../assets/img/flag.svg';
import duplicateIcon from '../../../../../assets/duplicate.svg';
import moveIcon from '../../../../../assets/move.svg';
import finishIcon from '../../../../../assets/finish-icon.svg';
import addIcon from '../../../../../assets/add.svg';
import collapseIcon from '../../../../../assets/collapse_arrow.svg';
import shuffleIcon from '../../../../../assets/shuffle-icon.svg';
import shuffleWhiteIcon from '../../../../../assets/shuffle-white-icon.svg';
import playVideoWhite from '../../../../../assets/PlayVideoWhite.svg';
import activeLink from '../../../../../assets/active-link.svg';
import inactiveLink from '../../../../../assets/inactive-link.svg';
import layer from '../../../../../assets/layer.svg';
import layerWhite from '../../../../../assets/layerWhite.svg';
import removeCircle from '../../../../../assets/remove-circle.svg';
import addCircle from '../../../../../assets/add-circle.svg';
import checkboxChecked from '../../../../../assets/checkbox-checked.svg';
import checkboxUnChecked from '../../../../../assets/checkbox-unchecked.svg';
import ljArrow from '../../../../../assets/lj-arrow.svg';
import ungroup from '../../../../../assets/ungroup.svg';
import lockClosed from '../../../../../../assets/img/lock-closed.svg';
import lockOpened from '../../../../../../assets/img/lock-opened.svg';
import addOpenAnswer from '../../../../../../assets/img/add-open-answer-icon.svg';
import tagging from '../../../../../assets/tagging.svg';
import edit from '../../../../../assets/edit.svg';
import matrix from '../../../../../assets/matrix.svg';

import { QUESTION_TYPES } from '../../helpers/constants';

const Icon = ({ type, alt, className, style, onClick }) => {
  const iconsMapping = {
    [QUESTION_TYPES.MESSAGE]: messageQuestionIcon,
    [QUESTION_TYPES.OPEN_QUESTION]: openQuestionIcon,
    [QUESTION_TYPES.MULTIPLE_CHOICE]: mcQuestionIcon,
    [QUESTION_TYPES.RANKING]: rankingQuestionIcon,
    [QUESTION_TYPES.RATING_SCALE]: ratingQuestionIcon,
    [QUESTION_TYPES.TAKE_A_PICTURE]: cameraQuestionIcon,
    [QUESTION_TYPES.RECORD_A_VIDEO]: videoCameraQuestionIcon,
    [QUESTION_TYPES.PLAY_A_VIDEO]: videoQuestionIcon,
    [QUESTION_TYPES.IFRAME]: iFrameQuestion,
    [QUESTION_TYPES.ADD_TO_COMMUNITY]: addToCommunityQuestionIcon,
    [QUESTION_TYPES.MATRIX]: matrix,
    'settings-icon': settingsIcon,
    'settings-icon-clear': clearSettingsIcon,
    trash: trashIcon,
    x: xIcon,
    x_white: xWhiteIcon,
    branch: branchIcon,
    checkmark: checkmarkIcon,
    distributor: randomizeIcon,
    not_eligible: filterIcon,
    successfull: flagIcon,
    duplicate: duplicateIcon,
    move: moveIcon,
    finish: finishIcon,
    add: addIcon,
    collapse_arrow: collapseIcon,
    shuffle: shuffleIcon,
    play_video_white: playVideoWhite,
    inactive_link: inactiveLink,
    active_link: activeLink,
    layer,
    layerWhite,
    add_circle: addCircle,
    remove_circle: removeCircle,
    checkboxChecked,
    checkboxUnChecked,
    'shuffle-white': shuffleWhiteIcon,
    ljArrow,
    ungroup,
    lock_closed: lockClosed,
    lock_opened: lockOpened,
    add_open_answer: addOpenAnswer,
    tagging,
    edit
  };

  if (iconsMapping[type]) {
    return (
      <img
        role="presentation"
        className={className}
        src={iconsMapping[type]}
        alt={alt}
        style={style}
        onClick={onClick}
      />
    );
  }

  return null;
};

export default Icon;
