import React, { useContext } from 'react';

import TemplatesWizardContext from '../../../../../TemplatesWizardContext';

import styles from './InfoPopup.module.css';

import closeIcon from '../../../../../../../../../../../assets/img/delete.svg';
import iscanvas from '../../../../../../../../../../../assets/img/templateswizard/iscanvas.png';
import ctcanvas from '../../../../../../../../../../../assets/img/templateswizard/ctcanvas.png';
import pvcanvas from '../../../../../../../../../../../assets/img/templateswizard/pvcanvas.png';
import vscanvas from '../../../../../../../../../../../assets/img/templateswizard/vscanvas.png';

import { TEMPLATES_WIZARD_TEMPLATE_TYPES } from '../../../../../../../helpers/constants';

export default ({ infoPopupTemplateType, setInfoPopupTemplateType }) => {
  const { strings, styleText } = useContext(TemplatesWizardContext);

  const templateStrings = strings[infoPopupTemplateType];

  return (
    <div className={styles.container}>
      <img
        className={styles.closePopupButton}
        src={closeIcon}
        alt="Close icon"
        onClick={() => setInfoPopupTemplateType(null)}
        role="presentation"
      />
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          {styleText(strings.WHEN_TO_USE, styles)}
        </div>
        <div className={styles.text}>
          {styleText(templateStrings.TO_PRIORITISE_PAINS, styles)}
        </div>
        <div className={styles.text}>{styleText(strings.BENEFITS, styles)}</div>
        <div className={styles.text}>
          <span className={styles.purple}>+</span>{' '}
          {styleText(templateStrings.YOU_WONT_WASTE_RESOURCES, styles)}
        </div>
        <div className={styles.text}>
          <span className={styles.purple}>+</span>{' '}
          {styleText(templateStrings.YOU_WILL_KNOW_IT_MAKES_SENSE, styles)}
        </div>
        <div className={styles.text}>
          <span className={styles.purple}>+</span>{' '}
          {styleText(templateStrings.WELL_DEFINED_VALIDATED, styles)}
        </div>
        <div className={styles.subtitle}>
          {styleText(strings.HOW_TO_USE, styles)}
        </div>
        <div className={styles.text}>
          {styleText(templateStrings.PROBLEMS_ARE_PRIORITISED, styles)}
        </div>
        <div className={styles.text}>
          {styleText(templateStrings.X_AXIS_INFO, styles)}
        </div>
        <div className={styles.text}>
          {styleText(templateStrings.Y_AXIS_INFO, styles)}
        </div>
        <div className={styles.text}>
          {styleText(strings.FOR_ALL_QUESTIONS_CONTACT, styles)}{' '}
          <a href="mailto:operations@buffl.be">operations@buffl.be</a>
        </div>
        {infoPopupTemplateType ===
          TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION && (
          <img
            className={styles.canvasImage}
            src={pvcanvas}
            alt="Problem Validation"
          />
        )}
        {infoPopupTemplateType ===
          TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION && (
          <img
            className={styles.canvasImage}
            src={vscanvas}
            alt="Value selection"
          />
        )}
        {infoPopupTemplateType ===
          TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION && (
          <img
            className={styles.canvasImage}
            src={iscanvas}
            alt="Idea selection"
          />
        )}
        {infoPopupTemplateType ===
          TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST && (
          <img
            className={styles.canvasImage}
            src={ctcanvas}
            alt="Concept test"
          />
        )}
      </div>
    </div>
  );
};
