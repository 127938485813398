import React from 'react';

import DropdownSelector from '../../../../surveys/components/SurveyBuilderPage/TargetAudience/components/ProfilingQuestions/components/DropdownSelector/DropdownSelector';

import styles from './SearchDropdown.module.css';

export default ({
  fetchOptions,
  placeholder,
  onSelectCompleted,
  value,
  search = true,
  disabled = false,
  multiple = true,
  filterDropdownStyle = styles.filterDropdownStyle
}) => (
  <div>
    <div className={styles.filterItemContainer}>
      <div className={styles.filterType}>
        <DropdownSelector
          value={value}
          onSelectCompleted={onSelectCompleted}
          fetchOptions={fetchOptions}
          placeholder={placeholder}
          search={search}
          filterDropdownStyle={filterDropdownStyle}
          disabled={disabled}
          multiple={multiple}
        />
      </div>
    </div>
  </div>
);
