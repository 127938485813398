import { COLORS } from './constants';

export default deliveryFactor => {
  if (deliveryFactor > 0.9) {
    return COLORS.GREEN;
  }
  if (deliveryFactor < 0.5) {
    return COLORS.RED;
  }
  return COLORS.ORANGE;
};
