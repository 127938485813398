export default (filterName, subFilter, activeFiltersLength) => {
  let additionalFiltersInFetch = {};
  if (filterName && filterName === 'communities') {
    if (subFilter && subFilter.value) {
      // Static or dynamic community type
      additionalFiltersInFetch = {
        ...additionalFiltersInFetch,
        type: subFilter.value
      };
    }
    if (activeFiltersLength && activeFiltersLength > 1) {
      // Pass filter relation when there are multiple filters
      additionalFiltersInFetch = {
        ...additionalFiltersInFetch,
        filterRelation: 'AND'
      };
    }
  }

  return additionalFiltersInFetch;
};
