export const COMMUNITY_AUTOMATION_TYPES = {
  EVENT: 'EVENT',
  ACTION: 'ACTION'
};

export const COMMUNITY_AUTOMATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const COMMUNITY_EVENT_STEP_NAMES = {
  NOT_OPEN_APP_IN_X_DAYS: 'NOT_OPEN_APP_IN_X_DAYS',
  USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS: 'USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS'
};

export const COMMUNITY_EVENT_STEP_NAMES_LABELS = {
  NOT_OPEN_APP_IN_X_DAYS: 'Not opened the app in x days',
  USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS:
    'User successfully completed x survey(s) in the last y week(s)'
};

export const COMMUNITY_ACTION_STEP_NAMES = {
  SEND_PUSH_NOTIFICATION: 'SEND_PUSH_NOTIFICATION',
  SEND_EMAIL: 'SEND_EMAIL',
  POPUP_MESSAGE: 'POPUP_MESSAGE',
  GIVE_TOKENS: 'GIVE_TOKENS'
};

export const COMMUNITY_ACTION_STEP_NAMES_LABELS = {
  SEND_PUSH_NOTIFICATION: 'Push notification',
  SEND_EMAIL: 'Send mail',
  POPUP_MESSAGE: 'In-app pop-up message',
  GIVE_TOKENS: 'Give x tokens'
};
