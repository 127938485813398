import React from 'react';

import styles from './CalculatingIncidenceRate.module.css';
import Loader from '../../../../../../../CommunityDashboard/components/CommunityDashboardPage/components/Loader/Loader';

export default () => (
  <div className={styles.container}>
    <div className={styles.title}>Calculating incidence rate</div>
    <div className={styles.description}>This will only take a moment</div>
    <div>
      <Loader fixed={false} />
    </div>
  </div>
);
