import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_RESULTS_WORKSPACE } from './graphql/Workspaces';

import NoWorkspaces from './components/NoWorkspaces/NoWorkspaces';
import Sidebar from './components/Sidebar/Sidebar';
import MainContent from './components/MainContent/MainContent';
import Popup from '../../../Popup';
import ManageWorkspace from './components/ManageWorkspace/ManageWorkspace';
import Toggle from './components/Toggle/Toggle';

import filterResults from './helpers/filterResults/filterResults';
import removePopulationsTypename from './helpers/removePopulationsTypename';

import styles from './Workspaces.module.css';

import populationColors from '../../colors';

import addIcon from '../../../../../assets/img/add.svg';
import infoIcon from '../../../../../assets/img/info.svg';

const Workspaces = ({
  surveyId,
  resultBlocks,
  stats,
  unfilteredResults,
  showEditWorkspacePopup,
  setShowEditWorkspacePopup,
  authorized,
  selectedWorkspace,
  setSelectedWorkspace,
  showCreateWorkspacePopup,
  setShowCreateWorkspacePopup
}) => {
  const [filteredResults, setFilteredResults] = useState([]);
  const [activePopulations, setActivePopulations] = useState([]);

  const [
    getResultsWorkspaces,
    { loading: loadingResultsWorkspaces }
  ] = useLazyQuery(GET_RESULTS_WORKSPACE, {
    variables: { survey: surveyId },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getResultsWorkspaces) {
        if (data.getResultsWorkspaces.length) {
          const selectedWorkspaceResult = JSON.parse(
            JSON.stringify(data.getResultsWorkspaces[0])
          );
          selectedWorkspaceResult.populations = removePopulationsTypename(
            selectedWorkspaceResult.populations
          );
          setSelectedWorkspace(selectedWorkspaceResult);

          const filteredResultsArray = [];
          if (selectedWorkspaceResult) {
            selectedWorkspaceResult.populations.forEach(populationFilters => {
              filteredResultsArray.push(
                filterResults(populationFilters, unfilteredResults)
              );
            });
            setFilteredResults(filteredResultsArray);
          }
        } else {
          setSelectedWorkspace(false);
          setFilteredResults([]);
        }
      }
    }
  });

  useEffect(() => {
    if (authorized && !selectedWorkspace) {
      getResultsWorkspaces();
    }
    // document.body.style.backgroundColor = "#FFFFFF";
  }, []);

  if (loadingResultsWorkspaces) {
    return null;
  }

  const makeResultsFiltering = populations => {
    if (populations) {
      const filteredResultsArray = [];
      populations.forEach(populationFilters => {
        filteredResultsArray.push(
          filterResults(populationFilters, unfilteredResults)
        );
      });
      setFilteredResults(filteredResultsArray);
    }
  };

  const calculatePopulationResultsCount = populations => {
    if (populations) {
      return filterResults(populations, unfilteredResults).length;
    }
    return null;
  };

  const blocks = Object.values(resultBlocks).filter(
    block =>
      block.type === 'Multiple Choice Question' || block.type === 'Checkboxes'
  );

  const getFilteredResultsCount = () => {
    if (filteredResults && filteredResults.length) {
      return filteredResults.map(populationResults =>
        populationResults ? populationResults.length : null
      );
    }
    return '-';
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {selectedWorkspace && selectedWorkspace !== [] ? (
          <Sidebar
            selectedWorkspace={selectedWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
            blocks={blocks}
            filteredResultsCount={getFilteredResultsCount()}
            populationColors={populationColors}
            makeResultsFiltering={makeResultsFiltering}
            calculatePopulationResultsCount={populations =>
              calculatePopulationResultsCount(populations)
            }
            activePopulations={activePopulations}
            setActivePopulations={setActivePopulations}
          />
        ) : (
          <div className={styles.sideContainer}>
            <div
              className={`${styles.createAPopulationButton} ${
                !authorized ? styles.disabled : ''
              }`}
            >
              <img src={addIcon} alt="Add population" /> Create a population
            </div>
            {authorized && (
              <>
                <div className={styles.defaultSample}>
                  <div className={styles.titleTextContainer}>
                    <div
                      className={styles.colorBullet}
                      style={{ backgroundColor: populationColors[0] }}
                    />
                    <span className={styles.populationLabel}>
                      <span>Default Sample</span>
                      <span
                        className={styles.filteredResultsCount}
                        style={{ color: populationColors[0] }}
                      >
                        n = {unfilteredResults.length}
                      </span>
                    </span>
                  </div>
                  <Toggle
                    checked
                    onToggle={() => {}}
                    backgroundColor={populationColors[0]}
                  />
                </div>
                <div
                  className={`${
                    styles.populationContainer
                  } workspaces-sidebar-populations-container`}
                >
                  <div className={styles.populationsInfoContainer}>
                    <div className={styles.infoTitleWrapper}>
                      <img
                        src={infoIcon}
                        className={styles.infoIcon}
                        alt="Populations info"
                      />
                      <span className={styles.infoTitle}>Populations</span>
                    </div>
                    <div className={styles.infoText}>
                      You can create different populations to compare the
                      behaviour of different market segments and analyze the
                      differences in a statistically sound way.
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <MainContent
          surveyId={surveyId}
          selectedWorkspace={selectedWorkspace}
          blocks={blocks}
          stats={stats}
          filteredResults={filteredResults}
          populationColors={populationColors}
          activePopulations={activePopulations}
          disabled={!authorized}
          emptyWorkspace={!selectedWorkspace || selectedWorkspace === []}
        />
        {selectedWorkspace === false || !authorized ? (
          <NoWorkspaces
            blocks={blocks}
            surveyId={surveyId}
            setSelectedWorkspace={setSelectedWorkspace}
            authorized={authorized}
            showCreateWorkspacePopup={showCreateWorkspacePopup}
            setShowCreateWorkspacePopup={setShowCreateWorkspacePopup}
          />
        ) : null}
      </div>
      {showEditWorkspacePopup ? (
        <Popup
          component={
            <ManageWorkspace
              surveyId={surveyId}
              blocks={blocks}
              onClose={() => setShowEditWorkspacePopup(false)}
              setSelectedWorkspace={setSelectedWorkspace}
              selectedWorkspace={selectedWorkspace}
              setShowEditWorkspacePopup={setShowEditWorkspacePopup}
              makeResultsFiltering={makeResultsFiltering}
            />
          }
          onClose={() => setShowEditWorkspacePopup(false)}
        />
      ) : null}
    </div>
  );
};

export default Workspaces;
