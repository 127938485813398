export const defaultCommunityTag = {
  category: 'Community',
  tagName: 'communityName',
  tagValue: null
};

export const allSurveysVisibleOptions = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

export const communityConversionTypes = {
  WEB_PANEL: 'WEB_PANEL',
  MOBILE_APP: 'MOBILE_APP'
};

export const communityTypeOptions = [
  {
    value: null,
    label: '-'
  },
  {
    value: 'WEB_PANEL',
    label: 'Web panel (do not ask respondents to download mobile app)'
  },
  {
    value: 'MOBILE_APP',
    label: 'Mobile app (ask respondents to download mobile app)'
  }
];

export const seeGiveawayMessageOptions = [
  {
    value: null,
    label: '-'
  },
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

export const thankYouScreenConversionMessageOptionKeys = {
  TOKENS: 'TOKENS',
  EUROS: 'EUROS',
  IMPACT: 'IMPACT',
  CUSTOM: 'CUSTOM'
};

export const thankYouScreenConversionMessageOptions = [
  {
    value: thankYouScreenConversionMessageOptionKeys.TOKENS,
    label: 'Tokens'
  },
  {
    value: thankYouScreenConversionMessageOptionKeys.EUROS,
    label: 'Euros'
  },
  {
    value: thankYouScreenConversionMessageOptionKeys.IMPACT,
    label: 'Impact'
  },
  {
    value: thankYouScreenConversionMessageOptionKeys.CUSTOM,
    label: 'Custom'
  }
];
