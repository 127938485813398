import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

const Bar = props => {
  const {
    data,
    stats,
    displayRatingPercentage,
    vertical,
    displayStatisticalRelevance,
    statisticalRelevanceStats
  } = props;
  const [svg, setSvg] = useState(null);

  // Did mount
  useEffect(() => {
    drawChart(svg, props);
  }, []);

  // Did update
  useEffect(
    () => {
      drawChart(svg, props);
    },
    [
      svg,
      stats,
      displayRatingPercentage,
      vertical,
      displayStatisticalRelevance,
      statisticalRelevanceStats
    ]
  );

  const drawChart = (svgRef, chartProps) => {
    const chartData = chartProps.stats;
    if (chartProps.parent && chartProps.parent.offsetWidth) {
      const {
        height,
        start,
        end,
        xLabel,
        yLabel,
        marginLeft,
        marginRight,
        showCounts,
        populationsLength,
        answerStrings
      } = chartProps;

      const width =
        chartProps.parent.offsetWidth -
        (marginLeft ? parseInt(marginLeft, 10) : 0) -
        (marginRight ? parseInt(marginRight, 10) : 0);

      const isStartOrEnd = (start || start === 0) && (end || end === 0);
      const shouldShowCounts = end - start <= 20;

      let margin = {
        top: 20,
        right: 20,
        bottom: 30,
        left: 40
      };

      if (!vertical) {
        margin = {
          top: 20,
          right: 80,
          bottom: 30,
          left: 80
        };
      }

      const labelMargins = {
        yY: 20,
        ydY: 30,
        xY: 20,
        xX: margin.right + margin.left - 10
      };

      const isEmpty = arr => {
        let empty = false;
        arr.forEach(a => {
          if (!a.length) {
            empty = true;
          }
        });
        return empty;
      };

      const limitAnswerStringLength = (answerString, direction) => {
        let maxStringLength;
        if (direction === 'vertical') {
          maxStringLength = 150 / stats[0].length;
        } else {
          maxStringLength = 10;
        }
        if (answerString.length > maxStringLength) {
          return `${answerString.substring(0, maxStringLength)}...`;
        }
        return answerString;
      };

      if (vertical) {
        // functions to caluclate width and x property for spacing between bars
        let barWidthDivider = 1;
        const barWidthSettings = () => {
          switch (stats.length) {
            case 1:
              barWidthDivider = 6;
              break;
            case 2:
              barWidthDivider = 4;
              break;
            case 3:
              barWidthDivider = 4;
              break;
            case 4:
              barWidthDivider = 3;
              break;
            case 5:
              barWidthDivider = 3;
              break;
            case 6:
              barWidthDivider = 3;
              break;
            default:
              barWidthDivider = 1;
              break;
          }
          return barWidthDivider;
        };
        barWidthDivider = barWidthSettings();

        const calculateXAttribute = (
          xValue,
          passedWidth,
          passedExtraWidth,
          statIndex
        ) => {
          let xAttribute;
          switch (stats.length) {
            case 1:
              xAttribute =
                xValue + passedExtraWidth + passedWidth / barWidthDivider / 2;
              break;
            case 2:
              if (statIndex === 0) {
                xAttribute =
                  xValue + passedExtraWidth + passedWidth / barWidthDivider - 5;
              } else {
                xAttribute = xValue + passedExtraWidth + 5;
              }
              break;
            case 3:
              if (statIndex === 0) {
                xAttribute =
                  xValue + passedExtraWidth + passedWidth / barWidthDivider;
              } else if (statIndex === 2) {
                xAttribute = xValue + passedExtraWidth;
              } else {
                xAttribute =
                  xValue + passedExtraWidth + passedWidth / barWidthDivider / 2;
              }
              break;
            case 4:
              if (statIndex === 0) {
                xAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barWidthDivider) * 2 -
                  15;
              } else if (statIndex === 3) {
                xAttribute =
                  xValue +
                  passedExtraWidth -
                  passedWidth / barWidthDivider +
                  15;
              } else if (statIndex === 1) {
                xAttribute =
                  xValue + passedExtraWidth + passedWidth / barWidthDivider - 5;
              } else {
                xAttribute = xValue + passedExtraWidth + 5;
              }
              break;
            case 5:
              if (statIndex === 0) {
                xAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barWidthDivider) * 2;
              } else if (statIndex === 4) {
                xAttribute =
                  xValue + passedExtraWidth - passedWidth / barWidthDivider;
              } else if (statIndex === 1) {
                xAttribute =
                  xValue +
                  passedExtraWidth +
                  passedWidth / barWidthDivider +
                  passedWidth / barWidthDivider / 4;
              } else if (statIndex === 2) {
                xAttribute =
                  xValue + passedExtraWidth + passedWidth / barWidthDivider / 2;
              } else if (statIndex === 3) {
                xAttribute =
                  xValue + passedExtraWidth - passedWidth / barWidthDivider / 4;
              } else {
                xAttribute = xValue + passedExtraWidth;
              }
              break;
            case 6:
              if (statIndex === 0) {
                xAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barWidthDivider) * 2 -
                  15 +
                  passedWidth / barWidthDivider -
                  10;
              } else if (statIndex === 1) {
                xAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barWidthDivider) * 2 -
                  15;
              } else if (statIndex === 2) {
                xAttribute =
                  xValue + passedExtraWidth + passedWidth / barWidthDivider - 5;
              } else if (statIndex === 3) {
                xAttribute = xValue + passedExtraWidth + 5;
              } else if (statIndex === 4) {
                xAttribute =
                  xValue +
                  passedExtraWidth -
                  passedWidth / barWidthDivider +
                  15;
              } else {
                xAttribute =
                  xValue +
                  passedExtraWidth -
                  passedWidth / barWidthDivider / 2 +
                  15 -
                  passedWidth / barWidthDivider;
              }
              break;

            default:
              xAttribute = xValue + passedExtraWidth;
              break;
          }
          return xAttribute;
        };

        let barWidth = 0;
        let xAxisTransformX = 0;
        if (isStartOrEnd) {
          if (populationsLength) {
            barWidth =
              (width - margin.left - margin.right - 5 * (end - start + 1)) /
              (end - start + 1) /
              populationsLength;
            xAxisTransformX = barWidth * (populationsLength / 2);
          } else {
            barWidth =
              (width - margin.left - margin.right - 5 * (end - start + 1)) /
              (end - start + 1);
            xAxisTransformX = barWidth;
          }
        }
        const chartSvg = d3
          .select(svgRef)
          .attr('width', width)
          .attr('height', height);

        chartSvg.selectAll('*').remove();

        const chart = chartSvg
          .append('g')
          .attr('width', width - margin.left - margin.right)
          .attr('height', height - margin.top - margin.bottom)
          .attr('class', 'results-bar-container')
          .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const y = d3
          .scaleLinear()
          .range([height - margin.top - margin.bottom, 0]);

        let d3Max;
        if (displayStatisticalRelevance) {
          try {
            d3Max = d3.max(
              chartData.map((stat, index) =>
                d3.max(
                  stat,
                  (d, i) =>
                    (d.y / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100)
                )
              )
            );
          } catch {
            d3Max = d3.max(chartData.map(stat => d3.max(stat, d => d.y)));
          }
        } else {
          d3Max = d3.max(chartData.map(stat => d3.max(stat, d => d.y)));
        }

        y.domain([0, d3Max]);

        const maxHeights = [];
        for (let i = 0; i < stats.length; i += 1) {
          let numberOfStatResults = 0;
          stats[i].forEach(d => {
            numberOfStatResults += d.y;
          });
          maxHeights.push(
            (height - margin.top - margin.bottom) *
              (numberOfStatResults / d3Max)
          );
        }

        const countImage =
          '<g><g><circle stroke-width="0.501" stroke-miterlimit="4" stroke-dashoffset="0" r="0.959111" cy="1.249265" cx="2.05403" id="path832"/><path class="nocolor" stroke-width="" stroke-miterlimit="4" id="path842" d="m3.90661,4.815655c0,0 -0.0617,-2.01531 -1.84912,-1.99861c-1.78736,0.0167 -1.77656,1.86868 -1.77656,1.86868l3.73198,0"/></g></g>';

        let x = null;

        if (isStartOrEnd) {
          x = d3
            .scaleLinear()
            .range([
              0,
              width - margin.left - margin.right - xAxisTransformX * 2
            ]);
          x.domain([start, end]);
        } else {
          x = d3
            .scaleBand()
            .range([0, width - margin.left - margin.right])
            .padding(0.3);
          x.domain(chartData.map(d => d.x));
          barWidth = x.bandwidth();
        }
        const div = d3.select('.tooltip-container').style('display', 'none');
        let extraWidth = 0;
        stats.forEach((stat, index) => {
          chart
            .selectAll('.bar')
            .data(stat)
            .enter()
            .append('rect')
            .style('fill', chartProps.populationColors[index])
            .attr('x', d =>
              calculateXAttribute(x(d.x), barWidth, extraWidth, index)
            )
            .attr('width', barWidth - barWidth / barWidthDivider)
            .attr('y', d => y(d.y))
            .attr('height', d => height - margin.top - margin.bottom - y(d.y))
            .attr(
              'class',
              (d, i) =>
                `results-bar-single-bar result-chart-cursor-pointer result-chart-on-hover-opacity ${
                  displayStatisticalRelevance ? 'result-chart-half-opacity' : ''
                } workspace-result-bar-${i}`
            )
            .on('mouseover', (d, i) => {
              div
                .style('color', chartProps.populationColors[index])
                .attr('width', 300)
                .transition()
                .duration(200)
                .style('display', 'block');
              div
                .html(() => {
                  if (displayStatisticalRelevance) {
                    if (displayRatingPercentage) {
                      return `<strong>${d.y.toFixed(2)} %</strong>
                      <br><span style="font-size: 12px">${
                        statisticalRelevanceStats[index][i].lower
                      }%-${statisticalRelevanceStats[index][i].upper}%</span>`;
                    }
                    return `<strong>${d.y}</strong>
                      <br><span style="font-size: 12px">${(
                        (d.y /
                          (statisticalRelevanceStats[index][i].fit / 100)) *
                        (statisticalRelevanceStats[index][i].lower / 100)
                      ).toFixed(2)}-${(
                      (d.y / (statisticalRelevanceStats[index][i].fit / 100)) *
                      (statisticalRelevanceStats[index][i].upper / 100)
                    ).toFixed(2)}</span>`;
                  }
                  if (displayRatingPercentage) {
                    return `<strong>${d.y.toFixed(2)} %</strong>`;
                  }
                  return `<strong>${d.y}</strong>`;
                })
                .style('left', () => `${d3.event.pageX + 10}px`)
                .style('top', () => `${d3.event.pageY}px`);
            })
            .on('mouseout', () => {
              div
                .transition()
                .duration(500)
                .style('display', 'none');
            });
          extraWidth += barWidth;
        });

        const ticks = end - start <= 20 ? end - start : 20;

        chart
          .append('g')
          .attr('class', 'results-bar-bottom-axis')
          .attr(
            'transform',
            `translate(${xAxisTransformX},${height -
              margin.top -
              margin.bottom})`
          )
          .call(
            d3
              .axisBottom(x)
              .ticks(ticks)
              .tickFormat((t, i) =>
                limitAnswerStringLength(answerStrings[i], 'vertical')
              )
          );

        // hover over horizontal ticks with answers
        chart
          .select('.results-bar-bottom-axis')
          .selectAll('.tick')
          .on('mouseover', (t, i) => {
            div
              .style('color', '#000000')
              .attr('width', 300)
              .transition()
              .duration(200)
              .style('display', 'block');
            div
              .html(
                () => `<span style="font-size: 12px">${answerStrings[i]}</span>`
              )
              .style('left', () => `${d3.event.pageX + 10}px`)
              .style('top', () => `${d3.event.pageY}px`);
          })
          .on('mouseout', () => {
            div
              .transition()
              .duration(500)
              .style('display', 'none');
          });

        // Fix: fill gap between left and bottom axis
        if (isStartOrEnd) {
          chart
            .append('g')
            .append('line')
            .style('stroke', '#8b8b8d')
            .style('stroke-width', 1)
            .attr('x1', 0)
            .attr('y1', height - margin.top - margin.bottom)
            .attr('x2', width)
            .attr('y2', height - margin.top - margin.bottom);
        }

        if (
          showCounts &&
          shouldShowCounts &&
          chartData.length * data.length < 15 &&
          !displayStatisticalRelevance
        ) {
          let extraCountWidth = 0;
          stats.forEach((stat, index) => {
            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('text')
              .attr(
                'x',
                d =>
                  calculateXAttribute(
                    x(d.x),
                    barWidth,
                    extraCountWidth,
                    index
                  ) +
                  (barWidth - barWidth / barWidthDivider) / 2 +
                  3
              )
              .attr('y', d => y(d.y) - 5)
              .style('text-anchor', 'middle')
              .text(d =>
                displayRatingPercentage ? `${d.y.toFixed(2)} %` : d.y
              )
              .attr('font-family', 'sans-serif')
              .attr('font-size', '11px')
              .attr('font-weight', '600')
              .attr('fill', chartProps.populationColors[index])
              .attr('class', (d, i) => `bar-value-${i}`);
            if (!displayRatingPercentage) {
              chart
                .selectAll('.bar-values-user')
                .data(stat)
                .enter()
                .append('svg')
                .attr('x', (d, i) => {
                  const textElement = chart.select(`.bar-value-${i}`).node();
                  let textWidth = 12;
                  if (textElement) {
                    textWidth = textElement.getBoundingClientRect().width;
                    textWidth = 12;
                  }
                  return (
                    calculateXAttribute(
                      x(d.x),
                      barWidth,
                      extraCountWidth,
                      index
                    ) +
                    (barWidth - barWidth / barWidthDivider) / 2 -
                    4 -
                    textWidth
                  );
                })
                .attr('y', d => y(d.y) - 14)
                .attr('width', 16)
                .attr('height', 16)
                .attr('viewBox', '0 0 7 9')
                .html(countImage)
                .attr('fill', chartProps.populationColors[index]);
            }
            extraCountWidth += barWidth;
          });
        }

        if (
          displayStatisticalRelevance &&
          statisticalRelevanceStats &&
          statisticalRelevanceStats.length &&
          !isEmpty(statisticalRelevanceStats)
        ) {
          let extraStatWidth = 0;

          stats.forEach((stat, index) => {
            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('line')
              .style('stroke', chartProps.populationColors[index])
              .style('stroke-width', 2)
              .attr(
                'x1',
                d =>
                  calculateXAttribute(x(d.x), barWidth, extraStatWidth, index) +
                  (barWidth - barWidth / barWidthDivider) / 2 +
                  1
              )
              .attr(
                'x2',
                d =>
                  calculateXAttribute(x(d.x), barWidth, extraStatWidth, index) +
                  (barWidth - barWidth / barWidthDivider) / 2 +
                  1
              )
              .attr('y1', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].lower &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  const lowerY =
                    ((height - margin.top - margin.bottom - y(d.y)) /
                      (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].lower / 100);
                  const maxY = height - margin.top - margin.bottom;
                  if (lowerY < maxY) {
                    return maxY - lowerY;
                  }
                  return lowerY - maxY;
                }
                return height - margin.top - margin.bottom;
              })
              .attr('y2', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].upper &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  const upperY =
                    ((height - margin.top - margin.bottom - y(d.y)) /
                      (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100);
                  const maxY = height - margin.top - margin.bottom;
                  if (upperY < maxY) {
                    return maxY - upperY;
                  }
                  return -Math.abs(upperY - maxY);
                }
                return height - margin.top - margin.bottom;
              });

            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('line')
              .style('stroke', chartProps.populationColors[index])
              .style('stroke-width', d => (d.y > 0 ? 2 : 0))
              .attr(
                'x1',
                d =>
                  calculateXAttribute(x(d.x), barWidth, extraStatWidth, index) +
                  (barWidth - barWidth / barWidthDivider) / 2 +
                  1 -
                  5
              )
              .attr(
                'x2',
                d =>
                  calculateXAttribute(x(d.x), barWidth, extraStatWidth, index) +
                  (barWidth - barWidth / barWidthDivider) / 2 +
                  1 +
                  5
              )
              .attr('y1', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].lower &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  const lowerY =
                    ((height - margin.top - margin.bottom - y(d.y)) /
                      (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].lower / 100);
                  const maxY = height - margin.top - margin.bottom;
                  if (lowerY < maxY) {
                    return maxY - lowerY;
                  }
                  return lowerY - maxY;
                }
                return height - margin.top - margin.bottom;
              })
              .attr('y2', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].lower &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  const lowerY =
                    ((height - margin.top - margin.bottom - y(d.y)) /
                      (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].lower / 100);
                  const maxY = height - margin.top - margin.bottom;
                  if (lowerY < maxY) {
                    return maxY - lowerY;
                  }
                  return lowerY - maxY;
                }
                return height - margin.top - margin.bottom;
              });
            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('line')
              .style('stroke', chartProps.populationColors[index])
              .style('stroke-width', d => (d.y > 0 ? 2 : 0))
              .attr(
                'x1',
                d =>
                  calculateXAttribute(x(d.x), barWidth, extraStatWidth, index) +
                  (barWidth - barWidth / barWidthDivider) / 2 +
                  1 -
                  5
              )
              .attr(
                'x2',
                d =>
                  calculateXAttribute(x(d.x), barWidth, extraStatWidth, index) +
                  (barWidth - barWidth / barWidthDivider) / 2 +
                  1 +
                  5
              )
              .attr('y1', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].upper &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  const upperY =
                    ((height - margin.top - margin.bottom - y(d.y)) /
                      (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100);
                  const maxY = height - margin.top - margin.bottom;
                  if (upperY < maxY) {
                    return maxY - upperY;
                  }
                  return -Math.abs(upperY - maxY);
                }
                return height - margin.top - margin.bottom;
              })
              .attr('y2', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].upper &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  const upperY =
                    ((height - margin.top - margin.bottom - y(d.y)) /
                      (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100);
                  const maxY = height - margin.top - margin.bottom;
                  if (upperY < maxY) {
                    return maxY - upperY;
                  }
                  return -Math.abs(upperY - maxY);
                }
                return height - margin.top - margin.bottom;
              });
            extraStatWidth += barWidth;
          });
        }

        chart
          .append('g')
          .attr('class', 'results-bar-left-axis')
          .call(
            d3
              .axisLeft(y)
              .tickFormat(e => {
                if (Math.floor(e) !== e) {
                  return null;
                }
                return e;
              })
              .ticks(5)
          );

        if (yLabel) {
          chartSvg
            .append('text')
            .attr('class', 'axis-title')
            .attr('transform', 'rotate(-90)')
            .attr('y', labelMargins.y)
            .attr('dy', labelMargins.ydY)
            .attr('x', margin.top * -1)
            .style('text-anchor', 'end')
            .text(yLabel);
        }

        if (xLabel) {
          chartSvg
            .append('text')
            .attr('class', 'axis-title')
            .attr('y', height - labelMargins.xY)
            .attr('x', width - labelMargins.xX)
            .text(xLabel);
        }
      } else {
        // HORIZONTAL BARS
        let barHeightDivider = 1;
        const barHeightSettings = () => {
          switch (stats.length) {
            case 1:
              barHeightDivider = 6;
              break;
            case 2:
              barHeightDivider = 4;
              break;
            case 3:
              barHeightDivider = 4;
              break;
            case 4:
              barHeightDivider = 4;
              break;
            case 5:
              barHeightDivider = 10;
              break;
            case 6:
              barHeightDivider = 12;
              break;
            default:
              barHeightDivider = 1;
              break;
          }
          return barHeightDivider;
        };
        barHeightDivider = barHeightSettings();

        const calculateYAttribute = (
          xValue,
          passedWidth,
          passedExtraWidth,
          statIndex
        ) => {
          let yAttribute;
          switch (stats.length) {
            case 1:
              yAttribute =
                xValue + passedExtraWidth + passedWidth / barHeightDivider / 2;
              break;
            case 2:
              if (statIndex === 0) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  passedWidth / barHeightDivider -
                  3;
              } else {
                yAttribute = xValue + passedExtraWidth + 3;
              }
              break;
            case 3:
              if (statIndex === 0) {
                yAttribute =
                  xValue + passedExtraWidth + passedWidth / barHeightDivider;
              } else if (statIndex === 2) {
                yAttribute = xValue + passedExtraWidth;
              } else {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  passedWidth / barHeightDivider / 2;
              }
              break;
            case 4:
              if (statIndex === 0) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barHeightDivider) * 2 -
                  3;
              } else if (statIndex === 3) {
                yAttribute =
                  xValue +
                  passedExtraWidth -
                  passedWidth / barHeightDivider +
                  3;
              } else if (statIndex === 1) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  passedWidth / barHeightDivider -
                  1;
              } else {
                yAttribute = xValue + passedExtraWidth + 1;
              }
              break;
            case 5:
              if (statIndex === 0) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barHeightDivider) * 2;
              } else if (statIndex === 4) {
                yAttribute =
                  xValue + passedExtraWidth - passedWidth / barHeightDivider;
              } else if (statIndex === 1) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  passedWidth / barHeightDivider +
                  passedWidth / barHeightDivider / 4;
              } else if (statIndex === 2) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  passedWidth / barHeightDivider / 2;
              } else if (statIndex === 3) {
                yAttribute =
                  xValue +
                  passedExtraWidth -
                  passedWidth / barHeightDivider / 4;
              } else {
                yAttribute = xValue + passedExtraWidth;
              }
              break;
            case 6:
              if (statIndex === 0) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barHeightDivider) * 2 -
                  15 +
                  passedWidth / barHeightDivider -
                  10;
              } else if (statIndex === 1) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  (passedWidth / barHeightDivider) * 2 -
                  15;
              } else if (statIndex === 2) {
                yAttribute =
                  xValue +
                  passedExtraWidth +
                  passedWidth / barHeightDivider -
                  5;
              } else if (statIndex === 3) {
                yAttribute = xValue + passedExtraWidth + 5;
              } else if (statIndex === 4) {
                yAttribute =
                  xValue +
                  passedExtraWidth -
                  passedWidth / barHeightDivider +
                  15;
              } else {
                yAttribute =
                  xValue +
                  passedExtraWidth -
                  passedWidth / barHeightDivider / 2 +
                  15 -
                  passedWidth / barHeightDivider;
              }
              break;

            default:
              yAttribute = xValue + passedExtraWidth;
              break;
          }
          return yAttribute;
        };

        let barHeight = 0;
        let yAxisTransformY = 0;
        if (isStartOrEnd) {
          barHeight =
            (height - margin.top - margin.bottom * (end - start + 1)) /
            (end - start + 1) /
            populationsLength;
          yAxisTransformY = barHeight * (populationsLength / 2);
        }
        const chartSvg = d3
          .select(svgRef)
          .attr('width', width)
          .attr('height', height);

        chartSvg.selectAll('*').remove();

        const chart = chartSvg
          .append('g')
          .attr('width', width - margin.left - margin.right)
          .attr('height', height - margin.top - margin.bottom)
          .attr('class', 'results-bar-container')
          .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const x = d3
          .scaleLinear()
          .range([0, width - margin.left - margin.right]);

        let d3Max;
        if (displayStatisticalRelevance) {
          try {
            d3Max = d3.max(
              chartData.map((stat, index) =>
                d3.max(
                  stat,
                  (d, i) =>
                    (d.y / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100)
                )
              )
            );
          } catch {
            d3Max = d3.max(chartData.map(stat => d3.max(stat, d => d.y)));
          }
        } else {
          d3Max = d3.max(chartData.map(stat => d3.max(stat, d => d.y)));
        }
        x.domain([0, d3Max]);

        let y = null;

        if (isStartOrEnd) {
          y = d3
            .scaleLinear()
            .range([
              0,
              height - margin.top - margin.bottom - yAxisTransformY * 2
            ]);
          y.domain([start, end]);
        } else {
          y = d3
            .scaleBand()
            .range([0, height - margin.top - margin.bottom])
            .padding(0.3);
          y.domain(chartData.map(d => d.x));
          barHeight = y.bandwidth();
        }

        const div = d3.select('.tooltip-container').style('display', 'none');
        let extraHeight = 0;
        stats.forEach((stat, index) => {
          chart
            .selectAll('.bar')
            .data(stat)
            .enter()
            .append('rect')
            .style('fill', chartProps.populationColors[index])
            // .attr('x', d => x(d.x + 0.5 + extraWidth))
            .attr('x', 0)
            .attr('width', d => x(d.y))
            .attr('y', d =>
              calculateYAttribute(y(d.x), barHeight, extraHeight, index)
            )
            .attr('height', barHeight - barHeight / barHeightDivider)
            .attr(
              'class',
              `results-bar-single-bar result-chart-cursor-pointer result-chart-on-hover-opacity ${
                displayStatisticalRelevance ? 'result-chart-half-opacity' : ''
              }`
            )
            .on('mouseover', (d, i) => {
              div
                .style('color', chartProps.populationColors[index])
                .transition()
                .duration(200)
                .style('display', 'block');
              div
                .html(() => {
                  if (displayStatisticalRelevance) {
                    if (displayRatingPercentage) {
                      return `<strong>${d.y.toFixed(2)} %</strong>
                      <br><span style="font-size: 12px">${
                        statisticalRelevanceStats[index][i].lower
                      }%-${statisticalRelevanceStats[index][i].upper}%</span>`;
                    }
                    return `<strong>${d.y}</strong>
                      <br><span style="font-size: 12px">${(
                        (d.y /
                          (statisticalRelevanceStats[index][i].fit / 100)) *
                        (statisticalRelevanceStats[index][i].lower / 100)
                      ).toFixed(2)}-${(
                      (d.y / (statisticalRelevanceStats[index][i].fit / 100)) *
                      (statisticalRelevanceStats[index][i].upper / 100)
                    ).toFixed(2)}</span>`;
                  }
                  if (displayRatingPercentage) {
                    return `<strong>${d.y.toFixed(2)} %</strong>`;
                  }
                  return `<strong>${d.y}</strong>`;
                })
                .style('left', () => `${d3.event.pageX + 10}px`)
                .style('top', () => `${d3.event.pageY}px`);
            })
            .on('mouseout', () => {
              div
                .transition()
                .duration(500)
                .style('display', 'none');
            });
          extraHeight += barHeight;
        });

        const ticks = end - start <= 20 ? end - start : 20;

        chart
          .append('g')
          .attr('class', 'results-bar-bottom-axis')
          .attr(
            'transform',
            `translate(${0}, ${height - margin.top - margin.bottom})`
          )
          .call(
            d3
              .axisBottom(x)
              .ticks(ticks)
              .tickFormat((d, i) => {
                if (i % chartProps.tickFrequency === 0) {
                  if (typeof d === 'number') {
                    if (Number.isInteger(d)) {
                      return d;
                    }
                    return null;
                  }
                  return d;
                }
                return null;
              })
          );

        if (isStartOrEnd) {
          chart
            .append('g')
            .append('line')
            .style('stroke', '#8b8b8d')
            .style('stroke-width', 1)
            .attr('x1', 0)
            .attr('y1', height - margin.top - margin.bottom)
            .attr('x2', width)
            .attr('y2', height - margin.top - margin.bottom);
        }

        // Fix: fill gap between left and bottom axis
        if (isStartOrEnd) {
          chart
            .append('g')
            .append('line')
            .style('stroke', '#8b8b8d')
            .style('stroke-width', 1)
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', 0)
            .attr('y2', height - margin.top - margin.bottom);
        }

        let answerTicks = -1;
        chart
          .append('g')
          .attr('class', 'results-bar-left-axis')
          .attr('transform', `translate(${0},${yAxisTransformY})`)
          .call(
            d3
              .axisLeft(y)
              .tickFormat(e => {
                if (Math.floor(e) !== e) {
                  return null;
                }
                answerTicks += 1;
                return limitAnswerStringLength(
                  answerStrings[answerTicks],
                  'horizontal'
                );
              })
              .ticks(5)
          );

        chart
          .select('.results-bar-left-axis')
          .selectAll('.tick')
          .on('mouseover', t => {
            div
              .style('color', '#000000')
              .attr('width', 300)
              .transition()
              .duration(200)
              .style('display', 'block');
            div
              .html(
                () =>
                  `<span style="font-size: 12px">${answerStrings[t - 1]}</span>`
              )
              .style('left', () => `${d3.event.pageX + 10}px`)
              .style('top', () => `${d3.event.pageY}px`);
          })
          .on('mouseout', () => {
            div
              .transition()
              .duration(500)
              .style('display', 'none');
          });

        const countImage =
          '<g><g><circle stroke-width="0.501" stroke-miterlimit="4" stroke-dashoffset="0" r="0.959111" cy="1.249265" cx="2.05403" id="path832"/><path class="nocolor" stroke-width="" stroke-miterlimit="4" id="path842" d="m3.90661,4.815655c0,0 -0.0617,-2.01531 -1.84912,-1.99861c-1.78736,0.0167 -1.77656,1.86868 -1.77656,1.86868l3.73198,0"/></g></g>';

        if (
          showCounts &&
          shouldShowCounts &&
          chartData.length * data.length < 13 &&
          !displayStatisticalRelevance
        ) {
          let extraCountHeight = 0;
          stats.forEach((stat, index) => {
            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('text')
              .attr('x', d => x(d.y) + 30)
              .attr(
                'y',
                d =>
                  calculateYAttribute(
                    y(d.x),
                    barHeight,
                    extraCountHeight,
                    index
                  ) +
                  (barHeight - barHeight / barHeightDivider) / 2 +
                  5
              )
              .style('text-anchor', 'middle')
              .text(d =>
                displayRatingPercentage ? `${d.y.toFixed(2)} %` : d.y
              )
              .attr('font-family', 'sans-serif')
              .attr('font-size', '11px')
              .attr('font-weight', '600')
              .attr('fill', chartProps.populationColors[index])
              .attr('class', (d, i) => `bar-value-${i}`);

            if (!displayRatingPercentage) {
              chart
                .selectAll('.bar-values-user')
                .data(stat)
                .enter()
                .append('svg')
                .attr('x', (d, i) => {
                  const textElement = chart.select(`.bar-value-${i}`).node();
                  let textWidth = 0;
                  if (textElement) {
                    textWidth = textElement.getBoundingClientRect().width;
                  }
                  return x(d.y) - textWidth + 21;
                })
                .attr(
                  'y',
                  d =>
                    calculateYAttribute(
                      y(d.x),
                      barHeight,
                      extraCountHeight,
                      index
                    ) +
                    (barHeight - barHeight / barHeightDivider) / 2 -
                    4
                )
                .attr('width', 16)
                .attr('height', 16)
                .attr('viewBox', '0 0 7 9')
                .html(countImage)
                .attr('fill', chartProps.populationColors[index]);
            }
            extraCountHeight += barHeight;
          });
        }

        if (
          displayStatisticalRelevance &&
          statisticalRelevanceStats &&
          statisticalRelevanceStats.length &&
          !isEmpty(statisticalRelevanceStats)
        ) {
          let extraStatWidth = 0;

          stats.forEach((stat, index) => {
            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('line')
              .style('stroke', chartProps.populationColors[index])
              .style('stroke-width', 2)
              .attr(
                'y1',
                d =>
                  calculateYAttribute(
                    y(d.x),
                    barHeight,
                    extraStatWidth,
                    index
                  ) +
                  (barHeight - barHeight / barHeightDivider) / 2 +
                  1
              )
              .attr(
                'y2',
                d =>
                  calculateYAttribute(
                    y(d.x),
                    barHeight,
                    extraStatWidth,
                    index
                  ) +
                  (barHeight - barHeight / barHeightDivider) / 2 +
                  1
              )
              .attr('x1', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].lower &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  return (
                    (x(d.y) / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].lower / 100)
                  );
                }
                return width - margin.left - margin.right;
              })
              .attr('x2', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].upper &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  return (
                    (x(d.y) / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100)
                  );
                }
                return width - margin.left - margin.right;
              });
            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('line')
              .style('stroke', chartProps.populationColors[index])
              .style('stroke-width', d => (d.y > 0 ? 2 : 0))
              .attr(
                'y1',
                d =>
                  calculateYAttribute(
                    y(d.x),
                    barHeight,
                    extraStatWidth,
                    index
                  ) +
                  (barHeight - barHeight / barHeightDivider) / 2 +
                  5 +
                  1
              )
              .attr(
                'y2',
                d =>
                  calculateYAttribute(
                    y(d.x),
                    barHeight,
                    extraStatWidth,
                    index
                  ) +
                  (barHeight - barHeight / barHeightDivider) / 2 -
                  5 +
                  1
              )
              .attr('x1', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].lower &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  return (
                    (x(d.y) / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].lower / 100)
                  );
                }
                return width - margin.left - margin.right;
              })
              .attr('x2', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].lower &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  return (
                    (x(d.y) / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].lower / 100)
                  );
                }
                return width - margin.left - margin.right;
              });
            chart
              .selectAll('.bar-values')
              .data(stat)
              .enter()
              .append('line')
              .style('stroke', chartProps.populationColors[index])
              .style('stroke-width', d => (d.y > 0 ? 2 : 0))
              .attr(
                'y1',
                d =>
                  calculateYAttribute(
                    y(d.x),
                    barHeight,
                    extraStatWidth,
                    index
                  ) +
                  (barHeight - barHeight / barHeightDivider) / 2 +
                  5 +
                  1
              )
              .attr(
                'y2',
                d =>
                  calculateYAttribute(
                    y(d.x),
                    barHeight,
                    extraStatWidth,
                    index
                  ) +
                  (barHeight - barHeight / barHeightDivider) / 2 -
                  5 +
                  1
              )
              .attr('x1', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].upper &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  return (
                    (x(d.y) / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100)
                  );
                }
                return width - margin.left - margin.right;
              })
              .attr('x2', (d, i) => {
                if (
                  statisticalRelevanceStats[index][i].upper &&
                  statisticalRelevanceStats[index][i].fit
                ) {
                  return (
                    (x(d.y) / (statisticalRelevanceStats[index][i].fit / 100)) *
                    (statisticalRelevanceStats[index][i].upper / 100)
                  );
                }
                return width - margin.left - margin.right;
              });
            extraStatWidth += barHeight;
          });
        }
      }
    }
  };

  return (
    <div className="filter-results-charts-bar-wrapper">
      {data && data.length ? (
        <svg
          className="filter-results-charts-bar"
          ref={elem => {
            if (elem) {
              setSvg(elem);
            }
          }}
        />
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default Bar;
