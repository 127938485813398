/* eslint-disable no-case-declarations */
import React, { useState, useRef } from 'react';

import OpportunityGraphBar from '../OpportunityGraph/components/Bar/Bar';
import IdeaSelectionGraphBar from '../IdeaSelectionGraph/components/Bar/Bar';
import ConceptTestGraphBar from '../ConceptTestGraph/components/Bar/Bar';
import ValueSelectionGraphBar from '../ValueSelectionGraph/components/Bar/Bar';
import CustomGraphBar from '../CustomGraph/components/Bar/MCBar';
import ColorPicker from '../../../../../../../surveys/components/SurveyBuilderPage/SurveyOverview/components/ColorPicker/ColorPicker';

import tickIcon from '../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../assets/img/delete.svg';

import styles from './EditBackgroundPopup.module.css';

export default ({
  templateType,
  templateTypes,
  onClose,
  themeColors,
  onSaveGraph,
  questions,
  xQuestion,
  yQuestion,
  axisEdgeLabels,
  xLabel,
  yLabel
}) => {
  const barParent = useRef();
  const [backgroundColors, setBackgroundColors] = useState(themeColors);

  const renderGraph = () => {
    let graph;
    let graphProps = {
      parent: barParent,
      width: '300',
      height: '260',
      data: [],
      unFilteredData: [],
      isFiltered: false,
      dataPointColors: [],
      unFilteredDataPointColors: [],
      questions: [],
      activeLoversHaters: null,
      themeColors: backgroundColors,
      fontSize: '6px',
      dataTooltips: []
    };

    switch (templateType) {
      case templateTypes.OPPORTUNITY_GRAPH:
        graph = <OpportunityGraphBar {...graphProps} />;
        break;

      case templateTypes.IDEA_SELECTION_GRAPH:
        graph = <IdeaSelectionGraphBar {...graphProps} />;
        break;

      case templateTypes.VALUE_SELECTION_GRAPH:
        graph = <ValueSelectionGraphBar {...graphProps} />;
        break;

      case templateTypes.CONCEPT_TEST_GRAPH:
        graph = <ConceptTestGraphBar {...graphProps} />;
        break;

      case templateTypes.CUSTOM_GRAPH:
        const customGraphProps = {
          questions,
          numberOfXAnswers: xQuestion.answers
            ? xQuestion.answers.length - 1
            : 0,

          numberOfYAnswers: yQuestion.answers
            ? yQuestion.answers.length - 1
            : 0,

          axisEdgeLabels,
          xLabel,
          yLabel,
          customAxisLabelStyles: {
            fontSize: '11px'
          }
        };

        graphProps = {
          ...graphProps,
          ...customGraphProps
        };

        graph = <CustomGraphBar {...graphProps} />;
        break;

      default:
        graph = <ConceptTestGraphBar {...graphProps} />;
    }
    return graph;
  };

  return (
    <div className={styles.container} ref={barParent}>
      <div className={styles.templateTitle}>
        Edit background colors
        <div className={styles.bottomLine} />
      </div>
      <div className={styles.editContainer}>
        {renderGraph()}
        <div className={styles.colorPickersContainer}>
          {backgroundColors && backgroundColors.length
            ? backgroundColors.map((bColor, index) => (
                <div
                  className={styles.colorPicker}
                  key={`color-picker-${index.toString()}`}
                >
                  <ColorPicker
                    colorCode={bColor.colorCode}
                    onChange={colorCode => {
                      const newBackgroundColors = [...backgroundColors];
                      newBackgroundColors[index] = {
                        ...newBackgroundColors[index],
                        colorCode
                      };
                      setBackgroundColors(newBackgroundColors);
                    }}
                    style={{}}
                    fixedPosition
                  />
                </div>
              ))
            : null}
        </div>
        <div className={styles.bottomLine} />
      </div>
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          <img
            className={styles.confirm}
            src={tickIcon}
            alt="Confirm icon"
            onClick={() => {
              if (
                backgroundColors.every(
                  color =>
                    color.colorCode.length === 7 &&
                    color.colorCode.charAt(0) === '#'
                )
              ) {
                const customThemeColorsRequest = backgroundColors.map(uC => {
                  const iUC = uC;
                  delete iUC.__typename;
                  return iUC;
                });
                onSaveGraph(customThemeColorsRequest);
              }
              onClose();
            }}
            role="presentation"
          />
          <img
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
            onClick={() => onClose()}
            role="presentation"
          />
        </div>
      </div>
    </div>
  );
};
