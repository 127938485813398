import React, { useState } from 'react';

const CampaignName = props => {
  const [nameTooltipPosition, setNameTooltipPosition] = useState(null);

  const { name } = props;

  return (
    <div className="list-item-campaign-name">
      <span
        onMouseEnter={e => {
          // Only show tooltip for overflowing elements based on max width (250px)
          if (e && e.target && e.target.offsetWidth > 250) {
            const rect = e.target.getBoundingClientRect();
            const top = rect.top - 10;
            const left = rect.left + 255;

            setNameTooltipPosition({
              top,
              left
            });
          }
        }}
        onMouseLeave={() => {
          if (nameTooltipPosition) {
            setNameTooltipPosition(null);
          }
        }}
      >
        {name}
      </span>
      <div
        className="tooltip"
        style={
          nameTooltipPosition
            ? {
                display: 'inline-block',
                left: `${nameTooltipPosition.left}px`,
                top: `${nameTooltipPosition.top}px`
              }
            : {}
        }
      >
        {name}
      </div>
    </div>
  );
};

export default CampaignName;
