import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import addQuestionBranch from './addQuestionBranch';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  const newState = addQuestionBranch(
    surveysState,
    action.flowId,
    action.customAttributes
  );
  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });
}
