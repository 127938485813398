import { select, put, call } from 'redux-saga/effects';
import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';

import { SET_ACTIVE_FLOW_COMPLETED, SET_NEW_STATE } from '../../actions';

import updateDecisionFramework from '../updateDecisionFramework/updateDecisionFramework';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  if (
    action &&
    action.api &&
    surveysState.activeFlow &&
    !surveysState.activeFlow.flows &&
    surveysState.activeFlow.id
  ) {
    const newSurveyStateActiveFlow = {
      question: { ...surveysState.activeFlow }
    };
    action.api('updateQuestion', newSurveyStateActiveFlow);

    if (
      surveysState.activeFlow.matrix &&
      surveysState.activeFlow.matrix.decisionFrameworkType
    ) {
      const {
        surveyWithUpdatedFramework,
        updateRequest
      } = updateDecisionFramework(surveysState.survey, surveysState.activeFlow);
      if (surveyWithUpdatedFramework) {
        const newState = {
          ...surveysState,
          survey: surveyWithUpdatedFramework
        };

        yield put({ type: SET_NEW_STATE, newState });

        if (updateRequest) {
          yield call(action.api, updateRequest.api, {
            variables: updateRequest.variables
          });
        }
      }
    }
  }

  const surveyLists = generateSurveyLists(
    surveysState.survey.content,
    surveysState.survey.questions
  );

  yield put({
    type: SET_ACTIVE_FLOW_COMPLETED,
    flow: action.flow,
    surveyLists
  });
}
