import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import ActionDropdown from '../ActionDropdown/ActionDropdown';
import Loader from '../../../Loader/Loader';

import styles from './CommunityAutomationsRecord.module.css';
import Popup from '../../../../../../campaigns/components/Popup';
import ConfirmationDialog from '../../../../../../campaigns/components/ConfirmationDialog/ConfirmationDialog';
import {
  CHANGE_COMMUNITY_AUTOMATION_STATUS,
  DUPLICATE_COMMUNITY_AUTOMATION,
  REMOVE_COMMUNITY_AUTOMATION
} from '../../../../../../graphql/CommunityAutomations';
import dutchFlag from '../../../../../../campaigns/components/CampaignTable/CampaignListItem/assets/dutch.png';
import frenchFlag from '../../../../../../campaigns/components/CampaignTable/CampaignListItem/assets/french.png';
import englishFlag from '../../../../../../campaigns/components/CampaignTable/CampaignListItem/assets/english.png';
import germanFlag from '../../../../../../campaigns/components/CampaignTable/CampaignListItem/assets/german.png';

export default ({ history, record, refetch }) => {
  const [actionDropdownVisible, setActionDropdownVisible] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(null);

  const createdAtDate =
    record && record.createdAt ? new Date(record.createdAt) : null;
  const updatedAtDate =
    record && record.updatedAt ? new Date(record.updatedAt) : null;
  const createdAt = createdAtDate
    ? `${createdAtDate.getDate()}/${createdAtDate.getMonth() +
        1}/${createdAtDate.getFullYear()}`
    : '';
  const updatedAt = updatedAtDate
    ? `${updatedAtDate.getDate()}/${updatedAtDate.getMonth() +
        1}/${updatedAtDate.getFullYear()}`
    : '';

  const actionDropdownPositionStyle = {
    right: '10px',
    top: '25px',
    bottomPosition: '40px',
    width: '160px'
  };

  const [
    removeCommunityAutomation,
    { loading: removeCommunityAutomationLoading }
  ] = useMutation(REMOVE_COMMUNITY_AUTOMATION);

  const [
    changeCommunityAutomationStatus,
    { loading: changeCommunityAutomationStatusLoading }
  ] = useMutation(CHANGE_COMMUNITY_AUTOMATION_STATUS);

  const [
    duplicateCommunityAutomationStatus,
    { loading: duplicateCommunityAutomationStatusLoading }
  ] = useMutation(DUPLICATE_COMMUNITY_AUTOMATION);

  const isLoading =
    removeCommunityAutomationLoading ||
    changeCommunityAutomationStatusLoading ||
    duplicateCommunityAutomationStatusLoading;

  const getCampaignAvailableToLanguagesFlags = language => {
    const getLanguageFlagFile = () => {
      if (language === 'Dutch') {
        return dutchFlag;
      }
      if (language === 'French') {
        return frenchFlag;
      }
      if (language === 'English') {
        return englishFlag;
      }
      if (language === 'German') {
        return germanFlag;
      }
      return null;
    };

    if (language) {
      return (
        <img
          className={styles.languageFlag}
          src={getLanguageFlagFile()}
          alt={`${language} FLAG`}
        />
      );
    }
    return null;
  };

  return (
    <tr className={styles.record}>
      <td className={styles.rowContainer} style={{ position: 'relative' }}>
        {getCampaignAvailableToLanguagesFlags(record.languageFilterApplied)}
        <span className={styles.nameContainer}>{record.name}</span>
      </td>
      <td className={styles.rowContainer}>{record.client}</td>
      <td className={styles.rowContainer}>{createdAt}</td>
      <td className={styles.rowContainer}>{updatedAt}</td>
      <td className={`${styles.rowContainer} ${styles.statusContainer}`}>
        {record.status}
      </td>
      <td className={styles.rowContainer}>
        <div className={styles.actionsContainer}>
          {isLoading ? <Loader /> : null}
          <div
            role="presentation"
            className={styles.actionsButton}
            onClick={e => {
              e.stopPropagation();
              setActionDropdownVisible(true);
            }}
          >
            ...
          </div>
          {confirmationPopup ? (
            <Popup
              component={
                <ConfirmationDialog
                  label={confirmationPopup.actionLabel}
                  confirmLabel={confirmationPopup.confirmLabel}
                  cancelLabel="Cancel"
                  onConfirm={() => {
                    confirmationPopup.onConfirm();
                  }}
                  onCancel={() => {
                    setConfirmationPopup(null);
                  }}
                />
              }
              onClose={() => {
                setConfirmationPopup(null);
              }}
            />
          ) : null}
          {actionDropdownVisible ? (
            <ActionDropdown
              positionStyle={actionDropdownPositionStyle}
              recordStatus={record.status}
              onBlur={() => setActionDropdownVisible(false)}
              onEditClick={() => {
                setActionDropdownVisible(false);
                history.push(`/community-automations/${record.id}`);
              }}
              onDuplcateClick={() => {
                setActionDropdownVisible(false);
                setConfirmationPopup({
                  actionLabel:
                    'Are you sure you want to duplicate this automation?',
                  confirmLabel: 'Duplicate',
                  onConfirm: async () => {
                    await duplicateCommunityAutomationStatus({
                      variables: {
                        id: record.id
                      }
                    });
                    refetch();
                    setConfirmationPopup(null);
                  }
                });
              }}
              onDeactivateClick={async () => {
                await changeCommunityAutomationStatus({
                  variables: {
                    id: record.id
                  }
                });
                refetch();
                setActionDropdownVisible(false);
              }}
              onDeleteClick={() => {
                setActionDropdownVisible(false);

                setConfirmationPopup({
                  actionLabel:
                    'Are you sure you want to delete this automation?',
                  confirmLabel: 'Delete',
                  onConfirm: async () => {
                    await removeCommunityAutomation({
                      variables: {
                        id: record.id
                      }
                    });
                    refetch();
                    setActionDropdownVisible(false);
                  }
                });
                // refetch();
              }}
            />
          ) : null}
        </div>
      </td>
    </tr>
  );
};
