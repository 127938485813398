import React from 'react';

import CommunityAutomationsListPage from './components/CommunityAutomationsListPage/CommunityAutomationsListPage';
import CommunityAutomationPage from './components/CommunityAutomationPage/CommunityAutomationPage';

export default props => {
  const {
    match: {
      params: { automationId }
    }
  } = props;

  return (
    <>
      {!automationId && (
        <CommunityAutomationsListPage recordsPerPage={20} {...props} />
      )}
      {automationId && (
        <CommunityAutomationPage automationId={automationId} {...props} />
      )}
    </>
  );
};
