import gql from 'graphql-tag';

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: EmailAddress!) {
    requestPasswordReset(input: { email: $email }) {
      done
      errors {
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(input: { password: $password, token: $token }) {
      done
      errors {
        message
      }
    }
  }
`;
