import React from 'react';

import styles from './AdvancedFilterSetting.module.css';

export default ({
  title,
  description,
  options,
  onClick,
  settingValue,
  disabled
}) => (
  <>
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{title}</span>
        {options.map((o, i) => (
          <div
            key={`${title}-radio-${i.toString()}`}
            className={styles.optionContainer}
          >
            <input
              type="radio"
              value={o.value}
              name={title}
              onChange={onClick}
              checked={settingValue === o.value}
              disabled={disabled}
            />
            {o.label}
          </div>
        ))}
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  </>
);
