import React, { useState, useEffect } from 'react';
import styles from './SendNotificationFilter.module.css';
import Icon from '../../../campaigns/components/Icon';
import DropdownSelector from '../DropdownSelector/DropdownSelector';
import DebounceInput from '../../../campaigns/components/DebounceInput/DebounceInput';
import Loader from '../../../campaigns/components/Loader/Loader';
import MultipleChoiceFieldFilter from './MultipleChoiceFieldFilter';
import SingleChoiceFilter from './SingleChoiceFilter';

import prepareActiveFiltersForRequest from '../helpers/prepareActiveFiltersForRequest';

const SendNotificationFilter = props => {
  const {
    notificationProps,
    filterConfiguration,
    FILTER_CONSTANTS,
    COMMUNITY_MANAGEMENT_TYPES,
    activeFilters,
    setActiveFilters,
    updateActiveFilters,
    getPushNotificationTargetCountQuery,
    countField,
    section,
    targetUsers,
    setTargetUsers,
    SECTION_KEYS,
    showTargetUsersCount,
    customTargetLabel
  } = props;

  const [isCountFetching, setIsCountFetching] = useState(false);

  useEffect(
    () => {
      const getPushNotificationTargetCount = async activeFiltersWithValue => {
        setIsCountFetching(true);

        const pushNotificationTargetCount = await getPushNotificationTargetCountQuery(
          {
            variables: {
              activeFilters: prepareActiveFiltersForRequest(
                activeFiltersWithValue
              ),
              survey:
                notificationProps &&
                notificationProps.survey &&
                notificationProps.survey.value
            }
          }
        );

        setTargetUsers(pushNotificationTargetCount.data[countField].count);
        setIsCountFetching(false);
      };

      const activeFiltersWithValue = activeFilters.filter(filter => {
        if (filter.name === '') return false;
        if (Array.isArray(filter.values)) {
          return !!filter.values.length;
        }
        if (Object.keys(filter.values).length) return true;
        return null;
      });

      if (
        (notificationProps &&
          notificationProps.survey &&
          notificationProps.survey.value) ||
        section === SECTION_KEYS.PUSH_NOTIFICATION
      ) {
        getPushNotificationTargetCount(
          prepareActiveFiltersForRequest(activeFiltersWithValue)
        );
      }
    },
    [activeFilters, notificationProps.survey]
  );

  const onAddFilter = () => {
    const newFilter = {
      name: ''
    };

    setActiveFilters([...activeFilters, ...[newFilter]]);
  };

  const filterTypesOptions = () => {
    const availableOptions = [];
    Object.keys(filterConfiguration).forEach(availableFilter => {
      const checkIsFilterSelected = activeFilters.filter(
        activeFilter => activeFilter.name === availableFilter
      );

      const showForThisSection =
        filterConfiguration &&
        filterConfiguration[availableFilter] &&
        (!filterConfiguration[availableFilter].hideFromSections ||
          !filterConfiguration[availableFilter].hideFromSections.length ||
          filterConfiguration[availableFilter].hideFromSections.indexOf(
            section
          ) === -1);

      if (
        (!checkIsFilterSelected || checkIsFilterSelected.length === 0) &&
        showForThisSection
      ) {
        availableOptions.push({
          value: availableFilter,
          label: filterConfiguration[availableFilter].label
        });
      }
      return null;
    });
    return availableOptions;
  };

  const showFilterValues = filter => {
    let communityFilterType = null;
    if (
      filter.name === 'communities' &&
      !(filter.subFilter && filter.subFilter.value)
    ) {
      return null;
    }
    if (
      filter.name === 'communities' &&
      (filter.subFilter && filter.subFilter.value)
    ) {
      if (filter.subFilter.value === COMMUNITY_MANAGEMENT_TYPES.STATIC) {
        communityFilterType = FILTER_CONSTANTS.MULTIPLE_CHOICE;
      } else {
        communityFilterType = FILTER_CONSTANTS.SINGLE_OPTION;
      }
    }

    if (
      (communityFilterType &&
        communityFilterType === FILTER_CONSTANTS.MULTIPLE_CHOICE) ||
      (filterConfiguration[filter.name] &&
        filterConfiguration[filter.name].type ===
          FILTER_CONSTANTS.MULTIPLE_CHOICE)
    ) {
      return (
        <MultipleChoiceFieldFilter
          filter={filterConfiguration[filter.name]}
          FILTER_CONSTANTS={FILTER_CONSTANTS}
          activeFilters={activeFilters}
          updateActiveFilters={updateActiveFilters}
          subFilter={filter.subFilter}
        />
      );
    }
    if (
      filterConfiguration[filter.name] &&
      filterConfiguration[filter.name].type === FILTER_CONSTANTS.RANGE
    ) {
      return rangeField(filterConfiguration[filter.name]);
    }
    if (
      (communityFilterType &&
        communityFilterType === FILTER_CONSTANTS.SINGLE_OPTION) ||
      (filterConfiguration[filter.name] &&
        filterConfiguration[filter.name].type ===
          FILTER_CONSTANTS.SINGLE_OPTION)
    ) {
      return (
        <SingleChoiceFilter
          filter={filterConfiguration[filter.name]}
          FILTER_CONSTANTS={FILTER_CONSTANTS}
          activeFilters={activeFilters}
          onSelectCompleted={(value, label, filterValue) => {
            updateActiveFilters(filter.name, {
              ...(filterValue && filterValue.length
                ? filterValue[0].values
                : {}),
              ...{ value, label }
            });
          }}
          subFilter={filter.subFilter}
        />
      );
    }
    return null;
  };

  const showAdditionalFilters = filter => {
    if (
      filterConfiguration[filter.name] &&
      filterConfiguration[filter.name].additionalFilter &&
      filterConfiguration[filter.name].additionalFilter.options &&
      filterConfiguration[filter.name].additionalFilter.options.length
    ) {
      return filterConfiguration[filter.name].additionalFilter.options;
    }
    return false;
  };

  const rangeField = filter => (
    <div className={styles.rangeFieldContainer}>
      <div className={styles.rangeFieldContainerStartContainer}>
        <DebounceInput
          placeholder={filter && filter.gteLabel}
          initialValue={
            filter.name &&
            activeFilters[filter.name] &&
            activeFilters[filter.name].values &&
            activeFilters[filter.name].values.gte
          }
          onChangeValue={searchValue => {
            const singleRangeValue = activeFilters.filter(
              singleRangeFilter => singleRangeFilter.name === filter.name
            );
            const existingRangeValue =
              singleRangeValue && singleRangeValue[0]
                ? singleRangeValue[0].values
                : {};
            if (searchValue && searchValue !== '') {
              updateActiveFilters(filter.name, {
                ...existingRangeValue,
                ...{ gte: searchValue }
              });
            } else {
              delete existingRangeValue.gte;
              updateActiveFilters(filter.name, {
                ...existingRangeValue
              });
            }
          }}
          className={styles.rangeFilterInput}
        />
      </div>
      <div className={styles.rangeFieldContainerEndContainer}>
        <DebounceInput
          placeholder={filter && filter.lteLabel}
          initialValue={
            filter.name &&
            activeFilters[filter.name] &&
            activeFilters[filter.name].values &&
            activeFilters[filter.name].values.lte
          }
          onChangeValue={searchValue => {
            const singleRangeValue = activeFilters.filter(
              singleRangeFilter => singleRangeFilter.name === filter.name
            );
            const existingRangeValue =
              singleRangeValue && singleRangeValue[0]
                ? singleRangeValue[0].values
                : {};
            if (searchValue && searchValue !== '') {
              updateActiveFilters(filter.name, {
                ...existingRangeValue,
                ...{ lte: searchValue }
              });
            } else {
              delete existingRangeValue.lte;
              updateActiveFilters(filter.name, {
                ...existingRangeValue
              });
            }
          }}
          className={styles.rangeFilterInput}
        />
      </div>
    </div>
  );

  const renderFilter = (filter, index) => {
    if (!filter.type) {
      return (
        <div key={`push-notification-filter-${index}`}>
          <div className={styles.filterItemContainer}>
            <div className={styles.filterType}>
              <span
                className={styles.closeIcon}
                role="presentation"
                onClick={() => {
                  activeFilters.splice(index, 1);
                  setActiveFilters([...activeFilters]);
                }}
              >
                <Icon type="close" />
              </span>
              <DropdownSelector
                options={filterTypesOptions()}
                value={
                  filter && filter.label
                    ? { value: filter.name, label: filter.label }
                    : {}
                }
                onSelectCompleted={(value, label) => {
                  const newFilter = filter;
                  const newFilterConfiguration = filterConfiguration[value];
                  newFilter.name = value;
                  newFilter.label = label;

                  newFilter.values =
                    newFilterConfiguration.type ===
                    FILTER_CONSTANTS.MULTIPLE_CHOICE
                      ? []
                      : {};
                  setActiveFilters([...activeFilters]);
                }}
                placeholder={FILTER_CONSTANTS.DEFAULT_PLACEHOLDER}
              />
              {showAdditionalFilters(filter) ? (
                <DropdownSelector
                  filterDropdownStyle={styles.filterDropdown}
                  options={showAdditionalFilters(filter)}
                  value={
                    filter && filter.subFilter && filter.subFilter.label
                      ? {
                          value: filter.subFilter.value,
                          label: filter.subFilter.label
                        }
                      : {}
                  }
                  onSelectCompleted={(value, label) => {
                    const newFilter = filter;
                    newFilter.subFilter = {
                      value,
                      label
                    };

                    newFilter.values = [];
                    if (value === COMMUNITY_MANAGEMENT_TYPES.DYNAMIC) {
                      newFilter.values = {};
                    }

                    setActiveFilters([...activeFilters]);
                  }}
                  placeholder={FILTER_CONSTANTS.DEFAULT_PLACEHOLDER}
                />
              ) : null}
            </div>
            <div className={styles.filterType}>{showFilterValues(filter)}</div>
          </div>
          {filter && filter.name === 'campaigns' ? (
            <div className={styles.surveysFilterDescrption}>
              Targets eligible respondents who have not yet completed specific
              survey(s)
            </div>
          ) : null}
          {index < activeFilters.length - 1 ? (
            <div className={styles.andContainer}>
              <span className={styles.and}>AND</span>
            </div>
          ) : null}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className={styles.filterTopContainer}>
        {showTargetUsersCount ? (
          <div className={styles.counter}>
            Target group:{' '}
            <strong>
              {isCountFetching ? (
                <span className={styles.countLoader}>
                  <Loader />
                </span>
              ) : (
                targetUsers
              )}{' '}
              devices
            </strong>
          </div>
        ) : (
          <div className={styles.counter}>{customTargetLabel}</div>
        )}
        <div
          className={styles.addFilterButton}
          role="presentation"
          onClick={() => onAddFilter()}
        >
          Add filter
          <span className={styles.addFilterButtonIcon}>
            <Icon type="add" />
          </span>
        </div>
      </div>
      <div>
        {activeFilters.map((filter, index) => renderFilter(filter, index))}
      </div>
    </div>
  );
};

export default SendNotificationFilter;
