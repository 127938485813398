import React from 'react';

import CampaignResultsPage from '../../containers/CampaignResultsPageContainer';

export default props => {
  const { match } = props;

  return (
    <div style={style.mainContainer}>
      <div
        className="tooltip-container"
        style={{ position: 'absolute', zIndex: 9999999999999999 }}
      />
      <CampaignResultsPage viewToken={match.params.viewToken} {...props} />
    </div>
  );
};

const style = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  }
};
