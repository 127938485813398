import { DECISION_FRAMEWORK_TYPES } from '../../helpers/constants';

export default [
  {
    type: DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH,
    createApi: 'createOpportunityGraph',
    updateApi: 'updateOpportunityGraph',
    removeApi: 'removeOpportunityGraph',
    valuePath: 'opportunityGraphs',
    xQuestionName: 'importanceQuestion',
    yQuestionName: 'satisfactionQuestion'
  },
  {
    type: DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH,
    createApi: 'createIdeaSelectionGraph',
    updateApi: 'updateIdeaSelectionGraph',
    removeApi: 'removeIdeaSelectionGraph',
    valuePath: 'ideaSelectionGraphs',
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion'
  },
  {
    type: DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH,
    createApi: 'createValueSelectionGraph',
    updateApi: 'updateValueSelectionGraph',
    removeApi: 'removeValueSelectionGraph',
    valuePath: 'valueSelectionGraphs',
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion'
  },
  {
    type: DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH,
    createApi: 'createConceptTestGraph',
    updateApi: 'updateConceptTestGraph',
    removeApi: 'removeConceptTestGraph',
    valuePath: 'conceptTestGraphs',
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion'
  },
  {
    type: DECISION_FRAMEWORK_TYPES.CUSTOM_GRAPH,
    createApi: 'createCustomGraph',
    updateApi: 'updateCustomGraph',
    removeApi: 'removeCustomGraph',
    valuePath: 'customGraphs',
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion'
  }
];
