import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';

// Workaround while official useLazyQuery doesn't return a promise
export default (query, other = {}) => {
  const client = useApolloClient();

  return React.useCallback(
    ({ variables }) =>
      client.query({
        query,
        variables,
        ...other
      }),
    [client]
  );
};
