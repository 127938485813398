import React from 'react';

import mergedGraph from '../../assets/img/merged-graph.svg';
import ranking from '../../surveys/assets/AnswerRanking.svg';
import messageQuestionIcon from '../../surveys/assets/Text.svg';
import addToCommunityQuestionIcon from '../../surveys/assets/AddToCommunity.svg';
import matrixQuestionIcon from '../../surveys/assets/matrix.svg';
import downloadIcon from '../../assets/img/download.svg';

const Icon = props => {
  const { type, style } = props;

  const iconsMapping = {
    add: 'b',
    yesno: 'a',
    'add-page': 'c',
    'thank-you': 'd',
    'capture-video': 'e',
    rename: 'f',
    results: 'g',
    search: 'h',
    'short-text': 'i',
    'show-video': 'j',
    delete: 'k',
    duplicate: 'l',
    'showcase-website': 'm',
    camera: 'n',
    edit: 'o',
    grid: 'p',
    list: 'q',
    'long-text': 'r',
    'multiple-choice': 's',
    close: 't',
    rating: 'u',
    checked: 'w',
    unchecked: 'x',
    jump: 'v',
    checkboxes: 'y',
    image: 'z'
  };

  const customIconsMapping = {
    'merged-graph': mergedGraph,
    ranking,
    message: messageQuestionIcon,
    addToCommunity: addToCommunityQuestionIcon,
    matrix: matrixQuestionIcon,
    download: downloadIcon
  };

  if (iconsMapping[type]) {
    return (
      <span
        style={{
          ...style,
          fontFamily: 'BufflClientIcons',
          textTransform: 'lowercase'
        }}
      >
        {iconsMapping[type]}
      </span>
    );
  }

  if (customIconsMapping[type]) {
    return <img src={customIconsMapping[type]} alt={type} />;
  }

  return null;
};

export default Icon;

Icon.defaultProps = {
  type: null
};
