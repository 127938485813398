import generateSurveyLists from '../SurveyBuilder/helpers/generateSurveyLists/generateSurveyLists';
import { DECISION_FRAMEWORK_TYPES } from '../SurveyBuilder/helpers/constants';

const checkSettingsError = filterType => {
  if (filterType.$in.length === 0) {
    return true;
  }
  return false;
};

const parseRichTextToString = question =>
  JSON.parse(question)
    .blocks.map(block => (!block.text.trim() && '\n') || block.text)
    .join('\n');

const customChecker = (questionToCheck, questionIndex) => {
  const surveyErrors = [];

  const customCheckers = [
    {
      type: 'open_question',
      customCheckers: [
        (question, index) => {
          if (
            (!question.maxCharacters && question.maxCharacters !== 0) ||
            question.maxCharacters === ''
          ) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'Maximum Answer Length is missing'
            });
          }
          if (
            (!question.minCharacters && question.minCharacters !== 0) ||
            question.minCharacters === ''
          ) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'Minimum Answer Length is missing'
            });
          }
          if (
            (question.maxCharacters || question.maxCharacters === 0) &&
            (question.minCharacters || question.minCharacters === 0) &&
            parseInt(question.maxCharacters, 10) <=
              parseInt(question.minCharacters, 10)
          ) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message:
                'Maximum Answer Length should be higher than Minimum Answer Length'
            });
          }
        }
      ]
    },
    {
      type: 'mc_question',
      customCheckers: [
        (question, index) => {
          if (!question.choices || !question.choices.length) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'There should be at least one answer'
            });
          }
        }
      ]
    },
    {
      type: 'distributor',
      customCheckers: [
        (question, index) => {
          /*
          if (
            !question.branches ||
            (question.branches && question.branches.length < 2)
          ) {
            surveyErrors.push({
              type: `Distributor ${question.distributorIndex || ''}`,
              index,
              message: 'Minimum 2 paths required'
            });
          }
          */

          question.branches.forEach((b, i) => {
            if (b && (!b.label || b.label === '')) {
              surveyErrors.push({
                type: `Distributor ${question.distributorIndex || ''}`,
                index,
                message: `Path ${i + 1} is missing label`
              });
            }
            if (b && (!b.nextFlow && !b.end)) {
              surveyErrors.push({
                type: `Distributor ${question.distributorIndex || ''}`,
                index,
                message: `Path ${i + 1} is missing logic jump`
              });
            }
          });
        }
      ]
    },
    {
      type: 'ranking',
      customCheckers: [
        (question, index) => {
          if (!question.choices || !question.choices.length) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'There should be more than one answer'
            });
          }
        }
      ]
    },
    {
      type: 'slider',
      customCheckers: [
        (question, index) => {
          if (
            (!question.maxValue && question.maxValue !== 0) ||
            question.maxValue === ''
          ) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'Maximum Value is missing'
            });
          }
          if (
            (!question.minValue && question.minValue !== 0) ||
            question.minValue === ''
          ) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'Minimum Value is missing'
            });
          }
          if (
            (question.maxValue || question.maxValue === 0) &&
            (question.minValue || question.minValue === 0) &&
            parseInt(question.maxValue, 10) <= parseInt(question.minValue, 10)
          ) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'Maximum Value should be higher than Minimum Value'
            });
          }
        }
      ]
    },
    {
      type: 'video',
      customCheckers: [
        (question, index) => {
          if (!question.mediaFilename || question.mediaFilename === '') {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'Video file is missing'
            });
          }
        }
      ]
    },
    {
      type: 'iframe',
      fields: ['source'],
      customCheckers: [
        (question, index) => {
          if (!question.source || question.source === '') {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: 'Website URL is missing'
            });
          }
        }
      ]
    },
    {
      type: 'matrix',
      customCheckers: [
        (question, index) => {
          const isSingleType =
            question.matrix &&
            question.matrix.decisionFrameworkType &&
            question.matrix.decisionFrameworkType ===
              DECISION_FRAMEWORK_TYPES.SINGLE;

          // X label
          if (!(question.matrix && question.matrix.xLabel)) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: !isSingleType
                ? `Label x-axis is missing`
                : `Label is missing`
            });
          }
          // Y label
          if (!(question.matrix && question.matrix.yLabel) && !isSingleType) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: `Label y-axis is missing`
            });
          }
          // X question
          let xMatrixQuestionMissing = true;
          if (question.matrix && question.matrix.xQuestion) {
            const xMatrixQuestionString = parseRichTextToString(
              question.matrix.xQuestion
            );
            if (xMatrixQuestionString && xMatrixQuestionString.trim().length) {
              xMatrixQuestionMissing = false;
            }
          }
          if (xMatrixQuestionMissing) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: !isSingleType
                ? `Survey question linked to x-axis is missing`
                : `Question is missing`
            });
          }
          // Y question
          let yMatrixQuestionMissing = true;
          if (isSingleType) {
            yMatrixQuestionMissing = false;
          } else if (question.matrix && question.matrix.yQuestion) {
            const yMatrixQuestionString = parseRichTextToString(
              question.matrix.yQuestion
            );
            if (yMatrixQuestionString && yMatrixQuestionString.trim().length) {
              yMatrixQuestionMissing = false;
            }
          }
          if (yMatrixQuestionMissing) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: `Survey question linked to y-axis is missing`
            });
          }

          let choiceType = 'row';
          if (!(question.matrix && question.matrix.decisionFrameworkType)) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: `A decision framework should be selected`
            });
          } else {
            switch (question.matrix.decisionFrameworkType) {
              case DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH:
                choiceType = 'problem';
                break;
              case DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH:
                choiceType = 'idea';
                break;
              case DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH:
                choiceType = 'concept';
                break;
              case DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH:
                choiceType = 'concept';
                break;
              default:
                choiceType = 'row';
                break;
            }
          }
          if (
            !question.choices ||
            !(
              question.choices &&
              question.choices.length &&
              question.choices.filter(
                choice =>
                  question.matrix &&
                  choice.decisionFrameworkType &&
                  question.matrix.decisionFrameworkType &&
                  choice.decisionFrameworkType ===
                    question.matrix.decisionFrameworkType
              ).length
            )
          ) {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: `There should be at least one ${choiceType}`
            });
          }
        }
      ]
    }
  ];

  const filteredCustomChecker = customCheckers.filter(
    checker => checker.type === questionToCheck.type
  );
  if (filteredCustomChecker.length) {
    filteredCustomChecker[0].customCheckers.forEach(checkerFunction =>
      checkerFunction(questionToCheck, questionIndex)
    );
  }

  return surveyErrors;
};

export default survey => {
  let surveyErrors = [];

  // Campaign properties check
  if (!survey.name || survey.name === '')
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Survey name is missing'
    });
  if (!survey.projectName || survey.projectName === '')
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Internal survey name is missing'
    });

  if (
    (!survey.recordDetails || !survey.recordDetails.id) &&
    (!survey.recordCustomName || survey.recordCustomName === '')
  )
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Customer name or initiative is missing'
    });
  if (!survey.maxResponses || survey.maxResponses === '')
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Amount of responses is missing'
    });
  if (
    !survey.image ||
    !survey.imageFilename ||
    survey.image === '' ||
    survey.imageFilename === ''
  )
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Image file is missing'
    });
  if (!survey.language)
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Survey Language is missing'
    });
  if (!survey.averageDuration || survey.averageDuration === '')
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Average duration is missing'
    });
  if (!survey.baseReward || survey.baseReward === '')
    surveyErrors.push({
      type: 'Survey overview',
      message: 'Base reward is missing'
    });

  const allCommunitySettingsConfigured =
    survey.communitySettings &&
    typeof survey.communitySettings.allSurveysVisible === 'boolean' &&
    survey.communitySettings.communityType &&
    typeof survey.communitySettings.seeGiveawayMessage === 'boolean';

  const addToCommunityQuestionExists =
    survey.questions &&
    survey.questions.length &&
    survey.questions.some(
      q => q.type === 'mc_question' && q.isCommunityAssignQuestion
    );

  if (
    (allCommunitySettingsConfigured || addToCommunityQuestionExists) &&
    !(allCommunitySettingsConfigured && addToCommunityQuestionExists)
  ) {
    if (!allCommunitySettingsConfigured) {
      surveyErrors.push({
        type: `Survey overview`,
        message: `Conversion data missing`
      });
    } else if (!addToCommunityQuestionExists) {
      surveyErrors.push({
        type: `Survey builder`,
        message: `Add to community question missing`
      });
    }
  }

  const communitiesSelected =
    (survey.communities && survey.communities.length) ||
    !(survey.generalCommunity === false);

  if (!communitiesSelected) {
    surveyErrors.push({
      type: `Survey overview`,
      message: `Select at least one community`
    });
  }

  const scopingInvalid =
    survey.scoping &&
    (survey.scoping.assumptionToValidate == null ||
      survey.scoping.assumptionToValidate === '' ||
      survey.scoping.targetAudienceDescription == null ||
      survey.scoping.targetAudienceDescription === '');

  if (scopingInvalid) {
    surveyErrors.push({
      type: `Survey overview`,
      message: `Scoping field(s) missing`
    });
  }

  // Question questions
  try {
    const mappedQuestions =
      survey.questions &&
      survey.questions.reduce(
        (acc, q) => ({
          ...acc,
          [q.id]: q
        }),
        {}
      );

    let questions = generateSurveyLists(survey.content, mappedQuestions);

    // Distributor requires separate numbering
    let distributorIndex = 0;
    questions = questions.map(q => {
      if (q.type === 'distributor') {
        distributorIndex += 1;
        return {
          ...q,
          distributorIndex
        };
      }
      return q;
    });

    const questionsWithMoreData = questions.map(q => ({
      ...q,
      ...mappedQuestions[q.id]
    }));

    questionsWithMoreData.forEach((question, index) => {
      if (question && !question.question) {
        if (
          question.type !== 'video' &&
          question.type !== 'iframe' &&
          question.type !== 'matrix'
        ) {
          let questionErrorMessage = 'Question is missing';

          if (question.type === 'message') {
            questionErrorMessage = 'Text is missing';
          }

          if (question.type === 'distributor') {
            surveyErrors.push({
              type: `Distributor ${question.distributorIndex || ''}`,
              index,
              message: 'Distributor label is missing'
            });
          } else {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: questionErrorMessage
            });
          }
        }
      } else {
        const questionString = parseRichTextToString(question.question);

        if (
          !questionString ||
          questionString === '' ||
          questionString.trim() === ''
        ) {
          let questionErrorMessage = 'Question is missing';

          if (question.type === 'message') {
            questionErrorMessage = 'Text is missing';
          }

          if (question.type === 'distributor') {
            surveyErrors.push({
              type: `Distributor ${question.distributorIndex || ''}`,
              index,
              message: 'Distributor label is missing'
            });
          } else {
            surveyErrors.push({
              type: `Question ${question.formattedGlobalIndex || ''}`,
              index,
              message: questionErrorMessage
            });
          }
        }
      }

      const questionTypeErrors = customChecker(question, index);
      surveyErrors = [...surveyErrors, ...questionTypeErrors];
    });
  } catch (error) {
    surveyErrors.push({
      type: 'Questions format',
      index: 0,
      message: 'Questions format is not correct'
    });
  }

  if (survey && survey.userFilter) {
    if (survey.userFilter.language) {
      if (checkSettingsError(survey.userFilter.language))
        surveyErrors.push({
          type: 'Target Audience',
          message: 'No language is selected'
        });
    }
    if (survey.userFilter.gender) {
      if (checkSettingsError(survey.userFilter.gender))
        surveyErrors.push({
          type: 'Target Audience',
          message: 'No gender is selected'
        });
    }
  }

  if (
    (!survey.surveyDetailsConfirmed &&
      !(
        survey.history &&
        survey.history.length &&
        survey.history.some(h => h.type === 'PUBLISH_SURVEY')
      )) ||
    (survey.surveyDetailsConfirmed &&
      !survey.surveyDetailsConfirmedByAdmin &&
      survey.approvalRequestSent)
  )
    surveyErrors.push({
      type: 'Publish survey',
      message: 'Confirm survey details'
    });

  return surveyErrors.length > 0 ? surveyErrors : null;
};
