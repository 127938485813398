import React, { useState } from 'react';
import generatePptx from '../../../helpers/generatePptx';

import pdfIcon from '../../../../assets/img/pdf.svg';
import csvIcon from '../../../../assets/img/csv.svg';
import pptIcon from '../../../../assets/img/ppt.svg';
import warningIcon from '../../../../assets/img/warning.svg';
import confirmIcon from '../../../../assets/img/tick.svg';
import closeIcon from '../../../../assets/img/delete.svg';
import tickIconWhite from '../../../../assets/img/tick_white.svg';

import * as mixpanel from '../../../../../mixpanel';

export default props => {
  const {
    onGenerateCSV,
    isAdmin,
    isPremiumClient,
    questions,
    activeSorting,
    setExportPopup,
    surveyId
  } = props;
  const [exportRemovedResponses, setExportRemovedResponses] = useState(false);

  const filteredQuestions = questions.filter(q => q.type !== 'Distributor');

  const questionIndices = filteredQuestions.map(question => ({
    id: question.id,
    index: question.formattedGlobalIndex
      ? question.formattedGlobalIndex.toString()
      : question.globalIndex.toString(),
    resultsCount:
      (question.unfilteredResults && question.unfilteredResults.length) || 0
  }));

  const exportOptions = [
    {
      type: 'PDF',
      title: 'Export to PDF',
      explanation: 'Exports to a format ideal for sharing and printing.',
      icon: pdfIcon,
      additionalDetails: {
        warning: `In order to display charts correctly make sure to enable 'background graphics'/ 'achtergrondafbeeldingen' under the More Settings drop-down.`
      },
      action: async () => {
        mixpanel.actions.trackEvent(mixpanel.events.CLIENT_EXPORT_PDF, {
          [mixpanel.eventProperties.SURVEY_ID]: surveyId
        });
        await setExportPopup(false);
        window.print();
      }
    },
    {
      type: 'CSV',
      title: 'Export to CSV',
      explanation:
        'Exports to a format ideal for analytical programs like Microsoft Excel.',
      icon: csvIcon,
      additionalDetails: {
        remove: 'Export removed & flagged responses'
      },
      action: exportRemoved => {
        mixpanel.actions.trackEvent(mixpanel.events.CLIENT_EXPORT_CSV, {
          [mixpanel.eventProperties.SURVEY_ID]: surveyId
        });
        onGenerateCSV(questionIndices, exportRemoved);
      }
    },
    {
      type: 'PPT',
      title: 'Export to PowerPoint (ALPHA)',
      explanation:
        'Exports data into a Microsoft PowerPoint slide deck, which is ideal for presentations.',
      icon: pptIcon,
      warning: '',
      action: () => {
        mixpanel.actions.trackEvent(mixpanel.events.CLIENT_EXPORT_PPT, {
          [mixpanel.eventProperties.SURVEY_ID]: surveyId
        });
        generatePptx({ results: filteredQuestions, activeSorting });
      }
    }
  ];

  const [selectedOption, setSelectedOption] = useState(exportOptions[0]);

  return (
    <div
      className="results-page-export-overlay-background"
      role="presentation"
      onClick={() => setExportPopup(false)}
    >
      <div
        className="results-page-export-container"
        role="presentation"
        onClick={e => e.stopPropagation()}
      >
        <div className="results-page-export-introduction">
          <div className="results-page-export-introduction-title">
            Export workspace
          </div>
          <div className="results-page-export-introduction-subtitle">
            Select the file type you want to export to.
          </div>
        </div>
        <div className="results-page-export-options">
          {exportOptions.map(option =>
            option.type !== 'PPT' || (isAdmin || isPremiumClient) ? (
              <div
                key={option.type}
                className={`results-page-export-option ${
                  selectedOption.type === option.type
                    ? 'results-page-export-option-active'
                    : ''
                }`}
                role="presentation"
                onClick={() => setSelectedOption(option)}
              >
                <img
                  className="results-page-export-option-icon"
                  src={option.icon}
                  alt={`${option.type} icon`}
                />
              </div>
            ) : null
          )}
          <div className="results-page-export-options-information">
            <div className="results-page-export-options-information-title">
              {selectedOption.title}
            </div>
            <div className="results-page-export-options-information-subtitle">
              {selectedOption.explanation}
            </div>
          </div>
        </div>
        <div
          className={`results-page-export-additional-details ${
            selectedOption.additionalDetails
              ? 'results-page-export-additional-details-visible'
              : ''
          }`}
        >
          {selectedOption.additionalDetails &&
          selectedOption.additionalDetails.warning ? (
            <div className="results-page-export-warning">
              <img
                src={warningIcon}
                alt="Warning"
                className="results-page-export-warning-icon"
              />
              <span>{selectedOption.additionalDetails.warning}</span>
            </div>
          ) : null}
          {isAdmin &&
          selectedOption.additionalDetails &&
          selectedOption.additionalDetails.remove ? (
            <div className="">
              <div
                className={`checkbox ${
                  exportRemovedResponses ? 'selected-checkbox' : ''
                }`}
                onClick={() =>
                  setExportRemovedResponses(
                    prevExportRemovedResponseState =>
                      !prevExportRemovedResponseState
                  )
                }
                role="presentation"
              >
                <img src={tickIconWhite} alt="Tick" />
              </div>
              <span className="">
                {selectedOption.additionalDetails.remove}
              </span>
            </div>
          ) : null}
        </div>
        <div className="results-page-export-actions">
          <img
            src={confirmIcon}
            alt="Confirm"
            className="results-page-export-confirm-icon"
            role="presentation"
            onClick={() => selectedOption.action(exportRemovedResponses)}
          />
          <img
            src={closeIcon}
            alt="Confirm"
            className="results-page-export-close-icon"
            role="presentation"
            onClick={() => setExportPopup(false)}
          />
        </div>
      </div>
    </div>
  );
};
