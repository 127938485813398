import React, { useState, useEffect, useRef } from 'react';

import Icon from '../../../Icon';
import './FiltersBar.css';
import dropdownIcon from '../../../../../assets/img/dropdown-arrow-blue.svg';
import warningIcon from '../../../../../assets/img/exclamation.svg';

const FiltersBar = props => {
  const {
    activeFilters,
    onToggleFilter,
    resultBlocks,
    filtersRelation,
    onResetFilters,
    onSetFiltersRelation,
    mutuallyExclusive,
    sortedQuestions,
    showFiltersOnly = false
  } = props;

  const extremeUsersFilterLabels = {
    xLovers: 'Lovers x-axis: 25% highest scores',
    yLovers: 'Lovers y-axis: 25% highest scores',
    xLoversyLovers: 'Lovers x-axis and y-axis: highest 25% scores',
    xHaters: 'Haters x-axis: 25% lowest scores',
    yHaters: 'Haters y-axis: 25% lowest scores',
    xHatersyHaters: 'Haters x-axis and y-axis: lowest 25% scores'
  };

  const [filtersRelationDropdown, setfiltersRelationDropdown] = useState({
    active: false,
    index: 0
  });

  const filtersBarRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        filtersBarRef &&
        filtersBarRef.current &&
        filtersBarRef.current.children &&
        filtersBarRef.current.children.length &&
        Array.from(filtersBarRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setfiltersRelationDropdown({
        active: false,
        index: 0
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getFilterName = filterName => {
    if (filterName === 'genders') {
      return 'Genders';
    }
    if (filterName === 'employementTypes') {
      return 'Employment';
    }
    if (filterName === 'ageGroups') {
      return 'Age';
    }
    if (filterName === 'languages') {
      return 'Languages';
    }
    return '';
  };

  const getBlockIndex = blockId => {
    let blockIndex = null;

    const matchingQuestion =
      sortedQuestions &&
      sortedQuestions.length &&
      sortedQuestions.find(q => q.id === blockId);

    if (matchingQuestion && matchingQuestion.formattedGlobalIndex) {
      blockIndex = matchingQuestion.formattedGlobalIndex;
    } else if (matchingQuestion && matchingQuestion.globalIndex) {
      blockIndex = matchingQuestion.globalIndex;
    }

    return blockIndex;
  };

  const getFilterLabel = filter => {
    if (filter.resultsPath && resultBlocks) {
      const block = resultBlocks[filter.filterName];
      if (
        block &&
        block.question &&
        block.answers &&
        block.answers[filter.value] &&
        block.answers[filter.value].answer
      ) {
        return (
          <>
            Q{`${getBlockIndex(block.id)} `}&nbsp;
            <span className="single-active-filter-label-parentheses">
              (answer: {block.answers[filter.value].answer}
              {filter.additionalAnswerLabel
                ? ` ${filter.additionalAnswerLabel}`
                : ''}
              )
            </span>
          </>
        );
      }

      if (block && block.question && block.type === 'Slider') {
        if (filter.value)
          return (
            <>
              Q{`${getBlockIndex(block.id)} `}&nbsp;
              <span className="single-active-filter-label-parentheses">
                (answer: {filter.value})
              </span>
            </>
          );
        if (block.allowSkip)
          return (
            <>
              Q{`${getBlockIndex(block.id)} `}&nbsp;
              <span className="single-active-filter-label-parentheses">
                (answer: N/A)
              </span>
            </>
          );
      }
    } else if (
      filter.filterName &&
      resultBlocks &&
      resultBlocks[filter.filterName]
    ) {
      const block = resultBlocks[filter.filterName];
      if (block && block.type === 'Matrix' && block.answers) {
        return (
          <>
            Q{`${getBlockIndex(block.id)} `}&nbsp;
            <div
              className="single-active-filter-label-parentheses single-active-filter-overflow"
              title={filter.additionalAnswerLabel}
            >
              (answer:
              {filter.additionalAnswerLabel
                ? ` ${filter.additionalAnswerLabel}`
                : filter.value}
              )
            </div>
          </>
        );
      }
    }

    if (filter.filterName && filter.filterName.substring(0, 4) === 'CAT-') {
      const blockId = filter.filterName.replace('CAT-', '');
      return (
        <>
          Q{`${getBlockIndex(blockId)} `}&nbsp;
          <span className="single-active-filter-label-parentheses">
            (category: {filter.value})
          </span>
        </>
      );
    }

    if (extremeUsersFilterLabels[filter.value]) {
      return (
        <>
          <span
            title={filter.filterName}
            className="single-active-extreme-user-filter-name"
          >
            {filter.filterName}
          </span>{' '}
          <span className="single-active-filter-label-parentheses">
            ({extremeUsersFilterLabels[filter.value]})
          </span>
        </>
      );
    }

    if (
      filter.filterName &&
      (filter.filterName === 'genders' ||
        filter.filterName === 'employementTypes' ||
        filter.filterName === 'ageGroups' ||
        filter.filterName === 'languages')
    ) {
      return (
        <>
          {getFilterName(filter.filterName)}&nbsp;
          <span className="single-active-filter-label-parentheses">
            ({filter.value})
          </span>
        </>
      );
    }

    return filter.value;
  };

  const showFiltersRelationLabel = index => {
    if (index < activeFilters.length - 1) {
      if (showFiltersOnly) {
        return (
          <div className="single-active-filter-filters-relation">
            {filtersRelation === 'and' ? 'AND' : 'OR'}
          </div>
        );
      }

      return (
        <div
          className={`single-active-filter-relations ${
            filtersRelationDropdown.active &&
            filtersRelationDropdown.index === index
              ? 'single-active-filter-relations-active'
              : ''
          }`}
          role="presentation"
          onClick={() =>
            setfiltersRelationDropdown({
              active: !filtersRelationDropdown.active,
              index
            })
          }
        >
          {filtersRelation.toUpperCase()}
          <img
            src={dropdownIcon}
            alt="dropdown icon"
            className="single-active-filter-relations-dropdown-icon"
          />
          {filtersRelationDropdown.active &&
          filtersRelationDropdown.index === index ? (
            <span
              className="single-active-filter-relations-dropdown"
              role="presentation"
              onClick={() =>
                filtersRelation === 'and'
                  ? onSetFiltersRelation('or')
                  : onSetFiltersRelation('and')
              }
            >
              {filtersRelation === 'and' ? 'OR' : 'AND'}
            </span>
          ) : null}
        </div>
      );
    }

    return null;
  };

  if (activeFilters && activeFilters.length > 0) {
    return (
      <div className="filters-bar-container" ref={filtersBarRef}>
        {!showFiltersOnly ? (
          <span className="active-filters-title">Active filters</span>
        ) : null}
        {activeFilters.map((filter, index) => [
          <div
            className={`single-active-filter ${
              mutuallyExclusive ? 'single-active-filter-warning' : ''
            }`}
            key={`single-active-filter-${index.toString()}`}
          >
            <span className="single-active-filter-label">
              {filter ? getFilterLabel(filter) : null}
            </span>
            {!showFiltersOnly ? (
              <span
                className="remove-single-active-filter"
                role="presentation"
                onClick={() => {
                  onToggleFilter(
                    filter.filterName,
                    filter.valuePath,
                    filter.value,
                    null,
                    filter.transformator
                  );
                }}
              >
                <Icon type="close" style={{ color: '#5200f1' }} />
              </span>
            ) : null}
          </div>,
          showFiltersRelationLabel(index)
        ])}
        {!showFiltersOnly ? (
          <span
            className="reset-all-filters"
            role="presentation"
            onClick={onResetFilters}
          >
            Reset all filters
          </span>
        ) : null}
        {mutuallyExclusive ? (
          <span className="mutually-exclusive-filters-warning">
            <img
              src={warningIcon}
              alt="Warning"
              className="filters-warning-icon"
            />
            Mutually exclusive filters active
          </span>
        ) : null}
      </div>
    );
  }

  return null;
};

export default FiltersBar;
