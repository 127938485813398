import React from 'react';

import styles from './Legend.module.css';

export default ({ themeColors }) => {
  const themeColor = themeColors.reduce(
    (acc, tC) => ({
      ...acc,
      [tC.name]: tC.colorCode
    }),
    {}
  );

  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_2
            }}
          />
          <div>
            Ideas in this zone are valuable &amp; unique to your target user.{' '}
            <span className={styles.bold}>
              The ideas have market potential and their go to market should be
              accelerated
            </span>
            . Scores higher than 8 indicate that a{' '}
            <span className={styles.bold}>market leadership</span> strategy
            might be possible.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_4
            }}
          />
          <div>
            Ideas in this zone are valuable but not unique. These ideas might be
            able to obtain some market share, but will not be disruptive / lead
            to market leadership. Since there is a{' '}
            <span className={styles.bold}>competitive market</span>, you might
            want to pivot to a more niche market or try to optimize your
            solution or proceed depending on your project goals.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_1
            }}
          />{' '}
          <div>
            Ideas in this zone are not very valuable but are unique. Typically
            these ideas are not strong enough to generate a significant market
            share on their own, but they are excellent to grasp attention.{' '}
            <span className={styles.bold}>
              They typically can serve as marketing USP or generate cross-sells
              to other products / services
            </span>
            .
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: `1px solid #e9e7e7`,
              backgroundColor: themeColor.COLOR_3
            }}
          />
          <div>
            Ideas in this zone are neither valuable nor unique.{' '}
            <span className={styles.bold}>
              Don’t waste your resources on these ideas
            </span>
            . Contact our innovation experts for advice on pivots.
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.rectangle}
            style={{
              border: '1px solid rgba(255,255,255,0)',
              backgroundColor: 'rgba(255,255,255,0)'
            }}
          />
          <div className={styles.dots}>...</div>
          <div>Lovers benchmark</div>
        </div>
      </div>
    </div>
  );
};
