import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import AuthRoute from './routes/AuthRoute/AuthRoute';
import OrganisationsRoute from './routes/OrganisationsRoute/OrganisationsRouteContainer';

import LoginPage from './auth/components/LoginPage/LoginPage';
import RegistrationPage from './auth/components/RegistrationPage/RegistrationPage';
import ResetPasswordPage from './auth/components/ResetPasswordPage/ResetPasswordPage';
import RequestResetPasswordPage from './auth/components/RequestResetPasswordPage/RequestResetPasswordPage';

import DashboardPage from './dashboard/containers/DashboardPageContainer';
import EditGoalPage from './dashboard/containers/EditGoalPageContainer';

const Router = () => (
  <div>
    <BrowserRouter basename="/organisations">
      <div>
        <Switch>
          <AuthRoute
            exact
            path="/login"
            component={LoginPage}
            backgroundColor="green"
          />
          <AuthRoute
            exact
            path="/register"
            component={RegistrationPage}
            backgroundColor="blue"
          />
          <AuthRoute
            exact
            path="/password/reset"
            component={RequestResetPasswordPage}
            backgroundColor="green"
          />
          <AuthRoute
            exact
            path="/password/reset/:token"
            component={ResetPasswordPage}
            backgroundColor="green"
          />

          <OrganisationsRoute exact path="/" component={DashboardPage} />
          <OrganisationsRoute
            exact
            path="/view/:token"
            component={DashboardPage}
          />

          <OrganisationsRoute
            exact
            path="/goal/edit"
            component={EditGoalPage}
          />
        </Switch>
      </div>
    </BrowserRouter>
  </div>
);

export default Router;
