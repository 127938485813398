export const COMMUNITY_DASHBOARD_FILTER_TYPE = {
  PROFILE_TAG: 'PROFILE_TAG',
  USER_PROPERTY: 'USER_PROPERTY',
  COMMUNITY: 'COMMUNITY',
  ACQUISITION: 'ACQUISITION'
};

export const COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES = {
  [COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG]: 'Profiling tag',
  [COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY]: 'User property',
  [COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY]: 'Community',
  [COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION]: 'Acquisition'
};

export const USER_PROPERTIES = {
  LANGUAGE: 'LANGUAGE',
  GENDER: 'GENDER',
  AGE: 'AGE',
  COUNTRY: 'COUNTRY',
  PROVINCE: 'PROVINCE',
  POSTAL_CODE: 'POSTAL_CODE',
  PLACE: 'PLACE',
  BETA_TESTER: 'BETA_TESTER',
  SIGNED_UP_THROUGH: 'SIGNED_UP_THROUGH',
  PENDING_ACTIVATION: 'PENDING_ACTIVATION'
};

export const USER_PROPERTY_NAMES = {
  LANGUAGE: 'Language',
  GENDER: 'Gender',
  AGE: 'Age',
  COUNTRY: 'Country',
  PROVINCE: 'Province',
  POSTAL_CODE: 'Postal code',
  PLACE: 'Place',
  BETA_TESTER: 'Beta tester',
  SIGNED_UP_THROUGH: 'Signed up through',
  PENDING_ACTIVATION: 'Pending activation'
};

export const AGE_RANGES = {
  BELLOW_18: 'BELLOW_18',
  '18_24': '18_24',
  '25_34': '25_34',
  '35_44': '35_44',
  '45_54': '45_54',
  '55_65': '55_65',
  ABOVE_65: 'ABOVE_65'
};

export const AGE_RANGE_NAMES = {
  [AGE_RANGES.BELLOW_18]: '< 18 years old',
  [AGE_RANGES['18_24']]: '18 - 24 years old',
  [AGE_RANGES['25_34']]: '25 - 34 years old',
  [AGE_RANGES['35_44']]: '35 - 44 years old',
  [AGE_RANGES['45_54']]: '45 - 54 years old',
  [AGE_RANGES['55_65']]: '55 - 65 years old',
  [AGE_RANGES.ABOVE_65]: '> 65 years old'
};

export const FILTER_RELATIONS = {
  AND: 'AND',
  OR: 'OR'
};

export const FILTER_RANGES = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  BIWEEK: 'BIWEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR'
};

export const FILTER_RANGE_LABELS = {
  [FILTER_RANGES.DAY]: 'Daily',
  [FILTER_RANGES.WEEK]: 'Weekly',
  [FILTER_RANGES.MONTH]: 'Monthly',
  [FILTER_RANGES.YEAR]: 'Yearly',
  [FILTER_RANGES.BIWEEK]: 'Bi-weekly',
  [FILTER_RANGES.QUARTER]: 'Quarterly'
};

export const MAIN_DATA_METRICS = {
  ACTIVE_USERS: 'activeUsers',
  RESPONSE_RATE: 'responseRate',
  COMMUNITY_SIZE: 'communitySize',
  MAXIMUM_DELIVERY_POTENTIAL: 'maximumDeliveryPotential'
};

export const COMMUNITY_MANAGEMENT_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC'
};

export const COMMUNITY_MANAGEMENT_NAMES = {
  STATIC: 'Static',
  DYNAMIC: 'Dynamic'
};
