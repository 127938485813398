import React from 'react';

import styles from './Toggle.module.css';

const Toggle = ({ checked, onToggle, backgroundColor }) => (
  <div className={styles.container}>
    <label className={styles.switch} htmlFor="targetAudienceInput">
      <input
        type="checkbox"
        name="targetAudienceInput"
        checked={checked}
        readOnly
      />
      <span
        className={`${styles.slider} ${styles.round} workspaces-toggle-button`}
        style={backgroundColor && checked ? { backgroundColor } : null}
        role="presentation"
        onClick={e => {
          onToggle();
          e.stopPropagation();
        }}
      />
    </label>
  </div>
);

export default Toggle;
