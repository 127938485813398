import { call } from 'redux-saga/effects';

export default function*(api, requests) {
  if (api && requests) {
    // eslint-disable-next-line no-restricted-syntax
    for (const request of requests) {
      if (request.api && request.variables) {
        yield call(api, request.api, {
          variables: request.variables
        });
      }
    }
  }
}
