import React from 'react';

import styles from './TypeExplanation.module.css';

export default props => {
  const { communityType, COMMUNITY_TYPES } = props;

  const staticExplanation =
    'This community type consists of respondents that were added to this community using a conversion block in a survey.\nYou can add more respondents to this community using the conversion block in a survey. Respondents can be manually removed from this page.';
  const dynamicExplanation =
    'This community type consists of respondents that have the user properties and/or profiling tags defined below.\nWhen users gain/lose the defined profiling tag(s), they will be added/removed from this community. It is not possible to convert users to this community in a manual way.';

  return (
    <div className={styles.explanation}>
      {communityType === COMMUNITY_TYPES.STATIC
        ? staticExplanation
        : dynamicExplanation}
    </div>
  );
};
