import React, { useContext } from 'react';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import RangeSelector from './components/RangeSelector/RangeSelector';
import MCSelector from './components/MCSelector/MCSelector';

import Footer from '../../Footer/Footer';

import checkbox from '../../../../../../../../../assets/img/templateswizard/imnot.svg';
import checkboxSelected from '../../../../../../../../../assets/img/templateswizard/imselected.svg';

import {
  MC_QUESTION_OPTIONS,
  TEMPLATES_WIZARD_STEP_KEYS,
  TEMPLATES_SCALES,
  TEMPLATES_WIZARD_TEMPLATE_TYPES
} from '../../../../../helpers/constants';

import styles from './SetScale.module.css';

const ScalePicker = ({ label, value, selectedValue, onClick }) => (
  <div
    className={
      value === selectedValue
        ? `${styles.scalePicker} ${styles.selected}`
        : styles.scalePicker
    }
    role="presentation"
    onClick={onClick}
  >
    <img
      src={value === selectedValue ? checkboxSelected : checkbox}
      alt="Checkbox"
    />
    {label}
  </div>
);

export default () => {
  const {
    currentStep,
    setStepValue,
    goToNextStep,
    goToPreviousStep,
    steps,
    strings,
    styleText
  } = useContext(TemplatesWizardContext);

  const templateTypeStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
  );

  const pointsStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.DEFINE_POINTS
  );

  const templateStrings = strings[templateTypeStep.value];

  const getPointContainers = () => {
    if (currentStep.value.type === TEMPLATES_SCALES.RATING_SCALE) {
      return [
        <div key="set-scale-1" className={styles.pointContainer}>
          <div className={styles.text}>
            A: {templateStrings.WHAT_DO_YOU_THINK_OF_FOLLOWING}
          </div>
          <div className={styles.text}>
            {styleText(templateStrings.IT_IS_1, styles)}
            {` ${pointsStep.value[0] || ''}`}
          </div>
          <div className={styles.text}>
            {styleText(
              templateStrings.GIVE_A_SCORE_1.replace(
                '{MIN}',
                currentStep.value.values.min
              ).replace('{MAX}', currentStep.value.values.max),
              styles
            )}
          </div>
        </div>,
        <div key="set-scale-2" className={styles.pointContainer}>
          <div className={styles.text}>
            B: {templateStrings.WHAT_DO_YOU_THINK_OF_FOLLOWING}
          </div>
          <div className={styles.text}>
            {styleText(templateStrings.IT_IS_2, styles)}
            {` ${pointsStep.value[0] || ''}`}
          </div>
          <div className={styles.text}>
            {styleText(
              templateStrings.GIVE_A_SCORE_2.replace(
                '{MIN}',
                currentStep.value.values.min
              ).replace('{MAX}', currentStep.value.values.max),
              styles
            )}
          </div>
        </div>
      ];
    }

    if (currentStep.value.type === TEMPLATES_SCALES.MULTIPLE_CHOICE) {
      if (
        templateTypeStep.value ===
        TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
      ) {
        return [
          <div key="set-scale-1" className={styles.pointContainer}>
            <div className={styles.text}>
              A: {templateStrings.WHAT_DO_YOU_THINK_OF_FOLLOWING}
            </div>
            <div className={styles.text}>
              {styleText(templateStrings.IT_IS_1, styles)}
              {` ${pointsStep.value[0] || ''}`}
            </div>
          </div>,
          <div key="set-scale-2" className={styles.pointContainer}>
            <div className={styles.text}>
              B: {templateStrings.WHAT_DO_YOU_THINK_OF_FOLLOWING}
            </div>
            <div className={styles.text}>
              {styleText(templateStrings.IT_IS_2, styles)}
              {` ${pointsStep.value[0] || ''}`}
            </div>
          </div>
        ];
      }

      if (
        templateTypeStep.value !==
        TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
      ) {
        return [
          <div key="set-scale-1" className={styles.pointContainer}>
            <div className={styles.text}>
              A: {` ${pointsStep.value[0] || ''}`}
            </div>
            <div className={styles.text}>
              {styleText(templateStrings.TO_WHAT_EXTEND_YOU_AGREE_1, styles)}
            </div>
          </div>,
          <div key="set-scale-2" className={styles.pointContainer}>
            <div className={styles.text}>
              B: {` ${pointsStep.value[0] || ''}`}
            </div>
            <div className={styles.text}>
              {styleText(templateStrings.TO_WHAT_EXTEND_YOU_AGREE_2, styles)}
            </div>
          </div>
        ];
      }
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          {strings.WHAT_SCALE_WOULD_YOU_LIKE_TO_WORK_WITH}
        </div>
        <div className={styles.scalePickersContainer}>
          <ScalePicker
            label={strings.MULTIPLE_CHOICE}
            value={TEMPLATES_SCALES.MULTIPLE_CHOICE}
            selectedValue={currentStep.value.type}
            onClick={() => {
              setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SET_SCALE, {
                type: TEMPLATES_SCALES.MULTIPLE_CHOICE,
                values: MC_QUESTION_OPTIONS[0]
              });

              setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA, []);
            }}
          />
          <ScalePicker
            label={strings.RATING_SCALE}
            value={TEMPLATES_SCALES.RATING_SCALE}
            selectedValue={currentStep.value.type}
            onClick={() => {
              setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SET_SCALE, {
                type: TEMPLATES_SCALES.RATING_SCALE,
                values: {
                  min: 1,
                  max: 7
                }
              });

              setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA, []);
            }}
          />
        </div>
        <div className={styles.scaleDetailsWrapper}>
          <div className={styles.scaleTitle1}>
            {strings.DECIDE_WHAT_SCALE_YOU_WANT}
          </div>
          {currentStep.value.type === TEMPLATES_SCALES.RATING_SCALE && (
            <div className={styles.scaleContainer}>
              <div className={styles.scaleTitle2}>
                {strings.WE_RECOMMEND_SCALE}
              </div>
              <div className={styles.rangeContainer}>
                <RangeSelector
                  minValue={0}
                  maxValue={10}
                  value={currentStep.value.values}
                  setValue={v =>
                    setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SET_SCALE, {
                      type: TEMPLATES_SCALES.RATING_SCALE,
                      values: v
                    })
                  }
                />
              </div>
            </div>
          )}
          {currentStep.value.type === TEMPLATES_SCALES.MULTIPLE_CHOICE && (
            <div className={styles.scaleContainer}>
              <div className={styles.scaleTitle2}>
                {strings.WE_RECOMMEND_THE_FIRST_OPTION}
              </div>
              <div className={styles.mcContainer}>
                <MCSelector
                  mcOptions={MC_QUESTION_OPTIONS}
                  templateStrings={strings}
                  value={currentStep.value.values.id}
                  setValue={v =>
                    setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SET_SCALE, {
                      type: TEMPLATES_SCALES.MULTIPLE_CHOICE,
                      values: v
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.text}>
          {strings.EXAMPLE_OF_SCALE_WILL_BE_USED}
        </div>
        <div className={styles.text}>{templateStrings.POINT_INPUT_LABEL} 1</div>
        {getPointContainers()}
        <div className={styles.disclaimer}>{strings.DISCLAIMER}</div>
        <div className={styles.footerContainer}>
          <Footer
            onNextClick={goToNextStep}
            onPreviousClick={goToPreviousStep}
            nextLabel={strings.NEXT}
            previousLabel={strings.PREVIOUS}
          />
        </div>
      </div>
    </div>
  );
};
