import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import LinkShortenerPage from './components/LinkShortener';

const Router = () => (
  <div style={style.fullHeight}>
    <BrowserRouter>
      <div style={style.fullHeight}>
        <Switch>
          <Redirect
            exact
            path="/survey/saloncondities"
            to="/users/campaign/5f9fd389873b5900357ba7b2/user/5d2894d1c9cd90002ad9c267"
          />
          <Redirect
            exact
            path="/survey/les_conditions_salon"
            to="/users/campaign/5f9fd4a8873b5900357baae2/user/5d2894d1c9cd90002ad9c267"
          />
          <Route exact path="/survey/:id" component={LinkShortenerPage} />
        </Switch>
      </div>
    </BrowserRouter>
  </div>
);

const style = {
  fullHeight: {
    height: '100vh'
  }
};

export default Router;
