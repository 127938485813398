import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import deleteQuestionBranch from './deleteQuestionBranch';
import executeRequestsInSequence from '../../helpers/executeRequestsInSequence';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  const newState = deleteQuestionBranch(
    surveysState,
    action.flowId,
    action.branchId
  );
  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  // Remove all graphs of decision framework questions
  if (newState.requests && newState.requests.length) {
    yield executeRequestsInSequence(action.api, newState.requests);
  }

  if (
    newState.newState.deletedQuestions &&
    newState.newState.deletedQuestions.length > 0
  ) {
    action.api('deleteQuestions', {
      questionIdsArray: newState.newState.deletedQuestions,
      surveyContent: newState.newState.survey.content
    });
  }
}
