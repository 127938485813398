import React from 'react';

const HeaderStats = props => {
  const { stats } = props;

  return (
    <div className="right-results-header-content">
      <div className="right-header-content-container">
        <div className="right-header-value">
          {stats && stats.responses && stats.responses !== stats.totalResponses
            ? stats.responses
            : null}
          {stats && stats.responses && stats.responses !== stats.totalResponses
            ? '/'
            : null}
          {stats && stats.totalResponses ? stats.totalResponses : '/'}
        </div>
        <div className="right-header-title">
          {stats && stats.responses && stats.responses !== stats.totalResponses
            ? 'Filtered/Total Responses'
            : 'Total Responses'}
        </div>
      </div>
      <div className="right-header-content-container">
        <div className="right-header-value">
          {stats && stats.responseTarget ? stats.responseTarget : '/'}
        </div>
        <div className="right-header-title">Response Target</div>
      </div>
      <div className="right-header-content-container">
        <div className="right-header-value">
          {stats && stats.completion ? stats.completion : '/'}
          <span className="right-header-value-percentage">%</span>
        </div>
        <div className="right-header-title">Completion Rate</div>
      </div>
      {/* <div className="right-header-content-container">
          <div className="right-header-value">
            {campaign && campaign.results_count && stats && stats.responses
              ? `${(
                  100 -
                  (campaign.results_count / stats.responses) * 100
                ).toFixed(2)}%`
              : "/"}
          </div>
          <div className="right-header-title">Droproute rate</div>
        </div> */}
      {
        // <div className="right-header-content-container">
        //   <div className="right-header-value">{stats && stats.averageCompletionTime ?
        //       onTimePrettyPrint(stats.averageCompletionTime) : '/'}</div>
        //   <div className="right-header-title">Average completion time</div>
        // </div>
      }
    </div>
  );
};

export default HeaderStats;
