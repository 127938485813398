const getFlow = (sourceFlow, surveyLists, returnQuestionOnly, isPrevious) => {
  const sourceFlowIndex = surveyLists.findIndex(f => f.id === sourceFlow.id);

  // If "sourceFlow" is a group
  if (sourceFlowIndex === -1) {
    const groupQuestions = surveyLists.filter(
      f =>
        f.group &&
        (f.group.id === sourceFlow.id ||
          (f.group.parentGroup && f.group.parentGroup.id === sourceFlow.id))
    );

    if (groupQuestions && groupQuestions.length) {
      const edgeGroupQuestion =
        groupQuestions[isPrevious ? 0 : groupQuestions.length - 1];
      const edgeGroupQuestionIndex = surveyLists.findIndex(
        f => f.id === edgeGroupQuestion.id
      );
      return getFlow(
        surveyLists[edgeGroupQuestionIndex],
        surveyLists,
        returnQuestionOnly,
        isPrevious
      );
    }
  }

  const edgeQuestion =
    surveyLists[isPrevious ? sourceFlowIndex - 1 : sourceFlowIndex + 1];

  // All questions in same parent question as nextQuestion
  const parentGroupQuestions = surveyLists.filter(
    f =>
      f.group &&
      f.group.parentGroup &&
      edgeQuestion &&
      edgeQuestion.group &&
      edgeQuestion.group.parentGroup &&
      f.group.parentGroup.id === edgeQuestion.group.parentGroup.id &&
      (!sourceFlow.group ||
        (sourceFlow.group &&
          sourceFlow.group.id !== edgeQuestion.group.parentGroup.id))
  );

  if (
    edgeQuestion &&
    edgeQuestion.group &&
    parentGroupQuestions.length &&
    parentGroupQuestions[isPrevious ? parentGroupQuestions.length - 1 : 0]
      .id === edgeQuestion.id
  ) {
    if (returnQuestionOnly) {
      return parentGroupQuestions[
        isPrevious ? parentGroupQuestions.length - 1 : 0
      ];
    }
    return edgeQuestion.group.parentGroup;
  }

  // All questions in same group as nextQuestion
  const groupQuestions = surveyLists.filter(
    f =>
      f.group &&
      edgeQuestion &&
      edgeQuestion.group &&
      f.group.id === edgeQuestion.group.id &&
      ((isPrevious && f.globalIndex <= sourceFlow.globalIndex) ||
        (!isPrevious && f.globalIndex >= sourceFlow.globalIndex))
  );

  const destinationEdge =
    groupQuestions[isPrevious ? groupQuestions.length - 1 : 0];

  if (
    edgeQuestion &&
    edgeQuestion.group &&
    groupQuestions.length &&
    destinationEdge.id === edgeQuestion.id &&
    (!sourceFlow.group ||
      (sourceFlow.group &&
        sourceFlow.group.nestingLevel &&
        destinationEdge &&
        destinationEdge.group &&
        destinationEdge.group.nestingLevel &&
        !(
          sourceFlow.group.nestingLevel === 2 &&
          destinationEdge.group.nestingLevel === 1 &&
          sourceFlow.group.parentGroup.id === destinationEdge.group.id
        )))
  ) {
    if (returnQuestionOnly) {
      return groupQuestions[isPrevious ? groupQuestions.length - 1 : 0];
    }
    return edgeQuestion.group;
  }

  return edgeQuestion || null;
};

// Get previous flow (question, group)
export const getPreviousNeigboingFlow = (
  sourceFlow,
  surveyLists,
  returnQuestionOnly
) => getFlow(sourceFlow, surveyLists, returnQuestionOnly, true);

export const getPreviousQuestion = (sourceFlow, surveyLists) =>
  getPreviousNeigboingFlow(sourceFlow, surveyLists, true);

export const getNextNeigboingFlow = (
  sourceFlow,
  surveyLists,
  returnQuestionOnly
) => getFlow(sourceFlow, surveyLists, returnQuestionOnly);

export const getNextQuestion = (sourceFlow, surveyLists) =>
  getNextNeigboingFlow(sourceFlow, surveyLists, true);
