import React, { useState, useEffect } from "react";
import * as d3 from "d3";

const Line = props => {
  const { data } = props;
  const [svg, setSvg] = useState(null);

  // Did mount
  useEffect(() => {
    drawChart(svg, props);
  }, []);

  // Did update
  useEffect(
    () => {
      drawChart(svg, props);
    },
    [svg, data]
  );

  const drawChart = (svgRef, chartProps) => {
    const { parent } = props;

    if (parent && parent.offsetWidth) {
      const { height, xLabel, yLabel, order, xType } = chartProps;

      const width = props.parent.offsetWidth;

      const margin = {
        top: 30,
        right: 50,
        bottom: 70,
        left: 50,
      };

      const focusProps = {
        radius: 5,
        textX: 8,
        textY: -8,
      };

      const labelMargins = {
        yY: 20,
        ydY: 30,
        xY: 20,
        xX: margin.right + margin.left - 10,
      };

      let fullData = [];
      if (order) {
        if (data && data.length) {
          const sortedData = data.sort((a, b) => (a.x > b.x ? 1 : -1));
          const highestTime = sortedData[sortedData.length - 1].x;

          for (let i = 0; i <= highestTime + 1; i += 1) {
            const existingValue = sortedData.filter(value => value.x === i);
            if (existingValue.length) {
              fullData.push({ x: i, y: existingValue[0].y });
            } else {
              fullData.push({ x: i, y: 0 });
            }
          }
        }
      } else {
        fullData = chartProps.data.reverse();
      }

      const svgChart = d3
        .select(svgRef)
        .attr("width", width)
        .attr("height", height);

      svgChart.selectAll("*").remove();

      let x = null;
      if (xType === "number") {
        x = d3.scaleLinear().range([0, width - margin.left - margin.right]);
      }
      if (xType === "time") {
        x = d3.scaleTime().rangeRound([0, width - margin.left - margin.right]);
      }
      const y = d3
        .scaleLinear()
        .range([height - margin.top - margin.bottom, 0]);

      if (xType === "number") {
        x.domain([0, d3.max(fullData, d => d.x)]);
      }

      const parseTime = d3.timeParse("%Y-%m-%dT%H:%M");
      if (xType === "time") {
        x.domain(d3.extent(fullData, d => parseTime(d.x)));
      }
      y.domain([0, d3.max(fullData, d => d.y)]);

      const chart = svgChart
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      if (fullData && fullData.length) {
        const area = d3
          .area()
          .x(d => {
            if (xType === "time") {
              return x(parseTime(d.x));
            }
            if (xType === "number") {
              return x(d.x);
            }
            return null;
          })
          .y0(height - margin.top - margin.bottom)
          .y1(d => y(d.y));

        if (chart._groups && chart._groups[0] && !chart._groups[0][0])
          return false;

        chart
          .append("path")
          .data([fullData])
          .attr("class", "area")
          .attr("d", area);

        const valueline = d3
          .line()
          .x(d => {
            if (xType === "time") {
              return x(parseTime(d.x));
            }
            if (xType === "number") {
              return x(d.x);
            }
            return null;
          })
          .y(d => y(d.y));

        chart
          .append("path")
          .data([fullData])
          .attr("class", "line")
          .attr("d", valueline);
      }

      const focus = chart
        .append("g")
        .attr("class", "focus")
        .style("display", "none");

      focus
        .append("line")
        .attr("class", "x-hover-line hover-line")
        .attr("y1", 0)
        .attr("y2", height - margin.top - margin.bottom);

      focus
        .append("line")
        .attr("class", "y-hover-line hover-line")
        .attr("x1", width)
        .attr("x2", width);

      focus.append("circle").attr("r", focusProps.radius);

      focus
        .append("text")
        .attr("x", focusProps.textX)
        .attr("dy", focusProps.textY);

      const bisectX = d3.bisector(d => d.x).left;
      const mousemove = () => {
        const x0 = x.invert(d3.mouse(svgChart.node())[0] - margin.left);
        const i = bisectX(fullData, x0, 1);
        const d0 = fullData[i - 1];
        const d1 = fullData[i];
        if (d0 && d1) {
          const d = x0 - d0.x > d1.x - x0 ? d1 : d0;
          focus.attr("transform", `translate(${x(d.x)}, ${y(d.y)})`);
          focus.select("text").text(() => d.y);
          focus
            .select(".x-hover-line")
            .attr("y2", height - margin.top - margin.bottom - y(d.y));
          focus.select(".y-hover-line").attr("x2", width + width);
        }
      };

      svgChart
        .append("rect")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("class", "overlay")
        .attr("width", width)
        .attr("height", height - margin.top - margin.bottom)
        .on("mouseover", () => focus.style("display", null))
        .on("mouseout", () => focus.style("display", "none"))
        .on("mousemove", mousemove);

      chart
        .append("g")
        .attr(
          "transform",
          `translate(0, ${height - margin.bottom - margin.top})`
        )
        .attr("class", "axis")
        .call(d3.axisBottom(x));

      svgChart
        .append("text")
        .attr("class", "axis-title")
        .attr("transform", "rotate(-90)")
        .attr("y", labelMargins.y)
        .attr("dy", labelMargins.ydY)
        .attr("x", margin.top * -1)
        .style("text-anchor", "end")
        .text(yLabel);

      svgChart
        .append("text")
        .attr("class", "axis-title")
        .attr("y", height - labelMargins.xY)
        .attr("x", width - labelMargins.xX)
        .text(xLabel);
    }

    return null;
  };

  return (
    <div>
      {data && data.length ? (
        <svg
          ref={elem => {
            setSvg(elem);
          }}
        />
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default Line;
