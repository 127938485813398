import React, { useState, useRef, useMemo } from 'react';

import Bars from '../../Graphs/components/MergedGraph/components/Bars/Bars';
import QuestionsList from '../../Graphs/components/MergedGraph/components/QuestionsList/QuestionsList';

import getChartData from '../../Graphs/components/MergedGraph/helpers/getChartData';
import colorsPallete from '../../../colors';

import DutchStrings from '../../../../../../../users/strings/nl';
import FrenchStrings from '../../../../../../../users/strings/fr';
import EnglishStrings from '../../../../../../../users/strings/en';

import styles from '../../Graphs/components/MergedGraph/MergedGraph.module.css';

const Matrix = props => {
  const {
    block,
    surveyLanguage,
    activeFilters,
    barsVertical,
    isPercentage,
    onToggleFilter,
    filtersRelation,
    onSetFiltersRelation,
    filteringOnSelectionEnabled,
    responses,
    extremeUsersFilterLabels
  } = props;

  const loadedStrings = {
    Dutch: DutchStrings,
    French: FrenchStrings,
    English: EnglishStrings
  };

  const translations =
    surveyLanguage && loadedStrings[surveyLanguage]
      ? loadedStrings[surveyLanguage]
      : loadedStrings.English;

  const barsParent = useRef();
  const [hiddenQuestions, setHiddenQuestions] = useState([]);

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = JSON.parse(question)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
    } catch (error) {
      questionValue = question;
    }
    return questionValue;
  };

  const getQuestionText = (questionId, choiceId) => {
    let questionText = '';
    if (
      block.matrix &&
      block.matrix.xQuestion &&
      block.matrix.yQuestion &&
      block.matrix.xQuestionId &&
      block.matrix.yQuestionId
    ) {
      if (questionId === block.matrix.xQuestionId) {
        questionText = `${parseQuestion(block.matrix.xQuestion)}`;
      } else if (questionId === block.matrix.yQuestionId) {
        questionText = `${parseQuestion(block.matrix.yQuestion)}`;
      }

      if (
        block.answers &&
        block.answers.length &&
        block.answers.some(answer => answer.id === choiceId)
      ) {
        const answerFound = block.answers.find(
          answer => answer.id === choiceId
        );
        questionText += `\n${parseQuestion(answerFound.answer)}`;
      }
    }
    return questionText;
  };

  const answerOptions = [
    translations.MATRIX.I_STRONGLY_DISAGREE,
    translations.MATRIX.I_DISAGREE,
    translations.MATRIX.I_AGREE,
    translations.MATRIX.I_STRONGLY_AGREE
  ];

  const questionIds = [];
  if (block.matrix && block.matrix.xQuestionId) {
    questionIds.push(block.matrix.xQuestionId);
  }
  if (
    block.matrix &&
    block.matrix.yQuestionId &&
    block.matrix.decisionFrameworkType !== 'SINGLE'
  ) {
    questionIds.push(block.matrix.yQuestionId);
  }

  const matrixOptions = useMemo(
    () =>
      block.answers && block.answers.length && questionIds.length
        ? questionIds.reduce((options, questionId) => {
            const newOptions = [
              ...options,
              ...block.answers
                .filter(
                  answer =>
                    answer.decisionFrameworkType ===
                    block.matrix.decisionFrameworkType
                )
                .map(answer => ({
                  questionId,
                  choiceId: answer.id,
                  question: getQuestionText(questionId, answer.id)
                }))
            ];
            return newOptions;
          }, [])
        : [],
    []
  );

  const findMatrixQuestionStats = (option, stats, filteredStatsValues) => {
    if (stats.values && stats.values.length) {
      const optionStats = stats.values.find(
        v =>
          v.choiceId === option.choiceId && v.questionId === option.questionId
      );
      if (optionStats && optionStats.values && optionStats.values.length) {
        let values = [...optionStats.values.map(value => ({ ...value }))];
        if (filteringOnSelectionEnabled && filteredStatsValues) {
          const filteredData = filteredStatsValues;
          /* eslint-disable no-param-reassign */
          const newOptionStatsValues = values.reduce(
            (unfilteredValuesWithoutSelection, currentDataPoint) => {
              if (filteredData && filteredData.length) {
                const matchingValue = filteredData.find(
                  datapoint => datapoint.x === currentDataPoint.x
                );
                if (matchingValue) {
                  currentDataPoint.y -= matchingValue.y;
                }
                if (currentDataPoint.y > 0) {
                  unfilteredValuesWithoutSelection.push(currentDataPoint);
                }
              }
              return unfilteredValuesWithoutSelection;
            },
            []
          );
          values = [...newOptionStatsValues];
          /* eslint-enable no-param-reassign */
        }
        return values;
      }
    }
    return [];
  };

  const matrixQuestionStats = useMemo(
    () => {
      if (
        block.results &&
        block.resultStats &&
        block.unfilteredResults &&
        block.unfilteredResultStats
      ) {
        return matrixOptions.map(option => {
          const resultStatsValues = findMatrixQuestionStats(
            option,
            block.resultStats
          );
          return {
            results: block.results,
            resultStats: {
              values: resultStatsValues
            },
            unfilteredResults: block.unfilteredResults,
            unfilteredResultStats: {
              values: findMatrixQuestionStats(
                option,
                block.unfilteredResultStats,
                resultStatsValues
              )
            },
            answers: answerOptions.map(answer => ({ answer })),
            type: block.type
          };
        });
      }
      return [];
    },
    [block.results, filteringOnSelectionEnabled]
  );

  const isFiltered = !!(activeFilters && activeFilters.length);
  const data = useMemo(
    () =>
      getChartData(
        matrixQuestionStats,
        isPercentage,
        isFiltered,
        hiddenQuestions
      ),
    [matrixQuestionStats, isPercentage, hiddenQuestions]
  );

  const onBarClick = (questionIndex, answerStringIndex) => {
    let formattedQuestionIndex = questionIndex;
    if (questionIndex.includes('_nofilter')) {
      formattedQuestionIndex = Number(questionIndex.split('_').shift());

      if (filtersRelation === 'and') {
        onSetFiltersRelation('or');
      }
    }

    const clickedBarQuestion = matrixOptions[formattedQuestionIndex];
    const answerIndex = parseInt(answerStringIndex, 10);

    if (block.id && clickedBarQuestion) {
      let filterString = answerOptions[answerIndex] || answerIndex;
      const filteredMatrixOption = matrixOptions.find(
        o =>
          o.questionId === clickedBarQuestion.questionId &&
          o.choiceId === clickedBarQuestion.choiceId
      );
      if (filteredMatrixOption && filteredMatrixOption.question) {
        filterString += ` - ${filteredMatrixOption.question}`;
      }

      const value = `${clickedBarQuestion.questionId},${
        clickedBarQuestion.choiceId
      },${answerIndex}`;

      onToggleFilter(
        block.id,
        null,
        value,
        null,
        result => {
          if (
            result &&
            result.attributes &&
            result.attributes.block_results &&
            result.attributes.block_results.length
          ) {
            const questionBlock = result.attributes.block_results.find(
              question => question.block === block.id
            );
            if (
              questionBlock &&
              questionBlock.matrixAnswers &&
              questionBlock.matrixAnswers.length &&
              questionBlock.matrixAnswers.some(
                matrixAnswer =>
                  matrixAnswer.questionId &&
                  matrixAnswer.questionId === clickedBarQuestion.questionId &&
                  matrixAnswer.choices &&
                  matrixAnswer.choices.length &&
                  matrixAnswer.choices.some(
                    choice =>
                      choice.choiceId === clickedBarQuestion.choiceId &&
                      choice.answers &&
                      choice.answers.length &&
                      choice.answers.includes(answerIndex)
                  )
              )
            ) {
              return true;
            }
          }
          return false;
        },
        filterString
      );
    }
  };

  return (
    <div className={styles.blockContent}>
      <div className={styles.chartContainer} ref={barsParent}>
        <Bars
          parent={barsParent}
          height="400"
          data={data}
          barsVertical={barsVertical}
          colorsPallete={colorsPallete.filter(
            (_c, index) => hiddenQuestions.indexOf(index) === -1
          )}
          isPercentage={isPercentage}
          isFiltered={isFiltered}
          onBarClick={onBarClick}
          statisticalRelevance={false}
        />
      </div>
      <QuestionsList
        mergedGraphQuestions={matrixOptions}
        colorsPallete={colorsPallete}
        hiddenQuestions={hiddenQuestions}
        setHiddenQuestions={setHiddenQuestions}
        parseQuestion={parseQuestion}
        isMatrixQuestion
      />
      {responses < 75 &&
      !(
        activeFilters &&
        activeFilters.length &&
        activeFilters.some(
          activeFilter =>
            activeFilter.value &&
            extremeUsersFilterLabels.includes(activeFilter.value)
        )
      ) ? (
        <div className={styles.disclaimer}>
          Disclaimer: The{' '}
          {activeFilters && activeFilters.length ? 'selected' : 'total'} sample
          is rather small
          {` (< 75 respondents) `} which means that results are indicative but
          might not be statistically relevant.
        </div>
      ) : null}
    </div>
  );
};

export default Matrix;
