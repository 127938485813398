import React, { useRef } from 'react';

import Chart from '../Chart/Chart';
import Loader from '../../../../../../../CommunityDashboard/components/CommunityDashboardPage/components/Loader/Loader';

import styles from './ChartMetric.module.css';

export default ({
  title,
  chartData,
  metricWidth = 100,
  isSocioDemoChart,
  minimumQuotaEnabled,
  customLabels
}) => {
  const chartParentContainer = useRef();
  const tooltipContainerRef = useRef();

  if (!chartData) {
    return (
      <div className={styles.container} style={{ width: `${metricWidth}%` }}>
        <div className={styles.loadingContainer}>
          <Loader fixed={false} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} style={{ width: `${metricWidth}%` }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.chartContainer} ref={chartParentContainer}>
        <Chart
          title={title}
          parent={chartParentContainer}
          tooltipContainerRef={tooltipContainerRef}
          height={260}
          data={
            chartData.values && chartData.values.length ? chartData.values : []
          }
          unfilteredData={
            chartData.unfilteredValues && chartData.unfilteredValues.length
              ? chartData.unfilteredValues
              : []
          }
          deltaData={
            chartData.deltaValues && chartData.deltaValues.length
              ? chartData.deltaValues
              : []
          }
          sectorIncidenceRate={
            chartData.sectorIncidenceRate &&
            chartData.sectorIncidenceRate.length
              ? chartData.sectorIncidenceRate
              : []
          }
          isSocioDemoChart={isSocioDemoChart}
          customLabels={customLabels}
        />
        <div ref={tooltipContainerRef} className={styles.tooltipContainer} />
        <div className={styles.legend}>
          {isSocioDemoChart ? (
            <>
              <div className={styles.legendItem}>
                <span className={`${styles.bullet} ${styles.green}`} />
                {`> 90% feasible`}
              </div>
              <div className={styles.legendItem}>
                <span className={`${styles.bullet} ${styles.orange}`} />
                {`50% - 90% feasible`}
              </div>
              <div className={styles.legendItem}>
                <span className={`${styles.bullet} ${styles.red}`} />
                {`< 50% feasible`}
              </div>
              <div className={styles.legendItem}>
                <span className={`${styles.bullet} ${styles.lightGrey}`} />
                Not selected
              </div>
              <div className={styles.legendItem}>
                <span className={`${styles.stripe}`} />
                Representative sample
              </div>
            </>
          ) : (
            <>
              <div className={styles.legendItem}>
                <span className={`${styles.bullet} ${styles.blue}`} />
                Delivery potential in 48h
              </div>
              <div className={styles.legendItem}>
                <span className={`${styles.bullet} ${styles.lightGrey}`} />
                Not selected
              </div>
              {minimumQuotaEnabled ? (
                <div className={styles.legendItem}>
                  <span className={`${styles.stripe}`} />
                  Quota
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
