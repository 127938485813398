import React from 'react';

import tickIcon from '../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../assets/img/delete.svg';

import styles from './ResearchCostIncreased.module.css';

export default ({ onClick, onClose }) => (
  <div className={styles.container}>
    <div className={styles.title}>Are you sure?</div>
    <div className={styles.description}>
      With this question block it’s possible to add respondents to one or more
      communities. Please note that this will increase the cost of your
      research. Are you sure you want to continue?
    </div>
    <div className={styles.footer}>
      <div />
      <div className={styles.actions}>
        <img
          className={styles.confirm}
          src={tickIcon}
          alt="Confirm icon"
          onClick={() => {
            onClick();
            onClose();
          }}
          role="presentation"
        />
        <img
          className={styles.close}
          src={closeIcon}
          alt="Close icon"
          onClick={onClose}
          role="presentation"
        />
      </div>
    </div>
  </div>
);
