import React from 'react';

import styles from './InputField.module.css';

export default ({
  title,
  placeholder,
  value,
  setValue,
  values,
  mandatory,
  fieldName
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      {title}
      {mandatory ? <span className={styles.requiredAsterisk}>*</span> : null}
    </div>
    <div>
      <input
        className={styles.input}
        placeholder={placeholder}
        value={value || ''}
        onChange={e =>
          setValue({
            ...values,
            ...{ [fieldName]: e.target.value }
          })
        }
      />
    </div>
  </div>
);
