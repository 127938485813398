import React, { useState } from "react";
import TimeDistribution from "../Charts/TimeDistribution";

const ResponseTimes = props => {
  const { responseTimes } = props;

  const [chartRef, setChartRef] = useState(null);

  return (
    <div
      className="results-container results-container-response-times-container"
      ref={elem => setChartRef(elem)}
    >
      <TimeDistribution data={responseTimes} parent={chartRef} />
    </div>
  );
};

export default ResponseTimes;
