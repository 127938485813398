import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import qs from 'query-string';

import DutchStrings from '../../../strings/nl';
import FrenchStrings from '../../../strings/fr';
import EnglishStrings from '../../../strings/en';
import GermanStrings from '../../../strings/de';
import SignUp from './Steps/SignUp';
import Completed from './Steps/Completed';

import {
  GET_APP_CONFIGURATION_REGISTER,
  REGISTER,
  LOGIN,
  UPDATE_PROFILE
} from '../../../graphql/Auth';

const languages = {
  English: EnglishStrings,
  Dutch: DutchStrings,
  French: FrenchStrings,
  German: GermanStrings
};

/* global window, localStorage */

const RegistrationPage = props => {
  const { location } = props;

  const [fields, setFields] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    gender: '',
    civil_status: '',
    has_children: '',
    country: '',
    city: '',
    date_of_birth: '',
    language: 'NL'
  });

  const [errorFields, setErrorFields] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    date_of_birth: '',
    gender: ''
  });

  const [translations, setTranslations] = useState(languages.Dutch);

  useEffect(() => {
    const query = qs.parse(location.search);
    let transactionID;
    let referalID;
    const fieldValuesToSet = {};

    if (query && query.transaction_id) {
      transactionID = query.transaction_id;
    }
    if (query && query.referal) {
      referalID = query.referal;
    }
    if (query && query.lang) {
      if (query.lang === 'FR') {
        fieldValuesToSet.language = 'FR';
      }
    }
    // Conversion tracking with Tune platform (Server Postback Tracking) for client 'MVF'
    if (transactionID) {
      let fetchURL;
      if (query && query.lang && query.lang === 'FR') {
        fetchURL = `https://mvfglobal.go2cloud.org/aff_lsr?offer_id=111&transaction_id=${transactionID}`;
      } else {
        fetchURL = `https://mvfglobal.go2cloud.org/aff_lsr?offer_id=156&transaction_id=${transactionID}`;
      }
      fetch(fetchURL);
    }
    if (referalID) {
      fieldValuesToSet.refererUserId = referalID;
    }

    if (fieldValuesToSet && Object.values(fieldValuesToSet).length) {
      setFields({ ...fields, ...fieldValuesToSet });
    }
  }, []);

  useEffect(
    () => {
      if (fields.language) {
        switch (fields.language) {
          case 'NL':
            setTranslations(languages.Dutch);
            break;
          case 'FR':
            setTranslations(languages.French);
            break;
          case 'EN':
            setTranslations(languages.English);
            break;
          case 'DE':
            setTranslations(languages.German);
            break;
          default:
            setTranslations(languages.Dutch);
        }
      }
    },
    [fields.language]
  );

  const { data: { getAppConfiguration } = {} } = useQuery(
    GET_APP_CONFIGURATION_REGISTER,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [onRegister] = useMutation(REGISTER, {
    onCompleted: response => {
      if (
        response &&
        response.createUser &&
        response.createUser.errors &&
        response.createUser.errors.length
      ) {
        setRegistrationStarted(false);
        setComponentError(translations.EMAIL_ALREADY_USED);
      }
      if (response && response.createUser && response.createUser.done) {
        onLogin({
          variables: { email: fields.email, password: fields.password }
        });
      }
    }
  });

  const [onLogin] = useMutation(LOGIN, {
    onCompleted: response => {
      if (response && response.login && response.login.loginToken) {
        localStorage.setItem('@buffl:auth-token', response.login.loginToken);
        const fieldsToSubmit = {
          gender: fields.gender,
          dateOfBirth: fields.date_of_birth
        };
        onProfileUpdate({ variables: fieldsToSubmit });
      }
    }
  });

  const [onProfileUpdate] = useMutation(UPDATE_PROFILE, {
    onCompleted: response => {
      if (response && response.updateUser && response.updateUser.user) {
        props.history.push('?completed=true');
      }
    }
  });

  const mandatoryFields = ['email', 'password', 'repeatPassword'];

  const [registrationStarted, setRegistrationStarted] = useState(false);
  const [currentPage] = useState('signup');

  const [componentError, setComponentError] = useState(null);

  /* eslint-disable */
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };
  /* eslint-enable */

  const setFieldValue = (name, value) =>
    setFields({ ...fields, [name]: value });

  const goNext = event => {
    let error = false;
    setComponentError(null);

    if (currentPage === 'signup') {
      const componentErrors = mandatoryFields.filter(
        field => !fields[field].trim() || fields[field].trim() === ''
      );
      const newErrorFields = {
        email: '',
        password: '',
        repeatPassword: '',
        date_of_birth: '',
        gender: ''
      };

      if (componentErrors.length) {
        error = true;
        componentErrors.forEach(cError => {
          newErrorFields[cError] = 'PLEASE_FILL_FIELD';
        });
      }

      if (!fields.date_of_birth) {
        error = true;
        newErrorFields.date_of_birth = 'PLEASE_FILL_FIELD';
      }

      if (fields.email.length) {
        // valid email
        const regexTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        if (!regexTest.test(fields.email)) {
          error = true;
          newErrorFields.email = 'PLEASE_ENTER_VALID_EMAIL';
        }
      }

      if (!fields.gender.length) {
        error = true;
        newErrorFields.gender = 'PLEASE_SELECT_GENDER';
      }

      if (
        fields.password.trim() &&
        fields.repeatPassword.trim() &&
        fields.password.trim() !== fields.repeatPassword.trim()
      ) {
        error = true;
        newErrorFields.repeatPassword = 'PASSWORDS_NOT_SAME';
      }

      setErrorFields(newErrorFields);

      if (!error) {
        setRegistrationStarted(true);
        const fieldsToSubmit = {
          email: fields.email,
          password: fields.password,
          language: fields.language,
          refererUserId: fields.refererUserId
        };
        onRegister({ variables: fieldsToSubmit });
      }
    }
    event.preventDefault();
  };

  if (!getAppConfiguration) {
    return null;
  }

  if (getParameterByName('completed') === 'true') {
    return (
      <Completed
        fields={fields}
        setFieldValue={setFieldValue}
        translations={translations}
      />
    );
  }

  if (currentPage === 'signup') {
    return (
      <>
        <SignUp
          componentError={componentError}
          goNext={goNext}
          fields={fields}
          setFieldValue={setFieldValue}
          errorFields={errorFields}
          registrationStarted={registrationStarted}
          translations={translations}
        />
      </>
    );
  }
  return null;
};

export default RegistrationPage;
