export default (survey, propertyName) => {
  let permission = null;

  if (survey && survey.recordDetails) {
    if (survey.recordDetails.permissions) {
      const parsedPermissions = JSON.parse(survey.recordDetails.permissions);

      // Determine questions included in licence
      if (
        parsedPermissions &&
        parsedPermissions[propertyName] !== null &&
        parsedPermissions[propertyName] !== undefined
      ) {
        permission = parsedPermissions[propertyName];
      }
    }

    if (
      permission === null &&
      survey.recordDetails.parent &&
      survey.recordDetails.parent.permissions
    ) {
      const parsedParentPermissions = JSON.parse(
        survey.recordDetails.parent.permissions
      );

      if (
        parsedParentPermissions[propertyName] !== null &&
        parsedParentPermissions[propertyName] !== undefined
      ) {
        permission = parsedParentPermissions[propertyName];
      }
    }

    return permission;
  }

  return null;
};
