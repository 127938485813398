import React from 'react';
import { Redirect } from 'react-router-dom';

import AuthWelcome from './AuthWelcome';
import './LoginRegistration.css';
import bufflLogo from '../../assets/img/buffl.svg';

import getQueryStringParams from '../../helpers/getQueryStringParams';

const RegistrationForm = props => {
  const {
    fields,
    isAuthenticated,
    isProcessing,
    isCompleted,
    onChange,
    onSubmit,
    errors,
    onLoginAfterRegister,
    location: { search }
  } = props;

  const queryParameters = getQueryStringParams(search);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isCompleted) {
    if (
      fields &&
      fields.email &&
      fields.password &&
      fields.email.value &&
      fields.password.value
    ) {
      onLoginAfterRegister(
        fields.email.value,
        fields.password.value,
        queryParameters.token
      );
    } else {
      return <Redirect to="/auth/login" />;
    }
  }

  return (
    <div className="right-container registration-page">
      <AuthWelcome customClass="registration-page" />
      <div className="form-container registration-page">
        <form
          onSubmit={ev =>
            onSubmit(
              ev,
              fields.email.value,
              fields.password.value,
              fields.password2.value,
              fields.betaKey.value,
              fields.company.value,
              fields.telephone.value,
              fields.firstname.value,
              fields.lastname.value,
              fields.howDidYouLearnAboutBuffl.value
            )
          }
        >
          <img className="buffl-logo" src={bufflLogo} alt="Buffl logo" />
          <div className="form-container-title">Sign Up</div>
          <input
            type="text"
            value={fields.email.value}
            onChange={onChange('email')}
            placeholder="Email"
            className="client-registration-email-input"
          />
          <input
            type="password"
            value={fields.password.value}
            onChange={onChange('password')}
            placeholder="Password"
            className="client-registration-password-input"
          />
          <input
            type="password"
            value={fields.password2.value}
            onChange={onChange('password2')}
            placeholder="Repeat password"
            className="client-registration-repeatpassword-input"
          />

          <input
            type="text"
            value={fields.company.value}
            onChange={onChange('company')}
            placeholder="Company"
            className="client-registration-company-input"
          />
          <input
            type="text"
            value={fields.telephone.value}
            onChange={onChange('telephone')}
            placeholder="Telephone"
            className="client-registration-telephone-input"
          />
          <input
            type="text"
            value={fields.firstname.value}
            onChange={onChange('firstname')}
            placeholder="Firstname"
            className="client-registration-firstname-input"
          />
          <input
            type="text"
            value={fields.lastname.value}
            onChange={onChange('lastname')}
            placeholder="Lastname"
            className="client-registration-lastname-input"
          />
          <textarea
            value={fields.howDidYouLearnAboutBuffl.value}
            onChange={onChange('howDidYouLearnAboutBuffl')}
            placeholder="How did you learn about BUFFL?"
            className="client-registration-howDidYouLearnAboutBuffl-input"
          />
          {errors && errors[0] && !isProcessing ? (
            <div className="error-message">{errors[0].detail}</div>
          ) : null}
          {!isProcessing ? (
            <button type="submit" className="button client-registration-button">
              Register
            </button>
          ) : (
            <div className="button-loading">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
