import React from 'react';

import styles from './ImagePopup.module.css';

const ImagePopup = props => {
  const { src, alt, onClose } = props;

  return (
    <div
      className={styles.container}
      role="presentation"
      onClick={() => onClose()}
    >
      <img src={src} alt={alt} />
    </div>
  );
};

export default ImagePopup;
