import React from 'react';

import { TARGET_AUDIENCE_STEPS } from '../../../SurveyBuilder/helpers/constants';

import styles from './TargetAudienceHeader.module.css';

const stepTitle = {
  [TARGET_AUDIENCE_STEPS.SOCIO_DEMOGRAPHICS]: 'Socio demographics',
  [TARGET_AUDIENCE_STEPS.PROFILING_QUESTIONS]: 'Profiling questions',
  [TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS]: 'Not eligible respondents'
};

export default ({
  TARGET_AUDIENCE_TAB,
  targetAudienceStep,
  setTargetAudienceStep,
  surveyId,
  history
}) => {
  const alphabetArray = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const onClick = stepKey => {
    setTargetAudienceStep(stepKey);
    history.push(
      `/survey/${surveyId}/edit/${
        TARGET_AUDIENCE_TAB.name
      }/${stepKey.toLowerCase()}`
    );
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        {Object.entries(stepTitle).map(([key, title], index) => (
          <div
            className={`${styles.title} ${
              stepTitle[targetAudienceStep] === title ? styles.titleActive : ''
            }`}
            onClick={() => onClick(key)}
            role="presentation"
          >
            {alphabetArray[index]}. {title}
          </div>
        ))}
      </div>
    </div>
  );
};
