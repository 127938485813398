import React, { useContext } from 'react';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import Footer from '../../Footer/Footer';
import templateWizardToSurvey from '../../../../../state/actions/templateWizardToSurvey/templateWizardToSurvey';

import {
  TEMPLATES_WIZARD_STEP_KEYS,
  TEMPLATES_SCALES,
  TEMPLATES_WIZARD_TEMPLATE_TYPES
} from '../../../../../helpers/constants';

import styles from './SetSuccessCriteria.module.css';

export default () => {
  const {
    currentStep,
    goToNextStep,
    goToPreviousStep,
    steps,
    setStepValue,
    strings,
    styleText
  } = useContext(TemplatesWizardContext);

  const templateTypeStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
  );

  const testsNumber = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER
  );

  const scaleType = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SET_SCALE
  );

  const pointsStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.DEFINE_POINTS
  );

  const problemContextStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SET_CONTEXT
  );

  const templateStrings = strings[templateTypeStep.value];

  const mcText1 = templateStrings.WE_THINK_1.split('{SPLIT}');
  const mcText2 = templateStrings.WE_THINK_2.split('{SPLIT}');

  const isDisabledNext = () => {
    const emptyPoints = currentStep.value.filter(
      v =>
        !v ||
        !v.x ||
        v.x === '' ||
        v.x === '-' ||
        !v.y ||
        v.y === '' ||
        v.y === '-'
    );
    if (emptyPoints && emptyPoints.length) {
      return true;
    }

    if (Object.keys(currentStep.value).length !== testsNumber.value) {
      return true;
    }

    // Check if values are out of min and max for rating scale
    if (scaleType.value.type === TEMPLATES_SCALES.RATING_SCALE) {
      const pointsOutOfRange = currentStep.value.filter(
        v =>
          v &&
          (v.x > scaleType.value.values.max ||
            v.x < scaleType.value.values.min ||
            v.y > scaleType.value.values.max ||
            v.y < scaleType.value.values.min)
      );
      if (pointsOutOfRange && pointsOutOfRange.length) {
        return true;
      }
    }

    return false;
  };

  const onNextClick = () => {
    // Get survey structure to provide to chart
    const newStructure = templateWizardToSurvey(steps);

    setStepValue(TEMPLATES_WIZARD_STEP_KEYS.LOGIC_MAP, newStructure);

    return goToNextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          {templateStrings.FILL_IN_THE_SUCCESS_CRITERIA}
        </div>
        {templateTypeStep.value !==
          TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION && (
          <div className={styles.text}>
            {strings.PROBLEM_CONTEXT}: {problemContextStep.value}
          </div>
        )}
        <div className={styles.inputWrapper}>
          {[...Array(testsNumber.value).keys()].map((i, index) => (
            <div
              className="inputContainer"
              key={`success-criteria-${i.toString()}`}
            >
              <div className={styles.label}>
                <strong>
                  {templateStrings.POINT_INPUT_LABEL} {i + 1}:
                </strong>{' '}
                {templateStrings.POINT_ASSUMPTION_HEADER}
                {` ${pointsStep.value[index] || ''}`}
              </div>
              {scaleType.value.type === TEMPLATES_SCALES.RATING_SCALE && (
                <div className={styles.assumptionsContainer}>
                  <div className={styles.assumptionLine}>
                    {templateStrings.WE_THINK_RESPONDENTS_GIVE_AVGSCORE}
                  </div>
                  <div className={styles.assumptionLine}>
                    <input
                      className={styles.assumptionNumberInput}
                      onChange={e => {
                        const values = currentStep.value;
                        values[index] = {
                          ...values[index],
                          x: e.target.value
                        };

                        setStepValue(
                          TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA,
                          values
                        );
                      }}
                      value={
                        (currentStep.value[index] &&
                          currentStep.value[index].x) ||
                        ''
                      }
                      type="number"
                      onKeyDown={evt =>
                        evt.key === 'ArrowDown' ||
                        evt.key === 'ArrowUp' ||
                        evt.key === 'e' ||
                        evt.key === 'E' ||
                        evt.key === '.' ||
                        evt.key === '-'
                          ? evt.preventDefault()
                          : null
                      }
                      onWheel={e => e.target.blur()}
                    />
                    {styleText(
                      templateStrings.OUT_OF_TO_1.replace(
                        '{MAX}',
                        scaleType.value.values.max
                      ),
                      styles
                    )}
                  </div>
                  <div className={styles.assumptionLine}>
                    <input
                      className={styles.assumptionNumberInput}
                      onChange={e => {
                        const values = currentStep.value;
                        values[index] = {
                          ...values[index],
                          y: e.target.value
                        };

                        setStepValue(
                          TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA,
                          values
                        );
                      }}
                      value={
                        (currentStep.value[index] &&
                          currentStep.value[index].y) ||
                        ''
                      }
                      type="number"
                      onKeyDown={evt =>
                        evt.key === 'ArrowDown' ||
                        evt.key === 'ArrowUp' ||
                        evt.key === 'e' ||
                        evt.key === 'E' ||
                        evt.key === '.' ||
                        evt.key === '-'
                          ? evt.preventDefault()
                          : null
                      }
                      onWheel={e => e.target.blur()}
                    />
                    {styleText(
                      templateStrings.OUT_OF_TO_2.replace(
                        '{MAX}',
                        scaleType.value.values.max
                      ),
                      styles
                    )}
                  </div>
                </div>
              )}
              {scaleType.value.type === TEMPLATES_SCALES.MULTIPLE_CHOICE && (
                <div className={styles.assumptionsContainer}>
                  <div className={styles.assumptionLine}>
                    {styleText(mcText1[0], styles)}
                    <select
                      className={styles.assumptionDropdown}
                      onChange={e => {
                        const values = currentStep.value;
                        values[index] = {
                          ...values[index],
                          x: e.target.value
                        };

                        setStepValue(
                          TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA,
                          values
                        );
                      }}
                      value={
                        (currentStep.value[index] &&
                          currentStep.value[index].x) ||
                        ''
                      }
                    >
                      <option value={null}>-</option>
                      {scaleType.value.values.values.map((o, oIndex) => (
                        <option
                          key={`${o.toString()}-${oIndex.toString()}`}
                          value={oIndex}
                        >
                          {strings[o]}
                        </option>
                      ))}
                    </select>
                    {styleText(mcText1[1], styles)}
                  </div>
                  <div className={styles.assumptionLine}>
                    {styleText(mcText2[0], styles)}
                    <select
                      className={styles.assumptionDropdown}
                      onChange={e => {
                        const values = currentStep.value;
                        values[index] = {
                          ...values[index],
                          y: e.target.value
                        };

                        setStepValue(
                          TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA,
                          values
                        );
                      }}
                      value={
                        (currentStep.value[index] &&
                          currentStep.value[index].y) ||
                        ''
                      }
                    >
                      <option value={null}>-</option>
                      {scaleType.value.values.values.map((o, oIndex) => (
                        <option
                          key={`${o.toString()}-${oIndex.toString()}`}
                          value={oIndex}
                        >
                          {strings[o]}
                        </option>
                      ))}
                    </select>
                    {styleText(mcText2[1], styles)}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.disclaimer}>{templateStrings.DISCLAIMER}</div>
        <div className={styles.footerContainer}>
          <Footer
            onNextClick={onNextClick}
            onPreviousClick={goToPreviousStep}
            nextLabel={strings.NEXT}
            previousLabel={strings.PREVIOUS}
            disabledNext={isDisabledNext()}
          />
        </div>
      </div>
    </div>
  );
};
