import { transformAppliedFiltersBeforeDataFetch } from '../../CommunityDashboard/helpers/mainPageChartDataLoad';
import { MAIN_DATA_METRICS } from './constants';
import getProfileAttributesFiltersLength from './getProfileAttributesFiltersLength';
import removeQuotaFromFilters from './removeQuotaFromFilters';

export const getStatisticsData = (currentStatisticsData, d, value) => {
  if (currentStatisticsData === null) {
    return {
      [MAIN_DATA_METRICS.DELIVERY_POTENTIAL]: null,
      [MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS]: null,
      [MAIN_DATA_METRICS.PROFILE_ATTRIBUTES]: null
    };
  }

  const newStatisticsData = {
    ...currentStatisticsData
  };

  if (d === MAIN_DATA_METRICS.DELIVERY_POTENTIAL) {
    newStatisticsData[MAIN_DATA_METRICS.DELIVERY_POTENTIAL] = value;
  }

  if (d === MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS) {
    newStatisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS] = value;
  }

  if (d === MAIN_DATA_METRICS.PROFILE_ATTRIBUTES) {
    newStatisticsData[MAIN_DATA_METRICS.PROFILE_ATTRIBUTES] = value;
  }

  return newStatisticsData;
};

const prepareDataForChart = (data, responseRate) =>
  data.reduce(
    (dataSet, dataPoint) => ({
      values: [
        ...dataSet.values,
        {
          xValue: dataPoint.name,
          yValue: Math.floor(dataPoint.value * responseRate)
        }
      ],
      unfilteredValues: [
        ...dataSet.unfilteredValues,
        {
          xValue: dataPoint.name,
          yValue: dataPoint.value
            ? 0
            : Math.floor(dataPoint.unfilteredValue * responseRate)
        }
      ]
    }),
    { values: [], unfilteredValues: [] }
  );

export const mainChartDataFetch = {
  getDeliveryPotential: async (api, setStatisticsData, variables = {}) => {
    const res = await api.getDeliveryPotentialApi(variables);

    if (res && res.data && res.data.deliveryPotential) {
      let deliveryPotential =
        (res &&
          res.data &&
          res.data.deliveryPotential &&
          res.data.deliveryPotential.value) ||
        0;

      // Number rounding down (only include 'whole' persons)
      deliveryPotential = Math.floor(deliveryPotential);

      const responseRate =
        (res &&
          res.data &&
          res.data.deliveryPotential &&
          res.data.deliveryPotential.responseRate) ||
        0;

      const preparedData = {
        deliveryPotential,
        responseRate
      };

      setStatisticsData(currentStatisticsData =>
        getStatisticsData(
          currentStatisticsData,
          MAIN_DATA_METRICS.DELIVERY_POTENTIAL,
          preparedData
        )
      );
      return responseRate;
    }
    return 0;
  },
  getSocioDemographics: async (
    api,
    setStatisticsData,
    variables = {},
    responseRate
  ) => {
    const res = await api.getSocioDemographicsApi(variables);

    if (res && res.data && res.data.socioDemographicChecker) {
      const preparedData = {
        age: prepareDataForChart(
          res.data.socioDemographicChecker.age,
          responseRate
        ),
        gender: prepareDataForChart(
          res.data.socioDemographicChecker.gender,
          responseRate
        ),
        language: prepareDataForChart(
          res.data.socioDemographicChecker.language,
          responseRate
        )
      };

      setStatisticsData(currentStatisticsData =>
        getStatisticsData(
          currentStatisticsData,
          MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS,
          preparedData
        )
      );
    }
  },
  getProfileAttributes: async (
    api,
    setStatisticsData,
    variables = {},
    responseRate
  ) => {
    const res = await api.getProfileAttributesApi(variables);

    if (
      res &&
      res.data &&
      res.data.profileAttributesChecker &&
      res.data.profileAttributesChecker.sectors
    ) {
      const preparedData = res.data.profileAttributesChecker.sectors.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.name]: {
            ...prepareDataForChart(curr.data, responseRate),
            totalValue: curr.totalValue || 0,
            totalUnfilteredValue: curr.totalUnfilteredValue || 0
          }
        }),
        {}
      );

      setStatisticsData(currentStatisticsData =>
        getStatisticsData(
          currentStatisticsData,
          MAIN_DATA_METRICS.PROFILE_ATTRIBUTES,
          preparedData
        )
      );
    }
  }
};

export const loadAllMetrics = async (
  api,
  setStatisticsData,
  appliedFilters,
  filterRelation
) => {
  const filtersWithoutQuota = removeQuotaFromFilters(appliedFilters);
  const transformedFilters = transformAppliedFiltersBeforeDataFetch(
    filtersWithoutQuota
  );

  // Run in paralalel
  const responseRate = await mainChartDataFetch.getDeliveryPotential(
    api,
    setStatisticsData,
    {
      variables: { appliedFilters: transformedFilters, filterRelation }
    }
  );

  mainChartDataFetch.getSocioDemographics(
    api,
    setStatisticsData,
    {
      variables: { appliedFilters: transformedFilters, filterRelation }
    },
    responseRate
  );

  const profileAttributesFiltersLength = getProfileAttributesFiltersLength(
    transformedFilters || []
  );
  if (profileAttributesFiltersLength > 0) {
    mainChartDataFetch.getProfileAttributes(
      api,
      setStatisticsData,
      {
        variables: {
          appliedFilters: transformedFilters,
          filterRelation
        }
      },
      responseRate
    );
  }
};
