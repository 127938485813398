import React, { useState } from 'react';

import Icon from '../../../../../Icon';
import exclamation from '../../../../../../../assets/img/exclamation.svg';
import closeIcon from '../../../../../../../assets/img/delete-red.svg';

import styles from './GraphTitle.module.css';

const GraphTitle = props => {
  const { graphName, data, questionsHaveResults } = props;

  const [hideWarning, setHidewarning] = useState(false);

  let sampleSizeWarning = false;
  if (
    questionsHaveResults &&
    !hideWarning &&
    (data &&
      data.length &&
      data.some(d => !(d.x || d.x === 0) || !(d.y || d.y === 0)))
  ) {
    sampleSizeWarning = true;
  }

  return (
    <div
      className={`${styles.container} ${
        sampleSizeWarning ? styles.containerWithWarning : ''
      }`}
    >
      <div className={styles.titleContainer}>
        <div className={styles.icon}>
          <Icon type="merged-graph" />
        </div>
        <div className={styles.graphName}>{graphName}</div>
      </div>
      {sampleSizeWarning ? (
        <div className={styles.sampleErrorMessage}>
          <img className={styles.warningIcon} src={exclamation} alt="Warning" />
          One or more points have sample size N = 0 due to active filters. As a
          result, these data points are not visible on the framework.
          <img
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
            onClick={() => setHidewarning(true)}
            role="presentation"
          />
        </div>
      ) : null}
    </div>
  );
};

export default GraphTitle;
