import React, { useState, useEffect } from 'react';

import useLazyQuery from '../../../../../surveys/hooks/useLazyQuery';
import Loader from '../../../../../campaigns/components/Loader/Loader';

import styles from './SurveyFiltersDetails.module.css';
import { GET_SURVEY_FILTERS_DETAILS } from '../../../../../graphql/Survey';

export default ({ survey }) => {
  const [loading, setLoading] = useState(false);
  const [surveyFiltersDetails, setSurveyFiltersDetails] = useState(null);

  const getSurveyFiltersDetails = useLazyQuery(GET_SURVEY_FILTERS_DETAILS, {
    fetchPolicy: 'no-cache'
  });

  const loadSurveyFilterDetails = async () => {
    setLoading(true);
    const data = await getSurveyFiltersDetails({ variables: { survey } });

    if (
      data &&
      data.data &&
      data.data.getSurveyFiltersDetails &&
      data.data.getSurveyFiltersDetails.filters &&
      data.data.getSurveyFiltersDetails.filters.length
    ) {
      setSurveyFiltersDetails(data.data.getSurveyFiltersDetails.filters);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSurveyFilterDetails();
  }, []);

  useEffect(
    () => {
      loadSurveyFilterDetails();
    },
    [survey]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.sectionDetailsTitle}>
        Filters included in the survey
      </div>
      <div className={styles.sectionDetails}>
        {surveyFiltersDetails && surveyFiltersDetails.length
          ? surveyFiltersDetails.map(sD => (
              <>
                <div className={styles.sectionItemTitle}>{sD.name}</div>
                <div className={styles.sectionItemValues}>
                  {sD.values && sD.values.length ? sD.values.join() : ''}
                </div>
              </>
            ))
          : null}
      </div>
    </>
  );
};
