export default (setFunction, i) => {
  setFunction(tooltips =>
    tooltips.reduce((tips, t) => {
      if (t.index !== i) {
        return [...tips, t];
      }
      return tips;
    }, [])
  );
};
