import React from 'react';

import ChartMetric from '../ChartMetric/ChartMetric';
import Loader from '../../../../../../../CommunityDashboard/components/CommunityDashboardPage/components/Loader/Loader';

import styles from './ProfileAttributesMetrics.module.css';

export default ({
  profileAttributesData,
  profileAttributesFiltersLength,
  profileAttributesDeltas,
  getDeltaValues,
  minimumQuotaEnabled
}) => {
  if (!profileAttributesData && profileAttributesFiltersLength > 0) {
    return (
      <div className={styles.chartContainerBackground}>
        <div className={styles.loadingContainer}>
          <Loader fixed={false} />
        </div>
      </div>
    );
  }

  return profileAttributesData
    ? Object.entries(profileAttributesData).map(([title, chartData]) => (
        <ChartMetric
          key={title}
          title={title}
          chartData={{
            ...chartData,
            deltaValues: getDeltaValues(title, profileAttributesDeltas)
          }}
          metricWidth={
            chartData.values && chartData.values.length <= 5 ? 49 : 100
          }
          isSocioDemoChart={false}
          minimumQuotaEnabled={minimumQuotaEnabled}
        />
      ))
    : null;
};
