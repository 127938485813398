import React from 'react';

import styles from './Loader.module.css';

const Loader = ({ size = 'normal' }) => (
  <div className={styles.content}>
    <div
      className={`${styles.loadingAnimation} ${
        size === 'normal' ? styles.normal : null
      }  ${size === 'small' ? styles.small : null}`}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
