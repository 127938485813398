import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import style from './TimelineChart.module.css';

export default props => {
  const {
    tooltipContainerRef,
    parent,
    data,
    isPercentage,
    dateFormat = '%d/%m',
    tickDaysDifference = 4
  } = props;
  const parseDate = d3.timeParse('%Y-%m-%d');

  const [svg, setSvg] = useState(null);

  useEffect(
    () => {
      if (parent && parent.current) {
        drawChart(svg, props);
      }
    },
    [svg, data]
  );

  useEffect(
    () => {
      drawChart(svg, props);
    },
    [parent.current]
  );

  const drawChart = (svgRef, chartProps) => {
    const parentContainer = parent.current;
    const tooltipContainer = tooltipContainerRef.current;

    if (
      parentContainer &&
      tooltipContainer &&
      parentContainer.offsetWidth &&
      data &&
      data.length
    ) {
      const { height } = chartProps;

      const margins = {
        top: 10,
        right: 15,
        rightPadding: 5,
        bottom: 30,
        left: 50
      };

      const width = parentContainer.offsetWidth;

      const maxYTicks = data.length > 5 ? 5 : data.length;

      const svgContainer = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      svgContainer.selectAll('*').remove();

      const chartSvg = svgContainer
        .append('g')
        .attr('width', width - margins.left - margins.right)
        .attr('height', height - margins.top - margins.bottom)
        .attr('transform', `translate(${margins.left}, ${margins.top})`);

      const yScaleMin = d3.min(data, d => d.yValue);
      const yScaleMax = d3.max(data, d => d.yValue);
      const yRange = yScaleMax - yScaleMin;
      const extensionRange = 5;
      const yScaleMinExtended = yScaleMin - yRange / extensionRange;
      const yScaleMaxExtended = yScaleMax + yRange / extensionRange;

      // Scales
      const yScale = d3
        .scaleLinear()
        .domain([yScaleMinExtended, yScaleMaxExtended])
        .range([height - margins.top - margins.bottom, 0]);

      const xScale = d3
        .scaleTime()
        .domain(d3.extent(data, d => parseDate(d.xValue)))
        .range([
          0,
          width - margins.left - margins.right - margins.rightPadding
        ]);

      // Determine what values to show
      const minLabelResolution = 7;
      // X axis
      const xValues = data.map(d => parseDate(d.xValue));

      const xValuesDistant = xValues.filter((value, i) => {
        if (i > 0) {
          const diffDays = parseInt(
            (value - xValues[i - 1]) / (1000 * 60 * 60 * 24),
            10
          );
          if (diffDays < tickDaysDifference) return false;
        }
        return true;
      });

      // Y axis
      const yValues = [];

      data.forEach((d, i) => {
        if (i === 0) {
          yValues.push(d.yValue);
        }

        const isValueInAnyForbiddenRange = yValues.some(v => {
          const maxV = v + yRange / minLabelResolution;
          const minV = v - yRange / minLabelResolution;

          if (d.yValue <= maxV && d.yValue >= minV) {
            return true;
          }

          return false;
        });

        if (!isValueInAnyForbiddenRange) {
          yValues.push(d.yValue);
        }
      });

      // Gridlines
      const gridGroup = chartSvg
        .append('g')
        .attr('class', style.gridLines)
        .attr('transform', `translate(0, 0)`);

      // Y gridlines
      const yGridlines = d3
        .axisLeft()
        .tickFormat('')
        .tickSize(-width)
        .ticks(maxYTicks)
        .tickValues(yValues)
        .scale(yScale);
      gridGroup.append('g').call(yGridlines);

      // X gridlines
      const xGridlines = d3
        .axisBottom()
        .tickFormat('')
        .tickSize(height - margins.top - margins.bottom)
        .ticks(data.length)
        .tickValues(xValues)
        .scale(xScale);
      gridGroup.append('g').call(xGridlines);

      // Y Axis
      chartSvg
        .append('g')
        .attr('class', style.yAxis)
        .call(
          d3
            .axisLeft(yScale)
            .ticks(maxYTicks)
            .tickSizeOuter(0)
            .tickFormat(d => (isPercentage ? `${d}%` : d))
            .tickValues(yValues)
        );

      // X Axis
      chartSvg
        .append('g')
        .call(
          d3
            .axisBottom(xScale)
            .tickSizeOuter(0)
            .ticks(data.length)
            .tickFormat(d3.timeFormat(dateFormat))
            .tickValues(xValuesDistant)
        )
        .attr('class', style.xAxis)
        .attr(
          'transform',
          `translate(0, ${height - margins.bottom - margins.top})`
        );

      // Main line
      const line = d3
        .line()
        .x(d => xScale(parseDate(d.xValue)))
        .y(d => yScale(d.yValue));

      const chartValueSvg = chartSvg
        .append('g')
        .attr('class', style.valueCircle);

      chartValueSvg
        .append('path')
        .datum(data)
        .attr('class', style.mainLine)
        .attr('d', line);

      const tooltipDiv = d3.select(tooltipContainer).style('display', 'none');

      // Value dots
      /* eslint-disable */
      chartValueSvg
        .selectAll('.dotValue')
        .data(data)
        .enter()
        .append('circle')
        .attr('r', 4)
        .attr('cx', d => xScale(parseDate(d.xValue)))
        .attr('cy', d => yScale(d.yValue))
        .style('fill', '#a57bfa')
        .on('mousemove', function(d) {
          d3.select(this).style('fill', '#5200f1');
          tooltipDiv.style('display', 'block');

          tooltipDiv
            .html(`<strong>${d.yValue}${isPercentage ? '%' : ''}</strong>`)
            .style('left', `${d3.event.pageX + 5}px`)
            .style('top', `${d3.event.pageY - window.scrollY - 20}px`)
            .style('min-width', 'auto');
        })
        .on('mouseout', function() {
          d3.select(this).style('fill', '#a57bfa');
          tooltipDiv.style('display', 'none');
        });
      /* eslint-enable */
    }

    return null;
  };

  return (
    <div>
      <svg ref={elem => setSvg(elem)} />
    </div>
  );
};
