import React, { useEffect } from 'react';

import Dropdown from './Dropdown/Dropdown';
import ColorPicker from '../../../../../../../../../../../surveys/components/SurveyBuilderPage/SurveyOverview/components/ColorPicker/ColorPicker';

import closeIcon from '../../../../../../../../../../../assets/img/delete.svg';

import styles from './Feature.module.css';

export default ({
  feature,
  availableQuestions,
  removeFeature,
  updateFeatureField,
  xQuestionName,
  yQuestionName,
  placeholders,
  questionsTypeLabel,
  setFeaturesScrollingDisabled,
  scrollFeaturesContainerToTheBottom,
  newFeatureAdded,
  dataPointColors,
  editSingleDataPoint
}) => {
  useEffect(() => {
    if (newFeatureAdded) {
      scrollFeaturesContainerToTheBottom();
    }
  }, []);

  let isMatrixQuestionFeature = false;
  if (feature.choiceId) {
    isMatrixQuestionFeature = true;
  }

  return (
    <div className={styles.feature}>
      <div className={styles.background} />
      <div className={styles.featureFields}>
        <div
          style={
            editSingleDataPoint
              ? { display: 'flex', maxWidth: 'calc(100% - 5px)' }
              : {}
          }
        >
          {editSingleDataPoint && (
            <div className={styles.colorPickersContainer}>
              <div className={styles.colorPicker}>
                <ColorPicker
                  colorCode={
                    feature.colorCode || dataPointColors[feature.featureIndex]
                  }
                  onChange={colorCode =>
                    updateFeatureField('colorCode', colorCode)
                  }
                  style={{
                    marginRight: 0,
                    borderColor: '#dfe0e4',
                    boxSizing: 'border-box'
                  }}
                  fixedPosition
                />
              </div>
            </div>
          )}
          <input
            className={styles.description}
            value={feature.description}
            onChange={e => {
              updateFeatureField('description', e.target.value);
            }}
            placeholder={placeholders.description}
          />
        </div>
        {!isMatrixQuestionFeature && (
          <div className={styles.featureFieldsDropdowns}>
            <Dropdown
              options={availableQuestions}
              selectedOption={feature[xQuestionName]}
              placeholder={placeholders.xAxisDropdown}
              onSelectCompleted={option =>
                updateFeatureField(xQuestionName, option)
              }
              questionsTypeLabel={questionsTypeLabel}
              setFeaturesScrollingDisabled={setFeaturesScrollingDisabled}
              leftDropdown
            />
            <Dropdown
              options={availableQuestions}
              selectedOption={feature[yQuestionName]}
              placeholder={placeholders.yAxisDropdown}
              onSelectCompleted={option =>
                updateFeatureField(yQuestionName, option)
              }
              questionsTypeLabel={questionsTypeLabel}
              setFeaturesScrollingDisabled={setFeaturesScrollingDisabled}
            />
          </div>
        )}
      </div>
      {!editSingleDataPoint && (
        <img
          className={styles.close}
          src={closeIcon}
          alt="Close icon"
          onClick={() => removeFeature()}
          role="presentation"
        />
      )}
    </div>
  );
};
