import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { REQUEST_RESET_PASSWORD } from '../../../graphql/Auth';
import styles from './RequestResetPasswordPage.module.css';

import LoaderAnimation from '../../../base/components/LoaderAnimation/LoaderAnimation';

import herdyWorking from '../../../assets/img/herdy-working.svg';
import bottomBlobGreen from '../../../assets/img/registration-bottom-blob-green.svg';

const RequestResetPasswordPage = props => {
  const { isAuthenticated, strings } = props;

  const emptyFields = { email: '' };

  const [error, setError] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [fields, setFields] = useState(emptyFields);
  const [requestResetPassword, { loading }] = useMutation(
    REQUEST_RESET_PASSWORD,
    {
      onCompleted: () => setIsCompleted(true)
    }
  );

  const onRequestResetPassword = () => {
    setError(null);

    if (!fields.email || fields.email === '') {
      setError(strings.ALL_FIELDS_REQUIRED);
    } else {
      requestResetPassword({ variables: fields }).catch(registerError => {
        if (registerError) {
          setError(strings.EMAIL_NOT_FOUND);
        }
      });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isCompleted) {
    return (
      <>
        <div className={`${styles.mainContainer} ${styles.completed}`}>
          <div className={styles.form}>
            <h2 className={styles.title}>
              {strings.RESET_PASSWORD_EMAIL_SENT}
            </h2>
            <form>
              <div className={styles.label}>
                {strings.PASSWORD_RESET_LINK_HAS_BEEN_SENT}
              </div>{' '}
            </form>
          </div>
        </div>
        <div className={styles.bottomBlobWrapper}>
          <img
            className={styles.bottomBlob}
            src={bottomBlobGreen}
            alt="Blue bottom blob"
          />
          <img
            className={styles.herdyWorking}
            src={herdyWorking}
            alt="Herdy working"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.form}>
          <h2 className={styles.title}>{strings.RESET}</h2>
          <form>
            <div className={`roboto-light-font ${styles.label}`}>
              {strings.EMAIL}
            </div>
            <input
              className={styles.input}
              type="text"
              value={fields.email}
              onChange={e =>
                setFields({ ...fields, ...{ email: e.currentTarget.value } })
              }
              autoComplete="email"
            />

            <div className={styles.buttonContainer}>
              {loading ? (
                <LoaderAnimation />
              ) : (
                <div>
                  {error ? <div className={styles.error}>{error}</div> : null}
                  <div
                    className={`${styles.button} login-page-submit-button`}
                    onClick={() => onRequestResetPassword()}
                    role="presentation"
                  >
                    {strings.RESET_PASSWORD}
                  </div>
                  <div className={styles.register}>
                    {strings.NO_ACCOUNT_CREATED}{' '}
                    <span
                      className={styles.greenLink}
                      role="presentation"
                      onClick={() => props.history.push(`/register`)}
                    >
                      {strings.REGISTER}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className={styles.bottomBlobWrapper}>
        <img
          className={styles.bottomBlob}
          src={bottomBlobGreen}
          alt="Blue bottom blob"
        />
        <img
          className={styles.herdyWorking}
          src={herdyWorking}
          alt="Herdy working"
        />
      </div>
    </>
  );
};

export default RequestResetPasswordPage;
