export default d => {
  let date = d;
  if (!(date instanceof Date)) {
    date = new Date();
  }
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months start at zero
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
