import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import styles from './AuthRoute.module.css';

const AuthRoute = props => {
  const { component: Component, location, userTokens, ...rest } = props;

  useEffect(() => {
    document.documentElement.style.backgroundColor = '#2e2f32';
    document.getElementsByTagName('body')[0].style.backgroundColor = '#2e2f32';
  }, []);

  const pageContent = (renderProps, strings) => (
    <Component {...renderProps} strings={strings} />
  );

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <Route {...rest} render={renderProps => pageContent(renderProps)} />
      </div>
    </div>
  );
};

export default AuthRoute;
