import PptxGenJS from 'pptxgenjs';
import { getAverageRating } from '../components/CampaignResults/Blocks/Question/Questions/Slider/Slider';

import messageQuestionIcon from '../../surveys/assets/Text.svg';
import openQuestionIcon from '../../surveys/assets/OpenQuestion.svg';
import mcQuestionIcon from '../../surveys/assets/MultipleChoice.svg';
import ratingQuestionIcon from '../../surveys/assets/AnswerRanking.svg';
import videoQuestionIcon from '../../surveys/assets/PlayVideo.svg';
import cameraQuestionIcon from '../../surveys/assets/TakePicture.svg';
import videoCameraQuestionIcon from '../../surveys/assets/RecordVideo.svg';
import iFrameQuestion from '../../surveys/assets/Website.svg';

import checkboxChecked from '../../assets/img/checkbox-checked.png';
import checkboxUnchecked from '../../assets/img/checkbox-unchecked.png';

const processDropOutAnswers = (block, activeFilters, answer, index) => {
  let dropOutCount = 0;
  let dropOutState = false;
  if (
    (block.type === 'Checkboxes' ||
      (block.type === 'Multiple Choice Question' &&
        block.selectAtMost &&
        block.selectAtMost > 1)) &&
    block.end === 'Failure'
  ) {
    dropOutState = true;
    block.dropOutResults.forEach(result => {
      if (result.answers.indexOf(index) > -1) {
        dropOutCount += 1;
      }
    });
  }
  if (
    block.type === 'Multiple Choice Question' &&
    (!block.selectAtMost || block.selectAtMost === 1) &&
    answer.end === 'Failure'
  ) {
    dropOutState = true;
    block.dropOutResults.forEach(result => {
      if (result.answer === index) {
        dropOutCount += 1;
      }
    });
  }
  if (activeFilters && activeFilters.length) {
    dropOutCount = 0;
  }
  return {
    x: index,
    question: answer.answer,
    y: dropOutCount,
    image_filename: answer.image_filename,
    dropOut: dropOutState
  };
};

let totalAnswers;
const processAnswers = (block, activeSorting) => {
  const { answers, results, resultStats } = block;
  totalAnswers = 0;

  const detailedAnswers = answers.map((answer, index) => {
    const answerResponse = resultStats.values.filter(
      result => result.x === index
    );
    if (answerResponse.length) {
      totalAnswers += answerResponse[0].y;
      return {
        x: answerResponse[0].x,
        question: answers[answerResponse[0].x].answer,
        y: answerResponse[0].y,
        image_filename: answers[answerResponse[0].x].image_filename,
        dropOut: false
      };
    }
    if (block.dropOutResults) {
      if (block.dropOutResults.length) {
        return processDropOutAnswers(block, null, answer, index);
      }
    }
    return {
      x: index,
      question: answer.answer,
      y: 0,
      image_filename: answer.image_filename,
      dropOut: false
    };
  });

  const processedAnswers = detailedAnswers.map(answer => {
    const answerWithPercentage = answer;
    if (answerWithPercentage.dropOut === true) {
      answerWithPercentage.percentage = (
        (answer.y / (results.length + block.dropOutResults.length)) *
        100
      ).toFixed(2);
      return answerWithPercentage;
    }
    if (
      block.type === 'Checkboxes' ||
      (block.type === 'Multiple Choice Question' &&
        block.selectAtMost &&
        block.selectAtMost > 1)
    ) {
      answerWithPercentage.percentage =
        block && results && results.length
          ? ((answer.y / results.length) * 100).toFixed(2)
          : null;
    }
    if (
      block.type === 'Multiple Choice Question' &&
      (!block.selectAtMost || block.selectAtMost === 1)
    ) {
      answerWithPercentage.percentage = (
        (answer.y / totalAnswers) *
        100
      ).toFixed(2);
    }
    return answerWithPercentage;
  });

  if (activeSorting.length > 0) {
    const sorting = activeSorting.find(s => s.sortingName === block.id);
    if (sorting) {
      switch (sorting.sortingOrder) {
        case 'ASC':
          return processedAnswers.sort((a, b) => a.y - b.y);
        case 'DESC':
          return processedAnswers.sort((a, b) => a.y - b.y).reverse();
        default:
          return processedAnswers;
      }
    }
  }

  return processedAnswers;
};

export default ({ results, activeSorting }) => {
  if (results.length > 0) {
    const pptx = new PptxGenJS();
    pptx.layout = 'LAYOUT_WIDE';
    const headerY = '5%';

    pptx.defineSlideMaster({
      title: 'BASIC',
      bkgd: 'FFFFFF',
      objects: [
        { rect: { x: '5%', y: '7%', w: '90%', h: '87%', fill: 'FFFFFF' } },
        {
          text: {
            text: 'Powered by BUFFL',
            options: { x: '40%', y: 7.0, w: '20%', h: 0, align: 'center' }
          }
        }
      ],
      slideNumber: { x: '95%', y: 7 }
    });

    results.forEach(r => {
      let questionIcon;

      switch (r.type) {
        case 'Message':
          questionIcon = messageQuestionIcon;
          break;
        case 'Slider':
          questionIcon = ratingQuestionIcon;
          break;
        case 'Open Question':
          questionIcon = openQuestionIcon;
          break;
        case 'Multiple Choice Question':
          questionIcon = mcQuestionIcon;
          break;
        case 'Camera':
          questionIcon = cameraQuestionIcon;
          break;
        case 'Camera (Video)':
          questionIcon = videoCameraQuestionIcon;
          break;
        case 'Video':
          questionIcon = videoQuestionIcon;
          break;
        case 'Iframe':
          questionIcon = iFrameQuestion;
          break;
        default:
          questionIcon = mcQuestionIcon;
      }

      const slide = pptx.addSlide({ masterName: 'BASIC' });
      let question;
      try {
        question = JSON.parse(r.question)
          .blocks.map(block => (!block.text.trim() && '\n') || block.text)
          .join('\n');
      } catch (error) {
        question = r.question ? r.question.replace(/\n/g, ' ') : '';
      }

      question = question.replace(/\n\s*\n/g, '\n\n');

      const emptyLines = question.match(/^[ \t]*$/gm || []);

      let verticalOffset =
        question.length >= 100 ? question.length / 100 / 3 : 0;

      if (emptyLines && emptyLines.length)
        verticalOffset += emptyLines.length / 2.5;

      // Question Number
      slide.addText(r.formattedGlobalIndex || r.globalIndex, {
        x: '5%',
        y: headerY,
        fontSize: 18
      });

      // Question Icon
      slide.addImage({
        path: questionIcon,
        x: '8%',
        y: '5.5%',
        w: 0.2,
        h: 0.2
      });

      // Question Text
      slide.addText(question, {
        inset: 1.5,
        y: '3%',
        fontSize: 18,
        w: '100%',
        valign: 'top'
      });

      // Respondents info
      if (r.type !== 'Message') {
        slide.addText(
          `${r.results.length} ${
            r.results.length === 1 ? 'respondent' : 'respondents'
          } answered this question`,
          {
            inset: 1.5,
            y: 0.75 + verticalOffset,
            fontSize: 14
          }
        );
      }

      // Chart processing
      if (r.type === 'Multiple Choice Question' || r.type === 'Checkboxes') {
        const firstColumnXPos = 1.5;
        const secondColumnXPos = 5.5;
        const firstRowYPos = 1.2;
        const secondRowYPos = 1.6;
        const thirdRowYPos = 2;
        const charIconMarginTop = 0.07;
        const charIconMarginRight = 0.2;
        const iconSize = 0.2;
        const textWidth = 3;
        const fontSize = 14;

        // Ranking characteristic
        slide.addImage({
          path: r.show_selected_answers_order
            ? checkboxChecked
            : checkboxUnchecked,
          x: firstColumnXPos,
          y: firstRowYPos + verticalOffset,
          w: iconSize,
          h: iconSize
        });
        slide.addText('Ranking Question', {
          x: firstColumnXPos + charIconMarginRight,
          y: firstRowYPos - charIconMarginTop + verticalOffset,
          fontSize,
          w: textWidth,
          valign: 'top'
        });

        // Randomized Answer Order characteristic
        slide.addImage({
          path: r.random ? checkboxChecked : checkboxUnchecked,
          x: secondColumnXPos,
          y: firstRowYPos + verticalOffset,
          w: iconSize,
          h: iconSize
        });
        slide.addText('Randomized Answer Order', {
          x: secondColumnXPos + charIconMarginRight,
          y: firstRowYPos - charIconMarginTop + verticalOffset,
          fontSize,
          w: textWidth,
          valign: 'top'
        });

        // SelectAtLeast characteristic
        slide.addImage({
          path: r.selectAtMost > 1 ? checkboxChecked : checkboxUnchecked,
          x: firstColumnXPos,
          y: secondRowYPos + verticalOffset,
          w: iconSize,
          h: iconSize
        });
        slide.addText(
          `Select At Least ${r.selectAtLeast} Answer${
            r.selectAtLeast > 1 ? 's' : ''
          }`,
          {
            x: firstColumnXPos + charIconMarginRight,
            y: secondRowYPos - charIconMarginTop + verticalOffset,
            fontSize,
            w: textWidth,
            valign: 'top'
          }
        );

        // SelectAtMost characteristic
        slide.addImage({
          path: r.selectAtMost > 1 ? checkboxChecked : checkboxUnchecked,
          x: secondColumnXPos,
          y: secondRowYPos + verticalOffset,
          w: iconSize,
          h: iconSize
        });
        slide.addText(
          `Select At Most ${r.selectAtMost} Answer${
            r.selectAtMost > 1 ? 's' : ''
          }`,
          {
            x: secondColumnXPos + charIconMarginRight,
            y: secondRowYPos - charIconMarginTop + verticalOffset,
            fontSize,
            w: textWidth,
            valign: 'top'
          }
        );

        // Allow Other characteristic
        slide.addImage({
          path: r.allowOther ? checkboxChecked : checkboxUnchecked,
          x: firstColumnXPos,
          y: thirdRowYPos + verticalOffset,
          w: iconSize,
          h: iconSize
        });
        slide.addText('Allow Other', {
          x: firstColumnXPos + charIconMarginRight,
          y: thirdRowYPos - charIconMarginTop + verticalOffset,
          fontSize,
          w: textWidth,
          valign: 'top'
        });

        const processedAnswers = processAnswers(r, activeSorting).reverse();

        const values = processedAnswers.map(a => a.percentage);

        const dataChartAreaLine = [
          {
            name: 'Answers',
            labels: processedAnswers.map(a => a.question),
            values
          }
        ];

        const mcQuestionColors = [];
        processedAnswers.forEach(answer => {
          if (answer.dropOut) {
            mcQuestionColors.push('FC035D');
          } else {
            mcQuestionColors.push('5200F1');
          }
        });

        const mcQuestionChartOptions = {
          x: 1,
          y: 2.43 + verticalOffset,
          w: 11,
          h: 4.32 - verticalOffset,
          barDir: 'bar',
          barGrouping: 'clustered',
          showValue: true,
          valAxisMaxVal: 100,
          dataLabelColor: '000000',
          dataLabelPosition: 'outEnd',
          dataLabelFontSize: 14,
          dataLabelFormatCode: '#0.00##',
          lineDataSymbolLineColor: 'FFFFFF',
          valueBarColors: true,
          chartColors: mcQuestionColors,
          valGridLine: { style: 'none' },
          valAxisLineShow: false,
          valAxisHidden: true,
          catAxisLineShow: false,
          holeSize: 50
        };

        if (verticalOffset >= 3 || values.length > 15) {
          const separatedSlide = pptx.addSlide({ masterName: 'BASIC' });

          mcQuestionChartOptions.h = 6.67;
          mcQuestionChartOptions.y = 0.33;
          separatedSlide.addChart(
            pptx.ChartType.bar,
            dataChartAreaLine,
            mcQuestionChartOptions
          );
        } else {
          slide.addChart(
            pptx.ChartType.bar,
            dataChartAreaLine,
            mcQuestionChartOptions
          );
        }
      }

      if (r.type === 'Slider') {
        const data = r.resultStats.values.sort((a, b) => a.x - b.x);
        const averageRating = getAverageRating(
          data.filter((d, idx) => !(r && r.allowSkip && idx === 0))
        ).toFixed(2);

        const labels = [];
        const values = [];

        if (r.allowSkip) labels.push('N/A');
        for (let idx = r.minValue; idx <= r.maxValue; idx += 1) {
          labels.push(String(idx));
          values.push(0);
        }

        r.resultStats.values.forEach(e => {
          const xIndex = labels.indexOf(e.x ? String(e.x) : 'N/A');
          values[xIndex] += Number(e.y);
        });

        const dataChartAreaLine = [
          {
            name: 'Answers',
            labels,
            values
          }
        ];

        const ratingQuestionChartOptions = {
          x: 1,
          y: 3,
          w: 11,
          h: 4.0,
          barGrouping: 'clustered',
          showValue: true,
          dataLabelColor: 'FFFFFF',
          lineDataSymbolLineColor: 'FFFFFF',
          valueBarColors: true,
          chartColors: ['5200F1'],
          valGridLine: { style: 'none' },
          valAxisMajorUnit: 1.0,
          barDir: 'col',
          barGapWidthPct: 10,
          valAxisMaxVal: 10,
          lineDash: 'solid'
        };

        ratingQuestionChartOptions.valAxisMaxVal = Math.max(...values);
        if (verticalOffset >= 3) {
          const separatedSlide = pptx.addSlide({ masterName: 'BASIC' });

          separatedSlide.addText(`Average rating: ${averageRating}`, {
            x: 1,
            y: 0.5,
            fontSize: 14
          });

          ratingQuestionChartOptions.h = 6;
          ratingQuestionChartOptions.y = 1;
          separatedSlide.addChart(
            pptx.ChartType.bar,
            dataChartAreaLine,
            ratingQuestionChartOptions
          );
        } else {
          slide.addText(`Average rating: ${averageRating}`, {
            inset: 1.5,
            y: 1.25 + verticalOffset,
            fontSize: 14
          });

          slide.addChart(
            pptx.ChartType.bar,
            dataChartAreaLine,
            ratingQuestionChartOptions
          );
        }
      }
    });

    pptx.writeFile('BUFFL-presentation.pptx');
  }
};
