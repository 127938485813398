import React from 'react';

import styles from './RecordAction.module.css';

export default ({ label, icon, onClick }) => (
  <div className={styles.container} role="presentation" onClick={onClick}>
    <img src={icon} alt={label} />
    {label}
  </div>
);
