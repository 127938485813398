import React from 'react';
import RecordAction from '../RecordAction/RecordAction';

import { recordActionPopups } from '../../helpers/constants';

import startIcon from '../../../../assets/img/star.svg';
import amDelete from '../../../../assets/img/accountmanagement/am-delete.svg';

import styles from './AccountManagementMember.module.css';

export default ({ record, setActivePopup, actionsAllowed }) => (
  <tr>
    <td className={styles.rowContainer}>
      {record.isManager ? (
        <img
          src={startIcon}
          alt="Starred user"
          className={styles.starredUser}
        />
      ) : null}
      <span className={styles.nameContainer}>{record.name}</span>
    </td>
    <td className={styles.rowContainer}>
      <div className={styles.actionButtonsContainer}>
        {actionsAllowed ? (
          <RecordAction
            label="Delete"
            icon={amDelete}
            onClick={() => {
              setActivePopup({ type: recordActionPopups.DELETE, record });
            }}
          />
        ) : null}
      </div>
    </td>
  </tr>
);
