import React, { useEffect } from 'react';
import InputRange from 'react-input-range';
import styles from '../../CampaignQuestionsPage.module.css';
import './Slider.css';
import 'react-input-range/lib/css/index.css';

export default props => {
  const {
    block,
    setValid,
    values,
    setValues,
    skipQuestion,
    setSkipQuestion,
    naOptionSelected,
    setNaOptionSelected,
    customThemeBaseColor
  } = props;

  const checkIsValid = (value, naOption) => {
    if (
      (value !== null && value <= block.maxValue && value >= block.minValue) ||
      naOption
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const onChangeValue = value => {
    checkIsValid(value, naOptionSelected);
    setValues(value);
  };

  useEffect(
    () => {
      if (!values && values !== 0) {
        checkIsValid(values, naOptionSelected);
      }
    },
    [block]
  );

  useEffect(() => {
    if (document && document.documentElement && customThemeBaseColor) {
      document.documentElement.style.setProperty(
        '--slider-circle-color',
        customThemeBaseColor
      );
      document.documentElement.style.setProperty(
        '--mc-question-answer-background-color',
        `${customThemeBaseColor}10`
      );
      document.documentElement.style.setProperty(
        '--mc-question-answer-background-color-active',
        `${customThemeBaseColor}60`
      );
    }
  }, []);

  return (
    <>
      <div style={{ height: 60 }}>
        <div
          className={`${styles.questionContainer} ${
            styles.inputRangeSliderContainer
          } user-campaign-questions-slider-container ${
            skipQuestion ? 'input-range__disabled' : null
          }`}
        >
          <InputRange
            step={1}
            maxValue={block.maxValue}
            minValue={block.minValue}
            value={
              values === null || values.length === 0 ? block.minValue : values
            }
            onChange={value => {
              onChangeValue(value);
            }}
            disabled={skipQuestion}
          />
        </div>
      </div>
      {block.allowSkip && (
        <div
          className={`${styles.questionContainer} ${
            styles.sliderContainer
          } user-answers-question-container`}
        >
          <div className={styles.sliderOrContainer}>
            <div className={styles.sliderOrContainerDivider} />
            <span>OR</span>
            <div className={styles.sliderOrContainerDivider} />
          </div>
          <div
            className={
              skipQuestion
                ? `${styles.answer} ${styles.sliderAnswer} ${
                    styles.active
                  } active-answer`
                : `${styles.answer} ${styles.sliderAnswer}`
            }
            role="presentation"
            onClick={() => {
              setNaOptionSelected(!naOptionSelected);
              if (values) {
                setValues(null);
              }
              setSkipQuestion(prev => {
                if (!prev) {
                  setValid(!prev);
                } else {
                  checkIsValid(values, !naOptionSelected);
                }
                return !prev;
              });
            }}
          >
            This doesn&lsquo;t apply to me
          </div>
        </div>
      )}
    </>
  );
};
