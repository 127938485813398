import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import smileyImage from '../../../../assets/img/user_result_icon.svg';
import smileyImageGrey from '../../../../assets/img/user_result_icon_grey.svg';

const Bar = props => {
  const {
    data,
    onChartClick,
    filterCollectDataGroup,
    unfilteredData,
    onSetFiltersRelation,
    unfilteredAgeGroupsData,
    displayRatingPercentage,
    numberOfResults,
    unfilteredNumberOfResults,
    displayPercentageYLabel,
    block,
    isAgeGroupsChart,
    activeFilteringOnThisChart
  } = props;
  const [svg, setSvg] = useState(null);

  // Did mount
  useEffect(() => {
    drawChart(svg, props);
  }, []);

  // Did update
  useEffect(
    () => {
      drawChart(svg, props);
    },
    [svg, data, displayRatingPercentage, unfilteredAgeGroupsData]
  );

  const drawChart = (svgRef, chartProps) => {
    const chartData = chartProps.data;
    if (chartProps.parent && chartProps.parent.offsetWidth) {
      const {
        height,
        start,
        end,
        xLabel,
        yLabel,
        marginLeft,
        marginRight,
        showCounts
      } = chartProps;
      const width =
        chartProps.parent.offsetWidth -
        (marginLeft ? parseInt(marginLeft, 10) : 0) -
        (marginRight ? parseInt(marginRight, 10) : 0);

      const isStartOrEnd = (start || start === 0) && (end || end === 0);
      const shouldShowCounts = end - start <= 20;

      const margin = {
        top: 20,
        right: 20,
        bottom: 30,
        left: 40
      };

      const labelMargins = {
        yY: 20,
        ydY: 30,
        xY: 20,
        xX: margin.right + margin.left - 10
      };

      let barWidth = 0;
      let xAsisTransfoormX = 0;
      if (isStartOrEnd) {
        barWidth =
          (width - margin.left - margin.right - 5 * (end - start + 1)) /
          (end - start + 1);
        xAsisTransfoormX = barWidth / 2;
      }
      const chartSvg = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      chartSvg.selectAll('*').remove();

      const chart = chartSvg
        .append('g')
        .attr('width', width - margin.left - margin.right)
        .attr('height', height - margin.top - margin.bottom)
        .attr('class', 'results-bar-container')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      let y;
      if (displayRatingPercentage) {
        y = d3.scaleLinear().range([height - margin.top - margin.bottom, 0]);

        if (displayPercentageYLabel && unfilteredAgeGroupsData) {
          const maxY = Math.max(
            d3.max(
              unfilteredAgeGroupsData,
              d => (100 * d.y) / unfilteredNumberOfResults
            ),
            d3.max(data, d => (100 * d.y) / numberOfResults)
          );
          y.domain([0, !maxY || maxY > 100 ? 100 : maxY]);
        } else {
          y.domain([
            0,
            Math.max(
              d3.max(data, d => (100 * d.y) / numberOfResults),
              unfilteredData
                ? d3.max(
                    unfilteredData,
                    d => (100 * d.y) / unfilteredNumberOfResults
                  )
                : 0
            )
          ]);
        }
      } else if (!unfilteredData && !unfilteredAgeGroupsData) {
        y = d3.scaleLinear().range([height - margin.top - margin.bottom, 0]);
        y.domain([0, d3.max(data, d => d.y)]);
      } else if (unfilteredAgeGroupsData) {
        y = d3.scaleLinear().range([height - margin.top - margin.bottom, 0]);
        y.domain([0, d3.max(unfilteredAgeGroupsData, d => d.y)]);
      } else {
        const maxY = Math.max(
          d3.max(unfilteredData, d => d.y),
          d3.max(data, d => d.y)
        );

        y = d3.scaleLinear().range([height - margin.top - margin.bottom, 0]);
        y.domain([0, maxY]);
      }

      // /////////////////////////////////////////////////////////////////////////////////////////////
      let x = null;

      if (isStartOrEnd) {
        x = d3
          .scaleLinear()
          .range([
            0,
            width - margin.left - margin.right - xAsisTransfoormX * 2
          ]);
        x.domain([block.allowSkip ? start - 1 : start, end]);
      } else {
        x = d3
          .scaleBand()
          .range([0, width - margin.left - margin.right])
          .padding(0.3);
        x.domain(chartData.map(d => d.x));
        barWidth = x.bandwidth();
      }

      const div = d3.select('.tooltip-container').style('display', 'none');
      let barWidthUnfiltered = !unfilteredData ? barWidth : barWidth / 4;
      let barWidthUnfilteredAgeGroups = !unfilteredAgeGroupsData
        ? barWidth
        : barWidth / 2;

      if (barWidthUnfiltered < 0) {
        barWidthUnfiltered = 1;
      }

      if (barWidthUnfilteredAgeGroups < 0) {
        barWidthUnfilteredAgeGroups = 1;
      }

      const getFilteredResultsPercentage = d => (d.y / numberOfResults) * 100;

      const getUnfilteredResultsPercentage = d =>
        (d.y / unfilteredNumberOfResults) * 100;

      chart
        .selectAll('.bar')
        .data(chartData)
        .enter()
        .append('rect')
        .style('fill', chartProps.color)
        .each((r, i, arr) => {
          if (r.xLabel === 'N/A') {
            d3.select(arr[i]).style('opacity', 0.3);
          }
        })
        .attr('x', d => {
          if (!unfilteredData && !unfilteredAgeGroupsData) {
            return x(d.x);
          }
          if (unfilteredAgeGroupsData) {
            return x(d.x) + barWidth / 2;
          }
          return x(d.x + 0.5);
        })
        .attr(
          'width',
          isAgeGroupsChart ? barWidthUnfilteredAgeGroups : barWidthUnfiltered
        )
        .attr('y', d =>
          y(displayRatingPercentage ? getFilteredResultsPercentage(d) : d.y)
        )
        .attr(
          'height',
          d =>
            height -
            margin.top -
            margin.bottom -
            y(displayRatingPercentage ? getFilteredResultsPercentage(d) : d.y)
        )
        .attr(
          'class',
          'results-bar-single-bar result-chart-cursor-pointer result-chart-hover-opacity'
        )
        .on('click', (d, i) => {
          onChartClick(
            filterCollectDataGroup.name,
            filterCollectDataGroup.valuePath,
            block && block.allowSkip && i === 0 ? null : d.x,
            filterCollectDataGroup.resultsPath,
            filterCollectDataGroup.transformator
          );
        })
        .on('mouseover', d => {
          div
            .transition()
            .duration(200)
            .style('color', '#000000')
            .style('display', 'block');
          div
            .html(
              `<strong>${d.xLabel ? d.xLabel : d.x}</strong>: ${
                displayRatingPercentage
                  ? `${getFilteredResultsPercentage(d).toFixed(2)} %`
                  : d.y
              }`
            )
            .style('left', () => `${d3.event.pageX + 10}px`)
            .style('top', () => `${d3.event.pageY}px`);
        })
        .on('mouseout', () => {
          div
            .transition()
            .duration(500)
            .style('display', 'none');
        });

      if (unfilteredData) {
        chart
          .selectAll('.bar')
          .data(unfilteredData)
          .enter()
          .append('rect')
          .style('fill', '#d4d4d4')
          .attr('x', d => x(d.x + 0.23))
          .attr('width', barWidth / 4)
          .attr('y', d =>
            y(displayRatingPercentage ? getUnfilteredResultsPercentage(d) : d.y)
          )
          .attr(
            'height',
            d =>
              height -
              margin.top -
              margin.bottom -
              y(
                displayRatingPercentage
                  ? getUnfilteredResultsPercentage(d)
                  : d.y
              )
          )
          .attr(
            'class',
            () =>
              `results-bar-single-bar result-chart-cursor-pointer ${
                activeFilteringOnThisChart ? 'result-chart-grey-bar' : ''
              } realGreyBars`
          )
          .on('click', d => {
            onSetFiltersRelation('or');
            onChartClick(
              filterCollectDataGroup.name,
              filterCollectDataGroup.valuePath,
              d.x,
              filterCollectDataGroup.resultsPath,
              filterCollectDataGroup.transformator
            );
          })
          .on('mouseover', d => {
            if (!activeFilteringOnThisChart) {
              div
                .transition()
                .duration(200)
                .style('color', '#000000')
                .style('display', 'block');
              div
                .html(
                  `<strong>${d.xLabel ? d.xLabel : d.x}</strong>: ${
                    displayRatingPercentage
                      ? `${getUnfilteredResultsPercentage(d).toFixed(2)} %`
                      : d.y
                  }`
                )
                .style('left', () => `${d3.event.pageX + 10}px`)
                .style('top', () => `${d3.event.pageY}px`);
            }
          })
          .on('mouseout', () => {
            div
              .transition()
              .duration(500)
              .style('display', 'none');
          });
      }

      if (unfilteredAgeGroupsData) {
        chart
          .selectAll('.bar')
          .data(unfilteredAgeGroupsData)
          .enter()
          .append('rect')
          .style('fill', '#d4d4d4')
          .attr('x', d => x(d.x))
          .attr('width', barWidth / 2)
          .attr('y', d => {
            if (!d.y) return 0;
            const divider = unfilteredNumberOfResults || numberOfResults;

            return y(displayRatingPercentage ? (d.y / divider) * 100 : d.y);
          })
          .attr('height', d => {
            if (!d.y) return 0;
            const divider = unfilteredNumberOfResults || numberOfResults;

            return (
              height -
              margin.top -
              margin.bottom -
              y(displayRatingPercentage ? (d.y / divider) * 100 : d.y)
            );
          })
          .attr(
            'class',
            () =>
              `results-bar-single-bar result-chart-cursor-pointer ${
                isAgeGroupsChart && activeFilteringOnThisChart
                  ? 'result-chart-grey-bar'
                  : ''
              } realGreyBars`
          )
          .on('click', d => {
            onSetFiltersRelation('or');
            onChartClick(
              filterCollectDataGroup.name,
              filterCollectDataGroup.valuePath,
              d.x,
              filterCollectDataGroup.resultsPath,
              filterCollectDataGroup.transformator
            );
          })
          .on('mouseover', d => {
            if (!activeFilteringOnThisChart) {
              const divider = unfilteredNumberOfResults || numberOfResults;

              let number;
              if (!d.y) {
                number = 0;
              } else {
                number = displayRatingPercentage
                  ? `${((d.y / divider) * 100).toFixed(2)} %`
                  : d.y;
              }

              div
                .transition()
                .duration(200)
                .style('color', '#000000')
                .style('display', 'block');
              div
                .html(
                  `<strong>${d.xLabel ? d.xLabel : d.x}</strong>: ${number}`
                )
                .style('left', () => `${d3.event.pageX + 10}px`)
                .style('top', () => `${d3.event.pageY}px`);
            }
          })
          .on('mouseout', () => {
            div
              .transition()
              .duration(500)
              .style('display', 'none');
          });
      }

      if (
        ((unfilteredAgeGroupsData && isAgeGroupsChart) || unfilteredData) &&
        activeFilteringOnThisChart
      ) {
        chart
          .selectAll('.bar')
          .data(isAgeGroupsChart ? unfilteredAgeGroupsData : unfilteredData)
          .enter()
          .append('rect')
          .style('fill', 'rgba(255,255,255,0)')
          .attr('x', d => (isAgeGroupsChart ? x(d.x) : x(d.x + 0.23)))
          .attr('width', barWidth / (isAgeGroupsChart ? 2 : 4))
          .attr('y', d => {
            if (!d.y) return 0;
            const divider = numberOfResults;

            const maxY = Math.max(...data.map(o => o.y));

            return y(displayRatingPercentage ? (maxY / divider) * 100 : maxY);
          })
          .attr('height', d => {
            if (!d.y) return 0;
            const divider = numberOfResults;
            const maxY = Math.max(...data.map(o => o.y));

            return (
              height -
              margin.top -
              margin.bottom -
              y(displayRatingPercentage ? (maxY / divider) * 100 : maxY)
            );
          })
          .attr(
            'class',
            `results-bar-single-bar result-chart-cursor-pointer result-chart-grey-bar`
          )
          .on('click', d => {
            onSetFiltersRelation('or');
            onChartClick(
              filterCollectDataGroup.name,
              filterCollectDataGroup.valuePath,
              d.x,
              filterCollectDataGroup.resultsPath,
              filterCollectDataGroup.transformator
            );
          })
          .on('mouseover', (d, i) => {
            chart
              .selectAll('.realGreyBars')
              .classed('result-chart-grey-bar-active', (_d, j) => j === i);

            const divider = unfilteredNumberOfResults || numberOfResults;
            let number;
            if (!d.y) {
              number = 0;
            } else {
              number = displayRatingPercentage
                ? `${((d.y / divider) * 100).toFixed(2)} %`
                : d.y;
            }

            div
              .transition()
              .duration(200)
              .style('color', '#000000')
              .style('display', 'block');
            div
              .html(`<strong>${d.xLabel ? d.xLabel : d.x}</strong>: ${number}`)
              .style('left', () => `${d3.event.pageX + 10}px`)
              .style('top', () => `${d3.event.pageY}px`);
          })
          .on('mouseout', () => {
            chart
              .selectAll('.realGreyBars')
              .classed('result-chart-grey-bar-active', () => false);
            div
              .transition()
              .duration(500)
              .style('display', 'none');
          });
      }

      const ticks = end - start <= 20 ? end - start : 20;

      chart
        .append('g')
        .attr('class', 'results-bar-bottom-axis')
        .attr(
          'transform',
          `translate(${xAsisTransfoormX},${height -
            margin.top -
            margin.bottom})`
        )
        .call(
          d3
            .axisBottom(x)
            .ticks(ticks)
            .tickFormat((d, i) => {
              if (block && block.allowSkip && i === 0) return 'N/A';
              if (i % chartProps.tickFrequency === 0) {
                if (typeof d === 'number') {
                  if (Number.isInteger(d)) {
                    return d;
                  }
                  return null;
                }
                return d;
              }
              return null;
            })
        );

      // Fix: fill gap between left and bottom axis
      if (isStartOrEnd) {
        chart
          .append('g')
          .append('line')
          .style('stroke', '#8b8b8d')
          .style('stroke-width', 1)
          .attr('x1', 0)
          .attr('y1', height - margin.top - margin.bottom)
          .attr('x2', width)
          .attr('y2', height - margin.top - margin.bottom);
      }

      if (
        showCounts &&
        shouldShowCounts &&
        ((chartData.length < 16 && !unfilteredData) ||
          (unfilteredData && unfilteredData.length < 16))
      ) {
        chart
          .selectAll('.bar-values')
          .data(chartData)
          .enter()
          .append('text')
          .attr('x', d => {
            if (!unfilteredData) {
              if (displayRatingPercentage) {
                return x(d.x + 0.07) + barWidth / 2 - 6;
              }
              return x(d.x) + barWidth / 2 - 6;
            }
            if (displayRatingPercentage) {
              return x(d.x - 0.05) + barWidth / 4 + barWidth / 2 - 6;
            }
            return x(d.x + 0.13) + barWidth / 2 - 6;
          })
          .attr(
            'y',
            d =>
              y(
                displayRatingPercentage ? getFilteredResultsPercentage(d) : d.y
              ) - 5
          )
          .style('text-anchor', 'middle')
          .text(d =>
            displayRatingPercentage
              ? `${getFilteredResultsPercentage(d).toFixed(2)} %`
              : d.y
          )
          .attr('font-family', 'Open Sans SemiBold')
          .attr(
            'font-size',
            `${
              unfilteredData &&
              displayRatingPercentage &&
              unfilteredData.length > 5
                ? '10px'
                : '12px'
            }`
          )
          .attr('fill', ' #5300F2')
          .attr('class', (d, i) => `bar-value-${i}`);

        // if not percentage show person icon
        if (!displayRatingPercentage) {
          chart
            .selectAll('.bar-values-user')
            .data(chartData)
            .enter()
            .append('image')
            .attr('x', (d, i) => {
              const textElement = chart.select(`.bar-value-${i}`).node();
              let textWidth = 0;
              if (textElement) {
                textWidth = textElement.getBoundingClientRect().width;
              }
              return !unfilteredData
                ? x(d.x) + barWidth / 2 + textWidth - 7
                : x(d.x + 0.15) + barWidth / 2 + textWidth - 7;
            })
            .attr('y', d => y(d.y) - 13)
            .attr('xlink:href', smileyImage)
            .attr('width', 8)
            .attr('height', 8);
        }

        // display grey bars text
        if (unfilteredData && !activeFilteringOnThisChart) {
          chart
            .selectAll('.bar-values')
            .data(unfilteredData)
            .enter()
            .append('text')
            .attr('x', d => {
              if (displayRatingPercentage) {
                if (chartData.find(element => element.x === d.x)) {
                  // if grey bar has blue bar next to it (prevent overlapping of text)
                  // return x(d.x - 0.3) + barWidth / 2 - 6;
                  return x(d.x - 0.1) + barWidth / 2 - 6;
                }
                // return x(d.x) + barWidth / 2 - 6;
                return x(d.x - 0.1) + barWidth / 2 - 6;
              }
              return x(d.x - 0.13) + barWidth / 2 - 6;
            })
            .attr('y', d =>
              displayRatingPercentage && unfilteredData.length > 5
                ? y(getUnfilteredResultsPercentage(d)) - 5
                : y(d.y) - 5
            )
            .style('text-anchor', 'middle')
            .text(d =>
              displayRatingPercentage
                ? `${getUnfilteredResultsPercentage(d).toFixed(2)} %`
                : d.y
            )
            .attr('font-family', 'sans-serif')
            .attr(
              'font-size',
              `${
                unfilteredData &&
                displayRatingPercentage &&
                unfilteredData.length > 5
                  ? '10px'
                  : '12px'
              }`
            )
            .attr('fill', '#b6b6b6')
            .attr('class', (_d, i) => `bar-value-${i}`);

          if (!displayRatingPercentage && !activeFilteringOnThisChart) {
            chart
              .selectAll('.bar-values-user')
              .data(unfilteredData)
              .enter()
              .append('image')
              .attr('x', (d, i) => {
                const textElement = chart.select(`.bar-value-${i}`).node();
                let textWidth = 0;
                if (textElement) {
                  textWidth = textElement.getBoundingClientRect().width;
                }
                return x(d.x - 0.12) + barWidth / 2 + textWidth - 7;
              })
              .attr('y', d => y(d.y) - 13)
              .attr('xlink:href', smileyImageGrey)
              .attr('width', 8)
              .attr('height', 8);
          }
        }
      }

      chart
        .append('g')
        .attr('class', 'results-bar-left-axis')
        .call(
          d3
            .axisLeft(y)
            .tickFormat(e => {
              if (Math.floor(e) !== e) {
                return null;
              }
              return displayPercentageYLabel ? `${e}%` : e;
            })
            .ticks(5)
        );

      if (yLabel) {
        chartSvg
          .append('text')
          .attr('class', 'axis-title')
          .attr('transform', 'rotate(-90)')
          .attr('y', labelMargins.y)
          .attr('dy', labelMargins.ydY)
          .attr('x', margin.top * -1)
          .style('text-anchor', 'end')
          .text(yLabel);
      }

      if (xLabel) {
        chartSvg
          .append('text')
          .attr('class', 'axis-title')
          .attr('y', height - labelMargins.xY)
          .attr('x', width - labelMargins.xX)
          .text(xLabel);
      }
    }
  };

  return (
    <div className="filter-results-charts-bar-wrapper">
      {data && data.length ? (
        <svg
          className="filter-results-charts-bar"
          ref={elem => {
            if (elem) {
              setSvg(elem);
            }
          }}
        />
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default Bar;
