export default {
  DASHBOARD: 'Dashboard',
  CONTACT_US: 'Contactez-nous',
  LOGOUT: 'Déconnexion',
  NO_DATA_AVAILABLE: 'Aucune donnée disponible',
  RANKING: 'Classement',
  GO_TO_RANKING: 'Aller vers classement',
  DATA: 'Données',
  GO_TO_DATA: 'Aller vers données',
  REVENUES: 'Revenus',
  GO_TO_REVENUES: 'Aller vers revenus',
  MONEY_GRAPH: 'Tableau des revenus',
  TOKENS: 'Jetons',
  LAST_MONTH_TOTAL_EARNED: 'Revenu total du mois dernier',
  USER_OF_THE_WEEK: 'Utilisateur de la semaine',
  NUMBER_OF_THE_USERS_ON_THE_APP: 'Nombre d’utilisateurs sur l’app',
  GOAL: 'Objectif',
  FROM_THE_TOTAL: 'Du total',
  PERSONAL_LINK: 'Votre lien personnel',
  JANUARY: 'Janvier',
  FEBRUARY: 'Février',
  MARCH: 'Mars',
  APRIL: 'Avril',
  MAY: 'Mai',
  JUNE: 'Juin',
  JULY: 'Juillet',
  AUGUST: 'Août',
  SEPTEMBER: 'Septembre',
  OCTOBER: 'Octobre',
  NOVEMBER: 'Novembre',
  DECEMBER: 'Décembre',
  NUMBER_OF_PEOPLE_USING_APP_THROUGH_ASSOCIATION:
    "Nombre de personnes utilisant l'application BUFFL via votre association",
  TOTAL_SUM_COLLECTED_BY_PEOPLE_IN_ASSOCIATION:
    "Somme totale collectée par le nombre de personnes utilisant l'application BUFFL via votre association",
  TOKEN_TARGET_FOR_YOUR_GROUP: 'Objectif de jeton pour votre groupe',
  TOTAL_TOKENS_SAVED_IN_YOUR_GROUP:
    'Nombre total de jetons enregistrés dans votre groupe',
  TOTAL_EUROS_SAVED_IN_GROUP: 'Montant total en euros de votre groupe',
  MONEY_COLLECTED_IN_EXTRA_PROMOTIONS:
    'Argent collecté grâce à la participation à des promotions supplémentaires',
  TOTAL_EUROS_COLLECTED_IN_BUFFL: 'Montant total collectés via BUFFL',
  TOTAL_EUROS_RECEIVED_FROM_BUFFL: 'Montant total reçu de BUFFL',
  TOTAL_EUROS_STILL_TO_RECEIVE_FROM_BUFFL:
    ' Montant total à recevoir de BUFFL ',
  DATE_OF_NEXT_PAYMENT: 'Date du prochain versement',
  PEOPLE: 'Des gens',
  ALL_FIELDS_REQUIRED: 'Tous les champs sont obligatoires',
  FIELD_IS_REQUIRED: 'Ce champ est obligatoire',
  ADJUST_SAVING_GOAL_HERE: 'Modifiez vos informations ici',
  PLEASE_FIX_ERRORS_IN_FORM: 'Veuillez résoudre les erreurs dans le formulaire',
  SAVE_ADJUSTMENTS: 'Sauvegarder les modifications',
  DO_YOU_STILL_HAVE_QUESTION: 'Avez-vous encore une question? Contactez nous.',
  ANY_QUESTIONS_OR_SUGGESTIONS:
    'Des questions ou suggestions? Nous serions ravis de les entendre!',
  CONTACT: 'Contacter',
  ORGANISATION_NOT_ACTIVATED: 'Organisation non activée',
  INVALID_CREDENTIALS: 'Connexion invalide',
  LOGIN: 'Connexion',
  LOG_IN: 'Connecte-toi!',
  EMAIL: 'Adresse électronique (email)',
  PASSWORD: 'Mot de passe',
  REPEAT_PASSWORD: 'Confirmation mot de passe',
  NO_ACCOUNT_CREATED: 'Tu n’es pas encore notre partenaire?',
  REGISTER: 'Inscris-toi!',
  FORGOT_PASSWORD_RESET_HERE: 'Oublié ton mot de passe?',
  PASSWORDS_NOT_SAME: 'Les mots de passe ne sont pas les mêmes',
  EMAIL_SHOULD_HAVE_CHARACTERS: "L'e-mail doit contenir au moins 5 caractères.",
  PASSWORD_SHOULD_HAVE_CHARACTERS:
    'Le mot de passe doit contenir au moins 6 caractères.',
  EMAIL_ALREADY_IN_USE: "L'adresse e-mail est déjà utilisée",
  WAITING_FOR_CONFIRMATION: 'En attente de confirmation',
  THANKS_FOR_APPLICATION:
    'Merci pour votre candidature. Nous vous recontacterons prochainement.',
  EMAIL_OF_ORGANISATION: 'Adresse électronique (email) de l’organisation',
  REGISTER_ORGANISATION: 'Inscrire votre organisation',
  REGISTER_MY_ORGANISATION: 'Inscrire mon organisation',
  ALREADY_HAVE_ACCOUNT: 'Déjà membre?',
  BUFFL_ORGANISATION_DASHBOARD:
    "Le tableau de bord BUFFL pour l'organisation affiche une image claire et structurée de votre campagne BUFFL. Les résultats sont constamment mis à jour et permettent de suivre de près votre campagne.",
  EMAIL_NOT_FOUND: 'E-mail introuvable',
  RESET_PASSWORD_EMAIL_SENT:
    'E-mail de réinitialisation du mot de passe envoyé',
  PASSWORD_RESET_LINK_HAS_BEEN_SENT:
    'lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail.',
  RESET_PASSWORD_PAGE: 'Réinitialisation de la page mot de passe.',
  RESET_PASSWORD: 'Réinitialisation du mot de passe',
  RESET: 'Réinitialisation',
  SOMETHING_WENT_WRONG:
    "Quelque chose s'est mal passé. Contactez notre administration.",
  PASSWORD_SUCCESSFULLY_RESET: 'Réinitialisation du mot de passe réussie',
  ORGANISATION_NAME: 'Nom de l’organisation',
  CONTACT_PERSON_NAME: 'Prénom de la personne de contact',
  CONTACT_PERSON_LAST_NAME: 'Nom de famille de la personne de contact',
  CONTACT_PERSON_PHONE_NUMBER: 'Numéro de la personne de contact',
  CONTACT_PERSON_EMAIL:
    'Adresse électronique (email) de la personne de contact',
  SAVING_TARGET_EURO: 'Objectif de collection (en €)',
  DESCRIPTION_OF_ORGANISATION: 'Description de l’organisation',
  UPLOAD_AN_IMAGE: 'Télécharger une image ...',
  LINK: 'Lien',
  YOU_HAVE_EARNED_TOKENS_THROUGH_REFERRAL:
    'Vous avez gagné des jetons {TOKENS} grâce à des filleuls convertis en argent',
  CONVERT: 'Convertir',
  ORGANISATIONS: 'Organisations',
  ORGANISATION: 'Organisation',
  CONTACT_PERSON: 'Personne de contact',
  SAVING_TARGET: 'Objectif d’épargne',
  NEXT: 'Suivant',
  HOW_MUCH_WOULD_YOU_LIKE_TO_COLLECT: 'Objectif d’épargne (en euros)',
  WHAT_WILL_YOU_USE_THE_MONEY_FOR: 'Description de l’objectif d’épargne',
  BECOME_A_PARTNER: 'Inscris-toi et deviens notre partenaire!',
  WELCOME_BACK: 'Te revoilà!  Bon retour parmi nous!',
  ORGANISATION_LOGO: 'Logo de l’organisation',
  JPG_OR_PNG: '.jpg ou .png',
  SAVINGS_GOAL_CAN_HAVE_CHARACTERS:
    'Ce champ peut contenir un maximum de 200 caractères',
  CONGRATULATIONS: 'Félécitations',
  ORGANISATION_SUCCESFULLY_REGISTERED:
    'Ton organisation est enregistrée sur la plateforme BUFFL!',
  PASS_THE_WORD: 'Passe le mot à tes membres et fans et commence à épargner.'
};
