import { put, select, call } from 'redux-saga/effects';

import { SET_NEW_STATE } from '../../actions';

import updateDecisionFramework from './updateDecisionFramework';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  if (
    action.question &&
    action.question.matrix &&
    action.question.matrix.decisionFrameworkType
  ) {
    const {
      surveyWithUpdatedFramework,
      updateRequest
    } = updateDecisionFramework(surveysState.survey, action.question);
    if (surveyWithUpdatedFramework) {
      const newState = {
        ...surveysState,
        survey: surveyWithUpdatedFramework
      };

      yield put({ type: SET_NEW_STATE, newState });

      if (updateRequest) {
        yield call(action.api, updateRequest.api, {
          variables: updateRequest.variables
        });
      }
    }
  }
}
