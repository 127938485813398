import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import { PARTICIPATION_ACTION_TYPES } from '../../../../../helpers/constants';

import api from '../../../../../../api';

import CampaignNameInput from '../../../../../../campaigns/components/CampaignTable/CampaignNameInput/CampaignNameInput';
import PreviousSurveysList from './PreviousSurveysList/PreviousSurveysList';

import styles from './PreviousSurveyParticipantConfiguration.module.css';

export default ({
  surveyId,
  surveyParticipationConfiguration,
  onUpdate,
  selectedAccountManagementRecord
}) => {
  const initialSurveyIds =
    (surveyParticipationConfiguration &&
      surveyParticipationConfiguration.participationCriteria &&
      surveyParticipationConfiguration.participationCriteria.surveyIds) ||
    [];

  const [filter, setFilter] = useState({});
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalSurveysCount, setTotalSurveysCount] = useState(0);
  const [surveysLoading, setSurveysLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [initialSurveysLoaded, setInitialSurveysLoaded] = useState(false);

  const rowsPerPage = 10;

  useEffect(
    () => {
      if (
        filter &&
        filter.campaignNameSearch &&
        (filter.campaignNameSearch.value ||
          filter.campaignNameSearch.value === '') &&
        filter.campaignNameSearch.value !== searchValue
      ) {
        setSearchValue(filter.campaignNameSearch.value);
        setCurrentPage(0);
        let selectedSurveysIds = [];
        if (selectedSurveys && selectedSurveys.length) {
          selectedSurveysIds = selectedSurveys.map(survey => survey.id);
        }
        fetchSurveys(0, filter.campaignNameSearch.value, selectedSurveysIds);
      }
    },
    [filter]
  );

  const fetchSelectedSurveys = async selectedSurveysIds => {
    const campaignsList = await api().getCampaigns(
      selectedAccountManagementRecord,
      0,
      20,
      `&campaignFilter[showIds]=${selectedSurveysIds.join(',')}`,
      'createdAt', // sortcolumn
      'desc' // sortType
    );

    setSelectedSurveys(campaignsList.campaigns);
    setInitialSurveysLoaded(true);
  };

  const fetchSurveys = async (page, search, selectedSurveysIds) => {
    setSurveysLoading(true);

    let excludeIds = [surveyId]; // This survey should not be an option in the list
    if (selectedSurveysIds && selectedSurveysIds.length) {
      excludeIds = [...excludeIds, ...selectedSurveysIds];
    }
    let queryFilter = `&campaignFilter[excludeIds]=${excludeIds.join(',')}`;
    if (search && search.length > 0) {
      queryFilter += `&campaignFilter[campaignNameSearch]=${search}&campaignFilter[onlySearchProjectName]=true`;
    }
    const campaignsList = await api().getCampaigns(
      selectedAccountManagementRecord,
      page,
      rowsPerPage,
      queryFilter,
      'createdAt',
      'desc'
    );
    setSurveys(campaignsList.campaigns);

    if (
      (campaignsList.total || campaignsList.total === 0) &&
      campaignsList.total !== totalSurveysCount
    ) {
      setTotalSurveysCount(campaignsList.total); // count to determine how many pages to show
    }

    setSurveysLoading(false);
  };

  useEffect(() => {
    if (initialSurveyIds && initialSurveyIds.length) {
      fetchSurveys(0, '', initialSurveyIds);
      fetchSelectedSurveys(initialSurveyIds);
    } else {
      fetchSurveys(0);
    }
  }, []);

  const changePage = pageNumber => {
    setCurrentPage(pageNumber.selected);
    let selectedSurveysIds = [];
    if (selectedSurveys && selectedSurveys.length) {
      selectedSurveysIds = selectedSurveys.map(survey => survey.id);
    }
    fetchSurveys(pageNumber.selected, searchValue, selectedSurveysIds);
  };

  const updateParticipationConfiguration = surveyIds => {
    const newPayload = {
      participationCriteria: {
        surveyIds
      },
      participationAction: PARTICIPATION_ACTION_TYPES.EXCLUDE
    };

    onUpdate('participationConfiguration', newPayload);
  };

  let pages = 0;
  if (totalSurveysCount) {
    pages = Math.ceil(totalSurveysCount / rowsPerPage);
  }

  return (
    <div className={styles.container}>
      <div className={styles.topDescription}>
        Respondents that completed following survey(s), are not eligible for
        this survey:
      </div>
      <CampaignNameInput
        filter={filter}
        setCampaignFilters={setFilter}
        setSearchVisible={() => {
          setFilter({
            campaignNameSearch: { value: '', label: '' }
          });
        }}
        onlySearchProjectName
        fullWidth
      />
      <div className={styles.surveysContainer}>
        {(initialSurveyIds &&
          initialSurveyIds.length &&
          !initialSurveysLoaded) ||
        (selectedSurveys && selectedSurveys.length) ? (
          <PreviousSurveysList
            title="Selected surveys"
            surveys={selectedSurveys}
            isLoading={
              !initialSurveysLoaded &&
              !(selectedSurveys && selectedSurveys.length)
            }
            loadingData={[...Array(initialSurveyIds.length).keys()]}
            checked
            onClick={unSelectedSurvey => {
              const unSelectedSurveyIndex = selectedSurveys.findIndex(
                s => s.id === unSelectedSurvey.id
              );
              const alteredSurveys = [...selectedSurveys].map(s => ({
                ...s
              }));
              alteredSurveys.splice(unSelectedSurveyIndex, 1);
              setSelectedSurveys(alteredSurveys);
              updateParticipationConfiguration(alteredSurveys.map(s => s.id));
              fetchSurveys(
                currentPage,
                searchValue,
                alteredSurveys.map(survey => survey.id)
              );
            }}
          />
        ) : null}
        <PreviousSurveysList
          title={
            selectedSurveys && selectedSurveys.length
              ? 'Other surveys'
              : 'Available surveys'
          }
          surveys={surveys}
          isLoading={surveysLoading}
          loadingData={[...Array(rowsPerPage).keys()]}
          checked={false}
          onClick={newSelectedSurvey => {
            const newSelectedSurveys = [...selectedSurveys, newSelectedSurvey];
            setSelectedSurveys(newSelectedSurveys);
            updateParticipationConfiguration(newSelectedSurveys.map(s => s.id));
            fetchSurveys(
              currentPage,
              searchValue,
              newSelectedSurveys.map(s => s.id)
            );
          }}
        />
        {pages ? (
          <div>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              initialPage={currentPage}
              forcePage={currentPage}
              disableInitialCallback
              onPageChange={changePage}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
