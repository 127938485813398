import React from 'react';
import Dropdown from '../../Dropdown';
import Icon from '../../Icon';
import Popup from '../../Popup';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import './ActionDropdown.css';

import * as mixpanel from '../../../../../mixpanel';

export default class ActionDropdown extends Dropdown {
  constructor(props) {
    super(props);

    this.state = {
      showDuplicateConfirmationDialog: false,
      showDeleteConfirmationDialog: false
    };
  }

  render() {
    const that = this;

    return (
      <div
        ref={this.setWrapperRef}
        className="popup actionDropdownPopup"
        style={this.props.positionStyle}
      >
        {this.props.isAdmin || !this.props.selectedCampaign.paid ? (
          <div
            className="option action-dropdown-web-campaign-option"
            target="new"
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              this.props.history.push(
                `/survey/${this.props.selectedCampaign.id}/edit`
              );
            }}
          >
            <span className="icon">
              <Icon type="edit" />
            </span>
            Edit
          </div>
        ) : null}

        {this.props.selectedCampaign.status === 'testing' ? (
          <div
            role="presentation"
            className="option"
            onClick={async e => {
              e.stopPropagation();
              this.props.onRequestApproval({
                variables: {
                  survey: this.props.selectedCampaign.id,
                  status: true
                }
              });
              this.props.onBlur();
              mixpanel.actions.trackEvent(
                mixpanel.events.CLIENT_REQUEST_APPROVAL,
                {
                  [mixpanel.eventProperties.SURVEY_ID]: this.props
                    .selectedCampaign.id
                }
              );
            }}
          >
            <span className="icon">
              <Icon type="add-page" />
            </span>
            Request Approval
          </div>
        ) : null}

        {this.props.isAdmin &&
        (this.props.selectedCampaign.status === 'awaiting publish' ||
          (this.props.selectedCampaign.status === 'finished' &&
            this.props.selectedCampaign.results_count <
              this.props.selectedCampaign.max_responses)) ? (
          <div
            role="presentation"
            className={`option action-dropdown-publish-option ${
              !(
                this.props.selectedCampaign &&
                this.props.selectedCampaign.surveyDetailsConfirmedByAdmin
              )
                ? 'action-dropdown-publish-option-disabled'
                : ''
            }`}
            onClick={e => {
              if (
                this.props.selectedCampaign &&
                this.props.selectedCampaign.surveyDetailsConfirmedByAdmin
              ) {
                e.stopPropagation();
                this.props.onPublishSurvey({
                  variables: {
                    survey: this.props.selectedCampaign.id,
                    publish: true
                  }
                });
                this.props.onBlur();
                mixpanel.actions.trackEvent(
                  mixpanel.events.CLIENT_PUBLISH_SURVEY,
                  {
                    [mixpanel.eventProperties.SURVEY_ID]: this.props
                      .selectedCampaign.id
                  }
                );
              }
            }}
          >
            <span className="icon">
              <Icon type="checked" />
            </span>
            Publish
          </div>
        ) : null}

        {this.props.selectedCampaign.status === 'published' ? (
          <div
            role="presentation"
            className="option action-dropdown-unpublished-option"
            onClick={e => {
              e.stopPropagation();
              this.props.onPublishSurvey({
                variables: {
                  survey: this.props.selectedCampaign.id,
                  publish: false
                }
              });
              this.props.onBlur();
            }}
          >
            <span className="icon">
              <Icon type="close" />
            </span>
            Unpublish
          </div>
        ) : null}

        {this.props.selectedCampaign.status === 'awaiting publish' &&
        this.props.selectedCampaign.approvalRequestSent &&
        !this.props.selectedCampaign.paid ? (
          <div
            role="presentation"
            className="option action-dropdown-withdraw-request-option"
            onClick={e => {
              e.stopPropagation();
              this.props.onRequestApproval({
                variables: {
                  survey: this.props.selectedCampaign.id,
                  status: false
                }
              });
              this.props.onBlur();
            }}
          >
            <span className="icon">
              <Icon type="close" />
            </span>
            Withdraw approval request
          </div>
        ) : null}

        <div
          role="presentation"
          className="option"
          onClick={e => {
            e.stopPropagation();
            this.props.onShowResults(this.props.selectedCampaign);
            this.props.onBlur();
          }}
        >
          <span className="icon">
            <Icon type="results" />
          </span>
          Results
        </div>
        <div className="separator" />
        {this.props.selectedCampaign.published && (
          <div
            className="option action-dropdown-web-campaign-option"
            target="new"
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              window.open(
                `${window.location.origin.toString()}/users/campaign/${
                  this.props.selectedCampaign.id
                }/user/${this.props.selectedCampaign.client.id ||
                  this.props.selectedCampaign.client}`,
                '_blank'
              );
            }}
          >
            <span className="icon">
              <Icon type="showcase-website" />
            </span>
            Web Survey
          </div>
        )}
        {!this.props.selectedCampaign.disableViewLink && (
          <a
            className="option action-dropdown-view-link-option"
            target="new"
            href={this.props.selectedCampaign.viewUrl}
            onClick={e => {
              e.stopPropagation();
              this.props.onBlur();
            }}
          >
            <span className="icon">
              <Icon type="showcase-website" />
            </span>
            View Link
          </a>
        )}
        {(this.props.selectedCampaign.published ||
          !this.props.selectedCampaign.disableViewLink) && (
          <div className="separator" />
        )}
        <div
          role="presentation"
          className="option action-dropdown-delete-option"
          onClick={e => {
            e.stopPropagation();
            this.setState({ showDuplicateConfirmationDialog: true });
          }}
        >
          <span className="icon">
            <Icon type="duplicate" />
          </span>
          Duplicate
        </div>
        <div
          role="presentation"
          className="option"
          onClick={e => {
            e.stopPropagation();
            this.props.mergeSurvey();
            this.props.setDisplayCombineSurveysTab(true);
          }}
        >
          <span className="icon">
            <Icon type="jump" />
          </span>
          Merge survey
        </div>
        <div
          role="presentation"
          className="option action-dropdown-delete-option"
          onClick={e => {
            e.stopPropagation();
            this.setState({ showDeleteConfirmationDialog: true });
          }}
        >
          <span className="icon">
            <Icon type="delete" />
          </span>
          Delete
        </div>
        {this.state.showDuplicateConfirmationDialog ? (
          <Popup
            component={
              <ConfirmationDialog
                label={`Are you sure you want to duplicate this survey ${this
                  .props.selectedCampaign.projectName ||
                  this.props.selectedCampaign.name}?`}
                confirmLabel="Duplicate"
                cancelLabel="Cancel"
                onConfirm={() => {
                  this.props.onDuplicateSurvey(this.props.selectedCampaign.id);
                  this.props.onBlur();
                }}
                onCancel={() => {
                  this.setState({ showDuplicateConfirmationDialog: false });
                  this.props.onBlur();
                }}
              />
            }
            onClose={() => {
              this.setState({ showDuplicateConfirmationDialog: false });
              this.props.onBlur();
            }}
          />
        ) : null}
        {this.state.showDeleteConfirmationDialog ? (
          <Popup
            component={
              <ConfirmationDialog
                label={`Are you sure you want to delete ${this.props
                  .selectedCampaign.name || 'this survey'}?`}
                confirmLabel="Delete"
                cancelLabel="Cancel"
                onConfirm={() => {
                  that.props.onDeleteCampaign(this.props.selectedCampaign.id);
                  this.props.onBlur();
                }}
                onCancel={() => {
                  this.setState({ showDeleteConfirmationDialog: false });
                  this.props.onBlur();
                }}
              />
            }
            onClose={() => {
              this.setState({ showDeleteConfirmationDialog: false });
              this.props.onBlur();
            }}
          />
        ) : null}
      </div>
    );
  }
}
