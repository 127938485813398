import { connect } from 'react-redux';
import {
  getCredentials,
  logout,
  setSelectedAccountManagementRecord
} from '../actions';

import ClientRoute from '../components/ClientRoute';

const mapStateToProps = state => ({
  isAuthenticationProcessing: state.auth.isAuthenticationProcessing,
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
  isProfilingTagsManager: state.auth.isProfilingTagsManager,
  accountManagement: state.auth.accountManagement
});

const mapDispatchToProps = dispatch => ({
  onLoad: beforeLogin => dispatch(getCredentials(beforeLogin)),
  onLogout: () => dispatch(logout()),
  setSelectedAccountManagementRecord: record => {
    dispatch(setSelectedAccountManagementRecord(record));
    window.location.href = '/clients';
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientRoute);
