import React, { useRef } from 'react';

import TimelineChart from '../../../Charts/TimelineChart';
import RangeSelector from '../RangeSelector/RangeSelector';
import Loader from '../../../Loader/Loader';

import { reloadMetricData } from '../../../../../../helpers/mainPageChartDataLoad';
import getFormattedNumber from '../../../../../../helpers/getFormattedNumber';

import styles from './ChartMetric.module.css';

export default ({
  name,
  title,
  value,
  description,
  isPercentage,
  availableRanges,
  metricInnerSettings,
  setMetricInnerSettings,
  api,
  appliedFilters,
  statisticsData,
  setStatisticsData,
  filterRelation,
  dateFormat,
  metricWidth = 100,
  tickDaysDifference
}) => {
  const chartParentContainer = useRef();
  const tooltipContainerRef = useRef();

  if (!value) {
    return (
      <div className={styles.container} style={{ width: `${metricWidth}%` }}>
        <div className={styles.loadingContainer}>
          <Loader fixed={false} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} style={{ width: `${metricWidth}%` }}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.valueContainer}>
            <div className={styles.value}>
              {getFormattedNumber(value.value)}
              {isPercentage && '%'}
            </div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        {availableRanges && availableRanges.length && (
          <div className={styles.rangesContainer}>
            <RangeSelector
              name={name}
              availableRanges={availableRanges}
              value={metricInnerSettings[name].range}
              onClick={r => {
                const newMetricSetting = {
                  ...metricInnerSettings[name],
                  range: r
                };

                setMetricInnerSettings({
                  ...metricInnerSettings,
                  [name]: newMetricSetting
                });
                reloadMetricData(
                  api,
                  statisticsData,
                  setStatisticsData,
                  appliedFilters,
                  name,
                  newMetricSetting,
                  filterRelation
                );
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.chartContainer} ref={chartParentContainer}>
        <TimelineChart
          parent={chartParentContainer}
          tooltipContainerRef={tooltipContainerRef}
          height={170}
          data={value.chartData}
          isPercentage={isPercentage}
          dateFormat={dateFormat}
          tickDaysDifference={tickDaysDifference}
        />
        <div ref={tooltipContainerRef} className={styles.tooltipContainer} />
      </div>
    </div>
  );
};
