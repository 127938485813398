import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import emptySurveyPlaceholderCover from '../../../../../assets/empty-survey-placeholder.svg';

import styles from './SurveysPlaceholder.module.css';

export default ({ survey, questions }) => {
  if (questions && Object.values(questions).length === 0) {
    return (
      <Droppable droppableId="add-new-question">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div className={styles.emptyStatePlaceholder}>
              <img
                src={emptySurveyPlaceholderCover}
                className={styles.emptySurveyPlaceholderCover}
                alt="Empty survey placeholder"
              />
              <div className={styles.emptyStateDescription}>
                Everything starts small. Get started by dragging a question
                block from the right and dropping it here.
              </div>
              <div className={styles.emptyStateDescription}>
                Maybe a <strong>TEXT BLOCK</strong> to tell your audience a bit
                about idea, for instance?
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  if (!survey) {
    return (
      <div className={styles.loadingPlaceholdersContainer}>
        <div className={`${styles.loadingPlaceholderFirst} loader-container`} />
        <div
          className={`${styles.loadingPlaceholderSecond} loader-container`}
        />
        <div className={`${styles.loadingPlaceholderSide} loader-container`} />
      </div>
    );
  }

  return null;
};
