import React from 'react';

import styles from './RespondentsLegend.module.css';

const RespondentsLegend = props => {
  const {
    filtering,
    totalResultsCount,
    selectedResultsCount,
    filteringOnSelection,
    questionHasFilteredData
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.sample}>
        {filtering && !filteringOnSelection && questionHasFilteredData ? (
          <div
            className={styles.bullet}
            style={{ backgroundColor: '#8B8B8D' }}
          />
        ) : null}
        Total sample {`(N = ${totalResultsCount})`}
      </div>
      {filtering ? (
        <>
          <div className={styles.sample}>
            <div
              className={styles.bullet}
              style={{ backgroundColor: '#5300f2' }}
            />
            Selected {`(N = ${selectedResultsCount})`}
          </div>
          {filteringOnSelection && questionHasFilteredData ? (
            <div className={styles.sample}>
              <div
                className={styles.bullet}
                style={{ backgroundColor: '#8B8B8D' }}
              />
              Not selected {`(N = ${totalResultsCount - selectedResultsCount})`}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default RespondentsLegend;
