/* eslint-disable */
export const CUSTOM_THEME_COLOR_NAMES = {
  BASE: 'BASE'
};
export const CUSTOM_THEME_IMAGE_NAMES = {
  LOGO: 'LOGO'
};
export const PARTICIPATION_ACTION_TYPES = {
  EXCLUDE: 'EXCLUDE',
  INCLUDE: 'INCLUDE'
};
/* eslint-enable */
