import React, { useState } from 'react';

import RecordAction from '../RecordAction/RecordAction';

import { recordActionPopups } from '../../helpers/constants';

import startIcon from '../../../../assets/img/star.svg';
import amDelete from '../../../../assets/img/accountmanagement/am-delete.svg';
import amDownload from '../../../../assets/img/accountmanagement/am-download.svg';
import amEdit from '../../../../assets/img/accountmanagement/am-edit.svg';
import amTeams from '../../../../assets/img/accountmanagement/am-teams.svg';
import amWarning from '../../../../assets/img/accountmanagement/am-warning.svg';

import styles from './AccountManagementRecord.module.css';

const getAccountManagementColor = percent => {
  if (percent < 30) {
    return '#f61553';
  }
  if (percent >= 30 && percent <= 75) {
    return '#f9ad2c';
  }
  if (percent > 75) {
    return '#7fc26c';
  }
  return null;
};

export default ({
  level,
  parent,
  record,
  setActivePopup,
  onRecordClick,
  accountManagement,
  isAdmin,
  actionsAllowed,
  higherRecords,
  onSelectRecord,
  onGlobalExportClick
}) => {
  const [isRecordHovered, setIsRecordHovered] = useState(false);

  let shouldBudgetOverviewBeAvailable = false;
  if (record && record.budgetAvailable === true && isAdmin) {
    shouldBudgetOverviewBeAvailable = true;
  }
  let licenceStartDateParsed = null;
  let licenceEndDateParsed = null;
  let surveysThatShouldPublishPerDay = null;

  if (level === 1) {
    licenceStartDateParsed =
      record && record.licenceStartDate
        ? new Date(record.licenceStartDate)
        : null;
    licenceEndDateParsed =
      record && record.licenceEndDate ? new Date(record.licenceEndDate) : null;
    surveysThatShouldPublishPerDay =
      record &&
      record.permissions &&
      record.permissions.surveysIncludedInLicense
        ? record.permissions.surveysIncludedInLicense / 365
        : null;
  }

  if (level === 2) {
    const parentRecordInformation = higherRecords.find(r => r.id === parent);

    if (parentRecordInformation) {
      licenceStartDateParsed = parentRecordInformation.licenceStartDate
        ? new Date(parentRecordInformation.licenceStartDate)
        : null;
      licenceEndDateParsed = parentRecordInformation.licenceEndDate
        ? new Date(parentRecordInformation.licenceEndDate)
        : null;
      surveysThatShouldPublishPerDay = null;
    }
  }

  const licenceStartDateString = licenceStartDateParsed
    ? `${licenceStartDateParsed.getDate()}/${licenceStartDateParsed.getMonth() +
        1}/${licenceStartDateParsed.getFullYear()}`
    : '';
  const licenceEndDateString = licenceStartDateParsed
    ? `${licenceEndDateParsed.getDate()}/${licenceEndDateParsed.getMonth() +
        1}/${licenceEndDateParsed.getFullYear()}`
    : '';

  const currentMoment = new Date();
  const currentMomentNullHours = currentMoment.setUTCHours(0, 0, 0, 0);
  const currentMomentDate = new Date(currentMomentNullHours);

  const licenceEndDateToCompareNullHours = licenceEndDateParsed
    ? licenceEndDateParsed.setUTCHours(0, 0, 0, 0)
    : null;
  const licenceEndDateToCompare =
    licenceEndDateToCompareNullHours &&
    new Date(licenceEndDateToCompareNullHours);

  const dateLeftToExpire =
    licenceEndDateParsed && licenceEndDateToCompare
      ? currentMomentDate.getTime() - licenceEndDateToCompare.getTime()
      : null;
  const daysLeftToExpire = dateLeftToExpire
    ? dateLeftToExpire / (1000 * 3600 * 24)
    : null;
  const daysLeftToExpireInteger =
    daysLeftToExpire !== null ? Math.floor(daysLeftToExpire) : null;

  const licenceDaysElapsed =
    surveysThatShouldPublishPerDay !== null
      ? 365 - daysLeftToExpireInteger
      : null;

  const surveysThatShouldHaveBeenPublished =
    licenceDaysElapsed !== null
      ? licenceDaysElapsed * surveysThatShouldPublishPerDay
      : null;

  let touchpoint = null;
  // TODO: Check this!
  if (record.surveysPublished === 0) {
    touchpoint = 0;
  } else if (
    record &&
    record.permissions &&
    record.permissions.surveysIncludedInLicense &&
    record.permissions.surveysIncludedInLicense > 0 &&
    record.surveysPublished
  ) {
    touchpoint = Math.round(
      (record.surveysPublished / surveysThatShouldHaveBeenPublished) * 100
    );
    if (touchpoint > 100) {
      touchpoint = 100;
    }
  }

  const allowedToSelectRecord =
    isAdmin ||
    level === 2 ||
    (level === 1 &&
      accountManagement &&
      accountManagement.manager &&
      accountManagement.manager.some(m => m.id === record.id));

  return (
    <tr
      onClick={() => {
        if (allowedToSelectRecord && isRecordHovered) {
          onSelectRecord();
        }
      }}
      className={`${styles.record} ${
        allowedToSelectRecord && isRecordHovered ? styles.isRecordHovered : ''
      }`}
      onMouseEnter={() => {
        if (allowedToSelectRecord) setIsRecordHovered(true);
      }}
      onMouseLeave={() => {
        if (allowedToSelectRecord) setIsRecordHovered(false);
      }}
    >
      <td className={styles.rowContainer}>
        {record.isManager ? (
          <img
            src={startIcon}
            alt="Starred user"
            className={styles.starredUser}
          />
        ) : null}
        <span className={styles.nameContainer}>{record.name}</span>
      </td>
      <td className={`${styles.rowContainer} ${styles.touchpointsContainer}`}>
        {touchpoint !== null ? (
          <>
            <div className={styles.touchpointNumber}>{touchpoint}%</div>
            <div className={styles.barContainer}>
              <div
                className={styles.bar}
                style={{
                  width: `${touchpoint}%`,
                  backgroundColor: getAccountManagementColor(touchpoint)
                }}
              />
            </div>
          </>
        ) : null}
      </td>
      {level === 1 ? (
        <>
          <td className={styles.rowContainer}>{licenceStartDateString}</td>
          <td className={styles.rowContainer}>
            {licenceEndDateString}
            {daysLeftToExpireInteger &&
            daysLeftToExpireInteger < 0 &&
            Math.abs(daysLeftToExpireInteger) < 60 ? (
              <div className={styles.warningTooltipContainer}>
                <img
                  className={styles.warningTooltipIcon}
                  alt="Touchpoints tooltip"
                  src={amWarning}
                />
                <div className={styles.warningTooltipPopup}>
                  <div className={styles.warningTooltipPopupTriangle} />
                  License will expire in {daysLeftToExpireInteger * -1}{' '}
                  {daysLeftToExpireInteger === 1 ? 'day' : 'days'}
                </div>
              </div>
            ) : null}
            {dateLeftToExpire === 0 ? (
              <div className={styles.warningTooltipContainer}>
                <img
                  className={styles.warningTooltipIcon}
                  alt="Touchpoints tooltip"
                  src={amWarning}
                />
                <div className={styles.warningTooltipPopup}>
                  <div className={styles.warningTooltipPopupTriangle} />
                  License expires today
                </div>
              </div>
            ) : null}
            {daysLeftToExpireInteger !== null && daysLeftToExpireInteger > 0 ? (
              <div className={styles.warningTooltipContainer}>
                <img
                  className={styles.warningTooltipIcon}
                  alt="Touchpoints tooltip"
                  src={amWarning}
                />
                <div className={styles.warningTooltipPopup}>
                  <div className={styles.warningTooltipPopupTriangle} />
                  License expired
                </div>
              </div>
            ) : null}
          </td>
        </>
      ) : null}
      <td className={styles.rowContainer}>
        <div
          className={styles.actionButtonsContainer}
          onMouseEnter={() => {
            if (allowedToSelectRecord) setIsRecordHovered(false);
          }}
          onMouseLeave={() => {
            if (allowedToSelectRecord) setIsRecordHovered(true);
          }}
        >
          <RecordAction
            label={level === 1 ? 'Initiatives' : 'Members'}
            icon={amTeams}
            onClick={() => {
              if (onRecordClick) {
                document.documentElement.scrollTop = 0;
                onRecordClick(record);
              }
            }}
          />
          {shouldBudgetOverviewBeAvailable ? (
            <RecordAction
              label="License overview"
              icon={amDownload}
              onClick={async () =>
                onGlobalExportClick(
                  recordActionPopups.LICENSE_OVERVIEW,
                  record.id
                )
              }
            />
          ) : null}
          {actionsAllowed ? (
            <>
              <RecordAction
                label="Edit"
                icon={amEdit}
                onClick={() => {
                  setActivePopup({
                    type: recordActionPopups.EDIT,
                    record: {
                      ...record,
                      licenceStartDate:
                        record &&
                        record.licenceStartDate &&
                        new Date(record.licenceStartDate),
                      licenceEndDate:
                        record &&
                        record.licenceEndDate &&
                        new Date(record.licenceEndDate)
                    }
                  });
                }}
              />
              <RecordAction
                label="Delete"
                icon={amDelete}
                onClick={() => {
                  setActivePopup({ type: recordActionPopups.DELETE, record });
                }}
              />
            </>
          ) : null}
        </div>
      </td>
    </tr>
  );
};
