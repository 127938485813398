import React from 'react';

import styles from './OverallStatistics.module.css';

const OverallStatistics = ({ stats, disabled }) => (
  <div className={`${styles.statistics} ${disabled ? styles.disabled : ''}`}>
    <div className={styles.statisticsItem}>
      <div className={`${styles.count} workspaces-overall-statistics-total`}>
        {stats && stats.totalResponses ? stats.totalResponses : '/'}
      </div>
      <div className={styles.label}>
        <span>Total</span>
        <span>Responses</span>
      </div>
    </div>
    <div className={styles.statisticsItem}>
      <div className={`${styles.count} workspaces-overall-statistics-target`}>
        {stats && stats.responseTarget ? stats.responseTarget : '/'}
      </div>
      <div className={styles.label}>
        <span>Response</span>
        <span>Target</span>
      </div>
    </div>
    <div className={styles.statisticsItem}>
      <div
        className={`${styles.count} workspaces-overall-statistics-completion`}
      >
        {stats && stats.completion ? stats.completion : '/'}
        <span className={styles.countPercentage}>%</span>
      </div>
      <div className={styles.label}>
        <span>Completion</span>
        <span>Rate</span>
      </div>
    </div>
  </div>
);

export default OverallStatistics;
