import React, { useState } from 'react';
import styles from './NotificationDetails.module.css';
import TargetFilters from './TargetFilters/TargetFilters';
import Icon from '../../../campaigns/components/Icon';
import Loader from '../../../campaigns/components/Loader/Loader';

const NotificationDetails = props => {
  const {
    pushNotificationDetails,
    setPushNotificationDetails,
    setPushNotificationToSendAgain,
    cancelPushNotification,
    SECTION_KEYS,
    section
  } = props;
  const [isFetching] = useState(false);
  const [pushNotificationDetailsList] = useState([]);
  const [error] = useState(false);
  const [requestProcessing, setRequestProcessing] = useState(false);

  return (
    <div className={styles.detailsContainer}>
      <div
        className={styles.closeIconContainer}
        role="presentation"
        onClick={() => setPushNotificationDetails(null)}
      >
        <Icon type="close" />
      </div>

      {section === SECTION_KEYS.PUSH_NOTIFICATION ? (
        <>
          <div className={styles.sectionTitle}>Title</div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails && pushNotificationDetails.title}
          </div>
          <div className={styles.sectionTitle}>Description</div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails && pushNotificationDetails.description}
          </div>
          <div className={styles.sectionTitle}>Target</div>
          <div className={styles.sectionDetails}>
            {(pushNotificationDetails && pushNotificationDetails.count) ||
              'No details available'}
          </div>
          <div className={styles.sectionTitle}>Filter</div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails &&
            pushNotificationDetails.filter_string &&
            pushNotificationDetails.filter_string !== '[]' ? (
              <TargetFilters
                filterString={pushNotificationDetails.filter_string}
              />
            ) : (
              'No filters selected. Send push notification to all users!'
            )}
          </div>
          <div className={styles.sectionTitle}>Created At</div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails && pushNotificationDetails.createdAt}
          </div>
        </>
      ) : null}

      {section === SECTION_KEYS.EMAIL ? (
        <>
          <div className={styles.sectionTitle}>Internal email name</div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails && pushNotificationDetails.description}
          </div>
          <div className={styles.sectionTitle}>
            Send to respondents that are eligible and have not filled in
            following survey
          </div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails && pushNotificationDetails.surveyName}
          </div>
          <div className={styles.sectionTitle}>
            {"What's the subject line for this email?"}
          </div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails && pushNotificationDetails.title}
          </div>
          <div className={styles.sectionTitle}>Target</div>
          <div className={styles.sectionDetails}>
            {(pushNotificationDetails && pushNotificationDetails.count) ||
              'No details available'}
          </div>
          <div className={styles.sectionTitle}>Filter</div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails &&
            pushNotificationDetails.filter_string &&
            pushNotificationDetails.filter_string !== '[]' ? (
              <TargetFilters
                filterString={pushNotificationDetails.filter_string}
                hideSurveyFilter
              />
            ) : (
              'No filters selected. Send push notification to all users!'
            )}
          </div>
          <div className={styles.sectionTitle}>Created At</div>
          <div className={styles.sectionDetails}>
            {pushNotificationDetails && pushNotificationDetails.createdAt}
          </div>
        </>
      ) : null}

      {requestProcessing ? <Loader /> : null}
      {!requestProcessing && section === SECTION_KEYS.PUSH_NOTIFICATION ? (
        <div className={styles.footerContainer}>
          {pushNotificationDetails.scheduled ? (
            <div
              className={`${styles.sendPushButton} ${styles.cancelScheduledPN}`}
              role="presentation"
              onClick={() => {
                setRequestProcessing(true);
                cancelPushNotification(pushNotificationDetails.id);
              }}
            >
              Cancel
            </div>
          ) : (
            <div
              className={`${styles.sendPushButton} ${styles.sendThisPushAgain}`}
              role="presentation"
              onClick={() => {
                setPushNotificationToSendAgain(pushNotificationDetails);
                setPushNotificationDetails(null);
              }}
            >
              Send this push again
            </div>
          )}
        </div>
      ) : null}
      {!isFetching && pushNotificationDetailsList.length ? (
        <div className={styles.pushNotificationDetailsListContainer}>
          {pushNotificationDetailsList.map((listItem, index) => (
            <div
              className={styles.pushNotificationDetailsListItem}
              key={`push-notification-details-list-item-${index.toString()}`}
            >
              {JSON.stringify(listItem)}
            </div>
          ))}
        </div>
      ) : null}
      {!isFetching && error ? (
        <div className={styles.pushNotificationDetailsListContainer}>
          Error occured. Please contact administrator.
        </div>
      ) : null}
      {isFetching ? (
        <div className={styles.pushNotificationDetailsListContainer}>
          <div className={styles.pushNotificationDetailsListItem}>Loading</div>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationDetails;
