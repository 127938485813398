import { SURVEY_BUILDER_POPUP_TYPES } from '../constants';
import { getNumberOfQuestionsToCharge } from '../getChargeAmount/getChargeAmount';
import getPermissionValue from '../getPermissionValue';

export default (
  isAdmin,
  survey,
  surveyLists,
  accountManagement,
  surveyPermissionsCharge
) => {
  const numberOfQuestionsToCharge = getNumberOfQuestionsToCharge(surveyLists);

  const questionsPerSurveyIncludedInLicence = getPermissionValue(
    survey,
    'questionsPerSurveyIncludedInLicence'
  );

  if (survey) {
    const isThereAnyLicence =
      survey && survey.recordDetails && survey.recordDetails.haveLicence;

    if (!survey.paid) {
      if (
        !isThereAnyLicence &&
        !surveyPermissionsCharge.noLicenceMoreThan15WarningShown &&
        numberOfQuestionsToCharge > 15
      ) {
        return {
          accountManagementQuestionsWarning:
            SURVEY_BUILDER_POPUP_TYPES.NO_LICENCE_MORE_THAN_15
        };
      }

      if (
        isThereAnyLicence &&
        questionsPerSurveyIncludedInLicence &&
        numberOfQuestionsToCharge > questionsPerSurveyIncludedInLicence
      ) {
        return {
          accountManagementQuestionsWarning:
            SURVEY_BUILDER_POPUP_TYPES.EXTRA_QUESTIONS_ABOVE_LICENCE_ADDED
        };
      }
    }

    if (
      survey.paid &&
      !surveyPermissionsCharge.unpublishedWarningShown &&
      isThereAnyLicence
    ) {
      return {
        accountManagementQuestionsWarning:
          SURVEY_BUILDER_POPUP_TYPES.SURVEY_UNPUBLISHED_QUESTIONS_ADDED
      };
    }
  }

  return {
    accountManagementQuestionsWarning: null
  };
};
