import React, { useState, useContext } from 'react';

import Scoping from './components/Scoping/Scoping';
import Properties from './components/Properties/Properties';
import Welcome from './components/Welcome/Welcome';
import WebSurveyRedirections from './components/WebSurveyRedirections/WebSurveyRedirections';
// import SpellCheck from './components/SpellCheck/SpellCheck';
import WebSurveySettings from './components/WebSurveySettings/WebSurveySettings';
import Conversion from './components/Conversion/Conversion';
import CustomBranding from './components/CustomBranding/CustomBranding';
import Popup from '../Popup/Popup';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';

import SurveyBuilderPageContext from '../SurveyBuilderPageContext';

export default ({ defaultConstants }) => {
  const { api, survey, isAdmin, surveyRefetch } = useContext(
    SurveyBuilderPageContext
  );

  const [confirmationPopup, setConfirmationPopup] = useState(null);

  const updateSurveyProperty = async (property, value, preventRefetch) => {
    await api('updateSurvey', {
      variables: {
        survey: survey.id,
        [property]: value
      }
    });
    if (surveyRefetch && !preventRefetch) {
      await surveyRefetch();
    }
  };

  const updateSurveyProperties = async (properties, preventRefetch) => {
    await api('updateSurvey', {
      variables: {
        survey: survey.id,
        ...properties
      }
    });
    if (surveyRefetch && !preventRefetch) {
      await surveyRefetch();
    }
  };

  if (!survey) {
    return null;
  }

  const updateCustomCopies = async variables => {
    await api('updateCustomCopies', {
      variables: {
        survey: survey.id,
        ...variables,
        active: true
      }
    });
  };

  return (
    <div className="flow-container">
      <Scoping
        survey={survey}
        isAdmin={isAdmin}
        onUpdate={updateSurveyProperty}
      />
      <Welcome
        survey={survey}
        onUpdate={updateSurveyProperty}
        onUpdateMultiple={updateSurveyProperties}
        setConfirmationPopup={setConfirmationPopup}
        api={api}
        surveyRefetch={surveyRefetch}
        isAdmin={isAdmin}
        defaultConstants={defaultConstants}
      />

      <WebSurveySettings
        survey={survey}
        isAdmin={isAdmin}
        onUpdate={updateSurveyProperty}
      />

      {survey && survey.customBranding && (
        <CustomBranding
          survey={survey}
          isAdmin={isAdmin}
          onUpdate={updateSurveyProperty}
        />
      )}

      {isAdmin && (
        <WebSurveyRedirections
          survey={survey}
          updateSurveySettings={updateSurveyProperty}
        />
      )}

      {isAdmin && (
        <Properties
          survey={survey}
          isAdmin={isAdmin}
          onUpdate={updateSurveyProperty}
        />
      )}

      {
        // <SpellCheck />
      }

      {isAdmin && (
        <Conversion survey={survey} updateCustomCopies={updateCustomCopies} />
      )}

      {confirmationPopup ? (
        <Popup
          component={
            <ConfirmationPopup
              onClick={confirmationPopup.onClick}
              onClose={confirmationPopup.onClose}
              onDontShowAnymoreChecked={
                confirmationPopup.onDontShowAnymoreChecked
              }
              title={confirmationPopup.title}
              description={confirmationPopup.description}
              warning={confirmationPopup.warning}
              checkboxLabel={confirmationPopup.checkboxLabel}
              defaultValue={confirmationPopup.defaultValue}
              surveySelection={confirmationPopup.surveySelection}
            />
          }
          onClose={() => {}}
        />
      ) : null}
    </div>
  );
};
