import React, { useState, useContext, useEffect } from 'react';

import {
  getGroupFirstFlow,
  getGroupLastFlow
} from '../../../../helpers/getGroupEdgeFlows/getGroupEdgeFlows';
import SidePropertiesContainer from '../SidePropertiesContainer/SidePropertiesContainer';
import SideProperties from './components/SideProperties/SideProperties';
import SurveyBuilderContext from '../../../../SurveyBuilderContext';
import GroupHeader from './components/GroupHeader/GroupHeader';
import GroupFooter from './components/GroupFooter/GroupFooter';
import FlowLogicJump from '../FlowLogicJump/FlowLogicJump';
import DropZone from '../DropZone';

import {
  getPreviousNeigboingFlow,
  getNextNeigboingFlow
} from '../../../../helpers/getNeigboringFlows/getNeigboringFlows';

import styles from './Group.module.css';

export default ({ group, elements }) => {
  const [isActiveGroup, setIsActiveGroup] = useState(false);
  const { state, actions } = useContext(SurveyBuilderContext);
  const [groupNameRef, setGroupNameRef] = useState(null);

  useEffect(
    () => {
      if (
        isActiveGroup &&
        ((state.activeFlow && state.activeFlow.id !== group.id) ||
          !state.activeFlow)
      ) {
        setIsActiveGroup(false);
      }
      if (
        !isActiveGroup &&
        state.activeFlow &&
        state.activeFlow.id === group.id
      ) {
        setIsActiveGroup(true);
      }
    },
    [state.activeFlow]
  );

  let surveyListGroup = state.surveyLists.find(
    sLQ => sLQ.group && sLQ.group.id === group.id
  );
  if (surveyListGroup) {
    surveyListGroup = surveyListGroup.group;
  }
  if (!surveyListGroup) {
    const surveyListParentGroup = state.surveyLists.find(
      sLQ =>
        sLQ.group &&
        sLQ.group.parentGroup &&
        sLQ.group.parentGroup.id === group.id
    );
    if (surveyListParentGroup) {
      surveyListGroup = surveyListParentGroup.group.parentGroup;
    }
  }

  if (!surveyListGroup) return null;

  const firstGroupQuestionId = getGroupFirstFlow(state.survey.content, group);
  const firstGroupQuestion = state.surveyLists.find(
    sL => sL.id === firstGroupQuestionId
  );

  const lastGroupQuestionId = getGroupLastFlow(state.survey.content, group);
  const lastGroupQuestion = state.surveyLists.find(
    sL => sL.id === lastGroupQuestionId
  );

  const isFirstGroupChildFlowQuestion =
    firstGroupQuestion &&
    firstGroupQuestion.group &&
    firstGroupQuestion.group.nestingLevel === 1 &&
    firstGroupQuestion.group.parent;
  const isLastGroupChildFlowQuestion =
    lastGroupQuestion &&
    lastGroupQuestion.group &&
    lastGroupQuestion.group.nestingLevel === 1 &&
    lastGroupQuestion.group.parent;

  let containerStyle = styles.groupContainer;
  if (surveyListGroup && surveyListGroup.parent) {
    containerStyle = `${containerStyle} ${styles.groupParentTopContainer} ${
      styles.groupContainer
    }`;
  }
  if (isActiveGroup) {
    containerStyle = `${containerStyle} ${styles.active}`;
  }
  if (surveyListGroup && surveyListGroup.nestingLevel === 2) {
    containerStyle = `${containerStyle} ${styles.childGroup}`;
  }
  if (isFirstGroupChildFlowQuestion) {
    containerStyle = `${containerStyle} ${styles.firstChildQuestion}`;
  }
  if (isLastGroupChildFlowQuestion) {
    containerStyle = `${containerStyle} ${styles.lastChildQuestion}`;
  }

  const nextNeigboingFlow = getNextNeigboingFlow(
    surveyListGroup,
    state.surveyLists
  );
  const isNextGroup = nextNeigboingFlow && nextNeigboingFlow.nestingLevel;

  const previousNeigboingFlow = getPreviousNeigboingFlow(
    surveyListGroup,
    state.surveyLists
  );
  const isPreviousGroup =
    previousNeigboingFlow && previousNeigboingFlow.nestingLevel;

  const showGroupFooter =
    lastGroupQuestion &&
    (!lastGroupQuestion.additionalInformation ||
      !lastGroupQuestion.additionalInformation.isNextGroup) &&
    (!surveyListGroup.additionalInformation ||
      !surveyListGroup.additionalInformation.nestedBottomEdgeGroup) &&
    !isNextGroup &&
    nextNeigboingFlow;

  const hideGroupHeaderActions =
    (surveyListGroup.additionalInformation &&
      surveyListGroup.additionalInformation.nestedTopEdgeGroup) ||
    !previousNeigboingFlow;

  const hideBottomDropZone =
    surveyListGroup.additionalInformation &&
    surveyListGroup.additionalInformation.nestedBottomEdgeGroup;

  const showTopDropZone =
    (hideGroupHeaderActions && surveyListGroup.nestingLevel === 2) ||
    isPreviousGroup ||
    !previousNeigboingFlow; // Group as first survey element

  const isTopDropZoneWithReducedHeight =
    isPreviousGroup ||
    (!previousNeigboingFlow &&
      surveyListGroup.additionalInformation &&
      !surveyListGroup.additionalInformation.nestedTopEdgeGroup);

  const surveyStructure = {
    content: state.survey.content,
    questions: state.survey.questions
  };

  // For two groups in a row, Group actions are visible only in GroupHeader
  return (
    <div>
      {showTopDropZone && (
        <DropZone
          droppableId={`dropzone-${group.id}-0`}
          reducedHeight={isTopDropZoneWithReducedHeight}
        />
      )}
      <GroupHeader
        api={null}
        setGroupNameRef={setGroupNameRef}
        survey={null}
        group={group}
        isActiveGroup={isActiveGroup}
        actions={actions}
        hideGroupHeaderActions={hideGroupHeaderActions}
        surveyListGroup={surveyListGroup}
        surveyLists={state.surveyLists}
        surveyStructure={surveyStructure}
      />
      <div
        className={
          isActiveGroup
            ? `${styles.groupWrapper} ${styles.active}`
            : styles.groupWrapper
        }
      >
        <div className={containerStyle}>{elements}</div>
        {!surveyListGroup.parent && (
          <div
            className={
              isActiveGroup
                ? `${styles.verticalGroupLine} ${styles.active}`
                : styles.verticalGroupLine
            }
          />
        )}
        {isActiveGroup && (
          <SidePropertiesContainer top={0} title="Edit group">
            <SideProperties
              actions={actions}
              group={group}
              surveyLists={state.surveyLists}
              surveyStructure={surveyStructure}
              groupNameRef={groupNameRef}
              surveyListGroup={surveyListGroup}
            />
          </SidePropertiesContainer>
        )}
      </div>
      {isActiveGroup && (
        <FlowLogicJump
          key="flow-logic-jump"
          flow={group}
          surveyLists={state.surveyLists}
          actions={actions}
          surveyStructure={surveyStructure}
          isActive={isActiveGroup}
          isGroup
        />
      )}
      {showGroupFooter && (
        <GroupFooter
          surveyListGroup={surveyListGroup}
          surveyLists={state.surveyLists}
          surveyStructure={surveyStructure}
          actions={actions}
        />
      )}
      {hideBottomDropZone && surveyListGroup.nestingLevel === 2 && (
        <DropZone droppableId={`dropzone-${group.id}-1`} />
      )}
    </div>
  );
};
