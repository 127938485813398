import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import styles from './Bars.module.css';

const Bar = props => {
  const {
    data,
    barsVertical,
    colorsPallete,
    isPercentage,
    isFiltered,
    onBarClick,
    statisticalRelevance
  } = props;

  const [svg, setSvg] = useState(null);

  // Did mount
  useEffect(() => {
    drawChart(svg, props);
  }, []);

  // Did update
  useEffect(
    () => {
      drawChart(svg, props);
    },
    [
      svg,
      data,
      barsVertical,
      colorsPallete,
      isPercentage,
      isFiltered,
      onBarClick,
      statisticalRelevance
    ]
  );

  const limitAnswerStringLength = (answerString, total) => {
    let maxStringLength = total < 6 ? 40 - (total / 2) * 5 : 20;

    if (!barsVertical) {
      maxStringLength = 15;
    }

    if (answerString.length > maxStringLength) {
      return `${answerString.substring(0, maxStringLength)}...`;
    }
    return answerString;
  };

  const drawChart = (svgRef, chartProps) => {
    const margin = { top: 10, right: 30, bottom: 20 };
    margin.left = barsVertical ? 50 : 90;
    const width =
      chartProps.parent.current.offsetWidth -
      (margin.left ? parseInt(margin.left, 10) : 0) -
      (margin.right ? parseInt(margin.right, 10) : 0);
    const height = chartProps.height - margin.top - margin.bottom;

    if (!data || (data && data.length === 0)) return null;

    // Get max number
    let maxNumber = chartProps.statisticalRelevance
      ? 100
      : chartProps.data.reduce((max, num) => {
          /* eslint-disable */
          const numbers = Object.values(num).filter(
            chartPropNum => !isNaN(chartPropNum)
          );
          /* eslint-enable */
          const maxNumberInGroup = Math.max(...numbers);
          return max > maxNumberInGroup ? max : maxNumberInGroup;
        }, 0);

    if (chartProps.statisticalRelevance) {
      let maxStatisticalRelevance = 0;
      Object.values(chartProps.statisticalRelevance).map(singleGroup =>
        Object.values(singleGroup).map(singleAnswerGroup => {
          if (singleAnswerGroup && singleAnswerGroup.max) {
            if (singleAnswerGroup.max > maxStatisticalRelevance)
              maxStatisticalRelevance = singleAnswerGroup.max;
          }
          return null;
        })
      );

      maxNumber =
        maxStatisticalRelevance > maxNumber
          ? maxStatisticalRelevance
          : maxNumber;
    }

    let barColorsPallete = colorsPallete;

    if (isFiltered) {
      barColorsPallete = barColorsPallete.reduce(
        (pallete, color) => [...pallete, ...[color, `${color}33`]],
        []
      );
    }

    const chartSvg = d3
      .select(svgRef)
      .attr('width', chartProps.parent.current.offsetWidth)
      .attr('height', chartProps.height);

    chartSvg.selectAll('*').remove();

    const chart = chartSvg
      .append('g')
      .attr('width', width - margin.left - margin.right)
      .attr('height', height)
      .attr('class', 'results-bar-container')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    let subgroups =
      data &&
      data.length &&
      Object.keys(data[0]).filter(
        singleColumn =>
          singleColumn !== 'group' && !singleColumn.includes('nofilter')
      );

    if (isFiltered) {
      subgroups = subgroups.reduce(
        (all, subgroup) => [...all, ...[subgroup, `${subgroup}_nofilter`]],
        []
      );
    }

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    const groups = d3.map(data, d => d.group).keys();

    const tooltipDiv = d3.select('.tooltip-container').style('display', 'none');

    const onBarClickEvent = (d, i, subgroup) => {
      if (subgroup && subgroup.length) {
        const firstSubgroup = d3.select(subgroup[0]);

        if (firstSubgroup && firstSubgroup.node()) {
          /* eslint-disable */
          const parentNode = firstSubgroup.node().parentNode;
          /* eslint-enable */

          if (d3.select(parentNode)) {
            const subgroupIndex = d3.select(parentNode).attr('subgroupIndex');

            onBarClick(d.key, subgroupIndex);
          }
        }
      }
    };

    // Vertical
    if (barsVertical) {
      // Add X axis
      const x = d3
        .scaleBand()
        .domain(groups)
        .range([0, width])
        .padding([0.2]);
      chart
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .attr('class', 'x')
        .call(
          d3
            .axisBottom(x)
            .tickSize(0)
            .tickFormat(d => limitAnswerStringLength(d, data.length))
        );

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([0, maxNumber])
        .range([height, 0]);
      chart.append('g').call(
        d3.axisLeft(y).tickFormat(e => {
          if (Math.floor(e) !== e) {
            return null;
          }
          return isPercentage ? `${e}%` : e;
        })
      );

      const xSubgroup = d3
        .scaleBand()
        .domain(subgroups)
        .range([0, x.bandwidth()])
        .paddingInner([0])
        .paddingOuter([1]);

      const color = d3
        .scaleOrdinal()
        .domain(subgroups)
        .range(barColorsPallete);

      chart
        .append('g')
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${x(d.group)}, 0)`)
        .attr('subgroupIndex', (d, i) => i)
        .selectAll('rect')
        .data(d => subgroups.map(key => ({ key, value: d[key] })))
        .enter()
        .append('rect')
        .attr('x', d => xSubgroup(d.key))
        .attr('y', d => y(d.value))
        .attr('width', xSubgroup.bandwidth())
        .attr('height', d => height - y(d.value))
        .attr('fill', d => color(d.key))
        .style('opacity', chartProps.statisticalRelevance ? 0.4 : 1.0)
        .style('cursor', 'pointer')
        .on('click', onBarClickEvent)
        .on('mousemove', d => {
          tooltipDiv.style('color', color(d.key)).style('display', 'block');

          tooltipDiv
            .html(`<strong>${isPercentage ? `${d.value}%` : d.value}</strong>`)
            .style('left', `${d3.event.pageX + 5}px`)
            .style('top', `${d3.event.pageY - 28}px`)
            .style('min-width', 'auto')
            .style('font-size', '12px');
        })
        .on('mouseout', () => {
          tooltipDiv.style('display', 'none');
        });

      // X axis tooltip
      d3.selectAll('.x .tick')
        .data(data)
        .on('mousemove', d => {
          tooltipDiv.style('color', '#000000').style('display', 'block');

          tooltipDiv
            .html(d.group)
            .style('left', `${d3.event.pageX + 5}px`)
            .style('top', `${d3.event.pageY - 28}px`)
            .style('min-width', 'auto')
            .style('max-width', '200px')
            .style('font-size', '12px');
        })
        .on('mouseout', () => {
          tooltipDiv.style('display', 'none');
        });

      // Statistical relevance
      if (chartProps.statisticalRelevance) {
        const getYValue = (d, subgroup, valueType) => {
          if (subgroup && subgroup.length) {
            const firstSubgroup = d3.select(subgroup[0]);

            if (firstSubgroup && firstSubgroup.node()) {
              /* eslint-disable */
              const parentNode = firstSubgroup.node().parentNode;
              /* eslint-enable */

              if (d3.select(parentNode)) {
                const subgroupIndex = d3
                  .select(parentNode)
                  .attr('subgroupIndex');

                const maxValue =
                  chartProps.statisticalRelevance[d.key] &&
                  chartProps.statisticalRelevance[d.key][subgroupIndex] &&
                  chartProps.statisticalRelevance[d.key][subgroupIndex][
                    valueType
                  ];

                if (maxValue || maxValue === 0) {
                  return y(maxValue * maxNumber);
                }
              }
            }
          }
          return null;
        };

        // Straight line
        chart
          .append('g')
          .selectAll('g')
          .data(data)
          .enter()
          .append('g')
          .attr(
            'transform',
            d => `translate(${x(d.group) + xSubgroup.bandwidth() / 2}, 0)`
          )
          .attr('subgroupIndex', (d, i) => i)
          .selectAll('rect')
          .data(d => subgroups.map(key => ({ key, value: d[key] })))
          .enter()
          .append('line')
          .style('stroke', d => color(d.key))
          .style('stroke-width', (d, i, subgroup) =>
            getYValue(d, subgroup, 'max') !== null ? 1 : 0
          )
          .attr('x1', d => xSubgroup(d.key))
          .attr('y1', (d, i, subgroup) => getYValue(d, subgroup, 'max'))
          .attr('x2', d => xSubgroup(d.key))
          .attr('y2', (d, i, subgroup) => getYValue(d, subgroup, 'min'));

        // Max horizontal line
        chart
          .append('g')
          .selectAll('g')
          .data(data)
          .enter()
          .append('g')
          .attr(
            'transform',
            d => `translate(${x(d.group) + xSubgroup.bandwidth() / 2}, 0)`
          )
          .attr('subgroupIndex', (d, i) => i)
          .selectAll('rect')
          .data(d => subgroups.map(key => ({ key, value: d[key] })))
          .enter()
          .append('line')
          .style('stroke', d => color(d.key))
          .style('stroke-width', (d, i, subgroup) =>
            getYValue(d, subgroup, 'max') !== null ? 1 : 0
          )
          .attr('x1', d => xSubgroup(d.key) - xSubgroup.bandwidth() / 4)
          .attr('y1', (d, i, subgroup) => getYValue(d, subgroup, 'max'))
          .attr('x2', d => xSubgroup(d.key) + xSubgroup.bandwidth() / 4)
          .attr('y2', (d, i, subgroup) => getYValue(d, subgroup, 'max'));

        // Min bottom line
        chart
          .append('g')
          .selectAll('g')
          .data(data)
          .enter()
          .append('g')
          .attr(
            'transform',
            d => `translate(${x(d.group) + xSubgroup.bandwidth() / 2}, 0)`
          )
          .attr('subgroupIndex', (d, i) => i)
          .selectAll('rect')
          .data(d => subgroups.map(key => ({ key, value: d[key] })))
          .enter()
          .append('line')
          .style('stroke', d => color(d.key))
          .style('stroke-width', (d, i, subgroup) =>
            getYValue(d, subgroup, 'min') !== null ? 1 : 0
          )
          .attr('x1', d => xSubgroup(d.key) - xSubgroup.bandwidth() / 4)
          .attr('y1', (d, i, subgroup) => getYValue(d, subgroup, 'min'))
          .attr('x2', d => xSubgroup(d.key) + xSubgroup.bandwidth() / 4)
          .attr('y2', (d, i, subgroup) => getYValue(d, subgroup, 'min'));
      }
    }

    // Horizontal
    if (!barsVertical) {
      // Add X axis
      const x = d3
        .scaleLinear()
        .domain([0, maxNumber])
        .range([0, width]);
      chart
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(
          d3
            .axisBottom(x)
            .tickSize(0)
            .tickFormat(e => {
              if (Math.floor(e) !== e) {
                return null;
              }
              return isPercentage ? `${e}%` : e;
            })
        );

      // Add Y axis
      const y = d3
        .scaleBand()
        .domain(groups)
        .range([0, height])
        .padding([0.2]);
      chart
        .append('g')
        .attr('class', 'y')
        .call(
          d3
            .axisLeft(y)
            .tickFormat(d => limitAnswerStringLength(d, data.length))
        );

      const ySubgroup = d3
        .scaleBand()
        .domain(subgroups)
        .range([0, y.bandwidth()])
        .paddingInner([0])
        .paddingOuter([1]);

      const color = d3
        .scaleOrdinal()
        .domain(subgroups)
        .range(barColorsPallete);

      chart
        .append('g')
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', d => `translate(0, ${y(d.group)})`)
        .attr('subgroupIndex', (d, i) => i)
        .selectAll('rect')
        .data(d => subgroups.map(key => ({ key, value: d[key] })))
        .enter()
        .append('rect')
        .attr('x', x(0))
        .attr('y', d => ySubgroup(d.key))
        .attr('width', d => x(d.value))
        .attr('height', ySubgroup.bandwidth())
        .attr('fill', d => color(d.key))
        .style('opacity', chartProps.statisticalRelevance ? 0.4 : 1.0)
        .style('cursor', 'pointer')
        .on('click', onBarClickEvent)
        .on('mousemove', d => {
          tooltipDiv.style('color', color(d.key)).style('display', 'block');

          tooltipDiv
            .html(`<strong>${isPercentage ? `${d.value}%` : d.value}</strong>`)
            .style('left', `${d3.event.pageX + 5}px`)
            .style('top', `${d3.event.pageY - 28}px`)
            .style('min-width', 'auto')
            .style('font-size', '12px');
        })
        .on('mouseout', () => {
          tooltipDiv.style('display', 'none');
        });

      // y axis tooltip
      d3.selectAll('.y .tick')
        .data(data)
        .on('mousemove', d => {
          tooltipDiv.style('color', '#000000').style('display', 'block');

          tooltipDiv
            .html(d.group)
            .style('left', `${d3.event.pageX + 5}px`)
            .style('top', `${d3.event.pageY - 28}px`)
            .style('min-width', 'auto')
            .style('max-width', '200px')
            .style('font-size', '12px');
        })
        .on('mouseout', () => {
          tooltipDiv.style('display', 'none');
        });

      // Statistical relevance
      if (chartProps.statisticalRelevance) {
        const getXValue = (d, subgroup, valueType) => {
          if (subgroup && subgroup.length) {
            const firstSubgroup = d3.select(subgroup[0]);

            if (firstSubgroup && firstSubgroup.node()) {
              /* eslint-disable */
              const parentNode = firstSubgroup.node().parentNode;
              /* eslint-enable */

              if (d3.select(parentNode)) {
                const subgroupIndex = d3
                  .select(parentNode)
                  .attr('subgroupIndex');

                const maxValue =
                  chartProps.statisticalRelevance[d.key] &&
                  chartProps.statisticalRelevance[d.key][subgroupIndex] &&
                  chartProps.statisticalRelevance[d.key][subgroupIndex][
                    valueType
                  ];

                if (maxValue || maxValue === 0) {
                  return x(maxValue * maxNumber);
                }
              }
            }
          }
          return null;
        };

        // Straight line
        chart
          .append('g')
          .selectAll('g')
          .data(data)
          .enter()
          .append('g')
          .attr(
            'transform',
            d => `translate(0, ${y(d.group) + ySubgroup.bandwidth() / 2})`
          )
          .attr('subgroupIndex', (d, i) => i)
          .selectAll('rect')
          .data(d => subgroups.map(key => ({ key, value: d[key] })))
          .enter()
          .append('line')
          .style('stroke', d => color(d.key))
          .style('stroke-width', (d, i, subgroup) =>
            getXValue(d, subgroup, 'min') !== null ? 1 : 0
          )
          .attr('x1', (d, i, subgroup) => getXValue(d, subgroup, 'max'))
          .attr('y1', d => ySubgroup(d.key))
          .attr('x2', (d, i, subgroup) => getXValue(d, subgroup, 'min'))
          .attr('y2', d => ySubgroup(d.key));

        // Max horizontal line
        chart
          .append('g')
          .selectAll('g')
          .data(data)
          .enter()
          .append('g')
          .attr(
            'transform',
            d => `translate(0, ${y(d.group) + ySubgroup.bandwidth() / 2})`
          )
          .attr('subgroupIndex', (d, i) => i)
          .selectAll('rect')
          .data(d => subgroups.map(key => ({ key, value: d[key] })))
          .enter()
          .append('line')
          .style('stroke', d => color(d.key))
          .style('stroke-width', (d, i, subgroup) =>
            getXValue(d, subgroup, 'min') !== null ? 1 : 0
          )
          .attr('x1', (d, i, subgroup) => getXValue(d, subgroup, 'min'))
          .attr('y1', d => ySubgroup(d.key) - ySubgroup.bandwidth() / 4)
          .attr('x2', (d, i, subgroup) => getXValue(d, subgroup, 'min'))
          .attr('y2', d => ySubgroup(d.key) + ySubgroup.bandwidth() / 4);

        // Min bottom line
        chart
          .append('g')
          .selectAll('g')
          .data(data)
          .enter()
          .append('g')
          .attr(
            'transform',
            d => `translate(0, ${y(d.group) + ySubgroup.bandwidth() / 2})`
          )
          .attr('subgroupIndex', (d, i) => i)
          .selectAll('rect')
          .data(d => subgroups.map(key => ({ key, value: d[key] })))
          .enter()
          .append('line')
          .style('stroke', d => color(d.key))
          .style('stroke-width', (d, i, subgroup) =>
            getXValue(d, subgroup, 'max') !== null ? 1 : 0
          )
          .attr('x1', (d, i, subgroup) => getXValue(d, subgroup, 'max'))
          .attr('y1', d => ySubgroup(d.key) - ySubgroup.bandwidth() / 4)
          .attr('x2', (d, i, subgroup) => getXValue(d, subgroup, 'max'))
          .attr('y2', d => ySubgroup(d.key) + ySubgroup.bandwidth() / 4);
      }
    }

    return null;
  };

  return (
    <div>
      {data && data.length ? (
        <svg
          className={styles.container}
          ref={elem => {
            if (elem) {
              setSvg(elem);
            }
          }}
        />
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default Bar;
