import React, { useState } from 'react';

import tickIcon from '../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../assets/img/delete.svg';
import tickIconWhite from '../../../../../../../../assets/img/tick_white.svg';

import styles from './HidePopup.module.css';

const HidePopup = ({ onClose, onCreateHiddenWordCloudWord, activeWords }) => {
  const [selectedWords, setSelectedWords] = useState(activeWords);

  const onPopupClick = e => {
    e.stopPropagation();
  };

  const getSelectedWords = () => {
    const checkedSelectedWords = selectedWords.filter(
      selectedWord => selectedWord.visible
    );
    return checkedSelectedWords;
  };

  return (
    <div
      role="presentation"
      className={styles.popupContainer}
      onClick={onClose}
    >
      <div role="presentation" className={styles.popup} onClick={onPopupClick}>
        <div className={styles.mainTitle}>Block</div>
        <div className={styles.mainText}>
          <div>
            Following words will be permanently removed from all surveys. You
            cannot undo this action unless you have access to Forest.
          </div>
          <div className={styles.wordsToHideList}>
            {selectedWords && selectedWords.length
              ? selectedWords.map((word, i) => (
                  <div className={styles.wordsToHideListItem}>
                    <div
                      role="presentation"
                      className={`${styles.visibleBox} ${
                        word.visible ? styles.active : null
                      }`}
                      onClick={() => {
                        const wordsArray = [...selectedWords];
                        const updatedWord = {
                          ...wordsArray[i],
                          visible: !selectedWords[i].visible
                        };
                        wordsArray[i] = updatedWord;
                        setSelectedWords(wordsArray);
                      }}
                    >
                      <img
                        src={tickIconWhite}
                        className={styles.visibleTick}
                        alt="Tick"
                      />
                    </div>
                    <span className={styles.wordsToHideListItemText}>
                      {word.text}
                    </span>
                    <span>({word.value})</span>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className={styles.actions}>
          <img
            src={tickIcon}
            alt="confirm"
            className={`${styles.confirmIcon} ${
              getSelectedWords().length > 0
                ? styles.confirmIconActive
                : styles.confirmIconDisabled
            }`}
            role="presentation"
            onClick={() => {
              if (getSelectedWords().length > 0) {
                onCreateHiddenWordCloudWord(getSelectedWords());
                onClose();
              }
            }}
          />
          <img
            src={closeIcon}
            alt="close"
            className={styles.closeIcon}
            role="presentation"
            onClick={() => onClose()}
          />
        </div>
      </div>
    </div>
  );
};

export default HidePopup;
