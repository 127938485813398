import { useState, useEffect } from 'react';

export default (ref, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(
    () => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin
        }
      );

      if (ref && observer) {
        observer.observe(ref);
      }
      return () => {
        if (ref && observer) {
          observer.unobserve(ref);
        }
      };
    },
    [ref]
  ); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
};
