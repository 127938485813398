import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';

import ShowInsideScreen from '../../../ShowInsideScreen/ShowInsideScreen';

import styles from './ColorPicker.module.css';

export default ({ colorCode, onChange, style, fixedPosition }) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerContainerRef, setPickerContainerRef] = useState(null);
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const [leftPosition, setLeftPosition] = useState(-1000); // hacky but works
  const [internalValue, setInternalValue] = useState(colorCode);

  const wrapperRef = useRef(null);

  useEffect(
    () => {
      if (fixedPosition && wrapperRef && wrapperRef.current) {
        const height = wrapperRef.current.offsetHeight;
        const rect = wrapperRef.current.getBoundingClientRect();
        const win = wrapperRef.current.ownerDocument.defaultView;

        setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
        setLeftPosition(rect.left + win.pageXOffset - window.scrollX);
      }
    },
    [pickerVisible]
  );

  let positionStyle = {
    top: '33px',
    left: '10px',
    bottomPosition: '33px'
  };

  if (fixedPosition && leftPosition >= 0 && topPosition >= 0) {
    positionStyle = {
      left: `${leftPosition}px`,
      top: `${topPosition}px`,
      bottomPosition: `${topPosition}px`
    };
  }

  return (
    <div
      className={styles.container}
      role="presentation"
      onClick={() => setPickerVisible(true)}
      style={style || {}}
      ref={wrapperRef}
    >
      <div
        className={styles.colorRectangle}
        style={{ backgroundColor: colorCode }}
      />
      <div className={styles.colorCode}>{colorCode}</div>
      {pickerVisible && [
        <ShowInsideScreen
          key="show-inside-screen-picker"
          positionStyle={positionStyle}
          childReference={pickerContainerRef}
          onBlur={() => {
            setPickerVisible(false);
            onChange(internalValue.hex || internalValue);
          }}
        >
          <div
            className={
              fixedPosition
                ? styles.fixedPickerContainer
                : styles.pickerContainer
            }
            ref={r => setPickerContainerRef(r)}
          >
            <ChromePicker
              disableAlpha
              color={internalValue}
              onChangeComplete={color => {
                setInternalValue(color);
              }}
            />
          </div>
        </ShowInsideScreen>,
        <div
          key="show-inside-screen-cover"
          className={styles.transparentPageConver}
        />
      ]}
    </div>
  );
};
