export default (setFunction, i) => {
  setFunction(tooltips =>
    tooltips.map(t => {
      if (t.index === i) {
        return {
          ...t,
          click: !t.click
        };
      }
      return t;
    })
  );
};
