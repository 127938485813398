import gql from 'graphql-tag';

export const GET_APP_CONFIGURATION = gql`
  query GetAppConfiguration {
    getAppConfiguration {
      geodata
      profileOptions {
        genders {
          label
          value
        }
        civilStatus {
          label
          value
        }
        newOccupations {
          label
          value
          routes {
            options
            nextStep {
              label
              value
              options
              nextStep {
                label
                value
                options
                nextStep {
                  label
                  value
                  options
                }
              }
              routes {
                options
                nextStep {
                  label
                  value
                  options
                }
              }
            }
            routes {
              options
              nextStep {
                label
                value
                options
              }
            }
          }
        }
        countries {
          label
          value
        }
        languages {
          label
          value
        }
        newHasChildren {
          label
          value
        }
        livingArrangement {
          label
          value
        }
        surroundings {
          label
          value
        }
        degree {
          label
          value
        }
        signedUpThrough {
          label
          value
        }
      }
    }
  }
`;

export const GET_ACTIVE_USERS = gql`
  query Me(
    $appliedFilters: [CommunityDashboardStatisticsAppliedFiltersInput]
    $range: String
    $filterRelation: String
  ) {
    activeUsersStatistics(
      input: {
        appliedFilters: $appliedFilters
        range: $range
        filterRelation: $filterRelation
      }
    ) {
      buckets {
        name
        value
        previousValue
      }
      activeUserTodayCount
    }
  }
`;

export const GET_RESPONSE_RATE = gql`
  query Me(
    $appliedFilters: [CommunityDashboardStatisticsAppliedFiltersInput]
    $range: String
    $filterRelation: String
  ) {
    responseRateStatistics(
      input: {
        appliedFilters: $appliedFilters
        range: $range
        filterRelation: $filterRelation
      }
    ) {
      buckets {
        name
        value
      }
      timestamp
    }
  }
`;

export const GET_COMMUNITY_SIZE = gql`
  query Me(
    $appliedFilters: [CommunityDashboardStatisticsAppliedFiltersInput]
    $range: String
    $filterRelation: String
  ) {
    communitySizeStatistics(
      input: {
        appliedFilters: $appliedFilters
        range: $range
        filterRelation: $filterRelation
      }
    ) {
      buckets {
        name
        value
      }
    }
  }
`;
