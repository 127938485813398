import React, { useState, useEffect } from 'react';

import TemplatesWizardContext from './TemplatesWizardContext';
import SelectStart from './components/Steps/SelectStart/SelectStart';
import SelectLanguage from './components/Steps/SelectLanguage/SelectLanguage';
import SelectTemplateType from './components/Steps/SelectTemplateType/SelectTemplateType';
import SelectTestsNumber from './components/Steps/SelectTestsNumber/SelectTestsNumber';
import DefinePoints from './components/Steps/DefinePoints/DefinePoints';
import SetScale from './components/Steps/SetScale/SetScale';
import SetSuccessCriteria from './components/Steps/SetSuccessCriteria/SetSuccessCriteria';
import LogicMap from './components/Steps/LogicMap/LogicMap';
import SetContext from './components/Steps/SetContext/SetContext';

import {
  MC_QUESTION_OPTIONS,
  TEMPLATES_WIZARD_STEP_KEYS,
  SURVEY_LANGUAGES,
  TEMPLATES_WIZARD_START_OPTIONS,
  TEMPLATES_WIZARD_TEMPLATE_TYPES,
  TEMPLATES_SCALES
} from '../../helpers/constants';

import enStrings from './strings/en';
import nlStrings from './strings/nl';
import frStrings from './strings/fr';

import styleText from './helpers/styleText';

import closeIcon from '../../../../../../assets/img/delete.svg';

import styles from './TemplatesWizard.module.css';

export default ({ setWizardHidden, actions, surveyId, surveyLanguage }) => {
  const [currentStepKey, setCurrentStepKey] = useState(
    TEMPLATES_WIZARD_STEP_KEYS.SELECT_START
  );
  const [strings, setStrings] = useState(enStrings);

  const getWizardLanguageFromSurveyConfiguration = () => {
    if (surveyLanguage === SURVEY_LANGUAGES.DUTCH)
      return SURVEY_LANGUAGES.DUTCH;
    if (surveyLanguage === SURVEY_LANGUAGES.FRENCH)
      return SURVEY_LANGUAGES.FRENCH;

    // For English and German
    return SURVEY_LANGUAGES.ENGLISH;
  };

  const [steps, setSteps] = useState([
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.SELECT_START,
      component: <SelectStart />,
      value: TEMPLATES_WIZARD_START_OPTIONS.SELECT_TEMPLATE
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.SELECT_LANGUAGE,
      component: <SelectLanguage />,
      value: getWizardLanguageFromSurveyConfiguration()
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE,
      component: <SelectTemplateType />,
      value: TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.SET_CONTEXT,
      component: <SetContext />,
      value: null
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER,
      component: <SelectTestsNumber />,
      value: 3
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.DEFINE_POINTS,
      component: <DefinePoints />,
      value: []
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.SET_SCALE,
      component: <SetScale />,
      value: {
        type: TEMPLATES_SCALES.MULTIPLE_CHOICE,
        values: MC_QUESTION_OPTIONS[0]
      }
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA,
      component: <SetSuccessCriteria />,
      value: []
    },
    {
      key: TEMPLATES_WIZARD_STEP_KEYS.LOGIC_MAP,
      component: <LogicMap />,
      value: null
    }
  ]);

  const currentStep = steps.find(s => s.key === currentStepKey);

  const setStepValue = (stepKey, value) => {
    const stepsToEdit = steps;

    const setSingleStep = (sK, v) => {
      const stepToEdit = stepsToEdit.find(s => s.key === sK);
      stepToEdit.value = v;

      // Load language strings on language key change
      /*
      if (sK === TEMPLATES_WIZARD_STEP_KEYS.SELECT_LANGUAGE) {
        if (v === SURVEY_LANGUAGES.ENGLISH) {
          setStrings(enStrings);
        }
        if (v === SURVEY_LANGUAGES.DUTCH) {
          setStrings(nlStrings);
        }
        if (v === SURVEY_LANGUAGES.FRENCH) {
          setStrings(frStrings);
        }
      }
      */
    };

    if (Array.isArray(stepKey)) {
      stepKey.forEach((sK, sKi) => setSingleStep(sK, value[sKi]));
    } else {
      setSingleStep(stepKey, value);
    }

    setSteps([...stepsToEdit]);
  };

  const goToNextStep = () => {
    const currentStepKeyIndex = steps.findIndex(s => s.key === currentStepKey);

    let nextStepIndexIncrease = 1;
    if (currentStepKeyIndex === 2) {
      // For Problem Validation template type SET_CONTEXT step is skipped
      const templateTypeStep = steps.find(
        s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
      );

      if (
        templateTypeStep.value ===
        TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
      ) {
        nextStepIndexIncrease = 2;
      }
    }

    // Skip language wizard step
    if (currentStepKeyIndex === 0) {
      nextStepIndexIncrease = 2;
    }

    const nextStepKey = steps[currentStepKeyIndex + nextStepIndexIncrease].key;
    setCurrentStepKey(nextStepKey);
  };

  const goToPreviousStep = () => {
    const currentStepKeyIndex = steps.findIndex(s => s.key === currentStepKey);

    let nextStepIndexDecrease = 1;
    if (currentStepKeyIndex === 4) {
      // For Problem Validation template type SET_CONTEXT step is skipped
      const templateTypeStep = steps.find(
        s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
      );

      if (
        templateTypeStep.value ===
        TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
      ) {
        nextStepIndexDecrease = 2;
      }
    }

    // Skip language wizard step
    if (currentStepKeyIndex === 2) {
      nextStepIndexDecrease = 2;
    }

    const previousStepKey =
      steps[currentStepKeyIndex - nextStepIndexDecrease].key;
    setCurrentStepKey(previousStepKey);
  };

  const resetWizartInputData = () => {
    setStepValue(
      [
        TEMPLATES_WIZARD_STEP_KEYS.DEFINE_POINTS,
        TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA
      ],
      [[], []]
    );
  };

  useEffect(
    () => {
      if (
        surveyLanguage === SURVEY_LANGUAGES.ENGLISH ||
        surveyLanguage === SURVEY_LANGUAGES.GERMAN
      ) {
        setStrings(enStrings);
      }
      if (surveyLanguage === SURVEY_LANGUAGES.DUTCH) {
        setStrings(nlStrings);
      }
      if (surveyLanguage === SURVEY_LANGUAGES.FRENCH) {
        setStrings(frStrings);
      }
    },
    [surveyLanguage]
  );

  return (
    <TemplatesWizardContext.Provider
      value={{
        steps,
        setSteps,
        currentStepKey,
        setCurrentStepKey,
        currentStep,
        setStepValue,
        goToNextStep,
        goToPreviousStep,
        setWizardHidden,
        strings,
        styleText,
        actions,
        resetWizartInputData,
        surveyId
      }}
    >
      <img
        className={styles.closePopupButton}
        src={closeIcon}
        alt="Close icon"
        onClick={() => setWizardHidden(true)}
        role="presentation"
      />
      {currentStep.component}
    </TemplatesWizardContext.Provider>
  );
};
