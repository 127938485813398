import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from '@apollo/react-hooks';

import { GET_PROFILING_QUESTIONS } from '../../graphql/Question';

import AppBar from '../../base/components/AppBar/AppBar';
import ProfileQuestionsTable from './components/ProfileQuestionsTable/ProfileQuestionsTable';
import ProfileTagFilters from './components/ProfileTagFilters/ProfileTagFilters';

import styles from './ProfileTagsManagement.module.css';

const RECORDS_PER_PAGE = 20;

export default props => {
  const [appliedFilters, setAppliedFilters] = useState({ language: 'Dutch' });
  const [page, setPage] = useState(0);

  const {
    data: { getProfilingQuestions: { questions, maxCount } = {} } = {},
    loading: surveyLoading,
    refetch: surveyRefetch
  } = useQuery(GET_PROFILING_QUESTIONS, {
    variables: {
      start: page * RECORDS_PER_PAGE,
      end: page * RECORDS_PER_PAGE + RECORDS_PER_PAGE,
      ...appliedFilters
    },
    fetchPolicy: 'no-cache'
  });

  return (
    <div>
      <AppBar {...props} />

      <div className={styles.container}>
        <div className={styles.title}>Profile Tags Management</div>
        <ProfileTagFilters
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
        <div className={styles.resultsContainer}>
          <ProfileQuestionsTable
            loading={surveyLoading}
            maxCount={maxCount}
            questions={questions}
            surveyRefetch={surveyRefetch}
          />
        </div>
        {maxCount ? (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={Math.ceil(maxCount / RECORDS_PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            initialPage={page}
            forcePage={page}
            disableInitialCallback
            onPageChange={pageNumber => setPage(pageNumber.selected)}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </div>
  );
};
