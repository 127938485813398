import React from 'react';

import check from '../../../../../../assets/img/check.svg';

import styles from './ErrorOccuredPopup.module.css';

export default ({ onClick }) => (
  <div
    className={styles.container}
    role="presentation"
    onClick={() => onClick()}
  >
    <div className={styles.title}>Error</div>
    <div className={styles.description}>
      A server error occurred. Please contact the administrator.
    </div>
    <img alt="Checkmark" src={check} className={styles.checkmark} />
  </div>
);
