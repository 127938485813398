import React, { useState } from 'react';

import tickIcon from '../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../assets/img/delete.svg';
import tickIconWhite from '../../../../../../../../assets/img/tick_white.svg';
import errorIcon from '../../../../../../../../assets/img/exclamation.svg';

import styles from './MergePopup.module.css';

const MergePopup = ({
  words,
  setWords,
  activeWords,
  updateWordCloudsActiveWords,
  mergedWords,
  updateWordCloudsMergedWords,
  selectedMergedWord,
  onClose,
  action,
  mergePopupActions,
  newWordCloud,
  onCreateWordCloud,
  onUpdateWordCloud
}) => {
  const [newMergedName, setNewMergedName] = useState(
    selectedMergedWord ? selectedMergedWord.text : ''
  );
  const [wordExistsError, setWordExistsError] = useState(false);

  let initialSelectedWords;
  if (selectedMergedWord && action === mergePopupActions.EDIT) {
    initialSelectedWords = selectedMergedWord.parentWords;
  } else if (selectedMergedWord) {
    const nonAlreadyMergedWords = activeWords.filter(
      word => !word.parentWords.length
    );
    initialSelectedWords = [
      ...nonAlreadyMergedWords,
      ...selectedMergedWord.parentWords
    ];
  } else {
    initialSelectedWords = activeWords;
  }

  const [selectedWords, setSelectedWords] = useState(initialSelectedWords);

  const onPopupClick = e => {
    e.stopPropagation();
  };

  const getParentWordsCombinedValue = () => {
    let totalValue = 0;
    const activeSelectedWords = selectedWords.filter(
      selectedWord => selectedWord.visible
    );
    activeSelectedWords.forEach(selectedWord => {
      totalValue += selectedWord.value;
    });
    return totalValue;
  };

  const getSelectedWords = () => {
    const checkedSelectedWords = selectedWords.filter(
      selectedWord => selectedWord.visible
    );
    return checkedSelectedWords;
  };

  const getUnSelectedWords = () => {
    const unCheckedSelectedWords = selectedWords.filter(
      selectedWord => !selectedWord.visible
    );
    return unCheckedSelectedWords;
  };

  const getSelectedWordsLength = () => {
    let allSelectedWords = getSelectedWords();
    if (selectedMergedWord && action === mergePopupActions.ADD) {
      allSelectedWords = allSelectedWords.filter(
        word => !selectedMergedWord.parentWords.includes(word)
      );
    }
    return allSelectedWords.length;
  };

  return (
    <div
      role="presentation"
      className={styles.popupContainer}
      onClick={onClose}
    >
      <div role="presentation" className={styles.popup} onClick={onPopupClick}>
        <div className={styles.mainTitle}>Merge</div>
        <div className={styles.wordsToMerge}>
          <div>Merged word includes</div>
          <div className={styles.wordsToMergeList}>
            {selectedWords && selectedWords.length
              ? selectedWords.map((word, i) => (
                  <div className={styles.wordsToMergeListItem}>
                    <div
                      role="presentation"
                      className={`${styles.visibleBox} ${
                        word.visible ? styles.active : null
                      } ${
                        selectedMergedWord &&
                        action === mergePopupActions.ADD &&
                        selectedMergedWord.parentWords.find(
                          parentWord => parentWord.text === word.text
                        )
                          ? styles.disabled
                          : ''
                      }`}
                      onClick={() => {
                        // existing parentWords can only be altered when editing merged word
                        if (
                          !selectedMergedWord ||
                          !(
                            selectedMergedWord &&
                            action === mergePopupActions.ADD &&
                            selectedMergedWord.parentWords.find(
                              parentWord => parentWord.text === word.text
                            )
                          )
                        ) {
                          const wordsArray = [...selectedWords];
                          const updatedWord = {
                            ...wordsArray[i],
                            visible: !selectedWords[i].visible
                          };
                          wordsArray[i] = updatedWord;
                          setSelectedWords(wordsArray);
                        }
                      }}
                    >
                      <img
                        src={tickIconWhite}
                        className={styles.visibleTick}
                        alt="Tick"
                      />
                    </div>
                    <span className={styles.wordsToMergeListItemText}>
                      {word.text}
                    </span>
                    <span>({word.value})</span>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className={styles.popupTitle}>Name</div>
        <div className={styles.inputContainer}>
          <input
            className={styles.inputContainerInput}
            placeholder="merged word name"
            value={newMergedName}
            onChange={e => setNewMergedName(e.target.value)}
          />
        </div>
        {action === mergePopupActions.EDIT &&
        getSelectedWords().length === 0 ? (
          <div className={styles.errorContainer}>
            <img src={errorIcon} alt="Warning" className={styles.errorIcon} />
            You are about to delete the merged word. The original words will
            reappear in the list.
          </div>
        ) : null}
        {wordExistsError ? (
          <div className={styles.errorContainer}>
            <img src={errorIcon} alt="Warning" className={styles.errorIcon} />
            This name already exists.
          </div>
        ) : null}
        <div className={styles.actions}>
          <img
            src={tickIcon}
            alt="confirm"
            className={`${styles.confirmIcon} ${
              newMergedName.length &&
              (getSelectedWordsLength() > 0 ||
                action === mergePopupActions.EDIT)
                ? styles.confirmIconActive
                : styles.confirmIconDisabled
            }`}
            role="presentation"
            onClick={() => {
              if (
                newMergedName.length &&
                (getSelectedWordsLength() > 0 ||
                  action === mergePopupActions.EDIT)
              ) {
                const wordsToBeMerged = getSelectedWords();
                const newMergedWord = {
                  text: newMergedName.toLowerCase(),
                  value: getParentWordsCombinedValue(),
                  visible: true,
                  parentWords: wordsToBeMerged
                };
                if (
                  words.find(
                    word =>
                      word.text === newMergedWord.text &&
                      !newMergedWord.parentWords.find(
                        parentWord => parentWord.text === newMergedWord.text
                      ) &&
                      (!selectedMergedWord ||
                        (selectedMergedWord &&
                          selectedMergedWord.text !== newMergedWord.text))
                  )
                ) {
                  setWordExistsError(true);
                } else {
                  if (selectedMergedWord && action === mergePopupActions.EDIT) {
                    let newGeneralWords = [...words];
                    let updatedMergedWords = [...mergedWords];
                    let newActiveWords = [...activeWords];

                    if (newMergedWord.parentWords.length === 0) {
                      // if no parentWords left then disband word
                      // REMOVE word from all states
                      newGeneralWords = newGeneralWords.filter(
                        word => word.text !== selectedMergedWord.text
                      );
                      updatedMergedWords = updatedMergedWords.filter(
                        word => word.text !== selectedMergedWord.text
                      );
                      newActiveWords = newActiveWords.filter(
                        word => word.text !== selectedMergedWord.text
                      );
                    } else {
                      // UPDATE general words
                      newGeneralWords[
                        newGeneralWords.findIndex(
                          mergedWord =>
                            mergedWord.text === selectedMergedWord.text
                        )
                      ] = newMergedWord;

                      // UPDATE word in merged state
                      updatedMergedWords[
                        updatedMergedWords.findIndex(
                          mergedWord =>
                            mergedWord.text === selectedMergedWord.text
                        )
                      ] = newMergedWord;

                      // UPDATE word in active state
                      newActiveWords[
                        newActiveWords.findIndex(
                          mergedWord =>
                            mergedWord.text === selectedMergedWord.text
                        )
                      ] = newMergedWord;
                    }

                    // ADD if there are unselected words back in general words
                    const unSelectedWords = getUnSelectedWords();
                    if (unSelectedWords && unSelectedWords.length) {
                      setWords([...newGeneralWords, ...unSelectedWords]);
                    } else {
                      setWords(newGeneralWords);
                    }
                    updateWordCloudsMergedWords(updatedMergedWords);
                    onUpdateWordCloud(updatedMergedWords);
                    updateWordCloudsActiveWords(newActiveWords);
                  } else if (selectedMergedWord) {
                    // ADD to existing merged word

                    // REMOVE prev words from general state and UDPATE merged word in general state
                    const newGeneralWords = words.filter(
                      word =>
                        !wordsToBeMerged.find(
                          wordToBeMerged => wordToBeMerged.text === word.text
                        )
                    );
                    newGeneralWords[
                      newGeneralWords.findIndex(
                        mergedWord =>
                          mergedWord.text === selectedMergedWord.text
                      )
                    ] = newMergedWord;
                    setWords(newGeneralWords);

                    // UPDATE word in merged state
                    const updatedMergedWords = [...mergedWords];
                    updatedMergedWords[
                      updatedMergedWords.findIndex(
                        mergedWord =>
                          mergedWord.text === selectedMergedWord.text
                      )
                    ] = newMergedWord;
                    updateWordCloudsMergedWords(updatedMergedWords);
                    onUpdateWordCloud(updatedMergedWords);

                    // REMOVE prev words from active state and UDPATE merged word in active state
                    const newActiveWords = activeWords.filter(
                      word =>
                        !wordsToBeMerged.find(
                          wordToBeMerged => wordToBeMerged.text === word.text
                        )
                    );
                    newActiveWords[
                      newActiveWords.findIndex(
                        mergedWord =>
                          mergedWord.text === selectedMergedWord.text
                      )
                    ] = newMergedWord;
                    updateWordCloudsActiveWords(newActiveWords);
                  } else {
                    // ADD new merged word

                    // REMOVE GENERAL STATE
                    const newGeneralWords = words.filter(
                      word =>
                        !wordsToBeMerged.find(
                          wordToBeMerged => wordToBeMerged.text === word.text
                        )
                    );
                    setWords([...newGeneralWords, newMergedWord]);

                    // ADD MERGED STATE
                    updateWordCloudsMergedWords([
                      ...mergedWords,
                      newMergedWord
                    ]);

                    // ADD ACTIVE STATE and REMOVE PREVIOUS WORDS
                    const newActiveWords = activeWords.filter(
                      word =>
                        !wordsToBeMerged.find(
                          wordToBeMerged => wordToBeMerged.text === word.text
                        )
                    );
                    updateWordCloudsActiveWords([
                      ...newActiveWords,
                      newMergedWord
                    ]);

                    if (newWordCloud) {
                      onCreateWordCloud([
                        {
                          text: newMergedWord.text,
                          visible: newMergedWord.visible,
                          parentWords: newMergedWord.parentWords.map(
                            parentWord => parentWord.text
                          )
                        }
                      ]);
                    } else {
                      onUpdateWordCloud([...mergedWords, newMergedWord]);
                    }
                  }
                  onClose();
                }
              }
            }}
          />
          <img
            src={closeIcon}
            alt="close"
            className={styles.closeIcon}
            role="presentation"
            onClick={() => onClose()}
          />
        </div>
      </div>
    </div>
  );
};

export default MergePopup;
