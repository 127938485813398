import React from 'react';
import { Redirect, Link } from 'react-router-dom';

import AuthWelcome from './AuthWelcome';
import './LoginRegistration.css';
import bufflLogo from '../../assets/img/buffl.svg';

import getQueryStringParams from '../../helpers/getQueryStringParams';

/* global window, localStorage */

const LoginForm = props => {
  const {
    fields,
    isAuthenticated,
    isProcessing,
    onChange,
    onSubmit,
    errors,
    location
  } = props;

  const queryParameters = getQueryStringParams(location.search);

  const backend = localStorage.getItem('backend')
    ? localStorage.getItem('backend')
    : '';

  const onChangeBackend = event => {
    localStorage.setItem('backend', event.target.value);
    window.location.reload();
  };

  const SUCCESS_MESSAGES = {
    PASSWORD_RESET_SUCCESS: 'Your password has been changed successfully'
  };

  if (isAuthenticated) {
    if (queryParameters && queryParameters.beforeLogin) {
      return <Redirect to={queryParameters.beforeLogin} />;
    }
    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from.pathname
    ) {
      return <Redirect to={location.state.from.pathname} />;
    }
    return <Redirect to="/" />;
  }

  return (
    <div className="right-container">
      <AuthWelcome />
      <div className="form-container">
        <form
          onSubmit={ev =>
            onSubmit(
              ev,
              fields.email.value,
              fields.password.value,
              queryParameters.token
            )
          }
        >
          <img className="buffl-logo" src={bufflLogo} alt="Buffl Logo" />
          <div className="form-container-title">Sign In</div>
          <input
            type="text"
            className="email-input"
            value={fields.email.value}
            onChange={onChange('email')}
            placeholder="Email"
          />
          <input
            type="password"
            className="password-input"
            value={fields.password.value}
            onChange={onChange('password')}
            placeholder="Password"
          />
          {(window.location.hostname.indexOf('localhost') >= 0 ||
            window.location.hostname.indexOf('test.buffl.be') >= 0) && (
            <select
              type="backend"
              className="backend-input"
              onChange={onChangeBackend}
              value={backend}
              placeholder="Backend"
            >
              <option value="">Default</option>
              <option value="local">Local</option>
              <option value="test">QA</option>
              <option value="prod">Production</option>
            </select>
          )}
          {errors && errors[0] && !isProcessing ? (
            <div className="error-message">{errors[0].detail}</div>
          ) : null}
          {queryParameters.passwordReset &&
          queryParameters.passwordReset === 'success' ? (
            <div className="success-message">
              {SUCCESS_MESSAGES.PASSWORD_RESET_SUCCESS}
            </div>
          ) : null}
          {!isProcessing ? (
            <button type="submit" className="button">
              Log In
            </button>
          ) : (
            <div className="button-loading">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
          <span className="link">
            <Link to="/auth/register">Don&#39;t have an account? Sign up</Link>
          </span>
          <div className="link forgot-password">
            <Link
              to={`/auth/password/reset${
                fields.email.value && fields.email.value !== ''
                  ? `?email=${fields.email.value}`
                  : ''
              }`}
            >
              Forgot password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
