import getColorCode from './getColorCode';

export default (deltaValue, missingValue, actualValue) => {
  const missingColorValue = 1 - missingValue / (actualValue + missingValue); // Number between 0-1

  return {
    ...deltaValue,
    yValue: actualValue + missingValue,
    missingValue,
    color: getColorCode(missingColorValue)
  };
};
