const favorites = '🐃😀😂😉😎😍🤔👍👎👊👌👋💪🙏💩';
const smileys =
  '😀😁😂🤣😃😄😅😆😉😊😋😎😍😘🥰😗😙😚🙂🤗🤩🤔🤨😐😑😶🙄😏😣😥😮🤐😯😪😫😴😌😛😜😝🤤😒😓😔😕🙃🤑😲🙁😖😞😟😤😢😭😦😧😨😩🤯😬😰😱😳🤪😵😡😠🤬😷🤒🤕🤢🤮🤧😇🤠🤡🤥🤫🤭🧐🤓😈👿👹👺💀👻👽🤖💩';
const people = '👶👧🧒👵👴👰🤵👪🎅🙌👍👎👊👌👋💪🙏👀';
const animalsNature =
  '🐃🐂🐮🐶🐱🐻🦁🐷🐵🙈🐔🐤🦆🦅🦉🦋🐞🌲🌴🌞🌙⛅️⛄️🌍🌟🔥🌈🌊';
const foodDrinks = '🍏🍎🍋🍌🍉🍓🍗🍖🍔🍕🥪🥙🍩🍪🎂☕️🥤🍺🥂🍾🍴';
const activitySports = '⚽️🏀🏈🎱🛹🎿🏆🎧🎸🎲🎯🎳';
const travelPlaces = '🚗🚕🚌🚑🚒🚚🚲🛵🚔🚝🛫🚁🚀🚢⚓️⛽️🎢🗻🏠🌅🌇';
const objects = '⌚️📱💻📷🎥📞📺💰🛌🚿📝🎁🎉📆🛒🔎⏳';
const symbols = '💖💔❌♻️✅💯🚹🚺🎵💲🔉🔊🔔🕒';

const EmojiCategories = [
  {
    title: 'BUFFL favorites',
    emojis: [...favorites]
  },
  {
    title: 'Smileys',
    emojis: [...smileys]
  },
  {
    title: 'People',
    emojis: [...people]
  },
  {
    title: 'Animals & nature',
    emojis: [...animalsNature]
  },
  {
    title: 'Food & drinks',
    emojis: [...foodDrinks]
  },
  {
    title: 'Activity & sports',
    emojis: [...activitySports]
  },
  {
    title: 'Travel & places',
    emojis: [...travelPlaces]
  },
  {
    title: 'Objects',
    emojis: [...objects]
  },
  {
    title: 'Symbols',
    emojis: [...symbols]
  }
];

export default EmojiCategories;
