import React, { useState, useRef } from 'react';

import * as mixpanel from '../../../../../../../../../mixpanel';

import tickIcon from '../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../assets/img/delete.svg';
import tickIconWhite from '../../../../../../../../assets/img/tick_white.svg';
import addIcon from '../../../../../../../../assets/img/add.svg';

import styles from './CategoryPopup.module.css';

const CategoryPopup = ({ onClose, openQuestionCategories, onComplete }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [categories, setCategories] = useState(openQuestionCategories);
  const categoryListRef = useRef();
  const inputRef = useRef();

  const onPopupClick = e => {
    e.stopPropagation();
  };

  const checkExistingCategories = () => {
    if (!categories || !categories.length) {
      return <div>No existing categories</div>;
    }
    if (
      categories &&
      categories.length &&
      categories.find(category => category.includes(searchValue))
    ) {
      return <div>Existing categories</div>;
    }
    return <div className={styles.disabled}>Category not found</div>;
  };

  const scrollToBottom = async () => {
    await setSearchValue('');
    categoryListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      role="presentation"
      className={styles.popupContainer}
      onClick={onClose}
    >
      <div role="presentation" className={styles.popup} onClick={onPopupClick}>
        <div className={styles.mainTitle}>Add to category</div>
        <div className={styles.mainText}>
          <div className={styles.categoryActions}>
            <div className={styles.searchAnswerInputContainer}>
              <input
                className={styles.searchAnswerInput}
                type="text"
                placeholder="Create a category..."
                value={searchValue}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
                ref={inputRef}
              />
              {searchValue && searchValue.length && (
                <span
                  className={styles.closeIcon}
                  role="presentation"
                  onClick={() => {
                    setSearchValue('');
                    if (inputRef && inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                >
                  t
                </span>
              )}
            </div>
            <div
              className={`${styles.actionButton} ${
                searchValue &&
                searchValue.length &&
                (!categories.length ||
                  (categories.length &&
                    !categories.find(category => category === searchValue)))
                  ? ''
                  : styles.disabledButton
              }`}
              role="presentation"
              onClick={() => {
                if (
                  searchValue &&
                  searchValue.length &&
                  (!categories.length ||
                    (categories.length &&
                      !categories.find(category => category === searchValue)))
                ) {
                  setCategories([...categories, searchValue]);
                  setSelectedCategories([...selectedCategories, searchValue]);
                  scrollToBottom();
                  mixpanel.actions.trackEvent(
                    mixpanel.events.CLIENT_CREATE_NEW_OPEN_ANSWER_CATEGORY,
                    {
                      [mixpanel.eventProperties
                        .OPEN_ANSWER_CATEGORY]: searchValue
                    }
                  );
                }
              }}
            >
              <img src={addIcon} className={styles.addIcon} alt="Category" />
              <span className={styles.text}>Add</span>
            </div>
          </div>
          {checkExistingCategories()}
          <div className={styles.categoryList}>
            {categories && categories.length
              ? categories.map(category =>
                  category.includes(searchValue) ? (
                    <div className={styles.categoryListItem} key={category}>
                      <div
                        role="presentation"
                        className={`${styles.visibleBox} ${
                          selectedCategories.includes(category)
                            ? styles.active
                            : null
                        }`}
                        onClick={() => {
                          if (selectedCategories.includes(category)) {
                            setSelectedCategories(
                              selectedCategories.filter(
                                selectedCategory =>
                                  selectedCategory !== category
                              )
                            );
                          } else {
                            setSelectedCategories([
                              ...selectedCategories,
                              category
                            ]);
                          }
                        }}
                      >
                        <img
                          src={tickIconWhite}
                          className={styles.visibleTick}
                          alt="Tick"
                        />
                      </div>
                      <span className={styles.categoryListItemText}>
                        {category}
                      </span>
                    </div>
                  ) : null
                )
              : null}
            <div ref={categoryListRef} />
          </div>
        </div>
        <div className={styles.actions}>
          <img
            src={tickIcon}
            alt="confirm"
            className={`${styles.confirmIcon} ${
              selectedCategories && selectedCategories.length
                ? styles.confirmIconActive
                : styles.confirmIconDisabled
            }`}
            role="presentation"
            onClick={() => {
              if (selectedCategories && selectedCategories.length) {
                onComplete(selectedCategories);
                onClose();
              }
            }}
          />
          <img
            src={closeIcon}
            alt="close"
            className={styles.closeIcon}
            role="presentation"
            onClick={() => onClose()}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryPopup;
