import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import AppBar from '../../base/components/AppBar/AppBar';
import Footer from '../../base/components/Footer/Footer';

import { GET_ORGANISATION_DETAILS } from '../../graphql/Organisation';
import styles from './OrganisationsRoute.module.css';

import DutchStrings from '../../strings/nl';
import FrenchStrings from '../../strings/fr';

const OrganisationsRoute = props => {
  const {
    component: Component,
    location,
    userTokens,
    organisation,
    onSetOrganisation,
    computedMatch,
    ...rest
  } = props;

  const stringsMapping = {
    Dutch: DutchStrings,
    French: FrenchStrings
  };

  let isLoggedIn = false;

  const [language, setLanguage] = useState('NL');

  const getOrganisationDetails = token =>
    useQuery(GET_ORGANISATION_DETAILS, {
      variables: { token },
      fetchPolicy: 'network-only',
      onCompleted: response => {
        if (response && response.organisation) {
          if (
            response.organisation.language &&
            response.organisation.language === 'French'
          ) {
            setLanguage('FR');
          }
          onSetOrganisation(response.organisation);
        }
      },
      onError: () => {
        localStorage.removeItem('@buffl:auth-token');
        window.location.href = '/organisations/login';
        onSetOrganisation(null);
      }
    });

  if (computedMatch && computedMatch.params && computedMatch.params.token) {
    getOrganisationDetails(computedMatch.params.token);
  } else {
    getOrganisationDetails(null);
    isLoggedIn = true;
  }

  useEffect(() => {
    document.documentElement.style.backgroundColor = '#FFFFFF';
    document.getElementsByTagName('body')[0].style.backgroundColor = '#FFFFFF';
  }, []);

  const pageContent = (renderProps, strings) => (
    <Component {...renderProps} isLoggedIn={isLoggedIn} strings={strings} />
  );

  if (organisation) {
    const strings = stringsMapping[organisation.language];

    if (!strings) return null;

    return (
      <div className={styles.container}>
        <div className={styles.mainContainer}>
          <AppBar
            organisation={organisation}
            isLoggedIn={isLoggedIn}
            strings={language === 'NL' ? DutchStrings : FrenchStrings}
            onLogoClick={() => {
              window.location.href = '/organisations/';
            }}
            language={language}
            setLanguage={setLanguage}
          />
          <Route
            {...rest}
            render={renderProps =>
              pageContent(
                renderProps,
                language === 'NL' ? DutchStrings : FrenchStrings
              )
            }
          />
        </div>
        <Footer strings={language === 'NL' ? DutchStrings : FrenchStrings} />
      </div>
    );
  }
  return null;
};

export default OrganisationsRoute;
