import React, { useState, memo } from 'react';

import TooltipContainer from '../TooltipContainer/TooltipContainer';
import Icon from '../../../../../../../../../Icon/Icon';

import styles from './AddNewAnswer.module.css';

export default memo(
  ({
    allowOther,
    allowNoneOfTheAbove,
    questionId,
    actions,
    setChoiceIndexToSelect,
    choicesLength,
    otherAnswerIndex,
    noneOfTheAboveAnswerIndex,
    notAllowedTextContentEdit,
    isMatrixQuestion,
    matrixAnswerLabel,
    decisionFrameworkType,
    matrixLimitWarningPopupType
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const addNewAnswer = (other, noneOfTheAbove) => {
      let customAttributes = {};
      if (isMatrixQuestion && decisionFrameworkType) {
        customAttributes = {
          ...customAttributes,
          decisionFrameworkType
        };
      }
      actions.addQuestionChoice(
        questionId,
        customAttributes,
        other,
        noneOfTheAbove
      );
      let newIndex = choicesLength;
      if (other) {
        newIndex =
          noneOfTheAboveAnswerIndex > -1
            ? noneOfTheAboveAnswerIndex
            : choicesLength;
      } else if (!noneOfTheAbove) {
        if (noneOfTheAboveAnswerIndex > -1) {
          newIndex -= 1;
        }
        if (otherAnswerIndex > -1) {
          newIndex -= 1;
        }
      }
      setChoiceIndexToSelect(newIndex);

      if (isMatrixQuestion && choicesLength === 6) {
        actions.toggleSurveyBuilderPopup({
          type: matrixLimitWarningPopupType,
          onClose: () => actions.toggleSurveyBuilderPopup()
        });
      }
    };

    return (
      <div
        className={
          notAllowedTextContentEdit
            ? `${styles.addAnswerContainer} ${styles.disabled}`
            : styles.addAnswerContainer
        }
      >
        <div
          role="presentation"
          className={styles.addAnswerButton}
          style={{ marginRight: 6 }}
          onClick={() => {
            if (!notAllowedTextContentEdit) {
              addNewAnswer(false, false);
            }
          }}
        >
          <Icon type="add" className={styles.addAnswerIcon} />
          {!isMatrixQuestion ? 'Add answer' : matrixAnswerLabel}
        </div>
        {!allowOther && !isMatrixQuestion && (
          <div
            role="presentation"
            className={styles.addAnswerButton}
            style={{ marginRight: 6 }}
            onClick={() => {
              if (!notAllowedTextContentEdit) {
                addNewAnswer(true, false);
              }
            }}
          >
            <Icon type="add" className={styles.addAnswerIcon} />
            Add open answer
          </div>
        )}
        {!allowNoneOfTheAbove && !isMatrixQuestion && (
          <div
            role="presentation"
            className={styles.addAnswerButton}
            style={{ marginRight: 6 }}
            onClick={() => {
              if (!notAllowedTextContentEdit) {
                addNewAnswer(false, true);
              }
            }}
          >
            <Icon type="add" className={styles.addAnswerIcon} />
            Add none of the above
          </div>
        )}
        {!isMatrixQuestion && (
          <div>
            <span
              role="presentation"
              className={styles.questionMark}
              onClick={() => {
                if (!notAllowedTextContentEdit) {
                  setShowTooltip(prev => !prev);
                }
              }}
            >
              ?
            </span>
            {showTooltip && (
              <TooltipContainer setShowTooltip={setShowTooltip} />
            )}
          </div>
        )}
      </div>
    );
  }
);
