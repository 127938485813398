import React, { memo } from 'react';

import { Droppable } from 'react-beautiful-dnd';
import styles from './QuestionColumn.module.css';

const getQuestionsSetClassName = (isInGroup, isDraggingOver) => {
  let className = styles.questionSet;

  if (isInGroup) {
    className = `${className} ${styles.setInGroup}`;
  }

  if (isDraggingOver) {
    className = `${className} ${styles.dragging}`;
  }

  return className;
};

export default memo(({ elements, setIndex, isInGroup }) => (
  <Droppable droppableId={`questions-set-${setIndex}`}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        className={getQuestionsSetClassName(isInGroup, snapshot.isDraggingOver)}
      >
        {elements}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
));
