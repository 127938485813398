export const recordActionPopups = {
  ACTIONS: 'ACTIONS',
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  GLOBAL_BUDGET_OVERVIEW: 'GLOBAL_BUDGET_OVERVIEW',
  GLOBAL_SCOPING_SURVEYS: 'GLOBAL_SCOPING_SURVEYS',
  LICENSE_OVERVIEW: 'LICENSE_OVERVIEW'
};

export const invitationTypes = {
  SPOC: 'SPOC',
  TEAM_LEAD: 'TEAM_LEAD',
  MEMBER: 'MEMBER'
};

export const licenceTypes = {
  UNLIMITED: 'UNLIMITED',
  ESSENTIAL: 'ESSENTIAL',
  PLUS: 'PLUS',
  ADVANCED: 'ADVANCED',
  ORGANISATION: 'ORGANISATION',
  FRIEND: 'FRIEND',
  FRIENDS_WITH_BENEFITS: 'FRIENDS_WITH_BENEFITS',
  PARTNER: 'PARTNER'
};

export const AccountManagementAddEditPopupTabs = {
  BASIC_SETTINGS: 'BASIC_SETTINGS',
  ACCESS_TO_COMMUNITY: 'ACCESS_TO_COMMUNITY',
  ACCESS_TO_TECHNOLOGY: 'ACCESS_TO_TECHNOLOGY'
};

export const creditsChangeType = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
  NO_CHANGE: 'NO_CHANGE'
};
