export default questions =>
  questions && questions.length
    ? questions.reduce((reducedQuestions, q) => {
        let newQuestions = [...reducedQuestions];
        if (
          q.type === 'Multiple Choice Question' ||
          q.type === 'Slider' ||
          (q.type === 'Matrix' &&
            q.matrix &&
            q.matrix.xQuestionId &&
            q.matrix.yQuestionId)
        ) {
          if (
            q.type === 'Matrix' &&
            q.matrix &&
            q.matrix.decisionFrameworkType !== 'SINGLE'
          ) {
            // Create two matrix questions (x and y)
            newQuestions = [
              ...newQuestions,
              ...[
                {
                  ...q,
                  id: q.matrix.xQuestionId,
                  question: q.matrix.xQuestion
                },
                { ...q, id: q.matrix.yQuestionId, question: q.matrix.yQuestion }
              ]
            ];
          } else {
            newQuestions = [...newQuestions, q];
          }
        }
        return newQuestions;
      }, [])
    : [];
