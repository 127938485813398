import React, { useState, useEffect } from 'react';

import Loader from '../../../../../Loader/Loader';
import Feature from '../EditGraphsPopup/Templates/components/GraphFeatures/Feature/Feature';

import { templateConfigurations, templateTypes } from '../../helpers/constants';
import getAvailableQuestions from '../../helpers/getAvailableQuestions';
import getMatrixQuestions from '../../helpers/getMatrixQuestions';
import validateTemplateGraph from '../EditGraphsPopup/helpers/validateTemplateGraph';

import tickIcon from '../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../assets/img/delete.svg';
import warningIcon from '../../../../../../../assets/img/exclamation.svg';

import styles from './EditFeaturePopup.module.css';

export default ({
  templateType,
  questions,
  features,
  feature,
  onClose,
  onSaveFeature,
  dataPointColors
}) => {
  const isLoading = false;
  const [featureField, setFeatureField] = useState(null);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [error, setError] = useState('');

  const templateConfiguration = templateConfigurations[templateType];

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = JSON.parse(question)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
    } catch (e) {
      questionValue = question;
    }
    return questionValue;
  };

  useEffect(
    () => {
      const availableQs =
        feature && feature.choiceId
          ? getMatrixQuestions(questions)
          : getAvailableQuestions(
              questions,
              questions,
              features.map(f => ({
                ...f,
                [templateConfiguration.xQuestionName]: questions.find(
                  q => q.id === f[templateConfiguration.xQuestionName]
                ),
                [templateConfiguration.yQuestionName]: questions.find(
                  q => q.id === f[templateConfiguration.yQuestionName]
                )
              })),
              templateConfiguration.xQuestionName,
              templateConfiguration.yQuestionName
            ).map(q => ({ ...q, question: parseQuestion(q.question) }));

      setAvailableQuestions(availableQs);

      setFeatureField({
        ...feature,
        [templateConfiguration.xQuestionName]: availableQs.find(
          q => q.id === feature[templateConfiguration.xQuestionName]
        ),
        [templateConfiguration.yQuestionName]: availableQs.find(
          q => q.id === feature[templateConfiguration.yQuestionName]
        )
      });
    },
    [feature]
  );

  let isMatrixQuestionFeature = false;
  if (feature && feature.choiceId) {
    isMatrixQuestionFeature = true;
  }

  if (featureField) {
    return (
      <div className={styles.container}>
        <div className={styles.templateTitle}>
          Edit data point
          <div className={styles.bottomLine} />
        </div>
        {!isMatrixQuestionFeature && (
          <div
            className={`${styles.description} ${styles.templateDescription}`}
          >
            {templateConfiguration.description}
          </div>
        )}
        <div>
          <Feature
            feature={featureField}
            availableQuestions={availableQuestions}
            updateFeatureField={(field, value) => {
              setFeatureField({
                ...featureField,
                [field]: value
              });
            }}
            xQuestionName={templateConfiguration.xQuestionName}
            yQuestionName={templateConfiguration.yQuestionName}
            placeholders={templateConfiguration.placeholders}
            questionsTypeLabel="rating"
            setFeaturesScrollingDisabled={() => {}}
            scrollFeaturesContainerToTheBottom={false}
            newFeatureAdded={false}
            dataPointColors={dataPointColors}
            editSingleDataPoint
          />
        </div>
        <div style={{ paddingTop: '8px' }}>
          <div className={styles.bottomLine} />
        </div>
        <div className={styles.footer}>
          {isLoading ? (
            <div className={styles.actions}>
              <Loader size="small" />
            </div>
          ) : (
            <>
              {error.length ? (
                <>
                  <div className={styles.footerErrorBackground} />
                  <div className={styles.error}>
                    <img
                      className={styles.warningIcon}
                      src={warningIcon}
                      alt="Warning icon"
                    />
                    {error}
                  </div>
                </>
              ) : (
                <div className={styles.details} />
              )}
              <div className={styles.actions}>
                <img
                  className={styles.confirm}
                  src={tickIcon}
                  alt="Confirm icon"
                  onClick={() => {
                    const errorExists = validateTemplateGraph(
                      [featureField],
                      'mergeQuestionName',
                      setError,
                      templateTypes,
                      templateType,
                      templateConfiguration
                    );
                    if (!errorExists) {
                      // When saving feature we save xQuestion and yQuestion ID not whole question
                      const newFeature = {
                        ...featureField,
                        [templateConfiguration.xQuestionName]:
                          featureField[templateConfiguration.xQuestionName].id,
                        [templateConfiguration.yQuestionName]:
                          featureField[templateConfiguration.yQuestionName].id
                      };
                      onSaveFeature(newFeature);
                      onClose();
                    }
                  }}
                  role="presentation"
                />
                <img
                  className={styles.close}
                  src={closeIcon}
                  alt="Close icon"
                  onClick={() => onClose()}
                  role="presentation"
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  return null;
};
