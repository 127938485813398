import React, { useState } from 'react';

import styles from './InsightsTextarea.module.css';
import RichTextAction from '../../../../../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/components/QuestionsColumn/components/SidePropertiesContainer/components/RichTextAction/RichTextAction';
import Draft from '../../../../../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/components/QuestionsColumn/components/QuestionContainer/components/Draft/Draft';

export default ({ internalData, setInternalData }) => {
  const [activeDraftButtons, setActiveDraftButtons] = useState([]);
  const [addDraftEmoji, setAddDraftEmoji] = useState(null);
  const [toggleDraftButton, setToggleDraftButton] = useState(null);

  const onToggleDraftButton = style => {
    setToggleDraftButton(style);
    if (activeDraftButtons.indexOf(style) >= 0) {
      setActiveDraftButtons(activeDraftButtons.filter(s => s !== style));
    } else {
      setActiveDraftButtons([...activeDraftButtons, style]);
    }
  };

  const textActions = [
    {
      label: 'B',
      name: 'toggle-bold-text',
      type: 'message-text-style',
      style: 'BOLD',
      active: activeDraftButtons.indexOf('BOLD') >= 0,
      action: onToggleDraftButton
    },
    {
      label: 'I',
      name: 'toggle-italic-text',
      type: 'message-text-style',
      style: 'ITALIC',
      active: activeDraftButtons.indexOf('ITALIC') >= 0,
      action: onToggleDraftButton
    },
    {
      label: 'U',
      name: 'toggle-underline-text',
      type: 'message-text-style',
      style: 'UNDERLINE',
      active: activeDraftButtons.indexOf('UNDERLINE') >= 0,
      action: onToggleDraftButton
    },
    {
      label: 'E',
      name: 'toggle-underline-text',
      type: 'rich-text-emoji',
      emoji: '',
      action: e => setAddDraftEmoji(e)
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.draftContainer}>
        <Draft
          addDraftEmoji={addDraftEmoji}
          setAddDraftEmoji={setAddDraftEmoji}
          setActiveDraftButtons={setActiveDraftButtons}
          toggleDraftButton={toggleDraftButton}
          setToggleDraftButton={setToggleDraftButton}
          isActiveQuestion
          value={(internalData && internalData.description) || ''}
          placeholder="Type here"
          onChangeQuestionProperty={(property, value) =>
            setInternalData({
              ...internalData,
              description: value
            })
          }
        />
      </div>

      <div className={`${styles.blockContent} ${styles.textContainer}`}>
        {textActions.map((action, index) => (
          <RichTextAction
            key={`active-block-properties-action-${index.toString()}`}
            action={action}
            isActiveQuestion
          />
        ))}
      </div>
    </div>
  );
};
