export default date => {
  if (!date) return null;

  const monthShortNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const convertDate = new Date(date);

  const day = convertDate.getDate();
  const monthIndex = convertDate.getMonth() + 1;
  const year = convertDate.getFullYear();

  // Today
  const today = new Date();

  // Yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (
    day === today.getDate() &&
    monthIndex === today.getMonth() + 1 &&
    year === today.getFullYear()
  ) {
    return `${convertDate.getHours()}:${
      convertDate.getMinutes() < 10 ? '0' : ''
    }${convertDate.getMinutes()}, Today`;
  }

  if (
    day === yesterday.getDate() &&
    monthIndex === yesterday.getMonth() + 1 &&
    year === yesterday.getFullYear()
  ) {
    return 'Yesterday';
  }

  if (year === today.getFullYear())
    return `${day} ${monthShortNames[monthIndex - 1]}`;
  return `${day} ${monthShortNames[monthIndex - 1]}, ${year}`;
};
