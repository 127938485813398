import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';
import createDecisionFramework from '../createDecisionFramework/createDecisionFramework';

const ObjectID = require('bson-objectid');

export default (
  state,
  flow,
  destinationPosition,
  destinationQuestionId,
  newQuestionId
) => {
  const newFlow = {
    ...flow,
    id: newQuestionId || ObjectID().toString()
  };

  const requests = [];

  if (!newQuestionId) {
    // Remove question jumps
    if (newFlow) {
      if (newFlow.nextFlow) {
        delete newFlow.nextFlow;
      }

      if (newFlow.end) {
        delete newFlow.end;
      }
    }

    if (newFlow.choices && newFlow.choices.length) {
      newFlow.choices = newFlow.choices.map(c => {
        const choice = { ...c };

        choice.id = ObjectID().toString();

        if (choice.nextFlow) {
          delete choice.nextFlow;
        }
        if (choice.end) {
          delete choice.end;
        }

        return choice;
      });
    }

    if (newFlow.branches && newFlow.branches.length) {
      newFlow.branches = newFlow.branches.map(b => {
        const branch = { ...b };

        branch.id = ObjectID().toString();

        if (branch.nextFlow) {
          delete branch.nextFlow;
        }
        if (branch.end) {
          delete branch.end;
        }

        return branch;
      });
    }

    if (newFlow.matrix && newFlow.matrix.decisionFrameworkType) {
      newFlow.matrix = {
        ...newFlow.matrix,
        xQuestionId: ObjectID().toString(),
        yQuestionId: ObjectID().toString()
      };
    }
  }

  const surveyToModify = JSON.parse(JSON.stringify(state.survey));

  const questionDestinationPosition = {
    position: destinationPosition,
    questionId: destinationQuestionId
  };

  if (!destinationQuestionId) {
    surveyToModify.content.flows = [
      newFlow.id,
      ...surveyToModify.content.flows
    ];
  } else if (
    surveyToModify.content.flows.indexOf(
      questionDestinationPosition.questionId
    ) > -1
  ) {
    const destinationQuestionIndex = surveyToModify.content.flows.indexOf(
      questionDestinationPosition.questionId
    );

    const destinationIndex =
      questionDestinationPosition.position === 'BEFORE'
        ? destinationQuestionIndex
        : destinationQuestionIndex + 1;

    surveyToModify.content.flows.splice(destinationIndex, 0, newFlow.id);
  } else {
    const group = surveyToModify.content.groups.find(
      g => g.flows.indexOf(questionDestinationPosition.questionId) > -1
    );

    if (group) {
      const destinationQuestionIndex = group.flows.indexOf(
        questionDestinationPosition.questionId
      );

      const destinationIndex =
        questionDestinationPosition.position === 'BEFORE'
          ? destinationQuestionIndex
          : destinationQuestionIndex + 1;

      group.flows.splice(destinationIndex, 0, newFlow.id);
    }
  }

  surveyToModify.questions[newFlow.id] = newFlow;

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      content: surveyToModify.content,
      questions: surveyToModify.questions
    }
  };

  if (!newQuestionId) {
    // Set new question as active question
    newState.activeFlow = newFlow;
  }

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  // Sort distributor groups according to branch jumps
  sortDistributorGroupsAndJumps(newState);

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  if (newFlow.matrix && newFlow.matrix.decisionFrameworkType) {
    const {
      surveyWithCreatedFramework,
      createRequest
    } = createDecisionFramework(newState.survey, newFlow);
    if (surveyWithCreatedFramework) {
      newState = {
        ...newState,
        survey: surveyWithCreatedFramework
      };
      if (createRequest) {
        requests.push(createRequest);
      }
    }
  }

  return { newState, duplicatedQuestion: newFlow, requests };
};
