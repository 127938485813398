import React from 'react';

import CommunityAutomationMainDiagram from './components/CommunityAutomationMainDiagram/CommunityAutomationMainDiagram';
import CommunityAutomationPropertesColumn from './components/CommunityAutomationPropertesColumn/CommunityAutomationPropertesColumn';

import styles from './CommunityAutomationMainContainer.module.css';

export default ({
  communityAutomation,
  setCommunityAutomation,
  selectedCommunityAutomationStepIndex,
  setSelectedCommunityAutomationStepIndex
}) => (
  <div className={styles.container}>
    <div className={styles.mainDiagramContainer}>
      <CommunityAutomationMainDiagram
        communityAutomation={communityAutomation}
        setCommunityAutomation={setCommunityAutomation}
        selectedCommunityAutomationStepIndex={
          selectedCommunityAutomationStepIndex
        }
        setSelectedCommunityAutomationStepIndex={
          setSelectedCommunityAutomationStepIndex
        }
      />
    </div>
    <div className={styles.rightColumnContainer}>
      <CommunityAutomationPropertesColumn
        communityAutomation={communityAutomation}
        setCommunityAutomation={setCommunityAutomation}
        selectedCommunityAutomationStepIndex={
          selectedCommunityAutomationStepIndex
        }
        setSelectedCommunityAutomationStepIndex={
          setSelectedCommunityAutomationStepIndex
        }
      />
    </div>
  </div>
);
