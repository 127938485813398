import React, { useState } from 'react';
import Icon from '../../../../../../campaigns/components/Icon';
import Button from '../../../../../../campaigns/components/Button/Button';
import DebounceInput from '../../../../../../campaigns/components/DebounceInput/DebounceInput';

import styles from './CommunityAutomationsHeader.module.css';

export default ({ title, onAddButtonLabel, onAddClick, search, onSearch }) => {
  const [searchExpanded, setSearchExpanded] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.rightContainer}>
        <div className={styles.searchContainer}>
          {searchExpanded ? (
            <div className={styles.searchInputContainer}>
              <DebounceInput
                className={styles.questionContainingInput}
                initialValue={search}
                onChangeValue={searchText => onSearch(searchText)}
                placeholder="Type search here"
              />
              <span
                role="presentation"
                className={styles.close}
                onClick={() => {
                  onSearch('');
                  setSearchExpanded(false);
                }}
              >
                <Icon type="close" />
              </span>
            </div>
          ) : (
            <div
              onClick={() => setSearchExpanded(true)}
              className={styles.searchIcon}
              role="presentation"
            >
              <Icon type="search" />
            </div>
          )}
        </div>
        <Button
          role="button"
          label={onAddButtonLabel}
          onClick={() => onAddClick()}
          icon="add"
        />
      </div>
    </div>
  );
};
