import React, { Component } from 'react';
import CampaignTableContainer from '../containers/CampaignTableContainer';
import AppBar from '../../base/components/AppBar/AppBar';
import './PortalPage.css';

class PortalPage extends Component {
  componentDidMount() {
    // this.props.onLoad();
  }

  render() {
    return (
      <div>
        <AppBar {...this.props} />
        <CampaignTableContainer {...this.props} />
      </div>
    );
  }
}

export default PortalPage;
