import React from 'react';

import Popup from '../../../../../Popup';
import ManageWorkspace from '../ManageWorkspace/ManageWorkspace';

import noWorkspacesHeardy from '../../../../../../../assets/img/no-workspaces-heardy.svg';
import editIcon from '../../../../../../../assets/img/edit.svg';

import styles from './NoWorkspaces.module.css';

const NoWorkspaces = ({
  surveyId,
  blocks,
  setSelectedWorkspace,
  authorized,
  showCreateWorkspacePopup,
  setShowCreateWorkspacePopup
}) => (
  <div
    className={`${styles.container} ${
      authorized ? styles.authorizedContainer : ''
    } workspaces-no-workspace-page`}
  >
    <img
      className={styles.heardy}
      src={noWorkspacesHeardy}
      alt="No Workspaces Herdy"
    />
    {authorized ? (
      <>
        <div className={styles.title}>
          Uh oh, there’s nothing to analyze yet.
        </div>
        <div className={styles.text}>
          Get started now by choosing which results you would like to analyze.
        </div>
        <div
          className={`${
            styles.newWorkspaceButton
          } workspaces-new-workspace-button`}
          role="presentation"
          onClick={() => setShowCreateWorkspacePopup(true)}
        >
          <img src={editIcon} alt="Edit icon" className={styles.editIcon} />
          Edit Workspace
        </div>
      </>
    ) : (
      <>
        <div className={styles.title}>
          Uh oh, you don’t have access to this area.
        </div>
        <div className={styles.text}>
          Contact your company representative in order to gain access to this
          workspace and start analyzing your data.
        </div>
      </>
    )}
    {showCreateWorkspacePopup ? (
      <Popup
        component={
          <ManageWorkspace
            surveyId={surveyId}
            blocks={blocks}
            onClose={() => setShowCreateWorkspacePopup(false)}
            setSelectedWorkspace={setSelectedWorkspace}
          />
        }
        onClose={() => setShowCreateWorkspacePopup(false)}
      />
    ) : null}
  </div>
);

export default NoWorkspaces;
