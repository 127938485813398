export default {
  CONFIRM: 'Confirm',
  TERMS: 'Terms',
  SURVEY: 'Survey',
  YOUR_INFO: 'Your Info',
  THANKS: 'Thanks',
  BY_CLICK_AGREE:
    'By clicking Agree and Continue, I hereby agree and consent to the terms of: the Terms and Conditions and the Privacy Policy',
  AGREE_AND_CONTINUE: 'Agree and continue',
  BACK: 'Back',
  NEXT: 'Next',
  I_AM_AWARE_OF_THE: 'I have read and understand the',
  READ_AND_UNDERSTAND: '',
  THE_TERMS_AND_CONDITIONS:
    'The general terms and conditions and the privacy policy',
  TERMS_AND_CONDITIONS: 'terms and conditions',
  AND_AGREE_BY_CHECKING_THIS_BOX: 'and agree to these by checking this box.',
  AND_CONFIRM_I_AGREE: 'and confirm that I agree.',
  I_AGREE: 'I agree',
  GOVERNED_BY_BUFFL_POLICY:
    'This survey is governed by the privacy policy of BUFFL.\nPlease review our',
  PRIVACY_POLICY: 'privacy policy',
  BEFORE_PROCEEDING: 'before proceeding.',
  AGREE_TO_PROCEED: 'Do you agree to proceed?',
  YES_I_AGREE: 'Yes, I agree',
  NO_I_DO_NOT_AGREE: 'No, I do not agree',
  PLEASE_SELECT_VALUE: 'Please select ',
  PLEASE_ENTER: 'Please enter',
  WE_ARE_ALMOST_THERE:
    'We are almost there, we just want to get to know you a little better!',
  FEMALE: 'Female',
  MALE: 'Male',
  OTHER: 'Other',
  GENDER: 'Gender',
  AGE: 'Age',
  POSTAL_CODE: 'Postal code',
  CREATE_USER_PROFILE: 'Create user profile',
  PASSWORD: 'Password',
  REPEAT_PASSWORD: 'Repeat password',
  PLEASE_REPEAT_PASSWORD: 'Please repeat password',
  WE_NEED_YOUR_EMAIL: 'We need your email address.',
  SO_WE_CAN_SEND_YOU_EMAIL:
    '.. So we can send you a verification email to validate your survey',
  EMAIL: 'Email',
  SO_YOU_CAN_BE_CONTACTED_IF_WIN:
    '.. So you can be contacted when you win a prize',
  YES_I_WANT_TO_BE_CONTACTED_IF_WIN: 'Yes, I want to be contacted if I win',
  SO_YOU_CAN_BE_CONTACTED_FOR_THE_NEXT_PARTS:
    '.. So you can be contacted for the next parts of the survey',
  YES_I_WANT_TO_BE_CONTACTED_FOR_NEXT_SURVEYS:
    'Yes, I want to be contacted for the next surveys',
  SO_YOU_CAN_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    '.. So you can be added to the mailing list',
  YES_I_WANT_TO_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    'I want to be added to the mailing list',
  SO_YOU_CAN_HAVE_OWN_BUFFL_LOGIN: '..So you can have your own BUFFL login.',
  WHAT_IS_BUFFL_ABOUT: 'What is BUFFL about?',
  AT_BUFFL_YOU_CAN_HELP_DETERMINE_PRODUCTS:
    'At BUFFL you can help determine what the products, services and brands of tomorrow should look like. On The BUFFL mobile app, you can participate in more surveys and you get rewarded with digital gift vouchers!',
  THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE: 'Thank you!',
  THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE:
    'The survey you are looking for is currently no longer available.',
  CHECKOUT_AWESOME_BUFFL_APP:
    'Check out the awesome BUFFL app for more surveys and get rewarded with awesome prizes.',
  START: 'Start',
  ALL_FIELDS_ARE_REQUIRED: 'All fields are required',
  PASSWORDS_ARE_NOT_SAME: 'Passwords are not the same',
  EMAIL_IS_REQUIRED: 'Email is required',
  WE_HAVE_SENT_YOU_EMAIL_WITH_CONFIRMATION_LINK:
    'We have sent you an e-mail with a confirmation link to your e-mail address. It is possible that we got sent to your spam folder.',
  RESULT_ACCEPTED: 'Result accepted.',
  YOUR_RESULTS_HAS_BEEN_CONFIRMED:
    'Your result has been sucessfully confirmed.',
  SURVEY_FAILED: 'Thanks a lot for participating!',
  UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE: 'Thank you!',
  UNFORTUNATELY: 'Unfortunately',
  UNFORTUNATELY_ON_FAILED_PAGE: 'Unfortunately',
  UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE: 'Unfortunately',
  YOU_ARE_NOT_ELIGIBLE:
    'Your profile doesn’t match the requirements for this research.',
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED:
    "Thank you for wanting to share your opinion. We already have enough respondents with a similar profile to yours. Don't worry, new surveys will be coming online soon. Do you want to be the first to know? Then download the BUFFL app now!",
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE:
    "Thank you for wanting to share your opinion. We already have enough respondents for this survey. Don't worry, new surveys will be coming online soon. Do you want to be the first to know? Then download the BUFFL app now!",
  RESULT_CONFIRMED: 'Result confirmed.',
  YOUR_RESULT_WAS_ALREADY_REGISTERED_SUCESSFULY:
    'Your answer was already registered successfully!',
  IF_YOU_LIKE_PARTICIPATING_IN_MORE_SURVEYS:
    'If you like participating in more surveys and earn awesome prizes, install our BUFFL app!',
  CONFIRM_EMAIL: 'Confirm Email.',
  MINIMAL_CHARACTERS: 'Minimal characters',
  MAXIMAL_CHARACTERS: 'Maximal characters',
  START_A_NEW_SURVEY: 'Start a new survey',
  READ_FULL_TERMS_AND_CONDITIONS: 'Read full Terms and Conditions',
  READ_FULL_PRIVACY_POLICY: 'Read full Privacy Policy',

  WELCOME: 'Welcome',
  WELCOME_STEP: 'Welcome',
  USER_PROFILE: 'User profile',
  CONFIRMATION: 'Confirmation',
  WE_MAKE_YOU_PLEASANT_HERE:
    'We want to make it as pleasant as possible for you throughout this survey. Therefore, please select below in which language you wish to continue.',
  YOU_ARE_NOT_JANE: 'Nice to meet you!',
  SOCIO_DEMO: `Before we can kick off the survey, we would like to ask you for some basic information. This information helps us understand our respondents better.\n\nRest assured - BUFFL treats your details with care. This means that all responses are anonymized.`,
  WE_NEED_FEW_MORE_DETAILS_FROM_YOU:
    'Before we can kick off the survey, we would like to ask you for some basic information. This information helps us understand our respondents better.',
  REST_ASSURED_RESPONSES_ARE_ANONYMIZED:
    'Rest assured - BUFFL treats your details with care. This means that all responses are anonymized.',
  YOU_ARE: 'You are...',
  YOU_ARE_A: 'You are a...',
  YOUR_POSTCODE_IS: 'Your postcode is...',
  YEARS_OLD: '...years old.',
  HOW_OLD_ARE_YOU: 'How old are you?',
  MALE_SHORT: 'M',
  FEMALE_SHORT: 'F',
  ENTER_POSTCODE: 'Vul je postcode in',
  INTRODUCTION: `Are you ready?\n\n This survey will contain a variety of questions - and we are primarily interested in your personal response: there is no such thing as a bad answer!\n\nThis survey will take about {time} minutes of your time.\n\nWe hope you will enjoy it.\n\nLet’s go!`,
  READY_TO_LAUNCH: 'Are you ready?',
  DURING_SURVEY_WILL_PRESENT_SURVEY:
    'This survey will contain a variety of questions - and we are primarily interested in your personal response: there is no such thing as a bad answer!',
  THIS_SURVEY_TAKES: 'This survey will take about {time} minutes of your time.',
  WE_HOPE_YOU_WILL_ENJOY: 'We hope you will enjoy it.',
  LETS_GO: 'Let’s go!',
  JUST_THIS: 'Thanks a lot for participating!',
  WE_CAN_REACH_YOU:
    'Could you please confirm your email? We need this in order to validate your answers.',
  WOULD_YOU_LIKE_TO_RECEIVE_NEW_SURVEYS: 'Keep me updated on future surveys',
  YOU_WANT_TO_BE_CONTACTED: 'Notify me if I’ve won a prize',
  A_DONKEY_DOESNT_BUMP:
    "A donkey doesn't bump into the same stone twice. Quickly create a user profile and avoid wasting time taking part in future surveys or competitions!",
  CREATE_A_BUFFL_USER_PROFILE: 'Create a BUFFL user profile',
  BUFFL_HAS_DOZENS_OF_RESEARCHES:
    "BUFFL has dozens of researches a week around a wide variety of topics - so there's something to everyone's liking.",
  COMPLETED: `Would you be interested in giving your opinion on the latest innovative products, earn tokens and have a chance to win fantastic prizes - including trying out new products?\n\nInstall the BUFFL-app, available in the App Store and Google Play Store.`,
  WOULD_YOU_BE_INTERESTED_GIVING_AN_OPINION:
    'Would you be interested in giving your opinion on the latest innovative products, earn tokens and have a chance to win fantastic prizes - including trying out new products?',
  YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY:
    'Your answers will be processed securely and anonymously.',
  INSTALL_THE_BUFFL_APP:
    'Install the BUFFL-app, available in the App Store and Google Play Store.',
  WOULD_YOU_STAY_UP_OF_LATEST_NEWS_VIA_MAIL:
    'Would you rather stay up to speed of the latest news via email - or only want to leave your email to win a prize? Then leave your email address here!',
  CHOOSE_PASSWORD: 'Choose your password',
  ENTER_YOUR_PASSWORD: 'Enter your password',
  CONFIRM_PASSWORD: 'Confirm your password',
  OPEN_MAILBOX_CONFIRM_ANSWER:
    'Open your mailbox and confirm your answers and email address.',
  CHECK_SPAM_FOLDER: 'Be sure to check your spam folder for the sneaky ones.',
  THANK_YOU: 'Thank you for participating.',
  MORE_IMPACT: 'Longing for more impact?',
  WE_GOT_YOUR_ANSWERS: 'We received your answers, thank you!',
  WE_ARE_BUFFL: "We're BUFFL.",
  THROUGH_YOUR_PARTICIPATION:
    'Through your participation in these kinds of surveys, you decide what products, services and brands should look like. Do you want to participate more and always have a chance to win nice gifts? Then be sure to download the BUFFL app and let me hear from you.',
  YOU_HAVE_ALREADY_PARTICIPATED: 'You have already participated in this survey',
  SHARE_YOUR_OPINION: '',
  CONTRIBUTE_TO_INNOVATIONS_OF_TOMORROW: '',
  BE_REWARDED: '',
  REVIEW_ONE: '',
  REVIEW_TWO: '',
  REVIEW_THREE: '',
  JOIN_THE_HERD: 'Join the herd',
  WHAT_DOES_BUFFL_OFFER: '',
  DATE_OF_BIRTH: 'Date of birth',
  CHOOSE_A_GENDER: 'Choose a gender',
  CREATE_ACCOUNT: 'Create Account',
  CONGRATULATIONS: 'Congratulations',
  PART_OF_THE_HERD: 'You are now officially part of the herd.',
  DOWNLOAD_AND_START_EARNING_TOKENS:
    'Download the app and start earning tokens',
  DOWNLOAD_THE_APP_NOW: 'Download the BUFFL app now!',
  PLEASE_FILL_FIELD: 'Please fill in this field',
  PLEASE_ENTER_VALID_EMAIL: 'Please enter a valid email',
  PLEASE_ENTER_DATE_OF_BIRTH_RIGHT_FORMAT:
    'Please enter date of birth in right format',
  PLEASE_ENTER_VALID_DATE_OF_BIRTH: 'Please enter a valid date of birth',
  PLEASE_SELECT_GENDER: 'Please select a gender',
  PASSWORDS_NOT_SAME: 'Passwords do not match',
  EMAIL_ALREADY_USED: 'This email address is already in use',
  START_SURVEY: 'Start survey',
  GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS:
    "It's important to give your opinion in this survey. There are no bad answers. The more honest, the better!",
  LOADING_IMAGE_FAILED: 'The image could not be loaded.',
  AMOUNT_OF_ANWSERS: 'Amount of answers',
  PLEASE_ENTER_VALID_POSTAL_CODE: 'Please enter a valid postal code.',
  OPEN_QUESTION_REMAINING_CHARACTERS: 'characters remaining',
  OPEN_QUESTION_MIN_CHARACTERS: 'min.',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS:
    'Would you like to have more impact on decisions by participating in short surveys like this one?\n\n+ We are giving away a voucher for a 3-day family weekend worth € 199.90',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT:
    'Would you like to have more impact on decisions by participating in short surveys like this one?',
  I_AM_NOT_INTERESTED: 'I am not interested.',
  WELL_KEEP_IN_TOUCH: 'We’ll keep in touch!',
  YOU_HAVE_ALREADY_EARNED_TOKENS_BY_COMPLETING_SURVEYS:
    'You’ve already earned €{euro} by completing {surveys} surveys. Ready to claim your reward? \nDownload the app, register using {email} and we’ll transfer the tokens to your account.',
  WE_HAVE_NOTICED_EMAIL_IS_LINKED_TO_MOBILE_ACCOUNT:
    'We have noticed that {email} is linked to an account on our mobile app.\nYou’ll receive {tokens} tokens for completing this survey. ',
  YOU_HAVE_ALREADY_CLAIMED_THE_REWARD_FOR_THIS_SURVEY:
    'You already claimed the reward for this survey.',
  SEC: 'sec',
  PROFILING_QUESTION_CONSENT_TITLE:
    'We would like to make your surveys shorter',
  PROFILING_QUESTION_CONSENT_DESCRIPTION:
    'May we save your answer to some questions? This is done completely anonymously and ensures that you will not see these specific questions again in future surveys.',
  PROFILING_QUESTION_YES_CONSENT: 'Yes, I would like shorter surveys!',
  PROFILING_QUESTION_NO_CONSENT: 'No, rather not.',
  PROFILING_QUESTION_NO_DO_NOT_ASK: 'No, don’t ask again for these questions.',
  PROFILING_QUESTION_NO_DO_NOT_ASK_EVER:
    'No, don’t ask again for these or any other questions.',
  CONVERSION_THANK_YOU: 'Thank you for participating.',
  CONVERSION_NO_CONSENT:
    'Your answers will be processed securely and anonymously.',
  SELECT_ANSWERS: 'Select {selectAtLeast} answers',
  SELECT_AT_LEAST_AND_AT_MOST_ANSWERS:
    'Select at least {selectAtLeast} and at most {selectAtMost} answers',
  MATRIX: {
    I_STRONGLY_DISAGREE: 'Strongly disagree',
    I_DISAGREE: 'Disagree',
    I_AGREE: 'Agree',
    I_STRONGLY_AGREE: 'Strongly agree',
    I_NEITHER_AGREE_NOR_DISAGREE: 'Neither agree nor disagree',
    I_SOMEWHAT_DISAGREE: 'Somewhat disagree',
    I_SOMEWHAT_AGREE: 'Somewhat agree'
  },
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_EUROS_CONVERSION:
    "You’ve already earned {EUROS} completing {SURVEYS} survey(s). Ready to claim your reward? Download the app, register with {EMAIL} and we'll deposit the amount to your account.",
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_TOKENS_CONVERSION:
    'You’ve already earned {TOKENS} tokens by completing {SURVEYS} survey(s). Ready to claim your reward? Download the app, register with {EMAIL} and we’ll transfer the tokens to your account.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_IMPACT_CONVERSION:
    'Ready to put your frustrations, ideas or insights around the topic of ... into practice? Download the app and register with {EMAIL}',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_EUROS_CONVERSION:
    'We have noticed that {EMAIL} is linked to an account on our mobile app. You’ll receive €{EUROS} for completing this survey.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_TOKENS_CONVERSION:
    'We have noticed that {EMAIL} is linked to an account on our mobile app. You’ll receive {TOKENS} tokens for completing this survey.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_IMPACT_CONVERSION:
    'Ready to put your frustrations, ideas or insights around the topic of ... into practice? Download the app and log in with {EMAIL}.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_TO_WEB_CONVERSION: 'We’ll keep in touch!',

  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_AS: 'Are you',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO_NEED_DATA: '',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_YES: 'Yes, give me a shorter survey!',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO: 'No, continue as guest'
};
