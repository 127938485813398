import { SURVEY_END_TYPES } from '../../../helpers/constants';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';

export default (state, groupId, jump) => {
  const groupIndex = state.survey.content.groups.findIndex(
    g => g.id === groupId
  );

  const newGroup = { ...state.survey.content.groups[groupIndex] };

  let newState = { ...state };

  if (
    jump === SURVEY_END_TYPES.SUCCESSFUL ||
    jump === SURVEY_END_TYPES.NOT_ELIGIBLE
  ) {
    if (newGroup.nextFlow) {
      newGroup.nextFlow = null;
    }

    newGroup.end = jump;
  } else {
    if (newGroup.end) {
      newGroup.end = null;
    }

    if (newGroup && newGroup.id && newGroup.id !== jump) {
      newGroup.nextFlow = jump;
    }
  }

  newState.survey.content.groups[groupIndex] = newGroup;

  newState = {
    ...newState,
    survey: {
      ...newState.survey,
      content: {
        ...newState.survey.content,
        groups: newState.survey.content.groups
      }
    }
  };

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return { newState };
};
