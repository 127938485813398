import React, { useState, useEffect, useRef } from 'react';

import Icon from '../../../../../../../../campaigns/components/Icon';
import Loader from '../../../../../../../../campaigns/components/Loader/Loader';
import DebounceInput from '../../../../../../../../campaigns/components/DebounceInput/DebounceInput';

import arrowDown from '../../../../../../../../assets/img/arrow-down.svg';

import styles from './Dropdown.module.css';

const Dropdown = props => {
  const {
    selectedValue,
    onSelectCompleted,
    searchEnabled,
    options,
    fetchOptions,
    loading,
    customPlaceholder,
    findList,
    customBackgroundStyle,
    disabled
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(0);
  const [isMaxReached, setIsMaxReached] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(options || []);

  const dropDownRef = useRef(null);

  const placeholder = customPlaceholder || 'Select option';

  const handleClickOutside = event => {
    if (
      !(
        dropDownRef &&
        dropDownRef.current &&
        dropDownRef.current.children &&
        dropDownRef.current.children.length &&
        Array.from(dropDownRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(
    () => {
      if (showPopup) {
        fetchOptionsExecute(0);
      } else if (fetchOptions) {
        emptyState();
      }
    },
    [showPopup]
  );

  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    if (elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight) {
      fetchOptionsExecute(page + 1, inputSearch);
    }
  };

  const onInputSearchChangeValue = searchValue => {
    setIsMaxReached(false);
    setInputSearch(searchValue);
    fetchOptionsExecute(0, searchValue, true);
  };

  const fetchOptionsExecute = (targetPage, inputFieldSearch, forceSearch) => {
    const runFetchOptions = async () => {
      const searchString =
        inputFieldSearch || inputFieldSearch === ''
          ? inputFieldSearch
          : inputSearch;
      setPage(targetPage);
      const fetchedOptions = await fetchOptions(targetPage, searchString);

      if (
        availableOptions &&
        fetchedOptions &&
        availableOptions.length === fetchedOptions.length
      ) {
        setIsMaxReached(true);
      }

      setAvailableOptions(fetchedOptions);
      setIsFetching(false);
    };

    if (
      fetchOptions &&
      !isFetching &&
      (!isMaxReached || forceSearch === true)
    ) {
      setIsFetching(true);
      runFetchOptions();
    }
  };

  const emptyState = () => {
    setPage(0);
    setInputSearch('');
    setAvailableOptions([]);
    setIsMaxReached(false);
  };

  const itemIsSelected = value =>
    findList && findList.length && findList.includes(value);

  return (
    <div className={styles.container} ref={dropDownRef}>
      <div
        role="presentation"
        className={`${styles.selectedValue} ${disabled ? styles.disabled : ''}`}
        style={{
          backgroundImage: `url(${arrowDown})`,
          ...customBackgroundStyle
        }}
        onClick={() => {
          if (!disabled) {
            setShowPopup(!showPopup);
          }
        }}
      >
        {selectedValue ||
          (loading && (
            <div className={styles.selectedValueLoader}>
              <Loader size="small" />
            </div>
          )) || <span className={styles.placeholder}>{placeholder}</span>}
      </div>
      {showPopup ? (
        <div className={styles.popupContainer}>
          {searchEnabled && (
            <div className={styles.searchInput}>
              <DebounceInput
                initialValue={inputSearch}
                onChangeValue={searchValue =>
                  onInputSearchChangeValue(searchValue)
                }
                placeholder="Search"
              />
              {isFetching && availableOptions.length > 0 ? (
                <div className={styles.inputLoaderContainer}>
                  <Loader size="small" />
                </div>
              ) : null}
            </div>
          )}
          <div
            className={styles.popupItemsContainer}
            onScroll={listOptionsDivScroll}
          >
            {availableOptions && availableOptions.length && !disabled
              ? availableOptions.map((item, index) => (
                  <div
                    className={styles.popupItem}
                    key={`filter-dropdown-item-${index.toString()}`}
                    role="presentation"
                    onClick={() => {
                      onSelectCompleted(item.label, item.value);
                      if (!searchEnabled) {
                        setShowPopup(false);
                      }
                    }}
                  >
                    {searchEnabled && (
                      <span
                        className={`${
                          itemIsSelected(item.value)
                            ? styles.checkboxSelected
                            : styles.checkbox
                        } check-icon`}
                      >
                        <Icon
                          type={
                            itemIsSelected(item.value) ? 'checked' : 'unchecked'
                          }
                        />
                      </span>
                    )}
                    <div className={styles.itemLabel}>{item.label}</div>
                  </div>
                ))
              : null}

            {!isFetching &&
            (!availableOptions ||
              (availableOptions && availableOptions.length === 0)) ? (
              <div className={styles.noItems}>No search results</div>
            ) : null}
            {isFetching && availableOptions.length === 0 ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
