import React from "react";
import { Player, LoadingSpinner } from "video-react";
import Button from "../Button/Button";

import "./VideoPopup.css";
import "video-react/dist/video-react.css";

const VideoPopup = props => {
  const { video, setVideoPopup } = props;

  return (
    <div className="video-popup-container">
      <div className="video-popup-actions-container">
        <a href={`/media/${video}`} target="new">
          <Button
            type="white"
            role="button"
            label="Download"
            icon="thank-you"
          />
        </a>
        <Button
          type="white"
          role="button"
          label="Close"
          icon="close"
          onClick={() => {
            setVideoPopup(null);
          }}
        />
      </div>
      <Player fluid={false} width={700} height={445} src={`/media/${video}`}>
        <LoadingSpinner />
      </Player>
    </div>
  );
};

export default VideoPopup;
