import React, { useState, useEffect } from 'react';

import {
  FILTER_RANGES,
  MAIN_DATA_METRICS
} from '../../../../helpers/constants';
import useApi from '../../../../hooks/useApi';
import {
  getStatisticsData,
  loadAllMetrics
} from '../../../../helpers/mainPageChartDataLoad';

import ChartMetric from './components/ChartMetric/ChartMetric';
import ValueMetric from './components/ValueMetric/ValueMetric';

import styles from './MainPageCharts.module.css';
import BarMetric from './components/BarMetric/BarMetric';
import PlaceholderChart from './components/PlaceholderChart/PlaceholderChart';

export default ({
  appliedFilters,
  filterRelation,
  isGlobalLoading,
  setIsGlobalLoading
}) => {
  const [statisticsData, setStatisticsData] = useState(getStatisticsData(null));
  const [metricInnerSettings, setMetricInnerSettings] = useState({
    [MAIN_DATA_METRICS.COMMUNITY_SIZE]: {
      range: FILTER_RANGES.MONTH
    },
    [MAIN_DATA_METRICS.ACTIVE_USERS]: {
      range: FILTER_RANGES.BIWEEK
    }
  });
  const [api] = useApi();

  useEffect(
    () => {
      // Empty values, loaders are shown
      setStatisticsData(getStatisticsData(null));

      loadAllMetrics(
        api,
        setStatisticsData,
        appliedFilters,
        metricInnerSettings,
        filterRelation
      );
    },
    [appliedFilters, filterRelation]
  );

  useEffect(
    () => {
      const isAnyMetricLoading = Object.values(statisticsData).some(
        sD => sD === null
      );

      if (isAnyMetricLoading !== isGlobalLoading) {
        // eslint-disable-next-line
        setIsGlobalLoading(isGlobalLoading => isAnyMetricLoading);
      }
    },
    [statisticsData]
  );

  const metricCommonProperties = {
    api,
    appliedFilters,
    metricInnerSettings,
    setMetricInnerSettings,
    statisticsData,
    setStatisticsData,
    filterRelation
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <BarMetric
          name={MAIN_DATA_METRICS.ACTIVE_USERS}
          title="User activity"
          value={statisticsData[MAIN_DATA_METRICS.ACTIVE_USERS]}
          description="active users"
          availableRanges={[
            FILTER_RANGES.BIWEEK,
            FILTER_RANGES.MONTH,
            FILTER_RANGES.QUARTER,
            FILTER_RANGES.YEAR
          ]}
          metricWidth={100}
          {...metricCommonProperties}
        />
      </div>
      <div className={styles.row}>
        <ChartMetric
          name={MAIN_DATA_METRICS.RESPONSE_RATE}
          title="Response rate"
          value={statisticsData[MAIN_DATA_METRICS.RESPONSE_RATE]}
          description="response rate in 48h"
          metricWidth={49}
          isPercentage
          tickDaysDifference={0}
          {...metricCommonProperties}
        />
        <PlaceholderChart
          title="Tokens issued"
          description="Not available yet."
          metricWidth={49}
          {...metricCommonProperties}
        />
      </div>
      <div className={styles.row}>
        <ChartMetric
          name={MAIN_DATA_METRICS.COMMUNITY_SIZE}
          title="Community size"
          value={statisticsData[MAIN_DATA_METRICS.COMMUNITY_SIZE]}
          description="users in total"
          availableRanges={[FILTER_RANGES.MONTH, FILTER_RANGES.YEAR]}
          dateFormat={
            metricInnerSettings &&
            metricInnerSettings[MAIN_DATA_METRICS.COMMUNITY_SIZE] &&
            metricInnerSettings[MAIN_DATA_METRICS.COMMUNITY_SIZE].range &&
            metricInnerSettings[MAIN_DATA_METRICS.COMMUNITY_SIZE].range ===
              FILTER_RANGES.YEAR
              ? '%Y'
              : '%m/%Y'
          }
          {...metricCommonProperties}
        />
      </div>
      <div className={styles.row}>
        <ValueMetric
          name={MAIN_DATA_METRICS.MAXIMUM_DELIVERY_POTENTIAL}
          title="Maximum delivery potential"
          value={statisticsData[MAIN_DATA_METRICS.MAXIMUM_DELIVERY_POTENTIAL]}
          description="max amount of completes in 48h"
          {...metricCommonProperties}
        />
      </div>
    </div>
  );
};
