import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import api from '../../../../api';

import {
  COMPARE_SURVEYS,
  COMBINE_SURVEYS,
  GET_SURVEY_CONTENT_QUESTIONS
} from '../../../../graphql/Survey';

import styles from './CombineSurveys.module.css';
import Loader from '../../Loader/Loader';
import DebounceInput from '../../DebounceInput/DebounceInput';
// import getQuestionSets from '../../../../surveys/helpers/getQuestionSets/getQuestionSets';
import generateSurveyLists from '../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/generateSurveyLists/generateSurveyLists';

const CombineSurveys = props => {
  const {
    surveysToCombine,
    setSurveysToCombine,
    onShowResults,
    accountManagement
  } = props;

  const selectedAccountManagementRecord =
    accountManagement &&
    accountManagement.selectedAccountManagementRecord &&
    accountManagement.selectedAccountManagementRecord.value;

  const [combineError, setCombineError] = useState(false);
  const [compareSucces, setCompareSuccess] = useState(false);

  const [campaignFilter, setCampaignFilter] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setfilteredCampaigns] = useState([]);

  const [campaignsPage, setCampaignsPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllCampaigns = async newCampaignsPage => {
    setLoading(true);
    const getCampaignsData = {
      page: newCampaignsPage || campaignsPage,
      rowsPerPage: 20
    };
    const campaignsList = await api().getCampaigns(
      selectedAccountManagementRecord,
      getCampaignsData.page,
      getCampaignsData.rowsPerPage,
      '',
      'createdAt', // sortcolumn
      'desc' // sortType
    );
    const newCampaignsList = campaigns.concat(campaignsList.campaigns);
    setCampaigns(newCampaignsList);
    setLoading(false);
  };

  const searchCampaigns = async value => {
    setLoading(true);
    const campaignsList = await api().getCampaigns(
      selectedAccountManagementRecord,
      0, // page
      20, // size
      `&campaignFilter[excludeIds]=&campaignFilter[campaignNameSearch]=${value}`, // filter
      'createdAt', // sortcolumn
      'desc' // sortType
    );
    setCampaignFilter(value);
    setfilteredCampaigns(campaignsList.campaigns);
    setLoading(false);
  };

  useEffect(() => {
    const initialCampaignsLoad = async () => {
      await getAllCampaigns();
    };
    initialCampaignsLoad();
  }, []);

  useEffect(
    () => {
      if (surveysToCombine && surveysToCombine.length === 2) {
        setLoading(true);
        getSurveysContent({
          variables: {
            id: surveysToCombine[0].id
          }
        });
      }
    },
    [surveysToCombine]
  );

  const [combineSurveys] = useMutation(COMBINE_SURVEYS, {
    onCompleted: response => {
      if (
        response.combineSurveys.errors &&
        response.combineSurveys.errors.length
      ) {
        setCombineError(response.combineSurveys.errors);
        setLoading(false);
      } else {
        setCompareSuccess(false);
        onShowResults({ id: response.combineSurveys.survey.id });
        setSurveysToCombine([]);
      }
    },
    onError: cError => {
      setCombineError(cError);
      setLoading(false);
    }
  });

  const [compareSurveys] = useMutation(COMPARE_SURVEYS, {
    // fetchPolicy: 'no-cache', for some
    onCompleted: response => {
      if (
        response.compareSurveys.errors &&
        response.compareSurveys.errors.length
      ) {
        setCombineError(response.compareSurveys.errors);
      } else {
        setCompareSuccess(true);
      }
      setLoading(false);
    },
    onError: cError => {
      setCombineError(cError);
      setLoading(false);
    }
  });

  const [getSurveysContent] = useLazyQuery(GET_SURVEY_CONTENT_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      if (
        response &&
        response.survey &&
        response.survey.survey &&
        response.survey.survey.content &&
        response.survey.survey.questions &&
        response.survey.survey.questions.length &&
        Array.isArray(response.survey.survey.questions)
      ) {
        const questionsMapping = response.survey.survey.questions.reduce(
          (acc, q) => ({
            ...acc,
            [q.id]: q
          }),
          {}
        );

        const orderQuestionsWithGroupNodes = generateSurveyLists(
          response.survey.survey.content,
          questionsMapping
        );
        const formattedQuestionIndexes = orderQuestionsWithGroupNodes.map(
          orderedQuestion =>
            orderedQuestion.formattedGlobalIndex
              ? orderedQuestion.formattedGlobalIndex.toString()
              : orderedQuestion.globalIndex.toString()
        );
        const surveysToCompare = [];
        surveysToCombine.forEach(survey => {
          surveysToCompare.push({ id: survey.id });
        });
        compareSurveys({
          variables: {
            formattedQuestionIndexes,
            surveysToCompare
          }
        });
      } else if (response && response.errors) {
        setCombineError(response.errors);
      } else {
        setCombineError({ message: 'Unable to combine these surveys' });
      }
    },
    onError: error => {
      setCombineError(error);
    }
  });

  const isChecked = id => {
    for (let i = 0; i < surveysToCombine.length; i += 1) {
      if (surveysToCombine[i].id === id) {
        return true;
      }
    }
    return false;
  };

  const mergeListItem = campaign => (
    <div className={styles.campaignListItem} key={campaign.id}>
      <input
        type="checkbox"
        onClick={e => {
          if (loading) return;

          const surveyInList = isChecked(campaign.id);
          let updatedCombinedSurveys = surveysToCombine;

          if (surveyInList) {
            updatedCombinedSurveys = surveysToCombine.filter(
              survey => survey.id !== campaign.id
            );
            setCombineError(null);
            setCompareSuccess(null);
          } else if (surveysToCombine.length < 2) {
            updatedCombinedSurveys = surveysToCombine.concat({
              id: campaign.id,
              name: campaign.projectName || campaign.name
            });
            setCombineError(null);
            setCompareSuccess(null);
          }
          setSurveysToCombine(updatedCombinedSurveys);
          e.stopPropagation();
        }}
        checked={isChecked(campaign.id)}
      />
      <span className={styles.campaignListItemName}>
        {campaign.projectName || campaign.name}
      </span>
    </div>
  );

  return (
    <div className={styles.combineSurveysContainer}>
      <div className={styles.combineSurveysTitle}>Merge surveys</div>
      <div className={styles.combineSurveysExplanation}>
        To combine multiple surveys they need to have the exact same form: same
        question types at same indices, same question properties, same answer
        lengths, same flow.
        <br />
        The settings of the surveys do not need to be the same.
      </div>
      {combineError && combineError.length && (
        <>
          {combineError.map(cError => (
            <div className={styles.combineResultError}>{cError.message}</div>
          ))}
        </>
      )}
      {compareSucces && (
        <div className={styles.combineResultSuccess}>
          Surveys are combinable
        </div>
      )}
      {!combineError && !compareSucces && surveysToCombine.length < 2 && (
        <div className={styles.selectSurveys}>Select surveys to merge</div>
      )}
      {
        <DebounceInput
          value={campaignFilter}
          onChangeValue={async value => {
            if (value && value !== '' && value !== ' ') {
              searchCampaigns(value);
            }
            if (!value || value === '') {
              setCampaignFilter(null);
              setfilteredCampaigns([]);
            }
          }}
          placeholder="search survey"
          className={styles.searchInput}
        />
      }
      {((campaigns && campaigns.length && !campaignFilter) ||
        (campaignFilter && filteredCampaigns && filteredCampaigns.length)) && (
        <div
          className={
            loading || surveysToCombine.length === 2
              ? `${styles.campaignList} ${styles.disabled}`
              : styles.campaignList
          }
        >
          {filteredCampaigns && filteredCampaigns.length
            ? filteredCampaigns.map(filercampaign =>
                mergeListItem(filercampaign)
              )
            : campaigns.map(campaign => mergeListItem(campaign))}
          {!campaignFilter && (
            <div
              className={styles.loadMore}
              role="presentation"
              onClick={() => {
                getAllCampaigns(campaignsPage + 1);
                setCampaignsPage(campaignsPage + 1);
              }}
            >
              Load more
            </div>
          )}
        </div>
      )}
      {campaignFilter &&
        filteredCampaigns &&
        filteredCampaigns.length === 0 &&
        !loading && <div className={styles.noCampaigns}>No surveys found</div>}
      {loading && campaigns && campaigns.length === 0 && (
        <div className={styles.noCampaigns}>Loading surveys...</div>
      )}
      {surveysToCombine && surveysToCombine.length ? (
        <div
          className={styles.selectedSurveys}
          title={surveysToCombine.map(
            survey => survey.projectName || survey.name
          )}
        >
          Selected surveys:
          {surveysToCombine.map((survey, index) => (
            <span className={styles.surveysListElement} key={survey.id}>
              {index > 0 ? ',' : ''} {survey.projectName || survey.name}
            </span>
          ))}
        </div>
      ) : null}
      {loading ? (
        <div className={styles.combineLoading}>
          <Loader />
        </div>
      ) : (
        <div
          role="presentation"
          className={
            compareSucces &&
            surveysToCombine.length < 3 &&
            surveysToCombine.length >= 2
              ? styles.combineButton
              : styles.combineButtonDisabled
          }
          onClick={() => {
            if (
              compareSucces &&
              surveysToCombine.length < 3 &&
              surveysToCombine.length >= 2
            ) {
              const surveyIds = [];
              surveysToCombine.forEach(survey => {
                surveyIds.push({ id: survey.id });
              });
              setLoading(true);
              combineSurveys({
                variables: {
                  surveysToCombine: surveyIds
                }
              });
            }
          }}
        >
          Merge surveys
        </div>
      )}
    </div>
  );
};

export default CombineSurveys;
