import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './Loader.module.css';

const Loader = () => (
  <div className={styles.loadingAnimation}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default withRouter(Loader);
