import React, { useState, useEffect } from 'react';
import styles from './SendEmailNotifications.module.css';
import SendNotificationFilter from '../SendNotificationFilter/SendNotificationFilter';
import InputField from './components/InputField/InputField';
import Loader from '../../../campaigns/components/Loader/Loader';
import {
  GET_EMAIL_NOTIFICATION_TEMPLATES,
  GET_EMAIL_NOTIFICATIONS_TARGET_COUNT,
  SEND_EMAIL_NOTIFICATIONS
} from '../../../graphql/PushNotifications';
import useLazyQuery from '../../../surveys/hooks/useLazyQuery';
import DropdownSelector from '../DropdownSelector/DropdownSelector';
import SurveyFiltersDetails from './components/SurveyFiltersDetails/SurveyFiltersDetails';

import prepareActiveFiltersForRequest from '../helpers/prepareActiveFiltersForRequest';

export default props => {
  const {
    filterConfiguration,
    FILTER_CONSTANTS,
    COMMUNITY_MANAGEMENT_TYPES,
    activeFilters,
    setActiveFilters,
    isListFetching,
    setPushNotificationDetails,
    LIST_TABS,
    section,
    fetchOptionsExecute,
    fetchSurveys,
    updateActiveFilters,
    SECTION_KEYS
  } = props;

  const [emailNotificationProps, setEmailNotificationProps] = useState({
    title: '',
    description: '',
    survey: {
      value: null,
      label: ''
    },
    template: {
      value: null,
      label: ''
    }
  });
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [requestCompletedStatus, setRequestCompletedStatus] = useState(null);
  const [targetUsers, setTargetUsers] = useState(false);

  useEffect(
    () => () => {
      setEmailNotificationProps({
        description: '',
        title: '',
        survey: {
          value: null,
          label: ''
        },
        template: {
          value: null,
          label: ''
        }
      });

      setActiveFilters([]);
    },
    []
  );

  const allowedToSendEmail = () => {
    if (
      emailNotificationProps &&
      emailNotificationProps.description &&
      emailNotificationProps.description !== '' &&
      emailNotificationProps.title &&
      emailNotificationProps.title !== '' &&
      emailNotificationProps.survey &&
      emailNotificationProps.survey.value &&
      emailNotificationProps.template &&
      emailNotificationProps.template.value
    ) {
      return true;
    }
    return false;
  };

  const sendEmailNotificationQuery = useLazyQuery(SEND_EMAIL_NOTIFICATIONS, {
    fetchPolicy: 'no-cache'
  });

  const getEmailNotificationTemplates = useLazyQuery(
    GET_EMAIL_NOTIFICATION_TEMPLATES,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const getEmailNotificationTargetCountQuery = useLazyQuery(
    GET_EMAIL_NOTIFICATIONS_TARGET_COUNT,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const sendEmailNotification = () => {
    const sendEmailNotificationApi = async () => {
      const activeFiltersWithValue = activeFilters.filter(filter => {
        if (filter.name === '') return false;
        if (Array.isArray(filter.values)) {
          return !!filter.values.length;
        }
        if (Object.keys(filter.values).length) return true;
        return null;
      });
      setRequestProcessing(true);

      const newPushNotification = await sendEmailNotificationQuery({
        variables: {
          ...emailNotificationProps,
          type: section,
          activeFilters: prepareActiveFiltersForRequest(activeFiltersWithValue),
          survey: emailNotificationProps.survey.value,
          template: emailNotificationProps.template.value
        }
      });

      if (
        newPushNotification &&
        newPushNotification.data &&
        newPushNotification.data.sendEmailNotifications &&
        newPushNotification.data.sendEmailNotifications.newPushNotification
      ) {
        const newPushNotificationDetails =
          newPushNotification.data.sendEmailNotifications.newPushNotification;

        const newPushNotificationFormatted = {
          id: newPushNotificationDetails.id,
          count: newPushNotificationDetails.count,
          createdAt: newPushNotificationDetails.createdAt,
          description: newPushNotificationDetails.description,
          filter_string: newPushNotificationDetails.filter_string,
          title: newPushNotificationDetails.title,
          servicePushIds: newPushNotificationDetails.servicePushIds,
          scheduled: newPushNotificationDetails.scheduled,
          scheduledDate: newPushNotificationDetails.scheduledDate,
          surveyName: newPushNotificationDetails.surveyName,
          surveyId: newPushNotificationDetails.surveyId,
          template: newPushNotificationDetails.template
        };
        setPushNotificationDetails(newPushNotificationFormatted);
        fetchOptionsExecute(
          false,
          true,
          [newPushNotificationFormatted],
          LIST_TABS.HISTORY
        );
        setRequestCompletedStatus(true);
      } else {
        setRequestCompletedStatus(false);
      }
      setRequestProcessing(false);
    };

    sendEmailNotificationApi();
  };

  const fetchTemplates = async () => {
    const templates = await getEmailNotificationTemplates({
      variables: {
        mappingName: 'SURVEY'
      }
    });

    if (
      templates &&
      templates.data &&
      templates.data.getEmailNotificationTemplates &&
      templates.data.getEmailNotificationTemplates.data &&
      templates.data.getEmailNotificationTemplates.data.length
    ) {
      return templates.data.getEmailNotificationTemplates.data.map(
        template => ({
          value: template.id,
          label: template.name.replace('[SURVEY] ', '')
        })
      );
    }

    return null;
  };

  if (requestCompletedStatus === true || requestCompletedStatus === false) {
    return (
      <div className={styles.sendPushContainer}>
        <div className={styles.title}>Send Email</div>
        <div className={styles.inputContainer}>
          {requestCompletedStatus === true
            ? 'Email Scheduled Sucessfully'
            : 'Error occured. Please contact administrator'}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.sendPushContainer}>
      <div className={styles.title}>Send Email</div>
      <div className={styles.inputContainer}>
        <InputField
          title="Add internal email name"
          placeholder="Type here"
          value={emailNotificationProps.description || ''}
          setValue={setEmailNotificationProps}
          values={emailNotificationProps}
          fieldName="description"
          mandatory
        />
        <div className={styles.inputTitle}>
          Select email template
          <span className={styles.requiredAsterisk}>*</span>
        </div>
        <div>
          <DropdownSelector
            filterDropdownStyle={
              emailNotificationProps &&
              emailNotificationProps.template &&
              emailNotificationProps.template.value
                ? `${styles.dropdownWiderFilterStyle} ${styles.selectedValue}`
                : styles.dropdownWiderFilterStyle
            }
            dropdownPopupStyle={styles.filterDropdownPopup}
            options={null}
            fetchOptions={fetchTemplates}
            value={
              emailNotificationProps &&
              emailNotificationProps.template &&
              emailNotificationProps.template.value
                ? {
                    value: emailNotificationProps.template.value,
                    label: emailNotificationProps.template.label
                  }
                : { value: null, label: '' }
            }
            onSelectCompleted={(value, label) => {
              setEmailNotificationProps({
                ...emailNotificationProps,
                template: {
                  value,
                  label
                }
              });
            }}
            placeholder="Select template"
          />
        </div>
        <InputField
          title="What's the subject line for this email?"
          placeholder="Type here"
          value={emailNotificationProps.title || ''}
          setValue={setEmailNotificationProps}
          values={emailNotificationProps}
          fieldName="title"
          mandatory
        />
        <div className={styles.inputTitle}>
          Send to respondents that are eligible and have not filled in following
          survey
          <span className={styles.requiredAsterisk}>*</span>
        </div>
        <div>
          <DropdownSelector
            filterDropdownStyle={
              emailNotificationProps &&
              emailNotificationProps.survey &&
              emailNotificationProps.survey.value
                ? `${styles.dropdownFilterStyle} ${styles.selectedValue}`
                : styles.dropdownFilterStyle
            }
            options={null}
            fetchOptions={fetchSurveys}
            value={
              emailNotificationProps &&
              emailNotificationProps.survey &&
              emailNotificationProps.survey.value
                ? {
                    value: emailNotificationProps.survey.value,
                    label: emailNotificationProps.survey.label
                  }
                : { value: null, label: '' }
            }
            onSelectCompleted={(value, label) => {
              setEmailNotificationProps({
                ...emailNotificationProps,
                survey: {
                  value,
                  label
                }
              });
            }}
            placeholder="Select survey"
            search
          />
        </div>
        {emailNotificationProps &&
        emailNotificationProps.survey &&
        emailNotificationProps.survey.value ? (
          <div className={styles.sectionDetailsContainer}>
            <SurveyFiltersDetails
              survey={emailNotificationProps.survey.value}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.sendNotificationFilterContainer}>
        <SendNotificationFilter
          notificationProps={emailNotificationProps}
          filterConfiguration={filterConfiguration}
          FILTER_CONSTANTS={FILTER_CONSTANTS}
          COMMUNITY_MANAGEMENT_TYPES={COMMUNITY_MANAGEMENT_TYPES}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          updateActiveFilters={updateActiveFilters}
          getPushNotificationTargetCountQuery={
            getEmailNotificationTargetCountQuery
          }
          countField="getEmailNotificationTargetCount"
          section={section}
          SECTION_KEYS={SECTION_KEYS}
          targetUsers={targetUsers}
          setTargetUsers={setTargetUsers}
          customTargetLabel="Do you want to add filters on top of the filters already included in the survey?"
        />
      </div>
      {requestProcessing ? <Loader /> : null}
      {!isListFetching && !requestProcessing ? (
        <div className={styles.footerContainer}>
          <div
            className={
              allowedToSendEmail()
                ? `${styles.sendPushButton}`
                : `${styles.sendPushButton} ${styles.sendPushButtonDisabled}`
            }
            role="presentation"
            onClick={() => {
              if (
                emailNotificationProps.description &&
                emailNotificationProps.title &&
                emailNotificationProps.survey &&
                allowedToSendEmail()
              ) {
                sendEmailNotification();
              }
            }}
          >
            Send to {targetUsers || targetUsers === 0 ? targetUsers : '-'}{' '}
            respondents
          </div>
        </div>
      ) : null}
    </div>
  );
};
