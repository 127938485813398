import useLazyQuery from '../../../surveys/hooks/useLazyQuery';

import {
  GET_DELIVERY_POTENTIAL,
  GET_SOCIO_DEMOGRAPHICS,
  GET_PROFILE_ATTRIBUTES
} from '../../../graphql/FeasibilityChecker';

export default () => [
  {
    getDeliveryPotentialApi: useLazyQuery(GET_DELIVERY_POTENTIAL, {
      fetchPolicy: 'no-cache'
    }),
    getSocioDemographicsApi: useLazyQuery(GET_SOCIO_DEMOGRAPHICS, {
      fetchPolicy: 'no-cache'
    }),
    getProfileAttributesApi: useLazyQuery(GET_PROFILE_ATTRIBUTES, {
      fetchPolicy: 'no-cache'
    })
  }
];
