import React from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useDropzone } from 'react-dropzone';
import { withRouter } from 'react-router-dom';

import styles from './CouponManagement.module.css';
import AppBar from '../../base/components/AppBar/AppBar';
import {
  QUERY_SHOP_ITEMS,
  COUPON_PDF_UPLOAD_SAS,
  GENERATE_SHOP_ITEM_COUPONS
} from '../../graphql/Shop';

import arrowDownPurple from '../../assets/img/arrow-down-purple.svg';
import pdfIcon from '../../assets/img/pdf.png';
import close from '../../assets/img/close-white.svg';

const DropZoneText = ({ isDragActive, uploading }) => {
  if (uploading) {
    return (
      <div className={styles.dropContainer}>
        Uploading coupon files, please wait...
      </div>
    );
  }

  if (isDragActive) {
    return (
      <div className={styles.dropContainer}>Drop the coupon files here...</div>
    );
  }

  return (
    <div className={styles.dropContainer}>
      <div className={styles.button}>Select coupon files</div>
      <div className={styles.dropDescription}>
        or drag and drop coupon files here
      </div>
    </div>
  );
};

const SECTIONS = {
  PDF: 'PDF Coupons',
  KEY: 'Key Coupons'
};

const CouponManagement = props => {
  const [uploading, setUploading] = React.useState(false);
  const [files, setFiles] = React.useState(null);
  const [expanded, setExpanded] = React.useState(true);
  const [section, setSection] = React.useState('PDF');

  const [invoice, setInvoice] = React.useState('');
  const [invoiceError, setInvoiceError] = React.useState(null);
  const [purchasedAt, setPurchasedAt] = React.useState(new Date());
  const [supplier, setSupplier] = React.useState('');
  const [supplierError, setSupplierError] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [valueError, setValueError] = React.useState(null);
  const [pdfCoupons, setPDFCoupons] = React.useState([]);
  const [keyCoupons, setKeyCoupons] = React.useState([]);
  const [couponsError, setCouponsError] = React.useState(null);
  const [shopItem, setShopItem] = React.useState(null);

  const { data: { shopItems } = {} } = useQuery(QUERY_SHOP_ITEMS, {
    fetchPolicy: 'network-only'
  });
  const [getCouponPDFUploadSAS] = useLazyQuery(COUPON_PDF_UPLOAD_SAS, {
    fetchPolicy: 'no-cache',
    onCompleted: async ({ couponPDFUploadSAS: { result } }) => {
      if (!files) return;

      try {
        const newPDFCoupons = await Promise.all(
          files.map(
            (f, i) =>
              new Promise((resolve, reject) => {
                const sas = result[i];
                const xhr = new XMLHttpRequest();

                xhr.onload = () => {
                  if (xhr.status >= 200 && xhr.status < 300) {
                    resolve({
                      pdf: sas.filename,
                      pdfFilename: f.name
                    });
                  } else {
                    reject(xhr.status, sas, i);
                  }
                };

                xhr.onerror = () => {
                  reject(xhr.status, sas, i);
                };

                xhr.open('PUT', sas.uri);
                xhr.setRequestHeader('Content-Type', 'application/pdf');
                xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

                xhr.send(f);
              })
          )
        );

        setPDFCoupons([...pdfCoupons, ...newPDFCoupons]);

        setUploading(false);
        setFiles(null);
      } catch (err) {
        setUploading(false);
        setCouponsError(
          'Encountered an error during upload... Please try again later or contact an administrator.'
        );
      }
    }
  });

  const [generateShopItemCoupons] = useMutation(GENERATE_SHOP_ITEM_COUPONS, {
    onCompleted: () => {
      setUploading(false);
      setInvoice('');
      setPurchasedAt(new Date());
      setSupplier('');
      setValue('');
      setKeyCoupons([]);
      setPDFCoupons([]);
      setShopItem(null);
      // eslint-disable-next-line no-alert
      window.alert(
        'Coupons were successfully uploaded! Use forest to assign them to shop items.'
      );
    }
  });

  const onDrop = React.useCallback(acceptedFiles => {
    setUploading(true);

    getCouponPDFUploadSAS({
      variables: {
        amount: acceptedFiles.length
      }
    });

    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    multiple: true,
    onDrop
  });

  const submitCoupons = () => {
    setInvoiceError(null);
    setSupplierError(null);
    setValueError(null);
    setCouponsError(null);

    let error = false;
    if (invoice === '') {
      error = true;
      setInvoiceError('This value is required');
    }
    if (supplier === '') {
      error = true;
      setSupplierError('This value is required');
    }
    if (value === '') {
      error = true;
      setValueError('This value is required');
    }
    if (pdfCoupons.length <= 0 && keyCoupons.length <= 0) {
      error = true;
      setCouponsError('At least one coupon is required');
    }

    if (error) return;

    generateShopItemCoupons({
      variables: {
        invoice,
        purchasedAt,
        supplier,
        value,
        keyCoupons,
        pdfCoupons,
        shopItem
      }
    });
  };

  return (
    <div>
      <AppBar {...props} />

      <div className={styles.pageContainer}>
        <div className={styles.pageHeader}>
          <div className={styles.sections}>
            {Object.keys(SECTIONS).map((s, index) => (
              <div
                className={
                  s === section
                    ? `${styles.sectionName} ${styles.active}`
                    : styles.sectionName
                }
                onClick={() => setSection(s)}
                role="presentation"
                key={`test-survey-section-item-${index.toString()}`}
              >
                {SECTIONS[s]}
              </div>
            ))}
          </div>

          <div className={styles.container} role="presentation">
            <div className={styles.titleContainer}>
              <div className={styles.title}>Upload Coupon Order</div>
              <img
                src={arrowDownPurple}
                alt="ExpandColapse"
                className={
                  expanded ? styles.arrowExpanded : styles.arrowCollapsed
                }
                role="presentation"
                onClick={() => setExpanded(() => setExpanded(!expanded))}
              />
            </div>
            {expanded ? (
              <div className={styles.detailsContainer}>
                <div className={styles.detailContainer}>
                  <input
                    name="invoiceID"
                    className={styles.field}
                    placeholder="Invoice Identifier"
                    value={invoice}
                    onChange={e => setInvoice(e.target.value)}
                    style={invoiceError ? { borderColor: '#d9534f' } : {}}
                  />
                  {invoiceError && (
                    <div className={styles.errorText}>{invoiceError}</div>
                  )}
                </div>

                <div className={styles.detailContainer}>
                  <input
                    type="date"
                    name="purchasedAt"
                    className={styles.field}
                    placeholder="Purchased At"
                    value={purchasedAt}
                    onChange={e => setPurchasedAt(e.target.value)}
                  />
                </div>

                <div className={styles.detailContainer}>
                  <input
                    name="supplier"
                    className={styles.field}
                    placeholder="Name of the supplier"
                    value={supplier}
                    onChange={e => setSupplier(e.target.value)}
                    style={supplierError ? { borderColor: '#d9534f' } : {}}
                  />
                  {supplierError && (
                    <div className={styles.errorText}>{supplierError}</div>
                  )}
                </div>

                <div className={styles.detailContainer}>
                  <input
                    type="number"
                    name="value"
                    className={styles.field}
                    placeholder="Montery Value (€)"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    style={valueError ? { borderColor: '#d9534f' } : {}}
                  />
                  {valueError && (
                    <div className={styles.errorText}>{valueError}</div>
                  )}
                </div>

                <div className={styles.detailContainer}>
                  <select
                    name="shopitem"
                    className={`${styles.field} ${styles.selectField}`}
                    value={shopItem || ''}
                    onChange={e => setShopItem(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Assign to shop item...
                    </option>
                    {(shopItems || []).map(si => (
                      <option key={si.id} value={si.id}>
                        {si.name}
                      </option>
                    ))}
                  </select>
                </div>

                {section === 'PDF' ? (
                  <div className={styles.detailContainer}>
                    <div className={styles.dropZone}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <DropZoneText
                          isDragActive={isDragActive}
                          uploading={uploading}
                        />
                      </div>
                    </div>

                    {pdfCoupons.map((c, i) => (
                      <div className={styles.imageContainer}>
                        <img
                          className={styles.coverImage}
                          alt="Cover"
                          src={pdfIcon}
                        />
                        <div
                          className={styles.removeImageContainer}
                          role="presentation"
                          onClick={() => {
                            setPDFCoupons(pdfCoupons.filter((_, j) => i !== j));
                          }}
                        >
                          <img alt="Close" src={close} />
                        </div>
                        <div className={styles.previewFilename}>
                          {c.pdfFilename}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.detailContainer}>
                    <textarea
                      className={styles.field}
                      rows="10"
                      placeholder="Paste the coupon keys here"
                      onChange={e => {
                        setKeyCoupons(
                          e.target.value.split('\n').map(key => ({
                            key
                          }))
                        );
                      }}
                      value={keyCoupons.map(k => k.key).join('\n')}
                    />
                  </div>
                )}
                {couponsError && (
                  <div className={styles.detailContainer}>
                    <div className={styles.errorText}>{couponsError}</div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <div
            className={styles.bigButton}
            onClick={submitCoupons}
            role="presentation"
          >
            UPLOAD ORDER COUPONS
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CouponManagement);
