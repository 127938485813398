import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useMutation } from '@apollo/react-hooks';

import Icon from '../Icon';
import Button from '../Button/Button';
import CampaignListItem from './CampaignListItem/CampaignListItem';
import CampaignFilters from './CampaignFilters/CampaignFilters';
import CampaignNameInput from './CampaignNameInput/CampaignNameInput';
import './CampaignTable.css';
import useUserback from '../../../hooks/useUserback';
import CombineSurveys from './CombineSurveys/CombineSurveys';
import Popup from '../Popup';

import warningIcon from '../../../assets/img/accountmanagement/am-warning.svg';

import { REQUEST_APPROVAL, PUBLISH_SURVEY } from '../../../graphql/Survey';
import SURVEY_STATUSES from '../../helpers/constants';

const statuses = {
  [SURVEY_STATUSES.MISSING_REQUIRED_DATA]: 'Missing Required Data',
  [SURVEY_STATUSES.TESTING]: 'Testing',
  [SURVEY_STATUSES.AWAITING_PUBLISH]: 'Awaiting Launch',
  [SURVEY_STATUSES.PUBLISHED]: 'Published',
  [SURVEY_STATUSES.FINISHED]: 'Finished'
};

const CampaignTable = props => {
  const {
    onPayCampaign,
    onWithdrawApprovalRequest,
    isFetching,
    isProcessing,
    pages,
    onChangePage,
    onLoad,
    onCreateCampaign,
    currentPage,
    onDeleteCampaign,
    onEditCampaign,
    onShowResults,
    isAdmin,
    totalCampaigns,
    onChangeColumnSort,
    otherCampaigns,
    starredCampaigns,
    starredCampaignIds,
    availableClients,
    onChangeRowsPerPage,
    rowsPerPage,
    filter,
    onSetCampaignFilters,
    clientEmail,
    accountManagement
  } = props;

  useUserback({
    client: clientEmail
  });

  const [searchVisible, setSearchVisible] = useState(false);
  const rowsPerPageOptions = [10, 20, 50];
  const [surveysToCombine, setSurveysToCombine] = useState([]);
  const [displayCombineSurveysTab, setDisplayCombineSurveysTab] = useState(
    false
  );
  const [requestApprovalError, setRequestApprovalError] = useState(null);

  const [requestApprovalRequest] = useMutation(REQUEST_APPROVAL, {
    onCompleted: () => onLoad()
  });
  const [publishSurvey] = useMutation(PUBLISH_SURVEY, {
    onCompleted: () => onLoad()
  });

  const requestApproval = async variables => {
    const response = await requestApprovalRequest(variables);

    if (
      response &&
      response.data &&
      response.data.requestApproval &&
      response.data.requestApproval.errors &&
      response.data.requestApproval.errors.length
    ) {
      setRequestApprovalError(response.data.requestApproval.errors[0].message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    onLoad();
  }, []);

  const formatDate = date => {
    if (!date) return null;

    const monthShortNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const convertDate = new Date(date);

    const day = convertDate.getDate();
    const monthIndex = convertDate.getMonth() + 1;
    const year = convertDate.getFullYear();

    // Today
    const today = new Date();

    // Yesterday
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      day === today.getDate() &&
      monthIndex === today.getMonth() + 1 &&
      year === today.getFullYear()
    ) {
      return `${convertDate.getHours()}:${
        convertDate.getMinutes() < 10 ? '0' : ''
      }${convertDate.getMinutes()}, Today`;
    }

    if (
      day === yesterday.getDate() &&
      monthIndex === yesterday.getMonth() + 1 &&
      year === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }

    if (year === today.getFullYear())
      return `${day} ${monthShortNames[monthIndex - 1]}`;
    return `${day} ${monthShortNames[monthIndex - 1]}, ${year}`;
  };

  const calculatePercentage = (count, max, limitMax) => {
    if (!count) return 0;
    if (!max) return 0;

    const calculated = ((count / max) * 100).toFixed(0);

    if (limitMax && calculated > 100) {
      return '100%';
    }

    return `${calculated}%`;
  };

  const statusDisplay = campaign => {
    if (
      campaign &&
      campaign.status === 'testing' &&
      campaign.approvalRequestSent &&
      !campaign.paid
    ) {
      return 'Approval Request Sent';
    }

    if (
      campaign &&
      campaign.status === 'testing' &&
      campaign.approvalRequestSent &&
      campaign.paid
    ) {
      return statuses.pending;
    }

    if (campaign && campaign.status === 'launched') {
      return 'Published';
    }

    return statuses[campaign.status];
  };

  const changePage = pageNumber => {
    onChangePage(pageNumber.selected);
    onLoad();
  };

  const changeRowsPerPage = rows => {
    onChangeRowsPerPage(rows);
    onLoad();
  };

  const setCampaignFilters = newFilter => {
    onSetCampaignFilters(newFilter);
    onLoad(0);
  };

  const RequestApprovalErrorComponent = () => (
    <div
      className="campaigns-table-publish-error-container"
      role="presentation"
      onClick={() => {
        setRequestApprovalError(false);
      }}
    >
      <div>
        <img src={warningIcon} alt="Warning icon" />
      </div>
      Survey not published. Unfortunately, it appears that your license has
      expired or your account does not have enough budget to complete this
      action.
    </div>
  );

  return (
    <>
      <div className="campaigns-table-main-container">
        <div className="campaigns-table-header">
          <div className="left-header-content">
            <div className="header1">My surveys</div>
            {/*
          <div style={style.header2}>Library</div>
          <div style={style.header2}>Best practices</div> */}
          </div>
          <div className="right-header-content">
            <span
              className="header-icon active search"
              role="presentation"
              onClick={() => {
                if (!searchVisible) {
                  setSearchVisible(true);
                }
              }}
            >
              <Icon type="search" />
            </span>
            {searchVisible ? (
              <CampaignNameInput
                filter={filter}
                setCampaignFilters={setCampaignFilters}
                setSearchVisible={setSearchVisible}
              />
            ) : null}
            <Button
              role="button"
              label="New survey"
              onClick={() => {
                onCreateCampaign();
              }}
              icon="add"
            />
          </div>
        </div>
        <div className="campaigns-table-filters-container">
          <CampaignFilters
            totalCampaigns={totalCampaigns}
            availableClients={availableClients}
            statuses={statuses}
            filter={filter}
            setCampaignFilters={setCampaignFilters}
            isFetching={isFetching}
            isProcessing={isProcessing}
            accountManagement={accountManagement}
            isAdmin={isAdmin}
          />
        </div>
        <div>
          <CampaignListItem
            {...props}
            formatDate={formatDate}
            calculatePercentage={calculatePercentage}
            statusDisplay={statusDisplay}
            onPublishSurvey={publishSurvey}
            onRequestApproval={requestApproval}
            onPayCampaign={onPayCampaign}
            onWithdrawApprovalRequest={onWithdrawApprovalRequest}
            onDeleteCampaign={onDeleteCampaign}
            onEditCampaign={onEditCampaign}
            onShowResults={onShowResults}
            isAdmin={isAdmin}
            onChangeColumnSort={onChangeColumnSort}
            starredCampaigns={starredCampaigns}
            starredCampaignIds={starredCampaignIds}
            surveysToCombine={surveysToCombine}
            setSurveysToCombine={setSurveysToCombine}
            setDisplayCombineSurveysTab={setDisplayCombineSurveysTab}
          />
          {!isFetching &&
          !isProcessing &&
          otherCampaigns &&
          otherCampaigns.campaigns &&
          otherCampaigns.campaigns.length === 0 ? (
            <div className="campaigns-table-nocampaigns">No surveys</div>
          ) : null}
        </div>
        {pages ? (
          <div>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              initialPage={currentPage}
              forcePage={currentPage}
              disableInitialCallback
              onPageChange={changePage}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
            <div className="campaigns-table-results-per-page-container">
              <div>Results per page:</div>
              {rowsPerPageOptions.map((rows, index) => (
                <div
                  key={`campaign-table-key-per-page-${index.toString()}`}
                  className={`campaigns-table-results-per-page-container-single-option ${
                    rows === rowsPerPage ? 'active-results-option' : null
                  }`}
                  role="presentation"
                  onClick={() => changeRowsPerPage(rows)}
                >
                  {rows}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {displayCombineSurveysTab && (
        <Popup
          key="error"
          component={
            <CombineSurveys
              surveysToCombine={surveysToCombine}
              setSurveysToCombine={setSurveysToCombine}
              onShowResults={onShowResults}
              accountManagement={accountManagement}
            />
          }
          onClose={() => {
            setDisplayCombineSurveysTab(false);
          }}
        />
      )}
      {requestApprovalError && (
        <Popup
          key="error"
          component={
            <RequestApprovalErrorComponent
              requestApprovalError={requestApprovalError}
            />
          }
          onClose={() => {
            setRequestApprovalError(false);
          }}
        />
      )}
    </>
  );
};

export default CampaignTable;
