import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import AppBar from '../AppBar/AppBar';
import SelectDropdown from '../SelectDropdown/SelectDropdown';

import styles from '../RegistrationPage.module.css';

import guitarPlayersHerdie from '../../../../assets/img/guitar-players-herdie.svg';
import phoneHerdie from '../../../../assets/img/phone-herdie.svg';
import registrationReviewBlob from '../../../../assets/img/registration-review-blob.svg';
import blobGuitarPhone from '../../../../assets/img/blob-guitar-phone.svg';
import groupHerdies from '../../../../assets/img/group-herdies.svg';
import workingHerdies from '../../../../assets/img/working-herdies.svg';
import registrationBottomBlob from '../../../../assets/img/registration-bottom-blob.svg';

const SignUp = props => {
  const {
    componentError,
    goNext,
    fields,
    setFieldValue,
    registrationStarted,
    translations,
    errorFields
  } = props;

  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 13, 0);
  const toMonth = new Date(currentYear - 100, 11);

  const modifiers = {
    selected: fields.date_of_birth
  };

  const modifiersStyles = {
    selected: {
      backgroundColor: '#5300F2'
    }
  };

  const [month, setMonth] = useState(fromMonth);
  const [showPicker, setShowPicker] = useState(false);

  const YearMonthForm = ({ date, localeUtils, onChange }) => {
    const months = localeUtils.getMonths();

    const years = [];
    for (let i = fromMonth.getFullYear(); i >= toMonth.getFullYear(); i -= 1) {
      years.push(i);
    }

    const handleChange = function handleChange(e) {
      // eslint-disable-next-line no-shadow
      const { year, month } = e.target.form;
      onChange(new Date(year.value, month.value));
    };

    return (
      <div className="DayPicker-Caption">
        <select name="month" onChange={handleChange} value={date.getMonth()}>
          {months.map((m, i) => (
            <option key={m} value={i}>
              {m}
            </option>
          ))}
        </select>
        <select name="year" onChange={handleChange} value={date.getFullYear()}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleYearMonthChange = m => {
    const newValue = fields.date_of_birth || new Date();
    setMonth(m);
    setFieldValue(
      'date_of_birth',
      new Date(m.getFullYear(), m.getMonth(), newValue.getDate())
    );
  };

  const handleDayClick = day => {
    setShowPicker(false);
    setFieldValue(
      'date_of_birth',
      new Date(month.getFullYear(), month.getMonth(), day.getDate())
    );
  };

  const onClosePicker = () => {
    setShowPicker(false);
  };

  const onClickPicker = e => {
    e.stopPropagation();
  };

  const onClickPickerInput = e => {
    e.stopPropagation();
    setShowPicker(!showPicker);
  };

  return (
    <div
      className={`${styles.registrationWrapper} ${styles.bottomPaddingWrapper}`}
    >
      <AppBar fields={fields} setFieldValue={setFieldValue} />
      <div className={`${styles.mainContainer} ${styles.mainContainerPadding}`}>
        <div className={styles.mainContentContainer}>
          <section className={styles.introContainer}>
            <div className={styles.introImages}>
              <img
                className={styles.blobGuitarPhone}
                src={blobGuitarPhone}
                alt="BUFFL app"
              />
              <img
                className={styles.phoneHerdie}
                src={phoneHerdie}
                alt="BUFFL app"
              />
              <img
                className={styles.introGuitarPlayersHerdie}
                src={guitarPlayersHerdie}
                alt="BUFFL app displayed on a phone"
              />
            </div>
            <div className={styles.introText}>
              <p>{translations.SHARE_YOUR_OPINION}</p>
              <h1 className={styles.title}>
                {translations.CONTRIBUTE_TO_INNOVATIONS_OF_TOMORROW}
              </h1>
              <p>{translations.BE_REWARDED}</p>
            </div>
          </section>
          <section className={styles.reviewsContainer}>
            <div className={styles.reviewWrapper}>
              <img
                className={`${styles.reviewBlob} ${styles.reviewOneBlobTop}`}
                src={registrationReviewBlob}
                alt=""
              />
              <img
                className={`${styles.reviewBlob} ${styles.reviewOneBlobBottom}`}
                src={registrationReviewBlob}
                alt=""
              />
              <div className={styles.review}>
                <span className={styles.reviewTitle}>Jorne Ernalsteen</span>
                <span className={styles.reviewText}>
                  {translations.REVIEW_ONE}
                </span>
              </div>
            </div>
            <div className={styles.reviewWrapper}>
              <img
                className={`${styles.reviewBlob} ${styles.reviewTwoBlobBottom}`}
                src={registrationReviewBlob}
                alt=""
              />
              <div className={styles.review}>
                <span className={styles.reviewTitle}>Celine Mouha</span>
                <span className={styles.reviewText}>
                  {translations.REVIEW_TWO}
                </span>
              </div>
            </div>
            <div className={styles.reviewWrapper}>
              <img
                className={`${styles.reviewBlob} ${
                  styles.reviewThreeBlobBottom
                }`}
                src={registrationReviewBlob}
                alt=""
              />
              <div className={styles.review}>
                <span className={styles.reviewTitle}>Diana de Bie</span>
                <span className={styles.reviewText}>
                  {translations.REVIEW_THREE}
                </span>
              </div>
            </div>
          </section>
          <section className={styles.joinContainer}>
            <h2 className={styles.title}>{translations.JOIN_THE_HERD}</h2>
            <p className={styles.joinText}>
              {translations.WHAT_DOES_BUFFL_OFFER}
            </p>
            <img
              className={styles.workingHerdies}
              src={workingHerdies}
              alt=""
            />
            <img className={styles.groupHerdies} src={groupHerdies} alt="" />
          </section>
          <div
            className={styles.bottomBlob}
            style={{ backgroundImage: `url('${registrationBottomBlob}')` }}
          />
        </div>
        <div
          role="presentation"
          className={styles.formWrapper}
          onClick={() => onClosePicker()}
        >
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <h2 className={styles.title}>Sign up</h2>
              {componentError && (
                <span className={styles.componentError}>{componentError}</span>
              )}
              <form>
                <span className={styles.label}>
                  <span className={styles.labelName}>Email</span>
                </span>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className={`${styles.input} register-email-input`}
                  value={fields.email}
                  onChange={event => {
                    setFieldValue('email', event.target.value);
                  }}
                  autoComplete="email"
                />
                {errorFields.email.length ? (
                  <span className={styles.error}>
                    {translations[errorFields.email]}
                  </span>
                ) : (
                  ''
                )}

                <span className={styles.label}>
                  <span className={styles.labelName}>
                    {translations.PASSWORD}
                  </span>
                </span>
                <input
                  type="password"
                  className={`${styles.input} register-password-input`}
                  value={fields.password}
                  onChange={event => {
                    setFieldValue('password', event.target.value);
                  }}
                  autoComplete="new-password"
                />
                {errorFields.password.length ? (
                  <span className={styles.error}>
                    {translations[errorFields.password]}
                  </span>
                ) : (
                  ''
                )}

                <span className={styles.label}>
                  {translations.REPEAT_PASSWORD}
                </span>
                <input
                  type="password"
                  className={`${styles.input} register-repeat-password-input`}
                  value={fields.repeatPassword}
                  onChange={event => {
                    setFieldValue('repeatPassword', event.target.value);
                  }}
                  autoComplete="new-password"
                />
                {errorFields.repeatPassword.length ? (
                  <span className={styles.error}>
                    {translations[errorFields.repeatPassword]}
                  </span>
                ) : (
                  ''
                )}

                <span className={styles.label}>
                  {translations.DATE_OF_BIRTH}
                </span>
                <div
                  role="presentation"
                  className={`${styles.input} ${styles.pickerInput}`}
                  onClick={e => onClickPickerInput(e)}
                >
                  {fields.date_of_birth
                    ? `${fields.date_of_birth.getDate()}/${fields.date_of_birth.getMonth() +
                        1}/${fields.date_of_birth.getFullYear()}`
                    : ''}
                </div>
                {showPicker && (
                  <div
                    role="presentation"
                    className={styles.yearNavigation}
                    onClick={e => onClickPicker(e)}
                  >
                    <DayPicker
                      month={month}
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                      selectedDays={fields.date_of_birth || month}
                      onDayClick={d => handleDayClick(d)}
                      captionElement={({ date, localeUtils }) => (
                        <YearMonthForm
                          date={date}
                          localeUtils={localeUtils}
                          onChange={m => handleYearMonthChange(m)}
                        />
                      )}
                      modifiers={modifiers}
                      modifiersStyles={modifiersStyles}
                    />
                  </div>
                )}
                {errorFields.date_of_birth.length ? (
                  <span className={styles.error}>
                    {translations[errorFields.date_of_birth]}
                  </span>
                ) : (
                  ''
                )}

                <SelectDropdown
                  name="gender"
                  value={fields.gender}
                  label={translations.GENDER}
                  onChange={value => setFieldValue('gender', value)}
                  options={[
                    { label: translations.MALE, value: 'M' },
                    { label: translations.FEMALE, value: 'F' },
                    { label: translations.OTHER, value: 'O' }
                  ]}
                  className={`${styles.input} register-gender-input`}
                  labelStyle={styles.label}
                  noValueSelectedClassName={styles.noValueSelected}
                  placeholder={translations.CHOOSE_A_GENDER}
                  error={translations[errorFields.gender]}
                />
                {!registrationStarted ? (
                  <div
                    onClick={event => {
                      goNext(event);
                    }}
                    role="presentation"
                    type="submit"
                    className={`${styles.button} logindata-next-button`}
                  >
                    {translations.CREATE_ACCOUNT}
                  </div>
                ) : (
                  <div className="user-app-button-loading">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
