import { connect } from 'react-redux';
import { logout } from '../actions';

import ClientsInvitation from '../components/ClientsInvitation/ClientsInvitation';

const mapStateToProps = () => {};

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsInvitation);
