import React from 'react';

import warningIcon from '../../../../../../../../assets/img/exclamation.svg';

import styles from './DeltaWarnings.module.css';

export default props => {
  const { warnings } = props;

  return (
    <div className={styles.container}>
      {warnings && warnings.length
        ? warnings.map(warning => (
            <div key={warning} className={styles.warning}>
              <img
                src={warningIcon}
                alt="Warning"
                className={styles.warningIcon}
              />
              {warning}
            </div>
          ))
        : null}
    </div>
  );
};
