import React from 'react';

import styles from './RouteTitle.module.css';

export default props => {
  const { history } = props;

  return (
    <button
      className={`${styles.myCampaignsAction} my-campaigns-action`}
      type="button"
      onClick={() => {
        history.push(`/community-dashboard`);
      }}
    >
      <span className={styles.actionLabel}>&#x3c; Community Dashboard</span>
    </button>
  );
};
