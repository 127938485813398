import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import styles from './CampaignProfilingConsentPage.module.css';

import Loader from '../../../../base/components/Loader/Loader';
import Footer from '../Footer/Footer';

import { PROFILING_QUESTION_CONSENTS } from '../../../../helpers/constants';

import {
  CUSTOM_THEME_COLOR_NAMES,
  CUSTOM_THEME_IMAGE_NAMES
} from '../../../../../clients/surveys/helpers/constants';

import {
  GET_RESULT,
  SUBMIT_PROFILING_CONSENT
} from '../../../../graphql/Campaigns';
import useUserback from '../../../../hooks/useUserback';

export default props => {
  const {
    setCampaignComponents,
    campaign,
    block,
    result,
    location: { search },
    strings,
    match,
    setCustomThemeBaseColor,
    customTranslationsLoaded,
    setCustomTranslations,
    setCustomThemeImage,
    updateTranslationsLanguage,
    history,
    userEmailSuggestion
  } = props;

  useUserback({
    ...match.params
  });

  const [valid, setValid] = useState(false);
  const [profilingConsentValue, setProfilingConsentValue] = useState(false);

  const [
    submitProfilingConsent,
    { submitProfilingConsentLoading }
  ] = useMutation(SUBMIT_PROFILING_CONSENT);

  const { customClientName, customClientIdentifier, userId } = match.params;

  const isTesting = () => search === '?test=true';

  const goToCampaignBeginning = () => {
    setCampaignComponents(null, null, null);
    if (campaign) {
      let finalUrl = `/campaign/${campaign.id}/user/${
        props.match.params.userId
      }`;
      if (isTesting()) {
        finalUrl = `${finalUrl}?test=true`;
      }

      props.history.push(finalUrl);
    }
  };

  const goToUrl = url => {
    let finalUrl = url;
    if (customClientIdentifier) {
      finalUrl = `${url}/${customClientName}/${customClientIdentifier}`;
    }
    if (isTesting()) {
      finalUrl = `${url}?test=true`;
    }
    if (userEmailSuggestion) {
      finalUrl = `${finalUrl}${
        isTesting() ? '&' : '?'
      }userEmailSuggestion=${userEmailSuggestion}`;
    }

    history.push(finalUrl);
  };

  const [getResult, { loading: loadingResult }] = useLazyQuery(GET_RESULT, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      if (response && response.result) {
        if (response && response.result && response.result.result) {
          setCampaignComponents(
            response.result.result.survey,
            false,
            response.result.result
          );
        } else {
          setCampaignComponents(null, null, null);
          goToUrl(
            `/campaign/survey/user/${props.match.params.userId}/campaign-failed`
          );
        }
      } else {
        goToCampaignBeginning();
      }
    },
    onError: () => goToCampaignBeginning()
  });

  const translations =
    localStorage.getItem('campaignLanguage') &&
    strings[localStorage.getItem('campaignLanguage')]
      ? strings[localStorage.getItem('campaignLanguage')]
      : strings.Dutch;

  const customThemeBaseColor =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeColors &&
    campaign.customThemeColors.length &&
    campaign.customThemeColors.find(
      cT => cT.name === CUSTOM_THEME_COLOR_NAMES.BASE
    ).colorCode;

  const customThemeImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length;

  const customThemeLogoName =
    customThemeImage &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).name;

  const customThemeLogoImage =
    customThemeImage &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).imageFilename;

  const profilingConsentOptions = [
    {
      label: translations.PROFILING_QUESTION_YES_CONSENT,
      value: PROFILING_QUESTION_CONSENTS.PROFILING_QUESTION_YES_CONSENT
    },
    {
      label: translations.PROFILING_QUESTION_NO_CONSENT,
      value: PROFILING_QUESTION_CONSENTS.PROFILING_QUESTION_NO_CONSENT
    },
    {
      label: translations.PROFILING_QUESTION_NO_DO_NOT_ASK,
      value: PROFILING_QUESTION_CONSENTS.PROFILING_QUESTION_NO_DO_NOT_ASK
    },
    {
      label: translations.PROFILING_QUESTION_NO_DO_NOT_ASK_EVER,
      value: PROFILING_QUESTION_CONSENTS.PROFILING_QUESTION_NO_DO_NOT_ASK_EVER
    }
  ];

  const languages = {
    ENGLISH: 'English',
    DUTCH: 'Dutch',
    FRENCH: 'French',
    GERMAN: 'German'
  };

  const userLanguages = {
    EN: 'English',
    NL: 'Dutch',
    FR: 'French',
    DE: 'German'
  };

  let campaignLanguage = languages.DUTCH;
  if (result && result.userData && result.userData.language) {
    campaignLanguage = userLanguages[result.userData.language];
  } else if (campaign && campaign.language) {
    campaignLanguage = campaign.language;
  }

  useEffect(() => {
    if (!campaign) {
      getResult({ variables: { id: match.params.resultId } });
    }
  }, []);

  useEffect(
    () => {
      if (
        campaign &&
        !(campaign.customCopies && campaign.customCopies.length)
      ) {
        updateTranslationsLanguage(campaignLanguage);
      }
      if (
        !customTranslationsLoaded &&
        campaign &&
        campaign.customCopies &&
        campaign.customCopies.length
      ) {
        setCustomTranslations(campaignLanguage, campaign.customCopies);
      }
      if (customThemeBaseColor) setCustomThemeBaseColor(customThemeBaseColor);
      if (customThemeImage) {
        setCustomThemeImage({
          NAME: customThemeLogoName,
          IMAGE: customThemeLogoImage
        });
      }
    },
    [campaign]
  );

  const onChangeValue = selectedOption => {
    setProfilingConsentValue(selectedOption);
    setValid(true);
  };

  const onClickNext = async () => {
    if (
      !submitProfilingConsentLoading &&
      result &&
      result.id &&
      profilingConsentValue
    ) {
      await submitProfilingConsent({
        variables: {
          result: result.id,
          profilingConsent: profilingConsentValue
        }
      });

      goToUrl(`/campaign-email-conversion/result/${result.id}/user/${userId}`);
    }
    window.scrollTop = 0;
  };

  const page = [];

  if (!campaign || !result || loadingResult || submitProfilingConsentLoading)
    page.push(<Loader key="loading-popup" />);

  if (campaign && result) {
    page.push(
      <div className={styles.parentContainer} key="page-content">
        <div className={styles.mainContainer}>
          <div
            className={`${styles.campaignQuestion} campaign-questions-question`}
          >
            <strong>{translations.PROFILING_QUESTION_CONSENT_TITLE}</strong>
            <br />
            <br />
            {translations.PROFILING_QUESTION_CONSENT_DESCRIPTION}
          </div>
          <div
            className={`${styles.questionContainer} ${
              styles.mcQuestionContainer
            } user-answers-question-container`}
          >
            <div className={styles.mcQuestionAnswers}>
              {profilingConsentOptions &&
                profilingConsentOptions.map((option, index) => (
                  <div
                    key={`answer-option-${index.toString()}`}
                    className={styles.mcAnswerContainer}
                  >
                    <div
                      className={
                        profilingConsentValue === option.value
                          ? `${styles.answer} ${styles.active} active-answer`
                          : styles.answer
                      }
                      role="presentation"
                      onClick={() => onChangeValue(option.value)}
                    >
                      {option.label}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div>
          <Footer
            blockId={block && block.id}
            resultId={result && result.id}
            onClickNext={onClickNext}
            onClickBack={false}
            disabledNext={!valid}
            nextLabel={translations.NEXT}
            backLabel={translations.BACK}
            secLabel={translations.SEC}
            customThemeBaseColor={customThemeBaseColor}
          />
        </div>
      </div>
    );
  }

  return page;
};
