import { DECISION_FRAMEWORK_TYPES } from '../../../helpers/constants';

export default {
  [DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH]: {
    X_MATRIX_LABEL: 'important',
    Y_MATRIX_LABEL: 'difficile',
    X_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les problèmes suivants: <b>"Je pense que c'est important"</b>`,
    Y_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les problèmes suivants: <b>"Je pense que c'est difficile"</b>`
  },
  [DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'valable',
    Y_MATRIX_LABEL: 'unique',
    X_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les idées suivants: <b>"Je pense que c'est valable"</b>`,
    Y_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les idées suivants: <b>"Je pense que c'est unique"</b>`
  },
  [DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH]: {
    X_MATRIX_LABEL: 'valable',
    Y_MATRIX_LABEL: 'une exigence de base',
    X_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les concepts suivants: <b>"Je pense que c'est valable"</b>`,
    Y_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les concepts suivants: <b>"Je pense que c'est une exigence de base"</b>`
  },
  [DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH]: {
    X_MATRIX_LABEL: 'valable',
    Y_MATRIX_LABEL: 'unique',
    X_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les concepts suivants: <b>"Je pense que c'est valable"</b>`,
    Y_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les concepts suivants: <b>"Je pense que c'est unique"</b>`
  },
  [DECISION_FRAMEWORK_TYPES.CUSTOM_GRAPH]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field empty for this type
    X_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les concepts suivants: <b>"Je pense que c'est ..."</b>`,
    Y_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les concepts suivants: <b>"Je pense que c'est ..."</b>`
  },
  [DECISION_FRAMEWORK_TYPES.SINGLE]: {
    X_MATRIX_LABEL: '', // field empty for this type
    Y_MATRIX_LABEL: '', // field not available for this type
    X_MATRIX_QUESTION: `Qu'est-ce que tu penses de cette affirmation pour les concepts suivants: <b>"Je pense que c'est ..."</b>`,
    Y_MATRIX_QUESTION: '' // field not available for this type
  }
};
