import React from 'react';
import AppBar from '../AppBar/AppBar';

import styles from '../RegistrationPage.module.css';
import availableAppleStore from '../../../../assets/img/app-store.png';
import availablePlayStore from '../../../../assets/img/play-store.png';
import registedSuccessfulHerdies from '../../../../assets/img/register-successful-herdies.svg';
import QRCode from '../../../../assets/img/qrcode-buffl-app.svg';

const Completed = props => {
  const { fields, setFieldValue, translations } = props;

  return (
    <div className={styles.registrationWrapper}>
      <AppBar fields={fields} setFieldValue={setFieldValue} />
      <div className={`${styles.mainContainer} ${styles.completedContainer}`}>
        <img
          src={registedSuccessfulHerdies}
          className={styles.centeredImage}
          alt="BUFFL successful registered"
        />
        <h1 className={`${styles.title} ${styles.completedTitle}`}>
          {translations.CONGRATULATIONS}
        </h1>
        <p className={styles.completedText}>
          {translations.PART_OF_THE_HERD}{' '}
          {translations.DOWNLOAD_AND_START_EARNING_TOKENS}
        </p>
        <img className={styles.qrCode} src={QRCode} alt="BUFFL app QR code" />
        <div
          className={styles.mobileAppsContainer}
          style={{ paddingBottom: '50px' }}
        >
          <a
            href="https://itunes.apple.com/be/app/buffl/id1356715033?l=nl&mt=8"
            target="new"
          >
            <img src={availableAppleStore} alt="Buffl on Apple Store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=org.nativescript.buffl"
            target="new"
          >
            <img src={availablePlayStore} alt="Buffl on Play Store" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Completed;
