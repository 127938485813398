import React from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

import './Draft.css';

const Draft = props => {
  const { block } = props;

  // Create state for editor and check if question already has a draft value
  const createEditorState = () => {
    if (
      block.question &&
      (block.question !== undefined || block.question !== '')
    ) {
      const parsedQuestion = JSON.parse(block.question);

      if (
        parsedQuestion.blocks &&
        (parsedQuestion.blocks > 1 || parsedQuestion.blocks[0].text !== '')
      ) {
        const contentState = convertFromRaw(parsedQuestion);
        return EditorState.createWithContent(contentState);
      }
    }

    return null;
  };

  const editorState = createEditorState();

  const RenderStyleControls = () => {
    if (editorState) {
      return <Editor editorState={editorState} readOnly="true" />;
    }
    return block.type;
  };

  return <RenderStyleControls />;
};

export default Draft;
