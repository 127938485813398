import React from 'react';

import Icon from '../../../../../../../../../Icon/Icon';
import styles from './TooltipContainer.module.css';

export default ({ setShowTooltip }) => (
  <div className={styles.container}>
    <div className={styles.arrow} />
    <div className={styles.header}>
      What{"'"}s the difference?
      <Icon
        type="x_white"
        className={styles.xIcon}
        onClick={() => setShowTooltip(prev => !prev)}
      />
    </div>
    <div className={styles.description}>
      <div className={styles.addAnswerButton}>
        <Icon type="add" className={styles.addAnswerIcon} />
        Add answer
      </div>
      <span>Adds a simple clickable answer to the list of answer options.</span>
      <div className={styles.addAnswerButton}>
        <Icon type="add" className={styles.addAnswerIcon} />
        Add open answer
      </div>
      <span>
        Adds a clickable option that also allows the user to elaborate in an
        input field.
      </span>
      <div className={styles.addAnswerButton}>
        <Icon type="add" className={styles.addAnswerIcon} />
        Add none of the above
      </div>
      <span>
        Adds a clickable option that unselects all other answer options and
        allows respondents to proceed even if a minimum number of answer options
        is required.
      </span>
    </div>
  </div>
);
