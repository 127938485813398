import React, { useState } from 'react';

import editBlack from '../../../../../../assets/img/edit-black.svg';

import styles from './ActionNameEditor.module.css';

export default ({ value, onChange }) => {
  const [editStateActive, setEditStateActive] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(value || '');

  return (
    <div className={styles.container}>
      <div
        className={
          value
            ? styles.inputFieldContainer
            : `${styles.inputFieldContainer} ${styles.placeholder}`
        }
      >
        {editStateActive ? (
          <input
            autoFocus // eslint-disable-line
            className={styles.inputField}
            value={debouncedValue}
            onChange={e => setDebouncedValue(e.target.value)}
            onBlur={() => {
              onChange(debouncedValue);
              setEditStateActive(false);
            }}
            onKeyDown={e => e.key === 'Enter' && e.target.blur()}
            placeholder="Add automation name"
          />
        ) : (
          <span>{value || 'Add automation name'}</span>
        )}
      </div>
      {!editStateActive ? (
        <img
          alt="Edit name"
          src={editBlack}
          className={styles.editIcon}
          role="presentation"
          onClick={() => setEditStateActive(!editStateActive)}
        />
      ) : null}
    </div>
  );
};
