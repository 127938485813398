import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { useQuery } from '@apollo/react-hooks';

import styles from './UserDetails.module.css';
import SocioDemo from './components/SocioDemo/SocioDemo';
import Footer from '../../../Footer/Footer';
import { GET_USER_DATA_FROM_USER_TOKEN } from '../../../../../../graphql/Campaigns';
import Loader from '../../../../../../base/components/Loader/Loader';

export default props => {
  const {
    translations,
    choices,
    startWebSurvey,
    campaign,
    userId,
    error,
    isTesting,
    match,
    onClickBack,
    userDetailsCompleted,
    setUserDetailsCompleted,
    userDetails,
    setUserDetails,
    customThemeBaseColor,
    translationInputFieldProps,
    onUpdateLocalCustomCopy,
    search
  } = props;

  const [componentErrors, setComponentErrors] = useState(null);
  const [isThatYouSelectedOption, setIsThatYouSelectedOption] = useState(null);
  const [forceSocioDemoInput, setForceSocioDemoInput] = useState(false);

  const query = qs.parse(search);

  const isThatYouOptions = {
    YES: 'YES',
    NO: 'NO'
  };

  let userTokenData = null;
  let userTokenDataLoading = null;
  if (query && query.userToken) {
    ({ data: userTokenData, loading: userTokenDataLoading } = useQuery(
      GET_USER_DATA_FROM_USER_TOKEN,
      {
        variables: { userToken: query.userToken },
        fetchPolicy: 'network-only'
      }
    ));
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    // If User Details page is empty create result and skip page
    if (campaign && !campaign.askForSocioDemoWebSurvey) {
      if (!userDetailsCompleted) {
        setUserDetailsCompleted(true);
      }
      submitUserDetails();
    }

    // getUserDataFromUserToken();
  }, []);

  const languageChoices = {
    English: 'EN',
    Dutch: 'NL',
    French: 'FR',
    German: 'DE'
  };

  const isLoading = userTokenDataLoading;

  const submitUserDetails = (additionalProperties = {}) => {
    document.documentElement.scrollTop = 0;
    setComponentErrors(null);

    const userDataVariables = {
      ...userDetails,
      language: languageChoices[userDetails.language]
    };

    // TODO WHY??
    delete userDataVariables.createProfile;

    const startWebSurveyVariables = {
      survey: campaign.id,
      referrer: userId,
      userData: userDataVariables,
      testing: isTesting(),
      ...additionalProperties
    };

    if (match.params.customClientIdentifier) {
      startWebSurveyVariables.customClientIdentifier =
        match.params.customClientIdentifier;
    }
    if (match.params.customClientName) {
      startWebSurveyVariables.customClientName = match.params.customClientName;
    }
    startWebSurvey({ variables: startWebSurveyVariables });
  };

  /* eslint-disable */
  const onClickNext = () => {
    if (isLoading) return null;

    setComponentErrors(null);

    if (
      query &&
      query.userToken &&
      userTokenData &&
      userTokenData.getUserDataFromUserToken &&
      userTokenData.getUserDataFromUserToken.email &&
      isThatYouSelectedOption === isThatYouOptions.YES
    ) {
      setUserDetailsCompleted(true);
      submitUserDetails({
        userToken: query.userToken
      });
      return null;
    }

    if (
      query &&
      query.userToken &&
      userTokenData &&
      userTokenData.getUserDataFromUserToken &&
      userTokenData.getUserDataFromUserToken.email &&
      isThatYouSelectedOption === isThatYouOptions.NO
    ) {
      setForceSocioDemoInput(true);
      setIsThatYouSelectedOption(null);
      return null;
    }

    if (
      campaign.askForSocioDemoWebSurvey &&
      (!userDetails.gender ||
        !userDetails.age ||
        (!campaign.hidePostalCodeInWebSurvey && !userDetails.city))
    ) {
      setComponentErrors(translations.ALL_FIELDS_ARE_REQUIRED);
      return null;
    }

    if (!campaign.hidePostalCodeInWebSurvey && userDetails.city.length !== 4) {
      setComponentErrors(translations.PLEASE_ENTER_VALID_POSTAL_CODE);
      return null;
    }

    setUserDetailsCompleted(true);
    submitUserDetails();
  };
  /* eslint-enable */

  const userNameFromUserToken =
    userTokenData && userTokenData.getUserDataFromUserToken
      ? userTokenData.getUserDataFromUserToken.email ||
        userTokenData.getUserDataFromUserToken.nickname
      : null;

  if (!choices || (campaign && !campaign.askForSocioDemoWebSurvey)) return null;

  return (
    <div className={styles.parentContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          {!componentErrors && error && (
            <div
              className={`${
                styles.userDetailsError
              } campaign-user-detail-error`}
            >
              {error}
            </div>
          )}

          {isLoading ? <Loader /> : null}
          {!forceSocioDemoInput &&
          query &&
          query.userToken &&
          userTokenData &&
          userTokenData.getUserDataFromUserToken &&
          userTokenData.getUserDataFromUserToken.email &&
          !isLoading ? (
            <div className={styles.isThatYouTopContainer}>
              <div className={styles.isThatYouTopContainerTitle}>
                {translations.USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_AS}{' '}
                {userNameFromUserToken}?
              </div>
              <div>
                {translations.USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO_NEED_DATA}
              </div>

              <div className={styles.isThatYouContainer}>
                <div
                  role="presentation"
                  onClick={() =>
                    setIsThatYouSelectedOption(isThatYouOptions.YES)
                  }
                  className={styles.isThatYouOptionSelector}
                  style={{
                    // eslint-disable-next-line
                    backgroundColor: customThemeBaseColor
                      ? isThatYouSelectedOption === isThatYouOptions.YES
                        ? customThemeBaseColor
                        : `${customThemeBaseColor}10`
                      : isThatYouSelectedOption === isThatYouOptions.YES
                      ? '#a7e0f5'
                      : '#e9f7fc'
                  }}
                >
                  {translations.USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_YES}
                </div>

                <div
                  role="presentation"
                  onClick={() =>
                    setIsThatYouSelectedOption(isThatYouOptions.NO)
                  }
                  className={styles.isThatYouOptionSelector}
                  style={{
                    // eslint-disable-next-line
                    backgroundColor: customThemeBaseColor
                      ? isThatYouSelectedOption === isThatYouOptions.NO
                        ? customThemeBaseColor
                        : `${customThemeBaseColor}10`
                      : isThatYouSelectedOption === isThatYouOptions.NO
                      ? '#a7e0f5'
                      : '#e9f7fc'
                  }}
                >
                  {translations.USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO}
                </div>
              </div>
            </div>
          ) : null}
          {(forceSocioDemoInput ||
            !query ||
            !query.userToken ||
            (!userTokenData ||
              !userTokenData.getUserDataFromUserToken ||
              !userTokenData.getUserDataFromUserToken.email)) &&
          !isLoading ? (
            <SocioDemo
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              componentErrors={componentErrors}
              translations={translations}
              hidePostalCode={campaign.hidePostalCodeInWebSurvey}
              translationInputFieldProps={translationInputFieldProps}
              onUpdateLocalCustomCopy={onUpdateLocalCustomCopy}
            />
          ) : null}
        </div>
      </div>
      <div>
        <Footer
          onClickNext={() => onClickNext()}
          onClickBack={onClickBack}
          backLabel={translations.BACK}
          nextLabel={translations.NEXT}
          allowMarginTop
          hideIllustrationsInWebSurvey={
            campaign &&
            campaign.hideIllustrationsInWebSurvey &&
            campaign.customBranding
          }
          customThemeBaseColor={customThemeBaseColor}
        />
      </div>
    </div>
  );
};
