import React, { useState, useEffect } from 'react';

import copyLinkToClipboard from '../../helpers/copyLinkToClipboard';

import linkIcon from '../../../../../../assets/img/link.svg';
import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';
import styles from './WebSurveyRedirections.module.css';

const WebSurveyRedirections = props => {
  const { survey, updateSurveySettings } = props;

  const [redirectionsWebSurvey, setRedirectionsWebSurvey] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [linkCopied, setLinkCopied] = useState({});

  useEffect(
    () => {
      if (!survey) return;

      const updateRedirects = { ...redirectionsWebSurvey };

      if (survey.surveyEndSuccessRedirection)
        updateRedirects.surveyEndSuccessRedirection =
          survey.surveyEndSuccessRedirection;

      if (survey.surveyEndFailedRedirection)
        updateRedirects.surveyEndFailedRedirection =
          survey.surveyEndFailedRedirection;

      if (survey.surveyQuotaFullRedirection)
        updateRedirects.surveyQuotaFullRedirection =
          survey.surveyQuotaFullRedirection;

      setRedirectionsWebSurvey(updateRedirects);
    },
    [survey]
  );

  const redirectionTypes = [
    {
      label: 'Survey end successfully',
      name: 'surveyEndSuccessRedirection',
      placeholder: 'URL in case survey end successfully'
    },
    {
      label: 'Survey failed',
      name: 'surveyEndFailedRedirection',
      placeholder:
        'URL in case respondents are filtered out during survey (not eligible, answer option quota, not accepting privacy policy)'
    },
    {
      label: 'Quota full',
      name: 'surveyQuotaFullRedirection',
      placeholder:
        'URL in case respondents are filtered out due to socio demographics'
    }
  ];

  return (
    <div className={styles.targetAudienceTabContainer}>
      <div className={styles.container}>
        <div
          className={styles.containerTitle}
          role="presentation"
          onClick={() => setExpanded(() => setExpanded(!expanded))}
        >
          Web Survey Redirections
          <img
            src={arrowDownPurple}
            alt="ExpandColapse"
            className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
          />
        </div>
        {expanded ? (
          <div className={styles.blockContainer}>
            <div>
              {redirectionTypes.map((type, index) => (
                <div
                  className={styles.inputContainer}
                  key={`web-survey-redirection-${index.toString()}`}
                >
                  <div className={styles.link}>
                    <img
                      className={styles.websiteIcon}
                      src={linkIcon}
                      alt="Website icon"
                    />
                    <input
                      value={
                        redirectionsWebSurvey &&
                        redirectionsWebSurvey[type.name]
                          ? redirectionsWebSurvey[type.name]
                          : ''
                      }
                      onChange={e =>
                        setRedirectionsWebSurvey({
                          ...redirectionsWebSurvey,
                          [type.name]: e.currentTarget.value
                        })
                      }
                      onBlur={() =>
                        updateSurveySettings(
                          type.name,
                          redirectionsWebSurvey[type.name],
                          true
                        )
                      }
                      placeholder={type.placeholder}
                      className={`${
                        styles.urlInput
                      } web-survey-redirections-input-${type.name}`}
                    />
                  </div>
                  <div
                    className={
                      linkCopied[type.name] === true
                        ? `${styles.copyLinkButton} ${styles.copied}`
                        : styles.copyLinkButton
                    }
                    onClick={() =>
                      copyLinkToClipboard(
                        redirectionsWebSurvey &&
                          redirectionsWebSurvey[type.name]
                          ? redirectionsWebSurvey[type.name]
                          : '',
                        type.name,
                        linkCopied,
                        setLinkCopied
                      )
                    }
                    role="presentation"
                  >
                    {linkCopied[type.name] === true ? 'Copied' : 'Copy link'}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WebSurveyRedirections;
