import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import removeQuestionFromSurvey from '../../../helpers/removeQuestionFromSurvey/removeQuestionFromSurvey';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';
import removeDecisionFramework from '../removeDecisionFramework/removeDecisionFramework';

export default (state, questionId) => {
  const surveyToModify = JSON.parse(JSON.stringify(state.survey));

  const newSurvey = removeQuestionFromSurvey(
    questionId,
    surveyToModify.content,
    surveyToModify.questions,
    true,
    false
  );

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      content: newSurvey.content,
      questions: newSurvey.questions
    }
  };

  const requests = [];

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  // Sort distributor groups according to branch jumps
  sortDistributorGroupsAndJumps(newState);

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  if (
    surveyToModify &&
    surveyToModify.questions &&
    surveyToModify.questions[questionId] &&
    surveyToModify.questions[questionId].matrix &&
    surveyToModify.questions[questionId].matrix.decisionFrameworkType
  ) {
    const { surveyWithRemovedGraph, removeRequest } = removeDecisionFramework(
      newState.survey,
      surveyToModify.questions[questionId],
      surveyToModify.questions[questionId].matrix.decisionFrameworkType
    );
    if (surveyWithRemovedGraph && removeRequest) {
      newState = {
        ...newState,
        survey: surveyWithRemovedGraph
      };
      if (removeRequest) {
        requests.push(removeRequest);
      }
    }
  }

  return {
    newState,
    changedFlowsRemovedJumpsToUpdate:
      removedNextFlowJumps.changedFlowsRemovedJumpsToUpdate,
    requests
  };
};
