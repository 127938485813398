export default (
  features,
  mergeQuestionName,
  setError,
  templateTypes,
  currentTemplatesTab,
  templateConfiguration
) => {
  if (!mergeQuestionName || !mergeQuestionName.length) {
    setError('Graph name missing');
    return true;
  }
  for (let i = 0; i < features.length; i += 1) {
    if (
      !features[i][templateConfiguration.xQuestionName] ||
      !features[i][templateConfiguration.yQuestionName] ||
      !features[i].description.length
    ) {
      setError('Empty fields');
      return true;
    }

    if (
      features[i][templateConfiguration.xQuestionName].id ===
      features[i][templateConfiguration.yQuestionName].id
    ) {
      setError('You need to combine two questions to create a data point.');
      return true;
    }

    // Number of answers in MC questions for Custom Graphs should be the same for all added features
    if (currentTemplatesTab === templateTypes.CUSTOM_GRAPH) {
      const xQuestionAnswersNumber =
        features[i][templateConfiguration.xQuestionName].answers &&
        features[i][templateConfiguration.xQuestionName].answers.length;
      const yQuestionAnswersNumber =
        features[i][templateConfiguration.yQuestionName].answers &&
        features[i][templateConfiguration.yQuestionName].answers.length;

      const xNextQuestionAnswersNumber =
        features[i + 1] &&
        features[i + 1][templateConfiguration.xQuestionName] &&
        features[i + 1][templateConfiguration.xQuestionName].answers &&
        features[i + 1][templateConfiguration.xQuestionName].answers.length;
      const yNextQuestionAnswersNumber =
        features[i + 1] &&
        features[i + 1][templateConfiguration.yQuestionName] &&
        features[i + 1][templateConfiguration.yQuestionName].answers &&
        features[i + 1][templateConfiguration.yQuestionName].answers.length;

      if (
        xNextQuestionAnswersNumber !== undefined &&
        xQuestionAnswersNumber !== xNextQuestionAnswersNumber
      ) {
        setError(
          'The selected questions should have the same number of answers'
        );
        return true;
      }
      if (
        yNextQuestionAnswersNumber !== undefined &&
        yQuestionAnswersNumber !== yNextQuestionAnswersNumber
      ) {
        setError(
          'The selected questions should have the same number of answers'
        );
        return true;
      }
    }
  }
  return false;
};
