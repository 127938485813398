import gql from 'graphql-tag';

export const REGISTER = gql`
  mutation Register(
    $email: String!
    $password: String!
    $name: String!
    $contactPersonFirstName: String!
    $contactPersonLastName: String!
    $contactPersonPhone: String!
    $savingsTarget: Float!
    $description: String!
    $imageFilename: String
    $language: String!
  ) {
    register(
      input: {
        email: $email
        password: $password
        name: $name
        contactPersonFirstName: $contactPersonFirstName
        contactPersonLastName: $contactPersonLastName
        contactPersonPhone: $contactPersonPhone
        savingsTarget: $savingsTarget
        description: $description
        imageFilename: $imageFilename
        language: $language
      }
    ) {
      organisation {
        id
        email
        name
        contact_person {
          first_name
          last_name
          phone
        }
        savings_target
        description
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      loginToken
    }
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(input: { email: $email }) {
      done
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(input: { password: $password, token: $token }) {
      loginToken
    }
  }
`;
