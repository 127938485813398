import React from 'react';
import styles from './AcceptTermsInput.module.css';

import tick from '../../assets/img/white-tick.svg';

export default props => {
  const {
    name,
    label,
    onClick,
    active,
    setViewTerms,
    customThemeBaseColor,
    declineTerms
  } = props;

  return (
    <div
      className={
        active
          ? `${styles.acceptInputContainer} ${
              styles.acceptInputContainerActive
            } accept-input-${name}`
          : `${styles.acceptInputContainer} accept-input-${name}`
      }
    >
      <div
        className={
          declineTerms
            ? `${styles.hideOnMobile} ${styles.desktopInput}`
            : styles.desktopInput
        }
      >
        <div
          className={styles.checkmark}
          style={{
            borderColor: customThemeBaseColor || '#24B2E8',
            ...(active
              ? { backgroundColor: customThemeBaseColor || '#24B2E8' }
              : {})
          }}
          role="presentation"
          onClick={() => onClick()}
        >
          {active ? (
            <img src={tick} alt="Tick" className={styles.tick} />
          ) : null}
        </div>
        <div className={styles.termsText}>
          {declineTerms ? (
            <span>{label.I_AM_NOT_INTERESTED}</span>
          ) : (
            <>
              {label.I_AM_AWARE_OF_THE}{' '}
              <span
                role="presentation"
                className={styles.termsLink}
                onClick={() => setViewTerms(true)}
              >
                {label.TERMS_AND_CONDITIONS}
              </span>{' '}
              {label.READ_AND_UNDERSTAND} {label.AND_AGREE_BY_CHECKING_THIS_BOX}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
