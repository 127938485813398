import React, { useState } from 'react';
import useLazyQuery from '../../../../../../../users/hooks/useLazyQuery'; // Use from users because other params (to be fixed)

import linkIcon from '../../../../../../assets/img/link-horizontal.svg';

import styles from './ManagerSelectInvite.module.css';

import Dropdown from '../../../Dropdown/Dropdown';

import {
  GET_INVITATION_LINK,
  GET_CLIENT_ACCOUNTS_TO_INVITE
} from '../../../../../../graphql/AccountManagement';

export default ({
  subtitle2,
  inviteText,
  internalRecord,
  setInternalRecord,
  invitationParent,
  invitationType
}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [invitationLink, setInvitationLink] = useState(null);

  const getInvitationLink = useLazyQuery(GET_INVITATION_LINK, {
    fetchPolicy: 'no-cache'
  });

  const getClientAccountsToInvite = useLazyQuery(
    GET_CLIENT_ACCOUNTS_TO_INVITE,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const copyLinkToClipboard = () => {
    if (invitationLink) {
      const dummy = document.createElement('input');
      // dummy.style.display = 'none';
      document.body.appendChild(dummy);

      dummy.setAttribute('id', 'goalurl');
      document.getElementById('goalurl').value = invitationLink;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }
  };

  const onSelectClientCompleted = record => {
    let newMembers = [];

    if (
      internalRecord &&
      internalRecord.managers &&
      internalRecord.managers.length
    ) {
      newMembers = [...internalRecord.managers];
    }

    if (
      !internalRecord.managers ||
      !internalRecord.managers.some(mng => mng.id === record.value)
    ) {
      // Check if already selected
      const newRecord = {
        id: record.value,
        email: record.label
      };

      setInternalRecord({
        ...internalRecord,
        managers: [...newMembers, newRecord]
      });
    } else {
      const filteredSelectedRecords = internalRecord.managers.filter(
        mng => mng.id !== record.value
      );

      setInternalRecord({
        ...internalRecord,
        managers: filteredSelectedRecords
      });
    }
  };

  const fetchClientOptions = async (page, search) => {
    const clientsToInvite = await getClientAccountsToInvite({
      variables: {
        start: 0,
        end: (page + 1) * 10,
        search
      }
    });

    if (
      clientsToInvite &&
      clientsToInvite.data &&
      clientsToInvite.data.getClientAccountsToInvite &&
      clientsToInvite.data.getClientAccountsToInvite.clients &&
      clientsToInvite.data.getClientAccountsToInvite.clients.length
    ) {
      return clientsToInvite.data.getClientAccountsToInvite.clients.map(c => ({
        label: c.email,
        value: c.id
      }));
    }
    return [];
  };

  return (
    <div className={styles.content}>
      <>
        <div className={styles.subtitle}>{subtitle2}</div>
        <div className={styles.dropdownContainer}>
          <Dropdown
            customPlaceholder="Select client account(s)"
            onSelectCompleted={onSelectClientCompleted}
            searchEnabled
            fetchOptions={fetchClientOptions}
            loading={false}
            selectedValues={
              internalRecord && internalRecord.managers
                ? internalRecord.managers
                : []
            }
            labelField="email"
            valueField="id"
            fixedPosition
          />
        </div>
      </>
      <div className={styles.subtitle}>{inviteText}</div>
      {!invitationLink ? (
        <div
          className={styles.copyLinkButton}
          role="presentation"
          onClick={async () => {
            const invitationRecord = invitationParent || internalRecord.id;

            const invitationToken = await getInvitationLink({
              variables: {
                record: invitationRecord,
                invitationType
              }
            });
            if (
              invitationToken &&
              invitationToken.data &&
              invitationToken.data.getInvitationLink &&
              invitationToken.data.getInvitationLink.link
            ) {
              setInvitationLink(invitationToken.data.getInvitationLink.link);
            }
          }}
        >
          <img src={linkIcon} alt="Link icon" className={styles.linkIcon} />
          Generate link
        </div>
      ) : null}
      {invitationLink ? (
        <div className={styles.invitationLinkContainer}>
          <input value={invitationLink || ''} onChange={() => {}} />
          {!linkCopied ? (
            <div
              role="presentation"
              className={styles.copyInputLink}
              onClick={() => {
                copyLinkToClipboard();
                setLinkCopied(true);
              }}
            >
              Copy link
            </div>
          ) : null}
          {linkCopied ? (
            <div className={styles.copiedInputLink}>Copied</div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
