import useLazyQuery from '../../../surveys/hooks/useLazyQuery';

import {
  GET_ACTIVE_USERS,
  GET_RESPONSE_RATE,
  GET_COMMUNITY_SIZE
} from '../../../graphql/Dashboard';

export default () => [
  {
    getActiveUsersApi: useLazyQuery(GET_ACTIVE_USERS, {
      fetchPolicy: 'no-cache'
    }),
    getResponseRateApi: useLazyQuery(GET_RESPONSE_RATE, {
      fetchPolicy: 'no-cache'
    }),
    getCommunitySizeApi: useLazyQuery(GET_COMMUNITY_SIZE, {
      fetchPolicy: 'no-cache'
    })
  }
];
