// eslint-disable-next-line import/prefer-default-export
export const COMMUNITY_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC'
};

export const COMMUNITY_TYPE_LABELS = {
  [COMMUNITY_TYPES.STATIC]: 'Static',
  [COMMUNITY_TYPES.DYNAMIC]: 'Dynamic'
};
