import React, { useState, useEffect, useContext } from 'react';

import SurveyBuilderPageContext from '../SurveyBuilderPageContext';

import AgeDynamicFilters from './components/AgeDynamicFilters/AgeDynamicFilters';
import GenderDynamicFilters from './components/GenderDynamicFilters/GenderDynamicFilters';
import TargetAudienceHeader from './components/TargetAudienceHeader/TargetAudienceHeader';
import ProfilingQuestions from './components/ProfilingQuestions/ProfilingQuestions';
import PreviousSurveyParticipantConfiguration from './components/PreviousSurveyParticipantConfiguration/PreviousSurveyParticipantConfiguration';

import { TARGET_AUDIENCE_STEPS } from '../SurveyBuilder/helpers/constants';

import prepareSurveyToLoadInState from '../SurveyBuilder/helpers/prepareSurveyToLoadInState';

import styles from './TargetAudience.module.css';
import Popup from '../Popup/Popup';
import WarningPopup from './components/WarningPopup/WarningPopup';

export default ({
  match,
  history,
  actions,
  accountManagement,
  TARGET_AUDIENCE_TAB
}) => {
  const [confirmationPopup] = useState(null);
  const [targetAudienceStep, setTargetAudienceStep] = useState(
    (() => {
      if (match && match.params && match.params.nodeId) {
        if (TARGET_AUDIENCE_STEPS[match.params.nodeId.toUpperCase()]) {
          return TARGET_AUDIENCE_STEPS[match.params.nodeId.toUpperCase()];
        }
      }

      return TARGET_AUDIENCE_STEPS.SOCIO_DEMOGRAPHICS;
    })()
  );

  const { api, survey, surveyRefetch } = useContext(SurveyBuilderPageContext);
  const [userFilter, setUserFilter] = useState(
    survey.userFilter ? JSON.parse(survey.userFilter) : {}
  );

  useEffect(() => {
    const setSurvey = () => {
      const surveyToSet = prepareSurveyToLoadInState(survey);

      if (surveyToSet === false) {
        alert('Please contact administrator!');
      }

      actions.setSurvey({
        ...surveyToSet.surveyTemp,
        questions: surveyToSet.questions
      });

      if (match && match.params && match.params.nodeId) {
        actions.setActiveFlowFromId(match.params.nodeId);
      }
    };

    setTimeout(() => {
      setSurvey();
    }, 1);
  }, []);

  const updateSurveySettings = async (attributes, waitingTime) => {
    await api(
      'updateTargetAudience',
      {
        variables: {
          survey: survey.id,
          ...attributes
        }
      },
      waitingTime
    );
    await surveyRefetch();
  };

  const onUserFilterChange = (parsedUserFilter, filterSettings) => {
    setUserFilter(parsedUserFilter);

    if (parsedUserFilter) {
      let updateProperties = {
        userFilter: JSON.stringify(parsedUserFilter)
      };

      if (filterSettings) {
        updateProperties = {
          ...updateProperties,
          filterSettings
        };
      }

      updateSurveySettings(updateProperties);
    }
  };

  return (
    <div className={styles.topContainer}>
      <TargetAudienceHeader
        TARGET_AUDIENCE_TAB={TARGET_AUDIENCE_TAB}
        history={history}
        surveyId={survey.id}
        targetAudienceStep={targetAudienceStep}
        setTargetAudienceStep={setTargetAudienceStep}
      />

      {targetAudienceStep === TARGET_AUDIENCE_STEPS.SOCIO_DEMOGRAPHICS ? (
        <>
          <AgeDynamicFilters
            userFilter={userFilter}
            filterSettings={survey.filterSettings}
            onUserFilterChange={(e, filterSettingsToUpdate) =>
              onUserFilterChange(e, filterSettingsToUpdate)
            }
            updateSurveySettings={updateSurveySettings}
            maxResponses={survey.maxResponses}
          />

          <GenderDynamicFilters
            userFilter={userFilter}
            filterSettings={survey.filterSettings}
            onUserFilterChange={(e, filterSettingsToUpdate) =>
              onUserFilterChange(e, filterSettingsToUpdate)
            }
            updateSurveySettings={updateSurveySettings}
            maxResponses={survey.maxResponses}
          />
        </>
      ) : null}

      {targetAudienceStep === TARGET_AUDIENCE_STEPS.PROFILING_QUESTIONS ? (
        <ProfilingQuestions
          api={api}
          actions={actions}
          targetAudienceStep={targetAudienceStep}
          survey={survey}
        />
      ) : null}

      {targetAudienceStep === TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS ? (
        <PreviousSurveyParticipantConfiguration
          surveyId={survey.id}
          surveyParticipationConfiguration={
            (survey && survey.participationConfiguration) || null
          }
          onUpdate={async (property, value) => {
            await api('updateSurvey', {
              variables: {
                survey: survey.id,
                [property]: value
              }
            });
          }}
          selectedAccountManagementRecord={
            (accountManagement &&
              accountManagement.selectedAccountManagementRecord &&
              accountManagement.selectedAccountManagementRecord.value) ||
            null
          }
        />
      ) : null}

      {confirmationPopup ? (
        <Popup
          component={
            <WarningPopup
              onClose={confirmationPopup.onClose}
              title={confirmationPopup.title}
              description={confirmationPopup.description}
            />
          }
          onClose={() => {}}
        />
      ) : null}
    </div>
  );
};
