import React from 'react';

import SelectDropdown from '../../../../../../../../form/SelectDropdown/SelectDropdown';
import TranslationInputField from '../../../../../TranslationInputField/TranslationInputField';

import errorIcon from '../../../../../../../../assets/img/error-rebranded.svg';

import styles from '../../UserDetails.module.css';

const ageChoices = new Array(100 - 16 + 1)
  .fill(undefined)
  .map((_, i) => i + 16);

export default props => {
  const {
    userDetails,
    setUserDetails,
    componentErrors,
    translations,
    hidePostalCode,
    translationInputFieldProps,
    onUpdateLocalCustomCopy
  } = props;

  const genderChoices = [
    translations.MALE,
    translations.FEMALE,
    translations.OTHER
  ];

  const genderValues = {
    [translations.MALE]: {
      value: 'M'
    },
    [translations.FEMALE]: {
      value: 'F'
    },
    [translations.OTHER]: {
      value: 'O'
    }
  };

  const genderKeys = {
    M: {
      key: translations.MALE
    },
    F: {
      key: translations.FEMALE
    },
    O: {
      key: translations.OTHER
    }
  };

  const customCopyFields = {
    socioDemo: {
      name: 'socioDemo',
      fields: {
        YOU_ARE_NOT_JANE: 'YOU_ARE_NOT_JANE',
        SOCIO_DEMO: 'SOCIO_DEMO'
      }
    }
  };

  return (
    <div>
      <TranslationInputField
        name={customCopyFields.socioDemo.name}
        translationKey={customCopyFields.socioDemo.fields.YOU_ARE_NOT_JANE}
        value={translations[customCopyFields.socioDemo.fields.YOU_ARE_NOT_JANE]}
        customClasses={`${styles.title} campaign-user-details-page-title`}
        {...translationInputFieldProps}
        onUpdateLocalCustomCopy={(key, value, name) =>
          onUpdateLocalCustomCopy(key, value, name)
        }
      />
      <TranslationInputField
        name={customCopyFields.socioDemo.name}
        translationKey={customCopyFields.socioDemo.fields.SOCIO_DEMO}
        value={translations[customCopyFields.socioDemo.fields.SOCIO_DEMO]}
        customClasses={`${
          styles.description
        } campaign-user-details-page-description`}
        {...translationInputFieldProps}
        onUpdateLocalCustomCopy={(key, value, name) =>
          onUpdateLocalCustomCopy(key, value, name)
        }
      />
      <div className={styles.userDetailsPage}>
        <div className={styles.formContainer}>
          <div className={styles.formContainerSection}>
            <div className={styles.genderSelectorContainer}>
              <SelectDropdown
                name="gender"
                value={
                  genderKeys[userDetails.gender]
                    ? genderKeys[userDetails.gender].key
                    : ''
                }
                onChange={value =>
                  setUserDetails({
                    ...userDetails,
                    gender: genderValues[value] ? genderValues[value].value : ''
                  })
                }
                options={genderChoices}
                className={`campaign-user-details-gender-input ${
                  componentErrors &&
                  (!userDetails.gender ||
                    userDetails.gender === null ||
                    userDetails.gender === '')
                    ? styles.errorField
                    : ''
                }`}
                placeholder={translations.GENDER}
                // noValueSelectedClassName={styles.placeholder}
              />
            </div>
            <div className={styles.ageSelectorContainer}>
              <SelectDropdown
                name="age"
                value={userDetails.age ? userDetails.age : ''}
                onChange={value =>
                  setUserDetails({ ...userDetails, age: value })
                }
                options={ageChoices}
                className={`campaign-user-details-age-input ${
                  componentErrors &&
                  (!userDetails.age ||
                    userDetails.age === null ||
                    userDetails.age === '')
                    ? styles.errorField
                    : ''
                }`}
                placeholder={translations.AGE}
              />
            </div>
          </div>
          {!hidePostalCode && (
            <div className={styles.postalSelectorContainer}>
              <input
                onChange={event => {
                  if (
                    event &&
                    event.target &&
                    event.target.value.length === 0
                  ) {
                    setUserDetails({ ...userDetails, city: null });
                    return;
                  }

                  if (
                    event &&
                    event.target &&
                    event.target.value.length <= 4 &&
                    event.target.value.match(/^-{0,1}\d+$/)
                  ) {
                    setUserDetails({
                      ...userDetails,
                      city: event.target.value
                    });
                  }
                }}
                value={userDetails.city ? userDetails.city : ''}
                className={`campaign-user-details-city-input ${
                  componentErrors &&
                  (!userDetails.city ||
                    userDetails.city === null ||
                    userDetails.city === '' ||
                    userDetails.city.length !== 4)
                    ? styles.errorField
                    : ''
                }`}
                type="number"
                onKeyDown={evt =>
                  !((evt.key >= 0 && evt.key <= 9) || evt.key === 'Backspace')
                    ? evt.preventDefault()
                    : null
                }
                placeholder={translations.POSTAL_CODE}
              />
            </div>
          )}
          {componentErrors && componentErrors.length ? (
            <div className={styles.errorContainer}>
              <img src={errorIcon} alt="Error" className={styles.errorIcon} />
              <span>{componentErrors}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
