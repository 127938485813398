import React, { useState, useEffect } from 'react';

import questionMark from '../../../../../../../../assets/img/accountmanagement/am-questionmark.svg';

import styles from './NumberInputTooltip.module.css';

export default ({ textContainerRef, tooltipText }) => {
  const [tooltipPosition, setTooltipPosition] = useState(null);

  useEffect(
    () => {
      if (!tooltipPosition && textContainerRef && textContainerRef.current) {
        const rect = textContainerRef.current.getBoundingClientRect();
        const top = rect.top - 5;
        const left = rect.right;

        setTooltipPosition({
          top,
          left
        });
      }
    },
    [textContainerRef]
  );

  return (
    <div className={styles.tooltipContainer}>
      <img
        className={styles.tooltipIcon}
        alt={tooltipText}
        src={questionMark}
      />
      {tooltipPosition && (
        <div
          className={styles.tooltipPopup}
          style={{
            left: `${tooltipPosition.left}px`,
            top: `${tooltipPosition.top}px`
          }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};
