import React from 'react';

import styles from './DropdownPopup.module.css';

import Dropdown from '../../../../../../../../../../../Dropdown';
import Icon from '../../../../../../../../../../../Icon';

class DropdownPopup extends Dropdown {
  constructor(props) {
    super(props);

    this.state = { selectedOptions: props.selectedOptions };
  }

  render() {
    const {
      options,
      selectedOptions,
      onSelectCompleted,
      multipleChoice,
      setShowDropdownPopup,
      inverse
    } = this.props;

    const that = this;

    const isOptionSelected = option => {
      if (
        selectedOptions &&
        selectedOptions.length &&
        (selectedOptions[0].value || selectedOptions[0].value === 0)
      ) {
        const seletedValue = selectedOptions.filter(
          o => o.value === option.value
        );
        if (seletedValue && seletedValue.length) {
          return true;
        }
      }
      return false;
    };

    const parseQuestion = question => {
      let questionValue;
      try {
        questionValue = [].concat(
          JSON.parse(question)
            .blocks.map(draftBlock => draftBlock.text)
            .join('\n')
        );
      } catch (error) {
        questionValue = question;
      }
      return questionValue;
    };

    return (
      <div ref={that.setWrapperRef} className={styles.container}>
        <div
          className={`${
            styles.dropdownPopupItemsContainer
          } workspaces-dropdown-popup-options-container`}
        >
          {options && options.length ? (
            options.map((option, index) => (
              <div
                className={`${
                  styles.dropdownItem
                } workspaces-dropdown-popup-option`}
                key={`dropdown-popup-item-${index.toString()}`}
                role="presentation"
                onClick={() => {
                  if (multipleChoice) {
                    const alreadySelectedOption = that.state.selectedOptions.filter(
                      o => o.value === option.value
                    );
                    let newSelectedOptions = null;
                    if (alreadySelectedOption && alreadySelectedOption.length) {
                      newSelectedOptions = that.state.selectedOptions.filter(
                        o => o.value !== option.value
                      );
                    } else {
                      newSelectedOptions = [
                        ...that.state.selectedOptions,
                        ...[option]
                      ];
                    }

                    that.setState({ selectedOptions: newSelectedOptions });
                    onSelectCompleted(newSelectedOptions);
                  }
                  if (!multipleChoice) {
                    const newSelectedOptions = [option];
                    that.setState({ selectedOptions: newSelectedOptions });
                    onSelectCompleted(newSelectedOptions);
                    setShowDropdownPopup(false);
                  }
                }}
              >
                {multipleChoice ? (
                  <span
                    className={`${
                      isOptionSelected(option)
                        ? `${
                            styles.dropdownItemCheckboxSelected
                          } workspaces-dropdown-popup-selected`
                        : `${
                            styles.dropdownItemCheckbox
                          } workspaces-dropdown-popup-unselected`
                    } check-icon`}
                  >
                    <Icon
                      type={isOptionSelected(option) ? 'checked' : 'unchecked'}
                    />
                  </span>
                ) : null}
                <div
                  className={`${
                    styles.dropdownItemLabel
                  } workspaces-dropdown-popup-option-label`}
                >
                  {inverse
                    ? `NOT ${parseQuestion(option.label)}`
                    : parseQuestion(option.label)}
                </div>
              </div>
            ))
          ) : (
            <div
              className={`${
                styles.noAvailableOptions
              } workspaces-dropdown-popup-no-available-options`}
            >
              No available options
            </div>
          )}
        </div>
        {multipleChoice ? (
          <div className={styles.dropdownPopupFooterContainer}>
            <div
              className={`${
                styles.dropdownPopupConfirm
              } workspaces-dropdown-popup-option-footer-done`}
              role="presentation"
              onClick={() => setShowDropdownPopup(false)}
            >
              {selectedOptions && selectedOptions.length ? 'Done' : 'Close'}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DropdownPopup;
