import React, { useState, useEffect } from "react";
import * as d3 from "d3";

const Gauge = props => {
  const { data, onChartClick, filterCollectDataGroup } = props;
  const [svg, setSvg] = useState(null);

  // Did mount
  useEffect(() => {
    drawChart(svg, props);
  }, []);

  // Did update
  useEffect(
    () => {
      drawChart(svg, props);
    },
    [svg, data]
  );

  const percToDeg = perc => perc * 360;

  const percToRad = perc => degToRad(percToDeg(perc));

  const degToRad = deg => (deg * Math.PI) / 180;

  const addArc = (chartData, arcStartRad, chart, radius, barWidth) => {
    chart
      .append("path")
      .attr(
        "class",
        `arc ${
          chartData.name
        } result-chart-cursor-pointer result-chart-hover-opacity`
      )
      .attr("fill", chartData.color)
      .on("click", () => {
        onChartClick(
          filterCollectDataGroup.name,
          filterCollectDataGroup.valuePath,
          chartData.name
        );
      });
    const arc = d3
      .arc()
      .outerRadius(radius - 1)
      .innerRadius(radius - 1 - barWidth);
    const arcEndRad = arcStartRad + percToRad(chartData.value / 2);
    arc.startAngle(arcStartRad).endAngle(arcEndRad);
    chart.select(`.${chartData.name}`).attr("d", arc);
    return arcEndRad;
  };

  const drawChart = (svgRef, chartProps) => {
    if (svgRef) {
      const { width, height } = chartProps;
      const barWidth = 10;
      const radius = 60;
      let arcStartRad = degToRad(270);

      const svgChart = d3
        .select(svgRef)
        .attr("width", width)
        .attr("height", height);

      const chart = svgChart
        .append("g")
        .attr("transform", `translate(${width / 2},${radius})`);

      if (chartProps.data && chartProps.data.length) {
        chartProps.data.forEach(arc => {
          arcStartRad = addArc(arc, arcStartRad, chart, radius, barWidth);
        });
      }
    }
  };

  return (
    <div>
      {data && data.length ? (
        <div className="gauge-container">
          <svg
            ref={elem => {
              if (elem) {
                setSvg(elem);
              }
            }}
          />
          <div className="gauge-legend-container">
            {data.map(dataItem => (
              <div className="gauge-legend-item-container" key={dataItem.name}>
                <span
                  className="gauge-legent-item-bullet"
                  style={{ backgroundColor: dataItem.color }}
                />
                <span className="gauge-legent-item-label">
                  {dataItem.label}
                </span>
                <span className="gauge-legent-item-value">
                  {(dataItem.value * 100).toFixed(2)}%
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default Gauge;
