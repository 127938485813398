import React from 'react';
import Icon from '../../../../../../../../../../../Icon/Icon';
import LjArrow from '../../../../../../../../../../../Icon/custom/LjArrow';
import AnswerQuotaInput from './components/AnswerQuotaInput/AnswerQuotaInput';
import ConstraintTooltip from '../../../../../../../../../ConstraintTooltip/ConstraintTooltip';

import { QUOTA_UNITS } from '../../../../../../../../../../../../helpers/constants';

import warningIcon from '../../../../../../../../../../../../../../../../assets/img/exclamation.svg';

import styles from './AnswerQuota.module.css';

export default ({
  choice,
  actions,
  questionId,
  minQuotaHigherThanMax,
  minQuotaExceedsTotalSample,
  minQuotaDisabled
}) => (
  <div
    className={
      minQuotaHigherThanMax || minQuotaExceedsTotalSample
        ? `${styles.container} ${styles.error}`
        : styles.container
    }
  >
    <div className={styles.dropdownRow}>
      <div className={styles.dropdownRowParent}>
        <div className={styles.ljArrow}>
          <LjArrow
            fill={
              minQuotaHigherThanMax || minQuotaExceedsTotalSample
                ? '#f61662'
                : undefined
            }
          />
        </div>
        Minimum
        <AnswerQuotaInput
          value={
            choice.quotaMinAmount || choice.quotaMinAmount === 0
              ? choice.quotaMinAmount
              : ''
          }
          onChange={e => {
            const newChoice = choice;
            newChoice.quotaMinAmount = parseInt(e.currentTarget.value, 10);
            newChoice.quotaType = QUOTA_UNITS.ABSOLUTE;
            actions.updateQuestionChoice(questionId, newChoice);
          }}
          disabled={minQuotaDisabled}
        />
        and maximum
        <AnswerQuotaInput
          value={
            choice.quotaAmount || choice.quotaAmount === 0
              ? choice.quotaAmount
              : ''
          }
          onChange={e => {
            const newChoice = choice;
            newChoice.quotaAmount = parseInt(e.currentTarget.value, 10);
            newChoice.quotaType = QUOTA_UNITS.ABSOLUTE;
            actions.updateQuestionChoice(questionId, newChoice);
          }}
        />
        respondents
        {minQuotaHigherThanMax || minQuotaExceedsTotalSample ? (
          <div className={styles.errorIconContainer}>
            <img src={warningIcon} alt="Error" />
            <span className={styles.errorTooltip}>
              {minQuotaExceedsTotalSample
                ? `Minimum quota cannot exceed total sample size${
                    minQuotaHigherThanMax ? '\n\n' : ''
                  }`
                : ''}
              {minQuotaHigherThanMax ? 'Minimum cannot exceed maximum' : ''}
            </span>
          </div>
        ) : null}
      </div>
      <span
        role="presentation"
        className={styles.close}
        onClick={() => {
          const newChoice = choice;
          newChoice.quotaAmount = null;
          newChoice.quotaMinAmount = null;
          newChoice.quotaType = null;
          actions.updateQuestionChoice(questionId, newChoice);
        }}
      >
        <Icon type="x" />
      </span>
      {minQuotaDisabled ? (
        <div className={styles.constraintTooltip}>
          <ConstraintTooltip label="You cannot apply minimum quota after a distributor or logic jump." />
        </div>
      ) : null}
    </div>
  </div>
);
