import React, { useContext } from 'react';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import Footer from '../../Footer/Footer';
import FlowChart from '../../../../FlowNavigation/FlowChart';

import createSurveyTemplateEvent from '../../../../../helpers/eventTracking/createSurveyTemplateEvent';

import styles from './LogicMap.module.css';

export default () => {
  const {
    currentStep,
    setWizardHidden,
    goToPreviousStep,
    strings,
    actions,
    steps
  } = useContext(TemplatesWizardContext);

  const onNextClick = () => {
    actions.templateWizardToSurvey(steps);
    setWizardHidden(true);
    createSurveyTemplateEvent(steps);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{strings.LOGIC_MAP_FOR_SURVEY}</div>
      <div className={styles.chartContainer}>
        <FlowChart
          width="795"
          height="100%"
          onQuestionClick={() => {}}
          surveyLists={currentStep.value.surveyLists}
          content={currentStep.value.surveyContent}
          questions={currentStep.value.questionsMapping}
          activeFlowId={null}
        />
      </div>
      <div className={styles.footerContainer}>
        <Footer
          onNextClick={onNextClick}
          onPreviousClick={goToPreviousStep}
          nextLabel={strings.NEXT}
          previousLabel={strings.PREVIOUS}
        />
      </div>
    </div>
  );
};
