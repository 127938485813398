import React from 'react';

import bubble1 from '../../../assets/img/bubble1.svg';
import bubble2 from '../../../assets/img/bubble2.svg';

import styles from './Bubbles1.module.css';

const Bubbles1 = () => (
  <div className={styles.container}>
    <img src={bubble1} className={styles.bubble1} alt="Bubble 1" />
    <img src={bubble2} className={styles.bubble2} alt="Bubble 2" />
  </div>
);

export default Bubbles1;
