import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import getBlockQuestion from '../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/getBlockQuestion';

import styles from './ProfileQuestionsTable.module.css';

import dutchFlag from '../../../../campaigns/components/CampaignTable/CampaignListItem/assets/dutch.png';
import englishFlag from '../../../../campaigns/components/CampaignTable/CampaignListItem/assets/english.png';
import frenchFlag from '../../../../campaigns/components/CampaignTable/CampaignListItem/assets/french.png';
import germanFlag from '../../../../campaigns/components/CampaignTable/CampaignListItem/assets/german.png';

import { SET_PROFILING_QUESTION_VISIBILITY } from '../../../../graphql/Question';

import { SURVEY_LANGUAGES } from '../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/constants';

import Popup from '../../../../campaigns/components/Popup';
import ProfileQuestionDetails from '../ProfileQuestionDetails/ProfileQuestionDetails';
import Loader from '../../../../surveys/components/SurveyBuilderPage/Loader/Loader';

export default ({ questions, loading = true, maxCount, surveyRefetch }) => {
  const [questionDetailsPopup, setQuestionDetailsPopup] = useState(null);

  const [
    updateQuestion,
    { loading: setProfilingQuestionVisibilityLoading }
  ] = useMutation(SET_PROFILING_QUESTION_VISIBILITY);

  const getQuestionText = questionText => {
    const MAX_LENGTH = 90;

    if (!questionText) return '';

    const parsedText = getBlockQuestion(questionText);
    const text = parsedText[0];
    return text && text.length && text.length > MAX_LENGTH
      ? `${text.substring(0, MAX_LENGTH - 1)}…`
      : text;
  };

  const formatDate = date => {
    if (!date) return null;

    const monthShortNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const convertDate = new Date(date);

    const day = convertDate.getDate();
    const monthIndex = convertDate.getMonth() + 1;
    const year = convertDate.getFullYear();

    // Today
    const today = new Date();

    // Yesterday
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      day === today.getDate() &&
      monthIndex === today.getMonth() + 1 &&
      year === today.getFullYear()
    ) {
      return `${convertDate.getHours()}:${
        convertDate.getMinutes() < 10 ? '0' : ''
      }${convertDate.getMinutes()}, Today`;
    }

    if (
      day === yesterday.getDate() &&
      monthIndex === yesterday.getMonth() + 1 &&
      year === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }

    if (year === today.getFullYear())
      return `${day} ${monthShortNames[monthIndex - 1]}`;
    return `${day} ${monthShortNames[monthIndex - 1]}, ${year}`;
  };

  const getFlag = language => {
    switch (language) {
      case SURVEY_LANGUAGES.ENGLISH:
        return englishFlag;
      case SURVEY_LANGUAGES.DUTCH:
        return dutchFlag;
      case SURVEY_LANGUAGES.FRENCH:
        return frenchFlag;
      case SURVEY_LANGUAGES.GERMAN:
        return germanFlag;
      default:
        return dutchFlag;
    }
  };

  const onChangeVisibility = async (id, currentVisibility) => {
    await updateQuestion({
      variables: {
        id,
        visibleForSearch: !currentVisibility
      }
    });
    await surveyRefetch();
  };

  return (
    <>
      {questionDetailsPopup ? (
        <Popup
          component={
            <ProfileQuestionDetails
              question={questionDetailsPopup}
              setQuestionDetailsPopup={setQuestionDetailsPopup}
            />
          }
          customStyles={{ padding: 'none' }}
          onClose={() => setQuestionDetailsPopup(false)}
        />
      ) : null}
      {setProfilingQuestionVisibilityLoading ? <Loader /> : null}
      {loading ? (
        <div className={`${styles.listContainer} list-container`}>
          <div className="loader-container">
            <div className={`${styles.loadingItem} item`} />
            <div className={`${styles.loadingItem} item`} />
            <div className={`${styles.loadingItem} item`} />
            <div className={`${styles.loadingItem} item`} />
          </div>
        </div>
      ) : null}
      {!loading && maxCount ? (
        <table border="0" className={styles.tableContainer}>
          <thead>
            <tr className={styles.headerRow}>
              <th width="20" />
              <th>Question</th>
              <th width="10%">Created</th>
              <th width="20">Visible</th>
            </tr>
          </thead>
          <tbody>
            {questions &&
              questions.map((q, i) => (
                <tr
                  className={styles.tableRow}
                  key={`profile-question-${i.toString()}`}
                >
                  <td>
                    <img
                      src={getFlag(q.language)}
                      width="10"
                      alt="Dutch flag"
                    />
                  </td>
                  <td>
                    <span
                      className={styles.questionText}
                      onClick={() => setQuestionDetailsPopup(q)}
                      role="presentation"
                    >
                      {getQuestionText(q.question)}
                    </span>
                  </td>
                  <td>{formatDate(q.createdAt)}</td>
                  <td>
                    <input
                      className={styles.visibleForSearchCheckbox}
                      type="checkbox"
                      checked={q.profiling.visibleForSearch}
                      onClick={() =>
                        onChangeVisibility(q.id, q.profiling.visibleForSearch)
                      }
                      onChange={() => {}}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : null}
      {!loading && !maxCount ? (
        <div className={styles.noResults}>No profiling questions</div>
      ) : null}
    </>
  );
};
