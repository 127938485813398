import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useLazyQuery from '../../../../../hooks/useLazyQuery';

import {
  UPDATE_SURVEY_COMMUNITY_SETTINGS,
  GET_SURVEY_COMMUNITIES
} from '../../../../../../graphql/Survey';

import CustomCopyField from '../WebSurveyCustomCopy/components/CustomCopyField';
import Dropdown from './components/Dropdown/Dropdown';

import DutchStrings from '../../../../../../../users/strings/nl';
import FrenchStrings from '../../../../../../../users/strings/fr';
import EnglishStrings from '../../../../../../../users/strings/en';
import GermanStrings from '../../../../../../../users/strings/de';

import { getCustomCopyFieldValue } from '../WebSurveyCustomCopy/WebSurveyCustomCopy';
import copyLinkToClipboard from '../../helpers/copyLinkToClipboard';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';

import {
  defaultCommunityTag,
  allSurveysVisibleOptions,
  communityTypeOptions,
  seeGiveawayMessageOptions,
  thankYouScreenConversionMessageOptions,
  thankYouScreenConversionMessageOptionKeys,
  communityConversionTypes
} from './defaultCommunitySettings';

import styles from './Conversion.module.css';

const PLACEHOLDER_MAPPINGS = {
  pending: {
    [thankYouScreenConversionMessageOptionKeys.EUROS]: [
      'EUROS',
      'SURVEYS',
      'EMAIL'
    ],
    [thankYouScreenConversionMessageOptionKeys.TOKENS]: [
      'TOKENS',
      'SURVEYS',
      'EMAIL'
    ],
    [thankYouScreenConversionMessageOptionKeys.IMPACT]: ['EMAIL'],
    [thankYouScreenConversionMessageOptionKeys.CUSTOM]: []
  },
  existing: {
    [thankYouScreenConversionMessageOptionKeys.EUROS]: ['EUROS', 'EMAIL'],
    [thankYouScreenConversionMessageOptionKeys.TOKENS]: ['TOKENS', 'EMAIL'],
    [thankYouScreenConversionMessageOptionKeys.IMPACT]: ['EMAIL'],
    [thankYouScreenConversionMessageOptionKeys.CUSTOM]: []
  }
};

const COMMUNITY_MANAGEMENT_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC'
};

const Conversion = ({ survey, updateCustomCopies }) => {
  const [expanded, setExpanded] = useState(false);
  const [linkCopied, setLinkCopied] = useState({});
  const [communitySettings, setCommunitySettings] = useState(
    survey && survey.communitySettings
      ? {
          ...survey.communitySettings,
          profilingTags:
            (survey.communitySettings.profilingTags &&
              survey.communitySettings.profilingTags.length &&
              survey.communitySettings.profilingTags.map(
                pT => pT.profilingTag
              )) ||
            []
        }
      : {}
  );
  const [resize, setResize] = useState(false);
  const [initialSelectedCommunities, setInitialSelectedCommunities] = useState(
    []
  );
  const [fetchedCommunities, setFetchedCommunities] = useState([]);
  const [initialProfilingTagsLength] = useState(
    (survey &&
      survey.communitySettings &&
      survey.communitySettings.profilingTags &&
      survey.communitySettings.profilingTags.length) ||
      0
  );

  const [updateSurveyCommunitySettings] = useMutation(
    UPDATE_SURVEY_COMMUNITY_SETTINGS
  );

  const {
    data: { getSurveyCommunities: { records } = {} } = {},
    loading: selectedCommunityDataLoading
  } = useQuery(GET_SURVEY_COMMUNITIES, {
    skip: !(
      survey &&
      survey.communitySettings &&
      survey.communitySettings.profilingTags &&
      survey.communitySettings.profilingTags.length
    ),
    variables: {
      start: 0,
      end: initialProfilingTagsLength,
      type: COMMUNITY_MANAGEMENT_TYPES.STATIC,
      profilingTags: survey.communitySettings.profilingTags.map(pTag => ({
        category: pTag.profilingTag.category,
        tagName: pTag.profilingTag.tagName,
        tagValue: pTag.profilingTag.tagValue
      }))
    },
    fetchPolicy: 'no-cache'
  });

  if (records && records.length && !initialSelectedCommunities.length) {
    setInitialSelectedCommunities(records);
    setFetchedCommunities(records);
  }

  const loadCommunitiesQuery = useLazyQuery(GET_SURVEY_COMMUNITIES, {
    fetchPolicy: 'no-cache'
  });

  const searchCommunities = async (page, search) => {
    const variables = {
      start: 0,
      end: (page + 1) * 10,
      search,
      salt: Math.random().toString(),
      type: COMMUNITY_MANAGEMENT_TYPES.STATIC
    };

    const searchResults = await loadCommunitiesQuery({
      variables
    });

    const searchedCommunities =
      (searchResults &&
        searchResults.data &&
        searchResults.data.getSurveyCommunities &&
        searchResults.data.getSurveyCommunities.records) ||
      null;

    if (searchedCommunities) {
      const reducedFetchedData = [
        ...fetchedCommunities,
        ...searchedCommunities
      ].reduce((newFetchedData, c) => {
        if (!newFetchedData.some(community => community.id === c.id)) {
          return [...newFetchedData, c];
        }
        return newFetchedData;
      }, []);
      setFetchedCommunities(reducedFetchedData);
    }

    return searchedCommunities && searchedCommunities.length
      ? searchedCommunities.map(r => ({
          label: r.name,
          value: r.profilingTag.tagValue
        }))
      : [];
  };

  const surveyLanguage = (survey && survey.language) || 'English';

  const customCopies =
    survey && survey.customCopies && survey.customCopies.length
      ? survey.customCopies
      : [];

  const strings = {
    Dutch: DutchStrings,
    French: FrenchStrings,
    English: EnglishStrings,
    German: GermanStrings
  };

  const translations = strings[surveyLanguage];

  const translation =
    communitySettings && communitySettings.seeGiveawayMessage
      ? 'WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS'
      : 'WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT';

  // Convert to Mobile user
  // pending user
  const thankYouScreenConversionPendingEurosTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_EUROS_CONVERSION';
  const thankYouScreenConversionPendingTokensTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_TOKENS_CONVERSION';
  const thankYouScreenConversionPendingImpactTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_IMPACT_CONVERSION';
  const thankYouScreenConversionPendingCustomTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_CUSTOM_CONVERSION';

  // existing user
  const thankYouScreenConversionExistingEurosTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_EUROS_CONVERSION';
  const thankYouScreenConversionExistingTokensTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_TOKENS_CONVERSION';
  const thankYouScreenConversionExistingImpactTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_IMPACT_CONVERSION';
  const thankYouScreenConversionExistingCustomTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_CUSTOM_CONVERSION';

  // Convert to Web user
  const thankYouScreenConversionToWebTranslationKey =
    'END_OF_THE_SURVEY_THANK_YOU_TO_WEB_CONVERSION';

  const translationFields = [
    translation,
    thankYouScreenConversionPendingEurosTranslationKey,
    thankYouScreenConversionPendingTokensTranslationKey,
    thankYouScreenConversionPendingImpactTranslationKey,
    thankYouScreenConversionPendingCustomTranslationKey,
    thankYouScreenConversionExistingEurosTranslationKey,
    thankYouScreenConversionExistingTokensTranslationKey,
    thankYouScreenConversionExistingImpactTranslationKey,
    thankYouScreenConversionExistingCustomTranslationKey,
    thankYouScreenConversionToWebTranslationKey
  ].reduce(
    (acc, t) => ({
      ...acc,
      [t]: getCustomCopyFieldValue(
        t,
        surveyLanguage,
        customCopies,
        translations
      )
    }),
    {}
  );

  const getThankYouScreenTranslationKeys = thankYouScreenConversionMessage => {
    switch (thankYouScreenConversionMessage) {
      case thankYouScreenConversionMessageOptionKeys.EUROS:
        return {
          pending: thankYouScreenConversionPendingEurosTranslationKey,
          existing: thankYouScreenConversionExistingEurosTranslationKey
        };
      case thankYouScreenConversionMessageOptionKeys.TOKENS:
        return {
          pending: thankYouScreenConversionPendingTokensTranslationKey,
          existing: thankYouScreenConversionExistingTokensTranslationKey
        };
      case thankYouScreenConversionMessageOptionKeys.CUSTOM:
        return {
          pending: thankYouScreenConversionPendingCustomTranslationKey,
          existing: thankYouScreenConversionExistingCustomTranslationKey
        };
      default:
        // IMPACT
        return {
          pending: thankYouScreenConversionPendingImpactTranslationKey,
          existing: thankYouScreenConversionExistingImpactTranslationKey
        };
    }
  };

  const [customCopyFields, setCustomCopyFields] = useState({});

  const mailchimpLink = `${window.location.origin.toString()}/users/campaign/${
    survey.id
  }/user/${survey.client.id}/referral/*|EMAIL|*?userEmailSuggestion=*|EMAIL|*`;

  useEffect(
    () => {
      if (survey) {
        setCustomCopyFields(translationFields);
      }
    },
    [
      survey,
      communitySettings.seeGiveawayMessage,
      communitySettings.thankYouScreenConversionMessage
    ]
  );

  const getPropertyLabel = (property, options) =>
    options.some(option => option.value === property)
      ? options.find(option => option.value === property).label
      : null;

  const getSelectedCommunities = () => {
    let selectedValue = 'General BUFFL community';
    if (
      communitySettings.profilingTags &&
      communitySettings.profilingTags.length
    ) {
      if (
        communitySettings.profilingTags.length === 1 &&
        communitySettings.profilingTags[0] &&
        communitySettings.profilingTags[0].tagValue
      ) {
        selectedValue = communitySettings.profilingTags[0].tagValue;
        // Find community name related to tag to display as label
        const selectedCommunity = fetchedCommunities.find(
          c =>
            c.profilingTag &&
            c.profilingTag.tagValue &&
            c.profilingTag.tagValue ===
              communitySettings.profilingTags[0].tagValue
        );
        if (selectedCommunity) {
          selectedValue = selectedCommunity.name;
        }
      } else {
        selectedValue = `${
          communitySettings.profilingTags.length
        } communities selected`;
      }
    }
    return selectedValue;
  };

  const getThankYouScreenConversionErrorMessage = (
    path,
    placeholdersMapping
  ) => {
    const text =
      communitySettings &&
      customCopyFields &&
      customCopyFields[
        getThankYouScreenTranslationKeys(
          communitySettings.thankYouScreenConversionMessage
        )[path]
      ];

    const missingPlaceholders =
      placeholdersMapping &&
      communitySettings.thankYouScreenConversionMessage &&
      placeholdersMapping[
        communitySettings.thankYouScreenConversionMessage
      ].filter(p => text && !text.includes(`{${p}}`));

    if (missingPlaceholders && missingPlaceholders.length) {
      return `You are missing ${missingPlaceholders.map(mP => `{${mP}}`)}`;
    }

    return null;
  };
  const thankYouScreenPendingConversionErrorMessage = getThankYouScreenConversionErrorMessage(
    'pending',
    PLACEHOLDER_MAPPINGS.pending
  );
  const thankYouScreenExistingConversionErrorMessage = getThankYouScreenConversionErrorMessage(
    'existing',
    PLACEHOLDER_MAPPINGS.existing
  );

  return (
    <div className={styles.container}>
      <div
        className={styles.titleContainer}
        role="presentation"
        onClick={() => setExpanded(() => setExpanded(!expanded))}
      >
        <div className={styles.title}>Conversion (admin only)</div>
        <img
          src={arrowDownPurple}
          alt="ExpandColapse"
          className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
        />
      </div>
      {expanded ? (
        <div className={styles.content}>
          <div className={`${styles.row} ${styles.flexRow}`}>
            <div className={styles.flexColumn}>
              <div className={styles.rowText} role="presentation">
                Add respondents to community
              </div>
              <Dropdown
                selectedValue={getSelectedCommunities()}
                onSelectCompleted={async (_label, value) => {
                  const newProfilingTags = [
                    ...communitySettings.profilingTags
                  ].map(profilingTag => {
                    const pT = { ...profilingTag };
                    delete pT.__typename;
                    return pT;
                  });
                  // Remove from state if exists else add it
                  if (
                    communitySettings.profilingTags.some(
                      profilingTag => profilingTag.tagValue === value
                    )
                  ) {
                    newProfilingTags.splice(
                      communitySettings.profilingTags.findIndex(
                        profilingTag => profilingTag.tagValue === value
                      ),
                      1
                    );
                  } else {
                    newProfilingTags.push({
                      ...defaultCommunityTag,
                      tagValue: value
                    });
                  }

                  let variables = {
                    survey: survey.id,
                    profilingTags: newProfilingTags
                  };

                  if (
                    newProfilingTags.length === 0 &&
                    communitySettings.allSurveysVisible === false
                  ) {
                    variables = {
                      ...variables,
                      allSurveysVisible: true
                    };
                  }

                  setCommunitySettings({
                    ...communitySettings,
                    profilingTags: newProfilingTags,
                    allSurveysVisible:
                      newProfilingTags.length === 0
                        ? true
                        : communitySettings.allSurveysVisible
                  });

                  await updateSurveyCommunitySettings({
                    variables
                  });
                }}
                searchEnabled
                fetchOptions={searchCommunities}
                loading={selectedCommunityDataLoading}
                findList={
                  communitySettings.profilingTags &&
                  communitySettings.profilingTags.length
                    ? communitySettings.profilingTags.map(
                        profilingTag => profilingTag.tagValue
                      )
                    : []
                }
              />
            </div>
            <div className={styles.flexColumn}>
              <div className={styles.rowText}>
                Can respondents see general BUFFL surveys (all topics)?
              </div>
              <Dropdown
                selectedValue={getPropertyLabel(
                  communitySettings.allSurveysVisible,
                  allSurveysVisibleOptions
                )}
                onSelectCompleted={async (_label, value) => {
                  setCommunitySettings({
                    ...communitySettings,
                    allSurveysVisible: value
                  });

                  updateSurveyCommunitySettings({
                    variables: {
                      survey: survey.id,
                      allSurveysVisible: value
                    }
                  });
                }}
                searchEnabled={false}
                options={allSurveysVisibleOptions}
                disabled={!communitySettings.profilingTags.length}
              />
            </div>
          </div>
          <div className={`${styles.row} ${styles.flexRow}`}>
            <div className={styles.flexColumn}>
              <div className={styles.rowText}>
                Would you like to convert respondents to web or mobile app?
              </div>
              <Dropdown
                selectedValue={getPropertyLabel(
                  communitySettings.communityType,
                  communityTypeOptions
                )}
                onSelectCompleted={(_label, value) => {
                  setCommunitySettings({
                    ...communitySettings,
                    communityType: value
                  });

                  updateSurveyCommunitySettings({
                    variables: {
                      survey: survey.id,
                      communityType: value
                    }
                  });
                }}
                searchEnabled={false}
                options={communityTypeOptions}
              />
            </div>
            <div className={styles.flexColumn} />
          </div>

          <div className={`${styles.row} ${styles.topBorder}`}>
            <div className={styles.rowText}>
              Would you like to add a giveaway to convert more respondents?
            </div>
            <Dropdown
              selectedValue={getPropertyLabel(
                communitySettings.seeGiveawayMessage,
                seeGiveawayMessageOptions
              )}
              onSelectCompleted={async (_label, value) => {
                await setCommunitySettings({
                  ...communitySettings,
                  seeGiveawayMessage: value
                });
                setResize(!resize);

                updateSurveyCommunitySettings({
                  variables: {
                    survey: survey.id,
                    seeGiveawayMessage: value
                  }
                });
              }}
              searchEnabled={false}
              options={seeGiveawayMessageOptions}
            />
          </div>

          <div className={styles.row}>
            <div className={styles.rowText}>
              Ask respondents to leave their email
            </div>
            <CustomCopyField
              propertyName="askForEmailAddressInWebSurvey"
              field={{
                name: translation,
                value: customCopyFields[translation]
              }}
              updateCustomCopies={updateCustomCopies}
              customCopyFields={customCopyFields}
              setCustomCopyFields={setCustomCopyFields}
              language={surveyLanguage}
              resize={resize}
            />
          </div>

          {communitySettings &&
          communitySettings.communityType ===
            communityConversionTypes.MOBILE_APP ? (
            <>
              <div className={`${styles.row} ${styles.topBorder}`}>
                <div className={styles.rowText}>Select your incentive</div>
                <Dropdown
                  selectedValue={getPropertyLabel(
                    communitySettings.thankYouScreenConversionMessage,
                    thankYouScreenConversionMessageOptions
                  )}
                  onSelectCompleted={async (_label, value) => {
                    await setCommunitySettings({
                      ...communitySettings,
                      thankYouScreenConversionMessage: value
                    });
                    setResize(!resize);

                    updateSurveyCommunitySettings({
                      variables: {
                        survey: survey.id,
                        thankYouScreenConversionMessage: value
                      }
                    });
                  }}
                  searchEnabled={false}
                  options={thankYouScreenConversionMessageOptions}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.rowText}>
                  Edit the copy respondents see at the end of the web survey in
                  case they do not have an account on our mobile app yet
                </div>
                <CustomCopyField
                  propertyName="thankYouScreenConversionMessage"
                  field={{
                    name: getThankYouScreenTranslationKeys(
                      communitySettings.thankYouScreenConversionMessage
                    ).pending,
                    value:
                      customCopyFields[
                        getThankYouScreenTranslationKeys(
                          communitySettings.thankYouScreenConversionMessage
                        ).pending
                      ]
                  }}
                  updateCustomCopies={updateCustomCopies}
                  customCopyFields={customCopyFields}
                  setCustomCopyFields={setCustomCopyFields}
                  language={surveyLanguage}
                  resize={resize}
                />
                {thankYouScreenPendingConversionErrorMessage && (
                  <div className={styles.thankYouScreenConversionMessageError}>
                    {thankYouScreenPendingConversionErrorMessage}
                  </div>
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.rowText}>
                  Edit the copy respondents see at the end of the web survey in
                  case they already have an account on our mobile app
                </div>
                <CustomCopyField
                  propertyName="thankYouScreenConversionMessage"
                  field={{
                    name: getThankYouScreenTranslationKeys(
                      communitySettings.thankYouScreenConversionMessage
                    ).existing,
                    value:
                      customCopyFields[
                        getThankYouScreenTranslationKeys(
                          communitySettings.thankYouScreenConversionMessage
                        ).existing
                      ]
                  }}
                  updateCustomCopies={updateCustomCopies}
                  customCopyFields={customCopyFields}
                  setCustomCopyFields={setCustomCopyFields}
                  language={surveyLanguage}
                  resize={resize}
                />
                {thankYouScreenExistingConversionErrorMessage && (
                  <div className={styles.thankYouScreenConversionMessageError}>
                    {thankYouScreenExistingConversionErrorMessage}
                  </div>
                )}
              </div>
            </>
          ) : null}

          {communitySettings &&
          communitySettings.communityType ===
            communityConversionTypes.WEB_PANEL ? (
            <div className={`${styles.row} ${styles.topBorder}`}>
              <div className={styles.rowText}>
                Edit the copy respondents see at the end of the web survey
              </div>
              <CustomCopyField
                propertyName="thankYouScreenConversionMessage"
                field={{
                  name: thankYouScreenConversionToWebTranslationKey,
                  value:
                    customCopyFields[
                      thankYouScreenConversionToWebTranslationKey
                    ]
                }}
                updateCustomCopies={updateCustomCopies}
                customCopyFields={customCopyFields}
                setCustomCopyFields={setCustomCopyFields}
                language={surveyLanguage}
                resize={resize}
              />
            </div>
          ) : null}

          <div className={`${styles.row} ${styles.topBorder}`}>
            <div className={styles.rowText}>
              Copy &amp; paste this link in Mailchimp
            </div>
            <div className={styles.linkContainer}>
              <div className={styles.link}>
                <div className={styles.url}>{mailchimpLink}</div>
              </div>
              <div
                className={
                  linkCopied.mailchimp === true
                    ? `${styles.copyLinkButton} ${styles.copied}`
                    : styles.copyLinkButton
                }
                onClick={() =>
                  copyLinkToClipboard(
                    mailchimpLink,
                    'mailchimp',
                    linkCopied,
                    setLinkCopied
                  )
                }
                role="presentation"
              >
                {linkCopied.mailchimp === true ? 'Copied' : 'Copy'}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Conversion;
