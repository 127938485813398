import React from 'react';

import styles from './CategoriesDistribution.module.css';

const CategoriesDistribution = ({
  categories,
  categoryResultsCount,
  categoryUnfilteredResultsCount,
  onToggleFilter,
  block,
  activeFilters,
  filteringOnSelectionEnabled
}) => {
  const categoryDetails = categories.map(category => {
    const unfilteredCount =
      filteringOnSelectionEnabled && categoryResultsCount[category]
        ? categoryUnfilteredResultsCount[category] -
          categoryResultsCount[category]
        : categoryUnfilteredResultsCount[category];

    const singleCategory = {
      label: category,
      count: categoryResultsCount[category],
      unfilteredCount
    };

    const singleCount = categoryResultsCount[category] || 0;
    const percentage = (singleCount / block.results.length) * 100;
    singleCategory.percentage = (Number.isNaN(percentage)
      ? 0
      : percentage
    ).toFixed(2);

    const singleUnfilteredCount = categoryUnfilteredResultsCount[category]
      ? unfilteredCount
      : 0;

    const notSelectedCount =
      block.unfilteredResults.length - block.results.length;

    const unfilteredPercentage =
      (singleUnfilteredCount /
        (filteringOnSelectionEnabled && block.results.length
          ? notSelectedCount
          : block.unfilteredResults.length)) *
      100;

    singleCategory.unfilteredPercentage = (Number.isNaN(unfilteredPercentage)
      ? 0
      : unfilteredPercentage
    ).toFixed(2);

    return singleCategory;
  });

  const getSingleBar = category => (
    <div className={styles.barContainer}>
      <div className={styles.barLabel}>{category.label}</div>
      <div className={styles.barRectangleContainer}>
        <div
          className={
            activeFilters &&
            activeFilters.length &&
            categoryUnfilteredResultsCount
              ? `${styles.barSingleRectangleContainer} ${
                  styles.unfilteredBarContainer
                }`
              : styles.barSingleRectangleContainer
          }
          role="presentation"
          onClick={() => {
            onToggleFilter(
              `CAT-${block.id}`,
              null,
              category.label,
              null,
              result => {
                if (result && result.attributes.block_results) {
                  const questionBlock = result.attributes.block_results.filter(
                    question => question.block === block.id
                  );
                  if (questionBlock.length) {
                    if (
                      questionBlock[0].categories &&
                      questionBlock[0].categories.length
                    ) {
                      if (
                        questionBlock[0].categories &&
                        questionBlock[0].categories.indexOf(category.label) > -1
                      ) {
                        return true;
                      }
                    }
                  }
                }
                return false;
              }
            );
          }}
        >
          <div
            className={styles.barSingleRectangle}
            style={{ width: `${category.percentage}%` }}
          />
          {activeFilters &&
          activeFilters.length &&
          categoryUnfilteredResultsCount ? (
            <div
              className={styles.barUnfilteredSingleRectangle}
              style={{ width: `${category.unfilteredPercentage}%` }}
            />
          ) : null}
        </div>
        <div className={styles.percentage}>
          {category.percentage}%
          {activeFilters &&
          activeFilters.length &&
          categoryUnfilteredResultsCount ? (
            <div className={styles.unfiltered}>
              {category.unfilteredPercentage}%
            </div>
          ) : null}
        </div>
        <div className={styles.answers}>
          {category.count || 0}{' '}
          {category.count && category.count === 1 ? 'answer' : 'answers'}
          {activeFilters &&
          activeFilters.length &&
          categoryUnfilteredResultsCount ? (
            <div className={styles.unfiltered}>
              {category.unfilteredCount || 0}
              &nbsp;
              {category.unfilteredCount && category.unfilteredCount === 1
                ? 'answer'
                : 'answers'}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  return <div>{categoryDetails.map(category => getSingleBar(category))}</div>;
};

export default CategoriesDistribution;
