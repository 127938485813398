import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './Loader.module.css';

const Loader = ({ fixed = true }) => (
  <div
    className={fixed ? styles.loaderFixedContainer : styles.loaderFillContainer}
  >
    <div className={styles.loadingAnimation}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default withRouter(Loader);
