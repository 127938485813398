import React from 'react';

import styles from './Data.module.css';

const currentDate = new Date();
const nextPaymentPeriod = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  7
);

const Data = ({ data, organisation, strings }) => {
  const showLabel = (value, label) => {
    if (value && value !== 0) {
      return label;
    }
    return '-';
  };

  const showExtraPromotion = (promotion, index) => {
    if (!promotion.visible) return null;
    return (
      <div
        key={`subrow-item-${index.toString()}`}
        className={styles.tableRowSubRowContainer}
      >
        <div
          className={`${styles.label} organisation-data-extra-promotion-label`}
        >
          {promotion.label}
        </div>
        <div className="organisation-data-extra-promotion-value">
          € {promotion.value.toFixed(2)}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.title} roboto-bold-font`}>{strings.DATA}</div>
      <div className={styles.contentContainer}>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.NUMBER_OF_PEOPLE_USING_APP_THROUGH_ASSOCIATION}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-umber-of-the-users-on-the-app`}
            >
              {showLabel(
                data.numberOfUserOnTheApp,
                `${data.numberOfUserOnTheApp} ${strings.PEOPLE}`
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.TOTAL_SUM_COLLECTED_BY_PEOPLE_IN_ASSOCIATION}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-money-earned-from-users-on-the-app`}
            >
              {showLabel(
                data.numberOfUserOnTheApp * data.userEuroFactor,
                `€ ${(data.numberOfUserOnTheApp * data.userEuroFactor).toFixed(
                  2
                )}`
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.TOKEN_TARGET_FOR_YOUR_GROUP}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-savings-target-tokens`}
            >
              {showLabel(
                organisation.savings_target * data.euroTokenFactor,
                organisation.savings_target * data.euroTokenFactor
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.TOTAL_TOKENS_SAVED_IN_YOUR_GROUP}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-total-tokens-spent-on-buying-coupon`}
            >
              {showLabel(
                data.totalTokensSpentOnBuyingCoupon,
                data.totalTokensSpentOnBuyingCoupon
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.TOTAL_EUROS_SAVED_IN_GROUP}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-total-tokens-spent-on-buying-coupon-euro`}
            >
              {showLabel(
                (data.totalTokensSpentOnBuyingCoupon * data.userEuroFactor) /
                  data.euroTokenFactor,
                `€ ${(
                  (data.totalTokensSpentOnBuyingCoupon * data.userEuroFactor) /
                  data.euroTokenFactor
                ).toFixed(2)}`
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.MONEY_COLLECTED_IN_EXTRA_PROMOTIONS}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-extra-promotions-total`}
            >
              {showLabel(
                data.extraPromotionsTotal,
                `€ ${data.extraPromotionsTotal.toFixed(2)}`
              )}
            </div>
          </div>
          <div
            className={`${
              styles.tableRowSubRow
            } organisation-data-extra-promotions-list`}
          >
            {data.extraPromotions.map((promotion, index) =>
              showExtraPromotion(promotion, index)
            )}
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.TOTAL_EUROS_COLLECTED_IN_BUFFL}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-total-euros-collected-via-buffl`}
            >
              {showLabel(
                data.totalEurosCollectedViaBuffl,
                `€ ${data.totalEurosCollectedViaBuffl.toFixed(2)}`
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.TOTAL_EUROS_RECEIVED_FROM_BUFFL}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-received-from-buffl-total`}
            >
              {showLabel(
                data.receivedFromBufflTotal,
                `€ ${data.receivedFromBufflTotal.toFixed(2)}`
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>
              {strings.TOTAL_EUROS_STILL_TO_RECEIVE_FROM_BUFFL}
            </div>
            <div
              className={`${
                styles.value
              } organisation-data-total-euros-still-to-be-received-from-buffl`}
            >
              {showLabel(
                data.totalEurosStillToBeReceivedFromBuffl,
                `€ ${data.totalEurosStillToBeReceivedFromBuffl.toFixed(2)}`
              )}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.name}>{strings.DATE_OF_NEXT_PAYMENT}</div>
            <div className={styles.value}>
              {nextPaymentPeriod.toLocaleDateString('en-US')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Data;
