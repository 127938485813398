import React from 'react';

import styles from './Disclaimer.module.css';

const Disclaimer = props => {
  const { setDisplayContactInnovationExpertPopup } = props;

  return (
    <>
      <div className={styles.disclaimer}>
        The conclusions depicted are best practice conclusions, valid in most
        circumstances. They give a first idea of what you should decide. You
        might want to contact a validation expert for further advice on your
        particular project.
      </div>
      <div
        role="presentation"
        onClick={() => setDisplayContactInnovationExpertPopup(true)}
        className={styles.contactInnovationExpertButton}
      >
        Ask for help
      </div>
    </>
  );
};

export default Disclaimer;
