import React from 'react';
import DropdownSelector from '../DropdownSelector/DropdownSelector';

export default ({
  filter,
  FILTER_CONSTANTS,
  activeFilters,
  updateActiveFilters,
  emptyPlaceholder,
  filterDropdownStyle,
  subFilter
}) => {
  const placeholder = emptyPlaceholder || FILTER_CONSTANTS.DEFAULT_PLACEHOLDER;
  const singleActiveFilterListValue =
    activeFilters &&
    activeFilters.length &&
    activeFilters.filter(activeFilter => activeFilter.name === filter.name);

  const singleActiveFilterValue =
    singleActiveFilterListValue && singleActiveFilterListValue.length
      ? singleActiveFilterListValue[0].values
      : null;
  const arrayActiveFilterString =
    singleActiveFilterValue &&
    singleActiveFilterValue.map(arrayFilter => arrayFilter.label);
  const multipleChoiceFieldValue = {
    value: singleActiveFilterValue,
    label:
      arrayActiveFilterString && arrayActiveFilterString.length
        ? arrayActiveFilterString.join()
        : ''
  };

  return (
    <DropdownSelector
      filterDropdownStyle={filterDropdownStyle}
      options={filter.options}
      fetchOptions={filter.fetchOptions}
      value={multipleChoiceFieldValue}
      onSelectCompleted={(value, label) => {
        const existingMultipleValue = singleActiveFilterListValue[0].values.filter(
          filterList => filterList.value === value
        );

        if (existingMultipleValue && existingMultipleValue.length) {
          const excludedValues = singleActiveFilterListValue[0].values.filter(
            activeFilter => activeFilter.value !== value
          );
          updateActiveFilters(filter.name, excludedValues);
        } else {
          updateActiveFilters(filter.name, [
            ...singleActiveFilterListValue[0].values,
            ...[{ value, label }]
          ]);
        }
      }}
      placeholder={placeholder}
      search={filter.search}
      multiple
      showLoading
      filterName={filter.name}
      subFilter={subFilter}
      activeFiltersLength={
        activeFilters && activeFilters.length ? activeFilters.length : 0
      }
    />
  );
};
