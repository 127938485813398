import { takeLatest, all } from 'redux-saga/effects';

import updateSurvey from './actions/updateSurvey/updateSurvey';
import setQuestionJump from './actions/setQuestionJump/setQuestionJumpSagas';
import addFlowsToGroup from './actions/addFlowsToGroup/addFlowsToGroupSagas';
import addQuestion from './actions/addQuestion/addQuestionSagas';
import addQuestionBranch from './actions/addQuestionBranch/addQuestionBranchSagas';
import addQuestionChoice from './actions/addQuestionChoice/addQuestionChoiceSagas';
import deleteGroup from './actions/deleteGroup/deleteGroupSagas';
import deleteQuestion from './actions/deleteQuestion/deleteQuestionSagas';
import deleteQuestionBranch from './actions/deleteQuestionBranch/deleteQuestionBranchSagas';
import deleteQuestionChoice from './actions/deleteQuestionChoice/deleteQuestionChoiceSagas';
import duplicateGroup from './actions/duplicateGroup/duplicateGroupSagas';
import duplicateQuestion from './actions/duplicateQuestion/duplicateQuestionSagas';
import moveGroup from './actions/moveGroup/moveGroupSagas';
import moveQuestion from './actions/moveQuestion/moveQuestionSagas';
import nestFlows from './actions/nestFlows/nestFlowsSagas';
import setGroupJump from './actions/setGroupJump/setGroupJumpSagas';
import ungroup from './actions/ungroup/ungroupSagas';
import updateQuestionBranch from './actions/updateQuestionBranch/updateQuestionBranchSagas';
import updateQuestionChoice from './actions/updateQuestionChoice/updateQuestionChoiceSagas';
import setActiveFlow from './actions/setActiveFlow/setActiveFlowSagas';
import updateGroupProperty from './actions/updateGroupProperty/updateGroupPropertySagas';
import templateWizardToSurvey from './actions/templateWizardToSurvey/templateWizardToSurveySagas';
import updateDecisionFramework from './actions/updateDecisionFramework/updateDecisionFrameworkSagas';
import changeMatrixFrameworkType from './actions/changeMatrixFrameworkType/changeMatrixFrameworkTypeSagas';

import * as actions from './actions';

const UpdateSurvey = () => updateSurvey;
const SetActiveFlow = () => setActiveFlow;
const AddQuestion = () => addQuestion;
const SetQuestionJump = () => setQuestionJump;
const AddFlowsToGroup = () => addFlowsToGroup;
const AddQuestionBranch = () => addQuestionBranch;
const AddQuestionChoice = () => addQuestionChoice;
const DeleteGroup = () => deleteGroup;
const DeleteQuestion = () => deleteQuestion;
const DeleteQuestionBranch = () => deleteQuestionBranch;
const DeleteQuestionChoice = () => deleteQuestionChoice;
const DuplicateGroup = () => duplicateGroup;
const DuplicateQuestion = () => duplicateQuestion;
const MoveGroup = () => moveGroup;
const MoveQuestion = () => moveQuestion;
const NestFlows = () => nestFlows;
const Ungroup = () => ungroup;
const UpdateQuestionBranch = () => updateQuestionBranch;
const UpdateQuestionChoice = () => updateQuestionChoice;
const SetGroupJump = () => setGroupJump;
const UpdateGroupProperty = () => updateGroupProperty;
const TemplateWizardToSurvey = () => templateWizardToSurvey;
const UpdateDecisionFramework = () => updateDecisionFramework;
const ChangeMatrixFrameworkType = () => changeMatrixFrameworkType;

export default api =>
  function* surveysSaga() {
    yield all([
      takeLatest(actions.UPDATE_SURVEY, UpdateSurvey(api)),
      takeLatest(actions.ADD_FLOWS_TO_GROUP, AddFlowsToGroup(api)),
      takeLatest(actions.ADD_QUESTION, AddQuestion(api)),
      takeLatest(actions.ADD_QUESTION_BRANCH, AddQuestionBranch(api)),
      takeLatest(actions.ADD_QUESTION_CHOICE, AddQuestionChoice(api)),
      takeLatest(actions.DELETE_GROUP, DeleteGroup(api)),
      takeLatest(actions.DELETE_QUESTION, DeleteQuestion(api)),
      takeLatest(actions.DELETE_QUESTION_BRANCH, DeleteQuestionBranch(api)),
      takeLatest(actions.DELETE_QUESTION_CHOICE, DeleteQuestionChoice(api)),
      takeLatest(actions.DUPLICATE_GROUP, DuplicateGroup(api)),
      takeLatest(actions.DUPLICATE_QUESTION, DuplicateQuestion(api)),
      takeLatest(actions.MOVE_GROUP, MoveGroup(api)),
      takeLatest(actions.MOVE_QUESTION, MoveQuestion(api)),
      takeLatest(actions.NEST_FLOWS, NestFlows(api)),
      takeLatest(actions.UNGROUP, Ungroup(api)),
      takeLatest(actions.UPDATE_QUESTION_BRANCH, UpdateQuestionBranch(api)),
      takeLatest(actions.UPDATE_QUESTION_CHOICE, UpdateQuestionChoice(api)),
      takeLatest(actions.SET_GROUP_JUMP, SetGroupJump(api)),
      takeLatest(actions.SET_QUESTION_JUMP, SetQuestionJump(api)),
      takeLatest(actions.UPDATE_GROUP_PROPERTY, UpdateGroupProperty(api)),
      takeLatest(actions.SET_ACTIVE_FLOW_REQUESTED, SetActiveFlow(api)),
      takeLatest(
        actions.SET_SURVEY_FROM_TEMPLATE_WIZARD,
        TemplateWizardToSurvey(api)
      ),
      takeLatest(
        actions.UPDATE_DECISION_FRAMEWORK,
        UpdateDecisionFramework(api)
      ),
      takeLatest(
        actions.CHANGE_MATRIX_FRAMEWORK_TYPE,
        ChangeMatrixFrameworkType(api)
      )
    ]);
  };
