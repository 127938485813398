import React, { useState } from 'react';

import DropdownPopup from './components/DropdownPopup/DropdownPopup';
import blackArrowDown from '../../../../../../../../../assets/img/black-arrow-down.svg';

import styles from './Dropdown.module.css';

const Dropdown = props => {
  const { options, selectedOption, onSelectCompleted, placeholder } = props;

  const [showDropdownPopup, setShowDropdownPopup] = useState(false);

  const page = [];

  page.push(
    <div
      key="population-label-dropdown-placeholder"
      className={styles.placeholderContainer}
      style={{ backgroundImage: `url(${blackArrowDown})` }}
      onClick={() => {
        setShowDropdownPopup(true);
      }}
      role="presentation"
    >
      {selectedOption && selectedOption.color && (
        <span
          className={styles.circle}
          style={{
            backgroundColor: selectedOption.color
          }}
        />
      )}
      <span>
        {(selectedOption && selectedOption.description) || placeholder}
      </span>
    </div>
  );

  if (showDropdownPopup) {
    page.push(
      <DropdownPopup
        key="population-label-dropdown-placeholder-popup"
        options={options}
        onSelectCompleted={onSelectCompleted}
        hideDropdownPopup={() => {
          setShowDropdownPopup(false);
        }}
        emptyListPlaceholder={`You need at least two \n questions to create a data point.`}
      />
    );
  }

  return <div className={styles.dropdownContainer}>{page}</div>;
};

export default Dropdown;
