import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import deleteQuestionChoice from './deleteQuestionChoice';
import { QUESTION_TYPES } from '../../../helpers/constants';
import surveyPermissionsCharge from '../../../helpers/surveyPermissionsCharge';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);
  const authState = yield select(state => state.auth);

  const newSurveyStateActiveFlow = {
    question: { ...surveysState.activeFlow }
  };
  if (surveysState.activeFlow && !surveysState.activeFlow.flows) {
    action.api('updateQuestion', newSurveyStateActiveFlow);
  }

  const newState = deleteQuestionChoice(
    surveysState,
    action.flowId,
    action.choiceId
  );

  // Account management warnings
  if (
    newSurveyStateActiveFlow &&
    newSurveyStateActiveFlow.question &&
    newSurveyStateActiveFlow.question.type === QUESTION_TYPES.MATRIX
  ) {
    const updatedSurveyLists = newState.newState.surveyLists.map(sL => {
      if (
        sL &&
        newState &&
        newState.newState &&
        newState.newState.activeFlow &&
        sL.id === newState.newState.activeFlow.id
      ) {
        return newState.newState.activeFlow;
      }
      return sL;
    });

    const updatedNewState = {
      newState: {
        ...newState.newState,
        surveyLists: updatedSurveyLists
      }
    };

    const newPermissionsStateData = surveyPermissionsCharge(
      authState,
      updatedNewState
    );
    if (newPermissionsStateData) {
      newState.newState = {
        ...newState.newState,
        ...newPermissionsStateData
      };
    }
  }

  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });
}
