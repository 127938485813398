import React from 'react';

import styles from './TabContent.module.css';

const TabContent = ({ tabs, active, content }) => (
  <div className={styles.container}>
    <div className={styles.left}>
      <ul className={styles.tabs}>
        {tabs.map((tab, index) => (
          <li
            key={`tab-content-item-${index.toString()}`}
            className={active === index ? styles.active : null}
          >
            {tab}
          </li>
        ))}
      </ul>
    </div>
    <div className={styles.content}>{content || null}</div>
  </div>
);

export default TabContent;
