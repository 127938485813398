import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { LOGIN } from '../../../graphql/Auth';
import styles from './LoginPage.module.css';

import LoaderAnimation from '../../../base/components/LoaderAnimation/LoaderAnimation';
import herdyWorking from '../../../assets/img/herdy-working.svg';
import bottomBlobGreen from '../../../assets/img/registration-bottom-blob-green.svg';

const LoginPage = props => {
  const { isAuthenticated, strings } = props;

  const emptyFields = { email: '', password: '' };

  const [error, setError] = useState(null);
  const [fields, setFields] = useState(emptyFields);
  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: response => {
      if (response && response.login && response.login.loginToken) {
        localStorage.setItem('@buffl:auth-token', response.login.loginToken);
        props.history.push(`/`);
      }
    }
  });

  const onLogin = () => {
    setError(null);

    if (
      !fields.email ||
      fields.email === '' ||
      !fields.password ||
      fields.password === ''
    ) {
      setError(strings.ALL_FIELDS_REQUIRED);
    }

    login({ variables: fields }).catch(registerError => {
      if (registerError) {
        if (
          registerError &&
          registerError.networkError &&
          registerError.networkError.result &&
          registerError.networkError.result.errors &&
          registerError.networkError.result.errors.length
        ) {
          if (
            registerError.networkError.result.errors[0] &&
            registerError.networkError.result.errors[0].message ===
              strings.ORGANISATION_NOT_ACTIVATED
          ) {
            setError(strings.ORGANISATION_NOT_ACTIVATED);
          } else {
            setError(strings.INVALID_CREDENTIALS);
          }
        }
      }
    });
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.form}>
          <h2 className={styles.title}>{strings.WELCOME_BACK}</h2>
          <form>
            <div className={styles.label}>{strings.EMAIL}</div>
            <input
              className={styles.input}
              type="text"
              value={fields.email}
              onChange={e =>
                setFields({ ...fields, ...{ email: e.currentTarget.value } })
              }
              autoComplete="email"
            />

            <div className={styles.label}>{strings.PASSWORD}</div>
            <input
              className={styles.input}
              type="password"
              value={fields.password}
              onChange={e =>
                setFields({ ...fields, ...{ password: e.currentTarget.value } })
              }
            />
            <div
              className={styles.grey}
              onClick={() => props.history.push('/password/reset')}
              role="presentation"
            >
              {strings.FORGOT_PASSWORD_RESET_HERE}
            </div>

            <div className={styles.buttonContainer}>
              {loading ? (
                <LoaderAnimation />
              ) : (
                <div>
                  {error ? <div className={styles.error}>{error}</div> : null}
                  <div
                    className={styles.button}
                    onClick={() => onLogin()}
                    role="presentation"
                  >
                    {strings.LOGIN}
                  </div>
                  <div className={styles.register}>
                    {strings.NO_ACCOUNT_CREATED}{' '}
                    <span
                      className={styles.greenLink}
                      role="presentation"
                      onClick={() => props.history.push(`/register`)}
                    >
                      {strings.REGISTER}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className={styles.bottomBlobWrapper}>
        <img
          className={styles.bottomBlob}
          src={bottomBlobGreen}
          alt="Blue bottom blob"
        />
        <img
          className={styles.herdyWorking}
          src={herdyWorking}
          alt="Herdy working"
        />
      </div>
    </>
  );
};

export default LoginPage;
