import React, { useState, useEffect, useRef } from 'react';

import Icon from '../../../../../../../../../../../../Icon';
import styles from './DropdownPopup.module.css';

const DropdownPopup = ({
  options,
  onSelectCompleted,
  hideDropdownPopup,
  emptyListPlaceholder,
  dropdownParentRef,
  marginLeft
}) => {
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target)
      )
    ) {
      hideDropdownPopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(
    () => {
      const height = dropdownParentRef.current.offsetHeight;
      const rect = dropdownParentRef.current.getBoundingClientRect();
      const win = dropdownParentRef.current.ownerDocument.defaultView;

      setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
    },
    [dropdownParentRef]
  );

  return (
    <div
      ref={wrapperRef}
      className={styles.container}
      style={{ top: `${topPosition}px`, marginLeft }}
    >
      <div className={styles.dropdownPopupItemsContainer}>
        {options && options.length ? (
          options.map((option, index) => (
            <div
              className={styles.dropdownItem}
              key={option.id}
              role="presentation"
              onClick={() => {
                onSelectCompleted(option);
                hideDropdownPopup();
              }}
            >
              <span className={styles.dropdownItemNumber}>
                {option.formattedGlobalIndex
                  ? option.formattedGlobalIndex
                  : index}
                .
              </span>
              <Icon
                type={option.icon}
                style={{
                  color: '#5200f1',
                  marginLeft: '5px',
                  marginRight: '5px',
                  fontSize: '10px'
                }}
              />
              {option.question}
            </div>
          ))
        ) : (
          <div className={styles.noAvailableOptions}>
            {emptyListPlaceholder || 'No available options'}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownPopup;
