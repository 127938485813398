import React, { useState, useEffect } from 'react';

import useLazyQuery from '../../../../../../surveys/hooks/useLazyQuery';

import isFilterChanged from '../../../../helpers/isFilterChanged';
import isAllowedToApplyFilter from '../../../../helpers/isAllowedToApplyFilter';
import getUserPropertiesMapping from '../../../../helpers/getUserPropertiesMapping';

import filterResultsButton from '../../../../../../campaigns/components/CampaignTable/CampaignFilters/assets/filter-results-button.svg';
import addButton from '../../../../../../assets/img/add.svg';

import { COMMUNITY_DASHBOARD_FILTER_TYPE } from '../../../../helpers/constants';
import { events } from '../../../../../../../mixpanel';

import { GET_APP_CONFIGURATION } from '../../../../../../graphql/Dashboard';

import ActiveFilters from './components/ActiveFilters/ActiveFilters';
import SingleFilter from './components/SingleFilter/SingleFilter';
import Loader from '../Loader/Loader';
import Icon from '../../../../../../campaigns/components/Icon';

import styles from './Filters.module.css';

export default ({
  appliedFilters,
  setAppliedFilters,
  filterRelation,
  setFilterRelation,
  isGlobalLoading,
  isFeasibilityChecker,
  minimumQuotaEnabled,
  setMinimumQuotaEnabled,
  trackMixpanelEvent,
  customHideFilterTypes,
  customFiltersButtonLabel
}) => {
  const [showFiltersContainer, setShowFiltersContainer] = useState(false);
  const [innerAppliedFilters, setInnerAppliedFilters] = useState([]);
  const [innerFilterRelation, setInnerFilterRelation] = useState(null);

  const [userPropertiesMapping, setUserPropertiesMapping] = useState(null);

  const getAppConfiguration = useLazyQuery(GET_APP_CONFIGURATION, {
    fetchPolicy: 'no-cache'
  });

  useEffect(
    () => {
      setInnerAppliedFilters(JSON.parse(JSON.stringify(appliedFilters)));
      setInnerFilterRelation(filterRelation);
    },
    [appliedFilters]
  );

  useEffect(
    () => {
      const innerGetAppConfiguration = async () => {
        if (showFiltersContainer && !userPropertiesMapping) {
          const loadedAppConfiguration = await getAppConfiguration({});
          const processedUserPropertiesMapping = getUserPropertiesMapping(
            loadedAppConfiguration,
            isFeasibilityChecker
          );

          setUserPropertiesMapping(processedUserPropertiesMapping);
        }
      };

      innerGetAppConfiguration();
    },
    [showFiltersContainer]
  );

  const isConfigurationDataReady = userPropertiesMapping;

  const isCurrentFilterChanged = isFilterChanged(
    innerAppliedFilters,
    appliedFilters
  );
  const isAllowedToApplyCurrentFilter = isAllowedToApplyFilter(
    innerAppliedFilters,
    appliedFilters
  );

  const isApplyButtonAllowed = isAllowedToApplyCurrentFilter;

  return (
    <div
      className={
        isGlobalLoading
          ? `${styles.container} ${
              styles.transparent
            } community-dashboard-filters-container`
          : `${styles.container} community-dashboard-filters-container`
      }
    >
      <div className={styles.filtersButtonContainer}>
        <div
          role="presentation"
          className={
            showFiltersContainer
              ? `${styles.filtersButton} ${styles.filtersButtonActive}`
              : styles.filtersButton
          }
          onClick={() => {
            if (!isCurrentFilterChanged && !isGlobalLoading) {
              setShowFiltersContainer(!showFiltersContainer);
            }
          }}
        >
          <img src={filterResultsButton} alt="Filter result icon" />
          {customFiltersButtonLabel || 'Filters'}
        </div>
        {isFeasibilityChecker ? (
          <div
            className={styles.minimumQuotaContainer}
            role="presentation"
            onClick={() => {
              if (!isGlobalLoading) {
                if (!minimumQuotaEnabled) {
                  trackMixpanelEvent(
                    events.CLIENT_ENABLE_MIN_QUOTA_ON_FEASIBILITY_CHECKER,
                    innerAppliedFilters
                  );
                }
                setMinimumQuotaEnabled(!minimumQuotaEnabled);
              }
            }}
          >
            <span
              className={`${styles.checkbox} ${
                minimumQuotaEnabled ? styles.checkboxSelected : ''
              } check-icon`}
            >
              <Icon type={minimumQuotaEnabled ? 'checked' : 'unchecked'} />
            </span>
            <div className={styles.minQuotaLabel}>Minimum quota</div>
          </div>
        ) : null}
      </div>
      {showFiltersContainer ? (
        <div className={styles.filtersContainer}>
          {isConfigurationDataReady ? (
            <>
              <div className={styles.filters}>
                {innerAppliedFilters.map((singleFilter, singleFilterIndex) => (
                  <SingleFilter
                    key={`single-filter-${singleFilterIndex.toString()}`}
                    singleFilterIndex={singleFilterIndex}
                    appliedFilters={innerAppliedFilters}
                    setAppliedFilters={setInnerAppliedFilters}
                    filterRelation={innerFilterRelation}
                    setFilterRelation={setInnerFilterRelation}
                    userPropertiesMapping={userPropertiesMapping}
                    showQuota={isFeasibilityChecker && minimumQuotaEnabled}
                    isFeasibilityChecker={isFeasibilityChecker}
                    customHideFilterTypes={customHideFilterTypes}
                  />
                ))}
              </div>
              <div
                className={`${styles.footer} ${
                  innerAppliedFilters && innerAppliedFilters.length
                    ? styles.innerFilters
                    : ''
                }`}
              >
                <div>
                  <div
                    role="presentation"
                    className={styles.addFilterButton}
                    onClick={() => {
                      const newInnerAppliedFilters = [...innerAppliedFilters];
                      newInnerAppliedFilters.push({
                        type: COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG,
                        tags: {}
                      });
                      setInnerAppliedFilters(newInnerAppliedFilters);
                    }}
                  >
                    <img src={addButton} alt="add filter button" />
                    Add Filter
                  </div>
                  {isFeasibilityChecker ? (
                    <div className={styles.moreInfoContainer}>
                      <div className={styles.questionMark}>?</div>
                      <div className={styles.tooltip}>
                        <div>
                          Use AND logic if respondents need to meet all criteria
                          within a set of filters.
                        </div>
                        <div>
                          For example, respondents that have an electric vehicle
                          AND solar panels.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          Use OR logic if respondents need to meet one of the
                          criteria within a set of filters. For example,
                          respondents that have an electric vehicle OR solar
                          panels.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          The filter relationship between tag values from the
                          same tag is always OR. This way it’s possible to
                          combine AND/OR filters. For example, people that
                          either have OR consider getting solar panels AND own
                          an electric vehicle.
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={styles.filterControlsContainer}>
                  <div
                    role="presentation"
                    className={styles.whiteButton}
                    onClick={() => {
                      setInnerAppliedFilters(appliedFilters);
                      setFilterRelation(filterRelation);
                      setShowFiltersContainer(false);
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    role="presentation"
                    className={
                      isApplyButtonAllowed
                        ? styles.purpleButton
                        : styles.buttonDisabled
                    }
                    onClick={() => {
                      if (isApplyButtonAllowed) {
                        setAppliedFilters([...innerAppliedFilters]);
                        setFilterRelation(innerFilterRelation);
                        setShowFiltersContainer(false);

                        if (isFeasibilityChecker) {
                          trackMixpanelEvent(
                            events.CLIENT_APPLY_FILTERS_ON_FEASIBILITY_CHECKER,
                            innerAppliedFilters
                          );
                        }
                      }
                    }}
                  >
                    Apply filters
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={{ top: '25px' }}>
              <Loader fixed={false} />
            </div>
          )}
        </div>
      ) : (
        <ActiveFilters
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          isGlobalLoading={isGlobalLoading}
        />
      )}
    </div>
  );
};
