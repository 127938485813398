import {
  getGroupFirstFlow,
  getGroupLastFlow
} from '../getGroupEdgeFlows/getGroupEdgeFlows';

import { SURVEY_END_TYPES } from '../constants';

export default s => {
  const state = s;
  const changedFlowsRemovedJumpsToUpdate = [];

  const removeNextFlowIfPointsToNextFlow = f => {
    const flow = f;

    if (!flow.nextFlow) return;

    let lastQuestionJumpFrom = f;
    if (lastQuestionJumpFrom && lastQuestionJumpFrom.flows) {
      lastQuestionJumpFrom = getGroupLastFlow(
        state.survey.content,
        lastQuestionJumpFrom
      );
    }
    const lastQuestionJumpFromInSurveysList =
      lastQuestionJumpFrom && lastQuestionJumpFrom.id
        ? state.surveyLists.find(sLF => sLF.id === lastQuestionJumpFrom.id)
        : state.surveyLists.find(sLF => sLF.id === lastQuestionJumpFrom);

    let firstQuestionJumpTo = state.survey.questions[flow.nextFlow];
    if (!firstQuestionJumpTo) {
      firstQuestionJumpTo = state.survey.content.groups.find(
        g => g.id === flow.nextFlow
      );
    }
    if (firstQuestionJumpTo && firstQuestionJumpTo.flows) {
      firstQuestionJumpTo = getGroupFirstFlow(
        state.survey.content,
        firstQuestionJumpTo
      );
    }
    const firstQuestionJumpToInSurveysList =
      firstQuestionJumpTo && firstQuestionJumpTo.id
        ? state.surveyLists.find(sLF => sLF.id === firstQuestionJumpTo.id)
        : state.surveyLists.find(sLF => sLF.id === firstQuestionJumpTo);

    const isDistributorGroupFlow =
      lastQuestionJumpFromInSurveysList &&
      lastQuestionJumpFromInSurveysList.group &&
      lastQuestionJumpFromInSurveysList.group.jumpFromDistributor &&
      lastQuestionJumpFromInSurveysList.group.id === flow.id;

    if (
      !firstQuestionJumpToInSurveysList ||
      (lastQuestionJumpFromInSurveysList &&
        lastQuestionJumpFromInSurveysList.globalIndex + 1 ===
          firstQuestionJumpToInSurveysList.globalIndex &&
        ((lastQuestionJumpFromInSurveysList &&
          lastQuestionJumpFromInSurveysList.group &&
          !isDistributorGroupFlow) ||
          (lastQuestionJumpFromInSurveysList &&
            !lastQuestionJumpFromInSurveysList.group)))
    ) {
      flow.nextFlow = null;
      changedFlowsRemovedJumpsToUpdate.push(flow);
    }
  };

  Object.keys(state.survey.questions).forEach(qId =>
    removeNextFlowIfPointsToNextFlow(state.survey.questions[qId])
  );
  state.survey.content.groups.forEach(g => removeNextFlowIfPointsToNextFlow(g));

  const lastFlowId =
    state.survey.content.flows[state.survey.content.flows.length - 1];
  let lastFlow;
  if (state.survey.content.groups.some(g => g.id === lastFlowId)) {
    const group = state.survey.content.groups.find(g => g.id === lastFlowId);

    if (group && group.end === SURVEY_END_TYPES.SUCCESSFUL) {
      group.end = null; // No need to update group, it is survey is already updated
    }

    lastFlow = getGroupLastFlow(state.survey.content, group);

    lastFlow = state.survey.questions[lastFlow];
  } else {
    lastFlow = state.survey.questions[lastFlowId];
  }

  if (lastFlow && lastFlow.end === SURVEY_END_TYPES.SUCCESSFUL) {
    lastFlow.end = null;
    changedFlowsRemovedJumpsToUpdate.push(lastFlow);
  }

  return { state, changedFlowsRemovedJumpsToUpdate };
};
