import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

import { useQuery } from '@apollo/react-hooks';

import AppBar from '../../../../base/components/AppBar/AppBar';
import CommunityAutomationsHeader from './components/CommunityAutomationsHeader/CommunityAutomationsHeader';

import styles from './CommunityAutomationsListPage.module.css';

import CommunityAutomationsRecordLoader from './components/CommunityAutomationsRecordLoader/CommunityAutomationsRecordLoader';
import { GET_COMMUNITY_AUTOMATIONS } from '../../../../graphql/CommunityAutomations';
import CommunityAutomationsRecord from './components/CommunityAutomationsRecord/CommunityAutomationsRecord';

export default props => {
  const { history, recordsPerPage } = props;

  const [filtering, setFiltering] = useState({
    page: 0,
    search: ''
  });

  const recordVariables = {
    page: 0,
    search: filtering.search
  };

  const {
    data: { getCommunityAutomations: { records = [], totalCount } = {} } = {},
    refetch,
    loading: getAccountsLoading
  } = useQuery(GET_COMMUNITY_AUTOMATIONS, {
    variables: recordVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  return (
    <>
      <AppBar {...props} />
      <div className={styles.container}>
        <CommunityAutomationsHeader
          title="Community automations"
          onAddButtonLabel="New automation"
          addingAllowed
          onAddClick={() => history.push(`/community-automations/new`)}
          search={filtering.search}
          onSearch={searchText =>
            setFiltering({ ...filtering, search: searchText.trim() })
          }
        />
        {getAccountsLoading ? <CommunityAutomationsRecordLoader /> : null}
        {!getAccountsLoading ? (
          <table
            border="0"
            cellSpacing="0"
            cellPadding="0"
            className={styles.mainTable}
          >
            <thead>
              <tr>
                <th className={`${styles.headerRow} ${styles.nameContainer}`}>
                  Name
                </th>
                <th className={styles.headerRow}>Creator</th>
                <th className={styles.headerRow}>Created</th>
                <th className={styles.headerRow}>Last updated</th>
                <th className={styles.headerRow}>Status</th>
                <th className={styles.headerRow} />
              </tr>
            </thead>
            <tbody>
              {records && records.length
                ? records.map((r, i) => (
                    <CommunityAutomationsRecord
                      record={r}
                      key={`community-automations-record-${i.toString()}`}
                      history={history}
                      refetch={refetch}
                    />
                  ))
                : null}
              {!records || !records.length ? (
                <div className={styles.noResultsPlaceholder}>
                  No automations
                </div>
              ) : null}
            </tbody>
          </table>
        ) : null}
        {recordsPerPage && records && records.length ? (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={Math.ceil(totalCount / recordsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            initialPage={filtering.page}
            forcePage={filtering.page}
            disableInitialCallback
            onPageChange={pageNumber =>
              setFiltering({ ...filtering, page: pageNumber.selected })
            }
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </>
  );
};
