import React, { useRef } from 'react';

import BarChart from '../../../Charts/BarChart/BarChart';
import RangeSelector from '../RangeSelector/RangeSelector';
import Loader from '../../../Loader/Loader';

import { reloadMetricData } from '../../../../../../helpers/mainPageChartDataLoad';

import styles from './BarMetric.module.css';

export default ({
  name,
  title,
  value,
  isPercentage,
  availableRanges,
  metricInnerSettings,
  setMetricInnerSettings,
  api,
  appliedFilters,
  statisticsData,
  setStatisticsData,
  filterRelation,
  dateFormat,
  metricWidth = 100
}) => {
  const chartParentContainer = useRef();
  const tooltipContainerRef = useRef();

  if (!value) {
    return (
      <div className={styles.container} style={{ width: `${metricWidth}%` }}>
        <div className={styles.loadingContainer}>
          <Loader fixed={false} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} style={{ width: `${metricWidth}%` }}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>{title} </div>
          <div className={styles.moreInfoContainer}>
            <div className={styles.questionMark}>?</div>
            <div className={styles.tooltip}>
              <div>
                Active users are users that completed at least one survey in the
                selected timeframe.
              </div>
              <div style={{ marginTop: '12px' }}>
                Example for 2021: Recurring active users are unique users
                created before 2021 and still active (completed at least one
                survey) in 2021. New active users are unique users created in
                2021 and active (completed at least one survey) in 2021.
              </div>
            </div>
          </div>
        </div>
        {availableRanges && availableRanges.length && (
          <div className={styles.rangesContainer}>
            <RangeSelector
              name={name}
              availableRanges={availableRanges}
              value={metricInnerSettings[name].range}
              onClick={r => {
                const newMetricSetting = {
                  ...metricInnerSettings[name],
                  range: r
                };

                setMetricInnerSettings({
                  ...metricInnerSettings,
                  [name]: newMetricSetting
                });
                reloadMetricData(
                  api,
                  statisticsData,
                  setStatisticsData,
                  appliedFilters,
                  name,
                  newMetricSetting,
                  filterRelation
                );
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.chartContainer} ref={chartParentContainer}>
        <BarChart
          parent={chartParentContainer}
          tooltipContainerRef={tooltipContainerRef}
          height={170}
          data={value.chartData}
          isPercentage={isPercentage}
          dateFormat={dateFormat}
          range={metricInnerSettings[name].range}
        />
        <div ref={tooltipContainerRef} className={styles.tooltipContainer} />
        <div className={styles.legend}>
          <div className={styles.legendItem}>
            <span className={`${styles.bullet} ${styles.lightblue}`} />
            New active users
          </div>
          <div className={styles.legendItem}>
            <span className={`${styles.bullet} ${styles.blue}`} />
            Recurring active users
          </div>
        </div>
      </div>
    </div>
  );
};
