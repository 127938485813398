export default {
  DASHBOARD: 'Dashboard',
  CONTACT_US: 'Contacteer ons',
  LOGOUT: 'Logout',
  NO_DATA_AVAILABLE: 'Geen data beschikbaar',
  RANKING: 'Rangschikking',
  GO_TO_RANKING: 'Ga naar rangschikking',
  DATA: 'Data',
  GO_TO_DATA: 'Ga naar data',
  REVENUES: 'Opbrengsten',
  GO_TO_REVENUES: 'Ga naar opbrengsten',
  MONEY_GRAPH: 'Inkomstengrafiek',
  TOKENS: 'Tokens',
  LAST_MONTH_TOTAL_EARNED: 'Inkomstentotaal vorige maand',
  USER_OF_THE_WEEK: 'Gebruiker van de week',
  NUMBER_OF_THE_USERS_ON_THE_APP: 'Aantal gebruikers op de app',
  GOAL: 'Doel',
  FROM_THE_TOTAL: 'van het totaal',
  PERSONAL_LINK: 'Jouw organisatie link',
  JANUARY: 'Januari',
  FEBRUARY: 'Februari',
  MARCH: 'Maart',
  APRIL: 'April',
  MAY: 'Mei',
  JUNE: 'Juni',
  JULY: 'Juli',
  AUGUST: 'Augustus',
  SEPTEMBER: 'September',
  OCTOBER: 'Oktober',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  NUMBER_OF_PEOPLE_USING_APP_THROUGH_ASSOCIATION:
    'Aantal mensen die de BUFFL app gebruiken via jouw vereniging',
  TOTAL_SUM_COLLECTED_BY_PEOPLE_IN_ASSOCIATION:
    'Totale som verzameld door het aantal mensen die de BUFFL app gebruiken via jouw vereniging',
  TOKEN_TARGET_FOR_YOUR_GROUP: 'Token doel voor jouw groepspot',
  TOTAL_TOKENS_SAVED_IN_YOUR_GROUP:
    'Totaal aantal tokens in jouw groepspot gespaard',
  TOTAL_EUROS_SAVED_IN_GROUP: "Totaal aantal euro's in jouw groepspot",
  MONEY_COLLECTED_IN_EXTRA_PROMOTIONS:
    'Geld verzameld door deelname aan extra acties',
  TOTAL_EUROS_COLLECTED_IN_BUFFL: "Totaal aantal euro's verzameld via BUFFL",
  TOTAL_EUROS_RECEIVED_FROM_BUFFL: "Totaal aantal euro's ontvangen van BUFFL",
  TOTAL_EUROS_STILL_TO_RECEIVE_FROM_BUFFL:
    "Totaal aantal euro's nog te ontvangen van BUFFL",
  DATE_OF_NEXT_PAYMENT: 'Datum volgende uitbetaling',
  PEOPLE: 'mensen',
  ALL_FIELDS_REQUIRED: 'Alle velden zijn verplicht',
  FIELD_IS_REQUIRED: 'Dit veld is verplicht',
  ADJUST_SAVING_GOAL_HERE: 'Pas hier je informatie aan.',
  PLEASE_FIX_ERRORS_IN_FORM: 'Graag de fouten oplossen in het formulier',
  SAVE_ADJUSTMENTS: 'Aanpassingen opslaan',
  DO_YOU_STILL_HAVE_QUESTION: 'Zit je nog met een vraag? Contacteer ons.',
  ANY_QUESTIONS_OR_SUGGESTIONS: 'Nog vragen of suggesties? We horen het graag!',
  CONTACT: 'Contacteer',
  ORGANISATION_NOT_ACTIVATED: 'Organisatie niet geactiveerd',
  INVALID_CREDENTIALS: 'Ongeldige login',
  LOGIN: 'Inloggen',
  LOG_IN: 'Log in.',
  EMAIL: 'E-mail',
  PASSWORD: 'Wachtwoord',
  REPEAT_PASSWORD: 'Herhaal wachtwoord',
  NO_ACCOUNT_CREATED: 'Nog geen partner?',
  REGISTER: 'Registreer.',
  FORGOT_PASSWORD_RESET_HERE: 'Wachtwoord vergeten?',
  PASSWORDS_NOT_SAME: 'Wachtwoorden zijn niet hetzelfde',
  EMAIL_SHOULD_HAVE_CHARACTERS: 'E-mail moet tenminste 5 karakters bevatten.',
  PASSWORD_SHOULD_HAVE_CHARACTERS:
    'Het wachtwoord moet tenminste 6 karakters bevatten.',
  EMAIL_ALREADY_IN_USE: 'Het e-mailadres is al in gebruik',
  WAITING_FOR_CONFIRMATION: 'Wachten op bevestiging',
  THANKS_FOR_APPLICATION:
    'Bedankt voor jouw applicatie. We contacteren je binnenkort.',
  EMAIL_OF_ORGANISATION: 'E-mail organisatie',
  REGISTER_ORGANISATION: 'Registreer je organisatie.',
  REGISTER_MY_ORGANISATION: 'Registreer mijn organisatie',
  ALREADY_HAVE_ACCOUNT: 'Ben je al een partner?',
  BUFFL_ORGANISATION_DASHBOARD:
    'Het BUFFL dashboard voor organisatie geeft een duidelijk & gestructureerd beeld van je BUFFL campagne weer. De resultaten worden continu vernieuwd en maken het mogelijke je campagne op de voet te volgen.',
  EMAIL_NOT_FOUND: 'E-mail niet gevonden',
  RESET_PASSWORD_EMAIL_SENT: 'Reset wachtwoord e-mail verstuurd',
  PASSWORD_RESET_LINK_HAS_BEEN_SENT:
    'Wachtwoord reset link werd verstuurd naar jouw e-mail.',
  RESET_PASSWORD_PAGE: 'Reset wachtwoord pagina.',
  RESET_PASSWORD: 'Reset wachtwoord',
  RESET: 'Reset',
  SOMETHING_WENT_WRONG:
    'Iets is verkeerd gegaan. Contacteer onze administratie.',
  PASSWORD_SUCCESSFULLY_RESET: 'Wachtwoord succesvol gereset',
  ORGANISATION_NAME: 'Naam organisatie',
  CONTACT_PERSON_NAME: 'Voornaam contactpersoon',
  CONTACT_PERSON_LAST_NAME: 'Achternaam contactpersoon',
  CONTACT_PERSON_PHONE_NUMBER: 'Telefoonnummer contactpersoon',
  CONTACT_PERSON_EMAIL: 'E-mail contactpersoon',
  SAVING_TARGET_EURO: 'Spaardoel (in €)',
  DESCRIPTION_OF_ORGANISATION: 'Beschrijving organisatie',
  UPLOAD_AN_IMAGE: 'Upload een afbeelding...',
  LINK: 'Link',
  YOU_HAVE_EARNED_TOKENS_THROUGH_REFERRAL:
    'Je hebt {TOKENS} tokens verdiend die worden overgezet naar de groepssparen pot van jouw organisatie',
  CONVERT: 'Omzetten',
  ORGANISATIONS: 'Organisations',
  ORGANISATION: 'Organisatie',
  CONTACT_PERSON: 'Contactpersoon',
  SAVING_TARGET: 'Spaardoel',
  NEXT: 'Volgende',
  HOW_MUCH_WOULD_YOU_LIKE_TO_COLLECT:
    'Hoeveel geld willen jullie graag inzamelen?',
  WHAT_WILL_YOU_USE_THE_MONEY_FOR: 'Waarvoor gaan jullie het geld gebruiken?',
  BECOME_A_PARTNER: 'Word een partner',
  WELCOME_BACK: 'Welkom terug',
  ORGANISATION_LOGO: 'Logo organisatie',
  JPG_OR_PNG: '.jpg of .png',
  SAVINGS_GOAL_CAN_HAVE_CHARACTERS:
    'Dit veld mag maximaal 200 karakters bevatten',
  CONGRATULATIONS: 'Gefeliciteerd',
  ORGANISATION_SUCCESFULLY_REGISTERED:
    'Je organisatie is succesvol geregistreerd op het BUFFL platform!',
  PASS_THE_WORD: 'Zeg het voort aan je leden en fans en begin met sparen.'
};
