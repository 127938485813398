import { connect } from 'react-redux';
import App from '../components/App';
/* global localStorage */

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
