import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import updateQuestionChoice from './updateQuestionChoice';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  if (
    action &&
    action.updateActiveQuestion &&
    action.api &&
    surveysState.activeFlow &&
    !surveysState.activeFlow.flows &&
    surveysState.activeFlow.id
  ) {
    const newSurveyStateActiveFlow = {
      question: { ...surveysState.activeFlow }
    };
    action.api('updateQuestion', newSurveyStateActiveFlow);
  }

  const newState = updateQuestionChoice(
    surveysState,
    action.flowId,
    action.choice,
    action.answerUpdate
  );

  if (
    action.saveUpdatedQuestion &&
    newState &&
    newState.newState &&
    newState.newState.activeFlow
  ) {
    action.api('updateQuestion', { question: newState.newState.activeFlow });
  }

  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });
}
