import React, { useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';

import { useQuery, useMutation } from '@apollo/react-hooks';
import isActionDropdownAllowed from '../../helpers/isActionDropdownAllowed';
import isAddingAllowed from '../../helpers/isAddingAllowed';

import AppBar from '../../../../base/components/AppBar/AppBar';
import Popup from '../../../../campaigns/components/Popup';
import AccountManagementHeader from '../AccountManagementHeader/AccountManagementHeader';
import AccountManagementMember from '../AccountManagementMember/AccountManagementMember';
import AccountManagementAddEditPopup from '../AccountManagementAddEditPopup/AccountManagementAddEditPopup';
import AccountManagementRemovePopup from '../AccountManagementRemovePopup/AccountManagementRemovePopup';
import AccountManagementRecordLoader from '../AccountManagementRecordLoader/AccountManagementRecordLoader';

import { recordActionPopups } from '../../helpers/constants';
import getTitle from '../../helpers/getTitle';

import styles from './AccountManagementMembersPage.module.css';

import {
  GET_ACCOUNT_MANAGEMENT_MEMBERS,
  CREATE_ACCOUNT_MANAGEMENT_MEMBER,
  REMOVE_ACCOUNT_MANAGEMENT_MEMBER
} from '../../../../graphql/AccountManagement';

export default props => {
  const {
    history,
    parent,
    recordsPerPage,
    title = null,
    onAddButtonLabel,
    addPopupTitle,
    addPopupSubtitle1,
    addPopupSubtitle2,
    removePopupTitle,
    inviteText,
    goToRecordRoute,
    invitationType,
    isAdmin,
    accountManagement,
    clientId,
    defaultConstants
  } = props;

  const [activePopup, setActivePopup] = useState(null);
  const [filtering, setFiltering] = useState({
    page: 0,
    search: ''
  });

  const recordVariables = {
    parent,
    start: filtering.page * recordsPerPage,
    end: (filtering.page + 1) * recordsPerPage,
    search: filtering.search
  };

  const {
    data: {
      getAccountManagementMembers: {
        records = [],
        totalCount,
        higherRecords
      } = {}
    } = {},
    refetch,
    loading: getAccountsLoading
  } = useQuery(GET_ACCOUNT_MANAGEMENT_MEMBERS, {
    variables: recordVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const [
    createAccountManagementRecord,
    { loading: createLoading }
  ] = useMutation(CREATE_ACCOUNT_MANAGEMENT_MEMBER);
  const [
    removeAccountManagementRecord,
    { loading: removeLoading }
  ] = useMutation(REMOVE_ACCOUNT_MANAGEMENT_MEMBER);

  const submitLoading = createLoading || removeLoading;

  const memoizedTitle = useMemo(() => getTitle(null, title, higherRecords), [
    higherRecords
  ]);

  const memoizedRouteSubtitle = useMemo(
    () => goToRecordRoute(history, higherRecords, styles),
    [higherRecords]
  );

  const actionDropdownAllowed = useMemo(
    () => isActionDropdownAllowed(3, isAdmin, accountManagement, parent),
    []
  );

  const addingAllowed = useMemo(
    () => isAddingAllowed(3, isAdmin, accountManagement, parent),
    []
  );

  const isActionDropdownAllowedCheck = cT => {
    if (cT && clientId && cT.id === clientId) {
      return true;
    }

    return actionDropdownAllowed;
  };

  return (
    <>
      <AppBar
        {...props}
        appBarProperties={{
          routeSubtitle: memoizedRouteSubtitle
        }}
      />
      <div className={styles.container}>
        <AccountManagementHeader
          title={memoizedTitle}
          onAddButtonLabel={onAddButtonLabel}
          addingAllowed={addingAllowed}
          onAddClick={() => setActivePopup({ type: recordActionPopups.ADD })}
          search={filtering.search}
          onSearch={searchText =>
            setFiltering({ ...filtering, search: searchText.trim() })
          }
        />
        {getAccountsLoading ? <AccountManagementRecordLoader /> : null}
        {!getAccountsLoading ? (
          <table
            border="0"
            cellSpacing="0"
            cellPadding="0"
            className={styles.mainTable}
          >
            <thead>
              <tr>
                <th className={`${styles.headerRow} ${styles.nameContainer}`}>
                  Name
                </th>
                <th className={styles.headerRow} />
              </tr>
            </thead>
            <tbody>
              {records && records.length
                ? records.map((cT, i) => (
                    <AccountManagementMember
                      key={`record-item-${i.toString()}`}
                      record={cT}
                      setActivePopup={setActivePopup}
                      onRecordClick={null}
                      actionsAllowed={isActionDropdownAllowedCheck(cT)}
                    />
                  ))
                : null}
              {!records || !records.length ? (
                <div className={styles.noResultsPlaceholder}>
                  No results found
                </div>
              ) : null}
            </tbody>
          </table>
        ) : null}
        {activePopup && activePopup.type === recordActionPopups.ADD ? (
          <Popup
            component={
              <AccountManagementAddEditPopup
                parent={parent}
                invitationParent={parent}
                title={addPopupTitle}
                subtitle1={addPopupSubtitle1}
                subtitle2={addPopupSubtitle2}
                inviteText={inviteText}
                invitationType={invitationType}
                onSubmit={async record => {
                  const newMembers = {
                    members: record.managers,
                    parent: record.parent
                  };

                  await createAccountManagementRecord({
                    variables: newMembers
                  });
                  setActivePopup(null);
                  await refetch(recordVariables);
                }}
                onClose={() => setActivePopup(null)}
                submitLoading={submitLoading}
                validate={internalRecord => {
                  if (
                    internalRecord &&
                    internalRecord.managers &&
                    internalRecord.managers.length
                  ) {
                    return true;
                  }
                  return false;
                }}
                defaultConstants={defaultConstants}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
          />
        ) : null}
        {activePopup && activePopup.type === recordActionPopups.DELETE ? (
          <Popup
            component={
              <AccountManagementRemovePopup
                activePopup={activePopup}
                title={removePopupTitle}
                subtitle1={`Are you sure you want to delete ${activePopup &&
                  activePopup.record &&
                  activePopup.record.name}?`}
                onSubmit={async recordId => {
                  await removeAccountManagementRecord({
                    variables: { id: recordId, parent }
                  });
                  setActivePopup(null);

                  if (recordId === clientId) {
                    // If member is deleting himself
                    window.location.href = '/clients';
                  } else {
                    await refetch(recordVariables);
                  }
                }}
                onClose={() => setActivePopup(null)}
                submitLoading={submitLoading}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
          />
        ) : null}
        {recordsPerPage && records && records.length ? (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={Math.ceil(totalCount / recordsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            initialPage={filtering.page}
            forcePage={filtering.page}
            disableInitialCallback
            onPageChange={pageNumber =>
              setFiltering({ ...filtering, page: pageNumber.selected })
            }
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </>
  );
};
