import React from 'react';

import removeIcon from '../../../../../../../assets/img/delete.svg';

import styles from './CommunityUserRecord.module.css';

export default props => {
  const { key, user, removeUser } = props;

  const createdAtDate =
    user && user.createdAt ? new Date(user.createdAt) : null;
  const createdAt = createdAtDate
    ? `${createdAtDate.getDate()}/${createdAtDate.getMonth() +
        1}/${createdAtDate.getFullYear()}`
    : '';

  return (
    <tr className={styles.rowContainer} key={key}>
      <td className={styles.firstColumn}>{user.email}</td>
      <td>{createdAt}</td>
      <td>{user.convertedFromSurvey}</td>
      <td>{user.allSurveysVisible ? 'Yes' : 'No'}</td>
      <td>
        {removeUser ? (
          <div className={styles.actionsContainer}>
            <img
              src={removeIcon}
              alt="Remove"
              className={styles.removeIcon}
              role="presentation"
              onClick={() => removeUser(user.id)}
            />
          </div>
        ) : null}
      </td>
    </tr>
  );
};
