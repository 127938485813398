import React from 'react';

import arrow from '../../../../../../../../assets/img/templateswizard/arrow.svg';

import styles from './Footer.module.css';

export default ({
  onNextClick,
  onPreviousClick,
  hiddenPrevious,
  disabledPrevious,
  hiddenNext,
  disabledNext,
  nextLabel,
  previousLabel
}) => (
  <div className={styles.container}>
    <div
      className={
        disabledPrevious ? `${styles.left} ${styles.disabled}` : styles.left
      }
      role="presentation"
      onClick={() => {
        if (!disabledPrevious) {
          onPreviousClick();
        }
      }}
    >
      {!hiddenPrevious && (
        <>
          <img src={arrow} alt="Left arrow" />
          {previousLabel}
        </>
      )}
    </div>
    <div
      className={
        disabledNext
          ? `${styles.right} ${
              styles.disabled
            } builder-wizard-template-next-button`
          : `${styles.right} builder-wizard-template-next-button`
      }
      role="presentation"
      onClick={() => {
        if (!disabledNext) {
          onNextClick();
        }
      }}
    >
      {!hiddenNext && (
        <>
          {nextLabel}
          <img src={arrow} alt="Right arrow" />
        </>
      )}
    </div>
  </div>
);
