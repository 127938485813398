export const SET_NEW_STATE = 'SET_NEW_STATE';
export const SET_SURVEY = 'SET_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const SET_ACTIVE_FLOW_REQUESTED = 'SET_ACTIVE_FLOW_REQUESTED';
export const SET_ACTIVE_FLOW_COMPLETED = 'SET_ACTIVE_FLOW_COMPLETED';
export const SET_ACTIVE_FLOW_FROM_ID = 'SET_ACTIVE_FLOW_FROM_ID';
export const ADD_QUESTION = 'ADD_QUESTION';
export const SET_QUESTION_JUMP = 'SET_QUESTION_JUMP';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const ADD_QUESTION_CHOICE = 'ADD_QUESTION_CHOICE';
export const ADD_QUESTION_BRANCH = 'ADD_QUESTION_BRANCH';
export const UPDATE_QUESTION_CHOICE = 'UPDATE_QUESTION_CHOICE';
export const UPDATE_QUESTION_BRANCH = 'UPDATE_QUESTION_BRANCH';
export const DELETE_QUESTION_CHOICE = 'DELETE_QUESTION_CHOICE';
export const DELETE_QUESTION_BRANCH = 'DELETE_QUESTION_BRANCH';
export const UPDATE_QUESTION_PROPERTY = 'UPDATE_QUESTION_PROPERTY';
export const UPDATE_CUSTOM_QUESTION_PROPERTY =
  'UPDATE_CUSTOM_QUESTION_PROPERTY';
export const UPDATE_MATRIX_QUESTION_PROPERTY =
  'UPDATE_MATRIX_QUESTION_PROPERTY';
export const UPDATE_GROUP_PROPERTY = 'UPDATE_GROUP_PROPERTY';
export const SET_GROUP_JUMP = 'SET_GROUP_JUMP';
export const TOGGLE_SURVEY_BUILDER_POPUP = 'TOGGLE_SURVEY_BUILDER_POPUP';
export const UPDATE_ACTIVE_FLOW_BEFORE_LEAVE =
  'UPDATE_ACTIVE_FLOW_BEFORE_LEAVE';
export const MOVE_QUESTION = 'MOVE_QUESTION';
export const MOVE_GROUP = 'MOVE_GROUP';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_GROUP = 'DELETE_GROUP';
export const NEST_FLOWS = 'NEST_FLOWS';
export const ADD_FLOWS_TO_GROUP = 'ADD_FLOWS_TO_GROUP';
export const UNGROUP = 'UNGROUP';
export const DUPLICATE_QUESTION = 'DUPLICATE_QUESTION';
export const DUPLICATE_GROUP = 'DUPLICATE_GROUP';
export const EMPTY_SURVEY_STATE = 'EMPTY_SURVEY_STATE';
export const SET_CUSTOM_LOADING = 'SET_CUSTOM_LOADING';
export const SET_SURVEY_FROM_TEMPLATE_WIZARD =
  'SET_SURVEY_FROM_TEMPLATE_WIZARD';
export const UPDATE_DECISION_FRAMEWORK = 'UPDATE_DECISION_FRAMEWORK';
export const CHANGE_MATRIX_FRAMEWORK_TYPE = 'CHANGE_MATRIX_FRAMEWORK_TYPE';

export function setSurvey(api, survey) {
  return { type: SET_SURVEY, api, survey };
}

export function updateSurvey(api, property, value) {
  return { type: UPDATE_SURVEY, api, property, value };
}

export function setActiveFlow(api, flow) {
  return { type: SET_ACTIVE_FLOW_REQUESTED, api, flow };
}

export function setActiveFlowFromId(api, flowId) {
  return { type: SET_ACTIVE_FLOW_FROM_ID, api, flowId };
}

export function addQuestion(
  api,
  newQuestionType,
  dropzone,
  destinationSetIndex,
  destionationQuestionindex,
  addQuestionsListClick
) {
  return {
    type: ADD_QUESTION,
    api,
    newQuestionType,
    dropzone,
    destinationSetIndex,
    destionationQuestionindex,
    addQuestionsListClick
  };
}

export function setQuestionJump(api, jump) {
  return { type: SET_QUESTION_JUMP, api, jump };
}

export function updateQuestion(api, question) {
  return { type: UPDATE_QUESTION, api, question };
}

export function addQuestionChoice(
  api,
  flowId,
  customAttributes,
  isOther,
  isNoneOfTheAbove
) {
  return {
    type: ADD_QUESTION_CHOICE,
    api,
    flowId,
    customAttributes,
    isOther,
    isNoneOfTheAbove
  };
}

export function addQuestionBranch(api, flowId, customAttributes) {
  return { type: ADD_QUESTION_BRANCH, api, flowId, customAttributes };
}

export function updateQuestionChoice(
  api,
  flowId,
  choice,
  answerUpdate,
  updateActiveQuestion,
  saveUpdatedQuestion
) {
  return {
    type: UPDATE_QUESTION_CHOICE,
    api,
    flowId,
    choice,
    answerUpdate,
    updateActiveQuestion,
    saveUpdatedQuestion
  };
}

export function updateQuestionBranch(
  api,
  flowId,
  branch,
  updateActiveQuestion,
  onlyLabelChange
) {
  return {
    type: UPDATE_QUESTION_BRANCH,
    api,
    flowId,
    branch,
    updateActiveQuestion,
    onlyLabelChange
  };
}

export function deleteQuestionChoice(api, flowId, choiceId) {
  return { type: DELETE_QUESTION_CHOICE, api, flowId, choiceId };
}

export function deleteQuestionBranch(api, flowId, branchId) {
  return { type: DELETE_QUESTION_BRANCH, api, flowId, branchId };
}

export function updateQuestionProperty(api, property, value) {
  return { type: UPDATE_QUESTION_PROPERTY, api, property, value };
}

export function updateCustomQuestionProperty(flowId, property, value) {
  // Used for tests
  return { type: UPDATE_CUSTOM_QUESTION_PROPERTY, flowId, property, value };
}

export function updateMatrixQuestionProperty(api, property, value) {
  return { type: UPDATE_MATRIX_QUESTION_PROPERTY, api, property, value };
}

export function updateGroupProperty(api, groupId, property, value) {
  return { type: UPDATE_GROUP_PROPERTY, api, groupId, property, value };
}

export function setGroupJump(api, groupId, jump) {
  return { type: SET_GROUP_JUMP, api, groupId, jump };
}

export function toggleSurveyBuilderPopup(api, surveyBuilderPopup) {
  return { type: TOGGLE_SURVEY_BUILDER_POPUP, api, surveyBuilderPopup };
}

export function updateActiveFlowBeforeLeave(api, jump) {
  return { type: UPDATE_ACTIVE_FLOW_BEFORE_LEAVE, api, jump };
}

export function moveQuestion(
  api,
  dropzone,
  sourceSetIndex,
  sourceQuestionindex,
  destinationSetIndex,
  destionationQuestionindex,
  destinationPosition,
  destinationQuestionId
) {
  return {
    type: MOVE_QUESTION,
    api,
    dropzone,
    sourceSetIndex,
    sourceQuestionindex,
    destinationSetIndex,
    destionationQuestionindex,
    destinationPosition,
    destinationQuestionId
  };
}

export function moveGroup(
  api,
  groupId,
  destinationPosition,
  destinationQuestionId
) {
  return {
    type: MOVE_GROUP,
    api,
    groupId,
    destinationPosition,
    destinationQuestionId
  };
}

export function deleteQuestion(api, questionId, history) {
  return { type: DELETE_QUESTION, api, questionId, history };
}

export function deleteGroup(api, groupId, history) {
  return { type: DELETE_GROUP, api, groupId, history };
}

export function nestFlows(api, isTop, firstFlow, secondFlow) {
  return { type: NEST_FLOWS, api, isTop, firstFlow, secondFlow };
}

export function addFlowsToGroup(api, isTop, firstFlow, secondFlow) {
  return { type: ADD_FLOWS_TO_GROUP, api, isTop, firstFlow, secondFlow };
}

export function ungroup(api, groupId, questionIndexInSet, history) {
  return { type: UNGROUP, api, groupId, questionIndexInSet, history };
}

export function duplicateQuestion(
  api,
  flow,
  destinationPosition,
  destinationQuestionId,
  newQuestionId
) {
  return {
    type: DUPLICATE_QUESTION,
    api,
    flow,
    destinationPosition,
    destinationQuestionId,
    newQuestionId
  };
}

export function duplicateGroup(
  api,
  group,
  destinationPosition,
  destinationQuestionId,
  jumpFromDistributorGroup
) {
  return {
    type: DUPLICATE_GROUP,
    api,
    group,
    destinationPosition,
    destinationQuestionId,
    jumpFromDistributorGroup
  };
}

export function emptySurveyState() {
  return { type: EMPTY_SURVEY_STATE };
}

export function setCustomLoading(customLoading) {
  return { type: SET_CUSTOM_LOADING, customLoading };
}

export function setSurveyFromTemplateWizard(api, wizardSteps) {
  return { type: SET_SURVEY_FROM_TEMPLATE_WIZARD, api, wizardSteps };
}

export function updateDecisionFramework(api, question) {
  return { type: UPDATE_DECISION_FRAMEWORK, api, question };
}

export function changeMatrixFrameworkType(
  api,
  question,
  previousDecisionFrameworkType
) {
  return {
    type: CHANGE_MATRIX_FRAMEWORK_TYPE,
    api,
    question,
    previousDecisionFrameworkType
  };
}
