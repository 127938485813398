import React, { useState } from 'react';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';

import styles from './Properties.module.css';

const Properties = ({ survey, isAdmin, onUpdate }) => {
  const [expanded, setExpanded] = useState(false);

  const welcomeProperties = {
    general: [
      {
        label: 'Disable view link',
        type: 'survey-property-change',
        name: 'disable_view_link',
        graphName: 'disableViewLink',
        value: false,
        adminOnly: true,
        updateSurvey: true
      },
      {
        label: 'Onboarding survey',
        type: 'survey-property-change',
        name: 'isOnboardingSurvey',
        graphName: 'isOnboardingSurvey',
        value: false,
        adminOnly: true,
        updateSurvey: true,
        info:
          'Onboarding surveys are shown to new users (of a specific community) before they see any other survey(s).\nOnly if all onboarding surveys are completed, users will be able to see other surveys. They won’t see any more onboarding surveys in the future.'
      },
      {
        label: `Don't count survey in fair use policy`,
        type: 'survey-property-change',
        name: 'dontCountInFairUsePolicy',
        graphName: 'dontCountInFairUsePolicy',
        value: false,
        adminOnly: true,
        updateSurvey: true
      }
    ]
  };

  const getActiveBlockProperty = (property, index) => {
    if (!property.adminOnly || (property.adminOnly && isAdmin)) {
      return (
        <div
          key={`properties-item-${index.toString()}`}
          className={styles.singleProperty}
        >
          <div>
            <span>{property.label}</span>
            {property.info ? (
              <div className={styles.moreInfoContainer}>
                <div className={styles.questionMark}>?</div>
                <div className={styles.tooltip}>{property.info}</div>
              </div>
            ) : null}
          </div>

          {property.type === 'survey-property-change' ? (
            /* eslint-disable */
            <span role="presentation">
              <label className={styles.switch} htmlFor="checkbox">
                <input
                  type="checkbox"
                  checked={survey[property.graphName]}
                  readOnly
                />
                <span
                  className={`slider round survey-property-${property.name}`}
                  role="presentation"
                  onClick={e => {
                    property.value = !survey[property.graphName];
                    // activeBlock[property.name] = property.value;
                    survey[property.name] = property.value;
                    onUpdate(property.graphName, property.value);
                    e.stopPropagation();
                  }}
                />
              </label>
            </span>
          ) : /* eslint-enable */
          null}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.titleContainer}
        role="presentation"
        onClick={() => setExpanded(() => setExpanded(!expanded))}
      >
        <div className={styles.title}>Advanced Settings</div>
        <img
          src={arrowDownPurple}
          alt="ExpandColapse"
          className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
        />
      </div>
      {expanded ? (
        <div className={styles.contentContainer}>
          <div>
            <div className={styles.propertiesContentContainer}>
              {welcomeProperties.general.map((property, index) =>
                getActiveBlockProperty(property, index)
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Properties;
