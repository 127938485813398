import React from 'react';

import styles from './Toggle.module.css';

const Toggle = ({ checked, onToggle, style }) => (
  <div className={styles.container}>
    <label className={styles.switch} htmlFor="activeFeatureInput">
      <input
        type="checkbox"
        name="activeFeatureInput"
        checked={checked}
        readOnly
      />
      <span
        className={`${styles.slider} ${styles.round}`}
        style={style || null}
        role="presentation"
        onClick={e => {
          onToggle();
          e.stopPropagation();
        }}
      />
    </label>
  </div>
);

export default Toggle;
