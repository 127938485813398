import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import socketIOClient from 'socket.io-client';
import useLazyQuery from './useLazyQuery';

import {
  GET_SURVEY,
  UPDATE_TARGET_AUDIENCE,
  UPDATE_SURVEY,
  REQUEST_APPROVAL,
  PUBLISH_SURVEY,
  SCHEDULE_SURVEY,
  UPDATE_SCHEDULE,
  ADD_QUESTIONS,
  UPDATE_QUESTION,
  DELETE_QUESTIONS,
  UPDATE_CUSTOM_COPIES,
  UPDATE_SUCCESS_CRITERIA,
  CREATE_OPPORTUNITY_GRAPH, // Problem validation
  CREATE_CONCEPT_TEST_GRAPH,
  CREATE_VALUE_SELECTION_GRAPH,
  CREATE_IDEA_SELECTION_GRAPH,
  CREATE_CUSTOM_GRAPH,
  UPDATE_OPPORTUNITY_GRAPH,
  UPDATE_CONCEPT_TEST_GRAPH,
  UPDATE_VALUE_SELECTION_GRAPH,
  UPDATE_IDEA_SELECTION_GRAPH,
  UPDATE_CUSTOM_GRAPH,
  REMOVE_OPPORTUNITY_GRAPH,
  REMOVE_CONCEPT_TEST_GRAPH,
  REMOVE_VALUE_SELECTION_GRAPH,
  REMOVE_IDEA_SELECTION_GRAPH,
  REMOVE_CUSTOM_GRAPH
} from '../../graphql/Survey';

import {
  SEARCH_PROFILING_CATEGORIES,
  SEARCH_PROFILING_TAG_NAMES,
  SEARCH_PROFILING_TAG_VALUES
} from '../../graphql/Question';

export default (surveyId, webSocketUrl, webSocketSessionId) => {
  const [loading, setLoading] = useState(false);
  const [ws, setWs] = useState(null);
  const [surveyAlreadyUpdatedSocket, setSurveyAlreadyUpdatedSocket] = useState(
    false
  );

  useEffect(() => setWs(socketIOClient(webSocketUrl)), []);

  const {
    data: { survey: { survey } = {} } = {},
    loading: surveyLoading,
    refetch: surveyRefetch
  } = useQuery(GET_SURVEY, {
    variables: {
      id: surveyId
    },
    fetchPolicy: 'no-cache'
  });

  const [updateSurvey] = useMutation(UPDATE_SURVEY);
  const [updateTargetAudience] = useMutation(UPDATE_TARGET_AUDIENCE);
  const [scheduleSurvey] = useMutation(SCHEDULE_SURVEY, {
    ignoreResults: true,
    fetchPolicy: 'no-cache'
  });
  const [requestApproval] = useMutation(REQUEST_APPROVAL, {
    ignoreResults: true,
    fetchPolicy: 'no-cache'
  });
  const [publishSurvey] = useMutation(PUBLISH_SURVEY, {
    ignoreResults: true
  });
  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    ignoreResults: true,
    fetchPolicy: 'no-cache'
  });
  const [addQuestions] = useMutation(ADD_QUESTIONS);
  const [updateQuestion] = useMutation(UPDATE_QUESTION);
  const [deleteQuestions] = useMutation(DELETE_QUESTIONS);
  const [updateCustomCopies] = useMutation(UPDATE_CUSTOM_COPIES);
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);

  const [createOpportunityGraph] = useMutation(CREATE_OPPORTUNITY_GRAPH);
  const [createConceptTestGraph] = useMutation(CREATE_CONCEPT_TEST_GRAPH);
  const [createValueSelectionGraph] = useMutation(CREATE_VALUE_SELECTION_GRAPH);
  const [createIdeaSelectionGraph] = useMutation(CREATE_IDEA_SELECTION_GRAPH);
  const [createCustomGraph] = useMutation(CREATE_CUSTOM_GRAPH);

  const [updateOpportunityGraph] = useMutation(UPDATE_OPPORTUNITY_GRAPH);
  const [updateConceptTestGraph] = useMutation(UPDATE_CONCEPT_TEST_GRAPH);
  const [updateValueSelectionGraph] = useMutation(UPDATE_VALUE_SELECTION_GRAPH);
  const [updateIdeaSelectionGraph] = useMutation(UPDATE_IDEA_SELECTION_GRAPH);
  const [updateCustomGraph] = useMutation(UPDATE_CUSTOM_GRAPH);

  const [removeOpportunityGraph] = useMutation(REMOVE_OPPORTUNITY_GRAPH);
  const [removeConceptTestGraph] = useMutation(REMOVE_CONCEPT_TEST_GRAPH);
  const [removeValueSelectionGraph] = useMutation(REMOVE_VALUE_SELECTION_GRAPH);
  const [removeIdeaSelectionGraph] = useMutation(REMOVE_IDEA_SELECTION_GRAPH);
  const [removeCustomGraph] = useMutation(REMOVE_CUSTOM_GRAPH);

  const searchProfilingCategories = useLazyQuery(SEARCH_PROFILING_CATEGORIES, {
    fetchPolicy: 'no-cache'
  });
  const searchProfilingTagNames = useLazyQuery(SEARCH_PROFILING_TAG_NAMES, {
    fetchPolicy: 'no-cache'
  });
  const searchProfilingTagValues = useLazyQuery(SEARCH_PROFILING_TAG_VALUES, {
    fetchPolicy: 'no-cache'
  });

  const requests = {
    updateSurvey: async variables => updateSurvey(variables),
    updateTargetAudience: async variables => updateTargetAudience(variables),
    scheduleSurvey: async variables => scheduleSurvey(variables),
    updateSchedule: async variables => updateSchedule(variables),
    requestApproval: async variables => requestApproval(variables),
    publishSurvey: async variables => publishSurvey(variables),
    addQuestions: async variables => {
      const { questionsArray, surveyContent } = variables;

      questionsArray.forEach(q => {
        const newQ = q;

        if (newQ.profiling) {
          delete newQ.profiling.answerCollected;
          delete newQ.profiling.__typename;

          if (newQ.profiling.reuseQuestionSearchSelection) {
            delete newQ.profiling.reuseQuestionSearchSelection.__typename;
          }
        }

        if (newQ.matrix) {
          delete newQ.matrix.__typename;
        }

        if (newQ.incidenceRate) {
          delete newQ.incidenceRate.__typename;
        }
      });

      /* eslint-disable */
      delete surveyContent.__typename;
      surveyContent.groups = surveyContent.groups.map(g => {
        delete g.__typename;
        return g;
      });
      /* eslint-enable */

      return addQuestions({
        variables: {
          survey: surveyId,
          questions: questionsArray,
          surveyContent
        }
      });
    },
    updateQuestion: async question => {
      const updatedQuestion = JSON.parse(JSON.stringify(question.question));

      if (updatedQuestion && updatedQuestion.id) {
        delete updatedQuestion.type;
        delete updatedQuestion.__typename;

        updatedQuestion.choices =
          updatedQuestion.choices &&
          updatedQuestion.choices.map(c => {
            const newC = c;
            if (c && c.__typename) delete newC.__typename;
            if (c && c.profilingTag && c.profilingTag.__typename)
              delete newC.profilingTag.__typename;
            return newC;
          });

        if (updatedQuestion.profiling) {
          delete updatedQuestion.profiling.__typename;
          delete updatedQuestion.profiling.answerCollected;

          if (updatedQuestion.profiling.reuseQuestionSearchSelection) {
            delete updatedQuestion.profiling.reuseQuestionSearchSelection
              .__typename;
          }
        }

        if (updatedQuestion.matrix) {
          delete updatedQuestion.matrix.__typename;
        }

        if (updatedQuestion.incidenceRate) {
          delete updatedQuestion.incidenceRate.__typename;
        }

        return updateQuestion({ variables: { question: updatedQuestion } });
      }
      return null;
    },
    deleteQuestions: async variables => {
      const { questionIdsArray, surveyContent } = variables;

      /* eslint-disable */
      delete surveyContent.__typename;
      surveyContent.groups = surveyContent.groups.map(g => {
        delete g.__typename;
        return g;
      });
      /* eslint-enable */

      deleteQuestions({
        variables: { survey: surveyId, ids: questionIdsArray, surveyContent }
      });
    },
    updateCustomCopies: async variables => updateCustomCopies(variables),
    updateSuccessCriteria: async variables => updateSuccessCriteria(variables),

    // Survey Builder Templates
    createOpportunityGraph: async variables =>
      createOpportunityGraph(variables),
    createConceptTestGraph: async variables =>
      createConceptTestGraph(variables),
    createValueSelectionGraph: async variables =>
      createValueSelectionGraph(variables),
    createIdeaSelectionGraph: async variables =>
      createIdeaSelectionGraph(variables),
    createCustomGraph: async variables => createCustomGraph(variables),

    // Survey Builder Profiling Questions
    searchProfilingCategories: async variables =>
      searchProfilingCategories(variables),
    searchProfilingTagNames: async variables =>
      searchProfilingTagNames(variables),
    searchProfilingTagValues: async variables =>
      searchProfilingTagValues(variables),

    // Matrix question - decision frameworks
    updateOpportunityGraph: async variables =>
      updateOpportunityGraph(variables),
    updateConceptTestGraph: async variables =>
      updateConceptTestGraph(variables),
    updateValueSelectionGraph: async variables =>
      updateValueSelectionGraph(variables),
    updateIdeaSelectionGraph: async variables =>
      updateIdeaSelectionGraph(variables),
    updateCustomGraph: async variables => updateCustomGraph(variables),

    removeOpportunityGraph: async variables =>
      removeOpportunityGraph(variables),
    removeConceptTestGraph: async variables =>
      removeConceptTestGraph(variables),
    removeValueSelectionGraph: async variables =>
      removeValueSelectionGraph(variables),
    removeIdeaSelectionGraph: async variables =>
      removeIdeaSelectionGraph(variables),
    removeCustomGraph: async variables => removeCustomGraph(variables)
  };

  const api = async (
    request,
    variables,
    waitingTime = 600,
    returnError = null
  ) => {
    // If api request is not resolved in 600ms, show loader
    const t = setTimeout(() => setLoading(true), waitingTime);

    const response = await requests[request](variables);

    /* We do not expect backend to throw any error message. If that happen, Survey Builder will keep showing loading
      animation preventing client using and building survey. That way they are forced to refresh Survey Builder page
      and sync frontend application with backend.
    */
    if (
      response &&
      response.data &&
      response.data[request] &&
      response.data[request].errors &&
      response.data[request].errors.length &&
      !returnError
    ) {
      return null;
    }

    sendSocketMessageSurveyUpdated();

    clearTimeout(t);
    setLoading(false);
    return response;
  };

  const onSurveyUpdatedSocketMessageReceived = message => {
    const updateData = JSON.parse(message);

    if (
      updateData &&
      survey &&
      updateData.survey === survey.id &&
      updateData.webSocketSessionId !== webSocketSessionId
    ) {
      setSurveyAlreadyUpdatedSocket(true);
    }
  };

  const sendSocketMessageSurveyUpdated = () => {
    if (survey && ws) {
      const surveyDetails = JSON.stringify({
        survey: survey.id,
        webSocketSessionId
      });
      ws.emit('Campaign Update', surveyDetails);
    }
  };

  if (ws) {
    ws.on('Campaign Update', onSurveyUpdatedSocketMessageReceived);
  }

  return [
    api,
    survey,
    loading,
    surveyLoading,
    surveyRefetch,
    surveyAlreadyUpdatedSocket
  ];
};
