import React, { useState, useEffect } from 'react';

import textareaAutoExpand from '../../../../../../helpers/textareaAutoExpand';

import styles from './CustomCopyField.module.css';

const CustomCopyField = props => {
  const {
    propertyName,
    field,
    updateCustomCopies,
    customCopyFields,
    setCustomCopyFields,
    language,
    resize
  } = props;

  const [textArea, setTextArea] = useState(false);

  const onWindowResize = ev => {
    ev.preventDefault();
    if (textArea) {
      textareaAutoExpand(textArea);
    }
  };

  useEffect(
    () => {
      if (textArea) {
        textareaAutoExpand(textArea);
      }

      window.addEventListener('resize', onWindowResize);

      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    },
    [textArea, resize]
  );

  return (
    <textarea
      ket={field.name}
      className={styles.description}
      value={field.value}
      rows="1"
      ref={element => {
        if (element) {
          setTextArea(element);
        }
      }}
      onBlur={e => {
        updateCustomCopies({
          name: propertyName,
          translation: {
            key: field.name,
            value: e.target.value
          },
          language
        });
      }}
      onChange={e => {
        textareaAutoExpand(textArea);
        setCustomCopyFields({
          ...customCopyFields,
          [field.name]: e.target.value
        });
      }}
      maxLength="2000"
    />
  );
};

export default CustomCopyField;
