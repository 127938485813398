export default (newUserFilter, filterSettings, onUserFilterChange) => {
  let newFilterSettings = null;
  if (filterSettings) {
    newFilterSettings = { ...filterSettings };
    delete newFilterSettings.__typename;
    delete newFilterSettings.representativity.__typename;

    newFilterSettings.crossedSample = false;
  }
  if (newUserFilter && newFilterSettings) {
    onUserFilterChange(newUserFilter, newFilterSettings);
  }
};
