import { connect } from 'react-redux';
import { changeLoginField, requestLogin } from '../actions';

import LoginPage from '../components/LoginPage';

const mapStateToProps = state => ({
  fields: state.auth.login.fields,
  isFetching: state.auth.login.isFetching,
  isAuthenticated: state.auth.isAuthenticated,
  isProcessing: state.auth.isProcessing,
  isCompleted: state.auth.isCompleted,
  errors: state.auth.login.errors
});

const mapDispatchToProps = dispatch => ({
  onChange: field => event =>
    dispatch(changeLoginField(field, event.target.value)),
  onSubmit: (event, email, password, invitationToken) => {
    event.preventDefault();
    dispatch(requestLogin(email, password, invitationToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
