import React from 'react';
import LjArrow from '../../../../../../../../../../../Icon/custom/LjArrow';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

import { QUOTA_UNITS } from '../../../../../../../../../../../../helpers/constants';

import warningIcon from '../../../../../../../../../../../../../../../../assets/img/exclamation.svg';

import styles from './BranchQuota.module.css';

export default ({
  branches,
  branch,
  actions,
  questionId,
  maxSurveyResponses,
  distributeEvenly
}) => {
  const onQuotaTypeChange = async () => {
    if (!distributeEvenly) {
      const newBranch = { ...branch };

      if (!branch.quotaType) {
        newBranch.quotaType = QUOTA_UNITS.PERCENTAGE;
        newBranch.quotaAmount = 50;
      } else {
        newBranch.quotaType =
          newBranch.quotaType === QUOTA_UNITS.PERCENTAGE
            ? QUOTA_UNITS.ABSOLUTE
            : QUOTA_UNITS.PERCENTAGE;
      }

      actions.updateQuestionBranch(questionId, newBranch, true);
    }
  };

  const onQuotaValueChange = value => {
    const newBranch = { ...branch };

    if (!branch.quotaType) {
      newBranch.quotaType = QUOTA_UNITS.PERCENTAGE;
      newBranch.quotaAmount = value;
    } else {
      newBranch.quotaAmount = value;
    }

    actions.updateQuestionBranch(questionId, newBranch);
  };

  // Define to show error or not
  const branchOrderIndex = branches.findIndex(b => b.id === branch.id);
  const branchesBeforeIndex = branches.slice(0, branchOrderIndex + 1);
  const filteredBranchesBeforeIndex = branchesBeforeIndex.filter(
    b => b.quotaType === QUOTA_UNITS.PERCENTAGE
  );

  let error = null;
  if (filteredBranchesBeforeIndex.length) {
    const totalPercentageBeforeAndWithThisBranch = filteredBranchesBeforeIndex.reduce(
      (acc, c) => acc + parseInt(c.quotaAmount, 10),
      0
    );

    if (totalPercentageBeforeAndWithThisBranch > 100) {
      error = 'The distributor should be equal to 100%.';
    }
  }

  if (
    branch.quotaType === QUOTA_UNITS.ABSOLUTE &&
    branch.quotaAmount > maxSurveyResponses
  ) {
    error = 'Value exceeds total amount of respondents.';
  }

  return (
    <div
      className={
        error ? `${styles.container} ${styles.error}` : styles.container
      }
    >
      <div className={styles.dropdownRow}>
        <div className={styles.dropdownRowParent}>
          <div className={styles.ljArrow}>
            <LjArrow fill={error ? '#f61662' : undefined} />
          </div>
          Close path after
          <input
            disabled={distributeEvenly}
            className={styles.quotaInput}
            type="number"
            onKeyDown={evt =>
              evt.key === 'ArrowDown' ||
              evt.key === 'ArrowUp' ||
              evt.key === 'e' ||
              evt.key === 'E' ||
              evt.key === '.' ||
              evt.key === ',' ||
              evt.key === '-'
                ? evt.preventDefault()
                : null
            }
            onChange={e => onQuotaValueChange(e.target.value)}
            value={branch.quotaAmount}
          />
          <ToggleSwitch
            values={[
              {
                value: QUOTA_UNITS.ABSOLUTE,
                label: '#'
              },
              {
                value: QUOTA_UNITS.PERCENTAGE,
                label: '%'
              }
            ]}
            value={branch.quotaType}
            onClick={onQuotaTypeChange}
            error={error}
            disabled={distributeEvenly}
          />
          <span className={styles.dropdownContainerLabel}>
            {branch.quotaType === QUOTA_UNITS.PERCENTAGE ? 'of' : ''}{' '}
            respondents.
          </span>
          {error ? (
            <div className={styles.errorIconContainer}>
              <img src={warningIcon} alt="Error" />
              <span className={styles.errorTooltip}>{error}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
