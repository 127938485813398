export const USER_PROPERTIES = {
  LANGUAGE: 'LANGUAGE',
  GENDER: 'GENDER',
  AGE: 'AGE',
  PROVINCE: 'PROVINCE'
};

export const FILTER_RELATIONS = {
  AND: 'AND',
  OR: 'OR'
};

export const MAIN_DATA_METRICS = {
  DELIVERY_POTENTIAL: 'deliveryPotential',
  SOCIO_DEMOGRAPHICS: 'socioDemographics',
  PROFILE_ATTRIBUTES: 'profileAttributes',
  INCIDENCE_RATE: 'incidenceRate'
};

export const COLORS = {
  RED: 'RED',
  ORANGE: 'ORANGE',
  GREEN: 'GREEN',
  BLUE: 'BLUE'
};

export const REPRESENTATIVITY_OPTIONS = {
  RECOMMENDED: 'RECOMMENDED',
  STRICT: 'STRICT'
};

export const AGE_REPRESENTATIVITY = {
  [REPRESENTATIVITY_OPTIONS.RECOMMENDED]: {
    '18_24': 15,
    '25_34': 25,
    '35_44': 29,
    '45_54': 30,
    '55_65': 32
  },
  [REPRESENTATIVITY_OPTIONS.STRICT]: {
    '18_24': 12,
    '25_34': 21,
    '35_44': 21,
    '45_54': 22,
    '55_65': 24
  }
};

export const GENDER_REPRESENTATIVITY = {
  [REPRESENTATIVITY_OPTIONS.RECOMMENDED]: {
    Male: 55,
    Female: 55
  },
  [REPRESENTATIVITY_OPTIONS.STRICT]: {
    Male: 50,
    Female: 50
  }
};

export const LANGUAGE_REPRESENTATIVITY = {
  [REPRESENTATIVITY_OPTIONS.RECOMMENDED]: {
    Dutch: 60,
    French: 40
  },
  [REPRESENTATIVITY_OPTIONS.STRICT]: {
    Dutch: 60,
    French: 40
  }
};

export const SOCIO_DEMOGRAPHICS_CHARTS = {
  AGE: 'age',
  GENDER: 'gender',
  LANGUAGE: 'language'
};

export const WARNINGS = {
  SAMPLE_SIZE_NOT_FEASIBLE: 'Sample size is not feasible',
  AGE_NOT_REPRESENTATIVE: 'Age is not representative in this sample size',
  GENDER_NOT_REPRESENTATIVE: 'Gender is not representative in this sample size',
  LANGUAGE_NOT_REPRESENTATIVE:
    'Language is not representative in this sample size',
  QUOTA_NOT_FEASIBLE: 'Quota for {profileAttribute} is not feasible',
  DELTAS_WITH_OR_FILTERING:
    "You are using OR filters. The ∆'s in the graphs below display all possibilities to reach total sample size."
};

export const AGE_CHART_LABELS = {
  '18_24': '18-24',
  '25_34': '25-34',
  '35_44': '35-44',
  '45_54': '45-54',
  '55_65': '55-65'
};

export const GENDER_IR_MAPPING = {
  Male: 0.5,
  Female: 0.5,
  Other: 0.05
};

export const AGE_IR_MAPPING = {
  '18_24': 0.12,
  '25_34': 0.21,
  '35_44': 0.21,
  '45_54': 0.22,
  '55_65': 0.24
};
