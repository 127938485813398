import { put, select } from 'redux-saga/effects';

import * as actions from '../../actions';

import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  // Update survey
  const surveyUpdateContent = {
    survey: surveysState.survey.id,
    content: surveysState.survey.content
  };

  const newState = {
    ...surveysState
  };

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  yield put({ type: actions.SET_NEW_STATE, newState });

  action.api('updateSurvey', { variables: surveyUpdateContent });
}
