import React from 'react';

// Import Icon from '../../../../../../../Icon';

import styles from './QuestionsList.module.css';

export default ({
  mergedGraphQuestions,
  colorsPallete,
  hiddenQuestions,
  setHiddenQuestions,
  parseQuestion,
  isMatrixQuestion
}) => {
  const isQuestionHidden = index => hiddenQuestions.indexOf(index) === -1;

  return (
    <div className={styles.container}>
      <div className={styles.questionsList}>
        {mergedGraphQuestions.map((question, index) => (
          <div
            className={styles.question}
            title={question && parseQuestion(question.question)}
          >
            <div
              style={{
                backgroundColor: colorsPallete[index]
              }}
              className={styles.questionColor}
            />
            {!isMatrixQuestion && (
              <div className={styles.questionNumber}>
                Q{question && question.formattedGlobalIndex}
              </div>
            )}
            <div
              className={`${styles.questionString} ${
                isMatrixQuestion ? styles.matrixQuestionString : ''
              }`}
            >
              {question && parseQuestion(question.question)}
            </div>
            <div className={styles.questionToggle}>
              <span role="presentation">
                <label className={styles.questionSwitch} htmlFor="checkbox">
                  <input
                    type="checkbox"
                    checked={!isQuestionHidden(index)}
                    className={styles.input}
                  />
                  <span
                    className={styles.questionSlider}
                    style={{
                      backgroundColor: !isQuestionHidden(index)
                        ? '#d4d4d4'
                        : colorsPallete[index]
                    }}
                    role="presentation"
                    onClick={e => {
                      e.stopPropagation();

                      if (isQuestionHidden(index)) {
                        const newHiddenQuestion = [...hiddenQuestions, index];
                        setHiddenQuestions(newHiddenQuestion);
                      } else {
                        const indexOfHiddenQuestion = hiddenQuestions.indexOf(
                          index
                        );
                        if (indexOfHiddenQuestion > -1) {
                          hiddenQuestions.splice(indexOfHiddenQuestion, 1);
                          setHiddenQuestions(
                            JSON.parse(JSON.stringify(hiddenQuestions))
                          );
                        }
                      }
                    }}
                  />
                </label>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
