import React, { useContext } from 'react';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import Footer from '../../Footer/Footer';

import { TEMPLATES_WIZARD_STEP_KEYS } from '../../../../../helpers/constants';

import styles from './SetContext.module.css';

export default () => {
  const {
    currentStep,
    goToNextStep,
    goToPreviousStep,
    steps,
    strings,
    setStepValue,
    styleText
  } = useContext(TemplatesWizardContext);

  const templateTypeStep = steps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
  );

  const templateStrings = strings[templateTypeStep.value];

  const isDisabledNext = () => {
    if (!currentStep.value || !currentStep.value.length) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>{strings.PROBLEM_CONTEXT}</div>
        <div className={styles.text}>
          {templateStrings.DESCRIBE_THE_SITUATION}
        </div>
        <div className={styles.text}>
          {styleText(templateStrings.WHERE_AND_WHEN_WILL_USED, styles)}
        </div>
        <div className={styles.inputWrapper}>
          <textarea
            placeholder={templateStrings.CONTEXT_EXAMPLE}
            onChange={e =>
              setStepValue(
                TEMPLATES_WIZARD_STEP_KEYS.SET_CONTEXT,
                e.target.value
              )
            }
            value={currentStep.value || ''}
          />
        </div>
        <div className={styles.text}>
          {strings.IT_IS_STRONGLY_RECOMMENDED}{' '}
          <a href="mailto:operations@buffl.be">operations@buffl.be</a>{' '}
          {strings.FOR_HELP}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer
          onNextClick={goToNextStep}
          onPreviousClick={goToPreviousStep}
          nextLabel={strings.NEXT}
          previousLabel={strings.PREVIOUS}
          disabledNext={isDisabledNext()}
        />
      </div>
    </div>
  );
};
