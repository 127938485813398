import React, { useEffect } from 'react';
import styles from '../CampaignQuestionsPage.module.css';

export default props => {
  const {
    block,
    setValid,
    values,
    setValues,
    remainingCharacters,
    minCharacters
  } = props;

  const checkIsValid = value => {
    if (
      value.length <= block.answerMaxCharacters &&
      value.length >= block.answerMinCharacters
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const onChangeValue = value => {
    setValues(value);
    checkIsValid(value);
  };

  useEffect(
    () => {
      if (!values) {
        setValid(false);
      }
    },
    [block]
  );

  return (
    <div className={styles.questionContainer}>
      <textarea
        className={`${styles.inputField} ${
          styles.openAnswerInputField
        } user-answers-textarea-input`}
        onChange={event => onChangeValue(event.target.value)}
        value={!values ? '' : values}
      />
      <div className={styles.inputFieldDescription}>
        {block.answerMaxCharacters
          ? Math.max(
              0,
              block.answerMaxCharacters -
                (values && values.length ? values.length : 0)
            )
          : '∞'}{' '}
        {remainingCharacters}
        {block.answerMinCharacters &&
          ` (${minCharacters} ${block.answerMinCharacters})`}
      </div>
    </div>
  );
};
