import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useLazyQuery from '../../../../../users/hooks/useLazyQuery';

import {
  COMMUNITY_TYPES,
  COMMUNITY_TYPE_LABELS
} from '../../helpers/constants';
import {
  FILTER_RELATIONS,
  COMMUNITY_DASHBOARD_FILTER_TYPE
} from '../../../CommunityDashboard/helpers/constants';
import {
  CREATE_COMMUNITY,
  UPDATE_COMMUNITY,
  GET_COMMUNITY
} from '../../../../graphql/CommunityManagement';

import AppBar from '../../../../base/components/AppBar/AppBar';
import InputField from './components/InputField/InputField';
import DropdownSelector from './components/DropdownSelector/DropdownSelector';
import TypeExplanation from './components/TypeExplanation/TypeExplanation';
import Button from '../../../../campaigns/components/Button/Button';
import Filters from '../../../CommunityDashboard/components/CommunityDashboardPage/components/Filters/Filters';
import Loader from '../../../CommunityAutomations/components/Loader/Loader';
import RouteTitle from '../RouteTitle/RouteTitle';
import RouteSubtitle from '../RouteSubtitle/RouteSubtitle';
import CommunityUsersList from './components/CommunityUsersList/CommunityUsersList';
import Onboarding from './components/Onboarding/Onboarding';

import styles from './CommunityManagementPage.module.css';

export default props => {
  const { communityId, history } = props;

  const [communityValues, setCommunityValues] = useState({
    name: '',
    type: COMMUNITY_TYPES.DYNAMIC,
    filterRelation: FILTER_RELATIONS.AND,
    appliedFilters: []
  });
  const [communityUsers, setCommunityUsers] = useState([]);
  const [onboardingSurveys, setOnboardingSurveys] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [filtering, setFiltering] = useState({
    page: 0,
    search: ''
  });
  const [totalCount, setTotalCount] = useState(0);
  const [removedUsers, setRemovedUsers] = useState([]);

  const [createCommunity, { loading: createCommunityLoading }] = useMutation(
    CREATE_COMMUNITY
  );
  const [updateCommunity, { loading: updateCommunityLoading }] = useMutation(
    UPDATE_COMMUNITY
  );

  const getCommunity = useLazyQuery(GET_COMMUNITY, {
    fetchPolicy: 'no-cache'
  });

  const getLoadData = async paginationVariables => {
    setIsGlobalLoading(true);

    const data = await getCommunity({
      variables: {
        id: communityId,
        ...paginationVariables
      }
    });

    if (data && data.data && data.data.getCommunity) {
      if (
        !communityValues.id &&
        data.data.getCommunity.community &&
        data.data.getCommunity.community.id
      ) {
        setCommunityValues(data.data.getCommunity.community);
      }
      if (data.data.getCommunity.users && data.data.getCommunity.users.length) {
        setCommunityUsers(data.data.getCommunity.users);
      }
      if (data.data.getCommunity.totalCount) {
        setTotalCount(data.data.getCommunity.totalCount);
      }
      if (
        !onboardingSurveys.length &&
        data.data.getCommunity.onboardingSurveys
      ) {
        setOnboardingSurveys(data.data.getCommunity.onboardingSurveys);
      }
    }
    setIsGlobalLoading(false);
  };

  useEffect(() => {
    if (communityId !== 'new') {
      const paginationVariables = {
        page: filtering.page,
        search: filtering.search
      };
      getLoadData(paginationVariables);
    }
  }, []);

  const isLoading =
    createCommunityLoading || updateCommunityLoading || isGlobalLoading;

  const isAllowedToSaveCommunity = () => {
    if (
      communityValues &&
      communityValues.name &&
      communityValues.name.trim() !== '' &&
      communityValues.type &&
      communityValues.type !== '' &&
      (communityValues.type === COMMUNITY_TYPES.STATIC ||
        (communityValues.type === COMMUNITY_TYPES.DYNAMIC &&
          communityValues.appliedFilters &&
          communityValues.appliedFilters.length))
    ) {
      return true;
    }
    return false;
  };

  const onSaveClick = async () => {
    if (isAllowedToSaveCommunity()) {
      const communityToSave = {
        ...communityValues
      };

      if (
        communityToSave &&
        communityToSave.appliedFilters &&
        communityToSave.appliedFilters.length
      ) {
        communityToSave.appliedFilters.forEach(filter => {
          const newFilter = filter;

          delete newFilter.__typename;
          if (newFilter.tags) {
            delete newFilter.tags.__typename;
          }
          if (newFilter.userProperties) {
            delete newFilter.userProperties.__typename;
          }
        });
      } else {
        communityToSave.appliedFilters = [];
      }

      if (
        communityToSave &&
        communityToSave.steps &&
        communityToSave.steps.length
      ) {
        communityToSave.steps.forEach(step => {
          const newStep = step;

          delete newStep.__typename;
        });
      }

      if (communityId === 'new') {
        const data = await createCommunity({
          variables: communityToSave
        });

        if (
          data &&
          data.data &&
          data.data.createCommunity &&
          data.data.createCommunity.community &&
          data.data.createCommunity.community.id
        ) {
          history.push(
            `/community-management/${data.data.createCommunity.community.id}`
          );

          setCommunityValues({
            ...communityValues,
            id: data.data.createCommunity.community.id
          });

          if (errors && errors.length) {
            setErrors([]);
          }
        }

        if (
          data &&
          data.data.createCommunity &&
          !data.data.createCommunity.done &&
          data.data.createCommunity.errors &&
          data.data.createCommunity.errors.length
        ) {
          setErrors(data.data.createCommunity.errors);
        }
      }
      if (communityId !== 'new') {
        const data = await updateCommunity({
          variables: { ...communityToSave, removedUsers }
        });

        if (
          data &&
          data.data.updateCommunity &&
          !data.data.updateCommunity.done &&
          data.data.updateCommunity.errors &&
          data.data.updateCommunity.errors.length
        ) {
          setErrors(data.data.updateCommunity.errors);
        } else {
          history.push('/community-management');
        }
      }
    }
  };

  const routeTitle = <RouteTitle history={history} />;
  const routeSubtitle = (
    <RouteSubtitle
      history={history}
      communityName={(communityValues && communityValues.name) || ''}
    />
  );

  return (
    <>
      <AppBar
        {...props}
        appBarProperties={{
          routeTitle,
          routeSubtitle
        }}
      />
      {isLoading ? <Loader /> : null}
      {!isLoading ? (
        <div className={styles.container}>
          <div className={styles.title}>
            {communityId === 'new' ? 'Create community' : 'Update community'}
          </div>
          <div className={styles.inputContainer}>
            <InputField
              title="Community name"
              placeholder="Type here"
              value={communityValues.name || ''}
              setValue={setCommunityValues}
              values={communityValues}
              fieldName="name"
              mandatory
            />
            <DropdownSelector
              title="Community type"
              placeholder="Select type"
              filterDropdownStyle={
                communityValues && communityValues.type
                  ? `${styles.dropdownFilterStyle} ${styles.selectedValue}`
                  : styles.dropdownFilterStyle
              }
              options={Object.values(COMMUNITY_TYPES).map(type => ({
                value: type,
                label: COMMUNITY_TYPE_LABELS[type]
              }))}
              value={
                communityValues && communityValues.type
                  ? {
                      value: communityValues.type,
                      label: COMMUNITY_TYPE_LABELS[communityValues.type]
                    }
                  : { value: null, label: '' }
              }
              onSelectCompleted={value => {
                setCommunityValues({
                  ...communityValues,
                  appliedFilters: [],
                  filterRelation:
                    value === COMMUNITY_TYPES.DYNAMIC
                      ? FILTER_RELATIONS.AND
                      : '',
                  type: value
                });
              }}
              mandatory
              disabled={communityId !== 'new'}
            />
          </div>
          {communityValues && communityValues.type ? (
            <TypeExplanation
              communityType={communityValues.type}
              COMMUNITY_TYPES={COMMUNITY_TYPES}
            />
          ) : null}
          {communityValues &&
          communityValues.type &&
          communityValues.type === COMMUNITY_TYPES.DYNAMIC ? (
            <div>
              <div className={styles.sectionTitle}>
                Dynamically include respondents with specific user properties or
                profiling tags
                <span className={styles.requiredAsterisk}>*</span>
              </div>
              <Filters
                appliedFilters={
                  (communityValues && communityValues.appliedFilters) || []
                }
                setAppliedFilters={filters => {
                  setCommunityValues(prevValues => ({
                    ...prevValues,
                    appliedFilters: filters
                  }));
                }}
                filterRelation={
                  (communityValues && communityValues.filterRelation) ||
                  FILTER_RELATIONS.AND
                }
                setFilterRelation={relation => {
                  setCommunityValues(prevValues => ({
                    ...prevValues,
                    filterRelation: relation
                  }));
                }}
                isGlobalLoading={false}
                isFeasibilityChecker={false}
                minimumQuotaEnabled={false}
                trackMixpanelEvent={false}
                customHideFilterTypes={[
                  COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY
                ]}
                customFiltersButtonLabel="USER PROPERTIES & PROFILING TAGS"
              />
            </div>
          ) : null}
          {errors && errors.length ? (
            <div className={styles.errorsContainer}>
              {errors.map(e => (
                <div className={styles.error}>{e.message}</div>
              ))}
            </div>
          ) : null}
          {communityId !== 'new' ? (
            <Onboarding onboardingSurveys={onboardingSurveys} />
          ) : null}
          {communityId !== 'new' ? (
            <div>
              <div className={styles.sectionTitle}>Community members</div>
              <CommunityUsersList
                totalCount={totalCount}
                users={communityUsers.filter(u => !removedUsers.includes(u.id))}
                recordsPerPage={20}
                filtering={filtering}
                setFiltering={setFiltering}
                getLoadData={getLoadData}
                setRemovedUsers={
                  !(
                    communityValues &&
                    communityValues.type &&
                    communityValues.type === COMMUNITY_TYPES.DYNAMIC
                  )
                    ? setRemovedUsers
                    : null
                }
              />
            </div>
          ) : null}
          <div className={styles.createUpdatedButtonContainer}>
            <Button
              role="button"
              label={
                communityId === 'new' ? 'Create community' : 'Update community'
              }
              onClick={onSaveClick}
              disabled={!isAllowedToSaveCommunity()}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
