import React from 'react';

import finishIcon from '../../../../../../../../../../../../../assets/finish-icon.svg';

import styles from './IncidenceRateSelectors.module.css';
import SearchDropdown from './components/SearchDropdown/SearchDropdown';
import {
  INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS,
  QUESTION_TYPES,
  SURVEY_END_TYPES
} from '../../../../../../../../../../helpers/constants';

export default ({ actions, question }) => {
  const shouldShowQuotaRepresentative =
    question &&
    question.profiling &&
    question.profiling.isProfilingQuestion &&
    question.choices &&
    question.choices.some(
      c => c.end === SURVEY_END_TYPES.NOT_ELIGIBLE || c.quotaType
    );

  const shouldShowEstimatedIncidenceMCQuestionRate =
    question &&
    question.type === QUESTION_TYPES.MULTIPLE_CHOICE &&
    (!question.profiling || !question.profiling.isProfilingQuestion) &&
    ((question.choices &&
      question.choices.some(
        c => c.end === SURVEY_END_TYPES.NOT_ELIGIBLE || c.quotaType
      )) ||
      (question.end && question.end === SURVEY_END_TYPES.NOT_ELIGIBLE));
  const shouldShowEstimatedIncidenceSliderQuestionRate =
    question &&
    question.type === QUESTION_TYPES.RATING_SCALE &&
    (!question.profiling || !question.profiling.isProfilingQuestion) &&
    question.branches &&
    question.branches.some(c => c.end === SURVEY_END_TYPES.NOT_ELIGIBLE);

  const shouldShowEstimatedIncidenceQuestionRate =
    shouldShowEstimatedIncidenceMCQuestionRate ||
    shouldShowEstimatedIncidenceSliderQuestionRate;

  const getProfilingCorrectionOptions = () => [
    {
      label: 'Yes',
      value: false
    },
    {
      label: 'No',
      value: true
    }
  ];

  const getMCEstimatedIncidenceRates = () => [
    {
      label: 'Low (<30%)',
      value: INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS.LOW
    },
    {
      label: 'Mid (30% - 70%)',
      value: INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS.MID
    },
    {
      label: 'High (>70%)',
      value: INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS.HIGH
    }
  ];

  const onIsRepresentativeShareChange = value => {
    actions.updateQuestionProperty('incidenceRate', {
      ...question.incidenceRate,
      profilingQuestionCorrectionApplied: value
    });
  };

  const onMCEstimatedIncidenceRateChange = value => {
    actions.updateQuestionProperty('incidenceRate', {
      ...question.incidenceRate,
      dropoutValueEstimation: value
    });
  };

  const profilingCorrectionValue = getProfilingCorrectionOptions().find(
    pO =>
      pO &&
      question &&
      question.incidenceRate &&
      pO.value === question.incidenceRate.profilingQuestionCorrectionApplied
  );

  const mcEstimatedIncidenceRateChangeValue = getMCEstimatedIncidenceRates().find(
    pO =>
      pO &&
      question &&
      question.incidenceRate &&
      pO.value === question.incidenceRate.dropoutValueEstimation
  );

  if (shouldShowQuotaRepresentative) {
    return (
      <div className={styles.mcContainer}>
        <span className={styles.iconContainer}>#</span>
        Is quota representative of market share and distribution in society?
        <div>
          <SearchDropdown
            fetchOptions={getProfilingCorrectionOptions}
            placeholder="Please select a value"
            onSelectCompleted={value => onIsRepresentativeShareChange(value)}
            value={{
              value:
                question &&
                question.incidenceRate &&
                question.incidenceRate.profilingQuestionCorrectionApplied,
              label: profilingCorrectionValue && profilingCorrectionValue.label
            }}
            search={false}
            hideNoValueOption
            filterDropdownStyle={styles.filterDropdownSelectorContainer}
          />
        </div>
      </div>
    );
  }

  if (shouldShowEstimatedIncidenceQuestionRate) {
    return (
      <div
        className={
          shouldShowEstimatedIncidenceMCQuestionRate
            ? styles.mcContainer
            : styles.sliderContainer
        }
      >
        <span className={styles.iconContainer}>
          <img src={finishIcon} alt="Finish icon" />
        </span>
        Estimated incidence rate for this question
        <div>
          <SearchDropdown
            fetchOptions={getMCEstimatedIncidenceRates}
            placeholder="Please select a value"
            onSelectCompleted={value => onMCEstimatedIncidenceRateChange(value)}
            value={{
              value:
                question &&
                question.incidenceRate &&
                question.incidenceRate.dropoutValueEstimation,
              label:
                mcEstimatedIncidenceRateChangeValue &&
                mcEstimatedIncidenceRateChangeValue.label
            }}
            search={false}
            hideNoValueOption
            filterDropdownStyle={
              shouldShowQuotaRepresentative
                ? styles.filterDropdownSelectorContainer
                : styles.filterDropdownSelectorContainerWide
            }
          />
        </div>
      </div>
    );
  }

  return null;
};
