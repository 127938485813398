import React, { useState } from 'react';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';

import styles from '../Properties/Properties.module.css';

const WebSurveySettings = props => {
  const { survey, isAdmin, onUpdate } = props;

  const [expanded, setExpanded] = useState(false);

  const properties = [
    {
      label: 'Only web survey (survey not published in mobile app)',
      type: 'survey-property-change',
      name: 'only_web_version',
      graphName: 'onlyWebVersion',
      value: false,
      adminOnly: false,
      visible: true
    },
    {
      label: 'Ask for socio demo (age, gender and postal code)',
      type: 'survey-property-change',
      name: 'ask_for_socio_demo_web_survey',
      graphName: 'askForSocioDemoWebSurvey',
      value: true,
      adminOnly: false,
      visible: true
    },
    {
      label: 'Hide postal code (required for surveys outside of Belgium)',
      type: 'survey-property-change',
      name: 'hidePostalCodeInWebSurvey',
      graphName: 'hidePostalCodeInWebSurvey',
      value: false,
      adminOnly: false,
      visible: survey && survey.askForSocioDemoWebSurvey
    },
    {
      label: 'Custom branding',
      type: 'survey-property-change',
      name: 'customBranding',
      graphName: 'customBranding',
      value: false,
      adminOnly: false,
      visible: true
    },
    {
      label: 'Adapt welcome page for external panel',
      type: 'survey-property-change',
      name: 'adaptConsentForExternalInWebSurvey',
      graphName: 'adaptConsentForExternalInWebSurvey',
      value: false,
      adminOnly: true,
      visible: true
    }
  ];

  const getActiveBlockProperty = property => {
    if (
      (!property.adminOnly || (property.adminOnly && isAdmin)) &&
      property.visible
    ) {
      return (
        <React.Fragment key={property.name}>
          <div
            className={`${styles.singleProperty} ${
              property.name === 'askForEmailAddressInWebSurvey' &&
              survey.askForEmailAddressInWebSurvey
                ? styles.askForEmailAddressInWebSurveyProperty
                : ''
            }`}
          >
            <span>{property.label}</span>

            {property.type === 'survey-property-change' ? (
              /* eslint-disable */
              <span role="presentation" className={styles.switchContainer}>
                <label className={styles.switch} htmlFor="checkbox">
                  <input
                    type="checkbox"
                    checked={survey[property.graphName]}
                    readOnly
                  />
                  <span
                    className={`slider round survey-property-${property.name}`}
                    role="presentation"
                    onClick={e => {
                      property.value = !survey[property.graphName];
                      survey[property.name] = property.value;
                      onUpdate(property.graphName, property.value);
                      e.stopPropagation();
                    }}
                  />
                </label>
              </span>
            ) : /* eslint-enable */
            null}
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <div className={styles.container} role="presentation">
      <div
        className={styles.titleContainer}
        role="presentation"
        onClick={() => setExpanded(() => setExpanded(!expanded))}
      >
        <div className={styles.title}>Web Survey Settings</div>
        <img
          src={arrowDownPurple}
          alt="ExpandColapse"
          className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
        />
      </div>
      {expanded ? (
        <div className={styles.contentContainer}>
          <div>
            <div className={styles.propertiesContentContainer}>
              {properties.map((property, index) =>
                getActiveBlockProperty(property, index)
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WebSurveySettings;
