import React from 'react';
import logo from '../../../../assets/img/buffl.svg';
import styles from '../RegistrationPage.module.css';

const AppBar = props => {
  const { fields, setFieldValue } = props;

  return (
    <div className={styles.appBar}>
      <img className={styles.logo} src={logo} alt="BUFFL logo" />
      <div className={styles.languageOption}>
        <span
          role="presentation"
          onClick={() => setFieldValue('language', 'NL')}
          className={`${
            fields.language === 'NL' ? styles.languageActive : ''
          } ${styles.languageOption}`}
        >
          NL
        </span>
        <span
          role="presentation"
          onClick={() => setFieldValue('language', 'FR')}
          className={`${
            fields.language === 'FR' ? styles.languageActive : ''
          } ${styles.languageOption}`}
        >
          FR
        </span>
      </div>
    </div>
  );
};

export default AppBar;
