import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_SURVEY_PUBLISH_PAGE } from '../../../../graphql/Survey';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';
import ResultPopup from './components/ResultPopup/ResultPopup';
import AdminView from './AdminView';
import ClientView from './ClientView';
import SurveyDetails from './components/SurveyDetails/SurveyDetails';

import SurveyBuilderPageContext from '../SurveyBuilderPageContext';

import validateSurvey from './validateSurvey';

import * as mixpanel from '../../../../../mixpanel';

import amWarning from '../../../../assets/img/accountmanagement/am-warning.svg';
import styles from './PublishSurvey.module.css';

export const stepTypes = {
  PUBLISH_SURVEY: 'PUBLISH_SURVEY',
  UNPUBLISH_SURVEY: 'UNPUBLISH_SURVEY',
  REQUEST_APPROVAL: 'REQUEST_APPROVAL',
  REQUEST_APPROVAL_CANCEL: 'REQUEST_APPROVAL_CANCEL',
  INFO: 'INFO'
};

export const moments = {
  PAST: 'PAST',
  NOW: 'NOW',
  FUTURE: 'FUTURE',
  POTENTIAL: 'POTENTIAL'
};

export const getLastEvent = arr =>
  arr
    ? [...arr]
        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
        .pop()
    : null;

export const jumbotronLabels = {
  SCHEDULE:
    'Your schedule was successful. You can still make changes in the timeline below.',
  PUBLISH_SURVEY:
    'Your survey has been published. If you want to make any changes now, you will first have to unpublish it.',
  UNPUBLISH_SURVEY:
    'Your survey has been unpublished. You can now edit your survey',
  REQUEST_APPROVAL:
    'Your survey request is awaiting approval. You will be notified when it has been approved.'
};

export default ({ surveyId }) => {
  const [errors, setErrors] = useState(null);
  const [showResultPopup, setShowResultPopup] = useState(false);
  const [requestApprovalError, setRequestApprovalError] = useState(null);

  const { api, isAdmin } = useContext(SurveyBuilderPageContext);

  const {
    data: { survey: { survey } = {} } = {},
    loading: initialSurveyLoading,
    refetch: surveyRefetch
  } = useQuery(GET_SURVEY_PUBLISH_PAGE, {
    variables: {
      id: surveyId,
      includeTotalIncidenceRate: true,
      includeLongestQuestionsPathCount: true
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(
    () => {
      if (survey) {
        const allErrors = validateSurvey(survey);
        const errorGroups = {};
        if (allErrors) {
          allErrors.forEach(e => {
            if (errorGroups[e.type]) {
              errorGroups[e.type].push(e.message);
            } else {
              errorGroups[e.type] = [e.message];
            }
          });
        }
        setErrors(errorGroups);
      } else setErrors([]);
    },
    [survey]
  );

  if (!survey) return null;

  if (initialSurveyLoading) {
    return <Loader />;
  }

  const RequestApprovalErrorComponent = () => (
    <div
      className="campaigns-table-publish-error-container"
      role="presentation"
      onClick={() => {
        setRequestApprovalError(false);
      }}
    >
      <div>
        <img src={amWarning} alt="Warning icon" />
      </div>
      Survey not published. Unfortunately, it appears that your license has
      expired or your account does not have enough budget to complete this
      action.
    </div>
  );

  const updateConfirmationStatus = async (property, value) => {
    await api('updateSurvey', {
      variables: {
        survey: survey.id,
        [property]: value
      }
    });
    await surveyRefetch();
  };

  const adminNeedsToConfirmDetails =
    isAdmin && survey && survey.approvalRequestSent;

  const mapSurveyErrors = () =>
    Object.keys(errors).map((e, eIndex) => (
      <div
        key={`publish-survey-error-container-${eIndex.toString()}`}
        className={styles.errorContainer}
      >
        <div className={styles.title}>{e}</div>
        <div className={styles.errors}>
          {errors[e].map((em, emIndex) => (
            <div
              key={`publish-survey-single-error-${emIndex.toString()}`}
              className={styles.message}
            >
              <span>{em}</span>
            </div>
          ))}
        </div>
      </div>
    ));

  const hasSurveyErrorsExcludingConfirmation =
    errors &&
    Object.entries(errors) &&
    Object.entries(errors).length &&
    Object.entries(errors).filter(
      ([errorKey, errorValue]) =>
        !(
          errorKey === 'Publish survey' &&
          errorValue.includes('Confirm survey details')
        )
    ).length > 0;

  return (
    <div className={styles.container}>
      {showResultPopup ? (
        <Popup
          component={
            <ResultPopup
              title="Approval has been requested"
              description="You will be notified when your request has been approved."
            />
          }
          onClose={() => setShowResultPopup(false)}
        />
      ) : null}
      <div>
        {survey.status === 'incomplete' && (
          <div>
            <div className={styles.surveyInformationContainer}>
              <div className={styles.surveyInformation}>
                <div className={styles.titleContainer}>
                  <div className={styles.title}>Survey data check</div>
                  <div className={styles.errorMessage}>Errors found</div>
                </div>
                <div className={styles.errorsContainer}>
                  {errors ? mapSurveyErrors() : null}
                </div>
              </div>
              <div className={styles.surveyInformation}>
                <div className={styles.titleContainer}>
                  <div className={styles.title}>Survey details</div>
                </div>
                <SurveyDetails
                  survey={survey}
                  updateConfirmationStatus={updateConfirmationStatus}
                  allowedToChangeConfirmSurveyDetails={
                    !hasSurveyErrorsExcludingConfirmation
                  }
                  adminNeedsToConfirmDetails={adminNeedsToConfirmDetails}
                />
              </div>
            </div>
            <div className={styles.disabledPublishContainer}>
              <div className={styles.description}>
                You need to fix all the errors before you can publish your
                survey.
              </div>
              <div className={styles.buttonContainer}>
                <div className={`${styles.button} ${styles.publishButton}`}>
                  Publish this survey now
                </div>
              </div>
            </div>
          </div>
        )}

        {['testing', 'awaiting publish', 'published'].indexOf(survey.status) >
          -1 && (
          <div className={styles.surveyInformationContainer}>
            {adminNeedsToConfirmDetails &&
            errors &&
            Object.keys(errors) &&
            Object.keys(errors).length ? (
              <div className={styles.surveyInformation}>
                <div className={styles.titleContainer}>
                  <div className={styles.title}>Survey data check</div>
                  <div className={styles.errorMessage}>Errors found</div>
                </div>
                <div className={styles.errorsContainer}>
                  {errors ? mapSurveyErrors() : null}
                </div>
              </div>
            ) : (
              <div className={styles.surveyInformation}>
                <div className={styles.titleContainer}>
                  <div className={styles.title}>Survey data check</div>
                </div>
                <div className={styles.description}>
                  You have no missing data.
                  <br />
                  Your survey is ready to be published.
                </div>
              </div>
            )}
            <div className={styles.surveyInformation}>
              <div className={styles.titleContainer}>
                <div className={styles.title}>Survey details</div>
              </div>
              <SurveyDetails
                survey={survey}
                updateConfirmationStatus={updateConfirmationStatus}
                allowedToChangeConfirmSurveyDetails={
                  isAdmin || survey.status === 'testing'
                }
                adminNeedsToConfirmDetails={adminNeedsToConfirmDetails}
              />
            </div>
          </div>
        )}

        {survey.status === 'testing' && !survey.approvalRequestSent && (
          <div className={styles.testingContainer}>
            <div className={styles.description}>
              You need to request approval from an admin to publish your survey.
              <br /> Once the request has been approved, your survey will
              automatically be published
            </div>
            <div className={styles.buttonContainer}>
              <div
                className={`${styles.button} ${styles.publishButton}`}
                role="presentation"
                onClick={async () => {
                  //   setShowSurveyPublishedPopup(true);
                  const res = await api(
                    'requestApproval',
                    {
                      variables: {
                        survey: survey.id,
                        status: true
                      }
                    },
                    600,
                    true
                  );

                  if (
                    res &&
                    res.data &&
                    res.data.requestApproval &&
                    res.data.requestApproval.errors &&
                    res.data.requestApproval.errors[0] &&
                    res.data.requestApproval.errors[0].message
                  ) {
                    setRequestApprovalError(
                      res.data.requestApproval.errors[0].message
                    );
                    return;
                  }

                  setShowResultPopup(true);
                  surveyRefetch();
                  mixpanel.actions.trackEvent(
                    mixpanel.events.CLIENT_REQUEST_APPROVAL,
                    {
                      [mixpanel.eventProperties.SURVEY_ID]: survey.id
                    }
                  );
                }}
              >
                Request approval
              </div>
            </div>
          </div>
        )}

        {['testing', 'awaiting publish', 'published'].indexOf(survey.status) >
          -1 &&
        (survey.approvalRequestSent || survey.paid) &&
        isAdmin ? (
          <AdminView
            survey={survey}
            getSurvey={surveyRefetch}
            setShowResultPopup={setShowResultPopup}
            surveyRefetch={surveyRefetch}
          />
        ) : (
          <ClientView survey={survey} getSurvey={surveyRefetch} />
        )}

        {requestApprovalError && (
          <Popup
            key="error"
            component={<RequestApprovalErrorComponent />}
            onClose={() => {
              setRequestApprovalError(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
