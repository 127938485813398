import { COMMUNITY_DASHBOARD_FILTER_TYPE } from './constants';

const compareArrays = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((element, index) => element === b[index]);

export default (innerAppliedFilters, appliedFilters) => {
  if (innerAppliedFilters.length !== appliedFilters.length) {
    return true;
  }

  let filterDifferences = false;

  for (let i = 0; i < innerAppliedFilters.length; i += 1) {
    const iAF = innerAppliedFilters[i];

    if (iAF.type !== appliedFilters[i].type) {
      filterDifferences = true;
      break;
    }

    if (iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG) {
      if (!iAF.tags || !appliedFilters[i].tags) {
        filterDifferences = true;
        break;
      }

      if (iAF.tags.category !== appliedFilters[i].tags.category) {
        filterDifferences = true;
        break;
      }

      if (iAF.tags.tagName !== appliedFilters[i].tags.tagName) {
        filterDifferences = true;
        break;
      }

      if (!compareArrays(iAF.tags.tagValue, appliedFilters[i].tags.tagValue)) {
        filterDifferences = true;
        break;
      }
    }

    if (iAF.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY) {
      if (!iAF.userProperties || !appliedFilters[i].userProperties) {
        filterDifferences = true;
        break;
      }

      if (
        iAF.userProperties.propertyName !==
        appliedFilters[i].userProperties.propertyName
      ) {
        filterDifferences = true;
        break;
      }

      if (
        !compareArrays(
          iAF.userProperties.propertyValue,
          appliedFilters[i].userProperties.propertyValue
        )
      ) {
        filterDifferences = true;
        break;
      }
    }
  }

  return filterDifferences;
};
