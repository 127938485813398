export const SET_RESULT = 'SET_RESULT';
export const SET_BLOCK = 'SET_BLOCK';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_CAMPAIGN_COMPONENTS = 'SET_CAMPAIGN_COMPONENTS';
export const RESET_TO_INIT_STATE = 'RESET_TO_INIT_STATE';
export const SET_USER_EMAIL_SUGGESTION = 'SET_USER_EMAIL_SUGGESTION';

export function setCampaign(campaign) {
  return { type: SET_CAMPAIGN, campaign };
}
export function setResult(result) {
  return { type: SET_RESULT, result };
}
export function setBlock(block) {
  return { type: SET_BLOCK, block };
}
export function setCampaignComponents(campaign, block, result) {
  return { type: SET_CAMPAIGN_COMPONENTS, campaign, block, result };
}
export function resetToInitState() {
  return {
    type: RESET_TO_INIT_STATE
  };
}
export function setUserEmailSuggestion(userEmailSuggestion) {
  return { type: SET_USER_EMAIL_SUGGESTION, userEmailSuggestion };
}
