// eslint-disable-next-line
import React, { useEffect, forwardRef } from 'react';
/* global document, window */

export default ({
  children,
  childReference,
  positionStyle,
  handlePosition = true,
  footerHeight = 90,
  onBlur = null
}) => {
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const nodeElement = childReference;
  if (handlePosition && nodeElement) {
    const height = nodeElement.offsetHeight;

    if (positionStyle.top || positionStyle.top === 0) {
      nodeElement.style.top = positionStyle.top;
    }

    if (positionStyle.left || positionStyle.left === 0) {
      nodeElement.style.left = positionStyle.left;
    }

    const rect = nodeElement.getBoundingClientRect();
    const win = nodeElement.ownerDocument.defaultView;
    const viewpointHeight = window.innerHeight;

    const absolutePosition = {
      top: rect.top + win.pageYOffset - window.scrollY,
      left: rect.left + win.pageXOffset
    };

    if (
      absolutePosition &&
      absolutePosition.top &&
      absolutePosition.left &&
      height
    ) {
      if (absolutePosition.top + height + footerHeight > viewpointHeight) {
        nodeElement.style.top = 'auto';

        if (positionStyle && positionStyle.bottomPosition) {
          nodeElement.style.bottom = positionStyle.bottomPosition;
        } else {
          nodeElement.style.bottom = '10px';
        }

        if (height - absolutePosition.top > 0) {
          nodeElement.style.height = `${absolutePosition.top - 20}px`;
          nodeElement.style.overflowY = 'auto';
        }
      }
    }
  }

  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = event => {
    if (nodeElement && !nodeElement.contains(event.target) && onBlur) {
      onBlur();
    }
  };

  return <>{children}</>;
};
