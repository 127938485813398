import React, { useEffect } from 'react';
import styles from '../CampaignQuestionsPage.module.css';

export default props => {
  const {
    setValid,
    block,
    blockId,
    appendAdditionalDataToIframe,
    result
  } = props;

  useEffect(
    () => {
      setValid(true);
    },
    [block]
  );

  let src = block.source;

  if (appendAdditionalDataToIframe) {
    if (src.includes('?')) {
      src = `${src}&result=${result.id}`;
    } else {
      src = `${src}?result=${result.id}`;
    }

    src = `${src}&question=${block.id}`;

    if (result && result.userData) {
      if (result.userData.language) {
        src = `${src}&language=${result.userData.language}`;
      }
      if (result.userData.age) {
        src = `${src}&age=${result.userData.age}`;
      }
      if (result.userData.gender) {
        src = `${src}&gender=${result.userData.gender}`;
      }
    }
  }

  return (
    <iframe
      className={styles.iframeContainer}
      title="Question"
      src={src}
      key={blockId}
      allow="microphone"
    />
  );
};
