import gql from 'graphql-tag';

const BLOCK = `
  id
  type

  question
  mediaUrl

  source
  key
  autocomplete
  enableNextAfterSeconds
  appendAdditionalDataToIframe
  confirmResultWithApi

  answers {
    id
    answer
    imageUrl
    lockedPosition
    isTagged
    decisionFrameworkType
  }
  random
  ranked
  allowOther
  allowNoneOfTheAbove
  allowSkip

  selectAtLeast
  selectAtMost

  answerMinCharacters
  answerMaxCharacters

  minValue
  maxValue

  isProfilingQuestion
  showProfilingConsent

  matrix {
    decisionFrameworkType
    xQuestion
    yQuestion
    xQuestionId
    yQuestionId
  }
`;

export const GET_CAMPAIGN = gql`
  query campaign($id: String!, $referrer: String!) {
    campaign(input: { id: $id, referrer: $referrer }) {
      id
      name
      description
      language
      contactForPrizes
      contactForNextSurvey
      addToMailingListOfCustomer
      askToRegisterOnBufflWebSurvey
      redirectionsWebSurvey {
        campaignEndSuccess
        campaignEndFailed
        campaignQuotaFull
      }
      webVersionEmailConfirmation
      published
      status
      image
      askForSocioDemoWebSurvey
      duration
      hideDownloadBufflInWebSurvey
      hideLogoInWebSurvey
      hideIllustrationsInWebSurvey
      enableCustomCopyInWebSurvey
      hidePostalCodeInWebSurvey
      askForEmailAddressInWebSurvey
      adaptConsentForExternalInWebSurvey
      customCopies {
        active
        name
        translations {
          KEY
          EN
          NL
          FR
          DE
        }
      }
      customBranding
      customThemeColors {
        name
        colorCode
      }
      customThemeImages {
        name
        imageFilename
      }
      client
      collaborators {
        id
      }
      communitySettings {
        communityType
        seeGiveawayMessage
        thankYouScreenConversionMessage
      }
    }
  }
`;

export const START_WEB_SURVEY = gql`
  mutation StartWebSurvey(
    $survey: String!
    $referrer: String
    $userData: WebSurveyUserDataInput!
    $testing: Boolean
    $customClientIdentifier: String
    $customClientName: String
    $userToken: String
  ) {
    startWebSurvey(
      input: {
        survey: $survey
        referrer: $referrer
        userData: $userData
        testing: $testing
        customClientIdentifier: $customClientIdentifier
        customClientName: $customClientName
        userToken: $userToken
      }
    ) {
      nextBlock {
        ${BLOCK}
      }
      failure
      errors {
        field
        message
      }
      percentageCompleted
      reward
      result {
        id
        userData {
          language
          age
          gender
        }
      }
      user {
        id
      }
      shareId
      shareReward
      confirmationRequired
    }
  }
`;

export const FINALISE_WEB_SURVEY = gql`
  mutation finalizeWebSurvey(
    $result: String!
    $email: String
    $createProfile: Boolean
    $password: String
    $contactWin: Boolean
    $contactNextSurveys: Boolean
    $addToMailingListOfCustomer: Boolean
    $testing: Boolean
    $language: String
  ) {
    finalizeWebSurvey(
      input: {
        result: $result
        email: $email
        createProfile: $createProfile
        password: $password
        contactWin: $contactWin
        contactNextSurveys: $contactNextSurveys
        addToMailingListOfCustomer: $addToMailingListOfCustomer
        testing: $testing
        language: $language
      }
    ) {
      user {
        email
      }
    }
  }
`;

export const GET_RESULT = gql`
  query result($id: String!) {
    result(input: { id: $id }) {
      nextBlock {
        ${BLOCK}
      }
      result {
        id
        blockResults {
          block {
            ${BLOCK}
          }
          answerString
          answer
          answers
          mediaUrl
          finishedAt
          matrixAnswers {
            questionId
            choices {
              choiceId
              answers
            }
          }
        }
        survey {
          id
          name
          description
          language
          contactForPrizes
          contactForNextSurvey
          addToMailingListOfCustomer
          askToRegisterOnBufflWebSurvey
          redirectionsWebSurvey {
            campaignEndSuccess
            campaignEndFailed
            campaignQuotaFull
          }
          webVersionEmailConfirmation
          published
          status
          image
          askForSocioDemoWebSurvey
          hideDownloadBufflInWebSurvey
          hideIllustrationsInWebSurvey
          hideLogoInWebSurvey
          enableCustomCopyInWebSurvey
          hidePostalCodeInWebSurvey
          askForEmailAddressInWebSurvey
          adaptConsentForExternalInWebSurvey
          customCopies {
            active
            name
            translations {
              KEY
              EN
              NL
              FR
              DE
            }
          }
          customBranding
          customThemeColors {
            name
            colorCode
          }
          customThemeImages {
            name
            imageFilename
          }
          client
          collaborators {
            id
          }
          communitySettings {
            communityType
            seeGiveawayMessage
            thankYouScreenConversionMessage
          }
        }
        webSurveyFinalized
        userData {
          gender
          age
          language
        }
        assignUserToCommunity
        user {
          language
        }
      }
      failure
      percentageCompleted
      reward
      shareId
      shareReward
      confirmationRequired
      proflingTagsToConfirm
    }
  }
`;

export const RESULT = `
  nextBlock {
    ${BLOCK}
  }
  failure
  errors {
    field
    message
  }
  result {
    id
    blockResults {
      block {
        ${BLOCK}
      }
      answerString
      answer
      answers
      mediaUrl
      finishedAt
      matrixAnswers {
        questionId
        choices {
          choiceId
          answers
        }
      }
    }
    userData {
      language
      age
      gender
    }
    assignUserToCommunity
    user {
      language
    }
  }
  percentageCompleted
  reward
  shareId
  shareReward
  proflingTagsToConfirm
`;

export const SUBMIT_IFRAME_BLOCK = gql`
  mutation SubmitIFrameBlock(
    $id: String!
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $result: String
    $referrer: String
    $testing: Boolean
  ) {
    submitIFrameBlock(
      input: { id: $id, startedAt: $startedAt, finishedAt: $finishedAt, referrer: $referrer, result: $result, testing: $testing }
    ) {
      ${RESULT}
    }
  }
`;
export const SUBMIT_MO_BLOCK = gql`
  mutation SubmitMultipleOptionsBlock(
    $id: String!
    $referrer: String
    $answers: [Int!]!
    $otherAnswer: String
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $result: String
    $testing: Boolean
  ) {
    submitMultipleOptionsBlock(
      input: { id: $id, answers: $answers, otherAnswer: $otherAnswer, referrer: $referrer, startedAt: $startedAt, result: $result,
        testing: $testing, finishedAt: $finishedAt }
    ) {
      ${RESULT}
    }
  }
`;
export const SUBMIT_MC_QUESTION = gql`
  mutation SubmitMultipleChoiceQuestion(
    $id: String!
    $answers: [Int!]!
    $otherAnswer: String
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $result: String
    $referrer: String
    $testing: Boolean
    $profilingConsent: ProfilingQuestionConsents
  ) {
    submitMultipleChoiceQuestion(
      input: { id: $id, answers: $answers, otherAnswer: $otherAnswer, referrer: $referrer, startedAt: $startedAt, result: $result,
        testing: $testing, finishedAt: $finishedAt, profilingConsent: $profilingConsent }
    ) {
      ${RESULT}
    }
  }
`;
export const SUBMIT_OPEN_QUESTION_BLOCK = gql`
  mutation SubmitOpenQuestionBlock(
    $id: String!
    $referrer: String
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $result: String
    $testing: Boolean
    $answerString: String!
  ) {
    submitOpenQuestionBlock(
      input: { id: $id, referrer: $referrer, startedAt: $startedAt, result: $result, testing: $testing,
        finishedAt: $finishedAt, answerString: $answerString }
    ) {
      ${RESULT}
    }
  }
`;
export const SUBMIT_SLIDER_BLOCK = gql`
  mutation SubmitSliderBlock(
    $id: String!
    $referrer: String
    $answer: Int
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $result: String
    $testing: Boolean
  ) {
    submitSliderBlock(
      input: { id: $id, answer: $answer, referrer: $referrer, startedAt: $startedAt, result: $result,
        testing: $testing, finishedAt: $finishedAt }
    ) {
      ${RESULT}
    }
  }
`;
export const SUBMIT_VIDEO_BLOCK = gql`
  mutation SubmitVideoBlock(
    $id: String!
    $referrer: String
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $result: String
    $testing: Boolean
  ) {
    submitVideoBlock(
      input: { id: $id, referrer: $referrer, startedAt: $startedAt, result: $result, testing: $testing,
        finishedAt: $finishedAt }
    ) {
      ${RESULT}
    }
  }
`;

export const SUBMIT_MESSAGE_BLOCK = gql`
  mutation submitMessageBlock(
    $id: String!
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $result: String
    $testing: Boolean
  ) {
    submitMessageBlock(
      input: { id: $id, startedAt: $startedAt, result: $result, testing: $testing, finishedAt: $finishedAt }
    ) {
      ${RESULT}
    }
  }
`;

export const SUBMIT_MATRIX_BLOCK = gql`
  mutation submitMatrixBlock(
    $id: String!
    $result: String
    $matrixAnswers: [MatrixAnswerInput!]!
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $testing: Boolean
  ) {
    submitMatrixBlock(
      input: { id: $id, result: $result, matrixAnswers: $matrixAnswers, startedAt: $startedAt, finishedAt: $finishedAt, testing: $testing }
    ) {
      ${RESULT}
    }
  }
`;

export const CONFIRM_RESULT = gql`
  mutation ConfirmWebSurvey(
    $result: String!
    $confirmationToken: String!
    $language: String
  ) {
    confirmWebSurvey(
      input: {
        result: $result
        confirmationToken: $confirmationToken
        language: $language
      }
    ) {
      survey {
        id
        hideDownloadBufflInWebSurvey
      }
    }
  }
`;

export const FIND_AND_REWARD_EMAIL_CONVERSION_USER = gql`
  mutation FindAndRewardEmailConversionUser($result: ID!, $email: String!) {
    findAndRewardEmailConversionUser(
      input: { result: $result, email: $email }
    ) {
      user {
        id
        email
        pendingActivation
      }
      earnedTokens
      participatedSurveys
      errors {
        type
        message
      }
    }
  }
`;

export const SUBMIT_EMAIL_CONVERSION = gql`
  mutation SubmitEmailConversion(
    $result: ID!
    $email: String!
    $language: String
  ) {
    submitEmailConversion(
      input: { result: $result, email: $email, language: $language }
    ) {
      user {
        id
        email
        pendingActivation
      }
      earnedTokens
      participatedSurveys
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_CUSTOM_COPIES = gql`
  mutation UpdateCustomCopies(
    $survey: ID!
    $client: ID!
    $active: Boolean
    $name: String!
    $translation: CustomCopyTranslationInput!
    $language: String!
    $testing: Boolean!
  ) {
    updateCustomCopies(
      input: {
        survey: $survey
        client: $client
        active: $active
        name: $name
        translation: $translation
        language: $language
        testing: $testing
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const POLL_RESULT_IS_CONFIRMED = gql`
  query PollResultIsConfirmed($result: ID!, $question: ID!) {
    pollResultIsConfirmed(input: { result: $result, question: $question }) {
      done
      errors {
        message
        field
        language
      }
    }
  }
`;

export const SUBMIT_PROFILING_CONSENT = gql`
  mutation SubmitProfilingConsent(
    $result: ID!
    $profilingConsent: ProfilingQuestionConsents
  ) {
    submitProfilingConsent(
      input: { result: $result, profilingConsent: $profilingConsent }
    ) {
      done
      errors {
        field
        message
        language
      }
    }
  }
`;

export const GET_USER_DATA_FROM_USER_TOKEN = gql`
  query GetUserDataFromUserToken($userToken: String!) {
    getUserDataFromUserToken(input: { userToken: $userToken }) {
      email
      nickname
    }
  }
`;
