const getRemoveGroupFlowIds = (flows, groupdId, groups) => {
  let questionIds = [];
  const removeInnerGroupIds = [];

  flows.forEach(flow => {
    if (groups.some(g => g.id === flow)) {
      const innerGroup = groups.find(g => g.id === flow);
      if (innerGroup) {
        const innerGroupIds = getRemoveGroupFlowIds(
          innerGroup.flows,
          innerGroup.id,
          groups
        );
        removeInnerGroupIds.push(innerGroup.id);
        if (innerGroupIds.questionIds && innerGroupIds.questionIds.length) {
          questionIds = [...questionIds, ...innerGroupIds.questionIds];
        }
      }
      return;
    }

    questionIds.push(flow);
  });

  return { questionIds, groupIds: removeInnerGroupIds };
};

export default (groupId, c, q, preserveParent) => {
  const removeGroupIdFromSurvey = gId => {
    if (content.flows.indexOf(gId) > -1) {
      content.flows.splice(content.flows.indexOf(gId), 1);
    }

    content.groups.forEach(group => {
      if (group.flows.indexOf(gId) > -1) {
        group.flows.splice(group.flows.indexOf(gId), 1);
      }
    });
  };

  const survey = JSON.parse(JSON.stringify({ content: c, questions: q }));

  const { content, questions } = survey;
  const groupToRemove = content.groups.find(g => g.id === groupId);

  removeGroupIdFromSurvey(groupId);

  // List of deleted question flows
  const removedFlowIds = getRemoveGroupFlowIds(
    groupToRemove.flows,
    groupToRemove.id,
    content.groups
  );

  content.groups = content.groups.filter(group => group.id !== groupId);

  let removedMatrixQuestions = [];

  // Remove questions
  removedFlowIds.questionIds.forEach(questionId => {
    if (
      questions[questionId] &&
      questions[questionId].matrix &&
      questions[questionId].matrix.decisionFrameworkType
    ) {
      removedMatrixQuestions = [
        ...removedMatrixQuestions,
        questions[questionId]
      ];
    }
    delete questions[questionId];
  });

  if (
    removedFlowIds &&
    removedFlowIds.groupIds &&
    removedFlowIds.groupIds.length
  ) {
    content.groups = content.groups.filter(
      group => removedFlowIds.groupIds.indexOf(group.id) === -1
    );
  }

  // Check if parent group is empty after this child group is removed
  if (!preserveParent) {
    content.groups = content.groups.reduce((groups, g) => {
      if (g.flows.length) {
        return [...groups, g];
      }
      removedFlowIds.groupIds.push(g.id);
      removeGroupIdFromSurvey(g.id);

      return groups;
    }, []);
  }

  return {
    content,
    questions,
    removedQuestions: removedFlowIds.questionIds,
    additionalGroupsRemoved: removedFlowIds.groupIds,
    removedMatrixQuestions
  };
};
