import React from 'react';

import getBlockQuestion from '../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/getBlockQuestion';

import closeIcon from '../../../../assets/img/delete.svg';

import styles from './ProfileQuestionDetails.module.css';

export default ({ question, setQuestionDetailsPopup }) => (
  <div className={styles.container}>
    <div className={styles.question}>
      {question && question.question && getBlockQuestion(question.question)}
    </div>
    <table className={styles.tableContainer}>
      <thead>
        <tr className={styles.headerRow}>
          <th>Answer</th>
          <th width="15%">Category</th>
          <th width="15%">Tag name</th>
          <th width="15%">Tag value</th>
        </tr>
      </thead>
      <tbody>
        {question && question.choices && question.choices.length ? (
          question.choices.map((c, i) => (
            <tr
              className={styles.tableRow}
              key={`profile-question-answers-${i.toString()}`}
            >
              <td>{c.answer}</td>
              <td>
                {c.profilingTag && c.profilingTag.category
                  ? c.profilingTag.category
                  : '-'}
              </td>
              <td>
                {c.profilingTag && c.profilingTag.tagName
                  ? c.profilingTag.tagName
                  : '-'}
              </td>
              <td>
                {c.profilingTag && c.profilingTag.tagValue
                  ? c.profilingTag.tagValue
                  : '-'}
              </td>
            </tr>
          ))
        ) : (
          <tr className={styles.tableRow}>
            <td colSpan="4">No answer options available</td>
          </tr>
        )}
      </tbody>
    </table>
    <div className={styles.closeContainer}>
      <img
        className={styles.close}
        src={closeIcon}
        alt="Close icon"
        onClick={() => setQuestionDetailsPopup(null)}
        role="presentation"
      />
    </div>
  </div>
);
