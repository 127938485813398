import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported

const Camera = props => {
  const { results, block } = props;

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const getBlockQuestion = blockQuestion => {
    let question;
    try {
      question = [].concat(
        JSON.parse(blockQuestion)
          .blocks.map(draftBlock => draftBlock.text)
          .join(' ')
      );
    } catch (error) {
      question = blockQuestion;
    }
    return question;
  };

  /* eslint-disable */
  if (results && results.length) {
    return [
      results.map((result, index) => (
        <div
          className="camera-question-image-item"
          key={result._id}
          onClick={() => {
            setIsOpen(true);
            setPhotoIndex(index);
          }}
          role="presentation"
        >
          <img
            src={`/uploads/${result.media_filename}`}
            alt={result.media_filename}
          />
        </div>
      )),
      isOpen && (
        <Lightbox
          key={block.type}
          imageTitle={getBlockQuestion(block.question)}
          mainSrc={`/uploads/${block.results[photoIndex].media_filename}`}
          nextSrc={`/uploads/${
            block.results[photoIndex + 1]
              ? block.results[photoIndex + 1].media_filename
              : block.results[photoIndex].media_filename
          }`}
          prevSrc={`/uploads/${
            block.results[photoIndex - 1]
              ? block.results[photoIndex - 1].media_filename
              : block.results[photoIndex].media_filename
          }`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            photoIndex > 0 ? setPhotoIndex(photoIndex - 1) : setPhotoIndex(0);
          }}
          onMoveNextRequest={() => {
            photoIndex < block.results.length - 1
              ? setPhotoIndex(photoIndex + 1)
              : setPhotoIndex(photoIndex);
          }}
        />
      )
    ];
  }
  /* eslint-enable */

  if (!results || !results.length) {
    return <div className="no-chart-data">No data</div>;
  }
  return null;
};

export default Camera;
