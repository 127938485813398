import React from 'react';

import ShowInsideScreen from '../../../ShowInsideScreen/ShowInsideScreen';

import removeFilterGroup from '../../helpers/removeFilterGroup/removeFilterGroup';
import setDefaultFiltersForFilterGroup from '../../helpers/setDefaultFiltersForFilterGroup/setDefaultFiltersForFilterGroup';
import { DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS } from '../../../SurveyBuilder/helpers/constants';

import styles from '../../TargetAudience.module.css';

export default ({
  userFilter,
  filterGroup,
  advancedSettingsDropdownRef,
  setShowAdvancedSettingsDropdown,
  setAdvancedSettingsDropdownRef,
  setShowAdvancedSettingsPopup,
  onUserFilterChange,
  filterSettings
}) => {
  const onResetToDefaultClick = async () => {
    if (filterSettings) {
      const newUserFilter = setDefaultFiltersForFilterGroup(
        filterGroup,
        DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.RECOMMENDED,
        userFilter,
        false
      );

      // Reset all advanced properties
      const newFilterSettings = { ...filterSettings };
      delete newFilterSettings.__typename;
      delete newFilterSettings.representativity.__typename;

      newFilterSettings.crossedSample = false;
      newFilterSettings.representativity = {
        ...newFilterSettings.representativity,
        [filterGroup.toLowerCase()]: DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.RECOMMENDED
      };

      onUserFilterChange(newUserFilter, newFilterSettings);
    }

    setShowAdvancedSettingsDropdown(false);
  };

  const onRemoveFiltersClick = () => {
    const newUserFilter = removeFilterGroup(filterGroup, userFilter);

    const newFilterSettings = { ...filterSettings };
    delete newFilterSettings.__typename;
    delete newFilterSettings.representativity.__typename;

    newFilterSettings.crossedSample = false;

    onUserFilterChange(newUserFilter, newFilterSettings);
    setShowAdvancedSettingsDropdown(false);
  };

  return (
    <ShowInsideScreen
      positionStyle={{
        top: '33px',
        right: '0px',
        bottomPosition: '33px'
      }}
      childReference={advancedSettingsDropdownRef}
      onBlur={() => setShowAdvancedSettingsDropdown(false)}
    >
      <div
        className={styles.advancedSettingsDropdown}
        ref={r => setAdvancedSettingsDropdownRef(r)}
      >
        <div
          className={`${
            styles.advancedSettingsDropdownOption
          } reset-to-default-dropdown-button`}
          onClick={onResetToDefaultClick}
          role="presentation"
        >
          Reset to default
        </div>
        <div
          className={styles.advancedSettingsDropdownOption}
          onClick={onRemoveFiltersClick}
          role="presentation"
        >
          Remove {filterGroup} filters
        </div>
        <div
          className={styles.advancedSettingsDropdownOption}
          role="presentation"
          onClick={() => {
            setShowAdvancedSettingsDropdown(false);
            setShowAdvancedSettingsPopup(true);
          }}
        >
          Advanced age filters
        </div>
      </div>
    </ShowInsideScreen>
  );
};
