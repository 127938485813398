import gql from 'graphql-tag';

import { QUESTION_PROPERTIES } from './Survey';

export const GET_PROFILING_QUESTIONS = gql`
  query GetProfilingQuestions(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $category: String
    $tagName: String
    $tagValue: String
    $language: String
    $showOnlyVisibleForSearch: Boolean
    $salt: String
    $forceShowQuestionIds: [ID]
  ) {
    getProfilingQuestions(
      input: {
        start: $start
        end: $end
        search: $search
        category: $category
        tagName: $tagName
        tagValue: $tagValue
        language: $language
        showOnlyVisibleForSearch: $showOnlyVisibleForSearch
        salt: $salt
        forceShowQuestionIds: $forceShowQuestionIds
      }
    ) {
      maxCount
      questions {
        createdAt
        ${QUESTION_PROPERTIES}
      }
    }
  }
`;

export const SEARCH_PROFILING_CATEGORIES = gql`
  query SearchProfilingCategories(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $category: String
    $tagName: String
    $tagValue: [String]
    $language: String
    $salt: String
  ) {
    searchProfilingCategories(
      input: {
        start: $start
        end: $end
        search: $search
        category: $category
        tagName: $tagName
        tagValue: $tagValue
        language: $language
        salt: $salt
      }
    ) {
      options {
        label
        value
      }
    }
  }
`;

export const SEARCH_PROFILING_TAG_NAMES = gql`
  query SearchProfilingTagNames(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $category: String
    $tagName: String
    $tagValue: [String]
    $language: String
    $salt: String
  ) {
    searchProfilingTagNames(
      input: {
        start: $start
        end: $end
        search: $search
        category: $category
        tagName: $tagName
        tagValue: $tagValue
        language: $language
        salt: $salt
      }
    ) {
      options {
        label
        value
      }
    }
  }
`;

export const SEARCH_PROFILING_TAG_VALUES = gql`
  query SearchProfilingTagValues(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $category: String
    $tagName: String
    $tagValue: [String]
    $language: String
    $salt: String
  ) {
    searchProfilingTagValues(
      input: {
        start: $start
        end: $end
        search: $search
        category: $category
        tagName: $tagName
        tagValue: $tagValue
        language: $language
        salt: $salt
      }
    ) {
      options {
        label
        value
      }
    }
  }
`;

export const SET_PROFILING_QUESTION_VISIBILITY = gql`
  mutation SetProfilingQuestionVisibility(
    $id: ID!
    $visibleForSearch: Boolean!
  ) {
    setProfilingQuestionVisibility(
      input: { id: $id, visibleForSearch: $visibleForSearch }
    ) {
      errors {
        type
        message
      }
    }
  }
`;
