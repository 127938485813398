import gql from 'graphql-tag';

export const GET_PUSH_NOTIFICATIONS = gql`
  query GetPushNotifications(
    $page: PositiveInt!
    $pageSize: PositiveInt!
    $scheduled: Boolean!
    $type: NotificationType!
  ) {
    getPushNotifications(
      input: {
        page: $page
        pageSize: $pageSize
        scheduled: $scheduled
        type: $type
      }
    ) {
      data {
        id
        title
        description
        status
        filter_string
        count
        createdAt
        servicePushIds
        scheduled
        scheduledDate
        surveyName
        surveyId
      }
    }
  }
`;

export const GET_PUSH_NOTIFICATIONS_TARGET_COUNT = gql`
  query GetPushNotificationTargetCount(
    $activeFilters: [PushNotificationsActiveFilterInput]
  ) {
    getPushNotificationTargetCount(input: { activeFilters: $activeFilters }) {
      count
    }
  }
`;

export const GET_EMAIL_NOTIFICATIONS_TARGET_COUNT = gql`
  query GetEmailNotificationTargetCount(
    $activeFilters: [PushNotificationsActiveFilterInput]
    $survey: ID!
  ) {
    getEmailNotificationTargetCount(
      input: { activeFilters: $activeFilters, survey: $survey }
    ) {
      count
    }
  }
`;

export const SEND_PUSH_NOTIFICATIONS = gql`
  mutation SendPushNotifications(
    $title: String!
    $description: String!
    $activeFilters: [PushNotificationsActiveFilterInput]
  ) {
    sendPushNotifications(
      input: {
        title: $title
        description: $description
        activeFilters: $activeFilters
      }
    ) {
      newPushNotification {
        id
        title
        description
        status
        filter_string
        count
        createdAt
        servicePushIds
        scheduled
      }
    }
  }
`;

export const SEND_EMAIL_NOTIFICATIONS = gql`
  mutation SendEmailNotifications(
    $title: String!
    $description: String!
    $activeFilters: [PushNotificationsActiveFilterInput]
    $survey: ID
    $template: String!
  ) {
    sendEmailNotifications(
      input: {
        title: $title
        description: $description
        activeFilters: $activeFilters
        survey: $survey
        template: $template
      }
    ) {
      newPushNotification {
        id
        title
        description
        status
        filter_string
        count
        createdAt
        servicePushIds
        scheduled
        surveyName
        surveyId
      }
    }
  }
`;

export const SCHEDULE_PUSH_NOTIFICATIONS = gql`
  mutation SchedulePushNotifications(
    $title: String!
    $description: String!
    $activeFilters: [PushNotificationsActiveFilterInput]
    $date: DateTime!
  ) {
    schedulePushNotifications(
      input: {
        title: $title
        description: $description
        activeFilters: $activeFilters
        date: $date
      }
    ) {
      newPushNotification {
        id
        title
        description
        status
        filter_string
        count
        createdAt
        servicePushIds
        scheduled
        scheduledDate
      }
    }
  }
`;

export const CANCEL_PUSH_NOTIFICATION = gql`
  mutation CancelPushNotification($id: ID!) {
    cancelPushNotification(input: { id: $id }) {
      done
    }
  }
`;

export const GET_EMAIL_NOTIFICATION_TEMPLATES = gql`
  query GetEmailNotificationTemplates($mappingName: String!) {
    getEmailNotificationTemplates(input: { mappingName: $mappingName }) {
      data {
        id
        name
      }
    }
  }
`;
