import React from 'react';

import styles from './GraphInfoTabs.module.css';

const GraphInfoTabs = props => {
  const { graphInfoTabs, activegraphInfoTab, setActiveGraphInfoTab } = props;

  return (
    <div className={styles.container}>
      {Object.values(graphInfoTabs).map((graphInfoTab, i) => (
        <span
          key={`graph-info-tab-${i.toString()}`}
          className={`${styles.tab} ${
            activegraphInfoTab === graphInfoTab ? styles.active : ''
          }`}
          role="presentation"
          onClick={() => setActiveGraphInfoTab(graphInfoTab)}
        >
          {graphInfoTab}
        </span>
      ))}
    </div>
  );
};

export default GraphInfoTabs;
