import React, { useState } from 'react';

import Loader from '../../../../campaigns/components/Loader/Loader';

import tickIcon from '../../../../assets/img/tick.svg';
import closeIcon from '../../../../assets/img/delete.svg';

import styles from './AccountManagementRemovePopup.module.css';

export default ({
  activePopup,
  title,
  subtitle1,
  onSubmit,
  onClose,
  submitLoading
}) => {
  const [validationInput, setValidationInput] = useState('');

  const isValid = validationInput === 'DELETE';

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle1}</div>
        <div className={styles.subtitle}>
          Type <strong>DELETE</strong> to continue.
        </div>
        <input
          className={styles.input}
          placeholder="Type here"
          onChange={e => setValidationInput(e.target.value)}
          value={validationInput}
        />
      </div>
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          {submitLoading && (
            <div className={styles.loaderContainer}>
              <Loader size="small" />
            </div>
          )}
          {!submitLoading && [
            isValid ? (
              <img
                key="submit-button"
                className={`${
                  styles.confirm
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => {
                  if (isValid) {
                    onSubmit(activePopup.record.id);
                  }
                }}
                role="presentation"
              />
            ) : (
              <img
                key="submit-button"
                className={`${styles.confirm} ${
                  styles.invalid
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
              />
            ),
            <img
              key="close-button"
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => onClose()}
              role="presentation"
            />
          ]}
        </div>
      </div>
    </div>
  );
};
