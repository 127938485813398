import React from 'react';
import { Player, LoadingSpinner } from 'video-react';

import './VideoPopup.css';
import 'video-react/dist/video-react.css';

const VideoPopup = props => {
  const { video } = props;

  return (
    <div className="video-popup-container">
      <div className="video-popup-actions-container" />
      <Player fluid={false} width={700} height={445} src={video}>
        <LoadingSpinner />
      </Player>
    </div>
  );
};

export default VideoPopup;
