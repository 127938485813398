import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import AuthRoute from './routes/AuthRoute/AuthRoute';
// import ClaimRoute from './routes/ClaimRoute/ClaimRoute';
import CampaignsRoute from './routes/CampaignsRoute/CampaignsRoute';

import RegistrationPage from './auth/components/RegistrationPage/RegistrationPage';
import ResetPasswordPage from './auth/components/ResetPasswordPage/ResetPasswordPage';

import Campaigns from './campaigns/containers/Campaigns';
import CampaignQuestions from './campaigns/containers/CampaignQuestions';
import CampaignCompleted from './campaigns/containers/CampaignCompleted';
import CampaignFailedPage from './campaigns/containers/CampaignFailed';
import CampaignConfirmResult from './campaigns/components/Campaigns/ConfirmResult/ConfirmResultPage';
import CampaignEmailConversion from './campaigns/containers/CampaignEmailConversion';
import CampaignProfilingConsent from './campaigns/containers/CampaignProfilingConsent';

const Router = () => (
  <div>
    <BrowserRouter basename="/users">
      <div>
        <Switch>
          <AuthRoute exact path="/register" component={RegistrationPage} />
          <AuthRoute
            exact
            path="/password/reset"
            component={ResetPasswordPage}
          />

          <CampaignsRoute
            exact
            path="/campaign/:campaignId/user/:userId"
            component={Campaigns}
          />
          <CampaignsRoute
            exact
            path="/campaign/:campaignId/user/:userId/:customClientName/:customClientIdentifier"
            component={Campaigns}
          />
          <CampaignsRoute
            exact
            path="/result/:resultId/block/:blockId/user/:userId"
            component={CampaignQuestions}
          />
          <CampaignsRoute
            exact
            path="/result/:resultId/block/:blockId/user/:userId/:customClientName/:customClientIdentifier"
            component={CampaignQuestions}
          />

          <CampaignsRoute
            exact
            path="/confirm-profiling-tags/result/:resultId/user/:userId"
            component={CampaignProfilingConsent}
          />
          <CampaignsRoute
            exact
            path="/confirm-profiling-tags/result/:resultId/user/:userId/:customClientName/:customClientIdentifier"
            component={CampaignProfilingConsent}
          />

          <CampaignsRoute
            exact
            path="/result/:resultId/finish/user/:userId"
            component={CampaignCompleted}
          />
          <CampaignsRoute
            exact
            path="/result/:resultId/finish/user/:userId/:customClientName/:customClientIdentifier"
            component={CampaignCompleted}
          />
          <CampaignsRoute
            exact
            path="/campaign/:campaignId/user/:userId/campaign-failed"
            component={CampaignFailedPage}
          />
          <CampaignsRoute
            exact
            path="/campaign/:campaignId/user/:userId/campaign-failed/:customClientName/:customClientIdentifier"
            component={CampaignFailedPage}
          />
          <CampaignsRoute
            exact
            path="/campaign-failed/:customClientName/:customClientIdentifier"
            component={CampaignFailedPage}
          />
          <CampaignsRoute
            exact
            path="/result/:resultId/token/:token"
            component={CampaignConfirmResult}
          />
          <CampaignsRoute
            exact
            path="/campaign-email-conversion/result/:resultId/user/:userId"
            component={CampaignEmailConversion}
          />
          <CampaignsRoute
            exact
            path="/campaign-email-conversion/result/:resultId/user/:userId/:customClientName/:customClientIdentifier"
            component={CampaignEmailConversion}
          />
        </Switch>
      </div>
    </BrowserRouter>
  </div>
);

export default Router;
