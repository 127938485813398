import React from "react";

import "./ConfirmationDialog.css";
import Button from "../Button/Button";

const ConfirmationDialog = props => {
  const { label, onConfirm, onCancel, confirmLabel, cancelLabel } = props;

  return (
    <div className="confirmation-dialog-container">
      <div className="confirmation-dialog-message">{label}</div>
      <div className="confirmation-dialog-actions-container">
        <Button
          role="button"
          label={confirmLabel}
          onClick={() => {
            onConfirm();
          }}
        />
        <Button
          type="secondary"
          role="button"
          label={cancelLabel}
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmationDialog;
