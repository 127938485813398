import React from 'react';

import { Droppable } from 'react-beautiful-dnd';

export default ({ droppableId, reducedHeight }) => (
  <Droppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={
          snapshot.isDraggingOver
            ? { height: 100 }
            : { height: reducedHeight ? 5 : 20 }
        }
      >
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);
