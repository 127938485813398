import React, { memo } from 'react';

import Icon from '../../../../../../../../../Icon/Icon';
import BranchLogicJump from './components/BranchLogicJump/BranchLogicJump';
import BranchQuota from './components/BranchQuota/BranchQuota';

import { SURVEY_BUILDER_POPUP_TYPES } from '../../../../../../../../../../helpers/constants';

import styles from './Branch.module.css';

export default memo(
  ({
    branchIndex,
    branches,
    isActiveQuestion,
    actions,
    question,
    surveyListQuestion,
    surveyLists,
    surveyStructure,
    maxSurveyResponses
  }) => {
    const branch = branches[branchIndex];

    const branchDeleteOnClick = () => {
      const questionDesignerConstraintsPopup = localStorage.getItem(
        'qd_cpopup_delete_distbranch_dontshow'
      );

      const confirmationPopup = {
        type: SURVEY_BUILDER_POPUP_TYPES.CONFIRMATION,
        onClick: () => {
          actions.deleteQuestionBranch(question.id, branch.id);
        },
        onClose: () => actions.toggleSurveyBuilderPopup(),
        onDontShowAnymoreChecked: () => {
          if (!questionDesignerConstraintsPopup) {
            localStorage.setItem('qd_cpopup_delete_distbranch_dontshow', true);
          }
        },
        title: 'Are you sure?',
        description:
          'If you remove a path, the group linked to that path and the questions within that group will be removed.',
        warning: null
      };

      if (!questionDesignerConstraintsPopup && branch.nextFlow) {
        actions.toggleSurveyBuilderPopup(confirmationPopup);
      } else {
        actions.deleteQuestionBranch(question.id, branch.id);
      }
    };

    return (
      <div
        className={`${
          isActiveQuestion
            ? styles.blockAdditionalOption
            : `${styles.blockAdditionalOption} ${styles.inactive}`
        } mc-question-answer`}
        style={{
          borderBottom: isActiveQuestion && 'none'
        }}
      >
        <div className={styles.inputContainer}>
          <input
            className={
              isActiveQuestion
                ? styles.inputFieldContainer
                : `${styles.inputFieldContainer} ${styles.inactive}`
            }
            placeholder="Add label - invisible to respondents ..."
            onChange={e => {
              const newB = { ...branch };
              newB.label = e.target.value;

              actions.updateQuestionBranch(question.id, newB, false, true);
            }}
            value={branch.label}
          />
          {!isActiveQuestion ? (
            <span
              role="presentation"
              className={styles.inactiveClose}
              onClick={e => {
                branchDeleteOnClick();
                e.stopPropagation();
              }}
            >
              <Icon type="x" />
            </span>
          ) : null}
        </div>
        {isActiveQuestion
          ? [
              <BranchLogicJump
                questionId={question.id}
                branch={branch}
                actions={actions}
                surveyListQuestion={surveyListQuestion}
                surveyLists={surveyLists}
                surveyStructure={surveyStructure}
                branchDeleteOnClick={branchDeleteOnClick}
              />,
              <BranchQuota
                questionId={question.id}
                branches={branches}
                branch={branch}
                actions={actions}
                maxSurveyResponses={maxSurveyResponses}
                distributeEvenly={question.distributeEvenly}
              />
            ]
          : null}
      </div>
    );
  }
);
