import React from 'react';

import styles from './AnswerConnectionLine.module.css';

export default ({
  choiceIndex,
  choices,
  lockedPosition,
  randomOrder,
  otherAnswerIndex,
  noneOfTheAboveAnswerIndex
}) => {
  const totalAnswers = choices.length;

  if (totalAnswers === 1) return null;

  const hasPrev = choices.some(
    (c, i) =>
      (i === otherAnswerIndex || i === noneOfTheAboveAnswerIndex
        ? false
        : !c.lockedPosition) && i < choiceIndex
  );
  const hasNext = choices.some(
    (c, i) =>
      (i === otherAnswerIndex || i === noneOfTheAboveAnswerIndex
        ? false
        : !c.lockedPosition) && i > choiceIndex
  );

  if (!randomOrder && choices.length > 1) {
    if (choiceIndex === 0) {
      return (
        <div className={styles.container}>
          <div className={`${styles.topContainer} ${styles.bottomLine}`} />
          <div className={`${styles.bottomContainer} ${styles.leftLine}`} />
        </div>
      );
    }

    //  Last
    if (choiceIndex === totalAnswers - 1) {
      return (
        <div className={styles.container}>
          <div className={`${styles.topContainer} ${styles.leftLine}`} />
          <div className={`${styles.bottomContainer} ${styles.topLine}`} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div
          className={`${styles.topContainer} ${styles.leftLine}  ${
            styles.bottomLine
          }`}
        />
        <div className={`${styles.bottomContainer} ${styles.leftLine}`} />
      </div>
    );
  }

  if (!lockedPosition) {
    if (hasPrev && hasNext) {
      return (
        <div className={`${styles.container} fade-in`}>
          <div
            className={`${styles.topContainer}
              ${styles.leftLine} ${styles.bottomLine}`}
          />
          <div className={`${styles.bottomContainer} ${styles.leftLine}`} />
        </div>
      );
    }

    if (hasPrev) {
      return (
        <div className={`${styles.container} fade-in`}>
          <div
            className={`${styles.topContainer}
              ${styles.leftLine} ${styles.bottomLine}`}
          />
          <div className={`${styles.bottomContainer}`} />
        </div>
      );
    }

    if (hasNext) {
      return (
        <div className={`${styles.container} fade-in`}>
          <div className={`${styles.topContainer}`} />
          <div
            className={`${styles.bottomContainer} ${styles.topLine} ${
              styles.leftLine
            }`}
          />
        </div>
      );
    }
  }

  if (hasPrev && hasNext) {
    return (
      <div className={`${styles.container} fade-in`}>
        <div
          className={`${styles.topContainer}
            ${styles.leftLine}`}
        />
        <div className={`${styles.bottomContainer} ${styles.leftLine}`} />
      </div>
    );
  }

  return null;
};
