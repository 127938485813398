import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import tickIcon from '../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../assets/img/delete.svg';
import tickIconWhite from '../../../../../../../assets/img/tick_white.svg';

import Loader from '../../../../../Loader/Loader';

import styles from './ResultActionPopup.module.css';

import { REMOVE_RESULTS } from '../../../../../../../graphql/Survey';

const FlagPopup = ({
  answers,
  onClose,
  campaignId,
  title,
  description,
  flagUser,
  blockId
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState(answers);

  const [removeResults, { loading }] = useMutation(REMOVE_RESULTS, {
    onCompleted: () => window.location.reload()
  });

  const onPopupClick = e => {
    e.stopPropagation();
  };

  return (
    <div
      role="presentation"
      className={styles.popupContainer}
      onClick={onClose}
    >
      <div role="presentation" className={styles.popup} onClick={onPopupClick}>
        <div className={styles.mainTitle}>{title}</div>
        <div className={styles.mainText}>
          <div>{description}</div>
          {loading && (
            <div className={styles.loadingContainer}>
              <Loader />
            </div>
          )}
          <div className={styles.answerList}>
            {answers.map(answer => (
              <div className={styles.answerListItem} key={answer.resultId}>
                <div
                  role="presentation"
                  className={`${styles.visibleBox} ${
                    selectedAnswers.findIndex(
                      selectedAnswer =>
                        selectedAnswer.resultId === answer.resultId
                    ) > -1
                      ? styles.active
                      : null
                  }`}
                  onClick={() => {
                    if (
                      selectedAnswers.findIndex(
                        selectedAnswer =>
                          selectedAnswer.resultId === answer.resultId
                      ) > -1
                    ) {
                      setSelectedAnswers(
                        selectedAnswers.filter(
                          selectedAnswer =>
                            selectedAnswer.resultId !== answer.resultId
                        )
                      );
                    } else {
                      setSelectedAnswers([...selectedAnswers, answer]);
                    }
                  }}
                >
                  <img
                    src={tickIconWhite}
                    className={styles.visibleTick}
                    alt="Tick"
                  />
                </div>
                <span className={styles.answerListItemText}>
                  {answer.answer_string}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.actions}>
          <img
            src={tickIcon}
            alt="confirm"
            className={`${styles.confirmIcon} ${
              selectedAnswers && selectedAnswers.length
                ? styles.confirmIconActive
                : styles.confirmIconDisabled
            }`}
            role="presentation"
            onClick={() => {
              if (selectedAnswers && selectedAnswers.length) {
                const selectedAnswersResultsIdentifiers = selectedAnswers.map(
                  answer => answer.resultId
                );
                if (campaignId && selectedAnswersResultsIdentifiers.length) {
                  removeResults({
                    variables: {
                      survey: campaignId,
                      results: selectedAnswersResultsIdentifiers,
                      flagUser:
                        typeof flagUser === 'boolean' ? flagUser : false,
                      ...(blockId ? { questionId: blockId } : {})
                    }
                  });
                }
              }
            }}
          />
          <img
            src={closeIcon}
            alt="close"
            className={styles.closeIcon}
            role="presentation"
            onClick={() => onClose()}
          />
        </div>
      </div>
    </div>
  );
};

export default FlagPopup;
