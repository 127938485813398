import { TEMPLATES_WIZARD_TEMPLATE_TYPES } from '../../../helpers/constants';

export default {
  NEXT: 'Volgende',
  PREVIOUS: 'Vorige',
  WOULD_YOU_LIKE_TO_USE_TEMPLATE: 'Wilt u een template gebruiken?',
  SELECT_TEMPLATE: 'Selecteer een template',
  START_FROM_SCRATCH: 'Van nul beginnen',
  IN_WHAT_LANGUAGE_WOULD_YOU_LIKE_TO:
    'In welke taal wilt u {PURPLE}uw survey voorbereiden{/PURPLE}?',
  PREPARE_IN_MOTHER_LANGUAGE:
    'Bereid uw survey voor in de moedertaal van (ten minste een deel van) uw respondenten om vertaalkosten te vermijden.',
  WHEN_OR_HOW_TO_USE: 'Wanneer of hoe te gebruiken?',
  WHAT_TEMPLATE_WOULD_YOU_USE: 'Welke template wilt u gebruiken?',
  PROBLEM_VALIDATION_LABEL: 'Problem validation',
  IDEA_SELECTION_LABEL: 'Idea selection',
  VALUE_SELECTION_LABEL: 'Concept exploration',
  CONCEPT_TEST_LABEL: 'Concept validation',
  WOULD_YOU_LIKE_CUSTOM_TEMPLATE:
    'Wilt u een aangepaste template gebruiken? Stuur uw verzoek naar',
  WHEN_TO_USE: 'Wanneer gebruiken?',
  BENEFITS: 'VOORDELEN:',
  HOW_TO_USE: 'Hoe gebruiken?',
  FOR_ALL_QUESTIONS_CONTACT: 'Voor al uw vragen kunt u contact opnemen met',
  RATING_SCALE: 'Schaal',
  MULTIPLE_CHOICE: 'Meerkeuze',
  WHAT_SCALE_WOULD_YOU_LIKE_TO_WORK_WITH:
    'Met welke schaal wilt u graag werken?',
  DECIDE_WHAT_SCALE_YOU_WANT: 'Bepaal met welke schaal u wilt werken',
  WE_RECOMMEND_SCALE: 'Wij raden een schaal van 1 tot 7 aan',
  WE_RECOMMEND_THE_FIRST_OPTION: 'Wij raden de eerste optie aan',
  EXAMPLE_OF_SCALE_WILL_BE_USED:
    'Voorbeeld van hoe de schaal gebruikt zal worden',
  DISCLAIMER:
    'Disclaimer: De respondent vult de vragen in met de schaal die u geselecteerd hebt, ons systeem geeft de data daarna weer op een schaal van 0 tot 10 wegens benchmarkredenen.',
  I_STRONGLY_DISAGREE: 'Ik ben het er helemaal niet mee eens',
  I_DISAGREE: 'Ik ben het er niet mee eens',
  I_AGREE: 'Ik ben het er mee eens',
  I_STRONGLY_AGREE: 'Ik ben het er zeer mee eens',
  I_NEITHER_AGREE_NOR_DISAGREE: 'Ik ben het er niet mee eens of oneens',
  I_SOMEWHAT_DISAGREE: 'Ik ben het er enigszins mee oneens',
  I_SOMEWHAT_AGREE: 'Ik ben het er enigszins mee eens',
  LOGIC_MAP_FOR_SURVEY: 'Logic map',
  PROBLEM_CONTEXT: 'Probleemcontext',
  IT_IS_STRONGLY_RECOMMENDED:
    '*Het wordt ten sterkste aanbevolen om de (onvervulde) behoeften van uw doelgroep te valideren in de context waarvoor u vooraf een oplossing hebt gecreëerd. Gebruik hiervoor onze problem validation template of contacteer',
  FOR_HELP: 'voor hulp.',
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION]: {
    TO_PRIORITISE_PAINS:
      'Om prioriteit te geven aan de pijnpunten die u hebt ontdekt in een verkennend onderzoek (bijvoorbeeld interviews) en een inschatting te maken van de onvervulde gebruikersbehoeften en het daarmee onbenutte marktpotentieel.',
    YOU_WONT_WASTE_RESOURCES:
      '{BOLD}Verspil uw middelen niet.{/BOLD} Besteed uw middelen alleen aan gevalideerde problemen die marktpotentieel vertegenwoordigen.',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Kom te weten of het zin heeft om {BOLD}uw markt te segmenteren{/BOLD} en oplossingen te bouwen die afgestemd zijn op de behoeften van een bepaalde doelgroep.',
    WELL_DEFINED_VALIDATED:
      'Een goed gedefinieerde en gevalideerde probleemstelling (wanneer-waar-hoe doet een probleem zich voor?) is een perfecte {BOLD}scope voor een brainstormsessie / ideevormingssessie.{/BOLD} Het zal uw ideevorming minder vaag maken. Bovendien is het wetenschappelijk bewezen dat grenzen stellen aan de ideeëncontext uw creativiteit een boost geeft.',
    PROBLEMS_ARE_PRIORITISED:
      'Problemen worden weergegeven op de volgende assen',
    X_AXIS_INFO:
      'X-as: Hoe {BOLD}belangrijk{/BOLD} is het om een probleem voor een bepaalde doelgroep op te lossen. Hoe belangrijker, hoe meer kans u heeft om waarde te creëren.',
    Y_AXIS_INFO:
      'Y-as: Hoe {BOLD}uitdagend{/BOLD} is het voor uw doelgroep om een bepaald probleem op te lossen? Hoe uitdagender, hoe minder gepaste alternatieven er momenteel beschikbaar zijn. Minder gepaste alternatieven betekent minder concurrentie, dus meer mogelijkheden om marktleider te worden.',
    HOW_MANY_TO_TEST: 'Hoeveel problemen wilt u testen?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Wilt u meer dan {MAX_COUNTS} problemen testen? Stuur uw verzoek dan naar',
    DEFINE_YOUR: 'Definieer de problemen',
    YOUR_INPUT_WILL_BE_USED:
      'Schrijf een beknopte veronderstelling over het probleem, inclusief de contextelementen die er toe doen. Deze input wordt gebruikt om automatisch uw surveystructuur en vragen aan te maken.',
    SITUATION: 'Het is belangrijk maar uitdagend om…',
    POINT_INPUT_LABEL: 'Probleem',
    WE_INSTALL_CORNER_EXAMPLE:
      'continu (wanneer) huishoudelijke (waar) energieverspilling (wat) te verminderen zonder moeite (hoe).',
    NEED_HELP_CONTACT:
      'Problemen bij het definiëren van uw veronderstellingen? Contacteer',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'Wat vind je van de volgende stelling?',
    IT_IS_1: 'Het is {PURPLE}belangrijk{/PURPLE} om',
    GIVE_A_SCORE_1:
      'Geef een score van {MIN} (= {PURPLE}helemaal niet belangrijk{/PURPLE}) tot {MAX} (= {PURPLE}heel belangrijk{/PURPLE})',
    IT_IS_2: 'Het is {PURPLE}uitdagend{/PURPLE} om',
    GIVE_A_SCORE_2:
      'Geef een score van {MIN} (= {PURPLE}helemaal niet uitdagend{/PURPLE}) tot {MAX} (= {PURPLE}zeer uitdagend{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA: 'Vul de succescriteria voor uw problemen in',
    POINT_ASSUMPTION_HEADER: 'Het is belangrijk maar uitdagend om',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'We verwachten dat respondenten dit probleem een gemiddelde score van..',
    OUT_OF_TO_1:
      'op {MAX} geven als het gaat over hoe {BOLD}belangrijk{/BOLD} ze het vinden om dit probleem op te lossen',
    OUT_OF_TO_2:
      'op {MAX} geven als het gaat over hoe {BOLD}uitdagend{/BOLD} ze het vinden om dit probleem op te lossen',
    WE_THINK_1:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}belangrijk{/BOLD} is.',
    WE_THINK_2:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}uitdagend{/BOLD} is.',
    INTRO_QUESTION_1:
      'In de volgende vragen gaan we enkele problemen laten zien.',
    INTRO_QUESTION_2:
      'Het is aan u om aan te geven hoe {BOLD}belangrijk{/BOLD} of {BOLD}uitdagend{/BOLD} ze zijn.'
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST]: {
    TO_PRIORITISE_PAINS:
      'Om u een idee te geven hoe waardevol uw concept is voor uw doelgroep en hoe u zich kunt onderscheiden van de concurrentie.',
    YOU_WONT_WASTE_RESOURCES:
      'Dankzij onze concept validation template kunt u inschatten of uw concept aantrekkelijk genoeg is om interesse te wekken bij uw doelgroep. Is het mogelijk om het marktaandeel te behalen dat u voor ogen had?',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Evalueer of uw concept de mogelijkheid heeft om uw doelgroep tot actie aan te zetten.',
    WELL_DEFINED_VALIDATED:
      'Identificeer uw fans of early adopters, indien van toepassing.',
    PROBLEMS_ARE_PRIORITISED:
      'Concepten worden weergegeven op de volgende assen:',
    X_AXIS_INFO:
      'X-as: Hoe {BOLD}waardevol{/BOLD} is uw concept voor uw doelgroep? Hoe waardevoller uw concept, hoe groter de aantrekkingskracht.',
    Y_AXIS_INFO:
      'Y-as: Hoe {BOLD}uniek{/BOLD} is uw concept voor uw doelgroep? Hoe unieker, hoe groter het marktaandeel dat u kunt nastreven.',
    DESCRIBE_THE_SITUATION:
      'Beschrijf de situatie waar respondenten rekening mee moeten houden bij het beoordelen van uw concept. Het onderstaande tekstblok wordt getoond voordat uw concepten worden gepresenteerd.',
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Waar{/BOLD} en {BOLD}wanneer{/BOLD} wordt uw concept gebruikt? {BOLD}Hoe{/BOLD} voelt uw doelgroep zich? {BOLD}Wat{/BOLD} zijn hun problemen / onvervulde behoeftes in hun situatie?*',
    CONTEXT_EXAMPLE:
      'Je staat in de supermarkt (waar) na een drukke dag werken (wanneer) en moet snel (hoe) beslissen welke ingrediënten je koopt (wat).',
    HOW_MANY_TO_TEST: 'Hoeveel concepten wilt u testen?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Wilt u meer dan {MAX_COUNTS} concepten testen? Stuur dan een verzoek naar',
    DEFINE_YOUR: 'Definieer uw concepten',
    YOUR_INPUT_WILL_BE_USED:
      'Deze input wordt gebruikt om automatisch uw surveystructuur en vragen aan te maken.',
    POINT_INPUT_LABEL: 'Concept',
    WE_INSTALL_CORNER_EXAMPLE:
      "Een mobiele app (product of servicecategorie) die je een nieuw gezond recept voorstelt als je de supermarkt binnenkomt (functionele omschrijving) op basis van de voorkeuren die je gezin die dag aangeeft in de app & de in de supermarkt geldende kortingen (USP's)*",
    NEED_HELP_CONTACT:
      "*Als u meer dan 2 USP's wilt vermelden, raden we aan om eerst onze concept exploration template te gebruiken. Contacteer",
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'Wat vind je van het volgende concept?',
    GIVE_A_SCORE_1:
      'Geef een score van {MIN} (= {PURPLE}helemaal niet waardevol{/PURPLE}) tot {MAX} (= {PURPLE}zeer waardevol{/PURPLE})',
    GIVE_A_SCORE_2:
      'Geef een score van {MIN} (= {PURPLE}helemaal niet uniek{/PURPLE}) tot {MAX} (= {PURPLE}zeer uniek{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA: 'Vul de succescriteria voor uw concepten in',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'We verwachten dat respondenten dit concept een gemiddelde score van ... zullen geven',
    OUT_OF_TO_1: 'op {MAX} op vlak van {BOLD}waarde{/BOLD}',
    OUT_OF_TO_2: 'op of {MAX} op vlak van {BOLD}uniekheid{/BOLD}',
    WE_THINK_1:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}waardevol{/BOLD} is.',
    WE_THINK_2:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}uniek{/BOLD} is.',
    TO_WHAT_EXTEND_YOU_AGREE_1:
      'In hoeverre ben je het eens met de volgende stelling: "Ik denk dat dit een {PURPLE}waardevol{/PURPLE} concept is"?',
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'In hoeverre ben je het eens met de volgende stelling: "Ik denk dat dit een {PURPLE}uniek{/PURPLE} concept is"'
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION]: {
    TO_PRIORITISE_PAINS:
      'Om na een eerste ideatiesessie prioriteiten te stellen en de ideeën te selecteren die het waard zijn om verder uit te werken.',
    YOU_WONT_WASTE_RESOURCES:
      'Dot voting? Als u waarde wilt creëren voor uw eindgebruiker, moet de eindgebruiker beslissen welke ideeën de moeite waard zijn om uit te werken. U wilt uw middelen alleen besteden aan ideeën die waarde creëren en dus het potentieel hebben om succesvol te zijn. ',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Maak onmiddellijk komaf met kansloze ideeën om alle belanghebbenden op één lijn te brengen en voorkom vermoeiende beslissingen later in het proces. Hoe meer tijd mensen aan een idee besteden, hoe meer ze ervan beginnen te houden.',
    WELL_DEFINED_VALIDATED:
      'Gevalideerde ideeën kunnen de interesse van verschillende belanghebbenden vergroten om zich in te zetten voor de volgende stappen.',
    PROBLEMS_ARE_PRIORITISED: 'Ideeën worden weergegeven op de volgende assen:',
    X_AXIS_INFO:
      'X-as: Hoe {BOLD}waardevol{/BOLD} is een bepaald idee voor uw doelgroep? Hoe waardevoller uw idee, hoe meer mensen geïnteresseerd zijn en hoe hoger de prijs die u in kunt aanrekenen.',
    Y_AXIS_INFO:
      'Y-as: Hoe {BOLD}uniek{/BOLD} is een bepaald idee? Hoe unieker een idee is, hoe makkelijker het is om de aandacht van uw eindgebruikers te trekken en u te onderscheiden van de concurrentie.',
    DESCRIBE_THE_SITUATION:
      'Beschrijf de situatie waar respondenten rekening mee moeten houden bij het beoordelen van uw idee. Het onderstaande tekstblok wordt getoond voordat uw ideeën gepresenteerd worden.',
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Waar{/BOLD} en {BOLD}wanneer{/BOLD} wordt uw idee gebruikt? {BOLD}Hoe{/BOLD} voelt uw doelgroep zich? {BOLD}Wat{/BOLD} zijn hun problemen / onvervulde behoeftes in hun situatie?*',
    CONTEXT_EXAMPLE:
      'Je bent in een telecomwinkel (waar) en wilt zo snel mogelijk (hoe) je smartphone laten repareren (wat).',
    HOW_MANY_TO_TEST: 'Hoeveel ideeën wilt u testen?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Wilt u meer dan {MAX_COUNTS} ideeën testen? Stuur dan een verzoek naar',
    DEFINE_YOUR: 'Definieer de ideeën',
    YOUR_INPUT_WILL_BE_USED:
      'Deze input wordt gebruikt om automatisch uw surveystructuur en vragen aan te maken.',
    POINT_INPUT_LABEL: 'Idee',
    WE_INSTALL_CORNER_EXAMPLE:
      'Een hoekje in de telecomwinkel waar je je oude smartphone kunt inleveren voor reparatie.',
    NEED_HELP_CONTACT: 'Contacteer',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'Wat vind je van het volgende idee?',
    GIVE_A_SCORE_1:
      'Geef een score van {MIN} (= {PURPLE}helemaal niet waardevol{/PURPLE}) tot {MAX} (= {PURPLE}zeer waardevol{/PURPLE})',
    GIVE_A_SCORE_2:
      'Geef een score van {MIN} (= {PURPLE}helemaal niet uniek{/PURPLE}) tot {MAX} (= {PURPLE}zeer uniek{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA: 'Vul de succescriteria voor uw ideeën in',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'Wij verwachten dat respondenten dit idee een gemiddelde score van … zullen geven.',
    OUT_OF_TO_1: 'op {MAX} op vlak van {BOLD}waarde{/BOLD}',
    OUT_OF_TO_2: 'op {MAX} op vlak van {BOLD}uniciteit{/BOLD}',
    WE_THINK_1:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}waardevol{/BOLD} is.',
    WE_THINK_2:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}uniek{/BOLD} is.',
    TO_WHAT_EXTEND_YOU_AGREE_1:
      'In hoeverre ben je het eens met de volgende stelling: "Ik denk dat dit een {PURPLE}waardevol{/PURPLE} idee is"',
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'In hoeverre ben je het eens met de volgende stelling: "Ik denk dat dit een {PURPLE}uniek{/PURPLE} idee is"'
  },
  [TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION]: {
    TO_PRIORITISE_PAINS:
      'Om alleen die waarden in uw oplossing op te nemen die er toe doen voor uw doelgroep. Deze template is aan te raden als u moeite hebt met het uitschrijven van uw concept omdat u veel waarden tegelijk wilt benadrukken.',
    YOU_WONT_WASTE_RESOURCES:
      'Uw doelgroep gaat alleen de dingen gebruiken of kopen die er voor hen toe doen. Al de rest is een verlies van middelen en focus. ',
    YOU_WILL_KNOW_IT_MAKES_SENSE:
      'Definieer wat onderdeel moet zijn van uw MVP, eerste product of basispakket. ',
    WELL_DEFINED_VALIDATED:
      'Ontdek of het zinvol is om meerdere oplossingen of pakketten te maken met een op behoeften gebaseerde segmentering (verschillende doelgroepen geven de voorkeur aan verschillende waarden).',
    PROBLEMS_ARE_PRIORITISED:
      'Waarden worden weergegeven op de volgende assen:',
    X_AXIS_INFO:
      'X-as: Hoeveel {BOLD}waarde{/BOLD} wordt gecreëerd voor uw doelgroep? ',
    Y_AXIS_INFO:
      'Y-as: {BOLD}“Basisvereiste”{/BOLD}; wat onmisbaar is & dus aanwezig moet zijn in uw MVP, startproduct of basispakket.',
    DESCRIBE_THE_SITUATION:
      'Beschrijf de situatie waar respondenten rekening mee moeten houden bij het beoordelen van uw waarde. Het onderstaande tekstblok wordt getoond voordat uw waarden weergegeven worden.',
    WHERE_AND_WHEN_WILL_USED:
      '{BOLD}Waar{/BOLD} en {BOLD}wanneer{/BOLD} wordt uw waarde gebruikt? {BOLD}Hoe{/BOLD} voelt uw doelgroep zich? {BOLD}Wat{/BOLD} zijn hun problemen / onvervulde behoeftes in hun situatie?*',
    CONTEXT_EXAMPLE:
      'Je staat in de supermarkt (waar) na een drukke dag werken (wanneer) en moet snel (hoe) beslissen welke ingrediënten je koopt (wat).',
    HOW_MANY_TO_TEST: 'Hoeveel waarden wenst u te testen?',
    WOULD_YOU_LIKE_MORE_TO_TEST:
      'Wilt u meer dan {MAX_COUNTS} waarden testen? Stuur uw verzoek naar',
    DEFINE_YOUR: 'Definieer de waarden',
    YOUR_INPUT_WILL_BE_USED:
      'Deze input wordt gebruikt om automatisch uw surveystructuur en vragen aan te maken.',
    POINT_INPUT_LABEL: 'Waarde',
    WE_INSTALL_CORNER_EXAMPLE:
      'Gezinsleden/huisgenoten kunnen je via een mobiele app laten weten in welk gerecht ze die dag zin hebben.',
    NEED_HELP_CONTACT: 'Contact',
    WHAT_DO_YOU_THINK_OF_FOLLOWING: 'Wat vind je van de volgende waarde?',
    GIVE_A_SCORE_1:
      'Geef een score van {MIN} (= {PURPLE}helemaal niet waardevol{/PURPLE}) tot {MAX} (= {PURPLE}zeer waardevol{/PURPLE})',
    GIVE_A_SCORE_2:
      'Geef een score van {MIN} (= {PURPLE}helemaal geen basisvereiste{/PURPLE}) tot {MAX} (= {PURPLE}basisvereiste{/PURPLE})',
    FILL_IN_THE_SUCCESS_CRITERIA: 'Vul de succescriteria voor uw waarden in',
    WE_THINK_RESPONDENTS_GIVE_AVGSCORE:
      'We verwachten dat respondenten deze waarde een gemiddelde score van … zullen geven',
    OUT_OF_TO_1: 'op {MAX} op vlak van {BOLD}waarde{/BOLD}',
    OUT_OF_TO_2: 'op {MAX} op vlak van {BOLD}een basisvereiste{/BOLD}',
    WE_THINK_1:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}waardevol{/BOLD} is.',
    WE_THINK_2:
      'We verwachten dat respondenten zullen zeggen {SPLIT} dat dit {BOLD}een basisvereiste{/BOLD} is.',
    TO_WHAT_EXTEND_YOU_AGREE_1:
      'In hoeverre ben je het eens met de volgende stelling: "Ik vind dit {PURPLE}waardevol{/PURPLE}"',
    TO_WHAT_EXTEND_YOU_AGREE_2:
      'In hoeverre ben je het eens met de volgende stelling: "Ik denk dat dit {PURPLE}een basisvereiste{/PURPLE} is"'
  },
  COMMUNITY: {
    QUESTION_TEXT:
      'Zou jij graag nauw betrokken willen worden bij de verdere ontwikkeling van deze innovatie en hier specifieke inhoud rond ontvangen?',
    ANSWER_YES: 'Ja, graag!',
    ANSWER_NO: 'Nee, liever niet.'
  }
};
