/* Get features their index based on lovers/haters filter when there is a distributor */
export const getDistributorFeatures = (
  activeLoversHaters,
  distributorGroups,
  questionTypes,
  features
) => {
  if (
    activeLoversHaters &&
    activeLoversHaters.filter &&
    distributorGroups &&
    distributorGroups.length &&
    questionTypes &&
    activeLoversHaters[questionTypes.xQuestion] &&
    activeLoversHaters[questionTypes.yQuestion] &&
    features &&
    features.length
  ) {
    const activeLoversFeatures = [
      activeLoversHaters[questionTypes.xQuestion],
      activeLoversHaters[questionTypes.yQuestion]
    ];

    const matchingDistributorGroup = distributorGroups.find(
      g =>
        g.flows &&
        g.flows.length &&
        g.flows.some(f => activeLoversFeatures.includes(f))
    );

    if (matchingDistributorGroup && matchingDistributorGroup.flows) {
      return features.reduce((indexes, f, index) => {
        if (
          matchingDistributorGroup.flows.includes(
            f[questionTypes.xQuestion] || f[questionTypes.yQuestion]
          )
        ) {
          return [...indexes, index];
        }
        return indexes;
      }, []);
    }
  }

  return null;
};

/* Tranform unfiltered data when only data points of same distributor path should be shown */
export const transformDistributorUnfilteredData = (
  data,
  unFilteredData,
  activeLoversHatersFeatures
) =>
  unFilteredData.map((uD, i) =>
    !activeLoversHatersFeatures.includes(i) &&
    data[i] &&
    data[i].x === null &&
    data[i].y === null
      ? { x: null, y: null }
      : uD
  );
