import React from 'react';

import { REPRESENTATIVITY_OPTIONS } from '../../../../../../helpers/constants';

import styles from './RepresentativeSample.module.css';

export default props => {
  const { representativeSampleValue, setRepresentativeSampleValue } = props;

  return (
    <div>
      <div className={styles.title}>Can we deliver representatively?</div>
      <div className={styles.container}>
        <div className={styles.optionContainer}>
          <input
            type="radio"
            value={REPRESENTATIVITY_OPTIONS.RECOMMENDED}
            name="Recommended"
            onChange={e => setRepresentativeSampleValue(e.target.value)}
            checked={
              representativeSampleValue === REPRESENTATIVITY_OPTIONS.RECOMMENDED
            }
          />
          Recommended sample
        </div>
        <div className={styles.optionContainer}>
          <input
            type="radio"
            value={REPRESENTATIVITY_OPTIONS.STRICT}
            name="Strict"
            onChange={e => setRepresentativeSampleValue(e.target.value)}
            checked={
              representativeSampleValue === REPRESENTATIVITY_OPTIONS.STRICT
            }
          />
          Strict sample
        </div>
        <div className={styles.moreInfoContainer}>
          <div className={styles.questionMark}>?</div>
          <div className={styles.tooltip}>
            <div>
              Recommended approaches to the distribution within society,
              providing you reliable results in a fast and cost-effective way.
            </div>
            <div style={{ marginTop: '12px' }}>
              Strict is an exact reflection of the distribution in society. Keep
              in mind that this will increase the cost and duration of the field
              work.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
