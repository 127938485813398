import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import styles from './UserAccountFunds.module.css';

import { BUY_COUPONS_FROM_TOKENS } from '../../../../../graphql/Organisation';
import money from '../../../../../assets/img/money.svg';

const UserAccountFunds = ({ statsLoading, strings, stats, organisation }) => {
  const [buyCouponsFromTokens, { loading }] = useMutation(
    BUY_COUPONS_FROM_TOKENS,
    {
      onCompleted: () => {
        window.location.reload();
      }
    }
  );

  const buyCoupons = () => {
    buyCouponsFromTokens({
      variables: { organisationId: organisation.id }
    }).catch(() => {
      window.location.reload();
    });
  };

  if (statsLoading) {
    return (
      <div className={`${styles.placeholderContainer} loader-container`} />
    );
  }

  return (
    <div className={styles.container}>
      <img src={money} className={styles.image} alt="User Account Funds" />
      <div className={styles.description}>
        {strings.YOU_HAVE_EARNED_TOKENS_THROUGH_REFERRAL.replace(
          '{TOKENS}',
          stats.collectedTokensOnUserProfile
        )}
      </div>
      <div
        className={
          stats.buyCouponsFromTokensPossible || loading
            ? styles.button
            : styles.buttonDisabled
        }
        role="presentation"
        onClick={() => {
          if (stats.buyCouponsFromTokensPossible) {
            buyCoupons();
          }
        }}
      >
        {strings.CONVERT}
      </div>
    </div>
  );
};

export default UserAccountFunds;
