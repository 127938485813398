import * as actions from './actions';

const initialState = {
  campaign: null,
  block: null,
  result: null
};

const emptyState = JSON.parse(JSON.stringify(initialState));

export default (state = emptyState, action) => {
  switch (action.type) {
    case actions.RESET_TO_INIT_STATE:
      return initialState;
    case actions.SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign
      };
    case actions.SET_BLOCK:
      return {
        ...state,
        block: action.block
      };
    case actions.SET_RESULT:
      return {
        ...state,
        result: action.result
      };
    case actions.SET_CAMPAIGN_COMPONENTS:
      return {
        ...state,
        campaign: action.campaign,
        block: action.block,
        result: action.result
      };
    case actions.SET_USER_EMAIL_SUGGESTION:
      return {
        ...state,
        userEmailSuggestion: action.userEmailSuggestion
      };
    default:
      return state;
  }
};
