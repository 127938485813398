import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as mixpanel from '../../mixpanel';
import {
  GET_CREDENTIALS,
  SET_CREDENTIALS,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  REGISTER_REQUESTED,
  REGISTER_SUCCEEDED,
  REGISTER_FAILED
} from './actions';

import { RESET_CAMPAIGNS_STATE } from '../campaigns/actions';
/* global localStorage */

const GetCredentials = api =>
  function* getCredentials(action) {
    const token = localStorage.getItem('token');

    const clientDetails = yield call(
      api.getAuthenticatedClient,
      action.beforeLogin
    );

    if (clientDetails) {
      const accountManagement = {
        parents: clientDetails.accountManagementParents,
        manager: clientDetails.accountManagementManagerRoles,
        permissions: clientDetails.permissions,
        multipleAccountManagementRecordsToSelect:
          clientDetails.multipleAccountManagementRecordsToSelect
      };

      const recordsToSelect = [
        ...clientDetails.accountManagementManagerRoles,
        ...clientDetails.accountManagementParents
      ];

      if (
        !clientDetails.multipleAccountManagementRecordsToSelect &&
        recordsToSelect.length === 1
      ) {
        accountManagement.selectedAccountManagementRecord = recordsToSelect;
      } else if (
        clientDetails.selectedAccountManagementRecord &&
        clientDetails.selectedAccountManagementRecord.id
      ) {
        accountManagement.selectedAccountManagementRecord =
          clientDetails.selectedAccountManagementRecord;
      }

      yield put({
        type: SET_CREDENTIALS,
        isAuthenticated: true,
        token,
        isAdmin: clientDetails.admin,
        clientEmail: clientDetails.email,
        clientId: clientDetails.id,
        clientStarredCampaigns: clientDetails.starred_campaigns,
        isProfilingTagsManager: clientDetails.profilingTagsManager,
        accountManagement,
        defaultConstants: clientDetails.defaultConstants
      });
    }
  };

const Login = api =>
  function* login(action) {
    try {
      const client = yield call(
        api.login,
        action.email,
        action.password,
        action.invitationToken
      );

      localStorage.setItem('token', client.token);
      localStorage.setItem('qd_am_selected_record', 'All');

      mixpanel.actions.trackEvent(mixpanel.events.CLIENT_LOGIN, {
        [mixpanel.eventProperties.CLIENT_EMAIL]: action.email
      });

      mixpanel.actions.people.set({
        ID: client.id,
        Email: action.email,
        Admin: client.isAdmin,
        Premium: client.isPremiumClient,
        ProfilingTagsManager: client.isProfilingTagsManager
      });

      yield put({
        type: RESET_CAMPAIGNS_STATE
      });

      yield put({
        type: LOGIN_SUCCEEDED
      });
    } catch (err) {
      yield put({ type: LOGIN_FAILED, errors: err.errors ? err.errors : null });
    }
  };

function* logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('qd_am_selected_record');
  mixpanel.actions.logout();
  yield;
}

const Register = api =>
  function* register(action) {
    try {
      yield call(
        api.register,
        action.email,
        action.password,
        action.betaKey,
        action.company,
        action.telephone,
        action.firstname,
        action.lastname,
        action.howDidYouLearnAboutBuffl
      );

      mixpanel.actions.trackEvent(
        mixpanel.events.CLIENT_SIGNUP,
        {
          [mixpanel.eventProperties.EMAIL]: action.email,
          [mixpanel.eventProperties.COMPANY]: action.company,
          [mixpanel.eventProperties.TELEPHONE]: action.telephone,
          [mixpanel.eventProperties.FIRSTNAME]: action.firstname,
          [mixpanel.eventProperties.LASTNAME]: action.lastname,
          [mixpanel.eventProperties.HOW_DID_YOU_LEARN_ABOUT_BUFFL]:
            action.howDidYouLearnAboutBuffl
        },
        true
      );

      yield put({ type: REGISTER_SUCCEEDED });
    } catch (err) {
      yield put({
        type: REGISTER_FAILED,
        errors: err.errors ? err.errors : null
      });
    }
  };

export default api =>
  function* authSaga() {
    yield all([
      takeLatest(GET_CREDENTIALS, GetCredentials(api)),
      takeLatest(LOGIN_REQUESTED, Login(api)),
      takeLatest(LOGOUT, logout),
      takeLatest(REGISTER_REQUESTED, Register(api))
    ]);
  };
