import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import styles from './BasicSettings.module.css';

import formatDateForAcquisitionDate from '../../../../../AcquisitionMetrics/helpers/formatDateForAcquisitionDate';
import AccessToCommunity from '../AccessToCommunity/AccessToCommunity';

export default ({
  level,
  subtitle1,
  internalRecord,
  setInternalRecord,
  datePickerPopupVisible,
  setDatePickerPopupVisible,
  containerTopScroll,
  activePopup
}) => {
  const parseDate = dateString => {
    if (typeof dateString === 'string' && dateString.includes('/')) {
      const splitted = dateString.split('/');
      const day = parseInt(splitted[0], 10);
      const month = parseInt(splitted[1], 10);
      const year = parseInt(splitted[2], 10);
      const parsedDate = new Date(year, month - 1, day);
      if (parsedDate instanceof Date) {
        return parsedDate;
      }
    }
    return new Date();
  };

  const format = 'dd/MM/yyyy';

  return (
    <div className={styles.content}>
      {subtitle1 ? (
        <>
          <div className={styles.subtitle}>{subtitle1}</div>
          <input
            className={styles.input}
            placeholder="Enter name"
            value={
              internalRecord && internalRecord.name ? internalRecord.name : ''
            }
            onChange={e =>
              setInternalRecord({
                ...internalRecord,
                name: e.target.value
              })
            }
          />
        </>
      ) : null}

      {level === 1 ? (
        <>
          <div className={styles.dropdownLicenceTopContainer}>
            <div
              className={`${styles.dropdownContainer} ${
                styles.dropdownLicenceContainer
              }`}
            >
              <div className={styles.subtitle}>License</div>
              <div className={styles.licenceSelectorRow}>
                <div className={styles.licenceCheckboxContainer}>
                  <input
                    type="radio"
                    value={internalRecord.licence}
                    name="License Yes"
                    onChange={() => {
                      const newInternalRecord = { ...internalRecord };
                      newInternalRecord.licence = true;
                      setInternalRecord(newInternalRecord);
                    }}
                    checked={internalRecord.licence === true}
                  />
                  Yes
                </div>
                <div className={styles.licenceCheckboxContainer}>
                  <input
                    type="radio"
                    value={internalRecord.licence}
                    name="License No"
                    onChange={() => {
                      const newInternalRecord = { ...internalRecord };
                      newInternalRecord.licence = false;
                      setInternalRecord(newInternalRecord);
                    }}
                    checked={internalRecord.licence === false}
                  />
                  No
                </div>
              </div>
            </div>
          </div>
          {internalRecord && internalRecord.licence ? (
            <>
              <div className={styles.licenceDatesContainer}>
                <div className={styles.licenceSingleDateContainer}>
                  <div
                    className={
                      datePickerPopupVisible
                        ? styles.licenceSingleDateTitleStartDate
                        : null
                    }
                  >
                    License start date
                  </div>
                  <div className={styles.licenceSingleDateInputContainer}>
                    <div
                      className={
                        datePickerPopupVisible
                          ? styles.datePickerOuterContainer
                          : styles.datePickerNormalOuterContainer
                      }
                    >
                      <div
                        className={
                          styles.datePickerOuterAbsoluteContainerStartDate
                        }
                      >
                        <div
                          className={styles.datePickerOuterFixedContainer}
                          style={
                            datePickerPopupVisible
                              ? { marginTop: containerTopScroll }
                              : {}
                          }
                        >
                          <DayPickerInput
                            onDayChange={d => {
                              const proposedEndDate = new Date(d);
                              proposedEndDate.setFullYear(
                                proposedEndDate.getFullYear() + 1
                              );

                              setInternalRecord({
                                ...internalRecord,
                                licenceStartDate: d,
                                licenceEndDate: proposedEndDate
                              });
                            }}
                            value={
                              internalRecord && internalRecord.licenceStartDate
                            }
                            dayPickerProps={{
                              canChangeMonth: true
                            }}
                            placeholder="dd/mm/yyyy"
                            format={format}
                            formatDate={formatDateForAcquisitionDate}
                            parseDate={parseDate}
                            inputProps={{ readOnly: true }}
                            onDayPickerShow={() => {
                              setTimeout(() => {
                                setDatePickerPopupVisible(true);
                              }, 10);
                            }}
                            onDayPickerHide={() =>
                              setDatePickerPopupVisible(false)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.licenceSingleDateContainer}>
                  <div
                    className={
                      datePickerPopupVisible
                        ? styles.licenceSingleDateTitleEndDate
                        : null
                    }
                  >
                    License end date
                  </div>
                  <div className={styles.licenceSingleDateInputContainer}>
                    <div
                      className={
                        datePickerPopupVisible
                          ? styles.datePickerOuterContainer
                          : styles.datePickerNormalOuterContainer
                      }
                    >
                      <div
                        className={
                          styles.datePickerOuterAbsoluteContainerEndDate
                        }
                      >
                        <div
                          className={styles.datePickerOuterFixedContainer}
                          style={
                            datePickerPopupVisible
                              ? { marginTop: containerTopScroll }
                              : {}
                          }
                        >
                          <DayPickerInput
                            onDayChange={d => {
                              setInternalRecord({
                                ...internalRecord,
                                licenceEndDate: d
                              });
                            }}
                            value={
                              internalRecord && internalRecord.licenceEndDate
                            }
                            dayPickerProps={{
                              canChangeMonth: true
                            }}
                            placeholder="dd/mm/yyyy"
                            format={format}
                            formatDate={formatDateForAcquisitionDate}
                            parseDate={parseDate}
                            inputProps={{ readOnly: true }}
                            onDayPickerShow={() => {
                              setTimeout(() => {
                                setDatePickerPopupVisible(true);
                              }, 10);
                            }}
                            onDayPickerHide={() =>
                              setDatePickerPopupVisible(false)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.additionalOptionCheck}>
                <input
                  type="checkbox"
                  onChange={() => {
                    if (internalRecord.automaticallyExtendLicence) {
                      setInternalRecord({
                        ...internalRecord,
                        automaticallyExtendLicence: null
                      });
                    } else {
                      setInternalRecord({
                        ...internalRecord,
                        automaticallyExtendLicence: true
                      });
                    }
                  }}
                  checked={
                    internalRecord && internalRecord.automaticallyExtendLicence
                  }
                />
                Automatically extend licence
              </div>
              <AccessToCommunity
                internalRecord={internalRecord}
                setInternalRecord={setInternalRecord}
                activePopup={activePopup}
              />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
