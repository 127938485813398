import React, { useState } from 'react';

import styles from './ConstraintTooltip.module.css';

import ConstraintTooltipContainer from './components/ConstraintTooltipContainer/ConstraintTooltipContainer';

export default ({ label, scrolledFromTop, addedIndex }) => {
  const [tooltipContainerVisible, setTooltipContainerVisible] = useState(false);

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setTooltipContainerVisible(true)}
      onMouseLeave={() => setTooltipContainerVisible(false)}
    >
      <div className={styles.symbol}>
        ?
        {tooltipContainerVisible && (
          <ConstraintTooltipContainer
            label={label}
            scrolledFromTop={scrolledFromTop}
            addedIndex={addedIndex}
          />
        )}
      </div>
    </div>
  );
};
