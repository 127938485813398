import * as actions from './actions';

const initialState = {
  organisation: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ORGANISATION:
      return {
        ...state,
        organisation: action.organisation
      };
    default:
      return state;
  }
};
