import { QUESTION_TYPES } from './constants';

export default type => {
  switch (type) {
    case QUESTION_TYPES.OPEN_QUESTION:
      return 'Type your Open Question here ...';
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return 'Type your Multiple Choice Question here ...';
    case QUESTION_TYPES.RANKING:
      return 'Type your Ranking Options Question here ...';
    case QUESTION_TYPES.RATING_SCALE:
      return 'Type your Rating Scale Question here ...';
    case QUESTION_TYPES.TAKE_A_PICTURE:
      return 'Type your Ask Users To Send In A Picture Question here ...';
    case QUESTION_TYPES.RECORD_A_VIDEO:
      return 'Type your Ask Users To Send In A Video Question here ...';
    case QUESTION_TYPES.DISTRIBUTOR:
      return 'Add label - invisible to respondents ...';
    case QUESTION_TYPES.PLAY_A_VIDEO:
    case QUESTION_TYPES.IFRAME:
      return null;
    case QUESTION_TYPES.ADD_TO_COMMUNITY:
      return 'Type your Add To Community Question here ...';
    default:
      return 'Type your text here ...';
  }
};
