import React, { useState, useEffect, useContext, useMemo } from 'react';

import { Draggable } from 'react-beautiful-dnd';
import SurveyBuilderContext from '../../../../SurveyBuilderContext';
import Question from './Question';
import QuestionContainerActions from './components/QuestionContainerActions/QuestionContainerActions';
import FlowLogicJump from '../FlowLogicJump/FlowLogicJump';
import {
  QUESTION_TYPES,
  QUESTION_DESIGNER_ACTION
} from '../../../../helpers/constants';
import isActionAllowed from '../../../../helpers/isActionAllowed/isActionAllowed';

import styles from './Question.module.css';

const QuestionDraggingWrapper = ({
  question,
  actions,
  surveyPageRef,
  divRef,
  setDivRef,
  isActiveQuestion,
  surveyLists,
  globalIndex,
  indexInSet,
  setIndex,
  containerStyles,
  state,
  innerSurveyStructure,
  isAdmin,
  isProfilingTagsManager,
  surveyLanguage
}) => {
  const surveyListQuestion = surveyLists.find(sLQ => sLQ.id === question.id);

  return (
    <Draggable
      key={`question-drag-${globalIndex}`}
      draggableId={`question-drag-${globalIndex}`}
      isDragDisabled={
        !isActionAllowed(
          QUESTION_DESIGNER_ACTION.QUESTION_MOVE,
          surveyLists.find(sL => sL.globalIndex === globalIndex),
          null,
          surveyLists,
          innerSurveyStructure
        ).allowed
      }
      index={indexInSet}
    >
      {childProvided => (
        <div
          ref={childProvided.innerRef}
          {...childProvided.draggableProps}
          {...childProvided.dragHandleProps}
        >
          <div className={containerStyles} role="presentation">
            {question.type !== QUESTION_TYPES.DISTRIBUTOR && (
              <div
                className={styles.questionContainerNumber}
                role="presentation"
                onClick={() =>
                  !isActiveQuestion && actions.setActiveFlow(question)
                }
              >
                {surveyListQuestion.formattedGlobalIndex}
              </div>
            )}
            <Question
              question={question}
              actions={actions}
              surveyPageRef={surveyPageRef}
              divRef={divRef}
              setDivRef={setDivRef}
              isActiveQuestion={isActiveQuestion}
              surveyLists={surveyLists}
              indexInSet={indexInSet}
              setIndex={setIndex}
              innerSurveyStructure={innerSurveyStructure}
              isAdmin={isAdmin}
              maxSurveyResponses={state.survey.maxResponses}
              surveyListQuestion={surveyListQuestion}
              isProfilingTagsManager={isProfilingTagsManager}
              surveyLanguage={surveyLanguage}
            />
            {isActiveQuestion && question.type !== QUESTION_TYPES.DISTRIBUTOR && (
              <FlowLogicJump
                key="flow-logic-jump"
                flow={question}
                surveyLists={surveyLists}
                actions={actions}
                surveyStructure={{
                  content: state.survey.content,
                  questions: state.survey.questions
                }}
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ({ question, globalIndex, indexInSet, setIndex }) => {
  const [isActiveQuestion, setIsActiveQuestion] = useState(false);
  const [divRef, setDivRef] = useState(null);
  const {
    state,
    actions,
    surveyPageRef,
    isAdmin,
    isProfilingTagsManager
  } = useContext(SurveyBuilderContext);
  const [preventScroll, setPreventScroll] = useState(false);
  const [innerSurveyStructure, setInnerSurveyStructure] = useState(null);

  useEffect(
    () => {
      if (
        isActiveQuestion &&
        ((state.activeFlow && state.activeFlow.id !== question.id) ||
          !state.activeFlow)
      ) {
        setIsActiveQuestion(false);

        setInnerSurveyStructure({
          content: state.survey.content,
          questions: state.survey.questions
        });
      }
      if (
        !isActiveQuestion &&
        state.activeFlow &&
        state.activeFlow.id === question.id
      ) {
        setIsActiveQuestion(true);

        setInnerSurveyStructure({
          content: state.survey.content,
          questions: state.survey.questions
        });
      }
    },
    [state.activeFlow]
  );

  useEffect(
    () => {
      if (isActiveQuestion) {
        if (divRef && surveyPageRef && !preventScroll) {
          let element = divRef;
          let yPosition = 0;

          while (element) {
            const nextYPosition =
              yPosition +
              element.offsetTop -
              element.scrollTop +
              element.clientTop;

            if (nextYPosition > yPosition) {
              yPosition = nextYPosition;
            }

            element = element.offsetParent;
          }
          const verticalCorrection = 200;
          surveyPageRef.scrollTop = yPosition - verticalCorrection; // eslint-disable-line
        }
        if (preventScroll) {
          setPreventScroll(false);
        }
      }
    },
    [isActiveQuestion]
  );

  const memoizedActions = useMemo(() => actions, []);

  const memoizedSurveyLists = useMemo(() => state.surveyLists, [
    question,
    isActiveQuestion,
    globalIndex
  ]);

  // console.log(`Render Question Container ${question.id}`);

  let containerStyles = styles.questionParentContainer;

  if (question.type === QUESTION_TYPES.DISTRIBUTOR) {
    containerStyles = `${containerStyles} ${
      styles.questionParentRandomizerContainer
    }`;
  }

  const memoizedContainerStyles = useMemo(() => containerStyles, []);

  return (
    <div
      role="presentation"
      onClick={() => {
        setPreventScroll(true);
        if (!isActiveQuestion) {
          actions.setActiveFlow(question);
        }
      }}
    >
      <QuestionDraggingWrapper
        question={question}
        actions={memoizedActions}
        divRef={divRef}
        setDivRef={setDivRef}
        isActiveQuestion={isActiveQuestion}
        surveyLists={memoizedSurveyLists} // Set surveyLists instead of memoizedSurveyLists to test re render
        globalIndex={globalIndex}
        indexInSet={indexInSet}
        setIndex={setIndex}
        containerStyles={memoizedContainerStyles}
        state={state}
        innerSurveyStructure={innerSurveyStructure}
        isAdmin={isAdmin}
        isProfilingTagsManager={isProfilingTagsManager}
        surveyLanguage={state && state.survey && state.survey.language}
        surveyPageRef={surveyPageRef}
      />
      <QuestionContainerActions
        question={question}
        actions={actions}
        surveyLists={state.surveyLists}
        surveyStructure={{
          content: state.survey.content,
          questions: state.survey.questions
        }}
      />
    </div>
  );
};
