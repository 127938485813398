import React, { useState, useEffect } from 'react';

import useApi from '../../../../hooks/useApi';
import {
  getStatisticsData,
  loadAllMetrics
} from '../../../../helpers/mainPageChartDataLoad';
import { calculateSocioDemoDeltas } from '../../../../helpers/calculateSocioDemoDeltas/calculateSocioDemoDeltas';
import calculateProfileAttributesDeltas from '../../../../helpers/calculateProfileAttributesDeltas/calculateProfileAttributesDeltas';
import getProfileAttributesFiltersLength from '../../../../helpers/getProfileAttributesFiltersLength';
import calculateIncidenceRate from '../../../../helpers/calculateIncidenceRate/calculateIncidenceRate';

import DeliveryPotential from './components/DeliveryPotential/DeliveryPotential';
import ChartMetric from './components/ChartMetric/ChartMetric';
import RepresentativeSample from './components/RepresentativeSample/RepresentativeSample';
import DeltaWarnings from './components/DeltaWarnings/DeltaWarnings';
import ProfileAttributesMetrics from './components/ProfileAttributesMetrics/ProfileAttributesMetrics';

import {
  FILTER_RELATIONS,
  MAIN_DATA_METRICS,
  REPRESENTATIVITY_OPTIONS,
  WARNINGS,
  SOCIO_DEMOGRAPHICS_CHARTS,
  AGE_CHART_LABELS
} from '../../../../helpers/constants';

import styles from './MainPageCharts.module.css';

export default ({
  appliedFilters,
  filterRelation,
  isGlobalLoading,
  setIsGlobalLoading,
  totalSampleSize,
  totalSampleSizeLoading,
  minimumQuotaEnabled
}) => {
  const [statisticsData, setStatisticsData] = useState(getStatisticsData(null));
  const [api] = useApi();
  const [socioDemoDeltas, setSocioDemoDeltas] = useState(null);
  const [profileAttributesDeltas, setProfileAttributesDeltas] = useState(null);
  const [representativeSampleValue, setRepresentativeSampleValue] = useState(
    REPRESENTATIVITY_OPTIONS.RECOMMENDED
  );
  const [deltaWarnings, setDeltaWarnings] = useState([]);
  const [
    profileAttributesFiltersLength,
    setProfileAttributesFiltersLength
  ] = useState(getProfileAttributesFiltersLength(appliedFilters));
  const [calculateIRLoading, setCalculateIRLoading] = useState(false);

  useEffect(
    () => {
      const newProfileAttributesFiltersLength = getProfileAttributesFiltersLength(
        appliedFilters
      );
      if (
        newProfileAttributesFiltersLength !== profileAttributesFiltersLength
      ) {
        setProfileAttributesFiltersLength(newProfileAttributesFiltersLength);
      }

      // Empty values, loaders are shown
      setStatisticsData(getStatisticsData(null));

      // If no filters are configured basic request should have OR filterRelation
      loadAllMetrics(
        api,
        setStatisticsData,
        appliedFilters,
        !(appliedFilters && appliedFilters.length)
          ? FILTER_RELATIONS.OR
          : filterRelation
      );
    },
    [appliedFilters, filterRelation]
  );

  useEffect(
    () => {
      const isAnyMetricLoading = Object.entries(statisticsData).some(
        ([metricName, sD]) =>
          sD === null &&
          !(
            metricName === MAIN_DATA_METRICS.PROFILE_ATTRIBUTES &&
            profileAttributesFiltersLength === 0
          )
      );
      if (isAnyMetricLoading !== isGlobalLoading) {
        // eslint-disable-next-line
        setIsGlobalLoading(isGlobalLoading => isAnyMetricLoading);
      }
    },
    [statisticsData]
  );

  const updateWarningState = chartDeltas => {
    const newDeltaWarnings = chartDeltas.reduce(
      (warningToBeDisplayed, chartDelta) => {
        if (chartDelta.deltaWarning) {
          return [...warningToBeDisplayed, chartDelta.deltaWarning];
        }
        return warningToBeDisplayed;
      },
      []
    );
    setDeltaWarnings(newDeltaWarnings);
  };

  const updateDeltaState = (chartDeltas, setStateFunction) => {
    const newDeltas = chartDeltas.map(chart => ({
      deltaValues: chart.deltaValues,
      title: chart.title
    }));
    setStateFunction(newDeltas);
  };

  useEffect(
    () => {
      if (statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS]) {
        const chartDeltas = Object.entries(
          statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS]
        ).map(([title, chartData]) =>
          calculateSocioDemoDeltas(
            appliedFilters,
            filterRelation,
            totalSampleSize,
            chartData.values,
            representativeSampleValue,
            title
          )
        );
        updateWarningState(chartDeltas);
        updateDeltaState(chartDeltas, setSocioDemoDeltas);
      }
    },
    [
      totalSampleSize,
      representativeSampleValue,
      statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS]
    ]
  );

  useEffect(
    () => {
      if (statisticsData[MAIN_DATA_METRICS.PROFILE_ATTRIBUTES]) {
        const chartDeltas = Object.entries(
          statisticsData[MAIN_DATA_METRICS.PROFILE_ATTRIBUTES]
        ).map(([title, chartData]) =>
          calculateProfileAttributesDeltas(
            appliedFilters,
            chartData.values,
            title,
            minimumQuotaEnabled
          )
        );
        updateDeltaState(chartDeltas, setProfileAttributesDeltas);
      }
    },
    [statisticsData[MAIN_DATA_METRICS.PROFILE_ATTRIBUTES]]
  );

  const getDeltaValues = (title, deltas) => {
    if (
      deltas &&
      deltas.length &&
      deltas.some(delta => delta.title === title)
    ) {
      const chartDelta = deltas.find(delta => delta.title === title);
      if (
        chartDelta &&
        chartDelta.deltaValues &&
        chartDelta.deltaValues.length
      ) {
        return chartDelta.deltaValues;
      }
    }
    return [];
  };

  const getWarnings = () => {
    let warnings = deltaWarnings.reduce((newWarnings, currentWarning) => {
      if (!newWarnings.includes(currentWarning)) {
        return [...newWarnings, currentWarning];
      }
      return newWarnings;
    }, []);

    if (
      !profileAttributesFiltersLength &&
      statisticsData[MAIN_DATA_METRICS.DELIVERY_POTENTIAL] &&
      statisticsData[MAIN_DATA_METRICS.DELIVERY_POTENTIAL] < totalSampleSize
    ) {
      warnings = [WARNINGS.SAMPLE_SIZE_NOT_FEASIBLE, ...warnings];
    }
    return warnings;
  };

  const onCalculateIncidenceRateClick = async () => {
    setCalculateIRLoading(true);

    const { totalIR, newStatisticsData } = await calculateIncidenceRate(
      appliedFilters,
      statisticsData,
      api,
      filterRelation
    );

    newStatisticsData[MAIN_DATA_METRICS.INCIDENCE_RATE] = {
      totalIncidenceRate: totalIR
    };
    setStatisticsData(newStatisticsData);
    setCalculateIRLoading(false);
  };

  return (
    <div className={styles.container}>
      <DeliveryPotential
        totalSampleSize={totalSampleSize}
        maxDeliveryPotentialCount={
          statisticsData[MAIN_DATA_METRICS.DELIVERY_POTENTIAL] &&
          statisticsData[MAIN_DATA_METRICS.DELIVERY_POTENTIAL].deliveryPotential
        }
        totalIncidenceRate={
          statisticsData[MAIN_DATA_METRICS.INCIDENCE_RATE] &&
          statisticsData[MAIN_DATA_METRICS.INCIDENCE_RATE].totalIncidenceRate
        }
        totalSampleSizeLoading={totalSampleSizeLoading}
        onCalculateIncidenceRateClick={onCalculateIncidenceRateClick}
        calculateIRLoading={calculateIRLoading}
        isGlobalLoading={isGlobalLoading}
        appliedFilters={appliedFilters}
        filterRelation={filterRelation}
      />
      {statisticsData[MAIN_DATA_METRICS.PROFILE_ATTRIBUTES] &&
      profileAttributesFiltersLength ? (
        <div className={styles.title}>
          Can we deliver these specific parameters?
        </div>
      ) : null}
      <div className={styles.row}>
        <ProfileAttributesMetrics
          profileAttributesData={
            statisticsData[MAIN_DATA_METRICS.PROFILE_ATTRIBUTES]
          }
          profileAttributesFiltersLength={profileAttributesFiltersLength}
          profileAttributesDeltas={profileAttributesDeltas}
          getDeltaValues={getDeltaValues}
          minimumQuotaEnabled={minimumQuotaEnabled}
        />
      </div>
      <RepresentativeSample
        representativeSampleValue={representativeSampleValue}
        setRepresentativeSampleValue={setRepresentativeSampleValue}
      />
      <DeltaWarnings warnings={getWarnings()} />
      <div className={styles.row}>
        <ChartMetric
          title={SOCIO_DEMOGRAPHICS_CHARTS.AGE}
          chartData={
            statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS] &&
            statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS][
              SOCIO_DEMOGRAPHICS_CHARTS.AGE
            ]
              ? {
                  ...statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS][
                    SOCIO_DEMOGRAPHICS_CHARTS.AGE
                  ],
                  deltaValues: getDeltaValues(
                    SOCIO_DEMOGRAPHICS_CHARTS.AGE,
                    socioDemoDeltas
                  )
                }
              : null
          }
          metricWidth={49}
          isSocioDemoChart
          customLabels={AGE_CHART_LABELS}
        />
        <ChartMetric
          title={SOCIO_DEMOGRAPHICS_CHARTS.GENDER}
          chartData={
            statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS] &&
            statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS][
              SOCIO_DEMOGRAPHICS_CHARTS.GENDER
            ]
              ? {
                  ...statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS][
                    SOCIO_DEMOGRAPHICS_CHARTS.GENDER
                  ],
                  deltaValues: getDeltaValues(
                    SOCIO_DEMOGRAPHICS_CHARTS.GENDER,
                    socioDemoDeltas
                  )
                }
              : null
          }
          metricWidth={49}
          isSocioDemoChart
        />
        <ChartMetric
          title={SOCIO_DEMOGRAPHICS_CHARTS.LANGUAGE}
          chartData={
            statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS] &&
            statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS][
              SOCIO_DEMOGRAPHICS_CHARTS.LANGUAGE
            ]
              ? {
                  ...statisticsData[MAIN_DATA_METRICS.SOCIO_DEMOGRAPHICS][
                    SOCIO_DEMOGRAPHICS_CHARTS.LANGUAGE
                  ],
                  deltaValues: getDeltaValues(
                    SOCIO_DEMOGRAPHICS_CHARTS.LANGUAGE,
                    socioDemoDeltas
                  )
                }
              : null
          }
          metricWidth={49}
          isSocioDemoChart
        />
      </div>
    </div>
  );
};
