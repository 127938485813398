import React from 'react';
// import "../../auth/components/auth.css";
import loginImage from '../../../assets/img/login-cover.jpg';
import availableAppleStore from '../../../assets/img/available-apple-store.png';
import availablePlayStore from '../../../assets/img/available-play-store.png';

const AuthWelcome = ({ title, body }) => (
  <div
    className="welcome-container"
    style={{ backgroundImage: `url(${loginImage})` }}
  >
    <div className="welcome-container-content">
      <div>
        <div className="welcome-message">{title}</div>
        <div className="welcome-message-description">{body}</div>
      </div>

      <div className="mobile-apps-container">
        <a
          href="https://itunes.apple.com/be/app/buffl/id1356715033?l=nl&mt=8"
          target="new"
        >
          <img src={availableAppleStore} alt="Buffl on Apple Store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=org.nativescript.buffl"
          target="new"
        >
          <img src={availablePlayStore} alt="Buffl on Play Store" />
        </a>
      </div>
    </div>
  </div>
);

export default AuthWelcome;
