export default (level, isAdmin, accountManagement, parent) => {
  const levelManager =
    accountManagement &&
    accountManagement.manager &&
    accountManagement.manager.find(m => m.id === parent);

  // SPOC can create / edit / remove teams and
  if (levelManager && levelManager.level === 1) {
    return true;
  }

  // Team lead
  if (levelManager && levelManager.level === 2) {
    return true;
  }

  // Members
  if (level === 3) {
    const isFirstLevelManager =
      accountManagement &&
      accountManagement.manager &&
      accountManagement.manager.find(
        m =>
          m.level === 1 &&
          m.lowerLevelRecords &&
          m.lowerLevelRecords.length &&
          m.lowerLevelRecords.some(mLR => mLR.id === parent)
      );

    if (isFirstLevelManager) {
      return true;
    }
  }

  if (isAdmin) {
    return isAdmin;
  }

  return false;
};
