import React from 'react';

const BranchIcon = ({ fill = '#000000', width = 17, height = 14 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <g id="logic-jump-icon-blue" transform="translate(-393.88 -743.176)">
      <g id="Group_258" data-name="Group 258">
        <path
          id="Path_528"
          data-name="Path 528"
          d="M408.38,752.176a2.49,2.49,0,0,0-2.3,1.53c-1.95-1.54-5.65-4.84-6.44-7.28h6.35a2.5,2.5,0,1,0,0-1.5h-7.22a2.508,2.508,0,1,0-.43,2.3c1.36,3.39,6.68,7.62,7.74,8.44a2.5,2.5,0,1,0,2.3-3.49Zm0-7.5a1,1,0,1,1-1,1A1,1,0,0,1,408.38,744.676Zm-12,2a1,1,0,1,1,1-1A1,1,0,0,1,396.38,746.676Zm12,9a1,1,0,1,1,1-1A1,1,0,0,1,408.38,755.676Z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default BranchIcon;
