import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { REMOVE_INSIGHT } from '../../../../../../../../graphql/InsightsData';
import {
  insightQuestionTypes,
  loversHatersFilters
} from '../../../../helpers/constants';
import calculateExtremeUsersData from '../../../../../Blocks/Graphs/helpers/calculateExtremeUsersData';

import Popup from '../../../../../../Popup';
import ConfirmationDialog from '../../../../../../ConfirmationDialog/ConfirmationDialog';
import Draft from '../../../../../Blocks/Question/Draft/Draft';

import styles from './Insights.module.css';
import Icon from '../../../../../../Icon';
import getAllowedQuestions from '../../../../../Blocks/Graphs/helpers/getAllowedQuestions';

export default ({
  survey,
  onSetActiveFilters,
  activeInsightId,
  setActiveInsightId,
  surveyRefetch,
  onEditClick,
  filterCollectDataGroups,
  filtersRelation,
  onSetFiltersRelation,

  sortedQuestions,
  surveyGraphs,
  getQuestionIcon,
  setActiveSorting,

  viewToken
}) => {
  const [removeInsight] = useMutation(REMOVE_INSIGHT);
  const [confirmationPopup, setConfirmationPopup] = useState(null);

  const onDeleteClick = async insightId => {
    await removeInsight({
      variables: {
        id: insightId,
        survey: survey.id.toString()
      }
    });

    surveyRefetch();
  };

  const processInsightFilterBeforeApply = insightFilters => {
    const newInsightFilters = [...insightFilters];

    // Set transformator function
    newInsightFilters.forEach(f => {
      const newF = f;

      const filterCollectDataGroup = filterCollectDataGroups.find(
        dG => dG.name === f.filterName
      );
      if (filterCollectDataGroup && filterCollectDataGroup.transformator) {
        newF.transformator = filterCollectDataGroup.transformator;
      }

      // Question filter value needs to be transformed into integer from string
      if (newF && newF.valuePath === 'answer') {
        newF.value = parseInt(newF.value, 10);

        // Multiple choice questions need transformator to work properly
        const question = sortedQuestions.find(q => q.id === newF.filterName);
        if (question && question.selectAtMost && question.selectAtMost > 1) {
          newF.transformator = result => {
            if (result && result.attributes.block_results) {
              const questionBlock = result.attributes.block_results.filter(
                q => q.block === question.id
              );
              if (questionBlock.length) {
                if (
                  questionBlock[0].answers &&
                  questionBlock[0].answers.indexOf(newF.value) > -1
                ) {
                  return true;
                }
              }
            }
            return false;
          };
        }
      }

      // Open Answer Category filter needs transformator
      if (
        newF &&
        newF.filterName &&
        newF.filterName.substring(0, 4) === 'CAT-'
      ) {
        const questionId = newF.filterName.replace('CAT-', '');
        const question = sortedQuestions.some(q => q.id === questionId);
        if (question) {
          newF.transformator = result => {
            if (result && result.attributes.block_results) {
              const questionBlock = result.attributes.block_results.filter(
                q => q.block === questionId
              );
              if (questionBlock.length) {
                if (
                  questionBlock[0].categories &&
                  questionBlock[0].categories.length
                ) {
                  if (
                    questionBlock[0].categories &&
                    questionBlock[0].categories.indexOf(newF.value) > -1
                  ) {
                    return true;
                  }
                }
              }
            }
            return false;
          };
        }
      }

      // Matrix filter needs transformator
      if (
        newF &&
        newF.filterName &&
        sortedQuestions.some(
          q => q.type === 'Matrix' && q.id === newF.filterName
        )
      ) {
        const questionId = newF.filterName;

        // matrixQuestionId (x/y axis), choiceId and answerIndex are saved as single string separated by commas
        const [matrixQuestionId, choiceId, answerIndex] = newF.value.split(',');
        if (matrixQuestionId && choiceId && answerIndex >= 0) {
          newF.transformator = result => {
            if (
              result &&
              result.attributes &&
              result.attributes.block_results &&
              result.attributes.block_results.length
            ) {
              const questionBlock = result.attributes.block_results.find(
                question => question.block === questionId
              );
              if (
                questionBlock &&
                questionBlock.matrixAnswers &&
                questionBlock.matrixAnswers.length &&
                questionBlock.matrixAnswers.some(
                  matrixAnswer =>
                    matrixAnswer.questionId &&
                    matrixAnswer.questionId === matrixQuestionId &&
                    matrixAnswer.choices &&
                    matrixAnswer.choices.length &&
                    matrixAnswer.choices.some(
                      choice =>
                        choice.choiceId === choiceId &&
                        choice.answers &&
                        choice.answers.length &&
                        choice.answers.includes(parseInt(answerIndex, 10))
                    )
                )
              ) {
                return true;
              }
            }
            return false;
          };
        }
      }

      // Lovers/haters filter needs transformator
      if (newF && newF.value && loversHatersFilters.includes(newF.value)) {
        let extremeUsersResultIds = [];

        if (surveyGraphs && surveyGraphs.length) {
          const activeLoversHaters = surveyGraphs.reduce((dataP, g) => {
            if (!dataP) {
              const dataPointToFind =
                g.features && g.features.length
                  ? g.features.find(gF => gF.id === newF.additionalAnswerLabel)
                  : null;
              if (dataPointToFind) {
                return {
                  ...dataPointToFind,
                  filter: newF.value
                }; // Include filter value (xLovers, yHaters, ...)
              }
            }
            return dataP;
          }, null);

          if (activeLoversHaters) {
            const reducedQuestions = getAllowedQuestions(sortedQuestions);

            let questionTypes = {
              xQuestion: 'xQuestion',
              yQuestion: 'yQuestion'
            };

            if (
              !activeLoversHaters.xQuestion &&
              activeLoversHaters.importanceQuestion
            ) {
              questionTypes = {
                xQuestion: 'importanceQuestion',
                yQuestion: 'satisfactionQuestion'
              };
            }

            extremeUsersResultIds = calculateExtremeUsersData(
              activeLoversHaters,
              reducedQuestions,
              questionTypes
            );
          }
        }

        newF.transformator = result => {
          if (
            result &&
            result.id &&
            extremeUsersResultIds.indexOf(result.id) > -1
          ) {
            return true;
          }
          return false;
        };
      }

      if (newF.__typename) {
        delete newF.__typename;
      }
    });

    return insightFilters;
  };

  const getQuestionPreviews = iQ => {
    if (iQ && iQ.questions) {
      const previews = iQ.questions.map(q => {
        if (q.type === insightQuestionTypes.QUESTION) {
          const question =
            sortedQuestions &&
            sortedQuestions.length &&
            sortedQuestions.find(sQ => sQ.id === q.questionId);

          if (question) {
            return (
              <div className={styles.questionContainerPreview}>
                <Icon
                  type={getQuestionIcon(question)}
                  style={{
                    color: '#5200f1',
                    marginLeft: '5px',
                    marginRight: '5px',
                    fontSize: '10px'
                  }}
                />
                <span>Q{question.formattedGlobalIndex}</span>
              </div>
            );
          }
        }

        if (q.type === insightQuestionTypes.TEMPLATE) {
          const graph = surveyGraphs.find(sG => sG.id === q.questionId);

          if (graph) {
            return (
              <div className={styles.questionContainerPreview}>
                <Icon
                  type="merged-graph"
                  style={{
                    color: '#5200f1',
                    marginLeft: '5px',
                    marginRight: '5px',
                    fontSize: '10px'
                  }}
                />
                {graph.question}
              </div>
            );
          }
        }

        return [];
      });
      return previews;
    }
    return [];
  };

  return (
    <div className={styles.container}>
      {survey.insightsData.insights.map((iQ, iQi) => (
        <div
          key={`insight-${iQi.toString()}`}
          className={
            iQ && activeInsightId && iQ._id === activeInsightId
              ? `${styles.insightContainer} ${styles.active}`
              : styles.insightContainer
          }
          role="presentation"
          onClick={() => {
            if (iQ && activeInsightId && iQ._id === activeInsightId) {
              setActiveInsightId(null);
              onSetActiveFilters([]);
            } else {
              setActiveInsightId(iQ._id);
              if (
                iQ &&
                iQ.filters &&
                iQ.filters.activeFilters &&
                iQ.filters.activeFilters.length
              ) {
                // Change filterRelation if necessary
                if (
                  iQ.filters.filterRelation &&
                  iQ.filters.filterRelation !== filtersRelation
                ) {
                  onSetFiltersRelation(iQ.filters.filterRelation);
                }

                const newActiveFilters = processInsightFilterBeforeApply(
                  iQ.filters.activeFilters
                );
                onSetActiveFilters(newActiveFilters);
              } else {
                onSetActiveFilters([]);
              }

              if (
                iQ &&
                iQ.questionSettings &&
                iQ.questionSettings.activeSorting
              ) {
                setActiveSorting(iQ.questionSettings.activeSorting);
              }
            }
          }}
        >
          <div className={styles.name}>{iQ.name}</div>
          <div className={styles.description}>
            <Draft block={{ question: iQ.description }} />
          </div>
          <div className={styles.baseQuestionContainer}>
            <span className={styles.basedOnDataText}>Based on data</span>{' '}
            {getQuestionPreviews(iQ)}
          </div>
          {!viewToken ? (
            <div className={styles.showActionDropdownButton}>
              ...
              <div className={styles.actionDropdownContainer}>
                <div
                  className={styles.actionDropdownContainerOption}
                  role="presentation"
                  onClick={e => {
                    e.stopPropagation();
                    onEditClick(iQ);
                  }}
                >
                  Edit
                </div>
                <div
                  className={styles.actionDropdownContainerOption}
                  role="presentation"
                  onClick={e => {
                    e.stopPropagation();
                    setConfirmationPopup({
                      actionLabel:
                        'Are you sure you want to delete this insight?',
                      confirmLabel: 'Delete',
                      cancelLabel: 'Cancel',
                      onConfirm: async () => {
                        await onDeleteClick(iQ._id);
                        setConfirmationPopup(null);
                      }
                    });
                  }}
                >
                  Delete
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ))}
      {confirmationPopup ? (
        <Popup
          component={
            <ConfirmationDialog
              label={confirmationPopup.actionLabel}
              confirmLabel={confirmationPopup.confirmLabel}
              cancelLabel={confirmationPopup.cancelLabel}
              onConfirm={() => {
                confirmationPopup.onConfirm();
              }}
              onCancel={() => {
                setConfirmationPopup(null);
              }}
            />
          }
          onClose={() => {
            setConfirmationPopup(null);
          }}
        />
      ) : null}
    </div>
  );
};
