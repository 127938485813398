export const ACQUISITION_METRICS_CHANNEL_TYPES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE'
};

export const ACQUISITION_METRICS_PROPERTIES = {
  CHANNEL: 'channel',
  LAUNCH_DATE: 'launchDate',
  PARTNER: 'partner',
  MEDIUM: 'medium',
  ACTION: 'action'
};
