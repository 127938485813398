import React, { useState, useEffect, useRef } from 'react';
import Icon from '../../../Icon';
import blackArrowDown from '../assets/black-arrow-down.svg';

import styles from './FilterDropdownSelector.module.css';

const FilterDropdownSelector = props => {
  const {
    options,
    alreadySelectedOptions,
    onSelectCompleted,
    accountType,
    accountTypes,
    searchRecords,
    level
  } = props;

  const [selectedOptions, setSelectedOptions] = useState(
    alreadySelectedOptions || []
  );
  const [showPopup, setShowPopup] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(options);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isMaxReached, setIsMaxReached] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(
    () => {
      setSelectedOptions(alreadySelectedOptions || []);
    },
    [alreadySelectedOptions]
  );

  const onInputSearch = input => {
    if (
      accountType.value === accountTypes.teams.value ||
      accountType.value === accountTypes.customers.value
    ) {
      setIsMaxReached(false);
      setInputSearch(input);
      fetchOptionsExecute(0, input, true);
    } else {
      setAvailableOptions(
        options.filter(client => client.label.includes(input))
      );
    }
  };

  const isClientSelected = (clientsList, client) => {
    if (client && client.value && clientsList && clientsList.length) {
      const clientAccount = clientsList.filter(
        selectedClient => selectedClient.value === client.value
      );

      if (clientAccount && clientAccount.length) return true;
    }
    return false;
  };

  const findClientIndexInSelected = clientId =>
    selectedOptions.findIndex(client => client.value === clientId);

  const selectedClientsLabel = list => {
    if (!list || !list.length) {
      return `Choose ${
        accountType.value === accountTypes.teams.value ? 'an' : 'a'
      } ${accountType.label.toLowerCase()}`;
    }

    if (list.length === 1) {
      return list[0].label;
    }

    if (list.length > 1) {
      return `${list.length} ${accountType.label.toLowerCase()}s selected`;
    }

    return null;
  };

  const fetchOptionsExecute = (targetPage, inputFieldSearch, forceSearch) => {
    const runFetchOptions = async () => {
      const searchString =
        inputFieldSearch || inputFieldSearch === ''
          ? inputFieldSearch
          : inputSearch;
      setPage(targetPage);
      const fetchedOptions = await searchRecords(
        targetPage,
        searchString,
        level
      );

      if (
        availableOptions &&
        fetchedOptions &&
        availableOptions.length === fetchedOptions.length
      ) {
        setIsMaxReached(true);
      }

      setAvailableOptions(fetchedOptions);
      setIsFetching(false);
    };

    if (
      searchRecords &&
      !isFetching &&
      (!isMaxReached || forceSearch === true)
    ) {
      setIsFetching(true);
      runFetchOptions();
    }
  };

  const listOptionsDivScroll = async event => {
    if (accountType.value !== accountTypes.clients.value) {
      const elem = event.currentTarget;
      if (elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight) {
        fetchOptionsExecute(page + 1, inputSearch);
      }
    }
  };

  useEffect(
    () => () => {
      if (accountType.value !== accountTypes.clients.value) {
        setAvailableOptions([]);
      }
    },
    []
  );

  useEffect(
    () => {
      if (availableOptions.length !== options.length) {
        setAvailableOptions(options);
      }
    },
    [options]
  );

  const emptyState = () => {
    setPage(0);
    setInputSearch('');
    setAvailableOptions([]);
    setIsMaxReached(false);
  };

  useEffect(
    () => {
      if (accountType.value !== accountTypes.clients.value) {
        if (showPopup) {
          fetchOptionsExecute(0);
        } else {
          emptyState();
        }
      }
    },
    [showPopup]
  );

  const handleClickOutside = event => {
    if (
      !(
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target)
      )
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.filterDropdownSelectorContainer}>
      <div
        role="presentation"
        className={styles.selectedClientsLabel}
        style={{ backgroundImage: `url(${blackArrowDown})` }}
        onClick={() => setShowPopup(!showPopup)}
      >
        {selectedClientsLabel(selectedOptions)}
      </div>
      {showPopup ? (
        <div className={styles.filterDropdownPopup} ref={wrapperRef}>
          <div className={styles.filterDropdownPopupSearchInputContainer}>
            <input
              onChange={e => {
                setInputSearch(e.target.value);
                onInputSearch(e.target.value);
              }}
              placeholder="Search..."
              value={inputSearch}
              /* eslint-disable */
              autoFocus
              /* eslint-enable */
            />
          </div>
          <div
            className={styles.filterDropdownPopupItemsContainer}
            onScroll={listOptionsDivScroll}
          >
            {availableOptions && availableOptions.length ? (
              availableOptions.map((client, index) => (
                <div
                  className={styles.filterDropdownItem}
                  key={`filter-dropdown-item-${index.toString()}`}
                  role="presentation"
                  onClick={() => {
                    let newSelectedClients = selectedOptions;

                    if (isClientSelected(selectedOptions, client)) {
                      const clientIndex = findClientIndexInSelected(
                        client.value
                      );
                      newSelectedClients.splice(clientIndex, 1);
                    } else {
                      newSelectedClients = [...selectedOptions, ...[client]];
                    }
                    setSelectedOptions(
                      JSON.parse(JSON.stringify(newSelectedClients))
                    );
                  }}
                >
                  <span
                    className={`${
                      isClientSelected(selectedOptions, client)
                        ? styles.filterDropdownItemCheckboxSelected
                        : styles.filterDropdownItemCheckbox
                    } check-icon`}
                  >
                    <Icon
                      type={
                        isClientSelected(selectedOptions, client)
                          ? 'checked'
                          : 'unchecked'
                      }
                    />
                  </span>
                  <div className={styles.filterDropdownItemLabel}>
                    {client.label}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noAvailableClientAccounts}>
                {`No available ${accountType.label.toLowerCase()}s`}
              </div>
            )}
          </div>
          <div className={styles.filterDropdownPopupFooterContainer}>
            <div
              className={styles.filterDropdownPopupConfirm}
              role="presentation"
              onClick={() => {
                onSelectCompleted(accountType, selectedOptions);
                setShowPopup(false);
                setInputSearch('');
                setAvailableOptions(options);
              }}
            >
              Done
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterDropdownSelector;
