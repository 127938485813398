import React from 'react';
import { withRouter } from 'react-router-dom';

import greyWave from '../../../assets/img/grey-wave.svg';

import styles from './Footer.module.css';

const Footer = ({ strings }) => (
  <footer
    className={styles.container}
    style={{ backgroundImage: `url(${greyWave})` }}
  >
    <div className={styles.contactContainer}>
      <div className={`${styles.text} roboto-medium-font`}>
        {strings.ANY_QUESTIONS_OR_SUGGESTIONS}
      </div>
      <div
        className={`${styles.button} roboto-medium-font`}
        role="presentation"
        onClick={() => {
          window.location.href = 'https://landing.buffl.be/contact/';
        }}
      >
        {strings.CONTACT_US}
      </div>
    </div>
  </footer>
);

export default withRouter(Footer);
