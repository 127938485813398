import React, { useState, memo } from 'react';
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML
} from 'draft-js';

import {
  DECISION_FRAMEWORK_TYPES,
  SURVEY_LANGUAGES
} from '../../../../../../../../helpers/constants';
import questionEnStrings from '../../../../../../strings/en';
import questionNlStrings from '../../../../../../strings/nl';
import questionFrStrings from '../../../../../../strings/fr';

import Dropdown from './components/Fields/Dropdown/Dropdown';
import InputField from './components/Fields/InputField/InputField';
import Draft from '../../../Draft/Draft';

import styles from './Matrix.module.css';

export default memo(
  ({
    question,
    actions,
    isActiveQuestion,
    addDraftEmoji,
    setAddDraftEmoji,
    setActiveDraftButtons,
    toggleDraftButton,
    setToggleDraftButton,
    surveyLanguage
  }) => {
    const [activeDraftField, setActiveDraftField] = useState(null);
    const [triggerDraftValueReset, setTriggerDraftValueReset] = useState([]); // X/Y question Draft fields receive a new value when changing framework type and Draft components need to be reset in this case

    const decisionFrameworkLabels = {
      [DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH]: 'Problem validation',
      [DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH]: 'Idea selection',
      [DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH]: 'Concept exploration',
      [DECISION_FRAMEWORK_TYPES.CONCEPT_TEST_GRAPH]: 'Concept validation',
      [DECISION_FRAMEWORK_TYPES.CUSTOM_GRAPH]: 'Custom framework',
      [DECISION_FRAMEWORK_TYPES.SINGLE]: 'None (single matrix)'
    };

    const xMatrixLabelProperty = 'xLabel';
    const yMatrixLabelProperty = 'yLabel';
    const xMatrixQuestionProperty = 'xQuestion';
    const yMatrixQuestionProperty = 'yQuestion';

    let strings;
    switch (surveyLanguage) {
      case SURVEY_LANGUAGES.DUTCH:
        strings = questionNlStrings;
        break;
      case SURVEY_LANGUAGES.FRENCH:
        strings = questionFrStrings;
        break;
      default:
        strings = questionEnStrings;
    }

    const isSingleType =
      question.matrix &&
      question.matrix.decisionFrameworkType === DECISION_FRAMEWORK_TYPES.SINGLE;

    return (
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.label}>Select decision framework</div>
          <Dropdown
            value={
              question.matrix &&
              question.matrix.decisionFrameworkType &&
              decisionFrameworkLabels[question.matrix.decisionFrameworkType]
                ? decisionFrameworkLabels[question.matrix.decisionFrameworkType]
                : null
            }
            options={Object.entries(decisionFrameworkLabels).map(
              ([key, value]) => ({
                key,
                value
              })
            )}
            onSelectCompleted={key => {
              const previousDecisionFrameworkType =
                question.matrix.decisionFrameworkType;

              actions.updateMatrixQuestionProperty(
                'decisionFrameworkType',
                key
              );
              actions.updateMatrixQuestionProperty(
                xMatrixLabelProperty,
                strings[key].X_MATRIX_LABEL
              );
              actions.updateMatrixQuestionProperty(
                yMatrixLabelProperty,
                strings[key].Y_MATRIX_LABEL
              );

              const xQuestionFromHTML = convertFromHTML(
                strings[key].X_MATRIX_QUESTION
              );
              const defaultXQuestionText = JSON.stringify(
                convertToRaw(
                  EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      xQuestionFromHTML.contentBlocks,
                      xQuestionFromHTML.entityMap
                    )
                  ).getCurrentContent()
                )
              );
              actions.updateMatrixQuestionProperty(
                xMatrixQuestionProperty,
                defaultXQuestionText
              );

              const yQuestionFromHTML = convertFromHTML(
                strings[key].Y_MATRIX_QUESTION
              );
              const defaultYQuestionText = JSON.stringify(
                convertToRaw(
                  EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      yQuestionFromHTML.contentBlocks,
                      yQuestionFromHTML.entityMap
                    )
                  ).getCurrentContent()
                )
              );
              actions.updateMatrixQuestionProperty(
                yMatrixQuestionProperty,
                defaultYQuestionText
              );

              // Apply new received default value to Draft fields
              setTriggerDraftValueReset([
                xMatrixQuestionProperty,
                yMatrixQuestionProperty
              ]);

              const newQuestion = {
                ...question,
                matrix: {
                  ...question.matrix,
                  decisionFrameworkType: key,
                  [xMatrixLabelProperty]: strings[key].X_MATRIX_LABEL,
                  [yMatrixLabelProperty]: strings[key].Y_MATRIX_LABEL,
                  [xMatrixQuestionProperty]: defaultXQuestionText,
                  [yMatrixQuestionProperty]: defaultYQuestionText
                }
              };

              actions.changeMatrixFrameworkType(
                newQuestion,
                previousDecisionFrameworkType
              );
            }}
          />
        </div>
        <div className={`${styles.row} ${styles.flexRow}`}>
          <div className={!isSingleType ? styles.flexColumn : styles.flexGrow}>
            <div className={styles.label}>{`Label ${
              !isSingleType ? 'x-axis' : ''
            }`}</div>
            <InputField
              value={(question.matrix && question.matrix.xLabel) || ''}
              onChange={value => {
                actions.updateMatrixQuestionProperty(
                  xMatrixLabelProperty,
                  value
                );
              }}
            />
          </div>
          {!isSingleType && (
            <div className={styles.flexColumn}>
              <div className={styles.label}>Label y-axis</div>
              <InputField
                value={(question.matrix && question.matrix.yLabel) || ''}
                onChange={value => {
                  actions.updateMatrixQuestionProperty(
                    yMatrixLabelProperty,
                    value
                  );
                }}
              />
            </div>
          )}
        </div>
        <div
          onBlur={() => {
            if (activeDraftField) {
              setActiveDraftField(null);
            }
          }}
        >
          <div className={styles.row}>
            <div className={styles.label}>{`Survey question ${
              !isSingleType ? 'linked to x-axis' : ''
            }`}</div>
            <div
              className={`${styles.field} ${styles.draftField}`}
              role="presentation"
              onFocus={() => setActiveDraftField(xMatrixQuestionProperty)}
            >
              <Draft
                addDraftEmoji={addDraftEmoji}
                setAddDraftEmoji={setAddDraftEmoji}
                toggleDraftButton={
                  activeDraftField === xMatrixQuestionProperty
                    ? toggleDraftButton
                    : null
                }
                setToggleDraftButton={setToggleDraftButton}
                setActiveDraftButtons={setActiveDraftButtons}
                isActiveQuestion={
                  isActiveQuestion &&
                  activeDraftField === xMatrixQuestionProperty
                }
                value={(question.matrix && question.matrix.xQuestion) || ''}
                onChangeQuestionProperty={(_property, value) =>
                  actions.updateMatrixQuestionProperty(
                    xMatrixQuestionProperty,
                    value
                  )
                }
                matrixQuestionKey={xMatrixQuestionProperty}
                triggerValueReset={triggerDraftValueReset}
                setTriggerValueReset={setTriggerDraftValueReset}
              />
            </div>
          </div>
          {!isSingleType && (
            <div className={styles.row}>
              <div className={styles.label}>
                Survey question linked to y-axis
              </div>
              <div
                className={`${styles.field} ${styles.draftField}`}
                role="presentation"
                onFocus={() => setActiveDraftField(yMatrixQuestionProperty)}
              >
                <Draft
                  addDraftEmoji={addDraftEmoji}
                  setAddDraftEmoji={setAddDraftEmoji}
                  toggleDraftButton={
                    activeDraftField === yMatrixQuestionProperty
                      ? toggleDraftButton
                      : null
                  }
                  setToggleDraftButton={setToggleDraftButton}
                  setActiveDraftButtons={setActiveDraftButtons}
                  isActiveQuestion={
                    isActiveQuestion &&
                    activeDraftField === yMatrixQuestionProperty
                  }
                  value={(question.matrix && question.matrix.yQuestion) || ''}
                  onChangeQuestionProperty={(_property, value) =>
                    actions.updateMatrixQuestionProperty(
                      yMatrixQuestionProperty,
                      value
                    )
                  }
                  matrixQuestionKey={yMatrixQuestionProperty}
                  triggerValueReset={triggerDraftValueReset}
                  setTriggerValueReset={setTriggerDraftValueReset}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
