import React, { useState, useEffect } from 'react';
import Icon from '../../../campaigns/components/Icon';
import Loader from '../../../campaigns/components/Loader/Loader';
import DebounceInput from '../../../campaigns/components/DebounceInput/DebounceInput';
import blackArrowDown from './assets/black-arrow-down.svg';

import getAdditionalFetchFiltersByName from '../helpers/getAdditionalFetchFiltersByName';

import styles from './DropdownSelector.module.css';

export default props => {
  const {
    options,
    fetchOptions,
    value,
    onSelectCompleted,
    multiple,
    placeholder,
    search,
    filterDropdownStyle,
    dropdownPopupStyle,
    popupAlign,
    showLoading,
    filterName,
    subFilter,
    activeFiltersLength
  } = props;

  let wrapperRef = null;

  const [showPopup, setShowPopup] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(0);
  /* eslint-disable */
  const [isFetching, setIsFetching] = useState(false);
  /* eslint-enable */
  const [availableOptions, setAvailableOptions] = useState(options || []);

  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setShowPopup(false);
    }
  };

  const fetchOptionsExecute = (targetPage, inputFieldSearch) => {
    const runFetchOptions = async () => {
      const searchString =
        inputFieldSearch || inputFieldSearch === ''
          ? inputFieldSearch
          : inputSearch;
      setPage(targetPage);

      const fetchedOptions = await fetchOptions(
        targetPage,
        searchString,
        getAdditionalFetchFiltersByName(
          filterName,
          subFilter,
          activeFiltersLength
        )
      );
      setAvailableOptions(fetchedOptions);
      setIsFetching(false);
    };

    if (fetchOptions) {
      setIsFetching(true);
      runFetchOptions();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    fetchOptionsExecute(0);
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener('mousedown', handleClickOutside);
    },
    []
  );

  useEffect(() => setAvailableOptions(options), [options]);

  const selectValue = option => {
    onSelectCompleted(option.value, option.label);
    if (!multiple) {
      setShowPopup(false);
    }
  };

  const checkIsMultipleSelected = singleValue => {
    if (multiple && value && value.value && value.value.length) {
      const checkedFilter = value.value.filter(
        checkedValue => checkedValue.value === singleValue.value
      );
      if (checkedFilter && checkedFilter.length) {
        return true;
      }
      return false;
    }
    return false;
  };

  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    if (elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight) {
      fetchOptionsExecute(page + 1, inputSearch);
    }
  };

  const onInputSearchChangeValue = searchValue => {
    setInputSearch(searchValue);
    fetchOptionsExecute(0, searchValue);
  };

  return (
    <div
      className={filterDropdownStyle || styles.filterDropdownSelectorContainer}
      ref={elem => {
        if (elem) {
          wrapperRef = elem;
        }
      }}
    >
      <div
        role="presentation"
        className={styles.selectedOptionsLabel}
        style={{ backgroundImage: `url(${blackArrowDown})` }}
        onClick={() => setShowPopup(!showPopup)}
      >
        {value &&
        (value.value === '' || !value.value || value.value.length === 0)
          ? placeholder
          : value.label}
      </div>
      {showPopup ? (
        <div
          className={dropdownPopupStyle || styles.filterDropdownPopup}
          style={popupAlign ? { [popupAlign]: '0px' } : { left: '0px' }}
        >
          {search ? (
            <div className={styles.filterDropdownPopupSearchInputContainer}>
              <DebounceInput
                initialValue={inputSearch}
                onChangeValue={searchValue =>
                  onInputSearchChangeValue(searchValue)
                }
              />
              {showLoading && isFetching && inputSearch !== '' ? (
                <span className={styles.debouceInputLoader}>
                  <Loader />
                </span>
              ) : null}
            </div>
          ) : null}
          <div
            className={styles.filterDropdownPopupItemsContainer}
            onScroll={listOptionsDivScroll}
          >
            {showLoading &&
            isFetching &&
            (availableOptions && availableOptions.length === 0) ? (
              <Loader />
            ) : null}
            {availableOptions && availableOptions.length ? (
              availableOptions.map((option, index) => (
                <div
                  className={styles.filterDropdownItem}
                  key={`filter-dropdown-item-${index.toString()}`}
                  role="presentation"
                  onClick={() => selectValue(option)}
                >
                  {multiple ? (
                    <span
                      className={`${
                        checkIsMultipleSelected(option)
                          ? styles.filterDropdownItemCheckboxSelected
                          : styles.filterDropdownItemCheckbox
                      } check-icon`}
                    >
                      <Icon
                        type={
                          checkIsMultipleSelected(option)
                            ? 'checked'
                            : 'unchecked'
                        }
                      />
                    </span>
                  ) : null}
                  <div className={styles.filterDropdownItemLabel}>
                    {option && option.label ? option.label : ''}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noAvailableClientAccounts}>
                No available options
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
