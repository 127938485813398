import React from 'react';

import {
  COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES,
  USER_PROPERTIES,
  USER_PROPERTY_NAMES
} from '../../../../../../helpers/constants';
import { USER_PROPERTIES as FEASIBILITY_CHECKER_USER_PROPERTIES } from '../../../../../../../FeasibilityChecker/helpers/constants';

import getUserPropertiesLabels from '../../../../../../helpers/getUserPropertiesLabels';

import SearchDropdown from '../SearchDropdown/SearchDropdown';

import deleteIcon from '../../../../../../../../assets/img/delete.svg';

import styles from './UserPropertiesFilter.module.css';
import MultipleSearchDropdown from '../MultipleSearchDropdown/MultipleSearchDropdown';

export default ({
  singleFilterIndex,
  appliedFilters,
  setAppliedFilters,
  onFilterSelectCompleted,
  onTypeChange,
  getTypeOptions,
  userPropertiesMapping,
  showQuota,
  isFeasibilityChecker,
  onSelectAllClick
}) => {
  const singleFilter = appliedFilters[singleFilterIndex];

  const searchPropertyNames = async () =>
    Object.values(
      isFeasibilityChecker
        ? FEASIBILITY_CHECKER_USER_PROPERTIES
        : USER_PROPERTIES
    ).map(uP => ({
      value: uP,
      label: USER_PROPERTY_NAMES[uP]
    }));

  const searchPropertyValues = async (page, search) => {
    if (
      singleFilter &&
      singleFilter.userProperties &&
      singleFilter.userProperties.propertyName
    ) {
      const unfilteredResults =
        userPropertiesMapping[singleFilter.userProperties.propertyName];

      if (!search || search === '') {
        return unfilteredResults;
      }

      return unfilteredResults.filter(r => r.label.includes(search));
    }

    return [];
  };

  return (
    <div className={styles.container}>
      <SearchDropdown
        fetchOptions={getTypeOptions}
        placeholder="Select a filter type"
        onSelectCompleted={value => onTypeChange(value)}
        value={{
          value: singleFilter.type,
          label: COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[singleFilter.type]
        }}
        search={false}
        hideNoValueOption
      />
      <SearchDropdown
        fetchOptions={searchPropertyNames}
        placeholder="Property name"
        onSelectCompleted={value =>
          onFilterSelectCompleted('propertyName', value, 'userProperties')
        }
        value={{
          value:
            singleFilter &&
            singleFilter.userProperties &&
            singleFilter.userProperties.propertyName,
          label:
            singleFilter &&
            singleFilter.userProperties &&
            USER_PROPERTY_NAMES[singleFilter.userProperties.propertyName]
        }}
        search={false}
      />
      <MultipleSearchDropdown
        fetchOptions={searchPropertyValues}
        placeholder="Property value"
        onSelectCompleted={value =>
          onFilterSelectCompleted(
            'propertyValue',
            value,
            'userProperties',
            true
          )
        }
        value={{
          value:
            singleFilter &&
            singleFilter.userProperties &&
            singleFilter.userProperties.propertyValue,
          label:
            singleFilter &&
            singleFilter.userProperties &&
            singleFilter.userProperties.propertyValue &&
            getUserPropertiesLabels(singleFilter.userProperties.propertyValue)
              ? getUserPropertiesLabels(
                  singleFilter.userProperties.propertyValue
                )
              : singleFilter.userProperties.propertyValue
        }}
        onSelectAllClick={(values, allValuesSelected) =>
          onSelectAllClick(
            'propertyValue',
            values,
            'userProperties',
            allValuesSelected
          )
        }
        fixedWidth={200}
        showQuotaFields={
          showQuota &&
          !(
            singleFilter &&
            singleFilter.userProperties &&
            singleFilter.userProperties.propertyName &&
            [
              USER_PROPERTIES.AGE,
              USER_PROPERTIES.GENDER,
              USER_PROPERTIES.LANGUAGE
            ].includes(singleFilter.userProperties.propertyName)
          )
        }
        quotaValues={
          (singleFilter &&
            singleFilter.userProperties &&
            singleFilter.userProperties.quota) ||
          []
        }
        onQuotaChange={quotaValues => {
          onFilterSelectCompleted('quota', quotaValues, 'userProperties');
        }}
      />
      <img
        src={deleteIcon}
        className={styles.deleteIcon}
        onClick={() => {
          const newAppliedFilters = appliedFilters.filter(
            (aF, i) => i !== singleFilterIndex
          );
          setAppliedFilters(newAppliedFilters);
        }}
        alt="Delete filter"
        role="presentation"
      />
    </div>
  );
};
