import React from 'react';
import { withRouter } from 'react-router-dom';

import LoaderAnimation from '../LoaderAnimation/LoaderAnimation';
import './Loader.css';

const Loader = () => (
  <div className="loader-fixed-container">
    <LoaderAnimation />
  </div>
);
export default withRouter(Loader);
