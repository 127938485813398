import { connect } from 'react-redux';
import * as actions from '../actions';
import { setClientStarredSurvey } from '../../auth/actions';
import CampaignTable from '../components/CampaignTable/CampaignTable';

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  isProcessing: state.campaigns.isProcessing,
  isFetching: state.campaigns.isFetching,
  view: state.campaigns.portal.view,
  pages: state.campaigns.portal.otherCampaigns.total
    ? Math.ceil(
        state.campaigns.portal.otherCampaigns.total /
          state.campaigns.portal.otherCampaigns.rowsPerPage
      )
    : null,
  selectedCampaign: state.campaigns.portal.selectedCampaign,
  currentPage: state.campaigns.portal.otherCampaigns.page,
  totalCampaigns:
    state.campaigns.portal.starredCampaigns.total +
    state.campaigns.portal.otherCampaigns.total,
  starredCampaigns: state.campaigns.portal.starredCampaigns,
  otherCampaigns: state.campaigns.portal.otherCampaigns,
  starredCampaignIds: state.campaigns.portal.starredCampaignIds,
  availableClients: state.campaigns.portal.availableClients,
  rowsPerPage: state.campaigns.portal.otherCampaigns.rowsPerPage,
  filter: state.campaigns.portal.filter,
  clientEmail: state.auth.clientEmail,
  accountManagement: state.auth.accountManagement
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: page => dispatch(actions.getCampaigns(page)),
  setClientStarredSurvey: (campaign, star) =>
    dispatch(setClientStarredSurvey(campaign, star)),
  onChangePage: pageNumber => dispatch(actions.changeCampaignsPage(pageNumber)),
  onChangeView: view => dispatch(actions.changeCampaignsView(view)),
  onSelectCampaign: campaign => dispatch(actions.selectCampaign(campaign)),
  onBlurSelectedCampaign: () => dispatch(actions.onBlurSelectedCampaign()),
  onDeleteCampaign: id => dispatch(actions.deleteCampaign(id)),
  onCreateCampaign: () => dispatch(actions.createCampaign(ownProps.history)),
  onEditCampaign: campaign => {
    dispatch(actions.editCampaign(campaign));
    ownProps.history.push(`/survey/${campaign.id}/edit`);
  },
  onShowResults: campaign => {
    dispatch(actions.showCampaignResults());
    ownProps.history.push(`/campaign/${campaign.id}/results`);
  },
  onPublishCampaign: id => dispatch(actions.publishCampaign(id)),
  onUnpublishCampaign: id => dispatch(actions.unpublishCampaign(id)),
  onPayCampaign: id => dispatch(actions.payCampaign(id)),
  onWithdrawApprovalRequest: id =>
    dispatch(actions.withdrawApprovalRequest(id)),
  onDuplicateCampaign: id => dispatch(actions.duplicateCampaign(id)),
  onSearchCampaigns: search => dispatch(actions.searchCampaigns(search)),
  onChangeColumnSort: (sortColumn, sortType, campaignsPath) =>
    dispatch(actions.onChangeColumnSort(sortColumn, sortType, campaignsPath)),
  onChangeRowsPerPage: rowsPerPage =>
    dispatch(actions.onChangeRowsPerPage(rowsPerPage)),
  onSetCampaignFilters: filter => dispatch(actions.onSetCampaignFilters(filter))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignTable);
