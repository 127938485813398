import * as actions from './actions';
import generateSurveyLists from '../helpers/generateSurveyLists/generateSurveyLists';

const initialState = {
  survey: null,
  activeFlow: null,
  surveyLists: null,
  customLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_NEW_STATE:
      return action.newState;
    case actions.EMPTY_SURVEY_STATE:
      return { ...initialState };
    case actions.SET_CUSTOM_LOADING:
      return {
        ...state,
        customLoading: action.customLoading
      };
    case actions.SET_SURVEY:
      return {
        ...state,
        survey: action.survey,
        surveyLists: generateSurveyLists(
          action.survey.content,
          action.survey.questions
        )
      };
    case actions.UPDATE_SURVEY: {
      const newSurvey = { ...state.survey };
      newSurvey[action.property] = action.value;

      return {
        ...state,
        survey: newSurvey
      };
    }
    case actions.SET_ACTIVE_FLOW_COMPLETED:
      return {
        ...state,
        activeFlow: action.flow,
        surveyLists: action.surveyLists
      };
    case actions.SET_ACTIVE_FLOW_FROM_ID: {
      const selectedFlow = Object.values(state.survey.questions).find(
        q => q.id === action.flowId
      );

      if (!selectedFlow) return state;

      return {
        ...state,
        activeFlow: selectedFlow
      };
    }
    case actions.TOGGLE_SURVEY_BUILDER_POPUP: {
      if (action === {}) {
        return {
          ...state,
          surveyBuilderPopup: null
        };
      }

      return {
        ...state,
        surveyBuilderPopup: action.surveyBuilderPopup
      };
    }
    case actions.UPDATE_QUESTION_PROPERTY: {
      const newActiveFlow = {
        ...state.activeFlow,
        [action.property]: action.value
      };

      return {
        ...state,
        survey: {
          ...state.survey,
          questions: {
            ...state.survey.questions,
            [newActiveFlow.id]: newActiveFlow
          }
        },
        activeFlow: newActiveFlow
      };
    }
    case actions.UPDATE_CUSTOM_QUESTION_PROPERTY: {
      // Used for tests
      const newState = state;

      if (action.flowId && newState.survey.questions) {
        newState.survey.questions[action.flowId][action.property] =
          action.value;
      }
      return state;
    }
    case actions.UPDATE_MATRIX_QUESTION_PROPERTY: {
      const newActiveFlow = {
        ...state.activeFlow,
        matrix: {
          ...state.activeFlow.matrix,
          [action.property]: action.value
        }
      };

      return {
        ...state,
        survey: {
          ...state.survey,
          questions: {
            ...state.survey.questions,
            [newActiveFlow.id]: newActiveFlow
          }
        },
        activeFlow: newActiveFlow
      };
    }
    case actions.UPDATE_GROUP_PROPERTY: {
      const groupIndex = state.survey.content.groups.findIndex(
        g => g.id === action.groupId
      );

      const newState = state;

      newState.survey.content.groups[groupIndex] = {
        ...state.survey.content.groups[groupIndex],
        [action.property]: action.value
      };

      return {
        ...state,
        survey: {
          ...state.survey,
          content: {
            ...state.survey.content,
            groups: newState.survey.content.groups
          }
        }
      };
    }

    default:
      return state;
  }
};
