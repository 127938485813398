import React, { useState, useRef } from 'react';

import QuestionsSelectorDropdownPopup from './components/QuestionsSelectorDropdownPopup/QuestionsSelectorDropdownPopup';
import blackArrowDown from '../../../../../../../../assets/img/black-arrow-down.svg';

import styles from './QuestionsSelectorDropdown.module.css';

const QuestionsSelectorDropdown = props => {
  const {
    options = [],
    onSelectCompleted,
    placeholder,
    setFeaturesScrollingDisabled,
    internalData,
    setInternalData
  } = props;

  const [showDropdownPopup, setShowDropdownPopup] = useState(false);
  const dropdownParentRef = useRef();

  const page = [];

  const questionsSelected =
    internalData && internalData.questions && internalData.questions.length;

  page.push(
    <div
      key="population-label-dropdown-placeholder"
      className={
        questionsSelected > 1
          ? styles.placeholderContainer
          : `${styles.placeholderContainer} ${styles.emptyPlaceholderContainer}`
      }
      style={{ backgroundImage: `url(${blackArrowDown})` }}
      onClick={() => {
        setShowDropdownPopup(true);
        setFeaturesScrollingDisabled(true);
      }}
      role="presentation"
    >
      <span>
        {questionsSelected > 1
          ? `${questionsSelected - 1} questions selected`
          : placeholder}
      </span>
    </div>
  );

  if (showDropdownPopup) {
    page.push(
      <QuestionsSelectorDropdownPopup
        internalData={internalData}
        setInternalData={setInternalData}
        key="population-label-dropdown-placeholder-popup"
        options={options}
        onSelectCompleted={onSelectCompleted}
        hideDropdownPopup={() => {
          setShowDropdownPopup(false);
          setFeaturesScrollingDisabled(false);
        }}
        setFeaturesScrollingDisabled={setFeaturesScrollingDisabled}
        emptyListPlaceholder={`You need at least two multiple choice or rating scale\n questions to create a data point.`}
        dropdownParentRef={dropdownParentRef}
        marginLeft={297}
      />
    );
  }

  return (
    <div className={styles.dropdownContainer} ref={dropdownParentRef}>
      {page}
    </div>
  );
};

export default QuestionsSelectorDropdown;
