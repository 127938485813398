import React from 'react';
import { withRouter } from 'react-router-dom';

import './LoaderAnimation.css';

const LoaderAnimation = () => (
  <div className="loading-animation">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default withRouter(LoaderAnimation);
