import React, { useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import uuid from 'uuid';

import { REGISTER } from '../../../graphql/Auth';
import { GET_BLOCK_UPLOAD_SAS } from '../../../graphql/Organisation';
import styles from './RegistrationPage.module.css';

import LoaderAnimation from '../../../base/components/LoaderAnimation/LoaderAnimation';
import Loader from '../../../base/components/Loader/Loader';

import herdyPickingApples from '../../../assets/img/herdy-picking-apples.svg';
import herdyPickingSomeApples from '../../../assets/img/herdy-picking-some-apples.svg';
import herdyCollectingApples from '../../../assets/img/herdy-collecting-apples.svg';
import bottomBlobBlue from '../../../assets/img/registration-bottom-blob-blue.svg';
import uploadIcon from '../../../assets/img/upload.svg';

const RegistrationPage = props => {
  const { isAuthenticated, strings, language } = props;

  const emptyFields = {
    email: '',
    name: '',
    description: '',
    imageFilename: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
    contactPersonPhone: '',
    password: '',
    repeatPassword: '',
    savingsTarget: '',
    savingsGoal: ''
  };

  const steps = {
    ORGANISATION: {
      fields: ['name', 'email', 'description', 'imageFilename']
    },
    CONTACT_PERSON: {
      fields: [
        'contactPersonFirstName',
        'contactPersonLastName',
        'contactPersonPhone',
        'password',
        'repeatPassword'
      ]
    },
    SAVING_TARGET: {
      fields: ['savingsTarget', 'savingsGoal']
    }
  };

  const registrationSteps = {
    ORGANISATION: 'ORGANISATION',
    CONTACT_PERSON: 'CONTACT_PERSON',
    SAVING_TARGET: 'SAVING_TARGET'
  };

  const inputs = [
    {
      name: 'name',
      label: strings.ORGANISATION_NAME,
      type: 'text'
    },
    {
      name: 'email',
      label: strings.EMAIL_OF_ORGANISATION,
      type: 'email',
      autoComplete: 'email'
    },
    {
      name: 'description',
      label: strings.DESCRIPTION_OF_ORGANISATION,
      type: 'description'
    },
    {
      name: 'contactPersonFirstName',
      label: strings.CONTACT_PERSON_NAME,
      type: 'text'
    },
    {
      name: 'contactPersonLastName',
      label: strings.CONTACT_PERSON_LAST_NAME,
      type: 'text'
    },
    {
      name: 'contactPersonPhone',
      label: strings.CONTACT_PERSON_EMAIL,
      type: 'email'
    },
    {
      name: 'password',
      label: strings.PASSWORD,
      type: 'password'
    },
    {
      name: 'repeatPassword',
      label: strings.REPEAT_PASSWORD,
      type: 'password'
    },
    {
      name: 'savingsTarget',
      label: strings.HOW_MUCH_WOULD_YOU_LIKE_TO_COLLECT,
      type: 'number'
    }
  ];

  const [errors, setErrors] = useState({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [fields, setFields] = useState(emptyFields);
  const [currentStep, setCurrentStep] = useState(
    registrationSteps.ORGANISATION
  );
  const [uploading, setUploading] = useState(false);
  const [originalFilename, setOriginalFilename] = useState('');

  const organisationId = useMemo(() => uuid.v1(), []);

  const { data } = useQuery(GET_BLOCK_UPLOAD_SAS, {
    variables: { organisationId }
  });

  const [register, { loading }] = useMutation(REGISTER, {
    onCompleted: () => setIsCompleted(true)
  });

  const onRegister = () => {
    let registerErrors = {};
    setErrors({});

    Object.keys(fields).forEach(field => {
      if (
        (!fields[field] || fields[field] === '') &&
        steps[currentStep].fields.includes(field)
      ) {
        registerErrors = {
          ...registerErrors,
          ...{ [field]: strings.FIELD_IS_REQUIRED }
        };
      }
    });

    if (
      fields.password !== fields.repeatPassword &&
      steps[currentStep].fields.includes('password')
    ) {
      registerErrors = {
        ...registerErrors,
        ...{ password: strings.PASSWORDS_NOT_SAME }
      };
      registerErrors = {
        ...registerErrors,
        ...{ repeatPassword: strings.PASSWORDS_NOT_SAME }
      };
    }

    if (
      !registerErrors.email &&
      fields.email.length < 5 &&
      steps[currentStep].fields.includes('email')
    ) {
      registerErrors = {
        ...registerErrors,
        ...{ email: strings.EMAIL_SHOULD_HAVE_CHARACTERS }
      };
    }

    if (
      !registerErrors.password &&
      fields.password.length < 5 &&
      steps[currentStep].fields.includes('password')
    ) {
      registerErrors = {
        ...registerErrors,
        ...{ password: strings.PASSWORD_SHOULD_HAVE_CHARACTERS }
      };
    }

    if (
      !registerErrors.savingsGoal &&
      fields.savingsGoal.length > 200 &&
      steps[currentStep].fields.includes('savingsGoal')
    ) {
      registerErrors = {
        ...registerErrors,
        ...{ savingsGoal: strings.SAVINGS_GOAL_CAN_HAVE_CHARACTERS }
      };
    }

    if (Object.keys(registerErrors).length === 0) {
      if (currentStep === registrationSteps.ORGANISATION) {
        setCurrentStep(registrationSteps.CONTACT_PERSON);
      } else if (currentStep === registrationSteps.CONTACT_PERSON) {
        setCurrentStep(registrationSteps.SAVING_TARGET);
      } else {
        fields.savingsTarget = parseInt(fields.savingsTarget, 10);
        register({
          variables: {
            ...fields,
            language: language && language === 'FR' ? 'French' : 'Dutch'
          }
        }).catch(registerError => {
          if (registerError) {
            if (
              registerError &&
              registerError.networkError &&
              registerError.networkError.result &&
              registerError.networkError.result.errors &&
              registerError.networkError.result.errors.length &&
              registerError.networkError.result.errors[0].message ===
                'This email address is already in use'
            ) {
              registerErrors = {
                ...registerErrors,
                ...{ savingsGoal: strings.EMAIL_ALREADY_IN_USE }
              };
              setErrors(registerErrors);
            }
          }
        });
      }
    } else {
      setErrors(registerErrors);
    }
  };

  const onCoverImageUpload = async e => {
    if (data && data.blockUploadSAS) {
      const sasData = data.blockUploadSAS;
      setUploading(true);

      const xhr = new XMLHttpRequest();

      xhr.onload = () => {
        if (xhr.status === 201) {
          const imageFilename =
            sasData && sasData.uri
              ? sasData.uri.replace(/(\?.*)|(#.*)/g, '')
              : null;

          setFields({
            ...fields,
            ...{ imageFilename }
          });
        }
        setUploading(false);
      };

      xhr.open('PUT', sasData.uri);
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

      setOriginalFilename(e.target.files[0].name);
      xhr.send(e.target.files[0]);
    }
  };

  const getFormTitle = () => {
    switch (currentStep) {
      case registrationSteps.ORGANISATION:
        return strings.BECOME_A_PARTNER;

      case registrationSteps.CONTACT_PERSON:
        return strings.CONTACT_PERSON;

      case registrationSteps.SAVING_TARGET:
        return strings.SAVING_TARGET;

      default:
        return strings.BECOME_A_PARTNER;
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.mainContentContainer}>
          <div className={styles.stepsProgressContainer}>
            <div className={styles.stepsProgressContainerLine} />
            <div
              className={`${styles.stepContainer} ${
                currentStep === registrationSteps.ORGANISATION ||
                currentStep === registrationSteps.CONTACT_PERSON ||
                currentStep === registrationSteps.SAVING_TARGET
                  ? styles.stepContainerActive
                  : ''
              }`}
            >
              <div className={styles.circle} />
              <div className={styles.stepLabel}>{strings.ORGANISATION}</div>
            </div>
            <div
              className={`${styles.stepContainer} ${
                currentStep === registrationSteps.CONTACT_PERSON ||
                currentStep === registrationSteps.SAVING_TARGET
                  ? styles.stepContainerActive
                  : ''
              }`}
            >
              <div className={styles.circle} />
              <div className={styles.stepLabel}>{strings.CONTACT_PERSON}</div>
            </div>
            <div
              className={`${styles.stepContainer} ${
                currentStep === registrationSteps.SAVING_TARGET
                  ? styles.stepContainerActive
                  : ''
              }`}
            >
              <div className={styles.circle} />
              <div className={styles.stepLabel}>{strings.SAVING_TARGET}</div>
            </div>
          </div>
        </div>

        {isCompleted ? (
          <div className={styles.form}>
            <h2 className={styles.title}>{strings.CONGRATULATIONS}</h2>
            <div className={styles.label}>
              {strings.ORGANISATION_SUCCESFULLY_REGISTERED}
            </div>
            <div className={styles.label}>{strings.PASS_THE_WORD}</div>
          </div>
        ) : (
          <div className={styles.form}>
            <h2 className={styles.title}>{getFormTitle()}</h2>
            {uploading ? <Loader isFetching={uploading} /> : null}
            <form>
              {inputs.map(
                input =>
                  steps &&
                  steps[currentStep] &&
                  steps[currentStep].fields &&
                  steps[currentStep].fields.includes(input.name) && (
                    <div key={input.name}>
                      <span className={styles.label}>{input.label}</span>
                      {input.name === 'savingsTarget' ? (
                        <span className={styles.euroSign}>€</span>
                      ) : null}
                      <input
                        className={`${styles.input} ${
                          input.name === 'savingsTarget'
                            ? styles.inputSavingsTarget
                            : ''
                        }`}
                        type={input.type}
                        value={fields[input.name]}
                        onChange={e =>
                          setFields({
                            ...fields,
                            ...{ [input.name]: e.currentTarget.value }
                          })
                        }
                        {...(input.autoComplete
                          ? { autoComplete: input.autoComplete }
                          : null)}
                      />
                      {errors[input.name] ? (
                        <span className={styles.error}>
                          {errors[input.name]}
                        </span>
                      ) : null}
                    </div>
                  )
              )}
              {currentStep === registrationSteps.ORGANISATION && (
                <div>
                  <span htmlFor="file-upload" className={styles.label}>
                    {strings.ORGANISATION_LOGO}
                  </span>
                  <label className={styles.logoTextarea} htmlFor="file-upload">
                    <input
                      id="file-upload"
                      type="file"
                      onChange={onCoverImageUpload}
                      hidden
                    />
                    <img
                      src={uploadIcon}
                      alt="upload icon"
                      className={styles.uploadIcon}
                    />
                    {originalFilename && originalFilename.length ? (
                      <span className={styles.fileName}>
                        {originalFilename}
                      </span>
                    ) : (
                      <span>{strings.JPG_OR_PNG}</span>
                    )}
                  </label>
                  {errors.imageFilename ? (
                    <span className={styles.error}>{errors.imageFilename}</span>
                  ) : null}
                </div>
              )}
              {currentStep === registrationSteps.SAVING_TARGET && (
                <div>
                  <span className={styles.label}>
                    {strings.WHAT_WILL_YOU_USE_THE_MONEY_FOR}
                  </span>
                  <span className={styles.textareaCharactersLength}>
                    {fields.savingsGoal.length}/200
                  </span>
                  <textarea
                    className={`${styles.textarea} ${
                      styles.textareaCharacters
                    }`}
                    value={fields.savingsGoal}
                    onChange={e =>
                      setFields({
                        ...fields,
                        ...{ savingsGoal: e.currentTarget.value }
                      })
                    }
                  />
                  {errors.savingsGoal ? (
                    <span className={styles.error}>{errors.savingsGoal}</span>
                  ) : null}
                </div>
              )}
              <div className={styles.buttonContainer}>
                {loading ? (
                  <LoaderAnimation />
                ) : (
                  <div
                    className={styles.button}
                    onClick={() => onRegister()}
                    role="presentation"
                  >
                    {strings.NEXT}
                  </div>
                )}
                <div className={styles.login}>
                  {strings.ALREADY_HAVE_ACCOUNT}{' '}
                  <span
                    className={styles.blueLink}
                    role="presentation"
                    onClick={() => props.history.push(`/login`)}
                  >
                    {strings.LOG_IN}
                  </span>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <div className={styles.bottomBlobWrapper}>
        <img
          className={styles.bottomBlob}
          src={bottomBlobBlue}
          alt="Blue bottom blob"
        />
        {currentStep === registrationSteps.ORGANISATION && (
          <img
            className={styles.herdyPickingApples}
            src={herdyPickingApples}
            alt="Herdy picking apples"
          />
        )}
        {currentStep === registrationSteps.CONTACT_PERSON && (
          <img
            className={styles.herdyPickingApples}
            src={herdyPickingSomeApples}
            alt="Herdy picking apples"
          />
        )}
        {currentStep === registrationSteps.SAVING_TARGET && (
          <img
            className={styles.herdyCollectingApples}
            src={herdyCollectingApples}
            alt="Herdy collecting apples"
          />
        )}
      </div>
    </>
  );
};

export default RegistrationPage;
