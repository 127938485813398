import { put, select, call } from 'redux-saga/effects';

import {
  TEMPLATES_WIZARD_TEMPLATE_TYPES,
  TEMPLATES_WIZARD_STEP_KEYS
} from '../../../helpers/constants';

import prepareSuccessCriteria from './helpers/prepareSuccessCriteria/prepareSuccessCriteria';

import * as actions from '../../actions';

export default function*(action) {
  const surveyState = yield select(state => state.surveys);

  yield put({ type: actions.SET_CUSTOM_LOADING, customLoading: true });

  const newSurveyStructureData = action.wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.LOGIC_MAP
  );

  const newState = {
    ...surveyState,
    survey: {
      ...surveyState.survey,
      content: newSurveyStructureData.value.surveyContent,
      questions: newSurveyStructureData.value.questionsMapping
    },
    surveyLists: newSurveyStructureData.value.surveyLists
  };

  // Add new questions and save survey content
  // Set 0 waiting time to make sure loading is shown because of multiple api invocation in this sagas
  yield call(action.api, 'addQuestions', {
    questionsArray: newSurveyStructureData.value.newQuestions,
    surveyContent: newSurveyStructureData.value.surveyContent
  });

  // Update success criteria for this survey
  const successCriteriaStep = action.wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SET_SUCCESS_CRITERIA
  );

  const updateSuccessCriteria = {
    survey: surveyState.survey.id,
    successCriteria: prepareSuccessCriteria(
      successCriteriaStep.value,
      newSurveyStructureData.value.newQuestions
    )
  };

  // Set 0 waiting time to make sure loading is shown because of multiple api invocation in this sagas
  yield call(action.api, 'updateSuccessCriteria', {
    variables: updateSuccessCriteria
  });

  // Create templates based on wizard steps
  const successTemplateTypeStep = action.wizardSteps.find(
    s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
  );

  let templateApi = null;
  switch (successTemplateTypeStep.value) {
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION:
      templateApi = 'createOpportunityGraph';
      break;
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION:
      templateApi = 'createIdeaSelectionGraph';
      break;
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION:
      templateApi = 'createValueSelectionGraph';
      break;
    case TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST:
      templateApi = 'createConceptTestGraph';
      break;
    default:
  }

  yield call(action.api, templateApi, {
    variables: {
      features: newSurveyStructureData.value.features,
      name: 'Executive summary',
      survey: surveyState.survey.id
    }
  });

  yield put({ type: actions.SET_NEW_STATE, newState });
}
