import { SURVEY_END_TYPES } from '../../../helpers/constants';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';

export default (state, jump) => {
  const newActiveFlow = {
    ...state.activeFlow
  };

  if (
    jump === SURVEY_END_TYPES.SUCCESSFUL ||
    jump === SURVEY_END_TYPES.NOT_ELIGIBLE
  ) {
    if (newActiveFlow.nextFlow) {
      newActiveFlow.nextFlow = null;
    }

    newActiveFlow.end = jump;
  } else {
    if (newActiveFlow.end) {
      newActiveFlow.end = null;
    }

    if (newActiveFlow && newActiveFlow.id !== jump) {
      newActiveFlow.nextFlow = jump;
    }
  }

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      questions: {
        ...state.survey.questions,
        [newActiveFlow.id]: newActiveFlow
      }
    },
    activeFlow: newActiveFlow
  };

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return { newState };
};
