import * as actions from './actions';
import * as mixpanel from '../../mixpanel';

const initialState = {
  isProcessing: false,
  isFetching: false,
  error: null,
  portal: {
    campaignBuilder: {
      sections: [
        {
          name: 'welcome',
          type: 'welcome',
          label: 'Welcome',
          blocks: [
            {
              id: 'welcome',
              name: ''
            }
          ]
        },
        {
          name: 'section-1',
          type: 'questions',
          label: 'Questions',
          blocks: []
        }
      ],
      campaign: null,
      activeBlock: null,
      activeBlockProperties: {
        properties: null,
        actions: null
      },
      errors: null
    },
    campaignResults: {
      filter: {
        relation: 'and',
        activeFilters: []
      },
      activeSorting: [],
      survey: null,
      results: null,
      dropOutResults: null,
      loading: false
    },

    availableClients: [],

    view: 'list',
    selectedCampaign: null,
    starredCampaignIds: [],

    filter: {},

    starredCampaigns: {
      campaigns: [],
      total: null,
      sortColumn: 'createdAt',
      sortType: 'desc'
    },
    otherCampaigns: {
      campaigns: [],
      total: null,
      sortColumn: 'createdAt',
      sortType: 'desc',
      page: 0,
      rowsPerPage: 10
    }
  }
};

const emptyState = JSON.parse(JSON.stringify(initialState));

export default (state = emptyState, action) => {
  switch (action.type) {
    case actions.RESET_CAMPAIGNS_STATE:
      return {
        ...JSON.parse(JSON.stringify(initialState))
      };
    case actions.ASSIGN_CATEGORIES_TO_OPEN_ANSWER_REQUESTED:
      return {
        ...state,
        isProcessing: true,
        isFetching: false
      };
    case actions.GET_CAMPAIGNS_REQUESTED:
      return {
        ...state,
        portal: {
          ...state.portal,
          selectedCampaign: null
        },
        isProcessing: false,
        isFetching: true
      };
    case actions.LOAD_RESULTS_PAGE_REQUESTED:
      return {
        ...state,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            survey: action.survey,
            loading: true
          }
        }
      };
    case actions.GET_CAMPAIGN_OVERALL_STATS_REQUESTED:
      return {
        ...state,
        isProcessing: false,
        isFetching: true
      };
    case actions.CREATE_CAMPAIGN_REQUESTED:
    case actions.DELETE_CAMPAIGN_REQUESTED:
    case actions.PUBLISH_CAMPAIGN_REQUESTED:
    case actions.GO_TO_PORTAL_PAGE_REQUESTED:
    case actions.GENERATE_FILE_REQUESTED:
    case actions.MERGE_DATASETS_REQUESTED:
      return {
        ...state,
        isProcessing: true,
        isFetching: false
      };

    case actions.CHANGE_CAMPAIGNS_PAGE_REQUESTED:
      return {
        ...state,
        isProcessing: false,
        isFetching: true,
        portal: {
          ...state.portal,
          otherCampaigns: {
            ...state.portal.otherCampaigns,
            page: action.page
          }
        }
      };

    case actions.CREATE_CAMPAIGN_SUCCEEDED: {
      const sections = state.portal.campaignBuilder.sections.map(
        (section, index) => {
          const blockSection = section;
          if (index === 0) {
            const welcomeBlock = blockSection.blocks[0];
            welcomeBlock.web_version_email_cofirmation =
              action.campaign.web_version_email_cofirmation;
            welcomeBlock.ask_for_socio_demo_web_survey =
              action.campaign.ask_for_socio_demo_web_survey;
            welcomeBlock.ask_to_register_on_buffl_web_survey =
              action.campaign.ask_to_register_on_buffl_web_survey;
          }
          return blockSection;
        }
      );

      const createdCampaign = action.campaign;
      createdCampaign.userFilter = { language: { $in: ['Dutch'] } };

      return {
        ...state,
        portal: {
          ...state.portal,
          campaignBuilder: {
            ...state.portal.campaignBuilder,
            campaign: createdCampaign,
            sections
          }
        },
        isProcessing: false,
        isFetching: false
      };
    }
    case actions.GET_CAMPAIGNS_SUCCEEDED:
      return {
        ...JSON.parse(JSON.stringify(initialState)),
        isProcessing: false,
        isFetching: false,
        portal: {
          ...JSON.parse(JSON.stringify(initialState)).portal,
          filter: action.filter,
          starredCampaignIds: action.starredCampaignIds,
          starredCampaigns: action.starredCampaigns,
          otherCampaigns: action.otherCampaigns,
          availableClients: action.availableClients
        }
      };
    case actions.DELETE_CAMPAIGN_SUCCEEDED:
      return {
        ...state,
        isProcessing: false,
        isFetching: false,
        portal: {
          ...state.portal,
          selectedCampaign: null
        }
      };
    case actions.GENERATE_FILE_SUCCEEDED:
    case actions.MERGE_DATASETS_SUCCEEDED:
    case actions.ASSIGN_CATEGORIES_TO_OPEN_ANSWER_SUCCEEDED:
      return {
        ...state,
        isProcessing: false,
        isFetching: false
      };
    case actions.CHANGE_CAMPAIGNS_PAGE_SUCCEEDED:
      return {
        ...state,
        isProcessing: false,
        isFetching: false,
        portal: {
          ...state.portal,
          campaigns: action.campaigns,
          total: action.total
        }
      };
    case actions.LOAD_RESULTS_PAGE_SUCCEEDED: {
      return {
        ...state,
        isProcessing: false,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            loading: false,
            results: action.results,
            dropOutResults: action.dropOutResults
          }
        }
      };
    }
    case actions.SET_RESULTS_ONLY: {
      return {
        ...state,
        isProcessing: false,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            loading: false,
            results: action.results
          }
        }
      };
    }
    case actions.GET_CAMPAIGN_OVERALL_STATS_SUCCEEDED:
      return {
        ...state,
        isProcessing: false,
        isFetching: false,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            stats: action.stats
          }
        }
      };

    case actions.CHANGE_CAMPAIGNS_PAGE_FAILED:
    case actions.CREATE_CAMPAIGN_FAILED:
    case actions.DELETE_CAMPAIGN_FAILED:
    case actions.PUBLISH_CAMPAIGN_FAILED:
    case actions.LOAD_RESULTS_PAGE_FAILED:
    case actions.UPLOAD_MEDIA_FAILED:
    case actions.REQUEST_APPROVAL_FAILED:
    case actions.GO_TO_PORTAL_PAGE_FAILED:
    case actions.GENERATE_FILE_FAILED:
    case actions.MERGE_DATASETS_FAILED:
    case actions.GET_CAMPAIGN_OVERALL_STATS_FAILED:
    case actions.ASSIGN_CATEGORIES_TO_OPEN_ANSWER_FAILED:
      return {
        ...state,
        isProcessing: false,
        isFetching: false,
        error: action.error
      };

    case actions.EDIT_CAMPAIGN:
      return {
        ...state,
        portal: {
          ...state.portal,
          campaignBuilder: {
            ...state.portal.campaignBuilder,
            campaign: action.campaign
          }
        }
      };
    case actions.CHANGE_CAMPAIGNS_VIEW:
      return {
        ...state,
        portal: {
          ...state.portal,
          view: action.view === 'list' ? 'list' : 'grid'
        }
      };
    case actions.SELECT_CAMPAIGN:
      return {
        ...state,
        portal: {
          ...state.portal,
          selectedCampaign: action.campaign
        }
      };
    case actions.BLUR_SELECTED_CAMPAIGN:
      return {
        ...state,
        portal: {
          ...state.portal,
          selectedCampaign: null
        }
      };

    case actions.TOGGLE_SORTING: {
      const sorting = [...state.portal.campaignResults.activeSorting].filter(
        s => s.sortingName !== action.sortingName
      );

      if (action.sortingOrder !== 'DEFAULT') {
        sorting.push({
          sortingName: action.sortingName,
          sortingOrder: action.sortingOrder
        });
      }

      return {
        ...state,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            activeSorting: sorting
          }
        }
      };
    }

    case actions.TOGGLE_FILTER: {
      if (action.valuePath) {
        const filterTypeDict = {
          'attributes.userData.newOccupation.mainActivity': 'Employment',
          'attributes.userData.age': 'Age',
          'attributes.userData.gender': 'Gender'
        };

        if (filterTypeDict[action.valuePath]) {
          mixpanel.actions.trackEvent(
            mixpanel.events.CLIENT_RESULTS_PAGE_DEMOGRAPHICS_FILTER_USAGE,
            {
              [mixpanel.eventProperties.FILTER_TYPE]:
                filterTypeDict[action.valuePath]
            }
          );
        } else if (action.valuePath.includes('answer')) {
          mixpanel.actions.trackEvent(
            mixpanel.events.CLIENT_RESULTS_PAGE_QUESTIONS_FILTER_USAGE,
            { [mixpanel.eventProperties.QUESTION_ID]: action.filterName }
          );
        }
      }

      let filters = [...state.portal.campaignResults.filter.activeFilters];
      const matchedFilter = state.portal.campaignResults.filter.activeFilters.filter(
        filter =>
          filter.filterName === action.filterName &&
          filter.value === action.value
      );

      if (action.filterName === 'ageRangeGroups') {
        filters = filters.filter(
          filter =>
            filter.filterName !== 'ageRangeGroups' &&
            filter.filterName !== 'ageGroups'
        );
      }

      if (action.filterName === 'ageGroups') {
        filters = filters.filter(
          filter => filter.filterName !== 'ageRangeGroups'
        );
      }

      if (action.filterName.indexOf('ageRangeGroups-') !== -1) {
        filters = filters.filter(
          filter => filter.filterName !== action.filterName
        );
      }

      if (matchedFilter && matchedFilter.length === 0) {
        filters.push({
          filterName: action.filterName,
          valuePath: action.valuePath,
          value: action.value,
          resultsPath: action.resultsPath,
          transformator: action.transformator,
          additionalAnswerLabel: action.additionalAnswerLabel
        });
      } else {
        filters = filters.filter(
          filter =>
            filter.filterName !== matchedFilter[0].filterName ||
            filter.value !== matchedFilter[0].value
        );
      }

      if (filters && filters.length && filters.length > 1) {
        const eventProperties = {
          [mixpanel.eventProperties.MULTIPLE_ACTIVE_FILTERS_COUNT]:
            filters.length
        };
        if (
          state &&
          state.portal &&
          state.portal.campaignResults &&
          state.portal.campaignResults.survey &&
          state.portal.campaignResults.survey.id
        ) {
          eventProperties[mixpanel.eventProperties.SURVEY_ID] =
            state.portal.campaignResults.survey.id;
        }
        mixpanel.actions.trackEvent(
          mixpanel.events.CLIENT_MULTIPLE_ACTIVE_FILTERS,
          eventProperties
        );
      }

      return {
        ...state,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            filter: {
              ...state.portal.campaignResults.filter,
              activeFilters: filters
            }
          }
        }
      };
    }

    case actions.RESET_FILTERS: {
      return {
        ...state,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            filter: {
              ...state.portal.campaignResults.filter,
              activeFilters: []
            }
          }
        }
      };
    }

    case actions.SET_FILTERS_RELATION:
      return {
        ...state,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            filter: {
              ...state.portal.campaignResults.filter,
              relation: action.relation
            }
          }
        }
      };

    case actions.SET_ACTIVE_FILTERS:
      return {
        ...state,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            filter: {
              ...state.portal.campaignResults.filter,
              activeFilters: action.activeFilters
            }
          }
        }
      };

    case actions.SET_ACTIVE_SORTING:
      return {
        ...state,
        portal: {
          ...state.portal,
          campaignResults: {
            ...state.portal.campaignResults,
            activeSorting: action.activeSorting
          }
        }
      };

    case actions.SEARCH_CAMPAIGNS:
      return {
        ...state,
        portal: {
          ...state.portal,
          search: action.search
        }
      };

    case actions.CHANGE_COLUMN_SORT:
      return {
        ...state,
        portal: {
          ...state.portal,
          [action.campaignsPath]: {
            ...state.portal[action.campaignsPath],
            sortColumn: action.sortColumn,
            sortType: action.sortType
          }
        }
      };

    case actions.SET_ROWS_PER_PAGE:
      return {
        ...state,
        portal: {
          ...state.portal,
          otherCampaigns: {
            ...state.portal.otherCampaigns,
            rowsPerPage: action.rowsPerPage
          }
        }
      };

    case actions.SET_CAMPAIGN_FILTERS:
      return {
        ...state,
        portal: {
          ...state.portal,
          filter: JSON.parse(JSON.stringify(action.filter))
        }
      };

    case actions.REMOVE_SETTINGS_ERRORS:
      return {
        ...state,
        portal: {
          ...state.portal,
          campaignBuilder: {
            ...state.portal.campaignBuilder,
            errors: null
          }
        }
      };

    default:
      return state;
  }
};
