// Returns flow id
export const getGroupFirstFlow = (content, group) => {
  const firstFlow = group.flows[0];
  if (content.groups.some(cG => cG.id === firstFlow)) {
    const childGroupFlow = content.groups.find(cG => cG.id === firstFlow);
    return childGroupFlow.flows[0];
  }
  return firstFlow;
};

// Returns flow id
export const getGroupLastFlow = (content, group) => {
  const lastFlow = group.flows[group.flows.length - 1];
  if (content.groups.some(cG => cG.id === lastFlow)) {
    const childGroupFlow = content.groups.find(cG => cG.id === lastFlow);
    return childGroupFlow.flows[childGroupFlow.flows.length - 1];
  }
  return lastFlow;
};
