import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { RESET_PASSWORD } from '../../../graphql/Auth';
import styles from './ResetPasswordPage.module.css';

import LoaderAnimation from '../../../base/components/LoaderAnimation/LoaderAnimation';

import herdyWorking from '../../../assets/img/herdy-working.svg';
import bottomBlobGreen from '../../../assets/img/registration-bottom-blob-green.svg';

const ResetPasswordPage = props => {
  const { isAuthenticated, match, strings } = props;

  const emptyFields = { password: '', repeatPassword: '' };

  const [error, setError] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [fields, setFields] = useState(emptyFields);
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => setIsCompleted(true)
  });

  const onResetPassword = () => {
    let resetPasswordError = null;

    if (
      !fields.password ||
      fields.password === '' ||
      !fields.repeatPassword ||
      fields.repeatPassword === ''
    ) {
      resetPasswordError = strings.ALL_FIELDS_REQUIRED;
    }

    if (fields.password !== fields.repeatPassword) {
      resetPasswordError = strings.PASSWORDS_NOT_SAME;
    }

    if (!resetPasswordError) {
      const variables = {
        password: fields.password,
        token: match.params.token
      };
      resetPassword({ variables }).catch(registerError => {
        if (registerError) {
          setError(strings.SOMETHING_WENT_WRONG);
        }
      });
    } else {
      setError(resetPasswordError);
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isCompleted) {
    return (
      <>
        <div className={`${styles.mainContainer} ${styles.completed}`}>
          <div className={styles.form}>
            <h2 className={styles.title}>
              {strings.PASSWORD_SUCCESSFULLY_RESET}
            </h2>
            <form>
              <div className={styles.login}>
                {strings.ALREADY_HAVE_ACCOUNT}{' '}
                <span
                  className={styles.greenLink}
                  role="presentation"
                  onClick={() => props.history.push(`/login`)}
                >
                  {strings.LOG_IN}
                </span>
              </div>
            </form>
          </div>
        </div>
        <div className={styles.bottomBlobWrapper}>
          <img
            className={styles.bottomBlob}
            src={bottomBlobGreen}
            alt="Blue bottom blob"
          />
          <img
            className={styles.herdyWorking}
            src={herdyWorking}
            alt="Herdy working"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.form}>
          <h2 className={styles.title}>{strings.RESET}</h2>
          <form>
            <div className={styles.label}>{strings.PASSWORD}</div>
            <input
              className={styles.input}
              type="password"
              value={fields.password}
              onChange={e =>
                setFields({ ...fields, ...{ password: e.currentTarget.value } })
              }
            />
            <div className={styles.label}>{strings.REPEAT_PASSWORD}</div>
            <input
              className={styles.input}
              type="password"
              value={fields.repeatPassword}
              onChange={e =>
                setFields({
                  ...fields,
                  ...{ repeatPassword: e.currentTarget.value }
                })
              }
            />
            <div className={styles.buttonContainer}>
              {loading ? (
                <LoaderAnimation />
              ) : (
                <div>
                  {error ? <div className={styles.error}>{error}</div> : null}
                  <div
                    className={`${styles.button} login-page-submit-button`}
                    onClick={() => onResetPassword()}
                    role="presentation"
                  >
                    {strings.RESET_PASSWORD}
                  </div>
                  <div className={styles.register}>
                    {strings.NO_ACCOUNT_CREATED}{' '}
                    <span
                      className={styles.greenLink}
                      role="presentation"
                      onClick={() => props.history.push(`/register`)}
                    >
                      {strings.REGISTER}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className={styles.bottomBlobWrapper}>
        <img
          className={styles.bottomBlob}
          src={bottomBlobGreen}
          alt="Blue bottom blob"
        />
        <img
          className={styles.herdyWorking}
          src={herdyWorking}
          alt="Herdy working"
        />
      </div>
    </>
  );
};

export default ResetPasswordPage;
