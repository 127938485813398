import React from 'react';

const LayersIcon = ({
  fill = '#4b25e8',
  width = 17,
  height = 17,
  paddingTop = 0
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 477.867 477.867"
    style={{ paddingTop }}
  >
    <g>
      <path
        d="M470.382,224.808c-0.482-0.323-0.979-0.621-1.492-0.893l-33.911-18.261l-188.058,99.567
		c-4.996,2.646-10.978,2.646-15.974,0L42.89,205.654L8.978,223.915C0.736,228.29-2.4,238.518,1.975,246.76
		c0.272,0.512,0.57,1.01,0.893,1.492l236.066,124.979l236.066-124.979C480.199,240.503,478.132,230.007,470.382,224.808z"
        fill={fill}
      />
    </g>
    <g>
      <path
        d="M470.382,224.808c-0.482-0.323-0.979-0.621-1.492-0.893l-33.911-18.261l-188.058,99.567
		c-4.996,2.646-10.978,2.646-15.974,0L42.89,205.654L8.978,223.915C0.736,228.29-2.4,238.518,1.975,246.76
		c0.272,0.512,0.57,1.01,0.893,1.492l236.066,124.979l236.066-124.979C480.199,240.503,478.132,230.007,470.382,224.808z"
        fill={fill}
      />
    </g>
    <g>
      <g>
        <path
          d="M475.803,333.227c-1.581-2.929-3.984-5.332-6.912-6.912l-33.911-18.261l-188.058,99.567
			c-4.996,2.646-10.978,2.646-15.974,0L42.89,308.054L8.978,326.315c-8.295,4.477-11.389,14.83-6.912,23.125
			c1.581,2.929,3.984,5.332,6.912,6.912l221.867,119.467c5.05,2.719,11.129,2.719,16.179,0l221.867-119.467
			C477.185,351.875,480.28,341.522,475.803,333.227z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M470.382,122.408c-0.482-0.323-0.979-0.621-1.492-0.893L247.024,2.048c-5.05-2.719-11.129-2.719-16.179,0L8.978,121.515
			C0.736,125.89-2.4,136.118,1.975,144.36c0.272,0.512,0.57,1.01,0.893,1.492l236.066,124.979l236.066-124.979
			C480.199,138.103,478.132,127.607,470.382,122.408z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default LayersIcon;
