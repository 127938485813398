import React, { useMemo } from 'react';

import GraphFeatures from './GraphFeatures/GraphFeatures';

import getAvailableQuestions from '../../../../helpers/getAvailableQuestions';
import getMatrixQuestions from '../../../../helpers/getMatrixQuestions';

export default ({
  styles,
  tabProperties,
  mergeQuestionName,
  setMergeQuestionName,
  questions,
  questionBlocks,
  features,
  setFeatures,
  templateConfiguration,
  isAdmin
}) => {
  const isMatrixFramework =
    features && features.length && features.every(f => f.choiceId);

  const availableQuestions = useMemo(
    () =>
      isMatrixFramework
        ? getMatrixQuestions(questions)
        : getAvailableQuestions(
            questions,
            questionBlocks,
            features,
            templateConfiguration.xQuestionName,
            templateConfiguration.yQuestionName
          ),
    [features]
  );

  return (
    <>
      <div className={styles.templateTitle}>{templateConfiguration.title}</div>
      {tabProperties.searchBar && (
        <div className={styles.graphNameContainer}>
          <input
            className={styles.mergedGraphName}
            type="text"
            placeholder={tabProperties.searchBarPlaceholder}
            value={mergeQuestionName}
            onChange={e => setMergeQuestionName(e.target.value)}
          />
        </div>
      )}
      {!isMatrixFramework && (
        <div>
          <div
            className={`${styles.description} ${styles.templateDescription}`}
          >
            {templateConfiguration.description} Add data points to get started.
          </div>
        </div>
      )}
      <GraphFeatures
        features={features}
        setFeatures={setFeatures}
        availableQuestions={availableQuestions}
        maxAmountOfFeatures={isAdmin ? 100 : 6}
        xQuestionName={templateConfiguration.xQuestionName}
        yQuestionName={templateConfiguration.yQuestionName}
        placeholders={templateConfiguration.placeholders}
        questionsTypeLabel="rating"
        isMatrixFramework={isMatrixFramework}
      />
    </>
  );
};
