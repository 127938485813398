import React, { useState, useEffect } from "react";
import * as d3 from "d3";

const Line = props => {
  const { data } = props;
  const [svg, setSvg] = useState(null);

  // Did mount
  useEffect(() => {
    drawChart(svg, props);
  }, []);

  // Did update
  useEffect(
    () => {
      drawChart(svg, props);
    },
    [svg, data]
  );

  const drawChart = (svgRef, chartProps) => {
    if (props.parent && props.parent.offsetWidth) {
      const svgHeight = 500;

      const svgChart = d3
        .select(svgRef)
        .attr("width", props.parent.offsetWidth)
        .attr("height", svgHeight);

      svgChart.selectAll("*").remove();

      const fullData = chartProps.data;

      const parseDate = d3.utcParse("%Y-%m-%dT%H");
      // const parseDate = d3.utcParse("%Y-%m-%dT%H");

      const margin = { top: 20, right: 30, bottom: 110, left: 40 };
      const margin2 = { top: 430, right: 30, bottom: 30, left: 40 };

      const width = props.parent.offsetWidth - margin.left - margin.right;
      const height = +svgHeight - margin.top - margin.bottom;
      const height2 = +svgHeight - margin2.top - margin2.bottom;

      const x = d3.scaleTime().range([0, width]);
      const x2 = d3.scaleTime().range([0, width]);
      const y = d3.scaleLinear().range([height, 0]);
      const y2 = d3.scaleLinear().range([height2, 0]);

      const xAxis = d3.axisBottom(x);
      const xAxis2 = d3.axisBottom(x2);
      const yAxis = d3.axisLeft(y);

      const brushed = () => {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom")
          return; // ignore brush-by-zoom
        const s = d3.event.selection || x2.range();
        x.domain(s.map(x2.invert, x2));
        focus.select(".area").attr("d", area);
        focus.select(".axis--x").call(xAxis);
        svgChart
          .select(".zoom")
          .call(
            zoom.transform,
            d3.zoomIdentity.scale(width / (s[1] - s[0])).translate(-s[0], 0)
          );
      };

      const zoomed = () => {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush")
          return; // ignore zoom-by-brush
        const t = d3.event.transform;
        x.domain(t.rescaleX(x2).domain());
        focus.select(".area").attr("d", area);
        focus.select(".axis--x").call(xAxis);
        context.select(".brush").call(brush.move, x.range().map(t.invertX, t));
      };

      const brush = d3
        .brushX()
        .extent([[0, 0], [width, height2]])
        .on("brush end", brushed);

      const zoom = d3
        .zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .on("zoom", zoomed);

      const area = d3
        .area()
        .curve(d3.curveMonotoneX)
        .x(d => x(parseDate(d.x)))
        .y0(height)
        .y1(d => y(d.y));

      const area2 = d3
        .area()
        .curve(d3.curveMonotoneX)
        .x(d => x2(parseDate(d.x)))
        .y0(height2)
        .y1(d => y2(d.y));

      svgChart
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      const focus = svgChart
        .append("g")
        .attr("class", "focus")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const context = svgChart
        .append("g")
        .attr("class", "context")
        .attr("transform", `translate(${margin2.left},${margin2.top})`);

      x.domain(d3.extent(fullData, d => parseDate(d.x)));
      y.domain([0, d3.max(fullData, d => d.y)]);
      x2.domain(x.domain());
      y2.domain(y.domain());

      focus
        .append("g")
        .attr("class", "axis axis--x")
        .attr("transform", `translate(0,${height})`)
        .call(xAxis);

      const endHour = parseDate(fullData[0].x).setHours(
        parseDate(fullData[0].x).getHours() + 1
      );
      const fullRange = d3.timeHours(
        parseDate(fullData[fullData.length - 1].x),
        endHour,
        1
      );

      const fullDataWithZeros = fullRange.map(monthBucket => {
        const day = monthBucket.getUTCDate();
        let monthIndex = monthBucket.getUTCMonth() + 1;
        monthIndex = monthIndex.length === 2 ? monthIndex : `0${monthIndex}`;
        const year = monthBucket.getFullYear();
        let hour = monthBucket.getUTCHours();
        hour = hour > 9 ? hour : `0${hour}`;

        const date = `${year}-${monthIndex}-${day}T${hour}`;

        const dateMatch = fullData.filter(
          fullDataDate => fullDataDate.x === date
        );

        if (dateMatch && dateMatch.length) {
          return { x: date, y: dateMatch[0].y };
        }
        if (!dateMatch || !dateMatch.length) {
          return { x: date, y: 0 };
        }
        return null;
      });

      focus
        .append("path")
        .datum(fullDataWithZeros)
        .attr("class", "area")
        .attr("d", area);

      focus
        .append("g")
        .attr("class", "axis axis--y")
        .call(yAxis);

      context
        .append("path")
        .datum(fullDataWithZeros)
        .attr("class", "area")
        .attr("d", area2);

      context
        .append("g")
        .attr("class", "axis axis--x")
        .attr("transform", `translate(0,${height2})`)
        .call(xAxis2);

      context
        .append("g")
        .attr("class", "brush")
        .call(brush)
        .call(brush.move, x.range());

      svgChart
        .append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .call(zoom);
    }
  };

  return (
    <div>
      {data && data.length ? (
        <svg
          ref={elem => {
            setSvg(elem);
          }}
        />
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default Line;
