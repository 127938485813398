import React, { useState, useContext } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import SurveyBuilderContext from '../../SurveyBuilderContext';

import Icon from '../Icon/Icon';
import {
  QUESTION_TYPES,
  SURVEY_BUILDER_POPUP_TYPES
} from '../../helpers/constants';

import styles from './AddQuestionList.module.css';

const questionTypes = [
  {
    label: 'Text',
    type: QUESTION_TYPES.MESSAGE
  },
  {
    label: 'Open question',
    type: QUESTION_TYPES.OPEN_QUESTION
  },
  {
    label: 'Multiple choice',
    type: QUESTION_TYPES.MULTIPLE_CHOICE
  },
  {
    label: 'Ranking options',
    type: QUESTION_TYPES.RANKING
  },
  {
    label: 'Rating scale',
    type: QUESTION_TYPES.RATING_SCALE
  },
  {
    label: 'Take a picture',
    type: QUESTION_TYPES.TAKE_A_PICTURE
  },
  {
    label: 'Capture a video',
    type: QUESTION_TYPES.RECORD_A_VIDEO
  },
  {
    label: 'Show a video',
    type: QUESTION_TYPES.PLAY_A_VIDEO
  },
  {
    label: 'Showcase a website',
    type: QUESTION_TYPES.IFRAME
  },
  {
    label: 'Add to community',
    type: QUESTION_TYPES.ADD_TO_COMMUNITY
  },
  {
    label: 'Matrix',
    type: QUESTION_TYPES.MATRIX
  }
];

const actionTypes = [
  {
    label: 'Distributor',
    type: QUESTION_TYPES.DISTRIBUTOR
  }
];

export default () => {
  const [expanded, setExpanded] = useState(false);
  const [disableNewQuestionClick, setDisableNewQuestionClick] = useState(false);
  const { actions, state } = useContext(SurveyBuilderContext);
  const { surveyLists } = state;

  const isQuestionListEmpty = !surveyLists || surveyLists.length === 0;

  const onQuestionClick = type => {
    if (!disableNewQuestionClick) {
      const addQuestion = () => {
        actions.addQuestion(type, undefined, undefined, undefined, true);
        setDisableNewQuestionClick(true);
        setTimeout(() => setDisableNewQuestionClick(false), 500);
      };

      if (type === QUESTION_TYPES.ADD_TO_COMMUNITY) {
        actions.toggleSurveyBuilderPopup({
          type: SURVEY_BUILDER_POPUP_TYPES.RESEARCH_COST_INCREASED,
          onClose: () => actions.toggleSurveyBuilderPopup(),
          onClick: () => {
            addQuestion();
          }
        });
      } else {
        addQuestion();
      }
    }
  };

  return (
    <div
      className={
        expanded
          ? `${styles.container} ${styles.expanded} add-question-list-container`
          : `${styles.container} add-question-list-container`
      }
    >
      <div
        className={
          expanded
            ? styles.expandButton
            : `${styles.expandButton} ${styles.expandButtonResolutionCheck}`
        }
        role="presentation"
        onClick={() => setExpanded(!expanded)}
      >
        <div className={styles.label}>Question blocks</div>
        <Icon className={styles.icon} type="collapse_arrow" />
      </div>
      <Droppable droppableId="new-questions-list" isDropDisabled>
        {provided => (
          <div
            ref={provided.innerRef}
            className={styles.draggableQuestionsContainer}
          >
            <div className={styles.sectionTitle}>
              <span>Question blocks</span>
            </div>
            {questionTypes &&
              questionTypes.map((type, index) => (
                <div
                  key={`add-question-list-${index.toString()}`}
                  className={`${
                    styles.draggableQuestionPlaceholder
                  } add-question-list-question`}
                  role="presentation"
                  onClick={async event => {
                    event.preventDefault();
                    onQuestionClick(type.type);
                  }}
                >
                  <Draggable
                    key={`add-question-list-${type.type.toString()}`}
                    draggableId={type.type}
                    index={index}
                  >
                    {providedBlock => (
                      <div
                        ref={providedBlock.innerRef}
                        {...providedBlock.draggableProps}
                        {...providedBlock.dragHandleProps}
                        onClick={() => {}}
                        role="presentation"
                      >
                        <div className={styles.question}>
                          <Icon
                            type={type.type}
                            alt={type.label}
                            className={styles.icon}
                          />
                          {type.label}
                        </div>
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
            {!isQuestionListEmpty ? (
              <div>
                <div
                  className={`${styles.sectionTitle} ${
                    styles.sectionTitleMargin
                  }`}
                >
                  <span>Action blocks</span>
                </div>
                {actionTypes &&
                  actionTypes.map((type, index) => (
                    <div
                      key={`add-question-list-${index.toString()}`}
                      className={`${
                        styles.draggableQuestionPlaceholder
                      } add-question-list-question`}
                      role="presentation"
                      onClick={async event => {
                        event.preventDefault();
                        onQuestionClick(type.type);
                      }}
                    >
                      <Draggable
                        key={`add-question-list-${type.type.toString()}`}
                        draggableId={type.type}
                        index={questionTypes.length + index}
                      >
                        {providedBlock => (
                          <div
                            ref={providedBlock.innerRef}
                            {...providedBlock.draggableProps}
                            {...providedBlock.dragHandleProps}
                            onClick={() => {}}
                            role="presentation"
                          >
                            <div className={styles.question}>
                              <span className={styles.icon}>
                                <Icon
                                  type={type.type}
                                  className={styles.distributorIcon}
                                />
                              </span>
                              {type.label}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                {provided.placeholder}
              </div>
            ) : null}
          </div>
        )}
      </Droppable>
    </div>
  );
};
