import React from 'react';

import Icon from '../../../../../../../../../../../Icon/Icon';
import QuestionsListDropdown from '../../../../../../../../../QuestionsListDropdown/QuestionsListDropdown';

import isActionAllowed from '../../../../../../../../../../../../helpers/isActionAllowed/isActionAllowed';
import {
  QUESTION_DESIGNER_ACTION,
  SURVEY_END_TYPES
} from '../../../../../../../../../../../../helpers/constants';
import styles from './RangeLogicJump.module.css';

const RangeLogicJump = ({
  questionId,
  branch,
  actions,
  surveyListQuestion,
  surveyLists,
  surveyStructure,
  error
}) => (
  <div className={`${styles.container} ${error ? styles.error : ''}`}>
    <div className={styles.dropdownRow}>
      <div className={styles.dropdownRowParent}>
        <Icon type="ljArrow" className={styles.ljArrow} />
        Go to
        <QuestionsListDropdown
          value={branch.nextFlow || branch.end}
          onOptionClick={selectedJump => {
            const newBranch = branch;

            if (
              SURVEY_END_TYPES.SUCCESSFUL === selectedJump ||
              SURVEY_END_TYPES.NOT_ELIGIBLE === selectedJump
            ) {
              newBranch.end = selectedJump;
              delete newBranch.nextFlow;
            } else {
              newBranch.nextFlow = selectedJump;
              delete newBranch.end;
            }

            actions.updateQuestionBranch(questionId, newBranch, true);
          }}
          isActionAllowedChecker={destinationFlow =>
            isActionAllowed(
              QUESTION_DESIGNER_ACTION.SET_QUESTION_JUMP,
              surveyListQuestion,
              destinationFlow.id,
              surveyLists,
              surveyStructure
            )
          }
        />
      </div>
    </div>
  </div>
);

export default RangeLogicJump;
