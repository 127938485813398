import React from 'react';

import formatDate from '../../../../../../../helpers/formatDate';
import Icon from '../../../../SurveyBuilder/components/Icon/Icon';

import styles from './PreviousSurveysList.module.css';

export default ({
  title,
  surveys,
  isLoading,
  loadingData,
  checked,
  onClick
}) => (
  <div className={styles.listContainer}>
    <div className={styles.listTitle}>{title}</div>
    <div className={`${styles.row} ${styles.headerRow}`}>
      <span>Internal name</span>
      <span className={styles.surveyPublishDate}>Published</span>
    </div>
    {isLoading ? (
      <div className="loader-container">
        {loadingData.map((_loadingRow, index) => (
          <div
            className={styles.loadingRow}
            key={`previous-survey-loader-row-${index.toString()}`}
          />
        ))}
      </div>
    ) : (
      surveys.map(s => (
        <div
          className={`${styles.row} ${checked ? styles.checkedRow : ''}`}
          key={s.id}
        >
          <div
            className={styles.checkboxContainer}
            onClick={() => onClick(s)}
            role="presentation"
          >
            <Icon type={checked ? 'checkboxChecked' : 'checkboxUnChecked'} />
          </div>
          <span className={styles.surveyProjectName}>{s.projectName}</span>
          <span className={styles.surveyPublishDate}>
            {formatDate(s.publishedAt)}
          </span>
        </div>
      ))
    )}
  </div>
);
