import React from 'react';

import styles from './ExternalTermsInput.module.css';

export default props => {
  const {
    label,
    setViewTerms,
    customThemeBaseColor,
    onProceed,
    onDecline
  } = props;

  return (
    <div className={styles.container}>
      <span style={{ whiteSpace: 'pre-line' }}>
        {label.GOVERNED_BY_BUFFL_POLICY}{' '}
      </span>
      <span
        role="presentation"
        className={styles.termsLink}
        onClick={() => setViewTerms(true)}
      >
        {label.PRIVACY_POLICY}
      </span>{' '}
      {label.BEFORE_PROCEEDING}
      <p>{label.AGREE_TO_PROCEED}</p>
      <div className={styles.buttonWrapper}>
        <div
          className={styles.button}
          style={{
            backgroundColor: customThemeBaseColor || '#24B2E8'
          }}
          role="presentation"
          onClick={onProceed}
        >
          {label.YES_I_AGREE}
        </div>
      </div>
      <div
        className={styles.button}
        style={{
          backgroundColor: customThemeBaseColor || '#24B2E8'
        }}
        role="presentation"
        onClick={onDecline}
      >
        {label.NO_I_DO_NOT_AGREE}
      </div>
    </div>
  );
};
