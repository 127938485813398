import React from 'react';

import Icon from '../../../../../../../../../../../Icon/Icon';
import QuestionsListDropdown from '../../../../../../../../../QuestionsListDropdown/QuestionsListDropdown';

import isActionAllowed from '../../../../../../../../../../../../helpers/isActionAllowed/isActionAllowed';
import {
  QUESTION_DESIGNER_ACTION,
  SURVEY_END_TYPES
} from '../../../../../../../../../../../../helpers/constants';

import styles from './AnswerLogicJump.module.css';

export default ({
  questionId,
  choices,
  choice,
  actions,
  surveyListQuestion,
  surveyLists,
  surveyStructure,
  setShowLogicJumpControls,
  selectAtMost,
  singleJumpAllowed
}) => {
  const choicesWithJumps =
    singleJumpAllowed && choices.filter(c => c.nextFlow || c.end);

  const firstOfSingleValuesSelected =
    singleJumpAllowed &&
    ((choicesWithJumps &&
      choicesWithJumps.length &&
      choicesWithJumps[0].id === choice.id) ||
      !choicesWithJumps.length) &&
    choicesWithJumps.length < 2;

  const allowedValueOnly =
    singleJumpAllowed && choicesWithJumps && choicesWithJumps.length
      ? choicesWithJumps[0].nextFlow || choicesWithJumps[0].end
      : null;

  return (
    <div className={styles.container}>
      <div className={styles.dropdownRow}>
        <div className={styles.dropdownRowParent}>
          <Icon type="ljArrow" className={styles.ljArrow} />
          Go to{' '}
          <QuestionsListDropdown
            value={choice.nextFlow || choice.end}
            onOptionClick={selectedJump => {
              const newChoice = choice;

              if (
                SURVEY_END_TYPES.SUCCESSFUL === selectedJump ||
                SURVEY_END_TYPES.NOT_ELIGIBLE === selectedJump
              ) {
                newChoice.end = selectedJump;
                delete newChoice.nextFlow;
              } else {
                newChoice.nextFlow = selectedJump;
                delete newChoice.end;
              }

              actions.updateQuestionChoice(questionId, newChoice);
            }}
            isActionAllowedChecker={destinationFlow =>
              isActionAllowed(
                QUESTION_DESIGNER_ACTION.SET_QUESTION_JUMP,
                surveyListQuestion,
                destinationFlow.id,
                surveyLists,
                surveyStructure
              )
            }
            firstOfSingleValuesSelected={firstOfSingleValuesSelected}
            allowedValueOnly={allowedValueOnly}
          />
        </div>
        <span
          role="presentation"
          className={styles.close}
          onClick={() => {
            const newChoice = choice;
            if (choice.nextFlow) {
              newChoice.nextFlow = null;
            }
            if (choice.end) {
              newChoice.end = null;
            }
            actions.updateQuestionChoice(questionId, newChoice);
            setShowLogicJumpControls(false);
          }}
        >
          <Icon type="x" />
        </span>
      </div>
      {selectAtMost > 1 && (
        <div className={styles.evenOtherAreSelected}>
          even if other answers are selected
        </div>
      )}
    </div>
  );
};
