import React, { useState } from 'react';

// import whiteArrowDown from './assets/white-arrow-down.svg';
import styles from './SearchInputDropdown.module.css';

const SearchInputDropdown = props => {
  const { onlySearchProjectName } = props;
  const [showDropdown] = useState(false);

  return (
    <div
      className={styles.searchInputDropdownPlaceholder}
      // onClick={() => setShowDropdown(!showDropdown)}
      role="presentation"
    >
      {onlySearchProjectName
        ? 'INTERNAL SURVEY NAME CONTAINING'
        : 'SURVEY NAME CONTAINING'}
      {showDropdown ? (
        <div className={styles.searchInputDropdown}>
          <div className={styles.dropdownItem}>CREATOR NAME CONTAINING</div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchInputDropdown;
