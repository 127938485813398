import React from 'react';

const Popup = ({ component, onClose, showOverflow }) => {
  const onPopupClick = e => {
    e.stopPropagation();
  };

  return (
    <div
      role="presentation"
      className="popup-overlay-background"
      style={styles.popupContainer}
      onClick={onClose}
    >
      <div
        role="presentation"
        style={{
          ...styles.popup,
          ...(showOverflow ? styles.overflowVisible : styles.overflowHidden)
        }}
        onClick={onPopupClick}
      >
        {component}
      </div>
    </div>
  );
};

export default Popup;

const styles = {
  popupContainer: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: '9999999',
    top: '0px',
    left: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  popup: {
    backgroundColor: '#FFFFFF',
    padding: '10px',
    borderRadius: '10px',
    zIndex: '99999999',
    maxHeight: '90vh'
  },
  overflowHidden: {
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  overflowVisible: {
    overflowY: 'visible',
    overflowX: 'visible'
  }
};
