import React, { useState, useEffect, useRef } from 'react';

import styles from './TimePicker.module.css';

const hoursList = [];
const minutesList = [];

for (let i = 0; i < 24; i += 1) {
  hoursList.push(i < 10 ? `0${i}` : i);
}

for (let i = 0; i < 60; i += 1) {
  minutesList.push(i < 10 ? `0${i}` : i);
}

export default ({ placeholder, className, onTimeChange, time }) => {
  const [showTimePicker, setShowTimePicker] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const listener = document.addEventListener('mousedown', e =>
      setShowTimePicker(
        containerRef.current && containerRef.current.contains(e.target)
      )
    );
    return document.removeEventListener('mousedown', listener);
  });

  return (
    <div ref={containerRef}>
      <input
        onClick={() => setShowTimePicker(true)}
        placeholder={placeholder || '##:##'}
        value={
          time
            ? `${`0${new Date(time).getHours()}`.slice(-2)}:${`0${new Date(
                time
              ).getMinutes()}`.slice(-2)}`
            : ''
        }
        className={className}
        onChange={() => {}}
      />
      {showTimePicker && (
        <div className={styles.wrapper}>
          <div className={styles.pickerContainer}>
            <ul className={styles.valueContainer}>
              {hoursList.map((h, i) => (
                <li
                  className={`${styles.value} ${
                    time && new Date(time).getHours() === i
                      ? styles.active
                      : null
                  }`}
                  onClick={() => {
                    const newTime = time
                      ? new Date(time).setHours(h)
                      : new Date().setHours(h);
                    onTimeChange(newTime);
                  }}
                  onKeyPress={() => {}}
                  key={h}
                  role="presentation"
                >
                  {h}
                </li>
              ))}
            </ul>
            <ul className={styles.valueContainer}>
              {minutesList.map((m, i) => (
                <li
                  className={`${styles.value} ${
                    time && new Date(time).getMinutes() === i
                      ? styles.active
                      : null
                  }`}
                  onClick={() => {
                    const newTime = time
                      ? new Date(time).setMinutes(m)
                      : new Date().setMinutes(m);
                    onTimeChange(newTime);
                    setShowTimePicker(false);
                  }}
                  key={m}
                  role="presentation"
                >
                  {m}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
