import { connect } from 'react-redux';
import * as actions from './state/actions';
import SurveyBuilder from './SurveyBuilder';

const mapStateToProps = state => ({
  state: state.surveys
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    setSurvey: survey => dispatch(actions.setSurvey(ownProps.api, survey)),
    updateSurvey: (property, value) =>
      dispatch(actions.updateSurvey(ownProps.api, property, value)),
    setActiveFlow: flow => dispatch(actions.setActiveFlow(ownProps.api, flow)),
    setActiveFlowFromId: flowId =>
      dispatch(actions.setActiveFlowFromId(ownProps.api, flowId)),
    addQuestion: (
      newQuestionType,
      dropzone,
      destinationSetIndex,
      destionationQuestionindex,
      addQuestionsListClick
    ) =>
      dispatch(
        actions.addQuestion(
          ownProps.api,
          newQuestionType,
          dropzone,
          destinationSetIndex,
          destionationQuestionindex,
          addQuestionsListClick
        )
      ),
    setQuestionJump: jump =>
      dispatch(actions.setQuestionJump(ownProps.api, jump)),
    updateQuestion: question =>
      dispatch(actions.updateQuestion(ownProps.api, question)),
    addQuestionChoice: (flowId, customAttributes, isOther, isNoneOfTheAbove) =>
      dispatch(
        actions.addQuestionChoice(
          ownProps.api,
          flowId,
          customAttributes,
          isOther,
          isNoneOfTheAbove
        )
      ),
    addQuestionBranch: (flowId, customAttributes) =>
      dispatch(
        actions.addQuestionBranch(ownProps.api, flowId, customAttributes)
      ),
    updateQuestionChoice: (
      flowId,
      choice,
      answerUpdate,
      updateActiveQuestion
    ) =>
      dispatch(
        actions.updateQuestionChoice(
          ownProps.api,
          flowId,
          choice,
          answerUpdate,
          updateActiveQuestion
        )
      ),
    updateQuestionBranch: (
      flowId,
      branch,
      updateActiveQuestion,
      onlyLabelChange
    ) =>
      dispatch(
        actions.updateQuestionBranch(
          ownProps.api,
          flowId,
          branch,
          updateActiveQuestion,
          onlyLabelChange
        )
      ),
    deleteQuestionChoice: (flowId, choiceId) =>
      dispatch(actions.deleteQuestionChoice(ownProps.api, flowId, choiceId)),
    deleteQuestionBranch: (flowId, branchId) =>
      dispatch(actions.deleteQuestionBranch(ownProps.api, flowId, branchId)),
    updateQuestionProperty: (property, value) =>
      dispatch(actions.updateQuestionProperty(ownProps.api, property, value)),
    updateCustomQuestionProperty: (flowId, property, value) =>
      dispatch(actions.updateCustomQuestionProperty(flowId, property, value)), // Used for tests
    updateMatrixQuestionProperty: (property, value) =>
      dispatch(
        actions.updateMatrixQuestionProperty(ownProps.api, property, value)
      ),
    updateGroupProperty: (groupId, property, value) =>
      dispatch(
        actions.updateGroupProperty(ownProps.api, groupId, property, value)
      ),
    setGroupJump: (groupId, jump) =>
      dispatch(actions.setGroupJump(ownProps.api, groupId, jump)),
    toggleSurveyBuilderPopup: confirmationPopup =>
      dispatch(
        actions.toggleSurveyBuilderPopup(ownProps.api, confirmationPopup)
      ),
    moveQuestion: (
      dropzone,
      sourceSetIndex,
      sourceQuestionindex,
      destinationSetIndex,
      destionationQuestionindex,
      destinationPosition,
      destinationQuestionId
    ) =>
      dispatch(
        actions.moveQuestion(
          ownProps.api,
          dropzone,
          sourceSetIndex,
          sourceQuestionindex,
          destinationSetIndex,
          destionationQuestionindex,
          destinationPosition,
          destinationQuestionId
        )
      ),
    moveGroup: (groupId, destinationPosition, destinationQuestionId) =>
      dispatch(
        actions.moveGroup(
          ownProps.api,
          groupId,
          destinationPosition,
          destinationQuestionId
        )
      ),
    deleteQuestion: questionId =>
      dispatch(
        actions.deleteQuestion(ownProps.api, questionId, ownProps.history)
      ),
    deleteGroup: groupId =>
      dispatch(actions.deleteGroup(ownProps.api, groupId, ownProps.history)),
    nestFlows: (isTop, firstFlow, secondFlow) =>
      dispatch(actions.nestFlows(ownProps.api, isTop, firstFlow, secondFlow)),
    addFlowsToGroup: (isTop, firstFlow, secondFlow) =>
      dispatch(
        actions.addFlowsToGroup(ownProps.api, isTop, firstFlow, secondFlow)
      ),
    ungroup: (groupId, questionIndexInSet) =>
      dispatch(
        actions.ungroup(
          ownProps.api,
          groupId,
          questionIndexInSet,
          ownProps.history
        )
      ),
    duplicateQuestion: (flow, destinationPosition, destinationQuestionId) =>
      dispatch(
        actions.duplicateQuestion(
          ownProps.api,
          flow,
          destinationPosition,
          destinationQuestionId
        )
      ),
    duplicateGroup: (
      group,
      destinationPosition,
      destinationQuestionId,
      jumpFromDistributorGroup
    ) =>
      dispatch(
        actions.duplicateGroup(
          ownProps.api,
          group,
          destinationPosition,
          destinationQuestionId,
          jumpFromDistributorGroup
        )
      ),
    updateActiveFlowBeforeLeave: questionId =>
      dispatch(actions.updateActiveFlowBeforeLeave(ownProps.api, questionId)), // api call
    setCustomLoading: customLoading =>
      dispatch(actions.setCustomLoading(customLoading)),
    templateWizardToSurvey: wizardSteps =>
      dispatch(actions.setSurveyFromTemplateWizard(ownProps.api, wizardSteps)),
    updateDecisionFramework: question =>
      dispatch(actions.updateDecisionFramework(ownProps.api, question)),
    changeMatrixFrameworkType: (question, previousDecisionFrameworkType) =>
      dispatch(
        actions.changeMatrixFrameworkType(
          ownProps.api,
          question,
          previousDecisionFrameworkType
        )
      )
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyBuilder);
