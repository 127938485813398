import {
  QUESTION_TYPES,
  DECISION_FRAMEWORK_TYPES
} from '../../../helpers/constants';

import createDecisionFramework from '../createDecisionFramework/createDecisionFramework';
import removeDecisionFramework from '../removeDecisionFramework/removeDecisionFramework';

export default (state, question, previousDecisionFrameworkType) => {
  let newSurveyFrameworksState = null;
  const requests = [];

  if (
    state &&
    state.survey &&
    question &&
    question.type === QUESTION_TYPES.MATRIX &&
    question.matrix &&
    question.matrix.decisionFrameworkType
  ) {
    const questionExistsInState =
      state.surveyLists && state.surveyLists.length
        ? state.surveyLists.some(sLQ => sLQ.id === question.id)
        : false;

    if (questionExistsInState) {
      // Remove previous graph if previous type is not SINGLE
      if (previousDecisionFrameworkType !== DECISION_FRAMEWORK_TYPES.SINGLE) {
        const {
          surveyWithRemovedGraph,
          removeRequest
        } = removeDecisionFramework(
          state.survey,
          question,
          previousDecisionFrameworkType
        );

        if (surveyWithRemovedGraph) {
          newSurveyFrameworksState = {
            ...state,
            survey: surveyWithRemovedGraph
          };
          if (removeRequest) {
            requests.push(removeRequest);
          }
        }
      }

      // Create a new graph if new type is not SINGLE
      if (
        question.matrix.decisionFrameworkType !==
        DECISION_FRAMEWORK_TYPES.SINGLE
      ) {
        const {
          surveyWithCreatedFramework,
          createRequest
        } = createDecisionFramework(
          newSurveyFrameworksState && newSurveyFrameworksState.survey
            ? newSurveyFrameworksState.survey
            : state.survey,
          question
        );
        if (surveyWithCreatedFramework) {
          newSurveyFrameworksState = {
            ...(newSurveyFrameworksState || state),
            survey: surveyWithCreatedFramework
          };
          if (createRequest) {
            requests.push(createRequest);
          }
        }
      }
    }
  }

  return {
    newSurveyFrameworksState,
    requests
  };
};
