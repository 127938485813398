import React, { useState, useEffect, useRef } from 'react';

import arrowDown from '../../../../../../../../../../../../../../../assets/img/arrow-down.svg';

import styles from '../../../Matrix.module.css';

const Dropdown = props => {
  const { customPlaceholder, value, options, onSelectCompleted } = props;

  const [showPopup, setShowPopup] = useState(false);

  const dropDownRef = useRef(null);

  const placeholder = customPlaceholder || 'Select option';

  const handleClickOutside = event => {
    if (
      !(
        dropDownRef &&
        dropDownRef.current &&
        dropDownRef.current.children &&
        dropDownRef.current.children.length &&
        Array.from(dropDownRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.fieldContainer} ref={dropDownRef}>
      <div
        role="presentation"
        className={`${styles.field} ${styles.dropdown}`}
        style={{ backgroundImage: `url(${arrowDown})` }}
        onClick={() => setShowPopup(true)}
      >
        {value || <span className={styles.placeholder}>{placeholder}</span>}
      </div>
      {showPopup ? (
        <div className={styles.popupContainer}>
          <div className={styles.popupItemsContainer}>
            {options.map(option => (
              <div
                className={styles.popupItem}
                key={option.key}
                role="presentation"
                onClick={() => {
                  if (option.value !== value) {
                    onSelectCompleted(option.key);
                    setShowPopup(false);
                  }
                }}
              >
                {option.value}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
