import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import {
  getQuestionsColumn,
  getFlattenedQuestionsColumn,
  getQuestionPosition,
  getGroupParent
} from '../../../helpers/getQuestionsColumn/getQuestionsColumn';
import removeQuestionFromSurvey from '../../../helpers/removeQuestionFromSurvey/removeQuestionFromSurvey';
import removeNextFlowJumps from '../../../helpers/removeNextFlowJumps/removeNextFlowJumps';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';
import { QUESTION_TYPES } from '../../../helpers/constants';

export default (
  state,
  dropzone,
  sourceSetIndex,
  sourceQuestionindex,
  destinationSetIndex,
  destionationQuestionindex,
  destinationPosition,
  destinationQuestionId,
  groupId
) => {
  // If it is dragged but not moved, return current state
  if (
    sourceSetIndex !== undefined &&
    destinationSetIndex !== undefined &&
    sourceSetIndex === destinationSetIndex &&
    sourceQuestionindex === destionationQuestionindex
  ) {
    return { newState: state };
  }

  const surveyToModify = JSON.parse(JSON.stringify(state.survey));

  const questionsColumn = getQuestionsColumn(
    false,
    state.survey.content,
    state.survey.questions
  );

  const flattedQuestionColumn = getFlattenedQuestionsColumn(questionsColumn);

  const sourceSet =
    sourceSetIndex !== undefined && flattedQuestionColumn[sourceSetIndex];
  const destinationSet = flattedQuestionColumn[destinationSetIndex];

  const draggedQuestionId = groupId || sourceSet[sourceQuestionindex];

  const dropzoneParentGroup =
    dropzone &&
    getGroupParent(destinationSetIndex, state.survey.content.groups);

  // First remove element that is dragged
  const newSurvey = removeQuestionFromSurvey(
    draggedQuestionId,
    surveyToModify.content,
    surveyToModify.questions,
    true,
    dropzone
  );

  // Dropzone is visible if group is nested into parent group in edge position (top, bottom)
  let questionAdded = false;
  if (dropzone) {
    // Drop inside of parent group
    const parentGroup = newSurvey.content.groups.find(
      g => g.flows.indexOf(destinationSetIndex) > -1
    );
    if (parentGroup) {
      const groupIndex = parentGroup.flows.findIndex(
        f => f === destinationSetIndex
      );
      const destinationDropzoneIndex =
        destionationQuestionindex === 0 ? groupIndex : groupIndex + 1;

      parentGroup.flows.splice(destinationDropzoneIndex, 0, draggedQuestionId);
      questionAdded = true;
    }

    if (!parentGroup && dropzoneParentGroup) {
      const parentGroupIndex = newSurvey.content.groups.findIndex(
        g => g.id === dropzoneParentGroup.id
      );
      const parentGroupToReplace = newSurvey.content.groups[parentGroupIndex];
      parentGroupToReplace.flows = [draggedQuestionId];
      questionAdded = true;
    }
  }

  // Place moved element to the new position
  if ((destinationSet || destinationPosition) && !dropzone) {
    const questionDestinationPosition = !destinationPosition
      ? getQuestionPosition(
          sourceSet,
          sourceQuestionindex,
          destinationSet,
          destionationQuestionindex
        )
      : {
          position: destinationPosition,
          questionId: destinationQuestionId
        };

    if (
      newSurvey.content.flows.indexOf(questionDestinationPosition.questionId) >
      -1
    ) {
      const destinationQuestionIndex = newSurvey.content.flows.indexOf(
        questionDestinationPosition.questionId
      );

      const destinationIndex =
        questionDestinationPosition.position === 'BEFORE'
          ? destinationQuestionIndex
          : destinationQuestionIndex + 1;

      if (
        newSurvey &&
        newSurvey.questions &&
        newSurvey.questions[draggedQuestionId] &&
        newSurvey.questions[draggedQuestionId].type ===
          QUESTION_TYPES.DISTRIBUTOR &&
        destinationQuestionIndex === 0 &&
        destinationIndex === 0
      ) {
        return null;
      }

      newSurvey.content.flows.splice(destinationIndex, 0, draggedQuestionId);
    } else {
      const group = newSurvey.content.groups.find(
        g => g.flows.indexOf(questionDestinationPosition.questionId) > -1
      );

      if (group) {
        // Distributor type should not be allowed inside groups
        if (
          newSurvey &&
          newSurvey.questions &&
          newSurvey.questions[draggedQuestionId] &&
          newSurvey.questions[draggedQuestionId].type ===
            QUESTION_TYPES.DISTRIBUTOR
        ) {
          return null;
        }

        const destinationQuestionIndex = group.flows.indexOf(
          questionDestinationPosition.questionId
        );

        const destinationIndex =
          questionDestinationPosition.position === 'BEFORE'
            ? destinationQuestionIndex
            : destinationQuestionIndex + 1;

        group.flows.splice(destinationIndex, 0, draggedQuestionId);
      }
    }
  }

  // Question dropped on add new question placeholder
  if (!destinationSet && !destinationPosition && !dropzone) {
    newSurvey.content.flows.push(draggedQuestionId);
  }

  // If destination set doesn't exist and it is dropzoone put question on first position
  if (!destinationSet && !destinationPosition && dropzone && !questionAdded) {
    // Distributor type should not be allowed on the first position of the survey
    if (
      newSurvey &&
      newSurvey.questions &&
      newSurvey.questions[draggedQuestionId] &&
      newSurvey.questions[draggedQuestionId].type === QUESTION_TYPES.DISTRIBUTOR
    ) {
      return null;
    }

    newSurvey.content.flows = [draggedQuestionId, ...newSurvey.content.flows];
  }

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      content: newSurvey.content,
      questions: newSurvey.questions
    }
  };

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  // Check if any of the questions have flowJump pointing to the nextQuestion as a result of question move
  const removedNextFlowJumps = removeNextFlowJumps(newState);
  newState = removedNextFlowJumps.state;

  // Sort distributor groups according to branch jumps
  sortDistributorGroupsAndJumps(newState);

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return {
    newState,
    changedFlowsRemovedJumpsToUpdate:
      removedNextFlowJumps.changedFlowsRemovedJumpsToUpdate
  };
};
