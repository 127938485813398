import React, { useEffect, useState, useRef, useCallback } from 'react';

import RangeSelector from './components/RangeSelector';

import './DemographicsRangeSelector.css';

const DemographicsRangeSelector = ({
  onToggleFilter,
  activeFilters,
  onSetFiltersRelation,
  demographicsActions,
  setDemograpicsActions,
  showRangeSelector,
  setShowRangeSelector,
  dropdownTogglerRef,
  showCompareToTotalSample,
  ageFilteringOnSelection,
  setAgeFilteringOnSelection
}) => {
  const [ageGroups, setAgeGroups] = useState([]);
  const [counter, setCounter] = useState(0);

  const dropdownRef = useRef(null);

  const handleBlur = useCallback(e => {
    if (
      !(
        (dropdownRef.current && dropdownRef.current.contains(e.target)) ||
        (dropdownTogglerRef.current &&
          dropdownTogglerRef.current.contains(e.target))
      )
    ) {
      setDemograpicsActions(false);
    }
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);
    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, []);

  useEffect(
    () => {
      const demographicAgeActiveLength = activeFilters.filter(
        f => String('ageRange').indexOf(f.filterName.slice(0, 6)) !== -1
      ).length;

      const filterNames = activeFilters.map(f => f.filterName);
      setAgeGroups(ageGroups.filter(f => filterNames.indexOf(f.key) !== -1));

      if (demographicAgeActiveLength === 0) {
        setShowRangeSelector(true);
      }
    },
    [activeFilters]
  );

  const demographicAgeRangeActive = activeFilters.filter(
    filter => filter.filterName === 'ageRangeGroups'
  );

  const demographicAgeActive = activeFilters.filter(
    filter => filter.filterName === 'ageGroups'
  );

  let initialMinValue = null;
  let initialMaxValue = null;

  const calculatePotentialRange = () => {
    const usedRanges = activeFilters
      .filter(f => f.filterName.indexOf('ageRangeGroups-') !== -1)
      .map(f => f.value);

    initialMinValue = 16;
    initialMaxValue = 26;

    usedRanges.forEach(r => {
      let [minRange, maxRange] = r.split('-');

      minRange = parseInt(minRange, 10);
      maxRange = parseInt(maxRange, 10);

      if (
        (initialMinValue >= minRange && initialMinValue <= maxRange) ||
        (initialMaxValue >= minRange && initialMaxValue <= maxRange)
      ) {
        initialMinValue = maxRange + 1;
        initialMaxValue = initialMinValue + 10;

        if (initialMaxValue >= 80) initialMaxValue = 80;
        if (initialMinValue >= 80) initialMinValue = 80;
      }
    });

    return [initialMinValue, initialMaxValue];
  };

  if (demographicAgeRangeActive.length) {
    if (demographicAgeRangeActive[0].value) {
      const [
        initialMinValueString,
        initialMaxValueString
      ] = demographicAgeRangeActive[0].value.split('-');

      initialMinValue = parseInt(initialMinValueString, 10);
      initialMaxValue = parseInt(initialMaxValueString, 10);
    }
  }

  const pushSelector = () => {
    const [minValue, maxValue] = calculatePotentialRange();

    return (
      <RangeSelector
        minValue={16}
        maxValue={80}
        initialMinValue={minValue}
        initialMaxValue={maxValue}
        key={`ageRangeGroups-${counter}`}
        setTargetAudiencesConfigure={f => {
          if (f.age.$gte && f.age.$lte) {
            const range = `${f.age.$gte}-${f.age.$lte}`;

            onToggleFilter(
              `ageRangeGroups-${counter}`,
              'attributes.userData.age',
              range,
              null,
              value => {
                if (value && value >= f.age.$gte && value <= f.age.$lte) {
                  return range;
                }

                return true;
              }
            );

            if (
              ageGroups.length >= 1 ||
              (demographicAgeActive && demographicAgeActive.length >= 1)
            ) {
              onSetFiltersRelation('or');
            }
          }

          return null;
        }}
        audienceGroupName="age"
        greaterThanOperator="$gte"
        lessThanOperator="$lte"
      />
    );
  };

  return (
    <>
      {demographicsActions && (
        <div
          className="demographics-dropdown"
          style={{ top: showRangeSelector ? -170 : -190 }}
          ref={dropdownRef}
        >
          <div
            role="presentation"
            className="demographics-dropdown-action"
            onClick={() => {
              setDemograpicsActions(false);
              activeFilters.forEach(f => {
                if (
                  f.filterName.indexOf('ageRangeGroups') !== -1 ||
                  f.filterName === 'ageGroups'
                ) {
                  onToggleFilter(
                    f.filterName,
                    f.valuePath,
                    f.value,
                    null,
                    f.transformator
                  );
                }
              });
              setAgeGroups([]);
              setShowRangeSelector(true);
            }}
          >
            <span className="demographics-dropdown-action-text">
              Reset age filter
            </span>
          </div>
          <div
            role="presentation"
            className="demographics-dropdown-action"
            onClick={() => {
              setDemograpicsActions(false);
              setShowRangeSelector(false);
              setAgeGroups(prev => [...prev, pushSelector()]);
              setCounter(prev => prev + 1);
            }}
          >
            <span className="demographics-dropdown-action-text">
              Add age category
            </span>
          </div>
          {showCompareToTotalSample ? (
            <div
              role="presentation"
              className="demographics-dropdown-action"
              onClick={() => {
                setDemograpicsActions(false);
                setAgeFilteringOnSelection(!ageFilteringOnSelection);
              }}
            >
              <span className="demographics-dropdown-action-text">
                {ageFilteringOnSelection
                  ? `Compare to total sample`
                  : `Compare selected vs not selected`}
              </span>
            </div>
          ) : null}
        </div>
      )}
      {showRangeSelector ? (
        <div
          className="demographic-range-selector-activate-button"
          onClick={() => {
            setShowRangeSelector(false);
            setAgeGroups(prev => [...prev, pushSelector()]);
            setCounter(prev => prev + 1);
          }}
          role="presentation"
        >
          Select custom range
        </div>
      ) : (
        <div className="demographic-range-selector-container">
          {ageGroups && ageGroups.map(g => g)}
          <span className="demographic-range-selector-min input-range__label">
            16
          </span>
          <span className="demographic-range-selector-max input-range__label">
            80
          </span>
        </div>
      )}
    </>
  );
};

export default DemographicsRangeSelector;
