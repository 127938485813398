import gql from 'graphql-tag';

export const COUPON_PDF_UPLOAD_SAS = gql`
  query couponPDFUploadSAS($amount: PositiveInt!) {
    couponPDFUploadSAS(input: { amount: $amount }) {
      result {
        token
        uri
        filename
      }
    }
  }
`;

export const GENERATE_SHOP_ITEM_COUPONS = gql`
  mutation GenerateShopItemCoupons(
    $invoice: String!
    $purchasedAt: DateTime!
    $supplier: String!
    $value: PositiveInt!
    $keyCoupons: [KeyCouponInput!]!
    $pdfCoupons: [PDFCouponInput!]!
    $shopItem: ID
  ) {
    generateShopItemCoupons(
      input: {
        invoice: $invoice
        purchasedAt: $purchasedAt
        supplier: $supplier
        value: $value
        keyCoupons: $keyCoupons
        pdfCoupons: $pdfCoupons
        shopItem: $shopItem
      }
    ) {
      coupons {
        id
      }
    }
  }
`;

export const QUERY_SHOP_ITEMS = gql`
  query ShopItems {
    shopItems {
      id
      name
      visible
    }
  }
`;
