import React, { useContext } from 'react';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import Footer from '../../Footer/Footer';

import english from '../../../../../../../../../assets/img/templateswizard/english.svg';
import dutch from '../../../../../../../../../assets/img/templateswizard/dutch.svg';
import french from '../../../../../../../../../assets/img/templateswizard/french.svg';

import {
  TEMPLATES_WIZARD_STEP_KEYS,
  SURVEY_LANGUAGES
} from '../../../../../helpers/constants';

import styles from './SelectLanguage.module.css';

export default () => {
  const {
    currentStep,
    setStepValue,
    goToNextStep,
    goToPreviousStep,
    strings,
    styleText
  } = useContext(TemplatesWizardContext);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {styleText(strings.IN_WHAT_LANGUAGE_WOULD_YOU_LIKE_TO, styles)}
      </div>
      <div className={styles.optionsContainer}>
        <div
          className={styles.option}
          onClick={() =>
            setStepValue(
              TEMPLATES_WIZARD_STEP_KEYS.SELECT_LANGUAGE,
              SURVEY_LANGUAGES.DUTCH
            )
          }
          role="presentation"
        >
          <img src={dutch} alt="Nederlands" />
          <div className={styles.optionLabel}>Nederlands</div>
          {currentStep && currentStep.value === SURVEY_LANGUAGES.DUTCH && (
            <div className={styles.selectedBar} />
          )}
        </div>
        <div
          className={styles.option}
          onClick={() =>
            setStepValue(
              TEMPLATES_WIZARD_STEP_KEYS.SELECT_LANGUAGE,
              SURVEY_LANGUAGES.FRENCH
            )
          }
          role="presentation"
        >
          <img src={french} alt="Français" />
          <div className={styles.optionLabel}>Français</div>
          {currentStep && currentStep.value === SURVEY_LANGUAGES.FRENCH && (
            <div className={styles.selectedBar} />
          )}
        </div>
        <div
          className={styles.option}
          onClick={() =>
            setStepValue(
              TEMPLATES_WIZARD_STEP_KEYS.SELECT_LANGUAGE,
              SURVEY_LANGUAGES.ENGLISH
            )
          }
          role="presentation"
        >
          <img src={english} alt="English" />
          <div className={styles.optionLabel}>English</div>
          {currentStep && currentStep.value === SURVEY_LANGUAGES.ENGLISH && (
            <div className={styles.selectedBar} />
          )}
        </div>
      </div>
      <div className={styles.info}>{strings.PREPARE_IN_MOTHER_LANGUAGE}</div>
      <div className={styles.footerContainer}>
        <Footer
          onNextClick={goToNextStep}
          onPreviousClick={goToPreviousStep}
          nextLabel={strings.NEXT}
          previousLabel={strings.PREVIOUS}
        />
      </div>
    </div>
  );
};
