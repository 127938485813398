import React from 'react';
import Dropdown from '../../../../../../campaigns/components/Dropdown';
import Icon from '../../../../../../campaigns/components/Icon';

import playIcon from '../../../../../../assets/img/play.svg';
import pauseIcon from '../../../../../../assets/img/pause.svg';
import deleteIcon from '../../../../../../assets/img/accountmanagement/am-delete.svg';

import { COMMUNITY_AUTOMATION_STATUSES } from '../../../../helpers/constants';

import styles from './ActionDropdown.module.css';

export default class ActionDropdown extends Dropdown {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteConfirmationDialog: false
    };
  }

  render() {
    return (
      <div
        ref={this.setWrapperRef}
        className={`${styles.popup} ${styles.actionDropdownPopup}`}
        style={this.props.positionStyle}
      >
        <div
          className={styles.option}
          role="presentation"
          onClick={e => {
            e.stopPropagation();
            this.props.onEditClick();
          }}
        >
          <span className={styles.icon}>
            <Icon type="edit" />
          </span>
          Edit
        </div>
        <div
          className={styles.option}
          role="presentation"
          onClick={e => {
            e.stopPropagation();
            this.props.onDuplcateClick();
          }}
        >
          <span className={styles.icon}>
            <Icon type="duplicate" />
          </span>
          Duplicate
        </div>
        {COMMUNITY_AUTOMATION_STATUSES.ACTIVE === this.props.recordStatus ? (
          <div
            className={styles.option}
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              this.props.onDeactivateClick();
            }}
          >
            <span className={styles.icon}>
              <img
                alt="Deactivate icon"
                src={pauseIcon}
                className={styles.deleteIcon}
              />
            </span>
            Deactivate
          </div>
        ) : null}
        {COMMUNITY_AUTOMATION_STATUSES.INACTIVE === this.props.recordStatus ? (
          <div
            className={styles.option}
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              this.props.onDeactivateClick();
            }}
          >
            <span className={styles.icon}>
              <img
                alt="Activate icon"
                src={playIcon}
                className={styles.deleteIcon}
              />
            </span>
            Activate
          </div>
        ) : null}

        <div
          role="presentation"
          className={styles.option}
          onClick={e => {
            e.stopPropagation();
            this.props.onDeleteClick();
          }}
        >
          <span className={styles.icon}>
            <img
              alt="Delete icon"
              src={deleteIcon}
              className={styles.deleteIcon}
            />
          </span>
          Delete
        </div>
      </div>
    );
  }
}
