import React, { useEffect, useState } from 'react';

import Icon from '../../../../../../../SurveyBuilder/components/Icon/Icon';
import ExcludeDescriptionTextarea from '../ExcludeDescriptionTextarea/ExcludeDescriptionTextarea';

import {
  SURVEY_END_TYPES,
  TARGET_AUDIENCE_STEPS
} from '../../../../../../../SurveyBuilder/helpers/constants';

import styles from './ProfilingQuestionDetails.module.css';

import arrowDownPurple from '../../../../../../../../../../assets/img/arrow-down-purple.svg';

export default ({
  groupIndex,
  actions,
  availableProfilingQuestion,
  profilingQuestion,
  profilingQuestions,
  setProfilingQuestions,
  onSelectAvailableProfilingQuestionClick,
  isNotEligiblePage,
  scrollContainerRef,
  targetAudienceStep
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [divRef, setDivRef] = useState(null);

  useEffect(
    () => {
      if (
        profilingQuestion &&
        availableProfilingQuestion.id === profilingQuestion.id
      ) {
        if (divRef && scrollContainerRef) {
          let element = divRef;
          let yPosition = 0;

          while (element) {
            const nextYPosition =
              yPosition +
              element.offsetTop -
              element.scrollTop +
              element.clientTop;

            if (nextYPosition > yPosition) {
              yPosition = nextYPosition;
            }

            element = element.offsetParent;
          }
          const verticalCorrection = 200;
          scrollContainerRef.scrollTop = yPosition - verticalCorrection; // eslint-disable-line
        }
      }
    },
    [divRef, profilingQuestion]
  );

  useEffect(
    () => {
      if (targetAudienceStep === TARGET_AUDIENCE_STEPS.PROFILING_QUESTIONS) {
        setIsExpanded(false);
      }
      if (
        targetAudienceStep === TARGET_AUDIENCE_STEPS.NOT_ELIGIBLE_RESPONDENTS
      ) {
        setIsExpanded(true);
      }
    },
    [targetAudienceStep]
  );

  const getChoices = () => {
    if (profilingQuestion && Object.keys(profilingQuestion).length) {
      return profilingQuestion.choices;
    }
    if (
      availableProfilingQuestion &&
      Object.keys(availableProfilingQuestion).length
    ) {
      return availableProfilingQuestion.choices;
    }
    return [];
  };

  const displayBlockQuestion = blockQuestion => {
    try {
      return JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n\r');
    } catch (error) {
      return blockQuestion;
    }
  };

  const updateProfilingQuestionAnswer = (choiceIndex, newChoice) => {
    actions.updateQuestionChoice(
      profilingQuestion.id,
      newChoice,
      true,
      true,
      true
    );

    const newProfilingQuestion = { ...profilingQuestion };
    newProfilingQuestion.choices[choiceIndex] = newChoice;

    const newProfilingQuestionIndex = profilingQuestions.findIndex(
      q => q.id === newProfilingQuestion.id
    );

    const newProfilingQuestions = [...profilingQuestions];
    newProfilingQuestions[newProfilingQuestionIndex] = newProfilingQuestion;

    setProfilingQuestions([...newProfilingQuestions]);
  };

  return (
    <div
      className={
        Object.keys(profilingQuestion).length > 0 &&
        profilingQuestion.profiling &&
        profilingQuestion.profiling.id !== availableProfilingQuestion.id
          ? `${styles.container} ${styles.notActiveQuestion}`
          : styles.container
      }
      key={`question-details-${groupIndex.toString()}`}
      ref={r => setDivRef(r)}
    >
      <div
        className={
          isExpanded ? `${styles.question} ${styles.expanded}` : styles.question
        }
      >
        <div className={styles.checkboxContainer}>
          {!isNotEligiblePage ? (
            <input
              type="checkbox"
              onChange={() =>
                onSelectAvailableProfilingQuestionClick(
                  availableProfilingQuestion
                )
              }
              checked={
                profilingQuestion &&
                profilingQuestion.profiling &&
                profilingQuestion.profiling.id === availableProfilingQuestion.id
              }
            />
          ) : null}
        </div>
        <div className={styles.questionText}>
          {displayBlockQuestion(availableProfilingQuestion.question)}
        </div>
        <div className={styles.expandIconContainer}>
          <div
            className={styles.arrowDownPurple}
            onClick={() => setIsExpanded(!isExpanded)}
            role="presentation"
          >
            <img src={arrowDownPurple} alt="Purple arrow down" />
          </div>
        </div>
      </div>
      {isExpanded ? (
        <>
          <div className={styles.answersContainer}>
            {getChoices().map((a, i) => (
              <>
                <div
                  className={styles.answer}
                  key={`answers-${groupIndex.toString()}-${i.toString()}`}
                >
                  {isNotEligiblePage ? (
                    <input
                      type="checkbox"
                      onChange={() => {
                        const newChoice = { ...a };
                        if (a.end) {
                          newChoice.end = null;
                        } else {
                          newChoice.end = SURVEY_END_TYPES.NOT_ELIGIBLE;
                        }

                        updateProfilingQuestionAnswer(i, newChoice);
                      }}
                      checked={a.end === SURVEY_END_TYPES.NOT_ELIGIBLE}
                    />
                  ) : null}
                  {a.answer}
                </div>
                {isNotEligiblePage &&
                a.end === SURVEY_END_TYPES.NOT_ELIGIBLE ? (
                  <>
                    <div className={styles.bottomInfo}>
                      <Icon type="ljArrow" className={styles.ljArrow} />
                      Respondents will be filtered out of the survey when
                      selecting this answer option (not eligible)
                    </div>
                    <ExcludeDescriptionTextarea
                      className={styles.whyFilteredOutExplanation}
                      onChangeValue={value => {
                        const newChoice = { ...a };

                        if (!newChoice.profilingTag) {
                          newChoice.profilingTag = {};
                        }

                        newChoice.profilingTag.excludeDescription = value;

                        updateProfilingQuestionAnswer(i, newChoice);
                      }}
                      initialValue={
                        a && a.profilingTag && a.profilingTag.excludeDescription
                          ? a.profilingTag.excludeDescription
                          : ''
                      }
                      placeholder="Why do you want to exclude these respondents? Make sure to validate this decision. We strongly recommend to first compare target audiences and focus on specific audiences in follow up research."
                    />
                  </>
                ) : null}
              </>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};
