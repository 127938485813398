export const GET_CREDENTIALS = 'GET_CREDENTIALS';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const REGISTER_REQUESTED = 'REGISTER_REQUESTED';
export const REGISTER_SUCCEEDED = 'REGISTER_SUCCEEDED';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const CHANGE_LOGIN_FIELD = 'CHANGE_LOGIN_FIELD';
export const CHANGE_REGISTRATION_FIELD = 'CHANGE_REGISTRATION_FIELD';

export const LOGOUT = 'LOGOUT';
export const SHOW_ERRORS = 'SHOW_ERRORS';

export const SET_CLIENT_STARRED_SURVEY = 'SET_CLIENT_STARRED_SURVEY';

export const SET_SELECTED_ACCOUNT_MANAGEMENT_RECORD =
  'SET_SELECTED_ACCOUNT_MANAGEMENT_RECORD';

export function getCredentials(beforeLogin) {
  return {
    type: GET_CREDENTIALS,
    beforeLogin
  };
}

export function requestLogin(email, password, invitationToken) {
  return { type: LOGIN_REQUESTED, email, password, invitationToken };
}

export function receiveLogin(client, clientEmail) {
  return { type: LOGIN_SUCCEEDED, token: client.token, clientEmail };
}

export function loginError(message) {
  return {
    type: LOGIN_FAILED,
    message
  };
}

export function changeLoginField(field, value) {
  return {
    type: CHANGE_LOGIN_FIELD,
    field,
    value
  };
}

export function logout() {
  return { type: LOGOUT };
}

export function changeRegistrationField(field, value) {
  return {
    type: CHANGE_REGISTRATION_FIELD,
    field,
    value
  };
}

export function register(
  email,
  password,
  betaKey,
  company,
  telephone,
  firstname,
  lastname,
  howDidYouLearnAboutBuffl
) {
  return {
    type: REGISTER_REQUESTED,
    email,
    password,
    betaKey,
    company,
    telephone,
    firstname,
    lastname,
    howDidYouLearnAboutBuffl
  };
}

export function showErrors(detail) {
  return {
    type: SHOW_ERRORS,
    detail
  };
}

export function setClientStarredSurvey(campaign, star) {
  return { type: SET_CLIENT_STARRED_SURVEY, campaign, star };
}

export function setSelectedAccountManagementRecord(record) {
  return { type: SET_SELECTED_ACCOUNT_MANAGEMENT_RECORD, record };
}
