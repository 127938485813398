import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { REMOVE_COMMUNITY } from '../../../../../../graphql/CommunityManagement';
import { COMMUNITY_TYPE_LABELS } from '../../../../helpers/constants';
import formatDate from '../../../../../../helpers/formatDate';

import ActionDropdown from '../ActionDropdown/ActionDropdown';
import Loader from '../../../../../CommunityAutomations/components/Loader/Loader';
import Popup from '../../../../../../campaigns/components/Popup';
import ConfirmationDialog from '../../../../../../campaigns/components/ConfirmationDialog/ConfirmationDialog';

import styles from './CommunityManagementRecord.module.css';

export default ({ key, history, record, refetch, exportUsers }) => {
  const [actionDropdownVisible, setActionDropdownVisible] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(null);

  const [removeCommunity, { loading: removeCommunityLoading }] = useMutation(
    REMOVE_COMMUNITY
  );

  const isLoading = removeCommunityLoading;

  const createdAtDate =
    record && record.createdAt ? new Date(record.createdAt) : null;
  const createdAt = createdAtDate ? formatDate(createdAtDate) : '';

  const actionDropdownPositionStyle = {
    right: '10px',
    top: '25px',
    bottomPosition: '40px',
    width: '160px'
  };

  return (
    <tr className={styles.rowContainer} key={key}>
      <td className={`${styles.typeColumn} ${styles.firstColumn}`}>
        {COMMUNITY_TYPE_LABELS[record.type]}
      </td>
      <td className={styles.nameColumn} title={record.name}>
        {record.name}
      </td>
      <td className={styles.createdAtColumn}>{createdAt}</td>
      <td>
        <div className={styles.actionsContainer}>
          {isLoading ? <Loader /> : null}
          <div
            role="presentation"
            className={styles.actionsButton}
            onClick={e => {
              e.stopPropagation();
              setActionDropdownVisible(true);
            }}
          >
            ...
          </div>
          {confirmationPopup ? (
            <Popup
              component={
                <ConfirmationDialog
                  label={
                    <div style={{ textAlign: 'center' }}>
                      {confirmationPopup.actionLabel}
                    </div>
                  }
                  confirmLabel={confirmationPopup.confirmLabel}
                  cancelLabel="Cancel"
                  onConfirm={() => {
                    confirmationPopup.onConfirm();
                  }}
                  onCancel={() => {
                    setConfirmationPopup(null);
                  }}
                />
              }
              onClose={() => {
                setConfirmationPopup(null);
              }}
            />
          ) : null}
          {actionDropdownVisible ? (
            <ActionDropdown
              positionStyle={actionDropdownPositionStyle}
              onBlur={() => setActionDropdownVisible(false)}
              onEditClick={() => {
                setActionDropdownVisible(false);
                history.push(`/community-management/${record.id}`);
              }}
              onExportClick={async () => {
                setActionDropdownVisible(false);

                if (record && record.name) {
                  await exportUsers(record.name);
                }
              }}
              onDeleteClick={() => {
                setActionDropdownVisible(false);

                setConfirmationPopup({
                  actionLabel:
                    'Are you sure you want to delete this community?',
                  confirmLabel: 'Delete',
                  onConfirm: async () => {
                    await removeCommunity({
                      variables: {
                        id: record.id
                      }
                    });
                    refetch();
                  }
                });
              }}
            />
          ) : null}
        </div>
      </td>
    </tr>
  );
};
