import React, { useState } from 'react';

import tickIcon from '../../../../assets/img/tick.svg';
import closeIcon from '../../../../assets/img/delete.svg';

import Icon from '../SurveyBuilder/components/Icon/Icon';
import SurveySelector from './components/SurveySelector/SurveySelector';

import styles from './ConfirmationPopup.module.css';

export default ({
  title,
  description,
  warning,
  onClick,
  onClose,
  onDontShowAnymoreChecked,
  checkboxLabel = 'Dont show this again',
  defaultValue,
  surveySelection
}) => {
  const [dontShowChecked, setDontShowChecked] = useState(defaultValue || false);
  const [selectedSurveys, setSelectedSurveys] = useState([]);

  return (
    <div className={styles.container}>
      <div className={`${styles.title} workspaces-manage-workspace-title`}>
        {title}
      </div>
      <div className={styles.description}>{description}</div>
      {warning ? <div className={styles.warning}>{warning}</div> : null}
      {onDontShowAnymoreChecked ? (
        <div className={styles.selectAllContainerWrapper}>
          <div className={styles.selectAllContainer}>
            <div className={styles.selectorContainer}>
              <span
                onClick={() => setDontShowChecked(!dontShowChecked)}
                role="presentation"
                className="workspaces-manage-workspace-select-all"
              >
                <Icon
                  type={
                    dontShowChecked ? 'checkboxChecked' : 'checkboxUnChecked'
                  }
                />
              </span>
            </div>
            <span
              onClick={() => setDontShowChecked(!dontShowChecked)}
              role="presentation"
            >
              {checkboxLabel}
            </span>
          </div>
          {surveySelection && dontShowChecked ? (
            <SurveySelector
              amSurveyRecord={surveySelection.amSurveyRecord}
              initialSurveys={surveySelection.initialSurveys}
              surveyId={surveySelection.surveyId}
              selectedSurveys={selectedSurveys}
              setSelectedSurveys={setSelectedSurveys}
            />
          ) : null}
        </div>
      ) : null}
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          <img
            className={`${
              styles.confirm
            } workspaces-manage-workspace-confirm-button`}
            src={tickIcon}
            alt="Confirm icon"
            onClick={() => {
              onClick(dontShowChecked, selectedSurveys);

              if (onDontShowAnymoreChecked && dontShowChecked) {
                onDontShowAnymoreChecked();
              }

              onClose();
            }}
            role="presentation"
          />
          <img
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
            onClick={() => onClose()}
            role="presentation"
          />
        </div>
      </div>
    </div>
  );
};
