import React from 'react';

import NumberInputRow from '../NumberInputRow/NumberInputRow';

import styles from './AccessToCommunity.module.css';
import { licenceTypes } from '../../../../helpers/constants';

export default ({
  internalRecord,
  setInternalRecord
  // activePopup
}) => {
  const defaultPermissions = [
    {
      label: 'Number of surveys included in license',
      value: internalRecord.permissions.surveysIncludedInLicense,
      onChange: newAllocatedValue => {
        setInternalRecord({
          ...internalRecord,
          permissions: {
            ...internalRecord.permissions,
            surveysIncludedInLicense: newAllocatedValue
          }
        });
      }
    },
    {
      label: 'Incidence rate (in %)',
      value: internalRecord.permissions.incidenceRateIncludedInLicence,
      onChange: newAllocatedValue => {
        setInternalRecord({
          ...internalRecord,
          permissions: {
            ...internalRecord.permissions,
            incidenceRateIncludedInLicence: newAllocatedValue
          }
        });
      }
    },
    {
      label: 'Default sample size',
      value: internalRecord.permissions.sampleSizeIncludedInLicence,
      onChange: newAllocatedValue => {
        setInternalRecord({
          ...internalRecord,
          permissions: {
            ...internalRecord.permissions,
            sampleSizeIncludedInLicence: newAllocatedValue
          }
        });
      }
    },
    {
      label: 'Number of survey questions included in license',
      value: internalRecord.permissions.questionsPerSurveyIncludedInLicence,
      onChange: newAllocatedValue => {
        setInternalRecord({
          ...internalRecord,
          permissions: {
            ...internalRecord.permissions,
            questionsPerSurveyIncludedInLicence: newAllocatedValue
          }
        });
      }
    }
  ];

  let permissions = defaultPermissions;

  if (
    internalRecord.level === 1 &&
    internalRecord.licence &&
    internalRecord.licence === licenceTypes.UNLIMITED
  ) {
    const numberOfSurveysInLicencePermission = {
      label: 'Number of surveys included in license (fair use)',
      value: internalRecord.permissions.surveysIncludedInLicense,
      onChange: newAllocatedValue => {
        setInternalRecord({
          ...internalRecord,
          permissions: {
            ...internalRecord.permissions,
            surveysIncludedInLicense: newAllocatedValue
          }
        });
      },
      tooltipText:
        'Define the number of surveys customer can make without charging base budget.'
    };
    permissions = [numberOfSurveysInLicencePermission, ...permissions];
  }

  return (
    <div className={styles.content}>
      <div className={styles.subtitle}>Fair use policy</div>
      {permissions.map(permission => (
        <NumberInputRow
          key={permission.label}
          label={permission.label}
          value={permission.value}
          onChange={permission.onChange}
          tooltipText={permission.tooltipText}
        />
      ))}
      <div className={styles.bottomBorderDiv} />
    </div>
  );
};
