import React from 'react';

import Icon from '../../../Icon/Icon';

import styles from './SidePropertiesContainer.module.css';

export default ({ children, icon, top, title, right }) => (
  <div className={styles.container} style={{ top, right }}>
    <div className={styles.title}>
      {icon && false && (
        <Icon type="settings-icon" className={styles.settingsIcon} />
      )}
      <div className={styles.titleLabel}>{title}</div>
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);
