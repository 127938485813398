import React, { useState, useContext } from 'react';

import SurveyBuilderPageContext from '../SurveyBuilderPageContext';

import styles from './TestSurvey.module.css';
import linkIcon from '../../../../assets/img/link.svg';

export default () => {
  const { survey } = useContext(SurveyBuilderPageContext);

  const SECTIONS = {
    WEB: 'Web',
    MOBILE: 'Mobile app'
  };

  const [section, setSection] = useState('WEB');
  const [linkCopied, setLinkCopied] = useState(false);

  const copyLinkToClipboard = value => {
    const dummy = document.createElement('input');
    // dummy.style.display = 'none';
    document.body.appendChild(dummy);

    dummy.setAttribute('id', 'goalurl');
    document.getElementById('goalurl').value = value;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    setLinkCopied(true);
  };

  const testSurveyLink = `${window.location.origin.toString()}/users/campaign/${
    survey.id
  }/user/${survey.client.id}/?test=true`;

  return (
    <div className={styles.container}>
      <div className={styles.sections}>
        {Object.keys(SECTIONS).map((s, index) => (
          <div
            className={
              s === section
                ? `${styles.sectionName} ${styles.active}`
                : styles.sectionName
            }
            onClick={() => setSection(s)}
            role="presentation"
            key={`test-survey-section-item-${index.toString()}`}
          >
            {SECTIONS[s]}
          </div>
        ))}
      </div>
      {section === 'WEB' ? (
        <div className={styles.webContainer}>
          <div className={styles.subsectionContainer}>
            <div className={styles.subsectionTitle}>Open</div>
          </div>
          <div className={styles.smallTitle}>Pro tip</div>
          <div className={styles.description}>
            Use a web version of this survey to test the logic jumps and answer
            possibilities. Beware that the UI of our mobile app is slightly
            different from its web version.
          </div>
          <div
            className={styles.bigButton}
            onClick={() => window.open(testSurveyLink, '_blank')}
            role="presentation"
          >
            Open
          </div>
          <div className={styles.subsectionContainer}>
            <div className={styles.subsectionTitle}>Share</div>
          </div>
          <div className={styles.description}>
            Ask for a second opinion by sharing the link of the web version of
            this survey.
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.link}>
              <img
                className={styles.websiteIcon}
                src={linkIcon}
                alt="Website icon"
              />
              {`${window.location.origin.toString()}/users/campaign/${
                survey.id
              }/user/${survey.client.id}/?test=true`}
            </div>
            <div
              className={
                linkCopied
                  ? `${styles.copyLinkButton} ${styles.copied}`
                  : styles.copyLinkButton
              }
              onClick={() => copyLinkToClipboard(testSurveyLink)}
              role="presentation"
            >
              {linkCopied ? 'Copied' : 'Copy link'}
            </div>
          </div>
        </div>
      ) : null}
      {section === 'MOBILE' ? (
        <div className={styles.mobileContainer}>
          <div className={styles.subsectionContainer}>
            <div className={styles.subsectionTitle}>Download</div>
          </div>
          <div className={styles.description}>
            Want to experience the look and feel of your survey for respondent?
            <br />
            Test your survey in the app and login with the credentials below.
          </div>
          <div
            className={styles.bigButton}
            onClick={() =>
              window.open(
                'https://landing.buffl.be/installeer-buffl-app/',
                '_blank'
              )
            }
            role="presentation"
          >
            Download
          </div>
        </div>
      ) : null}
    </div>
  );
};
