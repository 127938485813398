import React, { useState, useEffect } from 'react';
import arrowDown from '../../../../../../assets/img/arrow-down.svg';

import styles from './DropdownSelector.module.css';

export default props => {
  const {
    title,
    mandatory,
    options,
    value,
    onSelectCompleted,
    multiple,
    placeholder,
    disabled
  } = props;

  let wrapperRef = null;

  const [showPopup, setShowPopup] = useState(false);

  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener('mousedown', handleClickOutside);
    },
    []
  );

  const selectValue = option => {
    onSelectCompleted(option.value, option.label);
    if (!multiple) {
      setShowPopup(false);
    }
  };

  const displayPlaceholder =
    value && (value.value === '' || !value.value || value.value.length === 0);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title}
        {mandatory ? <span className={styles.requiredAsterisk}>*</span> : null}
      </div>
      <div
        className={styles.filterDropdownSelectorContainer}
        ref={elem => {
          if (elem) {
            wrapperRef = elem;
          }
        }}
      >
        <div
          role="presentation"
          className={`${styles.selectedOptionsLabel} ${
            displayPlaceholder ? styles.placeholder : ''
          } ${disabled ? styles.disabled : ''}`}
          style={{ backgroundImage: `url(${arrowDown})` }}
          onClick={() => {
            if (!disabled) {
              setShowPopup(!showPopup);
            }
          }}
        >
          {displayPlaceholder ? placeholder : value.label}
        </div>
        {showPopup ? (
          <div className={styles.filterDropdownPopup}>
            <div className={styles.filterDropdownPopupItemsContainer}>
              {options && options.length ? (
                options.map((option, index) => (
                  <div
                    className={styles.filterDropdownItem}
                    key={`filter-dropdown-item-${index.toString()}`}
                    role="presentation"
                    onClick={() => selectValue(option)}
                  >
                    <div className={styles.filterDropdownItemLabel}>
                      {option && option.label ? option.label : ''}
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.noAvailableClientAccounts}>
                  No available options
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
