import React, { useState, useEffect } from 'react';

import tickIcon from '../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../assets/img/delete.svg';

import AdvancedFilterSetting from './components/AdvancedFilterSetting';

import {
  DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS,
  DYNAMIC_FILTER_GROUPS
} from '../../../SurveyBuilder/helpers/constants';

import checkIf65AgeIncluded from '../../helpers/checkIf65AgeIncluded/checkIf65AgeIncluded';
import setDefaultFiltersForFilterGroup from '../../helpers/setDefaultFiltersForFilterGroup/setDefaultFiltersForFilterGroup';
import checkIsUserFilterSameAsDefault from '../../helpers/isUserFilterSameAsDefault/isUserFilterSameAsDefault';
import checkAgeGroupsSum from '../../helpers/checkAgeGroupsSum/checkAgeGroupsSum';

import styles from './AdvancedFilterSettings.module.css';

export default ({
  type,
  updateSurveySettings,
  filterSettings,
  setShowAdvancedSettingsPopup,
  onUserFilterChange,
  userFilter,
  maxResponses
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [
    advancedFilterSettingsState,
    setAdvancedFilterSettingsState
  ] = useState({
    initial: null,
    saved: null
  });
  const [isUserFilterSameAsDefault, setIsUserFilterSameAsDefault] = useState(
    true
  );
  const [dirty, setDirty] = useState(false);
  const [ageGroupsSum, setAgeGroupsSum] = useState(
    checkAgeGroupsSum(userFilter, maxResponses)
  );
  const [isCrossedAvailable, setIsCrossedAvailable] = useState(
    ageGroupsSum && ageGroupsSum === 100
  );

  useEffect(() => {
    const filterSettingsState = {};

    if (filterSettings) {
      filterSettingsState.representativity =
        filterSettings.representativity[type.toLowerCase()] || false;
      filterSettingsState.crossedSample = filterSettings.crossedSample || false;
      filterSettingsState.include65 = checkIf65AgeIncluded(userFilter);
    }

    setAdvancedFilterSettingsState({
      initial: filterSettingsState,
      saved: filterSettingsState
    });

    const userFilterSameAsDefault =
      filterSettingsState &&
      checkIsUserFilterSameAsDefault(
        type,
        filterSettingsState.representativity,
        userFilter,
        checkIf65AgeIncluded(userFilter)
      );
    setIsUserFilterSameAsDefault(userFilterSameAsDefault);
  }, []);

  useEffect(
    () => {
      if (ageGroupsSum === 100) {
        setIsCrossedAvailable(true);
      } else {
        setIsCrossedAvailable(false);
        if (
          advancedFilterSettingsState.saved &&
          advancedFilterSettingsState.saved.crossedSample
        ) {
          setAdvancedFilterSettingsState({
            ...advancedFilterSettingsState,
            saved: {
              ...advancedFilterSettingsState.saved,
              crossedSample: false
            }
          });
        }
      }
    },
    [ageGroupsSum]
  );

  const onConfirmClick = async () => {
    setSaveLoading(true);

    const savedFilterRepresentativity = { ...filterSettings.representativity };
    delete savedFilterRepresentativity.__typename;
    const filterSurveySettingsToUpdate = {
      filterSettings: {
        representativity: {
          ...savedFilterRepresentativity,
          [type.toLowerCase()]: advancedFilterSettingsState.saved
            .representativity
        },
        crossedSample: advancedFilterSettingsState.saved.crossedSample
      }
    };

    let newUserFilter = null;
    if (advancedFilterSettingsState) {
      if (dirty) {
        newUserFilter = setDefaultFiltersForFilterGroup(
          type,
          advancedFilterSettingsState.saved.representativity,
          userFilter,
          advancedFilterSettingsState.saved.include65
        );
      }
    }

    await updateSurveySettings(filterSurveySettingsToUpdate);

    if (newUserFilter) {
      onUserFilterChange(newUserFilter);
    }

    setShowAdvancedSettingsPopup(false);
  };

  if (
    !advancedFilterSettingsState ||
    !advancedFilterSettingsState.initial ||
    !advancedFilterSettingsState.saved
  ) {
    return null;
  }

  const crossedSampleDescription = isCrossedAvailable
    ? 'A crossed sample respects gender distribution within age groups.'
    : `A crossed sample respects gender distribution within age groups. \nMake sure that the sum of all age groups, in percentages and/or absolute numbers, exactly equals the total sample size (sum is currently ${
        ageGroupsSum || ageGroupsSum === 0 ? ageGroupsSum.toFixed(2) : 0
      }%).`;

  const include65Description = isUserFilterSameAsDefault
    ? 'Includes respondents aged 65+ and recalculates the distribution of other age groups.'
    : 'Includes respondents aged 65+ and recalculates the distribution of other age groups. Selecting this preset will reset all values and previously made changes will get lost.';

  return (
    <>
      <div className={styles.container}>
        <div className={styles.blockContainer}>
          <AdvancedFilterSetting
            title="Representativity"
            description={`Recommended approaches to the distribution within society, providing you reliable results in a fast and cost-effective way. Strict is an exact reflection of the distribution in society. Keep in mind that this will increase the cost and duration of the field work. ${
              !isUserFilterSameAsDefault
                ? '\n\rSelecting a preset will reset all values and previously made changes will get lost.'
                : ''
            }`}
            options={[
              {
                label: 'Recommended',
                value: DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.RECOMMENDED
              },
              {
                label: 'Strict',
                value: DYNAMIC_FILTERS_REPRESENTATIVITY_OPTIONS.STRICT
              }
            ]}
            settingValue={
              isUserFilterSameAsDefault
                ? advancedFilterSettingsState.saved.representativity
                : null
            }
            onClick={async e => {
              setIsUserFilterSameAsDefault(true);
              setDirty(true);
              setAdvancedFilterSettingsState({
                ...advancedFilterSettingsState,
                saved: {
                  ...advancedFilterSettingsState.saved,
                  representativity: e.target.value
                }
              });
              const defaultUserFilter = setDefaultFiltersForFilterGroup(
                type,
                e.target.value,
                userFilter,
                advancedFilterSettingsState.saved.include65
              );
              const newAgeGroupsSum = checkAgeGroupsSum(
                defaultUserFilter,
                maxResponses
              );
              setAgeGroupsSum(newAgeGroupsSum);
            }}
          />
          <AdvancedFilterSetting
            title="Crossed sample"
            description={crossedSampleDescription}
            options={[
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ]}
            settingValue={advancedFilterSettingsState.saved.crossedSample}
            onClick={async e => {
              if (isCrossedAvailable) {
                setAdvancedFilterSettingsState({
                  ...advancedFilterSettingsState,
                  saved: {
                    ...advancedFilterSettingsState.saved,
                    crossedSample: JSON.parse(e.target.value)
                  }
                });
              }
            }}
            disabled={!isCrossedAvailable}
          />
          {type === DYNAMIC_FILTER_GROUPS.AGE && (
            <AdvancedFilterSetting
              title="Include age 65+"
              description={include65Description}
              options={[
                {
                  label: 'Yes',
                  value: true
                },
                {
                  label: 'No',
                  value: false
                }
              ]}
              settingValue={advancedFilterSettingsState.saved.include65}
              onClick={async e => {
                setDirty(true);
                setAdvancedFilterSettingsState({
                  ...advancedFilterSettingsState,
                  saved: {
                    ...advancedFilterSettingsState.saved,
                    include65: JSON.parse(e.target.value)
                  }
                });
              }}
            />
          )}
        </div>
        <div className={styles.footer}>
          <div />
          <div className={styles.actions}>
            {saveLoading ? (
              <div className={styles.loadingAnimation}>
                <div />
                <div />
                <div />
                <div />
              </div>
            ) : (
              <>
                <img
                  className={`${
                    styles.confirm
                  } workspaces-manage-workspace-confirm-button`}
                  src={tickIcon}
                  alt="Confirm icon"
                  onClick={() => onConfirmClick()}
                  role="presentation"
                />
                <img
                  className={styles.close}
                  src={closeIcon}
                  alt="Close icon"
                  onClick={() => setShowAdvancedSettingsPopup(false)}
                  role="presentation"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
