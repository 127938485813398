import { connect } from 'react-redux';
import * as actions from '../actions';

import CampaignEmailConversion from '../components/Campaigns/CampaignEmailConversion/CampaignEmailConversion';

const mapStateToProps = state => ({
  campaign: state.userCampaigns.campaign,
  result: state.userCampaigns.result,
  userEmailSuggestion: state.userCampaigns.userEmailSuggestion
});

const mapDispatchToProps = dispatch => ({
  setCampaignComponents: (campaign, block, result, userEmailSuggestion) =>
    dispatch(
      actions.setCampaignComponents(
        campaign,
        block,
        result,
        userEmailSuggestion
      )
    ),
  resetToInitState: () => dispatch(actions.resetToInitState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignEmailConversion);
