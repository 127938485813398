import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useMutation } from '@apollo/react-hooks';
import uuid from 'uuid';

import styles from './GenerateAcquisitionCampaignLinkPage.module.css';
import AppBar from '../../../../base/components/AppBar/AppBar';
import Dropdown from '../../../AccountManagement/components/Dropdown/Dropdown';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import Button from '../../../../campaigns/components/Button/Button';
import {
  ACQUISITION_METRICS_CHANNEL_TYPES,
  ACQUISITION_METRICS_PROPERTIES
} from '../../helpers/constants';
import Loader from '../../../../base/components/Loader/Loader';
import { GENERATE_ACQUISITION_METRIC_LINK } from '../../../../graphql/AcquisitionMetrics';
import useLazyQuery from '../../../../surveys/hooks/useLazyQuery';
import { GET_SURVEY_COMMUNITIES } from '../../../../graphql/Survey';
import { COMMUNITY_MANAGEMENT_TYPES } from '../../../CommunityDashboard/helpers/constants';

const formatDate = d => {
  let date = d;
  if (!(date instanceof Date)) {
    date = new Date();
  }
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months start at zero
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const parseDate = dateString => {
  if (typeof dateString === 'string' && dateString.includes('/')) {
    const splitted = dateString.split('/');
    const day = parseInt(splitted[0], 10);
    const month = parseInt(splitted[1], 10);
    const year = parseInt(splitted[2], 10);
    const parsedDate = new Date(year, month - 1, day);
    if (parsedDate instanceof Date) {
      return parsedDate;
    }
  }
  return new Date();
};

const format = 'dd/MM/yyyy';

export default ({ setShowCreatePage, pageProps }) => {
  const [newLink, setNewLink] = useState({});
  const [generatedNewLink, setGeneratedNewLink] = useState(null);
  const [selectedCommunity, setSelectedCommunity] = useState([]);
  const [datePickerUUID, setDatePickerUUID] = useState(uuid.v1());

  const [generateAcquisitionMetricLink, { loading }] = useMutation(
    GENERATE_ACQUISITION_METRIC_LINK
  );

  const searchCommunityTagValuesApi = useLazyQuery(GET_SURVEY_COMMUNITIES, {
    fetchPolicy: 'no-cache'
  });

  const getGoBackToAcquisitionMetrics = () => (
    <span>
      <span
        className={styles.appBarPath}
        role="presentation"
        onClick={() => {
          setShowCreatePage(false);
          document.documentElement.scrollTop = 0;
        }}
      >
        Acquisition metrics
      </span>
    </span>
  );

  const getChannelDropdownValue = () => {
    if (
      newLink &&
      newLink.channel === ACQUISITION_METRICS_CHANNEL_TYPES.ONLINE
    ) {
      return [
        {
          value: ACQUISITION_METRICS_CHANNEL_TYPES.ONLINE,
          label: 'Online'
        }
      ];
    }
    if (
      newLink &&
      newLink.channel === ACQUISITION_METRICS_CHANNEL_TYPES.OFFLINE
    ) {
      return [
        {
          value: ACQUISITION_METRICS_CHANNEL_TYPES.OFFLINE,
          label: 'Offline'
        }
      ];
    }
    return [];
  };

  const mandatoryFieldsCompleted = () => {
    const mandatoryFields = Object.values(ACQUISITION_METRICS_PROPERTIES);

    const allMandatoryFieldsCompleted = mandatoryFields.reduce((acc, mF) => {
      if (acc && newLink && newLink[mF] && newLink[mF] !== '') {
        return true;
      }
      return false;
    }, true);

    return allMandatoryFieldsCompleted;
  };

  const onGenerateLinkClick = async () => {
    setDatePickerUUID(22);

    if (mandatoryFieldsCompleted()) {
      const newInputLink = { ...newLink };
      newInputLink.launchDate = formatDate(newInputLink.launchDate);

      if (
        selectedCommunity &&
        selectedCommunity !== '' &&
        selectedCommunity.value
      ) {
        newInputLink.community = selectedCommunity.value;
      }

      const newLinkResponse = await generateAcquisitionMetricLink({
        variables: newInputLink
      });

      if (
        newLinkResponse &&
        newLinkResponse.data &&
        newLinkResponse.data.generateAcquisitionMetricLink &&
        newLinkResponse.data.generateAcquisitionMetricLink.generatedNewLink
      ) {
        setGeneratedNewLink(
          newLinkResponse.data.generateAcquisitionMetricLink.generatedNewLink
        );

        setNewLink({});
        setDatePickerUUID(uuid.v1());
        setSelectedCommunity(null);
        setGeneratedNewLink(
          newLinkResponse.data.generateAcquisitionMetricLink.generatedNewLink
        );
        document.documentElement.scrollTop = 150;

        return;
      }
      window.alert('Error! Not able to create a link!');
    }
  };

  const onCopyToClipboardClick = () => {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);

    dummy.setAttribute('id', 'goalurl');
    document.getElementById('goalurl').value = generatedNewLink;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };

  const getCommunities = async (page, search) => {
    const searchResults = await searchCommunityTagValuesApi({
      variables: {
        start: 0,
        end: (page + 1) * 10,
        search,
        salt: Math.random().toString(),
        type: COMMUNITY_MANAGEMENT_TYPES.STATIC
      }
    });

    return searchResults &&
      searchResults.data &&
      searchResults.data.getSurveyCommunities &&
      searchResults.data.getSurveyCommunities.records &&
      searchResults.data.getSurveyCommunities.records.length
      ? searchResults.data.getSurveyCommunities.records.map(r => ({
          label: r.name,
          value: r.id
        }))
      : [];
  };

  const getSelectedCommunityValue = () => {
    if (!selectedCommunity || !selectedCommunity.value) {
      return {
        value: null,
        label: null
      };
    }

    return selectedCommunity;
  };

  return (
    <>
      <AppBar
        {...pageProps}
        appBarProperties={{
          routeSubtitle: getGoBackToAcquisitionMetrics()
        }}
      />
      {loading && <Loader isProcessing />}
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Generate acquisition campaign link</div>
        </div>
        <div className={styles.resultsContainer}>
          <>
            <div
              className={`${styles.dropdownContainer} ${
                styles.dropdownLicenceContainer
              }`}
            >
              <div className={styles.subtitle}>
                Channel{' '}
                <span className={styles.description}>(online or offline)</span>
                <span className={styles.requiredAsterisk}>*</span>
              </div>
              <Dropdown
                customPlaceholder="Select licence"
                onSelectCompleted={s =>
                  setNewLink({ ...newLink, channel: s.value })
                }
                options={[
                  {
                    value: null,
                    label: '-'
                  },
                  {
                    value: ACQUISITION_METRICS_CHANNEL_TYPES.ONLINE,
                    label: 'Online'
                  },
                  {
                    value: ACQUISITION_METRICS_CHANNEL_TYPES.OFFLINE,
                    label: 'Offline'
                  }
                ]}
                loading={false}
                selectedValues={getChannelDropdownValue()}
                fixedPosition
              />
            </div>

            <div className={styles.subtitle}>
              Date <span className={styles.description}>(launch date)</span>
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            <div className={styles.datePickerContainer}>
              <DayPickerInput
                onDayChange={d => setNewLink({ ...newLink, launchDate: d })}
                selectedDays={(newLink && newLink.launchDate) || null}
                dayPickerProps={{
                  canChangeMonth: true
                }}
                placeholder="dd/mm/yyyy"
                format={format}
                formatDate={formatDate}
                parseDate={parseDate}
                inputProps={{ readOnly: true }}
                key={datePickerUUID}
              />
            </div>

            <div className={styles.subtitle}>
              Partner{' '}
              <span className={styles.description}>
                (partner name or location)
              </span>
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            <input
              className={styles.input}
              placeholder="Type here"
              value={(newLink && newLink.partner) || ''}
              onChange={e =>
                setNewLink({ ...newLink, partner: e.target.value })
              }
            />

            <div className={styles.subtitle}>
              Medium{' '}
              <span className={styles.description}>
                (e.g.: e-mail, poster, coaster, flyer, ...)
              </span>
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            <input
              className={styles.input}
              placeholder="Type here"
              value={(newLink && newLink.medium) || ''}
              onChange={e => setNewLink({ ...newLink, medium: e.target.value })}
            />

            <div className={styles.subtitle}>
              Action{' '}
              <span className={styles.description}>
                (name or a specific action)
              </span>
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            <input
              className={styles.input}
              placeholder="Type here"
              value={(newLink && newLink.action) || ''}
              onChange={e => setNewLink({ ...newLink, action: e.target.value })}
            />
            <div className={styles.subtitle}>
              Community{' '}
              <span className={styles.description}>
                (community that user should be added to when creating an account
                from this link)
              </span>
            </div>
            <SearchDropdown
              placeholder="Select community"
              onSelectCompleted={(value, label) =>
                setSelectedCommunity({ value, label })
              }
              searchEnabled
              fetchOptions={getCommunities}
              loading={false}
              value={getSelectedCommunityValue()}
              filterDropdownStyle={
                selectedCommunity && selectedCommunity.value
                  ? styles.communitiesDropdownSelected
                  : styles.communitiesDropdown
              }
              fixedPosition
              multiple={false}
            />
          </>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            role="button"
            label="Generate link"
            onClick={() => onGenerateLinkClick()}
            icon=""
            disabled={!mandatoryFieldsCompleted()}
          />
        </div>
        {generatedNewLink ? (
          <div className={styles.linkSuccessfullyGeneratedContainer}>
            <div className={styles.linkGeneratedTitle}>
              Link successfully generated
            </div>
            <div className={styles.generatedLink}>{generatedNewLink}</div>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonContainer} style={{ width: 180 }}>
                <Button
                  role="button"
                  label="Generate new link"
                  onClick={() => {
                    setGeneratedNewLink(null);
                    document.documentElement.scrollTop = 0;
                  }}
                  icon=""
                  type="white"
                />
              </div>
              <div className={styles.buttonContainer} style={{ width: 150 }}>
                <Button
                  role="button"
                  label="Copy link"
                  onClick={() => onCopyToClipboardClick()}
                  icon=""
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
