import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import graphqlClient from '../../graphqlClient';

import Router from '../containers/RouterContainer';

class App extends Component {
  componentDidMount() {
    // window.addEventListener("beforeunload", this.onUnload)
  }

  componentWillUnmount() {
    // window.removeEventListener("beforeunload", this.onUnload)
  }

  // onUnload(event) {
  //   event.returnValue = 'Unsaved changes will be lost!';
  //   return event.returnValue;
  // }

  render() {
    const { webSocketUrl } = this.props;

    return (
      <ApolloProvider client={graphqlClient}>
        <div>
          <Router webSocketUrl={webSocketUrl} />
        </div>
      </ApolloProvider>
    );
  }
}

export default App;
