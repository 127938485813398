import React, { useState, useEffect } from 'react';
import useDebounce from './debounceHook';

const DebounceInput = props => {
  const {
    className,
    onChangeValue,
    initialValue,
    onKeyDown,
    placeholder,
    type = 'text',
    debounceTime = 500
  } = props;

  const [inputValue, setInputValue] = useState(initialValue || '');
  const debouncedInputValue = useDebounce(inputValue, debounceTime);
  const [dirty, setDirty] = useState(false);

  useEffect(
    () => {
      if (dirty && (debouncedInputValue || debouncedInputValue === '')) {
        onChangeValue(inputValue);
      }
    },
    [debouncedInputValue]
  );

  useEffect(
    () => {
      setInputValue(initialValue || '');
      setDirty(false);
    },
    [initialValue]
  );

  return (
    <input
      onChange={e => {
        setInputValue(e.target.value);
        setDirty(true);
      }}
      className={`${className} header-input-container-input`}
      onKeyDown={onKeyDown}
      value={inputValue}
      placeholder={placeholder}
      type={type}
      /* eslint-disable */
      autoFocus
      /* eslint-enable */
    />
  );
};

export default DebounceInput;
