import React, { useEffect, useRef, useState } from 'react';

import Feature from './Feature/Feature';

import addIcon from '../../../../../../../../../../assets/img/add.svg';

import styles from './GraphFeatures.module.css';

export default ({
  features,
  setFeatures,
  availableQuestions,
  maxAmountOfFeatures,
  xQuestionName,
  yQuestionName,
  placeholders,
  questionsTypeLabel,
  isMatrixFramework
}) => {
  const featuresContainerRef = useRef();
  const [scrollingDisabled, setScrollingDisabled] = useState(false);
  const scrollingDisabledRef = useRef(scrollingDisabled);
  const [newFeatureAdded, setNewFeatureAdded] = useState(false);

  useEffect(
    () => {
      const onFeaturesContainerScroll = e => {
        const isDropdownPopup =
          e &&
          e.target &&
          e.target.className &&
          e.target.className.substring(0, 13) &&
          e.target.className.substring(0, 13) === 'DropdownPopup'; // hacky, but I do not see a better way to solve it now

        if (!isDropdownPopup && scrollingDisabledRef.current) {
          e.preventDefault();
        }
      };

      document.addEventListener('wheel', onFeaturesContainerScroll, {
        passive: false
      });
      return () => {
        document.removeEventListener('wheel', onFeaturesContainerScroll, {
          passive: false
        });
      };
    },
    [featuresContainerRef]
  );

  const removeFeature = index => {
    const newFeatures = [...features];
    newFeatures.splice(index, 1);
    setFeatures(newFeatures);
  };

  const updateFeatureField = (index, field, value) => {
    const newFeatures = [...features];
    newFeatures[index][field] = value;
    setFeatures(newFeatures);
  };

  const setFeaturesScrollingDisabled = value => {
    scrollingDisabledRef.current = value;
    setScrollingDisabled(value);
  };

  const availableQuestionsBoolean =
    availableQuestions &&
    availableQuestions.length &&
    availableQuestions.length > 1;

  const scrollFeaturesContainerToTheBottom = () => {
    if (featuresContainerRef && featuresContainerRef.current) {
      featuresContainerRef.current.scrollTop = 10000; // hacky but works
    }
  };

  return (
    <div>
      <div className={styles.featuresContainer} ref={featuresContainerRef}>
        {features && features.length
          ? features.map((feature, index) => (
              <Feature
                key={`feature-${index.toString()}`}
                feature={feature}
                availableQuestions={availableQuestions}
                removeFeature={() => removeFeature(index)}
                updateFeatureField={(field, value) =>
                  updateFeatureField(index, field, value)
                }
                xQuestionName={xQuestionName}
                yQuestionName={yQuestionName}
                placeholders={placeholders}
                questionsTypeLabel={questionsTypeLabel}
                setFeaturesScrollingDisabled={setFeaturesScrollingDisabled}
                scrollFeaturesContainerToTheBottom={
                  scrollFeaturesContainerToTheBottom
                }
                newFeatureAdded={newFeatureAdded}
              />
            ))
          : null}
      </div>
      {!isMatrixFramework && (
        <div
          className={`${styles.addFeatureButton} ${
            !(
              availableQuestionsBoolean &&
              features &&
              features.length + 1 <= maxAmountOfFeatures
            )
              ? styles.addFeatureButtonDisabled
              : ''
          }`}
          role="presentation"
          onClick={() => {
            if (
              availableQuestionsBoolean &&
              features &&
              features.length + 1 <= maxAmountOfFeatures
            ) {
              setFeatures([
                ...features,
                {
                  [xQuestionName]: null,
                  [yQuestionName]: null,
                  description: '',
                  active: false
                }
              ]);
              setNewFeatureAdded(true);
            }
          }}
        >
          <img className={styles.addIcon} src={addIcon} alt="Add icon" />
          <span>Add data point</span>
        </div>
      )}
    </div>
  );
};
