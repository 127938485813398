import React from 'react';

import styles from './ToggleSelector.module.css';

export default ({ label, value, onChange }) => (
  <div className={styles.singleProperty}>
    <span>{label}</span>
    <span role="presentation">
      <label className={styles.switch} htmlFor="checkbox">
        <input type="checkbox" checked={value} readOnly />
        <span
          className="slider round"
          role="presentation"
          onClick={e => {
            onChange(!value);
            e.stopPropagation();
          }}
        />
      </label>
    </span>
  </div>
);
