import getAccountManagementQuestionsChargeWarning from './getAccountManagementQuestionsChargeWarning/getAccountManagementQuestionsChargeWarning';
import { SURVEY_BUILDER_POPUP_TYPES } from './constants';

export default (authState, newState) => {
  const surveyPermissionsCharge =
    newState &&
    newState.newState &&
    newState.newState.surveyPermissionsCharge &&
    newState.newState.surveyPermissionsCharge[newState.newState.survey.id]
      ? newState.newState.surveyPermissionsCharge[newState.newState.survey.id]
      : {};

  const {
    accountManagementQuestionsWarning
  } = getAccountManagementQuestionsChargeWarning(
    authState.isAdmin,
    newState.newState.survey,
    newState.newState.surveyLists,
    authState.accountManagement,
    surveyPermissionsCharge
  );

  const surveyId = newState.newState.survey.id;
  const existingSurveyPermissionsCharge =
    newState.newState.surveyPermissionsCharge;
  const newStateData = {};

  // Charge amount is always updated
  newStateData.surveyPermissionsCharge = {
    ...(existingSurveyPermissionsCharge || {}),
    [surveyId]: {
      ...(existingSurveyPermissionsCharge &&
      existingSurveyPermissionsCharge[surveyId]
        ? existingSurveyPermissionsCharge[surveyId]
        : {})
    }
  };

  if (accountManagementQuestionsWarning !== null) {
    newStateData.surveyBuilderPopup = {
      type: accountManagementQuestionsWarning
    };

    if (
      accountManagementQuestionsWarning ===
      SURVEY_BUILDER_POPUP_TYPES.SURVEY_UNPUBLISHED_QUESTIONS_ADDED
    ) {
      newStateData.surveyPermissionsCharge = {
        ...existingSurveyPermissionsCharge,
        [surveyId]: {
          unpublishedWarningShown: true
        }
      };
    }

    if (
      accountManagementQuestionsWarning ===
      SURVEY_BUILDER_POPUP_TYPES.NO_LICENCE_MORE_THAN_15
    ) {
      newStateData.surveyPermissionsCharge = {
        ...existingSurveyPermissionsCharge,
        [surveyId]: {
          noLicenceMoreThan15WarningShown: true
        }
      };
    }
  }
  return newStateData;
};
