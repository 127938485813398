export default (dynamicFilters, maxResponses, property = '$max') => {
  if (!dynamicFilters || dynamicFilters.length === 0) {
    return true;
  }

  const type = dynamicFilters[0].$dynamic.$type;
  const areAllTypesTheSame = !dynamicFilters.some(
    dF => dF.$dynamic.$type !== type
  );

  if (areAllTypesTheSame) {
    const totalSum = dynamicFilters.reduce((total, filter) => {
      const value =
        filter.$dynamic &&
        filter.$dynamic[property] &&
        filter.$dynamic[property] !== ''
          ? filter.$dynamic[property]
          : 0;
      return total + value;
    }, 0);

    if (property === '$max') {
      if (type === '%') {
        return totalSum >= 100;
      }

      if (type === '#' && maxResponses) {
        return totalSum >= maxResponses;
      }
    }

    if (property === '$min') {
      if (type === '%') {
        return totalSum > 100;
      }

      if (type === '#' && maxResponses) {
        return totalSum > maxResponses;
      }
    }

    return true;
  }

  if (!areAllTypesTheSame && maxResponses) {
    const totalSum = dynamicFilters.reduce((total, filter) => {
      if (type === '%') {
        const percentageInAbsolute =
          filter.$dynamic &&
          filter.$dynamic[property] &&
          filter.$dynamic[property] !== ''
            ? (filter.$dynamic[property] / 100) * maxResponses
            : 0;
        return total + percentageInAbsolute;
      }
      if (type === '#') {
        const value =
          filter.$dynamic &&
          filter.$dynamic[property] &&
          filter.$dynamic[property] !== ''
            ? filter.$dynamic[property]
            : 0;
        return total + value;
      }
      return total;
    }, 0);
    return totalSum >= maxResponses;
  }

  return true;
};
