import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML
} from 'draft-js';

import generateSurveyLists from '../../../helpers/generateSurveyLists/generateSurveyLists';
import {
  getQuestionsColumn,
  getFlattenedQuestionsColumn,
  getQuestionPosition
} from '../../../helpers/getQuestionsColumn/getQuestionsColumn';
import sortDistributorGroupsAndJumps from '../../../helpers/sortDistributorGroupsAndJumps/sortDistributorGroupsAndJumps';
import createDecisionFramework from '../createDecisionFramework/createDecisionFramework';
import {
  DECISION_FRAMEWORK_TYPES,
  INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS,
  QUESTION_TYPES,
  SURVEY_LANGUAGES
} from '../../../helpers/constants';
import {
  minQuotaQuestionExists,
  minQuotaAfterThisFlow
} from '../../../helpers/isActionAllowed/isActionAllowed';

import enStrings from '../../../components/TemplatesWizard/strings/en';
import nlStrings from '../../../components/TemplatesWizard/strings/nl';
import frStrings from '../../../components/TemplatesWizard/strings/fr';
import deStrings from '../../../components/TemplatesWizard/strings/de';

import questionEnStrings from '../../../components/QuestionsColumn/strings/en';
import questionNlStrings from '../../../components/QuestionsColumn/strings/nl';
import questionFrStrings from '../../../components/QuestionsColumn/strings/fr';

const ObjectID = require('bson-objectid');

export default (
  state,
  newQuestionType,
  dropzone,
  destinationSetIndex,
  destionationQuestionindex,
  questionId,
  addQuestionsListClick
) => {
  const questionsColumn = getQuestionsColumn(
    false,
    state.survey.content,
    state.survey.questions
  );

  const flattedQuestionColumn = getFlattenedQuestionsColumn(questionsColumn);

  const destinationSet = flattedQuestionColumn[destinationSetIndex];

  // Dropzone is visible if group is nested into parent group in edge position (top, bottom)
  if (dropzone) {
    // Drop inside of parent group
    const parentGroup = state.survey.content.groups.find(
      g => g.flows.indexOf(destinationSetIndex) > -1
    );
    if (parentGroup) {
      const groupIndex = parentGroup.flows.findIndex(
        f => f === destinationSetIndex
      );
      const destinationDropzoneIndex =
        destionationQuestionindex === 0 ? groupIndex : groupIndex + 1;

      parentGroup.flows.splice(destinationDropzoneIndex, 0, questionId);
    }

    // If it is not child group position element above group
    if (!parentGroup) {
      if (state.survey.content.flows.indexOf(destinationSetIndex) > -1) {
        const destinationIndex = state.survey.content.flows.indexOf(
          destinationSetIndex
        );

        if (
          newQuestionType === QUESTION_TYPES.DISTRIBUTOR &&
          destinationIndex === 0
        ) {
          return null;
        }

        state.survey.content.flows.splice(destinationIndex, 0, questionId);
      } else {
        const group = state.survey.content.groups.find(
          g => g.flows.indexOf(destinationSetIndex) > -1
        );

        if (group) {
          const destinationIndex = group.flows.indexOf(destinationSetIndex);
          group.flows.splice(destinationIndex, 0, questionId);
        }
      }
    }
  }

  // Place moved element to the new position
  if (destinationSet && !dropzone) {
    const questionDestinationPosition = getQuestionPosition(
      null,
      null,
      destinationSet,
      destionationQuestionindex
    );

    if (
      state.survey.content.flows.indexOf(
        questionDestinationPosition.questionId
      ) > -1
    ) {
      const destinationQuestionIndex = state.survey.content.flows.indexOf(
        questionDestinationPosition.questionId
      );

      const destinationIndex =
        questionDestinationPosition.position === 'BEFORE'
          ? destinationQuestionIndex
          : destinationQuestionIndex + 1;

      if (
        newQuestionType === QUESTION_TYPES.DISTRIBUTOR &&
        destinationQuestionIndex === 0 &&
        !destinationIndex
      ) {
        return null;
      }

      if (
        newQuestionType === QUESTION_TYPES.DISTRIBUTOR &&
        minQuotaQuestionExists(state.surveyLists)
      ) {
        const contentFlow =
          (state.survey.content.flows &&
            state.survey.content.flows[destinationQuestionIndex]) ||
          null;
        const sourceFlow =
          contentFlow &&
          state.survey.questions &&
          state.survey.questions[contentFlow]
            ? state.survey.questions[contentFlow]
            : null;
        if (
          sourceFlow &&
          minQuotaAfterThisFlow(
            sourceFlow,
            state.surveyLists,
            state.survey.content.groups
          )
        ) {
          return null;
        }
      }

      state.survey.content.flows.splice(destinationIndex, 0, questionId);
    } else {
      const group = state.survey.content.groups.find(
        g => g.flows.indexOf(questionDestinationPosition.questionId) > -1
      );

      if (group) {
        // Distributor type should not be allowed inside groups
        if (newQuestionType === QUESTION_TYPES.DISTRIBUTOR) {
          return null;
        }

        const destinationQuestionIndex = group.flows.indexOf(
          questionDestinationPosition.questionId
        );

        const destinationIndex =
          questionDestinationPosition.position === 'BEFORE'
            ? destinationQuestionIndex
            : destinationQuestionIndex + 1;

        group.flows.splice(destinationIndex, 0, questionId);
      }
    }
  }

  // Question dropped on add new question placeholder
  if (
    destinationSetIndex === undefined &&
    destionationQuestionindex === undefined
  ) {
    if (addQuestionsListClick) {
      let addedInGroup = false;

      const activeFlowIndex =
        state.activeFlow &&
        state.survey.content.flows.indexOf(state.activeFlow.id);
      let newFlowIndex =
        (activeFlowIndex || activeFlowIndex === 0) &&
        activeFlowIndex > -1 &&
        activeFlowIndex + 1;

      // Check if active flow is in groups
      if (!newFlowIndex && state.activeFlow && state.survey.content.groups) {
        const innerActiveFlowGroupIndex = state.survey.content.groups.findIndex(
          g => g.flows && g.flows.indexOf(state.activeFlow.id) > -1
        );

        if (innerActiveFlowGroupIndex > -1) {
          const innerActiveFlowIndex =
            state.survey.content.groups[innerActiveFlowGroupIndex] &&
            state.survey.content.groups[innerActiveFlowGroupIndex].flows &&
            state.survey.content.groups[
              innerActiveFlowGroupIndex
            ].flows.indexOf(state.activeFlow.id);

          // Distributor type should not be allowed inside groups
          if (newQuestionType === QUESTION_TYPES.DISTRIBUTOR) {
            return null;
          }

          if (innerActiveFlowIndex || innerActiveFlowIndex === 0) {
            addedInGroup = true;
            state.survey.content.groups[innerActiveFlowGroupIndex].flows.splice(
              innerActiveFlowIndex + 1,
              0,
              questionId
            );
          }
        }
      }

      if (!newFlowIndex && newFlowIndex !== 0) {
        newFlowIndex = state.survey.content.flows.length;
      }

      if (!addedInGroup) {
        state.survey.content.flows.splice(newFlowIndex, 0, questionId);
      }

      if (
        state.activeFlow &&
        newQuestionType === QUESTION_TYPES.DISTRIBUTOR &&
        minQuotaQuestionExists(state.surveyLists)
      ) {
        if (
          minQuotaAfterThisFlow(
            state.activeFlow,
            state.surveyLists,
            state.survey.content.groups
          )
        ) {
          return null;
        }
      }
    }
    if (!addQuestionsListClick) {
      state.survey.content.flows.push(questionId);
    }
  }

  const flows = [...state.survey.content.flows];

  const newQuestion = {
    id: questionId,
    type: newQuestionType
  };

  // Predefined parameters for specific question types
  if (newQuestionType === QUESTION_TYPES.OPEN_QUESTION) {
    newQuestion.minCharacters = 1;
    newQuestion.maxCharacters = 1000;
  }
  if (newQuestionType === QUESTION_TYPES.RANKING) {
    newQuestion.type = QUESTION_TYPES.MULTIPLE_CHOICE;
    newQuestion.rankAnswers = true;
    newQuestion.selectAtLeast = 1;
    newQuestion.selectAtMost = 1;
  }
  if (newQuestionType === QUESTION_TYPES.RATING_SCALE) {
    newQuestion.minValue = 0;
    newQuestion.maxValue = 10;
    newQuestion.incidenceRate = {
      profilingQuestionCorrectionApplied: false,
      dropoutValueEstimation: INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS.HIGH
    };
  }
  if (newQuestionType === QUESTION_TYPES.MULTIPLE_CHOICE) {
    newQuestion.selectAtLeast = 1;
    newQuestion.selectAtMost = 1;
    newQuestion.incidenceRate = {
      profilingQuestionCorrectionApplied: false,
      dropoutValueEstimation: INCIDENCE_RATE_DROPOUT_VALUE_ESTIMATIONS.HIGH
    };
  }
  if (newQuestionType === QUESTION_TYPES.ADD_TO_COMMUNITY) {
    let strings;
    switch (state.survey.language) {
      case SURVEY_LANGUAGES.ENGLISH:
        strings = enStrings;
        break;
      case SURVEY_LANGUAGES.DUTCH:
        strings = nlStrings;
        break;
      case SURVEY_LANGUAGES.FRENCH:
        strings = frStrings;
        break;
      case SURVEY_LANGUAGES.GERMAN:
        strings = deStrings;
        break;
      default:
        strings = enStrings;
    }

    newQuestion.question = JSON.stringify(
      convertToRaw(
        EditorState.createWithContent(
          ContentState.createFromText(strings.COMMUNITY.QUESTION_TEXT)
        ).getCurrentContent()
      )
    );

    newQuestion.type = QUESTION_TYPES.MULTIPLE_CHOICE;
    newQuestion.isCommunityAssignQuestion = true;
    newQuestion.selectAtLeast = 1;
    newQuestion.selectAtMost = 1;
    newQuestion.choices = [
      {
        id: ObjectID().toString(),
        answer: strings.COMMUNITY.ANSWER_YES,
        assignUserToCommunity: true
      },
      {
        id: ObjectID().toString(),
        answer: strings.COMMUNITY.ANSWER_NO
      }
    ];
  }
  if (newQuestion.type === QUESTION_TYPES.MATRIX) {
    let strings;
    switch (state.survey.language) {
      case SURVEY_LANGUAGES.DUTCH:
        strings = questionNlStrings;
        break;
      case SURVEY_LANGUAGES.FRENCH:
        strings = questionFrStrings;
        break;
      default:
        strings = questionEnStrings;
    }

    newQuestion.randomOrder = true;
    newQuestion.matrix = {};
    newQuestion.matrix.decisionFrameworkType =
      DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH;
    newQuestion.matrix.xLabel =
      strings[DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH].X_MATRIX_LABEL;
    newQuestion.matrix.yLabel =
      strings[DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH].Y_MATRIX_LABEL;

    const xQuestionFromHTML = convertFromHTML(
      strings[DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH].X_MATRIX_QUESTION
    );
    newQuestion.matrix.xQuestion = JSON.stringify(
      convertToRaw(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            xQuestionFromHTML.contentBlocks,
            xQuestionFromHTML.entityMap
          )
        ).getCurrentContent()
      )
    );

    const yQuestionFromHTML = convertFromHTML(
      strings[DECISION_FRAMEWORK_TYPES.VALUE_SELECTION_GRAPH].Y_MATRIX_QUESTION
    );
    newQuestion.matrix.yQuestion = JSON.stringify(
      convertToRaw(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            yQuestionFromHTML.contentBlocks,
            yQuestionFromHTML.entityMap
          )
        ).getCurrentContent()
      )
    );

    newQuestion.matrix.xQuestionId = ObjectID().toString();
    newQuestion.matrix.yQuestionId = ObjectID().toString();

    newQuestion.selectAtLeast = 1;
    newQuestion.selectAtMost = 4;
  }

  if (newQuestion.type === QUESTION_TYPES.DISTRIBUTOR) {
    newQuestion.distributeEvenly = true;
  }

  const questions = {
    ...state.survey.questions,
    [questionId]: newQuestion
  };

  let newState = {
    ...state,
    survey: {
      ...state.survey,
      content: {
        ...state.survey.content,
        flows
      },
      questions
    }
  };

  const requests = [];
  if (
    newQuestion.type === QUESTION_TYPES.MATRIX &&
    newQuestion.matrix &&
    newQuestion.matrix.decisionFrameworkType &&
    newQuestion.matrix.decisionFrameworkType !== DECISION_FRAMEWORK_TYPES.SINGLE
  ) {
    const {
      surveyWithCreatedFramework,
      createRequest
    } = createDecisionFramework(newState.survey, newQuestion);
    if (surveyWithCreatedFramework && createRequest) {
      newState = {
        ...newState,
        survey: surveyWithCreatedFramework
      };
      if (createRequest) {
        requests.push(createRequest);
      }
    }
  }

  // Set new question as active question
  newState.activeFlow = newQuestion;

  // Sort distributor groups according to branch jumps
  sortDistributorGroupsAndJumps(newState);

  newState.surveyLists = generateSurveyLists(
    newState.survey.content,
    newState.survey.questions
  );

  return { newState, newQuestion, requests };
};
