const returnValueRelativeToPercentage = (value, type, maxResponses) => {
  if (type === '#') {
    if (maxResponses) {
      return (value / maxResponses) * 100;
    }
    return 0;
  }
  return value;
};

export default (userFilter, maxResponses) => {
  const ageFilters =
    userFilter && userFilter.$and ? userFilter.$and.filter(v => v.age) : [];

  const totalSum = ageFilters.reduce((sum, currentDynamicFilter) => {
    let newSum = sum;
    if (
      currentDynamicFilter &&
      currentDynamicFilter.$dynamic &&
      currentDynamicFilter.$dynamic.$type
    ) {
      if (currentDynamicFilter.$dynamic.$min) {
        newSum += returnValueRelativeToPercentage(
          currentDynamicFilter.$dynamic.$min,
          currentDynamicFilter.$dynamic.$type,
          maxResponses
        );
      } else if (currentDynamicFilter.$dynamic.$max) {
        newSum += returnValueRelativeToPercentage(
          currentDynamicFilter.$dynamic.$max,
          currentDynamicFilter.$dynamic.$type,
          maxResponses
        );
      }
    }

    return newSum;
  }, 0);
  return totalSum;
};
