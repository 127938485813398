import React, { useState, useEffect } from 'react';

import styles from './NotificationsList.module.css';

const NotificationsList = props => {
  const {
    isAppendFetching,
    pushNotificationDetails,
    setPushNotificationDetails,
    isListFetching,
    pushNotifications,
    fetchOptionsExecute,
    activeListTab,
    setActiveListTab,
    LIST_TABS,
    SECTION_KEYS,
    section
  } = props;

  const [page, setPage] = useState(0);
  const [previousCount, setPreviousCount] = useState(0);

  const formatDate = date => {
    if (!date) return null;

    const monthShortNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const convertDate = new Date(date);

    const day = convertDate.getDate();
    const monthIndex = convertDate.getMonth() + 1;
    const year = convertDate.getFullYear();

    // Today
    const today = new Date();

    // Yesterday
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const timeString = `${convertDate.getHours()}:${
      convertDate.getMinutes() < 10 ? '0' : ''
    }${convertDate.getMinutes()}`;

    if (
      day === today.getDate() &&
      monthIndex === today.getMonth() + 1 &&
      year === today.getFullYear()
    ) {
      return `${timeString}, Today`;
    }

    if (
      day === yesterday.getDate() &&
      monthIndex === yesterday.getMonth() + 1 &&
      year === yesterday.getFullYear()
    ) {
      return `${timeString}, Yesterday`;
    }

    if (year === today.getFullYear())
      return `${timeString}, ${day} ${monthShortNames[monthIndex - 1]}`;
    return `${timeString}, ${day} ${monthShortNames[monthIndex - 1]}, ${year}`;
  };

  useEffect(
    () => {
      fetchOptionsExecute(false, true);
      if (previousCount > 0) {
        setPreviousCount(0);
      }
    },
    [activeListTab]
  );

  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    if (
      elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight &&
      !isListFetching
    ) {
      const currentCount =
        pushNotifications && pushNotifications.length
          ? pushNotifications.length
          : 0;

      if (previousCount !== currentCount) {
        setPreviousCount(currentCount);
        setPage(page + 1);
        fetchOptionsExecute(page + 1);
      }
    }
  };

  const changeListTab = newListTab => {
    setActiveListTab(newListTab);
  };

  if (isListFetching) {
    return (
      <div
        className={`${styles.notificationsListContainter} ${
          styles.whiteContainer
        }`}
      >
        <div
          className={`${styles.pageContentListPlaceholder} loader-container`}
        />
      </div>
    );
  }

  return (
    <div
      className={`${styles.notificationsListContainter} ${
        styles.whiteContainer
      }`}
    >
      <div className={styles.listTypeContainer}>
        {section === SECTION_KEYS.PUSH_NOTIFICATION ? (
          <div
            className={
              activeListTab === LIST_TABS.SCHEDULED ? styles.active : null
            }
            role="presentation"
            onClick={() => changeListTab(LIST_TABS.SCHEDULED)}
          >
            Scheduled
          </div>
        ) : null}
        <div
          className={activeListTab === LIST_TABS.HISTORY ? styles.active : null}
          role="presentation"
          onClick={() => changeListTab(LIST_TABS.HISTORY)}
        >
          History
        </div>
      </div>
      <div
        className={styles.notificationsContainer}
        onScroll={listOptionsDivScroll}
      >
        {pushNotifications.map((pushNotification, index) => (
          <div
            className={
              pushNotificationDetails &&
              pushNotificationDetails.id === pushNotification.id
                ? `${styles.notificationItem} ${
                    styles.notificationItemSelected
                  }`
                : styles.notificationItem
            }
            key={`push-notification-list-item-${index.toString()}`}
            role="presentation"
            onClick={() => setPushNotificationDetails(pushNotification)}
          >
            <div className={styles.label}>
              <span className={styles.name}>
                {pushNotification && pushNotification.title}
              </span>
              {pushNotification.createdAt ? (
                <div className={styles.date}>
                  {formatDate(
                    pushNotification.scheduled
                      ? pushNotification.scheduledDate
                      : pushNotification.createdAt
                  )}
                </div>
              ) : null}
            </div>
            <div className={styles.filterDetails}>
              <div className={styles.filterType}>
                {pushNotification.filter_string === '[]'
                  ? 'All users'
                  : 'Custom filter'}
              </div>
              {pushNotification.count ? (
                <div className={styles.count}>
                  {pushNotification.count} users
                </div>
              ) : null}
            </div>
          </div>
        ))}
        {isAppendFetching ? (
          <div className={styles.loadingPlaceholder}>Loading</div>
        ) : null}
        {!isAppendFetching && pushNotifications && !pushNotifications.length ? (
          <div className={styles.loadingPlaceholder}>No push notifications</div>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationsList;
