import React from 'react';

import Icon from '../../../../../../../../../../../Icon/Icon';

import styles from './AnswerJumpMessage.module.css';

export default ({ message }) => (
    <div className={styles.container}>
      <div className={styles.dropdownRow}>
        <div className={styles.dropdownRowParent}>
          <Icon type="ljArrow" className={styles.ljArrow} />
          {message}
        </div>
      </div>
    </div>
  );
