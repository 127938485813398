import React from 'react';
import styles from './TargetFilters.module.css';

const TargetFilters = props => {
  const { filterString, hideSurveyFilter } = props;

  if (filterString) {
    let targetFilters = JSON.parse(filterString);

    if (targetFilters && targetFilters.length && hideSurveyFilter) {
      targetFilters = targetFilters.filter(tF => tF && tF.name !== 'campaigns');
    }

    if (!targetFilters || !targetFilters.length) return null;

    return (
      <div className={styles.targetFilterContainer}>
        {targetFilters.map((targetFilter, index) => (
          <div
            className={styles.targetFilterItem}
            key={`target-filter-item-${index.toString()}`}
          >
            <div className={styles.targetFilterLabel}>{targetFilter.label}</div>
            {targetFilter && targetFilter.values && targetFilter.values ? (
              <div className={styles.targetFilterSingleLabel}>
                {targetFilter.values.label}
              </div>
            ) : null}
            {targetFilter && targetFilter.values && targetFilter.values.length
              ? targetFilter.values.map(
                  (targetValuesFilter, targetFilterIndex) => (
                    <div
                      className={styles.targetFilterMultiLabel}
                      key={`target-filter-multi-label-${targetFilterIndex.toString()}`}
                    >
                      {targetValuesFilter.label}
                    </div>
                  )
                )
              : null}
            {targetFilter &&
            targetFilter.values &&
            targetFilter.values &&
            (targetFilter.values.gte || targetFilter.values.lte) ? (
              <div className={styles.targetFilterRangeLabel}>
                Less than: {targetFilter.values.gte}, More than:{' '}
                {targetFilter.values.lte}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export default TargetFilters;
