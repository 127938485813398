import React from 'react';

import useLazyQuery from '../../../../../../../../surveys/hooks/useLazyQuery';

import {
  SEARCH_PROFILING_CATEGORIES,
  SEARCH_PROFILING_TAG_NAMES,
  SEARCH_PROFILING_TAG_VALUES
} from '../../../../../../../../graphql/Question';

import { COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES } from '../../../../../../helpers/constants';

import SearchDropdown from '../SearchDropdown/SearchDropdown';

import deleteIcon from '../../../../../../../../assets/img/delete.svg';

import styles from './ProfilingTagsFilter.module.css';
import MultipleSearchDropdown from '../MultipleSearchDropdown/MultipleSearchDropdown';

export default ({
  singleFilterIndex,
  appliedFilters,
  setAppliedFilters,
  onFilterSelectCompleted,
  onTypeChange,
  getTypeOptions,
  showQuota,
  onSelectAllClick
}) => {
  const singleFilter = appliedFilters[singleFilterIndex];

  const searchProfilingCategoriesApi = useLazyQuery(
    SEARCH_PROFILING_CATEGORIES,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const searchProfilingTagNamesApi = useLazyQuery(SEARCH_PROFILING_TAG_NAMES, {
    fetchPolicy: 'no-cache'
  });
  const searchProfilingTagValuesApi = useLazyQuery(
    SEARCH_PROFILING_TAG_VALUES,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const searchCategories = async (page, search) => {
    const preparedAppliedFilters = { ...singleFilter.tags };
    delete preparedAppliedFilters.category;

    const searchResults = await searchProfilingCategoriesApi({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingCategories &&
      searchResults.data.searchProfilingCategories.options
      ? searchResults.data.searchProfilingCategories.options
      : [];
  };

  const searchProfilingTagNames = async (page, search) => {
    const preparedAppliedFilters = { ...singleFilter.tags };
    delete preparedAppliedFilters.tagName;

    const searchResults = await searchProfilingTagNamesApi({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagNames &&
      searchResults.data.searchProfilingTagNames.options
      ? searchResults.data.searchProfilingTagNames.options
      : [];
  };

  const searchProfilingTagValues = async (page, search) => {
    const preparedAppliedFilters = { ...singleFilter.tags };
    delete preparedAppliedFilters.tagValue;

    // Load all tag values if categories and tag names are populated
    let searchPage = page;
    if (
      preparedAppliedFilters &&
      preparedAppliedFilters.category &&
      preparedAppliedFilters.tagName
    ) {
      searchPage = 10;
    }

    const searchResults = await searchProfilingTagValuesApi({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (searchPage + 1) * 10,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagValues &&
      searchResults.data.searchProfilingTagValues.options
      ? searchResults.data.searchProfilingTagValues.options
      : [];
  };

  return (
    <div className={styles.container}>
      <SearchDropdown
        fetchOptions={getTypeOptions}
        placeholder="Select a filter type"
        onSelectCompleted={value => onTypeChange(value)}
        value={{
          value: singleFilter.type,
          label: COMMUNITY_DASHBOARD_FILTER_TYPE_NAMES[singleFilter.type]
        }}
        search={false}
        hideNoValueOption
      />
      <SearchDropdown
        fetchOptions={searchCategories}
        placeholder="Tag category"
        onSelectCompleted={value =>
          onFilterSelectCompleted('category', value, 'tags')
        }
        value={{
          value: singleFilter.tags.category,
          label: singleFilter.tags.category
        }}
      />
      <SearchDropdown
        fetchOptions={searchProfilingTagNames}
        placeholder="Tag name"
        onSelectCompleted={value =>
          onFilterSelectCompleted('tagName', value, 'tags')
        }
        value={{
          value: singleFilter.tags.tagName,
          label: singleFilter.tags.tagName
        }}
      />
      {singleFilter &&
      singleFilter.tags &&
      singleFilter.tags.category &&
      singleFilter.tags.tagName ? (
        <MultipleSearchDropdown
          fetchOptions={searchProfilingTagValues}
          placeholder="Tag value"
          onSelectCompleted={value =>
            onFilterSelectCompleted('tagValue', value, 'tags', true)
          }
          value={{
            value: singleFilter.tags.tagValue,
            label: singleFilter.tags.tagValue
          }}
          onSelectAllClick={(values, allValuesSelected) =>
            onSelectAllClick(
              'tagValue',
              values,
              'tags',
              allValuesSelected,
              searchProfilingTagValues
            )
          }
          fixedWidth={200}
          showQuotaFields={showQuota}
          quotaValues={
            (singleFilter && singleFilter.tags && singleFilter.tags.quota) || []
          }
          onQuotaChange={quotaValues => {
            onFilterSelectCompleted('quota', quotaValues, 'tags');
          }}
        />
      ) : (
        <SearchDropdown
          fetchOptions={searchProfilingTagValues}
          placeholder="Tag value"
          onSelectCompleted={value =>
            onFilterSelectCompleted('tagValue', value, 'tags', true)
          }
          value={{
            value: singleFilter.tags.tagValue,
            label: singleFilter.tags.tagValue
          }}
        />
      )}
      <img
        src={deleteIcon}
        className={styles.deleteIcon}
        onClick={() => {
          const newAppliedFilters = appliedFilters.filter(
            (aF, i) => i !== singleFilterIndex
          );
          setAppliedFilters(newAppliedFilters);
        }}
        alt="Delete filter"
        role="presentation"
      />
    </div>
  );
};
