import React from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import styles from './RangeSelector.module.css';

export default ({ minValue, maxValue, value, setValue }) => (
  <div className={styles.container}>
    <InputRange
      draggableTrack
      step={1}
      minValue={minValue}
      maxValue={maxValue}
      value={value}
      onChange={v => {
        const draggedValue = v;
        if (value.min < minValue) {
          draggedValue.min = minValue;
        }
        if (value.max > maxValue) {
          draggedValue.max = maxValue;
        }

        setValue(draggedValue);
      }}
      onChangeComplete={v => {
        const finalValue = {
          min: v.min < minValue ? minValue : v.min,
          max: v.max > maxValue ? maxValue : v.max
        };

        setValue(finalValue);
      }}
    />
  </div>
);
