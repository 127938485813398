import React, { useState } from 'react';

import AppBar from '../../base/components/AppBar/AppBar';

import AcquisitionMetricsPage from './components/AcquisitionMetricsPage/AcquisitionMetricsPage';
import GenerateAcquisitionCampaignLinkPage from './components/GenerateAcquisitionCampaignLinkPage/GenerateAcquisitionCampaignLinkPage';

export default props => {
  const [showCreatePage, setShowCreatePage] = useState(false);

  return (
    <div>
      <AppBar />
      {showCreatePage ? (
        <GenerateAcquisitionCampaignLinkPage
          pageProps={props}
          setShowCreatePage={setShowCreatePage}
        />
      ) : (
        <AcquisitionMetricsPage
          pageProps={props}
          setShowCreatePage={setShowCreatePage}
        />
      )}
    </div>
  );
};
