export const templateTypes = {
  OPPORTUNITY_GRAPH: 'OPPORTUNITY_GRAPH',
  CUSTOM_GRAPH: 'CUSTOM_GRAPH',
  VALUE_SELECTION_GRAPH: 'VALUE_SELECTION_GRAPH',
  IDEA_SELECTION_GRAPH: 'IDEA_SELECTION_GRAPH',
  CONCEPT_TEST_GRAPH: 'CONCEPT_TEST_GRAPH'
};

export const templateConfigurations = {
  [templateTypes.OPPORTUNITY_GRAPH]: {
    xQuestionName: 'importanceQuestion',
    yQuestionName: 'satisfactionQuestion',
    placeholders: {
      description: `Describe your problem as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (important)',
      yAxisDropdown: 'Select question (challenging)'
    },
    title: 'Problem validation',
    description:
      'The x-axis shows how important a problem is. The y-axis shows how challenging current solutions are.'
  },
  [templateTypes.CUSTOM_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your data point as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select value (x-axis)',
      yAxisDropdown: 'Select value (y-axis)'
    },
    title: 'Create custom graph',
    description: 'Name your x-axis and y-axis.'
  },
  [templateTypes.VALUE_SELECTION_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your value as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (value)',
      yAxisDropdown: 'Select question (basic requirement)'
    },
    title: 'Concept exploration',
    description:
      'The x-axis shows how valuable a value is. The y-axis shows which values are essential to include in your MVP or basic package.'
  },
  [templateTypes.IDEA_SELECTION_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your idea as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (value)',
      yAxisDropdown: 'Select question (uniqueness)'
    },
    title: 'Idea selection',
    description:
      'The x-axis shows how valuable an idea is. The y-axis shows how unique an idea is.'
  },
  [templateTypes.CONCEPT_TEST_GRAPH]: {
    xQuestionName: 'xQuestion',
    yQuestionName: 'yQuestion',
    placeholders: {
      description: `Describe your concept as you would like it displayed in the graph's legend`,
      xAxisDropdown: 'Select question (value)',
      yAxisDropdown: 'Select question (uniqueness)'
    },
    title: 'Concept validation',
    description:
      'The x-axis shows how valuable a concept is. The y-axis shows how unique a concept is.'
  }
};

export const defaultTemplateGraphName = 'Executive summary';
