export default async (
  surveyId,
  editGraphsPopup,
  features,
  mergeQuestionName,
  axisLabels,
  graphqlRequests,
  setError,
  onClose,
  templateTypes,
  currentTemplatesTab,
  templateConfiguration,
  surveyRefetch,
  setEditGraphsPopup,
  viewToken
) => {
  setError('');

  const inputFeatures = features.map(feature => ({
    ...(feature.id ? { id: feature.id } : {}),
    [templateConfiguration.xQuestionName]:
      feature[templateConfiguration.xQuestionName].id,
    [templateConfiguration.yQuestionName]:
      feature[templateConfiguration.yQuestionName].id,
    description: feature.description,
    active: feature.active,
    colorCode: feature.colorCode,
    ...(feature.choiceId ? { choiceId: feature.choiceId } : {})
  }));

  if (currentTemplatesTab === templateTypes.OPPORTUNITY_GRAPH) {
    if (editGraphsPopup && editGraphsPopup.id) {
      const result = await graphqlRequests.updateOpportunityGraph({
        variables: {
          id: editGraphsPopup.id,
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      if (
        result &&
        result.data &&
        result.data.updateOpportunityGraph &&
        result.data.updateOpportunityGraph.errors &&
        result.data.updateOpportunityGraph.errors.length
      ) {
        setError('Failed to update Opportunity graph');
      } else {
        await surveyRefetch();
        setEditGraphsPopup(false);
        onClose();
      }
    } else {
      await graphqlRequests.createOpportunityGraph({
        variables: {
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      /*
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      */
      setEditGraphsPopup(false);
      onClose();
    }
  }

  if (currentTemplatesTab === templateTypes.CUSTOM_GRAPH) {
    if (editGraphsPopup && editGraphsPopup.id) {
      const result = await graphqlRequests.updateCustomGraph({
        variables: {
          id: editGraphsPopup.id,
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          xLabel: axisLabels.x,
          yLabel: axisLabels.y,
          ...(viewToken ? { viewToken } : {})
        }
      });
      if (
        result &&
        result.data &&
        result.data.updateCustomGraph &&
        result.data.updateCustomGraph.errors &&
        result.data.updateCustomGraph.errors.length
      ) {
        setError('Failed to update Custom graph');
      } else {
        await surveyRefetch();
        setEditGraphsPopup(false);
        onClose();
      }
    } else {
      await graphqlRequests.createCustomGraph({
        variables: {
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          xLabel: axisLabels.x,
          yLabel: axisLabels.y,
          ...(viewToken ? { viewToken } : {})
        }
      });
      /*
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      */
      setEditGraphsPopup(false);
      onClose();
    }
  }

  if (currentTemplatesTab === templateTypes.VALUE_SELECTION_GRAPH) {
    if (editGraphsPopup && editGraphsPopup.id) {
      const result = await graphqlRequests.updateValueSelectionGraph({
        variables: {
          id: editGraphsPopup.id,
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      if (
        result &&
        result.data &&
        result.data.updateValueSelectionGraph &&
        result.data.updateValueSelectionGraph.errors &&
        result.data.updateValueSelectionGraph.errors.length
      ) {
        setError('Failed to update Concept exploration graph');
      } else {
        await surveyRefetch();
        setEditGraphsPopup(false);
        onClose();
      }
    } else {
      await graphqlRequests.createValueSelectionGraph({
        variables: {
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      /*
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      */
      setEditGraphsPopup(false);
      onClose();
    }
  }

  if (currentTemplatesTab === templateTypes.IDEA_SELECTION_GRAPH) {
    if (editGraphsPopup && editGraphsPopup.id) {
      const result = await graphqlRequests.updateIdeaSelectionGraph({
        variables: {
          id: editGraphsPopup.id,
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      if (
        result &&
        result.data &&
        result.data.updateIdeaSelectionGraph &&
        result.data.updateIdeaSelectionGraph.errors &&
        result.data.updateIdeaSelectionGraph.errors.length
      ) {
        setError('Failed to update Idea selection graph');
      } else {
        await surveyRefetch();
        setEditGraphsPopup(false);
        onClose();
      }
    } else {
      await graphqlRequests.createIdeaSelectionGraph({
        variables: {
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      /*
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      */
      setEditGraphsPopup(false);
      onClose();
    }
  }

  if (currentTemplatesTab === templateTypes.CONCEPT_TEST_GRAPH) {
    if (editGraphsPopup && editGraphsPopup.id) {
      const result = await graphqlRequests.updateConceptTestGraph({
        variables: {
          id: editGraphsPopup.id,
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      if (
        result &&
        result.data &&
        result.data.updateConceptTestGraph &&
        result.data.updateConceptTestGraph.errors &&
        result.data.updateConceptTestGraph.errors.length
      ) {
        setError('Failed to update Concept validation graph');
      } else {
        await surveyRefetch();
        setEditGraphsPopup(false);
        onClose();
      }
    } else {
      await graphqlRequests.createConceptTestGraph({
        variables: {
          survey: surveyId,
          name: mergeQuestionName,
          features: inputFeatures,
          ...(viewToken ? { viewToken } : {})
        }
      });
      /*
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      */
      setEditGraphsPopup(false);
      onClose();
    }
  }
};
