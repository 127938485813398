import React, { useState, useEffect, useRef } from 'react';

import tickIconWhite from '../../../../../../../assets/img/tick_white.svg';
import blackArrowDown from '../../../../../../../assets/img/black-arrow-down.svg';
import dropDownBoldGreyIcon from '../../../../../../../assets/img/dropdown-arrow-bold-grey.svg';

import { insightQuestionTypes } from '../../../../Insights/helpers/constants';

import styles from '../../../Question/Toolbars/Toolbar.module.css';
import AddInsight from '../../../Question/AddInsight/AddInsight';

const MergedGraphToolbar = props => {
  const {
    onStatisticalRelevanceClick,
    fetchedStatisticalRelevance,
    barsVertical,
    setBarsVertical,
    isPercentage,
    setIsPercentage,
    onRemoveMergedChartClick,
    isMatrixQuestion,
    filteringOnSelection,
    setFilteringOnSelection,
    showCompareToTotalSample,
    setShowAddInsightsPopup,
    questionId,
    viewToken
  } = props;

  const [toolbarActive, setToolbarActive] = useState(false);
  const [displayOrientationDropdown, setDisplayOrientationDropdown] = useState(
    false
  );
  const [displayNumbersDropdown, setDisplayNumbersDropdown] = useState(false);

  const orientationLabels = {
    VERTICAL: 'Bar graph (vertical)',
    HORIZONTAL: 'Bar graph (horizontal)'
  };

  const numberLabels = {
    PERCENTAGE: 'Relative numbers (%)',
    ABSOLUTE: 'Absolute numbers (#)'
  };

  const orientationRef = useRef(null);
  const numbersRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        orientationRef &&
        orientationRef.current &&
        orientationRef.current.children &&
        orientationRef.current.children.length &&
        Array.from(orientationRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setDisplayOrientationDropdown(false);
    }
    if (
      !(
        numbersRef &&
        numbersRef.current &&
        numbersRef.current.children &&
        numbersRef.current.children.length &&
        Array.from(numbersRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setDisplayNumbersDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.container} ${
          toolbarActive ? styles.containerActive : ''
        }`}
      >
        <div className={styles.toolbarItem}>
          <div
            ref={orientationRef}
            role="presentation"
            className={styles.dropdownContainer}
            onClick={() => setDisplayOrientationDropdown(prev => !prev)}
            style={{ minWidth: '160px' }}
          >
            <img
              className={`${styles.dropdownArrow} ${
                displayOrientationDropdown ? styles.dropdownArrowActive : ''
              }`}
              src={blackArrowDown}
              alt="Drop down arrow"
            />
            {barsVertical
              ? orientationLabels.VERTICAL
              : orientationLabels.HORIZONTAL}
            {displayOrientationDropdown && (
              <div className={styles.dropdownList}>
                {Object.values(orientationLabels).map(value => (
                  <span
                    role="presentation"
                    className={styles.dropdownOption}
                    key={value}
                    onClick={() => {
                      if (value === orientationLabels.VERTICAL) {
                        setBarsVertical(true);
                      } else {
                        setBarsVertical(false);
                      }
                    }}
                  >
                    {value}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        {!fetchedStatisticalRelevance ? (
          <div className={styles.toolbarItem}>
            <div
              ref={numbersRef}
              role="presentation"
              className={styles.dropdownContainer}
              onClick={() => setDisplayNumbersDropdown(prev => !prev)}
              style={{ minWidth: '160px' }}
            >
              <img
                className={`${styles.dropdownArrow} ${
                  displayNumbersDropdown ? styles.dropdownArrowActive : ''
                }`}
                src={blackArrowDown}
                alt="Drop down arrow"
              />
              {isPercentage ? numberLabels.PERCENTAGE : numberLabels.ABSOLUTE}
              {displayNumbersDropdown && (
                <div className={styles.dropdownList}>
                  {Object.values(numberLabels).map(value => (
                    <span
                      role="presentation"
                      className={styles.dropdownOption}
                      key={value}
                      onClick={() => {
                        if (value === numberLabels.PERCENTAGE) {
                          setIsPercentage(true);
                        } else {
                          setIsPercentage(false);
                        }
                      }}
                    >
                      {value}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : null}
        {!isMatrixQuestion ? (
          <div
            className={`${styles.toolbarItem} ${styles.clickable} ${
              styles.verticalAlign
            }`}
            role="presentation"
            onClick={() => {
              onStatisticalRelevanceClick(!fetchedStatisticalRelevance);
            }}
          >
            <div
              className={`${styles.visibleBox} ${
                fetchedStatisticalRelevance ? styles.visibleBoxActive : null
              }`}
            >
              <img
                src={tickIconWhite}
                className={styles.visibleTick}
                alt="Tick"
              />
            </div>
            Show statistical relevance
          </div>
        ) : null}
        {!isMatrixQuestion ? (
          <div
            className={`${styles.toolbarItem} ${styles.clickable}`}
            role="presentation"
            onClick={onRemoveMergedChartClick}
          >
            <span className={styles.itemLabel}>Delete merged graph</span>
          </div>
        ) : null}
        {isMatrixQuestion && showCompareToTotalSample ? (
          <div
            className={`${styles.toolbarItem} ${styles.verticalAlign} ${
              styles.clickable
            }`}
            role="presentation"
            onClick={() => {
              setFilteringOnSelection(!filteringOnSelection);
            }}
          >
            <div
              className={`${styles.visibleBox} ${
                !filteringOnSelection ? styles.visibleBoxActive : null
              }`}
            >
              <img
                src={tickIconWhite}
                className={styles.visibleTick}
                alt="Tick"
              />
            </div>
            Compare to total sample
          </div>
        ) : null}
        {!viewToken ? (
          <AddInsight
            setShowAddInsightsPopup={setShowAddInsightsPopup}
            questionId={questionId}
            type={insightQuestionTypes.TEMPLATE}
          />
        ) : null}
      </div>
      <img
        src={dropDownBoldGreyIcon}
        alt="Display toolbar"
        className={`${styles.dropDownToolbarIcon} ${
          !toolbarActive ? styles.dropDownToolbarIconInactive : ''
        }`}
        role="presentation"
        onClick={() => {
          setToolbarActive(!toolbarActive);
        }}
      />
    </>
  );
};

export default MergedGraphToolbar;
