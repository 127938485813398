import React from 'react';

import styles from './Onboarding.module.css';

export default props => {
  const { onboardingSurveys } = props;

  return (
    <div>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle}>
          Onboarding survey(s) for this community
        </div>
        <div className={styles.sectionDetails}>
          {onboardingSurveys && onboardingSurveys.length
            ? onboardingSurveys.map(s => (
                <div className={styles.item}>{s.projectName}</div>
              ))
            : null}
        </div>
      </div>
      <div className={styles.explanation}>
        {`Onboarding surveys are shown to new users (of a specific community) before they see any other survey(s).\nOnly if all onboarding surveys are completed, users will be able to see other surveys. They won’t see any more onboarding surveys in the future.`}
      </div>
    </div>
  );
};
