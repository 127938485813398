import React, { useState, useEffect, useMemo, useRef } from 'react';

import styles from './RankingQuestion.module.css';
import colorsPallete from '../../../../../../colors';

import Bars from './components/Bars/Bars';
import AnswersList from './components/AnswersList/AnswersList';

import api from '../../../../../../../../../api';

export default ({
  question,
  activeFilters,
  activeSorting,
  allowOtherIndex,
  allowNoneOfTheAboveIndex,
  results,
  campaignId,
  probabilityAnswerSelected,
  isAllowedToExecuteRequests,
  viewToken,
  showOtherAnswers,
  onToggleFilter,
  filteringOnSelectionEnabled
}) => {
  const barsParent = useRef();
  const [isPercentage, setIsPercentage] = useState(true);
  const [hiddenAnswers, setHiddenAnswers] = useState([]);
  const [initialChartData, setInitialChartData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [showError, setShowError] = useState(false);
  const [analyticAnswers, setAnalyticAnswers] = useState([]);

  useEffect(
    () => {
      if (probabilityAnswerSelected && !analyticAnswers.length) {
        // fetchAnalyticAnswers if they do not exist
        getAnalyticsData();
      }
      if (!probabilityAnswerSelected && showError && !isFetching) {
        setShowError(false);
      }
    },
    [probabilityAnswerSelected]
  );

  const isFiltered =
    activeFilters &&
    activeFilters.length > 0 &&
    !activeFilters.some(
      activeFilter => activeFilter.filterName === question.id
    );

  const sortOrderTable = (orderTable, weightValues) => {
    if (weightValues.length) {
      const sortedOrderTable = [];
      sortedOrderTable.push(orderTable[0]);
      const orderTableData = weightValues.map(
        weightValue => orderTable[weightValue.sortingIndex]
      );
      sortedOrderTable.push(orderTableData);
      return sortedOrderTable;
    }
    return orderTable;
  };

  const getNoneOfTheAboveCount = (i, answersDistribution, selectAtMost) => {
    // None of the above count is total results count - ranked options count
    let otherAnswerOptionsCount = 0;
    for (let y = 0; y < selectAtMost; y += 1) {
      if (answersDistribution[y] && answersDistribution[y][i]) {
        otherAnswerOptionsCount += answersDistribution[y][i];
      }
    }
    const noneOfTheAboveCount = results.length - otherAnswerOptionsCount;
    return noneOfTheAboveCount;
  };

  const answerOrderTable = (
    block,
    resultStatsName,
    initialAnswersDistribution
  ) => {
    if (block && block.show_selected_answers_order) {
      if (
        block[resultStatsName].values &&
        block[resultStatsName].values[0] &&
        block[resultStatsName].values[0].answersCounter
      ) {
        let answersDistribution =
          block[resultStatsName].values[0].answersCounter;

        // When active filters AND filtering on selection (not total sample)
        if (initialAnswersDistribution) {
          // Do values in initialAnswersDistribution minus values in current answersDistribution
          answersDistribution = Object.entries(
            initialAnswersDistribution
          ).reduce((distribution, [key, value]) => {
            const newRow = value ? { ...value } : {};
            const filteredRowValues = answersDistribution[key];

            // Check if this row exists in filtered answersDistribution
            if (filteredRowValues) {
              Object.entries(filteredRowValues).forEach(
                ([rowKey, rowValue]) => {
                  newRow[rowKey] = value[rowKey] - rowValue;
                }
              );
              return {
                ...distribution,
                [key]: newRow
              };
            }
            return distribution;
          }, {});
        }

        if (
          allowNoneOfTheAboveIndex &&
          answersDistribution[allowNoneOfTheAboveIndex]
        ) {
          let noneOfTheAboveAnswerDistribution = {};

          // Set none of the above all ranking options same value
          const answersArray = [];
          block.answers.forEach((_a, i) => {
            if (i !== allowNoneOfTheAboveIndex) {
              answersArray.push(i);
            }
            if (i <= block.selectAtMost - 1) {
              noneOfTheAboveAnswerDistribution = {
                ...noneOfTheAboveAnswerDistribution,
                [i]:
                  i === 0
                    ? answersDistribution[allowNoneOfTheAboveIndex][0]
                    : getNoneOfTheAboveCount(
                        i,
                        answersDistribution,
                        block.selectAtMost
                      )
              };
            }
          });

          answersDistribution[
            allowNoneOfTheAboveIndex
          ] = noneOfTheAboveAnswerDistribution;
        }

        const orderTable = [];
        const columnsTotal = {};
        Object.keys(answersDistribution).map(column => {
          Object.keys(answersDistribution[column]).map(columnItem => {
            if (columnsTotal[columnItem]) {
              columnsTotal[columnItem] +=
                answersDistribution[column][columnItem];
            } else {
              columnsTotal[columnItem] =
                answersDistribution[column][columnItem];
            }
            return columnsTotal[columnItem];
          });

          return column;
        });

        const calulatePercentage = (value, total) => {
          if (!value || !total || value === 0 || total === 0) {
            return 0;
          }
          return ((value / total) * 100).toFixed();
        };

        const output = {
          absolute: answersDistribution,
          percentage: []
        };

        // Table header
        const tableHeader = [<div className="left-title top-title" />];
        for (let i = 0; i < block.selectAtMost; i += 1) {
          tableHeader.push(<div className="top-title">{i + 1}</div>);
        }
        orderTable.push(<div className="table-row">{tableHeader}</div>);

        let weightValues = [];

        block.answers.forEach((answer, index) => {
          const sortedAnswers = [
            <div className="left-title">{answer.answer}</div>
          ];

          for (
            let indexSelection = 0;
            indexSelection < block.selectAtMost;
            indexSelection += 1
          ) {
            if (
              answersDistribution[index] &&
              (answersDistribution[index][indexSelection] ||
                index === allowNoneOfTheAboveIndex)
            ) {
              if (!output.percentage[index]) output.percentage[index] = {};

              output.percentage[index][indexSelection] = calulatePercentage(
                answersDistribution[index][indexSelection],
                columnsTotal[indexSelection]
              );

              sortedAnswers.push(
                <div className="table-value">
                  {answersDistribution[index][indexSelection]}
                  <br />
                  <span className="percentage">
                    {calulatePercentage(
                      answersDistribution[index][indexSelection],
                      columnsTotal[indexSelection]
                    )}
                    %
                  </span>
                </div>
              );
            } else {
              if (!output.percentage[index]) output.percentage[index] = {};

              output.percentage[index][indexSelection] = calulatePercentage(
                answersDistribution &&
                  answersDistribution[index] &&
                  answersDistribution[index][indexSelection]
                  ? answersDistribution[index][indexSelection]
                  : null,
                columnsTotal[indexSelection]
              );

              sortedAnswers.push(
                <div className="table-value">
                  0<br />
                  <span className="percentage">
                    {calulatePercentage(
                      answersDistribution &&
                        answersDistribution[index] &&
                        answersDistribution[index][indexSelection]
                        ? answersDistribution[index][indexSelection]
                        : null,
                      columnsTotal[indexSelection]
                    )}
                    %
                  </span>
                </div>
              );
            }
          }

          let totalWeight = 0;
          let totalScore = 0;
          if (answersDistribution[index]) {
            const completeAnswerDistribution = answersDistribution[index];
            for (let i = 0; i < block.answers.length; i += 1) {
              if (!completeAnswerDistribution[i]) {
                completeAnswerDistribution[i] = 0;
              }
            }
            totalWeight = Object.values(completeAnswerDistribution).reduce(
              (t, n) => t + n,
              0
            );
            totalScore = Object.values(completeAnswerDistribution).reduce(
              (t, n, i) =>
                t + (n * (block.answers.length + 1 - (i + 1))) / totalWeight,
              0
            );
          }
          weightValues.push({
            sortingIndex: index + 1,
            totalScore
          });

          orderTable.push(<div className="table-row">{sortedAnswers}</div>);
        });
        if (activeSorting.length > 0 && weightValues.length) {
          const sorting = activeSorting.find(s => s.sortingName === block.id);
          if (sorting) {
            switch (sorting.sortingOrder) {
              case 'ASC':
                weightValues = weightValues.sort(
                  (a, b) => a.totalScore - b.totalScore
                );
                break;
              case 'DESC':
                weightValues = weightValues
                  .sort((a, b) => a.totalScore - b.totalScore)
                  .reverse();
                break;
              default:
                break;
            }
          }
        }
        output.orderTable = (
          <div className="sort-answers-table-container">
            {sortOrderTable(orderTable, weightValues)}
          </div>
        );

        return output;
      }
    }
    return null;
  };

  const parseRichText = string => {
    let questionValue;
    try {
      questionValue = JSON.parse(string)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
    } catch (error) {
      questionValue = string;
    }
    return questionValue;
  };

  const parseChartData = answerOrderData => {
    const data = [];

    if (answerOrderData) {
      const values = isPercentage
        ? answerOrderData.percentage
        : answerOrderData.absolute;
      const numberOfChoices = question.answers.length;
      let numberOfSelectableChoices = question.selectAtMost;

      const sorting = activeSorting.find(s => s.sortingName === question.id);
      if (sorting && sorting.sortingOrder === 'ANSWER') {
        numberOfSelectableChoices = numberOfChoices;
      }

      for (let q = 0; q < numberOfChoices; q += 1) {
        for (let c = 0; c < numberOfSelectableChoices; c += 1) {
          if (data[c]) {
            data[c][q] = parseInt(
              values[q] && values[q][c] ? values[q][c] : 0,
              10
            );
          }

          if (!data[c]) {
            data.push({
              group: `${parseInt(c, 10) + 1}`,
              [q]: parseInt(values[q] && values[q][c] ? values[q][c] : 0, 10)
            });
          }
        }
      }
    }

    return data;
  };

  const prepareChartData = (answerOrderData, unfilteredChartData) => {
    const chartData = parseChartData(answerOrderData);
    let finalChartData = JSON.parse(JSON.stringify(chartData));

    if (isFiltered) {
      finalChartData = finalChartData.reduce(
        (acc, d, i) => [
          ...acc,
          Object.entries(d).reduce((nacc, [key, value], j, narr) => {
            let absolute;

            if (j < narr.length - 1) {
              if (unfilteredChartData) {
                if (isPercentage) {
                  if (
                    unfilteredChartData.percentage &&
                    unfilteredChartData.percentage[j] &&
                    unfilteredChartData.percentage[j][i]
                  ) {
                    absolute = Number(unfilteredChartData.percentage[j][i]);
                  } else {
                    absolute = 0;
                  }
                }
                if (!isPercentage) {
                  if (
                    unfilteredChartData &&
                    unfilteredChartData.absolute &&
                    unfilteredChartData.absolute[j] &&
                    unfilteredChartData.absolute[j][i]
                  ) {
                    absolute = unfilteredChartData.absolute[j][i];
                  } else {
                    absolute = 0;
                  }
                }
              } else {
                absolute = isPercentage
                  ? Number(chartData[i][j])
                  : chartData[i][j];
              }
            }

            return {
              ...nacc,
              [j]:
                key === 'group'
                  ? { group: value }
                  : {
                      relative: value,
                      absolute
                    }
            };
          }, {})
        ],
        []
      );
    }

    finalChartData = finalChartData.reduce((acc, d, i, arr) => {
      const map = new Map();
      let subgroup = Object.entries(d);

      const sorting = activeSorting.find(s => s.sortingName === question.id);

      if (
        hiddenAnswers &&
        hiddenAnswers.length &&
        !(sorting && sorting.sortingOrder === 'ANSWER')
      ) {
        subgroup = subgroup.filter(
          (_d, subgroupIndex) => !hiddenAnswers.includes(subgroupIndex)
        );
      }

      const groupEntity = subgroup.pop();

      if (sorting) {
        switch (sorting.sortingOrder) {
          case 'ASC':
            subgroup = subgroup
              .sort((a, b) =>
                isFiltered ? b[1].relative - a[1].relative : b[1] - a[1]
              )
              .reverse();
            break;
          case 'DESC':
            subgroup = subgroup.sort((a, b) =>
              isFiltered ? b[1].relative - a[1].relative : b[1] - a[1]
            );
            break;
          default:
            break;
        }
      }

      if (sorting && sorting.sortingOrder === 'ANSWER') {
        subgroup.forEach((_, j) => {
          if (arr[j][i]) {
            map.set(j, arr[j][i].relative || arr[j][i]);
            if (isFiltered) {
              map.set(
                `${j}_nofilter`,
                isPercentage
                  ? Number(unfilteredChartData.percentage[i][j])
                  : unfilteredChartData.absolute[i][j]
              );
            }
          }
        });
        map.set('group', question.answers[i].answer);
      } else {
        subgroup.forEach(([key, values]) => {
          map.set(
            Number.isNaN(+key) ? key : Number(key),
            values.relative || values
          );

          if (isFiltered) {
            map.set(`${key}_nofilter`, values.absolute);
          }
        });
        map.set('group', groupEntity[1].group || groupEntity[1]);
      }

      return [...acc, map];
    }, []);

    return finalChartData;
  };

  const answerOrderData = answerOrderTable(question, 'resultStats');
  if (!initialChartData) {
    // Save initial chart data to show filtered data compared to total sample
    setInitialChartData({
      percentage: answerOrderData && answerOrderData.percentage,
      absolute: answerOrderData && answerOrderData.absolute
    });
  }

  const data = useMemo(
    () => {
      let unfilteredChartData = null;
      if (isFiltered) {
        let unfilteredAnswerOrderData = answerOrderData;
        if (
          filteringOnSelectionEnabled &&
          initialChartData &&
          initialChartData.absolute
        ) {
          // Filtering on selection means unfilteredResultStats values should be deducted with filteredResultStats values
          unfilteredAnswerOrderData = answerOrderTable(
            question,
            'resultStats',
            initialChartData.absolute
          );
          unfilteredChartData = {
            percentage:
              unfilteredAnswerOrderData && unfilteredAnswerOrderData.percentage,
            absolute:
              unfilteredAnswerOrderData && unfilteredAnswerOrderData.absolute
          };
        } else {
          unfilteredChartData = initialChartData;
        }
      }
      return prepareChartData(answerOrderData, unfilteredChartData);
    },
    [
      question,
      isFiltered,
      isPercentage,
      activeSorting,
      hiddenAnswers,
      filteringOnSelectionEnabled
    ]
  );

  const getAnalyticsData = async () => {
    if (isAllowedToExecuteRequests) {
      const input = createAnalyticsServiceInputFromBlockResults(question);

      setIsFetching(true);

      const campaignsList = await api().getAnalyticsData(
        { id: campaignId },
        input,
        viewToken || null
      );

      if (campaignsList.status === 200) {
        setAnalyticAnswers(campaignsList.json.data);
      } else {
        setShowError(true);
      }
      setIsFetching(false);
    }
  };

  const createAnalyticsServiceInputFromBlockResults = inputBlock => {
    const answersToAnalyse = [];
    const values = inputBlock.results.map(result => {
      if (result.answer || result.answer === 0) {
        if (answersToAnalyse.indexOf(result.answer) === -1) {
          answersToAnalyse.push(result.answer);
        }

        return [`${result.answer}`];
      }
      if (result.answers || result.answers === 0) {
        result.answers.forEach(answer => {
          if (answersToAnalyse.indexOf(answer) === -1) {
            answersToAnalyse.push(parseInt(answer, 10));
          }
        });

        return [`${result.answers}`];
      }
      return null;
    });

    const levels = answersToAnalyse
      .sort((a, b) => a - b)
      .map(answerIndex => `${answerIndex}`);
    return {
      Q004: {
        class: ['checkbox'],
        values,
        levels,
        label: [inputBlock.question],
        label0: {}
      }
    };
  };

  const onBarClick = (key, subgroupIndex) => {
    // key = selected answer option
    // subgroupIndex = selected rank

    const rankedResultIds = results.reduce((ids, r) => {
      if (r.answers && r.answers.length && r.answers[subgroupIndex] === key) {
        return [...ids, r.resultId];
      }
      return ids;
    }, []);

    const rankValue = subgroupIndex ? parseInt(subgroupIndex, 10) + 1 : 0;

    onToggleFilter(
      question.id,
      'answer',
      key,
      'attributes.block_results',
      result => {
        if (result && result.id && rankedResultIds.indexOf(result.id) > -1) {
          return true;
        }
        return false;
      },
      rankValue ? `rank ${rankValue}` : ''
    );
  };

  return [
    <div className={styles.switchParentContainer}>
      <div className={styles.switchContainer}>
        <span role="presentation">
          <label className={styles.ratingSwitch} htmlFor="checkbox">
            <input
              type="checkbox"
              checked={isPercentage}
              className={styles.input}
            />
            <span
              className={styles.ratingSlider}
              role="presentation"
              onClick={e => {
                e.stopPropagation();
                setIsPercentage(!isPercentage);
              }}
            >
              <span
                className={
                  isPercentage
                    ? styles.ratingPercentageOn
                    : styles.ratingPercentageOff
                }
              >
                %
              </span>
              <span
                className={
                  !isPercentage ? styles.ratingNumberOn : styles.ratingNumberOff
                }
              >
                #
              </span>
            </span>
          </label>
        </span>
      </div>
    </div>,
    <div className={styles.chartContainer} ref={barsParent}>
      {showError || isFetching ? (
        <>
          {showError && (
            <div className="no-chart-data">
              Error occurred. Please contact administrator for more details.
            </div>
          )}
          {isFetching && (
            <div className={styles.loadingAnimation}>
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
        </>
      ) : (
        <Bars
          parent={barsParent}
          height="250"
          data={data}
          colorsPallete={colorsPallete}
          isPercentage={isPercentage}
          isFiltered={isFiltered}
          hiddenAnswers={hiddenAnswers}
          onBarClick={onBarClick}
          activeSorting={activeSorting}
          question={question}
          analyticAnswers={analyticAnswers}
          probabilityAnswerSelected={probabilityAnswerSelected}
        />
      )}
    </div>,
    <AnswersList
      answers={question.answers}
      colorsPallete={colorsPallete}
      hiddenAnswers={hiddenAnswers}
      setHiddenAnswers={setHiddenAnswers}
      parseRichText={parseRichText}
      allowOtherIndex={allowOtherIndex}
      allowNoneOfTheAboveIndex={allowNoneOfTheAboveIndex}
      showOtherAnswers={showOtherAnswers}
    />,
    <div>{answerOrderData && answerOrderData.orderTable}</div>
  ];
};
