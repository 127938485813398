import { connect } from 'react-redux';
import { logout, setSelectedAccountManagementRecord } from '../../auth/actions';
import * as actions from '../components/SurveyBuilderPage/SurveyBuilder/state/actions';
import SurveyBuilderPage from '../components/SurveyBuilderPage/SurveyBuilderPage';

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  isAuthenticated: state.auth.isAuthenticated,
  isProfilingTagsManager: state.auth.isProfilingTagsManager,
  clientEmail: state.auth.clientEmail,
  surveysState: state.surveys,
  accountManagement: state.auth.accountManagement,
  defaultConstants: state.auth.defaultConstants
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
  emptySurveyState: () => dispatch(actions.emptySurveyState()),
  updateDecisionFramework: (api, question) =>
    dispatch(actions.updateDecisionFramework(api, question)),
  setSelectedAccountManagementRecord: record => {
    dispatch(setSelectedAccountManagementRecord(record));
    window.location.href = '/clients';
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyBuilderPage);
