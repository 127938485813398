import React from 'react';
import Icon from '../../../../../../../../campaigns/components/Icon';

import {
  COMMUNITY_DASHBOARD_FILTER_TYPE,
  USER_PROPERTY_NAMES,
  COMMUNITY_MANAGEMENT_NAMES
} from '../../../../../../helpers/constants';
import getUserPropertiesLabels from '../../../../../../helpers/getUserPropertiesLabels';

import styles from './ActiveFilters.module.css';
import { ACQUISITION_METRICS_PROPERTIES } from '../../../../../../../AcquisitionMetrics/helpers/constants';

export default ({ appliedFilters, setAppliedFilters, isGlobalLoading }) => {
  const onRemoveFilterClick = index => {
    const newAppliedFilters = appliedFilters.filter((aF, i) => i !== index);
    setAppliedFilters(newAppliedFilters);
  };

  const getFilterLabel = index => {
    const singleFilter = appliedFilters[index];

    if (
      singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.PROFILE_TAG &&
      singleFilter.tags
    ) {
      return `${singleFilter.tags.category} > ${singleFilter.tags.tagName} > ${
        singleFilter.tags.tagValue
      }`;
    }

    if (
      singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.USER_PROPERTY &&
      singleFilter.userProperties
    ) {
      let { propertyName, propertyValue } = singleFilter.userProperties;

      if (USER_PROPERTY_NAMES[propertyName])
        propertyName = USER_PROPERTY_NAMES[propertyName];

      propertyValue =
        propertyValue && getUserPropertiesLabels(propertyValue)
          ? getUserPropertiesLabels(propertyValue)
          : propertyValue;

      return `User property > ${propertyName} > ${propertyValue}`;
    }

    if (
      singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.COMMUNITY &&
      singleFilter.community
    ) {
      let { communityType } = singleFilter.community;

      if (communityType && COMMUNITY_MANAGEMENT_NAMES[communityType])
        communityType = COMMUNITY_MANAGEMENT_NAMES[communityType];

      return `Community > ${communityType} > ${singleFilter.community.label ||
        singleFilter.community.name}`;
    }

    if (
      singleFilter.type === COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION &&
      singleFilter.acquisition
    ) {
      return Object.values(ACQUISITION_METRICS_PROPERTIES).reduce(
        (acc, property) => {
          if (
            singleFilter.acquisition &&
            singleFilter.acquisition[property] &&
            singleFilter.acquisition[property].length
          ) {
            return `${acc} > ${singleFilter.acquisition[property].join()}`;
          }
          return acc;
        },
        'Acquisition filters >'
      );
    }

    return '';
  };

  return (
    <div className={styles.activeFiltersContainer}>
      {appliedFilters.map((activeFilter, index) => (
        <div
          className={`${
            styles.activeFilterItem
          } campaign-filters-active-filter-item`}
          key={`active-filter-container-${index.toString()}`}
        >
          <span
            className={styles.activeFilterItemLabel}
            title={getFilterLabel(index)}
          >
            {getFilterLabel(index)}
          </span>
          <span
            className={styles.removeIcon}
            role="presentation"
            onClick={() => {
              if (!isGlobalLoading) {
                onRemoveFilterClick(index);
              }
            }}
          >
            <Icon type="close" />
          </span>
        </div>
      ))}
      {appliedFilters && appliedFilters.length ? (
        <span
          className={`${
            styles.clearAllFilters
          } campaign-filters-clear-all-filters-button`}
          role="presentation"
          onClick={() => {
            if (!isGlobalLoading) {
              setAppliedFilters([]);
            }
          }}
        >
          Clear all filters
        </span>
      ) : null}
    </div>
  );
};
