import React, { useState } from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import styles from './SchedulePopup.module.css';
import { scheduleErrors } from '../../../surveys/components/SurveyBuilderPage/PublishSurvey/components/SurveyScheduler/SurveyScheduler';
import TimePicker from '../../../surveys/components/SurveyBuilderPage/PublishSurvey/components/TimePicker/TimePicker';

export default ({ schedulePushNotification }) => {
  const [date, setDate] = useState(new Date());
  const [dateError] = useState();
  const [timeError] = useState();

  return (
    <div className={styles.actionOption}>
      <div className={styles.switcherContainer}>
        <span className={styles.checkboxLabel}>
          I want to schedule the time of{' '}
          <span className={styles.actionLabel}>PUBLISHING</span>
        </span>
      </div>
      <div className={`${styles.dateTimeContainer}`}>
        <div className={styles.dateTimeInputContainer}>
          Date
          <span className={`${styles.dateInput}`}>
            <DayPickerInput
              placeholder="##/##/##"
              format="MM/dd/yyyy"
              onDayChange={d => {
                const oldTime = new Date(date);
                setDate(
                  new Date(d).setHours(oldTime.getHours(), oldTime.getMinutes())
                );
              }}
              value={date && date !== '' ? new Date(date) : null}
              dayPickerProps={{
                canChangeMonth: true
              }}
            />
          </span>
          {dateError && dateError !== '' && (
            <span
              className={`${styles.errorMessage} ${dateError ===
                scheduleErrors.unpublishMatch && styles.extendedErrorMessage}`}
            >
              {dateError}
            </span>
          )}
        </div>
        <div
          className={`${styles.dateTimeInputContainer} ${
            timeError ? styles.errorMessageBorder : null
          }`}
        >
          Time
          <span>
            <TimePicker
              placeholder="##:##"
              className={styles.timeInput}
              onTimeChange={t => setDate(t)}
              time={date && date !== '' ? new Date(date) : null}
            />
            {timeError && (
              <span className={styles.errorMessage}>{timeError}</span>
            )}
          </span>
        </div>
      </div>
      <div className={styles.footer}>
        <div
          className={
            date
              ? `${styles.sendPushButton}`
              : `${styles.sendPushButton} ${styles.sendPushButtonDisabled}`
          }
          role="presentation"
          onClick={() => {
            if (date) {
              schedulePushNotification(date);
            }
          }}
        >
          Schedule
        </div>
      </div>
    </div>
  );
};
