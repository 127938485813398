export const insightQuestionTypes = {
  QUESTION: 'QUESTION',
  TEMPLATE: 'TEMPLATE'
};

export const loversHatersFilters = [
  'xLovers',
  'xHaters',
  'yLovers',
  'yHaters',
  'xLoversyLovers',
  'xHatersyHaters'
];
