import React, { useState, useEffect } from 'react';

import Question from './components/Question/Question';
import OverallStatistics from './components/OverallStatistics/OverallStatistics';

import styles from './MainContent.module.css';

const MainContent = ({
  surveyId,
  stats,
  blocks,
  filteredResults,
  populationColors,
  selectedWorkspace,
  activePopulations,
  disabled,
  emptyWorkspace
}) => {
  const [questionBlocks, setQuestionBlocks] = useState([]);

  if (!filteredResults) {
    return null;
  }

  useEffect(
    () => {
      if (!disabled && !emptyWorkspace) {
        const blockIds = selectedWorkspace.questions.map(w => w.id);
        const selectedBlocks = blocks.filter(b => blockIds.indexOf(b.id) > -1);
        if (selectedBlocks) {
          const modifyBlocks = [];
          selectedBlocks.forEach(block => {
            const modifyBlock = block;
            const blockPopulation = [];

            filteredResults.forEach(populationResults => {
              const singlePopulationResults = [];

              populationResults.forEach(populationResult => {
                const blockResult = populationResult.attributes.block_results.filter(
                  blockPopulationResult =>
                    blockPopulationResult.block === block.id
                );

                if (blockResult && blockResult.length) {
                  singlePopulationResults.push(blockResult[0]);
                }
              });
              blockPopulation.push(singlePopulationResults);
            });

            modifyBlock.blockPopulation = blockPopulation;
            modifyBlocks.push(JSON.parse(JSON.stringify(modifyBlock)));
          });
          setQuestionBlocks(modifyBlocks);
        }
      }
    },
    [filteredResults]
  );

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      <OverallStatistics stats={stats} disabled={disabled} />
      {!disabled && !emptyWorkspace ? (
        <>
          <div className={styles.dividerLine} />
          <div className="workspaces-main-content-questions-container">
            {questionBlocks.map((block, index) => (
              <Question
                key={`main-content-question-${index.toString()}`}
                surveyId={surveyId}
                index={index}
                block={block}
                populationColors={populationColors}
                activePopulations={activePopulations}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default MainContent;
