import React from 'react';

import styles from './RouteSubtitle.module.css';

export default props => {
  const { history, communityName } = props;

  return (
    <span className={styles.routeSubtitle}>
      <span
        key="management-routes-customers"
        className={styles.appBarPath}
        role="presentation"
        onClick={() => {
          history.push('/community-management');
        }}
      >
        Communities
      </span>
      <span key="customers-splitter" className={styles.appBarSpliter}>
        {'>'}
      </span>
      <span className={styles.appBarPath}>{communityName}</span>
    </span>
  );
};
