import React from 'react';

import Button from '../../../../../../campaigns/components/Button/Button';

import styles from './CommunityManagementHeader.module.css';

export default ({ title, onAddButtonLabel, onAddClick }) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.rightContainer}>
      {onAddClick ? (
        <Button
          role="button"
          label={onAddButtonLabel}
          onClick={() => onAddClick()}
          icon="add"
        />
      ) : null}
    </div>
  </div>
);
