const SURVEY_STATUSES = {
  MISSING_REQUIRED_DATA: 'incomplete',
  TESTING: 'testing',
  AWAITING_PUBLISH: 'awaiting publish',
  AWAITING_LAUNCH: 'awaiting launch',
  PUBLISHED: 'published',
  FINISHED: 'finished'
};

export default SURVEY_STATUSES;
