import React from 'react';
import useLazyQuery from '../../../../surveys/hooks/useLazyQuery';

import DebounceInput from '../../../../campaigns/components/DebounceInput/DebounceInput';
import SearchDropdown from '../SearchDropdown/SearchDropdown';

import {
  SEARCH_PROFILING_CATEGORIES,
  SEARCH_PROFILING_TAG_NAMES,
  SEARCH_PROFILING_TAG_VALUES
} from '../../../../graphql/Question';

import styles from './ProfileTagFilters.module.css';

export default ({ appliedFilters, setAppliedFilters }) => {
  const searchProfilingCategoriesApi = useLazyQuery(
    SEARCH_PROFILING_CATEGORIES,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const searchProfilingTagNamesApi = useLazyQuery(SEARCH_PROFILING_TAG_NAMES, {
    fetchPolicy: 'no-cache'
  });
  const searchProfilingTagValuesApi = useLazyQuery(
    SEARCH_PROFILING_TAG_VALUES,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const searchCategories = async (page, search) => {
    const preparedAppliedFilters = { ...appliedFilters };
    delete preparedAppliedFilters.category;

    const searchResults = await searchProfilingCategoriesApi({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingCategories &&
      searchResults.data.searchProfilingCategories.options
      ? searchResults.data.searchProfilingCategories.options
      : [];
  };

  const searchProfilingTagNames = async (page, search) => {
    const preparedAppliedFilters = { ...appliedFilters };
    delete preparedAppliedFilters.tagName;

    const searchResults = await searchProfilingTagNamesApi({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagNames &&
      searchResults.data.searchProfilingTagNames.options
      ? searchResults.data.searchProfilingTagNames.options
      : [];
  };

  const searchProfilingTagValues = async (page, search) => {
    const preparedAppliedFilters = { ...appliedFilters };
    delete preparedAppliedFilters.tagValue;

    const searchResults = await searchProfilingTagValuesApi({
      variables: {
        ...preparedAppliedFilters,
        start: 0,
        end: (page + 1) * 10,
        search,
        salt: Math.random().toString()
      }
    });
    return searchResults &&
      searchResults.data &&
      searchResults.data.searchProfilingTagValues &&
      searchResults.data.searchProfilingTagValues.options
      ? searchResults.data.searchProfilingTagValues.options
      : [];
  };

  const getLanguageOptions = () => [
    {
      label: 'Dutch',
      value: 'Dutch'
    },
    {
      label: 'French',
      value: 'French'
    },
    {
      label: 'English',
      value: 'English'
    },
    {
      label: 'German',
      value: 'German'
    }
  ];

  const onSelectCompleted = (property, value) => {
    if (value) {
      setAppliedFilters({
        ...appliedFilters,
        [property]: value
      });
    } else {
      const filteredAppliedFilters = {
        ...appliedFilters,
        [property]: null
      };
      setAppliedFilters(filteredAppliedFilters);
    }
  };

  const resetFilters = () => {
    setAppliedFilters({
      language: 'Dutch'
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchInputContainer}>
        <div className={styles.questionContaining}>Question containing</div>
        <DebounceInput
          className={styles.questionContainingInput}
          initialValue={appliedFilters.search || ''}
          onChangeValue={search =>
            setAppliedFilters({ ...appliedFilters, search })
          }
        />
      </div>
      <div className={styles.dropdownContainers}>
        <SearchDropdown
          fetchOptions={getLanguageOptions}
          placeholder="Select a language"
          onSelectCompleted={value => onSelectCompleted('language', value)}
          value={{
            value: appliedFilters.language,
            label: appliedFilters.language
          }}
          search={false}
        />
        <SearchDropdown
          fetchOptions={searchCategories}
          placeholder="Search for a category"
          onSelectCompleted={value => onSelectCompleted('category', value)}
          value={{
            value: appliedFilters.category,
            label: appliedFilters.category
          }}
        />
        <SearchDropdown
          fetchOptions={searchProfilingTagNames}
          placeholder="Search for tag name"
          onSelectCompleted={value => onSelectCompleted('tagName', value)}
          value={{
            value: appliedFilters.tagName,
            label: appliedFilters.tagName
          }}
        />
        <SearchDropdown
          fetchOptions={searchProfilingTagValues}
          placeholder="Search for tag value"
          onSelectCompleted={value => onSelectCompleted('tagValue', value)}
          value={{
            value: appliedFilters.tagValue,
            label: appliedFilters.tagValue
          }}
        />
      </div>
      <div className={styles.resetFilters}>
        <span onClick={() => resetFilters()} role="presentation">
          Reset filters
        </span>
      </div>
    </div>
  );
};
