import { put, select, call } from 'redux-saga/effects'; // call,

import * as actions from '../../actions';

import addFlowsToGroup from './addFlowsToGroup';
import updateDecisionFramework from '../updateDecisionFramework/updateDecisionFramework';

export default function*(action) {
  const surveysState = yield select(state => state.surveys);

  let newState = { ...surveysState };

  if (surveysState.activeFlow && !surveysState.activeFlow.flows) {
    const newSurveyStateActiveFlow = {
      question: { ...surveysState.activeFlow }
    };
    action.api('updateQuestion', newSurveyStateActiveFlow);

    if (
      surveysState.activeFlow.matrix &&
      surveysState.activeFlow.matrix.decisionFrameworkType
    ) {
      const {
        surveyWithUpdatedFramework,
        updateRequest
      } = updateDecisionFramework(surveysState.survey, surveysState.activeFlow);
      if (surveyWithUpdatedFramework) {
        newState = {
          ...newState,
          survey: surveyWithUpdatedFramework
        };
        if (updateRequest) {
          yield call(action.api, updateRequest.api, {
            variables: updateRequest.variables
          });
        }
      }
    }
  }

  newState = addFlowsToGroup(
    newState,
    action.isTop,
    action.firstFlow,
    action.secondFlow
  );
  yield put({ type: actions.SET_NEW_STATE, newState: newState.newState });

  // Update survey
  const surveyUpdateContent = {
    survey: newState.newState.survey.id,
    content: newState.newState.survey.content
  };
  action.api('updateSurvey', { variables: surveyUpdateContent });
}
