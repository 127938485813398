import React from 'react';
import styles from '../../UserConsents.module.css';
import ConfirmInput from '../../../../../../../../form/ConfirmInput/ConfirmInput';

export default props => {
  const {
    userDetails,
    setUserDetails,
    campaign,
    strings,
    componentErrors
  } = props;

  const translations =
    localStorage.getItem('campaignLanguage') &&
    strings[localStorage.getItem('campaignLanguage')]
      ? strings[localStorage.getItem('campaignLanguage')]
      : strings.English;

  /* eslint-disable */
  const createProfileSection = () => {
    let profileSection = [];

    profileSection.push(
      <div
        className={styles.createProfileInputContainer}
        key="confirm-input-container"
      >
        <ConfirmInput
          name="campaign-user-details-create-profile"
          label={translations.CREATE_USER_PROFILE}
          active={userDetails.createProfile}
          onClick={() =>
            setUserDetails({
              ...userDetails,
              createProfile: !userDetails.createProfile
            })
          }
        />
      </div>
    );

    if (userDetails.createProfile) {
      profileSection.push(
        <div>
          <label className={styles.labelText}>
            {translations.CHOOSE_PASSWORD}
          </label>
          <input
            type="password"
            className={`${styles.input} campaign-user-details-password-input`}
            placeholder={translations.ENTER_YOUR_PASSWORD}
            onChange={event =>
              setUserDetails({ ...userDetails, password: event.target.value })
            }
            value={userDetails.password}
          />
          <input
            type="password"
            className={`${styles.input} campaign-user-details-rpassword-input`}
            placeholder={translations.CONFIRM_PASSWORD}
            onChange={event =>
              setUserDetails({
                ...userDetails,
                repeatPassword: event.target.value
              })
            }
            value={userDetails.repeatPassword}
          />
        </div>
      );
    }

    return profileSection;
  };
  /* eslint-enable */

  return (
    <div className={styles.accountCreateContainer}>
      {(campaign.contactForPrizes ||
        campaign.contactForNextSurvey ||
        campaign.addToMailingListOfCustomer ||
        campaign.askToRegisterOnBufflWebSurvey ||
        campaign.webVersionEmailConfirmation) && (
        <div
          className={`${styles.title} campaign-user-details-page-title-second`}
        >
          {translations.JUST_THIS}
        </div>
      )}

      <div className={styles.userDetailsPage}>
        {(campaign.contactForPrizes ||
          campaign.contactForNextSurvey ||
          campaign.addToMailingListOfCustomer ||
          campaign.askToRegisterOnBufflWebSurvey ||
          campaign.webVersionEmailConfirmation) && (
          <div>
            <p className={styles.textDescription}>
              {translations.WE_CAN_REACH_YOU}
            </p>
            <label htmlFor="email" className={styles.label}>
              <span className={styles.labelText}>E-mail</span>
              <input
                id="email"
                type="email"
                className={`${styles.input} ${
                  componentErrors &&
                  (!userDetails.email ||
                    userDetails.email === null ||
                    userDetails.email === '' ||
                    componentErrors === translations.EMAIL_IS_REQUIRED ||
                    componentErrors === translations.PLEASE_ENTER_VALID_EMAIL)
                    ? styles.errorField
                    : ''
                } campaign-user-details-email-input`}
                onChange={event =>
                  setUserDetails({ ...userDetails, email: event.target.value })
                }
                value={userDetails.email ? userDetails.email : ''}
              />
            </label>
          </div>
        )}

        {(campaign.contactForPrizes ||
          campaign.contactForNextSurvey ||
          campaign.addToMailingListOfCustomer) && (
          <div className={styles.confirmInputContainer}>
            {campaign.contactForNextSurvey && (
              <ConfirmInput
                name="contacted-for-follow-up"
                label={translations.WOULD_YOU_LIKE_TO_RECEIVE_NEW_SURVEYS}
                active={userDetails.contactNextSurveys}
                onClick={() => {
                  setUserDetails({
                    ...userDetails,
                    contactNextSurveys: !userDetails.contactNextSurveys
                  });
                }}
              />
            )}

            {campaign.contactForPrizes && (
              <ConfirmInput
                name="contacted-if-win"
                label={translations.YOU_WANT_TO_BE_CONTACTED}
                active={userDetails.contactWin}
                onClick={() => {
                  setUserDetails({
                    ...userDetails,
                    contactWin: !userDetails.contactWin
                  });
                }}
              />
            )}

            {campaign.addToMailingListOfCustomer && (
              <ConfirmInput
                name="contacted-for-follow-up"
                label={
                  translations.YES_I_WANT_TO_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER
                }
                active={userDetails.addToMailingListOfCustomer}
                onClick={() => {
                  setUserDetails({
                    ...userDetails,
                    addToMailingListOfCustomer: !userDetails.addToMailingListOfCustomer
                  });
                }}
              />
            )}
          </div>
        )}

        {/*
        campaign.askToRegisterOnBufflWebSurvey && (
          <div>
            <div
              className={`${
                styles.title
              } campaign-user-details-page-title-second`}
            >
              {translations.CREATE_A_BUFFL_USER_PROFILE}
            </div>
            <div className={styles.textDescription}>
              {translations.A_DONKEY_DOESNT_BUMP}
            </div>
            {createProfileSection()}
          </div>
            ) */}
      </div>
    </div>
  );
};
