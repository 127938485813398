import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import styles from '../CampaignCompleted/CampaignCompletedPage.module.css';

import GooglePlayStore from '../../../../assets/img/play-store.png';
import AppleStore from '../../../../assets/img/app-store.png';
import QRCode from '../../../../assets/img/qrcode-buffl-app.svg';

import { CONFIRM_RESULT } from '../../../../graphql/Campaigns';

export default props => {
  const { strings, setHideBufflLogo } = props;

  const [showBUFFLDownload, setShowBUFFLDownload] = useState(false);
  const [confirmResult] = useMutation(CONFIRM_RESULT);

  useEffect(() => {
    const confirmResultMethod = async () => {
      const confirmedResult = await confirmResult({
        variables: {
          result: props.match.params.resultId,
          confirmationToken: props.match.params.token,
          language: localStorage.getItem('campaignLanguage')
        }
      });

      if (
        confirmedResult &&
        confirmedResult.data &&
        confirmedResult.data.confirmWebSurvey &&
        confirmedResult.data.confirmWebSurvey.survey &&
        !confirmedResult.data.confirmWebSurvey.survey
          .hideDownloadBufflInWebSurvey
      ) {
        setShowBUFFLDownload(true);
      }

      if (
        confirmedResult &&
        confirmedResult.data &&
        confirmedResult.data.confirmWebSurvey &&
        confirmedResult.data.confirmWebSurvey.survey &&
        confirmedResult.data.confirmWebSurvey.survey.hideLogoInWebSurvey
      )
        setHideBufflLogo(true);
    };

    confirmResultMethod();
  }, []);

  const translations =
    localStorage.getItem('campaignLanguage') &&
    strings[localStorage.getItem('campaignLanguage')]
      ? strings[localStorage.getItem('campaignLanguage')]
      : strings.Dutch;

  /*
  if (tokenValid === true) {
    document.documentElement.scrollTop = 0;
    return (
      <div className={styles.mainContainer}>
        <StepsProgress activeStep="4" steps={steps} />
        <div className={styles.container}>
          <div
            className={`${
              styles.title
            } campaign-user-details-waiting-to-confirm-title`}
          >
            {translations.RESULT_CONFIRMED}
          </div>
          <div className={styles.description}>
            {translations.YOUR_RESULTS_HAS_BEEN_CONFIRMED}
          </div>
          <DiscoverBuffl
            message={translations.IF_YOU_LIKE_PARTICIPATING_IN_MORE_SURVEYS}
          />
        </div>
      </div>
    );
  }

  if (tokenValid === false) {
    document.documentElement.scrollTop = 0;
    return (
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div
            className={`${
              styles.title
            } campaign-user-details-waiting-to-confirm-title`}
          >
            {translations.YOUR_RESULT_WAS_ALREADY_REGISTERED_SUCESSFULY}
          </div>
          <DiscoverBuffl
            message={translations.IF_YOU_LIKE_PARTICIPATING_IN_MORE_SURVEYS}
          />
        </div>
      </div>
    );
  }

  if (confirmLoading) {
    document.documentElement.scrollTop = 0;
    return (
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div
            className={`${
              styles.title
            } campaign-user-details-waiting-to-confirm-title`}
          >
            Proccessing
          </div>
          <div className={styles.description}>Please wait</div>
        </div>
      </div>
    );
  }
  */

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          <div
            className={`${
              styles.title
            } campaign-user-details-waiting-to-confirm-title`}
          >
            {translations.THANK_YOU}
            <br />
            {translations.MORE_IMPACT}
          </div>
          <div className={styles.description}>
            {translations.WOULD_YOU_BE_INTERESTED_GIVING_AN_OPINION}
            <br />
            <br />
            {showBUFFLDownload ? translations.INSTALL_THE_BUFFL_APP : null}
          </div>
          {showBUFFLDownload ? (
            <div className={styles.qrCode}>
              <img src={QRCode} alt="BUFFL app QR code" />
            </div>
          ) : null}
        </div>
        <div className={styles.appStoreButtons}>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=org.nativescript.buffl"
            rel="noopener noreferrer"
          >
            <img src={GooglePlayStore} alt="Google Play Store Button" />
          </a>
          <a
            target="_blank"
            href="https://itunes.apple.com/be/app/buffl/id1356715033?l=nl&mt=8"
            rel="noopener noreferrer"
          >
            <img src={AppleStore} alt="App Store Button" />
          </a>
        </div>
      </div>
    </>
  );
};
/* eslint-enable */
