import React from 'react';

export default ({ fill = '#5300f2', width = 12.351, height = 17.056 }) => (
  <svg
    id="lj-arrow"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      id="Path_858"
      data-name="Path 858"
      d="M88.881,24a.882.882,0,0,1,.882.882v10a1.176,1.176,0,0,0,1.176,1.176h6.4l-1.729-1.729a.882.882,0,1,1,1.248-1.248l3.235,3.235a.882.882,0,0,1,0,1.248L96.857,40.8a.882.882,0,1,1-1.248-1.248l1.729-1.729h-6.4A2.941,2.941,0,0,1,88,34.881v-10A.882.882,0,0,1,88.881,24Z"
      transform="translate(-87.999 -24)"
      fill={fill}
    />
  </svg>
);
